import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import {
  comparators,
  comparatorDistanceUnits,
  comparatorDistanceUnitLabels,
  dataTypes,
} from '@adalo/constants'

import { getFieldLabel } from 'utils/tables'
import { pluralize } from 'utils/strings'
import { getComparatorOptions, isLiteralComparator } from 'utils/comparators'

import Button from 'components/Shared/Button'
import { IconButton } from 'components/Shared/Icon'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import ComparisonField from 'components/Shared/ComparisonField'

import { getDataType } from 'utils/dataTypes'
import { MenuOption } from 'ducks/recommender'
import { useFlags } from 'flags'

const unitOptions = [
  comparatorDistanceUnits.MILES,
  comparatorDistanceUnits.KILOMETERS,
].map(unit => new MenuOption(comparatorDistanceUnitLabels[unit], unit, unit))

const FilterItem = props => {
  const { fields, table, options } = props

  const { hasExposedRecordId } = useFlags()

  const getLabel = value => {
    const { getLabel } = props

    if (typeof value === 'string') {
      return getFieldLabel(table, value, hasExposedRecordId)
    }

    return getLabel(value)
  }

  const getTableName = () => {
    if (!table?.name) return ''

    return pluralize(table?.name)
  }

  const renderFilter = (fieldName, index) => {
    const { appId, componentId, fieldOptions, object } = props

    const filters = options?.filter || []
    const filter = filters?.[index] || {}
    const dataType = getDataType(filter, table, hasExposedRecordId)
    const comparator = filter?.comparator

    return (
      <div className="filter-options conditional" key={index}>
        <div className="filter-options-header">
          <p>Custom Filter</p>
          <IconButton type="trash-small" onClick={() => fields.remove(index)} />
        </div>
        <div className="form-inspect-field-wrapper">
          <Field
            className="white"
            name={`${fieldName}.fieldId`}
            component={WrappedSelect}
            options={fieldOptions}
            displayName="Column"
            placeholder="Select column..."
            getLabel={getLabel}
            rowHeight={40}
            boxed
          />
        </div>
        {filter?.fieldId && dataType ? (
          <>
            <div className="form-inspect-field-wrapper">
              <Field
                autoSelect
                className="white"
                name={`${fieldName}.comparator`}
                component={WrappedSelect}
                options={getComparatorOptions(dataType)}
                placeholder="Select comparison..."
                rowHeight={40}
                boxed
              />
            </div>
            {!isLiteralComparator(comparator) ? (
              <>
                {dataType === dataTypes.LOCATION ? (
                  <>
                    <div className="form-inspect-field-wrapper">
                      <ComparisonField
                        name={`${fieldName}.comparatorOptions.radius`}
                        appId={appId}
                        componentId={componentId}
                        objectId={object.id}
                        dataTypes={[dataTypes.NUMBER]}
                        placeholder="Select value..."
                        value={filter?.comparatorOptions?.radius}
                        boxed
                      />
                    </div>
                    <div className="form-inspect-field-wrapper">
                      <Field
                        name={`${fieldName}.comparatorOptions.unit`}
                        component={WrappedSelect}
                        WrappedSelect
                        props
                        autoSelect
                        options={unitOptions}
                        displayName="Unit"
                        placeholder="Select unit..."
                        rowHeight={40}
                        boxed
                      />
                    </div>
                  </>
                ) : null}
                <div className="form-inspect-field-wrapper">
                  <ComparisonField
                    name={`${fieldName}.comparison`}
                    appId={appId}
                    componentId={componentId}
                    objectId={object.id}
                    dataTypes={[dataType]}
                    placeholder="Select value..."
                    value={filter?.comparison}
                    boxed
                  />
                </div>
                {filter.comparator === comparators.BETWEEN ? (
                  <div className="form-inspect-field-wrapper">
                    <ComparisonField
                      name={`${fieldName}.comparison2`}
                      appId={appId}
                      componentId={componentId}
                      objectId={object.id}
                      dataTypes={[dataType]}
                      placeholder="Select value..."
                      value={filter?.comparison2}
                      boxed
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </div>
    )
  }

  const handleAddFilter = () => fields.push({})

  const tableName = fields.length > 0 ? 'Another' : getTableName()

  return (
    <div className="filter-options-items">
      <div>{fields.map((field, i) => renderFilter(field, i))}</div>
      <p className="filter-options-add">
        <Button
          listAddButton
          small
          gray
          type="button"
          icon="plus-small"
          onClick={handleAddFilter}
        >
          Add {tableName} Filter
        </Button>
      </p>
    </div>
  )
}

FilterItem.propTypes = {
  options: PropTypes.object,
  table: PropTypes.object,
}

export default FilterItem
