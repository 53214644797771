import { LOCATION_FORMAT_OPTIONS } from 'components/Editor/Inspect/Libraries/Table/consts'
import type { DefaultCellProps } from './types'

const LocationCell = ({ field }: DefaultCellProps): JSX.Element | null => {
  for (const { value, label } of LOCATION_FORMAT_OPTIONS) {
    if (value === field.binding.format?.locationFormat ?? '') {
      return <>{label}</>
    }
  }

  return null
}

export default LocationCell
