import {
  positioning as positioningValues,
  responsivePositioningOptions,
} from '@adalo/constants'
import { update } from '@adalo/utils'
import { set, cloneDeep } from 'lodash'

import { DEVICE_TYPES } from 'ducks/editor/positioning'
import { ContainerAttributes, LayoutAttributes } from 'utils/responsiveTypes'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import { ObjectList } from '../types/ObjectList'
import calculatePushGraphs from '../pushing/calculatePushGraphs'
import getContainingScreen from '../objects/helpers/getContainingScreen'

const { TOP, FIXED_ON_SCROLL } = responsivePositioningOptions

export interface ToggleFixedPositionOptions {
  objectId: string
  isFixed: boolean
}

export interface ToggleFixedPositionInstruction {
  operation: 'toggleFixedPosition'
  options: ToggleFixedPositionOptions
}

export const toggleFixedPositionHandler = (
  state: InstructionState,
  { objectId, isFixed }: ToggleFixedPositionOptions
): InstructionState => {
  const { list, pathMap } = state
  const object = cloneDeep(getObject(list, pathMap, objectId))

  const verticalPositioning = isFixed ? FIXED_ON_SCROLL : TOP

  set(object, 'responsivity.verticalPositioning', verticalPositioning)
  set(object, 'positioning', object.positioning || positioningValues.FIXED)

  for (const device of DEVICE_TYPES) {
    const deviceObject = object[device] as Partial<LayoutAttributes & ContainerAttributes> // prettier-ignore

    if (deviceObject !== undefined) {
      set(
        object,
        `${device}.responsivity.verticalPositioning`,
        verticalPositioning
      )
      set(object, `${device}.positioning`, deviceObject.positioning || positioningValues.FIXED) // prettier-ignore
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let updatedList: ObjectList = update(list, pathMap[objectId], object)
  const screen = getContainingScreen(updatedList, pathMap, objectId)

  updatedList = calculatePushGraphs(updatedList, pathMap, screen.id)

  return {
    ...state,
    list: updatedList,
  }
}

const toggleFixedPosition = (
  objectId: string,
  isFixed: boolean
): ToggleFixedPositionInstruction => ({
  operation: 'toggleFixedPosition',
  options: {
    objectId,
    isFixed,
  },
})

export default toggleFixedPosition
