/**
 * Array of supported currencies for stripe
 */
export const currencies = [
  { label: 'AED', value: 'aed' },
  { label: 'AFN', value: 'afn' },
  { label: 'ALL', value: 'all' },
  { label: 'AMD', value: 'amd' },
  { label: 'ANG', value: 'ang' },
  { label: 'AOA', value: 'aoa' },
  { label: 'ARS', value: 'ars' },
  { label: 'AUD', value: 'aud' },
  { label: 'AWG', value: 'awg' },
  { label: 'AZN', value: 'azn' },
  { label: 'BAM', value: 'bam' },
  { label: 'BBD', value: 'bbd' },
  { label: 'BDT', value: 'bdt' },
  { label: 'BGN', value: 'bgn' },
  { label: 'BIF', value: 'bif' },
  { label: 'BMD', value: 'bmd' },
  { label: 'BND', value: 'bnd' },
  { label: 'BOB', value: 'bob' },
  { label: 'BRL', value: 'brl' },
  { label: 'BSD', value: 'bsd' },
  { label: 'BWP', value: 'bwp' },
  { label: 'BZD', value: 'bzd' },
  { label: 'CAD', value: 'cad' },
  { label: 'CDF', value: 'cdf' },
  { label: 'CHF', value: 'chf' },
  { label: 'CLP', value: 'clp' },
  { label: 'CNY', value: 'cny' },
  { label: 'COP', value: 'cop' },
  { label: 'CRC', value: 'crc' },
  { label: 'CVE', value: 'cve' },
  { label: 'CZK', value: 'czk' },
  { label: 'DJF', value: 'djf' },
  { label: 'DKK', value: 'dkk' },
  { label: 'DOP', value: 'dop' },
  { label: 'DZD', value: 'dzd' },
  { label: 'EGP', value: 'egp' },
  { label: 'ETB', value: 'etb' },
  { label: 'EUR', value: 'eur' },
  { label: 'FJD', value: 'fjd' },
  { label: 'FKP', value: 'fkp' },
  { label: 'GBP', value: 'gbp' },
  { label: 'GEL', value: 'gel' },
  { label: 'GIP', value: 'gip' },
  { label: 'GMD', value: 'gmd' },
  { label: 'GNF', value: 'gnf' },
  { label: 'GTQ', value: 'gtq' },
  { label: 'GYD', value: 'gyd' },
  { label: 'HKD', value: 'hkd' },
  { label: 'HNL', value: 'hnl' },
  { label: 'HRK', value: 'hrk' },
  { label: 'HTG', value: 'htg' },
  { label: 'HUF', value: 'huf' },
  { label: 'IDR', value: 'idr' },
  { label: 'ILS', value: 'ils' },
  { label: 'INR', value: 'inr' },
  { label: 'ISK', value: 'isk' },
  { label: 'JMD', value: 'jmd' },
  { label: 'JPY', value: 'jpy' },
  { label: 'KES', value: 'kes' },
  { label: 'KGS', value: 'kgs' },
  { label: 'KHR', value: 'khr' },
  { label: 'KMF', value: 'kmf' },
  { label: 'KRW', value: 'krw' },
  { label: 'KYD', value: 'kyd' },
  { label: 'KZT', value: 'kzt' },
  { label: 'LAK', value: 'lak' },
  { label: 'LBP', value: 'lbp' },
  { label: 'LKR', value: 'lkr' },
  { label: 'LRD', value: 'lrd' },
  { label: 'LSL', value: 'lsl' },
  { label: 'MAD', value: 'mad' },
  { label: 'MDL', value: 'mdl' },
  { label: 'MGA', value: 'mga' },
  { label: 'MKD', value: 'mkd' },
  { label: 'MMK', value: 'mmk' },
  { label: 'MNT', value: 'mnt' },
  { label: 'MOP', value: 'mop' },
  { label: 'MRO', value: 'mro' },
  { label: 'MUR', value: 'mur' },
  { label: 'MVR', value: 'mvr' },
  { label: 'MWK', value: 'mwk' },
  { label: 'MXN', value: 'mxn' },
  { label: 'MYR', value: 'myr' },
  { label: 'MZN', value: 'mzn' },
  { label: 'NAD', value: 'nad' },
  { label: 'NGN', value: 'ngn' },
  { label: 'NIO', value: 'nio' },
  { label: 'NOK', value: 'nok' },
  { label: 'NPR', value: 'npr' },
  { label: 'NZD', value: 'nzd' },
  { label: 'PAB', value: 'pab' },
  { label: 'PEN', value: 'pen' },
  { label: 'PGK', value: 'pgk' },
  { label: 'PHP', value: 'php' },
  { label: 'PKR', value: 'pkr' },
  { label: 'PLN', value: 'pln' },
  { label: 'PYG', value: 'pyg' },
  { label: 'QAR', value: 'qar' },
  { label: 'RON', value: 'ron' },
  { label: 'RSD', value: 'rsd' },
  { label: 'RUB', value: 'rub' },
  { label: 'RWF', value: 'rwf' },
  { label: 'SAR', value: 'sar' },
  { label: 'SBD', value: 'sbd' },
  { label: 'SCR', value: 'scr' },
  { label: 'SEK', value: 'sek' },
  { label: 'SGD', value: 'sgd' },
  { label: 'SHP', value: 'shp' },
  { label: 'SLL', value: 'sll' },
  { label: 'SOS', value: 'sos' },
  { label: 'SRD', value: 'srd' },
  { label: 'STD', value: 'std' },
  { label: 'SZL', value: 'szl' },
  { label: 'THB', value: 'thb' },
  { label: 'TJS', value: 'tjs' },
  { label: 'TOP', value: 'top' },
  { label: 'TRY', value: 'try' },
  { label: 'TTD', value: 'ttd' },
  { label: 'TWD', value: 'twd' },
  { label: 'TZS', value: 'tzs' },
  { label: 'UAH', value: 'uah' },
  { label: 'UGX', value: 'ugx' },
  { label: 'USD', value: 'usd' },
  { label: 'UYU', value: 'uyu' },
  { label: 'UZS', value: 'uzs' },
  { label: 'VND', value: 'vnd' },
  { label: 'VUV', value: 'vuv' },
  { label: 'WST', value: 'wst' },
  { label: 'XAF', value: 'xaf' },
  { label: 'XCD', value: 'xcd' },
  { label: 'XOF', value: 'xof' },
  { label: 'XPF', value: 'xpf' },
  { label: 'YER', value: 'yer' },
  { label: 'ZAR', value: 'zar' },
  { label: 'ZMW', value: 'zmw' },
]

export const DEFAULT_CURRENCY = 'usd'

/**
 * Currencies that do not use decimals
 * @example "¥500"
 */
export const zeroDecimalCurrencies = [
  { label: 'BIF', value: 'bif' },
  { label: 'CLP', value: 'clp' },
  { label: 'DJF', value: 'djf' },
  { label: 'GNF', value: 'gnf' },
  { label: 'JPY', value: 'jpy' },
  { label: 'KMF', value: 'kmf' },
  { label: 'KRW', value: 'krw' },
  { label: 'MGA', value: 'mga' },
  { label: 'PYG', value: 'pyg' },
  { label: 'RWF', value: 'rwf' },
  { label: 'UGX', value: 'ugx' },
  { label: 'VND', value: 'vnd' },
  { label: 'VUV', value: 'vuv' },
  { label: 'XAF', value: 'xaf' },
  { label: 'XOF', value: 'xof' },
  { label: 'XPF', value: 'xpf' },
]

/**
 * Format a Number to a USD Currency String
 * @param {Number} value
 * @param {String} currency
 * @returns {String} $1.00
 */
export const formatToCurrency = (value, currency = 'usd') => {
  if (!value || typeof value !== 'number') value = 0

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })

  // format to currency
  value = formatter.format(parseFloat(value) / 100)

  if (value.endsWith('.00')) {
    value = value.substring(0, value.length - 3)
  }

  return value
}
