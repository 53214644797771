import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'

import AdvancedOptions from 'components/Editor/Actions/Form/AdvancedOptions'
import { GroupedAccordion } from 'components/Shared/Accordion'

class Form extends Component {
  static propTypes = {
    dummyAction: PropTypes.object.isRequired,
  }

  renderChildren = () => {
    const { dummyAction } = this.props
    const { description } = dummyAction

    return (
      <div className="dummy-action-item-form-item-children action-item-form-item-children">
        {description}
      </div>
    )
  }

  renderAdvanced = () => {
    const { dummyAction } = this.props

    return <AdvancedOptions hideConditional dummyAction={dummyAction} />
  }

  render() {
    const { dummyAction } = this.props
    const { id, title, subtitle, reference } = dummyAction

    const advancedDummyActions = ['login', 'signup']
    const showAdvanced = advancedDummyActions.includes(reference)

    return (
      <GroupedAccordion
        boxed
        itemId={id}
        group="action-item"
        className="action-item-form-item"
        color="yellow"
        icon="action"
        title={
          <div className="action-item-form-item-header">
            <div className="action-item-type">
              <div className="action-item-type-title">{title}</div>
              <div className="action-item-type-subtitle">{subtitle}</div>
            </div>
          </div>
        }
        renderChildren={this.renderChildren}
        renderAdvanced={showAdvanced ? this.renderAdvanced : null}
      />
    )
  }
}

export default reduxForm({
  // config
})(Form)
