import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from 'components/Shared/Icon'

import './Stepper.scss'

/**
 *
 * @param {String} Color teal, orange, yellow, green, purple, darkPink
 */
export default function Stepper({ steps, currentStep, color, className }) {
  return (
    <div className={classNames('stepper', `stepper-${color}`, className)}>
      {steps.map((step, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <div
            className={classNames('step', {
              'step-done': currentStep >= index,
              'step-current': currentStep === index,
            })}
          >
            <div className="step-bullet">
              {currentStep > index ? <Icon type="done" /> : index + 1}
            </div>
            <div className="step-text">{step}</div>
          </div>
          {index + 1 !== steps.length ? <hr /> : null}
        </React.Fragment>
      ))}
    </div>
  )
}

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  color: PropTypes.oneOf([
    'teal',
    'orange',
    'yellow',
    'green',
    'purple',
    'darkPink',
    'red',
  ]),
}

Stepper.defaultProps = {
  color: 'teal',
}
