import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { showModal, setPaymentRouteType } from 'ducks/trialWarning'

import Modal from './Modal'
import './OverLimitModal.scss'

const UpgradeAlertModal = ({
  onClose,
  body,
  action,
  purchaseDescription,
  show,
}) => {
  if (!show) return null

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onUpgrade = async () => {
    try {
      setIsLoading(true)

      dispatch(setPaymentRouteType('upgrade'))
      dispatch(showModal())

      onClose()

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      borderless
      constrained
      constrainContent
      onClose={onClose}
      size="xs"
      className="over-limit-purchase-addon-modal"
    >
      <div>
        <Modal.Header>
          <h1>Upgrade to {action}!</h1>
        </Modal.Header>
        <Modal.Content>
          <p>{body}</p>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button text grey onClick={onClose}>
            Back
          </Modal.Button>
          <Modal.Button
            loading={isLoading}
            disabled={isLoading}
            onClick={onUpgrade}
            data-adalo-id="upgrade-blocked-editor-invite"
          >
            Upgrade
          </Modal.Button>
        </Modal.Actions>
      </div>
    </Modal>
  )
}

export default UpgradeAlertModal
