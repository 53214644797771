import DeviceType from 'ducks/editor/types/DeviceType'
import { EditorObject } from 'utils/responsiveTypes'

export default function hiddenOnDevice(
  object: EditorObject,
  device: DeviceType | undefined
): boolean {
  if (!device || !object.deviceVisibility) {
    return false
  }

  return !object.deviceVisibility[device]
}
