import React, { useState } from 'react'
import { useSelector } from 'react-redux'

// components
import OverLimitModal from 'components/Shared/OverLimitModal'

// ducks
import { getCurrentOrganization } from 'ducks/organizations'
import { adaloBackendAxios } from 'utils/io/http/axios'

const SeatAddonModal = ({ onClose, callback, show }) => {
  const [error, setError] = useState(null)
  const organization = useSelector(getCurrentOrganization)

  const handleSeatPurchase = async () => {
    try {
      const url = `/organizations/${organization.id}/subscription`

      await adaloBackendAxios.put(url, {
        type: 'ADDON_SEAT',
      })

      if (typeof callback === 'function') {
        await callback()
      }

      onClose()
    } catch (err) {
      const message = err?.message || err

      setError(message)
    }
  }

  return (
    <OverLimitModal
      addOnName="Editor Seat"
      purchaseDescription="You've reached your plan's quota of editor seats. Add an additional monthly editor seat to invite another member to your team."
      addOnPrice={15}
      onSuccess={handleSeatPurchase}
      onClose={onClose}
      organization={organization}
      error={error}
      show={show}
    />
  )
}

export default SeatAddonModal
