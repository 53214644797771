export const FORM_NAME = 'IOSPublishSettings'

export const requiredFactory = fieldName => value => {
  if (value) return undefined

  return `${fieldName} is required`
}

export const validateBundleIdFormat = val => {
  if (val.match(/[^a-z\d\-\.]/i)) {
    return 'Can only contain letters, numbers, dashes (-) and dots (.)'
  }
}

export const validateAppSpecificPassword = val => {
  if (val.match(/^[a-z]{16}$/i) || val.match(/^\w{4}(\-\w{4}){3}$/)) {
    return
  }

  return 'App specific password must contain 16 letters or look like XXXX-XXXX-XXXX-XXXX'
}

export const validateUuidV4 = val => {
  if (val.match(/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/i)) {
    return
  }

  return 'Should be in the format: "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"'
}

export const validateApiKeyId = val => {
  if (val.match(/^[a-zA-Z0-9]{10}$/i)) {
    return
  }

  return 'Should be a 10-character string'
}

export const validations = {
  displayName: [requiredFactory('App Display Name')],
  bundleId: [requiredFactory('Bundle ID'), validateBundleIdFormat],
  email: [requiredFactory('App Store Connect Email')],
  password: [requiredFactory('App Store Connect Password')],
  appSpecificPassword: [
    requiredFactory('App-specific Password'),
    validateAppSpecificPassword,
  ],
  launchScreenIcon: [requiredFactory('Launch Screen Graphic')],
  launchScreenColor: [requiredFactory('Launch Screen Background Color')],
  appleIssuerId: [requiredFactory('Apple Issuer ID'), validateUuidV4],
  apiKeyId: [requiredFactory('Apple API Key ID'), validateApiKeyId],
  apiKey: [requiredFactory('Apple API Key')],
}
