import React, { Component } from 'react'
import classNames from 'classnames'
import Icon from '../Shared/Icon'
import './PlatformToggle.css'

export default class PlatformToggle extends Component {
  handleChange = val => {
    const {
      input: { onChange },
    } = this.props

    onChange(val)
  }

  render() {
    const {
      input: { value },
    } = this.props

    return (
      <div className="new-app-platform">
        <ToggleItem
          value="responsive"
          label="Mobile, Tablet, & Desktop"
          onSelect={this.handleChange}
          selected={value === 'responsive'}
          version="NEW!"
        />
        <ToggleItem
          value="mobile"
          label="Mobile Only"
          onSelect={this.handleChange}
          selected={value === 'mobile'}
        />
      </div>
    )
  }
}

class ToggleItem extends Component {
  handleClick = () => {
    const { value, onSelect } = this.props

    onSelect(value)
  }

  render() {
    const { label, value, selected, version } = this.props

    return (
      <div
        className={classNames(
          'new-app-platform-item',
          `new-app-platform-${value}`,
          selected && 'new-app-platform-selected'
        )}
        onClick={this.handleClick}
      >
        <div className="new-app-platform-checkmark">
          <Icon type="done" />
        </div>
        {version ? (
          <div className="new-app-platform-version">{version}</div>
        ) : null}
        <div className="new-app-platform-icon" />
        <div className="new-app-platform-title">{label}</div>
      </div>
    )
  }
}
