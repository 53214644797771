import { dataTypes, sourceTypes } from '@adalo/constants'
import { parse } from 'mathjs'
import { v4 as uuid } from 'uuid'

const TIMES = ['x', 'X', '*']
const DIVIDE = ['÷', '/']
const PLUS = ['+']
const MINUS = ['-', '–', '—']
const PARENTHESIS_OPEN = ['(']
const PARENTHESIS_CLOSE = [')']
const COMMA = [',']

const assignments = [
  ['*', TIMES],
  ['/', DIVIDE],
  ['+', PLUS],
  ['-', MINUS],
  ['(', PARENTHESIS_OPEN],
  [')', PARENTHESIS_CLOSE],
  [',', COMMA],
]

const mapping = {}

const labelMapping = {
  '*': '×',
  '/': '÷',
  '+': '+',
  '-': '–',
  '(': '(',
  ')': ')',
}

for (const itm of assignments) {
  for (const match of itm[1]) {
    mapping[match] = itm[0]
  }
}

const reverseMapping = {
  multiply: 'product',
  add: 'sum',
  subtract: 'subtraction',
  divide: 'division',
  unaryMinus: 'negative',
  ROUND: 'round',
  INT: 'floor',
  ABS: 'abs',
  SQRT: 'sqrt',
  EXP: 'pow',
  RAND: 'random',
  LOG: 'log10',
  MILES: 'miles',
  KILOMETERS: 'kilometers',
}

export const transformFormula = formula => {
  let result = []

  if (!Array.isArray(formula)) {
    formula = formula ? [formula] : []
  }

  for (let itm of formula) {
    if (itm && typeof itm === 'object') {
      result.push(itm)

      continue
    }

    itm = String(itm)

    let start = 0

    for (let pos = 0; pos < itm.length; pos += 1) {
      const symbol = getSymbol(itm[pos])

      const before = prepareString(itm.slice(start, pos))

      if (symbol) {
        result = result.concat([before, symbol])
        start = pos + 1
      }
    }

    result.push(prepareString(itm.slice(start, itm.length)))
  }

  if (
    result.length === 0 ||
    (result.length === 1 && typeof result[0] === 'string')
  ) {
    return result[0] || ''
  }

  return result
}

export const scrubSpaces = formula => {
  return formula.filter(itm => itm !== '')
}

// Takes array from WrappedEntityTextarea
// Outputs { type: 'formula', formula: ... }
export const buildFormula = (
  formulaArgs,
  outputDataType,
  componentId = null,
  actionId = null
) => {
  let formula = transformFormula(formulaArgs)

  if (typeof formula === 'string') {
    formula = [formula]
  }

  const parsedFormula = parseFormula(scrubSpaces(formula))

  return {
    id: !actionId ? uuid() : null,
    componentId,
    type: 'formula',
    formula,
    parsedFormula,
    outputDataType,
    valid: !!parsedFormula,
  }
}

export const getSymbol = entity => {
  const symbol = mapping[entity]

  if (symbol) {
    return {
      type: 'symbol',
      symbol,
    }
  }
}

export const getSymbolLabel = symbolEntity => {
  const { symbol } = symbolEntity

  return labelMapping[symbol] || symbol
}

export const prepareString = str => {
  return str.trim().replace(/[^\d\.\-]/g, '')
}

/////////////////////////////////////////////////
// Formula Validation
/////////////////////////////////////////////////

export const validateFragment = fragment => {
  if (typeof fragment === 'number' && !Number.isNaN(fragment)) {
    return true
  }

  if (!fragment) {
    return false
  }

  if (typeof fragment === 'string') {
    return !fragment.match(/[^\d\.\-]/) && !Number.isNaN(Number(fragment))
  }

  if (fragment.type === 'binding') {
    return (
      fragment.source.dataType === dataTypes.NUMBER ||
      fragment.source.type === sourceTypes.INPUT
    )
  }

  return (
    fragment &&
    (fragment.dataType === dataTypes.NUMBER ||
      fragment.type === sourceTypes.INPUT)
  )
}

export const parseFormula = formula => {
  //return buildTree(formula)
  const [serialized, vars] = serializeFormula(formula)

  try {
    return parseSerializedFormula(serialized, vars)
  } catch (err) {
    return undefined
  }
}

export const serializeFormula = formula => {
  const variables = {}
  const pieces = []
  let i = 0

  for (const itm of formula) {
    if (['', null, undefined].includes(itm)) {
      continue
    } else if (itm && (itm.type === 'symbol' || itm.type === 'expression')) {
      pieces.push(itm.symbol)
    } else if (typeof itm === 'string') {
      pieces.push(itm)
    } else {
      const varName = `t${i}`
      i += 1
      variables[varName] = itm
      pieces.push(varName)
    }
  }

  return [pieces.join(' '), variables]
}

// Handles case where UserCount(4+2) is treated as a FunctionNode
// by converting UserCount(4+2) => UserCount * (4+2)
// Required as it resolves a bug in the mathjs library.
export const parseSerializedFormula = (formula, variables) => {
  if (Object.keys(variables).length !== 0) {
    const formulaLst = formula.split(' ')

    for (let i = 0; i < formulaLst.length; i += 1) {
      if (formulaLst[i] in variables && formulaLst[i + 1] === '(') {
        formulaLst.splice(i + 1, 0, '*')
      }
    }

    formula = formulaLst.join(' ')
  }

  const rootNode = parse(formula)

  return parseSub(rootNode, variables)
}

const parseSub = (node, variables) => {
  switch (node.type) {
    case 'OperatorNode':
      return [
        reverseMapping[node.fn],
        ...node.args.map(node => parseSub(node, variables)),
      ]
    case 'ParenthesisNode':
      return parseSub(node.content, variables)

    case 'ConstantNode':
      if (node.value === undefined) {
        throw new Error('Formula is empty')
      }

      return String(node.value)
    case 'FunctionNode':
      return [
        reverseMapping[node.fn],
        ...node.args.map(node => parseSub(node, variables)),
      ]
    case 'SymbolNode':
      if (!variables[node.name]) {
        throw new Error(`Invalid variable: ${node.name}`)
      }

      return variables[node.name]
    default:
      throw new Error(`Invalid node type: ${node.type}`)
  }
}
