import { reduxForm, Field } from 'redux-form'
import { useSelector } from 'react-redux'

import { isMaliciousHTML, MALICIOUS_ERROR_MESSAGE } from 'utils/validation'

import PasswordInput from 'components/Shared/Forms/PasswordInput'
import { SettingsFormSubmit } from 'components/Shared/Forms/SettingsFormField'
import Button from 'components/Shared/Button'

import { getCurrentOrganization, isFeatureEnabled } from 'ducks/organizations'

const validate = values => {
  const errors = {}

  if (isMaliciousHTML(values?.openai?.key)) {
    errors.openai = { key: MALICIOUS_ERROR_MESSAGE }
  }

  return errors
}

const OpenAIApiKeyForm = ({
  handleSubmit,
  dirty,
  submitting,
  initialValues,
  handleDelete,
}) => {
  const isEmpty = !initialValues || !initialValues.openAI

  const isEnabled = useSelector(state =>
    isFeatureEnabled(state, 'customIntegrations')
  )

  const orgName = useSelector(state => {
    const currentOrg = getCurrentOrganization(state)

    return currentOrg?.name
  })

  if (!isEnabled && isEmpty) {
    return null
  }

  return (
    <>
      <form
        className="api-keys__form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Field
          component={props => (
            <>
              <PasswordInput {...props} />
              <p className="openai-keys__org-message">
                This API Key will apply to all apps in
                {` ${orgName}` || ' this organization'}.
              </p>
            </>
          )}
          hasToggle
          name="openai.key"
          label="OpenAI API Key"
          placeholder="Add key..."
          disabled={!isEmpty}
          tooltip={
            <>
              You need an OpenAI API key to use AI features. Need help creating
              one? Check out our help doc on how to{' '}
              <a
                href="https://help.adalo.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                create your OpenAI API Key.
              </a>
            </>
          }
        />
        <div className="openai-button-keys__control api-keys__control">
          <Button type="button" onClick={() => handleDelete('openai')} flat>
            Delete API Key
          </Button>
          <SettingsFormSubmit enabled={dirty && !submitting} title="Save Key" />
        </div>
      </form>
    </>
  )
}

export default reduxForm({
  form: 'OpenAIApiKey',
  validate,
  enableReinitialize: true,
})(OpenAIApiKeyForm)
