import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface AlignToSelectionHorizontalCenterInstruction {
  operation: 'alignToSelectionHorizontalCenter'
  options: ActionOptions
}

export const alignToSelectionHorizontalCenterHandler = ({
  bounds,
  objects,
}: HandlerOptions): LayoutInstruction[] => {
  const boundsHorizontalCenter = bounds.left + (bounds.right - bounds.left) / 2

  return objects.map(object =>
    moveElement(object.id, boundsHorizontalCenter - object.width / 2, object.y)
  )
}

const alignToSelectionHorizontalCenter = (
  objectIds: string[]
): AlignToSelectionHorizontalCenterInstruction => ({
  operation: 'alignToSelectionHorizontalCenter',
  options: { objectIds },
})

export default alignToSelectionHorizontalCenter
