const START_DRAG = Symbol('START_DRAG')
const END_DRAG = Symbol('END_DRAG')
const DRAG = Symbol('DRAG')
const SCROLL = Symbol('SCROLL')

const INITIAL_STATE = {
  dragging: false,
  fieldId: null,
  newPosition: null,
  cursorOffset: null,
  cursorX: null,
  scrollPosition: 0,
  offsetY: 0,
  height: 0,
}

// Reducers

export default (state = INITIAL_STATE, action) => {
  if (action.type === START_DRAG) {
    const { fieldId, cursorOffset, offsetY, height } = action
    const { scrollPosition } = state

    return {
      ...INITIAL_STATE,
      offsetY,
      height,
      scrollPosition,
      fieldId,
      cursorOffset,
      dragging: true,
    }
  }

  if (action.type === DRAG) {
    const { newPosition, cursorX } = action

    return {
      ...state,
      newPosition,
      cursorX,
    }
  }

  if (action.type === END_DRAG) {
    const { scrollPosition } = state

    return { ...INITIAL_STATE, scrollPosition }
  }

  if (action.type === SCROLL) {
    const { scrollPosition } = action

    return { ...state, scrollPosition }
  }

  return state
}

// Actions

export const startDrag = (fieldId, cursorOffset, offsetY, height) => ({
  type: START_DRAG,
  fieldId,
  cursorOffset,
  offsetY,
  height,
})

export const drag = (newPosition, cursorX) => ({
  type: DRAG,
  newPosition,
  cursorX,
})

export const endDrag = () => ({
  type: END_DRAG,
})

export const scroll = scrollPosition => ({
  type: SCROLL,
  scrollPosition,
})

// Selectors

const getTablesState = state => {
  return state.editor.tables
}

export const getDragging = globalState => {
  const state = getTablesState(globalState)

  return state.dragging && state.cursorX
}

export const getDragInfo = state => {
  return getTablesState(state)
}

export const getScrollPosition = state => {
  return getTablesState(state).scrollPosition
}
