import React, { Component } from 'react'
import { Field } from 'redux-form'

export class InnerField extends Component {
  handleChange = ({ field }) => {
    const {
      input: { onChange },
    } = this.props

    onChange(field)
  }

  render() {
    const {
      input,
      childComponent: ChildComponent,
      passProps,
      meta,
    } = this.props

    return (
      <>
        <ChildComponent
          {...passProps}
          name="field"
          value={input.value}
          onChange={this.handleChange}
          meta={meta}
        />
        {meta?.error && meta?.touched && (
          <div className="settings-form-error">{meta.error}</div>
        )}
      </>
    )
  }
}

export default class ControlField extends Component {
  render() {
    const { name, component, boxed, defaultValue, validate, ...passProps } =
      this.props

    return (
      <Field
        name={name}
        component={InnerField}
        childComponent={component}
        defaultValue={defaultValue}
        passProps={passProps}
        validate={validate}
      />
    )
  }
}
