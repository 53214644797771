import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { LAYOUT_SECTION, LIBRARY_COMPONENT } from '@adalo/constants'
import { traverse } from '@adalo/utils'

import { setCurrentTab, CurrentTab } from 'ducks/magicLayout'
import { getApp, getAppBranding, updateApp } from 'ducks/apps'
import { selectObjects, createObject } from 'ducks/editor/objects'
import {
  getXGrid,
  getYGrid,
  setXSnap,
  setYSnap,
  resetSnaps,
} from 'ducks/editor/snapping'
import { resetTool, getStartPosition } from 'ducks/editor/tools'
import {
  getParentSelection,
  setSelectionParent,
  resetSelectionParent,
} from 'ducks/editor/selection'

import { getBestParent } from 'utils/geometry'
import { scale, unScale, scaleValue } from 'utils/zoom'
import { getSnapValue } from 'utils/snapping'
import { defaults } from 'utils/objects'
import getDeviceObject, { getObjectGhost } from 'utils/objectGhosts'
import { addComponentUsed, calculateSnappedPosition } from 'utils/libraries'
import { getBestParentForNewObject } from 'utils/positioning'
import { updateBuilderContentUsage } from 'utils/io/builderContentUsage'
import {
  isPrebuiltLayoutSection,
  getLayoutSectionDimensionsForGhostSnap,
} from 'utils/layoutSections/prebuilts'

import CanvasObject from './CanvasObject'

import './AddLibraryComponent.css'

const OPTIONS = Symbol('AddDraggableComponent$OPTIONS')
export const ADD_DRAGGABLE_OPTIONS = OPTIONS

const MIN_RADIUS_SQUARED = 20 ** 2

function centerObject(x, y, obj) {
  x -= Math.floor(obj.width / 2)
  y -= Math.floor(obj.height / 2)

  return [x, y]
}

function applyOffset(x, y, props) {
  if (props.options && props.options[OPTIONS]) {
    if (props.options[OPTIONS].xOffset) {
      x += props.options[OPTIONS].xOffset
    }

    if (props.options[OPTIONS].yOffset) {
      y += props.options[OPTIONS].yOffset
    }
  }

  return [x, y]
}

class AddDraggableComponent extends Component {
  bestParentOnMouseMove = undefined

  constructor(props) {
    super(props)

    const startPosition = props.startPosition ? props.startPosition : [0, 0]

    const [x, y] = unScale(startPosition, props.zoom)

    const {
      options: { type },
    } = props
    const [x2, y2] = centerObject(x, y, defaults[type])
    const [x3, y3] = applyOffset(x2, y2, props)

    this.state = {
      x: x3,
      y: y3,
      mouseOver: !!props.startPosition,
      xSnapped: x3,
      ySnapped: y3,
      widthSnapped: 0,
      heightSnapped: 0,
    }
  }

  handleMouseUp = e => {
    const { startPosition } = this.props
    const [x, y] = startPosition
    const distSquared = (e.clientX - x) ** 2 + (e.clientY - y) ** 2

    if (distSquared < MIN_RADIUS_SQUARED) {
      return
    }

    this.handleMouseDown(e)
  }

  getObjectLayoutOnMouseDown() {
    const { xSnapped: x, ySnapped: y } = this.state
    let { widthSnapped: width, heightSnapped: height } = this.state

    const { options } = this.props

    // If we're add-dragging a prebuilt layout section, we want to keep it's width & height
    if (isPrebuiltLayoutSection(options)) {
      width = options.width ?? width
      height = options.height ?? height
    }

    return { x, y, width, height }
  }

  getBestParentOnMouseDown(e) {
    const { options, zoom } = this.props
    const isPrebuilt = isPrebuiltLayoutSection(options)
    let bestParent

    if (isPrebuilt && e) {
      const { clientX, clientY } = e
      let [x, y] = unScale([clientX, clientY], zoom)
      const { width: ghostWidth, height: ghostHeight } =
        getLayoutSectionDimensionsForGhostSnap(options)

      x = Math.round(x - ghostWidth / 2)
      y = Math.round(y - ghostHeight / 2)

      const mouseCoords = { mouseX: clientX, mouseY: clientY }
      bestParent = this.getBestParent(x, y, mouseCoords)
    }

    return bestParent
  }

  handleMouseDown = e => {
    const {
      createObject,
      resetTool,
      resetSnaps,
      options,
      setCurrentTab,
      resetSelectionParent,
      updateApp,
      app,
    } = this.props
    const { type } = options

    const isPrebuilt = isPrebuiltLayoutSection(options)
    const typeDefaults = defaults[type]

    // If it's a prebuilt layout section, we want to override any defaults with the prebuilt layout section's content
    const baseObject = isPrebuilt
      ? { ...typeDefaults, ...options }
      : { ...options, ...typeDefaults }

    const layout = this.getObjectLayoutOnMouseDown()
    const obj = { ...baseObject, ...layout }

    // When dropping a prebuilt layout section, if it's too close to more than one screen, the logic
    // to determine the best parent when doing performCreate might choose the wrong one (different from the ghost).
    // So for those objects, we choose a best parent now and pass it down.
    const bestParent = this.getBestParentOnMouseDown(e)

    if (obj.type === LAYOUT_SECTION) {
      updateBuilderContentUsage(
        LAYOUT_SECTION,
        obj?.metadata?.prebuiltLayoutSectionId
      )

      traverse([obj], obj => {
        if (obj.type === LIBRARY_COMPONENT) {
          addComponentUsed(obj.libraryName, obj.componentName, app, updateApp)
        }
      })
    }

    createObject(
      obj,
      null,
      false,
      { mouseX: e.clientX, mouseY: e.clientY },
      bestParent?.id
    )

    resetTool()
    resetSnaps()
    setCurrentTab(CurrentTab.Setup)
    resetSelectionParent()
  }

  handleMouseMove = e => {
    const { zoom, options, setSelectionParent } = this.props

    let [x, y] = unScale([e.clientX, e.clientY], zoom)

    if (options.snappingRules) {
      let width = options.width
      let height = options.height
      if (isPrebuiltLayoutSection(options)) {
        ;({ width, height } = getLayoutSectionDimensionsForGhostSnap(options))
      }

      x = Math.round(x - width / 2)
      y = Math.round(y - height / 2)
    } else {
      const obj = { ...options, ...defaults[options.type] }
      const [x2, y2] = centerObject(x, y, obj)
      const [x3, y3] = applyOffset(x2, y2, this.props)
      x = x3
      y = y3
    }

    const mouseCoords = { mouseX: e.clientX, mouseY: e.clientY }
    const parent = this.getBestParent(x, y, mouseCoords)
    // Save reference to calculated best parent on mouse move to not have to re-do it when rendering the component
    this.bestParentOnMouseMove = parent

    const snap = this.calculateSnappedPosition(x, y, parent)

    const [xSnapped, ySnapped, widthSnapped, heightSnapped] = snap

    this.setState(
      {
        xSnapped,
        ySnapped,
        mouseOver: true,
        x,
        y,
        widthSnapped,
        heightSnapped,
      },
      () => {
        const {
          xSnapped: x,
          ySnapped: y,
          widthSnapped: width,
          heightSnapped: height,
        } = this.state

        setSelectionParent(
          getBestParentForNewObject(parent, x, y, width, height)
        )
      }
    )
  }

  handleMouseLeave = () => {
    this.setState({ mouseOver: false }, () => {
      const { resetSelectionParent } = this.props
      resetSelectionParent()
    })
  }

  getBestParent = (xArg, yArg, coords = {}) => {
    let { x, y } = this.state

    x = xArg || x
    y = yArg || y

    const { options, screens, zoom } = this.props

    const isPrebuilt = isPrebuiltLayoutSection(options)
    const typeDefaults = defaults[options.type]
    const deviceOptions = isPrebuilt
      ? getDeviceObject(options, 'tablet') // we default to tablet for prebuilt layout sections ghosts, so we use that for choosing the parent
      : options

    // If it's a prebuilt layout section, we want to override any defaults with the prebuilt layout section's content
    const baseObject = isPrebuilt
      ? { ...typeDefaults, ...deviceOptions }
      : { ...deviceOptions, ...typeDefaults }

    const obj = { ...baseObject, x, y }

    const parentId = getBestParent(obj, screens, null, null, zoom, coords)

    return screens.filter(s => s.id === parentId)[0]
  }

  calculateGridSnapPosition = (x, y) => {
    const { options, xGrid, yGrid, setXSnap, setYSnap, zoom } = this.props
    const { width, height } = defaults[options.type]

    if (!xGrid || !yGrid) {
      return [x, y, width, height]
    }

    let xDiff = 0
    let yDiff = 0

    const xCoords = {
      left: x,
      center: x + width / 2,
      right: x + width,
    }

    const yCoords = {
      top: y,
      center: y + height / 2,
      bottom: y + height,
    }

    const xSnap = getSnapValue(xGrid, xCoords, zoom)
    const ySnap = getSnapValue(yGrid, yCoords, zoom)

    if (xSnap) {
      const key = Object.keys(xSnap)[0]
      xDiff = xSnap[key] - xCoords[key]
      setXSnap(xSnap[key])
    } else {
      setXSnap(null)
    }

    if (ySnap) {
      const key = Object.keys(ySnap)[0]
      yDiff = ySnap[key] - yCoords[key]
      setYSnap(ySnap[key])
    } else {
      setYSnap(null)
    }

    return [Math.round(x + xDiff), Math.round(y + yDiff), width, height]
  }

  calculateSnappedPosition = (x, y, parent) => {
    const { options } = this.props

    if (!options.snappingRules) {
      return this.calculateGridSnapPosition(x, y)
    }

    const pos = calculateSnappedPosition({ ...options, x, y }, parent)

    if (pos.x && pos.y) {
      this.calculateGridSnapPosition(pos.x, pos.y)
    }

    return [pos.x, pos.y, pos.width, pos.height]
  }

  renderComponent = () => {
    const { zoom, options, branding } = this.props

    const { type } = options
    const { xSnapped, ySnapped, widthSnapped, heightSnapped } = this.state

    const [x, y] = scale([xSnapped, ySnapped], zoom)
    const defaultForType = defaults[type]
    let { width, height } = defaultForType

    const unScaledWidth = widthSnapped || width
    const unScaledHeight = heightSnapped || height

    width = scaleValue(unScaledWidth, zoom)
    height = scaleValue(unScaledHeight, zoom)

    const bestParent =
      this.bestParentOnMouseMove ?? this.getBestParent(xSnapped, ySnapped)

    const object = {
      ...options,
      width: unScaledWidth,
      height: unScaledHeight,
      x: 0,
      y: 0,
    }

    const childZoom = { ...zoom, offset: [0, 0] }

    const objectGhost = getObjectGhost(object, options, bestParent)

    return (
      <g transform={`translate(${x}, ${y})`} opacity={0.5}>
        <CanvasObject
          branding={branding}
          object={objectGhost}
          zoom={childZoom}
        />
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          stroke="none"
          fill="none"
        />
      </g>
    )
  }

  render() {
    const { mouseOver } = this.state

    const width = window.innerWidth
    const height = window.innerHeight

    return (
      <g className="add-component-instance">
        {mouseOver ? this.renderComponent() : null}
        <rect
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
          onMouseLeave={this.handleMouseLeave}
          onMouseEnter={this.handleMouseMove}
          x={0}
          y={0}
          width={width}
          height={height}
          className="add-component-backdrop"
        />
      </g>
    )
  }
}

const mapStateToProps = (state, { match, options }) => {
  const app = getApp(state, match.params.appId)

  return {
    app,
    branding: getAppBranding(state, app.id),
    screens: selectObjects(state),
    parentSelection: getParentSelection(state),
    xGrid: getXGrid(state),
    yGrid: getYGrid(state),
    startPosition: getStartPosition(state),
  }
}

export default withRouter(
  connect(mapStateToProps, {
    createObject,
    resetTool,
    setXSnap,
    setYSnap,
    resetSnaps,
    setCurrentTab,
    setSelectionParent,
    resetSelectionParent,
    updateApp,
  })(AddDraggableComponent)
)
