export const isDescendent = (childNode, parentNode) => {
  if (!childNode || !parentNode) {
    return false
  }

  for (let node = childNode; node; node = node.parentNode) {
    if (parentNode === node) {
      return true
    }
  }

  return false
}

export const scrollToHash = () => {
  const id = window.location.hash.substr(1)

  if (id) {
    const anchor = document.getElementById(id)

    if (anchor) {
      anchor.scrollIntoView()
    }
  }
}
