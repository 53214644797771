export enum PrebuiltBuilderContentStatus {
  LISTED = 'listed',
  UNLISTED = 'unlisted',
}

export enum PrebuiltBuilderContentPlatformTarget {
  MOBILE = 'mobile',
  RESPONSIVE = 'responsive',
  BOTH = 'both',
}

type PrebuiltBuilderContentType = 'layout-section'

export interface PrebuiltBuilderContentData {
  id: number
  name: string
  type: PrebuiltBuilderContentType
  body: Record<string, unknown>
  rawBody: Record<string, unknown>
  categories?: string
  primaryTags?: string
  secondaryTags?: string | null
  mobileThumbnail?: string
  responsiveThumbnail?: string
  status?: PrebuiltBuilderContentStatus
  rank?: number
  platformTarget?: PrebuiltBuilderContentPlatformTarget
  createdAt: Date
  updatedAt: Date
}

export interface PrebuiltBuilderContentError {
  message?: string
  error?: string
}
