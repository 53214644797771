import React, { Component } from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

import { uploadURL, uploadImage } from '../../../utils/uploads'
import Loading from '../Loading'
import { IconButton } from '../Icon'

import './FileInput.css'

export default class FileInput extends Component {
  static defaultProps = {
    uploadFunc: uploadImage,
    filenameFunc: value => value.filename,
    uploadURLFunc: value => uploadURL(value.key || value.url),
    acceptedMimeTypes: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'video/mp4',
      'video/avi',
      'video/mpeg',
      'video/quicktime',
      'video/x-msvideo',
      'video/x-ms-wmv',
      'video/webm',
      'video/ogg',
      'audio/mpeg',
      'audio/mp4',
      'audio/aac',
      'audio/m4a',
      'audio/x-m4a',
      'application/pdf',
      'application/x-compressed',
      'application/x-zip-compressed',
      'application/zip',
      'application/x-zip',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  }

  state = {
    uploadingFile: null,
  }

  handleDrop = acceptedFiles => {
    const {
      uploadFunc,
      input: { onChange },
    } = this.props

    const file = acceptedFiles[0]

    if (!file) {
      return this.errorMessage('No valid file')
    }

    const filename = file.name

    const reader = new FileReader()

    reader.onload = async () => {
      this.setState({ uploadingFile: reader.result })

      try {
        const url = await uploadFunc(reader.result, filename, file.type)
        onChange(url)
      } catch (err) {
        console.error('ERROR UPLOADING IMAGE:', err)
        this.errorMessage()
      }

      this.setState({ uploadingFile: null })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  errorMessage = (msg = null) => {
    if (msg) {
      console.error(msg)
    }

    alert(
      'There was an error uploading your file. Check that the file type extension is correct and try again. If this problem persists, please contact support.'
    )
  }

  getFileURL = () => {
    const { uploadingFile } = this.state

    const {
      uploadURLFunc,
      input: { value },
    } = this.props

    if (uploadingFile) {
      return null
    } else if (value) {
      return uploadURLFunc(value)
    }

    return null
  }

  handleRemove = () => {
    const {
      input: { onChange },
    } = this.props

    onChange(null)
  }

  render() {
    const { input, filenameFunc, acceptedMimeTypes } = this.props
    const { uploadingFile } = this.state
    const url = this.getFileURL()
    const value = input.value
    const filename = value ? filenameFunc(value) || 'Untitled' : 'No File'

    const mimetypes = acceptedMimeTypes.join(',')

    return (
      <div className="file-input-wrapper">
        {value ? (
          <div className="file-input-value">
            <div className="file-input-filename">
              <a
                className="deemphasize"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {filename}
              </a>
            </div>
            <div className="file-input-links">
              <IconButton type="trash-small" onClick={this.handleRemove} />
            </div>
          </div>
        ) : (
          <Dropzone
            className={classNames('file-input', !url && 'file-input-empty')}
            accept={mimetypes}
            onDrop={this.handleDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="file-input-sub" {...getRootProps()}>
                {url ? (
                  <input
                    {...getInputProps()}
                    style={{ backgroundImage: `url('${url}')` }}
                    className="file-input-filename"
                  />
                ) : null}
                <input {...getInputProps()} />
                <p className="file-input-select">
                  {uploadingFile ? <Loading small expanded /> : 'Choose File'}
                </p>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    )
  }
}
