import React from 'react'
import classnames from 'classnames'
import Icon from '../../../Shared/Icon'

const FormToggle = ({ input, meta, type }) => {
  const { checked, name } = input

  const handleChange = event => {
    const { onChange } = input
    const { value } = event.target
    if (value === 'true') return onChange(true)

    return onChange(false)
  }

  return (
    <div className="data-object-form-toggle">
      <div
        className={classnames('data-object-form-toggle-group', {
          active: checked,
        })}
      >
        <label htmlFor={`${name}-true`}>
          <Icon type="check" /> True
        </label>
        <input
          type="radio"
          name={name}
          id={`${name}-true`}
          value="true"
          checked={checked}
          onChange={handleChange}
        />
      </div>
      <div
        className={classnames('data-object-form-toggle-group', {
          active: !checked,
        })}
      >
        <label htmlFor={`${name}-false`}>
          <Icon type="close-big" /> False
        </label>
        <input
          type="radio"
          name={name}
          id={`${name}-false`}
          value="false"
          checked={!checked}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default FormToggle
