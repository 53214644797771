import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import Button from 'components/Shared/Button'

import { getApp } from 'ducks/apps'
import {
  getAppBuildsState,
  ensureAppBuildsAreLoaded,
  startAppBuild,
} from 'ducks/apps/builds'
import { getCurrentUser } from 'ducks/users'
import useDebugMode from 'hooks/useDebugMode'
import { requestAppBuilds } from 'utils/io'

import MobilePublishSummary from '../MobilePublishSummary'
import BuildList from '../BuildList'
import AndroidSettingsModal from './AndroidSettingsModal'
import NewVersionModal from '../NewVersionModal'

const AndroidPublish = props => {
  const history = useHistory()
  const debug = useDebugMode()

  const [currentModal, setCurrentModal] = useState(null)
  const currentUser = useSelector(getCurrentUser)
  const [skipBuildEnqueue, setSkipBuildEnqueue] = useState(false)

  const handleNewBuild = async () => {
    setCurrentModal(['settings', 'version'])
  }

  const handleStartBuild = async ({ version }) => {
    const { appId, startAppBuild, hasAppType } = props

    startAppBuild(
      appId,
      'android',
      version,
      skipBuildEnqueue && currentUser?.admin
    )

    if (!hasAppType && currentUser?.persona && !currentUser?.admin) {
      history.push(`/apps/${appId}/screens/native-app-questions`)
    }
  }

  const handleOpenSettings = () => {
    setCurrentModal(['settings'])
  }

  const handleCloseModal = () => {
    setCurrentModal(null)
  }

  const handleSubmitSettings = skipEnqueue => {
    if (skipEnqueue !== undefined) {
      setSkipBuildEnqueue(skipEnqueue)
    }

    if (Array.isArray(currentModal) && currentModal.length > 1) {
      setCurrentModal(prevModal => prevModal.slice(1))
    } else {
      setCurrentModal(null)
    }
  }

  const handleCredentialValidation = () => {
    const { androidSettings } = props

    if (!androidSettings) return false

    const { bundleId, frontendGoogleServices } = androidSettings

    if (!bundleId || !frontendGoogleServices) {
      return false
    }

    return true
  }

  const handleAppSettingsValidation = () => {
    const { androidSettings } = props

    if (!androidSettings) return false

    const { displayName, notificationsIcon } = androidSettings

    if (!displayName || !notificationsIcon) return false

    return true
  }

  const requestData = () => {
    const { appId } = props

    requestAppBuilds(appId, 'android', { order: 'DESC' })
  }

  useEffect(() => {
    const { appId, ensureAppBuildsAreLoaded } = props

    ensureAppBuildsAreLoaded(appId, 'android')

    const timer = window.setInterval(requestData, 5000)

    return () => {
      window.clearInterval(timer)
    }
  }, [])

  const {
    androidSettings,
    lastVersion,
    appId,
    buildList,
    disableButton,
    loadingBuilds,
  } = props

  const disabled = disableButton && !debug
  const currentModalName = currentModal && currentModal[0]

  return (
    <div className="mobile-publish-settings">
      <h2>Android Export Settings</h2>
      <MobilePublishSummary
        appId={appId}
        provider="android"
        onUpdateSettings={handleOpenSettings}
        handleCredentialValidation={handleCredentialValidation}
        handleAppSettingsValidation={handleAppSettingsValidation}
      />
      <h2>Android Builds</h2>
      <Button
        fluid
        outlined
        green
        icon={disabled ? '' : 'plus-small'}
        onClick={handleNewBuild}
        disabled={disabled}
      >
        {disableButton ? 'Build In Progress...' : 'Start New Android Build'}
      </Button>
      <BuildList appId={appId} list={buildList} loading={loadingBuilds} />
      {currentModalName === 'settings' ? (
        <AndroidSettingsModal
          androidSettings={androidSettings}
          initialValues={androidSettings}
          appId={appId}
          onClose={handleCloseModal}
          onSubmit={handleSubmitSettings}
          publishing={currentModal?.length > 1}
        />
      ) : currentModalName === 'version' ? (
        <NewVersionModal
          target="android"
          lastVersion={lastVersion}
          appId={appId}
          onClose={handleCloseModal}
          onSubmit={handleStartBuild}
        />
      ) : null}
    </div>
  )
}

AndroidPublish.propTypes = {
  appId: PropTypes.string.isRequired,
}

function mapStateToProps(state, { appId }) {
  const app = getApp(state, appId)
  const androidSettings = {
    ...(app &&
      app.androidSettings && {
        ...app.androidSettings,
      }),
    hasLocationEnabled: true,
  }

  const buildsState = getAppBuildsState(state, appId, 'android')

  const buildList = buildsState && buildsState.list

  const disableButton =
    buildList &&
    buildList.find(
      build =>
        (build.status === 'queued' || build.status === 'building') &&
        moment(build.createdAt).diff(Date.now(), 'hours') > -2
    )

  return {
    hasAppType: app.type,
    androidSettings,
    loadingBuilds: buildsState ? buildsState.loading : true,
    buildList: buildsState && buildsState.list,
    lastVersion: buildsState && buildsState.lastVersion,
    disableButton,
  }
}

export default connect(mapStateToProps, {
  ensureAppBuildsAreLoaded,
  startAppBuild,
})(AndroidPublish)
