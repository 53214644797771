import React, { useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Button from 'components/Shared/Button'
import Modal from 'components/Shared/Modal'

function getPortalElement() {
  let element = document.getElementById('mobile-publish-portal')

  if (!element) {
    element = document.createElement('div')
    element.id = 'mobile-publish-portal'
    element.className = 'mobile-publish-portal'
    document.body.appendChild(element)
  }

  return element
}

export function PublishSettings({
  children,
  formId,
  onClose,
  submitText,
  title,
  hideActions,
}) {
  const portalNode = useMemo(getPortalElement, [])

  const handleCancelClick = useCallback(
    e => {
      e.preventDefault()
      onClose()
    },
    [onClose]
  )

  return ReactDOM.createPortal(
    <Modal
      scrolling
      className="mobile-publish-settings-modal"
      onClose={onClose}
    >
      <div>
        <Modal.Header title={title} />
        <Modal.Content>{children}</Modal.Content>
        {!hideActions && (
          <Modal.Actions>
            <Button
              className="publish-settings-form-cancel"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button green form={formId}>
              {submitText}
            </Button>
          </Modal.Actions>
        )}
      </div>
    </Modal>,
    portalNode
  )
}

PublishSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  submitText: PropTypes.string,
  title: PropTypes.string.isRequired,
}

PublishSettings.defaultProps = {
  submitText: 'Save',
}
