import React, { useCallback, useMemo } from 'react'

import FloatingAccordion from 'components/Shared/FloatingAccordion'
import Icon, { IconButton } from 'components/Shared/Icon'

import { defaultIndependentRounding } from 'utils/objects'

import SliderControl from './Libraries/SliderControl'
import CommonInspectRow from './GenericRow'
import TextControl from './TextControl'

export function getMaxBorderRadius(object) {
  const { width = 100, height = 100 } = object

  return Math.floor(Math.min(width, height) / 2)
}

function areAllRadiusEqual(props) {
  const values = Object.values(props)

  if (values.length === 0) {
    return true
  }

  return values.every(value => value === values[0])
}

function getDefaultValue(object, value) {
  return value ?? object?.borderRadius
}

const BorderRadiusControl = props => {
  const { object, onChange, max, min, showAdvanced } = props

  const sharedRounding = useMemo(() => {
    const values = {}

    if (!object) {
      return true
    }

    for (const key in defaultIndependentRounding) {
      if (key in object) {
        values[key] = object[key]
      }
    }

    return areAllRadiusEqual(values)
  }, [object, defaultIndependentRounding])

  // TODO @danicunhac: write unit tests for this function
  const handleChange = obj => {
    if ('borderRadius' in obj) {
      Object.assign(obj, defaultIndependentRounding)

      return onChange(obj)
    }

    const radiusValues = {}

    for (const key in defaultIndependentRounding) {
      if (key) {
        // Get existing values
        if (key in object) {
          radiusValues[key] = getDefaultValue(object, object[key])
        }

        // Get changed (new) values
        if (key in obj) {
          radiusValues[key] = obj[key]
        }

        if (!(key in radiusValues)) {
          radiusValues[key] = object?.borderRadius
        }
      }
    }

    const updatedAreEqual = areAllRadiusEqual(radiusValues)

    const updatedValues =
      updatedAreEqual && !sharedRounding
        ? { borderRadius: radiusValues.borderTopLeftRadius }
        : radiusValues

    Object.assign(obj, updatedValues)

    onChange(obj)
  }

  const renderAdvanced = useCallback(
    ({ closeAdvanced }) => {
      if (!showAdvanced) return null

      const items = [
        {
          name: 'borderTopLeftRadius',
          value: getDefaultValue(object, object?.borderTopLeftRadius),
          icon: 'top-left-corner',
        },
        {
          name: 'borderTopRightRadius',
          value: getDefaultValue(object, object?.borderTopRightRadius),
          icon: 'top-right-corner',
        },
        {
          name: 'borderBottomLeftRadius',
          value: getDefaultValue(object, object?.borderBottomLeftRadius),
          icon: 'bottom-left-corner',
        },
        {
          name: 'borderBottomRightRadius',
          value: getDefaultValue(object, object?.borderBottomRightRadius),
          icon: 'bottom-right-corner',
        },
      ]

      return (
        <FloatingAccordion
          onClickOutside={closeAdvanced}
          renderChildren={() => (
            <CommonInspectRow
              className="radius-control independent"
              title={
                <>
                  <p>Independent Corners</p>
                  <IconButton type="close" onClick={closeAdvanced} small />
                </>
              }
            >
              {items.map(item => (
                <RowItem key={item.name}>
                  <Icon type={item.icon} />
                  <TextControl
                    type="number"
                    name={item.name}
                    value={item.value}
                    onChange={handleChange}
                    variant="slim"
                    minValue={min}
                    maxValue={max}
                  />
                </RowItem>
              ))}
            </CommonInspectRow>
          )}
        />
      )
    },
    [object, handleChange, showAdvanced, max]
  )

  const getOptions = useCallback(({ openAdvanced }) => {
    return [
      {
        value: 'Independent Corners',
        label: (
          <div className="collections-menu-option">
            <Icon type="top-right-corner" medium />
            Independent Corners
          </div>
        ),
        onClick: openAdvanced,
      },
    ]
  }, [])

  const renderDisabled = useCallback(
    ({ openAdvanced }) => {
      return (
        <TextControl
          gray
          type="text"
          value="Mixed"
          onChange={handleChange}
          fontSize="small"
          disabled
          onClick={openAdvanced}
          variant="advanced"
        />
      )
    },
    [handleChange]
  )

  if (!showAdvanced) {
    return <SliderControl {...props} />
  }

  return (
    <SliderControl
      {...props}
      onChange={handleChange}
      renderAdvanced={renderAdvanced}
      menuOptions={getOptions}
      renderDisabled={renderDisabled}
      disabled={!sharedRounding}
    />
  )
}

const RowItem = ({ children }) => <div className="control-item">{children}</div>

export default BorderRadiusControl
