import { Component } from 'react'
import PropTypes from 'prop-types'
import { normalizeColor } from '../../../utils/colors'

/**
 * @template T
 * @extends {Component<T, {}>}
 */
export default class BaseObject extends Component {
  static contextTypes = {
    editable: PropTypes.bool,
    getSelection: PropTypes.func,
  }

  cancelEvent = e => {
    e.stopPropagation()
  }

  handleSelect = (e = {}) => {
    const { onSelect } = this.props

    onSelect(e.shiftKey, e.metaKey)
  }

  isSelected = () => {
    const { id } = this.props
    const { getSelection } = this.context

    const selection = getSelection()

    return selection.indexOf(id) !== -1
  }

  handleMouseDown = e => {
    const { onPosition } = this.props

    this.handleSelect(e)

    const point = [e.clientX, e.clientY]
    onPosition(point)
  }

  handleDoubleClick = e => {
    const { editable } = this.context

    if (!editable) {
      return
    }

    e.stopPropagation()
    this.doubleClickHandler()
  }

  doubleClickHandler() {
    const { onExpand } = this.props

    onExpand()
  }

  getColor(color) {
    const { branding } = this.props

    return normalizeColor(color, branding)
  }
}
