import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { VelocityTransitionGroup } from 'velocity-react'
import QS from 'qs'

import { LIBRARY_COMPONENT } from '@adalo/constants'

import { getUID } from 'utils/uid'
import { colors } from 'utils/colors'

import {
  openAccordion,
  closeAccordion,
  toggleAccordion,
  getAccordionState,
  LIBRARY_INSPECT_GROUP,
} from 'ducks/accordions'

import { getMagicLayout, updateObject } from 'ducks/editor/objects'
import {
  getActiveState,
  getTrialState,
  isFeatureEnabled,
} from 'ducks/organizations'

import Icon, { IconButton } from './Icon'
import Button from './Button'

import './Accordion.scss'

export default class Accordion extends Component {
  static propTypes = {
    wrapperRef: PropTypes.object,
    color: PropTypes.oneOf(colors),
    light: PropTypes.bool,
    hideDone: PropTypes.bool,
    disabled: PropTypes.bool,
    closeDisabled: PropTypes.bool,
    keepExpanded: PropTypes.bool,
    titleComponent: PropTypes.node,
    onToggled: PropTypes.func,
  }

  static defaultProps = {
    titleComponent: 'div',
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: Boolean(props.defaultExpanded),
    }
  }

  handleToggle = () => {
    const expanded = this.getExpanded()

    const { closeDisabled } = this.props

    if (expanded && !closeDisabled) {
      this.closeSub()
    } else {
      this.openSub()
    }
  }

  // Ref-accessible function
  handleOpen = () => {
    this.openSub()
  }

  // Ref-accessible function
  handleClose = () => {
    this.closeSub()
  }

  getExpanded() {
    const { keepExpanded } = this.props
    const { expanded } = this.state

    return keepExpanded || expanded
  }

  openSub() {
    const { onToggled } = this.props
    onToggled?.(true)
    this.setState({ expanded: true })
  }

  closeSub() {
    const { onToggled, keepExpanded } = this.props
    if (!keepExpanded) {
      onToggled?.(false)
      this.setState({ expanded: false })
    }
  }

  handleChildrenMouseDown = e => {
    e.stopPropagation()
  }

  handleToggleAdvanced = () => {
    const { group, toggleAccordion, itemId } = this.props
    const advancedGroup = `${group}-advanced-${itemId}`

    if (!toggleAccordion) {
      return null
    }

    return toggleAccordion(advancedGroup, itemId)
  }

  renderChildren = () => {
    const {
      advancedShown,
      renderChildren,
      renderAdvanced,
      boxed,
      color,
      light,
      group,
      itemId,
      hideDone,
      isEditable,
      editAction,
      advanced,
      sectionAdvancedSettings,
    } = this.props

    if (!renderChildren) {
      return null
    }

    const advancedGroup = `${group}-advanced-${itemId}`

    const colorProp = color ? { [color]: true } : {}

    return (
      renderChildren && (
        <div className="accordion-children">
          {renderChildren()}
          {renderAdvanced && (
            <GroupedAccordion
              className="accordion-children-advanced-options"
              color={color}
              renderChildren={renderAdvanced}
              group={advancedGroup}
              itemId={itemId}
              hideCarret
              keepExpanded={advanced}
            />
          )}
          {(boxed || sectionAdvancedSettings) && (
            <div
              className={classNames('accordion-boxed-done', {
                'is-editable': isEditable,
              })}
            >
              {isEditable && (
                <IconButton type="pencil-small" onClick={editAction} />
              )}
              {renderAdvanced && (
                <Button
                  {...colorProp}
                  text
                  small
                  square
                  type="button"
                  onClick={this.handleToggleAdvanced}
                >
                  {advancedShown ? 'Hide' : 'Show'} Advanced
                </Button>
              )}
              {!hideDone && (
                <Button
                  {...colorProp}
                  secondary={light}
                  small
                  square
                  type="button"
                  onClick={this.handleClose}
                >
                  Done
                </Button>
              )}
            </div>
          )}
        </div>
      )
    )
  }

  renderHeader = () => {
    let {
      boxed,
      hideCarret,
      title,
      icon,
      collapsedTitle,
      expandedTitle,
      renderHeader,
      isAfterTrial,
      iconSmall = false,
      iconMedium = false,
    } = this.props

    const expanded = this.getExpanded()

    collapsedTitle = collapsedTitle || title
    expandedTitle = expandedTitle || title
    const currentTitle = expanded ? expandedTitle : collapsedTitle

    if (renderHeader) {
      return renderHeader(expanded)
    }

    const expandedClass = isAfterTrial
      ? 'expand expand-disabled-icon'
      : 'expand'
    const showCarret = !boxed && !hideCarret

    return (
      <>
        {showCarret && (
          <Icon type={expanded ? 'expand-vertical' : expandedClass} />
        )}
        {icon && (
          <Icon
            small={iconSmall}
            medium={iconMedium}
            className="accordion-icon"
            type={icon}
          />
        )}
        {currentTitle}
      </>
    )
  }

  render() {
    const expanded = this.getExpanded()

    let {
      wrapperRef = null,
      titleComponent,
      disableTitleToggle = false,
      className,
      hideOnly,
      boxed,
      color,
      light,
      disabled,
      isAfterTrial,
      sortableHandle,
      hoverContent,
      featureDisabled,
      transitionSpeed,
      description: AccordionDescription,
    } = this.props

    if (color === 'darkPink') {
      color = 'dark-pink'
    }

    const TitleComponent = titleComponent

    const handleTitleClick = () => {
      if (disabled || disableTitleToggle === true) {
        return
      }

      this.handleToggle()
    }

    const renderChildrenDirectly = !disabled && hideOnly
    const renderTransitionGroup = !disabled && !renderChildrenDirectly
    const expandDisabled = isAfterTrial

    return (
      <div
        className={classNames('accordion', className, {
          'accordion-expanded': expanded,
          'accordion-boxed': boxed,
          [`accordion-boxed-${color}`]: color,
          'accordion-light': light,
          'accordion-disabled': disabled,
          'accordion-expand-disabled': expandDisabled,
        })}
        ref={wrapperRef}
      >
        <TitleComponent
          className={classNames('accordion-title', {
            'feature-disabled': featureDisabled,
          })}
          onClick={handleTitleClick}
          data-sortablehandle={sortableHandle}
          hovercontent={hoverContent}
        >
          {this.renderHeader()}
        </TitleComponent>
        {AccordionDescription}
        {renderChildrenDirectly && this.renderChildren()}
        {renderTransitionGroup && (
          <VelocityTransitionGroup
            enter={{ animation: 'slideDown' }}
            leave={{ animation: 'slideUp' }}
            speed={transitionSpeed ?? 3}
          >
            {expanded && this.renderChildren()}
          </VelocityTransitionGroup>
        )}
      </div>
    )
  }
}

// Grouped Accordion

export class GroupedAccordionSub extends Accordion {
  constructor(props) {
    super(props)

    const { itemId } = props

    this.id = itemId || getUID()
  }

  getExpanded() {
    const { keepExpanded } = this.props

    return this.props.expandedId === this.id || keepExpanded
  }

  openSub() {
    const {
      openAccordion,
      group,
      isAfterTrial,
      object,
      magicLayout,
      updateObject,
      history,
      onToggled,
    } = this.props

    onToggled?.(true)

    if (!isAfterTrial) {
      openAccordion(group, this.id)

      if (group === 'settings') {
        const params = QS.stringify(
          { active: this.id.toLowerCase() },
          { addQueryPrefix: true }
        )

        history.replace({ search: params.toString() })
      }

      if (object && object.type === LIBRARY_COMPONENT) {
        if (magicLayout) {
          // force a no-op update to the object to force a bounds re-calculation
          updateObject(object.id, {})
        } else {
          updateObject(object.id, object)
        }
      }
    }
  }

  closeSub() {
    const {
      closeAccordion,
      group,
      object,
      magicLayout,
      updateObject,
      history,
      onToggled,
    } = this.props

    onToggled?.(false)
    closeAccordion(group)

    if (group === 'settings') {
      history.replace({ search: '' })
    }

    if (this.getExpanded() && object && object.type === LIBRARY_COMPONENT) {
      if (magicLayout) {
        // force a no-op update to the object to force a bounds re-calculation
        updateObject(object.id, {})
      } else {
        updateObject(object.id, object)
      }
    }
  }

  componentDidMount() {
    const { defaultExpanded } = this.props

    if (defaultExpanded) {
      this.openSub()
    }
  }

  componentWillUnmount() {
    const { closeAccordion, group } = this.props

    if (group === LIBRARY_INSPECT_GROUP) {
      closeAccordion(group)
    }
  }

  componentDidUpdate(prevProps) {
    const { disabled, openOnEnable } = this.props

    if (openOnEnable && prevProps.disabled && !disabled) {
      this.openSub()
    }

    if (!prevProps.disabled && disabled) {
      this.closeSub()
    }

    if (
      prevProps.itemId !== this.props.itemId &&
      typeof this.props.itemId !== 'undefined'
    ) {
      this.id = this.props.itemId
    }
  }
}

const mapStateToProps = (state, { group, itemId, isExternal, type }) => {
  const isAPIEnabled = isFeatureEnabled(state, 'customIntegrations')
  const isExternalDatabaseEnabled = isFeatureEnabled(state, 'externalDatabase')

  const { trialState } = getTrialState(state)

  const paying = getActiveState(state)

  const magicLayout = getMagicLayout(state)

  let isAfterTrial = false

  if (isExternal) {
    if (type && type === 'api') {
      isAfterTrial = (!paying && trialState === 'after') || !isAPIEnabled
    } else if (type && type === 'xano') {
      isAfterTrial =
        (!paying && trialState === 'after') || !isExternalDatabaseEnabled
    }
  }

  return {
    expandedId: getAccordionState(state, group),
    advancedShown: getAccordionState(state, `${group}-advanced-${itemId}`),
    isAfterTrial,
    magicLayout,
  }
}

export const GroupedAccordion = withRouter(
  connect(mapStateToProps, {
    openAccordion,
    closeAccordion,
    toggleAccordion,
    updateObject,
  })(GroupedAccordionSub)
)

// ConnectedAccordion works somewhat as GroupAccordions, where only one accordion can be open at a time.
// But instead of having to render the accordions as children within the same AccordionGroup, you can render
// ConnectedAccordions anywhere, and they'll close other ConnectedAccordion with the same group when they open.
class ConnectedAccordionSub extends Accordion {
  constructor(props) {
    super(props)
    const { itemId } = props
    this.id = itemId || getUID()
  }

  getExpanded() {
    const { expandedId, keepExpanded } = this.props

    return keepExpanded || expandedId === this.id
  }

  openSub() {
    const { openAccordion, group, onToggled } = this.props
    onToggled?.(true)
    openAccordion(group, this.id)
  }

  closeSub() {
    const { closeAccordion, group, onToggled } = this.props
    onToggled?.(false)
    closeAccordion(group, this.id)
  }
}

const mapStateToPropsConnectedAccordion = (state, { group }) => {
  return {
    expandedId: getAccordionState(state, group),
  }
}

export const ConnectedAccordion = withRouter(
  connect(mapStateToPropsConnectedAccordion, {
    openAccordion,
    closeAccordion,
  })(ConnectedAccordionSub)
)
