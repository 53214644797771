import React from 'react'

import { IconButton } from 'components/Shared/Icon'
import Modal from 'components/Shared/Modal'

import './ModalHeader.scss'

export default function Header({ title, betaUrl, infoUrl, onBackClick }) {
  return (
    <Modal.Header color="orange">
      {onBackClick && <IconButton type="arrow-back" onClick={onBackClick} />}
      <h1 className="permissions-header">{title}</h1>
      {infoUrl && <InfoIcon url={infoUrl} />}
    </Modal.Header>
  )
}

function InfoIcon({ url }) {
  return <IconButton type="info" orange href={url} newTab />
}
