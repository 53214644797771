import React, { Component } from 'react'
import { connect } from 'react-redux'

import { NEW_SCREEN_MODAL, showModal } from 'ducks/editor/modals'

import EmptyState from 'components/Shared/EmptyState'

import imgEmptyState from './empty-state.png'

import './Empty.scss'

class EmptyCanvas extends Component {
  handleClick = e => {
    e.preventDefault()
    const { showModal } = this.props

    showModal(NEW_SCREEN_MODAL, { zoom: true }).catch(() => {
      /* modal was closed */
    })
  }

  render() {
    return (
      <EmptyState greedy className="editor-empty-canvas">
        <img src={imgEmptyState} alt="browser and mobile screen" height={220} />
        <h1>The possibilities are endless!</h1>
        <p>
          <a className="add-screen-link" onClick={this.handleClick}>
            Add a screen
          </a>{' '}
          to begin creating your masterpiece.
        </p>
      </EmptyState>
    )
  }
}

export default connect(null, { showModal })(EmptyCanvas)
