import { CreateParams } from './hooks'

const stringAttributes: (keyof CreateParams)[] = [
  'categories',
  'primaryTags',
  'secondaryTags',
  'platformTarget',
  'mobileThumbnail',
  'responsiveThumbnail',
]

export const validatePrebuiltSectionValues = (
  values: Partial<CreateParams>
): string[] => {
  const errors: string[] = []

  if (!values.name) {
    errors.push(`Name is required`)
  }

  if (!values.body) {
    errors.push(`Missing object body`)
  }

  if (!values.status) {
    errors.push(`Missing status`)
  }

  stringAttributes.forEach(key => {
    if (values[key] && typeof values[key] !== 'string') {
      errors.push(`${key} must be a string`)
    }
  })

  return errors
}
