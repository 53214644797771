import React, { Component } from 'react'
import { Field } from 'redux-form'
import { formatterOptions, dataTypes } from '@adalo/constants'

import WrappedSelect from '../../../Shared/Forms/WrappedSelect'

export default class DateFormExtension extends Component {
  render() {
    return (
      <div className="action-item-split-row">
        <div>
          <label>Date Format</label>
          <Field
            autoSelect
            className="small white"
            name="format.type"
            component={WrappedSelect}
            options={formatterOptions[dataTypes.DATE]}
            placeholder="Auto"
          />
        </div>
      </div>
    )
  }
}
