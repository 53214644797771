import React, { Component } from 'react'

import TextControl from './TextControl'
import BooleanControl from './BooleanControl'
import ColorPicker from './ColorPicker'

export default class SizeControl extends Component {
  handleChange = newValue => {
    const { value, name, onChange } = this.props

    const newShadow = { ...value, ...newValue }

    if (newShadow.size > 50) {
      newShadow.size = 50
    }

    onChange({ [name]: newShadow })
  }

  render() {
    let { value } = this.props

    value = value || {}

    return (
      <React.Fragment>
        <BooleanControl
          onChange={this.handleChange}
          name="enabled"
          value={value.enabled}
        />
        <ColorPicker
          onChange={this.handleChange}
          name="color"
          value={value.color || '#000'}
          disabled={!value.enabled}
        />
        <TextControl
          onChange={this.handleChange}
          name="x"
          label="X"
          type="number"
          value={value.x}
          placeholder="0"
          disabled={!value.enabled}
        />
        <TextControl
          onChange={this.handleChange}
          name="y"
          label="Y"
          type="number"
          value={value.y}
          placeholder="0"
          disabled={!value.enabled}
        />
        <TextControl
          onChange={this.handleChange}
          name="size"
          label="Size"
          type="number"
          value={value.size}
          placeholder="0"
          disabled={!value.enabled}
        />
      </React.Fragment>
    )
  }
}
