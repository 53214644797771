import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import QS from 'qs'

import { useFlags } from 'flags'

import Modal from 'components/Shared/Modal'

import { startSecondTrial } from 'ducks/apps/trial'
import { getApp } from 'ducks/apps'
import { fetchOrganization } from 'ducks/organizations'
import history from '../../../history'

import StepOne from './StepOne'
import StepTwo from './StepTwo'
import { FeatureValue } from './features'

import './style.scss'

const featureMap: Map<string, FeatureValue> = new Map([
  ['googleApiKey', 'geolocation'],
  ['geolocation', 'geolocation'],
  ['api', 'collections-api'],
  ['designVersions', 'design-versions'],
  ['customFonts', 'custom-fonts'],
  ['customActions', 'custom-actions'],
  ['externalCollection', 'external-collections'],
  ['externalDatabase', 'external-collections'],
  ['customIntegrations', 'external-collections'],
])

const FreeTrialExtendModal = ({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  startSecondTrial,
  appId,
  orgId,
}: {
  startSecondTrial: (org: string, app: string, feature: string) => void
  appId: string
  orgId: string
}): JSX.Element => {
  const [selectedFeature, setSelectedFeature] = useState('' as FeatureValue)
  const [step, setStep] = useState(1)
  const { hasTrialExtension2023 } = useFlags()
  const dispatch = useDispatch()

  const location = useLocation()
  const { feature: query } =
    QS.parse(location.search, {
      ignoreQueryPrefix: true,
    }) || {}

  useEffect(() => {
    if (query) {
      const parseFeature = (feature: string | undefined): FeatureValue => {
        if (!feature) {
          return ''
        }

        return featureMap.get(feature) || ''
      }
      const initialFeature = parseFeature(String(query))
      setSelectedFeature(initialFeature)
    }
  }, [])

  const selectFeature = (feature: FeatureValue) => {
    setSelectedFeature(feature)
  }

  const handleClose = () => {
    dispatch(fetchOrganization(orgId))
    const url = `/apps/${appId}/screens`

    history.push(url)
  }

  if (!hasTrialExtension2023) {
    return <></>
  }

  const handleClickExtend = () => {
    startSecondTrial(orgId, appId, selectedFeature)
    setStep(2)
  }

  return (
    <Modal size="lg">
      {step === 1 && (
        <StepOne
          handleClose={handleClose}
          handleClickExtend={handleClickExtend}
          selectFeature={selectFeature}
          selectedFeature={selectedFeature}
        />
      )}
      {step === 2 && (
        <StepTwo
          handleClose={handleClose}
          selectedFeature={selectedFeature}
          appId={appId}
          orgId={orgId}
        />
      )}
    </Modal>
  )
}

const mapStateToProps = (
  state: Record<string, unknown>,
  { match }: { match: { params: { appId: string } } }
) => {
  const app = getApp(state, match.params.appId)
  const { OrganizationId } = app || {}

  return {
    appId: match.params.appId,
    orgId: OrganizationId,
  }
}

export default withRouter(
  connect(mapStateToProps, { startSecondTrial })(FreeTrialExtendModal)
)
