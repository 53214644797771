import React, { useMemo, useState } from 'react'
import { Dispatch } from 'redux'
import { reduxForm, Field, reset, InjectedFormProps } from 'redux-form'
import emailValidator from 'email-validator'
import { useHistory } from 'react-router-dom'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Modal from 'components/Shared/Modal'
import Icon from 'components/Shared/Icon'

import type { CurrentUser } from 'ducks/users'

import '../Settings.scss'

type Props = {
  onClose: () => void
  currentUser: CurrentUser
  isTeamInvite: boolean
  loading: boolean
}

type FormValues = {
  email: string
  freelancer?: boolean
}

const InviteForm: React.FC<Props & InjectedFormProps<FormValues, Props>> = ({
  handleSubmit,
  onClose,
  currentUser,
  isTeamInvite = false,
  loading,
}): JSX.Element => {
  const history = useHistory()

  const [email, setEmail] = useState<string | null>(null)
  const [freelancer, setFreelancer] = useState<boolean | null>(null)

  const showFreelancerDropdown = currentUser?.persona !== 'Freelancer/Agency'

  const handleCancel = () => {
    if (onclose) {
      onClose()
    } else {
      history.goBack()
    }
  }

  const disableButton = useMemo(() => {
    if (!email || !emailValidator.validate(email)) {
      return true
    }

    if (showFreelancerDropdown && freelancer === null && !currentUser?.admin) {
      return true
    }

    return false
  }, [email, freelancer, currentUser])

  return (
    <form className="user-invite-form" onSubmit={handleSubmit}>
      <Modal.Header>
        <h1>
          <div>
            Invite New Team Member
            <Icon type="party-guy" />
          </div>
          {isTeamInvite ? (
            <span>This person will be invited only to your team.</span>
          ) : (
            <span>
              This person will be both invited to your team and this app.
            </span>
          )}
        </h1>
      </Modal.Header>
      <Modal.Content>
        <Field
          inline
          small
          autoComplete="off"
          component={WrappedInput}
          name="email"
          label="Email Address"
          placeholder="elon.musk@spacex.com"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        {showFreelancerDropdown && (
          <Field
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            component={WrappedSelect}
            name="freelancer"
            label="Is this person a freelancer or from an agency?"
            onChangeValue={(value: boolean) => setFreelancer(value)}
          />
        )}
      </Modal.Content>
      <Modal.Actions
        leftButtons={[
          <Modal.Button key="cancel" type="button" onClick={handleCancel} text>
            Cancel
          </Modal.Button>,
        ]}
      >
        <Modal.Button
          type="submit"
          teal
          loading={loading}
          disabled={disableButton}
        >
          Send Invite
        </Modal.Button>
      </Modal.Actions>
    </form>
  )
}

const afterSubmit = (result: unknown, dispatch: Dispatch) => {
  dispatch(reset('inviteFormModal'))
}

export default reduxForm<FormValues, Props>({
  form: 'inviteFormModal',
  onSubmitSuccess: afterSubmit,
})(InviteForm)
