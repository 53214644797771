import React from 'react'
import deepEqual from 'deep-equal'
import { sourceTypes } from '@adalo/constants'

import { THEMES } from 'constants.js'

import GooglePlacesInput from 'components/Shared/Forms/GooglePlacesInput'

import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

import MenuControl from '../Libraries/MenuControl'

export const LocationControl = ({
  appId,
  fallbackValue,
  locationOptions,
  getLocationLabel,
  locationValue,
  handleChangeLocationSource,
  handleChangeLocationFallback,
}) => {
  return (
    <>
      <MenuControl
        options={locationOptions}
        getLabel={getLocationLabel}
        name="source"
        displayName="Sort in relation to..."
        value={locationValue}
        onChange={handleChangeLocationSource}
        comparator={deepEqual}
        placeholder="Select..."
        menuTheme={THEMES.DATA}
      />
      {locationValue ? (
        <div className="library-inspect-menu-control">
          {locationValue.type === sourceTypes.CUSTOM_LOCATION ? null : (
            <div className="library-inspect-menu-control-header">
              <p>Fallback address</p>
              <Tooltip
                small
                hideArrow
                placement="bottom-start"
                tooltip="The fallback address will be used if the referenced sorting location cannot be found."
              >
                <Icon type="help-small" />
              </Tooltip>
            </div>
          )}
          <GooglePlacesInput
            panelView
            appId={appId}
            input={{
              name: 'fallback',
              onChange: handleChangeLocationFallback,
              value: fallbackValue,
            }}
          />
        </div>
      ) : null}
    </>
  )
}
