import { connect } from 'react-redux'
import Button from 'components/Shared/Button'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import Icon from 'components/Shared/Icon'
import { App } from 'ducks/apps'
import { useEffect, useState } from 'react'

type AppLayoutProps = {
  app: App
  onSubmit: (value: string) => void
}

const AppLayoutTypeSettings = (props: AppLayoutProps): JSX.Element => {
  const { app, onSubmit } = props
  const { webSettings } = app
  const { layoutMode = 'responsive' } = webSettings || {}
  const [value, setValue] = useState('responsive')

  const label = 'Where do you want users to view this app?'
  const options = [
    { label: 'On Mobile Devices Only', value: 'mobile' },
    { label: 'On Mobile, Tablet, & Desktop Devices', value: 'responsive' },
  ]

  const buttonText =
    layoutMode === 'responsive' ? 'Save as Mobile' : 'Save as Responsive'

  useEffect(() => {
    if (layoutMode) {
      setValue(layoutMode)
    }
  }, [])

  const onChange = ({ layout }: { layout: string }): void => {
    setValue(layout)
  }

  const onClick = (): void => {
    onSubmit(value)
  }

  const buttonDisabled = layoutMode === value

  return (
    <div className="app-layout-settings">
      <MenuControl
        displayName={label}
        options={options}
        value={value}
        onChange={onChange}
        name="layout"
      />
      <div className="mobile-layout-help-text-container">
        <div className="mobile-layout-help-header">
          <Icon type="help-text" />
          <h2>Helpful Tip</h2>
        </div>
        <h3>What happens when you switch layout types?</h3>
        <p>
          Switching between mobile-only and an app that works on every device
          will change the options that are available for each component in the
          'Layout' section.
          <br />
          <br />
          You can easily change this back and forth at any point. To learn more,
          head over to our{' '}
          <a
            href="https://help.adalo.com/design/designing-your-app/"
            className="help-doc"
            target="_blank"
            rel="noreferrer"
          >
            Help Doc
          </a>
          .
        </p>
      </div>
      <Button
        outlined={buttonDisabled}
        disabled={buttonDisabled}
        teal={!buttonDisabled}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {})(AppLayoutTypeSettings)
