import React, { Component } from 'react'

import { reduxForm, Field, FieldArray, reset } from 'redux-form'
import emailValidator from 'email-validator'
import {
  isMaliciousHTML,
  MALICIOUS_ERROR_MESSAGE,
} from '../../utils/validation'

import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'

import AuthPageLogo from './Logo'

import './Auth.scss'

const FORM_NAME = 'teamForm'

const validate = ({ emails, name }) => {
  const errors = {}

  for (let email of emails) {
    if (!email || !email.email) {
      continue
    }

    email = email.email

    if (email && !emailValidator.validate(email)) {
      errors.email = 'Please enter a valid email address'
    }
  }

  if (name === '' || name === undefined) {
    errors.name = 'Please enter a name'
  }

  if (isMaliciousHTML) {
    errors.name = MALICIOUS_ERROR_MESSAGE
  }

  return errors
}

class TeamForm extends Component {
  renderEmails = ({ fields, meta }) => (
    <div>
      {fields.map((email, index) => {
        return (
          <div>
            <Field
              name={`${email}.email`}
              placeholder="elon@spacex.com"
              type="email"
              component={WrappedInput}
              label={index === 0 ? 'Invite Team Members' : null}
              onChange={() => {
                if (index === fields.length - 1) {
                  fields.push({})
                }
              }}
            />
          </div>
        )
      })}
    </div>
  )

  render() {
    const { handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Set Up Your Team</h1>
        <Field
          name="name"
          placeholder="Team Name"
          type="text"
          component={WrappedInput}
          label="Team Name"
        />

        <FieldArray name="emails" component={this.renderEmails} />

        <div className="auth-form-submission-row">
          <Button large yellow>
            CREATE TEAM
          </Button>
        </div>
        {/* {error && (
          <div className="auth-form-error">
            {error}
          </div>
        )} */}
      </form>
    )
  }
}

const afterSubmit = (result, dispatch) => {
  dispatch(reset(FORM_NAME))
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  onSubmitSuccess: afterSubmit,
})(TeamForm)
