import { CustomActionState, CustomAction } from './customAction.type'

export default function findTemplate(
  customActions: CustomActionState | undefined
): CustomAction | undefined {
  if (!customActions) {
    return undefined
  }

  for (const key in customActions) {
    if (
      customActions[key]?.body &&
      !customActions[key]?.deleted &&
      customActions[key]?.body.template
    ) {
      return customActions[key]
    }
  }

  return undefined
}
