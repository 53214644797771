import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import QS from 'qs'

import Button from 'components/Shared/Button'
import SettingsModalField from 'components/Shared/Forms/SettingsModalField'
import { adaloBackendAxios } from 'utils/io/http/axios'

const required = val => (val ? undefined : 'Required')

const minLength5 = val =>
  val.length < 5 ? `Too short! (must be at least 5 characters long)` : undefined

const isDomainFormat = val => {
  if (val.match(/[^a-z\d\-]/)) {
    return 'Can only contain letters, numbers, and dashes (-)'
  }

  if (val[0] === '-' || val[val.length - 1] === '-') {
    return 'Cannot start or end with a dash (-)'
  }

  if (val.match(/\-\-/)) {
    return 'Cannot have multiple dashes in a row'
  }

  return undefined
}

const asyncValidate = async ({ id, subdomain }) => {
  try {
    const query = QS.stringify({ id, subdomain })
    const url = `/organizations/subdomain-availability?${query}`

    await adaloBackendAxios.get(url)
  } catch (err) {
    throw { subdomain: `${subdomain} is not available.` }
  }
}

class SubdomainForm extends Component {
  getBaseURL = () => {
    return process.env.REACT_APP_WEB_BASE_URL
  }

  render() {
    const { submitting, handleSubmit, disabled, toggleEditing } = this.props

    return (
      <div className="settings-list-item">
        <form className="team-settings-domain-details" onSubmit={handleSubmit}>
          <Field
            inline
            autoFocus
            autoComplete="off"
            component={SettingsModalField}
            name="subdomain"
            disabled={disabled}
            validate={[required, minLength5, isDomainFormat]}
          >
            <span>{`.${this.getBaseURL()}`}</span>
          </Field>
          <div className="settings-modal-inline-submit">
            <Button small text onClick={toggleEditing}>
              Cancel
            </Button>
            <Button small disabled={submitting}>
              Save
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'customSubdomain',
  asyncValidate,
  enableReinitialize: true,
})(SubdomainForm)
