import { AxiosResponse } from 'utils/io/http/axios'

import {
  FETCH_AUTH_SIGNUP,
  FETCH_AUTH_LOGIN,
  FETCH_AUTH_ME,
} from '../actions/auth'
import { RESET } from '..'

export type XanoDatabaseState = {
  externalDatabases: {
    xano: {
      auth: {
        signup?: {
          missingRequestFields?: string[]
          missingResponseFields?: string[]
          requestBody: Record<string, string | number>
          responseBody: Record<string, string | number>
          missingEndpoint: boolean
        }
        login?: {
          missingRequestFields?: string[]
          missingResponseFields?: string[]
          requestBody: Record<string, string | number>
          responseBody: Record<string, string | number>
          missingEndpoint: boolean
        }
        me?: {
          missingRequestFields?: string[]
          missingResponseFields?: string[]
          missingEndpoint: boolean
          authMeErrorMessage?: string | undefined
        }
      }
    }
  } | null
}

type BaseUrlResponse = {
  status: number
}
export interface XanoDatabaseAction {
  type: typeof FETCH_AUTH_SIGNUP
  payload?: Promise<AxiosResponse<BaseUrlResponse>>
}

interface XanoDatabaseActionResponse
  extends Omit<XanoDatabaseAction, 'payload'> {
  payload: {
    missingRequestFields?: string[]
    missingResponseFields?: string[]
    requestBody: Record<string, string | number>
    responseBody: Record<string, string | number>
    missingEndpoint: boolean
    message?: string
  }
}

export const AUTH_INITIAL_STATE = {
  externalDatabases: {
    xano: {
      auth: {},
    },
  },
}

// Reducer
export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = AUTH_INITIAL_STATE,
  action: XanoDatabaseActionResponse
): XanoDatabaseState => {
  const authData = state.externalDatabases?.xano?.auth || {}

  if (action.type === `${RESET}`) {
    return AUTH_INITIAL_STATE
  }

  if (action.type === `${FETCH_AUTH_SIGNUP}`) {
    return {
      externalDatabases: {
        xano: {
          auth: {
            ...authData,
            signup: {
              missingRequestFields: action.payload?.missingRequestFields ?? [],
              missingResponseFields:
                action.payload?.missingResponseFields ?? [],
              requestBody: action.payload.requestBody ?? {},
              responseBody: action.payload.responseBody ?? {},
              missingEndpoint: action.payload?.missingEndpoint ?? false,
            },
          },
        },
      },
    }
  }

  if (action.type === `${FETCH_AUTH_LOGIN}`) {
    return {
      ...state,
      externalDatabases: {
        xano: {
          auth: {
            ...authData,
            login: {
              missingRequestFields: action.payload?.missingRequestFields ?? [],
              missingResponseFields:
                action.payload?.missingResponseFields ?? [],
              requestBody: action.payload.requestBody ?? {},
              responseBody: action.payload.responseBody ?? {},
              missingEndpoint: action.payload?.missingEndpoint ?? false,
            },
          },
        },
      },
    }
  }

  if (action.type === `${FETCH_AUTH_ME}`) {
    return {
      ...state,
      externalDatabases: {
        xano: {
          auth: {
            ...authData,
            me: {
              missingRequestFields: action.payload?.missingRequestFields ?? [],
              missingResponseFields:
                action.payload?.missingResponseFields ?? [],
              missingEndpoint: action.payload?.missingEndpoint ?? false,
              authMeErrorMessage: action.payload?.message,
            },
          },
        },
      },
    }
  }

  return state
}
