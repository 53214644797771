import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getTypeOptions } from 'utils/dataTypes'

import { getDatasources } from 'ducks/apps/datasources'
import {
  getParams,
  createParam,
  updateParam,
  deleteParam,
} from 'ducks/apps/params'

import Icon from 'components/Shared/Icon'

import ParamItem from './Item'
import ImplicitParams from './ImplicitParams'

import './Params.scss'

class Params extends Component {
  getTypeOptions = () => {
    const { datasources } = this.props

    return getTypeOptions(datasources)
  }

  handleCreateParam = () => {
    const { match, component, createParam } = this.props

    createParam(match.params.appId, component.id)
  }

  handleChangeParam = param => {
    if (!param.id) {
      return
    }

    const { match, component, updateParam } = this.props

    updateParam(match.params.appId, component.id, param.id, param)
  }

  handleDeleteParam = paramId => {
    const { match, component, deleteParam } = this.props

    deleteParam(match.params.appId, component.id, paramId)
  }

  renderEmpty() {
    return (
      <div className="empty-params-section" onClick={this.handleCreateParam}>
        + Add Manual Data
      </div>
    )
  }

  render() {
    const { match, component, params } = this.props
    const { appId } = match.params

    const typeOptions = this.getTypeOptions()

    return (
      <div className="params-section">
        <ImplicitParams appId={appId} componentId={component.id} />

        {params.length !== 0 ? (
          <div className="manual-params">
            <h5>Manually Added</h5>
            {params.length === 0
              ? this.renderEmpty()
              : params.map(param => (
                  <ParamItem
                    key={param.id}
                    param={param}
                    onChange={this.handleChangeParam}
                    onDelete={this.handleDeleteParam}
                    typeOptions={typeOptions}
                  />
                ))}
            {params.length > 0 ? (
              <div
                className="params-add-param"
                onClick={this.handleCreateParam}
              >
                <Icon type="plus-small" />
                <span>Add Manual Data</span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, { match, component }) => ({
  datasources: getDatasources(state, match.params.appId),
  params: getParams(state, match.params.appId, component.id),
})

export default withRouter(
  connect(mapStateToProps, { createParam, updateParam, deleteParam })(Params)
)
