import { ForwardedRef, forwardRef, useContext } from 'react'
import styled from 'styled-components'
import { makeUniqueFieldId } from 'components/Editor/Inspect/Libraries/Table/utils'
import { type TableField } from 'utils/responsiveTypes'
import TableThemeContext from './TableThemeContext'
import { type DefaultRowProps } from './types'
import { MIN_HEADER_HEIGHT } from './layout'
import { getColumnStyles } from './utils'

import { ReactComponent as SortIconAsc } from './sort-asc.svg'
import { ReactComponent as SortIconDesc } from './sort-desc.svg'

const ICON_MAP = {
  asc: SortIconAsc,
  desc: SortIconDesc,
}

const Container = styled('div')<{
  $backgroundColor: string
  $borderWidth: number
  $tableColumnLineColor: string
  $borderRadius: number
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  border-bottom: ${props => props.$borderWidth}px solid
    ${props => props.$tableColumnLineColor};
  background-color: ${props => props.$backgroundColor};
  border-top-left-radius: ${props =>
    props.$borderRadius - props.$borderWidth}px;
  border-top-right-radius: ${props =>
    props.$borderRadius - props.$borderWidth}px;
`

const RowHeaderContainer = styled('div')<{
  $tableColumnFontColor: string
  $tableColumnFontFamily: string
  $minWidth: number
  $minHeight: number
  $flex: string
}>`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.25px;
  color: ${props => props.$tableColumnFontColor};
  font-family: ${props => props.$tableColumnFontFamily};
  flex: ${props => props.$flex};
  min-width: ${props => props.$minWidth}px;
  min-height: ${props => props.$minHeight}px;
  word-break: break-word;
`

const IconContainer = styled('div')<{
  $color: string
}>`
  display: inline-block;

  svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    fill: ${props => props.$color};
    vertical-align: middle;
  }
`

const SortIndicator = ({
  field,
  sort,
  color,
}: {
  field: TableField
  sort: DefaultRowProps['sort']
  color: string
}) => {
  if (sort && sort.fieldId === field.binding.source.fieldId) {
    const Icon = ICON_MAP[sort.direction]

    return (
      <IconContainer $color={color}>
        <Icon />
      </IconContainer>
    )
  }

  return null
}

const TableRowHeader = (
  { fields, sort }: DefaultRowProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element | null => {
  const {
    borderWidth,
    borderRadius,
    tableColumnBackgroundColor,
    tableColumnFontColor,
    tableColumnLineColor,
    fontFamily,
  } = useContext(TableThemeContext)

  return (
    <Container
      $backgroundColor={tableColumnBackgroundColor}
      $borderWidth={1}
      $tableColumnLineColor={tableColumnLineColor}
      $borderRadius={borderRadius - borderWidth}
      ref={ref}
    >
      {fields.map((field: TableField) => {
        const { minWidth, flex } = getColumnStyles(field)

        return (
          <RowHeaderContainer
            key={makeUniqueFieldId(field)}
            $tableColumnFontColor={tableColumnFontColor}
            $tableColumnFontFamily={fontFamily}
            $minWidth={minWidth}
            $minHeight={MIN_HEADER_HEIGHT}
            $flex={flex}
          >
            {field.label}
            <SortIndicator
              field={field}
              color={tableColumnFontColor}
              sort={sort}
            />
          </RowHeaderContainer>
        )
      })}
    </Container>
  )
}

export default forwardRef(TableRowHeader)
