import { useEffect, useState } from 'react'
import WebFont from 'webfontloader'

export default function FontsLoader({ app = {} }) {
  const { branding = {} } = app

  const [families, setFamilies] = useState([])

  useEffect(() => {
    handleFontFamilies()
  }, [branding])

  useEffect(() => {
    handleLoadingFonts()
  }, [families])

  function handleLoadingFonts() {
    if (families.length > 0) WebFont.load({ google: { families } })
  }

  function handleFontFamilies() {
    const { fonts = {} } = branding
    const keys = Object.keys(fonts)

    const familiesToLoad = []

    for (const key of keys) {
      if (!key) continue

      const target = fonts[key]

      if (!target.family || target.family === 'default') continue

      familiesToLoad.push(handleFontFamily(target))
    }

    if (familiesToLoad.length > 0) setFamilies([...families, ...familiesToLoad])
  }

  return null
}

function handleFontFamily(obj) {
  const { family, variants } = obj

  if (variants?.length > 0) return `${family}:${variants.join(',')}`

  return family
}
