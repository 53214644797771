import React, { Component } from 'react'
import { getId as generateUuid } from '@adalo/utils'
import { isEmpty } from 'lodash'
import { FORM } from '@adalo/constants'
import StylesAccordion from 'components/Shared/StylesAccordion'
import { getFontWeightOptions, getFontFamilyOptions } from 'utils/type'
import { defaults } from 'utils/objects'
import ActionRow from '../Libraries/ActionRow'
import SimpleTextControl from '../Libraries/SimpleTextControl'
import SliderControl from '../Libraries/SliderControl'
import GenericInspectRow from '../GenericRow'
import TextControl from '../TextControl'
import { ShadowControl as DefaultShadowControl } from '../DefaultShadowControl'
import { FontControl } from '../DefaultFontControl'
import ColorDropdown from '../ColorDropdown'

/**
 * @typedef {import('./types').SubmitButtonProps} SubmitButtonProps
 * @typedef {import('./types').SecondaryButtonProps} SecondaryButtonProps
 */

const FONT_CONTROL_FEATURES = {
  fontSize: true,
  fontWeight: true,
  fontFamily: true,
}
const MIN_BORDER_WIDTH = 0
const MAX_BORDER_WIDTH = 6
const MIN_PADDING = 0
const MAX_PADDING = 40
const MIN_BORDER_RADIUS = 0
const MAX_BORDER_RADIUS = 50

export class SubmitButton extends Component {
  static defaultProps = {
    value: {},
  }

  renderStyles = () => {
    /** @type {SubmitButtonProps} */
    const { value, onChange, branding } = this.props

    return (
      <div>
        <GenericInspectRow className="default-font-control" key="fontControl">
          <ColorDropdown
            label="Fill Color"
            value={value.backgroundColor ?? '#60f'}
            name="backgroundColor"
            onChange={onChange}
            branding={branding}
          />
          <ColorDropdown
            label="Text Color"
            value={value.color ?? '#fff'}
            name="color"
            onChange={onChange}
            branding={branding}
          />
        </GenericInspectRow>
        <FontControl
          object={{
            fontSize: value.fontSize ?? 14,
            fontWeight: value.fontWeight ?? '400',
            fontFamily: value.fontFamily ?? '@body',
          }}
          onChange={onChange}
          features={FONT_CONTROL_FEATURES}
          fontWeightOptions={getFontWeightOptions(value.fontFamily, branding)}
          fontFamilyOptions={getFontFamilyOptions(branding)}
        />
        <GenericInspectRow key="padding">
          <SliderControl
            value={value.padding ?? 10}
            name="padding"
            onChange={onChange}
            title="Padding"
            min={MIN_PADDING}
            max={MAX_PADDING}
            sliderBackground
          />
        </GenericInspectRow>
        <GenericInspectRow key="rounding">
          <SliderControl
            value={value.borderRadius ?? 0}
            name="borderRadius"
            onChange={onChange}
            title="Rounding"
            min={MIN_BORDER_RADIUS}
            max={MAX_BORDER_RADIUS}
            sliderBackground
          />
        </GenericInspectRow>

        <GenericInspectRow className="default-font-control">
          <ColorDropdown
            label="Border Color"
            value={value.borderColor ?? '#999'}
            name="borderColor"
            onChange={onChange}
          />
          <TextControl
            gray
            type="number"
            name="borderWidth"
            value={value.borderWidth ?? 0}
            onChange={onChange}
            title="Border Width"
            minValue={MIN_BORDER_WIDTH}
            maxValue={MAX_BORDER_WIDTH}
          />
        </GenericInspectRow>
        <DefaultShadowControl object={value} onChange={onChange} />
      </div>
    )
  }

  handleChangeAdvanced = val => {
    /** @type {SecondaryButtonProps} */
    const { onChange } = this.props

    onChange(val)
  }

  getDummyActions() {
    /** @type {SecondaryButtonProps} */
    const {
      object: { reference, id },
      tableName,
      onChange,
    } = this.props

    let title
    let sentence

    if (reference === 'new') {
      title = 'Create'
      sentence = `create the ${tableName}`
    } else if (reference === 'login') {
      title = 'Log In'
      sentence = `log in the ${tableName}`
    } else if (reference === 'signup') {
      title = 'Sign Up'
      sentence = `sign up the ${tableName}`
    } else {
      title = 'Update'
      sentence = `update a ${tableName}`
    }

    return [
      {
        id: 'submit',
        title,
        subtitle: tableName,
        description: `Clicking the Submit Button on the form will ${sentence}`,
        reference,
        objectId: id,
        onChange,
      },
    ]
  }

  render() {
    /** @type {SecondaryButtonProps} */
    const { value, onChange, object, appId } = this.props

    return (
      <div>
        <SimpleTextControl
          displayName="Text"
          name="text"
          value={value.text}
          placeholder="SAVE"
          onChange={onChange}
        />
        <ActionRow
          key={object.id}
          appId={appId}
          displayName="Click Actions"
          name="action"
          value={value.action}
          onChange={onChange}
          objectId={object.id}
          dummyActions={this.getDummyActions()}
        />
        <StylesAccordion renderChildren={this.renderStyles} />
      </div>
    )
  }
}

const secondaryButtonDefaults = defaults[FORM].secondaryButton

export class SecondaryButton extends Component {
  static defaultProps = {
    value: {},
  }

  componentDidUpdate() {
    /** @type {SecondaryButtonProps} */
    const { value: secondaryButton, onChangeParent } = this.props

    if (!secondaryButton || isEmpty(secondaryButton)) {
      // Setup default secondaryButton if no secondaryButton property exists in the current form
      onChangeParent(secondaryButtonDefaults)
    }

    // Note that when the SecondaryButton is first enabled, it will have no action set, and the 'action' attribute will be undefined.
    // If it has been enabled at some point and the default actions were deleted, the 'action' attribute will be set to null.
    if (typeof secondaryButton?.action === 'undefined') {
      this.handleAddSecondaryButtonDefaultAction()
    }
  }

  handleAddSecondaryButtonDefaultAction() {
    /** @type {SecondaryButtonProps} */
    const { value, onChange } = this.props

    if (!value) {
      console.warn(
        `Attempted to add action to form secondary button, but secondary button does not exist`
      )

      return
    }

    const actionId = generateUuid()
    const actionItemId = generateUuid()
    const actionsUpdate = {
      [actionId]: {
        type: 'action',
        eventType: 'tap',
        actions: [
          {
            id: actionItemId,
            options: { target: 'navigation.back' },
            actionType: 'navigate',
          },
        ],
      },
    }

    const buttonUpdate = { action: { type: 'actionRef', actionId } }

    onChange(buttonUpdate, actionsUpdate)
  }

  renderStyles = () => {
    /** @type {SecondaryButtonProps} */
    const { value, onChange, branding } = this.props

    return (
      <div>
        <GenericInspectRow className="default-font-control" key="fontControl">
          <ColorDropdown
            label="Fill Color"
            value={
              value.backgroundColor ?? secondaryButtonDefaults.backgroundColor
            }
            name="backgroundColor"
            onChange={onChange}
            branding={branding}
          />
          <ColorDropdown
            label="Text Color"
            value={value.color ?? secondaryButtonDefaults.color}
            name="color"
            onChange={onChange}
            branding={branding}
          />
        </GenericInspectRow>
        <FontControl
          object={{
            fontSize: value.fontSize ?? secondaryButtonDefaults.fontSize,
            fontWeight: value.fontWeight ?? secondaryButtonDefaults.fontWeight,
            fontFamily: value.fontFamily ?? secondaryButtonDefaults.fontFamily,
          }}
          onChange={onChange}
          features={FONT_CONTROL_FEATURES}
          fontWeightOptions={getFontWeightOptions(value.fontFamily, branding)}
          fontFamilyOptions={getFontFamilyOptions(branding)}
        />
        <GenericInspectRow key="padding">
          <SliderControl
            value={value.padding ?? 10}
            name="padding"
            onChange={onChange}
            title="Padding"
            min={MIN_PADDING}
            max={MAX_PADDING}
            sliderBackground
          />
        </GenericInspectRow>
        <GenericInspectRow key="rounding">
          <SliderControl
            value={value.borderRadius ?? 0}
            name="borderRadius"
            onChange={onChange}
            title="Rounding"
            min={MIN_BORDER_RADIUS}
            max={MAX_BORDER_RADIUS}
            sliderBackground
          />
        </GenericInspectRow>
        <GenericInspectRow className="default-font-control">
          <ColorDropdown
            label="Border Color"
            value={value.borderColor ?? secondaryButtonDefaults.borderColor}
            name="borderColor"
            onChange={onChange}
          />
          <TextControl
            gray
            type="number"
            name="borderWidth"
            value={value.borderWidth ?? 0}
            onChange={onChange}
            title="Border Width"
            minValue={MIN_BORDER_WIDTH}
            maxValue={MAX_BORDER_WIDTH}
          />
        </GenericInspectRow>
        <DefaultShadowControl object={value} onChange={onChange} />,
      </div>
    )
  }

  filterActionMenuOptions = menuOptions => {
    /** @type {SecondaryButtonProps} */
    const { tableId } = this.props

    const filteredMenuOptions = []
    for (const menu of menuOptions) {
      if (menu && menu.children) {
        const filteredMenuChildren = menu.children.filter(
          c => c?.value?.options?.tableId !== tableId
        )

        // We want the secondary button actions to only be able to access collections different than the current form collection
        if (filteredMenuChildren.length > 0) {
          // If there's only one collection available, we don't want to show the default "Create New Collection" option either
          const hasExistingCollectionTarget =
            filteredMenuChildren
              .filter(Boolean)
              .filter(c => c?.value?.target !== 'createNewCollection').length >
            0

          if (hasExistingCollectionTarget) {
            menu.children = filteredMenuChildren
            filteredMenuOptions.push(menu)
          }
        }
      } else {
        // We want to keep falsey values, as they render as the separators
        filteredMenuOptions.push(menu)
      }
    }

    return filteredMenuOptions
  }

  render() {
    /** @type {SecondaryButtonProps} */
    const { value, onChange, object, appId } = this.props

    return (
      <div>
        <SimpleTextControl
          displayName="Text"
          name="text"
          value={value.text}
          placeholder={secondaryButtonDefaults.text}
          onChange={onChange}
        />
        <ActionRow
          key={object.id}
          appId={appId}
          displayName="Click Actions"
          name="action"
          value={value.action}
          onChange={onChange}
          objectId={object.id}
          filterMenuOptions={this.filterActionMenuOptions}
          omitContextualTables
        />
        <StylesAccordion renderChildren={this.renderStyles} />
      </div>
    )
  }
}
