import update from 'immutability-helper'
import { capitalize, tableNamesEqual, pluralize } from './strings'

export const getHasManyRelations = (datasource, tableId) => {
  // Iterate over tables
  // Iterate over fields
  // Check each field for field.type.type === 'belongsTo'
  // -> If so AND field.type.tableId === tableId, then add

  const relations = []

  for (const relationTableId of Object.keys(datasource.tables)) {
    // We don't allow self-referential tables for now
    if (tableId === relationTableId) {
      continue
    }

    const collections = datasource.tables || datasource.collections
    const table = collections[relationTableId]

    for (const fieldId of Object.keys(table.fields)) {
      const field = table.fields[fieldId]

      if (field.type?.type === 'belongsTo') {
        if (field.type.tableId === tableId) {
          const originalName = field.name
          let fieldInfo = ''

          if (
            !tableNamesEqual(
              originalName,
              datasource.tables[field.type.tableId].name
            )
          ) {
            fieldInfo = ` (${originalName})`
          }

          relations.push({
            fieldId,
            tableId: relationTableId,
            name: capitalize(table.name) + fieldInfo,
          })
        }
      }
    }
  }

  return relations
}

export const getBelongsToRelations = table => {
  // Iterate over all fields in TABLE
  // Check if any are type.type = 'belongsTo'
  // Return these

  const relations = []
  const fields = (table && table.fields) || {}

  for (const fieldId of Object.keys(fields)) {
    const field = table.fields[fieldId]

    if (field.type?.type === 'belongsTo') {
      relations.push({
        fieldId,
        tableId: field.type.tableId,
        name: field.name,
      })
    }
  }

  return relations
}

export const getInboundManyToManyRelations = (datasource, tableId) => {
  // Iterate over tables
  // Iterate over fields
  // Check each field for field.type.type === 'manyToMany'
  // -> If so AND field.type.tableId === tableId, then add

  const relations = []

  for (const relationTableId of Object.keys(datasource.tables)) {
    // We don't allow self-referential tables for now
    if (tableId === relationTableId) {
      continue
    }

    const table = datasource.tables[relationTableId]

    for (const fieldId of Object.keys(table.fields)) {
      const field = table.fields[fieldId]

      if (field.type?.type === 'manyToMany') {
        if (field.type.tableId === tableId) {
          const originalName = field.name
          let fieldInfo = ''

          if (
            !tableNamesEqual(
              originalName,
              datasource.tables[field.type.tableId].name
            )
          ) {
            fieldInfo = ` (${originalName})`
          }

          relations.push({
            fieldId,
            tableId: relationTableId,
            name: capitalize(table.name) + fieldInfo,
          })
        }
      }
    }
  }

  return relations
}

export const getManyToManyRelations = (datasource, tableId) => {
  // Iterate over columns of this table
  // Add inbound many-to-many relations

  const collections = datasource.tables || datasource.collections
  const table = collections[tableId]
  let relations = []

  for (const fieldId of Object.keys(table.fields)) {
    const field = table.fields[fieldId]

    if (field.type?.type === 'manyToMany') {
      relations.push({
        fieldId,
        tableId: field.type.tableId,
        name: field.name,
      })
    }
  }

  relations = relations.concat(
    getInboundManyToManyRelations(datasource, tableId)
  )

  return relations
}

export const getRelations = (datasource, source1, source2) => {
  // Source 1 & source 2 are single-level source objects
  // We want to return all many-to-many relations that exist between them

  if (!datasource || !source1 || !source2) {
    return []
  }

  const { tables } = datasource

  if (!(source1.tableId in tables) || !(source1.tableId in tables)) {
    return []
  }

  let relations = getManyToManyRelations(datasource, source1.tableId)
  relations = relations.filter(r => r.tableId === source2.tableId)

  return relations
}

// Adds a reciprocal field to the DB.
// If no fields are changed, returns an empty object.
export const createReciprocal = (
  datasource,
  datasourceId,
  field,
  fieldId,
  tableId
) => {
  const { tables } = datasource
  const targetTableId = field.type.tableId
  const originTable = datasource.tables[tableId]

  // If the relationship is self-referencing, no need for a reciprocal relationship
  if (targetTableId === tableId) {
    return datasource
  }

  const originalName = field.name
  let fieldInfo = ''
  let newDatasource = datasource

  if (
    !tableNamesEqual(originalName, datasource.tables[field.type.tableId].name)
  ) {
    fieldInfo = ` (${originalName})`
  }

  const originRelation = {
    fieldId,
    tableId,
    name: capitalize(originTable.name) + fieldInfo,
  }

  const reciprocalField = buildReciprocal({
    originRelation,
    tableId,
    fieldId,
    tables,
    datasourceId,
  })

  const oldFields = datasource.tables[targetTableId].fields

  const newFields = {
    ...oldFields,
    [fieldId]: reciprocalField,
  }

  if (JSON.stringify(oldFields) !== JSON.stringify(newFields)) {
    newDatasource = update(datasource, {
      tables: {
        [targetTableId]: {
          fields: {
            $set: newFields,
          },
        },
      },
    })
  }

  return newDatasource
}

// Cycles through all of the fields in a DB structure, and adds any non-existing
// reciprocal fields to the DB structure.
// If no fields are changed, returns an empty object.
export const createReciprocals = (datasource, datasourceId) => {
  let newDatasource = datasource

  if (datasource.type !== 'api' && datasource.tables) {
    const tableIds = Object.keys(datasource.tables)

    for (const tableId of tableIds) {
      // grab our established relationships
      const hasMany = getHasManyRelations(datasource, tableId)

      const manyToMany = getInboundManyToManyRelations(datasource, tableId)

      // * array containing all existing relationships
      const relationships = [...hasMany, ...manyToMany]

      const reciprocalFields = {}

      if (relationships.length) {
        // * tableId is the target location for reciprocal (cloned) item
        for (const r in relationships) {
          if (r) {
            const { tables } = datasource
            const origin = relationships[r]
            const { fieldId, tableId } = origin

            reciprocalFields[fieldId] = buildReciprocal({
              originRelation: origin,
              tableId,
              fieldId,
              tables,
              datasourceId,
            })
          }
        }
      }

      const oldFields = datasource.tables[tableId].fields

      const newFields = {
        ...reciprocalFields,
        ...oldFields,
      }

      if (JSON.stringify(oldFields) !== JSON.stringify(newFields)) {
        newDatasource = update(datasource, {
          tables: {
            [tableId]: {
              fields: {
                $set: newFields,
              },
            },
          },
        })
      }
    }
  }

  return newDatasource
}

const buildReciprocal = ({
  originRelation,
  tables,
  fieldId,
  tableId,
  datasourceId,
}) => {
  let { name } = originRelation
  let { type } = tables[tableId].fields[fieldId].type

  name = pluralize(name)

  switch (type) {
    case 'belongsTo':
      type = 'hasMany'

      break
    case 'manyToMany':
      type = 'manyToManyReverse'

      break
    default:
  }

  return {
    name,
    type: { type, datasourceId, tableId },
    reciprocal: originRelation,
  }
}
