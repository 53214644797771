import { GROUP, DeviceType } from '@adalo/constants'
import {
  insert,
  remove,
  getGroupPath,
  getObject,
  remapSiblings,
  removeChildren,
  pathLength,
  sortPaths,
  getId,
  getDeviceType,
} from '@adalo/utils'
import { hasDevicePosition } from 'utils/positioning'
import getSharedObject from 'utils/operations/getSharedObject'
import updateBounds from 'utils/operations/updateBounds'
import resizeParent from 'utils/operations/shouldResizeParent'
import calculatePushGraphs from 'ducks/editor/pushing/calculatePushGraphs'
import createEmptyObject from 'ducks/editor/objects/helpers/createEmptyObject'
import { DEVICE_TYPES } from 'ducks/editor/positioning'
import { EditorObject, SharedObject } from 'utils/responsiveTypes'
import InstructionState from '../types/InstructionState'
import { hasDeviceLayoutEnabled } from '../device-layouts/utils'
import { enableDeviceSpecificLayoutHandler } from './enableDeviceSpecificLayout'
import { toggleFixedPositionHandler } from './toggleFixedPosition'
import usesAbsolutePosition from '../objects/helpers/usesAbsolutePosition'
import { ObjectPathMap } from '../types/ObjectPathMap'
import {
  refreshLeftRightForObjectChildren,
  refreshLeftRightForObjects,
} from './refreshLeftRight'
import getObjectNew from '../objects/helpers/getObject'

export interface GroupObjectsOptions {
  objectIds: string[]
}

export interface GroupObjectsInstruction {
  operation: 'groupObjects'
  options: GroupObjectsOptions
}

export const groupObjectsHandler = (
  state: InstructionState,
  { objectIds }: GroupObjectsOptions
): InstructionState => {
  const { list, pathMap, featureFlags } = state

  if (objectIds.length === 0) {
    return state
  }

  let paths = objectIds
    .map(id => pathMap[id])
    .filter(path => pathLength(path) > 1)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  paths = removeChildren(sortPaths(paths))

  const hasMinMaxWidth = featureFlags?.['hasMinMaxWidth'] === true

  const skeletonObj: EditorObject = {
    id: getId() as string,
    type: GROUP,
    children: [],
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    positioning: null,
  }
  const group = createEmptyObject(
    list,
    pathMap,
    skeletonObj,
    true,
    false,
    hasMinMaxWidth
  )

  const groupPath = String(getGroupPath(paths))

  if (!groupPath) {
    return state
  }

  // turn off fixed-positioning for all prospective children
  let updatedList = [...list]

  const pieces = groupPath.split('.')
  const screenIndex = pieces[0]

  if (!screenIndex) {
    throw new Error('No screenIndex available')
  }

  const screen = updatedList[Number(screenIndex)]

  if (!screen) {
    throw new Error('No screen available')
  }

  group.initialDevice = getDeviceType(screen.width)

  for (const objectId of objectIds) {
    const object = getObject(updatedList, pathMap[objectId]) as EditorObject

    if (!usesAbsolutePosition(object)) {
      continue
    }

    ;({ list: updatedList } = toggleFixedPositionHandler(
      {
        ...state,
        list: updatedList,
      },
      { objectId, isFixed: false }
    ))
  }

  const childDevicesToInherit: Set<keyof SharedObject> = new Set()
  const childHasDeviceSpecificLayout: Set<keyof SharedObject> = new Set()

  group.children = []
  for (const path of paths) {
    const object = getObject(updatedList, path) as EditorObject

    for (const device of Object.values(DeviceType)) {
      if (hasDeviceLayoutEnabled(object, device)) {
        childDevicesToInherit.add(device)
      }

      if (hasDevicePosition(object, device)) {
        childHasDeviceSpecificLayout.add(device)
      }
    }

    group.children.push(object)
  }

  const pathsReversed = [...paths].reverse()
  for (const path of pathsReversed) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    updatedList = remove(updatedList, path)
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  updatedList = insert(updatedList, groupPath, group)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const updatedPathMap = remapSiblings(
    updatedList,
    pathMap,
    groupPath
  ) as ObjectPathMap

  const sharedObject = getSharedObject(
    getObject(updatedList, groupPath) as EditorObject
  )

  for (const device of DEVICE_TYPES) {
    if (
      !sharedObject[device as keyof SharedObject] ||
      childHasDeviceSpecificLayout.has(device as keyof SharedObject)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      updatedList = updateBounds(
        updatedList,
        updatedPathMap,
        groupPath,
        resizeParent,
        device,
        true
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  updatedList = updateBounds(
    updatedList,
    updatedPathMap,
    groupPath,
    resizeParent,
    undefined,
    true
  )

  updatedList = calculatePushGraphs(updatedList, updatedPathMap, screen.id)

  const updatedGroup = getObjectNew(updatedList, updatedPathMap, group.id)

  for (const device of childDevicesToInherit) {
    if (updatedGroup.children && Array.isArray(updatedGroup.children)) {
      for (const child of updatedGroup.children) {
        ;({ list: updatedList } = enableDeviceSpecificLayoutHandler(
          {
            ...state,
            pathMap: updatedPathMap,
            list: updatedList,
          },
          { objectId: child.id, device }
        ))
      }
    }

    const parentInstructionState = {
      ...state,
      pathMap: updatedPathMap,
      list: updatedList,
    }
    const parentInstructionOptions = { objectId: group.id, device }
    ;({ list: updatedList } = enableDeviceSpecificLayoutHandler(
      parentInstructionState,
      parentInstructionOptions
    ))
  }

  updatedList = refreshLeftRightForObjects(updatedList, updatedPathMap, [
    group.id,
  ])

  updatedList = refreshLeftRightForObjectChildren(
    updatedList,
    updatedPathMap,
    group.id
  )

  return {
    list: updatedList,
    pathMap: updatedPathMap,
    selection: [group.id],
  }
}

const groupObjects = (objectIds: string[]): GroupObjectsInstruction => {
  return {
    operation: 'groupObjects',
    options: {
      objectIds,
    },
  }
}

export default groupObjects
