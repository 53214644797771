import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { SubmissionError } from 'redux-form'
import classNames from 'classnames'

import SeatAddonModal from 'components/Settings/Team/SeatAddonModal'
import UpgradeAlertModal from 'components/Shared/UpgradeAlertModal'

import { createInvite, deleteInvite, removeUser } from 'ducks/organizations'

import { INVITE_LIMIT_MESSAGE } from '../../../constants'

import './Team.css'

const MemberItem = ({ email, name, isInvite, isCurrentUser, onDelete }) => {
  const containerClassName = ['settings-list-item']
  let memberName = name
  let memberEmail = email
  let actionText = 'Remove'

  if (isInvite) {
    memberName = email
    memberEmail = 'Invited'
    containerClassName.push('team-members-invite')
    actionText = 'Cancel Invitation'
  }

  return (
    <div className={classNames(containerClassName)}>
      <div className="team-members-member-details">
        <h4>{memberName}</h4>
        <p>{memberEmail}</p>
      </div>
      <div className="team-members-member-action">
        <ActionMessage
          isCurrentUser={isCurrentUser}
          onDelete={onDelete}
          actionText={actionText}
        />
      </div>
    </div>
  )
}

const ActionMessage = ({ isCurrentUser, actionText, onDelete }) => {
  if (isCurrentUser) {
    return 'You'
  }

  if (!onDelete) {
    return null
  }

  return (
    <div>
      <a onClick={onDelete} className="deemphasize">
        {actionText}
      </a>
    </div>
  )
}

const Members = ({
  organization,
  currentUserId,
  refreshData,
  userIsAdmin,
  seatLimit,
  currentUsers,
}) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [showSeatLimitModal, setShowSeatLimitModal] = useState(false)
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false)

  const inviteUser = async email => {
    try {
      await dispatch(
        createInvite({
          email,
          organizationId: organization.id,
        })
      )
    } catch (err) {
      const status = err.response && err.response.status
      const data = err.response && err.response.data

      let message = (data && data.error) || 'An unknown error occurred'

      if (status === 429) {
        message = INVITE_LIMIT_MESSAGE
      }

      throw new SubmissionError({
        email: message,
      })
    }

    refreshData(organization.id)
  }

  const handleRemoveUser = userId => async () => {
    const members = organization.Users
    const user = members.filter(u => u.id === userId)[0]
    const { name } = user

    if (!window.confirm(`Are you sure you want to remove ${name}?`)) {
      return
    }

    await dispatch(removeUser({ userId, organizationId: organization.id }))

    refreshData(organization.id)
  }

  const handleDeleteInvite = inviteId => async () => {
    if (!window.confirm(`Are you sure you want to remove this invitation?`)) {
      return
    }

    await dispatch(deleteInvite(inviteId))

    refreshData(organization.id)
  }

  if (!currentUserId) {
    return null
  }

  const members = useMemo(
    () => organization.Users,
    [organization?.Users?.length]
  )

  const invites = useMemo(
    () => organization.Invitations,
    [organization?.Invitations?.length]
  )

  return (
    <>
      <div className="team-members-form">
        <div className="team-members-members">
          {members.map(member => (
            <MemberItem
              key={member.id}
              name={member.name}
              email={member.email}
              isCurrentUser={member.id === currentUserId}
              onDelete={userIsAdmin ? handleRemoveUser(member.id) : null}
            />
          ))}
          {invites.map(invite => (
            <MemberItem
              isInvite
              key={invite.id}
              email={invite.email}
              onDelete={userIsAdmin ? handleDeleteInvite(invite.id) : null}
            />
          ))}
        </div>
      </div>
      <UpgradeAlertModal
        action="Add Editors"
        body="Switch to a paid plan to start collaborating on your app design."
        onClose={() => {
          setShowUpgradePlanModal(false)
          setEmail('')
        }}
        show={showUpgradePlanModal}
      />
      <SeatAddonModal
        callback={async () => {
          await inviteUser(email)
          setShowSeatLimitModal(false)
          setEmail('')
        }}
        onClose={() => {
          setShowSeatLimitModal(false)
          setEmail('')
        }}
        show={showSeatLimitModal}
      />
    </>
  )
}

export default Members
