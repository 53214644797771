import React, { Component } from 'react'
import classNames from 'classnames'
import './ToggleButton.scss'

export default class ToggleButton extends Component {
  handleChange = () => {
    const { disabled, input, onChange } = this.props
    const value = this.getValue()

    if (disabled) return null

    const newValue = !value

    if (input?.onChange && !onChange) {
      return input.onChange(newValue)
    }

    return onChange(newValue)
  }

  getValue() {
    const { input, value } = this.props

    if (input?.value && !value) {
      return input.value
    }

    return value
  }

  render() {
    const { disabled, color, inverted, label, wrapped = false } = this.props
    let value = this.getValue()

    if (inverted) {
      value = !value
    }

    return (
      <div className="toggle">
        {label ? <label onClick={this.handleChange}>{label}</label> : null}
        <div
          className={classNames('toggle-button', {
            'toggle-button-enabled': value,
            'toggle-button-disabled': disabled,
            [`toggle-button-${color}`]: color,
            'toggle-button-wrapped': wrapped,
          })}
          onClick={this.handleChange}
        >
          <div className="toggle-button-track" />
          <div className="toggle-button-ball" />
        </div>
      </div>
    )
  }
}
