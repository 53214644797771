import { getObject, subPath, update } from '@adalo/utils'
import { updateOptions } from 'utils/groupTypes'
import { EditorObject } from 'utils/responsiveTypes'
import updateParentBounds from 'utils/operations/updateParentBounds'
import resizeParent from 'utils/operations/shouldResizeParent'
import type { ObjectPathMap } from '../types/ObjectPathMap'
import type { ObjectList } from '../types/ObjectList'
import type DeviceType from '../types/DeviceType'
import calculatePushGraphs from '../pushing/calculatePushGraphs'

/**
 * Updates the object in the object list as well as updating the parent state.
 */
const updateChangedObject = (
  objects: ObjectList,
  pathMap: ObjectPathMap,
  newObject: EditorObject,
  device: DeviceType | undefined
): ObjectList => {
  const { id: objectId } = newObject
  const path = pathMap[objectId]

  let newList: ObjectList = objects
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars
  newList = update(newList, path, newObject)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  newList = updateParentBounds(
    newList,
    pathMap,
    objectId,
    null,
    resizeParent,
    device,
    true
  )
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  newList = updateOptions(newList, pathMap, objectId)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const screen = getObject(newList, subPath(path, 1))

  if (!screen) {
    throw new Error('Could not determine screen for update push graphs')
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  newList = calculatePushGraphs(newList, pathMap, screen.id)

  return newList
}

export default updateChangedObject
