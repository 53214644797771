import qs from 'qs'

import { PREVIEW_URL } from 'constants.js'
import { App } from 'ducks/apps'
import { getAuthToken } from 'utils/auth'

import { Domain } from '.'

const BASE_URL = process.env['REACT_APP_WEB_BASE_URL'] as string

const getOrgBaseUrl = (app?: App) => {
  return `${app?.Organization?.subdomain ?? ''}.${BASE_URL}/`
}

type GetAppHostOptions = {
  app: App
  domainId?: string | undefined | null
  domains?: Domain[] | undefined
  useAppDomain?: boolean
}

export const getAppHost = (options: GetAppHostOptions): string | null => {
  const { app, domainId, domains, useAppDomain } = options

  if (!domains || !app) {
    return null
  }

  const targetDomainId = useAppDomain ? app?.DomainId : domainId

  const domain = domains.find(d => d.id === targetDomainId)

  const isLegacyMobileApp = app?.primaryPlatform === 'mobile'

  if (isLegacyMobileApp && !domain) {
    return `${PREVIEW_URL}/${app.id}`
  }

  if (!domain) {
    return getOrgBaseUrl(app)
  }

  return `${domain.domain ?? ''}/`
}

type GetPublishUrlOptions = {
  app: App
  domainId?: string | undefined
  domains?: Domain[] | undefined
}

export const getPublishUrl = (
  options: GetPublishUrlOptions
): string | undefined => {
  const { app } = options
  const domainId = options.domainId ?? app?.DomainId
  const domains = options.domains ?? []

  const url = getAppHost({ app, useAppDomain: true, domainId, domains })

  const protocol = window.location.protocol

  if (!url) {
    return undefined
  }

  const protocolRegex = new RegExp('^https?://')

  if (protocolRegex.test(url)) {
    return url
  }

  const publishUrl = `${protocol}//${url}${app?.path ?? ''}`

  return publishUrl
}

type GetPreviewUrlOptions = {
  app: App
  isDeveloper?: boolean
}
export const getPreviewUrl = (
  options: GetPreviewUrlOptions
): string | undefined => {
  const { app, isDeveloper } = options

  if (!app) {
    return undefined
  }

  const isLegacyMobileApp = app?.primaryPlatform === 'mobile'

  if (isLegacyMobileApp) {
    return `/apps/${app.id}/screens/preview`
  }

  let url = `${PREVIEW_URL}/${app.id}`

  if (isDeveloper) {
    const sessionToken = getAuthToken()
    const params = { sessionToken }

    url = `${url}?${qs.stringify(params)}`
  }

  return url
}
