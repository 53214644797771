import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { fetchOrganizations, getOrganizations } from 'ducks/organizations'

import Modal from 'components/Shared/Modal'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'

import './CloneApp.css'

class OrganizationSelect extends Component {
  componentDidMount() {
    const { fetchOrganizations } = this.props

    fetchOrganizations()
  }

  getOptions() {
    const { organizations, handleOrganizationsLoaded } = this.props

    if (organizations.length > 0) {
      handleOrganizationsLoaded()
    }

    return Object.values(organizations).map(org => ({
      label: org.name,
      value: org.id,
    }))
  }

  render() {
    const options = this.getOptions()

    if (options.length <= 1) {
      return null
    }

    return <WrappedSelect {...this.props} options={options} />
  }
}

const mapStateToProps = state => ({
  organizations: getOrganizations(state),
})

const ConnectedOrgSelect = connect(mapStateToProps, { fetchOrganizations })(
  OrganizationSelect
)

class CloneAppForm extends Component {
  state = {
    disabled: true,
  }

  handleOrganizationsLoaded = () => {
    setTimeout(() => {
      this.setState({ disabled: false })
    }, 400)
  }

  render() {
    const { handleSubmit, loading, errorCreatingApp } = this.props
    const { disabled } = this.state

    return (
      <form onSubmit={handleSubmit}>
        <Modal.Header title="Name Your App" />
        <Modal.Content>
          <Field
            autoSelect
            name="name"
            label="Name"
            component={WrappedInput}
            placeholder="My App"
          />
          <Field
            name="organizationId"
            component={ConnectedOrgSelect}
            handleOrganizationsLoaded={this.handleOrganizationsLoaded}
          />
          <Modal.Error
            error={errorCreatingApp}
            errorMessages={[
              'An unexpected error occoured while trying to clone the app.',
              'Please try again later.',
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button
            type="submit"
            loading={loading}
            disabled={disabled}
            data-adalo-id="clone-app-submit"
          >
            Go
          </Modal.Button>
        </Modal.Actions>
      </form>
    )
  }
}

export default reduxForm({ form: 'copyDatabaseForm' })(CloneAppForm)
