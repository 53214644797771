import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { useFlags } from 'flags'

import SettingsFormField from 'components/Shared/Forms/SettingsFormField'

import { FORM_NAME, validations } from './shared'

const fakeUploadFunction = dataUrl => {
  // strip the dataURL prefix. Keep the base64 file content
  return dataUrl.replace('data:', '').replace(/^.+,/, '')
}

const getLegacyCredentialFields = publishing => (
  <>
    <Field
      component={SettingsFormField}
      name="appleUsername"
      label="Apple ID Email"
      type="email"
      placeholder="me@example.com"
      validate={publishing && validations.email}
    />
    <Field
      component={SettingsFormField}
      name="applePassword"
      label="Apple ID Password"
      type="password"
      placeholder="••••••••"
      autoComplete="new-password"
      validate={publishing && validations.password}
    />
    <Field
      component={SettingsFormField}
      name="appSpecificPassword"
      label={
        <span>
          App-specific Password -{' '}
          <a
            href="https://appleid.apple.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Create One Here
          </a>
        </span>
      }
      type="password"
      placeholder="••••••••"
      autoComplete="new-password"
      validate={publishing && validations.appSpecificPassword}
    />
    <p className="form-copy-body">
      The app-specific password will let Adalo use your Apple ID account to
      upload your app to TestFlight.{' '}
      <a
        href="https://help.adalo.com/testing-your-app/publishing-to-the-apple-app-store/create-your-adalo-app-specific-password"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn More
      </a>
    </p>
  </>
)

const getAppStoreConnectCredentialFields = publishing => {
  return (
    <>
      <Field
        component={SettingsFormField}
        name="appleIssuerId"
        label="Apple Issuer ID"
        type="text"
        placeholder="f32a5e86-9c23-4c87-9a3c-a00d7d37afd3"
        validate={publishing && validations.appleIssuerId}
      />
      <Field
        component={SettingsFormField}
        name="appleApiKeyId"
        label="Apple API Key ID"
        type="text"
        placeholder="8PKA3V1C34"
        autoComplete="off"
        validate={publishing && validations.apiKeyId}
      />
      <Field
        component={SettingsFormField}
        name="appleApiKey"
        label="Apple API Key (p8 file)"
        type="file"
        // no mime type for this type of file
        acceptedMimeTypes={['']}
        // we are not uploading the file but getting the raw base64 bytes
        uploadFunc={fakeUploadFunction}
        filenameFunc={() => 'AppleApiKey'}
        uploadURLFunc={a => null}
        validate={publishing && validations.apiKey}
      />
    </>
  )
}

const AppleCredentialsForm = ({ handleSubmit, publishing }) => {
  const { hasBuildsWithCodemagic } = useFlags()

  return (
    <form onSubmit={handleSubmit} id={FORM_NAME} autoComplete="off">
      <p className="form-copy-header">First Time Publishing an iOS App?</p>
      <p className="form-copy-body">
        Here's our helpful{' '}
        <a
          href="https://help.adalo.com/testing-your-app/publishing-to-the-apple-app-store"
          target="_blank"
          rel="noopener noreferrer"
        >
          step by step guide!
        </a>
      </p>
      <input style={{ opacity: 0, position: 'absolute' }} />
      <Field
        component={SettingsFormField}
        name="bundleId"
        label="Bundle ID"
        type="text"
        placeholder="com.your-company.your-app"
        validate={publishing && validations.bundleId}
        tooltip={
          <>
            Need help creating your own Bundle ID? Check out our help doc on how
            to{' '}
            <a
              href="https://help.adalo.com/testing-your-app/publishing-to-the-apple-app-store/create-your-apple-bundle-id"
              target="_blank"
              rel="noopener noreferrer"
            >
              create your Apple Bundle ID
            </a>
          </>
        }
      />
      {hasBuildsWithCodemagic
        ? getAppStoreConnectCredentialFields(publishing)
        : getLegacyCredentialFields(publishing)}
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AppleCredentialsForm)
