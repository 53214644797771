import { useDispatch, useSelector } from 'react-redux'
import { DeviceWidth } from '@adalo/constants'
import { resizeObject, getParentScreenComponent } from 'ducks/editor/objects'
import DeviceType from 'ducks/editor/types/DeviceType'
import { EditorObject } from 'utils/responsiveTypes'

const WidthForDevice: { [key in DeviceType]: number } = {
  mobile: DeviceWidth.MOBILE_DEFAULT_WIDTH,
  tablet: DeviceWidth.TABLET_DEFAULT_WIDTH,
  desktop: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
}

/*
 * For a given objectId, get a callback to change the device of it's parent screen
 */
const useSetScreenDevice = (
  objectId: string
): ((device: DeviceType) => void) => {
  const dispatch = useDispatch()

  const screen = useSelector(
    state => getParentScreenComponent(state, objectId) as EditorObject
  )

  const setScreenDevice = (device: DeviceType) => {
    const screenDeviceWidth = WidthForDevice[device]
    dispatch(resizeObject(screen.id, { width: screenDeviceWidth }))
  }

  return setScreenDevice
}

export default useSetScreenDevice
