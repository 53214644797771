import { marketplaceAxios } from 'utils/io/http/axios'

const SET_LIBRARIES = 'DEVELOPER_LIBRARIES/SET_LIBRARIES'
const SET_TESTING_ORG = 'DEVELOPER_LIBRARIES/SET_TESTING_ORG'

// Reducer
export default (state, action) => {
  if (action.type === `${SET_LIBRARIES}_FULFILLED`) {
    const { data } = action.payload || {}

    return {
      ...state,
      developerLibraries: {
        ...state.developerLibraries,
        libraries: data,
        status: 'loaded',
      },
    }
  } else if (action.type === `${SET_TESTING_ORG}_FULFILLED`) {
    const { data } = action.payload || {}

    return {
      ...state,
      developerLibraries: {
        ...state.developerLibraries,
        testingOrg: data.testingOrg,
        status: 'loaded',
      },
    }
  } else if (action.type === `${SET_LIBRARIES}_ERROR`) {
    return {
      ...state,
      developerLibraries: {
        ...state.developerLibraries,
        error: action.payload,
      },
    }
  } else if (action.type === `${SET_LIBRARIES}_PENDING`) {
    return {
      ...state,
      developerLibraries: {
        ...state.developerLibraries,
        status: 'loading',
      },
    }
  } else {
    return state
  }
}

// Actions
export const fetchDeveloperLibraries = userId => {
  const url = `/api/users/${userId}/libraries`

  return {
    type: SET_LIBRARIES,
    payload: marketplaceAxios.get(url),
  }
}

export const fetchTestingOrg = userId => {
  const url = `/api/users/${userId}/testingOrg`

  return {
    type: SET_TESTING_ORG,
    payload: marketplaceAxios.get(url),
  }
}

export const setTestingOrg = (userId, orgId) => {
  const url = `/api/users/${userId}/testingOrg`

  return {
    type: SET_TESTING_ORG,
    payload: marketplaceAxios.put(url, { orgId }),
  }
}

// Selectors
export const getDeveloperLibraries = state => {
  const { developerLibraries } = state.marketplace

  return developerLibraries.libraries || []
}

export const getTestingOrg = state => {
  const { developerLibraries } = state.marketplace

  return developerLibraries.testingOrg
}

export const getDeveloperLibrariesStatus = state => {
  const { status, error } = state.marketplace.developerLibraries

  return { status, error }
}
