import Button from 'components/Shared/Button'
import ProgressBar from 'components/Shared/ProgressBar'
import './Onboarding.scss'

type Props = {
  progressBarPosition: number
  nextLoading?: boolean
  previousLoading?: boolean
  showPrevious?: boolean
  showNext?: boolean
  previousEnabled?: boolean
  nextEnabled?: boolean
  onNext?: () => void
  onPrevious?: () => void
}

const FooterNav: React.FC<Props> = props => {
  const {
    showPrevious,
    showNext,
    previousEnabled,
    nextEnabled,
    onNext,
    onPrevious,
    nextLoading,
    previousLoading,
    progressBarPosition,
  } = props

  return (
    <div className="onboarding-footer">
      <div className="progress-bar-container">
        <ProgressBar position={progressBarPosition} />
      </div>
      <div className="nav-buttons">
        {showPrevious && (
          <Button
            text
            onClick={onPrevious}
            disabled={!previousEnabled}
            loading={previousLoading}
          >
            BACK
          </Button>
        )}
        {showNext && (
          <Button
            teal
            gradient
            onClick={onNext}
            disabled={!nextEnabled}
            loading={nextLoading}
            data-adalo-id="onboarding-user-continue"
          >
            CONTINUE
          </Button>
        )}
      </div>
    </div>
  )
}

export default FooterNav
