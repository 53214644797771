import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from 'components/Shared/Button'

// ducks
import { getApp } from 'ducks/apps'
import { getOrganization } from 'ducks/organizations'

import Accordion from './Accordion'
import './ComponentsAccordion.scss'

export class ComponentsAccordionGroup extends Component {
  static propTypes = {
    accordionClassName: PropTypes.string,
    renderItem: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
      })
    ).isRequired,
    emptyCallout: PropTypes.element,
  }

  render() {
    const {
      accordionClassName,
      categories,
      defaultOpen,
      openAll,
      renderItem,
      emptyCallout,
    } = this.props

    return (
      <Accordion.Group defaultOpen={defaultOpen} openAll={openAll}>
        {categories.map(category => (
          <ComponentsAccordion
            className={accordionClassName}
            key={category.id || category.title}
            title={category.title}
            options={category.options}
            renderItem={renderItem}
            emptyCallout={emptyCallout}
          />
        ))}
      </Accordion.Group>
    )
  }
}

class ComponentsAccordion extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    renderItem: PropTypes.func.isRequired,
    emptyCallout: PropTypes.element,
  }

  renderChildren = () => {
    let {
      className,
      options,
      renderItem,
      emptyCallout,
      maxItems,
      showMore,
      title,
    } = this.props

    if (className.includes('editor-add-panel-accordion')) {
      className = className.replace('editor-add-panel-accordion', '')
    }

    const showButton = maxItems && options.length > maxItems && showMore
    let renderedOptions = options

    if (maxItems && options.length > 0) {
      renderedOptions = options.slice(0, maxItems)
    }

    return (
      <div className={classNames('components-accordion-children', className)}>
        {options.length > 0
          ? renderedOptions.map(renderItem)
          : emptyCallout || null}
        {showButton && (
          <div className="expand-button">
            <Button onClick={() => showMore(title)}>Show more</Button>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { id, className, title, options } = this.props

    return (
      <Accordion
        className={className}
        id={id || title}
        title={
          <>
            <div className="components-accordion-title-inner">{title}</div>
            <div className="components-count">{options?.length || 0}</div>
          </>
        }
        renderChildren={this.renderChildren}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { appId } = match.params
  const app = getApp(state, appId)
  const org = getOrganization(state, app?.OrganizationId)

  return {
    app,
    appId,
    org,
  }
}

const connected = connect(mapStateToProps)(ComponentsAccordion)

export default withRouter(connected)
