import React, { useContext, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { marketplaceAxios } from 'utils/io/http/axios'

// ducks
import {
  getDeveloperLibraries,
  getDeveloperLibrariesStatus,
  getTestingOrg,
  setTestingOrg,
} from 'ducks/marketplace/developerLibraries'
import { getOrgsLicenses, fetchOrgsLicenses } from 'ducks/marketplace/licenses'

// components
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'
import Loading from 'components/Shared/Loading'

import { DevelopersContext } from '..'
import Library from '../Library'
import EmptyState from './EmptyState'

import './DeveloperLibraries.scss'

const DeveloperLibraries = () => {
  const dispatch = useDispatch()
  const { organizations, developer, user } = useContext(DevelopersContext)

  const orgIds = organizations.map(o => o.id)
  const licenses = useSelector(state => getOrgsLicenses(state, orgIds))
  const libraries = useSelector(getDeveloperLibraries)
  const { status, error } = useSelector(getDeveloperLibrariesStatus)

  const [stripeAccount, setStripeAccount] = useState({})

  const getStripeAccount = async () => {
    const { data } = await marketplaceAxios.get(
      `/api/stripe/developers/${user.id}/express_account`
    )

    if (typeof data.url === 'string') setStripeAccount(data)
  }

  useEffect(() => {
    getStripeAccount()
  }, [])

  useEffect(() => {
    if (organizations?.length) {
      dispatch(fetchOrgsLicenses(organizations.map(org => org.id)))
    }
  }, [organizations?.length])

  const hasExpressAccount = !!developer?.stripeAccountId
  const completedOnboarding = stripeAccount?.charges_enabled

  // libraries
  const [privateLibraries, setPrivateLibraries] = useState([])
  const [inReviewLibraries, setInReviewLibraries] = useState([])
  const [liveLibraries, setLiveLibraries] = useState([])
  const [unlistedLibraries, setUnlistedLibraries] = useState([])

  const handleLibraries = () => {
    if (libraries.length > 0) {
      const privateLibs = []
      const inReviewLibs = []
      const liveLibs = []
      const unlistedLibs = []

      for (const library of libraries) {
        const versions = library.Versions.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )

        if (library.shared) {
          continue
        }

        if (!library.public) {
          // library is a private library
          privateLibs.push(library)
        } else if (library.listed) {
          // library is currently listed in the marketplace
          const inReview = versions.find(v => v.submitted)
          const active = versions.find(v => v.active)

          if (active) {
            liveLibs.push({ ...library, target: active.version })
          }

          if (inReview) {
            inReviewLibs.push({ ...library, target: inReview.version })
          }
        } else {
          // library is currently NOT listed in the marketplace
          const inReview = versions.find(v => v.submitted)

          if (inReview) {
            // place library in "in review" if there is a version that is "submitted"
            inReviewLibs.push({ ...library, target: inReview.version })
          } else {
            // place library in "unlisted" as there are no versions "in review"
            unlistedLibs.push(library)
          }
        }
      }

      // update state
      setPrivateLibraries(privateLibs)
      setInReviewLibraries(inReviewLibs)
      setLiveLibraries(liveLibs)
      setUnlistedLibraries(unlistedLibs)
    }
  }

  useEffect(() => {
    handleLibraries()
  }, [libraries.length])

  if (['loading', 'idle'].includes(status) || !stripeAccount.url) {
    return (
      <div className="new-developer-libraries">
        <Loading />
      </div>
    )
  }

  if (status === 'error') {
    return (
      <div className="new-developer-libraries">
        <p style={{ color: 'red' }}>{error.response.data.message}</p>
      </div>
    )
  }

  const noPersonalLibraries =
    !liveLibraries.length &&
    !privateLibraries.length &&
    !unlistedLibraries.length &&
    !inReviewLibraries.length

  if (!libraries || libraries.length === 0 || noPersonalLibraries) {
    return (
      <div className="new-developer-libraries">
        <section>
          <EmptyState
            title="Create Your First Library"
            subtext="Get started at [developers.adalo.com](https://developers.adalo.com)"
          />

          {hasExpressAccount && completedOnboarding ? (
            <span className="new-developer-libraries-express-links">
              <a href={stripeAccount.url} target="_blank" rel="noreferrer">
                Stripe Dashboard <Icon type="launch" color="teal" small />
              </a>
            </span>
          ) : (
            <span className="new-developer-libraries-express-links">
              <Icon color="teal" type="dollar" iconStyle="circle" small />{' '}
              <a href={stripeAccount.url}>Create a Stripe Express Account</a> to
              start selling premium components!
            </span>
          )}
        </section>
      </div>
    )
  }

  return (
    <div className="new-developer-libraries">
      <TestingOrgSelector
        label="Set Testing Team"
        placeholder="Select Testing Team..."
      />

      <section>
        <h2>
          Marketplace
          {hasExpressAccount && completedOnboarding ? (
            <a
              className="new-developer-libraries-express-links"
              href={stripeAccount.url}
              target="_blank"
              rel="noreferrer"
            >
              Stripe Dashboard <Icon type="launch" color="teal" small />
            </a>
          ) : null}
        </h2>

        {!inReviewLibraries.length &&
        !liveLibraries.length &&
        !unlistedLibraries.length ? (
          <EmptyState title="No Public Libraries" />
        ) : (
          <>
            <LibrariesList
              disableEmptyState
              label="In Review"
              libraries={inReviewLibraries}
              organizations={organizations}
              orgLicenses={licenses}
            />

            <LibrariesList
              disableEmptyState
              label="Live"
              libraries={liveLibraries}
              organizations={organizations}
              orgLicenses={licenses}
            />

            <LibrariesList
              disableEmptyState
              label="Unlisted"
              tooltip="Libraries that are currently NOT listed in the Adalo Marketplace."
              libraries={unlistedLibraries}
              organizations={organizations}
              orgLicenses={licenses}
            />
          </>
        )}

        {!hasExpressAccount || !completedOnboarding ? (
          <span className="new-developer-libraries-express-links">
            <Icon color="teal" type="dollar" iconStyle="circle" small />{' '}
            <a href={stripeAccount.url}>Create a Stripe Express Account</a> to
            start selling premium components!
          </span>
        ) : null}
      </section>

      <section>
        <h2>Private</h2>

        <LibrariesList
          emptyStateTitle="No Private Libraries"
          libraries={privateLibraries}
          organizations={organizations}
          orgLicenses={licenses}
        />
      </section>
    </div>
  )
}

export default DeveloperLibraries

const TestingOrgSelector = ({ className, label, placeholder }) => {
  const dispatch = useDispatch()

  const { user, organizations } = useContext(DevelopersContext)
  const testingOrg = useSelector(getTestingOrg)

  const handleChange = orgId => {
    dispatch(setTestingOrg(user.id, orgId))
  }

  const options = useMemo(
    () => organizations.map(org => ({ label: org.name, value: org.id })),
    [organizations.length]
  )

  return (
    <div className={classNames('testing-org-selector', className)}>
      <Label tooltip="Select the team that has access to your Public library releases that are in review. Everyone on this team can use unreleased components in the editor, in native app builds, and to create demo apps.">
        {label}
      </Label>
      <WrappedSelect
        options={options}
        value={testingOrg}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  )
}

const Label = ({ children, label, tooltip, icon = 'help-small', ...props }) => {
  return (
    <label {...props}>
      {children || label}
      {tooltip ? (
        <Tooltip placement="bottom" tooltip={tooltip}>
          <Icon type={icon} small />
        </Tooltip>
      ) : null}
    </label>
  )
}

const LibrariesList = ({
  label,
  libraries,
  organizations,
  orgLicenses,
  disableEmptyState = false,
  emptyStateTitle,
  tooltip,
}) => {
  if (disableEmptyState && (!libraries || libraries.length === 0)) return null

  const { app } = useContext(DevelopersContext)

  return (
    <div className="new-developer-libraries-list">
      {label ? <Label tooltip={tooltip}>{label}</Label> : null}

      {libraries.length > 0 ? (
        <div className="new-developer-libraries-list-items">
          {libraries.map(lib => (
            <Library
              app={app}
              key={lib.id}
              library={lib}
              organizations={organizations}
              orgLicenses={orgLicenses}
            />
          ))}
        </div>
      ) : (
        <EmptyState title={emptyStateTitle || `No ${label} Libraries`} />
      )}
    </div>
  )
}
