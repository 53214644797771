import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray, formValueSelector } from 'redux-form'
import deepEqual from 'deep-equal'
import { getId } from '@adalo/utils'

import {
  endpointTypeNames,
  endpointTypes,
  httpMethods,
  dataTypes,
} from '@adalo/constants'

import { getBasicAPIFieldTypes } from '../../../../utils/dataTypes'
import { httpMethodOptions } from '../../../../utils/urls'
import { getEndpointURL, getEndpointMethod } from '../../../../utils/apis'
import WrappedCheckbox from '../../../Shared/Forms/WrappedCheckbox'
import WrappedSelect from '../../../Shared/Forms/WrappedSelect'
import WrappedInput from '../../../Shared/Forms/WrappedInput'
import Icon from '../../../Shared/Icon'

export default class EndpointsSection extends Component {
  render() {
    const { formName } = this.props

    return (
      <div className="api-collection-endpoints">
        <h3>Endpoints</h3>
        <FieldArray
          name="endpoints"
          formName={formName}
          component={EndpointsSub}
        />
      </div>
    )
  }
}

class EndpointsSub extends Component {
  handleAdd = () => {
    const { fields } = this.props

    fields.push({ id: getId(), enabled: true, type: endpointTypes.CUSTOM })
  }

  handleRemove = i => () => {
    const { fields } = this.props

    fields.remove(i)
  }

  render() {
    const { fields, formName } = this.props

    return (
      <div className="api-collection-fields">
        <ul className="api-collection-form-fields-list">
          {fields.map(field => (
            <li key={field}>
              <WrappedEndpointItem
                fieldName={field}
                onRemove={this.handleRemove(field)}
                formName={formName}
              />
            </li>
          ))}
        </ul>
        <a onClick={this.handleAdd}>+ Add Endpoint</a>
      </div>
    )
  }
}

const returnTypeOptions = [
  { label: 'Returns List', value: true },
  { label: 'Returns One', value: false },
]

class EndpointItem extends Component {
  showCustomFields = () => {
    const { endpoint } = this.props

    return (
      endpoint.type === endpointTypes.CUSTOM &&
      endpoint.method !== httpMethods.GET
    )
  }

  render() {
    const { fieldName, onRemove, endpoint, baseURL } = this.props

    return (
      <div className="api-collection-endpoint-item">
        <div className="api-collection-endpoint-item-sub">
          <Field name={`${fieldName}.enabled`} component={WrappedCheckbox} />
          <div className="api-collection-endpoint-name">
            <div>
              {endpoint.type === endpointTypes.CUSTOM ? (
                <Field
                  name={`${fieldName}.name`}
                  component={WrappedInput}
                  type="text"
                  placeholder="Endpoint Nickname"
                />
              ) : (
                endpointTypeNames[endpoint.type]
              )}
            </div>
          </div>
          <div className="api-collection-endpoint-description">
            {endpoint.type === endpointTypes.CUSTOM ? (
              <div className="api-collection-endpoint-details">
                <Field
                  autoSelect
                  name={`${fieldName}.method`}
                  component={WrappedSelect}
                  options={httpMethodOptions}
                />
                <Field
                  component={WrappedInput}
                  name={`${fieldName}.url`}
                  placeholder={`/${baseURL}/custom-endpoint`}
                />
                <Field
                  autoSelect
                  name={`${fieldName}.isList`}
                  component={WrappedSelect}
                  options={returnTypeOptions}
                />
              </div>
            ) : (
              <div className="api-collection-endpoint-details">
                {`
                    ${getEndpointMethod(endpoint).toUpperCase()}
                    ${getEndpointURL({ baseURL }, endpoint)}
                  `}
              </div>
            )}
            {endpoint.type === endpointTypes.LIST ? (
              <Field
                name={`${fieldName}.collectionPath`}
                component={WrappedInput}
                placeholder="Collection Path (leave empty for none)"
                className="api-collection-endpoint-path"
              />
            ) : null}
          </div>
          <div className="delete-item">
            {endpoint.type === endpointTypes.CUSTOM ? (
              <Icon type="trash-small" onClick={onRemove} />
            ) : null}
          </div>
        </div>
        {this.showCustomFields() ? (
          <div className="api-collection-endpoint-item-params">
            <h2>Params</h2>
            <FieldArray
              component={EndpointParams}
              name={`${fieldName}.params`}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const wrappedEndpointMapState = (state, { fieldName, formName }) => {
  const selector = formValueSelector(formName)

  return {
    endpoint: selector(state, fieldName),
    baseURL: selector(state, 'baseURL'),
  }
}

const WrappedEndpointItem = connect(wrappedEndpointMapState)(EndpointItem)

class EndpointParams extends Component {
  handleAdd = () => {
    const { fields } = this.props

    fields.push({
      name: '',
      type: dataTypes.TEXT,
    })
  }

  handleRemove = i => () => {
    const { fields } = this.props

    fields.remove(i)
  }

  render() {
    const { fields } = this.props

    return (
      <div className="api-endpoint-params">
        <div className="api-endpoint-param-items">
          {fields.map((field, i) => (
            <div className="api-endpoint-param-item">
              <Field
                name={`${field}.id`}
                component={WrappedInput}
                placeholder="name"
              />
              <Field
                name={`${field}.type`}
                component={WrappedSelect}
                options={getBasicAPIFieldTypes()}
                comparator={deepEqual}
              />
              <Icon type="trash-small" onClick={this.handleRemove(i)} />
            </div>
          ))}
        </div>
        <a onClick={this.handleAdd}>+Add Param</a>
      </div>
    )
  }
}
