import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { fetchOrganizations, getOrganizations } from 'ducks/organizations'

import Alert from 'components/Shared/Alert'
import Modal from 'components/Shared/Modal'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import WrappedRadio from 'components/Shared/Forms/WrappedRadio'

import './CopyApp.scss'
import { useFlags } from 'flags'
import classNames from 'classnames'

const databaseOptions = [
  {
    label: 'No, Create A New Database (Collections Copied With No Records)',
    value: '1',
  },
  {
    label: 'Yes, Share The Same Database & Records',
    value: '0',
  },
]

class OrganizationSelect extends Component {
  componentDidMount() {
    const { fetchOrganizations } = this.props

    fetchOrganizations()
  }

  getOptions() {
    const { organizations } = this.props

    return Object.values(organizations).map(org => ({
      label: org.name,
      value: org.id,
    }))
  }

  render() {
    const options = this.getOptions()

    if (options.length <= 1) {
      return null
    }

    return <WrappedSelect {...this.props} options={options} />
  }
}

const mapStateToProps = state => ({
  organizations: getOrganizations(state),
})

const ConnectedOrgSelect = connect(mapStateToProps, { fetchOrganizations })(
  OrganizationSelect
)

const CopyAppForm = props => {
  const {
    onCancel,
    handleSubmit,
    isXanoApp,
    isResponsiveApp,
    primaryPlatform,
  } = props
  const { hasNewMobileOnlyApp } = useFlags()

  const [responsiveCopy, setResponsiveCopy] = useState(false)
  const [shareDatabase, setShareDatabase] = useState(false)
  const platformTitle = primaryPlatform === 'web' ? 'Web' : 'Mobile'

  const appOptions = [
    { label: `Legacy ${platformTitle} App (Current Type)`, value: 'legacy' },
    { label: 'Copy As New Responsive App', value: 'responsive' },
  ]

  if (primaryPlatform !== 'web') {
    appOptions.push({ label: 'Copy As New Mobile-Only App', value: 'mobile' })
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames({ 'copy-app-modal': hasNewMobileOnlyApp })}
    >
      <Modal.Header title="Copy App" />
      <Modal.Content>
        <Field
          autoSelect
          name="name"
          label="Name"
          component={WrappedInput}
          placeholder="My App Copy"
        />
        {!isResponsiveApp ? (
          <div className="responsive-copy">
            {hasNewMobileOnlyApp ? (
              <Field
                wrapped
                name="appType"
                label="App Type"
                component={WrappedSelect}
                options={appOptions}
                onChange={value => setResponsiveCopy(value === 'responsive')}
                tooltip={
                  <>
                    Learn more about the different app types in{' '}
                    <a
                      href="https://help.adalo.com/design/migrating-legacy-apps"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this help doc.
                    </a>
                  </>
                }
              />
            ) : (
              <Field
                wrapped
                component={ToggleButton}
                name="copyAsResponsive"
                label="Copy as a Responsive App"
                onChange={value => setResponsiveCopy(value)}
              />
            )}
            {responsiveCopy ? (
              <Alert color="yellow">
                You will need to adjust the settings in the Layout panel for
                each component in your app.
              </Alert>
            ) : null}
          </div>
        ) : null}
        {hasNewMobileOnlyApp ? (
          !isXanoApp && (
            <Field
              wrapped
              name="copyDatabase"
              label="Do you want the copied app to share the same database?"
              component={WrappedSelect}
              options={databaseOptions}
              value={shareDatabase}
              onChange={value => setShareDatabase(!Number(value))}
            />
          )
        ) : (
          <div className="copy-app-radios">
            {!isXanoApp && (
              <Field
                styled
                component={WrappedRadio}
                name="copyDatabase"
                radioValue="0"
                label="Share the same database"
                onChange={() => setShareDatabase(true)}
              />
            )}
            <Field
              styled
              component={WrappedRadio}
              name="copyDatabase"
              radioValue="1"
              label="Copy database"
              onChange={() => setShareDatabase(false)}
            />
          </div>
        )}
        {!shareDatabase && (
          <Field
            name="organizationId"
            label={hasNewMobileOnlyApp && 'Team'}
            component={ConnectedOrgSelect}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button type="button" text onClick={onCancel}>
          Cancel
        </Modal.Button>
        <Modal.Button type="submit">Copy</Modal.Button>
      </Modal.Actions>
    </form>
  )
}

export default reduxForm({ form: 'copyAppForm' })(CopyAppForm)
