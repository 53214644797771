import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { requestData as request } from 'utils/io'
import { buildSearchFilter } from 'utils/search'
import { getPrimaryField } from 'utils/includes'
import { capitalize } from 'utils/type'
import { singularize } from 'utils/strings'
import { uploadURL } from 'utils/uploads'

import { getData, getDataObject } from 'ducks/datasources'

import Select from './Select'

const ReferenceList = props => {
  const { input, field, tableId, id } = props
  const { name } = input

  const [, setValue] = useState()

  const data = useSelector(state => getData(state, field.type.tableId))
  const initialValue = useSelector(state => getDataObject(state, tableId, id))

  useEffect(() => {
    getValue()
  }, [])

  useEffect(() => {
    getOptions()
  }, [data])

  const getValue = () => {
    const { primaryField } = props
    let target = null

    if (initialValue) target = initialValue[`${name}_value`]
    if (!target) return null

    return setValue({ value: target.id, label: target[primaryField] })
  }

  const getTable = () => {
    const { datasource } = props

    return datasource.tables[field.type.tableId]
  }

  const getTableName = () => {
    const table = getTable()

    return table && capitalize(singularize(table.name))
  }

  const getLabel = item => {
    const table = getTable()
    const primaryField = getPrimaryField(table)

    if (!primaryField) return `${getTableName()} ${item.id}`
    let value = item[primaryField]

    if (value && typeof value === 'object') {
      value = (
        <div
          className="data-object-form-input-image"
          style={{
            backgroundImage: `url(${uploadURL(value.url)})`,
          }}
        />
      )
    }

    return value
  }

  const getOptions = () => {
    if (!data) return []

    const options = data.map(item => ({
      label: getLabel(item),
      value: item.id,
    }))

    return options
  }

  const requestData = (opts = {}) => {
    const { appId, datasourceId, tableId } = props
    const tableConfig = getTable()

    const queryParams = {}

    if (opts) {
      if (opts.offset) queryParams.offset = opts.offset

      if (opts.columnFilter) {
        queryParams.column_filter = opts.columnFilter
        if (queryParams.offset) delete queryParams.offset
      }
    }

    const options = { appId, datasourceId, tableId, tableConfig, queryParams }

    return request(options, true)
  }

  const searchData = async value => {
    try {
      const table = getTable()
      const columnFilter = buildSearchFilter(table, String(value))

      const primaryField = Object.keys(table.fields).filter(
        fieldId => table.fields[fieldId].isPrimaryField
      )[0]

      let result

      if (value && value.length > 0) {
        result = await requestData({
          columnFilter,
          skipDBAssistantInitialization: true,
        })
      } else {
        result = await requestData({ skipDBAssistantInitialization: true })
      }

      return result.map(itm => ({
        label: itm[primaryField] || 'Untitled',
        value: itm.id,
      }))
    } catch (err) {
      console.log('ERROR LOADING OPTIONS!!!!!', err)
    }
  }

  const handleChange = newValue => {
    const {
      input: { onChange },
    } = props

    onChange(newValue)
  }

  const loadOptions = value => searchData(value)
  const options = getOptions()

  const { fieldId, object, primaryField } = props
  const referenceRow = object[`${fieldId}_value`] || {}
  const label = referenceRow[primaryField] || 'Untitled'

  const combinedValue =
    input.value && !input.value.label
      ? { label, value: input.value }
      : input.value

  return (
    <Select
      isClearable
      cacheOptions
      defaultOptions={options}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={combinedValue}
    />
  )
}

ReferenceList.propTypes = {
  appId: PropTypes.string.isRequired,
  datasource: PropTypes.object.isRequired,
  datasourceId: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
}

export default ReferenceList
