import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchOrganizations, getOrganizations } from 'ducks/organizations'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { getOrgApps } from 'ducks/apps'

import HelpText from 'components/Editor/Inspect/Libraries/HelpText'
import { getDatasources } from 'ducks/apps/datasources'

class OrgSelect extends Component {
  getOptions = () => {
    const { organizations } = this.props

    return organizations.map(({ id, name }) => ({
      label: name,
      value: id,
    }))
  }

  componentDidMount() {
    const { fetchOrganizations } = this.props

    fetchOrganizations()
  }

  render() {
    const { hasSharedDatabase } = this.props
    const options = this.getOptions()

    if (options.length <= 1) {
      return null
    }

    return (
      <>
        <div
          className="settings-form-row"
          style={hasSharedDatabase ? { marginBottom: '12px' } : {}}
        >
          <label>Team</label>
          <div className="settings-form-input">
            <WrappedSelect
              disabled={hasSharedDatabase}
              {...this.props}
              options={this.getOptions()}
              placeholder="Choose Team..."
            />
          </div>
        </div>
        <div
          className={
            hasSharedDatabase
              ? 'settings-form-row-help-text-wrapper'
              : undefined
          }
        >
          {hasSharedDatabase && (
            <HelpText
              text="You can't change the team ownership for apps that share a database. Please contact support for help with this"
              header
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, { appId }) => {
  const apps = getOrgApps(state)
  const datasources = getDatasources(state, appId)

  let hasSharedDatabase = false

  if (apps && datasources) {
    for (const datasource of datasources) {
      if (apps[datasource.id] && apps[datasource.id].length > 1) {
        hasSharedDatabase = true

        break
      }
    }
  }

  return { organizations: getOrganizations(state), hasSharedDatabase }
}

export default connect(mapStateToProps, { fetchOrganizations })(OrgSelect)
