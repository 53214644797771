import React, { Component } from 'react'
import { connect } from 'react-redux'

import { deleteDataObject, saveDataObject, requestData } from 'utils/io'
import { capitalize, singularize } from 'utils/strings'

import { getTable } from 'ducks/apps/datasources'
import { getDataObject, savingDataObject, getData } from 'ducks/datasources'
import { loadMetrics } from 'ducks/metrics'

import Loading from 'components/Shared/Loading'

import Form from './Form'

import './CreateObjectModal.scss'

class CreateObjectModal extends Component {
  handleSubmit = data => {
    data = this.removePassword(data)
    data = this.serializeReferences(data)

    const { match, savingDataObject, loadMetrics } = this.props
    const { appId, datasourceId, tableId, id } = match.params
    savingDataObject()
    saveDataObject(appId, datasourceId, tableId, id, data)
    loadMetrics(appId, datasourceId)
  }

  serializeReferences = data => {
    data = { ...data }

    Object.keys(data).forEach(key => {
      if (data[key] && data[key].value) {
        data[key] = data[key].value
      }
    })

    return data
  }

  shouldComponentUpdate(nextProps) {
    const { savingData, error, history } = this.props

    const saveDataBecameTrue =
      savingData === true && savingData !== nextProps.savingData

    const saveDataFalse = savingData === false && nextProps.savingData === false
    const errorBecameFalse = error && error !== nextProps.error

    if (
      (saveDataBecameTrue && !nextProps.error) ||
      (saveDataFalse && errorBecameFalse)
    ) {
      history.push(this.backURL())

      return false
    }

    return true
  }

  removePassword = data => {
    const { table } = this.props
    data = { ...data }
    let passwordField = null

    Object.keys(table.fields).forEach(fieldId => {
      const field = table.fields[fieldId]

      if ((field.name || '').match(/^password$/i)) {
        passwordField = fieldId
      }
    })

    if (passwordField) {
      const passwordValue = data[passwordField] || ''

      if (passwordValue.trim() === '[hidden]' || passwordValue.trim() === '') {
        delete data[passwordField]
      }
    }

    return data
  }

  handleDelete = () => {
    const { match, history, loadMetrics } = this.props
    const { appId, datasourceId, tableId, id } = match.params

    if (!id) {
      return
    }

    if (!window.confirm('Are you sure you want to delete this object')) {
      return
    }

    deleteDataObject(appId, datasourceId, tableId, id)
    loadMetrics(appId, datasourceId)

    history.push(this.backURL())
  }

  backURL = () => {
    const { match } = this.props
    const { appId, datasourceId, tableId } = match.params

    return `/apps/${appId}/data/${datasourceId}/${tableId}`
  }

  getTitle() {
    const { match, table } = this.props
    const { id } = match.params

    if (!table) {
      return 'Loading...'
    }

    const name = capitalize(singularize(table.name))

    return id ? name : `New ${name}`
  }

  requestData = () => {
    const { match, table } = this.props
    const { appId, datasourceId, tableId, id } = match.params

    if (id) {
      requestData({
        appId,
        datasourceId,
        tableId,
        id,
        tableConfig: table,
        skipDBAssistantInitialization: true,
      })
    }
  }

  componentDidMount() {
    const { table } = this.props

    if (table) {
      this.requestData()
    }
  }

  componentDidUpdate(oldProps) {
    const { table } = this.props

    if (table && !oldProps.table) {
      this.requestData()
    }
  }

  render() {
    let { table, object, match, error, savingData } = this.props
    const { id, appId, datasourceId, tableId } = match.params

    if (!table || (id && !object)) {
      return <Loading expanded />
    }

    object = { ...object }
    delete object.password

    return (
      <Form
        appId={appId}
        datasourceId={datasourceId}
        tableId={tableId}
        id={id}
        isCreate={!id}
        table={table}
        title={this.getTitle()}
        backURL={this.backURL()}
        onSubmit={this.handleSubmit}
        onDelete={id && this.handleDelete}
        initialValues={object}
        initialObject={object}
        form={`createObject-${tableId}.${id || 0}`}
        errorMessage={error}
        saving={savingData}
      />
    )
  }
}

const mapStateToProps = (state, { match }) => {
  return {
    table: getTable(
      state,
      match.params.appId,
      match.params.datasourceId,
      match.params.tableId
    ),
    object: getDataObject(state, match.params.tableId, match.params.id),
    data: getData(state, match.params.tableId),
    savingData: state.datasources.savingData,
    error: state.datasources.error,
  }
}

const WrappedCreateModal = connect(mapStateToProps, {
  savingDataObject,
  loadMetrics,
})(CreateObjectModal)

export default class CreateModalWrapped extends Component {
  render() {
    const { match, history } = this.props
    const { appId, datasourceId, tableId, id } = match.params
    const key = [appId, datasourceId, tableId, id].join('.')

    return (
      <React.Fragment>
        {[<WrappedCreateModal key={key} match={match} history={history} />]}
      </React.Fragment>
    )
  }
}
