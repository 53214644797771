import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import useSetSeenTrialEndModal from 'hooks/useSetSeenTrialEndModal'

import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'

import {
  showModal as showUpgradeModal,
  setPaymentRouteType,
} from 'ducks/trialWarning'
import { getApp } from 'ducks/apps'
import { getOrganization, updateOrganizationName } from 'ducks/organizations'

import trialCompleted from './trial-completed.png'

import history from '../../../history'

import './style.scss'

const IntegrationTrialEndModal = props => {
  const handleClose = () => {
    const { appId } = props
    const url = `/apps/${appId}/screens`

    history.push(url)
  }

  const handleClickUpgrade = async () => {
    const { showUpgradeModal, setPaymentRouteType } = props

    await setPaymentRouteType('upgrade')
    await showUpgradeModal()
  }

  useSetSeenTrialEndModal(true)

  const renderContent = () => {
    return (
      <div className="integration-trial-end">
        <img src={trialCompleted} alt="External Users Welcome" width="50%" />
        <h1 className="integration-trial-end-title">
          Your Integrations Pack Trial Has Ended...
        </h1>
        <p className="integration-trial-end-body">
          But the party isn't over! You're free to continue building your app,
          but your External Collections, Custom Actions, and use of the
          Collections API have been paused. Upgrade now to continue using your
          integrations.
        </p>
      </div>
    )
  }

  return (
    <Modal size="lg">
      <Modal.Content>{renderContent()}</Modal.Content>
      <Modal.Actions>
        <Button text onClick={handleClose} type="button">
          Not right now
        </Button>
        <Button onClick={handleClickUpgrade} type="button" orange>
          Upgrade
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = (state, { match }) => {
  const app = getApp(state, match.params.appId)
  const { OrganizationId } = app || {}

  const organization = getOrganization(state, OrganizationId)

  return {
    appId: match.params.appId,
    app,
    organization,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    showUpgradeModal,
    setPaymentRouteType,
    updateOrganizationName,
  })(IntegrationTrialEndModal)
)
