import React from 'react'
import { Field } from 'redux-form'
import ToggleField from 'components/Shared/Forms/ToggleField'

export default function ExternalLinkOptions({ field }) {
  return (
    <div className="external-link-options">
      <Field
        boxed
        hideInput
        inverted
        component={ToggleField}
        name={`${field}.options.useSystemBrowser`}
        color="yellow"
        label="Use In-app Browser"
      />
    </div>
  )
}
