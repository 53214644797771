import { SECTION } from '@adalo/constants'
import LayoutContext from './layout/LayoutContext'
import LayoutObject from './layout/LayoutObject'
import ObjectNode from './ObjectNode'

export default class ListItemNode extends ObjectNode<typeof SECTION> {
  constructor(id: string) {
    super(id, SECTION)
  }

  public override layout(context: LayoutContext): LayoutObject<typeof SECTION> {
    const itemContext: LayoutContext = {
      ...context,
      // List Items are always laid out with no restriction on height.
      containerHeight: undefined,
    }

    return super.layout(itemContext)
  }
}
