import React from 'react'
import CommonInspectRow from 'components/Editor/Inspect/GenericRow'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import { isSectionElement } from 'utils/layoutSections'

import './styles.css'

const FixedControl = ({ isFixed, onChange, hasComponentParent, object }) => {
  if (hasComponentParent || isSectionElement(object)) {
    return null
  }

  return (
    <CommonInspectRow title="Sticky while scrolling?" className="fixed-control">
      <div className="fixed-control-box">
        {isFixed ? 'On' : 'Off'}
        <ToggleButton value={isFixed} onChange={onChange} />
      </div>
    </CommonInspectRow>
  )
}
export default FixedControl
