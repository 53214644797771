import { borderStyle, backgroundStyle } from '@adalo/constants'
import BaseObject from './BaseObject'

import { scaleValue } from '../../../utils/zoom'

export default class BaseShape extends BaseObject {
  getBorderWidth() {
    const { borderWidth, zoom } = this.props

    return scaleValue(borderWidth, zoom)
  }

  getBorderProps() {
    const { borderColor, borderPosition, ...styles } = this.props

    if (!styles.borderStyle || styles.borderStyle === borderStyle.NONE) {
      return {
        strokeWidth: 0,
        stroke: 'transparent',
      }
    }

    const strokeWidth = this.getBorderWidth()

    const borderProps = {
      strokeWidth,
      stroke: this.getColor(borderColor),
    }

    if (styles.borderStyle === borderStyle.DOTTED) {
      borderProps.strokeDasharray = `${strokeWidth}, ${strokeWidth}`
    }

    if (styles.borderStyle === borderStyle.DASHED) {
      borderProps.strokeDasharray = `${strokeWidth * 4}, ${strokeWidth * 4}`
    }

    return borderProps
  }

  getBackgroundColor() {
    return this.props.backgroundStyle === backgroundStyle.COLOR
      ? this.getColor(this.props.backgroundColor)
      : 'transparent'
  }

  getShadowProps() {
    let { xScaled, yScaled, widthScaled, heightScaled, shadow, zoom } =
      this.props

    const border = this.getBorderProps()

    shadow = {
      ...shadow,
      color: this.getColor(shadow.color),
    }

    return {
      shadow,
      zoom,
      border,
      x: xScaled,
      y: yScaled,
      width: widthScaled,
      height: heightScaled,
    }
  }
}
