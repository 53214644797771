import React, { Component } from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import { comparators, dataTypes } from '@adalo/constants'

import {
  getComparatorOptions,
  isLiteralComparator,
  getComparisonDataTypes,
} from 'utils/comparators'

import { getLabel } from 'utils/sources'
import { getSources } from 'ducks/recommender'
import Accordion from 'components/Shared/Accordion'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import SelectableInput from 'components/Shared/Forms/SelectableInput'

import FilteringExtension from '../../DataBinding/FilteringFormExtension'

class VisibilityFormExtension extends Component {
  render() {
    const {
      comparisonOptions,
      binding,
      comparisonLabel,
      comparison2Label,
      hideAdvanced,
    } = this.props

    const { source } = binding

    if (!source) {
      return null
    }

    const parentSource = source.source

    const showFilter = parentSource && parentSource.dataType === dataTypes.LIST

    const comparatorOptions = getComparatorOptions(source.dataType)

    return (
      <React.Fragment>
        {showFilter ? <FilteringExtension {...this.props} /> : null}
        <div className="action-item-split-row">
          <Field
            autoSelect
            className="small white"
            name="comparator"
            options={comparatorOptions}
            component={WrappedSelect}
          />
        </div>
        {!isLiteralComparator(binding.comparator) ? (
          <div className="action-item-split-row">
            <Field
              className="form-input small white"
              name="comparison"
              component={SelectableInput}
              options={comparisonOptions}
              getOptionLabel={() => comparisonLabel}
            />
          </div>
        ) : null}
        {binding.comparator === comparators.BETWEEN ? (
          <div className="action-item-split-row">
            <Field
              className="form-input small white"
              name="comparison2"
              component={SelectableInput}
              options={comparisonOptions}
              getOptionLabel={() => comparison2Label}
            />
          </div>
        ) : null}
        {hideAdvanced ? null : (
          <Accordion
            className="visibility-form-extensions-accordion"
            title="Advanced"
            renderChildren={() => (
              <div className="list-form-extension-realtime">
                <Field
                  component="input"
                  name="options.affectLayout"
                  type="checkbox"
                />
                <label>Affect Layout</label>
              </div>
            )}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId, componentId, object, binding } = props
  const { comparator, comparison, comparison2, source } = binding
  const types = getComparisonDataTypes(comparator, source)

  let comparisonLabel
  let comparison2Label

  if (comparison && typeof comparison === 'object') {
    comparisonLabel = getLabel(state, comparison, appId, componentId)
  }

  if (comparison2 && typeof comparison2 === 'object') {
    comparison2Label = getLabel(state, comparison2, appId, componentId)
  }

  const comparisonOptions = getSources(
    state,
    appId,
    componentId,
    object.id,
    types
  )

  return {
    comparisonOptions,
    comparisonLabel,
    comparison2Label,
  }
}

export default connect(mapStateToProps)(VisibilityFormExtension)
