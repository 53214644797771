import './Onboarding.scss'

import millionApps from './assets/onboarding_onemillionapps.png'
import upperDoodad from './assets/onboarding_upperdoodads.png'
import lowerDoodad from './assets/onboarding_lowerdoodads.png'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const DefaultSecondColumn = () => {
  return (
    <div className="onboarding-default-graphic-background">
      <img
        className="onboarding-doodads upperdoodads"
        src={upperDoodad}
        alt="Doodad"
      />
      <img
        className="onboarding-doodads lowerdoodads"
        src={lowerDoodad}
        alt="Doodad"
      />
      <div className="onboarding-million-apps">
        <img src={millionApps} alt="One Million Apps & Counting..." />
        <h2>
          Join Adalo, where individuals and businesses like yours have
          transformed their ideas into reality.
        </h2>
      </div>
    </div>
  )
}

const OnboardingLayout = (props: Props): JSX.Element => {
  const { children: childrenProp } = props

  const children = Array.isArray(childrenProp)
    ? (childrenProp as React.ReactNode[])
    : [childrenProp]

  const columns = children.length

  if (columns > 2) {
    console.warn(
      `Attempting to render more than 2 columns in OnboardingLayout. Only the first 2 will be rendered.`
    )
  }

  const firstColumn = children[0]
  const secondColumn = children[1] ?? <DefaultSecondColumn />

  return (
    <main className="onboarding-layout">
      <div className="onboarding-column">{firstColumn}</div>
      <div className="onboarding-column">{secondColumn}</div>
    </main>
  )
}

export default OnboardingLayout
