import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'

import AuthPage from './AuthPage'
import TeamForm from './TeamForm'
import { INVITE_LIMIT_MESSAGE } from '../../constants'
import { createInvite, updateOrganizationName } from '../../ducks/organizations'

class TeamSetup extends Component {
  handleSubmit = async ({ name, emails }) => {
    const { createInvite, organization, updateOrganizationName, history } =
      this.props

    await updateOrganizationName(organization.id, name)

    for (let email of emails) {
      if (email.email === '' || typeof email.email === 'undefined') {
        continue
      }

      email = email.email
      email = email.toLowerCase()

      try {
        await createInvite({
          email,
          organizationId: organization.id,
        })
      } catch (err) {
        const status = err.response && err.response.status
        const data = err.response && err.response.data
        let message = (data && data.error) || 'An unknown error occurred'

        if (status === 429) {
          message = INVITE_LIMIT_MESSAGE
        }

        throw new SubmissionError({
          email: message,
        })
      }
    }

    history.push('/first-app-setup')
  }

  render() {
    const { organization } = this.props

    return (
      <AuthPage className="team-setup-page">
        <TeamForm
          form={`teamName-${organization.id}`}
          initialValues={{ name: organization.name, emails: [''] }}
          onSubmit={this.handleSubmit}
        />
      </AuthPage>
    )
  }
}

export default connect(null, { createInvite, updateOrganizationName })(
  TeamSetup
)
