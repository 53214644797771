import React from 'react'
import { reduxForm, Field } from 'redux-form'
import APIRequest from './Shared/APIRequest'
import TestRequest from './Shared/TestRequest'

export const FORM_NAME = 'externalUsersLogin'

const LoginSetupForm = props => {
  const { step, updateEnabled } = props

  const renderContent = () => {
    if (step === 0) {
      return <APIRequest formName={FORM_NAME} updateEnabled={updateEnabled} />
    } else {
      return (
        <Field
          name="testResults"
          formName={FORM_NAME}
          component={TestRequest}
          updateEnabled={updateEnabled}
        />
      )
    }
  }

  return renderContent()
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(LoginSetupForm)
