import classNames from 'classnames'

import './styles.scss'

type Props = {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  placeholder?: string
  autoFocus?: boolean
}

const SearchBar = ({
  value,
  onChange,
  className,
  placeholder = 'Search...',
  autoFocus = true,
}: Props): JSX.Element => {
  return (
    <div
      className={classNames('search-bar', { [className as string]: className })}
    >
      <i className="material-icons">search</i>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
      />
    </div>
  )
}

export default SearchBar
