const SET_ADD_OBJECT_VISIBLE = Symbol('SET_ADD_OBJECT_VISIBLE')

const INITIAL_STATE = {
  addObject: false,
}

// REDUCER

export default (state = INITIAL_STATE, action) => {
  if (action.type === SET_ADD_OBJECT_VISIBLE) {
    return {
      ...state,
      addObject: action.visible,
    }
  }

  return state
}

// ACTIONS

export const setAddObjectVisible = visible => ({
  type: SET_ADD_OBJECT_VISIBLE,
  visible,
})

// SELECTORS

export const getAddObject = state => state.editor.menus.addObject
