import { MutableRefObject, useEffect } from 'react'

const useClickOutside = (
  ref: MutableRefObject<unknown>,
  callback: () => void
): void => {
  const handleClickOutside = (event: MouseEvent) => {
    if (
      (ref.current as HTMLElement) &&
      !(ref.current as HTMLElement).contains(event.target as Node)
    ) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])
}

export default useClickOutside
