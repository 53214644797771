import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import { getApp } from 'ducks/apps'
import { getDatasources } from 'ducks/apps/datasources'
import { MenuOption } from 'ducks/recommender'

import NewTableOption from 'components/Editor/Inspect/Libraries/NewTableOption'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import ControlField from 'components/Shared/Forms/ControlField'

import { verifyXanoApp } from 'utils/externalDatabases'

import DatasourceSelector from './DatasourceSelector'
import Fields from './Fields'

class CreateObjectOptions extends Component {
  tableOptions() {
    const { options, datasources } = this.props
    const { datasourceId } = options
    const datasource = datasources.filter(ds => ds.id === datasourceId)[0]

    if (!datasource) {
      return []
    }

    return Object.keys(datasource.tables).map(
      tableId =>
        new MenuOption(
          datasource.tables[tableId].name,
          tableId,
          'relationship-single'
        )
    )
  }

  handleChangeTable = ({ source }) => {
    if (source.source === undefined) return

    return this.updateFieldOptions(source)
  }

  updateFieldOptions = source => {
    const { change, field, formName } = this.props
    const { datasourceId, tableId } = source.source
    change(formName, `${field}.options.tableId`, tableId)
    change(formName, `${field}.options.datasourceId`, datasourceId)
  }

  newTableOptions() {
    const { app } = this.props
    const options = this.tableOptions()

    const isXanoApp = verifyXanoApp(app)

    if (isXanoApp) {
      return options
    }

    const newOption = {
      label: <NewTableOption handleChangeTable={this.handleChangeTable} />,
      value: {},
    }

    return options.concat([null, newOption])
  }

  render() {
    const {
      appId,
      componentId,
      field,
      formName,
      objectId,
      actionId,
      options,
      reference,
      menuTheme,
    } = this.props

    return (
      <div className="action-item-form-options">
        <div className="form-inspect-field-wrapper">
          <DatasourceSelector
            appId={appId}
            name={`${field}.options.datasourceId`}
          />
          <ControlField
            autoSelect
            component={MenuControl}
            name={`${field}.options.tableId`}
            displayName="Collection"
            placeholder="Select Collection"
            options={this.newTableOptions()}
            menuTheme={menuTheme}
          />
        </div>
        {options.tableId ? (
          <>
            <hr />
            <Fields
              fields={options.fields || []}
              parentField={field}
              appId={appId}
              componentId={componentId}
              objectId={objectId}
              actionId={actionId}
              datasourceId={options.datasourceId}
              tableId={options.tableId}
              reference={reference}
              formName={formName}
              menuTheme={menuTheme}
            />
          </>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, { appId }) => ({
  app: getApp(state, appId),
  datasources: getDatasources(state, appId),
})

const mapDispatchToProps = {
  change,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateObjectOptions)
