const CONNECTED = Symbol('CONNECTED')
const DISCONNECTED = Symbol('DISCONNECTED')

const INITIAL_STATE = {
  connected: true,
}

// Reducer

export default (state = INITIAL_STATE, action) => {
  if (action.type === CONNECTED) {
    return { ...state, connected: true }
  }

  if (action.type === DISCONNECTED) {
    return { ...state, connected: false }
  }

  return state
}

// Actions

export const socketConnected = () => ({
  type: CONNECTED,
})

export const socketDisconnected = () => ({
  type: DISCONNECTED,
})

// Selectors

export const getConnected = state => {
  return state.sockets.connected
}
