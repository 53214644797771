import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import {
  getAccordionState,
  openAccordion,
  closeAccordion,
} from 'ducks/accordions'

import Button from 'components/Shared/Button'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import { validate } from './validations'

import { GROUP } from './Item'

const FORM_NAME = 'add-collection'

const AddCollection = props => {
  const {
    accordion,
    handleSubmit,
    isAddingCollection,
    submitting,
    submitSucceeded,
    toggle,
  } = props

  useEffect(() => {
    const { reset, openAccordion, createTableId } = props

    if (submitSucceeded) {
      reset()
      toggle()
      openAccordion(GROUP, createTableId)
    }
  }, [submitSucceeded])

  useEffect(() => {
    const { closeAccordion } = props
    if (isAddingCollection && accordion) closeAccordion(GROUP)
  }, [isAddingCollection])

  return (
    <form
      className="data-add-collection"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Field
        name="name"
        id="name"
        component={WrappedInput}
        type="text"
        label="Collection Name"
        placeholder="New Collection"
      />
      <div className="actions">
        <Button type="button" text onClick={toggle}>
          Cancel
        </Button>
        <Button type="submit" orange loading={submitting}>
          Add
        </Button>
      </div>
    </form>
  )
}

const WrappedAddCollection = reduxForm({
  form: FORM_NAME,
  validate,
})(AddCollection)

const mapStateToProps = state => ({
  accordion: getAccordionState(state, GROUP),
})

const mapDispatchToProps = {
  closeAccordion,
  openAccordion,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedAddCollection)
