import getContainingScreen from 'ducks/editor/objects/helpers/getContainingScreen'
import { ObjectList } from 'ducks/editor/types/ObjectList'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import { getDeviceType } from '@adalo/utils'
import { EditorObject } from './responsiveTypes'
import getDeviceObject from './getDeviceObject'

const getDeviceObjectFromState = (
  list: ObjectList,
  map: ObjectPathMap,
  object: EditorObject
): EditorObject => {
  const screen = getContainingScreen(list, map, object.id)
  const device = getDeviceType(screen.width)

  return getDeviceObject(object, device)
}

export default getDeviceObjectFromState
