import React from 'react'
import { scaleValue } from '../../../../utils/zoom'

import Input from '../Input'

export default class SelectMenu extends Input {
  renderExtras() {
    const { width, height, padding, zoom, editorResizingProps } = this.props

    const y = height / 2 - 1
    const x = editorResizingProps?.relativeWidth
      ? -8 - padding
      : width - 8 - padding

    const xScaled = scaleValue(x, zoom)
    const yScaled = scaleValue(y, zoom)

    const wrapperX = editorResizingProps?.relativeWidth ? '100%' : 0

    const transform = `translate(${xScaled}, ${yScaled}), scale(${scaleValue(
      1,
      zoom
    )})`

    return (
      <svg x={wrapperX} y="0" width="100%" height="100%" overflow="visible">
        <g transform={transform}>
          <path d="M 0 0 L 8 0 L 4 4 Z" fill="#999" stroke="none" />
        </g>
      </svg>
    )
  }
}
