import React from 'react'

import Modal from 'components/Shared/Modal'
import TermsModal from 'components/Editor/TermsModal'

const Terms = ({ history }) => {
  const goBack = () => {
    history.goBack()
  }

  return (
    <Modal scrolling fixedHeight onClose={goBack}>
      <TermsModal onClose={goBack} />
    </Modal>
  )
}

export default Terms
