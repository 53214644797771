import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import QS from 'qs'

import { requestApps, getLastApp, getAppsLoaded } from 'ducks/apps'

import EmptyState from '../Shared/EmptyState'
import Loading from '../Shared/Loading'
import Page from '../Shared/Page'
import './Apps.css'

class Apps extends Component {
  componentDidMount() {
    const { requestApps } = this.props

    requestApps()
  }

  getParams = () => {
    const {
      location: { search },
    } = this.props

    const params = QS.parse(search || '', { ignoreQueryPrefix: true })

    return params
  }

  render() {
    const { lastApp, children, modalProps, appsLoaded } = this.props

    const params = this.getParams()

    if (lastApp) {
      const path = params.marketplace ? 'marketplace' : 'screens/add'

      return <Redirect to={`/apps/${lastApp}/${path}`} />
    } else if (appsLoaded) {
      return <Redirect to="/onboarding" />
    }

    return (
      <Page
        className="apps-page"
        modalProps={modalProps}
        modalContent={children}
        title="Adalo"
        baseURL="/"
      >
        <EmptyState greedy>
          <Loading large />
        </EmptyState>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  lastApp: getLastApp(state),
  appsLoaded: getAppsLoaded(state),
})

export default connect(mapStateToProps, { requestApps })(Apps)
