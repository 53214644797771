const getGhostKey = (id: string, width: number) => `${id}-${width}`

interface GhostCache<T> {
  set: (id: string, width: number, value: T) => void
  get: (id: string, width: number) => T | undefined
}

// We cache ghosts to avoid running the expensive calculations of creating them on mouse move.
// Creating a ghost just once per interaction doesn't have a noticeable performance impact,
// so we don't need a big cache. We just need to set a limit to make sure we don't use up
// too much memory unnecessarily.
const MAX_CACHED_GHOSTS = 20

const getGhostCache = <T>(): GhostCache<T> => {
  const cacheMap = new Map<string, T>()

  const set = (id: string, width: number, value: T) => {
    if (cacheMap.size > MAX_CACHED_GHOSTS) {
      cacheMap.clear()
    }

    const key = getGhostKey(id, width)
    cacheMap.set(key, value)
  }

  const get = (id: string, width: number): T | undefined => {
    const key = getGhostKey(id, width)

    return cacheMap.get(key)
  }

  const ghostCache = { set, get }

  return ghostCache
}

export default getGhostCache
