import { useCallback } from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { Binding, DataType } from '@adalo/constants'
import { State, getObjectList, getMap } from 'ducks/editor/objects'
import getObject from 'ducks/editor/objects/helpers/getObject'
import { EditorObject } from 'utils/responsiveTypes'
import { getComparisonDataTypes } from 'utils/comparators'
import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'
import VisibilityForm from 'components/Editor/RightPanel/Visibility/VisibilityForm'

interface Props {
  name: string
  displayName: string | null
  namespace: string
  binding?: Binding
  onChange: (a: Record<string, Binding>) => void
  tooltip?: string
  comparisonDataType?: DataType | { type: DataType; tableId: string }
  object: EditorObject
}

function ConditionForm({
  displayName,
  binding,
  name,
  onChange,
  tooltip,
  comparisonDataType,
  object,
  namespace,
}: Props): JSX.Element {
  const handleChange = useCallback(
    (newBinding: Binding): void => {
      const updatedBinding = newBinding
      const [newDataType] = getComparisonDataTypes(
        newBinding?.comparator,
        newBinding?.source
      )

      if (
        !isEqual(comparisonDataType, newDataType) &&
        updatedBinding?.comparison
      ) {
        delete updatedBinding.comparison
        delete updatedBinding.comparison2
      }

      const changeObj = {
        [name]: updatedBinding,
      }

      onChange(changeObj)
    },
    [binding, onChange]
  )

  return (
    <div className="library-condition-form">
      <div className="right-panel-collapsible-options">
        <VisibilityForm
          binding={binding}
          onChange={handleChange}
          object={object}
          displayName={displayName}
          hideAdvanced
          formName={`${namespace}-${name}-condition`}
          tooltip={tooltip}
        />
      </div>
    </div>
  )
}

type ConnectProps = { binding?: Binding; objectId: string }

const mapStateToProps = (state: State, { binding, objectId }: ConnectProps) => {
  const list = getObjectList(state)
  const map = getMap(state)
  const object = getObject(list, map, objectId)

  const [comparisonDataType] = getComparisonDataTypes(
    binding?.comparator,
    binding?.source
  )

  return {
    comparisonDataType,
    object,
  }
}

export default withPremiumFeatureHandler(
  connect(mapStateToProps)(ConditionForm)
)
