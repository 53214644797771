import React from 'react'
import { connect } from 'react-redux'
import { getBoundingBox } from '@adalo/utils'
import { listTypes } from '@adalo/constants'

import { getSelection } from 'ducks/editor/selection'
import { getListItemWidth } from 'ducks/editor/objects/helpers/getListItemWidth'
import getCustomListProperties from 'ducks/editor/objects/helpers/getCustomListProperties'

import { defaultListChildren } from 'utils/lists'
import { getDeviceChildren } from 'utils/operations/getDeviceChildren'
import { scaleValue } from 'utils/zoom'

import GroupWrapper from '../Group/GroupWrapper'
import BaseObject from '../BaseObject'

class List extends BaseObject {
  static defaultChildren = defaultListChildren

  static defaultProps = {
    children: defaultListChildren,
    object: {
      children: defaultListChildren,
    },
  }

  getRowHeight = (includeRowMargin = false) => {
    const { object, deviceType } = this.props
    const { rowMargin } = getCustomListProperties(object, deviceType)
    let rowHeight = 0

    const objectChildren =
      object && object.children
        ? getDeviceChildren(object.children, deviceType)
        : List.defaultChildren

    if (objectChildren.length > 0) {
      const bbox = getBoundingBox(objectChildren)
      const topOffset = bbox.y - object.y
      const margin = includeRowMargin ? rowMargin : 0
      rowHeight = bbox.height + topOffset + margin
    } else {
      rowHeight = 80
    }

    if (rowHeight <= 0) {
      rowHeight = 20
    }

    return rowHeight
  }

  renderChildCopies() {
    const { children, height, zoom, object, deviceType } = this.props
    const { listType, columnCount, rowMargin } = getCustomListProperties(
      object,
      deviceType
    )

    const rowHeight = this.getRowHeight(true)

    const prototype = Array.isArray(children) && children[0]

    if (!prototype) {
      return null
    }

    const elements = []

    let computedColumnCount = 1
    let columnWidth = object.width
    const spacing = rowMargin || 0

    if (listType === listTypes.GRID) {
      computedColumnCount = columnCount || 1
      columnWidth =
        (object.width - (computedColumnCount - 1) * spacing) /
        computedColumnCount
    }

    for (let offset = 0; offset < height; offset += rowHeight) {
      for (let i = 0; i < computedColumnCount; i += 1) {
        if (i === 0 && offset === 0) {
          continue
        }

        const yOffset = scaleValue(offset, zoom)
        const xOffset = scaleValue(i * (columnWidth + spacing), zoom)

        const row = (
          <React.Fragment>
            {children.map(child => React.cloneElement(child))}
          </React.Fragment>
        )

        elements.push(
          <g
            transform={`translate(${xOffset}, ${yOffset})`}
            pointerEvents="none"
            opacity="0.5"
            key={elements.length}
          >
            {row}
          </g>
        )
      }
    }

    return elements
  }

  renderCellBbox() {
    const { xScaled, yScaled, zoom, object, deviceType } = this.props
    const rowHeight = this.getRowHeight()
    const rowHeightScaled = scaleValue(rowHeight, zoom)
    const dash = scaleValue(6, zoom)

    const boxWidth = getListItemWidth(object, deviceType)

    return (
      <rect
        stroke="#f40"
        strokeWidth={1}
        strokeDasharray={`${dash}, ${dash}`}
        fill="none"
        x={xScaled}
        y={yScaled}
        width={scaleValue(boxWidth, zoom)}
        height={rowHeightScaled}
      />
    )
  }

  render() {
    const {
      children,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      backgroundColor,
      opacity,
      onMouseEnter,
      isSelected,
      object,
      editorResizingProps,
    } = this.props

    return (
      <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
        <g className="group" style={{ opacity }} onMouseEnter={onMouseEnter}>
          {isSelected ? this.renderCellBbox() : null}
          <rect
            x={xScaled}
            y={yScaled}
            width={widthScaled}
            height={heightScaled}
            fill={backgroundColor || 'none'}
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDoubleClick}
          />
          {this.renderChildCopies()}
          {children}
        </g>
      </GroupWrapper>
    )
  }
}

const mapStateToProps = (state, { id }) => ({
  isSelected: getSelection(state).includes(id),
})

export default connect(mapStateToProps)(List)
