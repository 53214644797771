import React from 'react'
import { Field, FieldArray, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { NakedBindableTextControl } from 'components/Shared/BindableTextControl'
import ControlField from 'components/Shared/Forms/ControlField'

import { sanitization } from '@protonapp/react-entity-textarea'
import AuthFields from './AuthFields'
import Inputs from './Inputs'
import { FORM_NAME } from './Form'

const methodOptions = [
  { label: 'POST', value: 'post' },
  { label: 'GET', value: 'get' },
  { label: 'PUT', value: 'put' },
  { label: 'PATCH', value: 'patch' },
  { label: 'DELETE', value: 'delete' },
]

const buildInputs = inputs => {
  inputs = inputs || []
  //TODO: implement "New Input..."

  return inputs
}

const APIRequest = ({ showMainAPIInfo, handleClose }) => {
  const selector = formValueSelector(FORM_NAME)
  const inputs = useSelector(state => selector(state, 'inputs'))
  const history = useHistory()
  const match = useRouteMatch('/apps/:appId')

  const navigateToApiSettings = () => {
    const {
      params: { appId },
    } = match
    handleClose()
    history.push(`/apps/${appId}/app-settings?active=apikeys`)
  }

  return (
    <div className="api-request">
      <div className="api-request-values">
        {showMainAPIInfo ? (
          <>
            <div className="api-request-url">
              <ControlField
                disableFormulas
                name="baseURL"
                label="API Base URL"
                placeholder="https://api.example.com/v1/action"
                component={NakedBindableTextControl}
                disableChip
                bindingOptions={() => buildInputs(inputs)}
                getLabel={entity => `${entity.label}`}
                autoComplete="off"
              />
            </div>
            <div className="api-request-method">
              <label>Method</label>
              <Field
                name="method"
                placeholder="Select a Method"
                component={WrappedSelect}
                rowHeight={50}
                options={methodOptions}
                autoComplete="off"
              />
            </div>
            <div className="api-request-headers">
              <FieldArray
                name="auth"
                component={AuthFields}
                bindingOptions={() => buildInputs(inputs)}
              />
            </div>
          </>
        ) : (
          <div style={{ marginBottom: '50px' }}>
            <h2>OpenAI API Key</h2>
            <p>
              To use this custom action, you must{' '}
              <a onClick={navigateToApiSettings}>set up your OpenAI API key</a>.
            </p>
          </div>
        )}
        <div className="api-request-body">
          <ControlField
            disableFormulas
            name="body"
            label="Body"
            placeholder="Enter JSON here"
            component={NakedBindableTextControl}
            disableChip
            bindingOptions={() => buildInputs(inputs)}
            getLabel={entity => `${entity.label}`}
            className="api-request-body"
            autoComplete="off"
            sanitization={sanitization.IGNORE}
          />
        </div>
      </div>
      <div className="api-request-inputs">
        <FieldArray name="inputs" component={Inputs} />
      </div>
    </div>
  )
}

export default APIRequest
