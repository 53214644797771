import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import QS from 'qs'
import { parseJWT } from '../../utils/auth'
import { updatePassword } from '../../utils/io'
import { setCurrentUserToken } from '../../ducks/users/index.ts'
import Form from './RecoverPasswordForm'
import AuthPage from './AuthPage'
import './Auth.scss'

class RecoverPassword extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { token } = QS.parse(location.search.slice(1))
    let error = null

    if (token) {
      const tokenBody = parseJWT(token)

      if (!tokenBody) {
        error =
          'Your password recovery link has expired. Invitations expire after 7 days.'
      }
    }

    this.state = {
      error,
      recoveryToken: token,
    }
  }

  handleUpdate = data =>
    new Promise((resolve, reject) => {
      const { recoveryToken } = this.state
      const { history, setCurrentUserToken } = this.props
      data = { ...data, recoveryToken }

      updatePassword(data, ({ success, errors, sessionToken }) => {
        if (!success) {
          // Show errors
          console.log(errors)

          return reject(new SubmissionError(errors))
        }

        setCurrentUserToken(sessionToken)
        history.push('/')
        resolve()
      })
    })

  render() {
    const { error } = this.state

    return (
      <AuthPage className="login-page" error={error}>
        <Form onSubmit={this.handleUpdate} />
      </AuthPage>
    )
  }
}

export default connect(null, { setCurrentUserToken })(RecoverPassword)
