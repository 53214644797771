import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import { getDatasources, getDatasourcesMap } from 'ducks/apps/datasources'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Fields from './Fields'

class SignupOptions extends Component {
  datasourceOptions() {
    const { datasources } = this.props

    return Object.keys(datasources).map(id => ({
      label: 'App Data',
      value: datasources[id].id,
    }))
  }

  getTableId = () => {
    let { datasourcesMap, options } = this.props
    options = options || {}

    const datasource = datasourcesMap[options.datasourceId]

    if (datasource && datasource.auth && datasource.auth.table) {
      return datasource.auth.table
    }

    return null
  }

  render() {
    const {
      appId,
      componentId,
      objectId,
      field,
      options,
      reference,
      actionId,
      menuTheme,
    } = this.props

    const tableId = this.getTableId()

    return (
      <div className="action-form-signup">
        <div style={{ display: 'none' }}>
          <Field
            autoSelect
            className="small white"
            name={`${field}.options.datasourceId`}
            component={WrappedSelect}
            options={this.datasourceOptions()}
          />
        </div>
        {options.datasourceId && tableId ? (
          <Fields
            fields={options.fields || []}
            parentField={field}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
            datasourceId={options.datasourceId}
            tableId={tableId}
            reference={reference}
            actionId={actionId}
            menuTheme={menuTheme}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId } = props

  return {
    datasources: getDatasources(state, appId),
    datasourcesMap: getDatasourcesMap(state, appId),
  }
}

export default connect(mapStateToProps)(SignupOptions)
