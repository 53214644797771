import React from 'react'

import { scaleValue } from 'utils/zoom'

import Section from '../Section'
import Rect from '../Section/Rect'
import Icon from './image-large.svg'
import FullWidthUpload from './FullWidth'
import './ImageUpload.css'

export default class ImageUpload extends Section {
  render() {
    const {
      id,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      imageUploadType,
      editorResizingProps,
      zoom,
      width,
      height,
      borderRadius,
    } = this.props

    const opacity = this.getOpacity()

    if (imageUploadType === 'fullWidth') {
      return <FullWidthUpload {...this.props} />
    }

    const iconWidth = scaleValue(40, zoom)
    const iconHeight = scaleValue(30, zoom)
    const clipPathId = `clip-${id}`

    let iconX = '50%'
    let iconWrapperX = 0
    if (editorResizingProps?.relativeWidth) {
      iconWrapperX = -iconWidth / 2
    } else {
      iconX = widthScaled / 2 - iconWidth / 2
    }

    const defaultRectProps = {
      width,
      height,
      zoom,
      widthScaled,
      heightScaled,
      borderRadius,
    }

    return (
      <g
        transform={`translate(${xScaled}, ${yScaled})`}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        className="image-upload-object"
        {...opacity}
      >
        <defs>
          <clipPath id={clipPathId}>
            <Rect {...defaultRectProps} x={0} y={0} />
          </clipPath>
        </defs>
        <Rect
          {...defaultRectProps}
          x={0}
          y={0}
          className="image-upload-backdrop"
        />
        <svg
          x={iconWrapperX}
          y="0"
          width="100%"
          height="100%"
          overflow="visible"
        >
          <image
            href={Icon}
            width={iconWidth}
            height={iconHeight}
            x={iconX}
            y={heightScaled / 2 - iconHeight / 2}
            clipPath={`url(#${clipPathId})`}
          />
        </svg>
      </g>
    )
  }
}
