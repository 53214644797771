import React, { cloneElement } from 'react'
import { connect } from 'react-redux'
import { getCurrentUser } from 'ducks/users/index.ts'

import {
  getActiveModal,
  NEW_SCREEN_MODAL,
  NEW_TABLE,
  NEW_RELATIONSHIP,
  TWO_FACTOR_MODAL,
  CUSTOM_ACTION_MODAL,
  INTEGRATION_TRIAL_MODAL,
  FREE_TRIAL_MODAL,
  TERMS_OF_SERVICE_MODAL,
  DESIGN_VERSION_MODAL,
  CONFIRM_RESTORE_MODAL,
  UNPUBLISH_APP_MODAL,
  CHOOSE_ZAP_MODAL,
  CANCEL_SUBSCRIPTION_MODAL,
  FEATURE_TEMPLATE_MODAL,
  FEATURE_TEMPLATE_DETAILS_MODAL,
  CHURN_FORM_MODAL,
} from 'ducks/editor/modals'
import { getFeatureFlag } from 'ducks/featureFlags'

import Modal from 'components/Shared/Modal'
import NewScreenModal from 'components/Editor/NewScreenModal'
import NewTableModal from 'components/Editor/NewTableModal'
import NewRelationshipModal from 'components/Editor/NewRelationshipModal'
import TwoFactorModal from 'components/Editor/Publish/TwoFactorModal'
import CustomActionModal from 'components/Editor/CustomActionModal'
import IntegrationTrialModal from 'components/Shared/TrialWarning/IntegrationTrialModal'
import FreeTrialModal from 'components/Shared/TrialWarning/FreeTrialModal'
import TermsModal from 'components/Editor/TermsModal'
import DesignVersionModal from 'components/Editor/DesignVersionModal'
import ConfirmRestoreModal from 'components/Editor/ConfirmRestoreModal'
import CancelSubscription from 'components/Shared/TrialWarning/CancelSubscriptionModal.tsx'
import ChurnFormModal from 'components/Shared/TrialWarning/ChurnFormModal'

import UnpublishAppModal from '../Publish/UnpublishModal'
import ChooseZapModal from '../ChooseZapModal/index.tsx'
import FeatureTemplateModal from '../FeatureTemplateModal'
import FeatureTemplateDetails from '../FeatureTemplateModal/Details'

export const CleanModalWrapper = ({
  activeModal,
  user,
  hasNewAddMenuWithSections,
}) => {
  const handleSuccess = (value, ...opts) => activeModal.resolve(value, ...opts)
  const handleRejection = error => activeModal.reject(error)
  const handleClose = () => activeModal.resolve()

  const renderModal = (modal, modalProps = {}) => {
    return (
      <div className="modal-wrapper-wrapper">
        <Modal {...modalProps} scrolling onClose={handleClose}>
          {cloneElement(modal, {
            onSuccess: handleSuccess,
            onCancel: handleRejection,
            onClose: handleClose,
            opts: activeModal?.opts || {},
          })}
        </Modal>
      </div>
    )
  }

  if (!activeModal) return null

  switch (activeModal.modalType) {
    case NEW_SCREEN_MODAL: {
      const { templateId } = activeModal.opts

      return renderModal(<NewScreenModal />, {
        className: 'new-screen-modal',
        size: hasNewAddMenuWithSections ? 'md' : 'sm',
        templateId,
      })
    }
    case NEW_TABLE: {
      return renderModal(<NewTableModal />)
    }
    case NEW_RELATIONSHIP: {
      return renderModal(<NewRelationshipModal />)
    }
    case TWO_FACTOR_MODAL: {
      return renderModal(<TwoFactorModal />, { size: 'xs' })
    }
    case CUSTOM_ACTION_MODAL: {
      return renderModal(<CustomActionModal />, { size: 'lg' })
    }
    case INTEGRATION_TRIAL_MODAL: {
      const { appId, type } = activeModal.opts

      return renderModal(<IntegrationTrialModal appId={appId} type={type} />, {
        size: 'lg',
      })
    }
    case FREE_TRIAL_MODAL: {
      const { appId, type } = activeModal.opts

      return renderModal(<FreeTrialModal appId={appId} type={type} />, {
        size: 'lg',
      })
    }
    case DESIGN_VERSION_MODAL: {
      const {
        version,
        loadVersionsList,
        type,
        deleteVersion,
        handleRestoreVersion,
      } = activeModal.opts

      return renderModal(
        <DesignVersionModal
          version={version}
          loadVersionsList={loadVersionsList}
          type={type}
          deleteVersion={deleteVersion}
          restoreVersion={handleRestoreVersion}
        />,
        { size: 'sm' }
      )
    }
    case CONFIRM_RESTORE_MODAL: {
      const { restoreVersion } = activeModal.opts

      return renderModal(
        <ConfirmRestoreModal restoreVersion={restoreVersion} />,
        { size: 'sm' }
      )
    }
    case TERMS_OF_SERVICE_MODAL: {
      return renderModal(<TermsModal />, { size: 'sm', fixedHeight: true })
    }
    case UNPUBLISH_APP_MODAL: {
      return renderModal(<UnpublishAppModal />, {
        size: 'xs',
        constrained: true,
        borderless: true,
        contentPosition: 'center',
        showClose: true,
        constrainContent: true,
      })
    }
    case CANCEL_SUBSCRIPTION_MODAL: {
      return renderModal(<CancelSubscription />, {
        size: 'xs',
        constrained: true,
        borderless: true,
        contentPosition: 'center',
        showClose: true,
        constrainContent: true,
      })
    }
    case CHOOSE_ZAP_MODAL: {
      const { zapType, appId } = activeModal.opts

      return renderModal(
        <ChooseZapModal zapType={zapType} appId={appId} user={user} />,
        { size: 'lg' }
      )
    }
    case FEATURE_TEMPLATE_MODAL: {
      const { appId, linkingObjectId } = activeModal.opts

      return renderModal(
        <FeatureTemplateModal
          appId={appId}
          linkingObjectId={linkingObjectId}
        />,
        { size: 'lg' }
      )
    }
    case FEATURE_TEMPLATE_DETAILS_MODAL: {
      const { appId, feature, datasourceId, linkingObjectId, origin } =
        activeModal.opts

      return renderModal(
        <FeatureTemplateDetails
          feature={feature}
          appId={appId}
          datasourceId={datasourceId}
          linkingObjectId={linkingObjectId}
          origin={origin}
        />,
        { size: 'lg' }
      )
    }
    case CHURN_FORM_MODAL: {
      return renderModal(<ChurnFormModal />, {
        size: 'md',
        smallPadding: true,
        constrained: true,
        borderless: true,
      })
    }
    default: {
      return null
    }
  }
}

const mapStateToProps = state => ({
  user: getCurrentUser(state),
  activeModal: getActiveModal(state),
  hasNewAddMenuWithSections: getFeatureFlag(state, 'hasNewAddMenuWithSections'),
})

export default connect(mapStateToProps)(CleanModalWrapper)
