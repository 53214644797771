import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ToggleButton from '../ToggleButton'

import './ToggleField.scss'

const ToggleField = props => {
  let {
    name,
    boxed,
    label,
    inputValue,
    helpText,
    onChange,
    handleChange,
    onInputChange,
    value,
    hideLabelOnDisabled,
    hideInput,
    input,
    disabled,
    color,
    inverted,
    largeHelpText,
  } = props

  if (input) {
    name = input.name
    onChange = input.onChange
    value = input.value
  }

  return (
    <div
      className={classNames('toggle-field', {
        'toggle-field-boxed': boxed,
        'toggle-field-enabled': value,
        'toggle-field-disabled': !value,
        'toggle-field-label-hidden': !value && hideLabelOnDisabled,
        'toggle-field-input-hidden': hideInput,
        'toggle-field-large-help-text': largeHelpText,
      })}
    >
      {label && <label>{label}</label>}
      <div className={classNames('toggle-field-content')}>
        {!hideInput && (
          <input
            type="text"
            value={inputValue}
            onChange={onInputChange}
            disabled={!onInputChange}
          />
        )}
        <ToggleButton
          name={name}
          onChange={onChange || handleChange}
          value={!!value}
          disabled={disabled}
          color={color}
          inverted={inverted}
        />
      </div>
      {helpText && <span>{helpText}</span>}
    </div>
  )
}

ToggleField.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  boxed: PropTypes.bool,
  hideLabelOnDisabled: PropTypes.bool,
  inputValue: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
}

ToggleField.defaultProps = {
  boxed: false,
  hideLabelOnDisabled: false,
  disabled: false,
}

export default ToggleField
