import { backgroundStyle } from '@adalo/constants'
import BaseObject from './BaseObject'

export default class BaseShape extends BaseObject {
  getBorderWidth() {
    return this.props.borderWidth
  }

  getBorderProps() {
    const { borderColor, borderStyle } = this.props

    if (!borderStyle || borderStyle === borderStyle.NONE) {
      return {
        strokeWidth: 0,
        stroke: 'transparent',
      }
    }

    const strokeWidth = this.getBorderWidth()

    const borderProps = {
      strokeWidth,
      stroke: borderColor,
    }

    if (borderStyle === borderStyle.DOTTED) {
      borderProps.strokeDasharray = `${strokeWidth}, ${strokeWidth}`
    }

    if (borderStyle === borderStyle.DASHED) {
      borderProps.strokeDasharray = `${strokeWidth * 4}, ${strokeWidth * 4}`
    }

    return borderProps
  }

  getBackgroundColor() {
    return this.props.backgroundStyle === backgroundStyle.COLOR
      ? this.props.backgroundColor
      : 'transparent'
  }

  getShadowProps() {
    const { x, y, width, height, shadow, zoom } = this.props
    const border = this.getBorderProps()

    return {
      x,
      y,
      width,
      height,
      shadow,
      zoom,
      border,
    }
  }

  getBorderRadius() {
    let { width, height, borderRadius } = this.props

    borderRadius = borderRadius || 0

    return Math.floor(Math.min(width / 2, height / 2, borderRadius))
  }
}
