import React from 'react'
import classNames from 'classnames'
import { useFlags } from 'flags'

import Accordion, { GroupedAccordion } from '../Accordion'
import Button from '../Button'

import Icon from '../Icon'

import './StylesAccordion.scss'

const StylesAccordion = ({ spaced, title, accordionItemId, ...props }) => {
  const { hasNewEditStyles } = useFlags()

  const renderTitle = (expanded = false) => {
    return (
      <div className="edit-styles-accordion-title">
        <Button outlined focussed={expanded}>
          {title ?? 'Edit Styles'}
        </Button>
      </div>
    )
  }

  const renderNewTitle = (expanded = false) => {
    return (
      <div className="edit-styles-accordion-title">
        <Button
          outlined
          focussed={expanded}
          square
          block
          fluid
          icon="palette-outline"
          darkPink={expanded}
        >
          {title ?? `Edit Styles`}
          <Icon type="pencil-small" small />
        </Button>
      </div>
    )
  }

  const renderChildrenWrapper = () => {
    const { renderChildren } = props

    return (
      <div className="edit-styles-accordion-children-wrapper">
        {renderChildren()}
      </div>
    )
  }

  if (hasNewEditStyles) {
    return (
      <GroupedAccordion
        className={classNames(
          'edit-styles-accordion',
          spaced && 'edit-styles-accordion-spaced',
          'edit-styles-accordion--new'
        )}
        group={'edit-styles-accordion'}
        itemId={accordionItemId ?? 'edit-styles-accordion'}
        key={'edit-styles-accordion'}
        renderHeader={renderNewTitle}
        expandedTitle={renderNewTitle(true)}
        renderChildren={renderChildrenWrapper}
      />
    )
  }

  return (
    <Accordion
      hideCarret
      className={classNames(
        'edit-styles-accordion',
        spaced && 'edit-styles-accordion-spaced'
      )}
      title={renderTitle()}
      expandedTitle={renderTitle(true)}
      renderChildren={renderChildrenWrapper}
    />
  )
}

export default StylesAccordion
