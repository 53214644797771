import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { singularize, pluralize } from 'utils/strings'
import { NEW_RELATIONSHIP } from 'ducks/editor/modals'
import { getTable } from 'ducks/apps/datasources'

import Modal from 'components/Shared/Modal'
import Selector from 'components/Shared/Forms/Selector'

const Form = props => {
  const {
    onCancel,
    handleSubmit,
    pristine,
    selectedCollection,
    expandedCollection,
    opts,
  } = props

  const sel = selectedCollection && selectedCollection.name

  const exp = expandedCollection
    ? expandedCollection.name
    : opts.newCollectionName
    ? opts.newCollectionName
    : 'Untitled Collection'

  const title = `How are ${sel} & ${exp} related?`

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Modal.Header title={title} />
      <Modal.Content>
        <div className="new-relationship-key">
          <div className="group">
            <KeyItem small type="selected" name={sel} /> = {sel}
          </div>
          <div className="group">
            <KeyItem small type="expanded" name={exp} /> = {exp}
          </div>
        </div>

        <Field name="type" component={Selector} required>
          <Selector.Item value="belongsTo">
            <Relationship fromTable={exp} toTable={sel} type="belongsTo" />
          </Selector.Item>
          <Selector.Item value="hasMany">
            <Relationship fromTable={exp} toTable={sel} type="hasMany" />
          </Selector.Item>
          <Selector.Item value="manyToMany">
            <Relationship fromTable={exp} toTable={sel} type="manyToMany" />
          </Selector.Item>
        </Field>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button type="button" text onClick={onCancel}>
          Cancel
        </Modal.Button>
        <Modal.Button type="submit" orange disabled={pristine}>
          Done
        </Modal.Button>
      </Modal.Actions>
    </form>
  )
}

const getItems = (buildItem, count) => {
  const items = []

  for (let i = 0; i < count; i += 1) {
    const itm = React.cloneElement(buildItem(), { key: i })

    items.push(itm)
  }

  return items
}

export const Relationship = props => {
  const { type } = props

  return (
    <div className={classNames('new-relationship-item', type)}>
      <RelationshipGraphic {...props} />
      <div className="relationship-text">
        <RelationshipText {...props} />
      </div>
    </div>
  )
}

export const RelationshipGraphic = props => {
  const { type, fromTable, toTable } = props
  const fromCount = type === 'hasMany' ? 1 : 3
  const toCount = type === 'belongsTo' ? 1 : 3

  const fromItems = getItems(
    () => <KeyItem type="expanded" name={fromTable} />,
    fromCount
  )

  const toItems = getItems(
    () => <KeyItem type="selected" name={toTable} />,
    toCount
  )

  return (
    <div className="relationship-graphic">
      <div className="relationship-graphic-target">{toItems}</div>
      <div className={classNames('relationship-graphic-connections', type)} />
      <div className="relationship-graphic-target">{fromItems}</div>
    </div>
  )
}

export const RelationshipText = opts => {
  const { fromTable, toTable, type } = opts

  const singularFrom = singularize(fromTable)
  const pluralFrom = pluralize(fromTable)

  const singularTo = singularize(toTable)
  const pluralTo = pluralize(toTable)

  if (type === 'belongsTo') {
    return (
      <>
        <p className="relationship-description">
          {`A `}
          <strong className="selected">{singularTo}</strong>
          {` can have multiple `}
          <strong className="expanded">{pluralFrom}</strong>
        </p>
        <p className="relationship-description">
          {`A `}
          <strong className="expanded">{singularFrom}</strong>
          {` belongs to one `}
          <strong className="selected">{singularTo}</strong>
        </p>
      </>
    )
  } else if (type === 'hasMany') {
    return (
      <>
        <p className="relationship-description">
          {`A `}
          <strong className="selected">{singularTo}</strong>
          {` can only have one `}
          <strong className="expanded">{singularFrom}</strong>
        </p>
        <p className="relationship-description">
          {`A `}
          <strong className="expanded">{singularFrom}</strong>
          {` can have multiple `}
          <strong className="selected">{pluralTo}</strong>
        </p>
      </>
    )
  }

  return (
    <>
      <p className="relationship-description">
        {`A `}
        <strong className="selected">{singularFrom}</strong>
        {` can have multiple `}
        <strong className="expanded">{pluralTo}</strong>
      </p>
      <p className="relationship-description">
        {`A `}
        <strong className="expanded">{singularTo}</strong>
        {` can have multiple `}
        <strong className="selected">{pluralFrom}</strong>
      </p>
    </>
  )
}

export const KeyItem = ({ type, name, small }) => {
  const initial = name && String(name).charAt(0)

  return (
    <span
      className={classNames('new-relation-key-item', {
        small,
        'expanded-collection': type === 'expanded',
        'selected-collection': type === 'selected',
      })}
    >
      {initial}
    </span>
  )
}

const mapStateToProps = (state, { opts }) => {
  const {
    appId,
    selectedCollection: selected,
    expandedCollection: expanded,
  } = opts

  let selectedCollection
  let expandedCollection

  if (selected) {
    selectedCollection = getTable(
      state,
      appId,
      selected.datasourceId,
      selected.tableId
    )
  }

  if (expanded) {
    expandedCollection = getTable(
      state,
      appId,
      expanded.datasourceId,
      expanded.tableId
    )
  }

  return {
    selectedCollection,
    expandedCollection,
  }
}

const connected = connect(mapStateToProps)(Form)

export default reduxForm({
  form: NEW_RELATIONSHIP,
})(connected)
