import React, { useContext } from 'react'
import {
  comparatorDistanceUnitLabels,
  comparatorDistanceUnits,
  comparators,
  dataTypes,
} from '@adalo/constants'

import ComparisonField from 'components/Shared/ComparisonField'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { Field } from 'redux-form'
import { MenuOption } from 'ducks/recommender'

const StyleContext = React.createContext({})

function joinFieldNames(fieldName, ...fieldNames) {
  if (fieldNames.length === 0) {
    return fieldName
  }

  const [next, ...rest] = fieldNames

  // A falsy fieldName segment is valid but skipped in the joining.
  if (!fieldName) {
    return joinFieldNames(next, ...rest)
  }

  if (!next) {
    return joinFieldNames(fieldName, ...rest)
  }

  return joinFieldNames(`${fieldName}.${next}`, ...rest)
}

function SimpleComparisonOptions({
  fieldName,
  appId,
  componentId,
  objectId,
  comparisonDataType,
  comparison,
  reference,
}) {
  const { boxed, fieldClassName, menuTheme } = useContext(StyleContext)

  return (
    <ComparisonField
      name={joinFieldNames(fieldName, 'comparison')}
      className={fieldClassName}
      appId={appId}
      componentId={componentId}
      objectId={objectId}
      dataTypes={[comparisonDataType]}
      placeholder="Empty"
      value={comparison}
      reference={reference}
      boxed={boxed}
      menuTheme={menuTheme}
    />
  )
}

function BetweenComparisonOptions({
  fieldName,
  appId,
  componentId,
  objectId,
  comparisonDataType,
  comparison,
  comparison2,
  reference,
}) {
  const { boxed, fieldClassName, menuTheme } = useContext(StyleContext)

  return (
    <>
      <div className="action-item-split-row">
        <ComparisonField
          name={joinFieldNames(fieldName, 'comparison')}
          className={fieldClassName}
          appId={appId}
          componentId={componentId}
          objectId={objectId}
          dataTypes={[comparisonDataType]}
          placeholder="Empty"
          value={comparison}
          reference={reference}
          boxed={boxed}
          menuTheme={menuTheme}
        />
      </div>
      <ComparisonField
        name={joinFieldNames(fieldName, 'comparison2')}
        className={fieldClassName}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        dataTypes={[comparisonDataType]}
        placeholder="Empty"
        value={comparison2}
        reference={reference}
        boxed={boxed}
        menuTheme={menuTheme}
      />
    </>
  )
}

function DistanceComparisonOptions({
  fieldName,
  appId,
  componentId,
  objectId,
  comparatorOptions,
  comparisonDataType,
  comparison,
  reference,
}) {
  const { boxed, fieldClassName, menuTheme } = useContext(StyleContext)

  const unitOptions = [
    comparatorDistanceUnits.MILES,
    comparatorDistanceUnits.KILOMETERS,
  ].map(unit => new MenuOption(comparatorDistanceUnitLabels[unit], unit, unit))

  return (
    <>
      <div className="action-item-split-row">
        <ComparisonField
          name={joinFieldNames(fieldName, 'comparatorOptions', 'radius')}
          className={fieldClassName}
          appId={appId}
          componentId={componentId}
          objectId={objectId}
          dataTypes={[dataTypes.NUMBER]}
          placeholder="Empty"
          value={comparatorOptions?.radius}
          reference={reference}
          boxed={boxed}
          menuTheme={menuTheme}
        />
      </div>
      <div className="action-item-split-row">
        <Field
          name={joinFieldNames(fieldName, 'comparatorOptions', 'unit')}
          component={WrappedSelect}
          // WrappedSelect props
          className={fieldClassName}
          autoSelect
          options={unitOptions}
          displayName="Unit"
          placeholder="Select unit..."
          rowHeight={40}
          boxed={boxed}
        />
      </div>
      <ComparisonField
        name={joinFieldNames(fieldName, 'comparison')}
        className={fieldClassName}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        dataTypes={[comparisonDataType]}
        placeholder="Empty"
        value={comparison}
        reference={reference}
        boxed={boxed}
        menuTheme={menuTheme}
      />
    </>
  )
}

export default function ComparatorOptions({
  fieldName,
  appId,
  componentId,
  objectId,
  comparator,
  comparatorOptions,
  comparisonDataType,
  comparison,
  comparison2,
  reference,
  boxed,
  fieldClassName,
  menuTheme,
}) {
  const style = {
    boxed,
    fieldClassName,
    menuTheme,
  }

  switch (comparator) {
    case comparators.TRUE:
    // fall through
    case comparators.FALSE:
      return null
    case comparators.BETWEEN:
      return (
        <StyleContext.Provider value={style}>
          <BetweenComparisonOptions
            fieldName={fieldName}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
            comparisonDataType={comparisonDataType}
            comparison={comparison}
            comparison2={comparison2}
            reference={reference}
          />
        </StyleContext.Provider>
      )
    case comparators.DISTANCE_LESS_THAN:
    // fall through
    case comparators.DISTANCE_GREATER_THAN:
      return (
        <StyleContext.Provider value={style}>
          <DistanceComparisonOptions
            fieldName={fieldName}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
            comparatorOptions={comparatorOptions}
            comparisonDataType={comparisonDataType}
            comparison={comparison}
            reference={reference}
          />
        </StyleContext.Provider>
      )
    default:
      return (
        <StyleContext.Provider value={style}>
          <SimpleComparisonOptions
            fieldName={fieldName}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
            comparisonDataType={comparisonDataType}
            comparison={comparison}
            reference={reference}
          />
        </StyleContext.Provider>
      )
  }
}
