import React from 'react'
import TeamSwitcher from './TeamSwitcher'
import Stripe from '../../Shared/Stripe'
import './Navbar.css'

const Navbar = props => {
  const { children } = props

  return (
    <div className="app-navbar">
      <Stripe className="app-navbar-stripe" />
      <div className="app-navbar-brand">Adalo</div>
      {children}
      <TeamSwitcher />
    </div>
  )
}

export default Navbar

export const Spacer = () => <div className="navbar-spacer" />
