import { GROUP_TYPE_INPUT, GROUP, LABEL } from '@adalo/constants'

import { pathLength, getObject, subPath, update } from '@adalo/utils'

export const updateOptions = (list, map, groupId, path = null) => {
  if (!path) {
    path = map[groupId]
  }

  const obj = getObject(list, path)

  if (obj.type !== GROUP) {
    return updateParentOptions(list, map, obj.id)
  }

  let newObj = obj

  if (obj.groupType === GROUP_TYPE_INPUT) {
    const children = obj.children
    let textInputObject = null

    children
      .filter(c => c.type === LABEL)
      .forEach(child => {
        if (!textInputObject || child.fontSize > textInputObject.fontSize) {
          textInputObject = child
        }
      })

    let groupTypeOptions = obj.groupTypeOptions || {}

    const inputOptions = {
      ...groupTypeOptions[GROUP_TYPE_INPUT],
      textInputObject: textInputObject.id,
    }

    groupTypeOptions = {
      ...groupTypeOptions,
      [GROUP_TYPE_INPUT]: inputOptions,
    }

    newObj = {
      ...obj,
      groupTypeOptions,
    }
  }

  if (obj !== newObj) {
    list = update(list, path, newObj)
  }

  return list
}

export const updateParentOptions = (list, map, objectId) => {
  const path = map[objectId]

  if (pathLength(path) > 1) {
    const parentPath = subPath(path, pathLength(path) - 1)
    const parentObj = getObject(list, parentPath)
    const parentId = parentObj.id

    list = updateOptions(list, map, parentId)
  }

  return list
}
