import React, { Component } from 'react'
import { indexOf, matchLists } from '@adalo/utils'
import Modal from '../../../Shared/Modal'

import { getOrderedFields } from '../../../../ducks/apps/datasources'
import WrappedSelect from '../../../Shared/Forms/WrappedSelect'
import {
  getManyToManyRelations,
  getHasManyRelations,
} from '../../../../utils/relations'

export default class Matching extends Component {
  constructor(props) {
    super(props)

    const { header, table } = props

    //filters out unwanted relationship columns from mapping
    const filteredFields = Object.keys(table.fields).filter(
      fieldId =>
        !(
          table.fields[fieldId]?.type?.type === 'manyToMany' ||
          table.fields[fieldId]?.type?.type === 'hasMany'
        )
    )

    const fields = filteredFields.map(fieldId => table.fields[fieldId].name)

    const matches = matchLists(header, fields)

    const mapping = {}

    for (const key of Object.keys(matches)) {
      const index = indexOf(header, k => k === key)

      for (const fieldId in table.fields) {
        if (table.fields[fieldId].name === matches[key]) {
          mapping[index] = fieldId
        }
      }
    }

    this.state = {
      mapping,
      submitting: false,
    }
  }

  getFieldOptions = () => {
    const { table, datasource, tableId } = this.props

    const orderedFields = getOrderedFields(table)

    const hasManyRelations = getHasManyRelations(datasource, tableId)

    const manyToManyRelations = getManyToManyRelations(datasource, tableId)

    const relationsToRemove = []

    hasManyRelations.forEach(table => {
      relationsToRemove.push(table.fieldId)
    })

    manyToManyRelations.forEach(table => {
      relationsToRemove.push(table.fieldId)
    })

    // this removes all the many-to-many and many-to-one relationships from the
    // ordered fields list
    const orderedFieldsToDisplay = orderedFields.filter(
      x => !relationsToRemove.includes(x)
    )

    let options = [{ label: 'None', value: null }, null]

    options = options.concat(
      orderedFieldsToDisplay.map(fieldId => ({
        label: table.fields[fieldId].name,
        value: fieldId,
      }))
    )

    return options
  }

  handleChange = index => fieldId => {
    let { mapping } = this.state

    mapping = { ...mapping }

    for (const i in mapping) {
      if (mapping[i] && mapping[i] === fieldId) {
        mapping[i] = null
      }
    }

    mapping[index] = fieldId

    this.setState({ mapping })
  }

  handleImport = async () => {
    const { onSubmit } = this.props
    const { mapping } = this.state

    this.setState({ submitting: true })

    await onSubmit(mapping)
  }

  render() {
    const { mapping, submitting } = this.state
    const { header, onCancel } = this.props
    const options = this.getFieldOptions()

    return (
      <>
        <Modal.Content>
          <Modal.Container size="sm">
            <div className="csv-import-matching-row">
              <p>CSV Column</p>
              <p>Property</p>
            </div>
            {header.map((headerItem, i) => (
              <div
                key={i} // eslint-disable-line
                className="csv-import-matching-row"
              >
                <div className="csv-import-matching-source">{headerItem}</div>
                <div className="csv-import-matching-destination">
                  <WrappedSelect
                    className="wrapped-select"
                    options={options}
                    value={mapping[i]}
                    onChange={this.handleChange(i)}
                  />
                </div>
              </div>
            ))}
          </Modal.Container>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button type="button" text onClick={onCancel}>
            Cancel
          </Modal.Button>
          <Modal.Button
            type="button"
            orange
            onClick={this.handleImport}
            loading={submitting}
          >
            Import Data
          </Modal.Button>
        </Modal.Actions>
      </>
    )
  }
}
