import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'

import { getAppBranding } from 'ducks/apps'

import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
import ControlField from 'components/Shared/Forms/ControlField'
import ColorDropdown from 'components/Editor/Inspect/ColorDropdown'

import { FORM_NAME, validations } from './shared'
import LaunchScreenPreview from './LaunchScreenPreview'
import RequestPermission from './RequestPermission'

const AppSettingsForm = ({ appId, handleSubmit, publishing, isAdmin }) => {
  const selector = formValueSelector(FORM_NAME)
  const showAdminOptions = isAdmin === true
  const formValues = useSelector(state =>
    selector(state, 'launchScreenIcon', 'launchScreenColor')
  )

  const branding = useSelector(state => getAppBranding(state, appId))

  return (
    <form id={FORM_NAME} onSubmit={handleSubmit} autoComplete="off">
      <Field
        component={SettingsFormField}
        name="displayName"
        label="App Display Name"
        placeholder="Untitled App"
        type="text"
        validate={publishing && validations.displayName}
      />
      <div className="settings-form-launch-screen-wrapper">
        <div className="settings-form-launch-screen-column">
          <Field
            isAsset
            appId={appId}
            component={SettingsFormField}
            name="launchScreenIcon"
            label="Launch Screen Graphic"
            type="image"
            validate={publishing && validations.launchScreenIcon}
          />
          <div>
            <label>Launch Screen Background Color</label>
            <ControlField
              component={ColorDropdown}
              normalize={false}
              name="launchScreenColor"
              defaultValue="#fff"
              branding={branding}
              validate={publishing && validations.launchScreenColor}
            />
          </div>
        </div>
        <div className="settings-form-launch-screen-column">
          <label>Launch Screen Preview</label>
          <LaunchScreenPreview {...formValues} />
        </div>
      </div>
      <div className="settings-form-row">
        <div className="settings-form-label-wrapper">
          <label>App Request Permission</label>
        </div>
        <RequestPermission
          title="Camera Permission Text"
          name="cameraUsageDescription"
          displayName="Text"
          placeholder="We need to access your camera to allow you to upload a photo."
          description="Apple requires you to explain why your app needs camera access."
          goodExample="We need to access your camera to allow you to upload a profile picture, photos from your trips, etc."
          badExample="We need to access camera."
        />
        <RequestPermission
          title="Photo Library Permission Text"
          name="photoLibraryDescription"
          displayName="Text"
          placeholder="We need to to access your photos to allow you to save a photo you take."
          description="Apple requires you to explain why your app needs photo library access."
          goodExample="We need to access your photo library to allow you to upload a profile picture, photos from your trips, etc."
          badExample="We need to access photo library."
        />
        <RequestPermission
          title="Location Permission Text"
          name="locationUsageDescription"
          displayName="Text"
          placeholder="Allow us to access your location so we can show local recommendations."
          description="Apple requires you to explain why your app needs location access."
          goodExample="We need to access your location to allow you to see what destinations, trips, and restaurants are nearby."
          badExample="We need to access location."
        />
        {showAdminOptions && (
          <>
            <Field
              component={SettingsFormField}
              name="branch"
              label="Template App Branch"
              tooltip="Set this to 'feature/ipad-support' if you want a maker's app to have ipad support."
              type="text"
              placeholder="main"
            />
            <Field
              component={SettingsFormField}
              name="skipBuildEnqueue"
              label="Skip build enqueue:"
              tooltip="Set this to not enqueue the build job for packager to pick up. You'll get a command to run the build manually in the browser console."
              type="checkbox"
              placeholder=""
            />
          </>
        )}
      </div>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AppSettingsForm)
