import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import { getOrgApps, requestOrgApps, requestApps } from 'ducks/apps'
import { getCurrentOrganization, getOrganizations } from 'ducks/organizations'

import Icon from 'components/Shared/Icon'
import AppIcon from 'components/Shared/AppIcon'
import Dropdown from 'components/App/Navbar/Dropdown'
import addButtonIcon from 'components/Editor/LeftNav/add-button.svg'
import NewAppModal from 'components/NewApp/Modal'
import { setDocumentTitle } from 'utils/browsers'
import { isCurrentPlanType } from 'utils/billing.ts'

const AppLink = ({
  app,
  className,
  children,
  isCurrent,
  icon,
  name,
  organization,
  isPublished = false,
  ...otherProps
}) => {
  const LinkComponent = otherProps.to ? Link : 'a'

  return (
    <LinkComponent
      className={classNames(
        {
          'app-switcher-active-link': isCurrent,
        },
        className
      )}
      {...otherProps}
    >
      {icon ? (
        <img className="app-switcher-link-icon" src={icon} alt={name} />
      ) : app ? (
        <AppIcon medium app={app} />
      ) : null}
      {
        <div className="app-switcher-link-center">
          <div className="app-switcher-link-text">{children}</div>
          {isPublished ? (
            <Icon
              type="done-all"
              color="green"
              iconStyle="circle"
              tooltip="Published! 🙌"
              placement="right"
              tooltipClassname="published-tooltip"
              tooltipTriggerClassname="published-icon-tooltip-trigger"
            />
          ) : null}
        </div>
      }
    </LinkComponent>
  )
}

AppLink.defaultProps = {
  isCurrent: false,
}

const OrgAppSwitcher = ({ location }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestApps())
  }, [])

  const currentOrganization = useSelector(state =>
    getCurrentOrganization(state)
  )

  const currentAppId = useMemo(() => getAppId(location), [location])

  const apps = useSelector(state => {
    let orgApps = getOrgApps(state)

    if (orgApps) {
      orgApps = Object.values(orgApps)
    }

    return orgApps || []
  })

  const joinedApps = apps && [].concat(...apps)
  const currentApp = joinedApps.find(app => app.id === currentAppId)

  useEffect(() => {
    if (currentOrganization?.id) {
      dispatch(requestOrgApps(currentOrganization.id))
    }
  }, [currentOrganization?.id])

  useMemo(() => {
    if (currentApp?.name) {
      setDocumentTitle(currentApp.name)
    }
  }, [currentApp?.name])

  const organizations = useSelector(state => getOrganizations(state))

  const [isNewAppModalOpen, setAppModalOpen] = useState(false)

  if (!currentApp || !currentOrganization || !apps) {
    return null
  }

  const { planType } = currentOrganization
  const orgIsCurrentPlan = isCurrentPlanType(planType)

  const showPublishIcon = currentApp.published && orgIsCurrentPlan

  return (
    <div className="app-switcher-container">
      <Dropdown
        title={
          <div className="app-switcher-current-app" title={currentApp.name}>
            <span className="app-switcher-current-app-name">
              {currentApp.name}
            </span>
            {showPublishIcon ? (
              <Icon type="done-all" color="green" iconStyle="circle" />
            ) : null}
            <Icon type="expand-vertical" />
          </div>
        }
        className="app-switcher"
      >
        {organizations.length > 1 ? (
          <div
            className="app-switcher-organization-name"
            data-adalo-id="app-switcher"
          >
            {currentOrganization.name}
          </div>
        ) : null}
        <div className="app-switcher-dropdown-inner">
          {renderApps(apps, currentApp, orgIsCurrentPlan)}
        </div>
        <AppLink
          className="app-switcher-new-app-link app-switcher-link"
          href="/new"
          icon={addButtonIcon}
          name="New App"
          onClick={e => {
            e.preventDefault()
            setAppModalOpen(true)
          }}
        >
          CREATE NEW APP
        </AppLink>
      </Dropdown>
      {isNewAppModalOpen ? (
        <NewAppModal onClose={() => setAppModalOpen(false)} />
      ) : null}
    </div>
  )
}

const getAppId = location => {
  if (location?.match?.appId) {
    return location.match.appId
  }

  const match = location.pathname.match(/^\/apps\/([\w\-]+)(\/|$)/)

  return match && match[1]
}

const renderApps = (apps, currentApp, orgIsCurrentPlan) => {
  const appsList = []

  for (const sharedApps of apps) {
    if (sharedApps.length > 1) {
      const firstAppId = sharedApps[0]?.id

      appsList.push(
        <div key={firstAppId} className="app-switcher-shared-database">
          <div className="app-switcher-shared-database-title">
            Shared Database{' '}
            {orgIsCurrentPlan && sharedApps.find(app => app.published) ? (
              <Icon
                type="done-all"
                color="green"
                iconStyle="circle"
                tooltip="Published! 🙌"
                placement="right"
                tooltipClassname="published-tooltip"
                tooltipTriggerClassname="published-icon-tooltip-trigger"
                className="shared-database-published-icon"
              />
            ) : null}
          </div>
          {sharedApps.map(app => (
            <AppLink
              app={app}
              key={app.id}
              to={`/apps/${app.id}`}
              isCurrent={currentApp.id === app.id}
              organization={app.Organization && app.Organization.name}
              name={app.name}
              className="app-switcher-shared-database-link"
            >
              {app.name}
            </AppLink>
          ))}
        </div>
      )
    } else {
      const app = sharedApps[0]

      const appLink = (
        <div
          key={app.id}
          className={classNames({
            'app-switcher-shared-database': app.hasSharedDatasource,
          })}
        >
          {app.hasSharedDatasource ? (
            <div className="app-switcher-shared-database-title">
              Shared Database{' '}
              {orgIsCurrentPlan && app.published ? (
                <Icon type="done-all" color="green" iconStyle="circle" small />
              ) : null}
            </div>
          ) : null}
          <AppLink
            app={app}
            key={app.id}
            to={`/apps/${app.id}`}
            isCurrent={currentApp.id === app.id}
            organization={app.Organization && app.Organization.name}
            name={app.name}
            className={
              app.hasSharedDatasource
                ? 'app-switcher-shared-database-link'
                : 'app-switcher-link'
            }
            isPublished={
              orgIsCurrentPlan && app.published && !app.hasSharedDatasource
            }
          >
            {app.name}
          </AppLink>
        </div>
      )

      appsList.push(appLink)
    }
  }

  return appsList
}

export default withRouter(OrgAppSwitcher)
