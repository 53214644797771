import React, { Component } from 'react'
import Accordion from '../../../Shared/Accordion'
import DefaultCheckbox from './DefaultCheckbox'

export default class Image extends Component {
  render() {
    return (
      <Accordion
        title="Advanced"
        renderChildren={() => (
          <div>
            <DefaultCheckbox />
          </div>
        )}
      />
    )
  }
}
