import React from 'react'
import { useSelector } from 'react-redux'

import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'

import { getPricingPlans } from 'ducks/billing'
import {
  getCyclesAppActions,
  getCycleAppActionsByApp,
} from 'ducks/organizations'

import { isCurrentPlanType, getCurrentCycle } from 'utils/billing.ts'

import AppActionsOverTimeChart from './AppActionsOverTimeChart'
import AppActionsByAppChart from './AppActionsByAppChart'
import AppActionsSpendingLimit from './AppActionsSpendingLimit'
import UsageStats from './UsageStats'

import './UsageSection.scss'

const UsageSection = props => {
  const { organization, appId } = props

  if (!organization) {
    return null
  }

  const { planType } = organization

  const cycles = useSelector(state => getCyclesAppActions(state))
  const pricingPlans = useSelector(state => getPricingPlans(state))
  const actionsByApp = useSelector(state => getCycleAppActionsByApp(state))
  const isNewPlanType = isCurrentPlanType(planType)

  if (!cycles || !pricingPlans || !actionsByApp) {
    return (
      <EmptyState className="usage-section">
        <Loading />
      </EmptyState>
    )
  }

  const currentCycle = getCurrentCycle(cycles)

  return (
    <>
      <h3 className="settings-header">Monthly Usage</h3>
      {isNewPlanType && (
        <UsageStats
          organization={organization}
          pricingPlans={pricingPlans}
          actionsByApp={actionsByApp}
        />
      )}
      <AppActionsByAppChart
        organization={organization}
        pricingPlans={pricingPlans}
        actionsByApp={actionsByApp}
        currentCycle={currentCycle}
      />
      <AppActionsOverTimeChart
        organization={organization}
        appId={appId}
        cycles={cycles}
        pricingPlans={pricingPlans}
      />
      {isNewPlanType ? (
        <AppActionsSpendingLimit
          organization={organization}
          appId={appId}
          cycles={cycles}
          pricingPlans={pricingPlans}
        />
      ) : null}
    </>
  )
}

export default UsageSection
