import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DocumentEvents from 'react-document-events'

import ModalHeader from './ModalHeader'
import ModalContent from './ModalContent'
import ModalContainer from './ModalContainer'
import ModalActions from './ModalActions'
import ModalError from './ModalError'

import Button from '../Button'

import './Modal.scss'
import { IconButton } from '../Icon'

const ESC = 27

export default class Modal extends Component {
  static childContextTypes = {
    onClose: PropTypes.func,
  }

  static propTypes = {
    grayOut: PropTypes.bool,
    hideDimmer: PropTypes.bool,
    scrolling: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'fluid']),
    smallPadding: PropTypes.bool,
  }

  static defaultProps = {
    grayOut: true,
    scrolling: false,
    size: 'sm',
    smallPadding: false,
  }

  static Header = ModalHeader
  static Container = ModalContainer
  static Content = ModalContent
  static Actions = ModalActions
  static Button = Button
  static Error = ModalError

  getChildContext() {
    const { onClose } = this.props

    return { onClose }
  }

  handleKeyDown = e => {
    const { onClose } = this.props

    if (['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) >= 0) {
      return
    }

    if (e.which === ESC && onClose) {
      onClose()
    }
  }

  preventClose = e => {
    e.stopPropagation()
  }

  renderModal = () => {
    const {
      className,
      children,
      showClose,
      onClose,
      white,
      constrained,
      grayOut,
      scrolling,
      size,
      hideDimmer,
      top,
      fixedHeight,
      fitContent,
      borderless,
      contentPosition,
      smallPadding,
      constrainContent = false,
    } = this.props

    return (
      <div
        className={classNames('modal-wrapper', {
          'modal-wrapper-constrained': constrained,
          'modal-wrapper-white': white,
          'modal-wrapper-gray-out': grayOut,
          'modal-wrapper-hide-dimmer': hideDimmer,
          'modal-wrapper-fixed-height': fixedHeight,
          'modal-wrapper-borderless': borderless,
          [`modal-wrapper-content-position-${contentPosition}`]:
            contentPosition,
          'modal-wrapper-content-constrained': constrainContent,
          'modal-wrapper-small-padding': smallPadding,
        })}
        onClick={onClose}
      >
        <div
          className={classNames('modal-inner-wrapper', {
            'modal-inner-wrapper--top': top,
          })}
        >
          <DocumentEvents onKeyDown={this.handleKeyDown} />
          <div
            className={classNames('modal', className, {
              'modal-scrolling': scrolling,
              [`modal-width-${size}`]: size,
              'modal-fit-content': fitContent,
            })}
            onClick={this.preventClose}
          >
            {showClose ? (
              <IconButton
                className="modal-close"
                type="close"
                onClick={onClose}
                small
              />
            ) : null}
            {children}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return ReactDOM.createPortal(this.renderModal(), document.body)
  }
}
