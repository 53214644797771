import React from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { useSelector, connect } from 'react-redux'
import { dataTypes } from '@adalo/constants'

import { getComparatorOptions, getComparisonDataTypes } from 'utils/comparators'

import { getLabel } from 'utils/sources'
import { getSources } from 'ducks/recommender'
import Accordion from 'components/Shared/Accordion'
import ComparatorOptions from 'components/Shared/Filters/ComparatorOptions'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'

import FilteringExtension from '../../DataBinding/FilteringFormExtension'

function BindingForm({
  form,
  appId,
  componentId,
  binding,
  object,
  label,
  bindingOptions,
  handleSubmit,
  hideAdvanced,
  displayName,
  tooltip,
}) {
  const { source } = binding

  if (!source) {
    return null
  }

  const parentSource = source.source
  const showFilter = parentSource && parentSource.dataType === dataTypes.LIST

  const selector = formValueSelector(form)
  const comparator = useSelector(state => selector(state, 'comparator'))
  const comparison = useSelector(state => selector(state, 'comparison'))
  const comparison2 = useSelector(state => selector(state, 'comparison2'))

  const comparatorOptions = getComparatorOptions(source.dataType)

  const [comparisonDataType] = getComparisonDataTypes(comparator, source)

  return (
    <form onSubmit={handleSubmit}>
      <div className="action-item-split-row">
        <div>
          <label>{displayName || 'Will be visible if...'}</label>
          <Field
            className="small white"
            name="source"
            getLabel={() => label}
            options={bindingOptions}
            component={WrappedSelect}
            tooltip={tooltip}
          />
        </div>
      </div>
      {binding.source ? (
        <>
          {showFilter ? (
            <FilteringExtension
              appId={appId}
              componentId={componentId}
              binding={binding}
              object={object}
            />
          ) : null}
          <div className="action-item-split-row">
            <Field
              autoSelect
              className="small white"
              name="comparator"
              options={comparatorOptions}
              component={WrappedSelect}
            />
          </div>
          <ComparatorOptions
            fieldClassName="form-input white"
            appId={appId}
            componentId={componentId}
            objectId={object?.id}
            comparator={comparator}
            comparatorOptions={binding?.comparatorOptions}
            comparisonDataType={comparisonDataType || dataTypes.TEXT}
            comparison={comparison}
            comparison2={comparison2}
          />
          {hideAdvanced ? null : (
            <Accordion
              className="visibility-form-extensions-accordion"
              title="Advanced"
              renderChildren={() => (
                <div className="list-form-extension-realtime">
                  <Field
                    component="input"
                    name="options.affectLayout"
                    type="checkbox"
                  />
                  <label>Affect Layout</label>
                </div>
              )}
            />
          )}
        </>
      ) : null}
    </form>
  )
}

const mapStateToProps = (state, props) => {
  const { appId, componentId, object, binding } = props
  const { comparator, comparison, comparison2, source } = binding
  const types = getComparisonDataTypes(comparator, source)

  let comparisonLabel
  let comparison2Label

  if (comparison && typeof comparison === 'object') {
    comparisonLabel = getLabel(state, comparison, appId, componentId)
  }

  if (comparison2 && typeof comparison2 === 'object') {
    comparison2Label = getLabel(state, comparison2, appId, componentId)
  }

  const comparisonOptions = getSources(
    state,
    appId,
    componentId,
    object.id,
    types
  )

  return {
    comparisonOptions,
    comparisonLabel,
    comparison2Label,
  }
}

export default reduxForm({})(connect(mapStateToProps)(BindingForm))
