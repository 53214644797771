import React, { useCallback, useContext } from 'react'

import { GroupedAccordion } from 'components/Shared/Accordion'
import { ACTION, CONDITION, getCollectionPolicy } from 'utils/permissions'

import FieldList from './FieldList'

import DatasourceContext from './context/DatasourceContext'
import RelationshipsContext from './context/RelationshipsContext'
import CollectionAccessSelector from './CollectionAccessSelector'
import DropdownLabel from './DropdownLabel'

import emptyStateGraphic from './collectionsList-empty.png'
import './CollectionsList.scss'

// Special condition placeholder for 'Some Logged In Users'
const CONDITION_SOME_LOGGED_IN = 'SOME_LOGGED_IN'

// Used in drop-down lists (title casing)
const CONDITION_LABELS = {
  [CONDITION.ANYONE]: 'Everyone',
  [CONDITION.LOGGED_IN]: 'All Logged In Users',
  [CONDITION_SOME_LOGGED_IN]: 'Some Logged In Users',
  [CONDITION.NONE]: 'Nobody',
}

export default function CollectionsList({
  collectionAccordionGroup,
  sourceTableId,
  onChange,
}) {
  const datasource = useContext(DatasourceContext)
  const { auth, tables } = datasource

  const tableIds = Object.keys(tables).filter(
    tableId => tableId !== auth.table && tables[tableId].type !== 'api'
  )

  const renderTableRow = useCallback(
    tableId => {
      const { name, policies } = tables[tableId]

      const handleRowChanged = (action, allowed, paths) => {
        onChange(tableId, action, allowed, paths)
      }

      return (
        <CollectionsListItem
          tableId={tableId}
          tableName={name}
          tablePolicies={policies}
          accordionGroup={collectionAccordionGroup}
          expanded={sourceTableId === tableId}
          onChange={handleRowChanged}
        />
      )
    },
    [tableIds, onChange]
  )

  if (tableIds.length === 0) {
    return <EmptyState />
  }

  return (
    <FieldList
      heading="All Other Collections"
      itemKeys={tableIds}
      renderItem={renderTableRow}
    />
  )
}

export function EmptyState() {
  return (
    <div className="collections-list-empty-state">
      <img
        className="collections-list-empty-state__graphic"
        src={emptyStateGraphic}
        alt="No other collections yet"
      />
      <p className="collections-list-empty-state__message">
        No Other Collections Yet
      </p>
      <a
        className="collections-list-empty-state__link"
        href="https://help.adalo.com/database"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn About Collections
      </a>
    </div>
  )
}

function CollectionsListItem({
  tableId,
  tableName,
  tablePolicies,
  accordionGroup,
  expanded,
  onChange,
}) {
  const relationships = useContext(RelationshipsContext)
  const tableRelationships = (relationships && relationships[tableId]) || {}

  return (
    <div className="collections-list-item">
      <GroupedAccordion
        hideCarret
        boxed
        icon="collections"
        group={accordionGroup}
        color="orange"
        light
        hideDone
        itemId={tableId}
        title={
          <CollectionsListItemTitle
            collectionName={tableName}
            tablePolicies={tablePolicies}
          />
        }
        renderChildren={() => (
          <CollectionsListItemBody
            tablePolicies={tablePolicies}
            tableRelationships={tableRelationships}
            onChange={onChange}
          />
        )}
        defaultExpanded={expanded}
      />
    </div>
  )
}

function CollectionsListItemTitle({ collectionName, tablePolicies }) {
  const readPolicy = getCollectionPolicy(tablePolicies, ACTION.READ)
  const createPolicy = getCollectionPolicy(tablePolicies, ACTION.CREATE)
  const updatePolicy = getCollectionPolicy(tablePolicies, ACTION.UPDATE)

  const readLabel = mapPolicyToConditionLabel(readPolicy)
  const createLabel = mapPolicyToConditionLabel(createPolicy)
  const updateLabel = mapPolicyToConditionLabel(updatePolicy)

  const subtitle = `${readLabel} can view, ${createLabel} can create, ${updateLabel} can update & delete`

  return (
    <div className="collections-list-item-title">
      <p>{collectionName}</p>
      <p className="collections-list-item-title__subtitle">{subtitle}</p>
    </div>
  )
}

function mapPolicyToConditionLabel({ allowed, paths }) {
  let key = allowed

  if (allowed === CONDITION.LOGGED_IN && paths) {
    key = CONDITION_SOME_LOGGED_IN
  }

  return CONDITION_LABELS[key]
}

function CollectionsListItemBody({
  tablePolicies,
  tableRelationships,
  onChange,
}) {
  const readPathOptions =
    (tableRelationships && tableRelationships[ACTION.READ]) || []

  const createPathOptions =
    (tableRelationships && tableRelationships[ACTION.CREATE]) || []

  const updatePathOptions =
    (tableRelationships && tableRelationships[ACTION.UPDATE]) || []

  const readPolicy = getCollectionPolicy(tablePolicies, ACTION.READ)
  const createPolicy = getCollectionPolicy(tablePolicies, ACTION.CREATE)
  const updatePolicy = getCollectionPolicy(tablePolicies, ACTION.UPDATE)

  return (
    <div className="collections-list-item-body">
      <CollectionActionPolicy
        relationshipPaths={readPathOptions}
        currentCondition={readPolicy.allowed}
        currentPaths={readPolicy.paths}
        Label={() => (
          <>
            Who has access to <strong>view</strong>?
          </>
        )}
        onChange={(value, paths) => onChange(ACTION.READ, value, paths)}
      />
      <CollectionActionPolicy
        relationshipPaths={createPathOptions}
        currentCondition={createPolicy.allowed}
        currentPaths={createPolicy.paths}
        Label={() => (
          <>
            Who has access to <strong>create</strong>?
          </>
        )}
        onChange={(value, paths) => onChange(ACTION.CREATE, value, paths)}
      />
      <CollectionActionPolicy
        relationshipPaths={updatePathOptions}
        currentCondition={updatePolicy.allowed}
        currentPaths={updatePolicy.paths}
        Label={() => (
          <>
            Who has access to <strong>update &amp; delete</strong>?
          </>
        )}
        onChange={(value, paths) => {
          onChange(ACTION.UPDATE, value, paths)
          onChange(ACTION.DELETE, value, paths)
        }}
      />
    </div>
  )
}

function CollectionActionPolicy({
  relationshipPaths,
  currentCondition,
  currentPaths,
  tooltip,
  Label,
  onChange,
}) {
  return (
    <div className="collection-action-policy">
      <DropdownLabel Title={Label} tooltip={tooltip} />
      <CollectionAccessSelector
        relationshipPaths={relationshipPaths}
        currentCondition={currentCondition}
        selectedPaths={currentPaths}
        onChange={onChange}
      />
    </div>
  )
}
