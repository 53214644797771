import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dataTypes, bindingTypes } from '@adalo/constants'
import { Field } from 'redux-form'
import classNames from 'classnames'

import { getLabel } from '../../../../utils/sources'
import { getParams } from '../../../../ducks/apps/params'
import { getSources, addBindings } from '../../../../ducks/recommender'
import { getDefaultDatasource } from '../../../../ducks/apps/datasources'
import MenuControl from '../../Inspect/Libraries/MenuControl'
import { WrappedBindableTextControl } from '../../../Shared/BindableTextControl'
import FilteringExtension from '../../DataBinding/FilteringFormExtension'
import { LinkMenu } from './Navigate'
import Params from './Params'
import { THEMES } from '../../../../constants'

class NotificationOptions extends Component {
  render() {
    const {
      appId,
      componentId,
      objectId,
      actionId,
      field,
      userOptions,
      getLabel,
      reference,
      options,
      menuTheme,
    } = this.props

    const role = reference ? 'listItem' : null

    const { audience } = options

    const showFilter =
      audience && audience.source && audience.source.dataType === dataTypes.LIST

    return (
      <div
        className={classNames(
          'action-item-form-options',
          'form-inspect-field-wrapper',
          'notifications-action-form'
        )}
      >
        <Field
          displayName="Recipient"
          component={MenuControl}
          name={`${field}.options.audience`}
          options={userOptions}
          getLabel={getLabel}
          menuTheme={menuTheme}
        />
        {showFilter ? (
          <FilteringExtension
            name={`${field}.options.audience.source.options.filter`}
            appId={appId}
            componentId={componentId}
            object={{ id: objectId }}
            binding={audience}
          />
        ) : null}
        <Field
          label="Title"
          name={`${field}.options.title`}
          component={WrappedBindableTextControl}
          objectId={objectId}
          role={role}
          reference={reference}
          actionId={actionId}
        />
        <Field
          label="Body"
          name={`${field}.options.body`}
          component={WrappedBindableTextControl}
          objectId={objectId}
          role={role}
          reference={reference}
          actionId={actionId}
        />
        <div className="library-inspect-menu-control">
          <p>Screen</p>
          <Field
            allScreens
            includeAssociated
            name={`${field}.options.screenId`}
            component={LinkMenu}
            appId={appId}
            componentId={componentId}
            menuTheme={THEMES.ACTION}
          />
        </div>
        <Params {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  let { appId, componentId, objectId, actionId, reference, options } = props
  const datasource = getDefaultDatasource(state, appId)
  const { auth } = datasource
  const tableId = auth.table

  const userTypes = [
    { tableId, datasourceId: datasource.id, type: dataTypes.OBJECT },
    { tableId, datasourceId: datasource.id, type: dataTypes.LIST },
  ]

  const userOptions = addBindings(
    getSources(
      state,
      appId,
      componentId,
      objectId,
      userTypes,
      false,
      actionId,
      reference
    ),
    [bindingTypes.LIBRARY_PROP]
  )

  let screenId = options && options.screenId

  if (typeof screenId === 'object') {
    appId = screenId.appId
    screenId = screenId.screenId
  }

  return {
    userOptions,
    params: getParams(state, appId, screenId),
    getLabel: source => getLabel(state, source, appId, componentId),
  }
}

export default connect(mapStateToProps)(NotificationOptions)
