import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './ToggleCheckbox.scss'

export default class ToggleCheckbox extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.bool,
    onChange: PropTypes.func,
  }

  handleChange = () => {
    const { disabled, onChange, value, input } = this.props

    if (input) {
      return input.onChange(!input.value)
    } else {
      if (!disabled) {
        return onChange(!value)
      }
    }
  }

  render() {
    const { disabled, value, input } = this.props

    const targetValue = input ? input.value : value

    return (
      <div
        role="checkbox"
        aria-checked={targetValue}
        tabIndex="-1"
        className={classNames('toggle-checkbox', {
          enabled: targetValue,
          disabled,
        })}
        onClick={this.handleChange}
      >
        <span
          className={classNames({
            'icon icon-checkbox': targetValue,
            'icon icon-checkbox-empty': !targetValue,
          })}
        />
      </div>
    )
  }
}
