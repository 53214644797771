import classNames from 'classnames'

import './styles.scss'

type BetaLabelProps = {
  color?: string
}

export default function BetaLabel({
  color = 'orange',
}: BetaLabelProps): JSX.Element {
  return (
    <div
      className={classNames('beta-label', {
        [`beta-label-${color}`]: color,
      })}
    >
      <span>Beta</span>
    </div>
  )
}
