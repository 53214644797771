import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentOrganization } from 'ducks/organizations'
import { PlanType } from '@adalo/constants'

interface PlanFeatures {
  planType: PlanType | undefined
  enabledFeatures: Array<string> | undefined
  getHasFeatureEnabled: (feature: string) => boolean | undefined
}

const usePlanFeatures = (): PlanFeatures => {
  const organization = useSelector(state => getCurrentOrganization(state))

  const enabledFeatures = organization?.enabledFeatures
  const planType = organization?.planType

  const getHasFeatureEnabled = useCallback(
    (feature: string) => {
      const isEnabled = enabledFeatures?.includes?.(feature)

      return isEnabled
    },
    [enabledFeatures]
  )

  return { planType, enabledFeatures, getHasFeatureEnabled }
}

export default usePlanFeatures
