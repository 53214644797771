import React, { useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useFlags } from 'flags'

import { fetchTopMarketplaceComponents } from 'ducks/marketplace'

import SectionsTab from './ResponsiveAddMenu/SectionsTab'
import FeaturesTab from './ResponsiveAddMenu/FeaturesTab'
import GlobalSearch from './ResponsiveAddMenu/GlobalSearch'

import TabBar from './TabBar'
import ComponentsTab from './ComponentsTab'
import ScreensTab from './ScreensTab'
import ShortcutsMenu from './ShortcutsMenu'

import './AddObject.scss'

const AddPanel = props => {
  const {
    match: {
      params: { appId },
    },
    magicLayout,
    location,
    app,
    isXanoApp,
  } = props
  const dispatch = useDispatch()
  const { hasNewAddMenuWithSections } = useFlags()

  useEffect(() => {
    if (hasNewAddMenuWithSections && magicLayout) {
      dispatch(fetchTopMarketplaceComponents())
    }
  }, [hasNewAddMenuWithSections, magicLayout])

  const tabs = useMemo(() => {
    const defaultTabs = [
      {
        id: 'component',
        title: 'Components',
        component: ComponentsTab,
      },
      {
        id: 'section',
        title: 'Sections',
        component: SectionsTab,
      },
    ]

    if (isXanoApp) {
      return defaultTabs
    }

    return [
      ...defaultTabs,
      {
        id: 'feature',
        title: 'Features',
        component: FeaturesTab,
      },
    ]
  }, [isXanoApp])

  if (!app?.primaryPlatform) {
    return null
  }

  if (hasNewAddMenuWithSections && magicLayout) {
    const searchActive = location?.pathname.includes('search')

    return (
      <div className="responsive-add-menu">
        <ShortcutsMenu />
        <GlobalSearch appId={appId} />
        {!searchActive && (
          <TabBar
            initialActiveTab="section"
            tabs={tabs}
            extraProps={{ appId }}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <ShortcutsMenu />
      <TabBar
        initialActiveTab="component"
        tabs={[
          { id: 'component', title: 'ADD COMPONENT', component: ComponentsTab },
          { id: 'screen', title: 'ADD SCREEN', component: ScreensTab },
        ]}
        extraProps={{ appId }}
      />
    </>
  )
}

export default withRouter(AddPanel)
