import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { PASSWORD_LENGTH_MAX } from '@adalo/constants'
import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import AuthPageLogo from './Logo'

const FORM_NAME = 'recoverPasswordForm'

export const validate = data => {
  const errors = {}

  if (!data.password || data.password.length < 8) {
    errors.password = 'Password must be at least 8 characters'
  }

  if (data.password && data.password.length > PASSWORD_LENGTH_MAX) {
    errors.password = 'Password is too long'
  }

  return errors
}

class RecoverPasswordForm extends Component {
  render() {
    const { handleSubmit, error } = this.props

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Update Password</h1>
        <p>Please enter your new password</p>
        <Field
          autoFocus
          name="password"
          placeholder="New Password"
          type="password"
          label="New Password"
          component={WrappedInput}
        />
        <div className="auth-form-submission-row">
          <Button large yellow>
            Update
          </Button>
        </div>
        {error && <div className="auth-form-error">{error}</div>}
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(RecoverPasswordForm)
