import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sourceTypes } from '@adalo/constants'

import { selectObject, updateObject } from '../../../ducks/editor/objects'
import Icon from '../../Shared/Icon'
import Form from './Form'

class Binding extends Component {
  handleChange = data => {
    const { onChange } = this.props

    onChange(data)
  }

  handleSubmit = () => {
    // Do nothing
  }

  getBinding = () => {
    let { binding } = this.props
    let { source } = binding

    let options =
      source.type === sourceTypes.COUNT
        ? source.source && source.source.options
        : source.options

    const filter = options && options.filter

    if (filter && !Array.isArray(filter)) {
      options = {
        ...options,
        filter: [filter],
      }

      if (source.type === sourceTypes.COUNT) {
        source = {
          ...source,
          source: {
            ...source.source,
            options,
          },
        }
      } else {
        source = {
          ...source,
          options,
        }
      }

      binding = {
        ...binding,
        source,
      }
    }

    return binding
  }

  render() {
    const {
      appId,
      componentId,
      label,
      bindingOptions,
      objectId,
      object,
      onRemove,
      bindingTypes,
      form,
    } = this.props

    const binding = this.getBinding()

    if (!binding) {
      return null
    }

    return (
      <div className="data-binding">
        <a className="data-binding-remove" onClick={onRemove}>
          <Icon type="trash-small" />
        </a>
        <Form
          form={form || `dataBinding-${objectId}`}
          bindingOptions={bindingOptions}
          onChange={this.handleChange}
          onChangeManually={this.handleChange}
          onSubmit={this.handleSubmit}
          initialValues={binding}
          binding={binding}
          label={label}
          object={object}
          appId={appId}
          componentId={componentId}
          bindingTypes={bindingTypes}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { objectId }) => ({
  object: selectObject(state, objectId),
})

export default connect(mapStateToProps, { updateObject })(Binding)
