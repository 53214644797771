import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Modal from 'components/Shared/Modal'
import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import { User } from 'ducks/users'

import './ChooseZapModal.scss'
import { parseNames } from 'utils/parseNames'

const HEADINGS: Record<string, string> = {
  all: 'Most Popular Integrations using Zapier',
  gmail: 'Connect Adalo to Gmail with Zapier',
  sendgrid: 'Connect Adalo to SendGrid with Zapier',
  'google-sheets': 'Connect Adalo to Google Sheets with Zapier',
  twilio: 'Connect Adalo to Twilio with Zapier',
  mailchimp: 'Connect Adalo to Mailchimp with Zapier',
  slack: 'Connect Adalo to Slack with Zapier',
  'google-drive': 'Connect Adalo to Google Drive with Zapier',
}

const ChooseZapModal = ({
  zapType,
  onClose,
  appId,
  user,
}: {
  zapType: string
  onClose: () => void
  appId: string
  user: User
}): JSX.Element => {
  const history = useHistory()
  const heading = HEADINGS[zapType]
  const { email, name } = user || {}
  const { firstName, lastName } = name
    ? parseNames(name)
    : { firstName: undefined, lastName: undefined }

  useEffect(() => {
    history.push(`/apps/${appId}/zapier?app=${zapType}`)

    return () => {
      history.replace(`/apps/${appId}/screens`)
    }
  }, [])

  const renderHeaderContent = () => {
    return (
      <>
        <h1>{heading}</h1>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://help.adalo.com/integrations/zapier"
        >
          <Button text teal>
            <span>Learn More</span>
            <Icon small type="launch" />
          </Button>
        </a>
      </>
    )
  }

  return (
    <div className="zapier-modal">
      <Modal.Header content={renderHeaderContent} />
      <Modal.Content>
        {zapType === 'all' ? (
          <zapier-app-directory
            app="adalo"
            link-target="new-tab"
            theme="light"
            applimit={0}
            zaplimit={5}
            introcopy="hide"
            create-without-template="show"
            use-this-zap="show"
            sign-up-first-name={firstName}
            sign-up-last-name={lastName}
            sign-up-email={email}
            client-id={process.env['REACT_APP_ZAPIER_CLIENT_ID']}
          />
        ) : (
          <zapier-zap-templates
            apps={`adalo,${zapType}`}
            link-target="new-tab"
            create-without-template="show"
            limit="5"
            use-this-zap="show"
            theme="light"
            sign-up-first-name={firstName}
            sign-up-last-name={lastName}
            sign-up-email={email}
            client-id={process.env['REACT_APP_ZAPIER_CLIENT_ID']}
          />
        )}
      </Modal.Content>
      <Modal.Actions
        leftButtons={
          <Modal.Button type="button" text onClick={onClose}>
            Back
          </Modal.Button>
        }
      />
    </div>
  )
}

export default ChooseZapModal
