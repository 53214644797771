import { getDeviceType } from '@adalo/utils'

import { LAYOUT_SECTION } from '@adalo/constants'
import getDeviceObject from 'utils/getDeviceObject'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import getContainingScreen from '../objects/helpers/getContainingScreen'
import { getSelectionBounds } from '../getSelectionBounds'
import { moveElementHandler } from './moveElement'

export interface ReorderSectionOptions {
  objectId: string
  direction: 'up' | 'down'
}

export interface ReorderSectionInstruction {
  operation: 'reorderSection'
  options: ReorderSectionOptions
}

export const reorderSectionHandler = (
  state: InstructionState,
  options: ReorderSectionOptions
): InstructionState => {
  const { list, pathMap } = state
  const { objectId, direction } = options
  const moveUp = direction === 'up'

  const object = getObject(list, pathMap, objectId)

  if (object.type !== LAYOUT_SECTION) {
    throw new Error('Tried to reorder non-section')
  }

  const screen = getContainingScreen(list, pathMap, objectId)
  const device = getDeviceType(screen.width)

  if (!screen || !screen.children) {
    throw new Error('No screen')
  }

  const deviceObject = getDeviceObject(object, device)

  const sectionsOnScreen = screen.children
    .filter(({ type }) => type === LAYOUT_SECTION)
    .map(section => getDeviceObject(section, device))
    .sort((a, b) => a.y - b.y)

  const index = sectionsOnScreen.findIndex(({ id }) => id === objectId)

  if (
    (moveUp && index === 0) ||
    (!moveUp && index === sectionsOnScreen.length - 1)
  ) {
    return state
  }

  const otherIndex = moveUp ? index - 1 : index + 1
  const otherSection = sectionsOnScreen[otherIndex]

  if (!otherSection) {
    throw new Error('Invalid swap section')
  }

  const { top, bottom } = getSelectionBounds(state, [objectId, otherSection.id])
  let newList = list

  if (moveUp) {
    ;({ list: newList } = moveElementHandler(
      { ...state, list: newList },
      { objectId, x: deviceObject.x, y: top }
    ))
    ;({ list: newList } = moveElementHandler(
      { ...state, list: newList },
      {
        objectId: otherSection.id,
        x: otherSection.x,
        y: bottom - otherSection.height,
      }
    ))
  } else {
    ;({ list: newList } = moveElementHandler(
      { ...state, list: newList },
      { objectId: otherSection.id, x: otherSection.x, y: top }
    ))
    ;({ list: newList } = moveElementHandler(
      { ...state, list: newList },
      {
        objectId,
        x: deviceObject.x,
        y: bottom - deviceObject.height,
      }
    ))
  }

  return {
    ...state,
    list: newList,
  }
}

const reorderSection = (
  objectId: string,
  direction: 'up' | 'down'
): ReorderSectionInstruction => ({
  operation: 'reorderSection',
  options: {
    objectId,
    direction,
  },
})

export default reorderSection
