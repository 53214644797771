import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// ducks
import { getOrgsLicenses } from 'ducks/marketplace/licenses'

import Icon from 'components/Shared/Icon'
import Loading from 'components/Shared/Loading'
import Tooltip from 'components/Shared/Tooltip'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'

import {
  setTestingOrg,
  getDeveloperLibraries,
  getTestingOrg,
  getDeveloperLibrariesStatus,
} from 'ducks/marketplace/developerLibraries'

import { DevelopersContext } from './index'
import Library from './Library'

export default function DeveloperLibraries() {
  const dispatch = useDispatch()
  const { user, organizations, app } = useContext(DevelopersContext)
  const orgIds = organizations.map(org => org.id)

  const orgLicenses = useSelector(state => getOrgsLicenses(state, orgIds))
  const libraries = useSelector(getDeveloperLibraries)

  const { status, error } = useSelector(getDeveloperLibrariesStatus)
  const testingOrg = useSelector(getTestingOrg)

  if (['loading', 'idle'].includes(status)) return <Loading />

  if (status === 'error') {
    return <p style={{ color: 'red' }}>{error.response.data.message}</p>
  }

  if (!libraries || libraries.length === 0) {
    return <p>You have not published any libraries.</p>
  }

  const getOrganizations = () => {
    return organizations.map(org => {
      return {
        label: org.name,
        value: org.id,
      }
    })
  }

  const handleTestingOrgChange = orgId => {
    dispatch(setTestingOrg(user.id, orgId))
  }

  const publicLibraries = libraries.filter(l => l.public)
  const privateLibraries = libraries.filter(l => !l.public && !l.shared)

  const tooltip =
    'Select the team that has access to your Public library releases that are in review. Everyone on this team can use unreleased components in the editor, in native app builds, and to create demo apps.'

  return (
    <div className="developer-libraries-wrapper">
      <label className="select-header">
        <h2 className="select-label">Set Testing Team</h2>
        <Tooltip hideArrow placement="bottom" tooltip={tooltip}>
          <Icon className="select-tooltip" type="help-small" small />
        </Tooltip>
      </label>
      <WrappedSelect
        options={getOrganizations()}
        value={testingOrg}
        placeholder="Select Testing Org..."
        onChange={handleTestingOrgChange}
      />
      {publicLibraries.length > 0 && (
        <>
          <h2>Public</h2>
          <div className="developer-libraries">
            {publicLibraries
              .filter(lib => lib.public)
              .map(library => (
                <Library
                  library={library}
                  organizations={organizations}
                  orgLicenses={orgLicenses}
                  app={app}
                />
              ))}
          </div>
        </>
      )}
      {privateLibraries.length > 0 && (
        <>
          <h2>Private</h2>
          <div className="developer-libraries">
            {privateLibraries
              .filter(lib => !lib.public)
              .map(library => (
                <Library
                  library={library}
                  organizations={organizations}
                  orgLicenses={orgLicenses}
                  app={app}
                />
              ))}
          </div>
        </>
      )}
    </div>
  )
}
