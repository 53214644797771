export function replaceAllOccurrencesFromMap<T>(
  object: T,
  map: Record<string, string>
): T {
  let objectString = JSON.stringify(object)

  for (const [find, replace] of Object.entries(map)) {
    objectString = objectString.replace(new RegExp(find, 'g'), replace)
  }

  const newObject = JSON.parse(objectString) as T

  return newObject
}
