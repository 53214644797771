import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getPercentageFull } from 'utils/metrics'
import { useFlags } from 'flags'
import { getTrialState } from 'ducks/organizations'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import { loadMetrics, getMetrics } from 'ducks/metrics'

import Icon from 'components/Shared/Icon'

import Tooltip from 'components/Shared/Tooltip'
import Button from '../Button'
import './TrialWarning.scss'
import { getDaysLeftText } from '../FreeTrial/utils'

const TrialBanner = ({ onClick: handleClick, appId, bottomBar = true }) => {
  const dispatch = useDispatch()
  const { days, trialState, canExtendTrial } = useSelector(getTrialState)

  const { hasTrialExtension2023, hasViewAppMenu } = useFlags()
  const history = useHistory()
  const metrics = useSelector(state => getMetrics(state, appId))
  const datasource = useSelector(state => getDefaultDatasource(state, appId))
  const [datasourceId, setDatasourceId] = useState(datasource?.id)
  const [ctaColorProps, setCtaColorProps] = useState(
    hasTrialExtension2023
      ? { teal: true, secondary: hasViewAppMenu, gradient: !hasViewAppMenu }
      : { orange: true }
  )

  if (!datasourceId && datasource?.id) {
    setDatasourceId(datasource.id)
  }

  useEffect(() => {
    if (datasourceId) {
      dispatch(loadMetrics(appId, datasource.id))
    }
  }, [datasourceId])

  const daysLeft = getDaysLeftText(days)

  const trialTooltipTitle = useMemo(() => {
    if (hasTrialExtension2023) {
      if (canExtendTrial) {
        return 'Your team is elegible for a Free Trial Extension!'
      }

      return 'Congrats! Your team is currently on a Free Trial!'
    }

    return 'Congrats! Your team is currently on a 14-day Free Trial!'
  }, [hasTrialExtension2023, hasTrialExtension2023])

  const trialTooltipSubheader = useMemo(() => {
    if (hasTrialExtension2023) {
      if (canExtendTrial) {
        return 'What happens when the trial ends?'
      }

      return 'What happens after the trial ends?'
    }

    return 'What happens after 14 days?'
  }, [hasTrialExtension2023, canExtendTrial])

  const trialTooltip = (
    <>
      <h1 className="trial-tooltip-header">Free Trial | {daysLeft}</h1>
      <p>{trialTooltipTitle}</p>
      <br />
      <h2 className="trial-tooltip-subheader">
        What's included in the Free Trial?
      </h2>
      <div className="tooltip-features-lists">
        <ul>
          <li>
            <Icon type="check-circle" color="white" />
            External Collections Integration
          </li>
          <li>
            <Icon type="check-circle" color="white" />
            Unlimited Custom Actions
          </li>
          <li>
            <Icon type="check-circle" color="white" />
            Collections API Access
          </li>
        </ul>
        <ul>
          <li>
            <Icon type="check-circle" color="white" />
            Access to all Custom Fonts
          </li>
          <li>
            <Icon type="check-circle" color="white" />
            Design Versions Access
          </li>
          <li>
            <Icon type="check-circle" color="white" />
            Full Geolocation Feature Access
          </li>
        </ul>
      </div>
      <br />
      <h2 className="trial-tooltip-subheader">{trialTooltipSubheader}</h2>
      <p>
        Don't worry! You'll still be able to keep building your app, just all
        the premium features will be disabled. You'll have access to your app as
        long as you like.
      </p>
      <Button
        text
        to="https://help.adalo.com/integrations"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn More
      </Button>
    </>
  )

  const full = getPercentageFull(metrics, datasource) === '100%'

  const handleMouseEnter = () => {
    if (hasViewAppMenu && hasTrialExtension2023) {
      setCtaColorProps(prev => ({
        ...prev,
        secondary: false,
        outlined: true,
      }))
    }
  }

  const handleMouseLeave = () => {
    if (hasViewAppMenu && hasTrialExtension2023) {
      setCtaColorProps(prev => ({
        ...prev,
        secondary: true,
        outlined: false,
      }))
    }
  }

  const handleClickExtend = () => {
    const url = `/apps/${appId}/free-trial-extend`

    history.push(url)
  }

  return (
    <div
      className={classNames('trial-warning-button-wrapper', {
        'trial-warning-button-wrapper--button': hasViewAppMenu && !bottomBar,
      })}
    >
      {hasViewAppMenu && canExtendTrial && (
        <Tooltip
          tooltip={trialTooltip}
          placement="top"
          hideArrow
          containerClassname="trial-tooltip-container"
        >
          <div className="integrations-trial-tooltip-wrapper">
            <p className="integrations-trial-wrapper">
              <span role="img" aria-label="checked flag">
                🏁
              </span>
              Trial Finished
            </p>
          </div>
        </Tooltip>
      )}
      {!canExtendTrial && trialState === 'during' && (
        <Tooltip
          tooltip={trialTooltip}
          placement="top"
          hideArrow
          containerClassname="trial-tooltip-container"
        >
          <div className="integrations-trial-tooltip-wrapper">
            <p className="integrations-trial-wrapper">
              <span role="img" aria-label="hourglass">
                ⏳
              </span>
              <span className="free-trial-label">Free Trial |</span>
              <b>{daysLeft}</b>
            </p>
          </div>
        </Tooltip>
      )}
      <Button
        small
        {...ctaColorProps}
        className={classNames(
          'trial-warning-button',
          full && 'trial-warning-button-full'
        )}
        onClick={canExtendTrial ? handleClickExtend : handleClick}
        onMouseEnter={hasViewAppMenu ? handleMouseEnter : () => {}}
        onMouseLeave={hasViewAppMenu ? handleMouseLeave : () => {}}
        data-adalo-id={
          hasViewAppMenu && !bottomBar
            ? 'top-bar-upgrade'
            : 'bottom-bar-upgrade'
        }
      >
        {canExtendTrial ? 'Extend Trial' : 'Upgrade Now'}
      </Button>
    </div>
  )
}

export default TrialBanner
