import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'
import Icon, { IconButton } from '../../Shared/Icon'
import APIRequest from './APIRequest'
import TestRequest from './TestRequest'
import { validate } from './validation'

import './Form.scss'

export const FORM_NAME = 'newCustomActionForm'

const typeOptions = [
  { label: 'Create', value: 'create' },
  { label: 'Update', value: 'update' },
  { label: 'Delete', value: 'delete' },
]

const TypeAndName = () => {
  return (
    <div className="custom-action-first-step">
      <Field
        name="name"
        label="Name"
        placeholder="i.e. Sendgrid Email"
        component={WrappedInput}
        autoComplete="off"
      />
      <div className="custom-action-type">
        <label>Type</label>
        <Field
          name="type"
          placeholder="Select a Type"
          component={WrappedSelect}
          rowHeight={50}
          options={typeOptions}
          autoComplete="off"
        />
      </div>
    </div>
  )
}

class NewCustomActionForm extends Component {
  renderContent() {
    const { step, initialValues, handleClose } = this.props

    if (step === 0) {
      return <TypeAndName />
    } else if (step === 1) {
      return (
        <APIRequest
          showMainAPIInfo={!initialValues?.template}
          handleClose={handleClose}
        />
      )
    } else {
      return <Field name="testResults" component={TestRequest} />
    }
  }

  render() {
    const {
      step,
      deleteWarning,
      handleBack,
      handleSubmit,
      handleWarning,
      invalid,
      initialValues,
    } = this.props

    const isTemplateAction = initialValues?.template

    const disabled = step === 2 && invalid

    const saveText = 'Save Custom Action'

    return deleteWarning ? (
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <div className="delete-warning-header">
            <Icon type="warning-emoji" />
            <p>Are you sure you want to delete this Custom Action?</p>
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className="custom-action-warning-text">
            <p>
              Once this action is deleted, it will no longer be available in the
              list of Custom Actions you can add. However, this Custom Action
              will still exist anywhere you have previously added it.
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button text onClick={handleBack} type="button">
            CANCEL
          </Button>
          <Button yellow type="submit" disabled={disabled}>
            DELETE
          </Button>
        </Modal.Actions>
      </form>
    ) : (
      <form onSubmit={handleSubmit}>
        <Modal.Header
          stepper
          color="yellow"
          steps={['Name & Type', 'API Request', 'Outputs']}
          currentStep={step}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Actions>
          {initialValues.id && !isTemplateAction ? (
            <div className="custom-action-warning">
              <IconButton type="trash-small" onClick={handleWarning} />
            </div>
          ) : null}
          <Button text onClick={handleBack} type="button">
            {step === 0 || isTemplateAction ? 'Cancel' : 'Back'}
          </Button>
          <Button yellow type="submit" disabled={disabled}>
            {step === 2 ? saveText : step === 1 ? 'Run Test Request' : 'Next'}
          </Button>
        </Modal.Actions>
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  validate,
  enableReinitialize: true,
})(NewCustomActionForm)
