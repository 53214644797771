import { B2, BA, BAI, BB, BK, SHY } from './textMetricBreaks'
import { measureWidthWithFont } from './textMetricsCanvasMeasure'

export interface Parameters {
  fontSize: number
  evaluatedFontFamily: string
  fontWeight: number
  fontStyle: string
  multiline: boolean
  width: number | undefined
}
interface TextCanvasStyles {
  'font-size': string
  'line-height': string
  'font-style': string
  'font-family': string
  'white-space': 'pre-wrap'
  'font-weight': string
  width: number | undefined
}
// this is set to the current value used in src/utils/text.js line 12
export const getLineHeight = (fontSize: number): number =>
  Math.ceil(fontSize * 1.15)

const getStyles = ({
  fontSize,
  evaluatedFontFamily,
  fontStyle,
  fontWeight,
}: Pick<
  Parameters,
  'fontSize' | 'evaluatedFontFamily' | 'fontStyle' | 'fontWeight'
>): TextCanvasStyles => ({
  'font-size': `${fontSize}px`,
  'line-height': `${getLineHeight(fontSize)}px`,
  'font-style': fontStyle,
  'font-family': evaluatedFontFamily,
  'white-space': 'pre-wrap',
  'font-weight': String(fontWeight),
  width: undefined,
})

const getFont = (styles: TextCanvasStyles): string => {
  const font = []

  const fontWeight = styles['font-weight']
  if (
    [
      'normal',
      'bold',
      'bolder',
      'lighter',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
    ].includes(fontWeight)
  ) {
    font.push(fontWeight)
  }

  const fontStyle = styles['font-style']
  if (['normal', 'italic', 'oblique'].includes(fontStyle)) {
    font.push(fontStyle)
  }

  const fontSize = styles['font-size']
  font.push(fontSize)

  const fontFamily = styles['font-family']
  font.push(fontFamily)

  return font.join(' ')
}

const checkBreak = (chr: string): string | undefined => {
  if (B2.has(chr)) return 'B2'
  if (BAI.has(chr)) return 'BAI'
  if (SHY.has(chr)) return 'SHY'
  if (BA.has(chr)) return 'BA'
  if (BB.has(chr)) return 'BB'
  if (BK.has(chr)) return 'BK'

  return undefined
}

const computeLinesDefault = (font: string, text: string, max: number) => {
  const lines = []
  const parts = []
  const breakpoints = []
  let line = ''
  let firstPart = ''

  if (!text) {
    return []
  }

  // Compute array of breakpoints
  for (const chr of text) {
    const type = checkBreak(chr)
    if (firstPart === '' && type === 'BAI') {
      continue
    }

    if (type) {
      breakpoints.push({ chr, type })

      parts.push(firstPart)
      firstPart = ''
    } else {
      firstPart += chr
    }
  }

  if (firstPart) {
    parts.push(firstPart)
  }

  // Loop over text parts and compute the lines
  for (const [i, part] of parts.entries()) {
    if (i === 0) {
      line = part

      continue
    }

    const breakpoint = breakpoints[i - 1]
    if (!breakpoint) continue
    // Special treatment as we only render the soft hyphen if we need to split
    const chr = breakpoint.type === 'SHY' ? '' : breakpoint.chr
    if (breakpoint.type === 'BK') {
      lines.push(line)
      line = part

      continue
    }

    // Measure width
    const rawWidth = measureWidthWithFont(line + chr + part, font)
    const width = Math.round(rawWidth)

    // Still fits in line
    if (width <= max) {
      line += chr + part

      continue
    }

    // Line is to long, we split at the breakpoint
    switch (breakpoint.type) {
      case 'SHY':
        lines.push(`${line}-`)
        line = part

        break
      case 'BA':
        lines.push(line + chr)
        line = part

        break
      case 'BAI':
        lines.push(line)
        line = part

        break
      case 'BB':
        lines.push(line)
        line = chr + part

        break
      case 'B2':
        if (Math.floor(measureWidthWithFont(line + chr, font)) <= max) {
          lines.push(line + chr)
          line = part
        } else if (Math.floor(measureWidthWithFont(chr + part, font)) <= max) {
          lines.push(line)
          line = chr + part
        } else {
          lines.push(line, chr)
          line = part
        }

        break
      default:
        throw new Error('Undefined break')
    }
  }

  if ([...line].length > 0) {
    lines.push(line)
  }

  return lines
}

const normalizeWhitespace = (text: string, ws: string): string => {
  switch (ws) {
    case 'pre':
      return text
    case 'pre-wrap':
      return text
    case 'pre-line':
      return (text || '').replace(/\s+/gm, ' ').trim()
    default:
      return (text || '')
        .replace(/[\r\n]/gm, ' ')
        .replace(/\s+/gm, ' ')
        .trim()
  }
}

const prepareText = (text: string): string => {
  // Convert to unicode
  const newText = (text || '')
    .replace(/<wbr>/gi, '\u200B')
    .replace(/<br\s*\/?>/gi, '\u000A')
    .replace(/&shy;/gi, '\u00AD')
    .replace(/&mdash;/gi, '\u2014')

  if (
    /&#(\d+)(;?)|&#[xX]([a-fA-F\d]+)(;?)|&([\da-zA-Z]+);/g.test(newText) &&
    console
  ) {
    console.error(
      'textMetrics: Found encoded htmlenties. You may want to use https://mths.be/he to decode your text first.'
    )
  }

  return newText
}

export const getTextLines = (text: string, params: Parameters): string[] => {
  const styles = getStyles(params)
  styles.width = params.width

  const cleanText = prepareText(
    normalizeWhitespace(text, styles['white-space'])
  )

  const font = getFont(styles)

  // Get max width
  const max = styles.width || 0

  return computeLinesDefault(font, cleanText, max)
}

export const measureTextHeight = (text: string, params: Parameters): number => {
  const styles = getStyles(params)
  styles.width = params.width

  const cleanText = prepareText(
    normalizeWhitespace(text, styles['white-space'])
  )

  const lineHeight = Number.parseFloat(styles['line-height'])

  return Math.ceil(getTextLines(cleanText, params).length * lineHeight || 0)
}

export const measureTextWidth = (text: string, params: Parameters): number => {
  const styles = getStyles(params)
  const cleanText = prepareText(
    normalizeWhitespace(text, styles['white-space'])
  )
  if (!cleanText) {
    return 0
  }

  const font = getFont(styles)

  if (params.multiline) {
    return getTextLines(cleanText, params).reduce((result, line) => {
      const w = measureWidthWithFont(line, font)

      return Math.max(result, w)
    }, 0)
  }

  return measureWidthWithFont(cleanText, font)
}
