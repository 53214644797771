import React, { Component } from 'react'
import moment from 'moment'
import Button from 'components/Shared/Button'
import classNames from 'classnames'

export default class GranularityToggle extends Component {
  handleClick = e => {
    const { onClick } = this.props

    onClick(e)
  }

  render() {
    const { granularity, start, end } = this.props
    const difference = moment.duration(moment(end).diff(moment(start))).asDays()

    return (
      <div className="granularity-toggle">
        <Button
          onClick={() => this.handleClick('day')}
          className={classNames('granularity-button', {
            'granularity-button-selected': granularity === 'day',
            'granularity-button-start': difference > 13,
          })}
        >
          Daily
        </Button>
        {difference > 13 ? (
          <Button
            onClick={() => this.handleClick('week')}
            className={classNames('granularity-button', {
              'granularity-button-selected': granularity === 'week',
              'granularity-button-middle': difference > 89,
              'granularity-button-end': difference > 13 && difference < 90,
            })}
          >
            Weekly
          </Button>
        ) : null}
        {difference > 89 ? (
          <Button
            onClick={() => this.handleClick('month')}
            className={classNames(
              'granularity-button',
              'granularity-button-end',
              {
                'granularity-button-selected': granularity === 'month',
              }
            )}
          >
            Monthly
          </Button>
        ) : null}
      </div>
    )
  }
}
