import React, { useContext, useMemo, useState } from 'react'
import moment from 'moment'

// components
import AppIcon from 'components/Shared/AppIcon'
import Button from 'components/Shared/Button'

import { TeamAppsContext } from './index'

const AppsListItem = ({ app, handleJoin, makersCurrentApps }) => {
  const { isCurrentUserTeamAdmin } = useContext(TeamAppsContext)

  const [isLoading, setIsLoading] = useState(false)

  const { id, name } = app

  const isAppUser = useMemo(() => {
    return makersCurrentApps.includes(id)
  }, [id, makersCurrentApps])

  const createdOn = useMemo(
    () => moment(app.createdAt).format('MMM Do YYYY'),
    [app.createdAt]
  )

  const handleAction = async () => {
    try {
      setIsLoading(true)
      await handleJoin(id)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="settings-content-row">
      <AppIcon app={app} />

      <div className="settings-content-row-item">
        <h4>{name}</h4>
        <span>Created {createdOn}</span>
      </div>
      {isAppUser ? (
        <Button small teal secondary to={`/apps/${id}`}>
          Launch
        </Button>
      ) : (
        <Button
          text
          small
          outlined
          loading={isLoading}
          disabled={isLoading || !isCurrentUserTeamAdmin}
          onClick={handleAction}
        >
          Join
        </Button>
      )}
    </div>
  )
}

export default AppsListItem
