export const ADD = {
  type: 'add',
  path: 'screens/add',
}
export const BRANDING = {
  type: 'branding',
  icon: 'branding',
  path: 'branding',
  tooltip: 'Branding',
}
export const DATA = {
  type: 'data',
  icon: 'database-collections',
  path: 'data',
  tooltip: 'Database',
}
export const SCREENS = {
  type: 'screens',
  icon: 'screens',
  path: 'screen-list',
  tooltip: 'Screens',
}
export const SETTINGS = {
  type: 'settings',
  icon: 'settings',
  path: 'app-settings',
  tooltip: 'Settings',
}
export const PUBLISH = {
  type: 'publish',
  icon: 'done-all',
  path: 'publish',
  tooltip: 'Publish',
}
export const ANALYTICS = {
  type: 'analytics',
  icon: 'analytics',
  path: 'analytics',
  tooltip: 'Analytics',
}
export const VERSIONS = {
  type: 'versions',
  icon: 'versions',
  path: 'versions',
  tooltip: 'Version History',
}
export const EXPERTS = {
  type: 'experts',
  icon: 'experts',
  path: 'hire-an-expert',
  tooltip: 'Hire an Expert',
}

export const TABS = [
  ADD,
  BRANDING,
  SCREENS,
  DATA,
  SETTINGS,
  PUBLISH,
  ANALYTICS,
  VERSIONS,
  EXPERTS,
]
