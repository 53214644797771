import { GROUP, LAYOUT_SECTION, SECTION } from '@adalo/constants'

import { DEFAULT_DEVICE_SETTINGS } from 'utils/defaultDeviceSettings'
import getDeviceObject from 'utils/getDeviceObject'
import getResetDevicePosition from 'utils/getResetDevicePosition'
import { removeUnusedSize } from 'utils/objects/removeUnusedSize'
import translateChildren from 'utils/operations/translateChildren'
import { DeviceValue } from 'utils/responsiveTypes'
import { LayoutSectionPurpose } from 'utils/layoutSections'

import getObject from '../objects/helpers/getObject'
import { getParent } from '../device-layouts/utils'

import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'

export interface DisableDeviceSpecificLayoutOptions {
  objectId: string
  device: DeviceValue
}

export interface DisableDeviceSpecificLayoutInstruction {
  operation: 'disableDeviceSpecificLayout'
  options: DisableDeviceSpecificLayoutOptions
}

export const disableDeviceSpecificLayoutHandler = (
  state: InstructionState,
  { objectId, device }: DisableDeviceSpecificLayoutOptions
): InstructionState => {
  const { list, pathMap } = state

  const object = getObject(list, pathMap, objectId)
  const resetChildren =
    [GROUP, LAYOUT_SECTION].includes(object.type) ||
    (object.type === SECTION &&
      object.purpose === LayoutSectionPurpose.LAYOUT_HELPER)

  const deviceObject = getDeviceObject(object, device)
  const { responsivity } = deviceObject
  const { width, height } = resetChildren ? deviceObject : object

  const resetPosition = getResetDevicePosition(
    object,
    device,
    getParent(list, pathMap, object.id)
  )

  const layoutAttributes = removeUnusedSize(object, {
    ...resetPosition,
    width,
    height,
    ...(responsivity !== undefined && {
      responsivity,
    }),
  })

  let newList = updateChangedObject(
    list,
    pathMap,
    translateChildren(
      // updated object
      {
        ...object,
        shared: {
          ...DEFAULT_DEVICE_SETTINGS,
          ...object.shared,
          // set shared settings to true for given device
          [device]: true,
        },
        [device]: layoutAttributes,
      },
      // current object
      object,
      device,
      device === undefined
    ),
    device
  )

  // When device-specific layout is disabled for a group,
  // it is automatically disabled for all children of the group
  // (and all their positions should be reset as usual)
  if (
    resetChildren &&
    Array.isArray(object.children) &&
    object.children.length > 0
  ) {
    for (const child of object.children) {
      const childInstructionState = {
        ...state,
        list: newList,
      }
      const childInstructionOptions = { objectId: child.id, device }
      ;({ list: newList } = disableDeviceSpecificLayoutHandler(
        childInstructionState,
        childInstructionOptions
      ))
    }
  }

  return {
    ...state,
    list: newList,
  }
}

const disableDeviceSpecificLayout = (
  objectId: string,
  device: DeviceValue
): DisableDeviceSpecificLayoutInstruction => ({
  operation: 'disableDeviceSpecificLayout',
  options: {
    objectId,
    device,
  },
})

export default disableDeviceSpecificLayout
