import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray, formValueSelector } from 'redux-form'
import deepEqual from 'deep-equal'
import { dataTypes } from '@adalo/constants'

import { getAPIFieldTypes } from '../../../../utils/dataTypes'
import WrappedSelect from '../../../Shared/Forms/WrappedSelect'
import Accordion from '../../../Shared/Accordion'
import Icon from '../../../Shared/Icon'

const typeOptions = [
  { label: 'ID', value: 'id' },
  { label: 'URL', value: 'url' },
]

export default class FieldsSection extends Component {
  renderChildren = () => {
    const { datasource, formName } = this.props

    return (
      <div>
        <div className="api-collection-fields-header">
          <div className="api-collection-field-name">Field Name</div>
          <div className="api-collection-field-type">Type</div>
        </div>
        <FieldsSectionSub datasource={datasource} formName={formName} />
      </div>
    )
  }

  render() {
    return (
      <Accordion
        defaultExpanded
        className="api-collection-form-fields"
        title="Fields"
        titleComponent="h3"
        renderChildren={this.renderChildren}
      />
    )
  }
}

class FieldsSectionSub extends Component {
  render() {
    const { name, datasource, formName } = this.props

    return (
      <div>
        {name ? null : <PrimaryKeyField />}
        <FieldArray
          datasource={datasource}
          name={name || 'fields'}
          component={FieldsSectionItems}
          formName={formName}
        />
      </div>
    )
  }
}

class PrimaryKeyField extends Component {
  render() {
    const fieldName = 'primaryKeyField'
    const typeFieldName = 'primaryKeyType'

    return (
      <div className="api-collection-field-item">
        <div className="api-collection-field-item-sub">
          <div className="api-collection-field-name">
            <Field
              autoComplete="none"
              component="input"
              name={fieldName}
              placeholder="id"
            />
          </div>
          <div className="small api-collection-field-type api-primary-key-type">
            <span>Primary Key</span>
            <Field
              autoSelect
              className="small api-collection-field-type"
              component={WrappedSelect}
              name={typeFieldName}
              options={typeOptions}
            />
          </div>
          <Icon type="placeholder" />
        </div>
      </div>
    )
  }
}

class FieldsSectionItems extends Component {
  handleAdd = () => {
    const { fields } = this.props

    fields.push({ id: '' })
  }

  handleRemove = i => () => {
    const { fields } = this.props

    fields.remove(i)
  }

  render() {
    const { fields, datasource, formName } = this.props

    return (
      <div className="api-collection-fields">
        <ul className="api-collection-form-fields-list">
          {fields.map(field => (
            <li key={field}>
              <WrappedFieldItem
                fieldName={field}
                datasource={datasource}
                onRemove={this.handleRemove(field)}
                formName={formName}
              />
            </li>
          ))}
        </ul>
        <a onClick={this.handleAdd}>+ Add Field</a>
      </div>
    )
  }
}

class FieldItem extends Component {
  showChildren() {
    const { field } = this.props

    if (!field) {
      return false
    }

    return field.type === dataTypes.LIST || field.type === dataTypes.OBJECT
  }

  getTypeOptions() {
    const { datasource } = this.props

    const options = getAPIFieldTypes(datasource)

    return options
  }

  render() {
    const { formName, fieldName, onRemove, datasource } = this.props

    return (
      <div className="api-collection-field-item">
        <div className="api-collection-field-item-sub">
          <div className="api-collection-field-name">
            <Field
              autoComplete="none"
              component="input"
              name={`${fieldName}.id`}
              placeholder="Name"
            />
          </div>
          <Field
            autoSelect
            className="small api-collection-field-type"
            name={`${fieldName}.type`}
            component={WrappedSelect}
            options={this.getTypeOptions()}
            comparator={deepEqual}
          />
          <Icon type="trash-small" onClick={onRemove} />
        </div>
        {this.showChildren() ? (
          <div className="api-collection-field-item-children">
            <h5>Children</h5>
            <FieldsSectionSub
              formName={formName}
              name={`${fieldName}.children`}
              datasource={datasource}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const fieldItemMapState = (state, { formName, fieldName }) => {
  return {
    field: formValueSelector(formName)(state, fieldName),
  }
}

const WrappedFieldItem = connect(fieldItemMapState)(FieldItem)
