import { FC, memo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import { getBoundingBox, subPath, getObject } from '@adalo/utils'

import { getInboundLinks } from 'ducks/apps'
import {
  selectObjects,
  getMap,
  getSelectedIds,
  State,
} from 'ducks/editor/objects'
import { getPathData, getArrowPath } from 'utils/arrows'

import './Links.scss'
import { Rect } from 'utils/canvasTypes'

interface LinkProps {
  source: Rect
  target: Rect
  zoomScale: number
}
const ArrowLink: FC<LinkProps> = memo(props => {
  const { source, target, zoomScale } = props

  if (!source || !target) {
    return null
  }

  const path = getPathData(source, target)
  const arrow = getArrowPath(source, target, zoomScale)

  return (
    <g className="link-wrapper">
      <path className="link-path" d={path} strokeWidth={1 / zoomScale} />
      <path className="link-arrow" d={arrow} />
    </g>
  )
}, isEqual)

interface LinksProps {
  links: { source: Rect; target: Rect }[]
  zoomScale: number
}
const Links: FC<LinksProps> = memo(props => {
  const { links, zoomScale } = props

  if (links.length === 0) {
    return null
  }

  return (
    <g className="canvas-link-map">
      {links.map(({ source, target }) => (
        <ArrowLink
          key={`${source.x}-${source.y}-${target.x}-${target.y}`}
          source={source}
          target={target}
          zoomScale={zoomScale}
        />
      ))}
    </g>
  )
}, isEqual)

const getSelectedScreenIds = (
  screens: Rect[],
  screensMap: Record<string, string>,
  selection: string[]
): Set<string> => {
  if (selection.length === 0) {
    return new Set()
  }

  const screenPaths = new Set(
    selection.map(id => subPath(screensMap[id], 1) as string)
  )

  interface Screen {
    id: string
  }
  const getScreenId = (path: string): string => {
    const screen = getObject(screens, path) as Screen

    return screen?.id
  }

  return new Set([...screenPaths].map(getScreenId))
}

const getLinks = (
  selection: string[],
  inboundLinks: Record<string, string[]>,
  screens: Rect[],
  screensMap: Record<string, string>
) => {
  if (selection.length === 0) {
    return []
  }

  const selectedScreenIds = getSelectedScreenIds(screens, screensMap, selection)
  const filteredLinks = []
  for (const [targetId, sourceIds] of Object.entries(inboundLinks)) {
    for (const sourceId of sourceIds) {
      if (
        !selectedScreenIds.has(sourceId) &&
        !selectedScreenIds.has(targetId)
      ) {
        continue
      }
      const sourceIndex = parseInt(screensMap[sourceId] ?? '', 10)
      const targetIndex = parseInt(screensMap[targetId] ?? '', 10)
      const source = screens[sourceIndex]
      const target = screens[targetIndex]
      if (!source || !target) {
        continue
      }
      filteredLinks.push({
        source: getBoundingBox([source]),
        target: getBoundingBox([target]),
      })
    }
  }

  return filteredLinks
}

interface OwnProps extends RouteComponentProps<{ appId: string }> {
  zoomScale: number
}
interface WrapperProps extends OwnProps {
  links: { source: Rect; target: Rect }[]
}
const Wrapper: FC<WrapperProps> = ({ zoomScale, links }) => (
  <Links links={links} zoomScale={zoomScale} />
)

const mapStateToProps = (state: State, props: OwnProps) => {
  const { match, zoomScale } = props
  const { appId } = match.params

  const screens = selectObjects(state) as Rect[]
  const screensMap = getMap(state) as Record<string, string>
  const selection = getSelectedIds(state)
  const inboundLinks = getInboundLinks(state, appId) as Record<string, string[]>

  const links = getLinks(selection, inboundLinks, screens, screensMap) as {
    source: Rect
    target: Rect
  }[]

  return {
    links,
    zoomScale: Math.round(zoomScale * 10) / 10,
  }
}

export default withRouter(connect(mapStateToProps)(Wrapper))
