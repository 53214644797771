import { useDispatch, useSelector } from 'react-redux'
import { selectObject } from 'ducks/editor/objects'
import DeviceType from 'ducks/editor/types/DeviceType'
import { openAccordion } from 'ducks/accordions'
import { EditorObject } from 'utils/responsiveTypes'
import {
  getLayoutSettingsGroup,
  getLayoutSettingsItemId,
} from 'utils/accordionGroups'

const useOpenDeviceLayoutSettings = (
  objectId: string
): ((device: DeviceType) => void) => {
  const dispatch = useDispatch()
  const object = useSelector(
    state => selectObject(state, objectId) as EditorObject
  )

  const openLayoutSettingsForDevice = (device: DeviceType) => {
    let accordionItemSuffix: DeviceType | 'shared' | undefined

    if (object.shared?.[device] || !object?.[device]) {
      // Open the shared layout settings
      accordionItemSuffix = 'shared'
    } else if (object.shared && !object.shared[device] && object[device]) {
      // Open the layout settings for this device
      accordionItemSuffix = device
    }

    if (accordionItemSuffix) {
      // Open the shared layout accordion for this object
      const groupId = getLayoutSettingsGroup(objectId)
      const accordionItemId = getLayoutSettingsItemId(
        objectId,
        accordionItemSuffix
      )
      dispatch(openAccordion(groupId, accordionItemId))
    }
  }

  return openLayoutSettingsForDevice
}

export default useOpenDeviceLayoutSettings
