import { useContext } from 'react'
import styled from 'styled-components'
import { ReactComponent as CheckIcon } from 'components/Shared/Icon/icons/check.svg'
import TableThemeContext from './TableThemeContext'

const Cell = styled('div')<{ $tableRowFontColor: string }>`
  padding: 5px;

  svg {
    width: 24px;
    height: 24px;
    fill: ${props => props.$tableRowFontColor};
  }
`

const BooleanCell = (): JSX.Element => {
  const { tableRowFontColor } = useContext(TableThemeContext)

  return (
    <Cell $tableRowFontColor={tableRowFontColor}>
      <CheckIcon />
    </Cell>
  )
}

export default BooleanCell
