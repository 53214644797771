import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LAYOUT_SECTION } from '@adalo/constants'
import { getObject, pathLength, subPath } from '@adalo/utils'

import {
  selectObject,
  getObjectPosition,
  getZoom,
  getObjectList,
  getMap,
} from 'ducks/editor/objects'

import {
  getHoverSelection,
  getParentSelection,
  getSelection,
} from 'ducks/editor/selection'
import { getMarquee } from 'ducks/editor/marquee'
import {
  getContainerFromSection,
  isChildOfSection,
  isContainerSectionElement,
  isLayoutHelperSectionElement,
  isSectionElement,
  selectSectionChildNodes,
  selectSectionChildParentNodes,
} from 'utils/layoutSections'

import Box from './Box'

import './HoverSelection.scss'

class HoverSelection extends Component {
  render() {
    const { marquee, objects, parentObjects, zoom, magicLayout } = this.props

    if (marquee || (objects.length === 0 && parentObjects.length === 0)) {
      return null
    }

    return (
      <g>
        {objects.map(
          obj =>
            obj && (
              <Box
                isHover
                key={obj.id}
                object={obj}
                zoom={zoom}
                magicLayout={magicLayout}
                parentSelection={false}
              />
            )
        )}
        {parentObjects.map(
          obj =>
            obj && (
              <Box
                isHover
                key={obj.id}
                object={obj}
                zoom={zoom}
                magicLayout={magicLayout}
                parentSelection={obj.type !== LAYOUT_SECTION}
              />
            )
        )}
      </g>
    )
  }
}

const mapStateToProps = state => {
  const parentSelection = getParentSelection(state)
  const currentSelection = getSelection(state)

  /** @type {Array<import('utils/responsiveTypes').EditorObject>} */
  const parentObjects = []
  for (const id of parentSelection) {
    if (getSelection(state).indexOf(id) === -1) {
      const object = {
        ...selectObject(state, id),
        ...getObjectPosition(state, id),
      }

      // When dragging a child of a container inside a section (e.g. a rectangle containing a button),
      // we want to select that instead of the entire section/container
      if (object.type === LAYOUT_SECTION) {
        const container = getContainerFromSection(object)

        parentObjects.push({
          ...selectObject(state, container.id),
          ...getObjectPosition(state, container.id),
        })
      }

      parentObjects.push(object)
    }
  }

  /** @type {Set<string>} */
  const hoverSelection = new Set(getHoverSelection(state))

  /** @type {Array<import('utils/responsiveTypes').EditorObject>} */
  let hoveredObjects = []

  for (const id of hoverSelection) {
    hoveredObjects = [
      {
        ...selectObject(state, id),
        ...getObjectPosition(state, id),
      },
    ]
  }

  /** @type {Array<import('utils/responsiveTypes').EditorObject>} */
  let objects = []

  const [hoveredObject] = hoveredObjects

  // when hovering over a layout section OR a layout helper
  // we need to select it's childs up until the first non-section child
  const derivedObjects =
    hoveredObject?.type === LAYOUT_SECTION ||
    isLayoutHelperSectionElement(hoveredObject)
      ? selectSectionChildNodes(hoveredObjects, state)
      : hoveredObjects

  for (const obj of derivedObjects) {
    if (parentSelection.indexOf(obj.id) === -1) {
      objects.push(obj)
    }
  }

  if (
    hoveredObjects.length > 0 &&
    (isContainerSectionElement(hoveredObject) ||
      isLayoutHelperSectionElement(hoveredObject))
  ) {
    // when hovering a layout helper or a container we need to fetch the
    // parent until we get the layout section
    const derivedParentNodes = isChildOfSection(hoveredObject?.id, {
      list: getObjectList(state),
      map: getMap(state),
    })
      ? selectSectionChildParentNodes(hoveredObjects, state)
      : hoveredObjects

    for (const obj of derivedParentNodes) {
      if (parentSelection.indexOf(obj.id) === -1) {
        const objAlreadyExists = objects.find(o => o.id === obj.id)

        if (!objAlreadyExists) {
          objects.push(obj)
        }
      }
    }
  }

  const hasSelectedSectionChild = isChildOfSection(currentSelection[0], {
    list: getObjectList(state),
    map: getMap(state),
  })

  // this is a cleanup process since we don't really hover over child of containers (groups, lists, shapes and so on)
  // so in order to not select the containers we check to see if what's being hovered is the parent
  // of what was just selected, if that's the case we filter it out from objects
  // we only want to do this though if the parent is not a section element
  if (hasSelectedSectionChild) {
    const map = getMap(state)
    const selectedPath = map[currentSelection[0]]

    if (selectedPath) {
      const selectedParentPath = subPath(
        selectedPath,
        pathLength(selectedPath) - 1
      )
      const selectedParent = getObject(getObjectList(state), selectedParentPath)

      if (
        selectedParent?.id === hoveredObject?.id &&
        !isSectionElement(selectedParent)
      ) {
        objects = objects.filter(obj => obj.id !== selectedParent.id)
      }
    }
  }

  // we have a special case when the LAYOUT_SECTION is selected
  // we also want the container to be in the hover active state
  // this means that we need to filter the container here when
  // the selected object is a LAYOUT_SECTION
  // this only happens when the container itself is not being hovered
  const selectedObject = selectObject(state, currentSelection[0])

  if (
    selectedObject?.type === LAYOUT_SECTION &&
    !isContainerSectionElement(hoveredObject)
  ) {
    objects = objects.filter(obj => !isContainerSectionElement(obj))
  }

  // we need to deduplicate the objects if repeated nodes are present when fetching
  // child/parent nodes
  const dedupedObjects = Array.from(
    new Map(objects.map(object => [object.id, object])).values()
  )

  return {
    objects: dedupedObjects,
    parentObjects,
    zoom: getZoom(state),
    marquee: getMarquee(state),
  }
}

export default connect(mapStateToProps)(HoverSelection)
