import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { getId } from '@adalo/utils'

import { openAccordion } from 'ducks/accordions'
import BindableTextControl from 'components/Shared/BindableTextControl'
import Button from 'components/Shared/Button'
import { GroupedAccordion } from 'components/Shared/Accordion'
import { IconButton } from 'components/Shared/Icon'

import SimpleTextControl from './Libraries/SimpleTextControl'

import './APIFilters.scss'

const ACCORDION_NAME = 'api-filters'

class APIFilters extends Component {
  handleAdd = () => {
    let { name, value, onChange, openAccordion } = this.props
    value = value || []

    const id = getId()
    const result = value.concat({ id, name: '', value: '' })

    onChange({ [name]: result })
    openAccordion(ACCORDION_NAME, id)
  }

  handleRemove = index => () => {
    let { name, value, onChange } = this.props
    value = [...value]
    value.splice(index, 1)

    if (value.length === 0) {
      value = null
    }

    onChange({ [name]: value })
  }

  handleUpdate = change => {
    const index = parseInt(Object.keys(change)[0])
    const item = change[index]

    let { name, value, onChange } = this.props

    value = [...value]
    value.splice(index, 1, item)

    onChange({ [name]: value })
  }

  retrieveAdaloDataID = value => {
    if (value.length === 0) {
      return 'ext-collection-add-first-filter'
    } else if (value.length === 1) {
      return 'ext-collection-add-another-filter'
    } else {
      return null
    }
  }

  render() {
    let { value, objectId } = this.props
    value = value || []
    const empty = value.length === 0

    return (
      <div
        className={classNames(
          'inspect-query-param-filters',
          empty && 'inspect-query-param-filters-empty'
        )}
      >
        {!empty && <p className="inspect-query-param-filters-title">Filters</p>}
        <>
          {value.map((itm, i) => (
            <Item
              key={itm.id}
              name={i}
              value={itm}
              onChange={this.handleUpdate}
              onRemove={this.handleRemove(i)}
              objectId={objectId}
            />
          ))}
        </>
        <Button
          small
          gray
          listAddButton
          icon="plus-small"
          onClick={this.handleAdd}
          data-adalo-id={this.retrieveAdaloDataID(value)}
        >
          {empty ? 'Add Filter' : 'Add Another Filter'}
        </Button>
      </div>
    )
  }
}

export default connect(null, { openAccordion })(APIFilters)

class Item extends Component {
  handleChange = newVal => {
    const { name, value, onChange } = this.props

    onChange({ [name]: { ...value, ...newVal } })
  }

  renderChildren = () => {
    const { value, objectId } = this.props

    return (
      <div className="form-inspect-field-wrapper">
        <SimpleTextControl
          name="name"
          displayName="Name"
          value={value.name}
          onChange={this.handleChange}
        />
        <BindableTextControl
          name="value"
          displayName="Value"
          value={value.value}
          onChange={this.handleChange}
          objectId={objectId}
          hideParentListOptions
        />
      </div>
    )
  }

  render() {
    const { value, onRemove } = this.props
    const name = value.name || 'Untitled'
    const { id } = value

    return (
      <div className="data-accordion-item">
        <GroupedAccordion
          hideCarret
          boxed
          group={ACCORDION_NAME}
          color="orange"
          itemId={id}
          title={
            <>
              <div className="data-accordion-title-text">
                <p>Query Parameter</p>
                <p className="action-item-type-subtitle">{name}</p>
              </div>
              <IconButton type="trash-small" onClick={onRemove} />
            </>
          }
          renderChildren={this.renderChildren}
        />
      </div>
    )
  }
}
