import {
  LIST,
  listTypes,
  DeviceType as DeviceTypeValues,
} from '@adalo/constants'
import {
  DeviceValue,
  EditorObject,
  ListAttributes,
  ListTypes,
} from 'utils/responsiveTypes'

export const COLUMN_BREAKPOINTS = {
  SHARED: 'SHARED',
  CUSTOM: 'CUSTOM',
}

export const DEVICE_COLUMN_SETTINGS = {
  [DeviceTypeValues.MOBILE]: {
    rowMargin: 8,
    columns: {
      min: 1,
      max: 12,
      default: 1,
    },
  },
  [DeviceTypeValues.TABLET]: {
    rowMargin: 8,
    columns: {
      min: 1,
      max: 12,
      default: 1,
    },
  },
  [DeviceTypeValues.DESKTOP]: {
    rowMargin: 8,
    columns: {
      min: 1,
      max: 12,
      default: 1,
    },
  },
}

const getCustomListProperties = (
  baseObject: EditorObject,
  deviceType: DeviceValue | undefined
): {
  rowMargin: number
  columnCount: number
  listType: ListTypes
} => {
  const { type, columnBreakpoints = COLUMN_BREAKPOINTS.SHARED } = baseObject

  if (type !== LIST) {
    throw new Error(
      'getCustomListProperties should only be called on a list object'
    )
  }

  const defaults = {
    rowMargin: baseObject.rowMargin || 0,
    columnCount: baseObject.columnCount || 1,
    listType: baseObject.listType || listTypes.DEFAULT,
  }

  if (columnBreakpoints === COLUMN_BREAKPOINTS.SHARED) {
    return defaults
  }

  if (columnBreakpoints === COLUMN_BREAKPOINTS.CUSTOM) {
    if (!baseObject.deviceColumns) {
      throw new Error(`Missing deviceColumns for object ${baseObject.id}`)
    }

    if (!deviceType) {
      return defaults
    }

    const deviceColumns = baseObject.deviceColumns[
      deviceType
    ] as Partial<ListAttributes>

    return {
      rowMargin: deviceColumns.rowMargin || 0,
      columnCount: deviceColumns.columnCount || 1,
      listType: deviceColumns.listType || listTypes.DEFAULT,
    }
  }

  throw new Error(`Unknown columnBreakpoints: ${String(columnBreakpoints)}`)
}

export default getCustomListProperties
