import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'

// components
import BindableTextControl from 'components/Shared/BindableTextControl'

// ducks
import {
  updateObject,
  selectObject,
  getObjectPosition,
  getZoom,
} from 'ducks/editor/objects'
import { setEditingText, getEditingText } from 'ducks/editor/textEditing'

// utils
import { ESC, RETURN } from 'utils/keyboard'
import { getTransform } from 'utils/zoom'
import { getFontFamily } from 'utils/type'
import { normalizeColor } from 'utils/colors'

class TextEditor extends Component {
  handleKeyDown = e => {
    e.stopPropagation()

    if ((e.which === RETURN && e.metaKey) || e.which === ESC) {
      this.handleBlur()
    }
  }

  handleChange = e => {
    const { object, updateObject } = this.props

    updateObject(object.id, e)
  }

  handleBlur = () => {
    const { setEditingText } = this.props

    setEditingText(false)
  }

  inputRef = el => {
    // findDomNode is needed here because of react-textarea-autosize
    // eslint-disable-next-line react/no-find-dom-node
    this.input = findDOMNode(el)

    if (this.input) {
      window.setTimeout(() => {
        this.input.focus()
        this.input.select()
      }, 20)
    }
  }

  concatLayoutText = layoutText => {
    if (!Array.isArray(layoutText)) {
      return ''
    }

    return layoutText.join('\n')
  }

  render() {
    const { branding, object, position, zoom } = this.props

    if (!object) {
      return null
    }

    let {
      fontSize,
      fontFamily,
      textAlignment,
      color,
      left,
      right,
      minWidth,
      maxWidth,
      ...styles
    } = object
    const { x, y, width, height } = position

    const normalizedFontFamily = getFontFamily(fontFamily, branding)
    const lineHeight = `${Math.ceil(fontSize * 1.15)}px`

    const transform = getTransform(zoom)
    const normalizedColor = normalizeColor(color, branding)

    styles = {
      ...styles,
      fontFamily: normalizedFontFamily,
      fontSize,
      lineHeight,
      textAlign: textAlignment,
      color: normalizedColor,
      transform,
      backgroundColor: 'transparent',
    }

    let inputOffset = 0

    if (object.textAlignment === 'center') {
      inputOffset -= fontSize / 2
    } else if (object.textAlignment === 'right') {
      inputOffset -= fontSize
    }

    const inputStyles = {
      width,
      textAlign: object.textAlignment,
      marginLeft: inputOffset,
    }

    if (object.autoWidth) {
      inputStyles.width += fontSize
    }

    const subStyles = {
      left: x,
      top: y,
      width,
      height,
    }

    return (
      <div className="text-editor-wrapper">
        <div className="text-editor-backdrop" onClick={this.handleBlur} />
        <div className="text-editor" style={{ ...styles, width, height }}>
          <div className="text-editor-sub" style={subStyles}>
            <div
              className="text-editor-input-backdrop"
              style={{ width, height }}
            />
            <BindableTextControl
              name="text"
              displayName="Text"
              role="textbox"
              reference="text"
              onChange={this.handleChange}
              objectId={object.id}
              value={object.text}
              hideBinding
              hideDisplayName
              disableIcon
              disableChip
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const object = selectObject(state, getEditingText(state))

  return {
    object,
    position: object && getObjectPosition(state, object.id),
    zoom: getZoom(state),
  }
}

export default connect(mapStateToProps, { updateObject, setEditingText })(
  TextEditor
)
