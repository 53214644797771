import React, { Component } from 'react'
import IconMenu from '../../../Shared/MaterialIcons/IconMenu'

export default class IconControl extends Component {
  handleChange = val => {
    const { name, onChange } = this.props

    onChange({ [name]: val })
  }

  render() {
    const { value } = this.props

    return <IconMenu value={value} onChange={this.handleChange} />
  }
}
