import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { getApp, updateApp } from 'ducks/apps'

import Form from './Form'

class WebPublish extends Component {
  handleSubmit = values => {
    const { app, updateApp } = this.props
    const { id } = app
    const { path, DomainId, showAddToHomeScreen, previewType } = values
    const webSettings = {
      ...(values.webSettings ?? {}),
      showAddToHomeScreen,
      previewType,
    }
    updateApp(id, { path, DomainId, webSettings })
  }

  render() {
    const {
      app,
      appId,
      needUpgrade,
      getAppHost,
      showWebSettings,
      customDomainsDisabled,
    } = this.props

    const domainSettingsTitle = !showWebSettings ? null : (
      <h2>Domain Settings</h2>
    )

    const formInitialValues = {
      ...app,
      previewType: app?.webSettings?.previewType ?? 'web',
      // Only apps with magicLayout should default to showing add to homescreen
      showAddToHomeScreen:
        app?.webSettings?.showAddToHomeScreen ?? app?.magicLayout,
    }

    const wrapperClassName = classNames('web-publish', {
      'web-publish-web-settings-showing': showWebSettings,
    })

    return (
      <div className={wrapperClassName}>
        {domainSettingsTitle}
        <Form
          app={app}
          appId={appId}
          initialValues={formInitialValues}
          onSubmit={this.handleSubmit}
          needUpgrade={needUpgrade}
          getAppHost={getAppHost}
          showWebSettings={showWebSettings}
          customDomainsDisabled={customDomainsDisabled}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { appId }) => ({
  app: getApp(state, appId),
})

export default connect(mapStateToProps, { updateApp })(WebPublish)
