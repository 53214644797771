import qs from 'qs'

export const assetURL = filename => {
  if (typeof filename === 'object') {
    filename = filename.url
  }

  const params = { auto: 'compress' }

  return `${process.env.REACT_APP_IMAGE_BASE_URL}/${filename}?${qs.stringify(
    params
  )}`
}

export const fileURL = filename => {
  if (typeof filename === 'object') {
    filename = filename.url
  }

  return `${process.env.REACT_APP_FILE_UPLOADS_URL}/${filename}`
}

export const assetProxyURL = filename => {
  const baseURL = process.env.REACT_APP_BACKEND_URL

  return `${baseURL}/images/asset-proxy?filename=${filename}`
}
