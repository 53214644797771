import React from 'react'
import Analytics from 'react-router-ga'

export default function AnalyticsWrapper({ children }) {
  const id = process.env.REACT_APP_GA_ID

  if (id) {
    return <Analytics id={id}>{children}</Analytics>
  }

  return <>{children}</>
}
