import { singularize, pluralize } from 'utils/strings'

const addNewField = (fields, value, tables, reciprocal) => {
  const obj = {}

  if (value.name) obj.name = value.name

  if (value.type && tables) {
    const { type, tableId } = value
    const item = tables.filter(t => t.id === tableId)

    if (item.length === 1) {
      const name = item[0].name
      obj.name = type === 'belongsTo' ? singularize(name) : pluralize(name)
      obj.type = value
    }
  } else if (value.type && !tables) {
    obj.type = value.type
  } else {
    obj.type = value
  }

  if (reciprocal) {
    obj.reciprocal = reciprocal
  }

  return fields.push(obj)
}

export default addNewField
