import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { SortableContainer, SortableElement } from '@adalo/react-sortable-hoc'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { dataTypes, fields } from '@adalo/constants'
import update from 'immutability-helper'

import { getFieldIcon } from 'utils/icons'
import { getOptions } from 'utils/dataTypes'

import {
  addTableField,
  getOrderedFields,
  getTableIds,
  removeTableField,
  updateTableField,
  updateFieldOrder,
} from 'ducks/apps/datasources'
import { getAccordionState } from 'ducks/accordions'
import { showModal, NEW_RELATIONSHIP } from 'ducks/editor/modals'
import { getThirdPartyApiKeyFor } from 'ducks/thirdPartyApiKeys'
import {
  getActiveState,
  getTrialState,
  isFeatureEnabled,
} from 'ducks/organizations'

import useHandleTrialOrUpgrade, {
  generateHandleTrial,
} from 'hooks/useHandleTrialOrUpgrade'

import { singularize, pluralize } from 'utils/strings'

import { GroupedAccordion } from 'components/Shared/Accordion'
import Button from 'components/Shared/Button'
import Icon, { IconButton } from 'components/Shared/Icon'

import EditCollection from '../EditCollection'
import Content from './Content'

import {
  THEMES,
  BEFORE,
  START_TRIAL,
  UPGRADE,
} from '../../../../../../../constants'

const Properties = props => {
  return (
    <div className="data-accordion-item">
      <GroupedAccordion {...props} />
    </div>
  )
}

const SortableProperty = SortableElement(Properties)

class CollectionProperties extends React.Component {
  handleRemoveTableField = async (e, field, fieldId) => {
    e.stopPropagation()

    const { appId, datasourceId, tableId, tables, removeTableField } =
      this.props

    const name = field.name || 'undefined'

    if (field.locked) {
      return window.alert(`${field.name} cannot be removed`)
    }

    if (field.type && field.type.type) {
      // reciprocal tableId and fieldId
      let rTableId
      let rFieldId

      // if the field being deleted is a reciprocal field
      if (field.reciprocal) {
        const { reciprocal } = field
        rTableId = reciprocal.tableId
        rFieldId = reciprocal.fieldId
      } else {
        // need to find the reciprocal field
        const table = tables.find(table => table.id === field.type.tableId)

        const tableField = Object.keys(table.fields).find(f => {
          const field = table.fields[f]
          if (!field || !field.reciprocal) return null
          if (field.reciprocal.fieldId === fieldId) return f
        })

        if (table && tableField) {
          rTableId = table.id
          rFieldId = tableField
        }
      }

      const confirm = window.confirm(
        // eslint-disable-next-line max-len
        `Are you sure you want to delete ${name}? This will also remove the reciprocal field`
      )

      if (!confirm) return null
      await removeTableField(appId, datasourceId, rTableId, rFieldId)

      return removeTableField(appId, datasourceId, tableId, fieldId)
    }

    const confirm = window.confirm(`Are you sure you want to delete ${name}?`)
    if (!confirm) return null

    return removeTableField(appId, datasourceId, tableId, fieldId)
  }

  // render function for accordion children content
  renderChildren = (field, fieldId) => {
    const {
      appId,
      closeAccordion,
      datasourceId,
      tableId,
      tables,
      updateTableField,
    } = this.props

    const { name, type, disabled = false, enabled = true } = field

    const handleSubmit = async values => {
      let { name } = values

      // trim whitespace
      name = String(name).trim()
      const field = { name }

      await updateTableField({
        appId,
        datasourceId,
        tableId,
        fieldId,
        field,
      })

      return closeAccordion(tableId)
    }

    return (
      <Content
        appId={appId}
        datasourceId={datasourceId}
        tables={tables}
        form={fieldId}
        initialValues={{ name, type, disabled, enabled }}
        onSubmit={handleSubmit}
        closeAccordion={closeAccordion}
        tableId={tableId}
      />
    )
  }

  // render function for accordion title
  renderTitle = (field, fieldId) => {
    const {
      childAccordion,
      googleApiKey,
      isLocationEnabled,
      handleTrial,
      trialState,
      isPaidOrg,
      appId,
    } = this.props

    let rightHeaderIcon = null
    let callToAction = null

    let parsedType = field.type

    if (field.type === dataTypes.LOCATION) {
      if (!isLocationEnabled) {
        parsedType = 'location-disabled'
        rightHeaderIcon = <Icon type="lock-small" small />

        const showTrial = trialState === BEFORE && !isPaidOrg

        const hoverContent = showTrial ? START_TRIAL : UPGRADE

        callToAction = (
          <p
            onClick={() => handleTrial(trialState, isPaidOrg, appId)}
            className="accordion-call-to-action"
          >
            {hoverContent}
          </p>
        )
      }

      field.disabled = !isLocationEnabled
      field.enabled = isPaidOrg
    }

    const leftHeaderIcon = <Icon type={getFieldIcon(parsedType)} />

    if (childAccordion === fieldId) {
      if (field.isPrimaryField || field.locked) {
        rightHeaderIcon = <Icon type="lock-small" />
      } else {
        rightHeaderIcon = (
          <IconButton
            type="trash-small"
            onClick={e => this.handleRemoveTableField(e, field, fieldId)}
          />
        )
      }
    } else {
      if (
        field.type === dataTypes.LOCATION &&
        (!googleApiKey || !googleApiKey.isValid) &&
        isLocationEnabled
      ) {
        rightHeaderIcon = <Icon type="warning" small color="darkerGray" />
      }
    }

    return (
      <>
        {leftHeaderIcon}
        <span className="title">{field.name}</span>
        {callToAction}
        {rightHeaderIcon}
      </>
    )
  }

  render() {
    const { items, table, tableId, trialState, isPaidOrg } = this.props

    return (
      <div>
        {items.map((key, index) => {
          const field = table.fields[key]

          const hiddenFields = [
            fields.TEMPORARY_PASSWORD,
            fields.TEMPORARY_PASSWORD_EXPIRES_AT,
          ]

          if (hiddenFields.includes(key)) return null

          const showTrial = trialState === BEFORE && !isPaidOrg

          const hoverContent = showTrial ? START_TRIAL : UPGRADE

          return (
            <SortableProperty
              key={key}
              index={index}
              title={this.renderTitle(field, key)}
              renderChildren={() => this.renderChildren(field, key)}
              itemId={key}
              group={tableId}
              className="data-panel-accordion-children"
              hideCarret
              featureDisabled={field.disabled}
              hoverContent={hoverContent}
            />
          )
        })}
      </div>
    )
  }
}

const SortableProperties = connect(
  (state, { appId }) => ({
    googleApiKey: getThirdPartyApiKeyFor(state, appId, 'google'),
    isLocationEnabled: isFeatureEnabled(state, 'geolocation'),
    trialState: getTrialState(state).trialState,
    isPaidOrg: getActiveState(state),
  }),
  dispatch => {
    const handleTrial = (trialState, isPaidOrg, appId) => {
      const handle = generateHandleTrial({
        appId,
        trialState,
        type: 'geolocation',
        dispatch,
        isPaidOrg,
      })

      handle()
    }

    return { handleTrial }
  }
)(SortableContainer(CollectionProperties))

const lockIcon = <Icon type="lock" color="darkerGray" small />

const AccordionChildren = props => {
  const {
    appId,
    datasourceId,
    isEditingCollection,
    toggleEditCollection,
    table,
    tables,
    orderedFields,
  } = props

  const { trialState, isLocationEnabled, isPaidOrg } = useSelector(state => {
    const { trialState } = getTrialState(state)
    const isLocationEnabled = isFeatureEnabled(state, 'geolocation')
    const isPaidOrg = getActiveState(state)

    return { trialState, isLocationEnabled, isPaidOrg }
  })

  const handleTrialOrUpgrade = useHandleTrialOrUpgrade({
    appId,
    trialState,
    type: 'geolocation',
    isPaidOrg,
  })

  const { tableId } = table
  const isAPI = table.type === 'api'
  const isXano = table.type === 'xano'

  const showTrial = trialState === BEFORE && !isPaidOrg
  const hoverContent = showTrial ? START_TRIAL : UPGRADE

  const handleHeadingOptions = opts => {
    const locationOption = opts.find(o => o.value === dataTypes.LOCATION)

    if (locationOption) {
      locationOption.hoverContent = hoverContent
      /* Returning null removes locked props after upgrade/trial start without needing of refresh */
      locationOption.rightIcon = !isLocationEnabled ? lockIcon : null
      locationOption.onClick = !isLocationEnabled ? handleTrialOrUpgrade : null
      locationOption.locked = !isLocationEnabled
    }

    return opts
  }

  const allOptions = getOptions(tables, datasourceId)

  const options = handleHeadingOptions(allOptions)

  // cleanup accordion on component unmount
  useEffect(() => {
    const { closeAccordion } = props

    return () => {
      if (isEditingCollection) toggleEditCollection()
      closeAccordion(tableId)
    }
  }, [isEditingCollection])

  const handleAddProperty = async value => {
    const { addTableField, appId, datasourceId, tableId, openAccordion } = props
    const field = { type: value, name: 'New Property' }

    if (typeof value === 'string') {
      const { fieldId } = await addTableField(
        appId,
        datasourceId,
        tableId,
        field
      )

      return openAccordion(tableId, fieldId)
    }

    try {
      const { showModal, tables } = props

      // * selectedCollection is the collection selected for the relationship
      // * expandedCollection is the collection accordion that is currently open
      const opts = {
        appId,
        selectedCollection: {
          datasourceId: value.datasourceId,
          tableId: value.tableId,
        },
        expandedCollection: { datasourceId, tableId },
      }

      const { selectedCollection, expandedCollection } = opts
      const { type } = (await showModal(NEW_RELATIONSHIP, opts)).value

      const relationship = { type, tableId: undefined, datasourceId }

      // assign tableId, either selected or expended, based on the type
      switch (type) {
        case 'belongsTo':
        case 'manyToMany':
          relationship.tableId = selectedCollection.tableId

          break
        case 'hasMany':
          relationship.type = 'belongsTo'
          relationship.tableId = expandedCollection.tableId

          break
      }

      // assign the relationship object to field.type
      field.type = relationship

      let table

      if (type === 'hasMany') {
        table = tables.find(table => table.id === expandedCollection.tableId)
        if (table && table.name) field.name = singularize(table.name)

        const { fieldId } = await addTableField(
          appId,
          datasourceId,
          selectedCollection.tableId,
          field
        )

        // open the property accordion
        return openAccordion(tableId, fieldId)
      }

      table = tables.find(table => table.id === selectedCollection.tableId)

      if (table && table.name) {
        if (type === 'manyToMany') field.name = pluralize(table.name)
        if (type === 'belongsTo') field.name = singularize(table.name)
      }

      const { fieldId } = await addTableField(
        appId,
        datasourceId,
        tableId,
        field
      )

      // open the property accordion
      return openAccordion(tableId, fieldId)
    } catch (err) {
      return console.log('Error:', err)
    }
  }

  const handleEditCollectionSubmit = async values => {
    const { appId, datasourceId, tableId, table, updateTable } = props

    const updatedTable = update(table, {
      name: { $set: String(values.name).trim() },
    })

    await updateTable(appId, datasourceId, tableId, updatedTable)

    return toggleEditCollection()
  }

  const isValidPrimaryField = type => {
    const { IMAGE, FILE, PASSWORD, LOCATION } = dataTypes
    const types = [IMAGE, FILE, PASSWORD, LOCATION]

    return !type.type && !types.includes(type)
  }

  const handleUpdatePrimaryField = async fieldId => {
    const { appId, datasourceId, table, tableId, updateTableField } = props

    if (table.fields[fieldId]) {
      const { type } = table.fields[fieldId]

      // relationships, images and files can not become primary fields
      if (type.type) return null
      if (type === dataTypes.IMAGE || type === dataTypes.FILE) return null
    }

    for (const id in table.fields) {
      if (id) {
        const field = table.fields[id]

        // remove existing primary field key
        if (field.isPrimaryField) delete field.isPrimaryField
        await updateTableField({ appId, datasourceId, tableId, id, field })
      }
    }

    const field = table.fields[fieldId]
    field.isPrimaryField = true
    const opts = { appId, datasourceId, tableId, fieldId, field }

    return updateTableField(opts)
  }

  const handleSortProperty = async ({ oldIndex, newIndex }) => {
    const {
      appId,
      datasourceId,
      table,
      tableId,
      orderedFields,
      updateFieldOrder,
    } = props

    const fields = orderedFields.slice()
    const field = fields[oldIndex]

    if (table.fields[field]) {
      const { type } = table.fields[field]

      // reassign primary field
      // * prevent relationship, image or files from becoming a primary field
      if (oldIndex === 0) {
        if (fields.length <= 1) return null
        const nextField = table.fields[fields[1]]
        if (!isValidPrimaryField(nextField.type)) return null
        await handleUpdatePrimaryField(fields[1])
      }

      if (newIndex === 0) {
        if (!isValidPrimaryField(type)) return null
        await handleUpdatePrimaryField(field)
      }
    }

    fields.splice(oldIndex, 1)
    fields.splice(newIndex, 0, field)

    return updateFieldOrder(appId, datasourceId, tableId, fields)
  }

  // render function for editing collection
  const renderEditCollection = () => {
    const { name } = props

    return (
      <EditCollection
        initialValues={{ name }}
        onSubmit={handleEditCollectionSubmit}
        toggleEditCollection={toggleEditCollection}
      />
    )
  }

  const showAddProperty = !isAPI && !isXano

  return (
    <>
      {isEditingCollection ? renderEditCollection() : null}
      <SortableProperties
        items={orderedFields}
        onSortEnd={handleSortProperty}
        distance={4}
        {...props}
      />
      {showAddProperty && (
        <MultiMenuTrigger
          menu={options}
          rowHeight={40}
          onSelect={handleAddProperty}
          fitParent
          menuTheme={THEMES.DATA}
        >
          <Button
            text
            orange
            listAddButton
            data-adalo-id="collection-add-property"
          >
            <Icon type="plus" />
            Add Property
          </Button>
        </MultiMenuTrigger>
      )}
    </>
  )
}

const mapStateToProps = (state, { appId, datasourceId, table, tableId }) => ({
  orderedFields: getOrderedFields(table, true),
  childAccordion: getAccordionState(state, tableId),
  tables: getTableIds(state, appId, datasourceId),
  isPaidOrg: getActiveState(state),
})

const mapDispatchToProps = {
  addTableField,
  removeTableField,
  showModal,
  updateTableField,
  updateFieldOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccordionChildren)
