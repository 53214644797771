import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { APISetup, AddAPIModal } from 'components/AppDetail/Data'
import { CollectionPermissionsModal } from 'components/AppDetail/Data/Permissions'

import Modal from './Modal'

const Data = props => {
  return (
    <Switch>
      <Route
        path="/apps/:appId/data/new-external-collection"
        component={AddAPIModal}
      />
      <Route
        path="/apps/:appId/data/:datasourceId/:tableId/external"
        component={AddAPIModal}
      />
      <Route
        exact
        path="/apps/:appId/data/:datasourceId"
        component={APISetup}
      />
      <Route
        exact
        path="/apps/:appId/data/:datasourceId/permissions"
        component={CollectionPermissionsModal}
      />
      <Route
        path="/apps/:appId/data/:datasourceId/:tableId"
        component={Modal}
      />
    </Switch>
  )
}

export default Data
