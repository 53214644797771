import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import { getOrderedFields } from 'ducks/apps/datasources'

import Modal from 'components/Shared/Modal'

import FormRow from './FormRow'

class DataObjectForm extends Component {
  handleGoBack = () => {
    const { backURL, history } = this.props

    return history.push(backURL)
  }

  render() {
    const {
      appId,
      datasourceId,
      tableId,
      id,
      handleSubmit,
      table,
      title,
      backURL,
      onDelete,
      initialObject,
      errorMessage,
      saving,
    } = this.props

    return (
      <form className="data-object-form" onSubmit={handleSubmit}>
        <Modal.Header
          title={title}
          handleDelete={onDelete}
          goBack={this.handleGoBack}
          color="orange"
        />
        <Modal.Content>
          <Modal.Container size="sm">
            {getOrderedFields(table).map(fieldId => (
              <FormRow
                key={fieldId}
                fieldId={fieldId}
                field={table.fields[fieldId]}
                appId={appId}
                datasourceId={datasourceId}
                tableId={tableId}
                id={id}
                object={initialObject}
              />
            ))}
            {errorMessage && (
              <p className="data-object-form-error">{errorMessage}</p>
            )}
          </Modal.Container>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button to={backURL} text>
            Cancel
          </Modal.Button>
          <Modal.Button type="submit" orange loading={saving}>
            Save
          </Modal.Button>
        </Modal.Actions>
      </form>
    )
  }
}

export default reduxForm({
  // Form name is dynamic
})(withRouter(DataObjectForm))
