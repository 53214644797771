import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'
import Session from 'utils/session'

import moment from 'moment'
import confirmation from 'components/Shared/TrialWarning/FreeTrialModal/confirmation.gif'
import { showModal, CUSTOM_ACTION_MODAL } from 'ducks/editor/modals'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { SEEN_END_TRIAL_SESSION_KEY } from 'constants.js'
import features, { FeatureValue } from './features'
import history from '../../../history'

const StepTwo = ({
  appId,
  orgId,
  selectedFeature,
  handleClose,
}: {
  appId: string
  orgId: string
  selectedFeature: FeatureValue
  handleClose: () => void
}): JSX.Element => {
  const trialEndDate = moment(new Date()).add(7, 'days').format('MMMM Do YYYY')
  const { buttonText, destination } = features[selectedFeature] || {}
  const url = `/apps/${appId + destination}`
  const dispatch = useDispatch()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    Session.set(`${orgId}-${SEEN_END_TRIAL_SESSION_KEY}`, false)
  }, [])

  const onClick = () => {
    history.push(url)

    if (selectedFeature === 'custom-actions') {
      dispatch(showModal(CUSTOM_ACTION_MODAL))
    }
  }

  return (
    <>
      <Modal.Content>
        <div className="success-trial-pack-container">
          <img
            src={confirmation}
            className="free-trial-extend-success-image"
            alt="ta-da"
          />
          <h1 className="free-trial-extend-success-title">
            Continue Enjoying All of the Premium Features for the Next 7 Days!
          </h1>
          <p className="free-trial-extend-success-text">
            Until {trialEndDate}, you have unlimited access to all these great
            features: External Collections, Custom Actions, Design Versions,
            Geolocation, Custom Fonts, & Collections API.
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button text onClick={handleClose} type="button">
          Done
        </Button>
        <Button
          onClick={onClick}
          type="button"
          teal
          gradient
          disabled={!selectedFeature}
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </>
  )
}

export default StepTwo
