import React, { Component, useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import classnames from 'classnames'

import { adaloBackendAxios, defaultAxios } from 'utils/io/http/axios'
import { getApiKey, createApiKey } from 'ducks/apikeys'
import { getMetrics } from 'ducks/metrics'
import { showModal, FREE_TRIAL_MODAL } from 'ducks/editor/modals'
import {
  showModal as showTrialModal,
  setPaymentRouteType,
} from 'ducks/trialWarning'
import Tooltip from 'components/Shared/Tooltip'

import { singularize } from 'utils/strings'
import { getIsOverLimit } from 'utils/metrics'

import { scroll } from 'ducks/editor/tables'

import Modal from 'components/Shared/Modal'
import Icon, { IconButton } from 'components/Shared/Icon'

import './Section.scss'
import { removeSpecialChars } from 'utils/regex'
import { getDatasource } from 'ducks/apps/datasources'
import { getTrialInformation, getUpgraded } from '../../../ducks/apps'

const sleep = delayMillis =>
  new Promise(resolve => {
    setTimeout(() => resolve(), delayMillis)
  })

const CsvExportLoadingState = React.createContext(false)

export const Header = ({
  name,
  appId,
  datasourceId,
  tableId,
  searchValue,
  onSearch,
  onCsvDownloadClick,
  onApiDocumentationClick,
  onPermissionsViewClick,
  metrics,
  datasource,
  paying,
}) => {
  const permissionsUrl = `/apps/${appId}/data/${datasourceId}/permissions`

  return (
    <Modal.Header
      title={name || 'Untitled'}
      color="orange"
      content={() => (
        <HeaderButtons
          name={name}
          appId={appId}
          datasourceId={datasourceId}
          tableId={tableId}
          searchValue={searchValue}
          onSearch={onSearch}
          onCsvDownloadClick={onCsvDownloadClick}
          onApiDocumentationClick={onApiDocumentationClick}
          displaySetPermissions={!!permissionsUrl}
          onPermissionsViewClick={() => onPermissionsViewClick(permissionsUrl)}
          metrics={metrics}
          datasource={datasource}
          paying={paying}
        />
      )}
    />
  )
}

const HeaderButtons = ({
  name,
  appId,
  datasourceId,
  tableId,
  searchValue,
  onSearch,
  onCsvDownloadClick,
  onApiDocumentationClick,
  displaySetPermissions,
  onPermissionsViewClick,
  metrics,
  datasource,
  paying,
}) => {
  const dispatch = useDispatch()
  const baseURL = `/apps/${appId}/data/${datasourceId}/${tableId}`
  const isOverLimit = getIsOverLimit(metrics, datasource)
  const disabled = isOverLimit && !paying

  const openModal = () => {
    dispatch(setPaymentRouteType('upgrade'))
    dispatch(showTrialModal())
  }

  return (
    <>
      <Modal.Button
        to={`${baseURL}/new`}
        outlined
        orange
        icon="plus"
        tooltip={disabled ? <UpgradeTooltip openModal={openModal} /> : null}
        disabled={disabled}
        placement="bottom"
      >
        Add {singularize(name)}
      </Modal.Button>
      {displaySetPermissions && (
        <IconButton
          type="security-badge"
          onClick={onPermissionsViewClick}
          tooltip="Set Permissions"
          orange
          placement="bottom"
        />
      )}
      {disabled ? (
        <IconButton
          tooltip={<UpgradeTooltip openModal={openModal} />}
          placement="bottom"
          orange
          disabled
          type="import-csv"
        />
      ) : (
        <IconButton
          tooltip="Import CSV"
          placement="bottom"
          to={`${baseURL}/csv-import`}
          orange
          type="import-csv"
        />
      )}
      <CsvExportLoadingState.Consumer>
        {csvExportLoading => (
          <IconButton
            tooltip="Download CSV"
            placement="bottom"
            loading={csvExportLoading}
            onClick={onCsvDownloadClick}
            orange
            type="export-csv"
          />
        )}
      </CsvExportLoadingState.Consumer>
      <IconButton
        tooltip="API Documentation"
        placement="bottom"
        onClick={onApiDocumentationClick}
        orange
        type="code"
      />
      <Search
        tooltip="Search"
        placement="bottom"
        handleSearch={onSearch}
        searchValue={searchValue}
      />
    </>
  )
}

export const Actions = ({
  showDelete,
  deleteCount,
  onDeleteClick,
  onDoneClick,
}) => {
  let deletePrompt = 'Delete Records'

  if (deleteCount > 0) {
    deletePrompt = `Delete ${deleteCount.toString()} ${
      deleteCount === 1 ? 'Record' : 'Records'
    }`
  }

  return (
    <Modal.Actions
      leftButtons={
        <>
          {showDelete && (
            <Modal.Button type="button" outlined orange onClick={onDeleteClick}>
              {deletePrompt}
            </Modal.Button>
          )}
        </>
      }
    >
      <Modal.Button type="button" orange onClick={onDoneClick}>
        Done
      </Modal.Button>
    </Modal.Actions>
  )
}

class Section extends Component {
  state = {
    scrolled: false,
    scrollY: 0,
    modalSize: 0,
    csvExportLoading: false,
  }

  handleWheel = e => {
    e.preventDefault()
    const dx = e.deltaX
    const dy = e.deltaY
    const el = this.scrollEl

    el.scrollBy(dx, dy)
  }

  handleScroll = () => {
    if (!this.scrollEl) return null

    const { scroll } = this.props
    const { scrolled, scrollY } = this.state
    const scrollLeft = this.scrollEl.scrollLeft

    const newScrollY = this.scrollEl.scrollTop
    const newScrolled = scrollLeft >= 20

    let newState = this.state

    if (newScrolled !== scrolled) {
      newState = { ...newState, scrolled: newScrolled }
    }

    if (newScrollY !== scrollY) {
      newState = { ...newState, scrollY: newScrollY }
    }

    if (newState !== this.state) {
      this.setState(newState)
    }

    scroll(scrollLeft)
  }

  resetScroll = () => {
    const { scroll } = this.props

    scroll(0)
  }

  componentDidMount() {
    this.resetScroll()
  }

  contentWrapperRef = el => (this.scrollEl = el)

  wrapperRef = el => {
    if (this.wrapperEl) {
      this.wrapperEl.removeEventListener('wheel', this.handleWheel)
    }

    this.wrapperEl = el

    if (el) {
      this.wrapperEl.addEventListener('wheel', this.handleWheel, {
        passive: false,
      })
    }
  }

  handleCSVDownload = async () => {
    const { match } = this.props
    const { params } = match
    const { appId, datasourceId, tableId } = params

    try {
      this.setState({ csvExportLoading: true })

      // Start the export
      const { data } = await adaloBackendAxios.post(
        `/${appId}/data/${datasourceId}/${tableId}/csv-exports`
      )
      const { id: exportId } = data.result

      let status
      let downloadUrl
      let delayMillis = 100
      let done = false
      // Poll export status until it is complete
      while (!done) {
        await sleep(delayMillis)
        // Exponential backoff (up to a maximum)
        delayMillis = Math.min(delayMillis * 1.5, 2000)

        const {
          data: { result },
        } = await adaloBackendAxios.get(
          `/${appId}/data/${datasourceId}/${tableId}/csv-exports/${exportId}`
        )
        console.info(`CSV Export result:`, result)

        status = result.status
        if (status === 'SUCCESS') {
          console.log(`CSV Export is done. URL: ${result.downloadUrl}`)
          downloadUrl = result.downloadUrl
          done = true
        } else if (status === 'ERROR') {
          console.log(`CSV Export error:`, result.error)
          throw new Error(result.error)
        }
        console.log(`CSV Export is still processing. Status:`, status)
      }

      /* Browsers will block downloads from non-origin domains (eg, S3) by
       * default. This means we need to download the file in JS, then present
       * the file content as a `data:` url.
       *
       * Note: the exact behaviour varies but blocks are most likely when
       * there's a long delay between the user clicking a "Download" button and
       * the start of the actual download.
       */
      const downloadResponse = await defaultAxios.get(downloadUrl)
      const csvFileData = downloadResponse.data
      const contentType = downloadResponse.headers['Content-Type']

      let filename = 'download.csv'
      try {
        const { pathname } = new URL(downloadUrl)
        filename = pathname.substring(pathname.lastIndexOf('/') + 1)
      } catch (err) {
        console.error(`Error parsing filename from download URL:`, err)
      }

      // Create a hidden download link and click it.
      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:${contentType};charset=utf-8, ${encodeURIComponent(
        csvFileData
      )}`
      hiddenElement.download = filename
      hiddenElement.target = '_blank'
      hiddenElement.click()
    } catch (err) {
      console.error(`CSV Export Failed:`, err)
      alert('Download Failed.')
    } finally {
      this.setState({ csvExportLoading: false })
    }
  }

  handleAPIDocumentation = async () => {
    const {
      name,
      match,
      appApiKey,
      paying,
      createApiKey,
      showModal,
      history,
      isAfterTrial,
    } = this.props

    const { appId } = match.params

    const sanitizedName = removeSpecialChars(name, true)

    const url = `/apps/${appId}/api-docs/#tag/${sanitizedName}`

    if (paying) {
      if (!appApiKey) {
        await createApiKey(appId)
      }

      window.open(url)
    } else {
      try {
        if (isAfterTrial) {
          return history.push(`/apps/${appId}/free-trial-end`)
        } else {
          await showModal(FREE_TRIAL_MODAL, { type: 'api', appId })
          this.handleAPIDocumentation()
        }
      } catch (err) {
        // modal closed
        console.error("modal closed, here's the error:", err)
      }
    }
  }

  handlePermissionsView = permissionUrl => {
    const { match, history } = this.props
    const { params } = match
    const { appId, datasourceId, tableId } = params

    return history.push(permissionUrl, {
      sourceTableId: tableId,
      returnUrl: `/apps/${appId}/data/${datasourceId}/${tableId}`,
    })
  }

  renderHeader = () => {
    const { handleSearch, searchValue, name, match } = this.props
    const { appId, datasourceId, tableId } = match.params

    return (
      <HeaderButtons
        searchValue={searchValue}
        name={name}
        appId={appId}
        datasourceId={datasourceId}
        tableId={tableId}
        onSearch={handleSearch}
        onCsvDownloadClick={this.handleCSVDownload}
        onApiDocumentationClick={this.handleAPIDocumentation}
      />
    )
  }

  getContentRect = () => {
    return this.scrollEl?.getBoundingClientRect?.()
  }

  render() {
    const {
      name,
      match,
      renderContent,
      handleCloseModal,
      blockedList,
      idList,
      handleSearch,
      searchValue,
      handleBlockedListToggle,
      handleSelectToggle,
      handleBulkDelete,
      blockedListLength,
      datasource,
      metrics,
      paying,
    } = this.props

    const { appId, datasourceId, tableId } = match.params
    const baseURL = `/apps/${appId}/data/${datasourceId}/${tableId}`

    const { scrollY, csvExportLoading } = this.state
    const deleteCount = blockedList ? blockedListLength : idList.length
    const showDelete = deleteCount > 0

    const isUsersTable = datasource.auth?.table === tableId

    return (
      <CsvExportLoadingState.Provider value={csvExportLoading}>
        <div onScroll={this.handleScroll} ref={this.wrapperRef}>
          <Header
            searchValue={searchValue}
            name={name}
            appId={appId}
            datasourceId={datasourceId}
            tableId={tableId}
            isUsersTable={isUsersTable}
            onSearch={handleSearch}
            onCsvDownloadClick={this.handleCSVDownload}
            onApiDocumentationClick={this.handleAPIDocumentation}
            onPermissionsViewClick={this.handlePermissionsView}
            metrics={metrics}
            datasource={datasource}
            paying={paying}
          />
          <Modal.Content childRef={this.contentWrapperRef} fluid>
            {renderContent(
              scrollY,
              this.getContentRect,
              blockedList,
              idList,
              handleBlockedListToggle,
              handleSelectToggle
            )}
          </Modal.Content>
          <Actions
            permissionsUrl={`${baseURL}/permissions`}
            isUsersTable={isUsersTable}
            showDelete={showDelete}
            deleteCount={deleteCount}
            onDeleteClick={() => handleBulkDelete(deleteCount)}
            onDoneClick={handleCloseModal}
          />
        </div>
      </CsvExportLoadingState.Provider>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId, datasourceId } = match.params
  const { trialState } = getTrialInformation(state, appId)

  const paying = getUpgraded(state, appId)
  const isAfterTrial = !paying && trialState === 'after'

  return {
    appId,
    appApiKey: getApiKey(state, appId),
    datasource: getDatasource(state, appId, datasourceId),
    metrics: getMetrics(state, appId),
    paying: paying || trialState === 'during',
    isAfterTrial,
  }
}

export default connect(mapStateToProps, {
  scroll,
  showModal,
  setPaymentRouteType,
  createApiKey,
})(Section)

const Search = props => {
  const [state, setState] = useState({ isOpen: false })
  const { isOpen } = state
  const { handleSearch, searchValue, tooltip, placement } = props
  const inputRef = React.createRef()

  const toggle = () => setState({ ...state, isOpen: !isOpen })

  const handleBlur = () => {
    if (searchValue.length === 0) return toggle()

    return null
  }

  useEffect(() => {
    if (isOpen) inputRef.current.focus()
  }, [isOpen])

  const renderContent = () => {
    return (
      <div className={classnames('data-modal-search', { expanded: isOpen })}>
        <IconButton orange type="search" onClick={toggle} />
        <input
          name="search"
          onChange={handleSearch}
          value={searchValue}
          placeholder="Search..."
          onBlur={handleBlur}
          ref={inputRef}
          autoComplete="off"
        />
      </div>
    )
  }

  return tooltip ? (
    <Tooltip placement={placement || 'top'} tooltip={tooltip}>
      {renderContent()}
    </Tooltip>
  ) : (
    renderContent()
  )
}

const UpgradeTooltip = ({ openModal }) => {
  return (
    <>
      <span>You reached the maximum record count.</span>
      <div className="tooltip-link-container" onClick={openModal}>
        <Icon type="open-in-new" small color="yellow" />
        <a onClick={openModal}>Upgrade your plan</a>
      </div>
    </>
  )
}
