import YogaLoader from 'yoga-dom'

export const Yoga = {}

const promise = new Promise(resolve => {
  YogaLoader.then(_yoga => {
    Yoga.Node = _yoga.Node
    Yoga.Config = _yoga.Config
    Yoga.Constants = _yoga.Constants

    resolve(Yoga)
  })
})

export const getYoga = () => promise
