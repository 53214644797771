import React, { Component } from 'react'
import { NavLink, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  getCurrentOrganization,
  getCurrentOrgInitialDetails,
} from 'ducks/organizations'
import { getCurrentUser } from 'ducks/users/index.ts'

import history from '../../history'
import Page from '../Shared/Page'
import Modal from '../Shared/Modal'
import { IconButton } from '../Shared/Icon'
import Profile from '../Shared/Profile'

import Account from './Account'
import Billing from './Billing'
import Domain from './Domains'
import Team from './Team'
import Developer from './Developers'
import Apps from './Apps'

import './Settings.scss'

class Settings extends Component {
  closeModal = () => {
    const { match } = this.props

    history.push(`/apps/${match.params.appId}/screens`)
  }

  getBaseURL = () => {
    const { match } = this.props

    if (match && match.params && match.params.appId) {
      return `/apps/${match.params.appId}/screens/settings`
    }

    return '/settings'
  }

  renderContent() {
    const { children } = this.props
    const baseURL = this.getBaseURL()

    return (
      <Page form className="accounts-page" title="Settings">
        <div className="developers-page-close-button">
          <IconButton type="close-big" onClick={this.closeModal} />
        </div>
        <div className="developers-page-tabs">
          <NavLink
            className="developers-page-tab"
            activeClassName="active"
            to={`${baseURL}/account`}
          >
            Profile
          </NavLink>
          <>
            <NavLink
              className="developers-page-tab"
              activeClassName="active"
              to={`${baseURL}/team`}
            >
              Team
            </NavLink>
            <NavLink
              className="developers-page-tab"
              activeClassName="active"
              to={`${baseURL}/billing`}
            >
              Billing
            </NavLink>
          </>
          <NavLink
            className="developers-page-tab"
            activeClassName="active"
            to={`${baseURL}/domains`}
          >
            Domains
          </NavLink>
        </div>
        <div className="developers-page-content">{children}</div>
      </Page>
    )
  }

  renderSideBar() {
    const baseURL = this.getBaseURL()

    const settingsLinks = [
      { id: 'account', label: 'Account', path: '/account' },
      { id: 'apps', label: 'Apps', path: '/apps' },
      { id: 'team', label: 'Team Members', path: '/team' },
      { id: 'billing', label: 'Usage & Billing', path: '/billing' },
      { id: 'domains', label: 'Domains', path: '/domains' },
    ]

    return (
      <div className="settings-page-sidebar">
        {settingsLinks.map(({ id, label, path }) => (
          <NavLink
            key={id}
            className="settings-page-tab"
            activeClassName="active"
            to={baseURL + path}
            data-adalo-id={`settings-tab-${id}`}
          >
            {label}
          </NavLink>
        ))}
      </div>
    )
  }

  renderRedesignedContent() {
    const { children, currentOrganization, currentOrgInitialDetails, user } =
      this.props

    const { name } = currentOrganization || {}
    const { orgInitials, orgColor } = currentOrgInitialDetails || {}
    const { email } = user || {}

    return (
      <Page form className="accounts-page" title="Settings">
        <div className="settings-page-header">
          <div className="settings-page-close-button">
            <IconButton type="close-big" onClick={this.closeModal} />
          </div>
          <Profile big color={orgColor} profileText={orgInitials} />
          <div className="settings-page-header-title">
            <h1>{name}</h1>
            <p>{email}</p>
          </div>
        </div>
        <Modal.Content classNames="marketplace">
          <div className="marketplace-container">
            {this.renderSideBar()}
            <div className="settings-vertical-line" />
            <div className="settings-page-content">{children}</div>
          </div>
        </Modal.Content>
      </Page>
    )
  }

  render() {
    const { modal } = this.props

    if (modal) {
      return (
        <Modal
          size="lg"
          fixedHeight
          onClose={this.closeModal}
          className="settings-modal"
        >
          {this.renderRedesignedContent()}
        </Modal>
      )
    }

    return this.renderContent()
  }
}

const mapStateToProps = state => ({
  currentOrganization: getCurrentOrganization(state),
  currentOrgInitialDetails: getCurrentOrgInitialDetails(state),
  user: getCurrentUser(state),
})

export default connect(mapStateToProps)(Settings)

export { Account }
export { Apps }
export { Team }
export { Billing }
export { Domain }
export { Developer }

export const SettingsRoutes = () => {
  return (
    <Settings>
      <Switch>
        <Route exact path="/settings/account" component={Account} />
        <Route exact path="/settings/apps" component={Apps} />
        <Route exact path="/settings/team" component={Team} />
        <Route exact path="/settings/billing" component={Billing} />
        <Route exact path="/settings/domains" component={Domain} />
      </Switch>
    </Settings>
  )
}
