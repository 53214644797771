import React, { Component } from 'react'
import { connect } from 'react-redux'
import { imageResize } from '@adalo/constants'

import { getCurrentAppId, selectObjects } from 'ducks/editor/objects'
import { getSelection } from 'ducks/editor/selection'

import ImageInput from 'components/Shared/Forms/ImageInput'
import BindableTextControl from 'components/Shared/BindableTextControl'

import MenuControl from './MenuControl'

import './FileAndImageControl.css'

const videoResizeOptions = [
  { label: 'Crop Video to Fill Space', value: imageResize.COVER },
  { label: 'Show Full Video (Don’t Crop)', value: imageResize.CONTAIN },
]

const videoPlaceholderOptions = [
  { label: "Don't show anything", value: false },
  { label: 'Show a placeholder image', value: true },
]

const DEFAULT_KEY = 'videoBinding'

class VideoControl extends Component {
  getVideoBinding = () => {
    const { object } = this.props

    return object?.[DEFAULT_KEY]
  }

  getImageOptions = () => {
    const object = this.getVideoBinding()

    return object?.options
  }

  getPlaceholderStatus = () => {
    const options = this.getImageOptions()

    return Boolean(options?.placeholderImageEnabled)
  }

  getImageFileName = () => {
    const options = this.getImageOptions()

    if (options?.placeholderImage) {
      return options.placeholderImage
    }

    return null
  }

  handleChange = newValues => {
    const { onChange } = this.props

    if ('imageResize' in newValues) {
      onChange(newValues)

      return
    }

    onChange({ [DEFAULT_KEY]: newValues })
  }

  renderPlaceholderController = () => {
    const target = this.getVideoBinding()

    return (
      <MenuControl
        displayName="Placeholder Image"
        name="placeholderImageEnabled"
        value={this.getPlaceholderStatus()}
        onChange={value =>
          this.handleChange({
            ...target,
            options: { ...target?.options, ...value },
          })
        }
        options={videoPlaceholderOptions}
      />
    )
  }

  renderImageInput = () => {
    const { appId } = this.props

    return (
      <ImageInput
        isAsset
        displayName={''}
        buttons={['view', 'remove']}
        appId={appId}
        input={{
          value: this.getImageFileName(),
          name: '',
          onChange: url => {
            const target = this.getVideoBinding()

            this.handleChange({
              ...target,
              options: { ...target?.options, placeholderImage: url },
            })
          },
        }}
      />
    )
  }

  renderVideoURLInput = () => {
    const { object } = this.props
    const onUrlChange = ({ url: videoURL }) => {
      const { onChange } = this.props

      onChange({ videoURL })
    }

    return (
      <BindableTextControl
        displayName="Video URL"
        name="url"
        onChange={onUrlChange}
        value={object?.videoURL || ''}
        objectId={object?.id}
        placeholder="https://example.com/video.mp4"
      />
    )
  }

  renderImageCropping = () => {
    const { object } = this.props

    const value = object?.imageResize || ''

    return (
      <MenuControl
        autoSelect
        displayName="Video Cropping"
        name="imageResize"
        value={value}
        onChange={this.handleChange}
        options={videoResizeOptions}
      />
    )
  }

  render() {
    const { thumbnailControl } = this.props

    return (
      <div className="library-video-control">
        {this.renderVideoURLInput()}
        <p className="hint-text">
          Learn how your app users can upload their own videos.{' '}
          <a
            href="https://help.adalo.com/component-basics/video"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
          </a>
        </p>
        {thumbnailControl}
        {this.renderPlaceholderController()}
        {this.getPlaceholderStatus() && this.renderImageInput()}
        {this.renderImageCropping()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const appId = getCurrentAppId(state)
  const selection = getSelection(state)
  const objects = selectObjects(state, selection)

  return {
    appId,
    object: objects.length === 1 ? objects[0] : null,
  }
}

export default connect(mapStateToProps)(VideoControl)
