import React, { useState, useMemo } from 'react'
import { reduxForm } from 'redux-form'
import { connect, useSelector } from 'react-redux'
import { getCurrentOrganizationId } from 'ducks/organizations'
import { startTrial } from 'ducks/apps/trial'
import useSetSeenTrialEndModal from 'hooks/useSetSeenTrialEndModal'
import moment from 'moment'
import Modal from '../../Modal'

import './index.scss'
import confirmation from './confirmation.gif'
import Card from './Card'

import { FREE_TRIAL_CARDS, TYPES } from './constants'

const FORM_NAME = 'trialTrialModal'
const MODAL_TITLE = 'Unlock Premium Features for 14 Days with a Free Trial'

const getConfirmationModalTitle = type => {
  const title = TYPES[type]?.title || 'New Features'

  return `Enjoy ${title} for the next 14 days!`
}

const SecondaryButtonText = ({ step }) => {
  if (step === 1) {
    return <>Not Right Now</>
  }

  return <>Done</>
}

const PrimaryButtonText = ({ step, type }) => {
  let buttonText = 'Start Free Trial'

  if (step !== 1) {
    buttonText = TYPES[type]?.buttonText
  }

  return <>{buttonText}</>
}

const Content = ({ step, type }) => {
  if (step === 1) {
    return <StepOne type={type} />
  } else {
    return <StepTwo type={type} />
  }
}

const getColor = type => {
  const key = TYPES[type]?.color

  return {
    [key]: true,
  }
}

const StepOne = ({ type }) => {
  const cardList = useMemo(() => {
    const index = TYPES[type]?.index
    if (index === undefined) return []

    const firstPart = FREE_TRIAL_CARDS.slice(0, index)
    const secondPart = FREE_TRIAL_CARDS.slice(index)

    return secondPart.concat(firstPart)
  }, [type])

  return (
    <>
      <h2 className="trial-trial-header">
        Begin your 14-Day Free trial today &amp; experience premium features
        including Custom Fonts, External Collections, Custom Actions, Versions,
        Geolocation &amp; more!
      </h2>
      <div className="trial-trial-container grid columns-3 gap-4">
        {cardList.map(({ key, ...props }) => (
          <Card key={key} {...props} />
        ))}
      </div>
    </>
  )
}

const StepTwo = ({ type }) => {
  const title = getConfirmationModalTitle(type)
  const trialEndDate = moment(new Date()).add(14, 'days').format('MMMM Do YYYY')

  return (
    <div className="success-trial-pack-container">
      <img
        src={confirmation}
        className="free-trial-confirmation-end"
        alt="ta-da"
      />
      <h1>{title}</h1>
      <p>
        Don't forget, until {trialEndDate}, you have unlimited access to all
        these great features: External Collections, Custom Actions, Design
        Versions, Geolocation, Custom Fonts, & Collections API.
      </p>
    </div>
  )
}

const FreeTrialModal = ({
  startTrial,
  type,
  appId,
  onCancel: closeModal,
  onSuccess,
}) => {
  const title = MODAL_TITLE
  const [step, setStep] = useState(1)

  const currentOrgId = useSelector(state => getCurrentOrganizationId(state))

  const color = getColor(type)
  useSetSeenTrialEndModal(false)

  const handleClick = () => {
    setStep(2)

    if (currentOrgId) {
      startTrial(currentOrgId, appId)
    }
  }

  return (
    <form className="trial-trial-modal" onSubmit={onSuccess}>
      {step === 1 && <Modal.Header style={{ padding: '50px' }} title={title} />}
      <Modal.Content style={{ padding: '20px 50px 40px 50px' }}>
        <Content step={step} type={type} />
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button type="button" text onClick={closeModal}>
          <SecondaryButtonText step={step} />
        </Modal.Button>
        {step === 1 && (
          <Modal.Button {...color} type="button" onClick={handleClick}>
            <PrimaryButtonText step={step} type={type} />
          </Modal.Button>
        )}
        {step === 2 && (
          <Modal.Button {...color} type="submit">
            <PrimaryButtonText step={step} type={type} />
          </Modal.Button>
        )}
      </Modal.Actions>
    </form>
  )
}

const connected = connect(null, {
  startTrial,
})(FreeTrialModal)

export default reduxForm({ form: FORM_NAME })(connected)
