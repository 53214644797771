import { getTableId } from '@adalo/utils'

import { adaloBackendAxios } from 'utils/io/http/axios'

import { Datasource, DatasourceTable, UserField, TableFields } from './user'

export type APISpec = {
  [table: string]: {
    label: string
    path: string
    type: string
  }[]
}

export const fetchCollectionSpec = async (url: string): Promise<APISpec> => {
  const requestURL = `/external-databases/xano/external-collections`

  try {
    const { data } = await adaloBackendAxios.get<{ spec: APISpec }>(
      requestURL,
      {
        params: { url },
      }
    )

    const { spec } = data

    return spec
  } catch (err) {
    console.error(err)

    return {}
  }
}

type UpdatedDatasourceResponse = {
  datasourceId: string
  tableId: string
  updates: Partial<DatasourceTable>
}

type EndpointField = {
  url: string
  method: string
  enabled: boolean
}

export const updateCollectionDatasource = (
  datasource: Datasource,
  name: string,
  routes: { type: string; path: string; label: string; isValid: boolean }[],
  fields: UserField,
  url: string
): UpdatedDatasourceResponse => {
  const tableId = getTableId()

  const table = {
    baseURL: `${url}/${name}`,
    orderedFields: [] as string[],
    endpoints: {} as { [key: string]: EndpointField },
    fields: {} as TableFields,
    name,
    tableId,
    type: 'xano',
    id: tableId,
  }

  for (const route of routes) {
    const [, id] = route.path.substring(1).split('/')

    let endpoint = ''

    switch (route.type) {
      case 'get':
        endpoint = id ? 'detail' : 'list'

        break
      case 'patch':
      case 'put':
        endpoint = 'update'

        break
      case 'post':
        endpoint = id ? 'update' : 'create'

        break
      case 'delete':
        endpoint = 'delete'

        break
      default:
        break
    }

    if (route.path.includes('{')) {
      route.path = route.path.replace('{', '{{').replace('}', '}}')
      route.path = route.path.replace(new RegExp(/{{\w+}}/, 'g'), '{{id}}')
    }

    if (endpoint) {
      table.endpoints[endpoint] = {
        enabled: true,
        method: route.type,
        url: url + route.path,
      }
    }
  }

  for (const [key, value] of Object.entries(fields)) {
    table.orderedFields.push(key)

    table.fields[key] = {
      name: value.name,
      type: value.type,
      isPrimaryField: key === 'id',
      locked: key === 'id',
    }
  }

  return {
    datasourceId: datasource.id,
    tableId,
    updates: table,
  }
}
