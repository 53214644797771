import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import Icon, { IconButton } from 'components/Shared/Icon'
import { runInstructions } from 'ducks/editor/objects'
import { toggleDeviceVisibility } from 'ducks/editor/instructions'
import { isSectionElement } from 'utils/layoutSections'

export const EMPTY_ARRAY = []

const stopPropagation = e => e.stopPropagation()

const DEVICES = [
  { icon: <Icon type="desktop" />, device: 'desktop', label: 'Desktop' },
  { icon: <Icon type="tablet" />, device: 'tablet', label: 'Tablet' },
  { icon: <Icon type="mobile" />, device: 'mobile', label: 'Mobile' },
]

const DEFAULT_DEVICE_VISIBILITY = {
  mobile: true,
  tablet: true,
  desktop: true,
}

function VisibilitySection({ object, runInstructions }) {
  if (isSectionElement(object)) {
    return null
  }

  const { deviceVisibility = DEFAULT_DEVICE_VISIBILITY } = object

  const handleChanged = useCallback(
    device => {
      const instruction = toggleDeviceVisibility(object.id, device)

      runInstructions([instruction])
    },
    [object.id]
  )

  const title = 'Visible on...'
  const devices = DEVICES

  return (
    <div className="device-visibility">
      <p className="device-visibility-label">{title}</p>
      <div className="device-visibility-options">
        {devices.map(({ icon, device, label }) => (
          <DeviceOption
            icon={icon}
            key={device}
            device={device}
            label={label}
            handleChange={handleChanged}
            value={deviceVisibility[device]}
          />
        ))}
      </div>
    </div>
  )
}

const DeviceOption = ({ icon, device, label, handleChange, value }) => {
  const [hover, setHover] = useState(false)
  const visibilityIcon = !value === !hover ? 'eye-closed' : 'eye'

  return (
    <div
      className={classNames('device-element', { hidden: !value })}
      onClick={() => handleChange(device, !value)}
      onMouseDown={stopPropagation}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="device-label">
        <span className="device-label-title">{label}</span>
      </div>
      <IconButton type={visibilityIcon} />
    </div>
  )
}

export default connect(null, { runInstructions })(VisibilitySection)
