import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { requestDomains, getDomains } from 'ducks/domains'
import { getApp } from 'ducks/apps'

import { forceSaveComponents } from 'utils/io'
import { isSupportedOnWeb } from 'utils/platform'

import { PREVIEW_URL } from 'constants.js'

import './ShareButton.scss'

class ShareButton extends Component {
  componentDidUpdate = prevProps => {
    const { app, requestDomains } = this.props

    if (app) {
      if (prevProps.app) {
        if (app.id !== prevProps.app.id) {
          requestDomains(app.OrganizationId)
        }
      } else {
        requestDomains(app.OrganizationId)
      }
    }
  }

  getOrgBaseURL = () => {
    const { app } = this.props
    const baseURL = process.env.REACT_APP_WEB_BASE_URL

    const url = `${app?.Organization?.subdomain}.${baseURL}/`

    return url
  }

  getAppHost = (useAppDomain = false) => {
    let { app, domainId, domains } = this.props

    if (!domains || !app) return null

    domainId = useAppDomain ? app?.DomainId : domainId

    const domain = domains.filter(d => d.id === domainId)[0]

    if (!domain) return this.getOrgBaseURL()

    return `${domain.domain}/`
  }

  getPublishURL = () => {
    const { app } = this.props
    let url = this.getAppHost(true)
    const protocol = window.location.protocol

    url = `${protocol}//${url}${app?.path || ''}`

    return url
  }

  getPreviewURL = () => {
    const { app, domainId } = this.props

    if (!app) return null

    if (domainId || app.DomainId) return this.getPublishURL()

    return `${PREVIEW_URL}/${app.id}`
  }

  render() {
    const { app } = this.props

    const publishURL = this.getPublishURL()
    const previewURL = this.getPreviewURL()

    const url = isSupportedOnWeb(app?.primaryPlatform) ? publishURL : previewURL

    return (
      <div className="editor-share-button-wrapper">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="editor-share-button"
          disabled={!app}
          onClick={forceSaveComponents}
        >
          Share
        </a>
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params

  const app = getApp(state, appId)
  let domains = []

  if (app?.Organization?.id) {
    domains = getDomains(state, app.Organization.id)
  }

  return { app, domains }
}

export default withRouter(
  connect(mapStateToProps, { requestDomains, getDomains })(ShareButton)
)
