import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'
import { DeviceWidth } from '@adalo/constants'
import { useFlags } from 'flags'
import { resizeObject } from 'ducks/editor/objects'
import { recalculateSnapGrid, getSelection } from 'ducks/editor/selection'
import useOpenDeviceLayoutSettings from 'hooks/editor/useOpenDeviceLayoutSettings'
import useClearOtherScreensSelection from 'hooks/editor/useClearOtherScreensSelection'
import Icon from 'components/Shared/Icon'

export const DEVICE_ICONS_MEDIUM = {
  mobile: <Icon type="mobile-small" />,
  tablet: <Icon type="tablet-small" />,
  desktop: <Icon type="desktop-medium" />,
}

export const DEVICE_ICONS_LARGE = {
  mobile: <Icon type="mobile-small" />,
  tablet: <Icon type="tablet-small" />,
  desktop: <Icon type="desktop-large" />,
}

const SCREEN_SIZES = {
  mobile: DeviceWidth.MOBILE_DEFAULT_WIDTH,
  tablet: DeviceWidth.TABLET_DEFAULT_WIDTH,
  desktop: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
}

const devices = ['desktop', 'tablet', 'mobile']

const useOpenSelectionDeviceLayoutSettings = () => {
  const selection = useSelector(state => getSelection(state))
  const objectId = selection[0]
  const { hasAutoCustomLayout } = useFlags()

  const openLayoutSettingsForDevice = useOpenDeviceLayoutSettings(objectId)

  const openSelectionDeviceLayoutSettings = device => {
    if (hasAutoCustomLayout && selection.length === 1) {
      openLayoutSettingsForDevice(device)
    }
  }

  return openSelectionDeviceLayoutSettings
}

function SizeDropdown(props) {
  const {
    expanded,
    setExpanded,
    deviceType,
    component,
    recalculateSnapGrid,
    resizeObject,
    smallHeader,
  } = props

  const openSelectionDeviceLayoutSettings =
    useOpenSelectionDeviceLayoutSettings()
  const clearSelection = useClearOtherScreensSelection(component?.id)

  useEffect(() => {
    if (expanded) {
      clearSelection()
    }
  }, [expanded, clearSelection])

  if (!expanded || !component) {
    return null
  }
  const { id } = component
  const closeDropdown = () => setExpanded(false)

  const deviceIcons = smallHeader ? DEVICE_ICONS_MEDIUM : DEVICE_ICONS_LARGE

  const children = devices.map(device => {
    const deviceName = device.charAt(0).toUpperCase() + device.slice(1)
    const active = device === deviceType
    const activeIcon = active ? <Icon type="check-small" /> : null
    const onClick = () => {
      if (active) {
        return null
      }

      const width = SCREEN_SIZES[device]

      resizeObject(id, { width })
      recalculateSnapGrid()

      // If an object is selected the layout pane open, we want to open the layout settings
      // for the device that we're setting it's screen size to.
      openSelectionDeviceLayoutSettings(device)
    }

    return (
      <div
        className={classNames('screen-size-option', {
          active,
        })}
        onMouseDown={onClick}
        key={deviceName}
      >
        <div className="device-information">
          {deviceIcons[device]}
          <div className="device-name">
            <span>{deviceName} View</span>
          </div>
        </div>
        {activeIcon}
      </div>
    )
  })

  return (
    <div className="navbar-dropdown-children">
      {children}
      <DocumentEvents onMouseDown={closeDropdown} />
    </div>
  )
}

export default connect(null, { resizeObject, recalculateSnapGrid })(
  SizeDropdown
)
