import React from 'react'
import PropTypes from 'prop-types'
import { useThrottledValue } from 'utils/debounce.ts'

const LibraryComponentWrapper = props => {
  const {
    offsetX,
    offsetY,
    boundingWidth,
    boundingHeight,
    zoom,
    styleHeight,
    children,
    reRenderOnMove,
  } = props

  const posX = Math.floor(offsetX)
  const posY = Math.floor(offsetY)
  let transformX = offsetX % 1
  const transformY = offsetY % 1
  const style = { padding: 20, height: styleHeight }

  if (typeof boundingWidth === 'string') {
    style.paddingRight = 0
    style.paddingLeft = 0
    transformX += 10
  }

  // For the components that require re-rendering on move, we force the re-rendering by changing the key
  // We do not want to re-render on every tiny move, otherwise it would look laggy, so we use a throttled value
  // via: https://github.com/AdaloHQ/frontend/pull/2251
  const key = useThrottledValue(
    reRenderOnMove ? `${posX}-${posY}` : undefined,
    5
  )

  return (
    <foreignObject
      x={posX}
      y={posY}
      width={boundingWidth}
      height={boundingHeight}
      pointerEvents="none"
      transform={`translate(${transformX},${transformY})`}
      key={key}
    >
      <div
        className="library-component-inner-wrapper"
        style={{
          transform: `scale(${zoom.scale})`,
          transformOrigin: 'left top',
          width: `${(1 / zoom.scale) * 100}%`,
          zIndex: -1,
        }}
      >
        <div style={style}>{children}</div>
      </div>
    </foreignObject>
  )
}

LibraryComponentWrapper.propTypes = {
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  boundingWidth: PropTypes.number.isRequired,
  boundingHeight: PropTypes.number.isRequired,
  zoom: PropTypes.object.isRequired,
  styleHeight: PropTypes.number,
}

export default React.memo(LibraryComponentWrapper)
