import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindingTypes } from '@adalo/constants'

import { getBindingSuggestions } from 'ducks/recommender'
import { getLabel } from 'utils/sources'
import { getComponentId, getCurrentAppId } from 'ducks/editor/objects'

import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'

import MenuControl from '../../Inspect/Libraries/MenuControl'
import Binding from './Binding'

import { THEMES } from '../../../../constants'

function VisibilityForm({
  appId,
  componentId,
  object,
  options,
  binding,
  label,
  onChange,
  displayName,
  hideAdvanced,
  formName,
  tooltip,
}) {
  const handleChange = useCallback(
    ({ binding }) => {
      /**
       * @type {import('@adalo/constants').Binding}
       */
      const updatedBinding = {
        ...binding,
        options: {
          ...binding.options,
          affectLayout: true,
        },
      }

      onChange(updatedBinding)
    },
    [binding, onChange]
  )

  if (!binding) {
    return (
      <MenuControl
        name="binding"
        displayName={displayName || 'Will be visible if...'}
        options={options}
        onChange={handleChange}
        menuTheme={THEMES.DATA}
        tooltip={tooltip}
      />
    )
  }

  const name = formName || 'visibility'

  return (
    <Binding
      form={`${name}-${object.id}`}
      appId={appId}
      componentId={componentId}
      objectId={object.id}
      binding={binding}
      bindingOptions={options}
      onChange={onChange}
      label={label}
      displayName={displayName}
      hideAdvanced={hideAdvanced}
      tooltip={tooltip}
    />
  )
}

const mapStateToProps = (state, { binding, object, helpers }) => {
  const appId = getCurrentAppId(state)
  const componentId = getComponentId(state, object.id)

  return {
    appId,
    componentId,
    label: binding ? getLabel(state, binding, appId, componentId) : null,
    options: getBindingSuggestions(
      state,
      appId,
      componentId,
      object.id,
      !!binding,
      bindingTypes.VISIBILITY,
      undefined,
      undefined,
      helpers
    ),
  }
}

export default withPremiumFeatureHandler(
  connect(mapStateToProps)(VisibilityForm)
)
