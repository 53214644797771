import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import { IconButton } from 'components/Shared/Icon'

import CommonInspectRow from '../GenericRow'
import Slider from '../Slider'
import TextControl from '../TextControl'

export function getMaxBorderRadius(object) {
  const { width = 100, height = 100 } = object

  return Math.floor(Math.min(width, height) / 2)
}

const SliderControl = ({
  defaultValue,
  name,
  onChange,
  title,
  percentage,
  sliderBackground,
  showAdvanced,
  value,
  min,
  max,
  renderAdvanced,
  renderDisabled,
  menuOptions,
  disabled,
}) => {
  const [advancedOpen, setAdvancedOpen] = useState(false)

  const handleChange = obj => {
    if (percentage) {
      obj = { [name]: obj[name] / 100 }
    }

    onChange(obj)
  }

  const closeAdvanced = () => {
    if (advancedOpen) {
      setAdvancedOpen(false)
    }
  }

  const openAdvanced = () => {
    setAdvancedOpen(true)
  }

  if (percentage) {
    value = Math.round(100 * value)
    min = 0
    max = 100
  }

  const renderSlider = () => {
    if (sliderBackground) {
      return (
        <div className="radius-control__background">
          <Slider
            value={value}
            name={name}
            onChange={handleChange}
            defaultValue={defaultValue}
            min={min}
            max={max}
            disabled={disabled}
          />
        </div>
      )
    }

    return (
      <Slider
        value={value}
        name={name}
        onChange={handleChange}
        defaultValue={defaultValue}
        min={min}
        max={max}
      />
    )
  }

  const advancedMenu = useMemo(() => {
    if (typeof menuOptions === 'function') {
      return menuOptions({ openAdvanced, closeAdvanced, advancedOpen })
    }

    return menuOptions
  }, [menuOptions, openAdvanced, closeAdvanced, advancedOpen])

  return (
    <>
      <CommonInspectRow
        className={classNames('radius-control', {
          independent__slider: showAdvanced,
        })}
        title={title}
      >
        {renderSlider()}
        {disabled ? (
          renderDisabled({ openAdvanced, closeAdvanced, advancedOpen })
        ) : (
          <TextControl
            gray
            type="number"
            name={name}
            value={value}
            onChange={handleChange}
            variant={Boolean(renderAdvanced ? 'advanced' : null)}
            minValue={min}
          />
        )}
        {showAdvanced ? (
          <MultiMenuTrigger
            className={classNames('radius-control-advanced-menu', {
              disabled: advancedOpen,
            })}
            width={220}
            rowHeight={32}
            menu={advancedMenu}
          >
            <IconButton type="more-vert" />
          </MultiMenuTrigger>
        ) : null}
      </CommonInspectRow>
      {advancedOpen &&
        renderAdvanced({ openAdvanced, closeAdvanced, advancedOpen })}
    </>
  )
}

SliderControl.propTypes = {
  defaultValue: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.number,
  percentage: PropTypes.bool,
  sliderBackground: PropTypes.bool,
  showAdvanced: PropTypes.bool,
  renderAdvanced: PropTypes.func,
  renderDisabled: PropTypes.func,
  menuOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  disabled: PropTypes.bool,
}

SliderControl.defaultProps = {
  min: 0,
  defaultValue: 0,
  title: 'Rounding',
  showAdvanced: false,
  disabled: false,
}

export default SliderControl
