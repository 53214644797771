import React, { Component } from 'react'

import { verifyIOSCredentials } from 'utils/io'
import Button from 'components/Shared/Button'
import Loading from 'components/Shared/Loading'
import { PublishSettings } from '../PublishSettingsModal'

export default class VerifyCredentialsModal extends Component {
  state = { error: null }

  triggerVerify = async () => {
    const { appId, onContinue } = this.props

    try {
      await verifyIOSCredentials(appId)
      onContinue()
    } catch (err) {
      this.setState({ error: err.message })
    }
  }

  componentDidMount() {
    this.triggerVerify()
  }

  render() {
    const { error } = this.state
    const { onBack, onClose } = this.props

    return (
      <PublishSettings
        hideActions
        title="iOS Publish Settings"
        onClose={onClose}
      >
        <div className="verify-credentials">
          {error ? (
            <>
              <h2>
                We weren’t able to verify your App Store Connect Credentials.
              </h2>
              <p>{error}</p>
              <Button green onClick={onBack}>
                Back to Settings
              </Button>
            </>
          ) : (
            <>
              <Loading large />
              <h2>Checking Credentials...</h2>
            </>
          )}
        </div>
      </PublishSettings>
    )
  }
}
