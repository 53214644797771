import React, { ReactElement } from 'react'
import { IconButton } from 'components/Shared/Icon'
import stopPropagation from './stopPropagation'

interface Props {
  hasMagicLayout: boolean
  handleClickToLayoutSettings: () => void
  toggleVisibility: () => void
  hidden: boolean
  magicHidden: boolean
}

const HiddenIconButton = ({
  hasMagicLayout,
  handleClickToLayoutSettings,
  toggleVisibility,
  hidden,
  magicHidden,
}: Props): ReactElement | null => {
  if (!hasMagicLayout) {
    const iconType = hidden ? 'hidden' : 'eye'

    return (
      <IconButton
        type={iconType}
        onClick={toggleVisibility}
        onMouseDown={stopPropagation}
      />
    )
  }

  if (!magicHidden) {
    return null
  }

  return (
    <IconButton
      type="magic-layout-enabled"
      onClick={handleClickToLayoutSettings}
      onMouseDown={stopPropagation}
    />
  )
}

export default HiddenIconButton
