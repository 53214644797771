import React, { Component } from 'react'
import MultiSelectMenu from '@protonapp/react-multi-menu'
import classNames from 'classnames'

import './SelectControl.css'

export default class SelectControl extends Component {
  static defaultProps = {
    small: true,
  }

  handleChange = value => {
    const { name, onChange } = this.props

    onChange({ [name]: value })
  }

  render() {
    let { options, value, defaultValue, placeholder, label, small, title } =
      this.props

    if (value === undefined) {
      value = defaultValue
    }

    return (
      <div
        className={classNames('select-control', {
          'with-title': Boolean(title),
        })}
      >
        {Boolean(title) && (
          <label className="select-control__title">{title}</label>
        )}
        <MultiSelectMenu
          className={classNames('wrapped-select', small && 'small')}
          options={options}
          onChange={this.handleChange}
          value={value}
          placeholder={placeholder}
        />
        {Boolean(label) && <label>{label}</label>}
      </div>
    )
  }
}
