import React from 'react'
import classNames from 'classnames'

import Accordion from 'components/Shared/Accordion'
import ToggleField from 'components/Shared/Forms/ToggleField'
import { LAZY_LOADED_LISTS } from '../../../constants'

import './AdvancedListOptions.scss'

const Children = props => {
  const { onChange, value, isCustomList, componentName } = props

  const listenForChanges = value?.options?.listenForChanges
  const paginate = value?.options?.paginate

  const handleListenChange = listenForChanges => onChange({ listenForChanges })
  const handlePaginateChange = paginate => onChange({ paginate })

  const showPaginationOption =
    isCustomList || LAZY_LOADED_LISTS.includes(componentName)

  return (
    <>
      <ToggleField
        label="Auto refresh list when users add new items?*"
        helpText="*Too many lists with this enabled can slow down your app."
        onChange={handleListenChange}
        value={listenForChanges}
        inputValue="Auto Refresh"
        boxed
      />
      {showPaginationOption && (
        <ToggleField
          label="Only load & display visible list items?"
          inputValue="Load Items as User Scrolls"
          onChange={handlePaginateChange}
          value={paginate}
          boxed
        />
      )}
    </>
  )
}

const AdvancedListOptions = props => {
  const renderChildren = () => <Children {...props} />

  return (
    <Accordion
      className={classNames('advanced-options')}
      renderHeader={() => 'Advanced Options'}
      renderChildren={renderChildren}
    />
  )
}

AdvancedListOptions.propTypes = {}
AdvancedListOptions.defaultProps = {}

export default AdvancedListOptions
