import { DeviceValue, EditorObject } from 'utils/responsiveTypes'
import { listTypes } from '@adalo/constants'
import getCustomListProperties from './getCustomListProperties'

export const getListItemWidth = (
  object: EditorObject,
  deviceType: DeviceValue | undefined
): number => {
  const { columnCount, rowMargin, listType } = getCustomListProperties(
    object,
    deviceType
  )

  let boxWidth = object.width
  const spacing = rowMargin || 0

  if (listType === listTypes.GRID) {
    const computedColumnCount = columnCount || 1
    boxWidth =
      (object.width - (computedColumnCount - 1) * spacing) / computedColumnCount
  }

  return boxWidth
}
