import { COMPONENT, LAYOUT_SECTION } from '@adalo/constants'
import {
  pathLength,
  subPath,
  getGroupPath,
  getObject,
  sort,
  optimize,
  getDeviceType,
} from '@adalo/utils'

import { getAbsoluteBbox } from './geometry'
import {
  getContainerFromSection,
  isEditableSectionElement,
} from './layoutSections'

const COMPONENT_MIN_SNAP_WIDTH = 32
const COMPONENT_MIN_SNAP_HEIGHT = 36
const COMPONENT_SNAP_MARGIN_X = 16
const COMPONENT_SNAP_MARGIN_Y = 20

// Easier format for reducers
export const getSnapGrid = (state, selection) => {
  const selectionPath = getGroupPath(selection.map(id => state.map[id]))
  const parentPath = subPath(selectionPath, 1)
  const parent = getObject(state.list, parentPath)

  return createSnapGridForParent(state, parent?.id ?? '', selection ?? '', true)
}

export const getSnapGridForParent = (state, selection, parentId) => {
  return createSnapGridForParent(state, parentId, selection, true)
}

// Snap grid format:
//
// {
//   x: [{ x: 20, objectIds: [...] }],
//   y: [{ y: 42, objectIds: [...] }],
// }
//
// Coordinates are global and in zoom format

export const createSnapGridForParent = (
  state,
  parentId,
  selectionIds,
  hasComponentGuides = false
) => {
  const xPoints = {}
  const yPoints = {}
  const { list, map } = state

  const parentObj = list.slice().find(obj => obj.id === parentId)
  const objects = []

  if (!parentObj) {
    return { xGrid: [], yGrid: [] }
  }

  let layoutSectionSelected = false
  if (selectionIds.length === 1) {
    const selectedObject = getObject(list, map[selectionIds[0]])
    layoutSectionSelected = selectedObject.type === LAYOUT_SECTION
  }

  if (hasComponentGuides) {
    if (parentObj && parentObj.type === COMPONENT) {
      objects.push(parentObj)
      if (
        parentObj.width > COMPONENT_MIN_SNAP_WIDTH &&
        parentObj.height > COMPONENT_MIN_SNAP_HEIGHT
      ) {
        objects.push({
          id: parentObj.id,
          x: parentObj.x + COMPONENT_SNAP_MARGIN_X,
          y: parentObj.y + COMPONENT_SNAP_MARGIN_Y,
          width: parentObj.width - COMPONENT_MIN_SNAP_WIDTH,
          height: parentObj.height - COMPONENT_MIN_SNAP_HEIGHT,
        })
      }
    }
  }

  const parentPath = map[parentId]
  const parent = getObject(list, parentPath)
  const parentDevice =
    parent.type === COMPONENT ? getDeviceType(parent.width) : undefined

  for (let j = 0; j < parentObj.children?.length; j += 1) {
    const child = parentObj.children[j]

    // Selected children should not be included in the snap grid
    if (selectionIds?.includes(child.id)) {
      continue
    }

    // const bboxDevice = layoutSectionSelected ? parentDevice : undefined
    const bboxDevice = parentDevice

    const map = { [child.id]: `${parentPath}.${j}` }
    const absoluteChild = getAbsoluteBbox(child, list, map, bboxDevice)

    objects.push(absoluteChild)

    if (isEditableSectionElement(child)) {
      try {
        const container = getContainerFromSection(child)

        const map = { [container.id]: `${parentPath}.${j}.0.0` }
        const absoluteContainer = getAbsoluteBbox(
          container,
          list,
          map,
          bboxDevice
        )
        objects.push(absoluteContainer)
      } catch (e) {
        // no-op: in this specific case, an error thrown here
        // should not disrupt the rest of the snapping logic
      }
    }
  }

  for (let i = 0; i < objects.length; i += 1) {
    const obj = objects[i]

    const xCoords = [Math.round(obj.x), Math.round(obj.x + obj.width)]
    const objectTop = Math.round(obj.y)
    const objectBottom = Math.round(obj.y + obj.height)
    const yCoords = [objectTop, objectBottom]

    addPoints(xPoints, xCoords, obj)
    addPoints(yPoints, yCoords, obj)

    // layout sections don't need to snap to the center of other layout sections
    if (!(obj.type === LAYOUT_SECTION && layoutSectionSelected)) {
      const centerX = Math.round(obj.x) + Math.round(obj.width) / 2
      const centerY = Math.round(obj.y) + Math.round(obj.height) / 2

      // Centers
      addPoints(xPoints, [centerX], obj, true)
      addPoints(yPoints, [centerY], obj, true)
    }
  }

  const xGrid = Object.keys(xPoints).map(x => ({ ...xPoints[x], point: +x }))

  const yGrid = Object.keys(yPoints).map(y => ({ ...yPoints[y], point: +y }))

  const snapGrid = {
    xGrid: sort(xGrid, ({ point }) => point),
    yGrid: sort(yGrid, ({ point }) => point),
  }

  return snapGrid
}

export const createSnapGrid = (
  list,
  selectionPath = '',
  hasComponentGuides = false,
  selectionIds
) => {
  const xPoints = {}
  const yPoints = {}

  let objects = list.slice()

  if (hasComponentGuides) {
    const guides = []

    for (const obj of objects) {
      if (
        obj.width > COMPONENT_MIN_SNAP_WIDTH &&
        obj.height > COMPONENT_MIN_SNAP_HEIGHT
      ) {
        objects.push({
          id: obj.id,
          x: obj.x + COMPONENT_SNAP_MARGIN_X,
          y: obj.y + COMPONENT_SNAP_MARGIN_Y,
          width: obj.width - COMPONENT_MIN_SNAP_WIDTH,
          height: obj.height - COMPONENT_MIN_SNAP_HEIGHT,
        })
      }
    }

    objects = objects.concat(guides)
  }

  // Iterate through depths of selection
  for (let i = 1; i < pathLength(selectionPath); i += 1) {
    const parentPath = subPath(selectionPath, i)
    const parent = getObject(list, parentPath)

    // Iterate through siblings at this depth
    if (parent?.children) {
      for (let j = 0; j < parent.children.length; j += 1) {
        const child = parent.children[j]

        if (selectionIds?.includes(child.id)) {
          continue
        }

        const map = { [child.id]: `${parentPath}.${j}` }
        const absoluteChild = getAbsoluteBbox(child, list, map)
        objects.push(absoluteChild)
      }
    }
  }

  for (let i = 0; i < objects.length; i += 1) {
    const obj = objects[i]

    const xCoords = [Math.round(obj.x), Math.round(obj.x + obj.width)]
    const yCoords = [Math.round(obj.y), Math.round(obj.y + obj.height)]

    addPoints(xPoints, xCoords, obj)
    addPoints(yPoints, yCoords, obj)

    const centerX = Math.round(obj.x) + Math.round(obj.width) / 2
    const centerY = Math.round(obj.y) + Math.round(obj.height) / 2

    // Centers
    addPoints(xPoints, [centerX], obj, true)
    addPoints(yPoints, [centerY], obj, true)
  }

  const xGrid = Object.keys(xPoints).map(x => ({
    ...xPoints[x],
    point: +x,
  }))

  const yGrid = Object.keys(yPoints).map(y => ({
    ...yPoints[y],
    point: +y,
  }))

  const snapGrid = {
    xGrid: sort(xGrid, ({ point }) => point),
    yGrid: sort(yGrid, ({ point }) => point),
  }

  return snapGrid
}

// Util function for debugging
export const getSnapGridDebugMessage = snapGrid => {
  const { xGrid, yGrid } = snapGrid
  const [xGridMessage, yGridMessage] = [xGrid, yGrid].map(grid => {
    const gridMessage = grid
      .map(gridItem => {
        const { center, point, objects } = gridItem
        const pointType = center ? 'center' : 'edge'
        const objectIds = objects.map(({ id }) => id).join(', ')

        const message = `${pointType} ${point} ${objectIds}`

        return message
      })
      .join(`\n`)

    return gridMessage
  })

  const message = `\nxGrid:\n${xGridMessage}\n\nyGrid:\n${yGridMessage}`

  return message
}

export const addPoints = (pointsMap, coords, obj, center = false) => {
  for (let i = 0; i < coords.length; i += 1) {
    const coord = coords[i]

    if (!pointsMap[coord]) {
      pointsMap[coord] = { objects: [] }
    }

    if (center) {
      pointsMap[coord].center = true
    } else {
      pointsMap[coord].edge = true
    }

    pointsMap[coord].objects.push(bbox(obj))
  }
}

export const bbox = obj => {
  const { id, type, x, y, width, height } = obj

  return { id, type, x, y, width, height }
}

export const isCenter = key => {
  return key.match(/center$/)
}

export const getSnapValue = (grid, coords, zoom, isResize = false) => {
  const coordValues = Object.keys(coords).map(key => ({
    key,
    value: coords[key],
  }))

  const tolerance = 10 / zoom.scale

  const centerGrid = grid.filter(g => g.center)
  const edgeGrid = grid.filter(g => g.edge)

  const candidates = coordValues
    .map(({ key, value }) => {
      let scaledTolerance = tolerance

      if (isResize && isCenter(key)) {
        scaledTolerance = tolerance / 2
      }

      const grid = isCenter(key) ? centerGrid : edgeGrid

      const snapValue = optimize(grid, ({ point }) => {
        return -Math.abs(point - value)
      })

      if (snapValue && Math.abs(snapValue.point - value) <= scaledTolerance) {
        return { key, value: snapValue.point }
      }

      return undefined
    })
    .filter(result => result)

  const result = optimize(candidates, ({ key, value }) => {
    const factor = isCenter(key) && isResize ? 2 : 1

    return -Math.abs(value - coords[key]) * factor
  })

  if (result) {
    return {
      [result.key]: result.value,
    }
  }

  return null
}

export const roundObject = object => {
  const { x, y, width, height } = object

  const newLeft = Math.round(x)
  const newRight = Math.round(x + width)

  const newTop = Math.round(y)
  const newBottom = Math.round(y + height)

  return {
    ...object,
    x: newLeft,
    y: newTop,
    width: newRight - newLeft,
    height: newBottom - newTop,
  }
}
