import React, { Component } from 'react'
import { Field } from 'redux-form'

import { WrappedBindableTextControl } from '../../../Shared/BindableTextControl'

export default class NavigateOptions extends Component {
  render() {
    const { field, objectId, reference, actionId } = this.props
    const role = reference ? 'listItem' : null

    return (
      <div className="action-form-external-link form-inspect-field-wrapper">
        <Field
          name={`${field}.options.url`}
          displayName="Website URL"
          component={WrappedBindableTextControl}
          objectId={objectId}
          role={role}
          reference={reference}
          actionId={actionId}
        />
      </div>
    )
  }
}
