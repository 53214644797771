import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'

// ducks
import { addAppUser } from 'ducks/appUsers'
import { requestOrgApps } from 'ducks/apps'

// context
import { TeamAppsContext } from './index'

import AppsListItem from './AppsListItem'

const AppsList = ({ query }) => {
  const dispatch = useDispatch()
  const { isAppsListLoaded, currentUserId, currentOrganizationId } =
    useContext(TeamAppsContext)

  const { teamAppsList, orgAppsList } = useSelector(state => state.apps)

  const makersCurrentApps = useMemo(() => {
    if (orgAppsList) {
      const apps = []

      for (const app of Object.values(orgAppsList).flat()) {
        apps.push(app.id)
      }

      return apps
    } else {
      return []
    }
  }, [orgAppsList && Object.keys(orgAppsList).length])

  const itemsToRender = useMemo(() => {
    return teamAppsList.filter(app =>
      query ? app.name.toLowerCase().includes(query.toLowerCase()) : true
    )
  }, [teamAppsList.length, query])

  const handleJoin = useCallback(
    async appId => {
      await dispatch(addAppUser(appId, currentUserId))
      await dispatch(requestOrgApps(currentOrganizationId))
    },
    [dispatch, currentOrganizationId, currentUserId]
  )

  if (!isAppsListLoaded) {
    return (
      <div className="settings-content-box">
        <EmptyState greedy>
          <Loading />
        </EmptyState>
      </div>
    )
  }

  return (
    <div className="settings-content-box">
      {itemsToRender.length === 0 ? (
        <EmptyState greedy>
          <p>No results...</p>
        </EmptyState>
      ) : (
        itemsToRender.map(app => {
          return (
            <AppsListItem
              key={app.id}
              app={app}
              makersCurrentApps={makersCurrentApps}
              handleJoin={handleJoin}
            />
          )
        })
      )}
    </div>
  )
}

export default AppsList
