import { FC, memo, useCallback } from 'react'
import { connect } from 'react-redux'
import DocumentEvents from 'react-document-events'
import isEqual from 'lodash/isEqual'
import { getXSnap, getYSnap } from 'ducks/editor/snapping'
import { getSnapGridParent, setSnapGridParent } from 'ducks/editor/selection'
import { getMap, getZoom, selectObjects, State } from 'ducks/editor/objects'
import { Snap } from 'ducks/editor/types/snapping'
import { Screen } from 'ducks/editor/ObjectTypes'
import { scale, scaleRect, scaleValue, unScale } from 'utils/zoom'
import { getScreenOnPointer } from 'utils/editor'
import { useThrottledCallback } from 'utils/debounce'
import { Point, Rect, Zoom } from 'utils/canvasTypes'

import './SnapGrid.css'

interface SnapProps {
  coord: number | undefined
  zoom: Zoom
  bounds: Rect | undefined
}

const GRID_STROKE_COLOR = '#f04b25'

const SnapX: FC<SnapProps> = memo(({ coord, zoom, bounds }) => {
  if (!coord || !bounds) {
    return null
  }

  let [x] = scale([coord, 0], zoom) as Point
  x = Math.round(x + 0.5) - 0.5

  if (x > bounds.x + bounds.width || x < bounds.x) {
    return null
  }

  return (
    <line
      x1={x}
      x2={x}
      y1={bounds.y + scaleValue(20, zoom)}
      y2={bounds.y + bounds.height}
      strokeDasharray="3 3"
      stroke={GRID_STROKE_COLOR}
    />
  )
}, isEqual)

const SnapY: FC<SnapProps> = memo(({ coord, zoom, bounds }) => {
  if (!coord || !bounds) {
    return null
  }

  let [, y] = scale([0, coord], zoom) as Point
  y = Math.round(y + 0.5) - 0.5

  if (y < bounds.y || y > bounds.y + bounds.height) {
    return null
  }

  const overflowPadding = 100 * zoom.scale
  const x1 = bounds.x - overflowPadding
  const x2 = bounds.x + bounds.width + overflowPadding
  const overflowPercent = overflowPadding / bounds.width
  const fadedColor = 'rgb(240, 75, 37, 0)'
  const solidColor = GRID_STROKE_COLOR

  return (
    <>
      <defs>
        <linearGradient
          id="ySnapLine"
          x1={x1}
          x2={x2}
          y1={y}
          y2={y}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fadedColor} offset={0} />
          <stop stopColor={solidColor} offset={overflowPercent} />
          <stop stopColor={solidColor} offset={1 - overflowPercent} />
          <stop stopColor={fadedColor} offset={1} />
        </linearGradient>
      </defs>
      <line
        x1={x1}
        x2={x2}
        y1={y}
        y2={y}
        strokeDasharray="3 3"
        stroke="url(#ySnapLine)"
      />
    </>
  )
}, isEqual)

interface SnapGridProps {
  xSnap: Snap | undefined
  ySnap: Snap | undefined
  screens: Screen[]
  screensMap: Record<string, string>
  snapGridParent: string | undefined
  zoom: Zoom
  setSnapGridParentDispatch: (screenId: string | undefined) => void
}
const SnapGrid: FC<SnapGridProps> = ({
  xSnap,
  ySnap,
  screens,
  screensMap,
  snapGridParent,
  zoom,
  setSnapGridParentDispatch,
}) => {
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e

      const pointerCoords = unScale([clientX, clientY], zoom) as Point
      const pointerScreen = getScreenOnPointer(pointerCoords, screens)

      if (pointerScreen !== snapGridParent) {
        setSnapGridParentDispatch(pointerScreen)
      }
    },
    [snapGridParent, screens, zoom, setSnapGridParentDispatch]
  )

  // Throttle mouse movement listener to keep performance decent(-ish)
  const handleMouseMoveThrottled = useThrottledCallback(handleMouseMove, 200)

  let snappingGrid = null

  const shouldSnap = Boolean(xSnap?.coord || ySnap?.coord)
  if (shouldSnap && snapGridParent) {
    const screenIndex = parseInt(screensMap[snapGridParent] ?? '', 10)
    const screen = screens[screenIndex]
    const bounds = scaleRect(screen, zoom)

    snappingGrid = (
      <g className="snap-grid">
        <SnapX coord={xSnap?.coord} zoom={zoom} bounds={bounds} />
        <SnapY coord={ySnap?.coord} zoom={zoom} bounds={bounds} />
      </g>
    )
  }

  return (
    <>
      {snappingGrid}
      <DocumentEvents onMouseMove={handleMouseMoveThrottled} />
    </>
  )
}

const mapStateToProps = (state: State) => {
  const xSnap = getXSnap(state)
  const ySnap = getYSnap(state)
  const snapGridParent = getSnapGridParent(state)
  const screens = selectObjects(state)
  const screensMap = getMap(state)
  const zoom = getZoom(state)

  return { xSnap, ySnap, snapGridParent, screens, screensMap, zoom }
}

export default connect(mapStateToProps, {
  setSnapGridParentDispatch: setSnapGridParent,
})(SnapGrid)
