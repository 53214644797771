import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from 'components/Shared/Icon'
import TemplateItem from 'components/Shared/TemplateItem'
import Button from 'components/Shared/Button'

import { formatToCurrency } from 'utils/currency'

import './ComponentItem.scss'

export class ComponentItem extends Component {
  static propTypes = {
    iconStyles: PropTypes.object,
    iconClassName: PropTypes.string,
    isMobileScreen: PropTypes.bool,
    isSelected: PropTypes.bool,
    isTeaser: PropTypes.bool,
    name: PropTypes.string.isRequired,
    showCheckmark: PropTypes.bool,
  }

  static defaultProps = {
    isMobileScreen: false,
    isSelected: false,
    isTeaser: false,
    showCheckmark: false,
  }

  getPrice() {
    const { price, installed } = this.props

    if (installed) {
      return (
        <>
          <Icon small type="check" color="teal" />
          Installed
        </>
      )
    }

    if (typeof price === 'number') {
      if (price === 0) {
        return 'Free!'
      }

      return formatToCurrency(price)
    }

    return null
  }

  render() {
    const {
      name,
      className,
      image,
      iconStyles,
      iconClassName,
      isMobileScreen,
      isTeaser,
      isSelected,
      showCheckmark,
      isResponsive,
      price,
      installed,
      showView,
      onViewClick,
      ...otherProps
    } = this.props

    if (isResponsive) {
      return (
        <TemplateItem
          item={{ name, image }}
          showCheckmark={showCheckmark}
          isSelected={isSelected}
          showFooter={false}
          {...otherProps}
        />
      )
    }

    const parsedPrice = this.getPrice()

    return (
      <div className="editor-add-component-item-wrapper">
        <div
          {...otherProps}
          className={classNames(
            'editor-add-component-item',
            {
              'editor-add-component-item-teaser': isTeaser,
              'editor-add-component-screen': isMobileScreen,
              'editor-add-component-item-selected': isSelected,
              'editor-add-component-item--show-view': showView,
            },
            className
          )}
        >
          <div className="editor-add-component-name">
            <span>{name}</span>
          </div>
          <div
            className={classNames('editor-add-component-icon', iconClassName)}
            style={iconStyles}
          >
            {showCheckmark ? (
              <div className="editor-add-component-item-checkmark">
                <Icon type="done" />
              </div>
            ) : null}
          </div>
          {showView ? (
            <div className="editor-add-component-view">
              <Button teal small onClick={onViewClick}>
                View
              </Button>
            </div>
          ) : null}

          {parsedPrice ? (
            <div
              className={classNames('editor-add-component-price', {
                'editor-add-component-price--installed': installed,
              })}
            >
              {parsedPrice}
            </div>
          ) : null}

          {isTeaser ? (
            <div className="editor-add-component-banner">Coming Soon!</div>
          ) : null}
        </div>
      </div>
    )
  }
}
