import React from 'react'
import PropTypes from 'prop-types'

const LibraryComponentError = ({ object }) => {
  return (
    <div className="library-component-error">
      Missing Component: {object?.libraryName}.{object?.componentName}
      {object?.libraryName?.toLowerCase().includes('ad') ||
      object?.componentName?.toLowerCase().includes('ad') ? (
        <>
          <br />
          <br />
          <span>
            Make sure your ad blocker is disabled to use the{' '}
            {object.componentName} component.
          </span>
        </>
      ) : null}
    </div>
  )
}

LibraryComponentError.propTypes = {
  object: PropTypes.object.isRequired,
}

export default React.memo(LibraryComponentError)
