import Accordion from 'components/Shared/Accordion'

export default function PromptTips(): JSX.Element {
  return (
    <Accordion
      className="tips-for-prompting"
      title={
        <span className="tips-for-prompting-title">Tips for Prompting</span>
      }
      renderChildren={() => (
        <div className="tips-for-prompting-content">
          <div>
            <p className="tips-for-prompting-tip-title">
              Who are your users and what's the main point?
            </p>
            <p className="tips-for-prompting-tip-prompt">
              An app for clients of my gym to request custom workouts
            </p>
          </div>
          <div>
            <p className="tips-for-prompting-tip-title">
              What kinds of things can people do? What features should it have?
            </p>
            <p className="tips-for-prompting-tip-prompt">
              It should have a workout newsfeed, chat with coaches, and the
              option to send reminders to get motivated!
            </p>
          </div>
        </div>
      )}
    />
  )
}
