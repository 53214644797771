import { CustomActionBody } from './customAction.type'

const createChatGPTCustomAction = (apiKey: string): CustomActionBody => ({
  auth: [
    {
      name: 'Authorization',
      type: 'header',
      value: `Bearer ${apiKey}`,
    },
  ],
  body: [
    '{\n  "model": "gpt-4o-mini",\n  "messages": [\n    {\n      "role": "system",\n      "content": "',
    {
      id: '0d022661-80f9-4661-af94-33a194edb714',
      label: 'History (Optional)',
    },
    '"\n    },\n    {\n      "role": "user",\n      "content": "',
    {
      id: '72765b2f-4d00-42fa-860f-2a4a4a6c7db7',
      label: 'Prompt',
    },
    '"\n    }\n  ]\n}',
  ],
  name: 'Ask ChatGPT',
  template: true,
  type: 'create',
  inputs: [
    {
      type: 'text',
      label: 'Prompt',
      value: {
        id: '72765b2f-4d00-42fa-860f-2a4a4a6c7db7',
      },
      exampleText: "What is code if there's no-code?",
    },
    {
      type: 'text',
      label: 'History (Optional)',
      value: {
        id: '0d022661-80f9-4661-af94-33a194edb714',
      },
      exampleText: "Where's Japan?",
    },
  ],
  method: 'post',
  baseURL: 'https://api.openai.com/v1/chat/completions',
  outputs: {
    id: {
      key: 'id',
      name: 'Id',
      type: 'text',
      sampleValue: 'chatcmpl-72RhSoHNpved3A3wYafgnqrPmZbXa',
    },
    model: {
      key: 'model',
      name: 'Model',
      type: 'text',
      sampleValue: 'gpt-4o-mini',
    },
    object: {
      key: 'object',
      name: 'Object',
      type: 'text',
      sampleValue: 'chat.completion',
    },
    created: {
      key: 'created',
      name: 'Created',
      type: 'number',
      sampleValue: 1680817490,
    },
    'choices>0>index': {
      key: 'choices.0.index',
      name: 'Choice Index',
      type: 'number',
      sampleValue: 0,
    },
    'usage>total_tokens': {
      key: 'usage.total_tokens',
      name: 'Total Tokens',
      type: 'number',
      sampleValue: 98,
    },
    'usage>prompt_tokens': {
      key: 'usage.prompt_tokens',
      name: 'Prompt Tokens',
      type: 'number',
      sampleValue: 17,
    },
    'choices>0>message>role': {
      key: 'choices.0.message.role',
      name: 'Role',
      type: 'text',
      sampleValue: 'assistant',
    },
    'choices>0>finish_reason': {
      key: 'choices.0.finish_reason',
      name: 'Finish Reason',
      type: 'text',
      sampleValue: 'stop',
    },
    'usage>completion_tokens': {
      key: 'usage.completion_tokens',
      name: 'Completion Tokens',
      type: 'number',
      sampleValue: 81,
    },
    'choices>0>message>content': {
      key: 'choices.0.message.content',
      name: 'Response',
      type: 'text',
      sampleValue:
        "Code is a set of instructions that a computer can understand and execute in order to complete a task. If there's no-code, it means that the task is achieved without writing any code or programming. This can be done through visual tools, drag-and-drop interfaces, and other low-code or no-code platforms. These platforms enable non-technical users to create complex applications without requiring deep knowledge of programming languages.",
    },
  },
  testResults: {
    url: 'https://api.openai.com/v1/chat/completions',
    success: true,
    response: {
      data: {
        id: 'chatcmpl-72RhSoHNpved3A3wYafgnqrPmZbXa',
        model: 'gpt-4o-mini',
        usage: {
          total_tokens: 98,
          prompt_tokens: 17,
          completion_tokens: 81,
        },
        object: 'chat.completion',
        choices: [
          {
            index: 0,
            message: {
              role: 'assistant',
              content:
                "Code is a set of instructions that a computer can understand and execute in order to complete a task. If there's no-code, it means that the task is achieved without writing any code or programming. This can be done through visual tools, drag-and-drop interfaces, and other low-code or no-code platforms. These platforms enable non-technical users to create complex applications without requiring deep knowledge of programming languages.",
            },
            finish_reason: 'stop',
          },
        ],
        created: 1680817490,
      },
      status: 200,
      headers: {
        date: 'Thu, 06 Apr 2023 21:44:53 GMT',
        'cf-ray': '7b3d36e1bd8627f4-SLC',
        server: 'cloudflare',
        'alt-svc': 'h3=":443"; ma=86400, h3-29=":443"; ma=86400',
        connection: 'close',
        'content-type': 'application/json',
        'openai-model': 'gpt-4o-mini',
        'x-request-id': 'd68977f004a7ac0868b8962e1c5fa9fb',
        'cache-control': 'no-cache, must-revalidate',
        'content-length': '722',
        'openai-version': '2020-10-01',
        'cf-cache-status': 'DYNAMIC',
        'openai-organization': 'user-wfz8hcahnkoabquyq0rvheda',
        'openai-processing-ms': '2904',
        'strict-transport-security': 'max-age=15724800; includeSubDomains',
        'x-ratelimit-limit-requests': '3500',
        'x-ratelimit-reset-requests': '17ms',
        'access-control-allow-origin': '*',
        'x-ratelimit-remaining-requests': '3499',
      },
      statusText: 'OK',
    },
    availableOutputs: {
      id: {
        key: 'id',
        name: 'Id',
        type: 'text',
        sampleValue: 'chatcmpl-72RhSoHNpved3A3wYafgnqrPmZbXa',
      },
      model: {
        key: 'model',
        name: 'Model',
        type: 'text',
        sampleValue: 'gpt-4o-mini',
      },
      object: {
        key: 'object',
        name: 'Object',
        type: 'text',
        sampleValue: 'chat.completion',
      },
      created: {
        key: 'created',
        name: 'Created',
        type: 'number',
        sampleValue: 1680817490,
      },
      'choices>0>index': {
        key: 'choices.0.index',
        name: 'Choice Index',
        type: 'number',
        sampleValue: 0,
      },
      'usage>total_tokens': {
        key: 'usage.total_tokens',
        name: 'Total Tokens',
        type: 'number',
        sampleValue: 98,
      },
      'usage>prompt_tokens': {
        key: 'usage.prompt_tokens',
        name: 'Prompt Tokens',
        type: 'number',
        sampleValue: 17,
      },
      'choices>0>message>role': {
        key: 'choices.0.message.role',
        name: 'Role',
        type: 'text',
        sampleValue: 'assistant',
      },
      'choices>0>finish_reason': {
        key: 'choices.0.finish_reason',
        name: 'Finish Reasons',
        type: 'text',
        sampleValue: 'stop',
      },
      'usage>completion_tokens': {
        key: 'usage.completion_tokens',
        name: 'Completion Tokens',
        type: 'number',
        sampleValue: 81,
      },
      'choices>0>message>content': {
        key: 'choices.0.message.content',
        name: 'Response',
        type: 'text',
        sampleValue:
          "Code is a set of instructions that a computer can understand and execute in order to complete a task. If there's no-code, it means that the task is achieved without writing any code or programming. This can be done through visual tools, drag-and-drop interfaces, and other low-code or no-code platforms. These platforms enable non-technical users to create complex applications without requiring deep knowledge of programming languages.",
      },
    },
  },
})

export default createChatGPTCustomAction
