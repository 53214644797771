import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dataTypes } from '@adalo/constants'

import { getAppComponent, hasRole } from '../../../../utils/libraries'

import {
  getPrimaryFieldSource,
  getImageFieldSource,
} from '../../../../ducks/recommender'

import { selectObject, getCurrentAppId } from '../../../../ducks/editor/objects'
import { getApp } from '../../../../ducks/apps'
import store from '../../../../redux-store'
import CommonListControl from '../CommonListControl'

class ListControl extends Component {
  // When we change the table, we should also set the primary fields
  getAutomaticBindings = binding => {
    const { name, app, object } = this.props
    const { libraryName, componentName } = object
    const componentInfo = getAppComponent(app, libraryName, componentName)

    const state = store.getState()
    const primarySource = getPrimaryFieldSource(state, app.id, binding)
    const imageSource = getImageFieldSource(state, app.id, binding)

    const result = {}
    let primaryUsed = false
    let imageUsed = false

    for (const prop of componentInfo.props) {
      if (!hasRole(prop, 'listItem') || prop.reference !== name) {
        continue
      }

      if (primarySource && prop.type === dataTypes.TEXT && !primaryUsed) {
        result[prop.name] = primarySource
        primaryUsed = true
      } else if (imageSource && prop.type === dataTypes.IMAGE && !imageUsed) {
        result[prop.name] = imageSource
        imageUsed = true
      }
    }

    for (const child of componentInfo.childComponents || []) {
      if (!hasRole(child, 'listItem') || child.reference !== name) {
        continue
      }

      for (const prop of child.props) {
        if (primarySource && prop.type === dataTypes.TEXT && !primaryUsed) {
          result[child.name] = result[child.name] || {}
          result[child.name][prop.name] = primarySource
          primaryUsed = true
        } else if (imageSource && prop.type === dataTypes.IMAGE && !imageUsed) {
          result[child.name] = result[child.name] || {}
          result[child.name][prop.name] = imageSource
          imageUsed = true
        }
      }
    }

    return result
  }

  handleChange = (values, opts = {}) => {
    const { trigger } = opts
    const { name, onChange, object } = this.props
    const { [name]: source } = values

    const additionalChanges =
      trigger === 'table' && object.libraryName
        ? this.getAutomaticBindings(source)
        : {}

    onChange({ ...additionalChanges, [name]: source })
  }

  render() {
    const { onChange, listControl, ...otherProps } = this.props

    return (
      <CommonListControl
        {...otherProps}
        onChange={this.handleChange}
        listControl={listControl}
      />
    )
  }
}

const mapStateToProps = (state, { object, objectId }) => ({
  object: object || selectObject(state, objectId),
  app: getApp(state, getCurrentAppId(state)),
})

export default connect(mapStateToProps)(ListControl)
