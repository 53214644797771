import React from 'react'

import { assetProxyURL } from 'utils/assets'

import androidStatusBar from './android-status-bar.svg'

const NotificationsPreview = ({ notificationsIcon }) => {
  return (
    <div className="notifications-icon-preview">
      {notificationsIcon && (
        <span
          role="img"
          style={{
            maskImage: `url(${assetProxyURL(notificationsIcon)})`,
            WebkitMaskImage: `url(${assetProxyURL(notificationsIcon)})`,
          }}
        />
      )}
      <img src={androidStatusBar} alt="android status bar" />
    </div>
  )
}

export default React.memo(NotificationsPreview)
