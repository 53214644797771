import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Button from 'components/Shared/Button'
import { validate } from '../validations'

const FORM_NAME = 'editCollection'

const EditCollection = props => {
  const { accordion, handleSubmit, toggleEditCollection } = props

  useEffect(() => {
    const { openAccordion, tableId } = props
    if (accordion) openAccordion(tableId)
  }, [accordion])

  return (
    <form
      className="data-edit-collection"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Field
        name="name"
        id="name"
        component={WrappedInput}
        type="text"
        label="Collection Name"
        placeholder="Collection Name"
      />
      <div className="actions">
        <Button type="button" text onClick={toggleEditCollection}>
          Cancel
        </Button>
        <Button type="submit" orange>
          Update
        </Button>
      </div>
    </form>
  )
}

const WrappedEditCollection = reduxForm({
  form: FORM_NAME,
  validate,
})(EditCollection)

export default WrappedEditCollection
