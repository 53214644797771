import { DeviceType, LIST, listTypes } from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { EditorObject, ListAttributes } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import { decreaseWidth, increaseWidth } from 'utils/objects/listControl'

import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'
import { disableDeviceSpecificLayoutHandler } from './disableDeviceSpecificLayout'
import { resizeElementHandler } from './resizeElement'
import { moveElementHandler } from './moveElement'
import { COLUMN_BREAKPOINTS } from '../objects/helpers/getCustomListProperties'

export interface DisableDeviceSpecificCustomListColumnsOptions {
  objectId: string
}

export interface DisableDeviceSpecificCustomListColumnsInstruction {
  operation: 'disableDeviceSpecificCustomListColumns'
  options: DisableDeviceSpecificCustomListColumnsOptions
}

export const disableDeviceSpecificCustomListColumnsHandler = (
  state: InstructionState,
  options: DisableDeviceSpecificCustomListColumnsOptions
): InstructionState => {
  const { list, pathMap, selection } = state
  const { objectId } = options

  let updatedList = [...list]
  const oldObject: EditorObject = getObject(updatedList, pathMap, objectId)
  if (oldObject.type !== LIST) {
    throw new Error(
      `Cannot run this instruction on object type: ${oldObject.type}`
    )
  }

  if (
    oldObject.columnBreakpoints !== COLUMN_BREAKPOINTS.CUSTOM ||
    !oldObject.deviceColumns
  ) {
    return state
  }

  const screenObj = getParentScreen(list, pathMap, objectId)
  if (!screenObj) {
    throw new Error(`Could not find screen for object ${objectId}`)
  }

  const device = getDeviceType(screenObj.width)
  const deviceColumns = oldObject.deviceColumns?.[
    device
  ] as Partial<ListAttributes>
  if (!deviceColumns) {
    throw new Error(`Unknown device: ${device}`)
  }

  const {
    rowMargin = 8,
    columnCount = 1,
    listType = listTypes.DEFAULT,
  } = deviceColumns

  const { width, height, x, y } = getDeviceObject(oldObject, device)

  updatedList = updateChangedObject(
    updatedList,
    pathMap,
    {
      ...getObject(updatedList, pathMap, objectId),
      columnBreakpoints: COLUMN_BREAKPOINTS.SHARED,
      deviceColumns: {},
    },
    undefined
  )

  // Disable device specific layout for all devices
  for (const deviceType of [
    DeviceType.MOBILE,
    DeviceType.TABLET,
    DeviceType.DESKTOP,
  ]) {
    ;({ list: updatedList } = disableDeviceSpecificLayoutHandler(
      { list: updatedList, pathMap, selection },
      { objectId, device: deviceType }
    ))
  }

  // Resize the object to the width and height
  // of the device breakpoint that the maker is currently looking at
  ;({ list: updatedList } = resizeElementHandler(
    { list: updatedList, pathMap, selection },
    { objectId, width, height, resetLayout: undefined }
  ))
  ;({ list: updatedList } = moveElementHandler(
    { list: updatedList, pathMap, selection },
    { objectId, x, y }
  ))

  // ---
  // At this point, the object is now a shared object
  // It has the same rowMargin, columnCount, and listType for all devices
  // where those values are from the original shared object
  // ---

  // Update the shared object with the new columnCount, rowMargin, and listType
  // Update the shared object with the new width that accomodates the new columnCount
  const updatedObject = getObject(updatedList, pathMap, objectId)
  const modifier =
    columnCount > (updatedObject.columnCount || 1)
      ? increaseWidth
      : decreaseWidth
  updatedList = updateChangedObject(
    updatedList,
    pathMap,
    {
      ...updatedObject,
      columnCount,
      rowMargin,
      listType,
      width: modifier(updatedObject, columnCount, {
        rowMargin: updatedObject.rowMargin || 8,
        columnCount: updatedObject.columnCount || 1,
      }),
    },
    undefined
  )

  // ---
  // At this point, the object width has been updated to accomodate the new columnCount
  // It's not the correct width, however, we need to resize it to the correct width again
  // ---

  // Resize the object to the width and height
  // of the device breakpoint that the maker is currently looking at
  ;({ list: updatedList } = resizeElementHandler(
    { list: updatedList, pathMap, selection },
    { objectId, width, height, resetLayout: undefined }
  ))

  return {
    ...state,
    list: updatedList,
  }
}

const disableDeviceSpecificCustomListColumns = (
  objectId: string
): DisableDeviceSpecificCustomListColumnsInstruction => ({
  operation: 'disableDeviceSpecificCustomListColumns',
  options: {
    objectId,
  },
})

export default disableDeviceSpecificCustomListColumns
