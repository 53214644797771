import React, { Component } from 'react'
import Cell from './Cell'

export default class SelectCell extends Component {
  handleToggle = () => {
    const { checked, handleChange, blockedListLength } = this.props

    handleChange(!checked, blockedListLength)
  }

  render() {
    const { header, checked } = this.props

    return (
      <Cell
        width="50px"
        className={header ? 'data-browser-header-item' : null}
        onClick={this.handleToggle}
        select
      >
        <input
          checked={checked}
          onClick={e => {
            e.stopPropagation()
          }}
          onChange={this.handleToggle}
          type="checkbox"
        />
      </Cell>
    )
  }
}
