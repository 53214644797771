import { type ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from 'components/Shared/Modal'

import { showModal, CHURN_FORM_MODAL } from 'ducks/editor/modals'

import './CancelSubscriptionModal.scss'
import { getCurrentOrganization } from 'ducks/organizations'
import { cancelSubscription } from 'ducks/trialWarning'

interface ModalInput {
  onSuccess: { (value: boolean): void }
}

const CancelSubscription = ({ onSuccess }: ModalInput): ReactElement => {
  const dispatch = useDispatch()
  const currentOrganization = useSelector(getCurrentOrganization)

  const handleSuccess = () => {
    dispatch(cancelSubscription(currentOrganization.id))
    onSuccess(true)
    dispatch(showModal(CHURN_FORM_MODAL))
  }

  return (
    <div className="cancel-subscription-modal">
      <Modal.Header>
        <span role="img" aria-label="">
          🥺 🚫
        </span>
        <h1>You're about to unsubscribe from your plan</h1>
      </Modal.Header>
      <Modal.Content>
        <p>
          Your plan will be downgraded at the end of your billing cycle and you
          will lose access to any features not available on your new plan.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button
          teal
          outlined
          padded
          data-adalo-id="manual-churn"
          onClick={handleSuccess}
        >
          Unsubscribe
        </Modal.Button>
        <Modal.Button teal padded onClick={() => onSuccess(false)}>
          Nevermind
        </Modal.Button>
      </Modal.Actions>
    </div>
  )
}

export default CancelSubscription
