import React, { Component } from 'react'
import { connect } from 'react-redux'

import { createApp } from 'utils/io'
import { getNewAppWebSettings } from 'utils/webSettings'

import { getApp } from 'ducks/apps'
import { getOrganizations } from 'ducks/organizations'

import Modal from 'components/Shared/Modal'

import CloneAppForm from './Form'

class CloneApp extends Component {
  state = {
    loading: false,
    errorCreatingApp: false,
  }

  handleSubmit = async values => {
    this.setState({ loading: true, errorCreatingApp: false })

    const { app, organizations } = this.props

    if (!app) {
      return
    }

    const {
      id: appId,
      primaryPlatform,
      branding,
      visibility,
      magicLayout,
      webSettings: sourceWebSettings,
    } = app

    if (!values.organizationId) {
      values.organizationId = organizations[0] && organizations[0].id
    }

    const webSettings = getNewAppWebSettings({
      sourceWebSettings,
      primaryPlatform,
    })

    let opts = {
      ...values,
      branding,
      primaryPlatform,
      magicLayout,
      webSettings,
      copyApp: false,
    }

    if (visibility === 'screens') {
      opts = {
        ...opts,
        prototypeAppId: appId,
        cloneAppWithData: false,
      }
    } else if (visibility === 'data') {
      opts = {
        ...opts,
        datasourceAppId: appId,
        cloneAppWithData: true,
        copyDatabase: undefined,
      }
    }

    return createApp(opts, errorCreatingApp => {
      this.setState({ loading: false, errorCreatingApp })
    })
  }

  render() {
    const { app } = this.props

    const { loading, errorCreatingApp } = this.state

    if (!app || !app?.id) {
      return null
    }

    const initialValues = {
      name: `${app.name}`,
      copyDatabase: '1',
    }

    return (
      <Modal>
        <CloneAppForm
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          loading={loading}
          errorCreatingApp={errorCreatingApp}
        />
      </Modal>
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  app: getApp(state, match.params.appId),
  organizations: getOrganizations(state),
})

export default connect(mapStateToProps)(CloneApp)
