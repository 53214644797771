import React, { useCallback } from 'react'
import { Field, reduxForm } from 'redux-form'

import { assetURL } from 'utils/assets'
import { uploadImage } from 'utils/io'

import SettingsFormField from 'components/Shared/Forms/SettingsFormField'

import { FORM_NAME, asyncValidate, validations } from './shared'

const filenameFunc = value => value && 'google-services.json'

const AndroidCredentialsForm = ({
  appId,
  handleSubmit,
  publishing,
  isAdmin,
}) => {
  const uploadAssetJson = useCallback(
    async dataURL => {
      const file = dataURL.split(',')[1]

      return uploadImage(appId, file, 'file.json')
    },
    [appId]
  )

  return (
    <form id={FORM_NAME} onSubmit={handleSubmit} autoComplete="off">
      <Field
        component={SettingsFormField}
        name="bundleId"
        label="Package Name"
        placeholder="com.your_company.your_app"
        type="text"
        validate={publishing ? validations.bundleId : null}
      />
      <Field
        component={SettingsFormField}
        name="frontendGoogleServices"
        label="Frontend google-services.json"
        type="file"
        acceptedMimeTypes={['application/json']}
        uploadFunc={uploadAssetJson}
        filenameFunc={filenameFunc}
        uploadURLFunc={assetURL}
        validate={publishing ? validations.frontendGoogleServices : null}
        tooltip={
          <>
            For more information on creating a Frontend{' '}
            <code>google-services.json</code> checkout our documentation:{' '}
            <a
              href="https://help.adalo.com/testing-your-app/publishing-to-the-google-play-store/register-android-app-and-download-config-file"
              target="_blank"
              rel="noreferrer noopener"
            >
              Register Android App &amp; Download Config File
            </a>
          </>
        }
      />
      <Field
        component={SettingsFormField}
        name="serviceKeyGoogleServices"
        label="Service Account Key google-services.json"
        type="file"
        acceptedMimeTypes={['application/json']}
        uploadFunc={uploadAssetJson}
        filenameFunc={filenameFunc}
        uploadURLFunc={assetURL}
        validate={publishing ? validations.serviceKeyGoogleServices : null}
        tooltip={
          <>
            For more information on creating a Service Account Key{' '}
            <code>google-services.json</code> checkout our documentation:{' '}
            <a
              href="https://help.adalo.com/testing-your-app/publishing-to-the-google-play-store/generate-and-download-private-key"
              target="_blank"
              rel="noreferrer noopener"
            >
              Generate & Download Private Key
            </a>
          </>
        }
      />
      {isAdmin && (
        <>
          <Field
            component={SettingsFormField}
            name="keystoreAlias"
            label="Keystore Alias"
            type="text"
            tooltip={
              <>
                For more information check out the documentation:{' '}
                <a
                  href="https://www.notion.so/adalonotion/Android-Keystores-5a5462b724f34970bbf1dced10148a50"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Use a custom keystore
                </a>
              </>
            }
          />
          <Field
            component={SettingsFormField}
            name="keystorePassword"
            label="Keystore Password"
            type="password"
            autoComplete="new-password"
          />
          <Field
            component={SettingsFormField}
            name="keystore"
            label="Keystore Body"
            type="text"
          />
          <Field
            component={SettingsFormField}
            name="buildType"
            label="Build Type"
            type="text"
            tooltip={
              "Use 'apk' or 'aab' to generate an APK or bundle file, respectively"
            }
          />
        </>
      )}
      {isAdmin && (
        <Field
          component={SettingsFormField}
          name="skipBuildEnqueue"
          label="Skip build enqueue:"
          tooltip="Set this to not enqueue the build job for packager to pick up. You'll get a command to run the build manually in the browser console."
          type="checkbox"
          placeholder=""
        />
      )}
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  asyncValidate,
})(AndroidCredentialsForm)
