/* eslint-disable global-require */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Modal from 'components/Shared/Modal'

import {
  getScreenTemplatesState,
  ensureScreenTemplatesAreLoaded,
  getCategorizedScreenTemplates,
  SCREEN_TEMPLATES_DEFAULT_OPEN,
} from 'ducks/editor/screenTemplates'

import Loading from 'components/Shared/Loading'

import { assetURL } from 'utils/assets'

import { ComponentsAccordionGroup, ComponentItem } from '../ComponentsAccordion'

const FORM_NAME = 'newScreenForm'

class TemplatesAccordion extends Component {
  getCategories() {
    const { input, categories } = this.props
    const value = input && input.value

    return categories.map(category => {
      return {
        ...category,
        options: category.options.map(option => {
          const isSelected = option.id === value

          if (isSelected) {
            return {
              ...option,
              isSelected,
            }
          }

          return option
        }),
      }
    })
  }

  handleClick(id) {
    const { input } = this.props

    if (input && input.onChange) {
      input.onChange(id)
    }
  }

  renderItem = ({ id, name, thumbnail, iconClassName, isSelected }) => {
    const { primaryPlatform } = this.props
    const isMobileScreen = primaryPlatform === 'mobile'
    const iconStyles = {}

    if (thumbnail) {
      iconStyles.backgroundImage = `url("${assetURL(thumbnail)}")`
    }

    return (
      <ComponentItem
        key={id}
        name={name}
        showCheckmark
        iconClassName={iconClassName}
        iconStyles={iconStyles}
        isSelected={isSelected}
        isMobileScreen={isMobileScreen}
        onClick={() => {
          this.handleClick(id)
        }}
      />
    )
  }

  render() {
    return (
      <ComponentsAccordionGroup
        defaultOpen={SCREEN_TEMPLATES_DEFAULT_OPEN}
        categories={this.getCategories()}
        renderItem={this.renderItem}
        accordionClassName="screen-templates"
      />
    )
  }
}
class NewScreenForm extends Component {
  handleCancel = e => {
    const { onCancel } = this.props
    e.preventDefault()

    onCancel()
  }

  componentDidMount() {
    const { chooseTemplate, ensureScreenTemplatesAreLoaded, primaryPlatform } =
      this.props

    if (chooseTemplate) {
      ensureScreenTemplatesAreLoaded(primaryPlatform)
    }
  }

  render() {
    const {
      chooseTemplate,
      handleSubmit,
      defaultName,
      templatesLoaded,
      submitting,
    } = this.props

    return (
      <form onSubmit={handleSubmit} id="new-screen-form">
        <Modal.Header title="New Screen" />
        <Modal.Content>
          <Field
            autoFocus
            name="name"
            label="Name"
            placeholder={defaultName}
            component={WrappedInput}
            large
          />
          {this.renderTemplates()}
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button type="button" text onClick={this.handleCancel}>
            Cancel
          </Modal.Button>
          <Modal.Button
            form="new-screen-form"
            disabled={chooseTemplate && !templatesLoaded}
            type="submit"
            loading={submitting}
          >
            Create Screen
          </Modal.Button>
        </Modal.Actions>
      </form>
    )
  }

  renderTemplates() {
    const {
      chooseTemplate,
      templatesCategories,
      templatesLoaded,
      primaryPlatform,
    } = this.props

    if (!chooseTemplate) {
      return null
    }

    return !templatesLoaded ? (
      <Loading expanded />
    ) : (
      <Field
        name="template"
        component={TemplatesAccordion}
        primaryPlatform={primaryPlatform}
        categories={templatesCategories}
      />
    )
  }
}

function mapStateToProps(state, { chooseTemplate, primaryPlatform }) {
  if (!chooseTemplate) {
    return {}
  }

  const templatesState = getScreenTemplatesState(state, primaryPlatform)

  const templatesCategories = getCategorizedScreenTemplates(
    state,
    primaryPlatform
  )

  return {
    templatesCategories,
    templatesLoaded: templatesState.loaded,
  }
}

export default connect(mapStateToProps, { ensureScreenTemplatesAreLoaded })(
  reduxForm({
    initialValues: {
      template: 'blank',
    },
    form: FORM_NAME,
  })(NewScreenForm)
)
