import { getFontFamily } from 'utils/type'
import LayoutObject from './layout/LayoutObject'
import DeviceType from '../types/DeviceType'
import ObjectType from '../types/ObjectType'
import LayoutContext from './layout/LayoutContext'
import Length, { LengthUnit } from './Length'
import ObjectTreeVisitor from './ObjectTreeVisitor'
import {
  StyleMap,
  StyleRule,
  StyleRuleCollection,
  StyleKey,
  StyleValue,
  Display,
  Position,
} from './styles'
import ContentLayoutResult from './ContentLayoutResult'
import layoutPushGraphContent from './layoutPushGraphContent'
import layoutFlowContent from './layoutFlowContent'
import layoutAbsoluteContent from './layoutAbsoluteContent'
import { layoutGridContent } from './grid'
import layoutFixedContent from './layoutFixedContext'

export const NON_ZERO_WIDTH = 0.001

const VALID_MINMAX_INSTRUCTION_SOURCES = new Set<LayoutContext['instruction']>([
  'moveElement',
  'resizeElement',
  'resizeScreen',
  undefined,
])

/**
 * List of default properties which ensure every rule on every node has some value (no undefined rules).
 * These are akin to User Agent Stylesheets in CSS.
 */
const GLOBAL_DEFAULT_STYLES: StyleMap = {
  display: Display.Flow,

  // Positioning
  top: undefined,
  bottom: undefined,
  left: undefined,
  right: undefined,
  position: Position.Static,

  // Box sizing
  width: undefined,
  minHeight: Length.ZERO,

  minWidth: undefined,
  maxWidth: undefined,

  marginLeft: Length.ZERO,
  marginRight: Length.ZERO,
  marginTop: Length.ZERO,
  marginBottom: Length.ZERO,
  paddingTop: Length.ZERO,
  paddingBottom: Length.ZERO,

  // Grid containers
  gridTemplateColumns: { trackSizes: [] },
  gridTemplateRows: { trackSizes: [] },
  columnGap: Length.ZERO,
  rowGap: Length.ZERO,
  gridColumnStart: undefined,
  gridRowStart: undefined,

  // Push Graph containers
  pushGraphNodes: [],
  pushGraphEdges: [],
  pushNodeId: undefined,

  // Text
  text: '',
  fontSize: 16,
  fontStyle: 'normal',
  // TODO(toby): Does this always resolve to 'default`?
  fontFamily: getFontFamily('@primary'),
  fontWeight: 400,
  multiline: true,
  autoWidth: false,
  maxLength: undefined,

  fixedCenterHorizontalStyles: undefined,
  scalesCenterHorizontalStyles: undefined,
}

const getGlobalDefaultStyleRule = <K extends StyleKey>(
  key: K
): [K, StyleRule<K>] => [key, new StyleRule(key, GLOBAL_DEFAULT_STYLES[key])]
const populateGlobalDefaultStyles = () => {
  const keys = Object.keys(GLOBAL_DEFAULT_STYLES) as StyleKey[]
  const rules = keys.map(getGlobalDefaultStyleRule)

  return Object.fromEntries(rules)
}

const GLOBAL_DEFAULT_STYLES_RULES = populateGlobalDefaultStyles()

export default class ObjectNode<T extends ObjectType> {
  private parent: ObjectNode<ObjectType> | undefined
  private readonly children: ObjectNode<ObjectType>[] = []
  private readonly styles: StyleRule<StyleKey>[] = []

  /**
   * Individual node types can override specific default styles where they should differ from the global defaults.
   */
  protected readonly defaultStyles: Partial<StyleMap> = {}

  constructor(public readonly id: string, public readonly type: T) {}

  public getParent(): ObjectNode<ObjectType> | undefined {
    return this.parent
  }

  public setParent(parent: ObjectNode<ObjectType> | undefined): void {
    this.parent = parent
  }

  public getChildren(): ObjectNode<ObjectType>[] {
    return [...this.children]
  }

  public isVisible({ device }: LayoutContext): boolean {
    const display = this.getStyle('display', device)

    return display !== Display.None
  }

  public isInFlow(context: LayoutContext): boolean {
    if (!this.isVisible(context)) {
      return false
    }

    const { device } = context
    const position = this.getStyle('position', device)

    return position === Position.Static
  }

  public getInFlowChildren(context: LayoutContext): ObjectNode<ObjectType>[] {
    return this.getChildren().filter(child => child.isInFlow(context))
  }

  public addChild(obj: ObjectNode<ObjectType>): void {
    const objParent = obj.getParent()
    if (objParent !== undefined) {
      objParent.removeChild(obj)
    }

    obj.setParent(this)
    this.children.push(obj)
  }

  public removeChild(obj: ObjectNode<ObjectType>): void {
    const idx = this.children.indexOf(obj)
    if (idx >= 0) {
      this.children.splice(idx, 1)
    }

    if (obj.getParent() === this) {
      obj.setParent(undefined)
    }
  }

  public getStyleRules(): StyleRuleCollection {
    return [...this.styles]
  }

  public addStyleRule<K extends StyleKey>(
    key: StyleKey,
    value: StyleValue<K>,
    device: DeviceType | undefined
  ): void {
    const condition = device && { device }
    const rule = new StyleRule(key, value, condition)

    this.styles.push(rule)
  }

  public removeStyleRuleForDevice(key: StyleKey, device: DeviceType): void {
    const index = this.styles.findIndex(
      rule => rule.key === key && rule.condition?.device === device
    )
    if (index >= 0) {
      this.styles.splice(index, 1)
    }
  }

  private getDefaultStyle<K extends StyleKey>(key: K): StyleRule<K> {
    const nodeDefault = this.defaultStyles[key]
    if (nodeDefault !== undefined) {
      return new StyleRule(key, nodeDefault, undefined)
    }

    return GLOBAL_DEFAULT_STYLES_RULES[key] as StyleRule<K>
  }

  public getUsedStyleRule<K extends StyleKey>(
    key: K,
    device: DeviceType | undefined
  ): StyleRule<K> {
    // The default rule has the lowest precedence.
    let bestMatch: StyleRule<K> = this.getDefaultStyle(key)
    for (const rule of this.styles) {
      const keyMatches = rule.key === key
      if (!keyMatches) continue

      const score = rule.score(device)
      const conditionSatisfied = score >= 0
      if (!conditionSatisfied) continue
      // Multiple rules can have the same score. When there's a tie for highest score, the last rule always wins.
      const beatsBest = score >= bestMatch.score(device)

      if (beatsBest) {
        bestMatch = rule as StyleRule<K>
      }
    }

    return bestMatch
  }

  // Simple helper to reduce boilderplate
  public getStyle<K extends StyleKey>(
    key: K,
    device: DeviceType | undefined
  ): StyleValue<K> {
    const rule = this.getUsedStyleRule(key, device)

    return rule.value
  }

  private calcPadding({ device, containerWidth }: LayoutContext): {
    paddingTop: number
    paddingBottom: number
  } {
    const topLen = this.getStyle('paddingTop', device)
    const bottomLen = this.getStyle('paddingBottom', device)

    // Treating relative (ie, %) vertical values as relative to the parent
    // width is surprising but intentional.
    //
    // Basically, the parent height is unknowable at this point so there's no
    // way to calculate a % relative to it. Instead, we treat % as relative to
    // the width. This is what CSS does so it seems easiest to just mimic that.
    //
    // Note that at time of writing we don't support any relative values along
    // the vertical dimension so this isn't used - it's only included for the
    // sake of completeness.
    const paddingTop = topLen.toExact(containerWidth)
    const paddingBottom = bottomLen.toExact(containerWidth)

    return { paddingTop, paddingBottom }
  }

  private calcHorizontalLayout(context: LayoutContext): {
    x: number
    width: number
    left?: number
    right?: number
  } {
    const {
      device,
      viewingDevice,
      containerOffsetX,
      containerWidth,
      instruction,
    } = context

    // The position and size of the object depends on the combination of left, right, and width styles that are set.
    // Any two of the three can be set and the third will be calculated. However, setting all three is called "over-
    // constraining" (a term borrowed from CSS) and one value, 'right', will be ignored.
    const getHorizontalPositioning = ({
      leftStyle,
      rightStyle,
      widthStyle,
      marginLeft,
      marginRight,
    }: {
      leftStyle: StyleValue<'left'> | undefined
      rightStyle: StyleValue<'right'> | undefined
      widthStyle: StyleValue<'width'> | undefined
      marginLeft: StyleValue<'marginLeft'>
      marginRight: StyleValue<'marginRight'>
    }): {
      x: number
      width: number
    } => {
      let width
      if (widthStyle === undefined) {
        // If no width is set then we need to calculate it. There are effectively two options:
        // 1. If both left and right are set, use those. The width is whatever container space is left over.
        // 2. If either left or right is not set, then the width is zero.
        if (leftStyle === undefined || rightStyle === undefined) {
          // TODO(toby): Containers should default to 100%. Would it be simpler if all objects did? This edge case shouldn't occur in practice.
          width = 0
        } else {
          // Calculate width from left and right. Basically, the parent width minus each side will leave this object's width.
          // In this case, both left and right are effectively required so if either style is not present, consider it zero.
          // Note this calculation does not consider margins. Those adjustments are applied later.
          const left = leftStyle.toExact(containerWidth)
          const right = rightStyle.toExact(containerWidth)

          width = containerWidth - (left + right)
        }
      } else {
        width = widthStyle.toExact(containerWidth)
      }

      let offsetX
      if (leftStyle !== undefined) {
        // Whenever 'left' is set, use that. It doesn't matter if 'right' is set.
        // There are only two cases where 'right' will also be set. Either it was already used to calculate width - in
        // which case the values will all work out - or the style is over-constrained (see above for definition) and right
        // should be ignored.
        let left = leftStyle.toExact(containerWidth)

        // Adjust the left offset by the value of marginLeft.
        left += marginLeft.toExact(containerWidth)

        offsetX = left
      } else if (rightStyle !== undefined) {
        let right = rightStyle.toExact(containerWidth)

        // adjust the right offset by the value of marginRight. Positive values push the object to the left (ie, increase the value of 'right').
        right += marginRight.toExact(containerWidth)

        offsetX = containerWidth - (width + right)
      } else {
        // If neither left nor right are set, default to left=0 behaviour.
        offsetX = 0 + marginLeft.toExact(containerWidth)
      }

      return {
        width,
        x: containerOffsetX + offsetX,
      }
    }

    const main = {
      left: this.getStyle('left', device),
      right: this.getStyle('right', device),
      width: this.getStyle('width', device),
      marginLeft: this.getStyle('marginLeft', device),
      marginRight: this.getStyle('marginRight', device),
    }

    const { width, x: calculatedX } = getHorizontalPositioning({
      leftStyle: main.left,
      rightStyle: main.right,
      widthStyle: main.width,
      marginLeft: main.marginLeft,
      marginRight: main.marginRight,
    })

    const minWidthStyle = this.getStyle('minWidth', device)
    const maxWidthStyle = this.getStyle('maxWidth', device)

    // Default to Number.NEGATIVE_INFINITY and Number.POSITIVE_INFINITY
    // respectively to maintain current behaviour, where no min/max is set
    const minWidth =
      typeof minWidthStyle === 'undefined'
        ? Number.NEGATIVE_INFINITY
        : minWidthStyle.toExact(containerWidth)
    const maxWidth =
      typeof maxWidthStyle === 'undefined'
        ? Number.POSITIVE_INFINITY
        : maxWidthStyle.toExact(containerWidth)

    if (
      (width < minWidth || width > maxWidth) &&
      VALID_MINMAX_INSTRUCTION_SOURCES.has(instruction)
    ) {
      const notViewingDevice =
        device !== viewingDevice &&
        device !== undefined &&
        viewingDevice !== undefined
      if (notViewingDevice) {
        const scalesCenterStyles = this.getStyle('scalesCenterHorizontalStyles', device) // prettier-ignore

        if (scalesCenterStyles) {
          // APPLY SCALES CENTER STYLES
          const { width: newWidth, x: newX } = getHorizontalPositioning({
            leftStyle: scalesCenterStyles.left,
            rightStyle: scalesCenterStyles.right,
            widthStyle: undefined,
            marginLeft: Length.ZERO,
            marginRight: Length.ZERO,
          })

          return {
            width: newWidth,
            x: newX,
          }
        }
      }

      const fixedCenterStyles = this.getStyle('fixedCenterHorizontalStyles', device) // prettier-ignore

      if (fixedCenterStyles) {
        const whichWidth = Math.max(minWidth, Math.min(maxWidth, width))

        // APPLY FIXED CENTER STYLES
        const { width: newWidth, x: newX } = getHorizontalPositioning({
          leftStyle: fixedCenterStyles.left,
          rightStyle: fixedCenterStyles.right,
          widthStyle: Length.fromPixels(whichWidth),
          marginLeft: Length.fromPixels(-whichWidth / 2),
          marginRight: fixedCenterStyles.marginRight,
        })

        if (instruction === 'resizeElement' || instruction === 'moveElement') {
          return {
            width: newWidth,
            x: newX,
            ...(typeof main.left !== 'undefined' && {
              left:
                main.left.unit === LengthUnit.Percent
                  ? newX / containerWidth
                  : newX,
            }),
            ...(typeof main.right !== 'undefined' && {
              right:
                main.right.unit === LengthUnit.Percent
                  ? (containerWidth - (newX + newWidth)) / containerWidth
                  : containerWidth - (newX + newWidth),
            }),
          }
        }

        return {
          width: newWidth,
          x: newX,
        }
      }
    }

    return {
      width,
      x: calculatedX,
      ...(typeof main.left !== 'undefined' && {
        left:
          main.left.unit === LengthUnit.Percent
            ? main.left.value
            : main.left.toExact(containerWidth),
      }),
      ...(typeof main.right !== 'undefined' && {
        right:
          main.right.unit === LengthUnit.Percent
            ? main.right.value
            : main.right.toExact(containerWidth),
      }),
    }
  }

  private layoutInFlowContent(context: LayoutContext): ContentLayoutResult {
    const { device } = context
    const display = this.getStyle('display', device)

    switch (display) {
      case Display.Flow: {
        return layoutFlowContent(this, context)
      }
      case Display.Grid: {
        return layoutGridContent(this, context)
      }
      case Display.PushGraph: {
        return layoutPushGraphContent(this, context)
      }

      case Display.None: {
        // Indicates an error somewhere. Why are we trying to layout this content?
        throw new Error(`Tried to layout content of a hidden element`)
      }
      default:
        throw new Error(`Unknown 'display' style value: ${display as string}`)
    }
  }

  private layoutOutOfFlowContent(
    context: LayoutContext
  ): LayoutObject<ObjectType>[] {
    const layoutChildren: LayoutObject<ObjectType>[] = []

    layoutChildren.push(...layoutAbsoluteContent(this, context))
    layoutChildren.push(...layoutFixedContent(this, context))

    return layoutChildren
  }

  protected layoutContent(context: LayoutContext): ContentLayoutResult {
    const updatedContext: LayoutContext = {
      device: context.device,
      containerHeight: context.containerHeight,
      containerWidth: context.containerWidth,
      containerOffsetX: context.containerOffsetX,
      containerOffsetY: context.containerOffsetY,
      viewportWidth: context.viewportWidth,
      viewportHeight: context.viewportHeight,
      instruction: context.instruction,
      viewingDevice: context.viewingDevice,
      subtreeRootObject: context.subtreeRootObject,
    }

    // TODO (michael-adalo): this may not be needed anymore
    if (this.id === updatedContext.subtreeRootObject) {
      updatedContext.instruction = undefined
    }

    const { children: inFlowChildren, contentHeight } = this.layoutInFlowContent(updatedContext) // prettier-ignore
    const outOfFlowChildren = this.layoutOutOfFlowContent(updatedContext)
    const allVisibleChildren = [...inFlowChildren, ...outOfFlowChildren]

    return {
      children: allVisibleChildren,
      contentHeight,
    }
  }

  private getStylesForPositioning(context: LayoutContext): {
    marginTop: number
    top: number | undefined
    bottom: number | undefined
  } {
    const { device, containerHeight } = context

    const position = this.getUsedStyleRule('position', device)

    if (
      device !== undefined &&
      position.condition?.device === device &&
      position.value === 'fixed'
    ) {
      const top = this.getUsedStyleRule('top', device)
      const bottom = this.getUsedStyleRule('bottom', device)
      const marginTop = this.getUsedStyleRule('marginTop', device)

      const isFixedTop =
        top.condition?.device === device &&
        marginTop.condition?.device !== device &&
        bottom.condition?.device !== device

      const isFixedBottom =
        bottom.condition?.device === device &&
        marginTop.condition?.device !== device &&
        top.condition?.device !== device

      if (isFixedTop) {
        return {
          marginTop: 0,
          top: top.value?.toExact(containerHeight),
          bottom: undefined,
        }
      }

      if (isFixedBottom) {
        return {
          marginTop: 0,
          top: undefined,
          bottom: bottom.value?.toExact(containerHeight),
        }
      }

      return {
        marginTop: marginTop.value.toExact(containerHeight),
        top: top.value?.toExact(containerHeight),
        bottom: undefined,
      }
    }

    return {
      marginTop: this.getStyle('marginTop', device).toExact(containerHeight),
      top: this.getStyle('top', device)?.toExact(containerHeight),
      bottom: this.getStyle('bottom', device)?.toExact(containerHeight),
    }
  }

  public layout(context: LayoutContext): LayoutObject<T> {
    const {
      device,
      containerOffsetY,
      containerHeight,
      viewportWidth,
      viewportHeight,
      instruction,
      viewingDevice,
      subtreeRootObject,
    } = context

    let { x, width, left, right } = this.calcHorizontalLayout(context)

    /* Prevent width from ever being exactly 0
     * This is a hack to fix issues with content losing positioning info
     */
    if (width === 0) {
      width = NON_ZERO_WIDTH
    }

    // Note vertical values will resolve to zero if they are relative percentages and containerHeight is undefined.
    const marginBottom = this.getStyle('marginBottom', device) //
      .toExact(containerHeight)
    const minHeight = this.getStyle('minHeight', device) //
      .toExact(containerHeight)

    const { marginTop, top, bottom } = this.getStylesForPositioning(context)
    const { paddingTop, paddingBottom } = this.calcPadding(context)

    // Calculate content area
    const contentWidth = width
    const contentOffsetX = x
    // TODO(toby): contentHeight should be set on context when there is an explicit `height` style on the container.

    let borderBoxTop = containerOffsetY + marginTop

    let contentAreaContext: LayoutContext = {
      device,
      containerHeight: undefined,
      containerWidth: contentWidth,
      containerOffsetX: contentOffsetX,
      containerOffsetY: borderBoxTop + paddingTop,
      viewportWidth,
      viewportHeight,
      instruction,
      viewingDevice,
      subtreeRootObject,
    }

    // TODO(toby): Allow height of an element to be calculated when both `top` and `bottom` are defined. (Eg, for a
    //    sidebar that spans the full height of the screen)
    if (top !== undefined) {
      borderBoxTop += top
      contentAreaContext = {
        ...contentAreaContext,
        containerOffsetY: borderBoxTop + paddingTop,
      }
    } else if (bottom !== undefined && containerHeight !== undefined) {
      const containerBottom = containerOffsetY + containerHeight

      // Layout content to determine height
      const { contentHeight } = this.layoutContent(contentAreaContext)

      const heightFromPadding = paddingTop + contentHeight + paddingBottom
      // TODO(toby): Keep original height if content is scrollable.
      const height = Math.max(minHeight, heightFromPadding)

      const borderBoxBottom = containerBottom - (bottom + marginBottom)

      borderBoxTop = borderBoxBottom - height
      contentAreaContext = {
        ...contentAreaContext,
        containerOffsetY: borderBoxTop + paddingTop,
      }
    }

    const { children: layoutChildren, contentHeight } =
      this.layoutContent(contentAreaContext)

    const heightFromPadding = paddingTop + contentHeight + paddingBottom
    // TODO(toby): Keep original height if content is scrollable.
    const height = Math.max(minHeight, heightFromPadding)

    const layoutResult = {
      id: this.id,
      type: this.type,
      children: layoutChildren,
      width,
      height,
      x,
      y: borderBoxTop,
      left,
      right,
    }

    return layoutResult
  }

  public visit<R>(visitor: ObjectTreeVisitor<R>): R {
    return visitor.visitObjectNode(this)
  }
}
