/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

// TODO (michael-adalo): this is a temporary solution to get the Userflow SDK working, revisit the linter rules

import { User } from 'ducks/users'
import userflow, { EventAttributes, TrackOptions } from 'userflow.js'

const USERFLOW_TOKEN = process.env['REACT_APP_USERFLOW_TOKEN'] as string

let isSDKEnabled = false

export const USERFLOW_EVENTS = {
  LIST_CONNECTED: 'list_connected',
  LINK_ACTION_CREATED: 'link_action_created',
  MAGIC_TEXT_ADDED: 'magic_text_added',
  PREVIEWER_OPENED: 'previewer_opened',
  DATABASE_MAGIC_ADDED: 'database_magic_added',
  DATABASE_COLLECTION_ADDED: 'database_collection_added',
  DATABASE_PROPERTY_ADDED: 'database_property_added',
  DATABASE_RECORD_ADDED: 'database_record_added',
  FEATURE_TEMPLATE_ADDED: 'feature_template_added',
}

const validEvents = new Set(Object.values(USERFLOW_EVENTS))

export const initializeUserflow = async (user: User): Promise<void> => {
  if (!user || !USERFLOW_TOKEN) {
    isSDKEnabled = false

    return
  }

  isSDKEnabled = true

  userflow.setInferenceAttributeNames([
    'data-adalo-id',
    'for',
    'id',
    'name',
    'placeholder',
    'role',
  ])

  userflow.init(USERFLOW_TOKEN)

  await userflow.identify(String(user.id), {
    name: user.name,
    email: user.email,
    signed_up_at: user.createdAt,
  })
}

const trackUserflowEvent = async (
  event: string,
  attributes?: EventAttributes,
  opts?: TrackOptions
): Promise<void> => {
  if (!isSDKEnabled) {
    return
  }

  if (!validEvents.has(event)) {
    throw new Error(`Invalid event: ${event}`)
  }

  await userflow.track(event, attributes, opts)
}

const evaluateUserflowEvent = async (
  event: string,
  appId: string,
  data: {
    object: any
    oldObject: any
    [key: string]: any
  }
): Promise<void> => {
  if (!isSDKEnabled) {
    return
  }

  if (!validEvents.has(event)) {
    throw new Error(`Invalid event: ${event}`)
  }

  switch (event) {
    case USERFLOW_EVENTS.LIST_CONNECTED:
      // TODO (michael-adalo): this will only work for library components, e.g. Simple List
      // it will need more work to support custom lists
      if (
        typeof data.object?.attributes?.items === 'object' &&
        typeof data.oldObject?.attributes?.items === 'undefined'
      ) {
        await trackUserflowEvent(event, { appId })
      }

      break
    case USERFLOW_EVENTS.LINK_ACTION_CREATED:
      if (
        typeof data.object?.actions === 'object' &&
        typeof data.oldObject?.actions === 'object' &&
        Object.keys(data.object?.actions).length === 1 &&
        Object.keys(data.oldObject?.actions).length === 0 &&
        (Object.values(data.object?.actions) as any)[0].actions[0]
          .actionType === 'navigate'
      ) {
        await trackUserflowEvent(event, { appId })
      }

      break
    case USERFLOW_EVENTS.MAGIC_TEXT_ADDED:
      // TODO (michael-adalo): implement

      break
    case USERFLOW_EVENTS.PREVIEWER_OPENED:
      // TODO (michael-adalo): implement

      break
    case USERFLOW_EVENTS.DATABASE_MAGIC_ADDED:
      // TODO (michael-adalo): implement

      break
    case USERFLOW_EVENTS.DATABASE_COLLECTION_ADDED:
      // TODO (michael-adalo): implement

      break
    case USERFLOW_EVENTS.DATABASE_PROPERTY_ADDED:
      // TODO (michael-adalo): implement

      break
    case USERFLOW_EVENTS.DATABASE_RECORD_ADDED:
      // TODO (michael-adalo): implement

      break
    case USERFLOW_EVENTS.FEATURE_TEMPLATE_ADDED:
      // TODO (michael-adalo): implement

      break
    default:
      break
  }
}

export const evaluateUserflowEvents = async (
  events: string[],
  appId: string,
  data: {
    object: any
    oldObject: any
    [key: string]: any
  }
): Promise<void> => {
  if (!isSDKEnabled) {
    return
  }

  await Promise.all(
    events.map(event => evaluateUserflowEvent(event, appId, data))
  )
}
