import React from 'react'
import { useDispatch } from 'react-redux'

import { deleteObject } from 'ducks/editor/objects'

import EmptyState from '../../../Shared/EmptyState'
import Button from '../../../Shared/Button'

const ComponentError = ({ library, appId, object }) => {
  const dispatch = useDispatch()

  const deleteComponent = () => {
    dispatch(deleteObject(object.id))
  }

  if (library && !library.licensed) {
    if (library.price) {
      return (
        <div className="library-inspect-error">
          <h2>
            This is a premium component. Purchase it from the marketplace to
            start using it in your app!
          </h2>
          <Button teal large to={`/apps/${appId}/marketplace/${library.id}`}>
            View in Marketplace
          </Button>
        </div>
      )
    } else {
      return (
        <div className="library-inspect-error">
          <h2>Your team does not have access to this private component.</h2>
          <Button red large onClick={deleteComponent}>
            Delete Component
          </Button>
        </div>
      )
    }
  }

  return (
    <EmptyState>
      <h2>We’re having trouble loading this component!</h2>
    </EmptyState>
  )
}

export default ComponentError
