import { RefObject } from 'react'

export const MIN_HEADER_HEIGHT = 50
export const MIN_ROW_HEIGHT = 55
export const MIN_PAGINATION_HEIGHT = 40
export const ANIMATION_TIMING = 0.5 // seconds

export const getSuitableElementHeight = (
  ref: RefObject<HTMLDivElement>,
  defaultValue: number
): number => {
  if (ref.current) {
    return Math.max(defaultValue, ref.current.clientHeight)
  }

  return defaultValue
}
