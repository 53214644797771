import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { dataTypes } from '@adalo/constants'
import { Link } from 'react-router-dom'

import { requestData } from 'utils/io'
import { getPrimaryField } from 'utils/includes'

import {
  getAssociationObject,
  addAssociation,
  removeAssociation,
} from 'ducks/datasources'
import { getDatasource, getTableIds } from 'ducks/apps/datasources'

import { getFieldIcon } from 'utils/icons'

import WrappedTextarea from 'components/Shared/Forms/WrappedTextarea'
import ImageInput from 'components/Shared/Forms/ImageInput'
import FileInput from 'components/Shared/Forms/FileInput'
import WrappedDatePicker from 'components/Shared/Forms/WrappedDatePicker'
import GooglePlacesInput from 'components/Shared/Forms/GooglePlacesInput'
import Icon from 'components/Shared/Icon'
import WrappedTextInput from './WrappedTextInput'

import AssociationList from './AssociationList'
import FormToggle from './FormToggle'
import ReferenceList from './ReferenceList'

const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

class FormRow extends Component {
  handleAddAssociation = (id2, obj) => {
    const { appId, datasourceId, tableId, id, fieldId, field, addAssociation } =
      this.props

    const tableId2 = field.type.tableId

    const options = {
      appId,
      datasourceId,
      tableId,
      id,
      tableId2,
      id2,
      fieldId,
    }

    addAssociation(options, obj)
  }

  handleRemoveAssociation = id2 => {
    const {
      appId,
      datasourceId,
      tableId,
      id,
      fieldId,
      field,
      removeAssociation,
    } = this.props

    const tableId2 = field.type.tableId

    const options = {
      appId,
      datasourceId,
      tableId,
      id,
      tableId2,
      id2,
      fieldId,
    }

    removeAssociation(options)
  }

  componentDidMount() {
    const { appId, datasourceId, tableId, id, fieldId, field } = this.props

    if (!field) {
      return
    }

    if (field.type.type === 'belongsTo') {
      requestData({
        appId,
        datasourceId,
        tableId: field.type.tableId,
        skipDBAssistantInitialization: true,
      })
    } else if (id && field.type.type === 'manyToMany') {
      requestData({
        appId,
        datasourceId,
        tableId,
        id,
        fieldId,
        skipDBAssistantInitialization: true,
      })
    }
  }

  renderField() {
    const {
      associationData,
      appId,
      datasource,
      datasourceId,
      fieldId,
      field,
      id,
      object,
    } = this.props

    if (field.type.type) {
      const table = datasource.tables[field.type.tableId]
      const primaryField = getPrimaryField(table)

      if (field.type.type.includes('manyToMany')) {
        return (
          <AssociationList
            field={field}
            associationData={associationData}
            datasource={datasource}
            tableId={field.type.tableId}
            appId={appId}
            id={id}
            datasourceId={datasourceId}
            onAddItem={this.handleAddAssociation}
            onRemoveItem={this.handleRemoveAssociation}
            fieldId={fieldId}
            primaryField={primaryField}
            object={object}
          />
        )
      }

      if (field.type.type === 'hasMany') {
        const { tables } = this.props
        const { tableId, fieldId } = field.reciprocal
        const reciprocalTable = tables.find(table => table.id === tableId)
        const reciprocalField = reciprocalTable.fields[fieldId] || field

        const reciprocalURL = `/apps/${appId}/data/${datasourceId}/${tableId}`

        return (
          <>
            <AssociationList
              field={field}
              associationData={associationData}
              datasource={datasource}
              tableId={field.type.tableId}
              appId={appId}
              id={id}
              datasourceId={datasourceId}
              onAddItem={this.handleAddAssociation}
              onRemoveItem={this.handleRemoveAssociation}
              fieldId={fieldId}
              primaryField={primaryField}
              object={object}
              isDisabled
            />
            <br />
            <p>
              You can update <strong>{field.name}</strong> in the{' '}
              <strong>{reciprocalField.name}</strong> field, which is located in{' '}
              <strong>
                <Link to={reciprocalURL}>
                  {reciprocalTable.name} collection
                </Link>
              </strong>
            </p>
          </>
        )
      }

      if (field.type.type === 'belongsTo') {
        return (
          <Field
            name={fieldId}
            component={ReferenceList}
            appId={appId}
            datasource={datasource}
            datasourceId={datasourceId}
            field={field}
            fieldId={fieldId}
            id={id}
            tableId={field.type.tableId}
            primaryField={primaryField}
            object={object}
          />
        )
      }
    }

    switch (field.type) {
      case dataTypes.IMAGE:
        return <Field name={fieldId} component={ImageInput} height={64} />
      case dataTypes.FILE:
        return <Field name={fieldId} component={FileInput} />
      case dataTypes.DATE:
        return (
          <Field
            className="data-object-form-input-input"
            name={fieldId}
            component={WrappedDatePicker}
            showTimeInput
          />
        )
      case dataTypes.DATE_ONLY:
        return (
          <Field
            component={WrappedDatePicker}
            name={fieldId}
            className="data-object-form-input-input"
          />
        )
      case dataTypes.BOOLEAN:
        return <Field component={FormToggle} type="checkbox" name={fieldId} />
      case dataTypes.LOCATION:
        return (
          <Field component={GooglePlacesInput} name={fieldId} appId={appId} />
        )
    }

    const type = field.type === 'password' ? 'password' : 'text'

    const autoComplete =
      isChrome && field.type === 'password' ? 'new-password' : 'off'

    const classes =
      field.type === 'password'
        ? 'data-object-form-input-input data-object-form-input-shorter'
        : 'data-object-form-input-input'

    return (
      <Field
        autoComplete={autoComplete}
        name={fieldId}
        component={
          field.type === 'password' ? WrappedTextInput : WrappedTextarea
        }
        className={classes}
        type={type}
      />
    )
  }

  renderFieldLabel = () => {
    const { field } = this.props
    const { name, type } = field
    const icon = getFieldIcon(type)
    if (!name) return 'Untitled'
    if (!icon) return name

    return (
      <>
        <Icon type={icon} /> {name}
      </>
    )
  }

  render() {
    const { fieldId, field, id } = this.props

    if (!field) {
      return null
    }

    if (field.type.type === 'manyToMany' && !id) {
      return null
    }

    return (
      <div key={fieldId} className="data-object-form-row">
        <label htmlFor={fieldId} className="data-object-form-label">
          {this.renderFieldLabel()}
        </label>
        <div className="data-object-form-input">{this.renderField()}</div>
      </div>
    )
  }
}

const mapStateToProps = (
  state,
  { appId, datasourceId, field, fieldId, tableId }
) => {
  const datasource = getDatasource(state, appId, datasourceId)
  const datasources = state.datasources

  let associationData

  if (field && field.type.type === 'manyToMany') {
    const table = datasource && datasource.tables[field.type.tableId]
    const primaryField = getPrimaryField(table)

    associationData = getAssociationObject(
      state,
      tableId,
      fieldId,
      primaryField
    )
  }

  return {
    associationData,
    datasource,
    datasources,
    tables: getTableIds(state, appId, datasourceId),
  }
}

const mapDispatchToProps = {
  addAssociation,
  removeAssociation,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormRow)
