import React from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import { getUpgraded } from 'ducks/apps'
import { showUpgradeModal } from 'ducks/trialWarning'

import SettingsFormField, {
  SettingsFormSubmit,
} from 'components/Shared/Forms/SettingsFormField'
import ToggleField from 'components/Shared/Forms/ToggleField'
import {
  isMaliciousHTML,
  MALICIOUS_ERROR_MESSAGE,
} from '../../../utils/validation'

import OrgSelect from './OrgSelect'

const validate = values => {
  const errors = {}

  if (isMaliciousHTML(values.name)) {
    errors.name = MALICIOUS_ERROR_MESSAGE
  }

  if (isMaliciousHTML(values.description)) {
    errors.description = MALICIOUS_ERROR_MESSAGE
  }

  if (isMaliciousHTML(values.mixpanelToken)) {
    errors.mixpanelToken = MALICIOUS_ERROR_MESSAGE
  }

  if (values.mixpanelToken && !values.mixpanelToken.match(/^\w{32}$/)) {
    errors.mixpanelToken =
      'This doesn’t look quite right. It should be the 32-character Token copied mixpanel.'
  }

  return errors
}

const AppSettingsForm = props => {
  const {
    appId,
    handleSubmit,
    dirty,
    submitting,
    upgraded,
    showBranding,
    showUpgradeModal,
  } = props

  const renderUpgrade = () => {
    const handleShowModal = e => {
      e.preventDefault()

      return showUpgradeModal('upgrade')
    }

    return (
      <>
        To turn off this banner,{' '}
        <a alt="open upgrade modal" onClick={handleShowModal}>
          Upgrade Now!
        </a>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Field
        component={SettingsFormField}
        name="name"
        label="App Name"
        placeholder="Untitled App"
        type="text"
      />
      <Field
        component={SettingsFormField}
        name="description"
        label="Short Description (80 Characters Max)"
        type="text"
        tooltip="This will be used on the public share page for mobile apps. You'll also need this as a short description for submitting to Apple & Google."
        maxLength="80"
      />
      <Field
        isAsset
        appId={appId}
        component={SettingsFormField}
        name="icon"
        label="App Icon (1024px × 1024px)"
        type="image"
        maxPixelCount={1024}
      />
      <Field
        component={SettingsFormField}
        name="mixpanelToken"
        label="Mixpanel Token"
        placeholder="0yqDWsGkBF0r69g8mRz3VuApSXjnzWam"
        type="text"
        spellCheck="false"
      />
      <Field component={OrgSelect} appId={appId} name="OrganizationId" />
      <Field
        component={ToggleField}
        name="showBranding"
        label="Share Page Adalo Banner"
        helpText={!upgraded ? renderUpgrade() : ''}
        inputValue={
          showBranding
            ? "I'd like to spread the Adalo love"
            : 'Adalo Banner Turned Off'
        }
        disabled={!upgraded}
      />
      <SettingsFormSubmit enabled={dirty && !submitting} title="Update App" />
    </form>
  )
}

const mapStateToProps = (state, { appId }) => {
  const upgraded = getUpgraded(state, appId)

  const selector = formValueSelector('AppSettings')
  const showBranding = selector(state, 'showBranding')

  return {
    upgraded,
    showBranding,
  }
}

const connected = connect(mapStateToProps, { showUpgradeModal })(
  AppSettingsForm
)

export default reduxForm({
  form: 'AppSettings',
  validate,
  enableReinitialize: true,
})(connected)
