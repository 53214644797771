import React from 'react'
import classNames from 'classnames'

import './Legend.scss'
import './AppActionsCharts.scss'

export const LegendIcon = props => {
  const { color, borderColor, shape, className } = props

  return (
    <div
      className={classNames(
        'legend-icon',
        { [`legend-icon-${shape}`]: shape },
        className
      )}
      style={{ backgroundColor: color, borderColor }}
    />
  )
}

const LegendItem = props => {
  const { color, borderColor, shape, label } = props

  return (
    <div className="legend-item">
      <LegendIcon color={color} borderColor={borderColor} shape={shape} />
      <span className="legend-label">{label}</span>
    </div>
  )
}

const Legend = props => {
  const { className, payload } = props

  if (!payload) return null

  return (
    <div className={classNames(className, 'legend-container')}>
      {payload.map(({ color, borderColor, label, iconShape }) => (
        <LegendItem
          key={`legend-item-${label}`}
          color={color}
          borderColor={borderColor}
          shape={iconShape}
          label={label}
        />
      ))}
    </div>
  )
}

export default Legend
