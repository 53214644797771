import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { getCursorClass } from 'utils/tools'
import { getOffset, getTransform } from 'utils/zoom'
import { getYoga } from 'utils/yoga'

import {
  getLaunchComponentId,
  getAuthComponentId,
  getAppBranding,
  getAppDataLoaded,
} from 'ducks/apps'
import {
  setZoom,
  getZoom,
  selectObjects,
  getSelectedIds,
  getLoading,
  getPanning,
} from 'ducks/editor/objects'
import { getActiveTool } from 'ducks/editor/tools'
import { getDragging, drag, endDrag } from 'ducks/editor/positioning'
import { getEditingShape } from 'ducks/editor/shapeEditing'
import { getShowConnections } from 'ducks/settings'

import KeyboardEvents from './KeyboardEvents'
import CanvasBackdrop from './Backdrop'
import TextEditor from './TextEditor'
import BoundingBoxes from './BoundingBoxes'
import HoverSelection from './HoverSelection'
import CanvasLoader from './Loader'
import Screen from './Screen'
import CreateObject from './CreateObject'
import ShapeEditor from './ShapeEditor'
import EmptyCanvas from './Empty'
import LaunchComponent from './LaunchComponent'
import SnapGrid from './SnapGrid'
import Positioning from './Positioning'
import Panning from './Panning'
import Links from './Links'

import './Canvas.css'

class Canvas extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeZoom: null,
      yogaReady: false,
    }

    this.canvasRef = React.createRef()
  }

  static childContextTypes = {
    editable: PropTypes.bool,
    getSelection: PropTypes.func,
  }

  getChildContext() {
    return {
      editable: true,
      getSelection: this.getSelection,
    }
  }

  getSelection = () => {
    const { selection } = this.props

    return selection
  }

  setZoom = (scale, offset) => {
    window.clearTimeout(this._zoomTimeout)
    this._zoomTimeout = window.setTimeout(this.saveZoom, 100)

    const activeZoom = { scale, offset }
    this.setState({ activeZoom })
  }

  saveZoom = () => {
    const { setZoom } = this.props
    const { activeZoom } = this.state

    if (!activeZoom) {
      return
    }

    setZoom(activeZoom.scale, activeZoom.offset)
    this.setState({ activeZoom: null })
  }

  getZoom = () => {
    const { zoom } = this.props
    const { activeZoom } = this.state

    return activeZoom || zoom
  }

  handleWheel = e => {
    e.stopPropagation()
    e.preventDefault()

    const { objects } = this.props
    const zoom = this.getZoom()

    if (objects.length === 0) {
      return
    }

    const prevScale = zoom.scale
    const prevOffset = zoom.offset

    const [offsetX, offsetY] = prevOffset

    // ZOOM
    if (e.ctrlKey || e.metaKey || e.scale) {
      let scale

      if (e.scale) {
        scale = prevScale * (e.scale / this._prevEventScale)
        this._prevEventScale = e.scale
      } else {
        scale = prevScale * (1 + 0.01 * -e.deltaY)
      }

      const center = [e.clientX, e.clientY]

      if (scale > 64) {
        scale = 64
      } else if (scale < 1 / 16) {
        scale = 1 / 16
      }

      const offset = getOffset(scale, center, prevScale, prevOffset)

      return this.setZoom(scale, offset)
    }

    // PAN
    const newOffset = [offsetX - e.deltaX, offsetY - e.deltaY]

    this.setZoom(zoom.scale, newOffset)
  }

  handlePanning = e => {
    e.stopPropagation()
    e.preventDefault()

    const { objects } = this.props
    const zoom = this.getZoom()

    if (objects.length === 0) return null

    const prevOffset = zoom.offset
    const [offsetX, offsetY] = prevOffset
    const { movementX, movementY } = e

    const newOffset = [offsetX + movementX, offsetY + movementY]

    return this.setZoom(zoom.scale, newOffset)
  }

  handleGestureStart = e => {
    e.preventDefault()

    this._prevEventScale = 1
  }

  componentDidMount() {
    document.addEventListener('gesturestart', this.handleGestureStart)
    document.addEventListener('gesturechange', this.handleWheel)

    this.canvasRef.current.addEventListener('wheel', this.handleWheel, {
      passive: false,
    })

    getYoga().then(() => this.setState({ yogaReady: true }))
  }

  componentWillUnmount() {
    document.removeEventListener('gesturestart', this.handleGestureStart)
    document.removeEventListener('gesturechange', this.handleWheel)
    this.canvasRef.current.removeEventListener('wheel', this.handleWheel)
  }

  renderContent = () => {
    let {
      activeTool,
      positioningObjects,
      loading,
      objects,
      editingShape,
      authComponentId,
      branding,
      drag,
      endDrag,
      launchComponentId,
      isPanning,
      showConnections,
      selection,
      magicLayout,
      isLoaded,
    } = this.props

    const { activeZoom, yogaReady } = this.state

    const zoom = this.getZoom()

    authComponentId = authComponentId || launchComponentId

    if (!yogaReady || (loading && objects.length === 0)) {
      return <CanvasLoader />
    }

    const screenContainerTransform = getTransform(zoom)
    const screenContainerStyles = {
      transform: screenContainerTransform,
      transformOrigin: 'left top',
      position: 'fixed',
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      pointerEvents: 'none',
    }

    return (
      <>
        {isLoaded === true && <LaunchComponent zoom={zoom} />}
        {positioningObjects ? (
          <Positioning drag={drag} endDrag={endDrag} />
        ) : null}
        <KeyboardEvents />
        <CanvasBackdrop />
        {showConnections && (
          <div style={screenContainerStyles}>
            <svg className="canvas-links">
              <Links selection={selection} zoomScale={zoom.scale} />
            </svg>
          </div>
        )}
        {isPanning ? (
          <Panning
            objects={objects}
            zoom={zoom}
            setZoom={this.setZoom}
            handlePanning={this.handlePanning}
          />
        ) : null}
        {objects.length === 0 ? <EmptyCanvas /> : null}

        <div id="screen-container" style={screenContainerStyles}>
          {objects.map(obj => (
            <Screen
              isLaunchComponent={launchComponentId === obj.id}
              isAuthComponent={authComponentId === obj.id}
              key={obj.id}
              component={obj}
              zoom={zoom}
              zoomActive={!!activeZoom}
              branding={branding}
              magicLayout={magicLayout}
            />
          ))}
        </div>

        {activeZoom ? null : (
          <>
            <svg className="bounding-boxes" width="100%" height="100%">
              {/* Ordering matters here, build up layers from bottom to top */}
              <BoundingBoxes
                magicLayout={magicLayout}
                positioningObjects={positioningObjects}
              />
              <SnapGrid />
              <HoverSelection magicLayout={magicLayout} />
              {activeTool && <CreateObject tool={activeTool} zoom={zoom} />}
              {!activeTool && editingShape && <ShapeEditor zoom={zoom} />}
            </svg>
            <TextEditor branding={branding} />
          </>
        )}
      </>
    )
  }

  render() {
    const { activeTool } = this.props
    const cursorClass = getCursorClass(activeTool)

    return (
      <div>
        <div className={classNames('canvas', cursorClass)} ref={this.canvasRef}>
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, { appId }) => ({
  branding: getAppBranding(state, appId),
  positioningObjects: getDragging(state),
  editingShape: getEditingShape(state),
  activeTool: getActiveTool(state),
  objects: selectObjects(state),
  selection: getSelectedIds(state),
  zoom: getZoom(state),
  loading: getLoading(state),
  launchComponentId: getLaunchComponentId(state, appId),
  authComponentId: getAuthComponentId(state, appId),
  isPanning: getPanning(state),
  showConnections: getShowConnections(state),
  isLoaded: getAppDataLoaded(state, appId),
})

export default connect(mapStateToProps, {
  setZoom,
  drag,
  endDrag,
})(Canvas)
