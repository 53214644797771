import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import {
  getAppUsers,
  requestAppUsers,
  addAppUser,
  removeAppUser,
} from '../../../../ducks/appUsers'
import {
  getOrganizations,
  fetchOrganizations,
  deleteInvite,
  createInvite,
} from '../../../../ducks/organizations'
import { getApp } from '../../../../ducks/apps'
import EmptyState from '../../../Shared/EmptyState'
import Loading from '../../../Shared/Loading'
import UserItem from './UserItem'
import InviteItem from './InviteItem'
import AddForm, { FORM_NAME } from './AddForm'
import './AppUsers.css'

class AppUsers extends Component {
  componentDidMount() {
    const { requestAppUsers, appId } = this.props
    requestAppUsers(appId)
    this.fetchData()
  }

  getOrganization = () => {
    const { app, organizations } = this.props

    for (let i = 0; i < organizations.length; i += 1) {
      if (app?.Organization?.id === organizations?.[i]?.id) {
        return organizations[i]
      }
    }
  }

  handleRemove = userId => {
    const { users, appId, removeAppUser } = this.props
    const user = users.filter(u => u.UserId === userId)[0]

    if (window.confirm(`Are you sure you want to remove ${user.User.name}?`)) {
      removeAppUser(appId, userId)
    }
  }

  handleAdd = value => {
    if (value === 'invite') {
      this.handleInvite()

      return
    }

    const { appId, addAppUser, resetForm } = this.props
    addAppUser(appId, value)
    resetForm(FORM_NAME)
  }

  getInviteLink = () => {
    const { appId } = this.props

    return `/apps/${appId}/screens/invite`
  }

  handleInvite = () => {
    const { history } = this.props
    const path = this.getInviteLink()
    history.push(path)
  }

  fetchData = () => {
    const { fetchOrganizations } = this.props
    fetchOrganizations()
  }

  handleDeleteInvite = inviteId => async () => {
    const { deleteInvite } = this.props

    if (!window.confirm(`Are you sure you want to remove this invitation?`)) {
      return
    }

    await deleteInvite(inviteId)

    // Re-fetch the data
    this.fetchData()
  }

  userIsAdmin = organization => {
    const currentUserId = organization?.OrganizationUser?.UserId

    if (!currentUserId) return false

    const members = organization.Users
    const user = members.filter(u => u.id === currentUserId)[0]

    return user?.OrganizationUser?.isAdmin
  }

  renderUsers = organization => {
    const { users } = this.props

    if (users.length === 0) {
      return (
        <EmptyState>
          <Loading />
        </EmptyState>
      )
    } else {
      const isOrgAdmin = this.userIsAdmin(organization)

      return users.map(user => (
        <UserItem
          key={user.id}
          appUser={user}
          onRemove={this.handleRemove}
          isOrgAdmin={isOrgAdmin}
        />
      ))
    }
  }

  renderInvitedUsers = organization => {
    const { appId } = this.props

    if (!organization || typeof organization === 'undefined') {
      return null
    }

    const isAdminUser = this.userIsAdmin(organization)

    const invites = organization?.Invitations?.filter(
      inv => inv.appId === appId
    )

    if (invites.length) {
      return invites.map(invite => (
        <InviteItem
          isInvite
          key={invite.id}
          email={invite.email}
          onDelete={isAdminUser ? this.handleDeleteInvite(invite.id) : null}
          isAdminUser={isAdminUser}
        />
      ))
    }
  }

  renderAddForm = organization => {
    const { users } = this.props

    if (!organization || typeof organization === 'undefined') {
      return null
    }

    const isOrgAdmin = this.userIsAdmin(organization)

    return (
      <AddForm
        onSubmit={this.handleAdd}
        organization={organization}
        users={users}
        isOrgAdmin={isOrgAdmin}
      />
    )
  }

  render() {
    const org = this.getOrganization()

    return (
      <div className="app-settings-section app-settings-users">
        <h2>App Access</h2>
        <div className="app-settings-users-list">
          {this.renderUsers(org)}
          {this.renderInvitedUsers(org)}
        </div>
        {this.renderAddForm(org)}
      </div>
    )
  }
}

const mapStateToProps = (state, { appId }) => {
  return {
    users: getAppUsers(state, appId),
    app: getApp(state, appId),
    organizations: getOrganizations(state),
    appId,
  }
}

export default connect(mapStateToProps, {
  requestAppUsers,
  addAppUser,
  removeAppUser,
  createInvite,
  deleteInvite,
  resetForm: reset,
  fetchOrganizations,
})(AppUsers)
