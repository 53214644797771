import calculatePushGraphs from 'ducks/editor/pushing/calculatePushGraphs'
import { EditorObject } from 'utils/responsiveTypes'
import { ObjectList } from '../../types/ObjectList'
import { ObjectPathMap } from '../../types/ObjectPathMap'
import InstructionState from '../../types/InstructionState'

const defaultFeatureFlags = {
  hasAutoCustomLayout: false,
  // ... other flags in the future
}

export const buildMap = (
  objects: ObjectList,
  prefix: string[] = []
): ObjectPathMap => {
  let result: ObjectPathMap = {}
  for (const [key, object] of Object.entries(objects)) {
    const { id } = object

    const pathArray = [...prefix, key]
    result[id] = pathArray.join('.')

    if (object.children !== undefined) {
      const childMap = buildMap(object.children, pathArray)
      result = { ...result, ...childMap }
    }
  }

  return result
}

export const buildState = (
  screenObject: EditorObject,
  featureFlags = defaultFeatureFlags
): InstructionState => {
  const pathMap = buildMap([screenObject])
  const list = calculatePushGraphs([screenObject], pathMap, screenObject.id)

  return { list, pathMap, selection: [], featureFlags }
}
