import React from 'react'
import { reduxForm, Field } from 'redux-form'

import { useFlags } from 'flags'
import { THEMES } from 'constants.js'

import { getFieldLabel } from 'utils/tables'
import { getDataType, getComparisonDataType } from 'utils/dataTypes'
import { getComparatorOptions } from 'utils/comparators'

import ComparatorOptions from 'components/Shared/Filters/ComparatorOptions'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'

const FilterForm = ({
  appId,
  componentId,
  object,
  filter,
  fieldOptions,
  reference,
  table,
  getLabel,
}) => {
  const { hasExposedRecordId } = useFlags()

  const getColumnLabel = val => {
    if (typeof val === 'string') {
      return getFieldLabel(table, val, hasExposedRecordId)
    }

    return getLabel(val)
  }

  const dataType = getDataType(filter, table, hasExposedRecordId)
  const comparisonDataType = getComparisonDataType(
    filter,
    table,
    hasExposedRecordId
  )
  const comparator = filter.comparator

  return (
    <FilterFormBody
      appId={appId}
      componentId={componentId}
      object={object}
      filter={filter}
      fieldOptions={fieldOptions}
      reference={reference}
      getColumnLabel={getColumnLabel}
      dataType={dataType}
      comparator={comparator}
      comparisonDataType={comparisonDataType}
    />
  )
}

export const ConnectedFilterForm = reduxForm({
  destroyOnUnmount: false,
})(FilterForm)

const FilterFormBody = ({
  appId,
  componentId,
  object,
  filter,
  fieldOptions,
  reference,
  getColumnLabel,
  dataType,
  comparator,
  comparisonDataType,
}) => {
  return (
    <div className="filter-options">
      <div className="action-item-split-row">
        <Field
          name="fieldId"
          component={WrappedSelect}
          options={fieldOptions}
          placeholder="Select column..."
          getLabel={getColumnLabel}
          rowHeight={40}
          boxed
          menuTheme={THEMES.DATA}
        />
      </div>
      {filter.fieldId && dataType && (
        <>
          <div className="action-item-split-row">
            <Field
              autoSelect
              name="comparator"
              component={WrappedSelect}
              options={getComparatorOptions(dataType)}
              placeholder="Select operator..."
              rowHeight={40}
              boxed
              menuTheme={THEMES.DATA}
            />
          </div>
          {comparator && (
            <ComparatorOptions
              appId={appId}
              componentId={componentId}
              objectId={object.id}
              comparator={comparator}
              comparatorOptions={filter.comparatorOptions}
              comparisonDataType={comparisonDataType}
              comparison={filter.comparison}
              comparison2={filter.comparison2}
              reference={reference}
              boxed
              menuTheme={THEMES.DATA}
            />
          )}
        </>
      )}
    </div>
  )
}
