import { arrayMoveImmutable } from 'array-move'
import { getFieldIcon } from 'utils/icons'
import { Submenu } from 'ducks/recommender'
import type {
  Datasource,
  TableFields,
  TableField,
} from 'components/Editor/XanoExternalDatabaseModal/lib/user'
import {
  EditorObject,
  TableAttributes,
  TableField as TableFieldType,
} from 'utils/responsiveTypes'
import { getTableFields } from './utils'
import type { RelatedField, TableEditorObject } from './types'
import { makeTableField } from './bindings'

const fieldIsBelongsTo = (field: TableField) =>
  typeof field?.type === 'object' && field.type.type === 'belongsTo'

const getRelationshipFields = (
  object: EditorObject,
  datasource: Datasource,
  tableField: TableField,
  tableFieldId: string
) => {
  if (typeof tableField.type !== 'object') {
    throw new Error(`table field type is not an object`)
  }

  const relatedTableId = tableField.type.tableId
  const relatedTable = datasource.tables[relatedTableId]
  if (!relatedTable) {
    throw new Error(`related table not found for table id: ${relatedTableId}`)
  }

  const relatedTableFields = getTableFields(datasource, relatedTableId)

  const fields = []
  for (const [fieldId, field] of Object.entries(relatedTableFields)) {
    // exclude fields that have already been added
    const alreadyAdded =
      object.fields?.findIndex(
        f =>
          f.binding.source.fieldId === fieldId &&
          f.binding.source.source?.tableId === relatedTableId
      ) !== -1
    if (alreadyAdded) {
      continue
    }

    // exclude relationship fields on the related table
    // because we're only allowing 1 level deep!
    if (typeof field.type === 'object') {
      continue
    }

    fields.push({
      value: {
        field: tableFieldId,
        related: {
          field: fieldId,
          name: field.name,
          type: field.type,
          table: tableField.type.tableId,
        },
      },
      label: field.name,
      icon: getFieldIcon(field?.type),
    })
  }

  return new Submenu(tableField?.name, fields, getFieldIcon(tableField?.type))
}

type MenuFields = {
  label: string
  value: { field: string }
  icon: string | null
}

export const getFields = (
  object: EditorObject,
  datasource: Datasource,
  tableFields?: TableFields
): void | (Submenu | MenuFields)[] => {
  if (!tableFields) {
    throw new Error(`tableFields is required`)
  }

  const tableId = object.dataBinding?.source?.tableId
  if (!tableId) {
    throw new Error(`object dataBinding source has no tableId`)
  }

  if (!datasource?.tables) {
    return
  }

  const filteredFields = Object.keys(tableFields).filter(tableFieldId => {
    const tableField = tableFields[tableFieldId]
    if (!tableField) {
      throw new Error(`table field not found for field id: ${tableFieldId}`)
    }

    // allow all belongsTo fields through, we'll filter them out later
    if (fieldIsBelongsTo(tableField)) {
      return true
    }

    // exclude top-level fields that have already been added
    const alreadyAdded =
      object.fields?.findIndex(field => field.fieldId === tableFieldId) === -1

    return alreadyAdded
  })

  return filteredFields.map(tableFieldId => {
    const tableField = tableFields[tableFieldId]
    if (!tableField) {
      throw new Error(`table field not found for field id: ${tableFieldId}`)
    }

    const icon = getFieldIcon(tableField?.type)

    if (fieldIsBelongsTo(tableField)) {
      return getRelationshipFields(object, datasource, tableField, tableFieldId)
    }

    return {
      label: tableField.name,
      value: {
        field: tableFieldId,
      },
      icon,
    }
  })
}

export const getTableFieldsFromDatasource = (
  datasource: Datasource,
  object: TableEditorObject
): TableFields =>
  getTableFields(datasource, object.dataBinding?.source.tableId ?? '')

export const sortFields = (
  object: TableEditorObject,
  fromIndex: number,
  toIndex: number
): TableAttributes['fields'] =>
  arrayMoveImmutable(object.fields, fromIndex, toIndex)

export const deleteField = (
  object: TableEditorObject,
  index: number
): TableAttributes['fields'] => object.fields.filter((_, i) => i !== index)

export const addField = (
  datasource: Datasource,
  object: TableEditorObject,
  fieldId: string,
  fieldRelation?: RelatedField
): TableAttributes['fields'] => [
  ...object.fields,
  makeTableField(
    object,
    getTableFieldsFromDatasource(datasource, object),
    fieldId,
    fieldRelation
  ),
]

export const updateField = (
  object: TableEditorObject,
  updatedField: TableFieldType,
  fieldIndex: number
): TableAttributes['fields'] =>
  object.fields.map((field, index) => {
    if (index === fieldIndex) {
      return {
        ...field,
        ...updatedField,
      }
    }

    return field
  })
