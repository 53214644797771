import React, { Component } from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import { getLabel } from '../../../../utils/sources'
import { getSources } from '../../../../ducks/recommender'
import SelectableInput from '../../../Shared/Forms/SelectableInput'

class FieldValue extends Component {
  getLabel = source => {
    const { appId, componentId, getLabel } = this.props

    return getLabel(source, appId, componentId)
  }

  render() {
    const { name, options } = this.props

    return (
      <Field
        name={name}
        component={SelectableInput}
        options={options}
        getLabel={this.getLabel}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({
  getLabel: (...args) => getLabel(state, ...args),
  options: getSources(
    state,
    props.appId,
    props.componentId,
    props.objectId,
    props.allowedDataTypes,
    null,
    props.actionId,
    props.reference
  ),
})

export default connect(mapStateToProps)(FieldValue)
