import { singularize, pluralize } from 'utils/strings'

import Icon from 'components/Shared/Icon'
import { RelationshipGraphic } from 'components/Editor/NewRelationshipModal/Form'

type TableDetails = {
  id: string
  tableId: string
  orderedFields: string[]
  name: string
}

type RelationshipType =
  | 'hasMany'
  | 'belongsTo'
  | 'manyToMany'
  | 'manyToManyReverse'

// Setup in ./Content.js
type InitialValue = {
  name: string
  type:
    | string
    | { type: RelationshipType; tableId: string; datasourceId: string }
  disabled: boolean
  enabled: boolean
}

type Props = {
  table: TableDetails
  relationshipTable: TableDetails
  initialValues: InitialValue
}

const RelationshipField = (props: Props): JSX.Element | null => {
  const { table, relationshipTable, initialValues } = props

  if (typeof initialValues.type === 'string') {
    console.warn(
      `Attempted to show RelationshipField for field of type ${initialValues.type}`
    )

    return null
  }

  let relationshipType = initialValues.type.type
  if (relationshipType === 'manyToManyReverse') {
    // manyToManyReverse & manyToMany are displayed the same way
    relationshipType = 'manyToMany'
  }

  let description: string = relationshipTable.name
  let title = 'Relationship Type'

  switch (relationshipType) {
    case 'belongsTo':
      title = `Belongs To Relationship`
      description = `Many ${pluralize(table.name)}, one ${singularize(
        relationshipTable.name
      )}`

      break

    case 'hasMany':
      title = `Has Many Relationship`
      description = `One ${singularize(table.name)}, many ${pluralize(
        relationshipTable.name
      )}`

      break
    case 'manyToMany':
      title = `Many To Many Relationship`
      description = `Many ${pluralize(table.name)}, many ${pluralize(
        relationshipTable.name
      )}`

      break

    default:
  }

  return (
    <div className="edit-collection-relationship-field">
      <div className="relationship-header">
        <p>{title}</p>

        <Icon
          type="help"
          small
          color="darkerGray"
          tooltip={
            <div className="relationship-graphic-background">
              <RelationshipGraphic
                fromTable={table.name}
                toTable={relationshipTable.name}
                type={relationshipType}
              />
            </div>
          }
        />
      </div>
      <div className="relationship-description">
        <p>{description}</p>
      </div>
    </div>
  )
}

export default RelationshipField
