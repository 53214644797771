export const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Can’t be blank'
  }

  if (!values.baseURL) {
    errors.baseURL = 'Can’t be blank'
  } else if (encodeURIComponent(values.baseURL) !== values.baseURL) {
    errors.baseURL = 'Not a valid URL'
  }

  return errors
}
