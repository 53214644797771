import React from 'react'
import { change, Field, getFormValues } from 'redux-form'
import { CardElement } from '@stripe/react-stripe-js'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { marketplaceAxios } from 'utils/io/http/axios'

// ducks
import { getApp } from 'ducks/apps'
import { getCurrentOrganization } from 'ducks/organizations'
import { getSubscription } from 'ducks/billing'

// components
import Button from 'components/Shared/Button'
import SettingsFormField from '../Forms/SettingsFormField'
import WrappedNativeSelect from '../Forms/WrappedNativeSelect'
import ToggleButton from '../Forms/ToggleButton'
import PaymentMethods from './PaymentMethods'

// helpers
import { COUNTRY_CODES } from '../../../constants'

import './PaymentForm.scss'

const countryOptions = COUNTRY_CODES.map(c => ({
  label: c.name,
  value: c.code,
}))

const CARD_ELEMENT_STYLES = {
  base: {
    '::placeholder': {
      color: '#bbb',
    },
  },
}

const getOrg = ({ appId }) => {
  const app = useSelector(state => getApp(state, appId))

  if (app?.Organization) {
    return app.Organization
  }

  return useSelector(state => getCurrentOrganization(state))
}

/**
 * Simple Payment Form
 * @usage initialize reduxForm and withStripePromise HOC with parent component
 * @example
 * const MyComponent = props => {
 *  return (
 *    <div>
 *      <PaymentForm formName={FORM_NAME} />
 *    </div>
 *  )
 * }
 *
 * export default reduxForm({ ... })(withStripePromise(MyComponent))
 */
export const PaymentForm = ({
  formName,
  setHasPaymentMethods,
  includeAddress = false,
  includeSavePaymentToggle = true,
  updateCardFlagTrue,
}) => {
  const dispatch = useDispatch()
  const { appId } = useParams()

  const Organization = getOrg({ appId })

  // get current org subscription billing data from redux
  const subscription = useSelector(state =>
    getSubscription(state, Organization.id)
  )

  // retrieve paymentMethodId from subscription data – could be undefined
  const paymentMethodId = React.useMemo(
    () => subscription?.paymentMethodId,
    [subscription?.paymentMethodId]
  )

  const { useNewCard } =
    useSelector(state => getFormValues(formName)(state)) || {}

  const fetchDefaultCardInfo = () => {
    // Get card information from subscription object and set it as payment method
    const {
      cardholder: name,
      cardNumber: last4,
      cardExpMonth: exp_month,
      cardExpYear: exp_year,
      cardBrand: brand,
      paymentMethodId,
    } = subscription || {}

    const defaultPaymentCard = [
      {
        id: paymentMethodId,
        name,
        card: { last4, exp_month, exp_year, brand },
      },
    ]

    if (paymentMethodId && defaultPaymentCard[0].card.last4) {
      setPaymentMethods(defaultPaymentCard)

      if (setHasPaymentMethods) {
        setHasPaymentMethods(true)
      }
    }
  }

  const fetchPaymentMethods = async () => {
    if (!Organization.id) return null

    // if not custoemrId exists then user will not have any
    // available payment methods to fetch, so set fetched to true
    if (!Organization.stripeCustomerId) setFetchedPaymentMethods(true)

    try {
      const { data } = await marketplaceAxios.get(
        `/api/stripe/customer/payment_methods`,
        {
          headers: {
            'customer-id': Organization.stripeCustomerId,
          },
        }
      )

      if (Array.isArray(data) && data.length > 0) {
        setPaymentMethods(data)

        if (setHasPaymentMethods) {
          setHasPaymentMethods(true)
        }
      } else {
        fetchDefaultCardInfo()
      }
    } catch (err) {
      fetchDefaultCardInfo()
    }

    setFetchedPaymentMethods(true)
  }

  const [fetchedPaymentMethods, setFetchedPaymentMethods] =
    React.useState(false)

  const [paymentMethods, setPaymentMethods] = React.useState([])

  React.useEffect(() => {
    if (Organization.stripeCustomerId && !fetchedPaymentMethods) {
      fetchPaymentMethods()
    } else if (fetchedPaymentMethods && paymentMethods.length === 0) {
      dispatch(change(formName, 'useNewCard', true))
    } else {
      setFetchedPaymentMethods(true)
    }
  }, [
    Organization.stripeCustomerId,
    paymentMethods,
    fetchedPaymentMethods,
    useNewCard,
  ])

  const renderCards = () => {
    return (
      <>
        <Field
          name="paymentMethodId"
          component={PaymentMethods}
          paymentMethods={paymentMethods}
          defaultPaymentMethod={paymentMethodId}
          required={!useNewCard}
        />

        {fetchedPaymentMethods ? (
          <Field
            name="useNewCard"
            component={({ input }) => {
              return (
                <Button text gray small onClick={() => toggleUseNewCard(input)}>
                  Use a Different Card
                </Button>
              )
            }}
          />
        ) : null}
      </>
    )
  }

  const toggleUseNewCard = input => {
    input.onChange(true)
    if (updateCardFlagTrue) updateCardFlagTrue()
  }

  const handleCardChange = event => {
    if (event.complete) {
      dispatch(change(formName, 'cardComplete', true))
    } else {
      dispatch(change(formName, 'cardComplete', false))
    }
  }

  const renderForm = () => {
    return (
      <>
        <div className="settings-form-row">
          <label>Credit or Debit Card</label>
          <div className="settings-form-input settings-stripe-input">
            <CardElement
              onChange={handleCardChange}
              options={{
                hidePostalCode: true,
                style: CARD_ELEMENT_STYLES,
              }}
            />
          </div>
        </div>

        <Field
          small
          label="Name on Card"
          name="name"
          placeholder="Ada Lovelace"
          component={SettingsFormField}
          required={useNewCard}
        />

        {includeAddress ? (
          <>
            <h2> Billing Details </h2>
            <div className="payment-form-country-input">
              <Field
                small
                displayName="Country of Billing Address"
                name="address_country"
                options={countryOptions}
                component={WrappedNativeSelect}
                label="Country of billing address"
                required={useNewCard}
              />
            </div>

            <Field
              small
              label="Street Address"
              name="address_line1"
              placeholder="911 Washington Ave."
              component={SettingsFormField}
              required={useNewCard}
            />
            <Field
              small
              label="City"
              name="address_city"
              placeholder="Saint Louis"
              component={SettingsFormField}
              required={useNewCard}
            />
            <Field
              small
              label="Postal Code"
              name="address_zip"
              placeholder="63101"
              component={SettingsFormField}
              required={useNewCard}
            />
          </>
        ) : null}
        {includeSavePaymentToggle && (
          <Field
            small
            label="Save Payment Details"
            name="saveNewCard"
            component={ToggleButton}
            required={useNewCard}
          />
        )}
      </>
    )
  }

  return (
    <div className="payment-form">
      <h2> Payment Details </h2>

      {useNewCard ? renderForm() : renderCards()}
    </div>
  )
}

export const PaymentFormValidate = values => {
  const errors = {}

  if (values.useNewCard) {
    if (!values.cardComplete) {
      errors.cardComplete = 'Your card information is incomplete.'
    }

    if (!values.name) {
      errors.name = 'Name is Required'
    }

    if (!values.address_country) {
      errors.address_country = 'Country of Billing Address is Required'
    }

    if (!values.address_line1) {
      errors.address_line1 = 'Street Address is Required'
    }

    if (!values.address_city) {
      errors.address_city = 'City is Required'
    }

    if (!values.address_zip) {
      errors.address_zip = 'Zipcode is Required'
    }
  }

  return errors
}
