import ObjectType from '../../types/ObjectType'
import ContentLayoutResult from '../ContentLayoutResult'
import LayoutContext from '../layout/LayoutContext'
import ObjectNode from '../ObjectNode'
import positionGridItems from './positionGridItems'
import calculateTrackSizes from './calculateTrackSizes'
import measureGridSize from './measureGridSize'
import layoutItems from './layoutItems'

const layoutGridContent = (
  container: ObjectNode<ObjectType>,
  context: LayoutContext
): ContentLayoutResult => {
  const gridTemplate = positionGridItems(container, context)

  // Calculate track sizes
  const { items, numRows, numColumns } = gridTemplate
  const sizedGrid = calculateTrackSizes(
    container,
    items,
    numRows,
    numColumns,
    context
  )

  // Layout items in their resulting Grid Item content blocks.
  const layoutObjects = layoutItems(sizedGrid, context)

  // Calculate the final grid height
  const contentHeight = measureGridSize(sizedGrid.rows)

  return {
    children: layoutObjects,
    contentHeight,
  }
}

export default layoutGridContent
