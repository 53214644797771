import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getDeviceType } from '@adalo/utils'
import { updateObject, getParentScreenComponent } from 'ducks/editor/objects'
import { getAccordionState, openAccordion } from 'ducks/accordions'
import {
  getLayoutSettingsGroup,
  getLayoutSettingsItemId,
  getLayoutSettingsItemIdDevice,
} from 'utils/accordionGroups'
import usesSharedLayout from 'utils/objects/usesSharedLayout'
import VisibilitySection from './VisibilitySection'
import ResponsiveLayoutWrapper from './ResponsiveLayout/ResponsiveLayoutWrapper'
import LayoutControls from './ResponsiveLayout/LayoutControls'
import ResetToShared from './ResetToShared'

const useOpenCurrentLayoutAccordion = object => {
  const dispatch = useDispatch()

  const screen = useSelector(state =>
    getParentScreenComponent(state, object.id)
  )

  const screenDevice = getDeviceType(screen.width)

  const accordionGroup = getLayoutSettingsGroup(object.id)
  const currentAccordionItemId = useSelector(state =>
    getAccordionState(state, accordionGroup)
  )
  const accordionDevice = getLayoutSettingsItemIdDevice(currentAccordionItemId)

  useEffect(() => {
    // If we open the layouts tab and no accordion is open, determine which one should be open for the current screen device
    if (object && !accordionDevice) {
      const shouldOpenShared = usesSharedLayout(object, screenDevice)
      const layoutSettingsDevice = shouldOpenShared ? 'shared' : screenDevice
      const accordionItemId = getLayoutSettingsItemId(
        object.id,
        layoutSettingsDevice
      )

      dispatch(openAccordion(accordionGroup, accordionItemId))
    }
  }, [object.id, screenDevice, accordionDevice, accordionGroup])
}

function LayoutTab(props) {
  const { object, updateObject, componentParent, appId } = props

  useOpenCurrentLayoutAccordion(object)

  return (
    <>
      <div className="magic-layout-options">
        <ResponsiveLayoutWrapper
          object={object}
          updateObject={updateObject}
          componentParent={componentParent}
        >
          <VisibilitySection object={object} updateObject={updateObject} />
          <LayoutControls
            object={object}
            updateObject={updateObject}
            componentParent={componentParent}
          />
        </ResponsiveLayoutWrapper>
      </div>
      <ResetToShared object={object} appId={appId} />
    </>
  )
}

export default connect(null, { updateObject })(LayoutTab)
