import React, { Component } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { adaloBackendAxios } from 'utils/io/http/axios'

const colors = [
  'rgb(168, 32, 88)',
  'rgba(168, 32, 88, 0.60)',
  'rgba(168, 32, 88, 0.40)',
  'rgba(168, 32, 88, 0.20)',
]

class CustomizedLabel extends Component {
  render() {
    const { x, y, cx, percent, name } = this.props
    let platformString = ''

    switch (name) {
      case 'ios':
        platformString = 'iOS'

        break
      case 'android':
        platformString = 'Android'

        break
      case 'web':
        platformString = 'Web'

        break
      case 'web-pwa':
        platformString = 'PWA'

        break
    }

    return (
      <text
        x={x > cx ? x + 2 : x - 2}
        y={y}
        fill="#333"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontWeight={500}
      >
        {`${platformString} ${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
}

export default class DevicesUsed extends Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    await this.getData()
  }

  componentDidUpdate(prevProps) {
    const { start, range } = this.props

    if (start !== prevProps.start || range !== prevProps.range) {
      this.getData()
    }
  }

  getData = async () => {
    let { appId, start, end } = this.props
    const queryType = 'devices'

    start = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    end = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`

    let data = await adaloBackendAxios.post(`/analytics`, {
      appId,
      queryType,
      start,
      end,
    })

    if (data.data.success) {
      data = data.data.data

      if (data.length !== 0) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].users = +data[i].users
        }
      }
    } else {
      data = []
    }

    this.setState({ loading: false, data })
  }

  renderContent = () => {
    const { data } = this.state
    const { width } = this.props

    if (data.length === 0) {
      return (
        <div className="module-empty-state">
          <div className="module-empty-state-content">
            <h2>No Devices Used</h2>
            <span>There isn't any user data for the selected time frame.</span>
          </div>
        </div>
      )
    }

    return (
      <PieChart width={width / 2 - 40} height={240}>
        <Pie
          data={data}
          dataKey="users"
          nameKey="platform"
          outerRadius={80}
          fill="#8884d8"
          label={<CustomizedLabel />}
        >
          {data.map((entry, index) => (
            <Cell fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    )
  }

  render() {
    const { loading } = this.state
    const { width } = this.props

    return (
      <div>
        <div className="module-header-container">
          <h2 className="module-header">Devices Used</h2>
        </div>
        <div
          className="app-analytics-module-container app-analytics-devices-container"
          style={{ width: width / 2 - 40 }}
        >
          {loading ? null : this.renderContent()}
        </div>
      </div>
    )
  }
}
