import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'

import { getDatasourceId, getTableId, getLabel } from 'utils/sources'

import { getContextualObjects } from 'ducks/recommender'

import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import ControlField from 'components/Shared/Forms/ControlField'

import Fields from './Fields'

class UpdateObjectOptions extends Component {
  render() {
    const {
      appId,
      componentId,
      objectId,
      actionId,
      field,
      getLabel,
      objectOptions,
      options,
      reference,
      menuTheme,
    } = this.props

    return (
      <div className="action-item-form-options">
        <div className="form-inspect-field-wrapper">
          <ControlField
            component={MenuControl}
            name={`${field}.options.bindingId`}
            displayName="Record"
            placeholder="Select object..."
            options={objectOptions}
            getLabel={getLabel}
            comparator={deepEqual}
          />
        </div>
        {options.bindingId ? (
          <>
            <hr />
            <Fields
              fields={options.fields || []}
              parentField={field}
              appId={appId}
              componentId={componentId}
              objectId={objectId}
              actionId={actionId}
              datasourceId={getDatasourceId(options.bindingId)}
              tableId={getTableId(options.bindingId)}
              reference={reference}
              placeholder="No Change"
              menuTheme={menuTheme}
            />
          </>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  objectOptions: getContextualObjects(
    state,
    props.appId,
    props.componentId,
    props.objectId,
    undefined,
    props.actionId,
    props.reference
  ),
  getLabel: source => getLabel(state, source, props.appId, props.componentId),
})

export default connect(mapStateToProps)(UpdateObjectOptions)
