import { LAYOUT_SECTION } from '@adalo/constants'
import { adaloBackendAxios } from './http/axios'

export const updateBuilderContentUsage = async (
  type: string,
  prebuiltBuilderContentId: string
): Promise<void> => {
  try {
    let newType = type

    if (type === LAYOUT_SECTION) {
      newType = 'layoutSection'
    }

    await adaloBackendAxios.post(`/builder-content-usages`, {
      type: newType,
      prebuiltBuilderContentId,
    })
  } catch (error) {
    console.error(`Failed to update builder content usage:`, error)
  }
}
