import {
  COMPONENT,
  DeviceType as Device,
  DeviceWidth,
  LIBRARY_COMPONENT,
} from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'

import DeviceType from 'ducks/editor/types/DeviceType'
import { EditorObject } from 'utils/responsiveTypes'
import isVisibleInDevice from 'utils/objects/isVisible'
import getDeviceObject from 'utils/getDeviceObject'

const isSideNav = (obj: EditorObject) =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === '@adalo/navigation' &&
  obj.componentName === 'SideNav'

const isTopNavigation = (obj: EditorObject) =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === '@adalo/navigation' &&
  obj.componentName === 'NavigationBar'

const isAppBar = (obj: EditorObject) =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === '@protonapp/material-components' &&
  obj.componentName === 'AppBar'

const SIDE_NAV_HEIGHT_WHEN_TOP = 67

const getSideNavWidthForSizeOption = (sizeOption: string): number => {
  switch (sizeOption) {
    case 'top':
      // If the size is "top", then the sidebar width will be 100%
      // so it shouldn't impact the size of a Layout Section
      return 0
    case 'small':
      return 90
    case 'medium':
    default:
      return 290
  }
}

const devices = [Device.DESKTOP, Device.TABLET, Device.MOBILE]

const sideNavSizeProps: { [key in DeviceType]: string } = {
  [Device.DESKTOP]: 'onDesktop',
  [Device.TABLET]: 'onTablet',
  [Device.MOBILE]: 'onMobile',
}

const defaultSideNavSizeForDevice: { [key in DeviceType]: number } = {
  [Device.MOBILE]: 0,
  [Device.TABLET]: 90,
  [Device.DESKTOP]: 290,
}

// We only care for the x & width properties when figuring out the layout when a SideNav is present
type DeviceSpecificLayoutProps = { x: number; width: number }

type Result = DeviceSpecificLayoutProps & {
  [Device.DESKTOP]?: DeviceSpecificLayoutProps
  [Device.TABLET]?: DeviceSpecificLayoutProps
  [Device.MOBILE]?: DeviceSpecificLayoutProps
}

const SCREEN_SIZES = {
  [Device.MOBILE]: DeviceWidth.MOBILE_DEFAULT_WIDTH,
  [Device.TABLET]: DeviceWidth.TABLET_DEFAULT_WIDTH,
  [Device.DESKTOP]: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
}

type PartialEditorObject = Partial<EditorObject> &
  Pick<EditorObject, 'width' | 'type'>

/**
 * Returns the changes in layout needed for a LayoutSection to accommodate a SideNav
 */
const getLayoutSectionFitForScreen = (screen: PartialEditorObject): Result => {
  if (screen.type !== COMPONENT) {
    throw new Error(
      'Getting layout section width: Expected screen to be a component.'
    )
  }

  const { width: screenWidth } = screen

  const sideNav = screen.children?.find(isSideNav)

  if (!sideNav) {
    return { width: screenWidth, x: 0 }
  }

  const currentDevice = getDeviceType(screenWidth)

  const currentSideNavSizeAttributeValue = sideNav.attributes?.[
    sideNavSizeProps[currentDevice]
  ] as string | undefined

  const currentSideNavWidth = currentSideNavSizeAttributeValue
    ? getSideNavWidthForSizeOption(currentSideNavSizeAttributeValue)
    : defaultSideNavSizeForDevice[currentDevice]

  const currentSectionWidth = screenWidth - currentSideNavWidth
  const currentSectionX = currentSideNavWidth // section starts when sidenav ends

  const result: Result = { width: currentSectionWidth, x: currentSectionX }

  for (const device of devices) {
    const sideNavSizeAttributeValue = sideNav.attributes?.[
      sideNavSizeProps[device]
    ] as string | undefined

    const sideNavWidth = sideNavSizeAttributeValue
      ? getSideNavWidthForSizeOption(sideNavSizeAttributeValue)
      : defaultSideNavSizeForDevice[device]

    const screenWidthForDevice =
      device === currentDevice ? screenWidth : SCREEN_SIZES[device]

    const sectionWidth = screenWidthForDevice - sideNavWidth

    const sectionX = sideNavWidth
    result[device] = { x: sectionX, width: sectionWidth }
  }

  return result
}

type TopSnap = {
  ySnap: number
  targetObject: EditorObject | undefined
}

export const getTopScreenObjectToSnapTo = (screen: EditorObject): TopSnap => {
  if (screen.type !== COMPONENT) {
    throw new Error(
      'Getting top screen object to snap to: expected screen to be a component'
    )
  }

  const device = getDeviceType(screen.width)

  // Prefer snap to AppBar or TopNavigation over SideNav, since AppBar & TopNavigation are always on top,
  // whilst SideNav can change by screen
  let topSnapTarget = screen.children?.find(
    c => isVisibleInDevice(c, device) && (isAppBar(c) || isTopNavigation(c))
  )
  if (!topSnapTarget) {
    const sideNav = screen.children?.find(
      c => isVisibleInDevice(c, device) && isSideNav(c)
    )

    if (sideNav) {
      const sideNavSizeAttributeValue = sideNav.attributes?.[
        sideNavSizeProps[device]
      ] as string | undefined

      if (sideNavSizeAttributeValue === 'top') {
        // Only snap to SideNav if it's at the top
        topSnapTarget = sideNav
      }
    }
  }

  let ySnap = 0
  if (topSnapTarget) {
    const deviceTopSnapTarget = getDeviceObject(topSnapTarget, device)

    const height = isSideNav(topSnapTarget)
      ? SIDE_NAV_HEIGHT_WHEN_TOP
      : deviceTopSnapTarget.height

    ySnap = deviceTopSnapTarget.y + height
  }

  return { ySnap, targetObject: topSnapTarget }
}

export default getLayoutSectionFitForScreen
