import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ModalActions extends Component {
  render() {
    const { leftButtons, children } = this.props

    return (
      <div className="modal-actions">
        {leftButtons ? (
          <div className="modal-button-group modal-left-buttons">
            {leftButtons}
          </div>
        ) : null}
        <div className="modal-button-group modal-right-buttons">{children}</div>
      </div>
    )
  }
}

ModalActions.propTypes = {
  children: PropTypes.any,
}

export default ModalActions
