import React, { Component } from 'react'
import { Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { dataTypes } from '@adalo/constants'

import { getOptions } from 'utils/dataTypes'
import { getIcon } from 'utils/icons'

import { showModal, NEW_RELATIONSHIP } from 'ducks/editor/modals'

import { FormRow } from 'components/Shared/Forms/FormRow'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Icon, { IconButton } from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import GoogleHelpLink from 'components/Shared/GoogleHelpLink'

import validation from './Validation'
import addNewField from './addNewField'

import { THEMES } from '../../../constants'

class Columns extends Component {
  componentDidUpdate(prevProps) {
    const { fields, formValues } = this.props

    if (formValues === undefined || formValues.length === 0) {
      addNewField(fields, { type: dataTypes.TEXT, name: 'Name' })
    }

    if (
      formValues &&
      prevProps.formValues &&
      prevProps.formValues.length < formValues.length
    ) {
      // after the new field has been added, let's focus it
      const { formValues } = this.props
      const nodeList = document.querySelectorAll('input')
      const inputs = Array.from(nodeList)

      const newInput = inputs.filter(
        i => i.name === `columns[${formValues.length - 1}].name`
      )

      if (newInput) {
        const index = newInput.length - 1
        newInput[index].focus()
        newInput[index].select()
      }
    }
  }

  handleNewField = async (fields, value, tables) => {
    if (typeof value === 'string') {
      await addNewField(fields, value, tables)
    }

    if (typeof value === 'object') {
      const { match, showModal, tableName } = this.props
      const { appId } = match.params

      const opts = {
        appId,
        selectedCollection: value,
        newCollectionName: tableName || 'New Collection',
      }

      const { type } = (await showModal(NEW_RELATIONSHIP, opts)).value
      const relationship = { type, ...value }

      await addNewField(fields, relationship, tables)
    }
  }

  getHelpLink = type => {
    switch (type) {
      case dataTypes.LOCATION:
        return <GoogleHelpLink />
      default:
        return null
    }
  }

  render() {
    const { datasourceId, fields, formValues, tables } = this.props

    const options = getOptions(tables, datasourceId)

    return (
      <>
        {fields.map((field, index) => {
          const icon = getIcon(
            formValues[index].type,
            formValues[index].type.type
          )

          return (
            <FormRow key={field}>
              <Field
                component={WrappedInput}
                name={`${field}.name`}
                placeholder="Property Name"
                autoComplete="off"
                className="column-name"
                validate={[validation]}
                large={false}
                prefix={<Icon type={icon} />}
                suffix={
                  formValues.length > 1 ? (
                    <IconButton
                      type="trash-small"
                      onClick={() => fields.remove(index)}
                    />
                  ) : null
                }
                helpLink={this.getHelpLink(formValues[index].type)}
              />
            </FormRow>
          )
        })}
        <FormRow className="add-column">
          <MultiMenuTrigger
            menu={options}
            onSelect={value => this.handleNewField(fields, value, tables)}
            rowHeight={40}
            menuTheme={THEMES.DATA}
          >
            <Button text type="button">
              <Icon type="plus" />
              <span>Add Property</span>
            </Button>
          </MultiMenuTrigger>
        </FormRow>
      </>
    )
  }
}

const selector = formValueSelector('newTableForm')

const mapStateToProps = state => ({
  formValues: selector(state, 'columns'),
})

const connected = connect(mapStateToProps, { showModal })(Columns)

export default withRouter(connected)
