import React from 'react'
import PropTypes from 'prop-types'

import { getSVGTransform } from 'utils/zoom'
import { normalizeColor } from 'utils/colors'
import { getFontFamily } from 'utils/type'

import BaseShape from '../BaseShapeUnscaled'

export default class FullWidthUpload extends BaseShape {
  static contextTypes = {
    branding: PropTypes.object,
  }

  render() {
    let {
      x,
      y,
      width,
      height,
      color,
      zoom,
      branding,
      borderColor,
      borderWidth,
      borderRadius,
      fontFamily,
    } = this.props

    const fill = normalizeColor(this.getBackgroundColor(), branding)
    const fontSize = 14
    fontFamily = getFontFamily(fontFamily, branding)

    const textStyles = {
      fontSize,
      fontFamily,
      fill: color || '#06f',
    }

    const style = {
      stroke: normalizeColor(borderColor, branding),
      strokeWidth: borderWidth,
    }

    return (
      <g
        transform={getSVGTransform(zoom, x, y)}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
      >
        <rect
          {...this.getBorderProps()}
          x={0}
          y={0}
          rx={borderRadius}
          width={width}
          height={height}
          fill={fill}
          style={style}
        />
        <text
          x={width / 2}
          y={(height + fontSize) / 2}
          textAnchor="middle"
          style={textStyles}
        >
          Choose Photo
        </text>
      </g>
    )
  }
}
