import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { sourceTypes, dataTypes } from '@adalo/constants'

import {
  getLabel,
  getDatasourceId,
  getRootSource,
  getRootSourcePath,
} from '../../../utils/sources'

import { getEndpoint, getEndpointURL } from '../../../utils/apis'
import { getRouteParams } from '../../../utils/routes'
import { getSources } from '../../../ducks/recommender'
import { getDatasource } from '../../../ducks/apps/datasources'
import SelectableInput from '../../Shared/Forms/SelectableInput'

class ParamSelector extends Component {
  getParams() {
    const {
      binding: { source },
      datasource,
    } = this.props

    const rootSource = getRootSource(source)

    if (!rootSource || rootSource.type !== sourceTypes.API_ENDPOINT) {
      return []
    }

    const { collectionId, endpointId } = rootSource
    const collection = datasource.collections[collectionId]
    const endpoint = getEndpoint(collection, endpointId)

    if (!endpoint) {
      return []
    }

    const params = getRouteParams(getEndpointURL(collection, endpoint))

    return params
  }

  getRootSourcePath() {
    const {
      binding: { source },
    } = this.props

    if (!source) {
      return null
    }

    return getRootSourcePath(source, 'source')
  }

  render() {
    const { datasource, options, getLabel } = this.props

    if (!datasource || datasource.type !== 'api') {
      return null
    }

    const params = this.getParams()
    const path = `${this.getRootSourcePath()}.options.params`

    return (
      <div className="data-binding-params">
        {params.map((param, i) => (
          <div key={param} className="action-item-form-row">
            <label>{param}</label>
            <Field
              component={SelectableInput}
              name={`${path}.${param}`}
              options={options}
              getLabel={getLabel}
            />
          </div>
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId, componentId, object, binding } = props
  const { source } = binding

  if (!source) {
    return {}
  }

  const datasourceId = getDatasourceId(binding.source)
  const datasource = getDatasource(state, appId, datasourceId)

  const options = getSources(state, appId, componentId, object.id, [
    dataTypes.TEXT,
    dataTypes.NUMBER,
  ])

  return {
    datasource,
    options,
    getLabel: source => getLabel(state, source, appId, componentId),
  }
}

export default connect(mapStateToProps)(ParamSelector)
