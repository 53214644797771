import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentEvents from 'react-document-events'

import { getApp, updateApp } from '../../ducks/apps'
import { cut, copy, paste } from '../../ducks/editor/clipboard'

class Clipboard extends Component {
  inputActive = () => {
    const activeEl = document.activeElement

    const selection = document.getSelection()
    const textSelected = !selection.isCollapsed

    if (
      activeEl.tagName === 'INPUT' ||
      activeEl.tagName === 'TEXTAREA' ||
      activeEl.contentEditable === 'true' ||
      textSelected
    ) {
      return true
    }

    return false
  }

  handleCopy = e => {
    const { copy } = this.props

    if (!this.inputActive()) {
      copy(e)
    }
  }

  handleCut = e => {
    const { cut } = this.props

    if (!this.inputActive()) {
      cut(e)
    }
  }

  handlePaste = e => {
    const { app, paste, updateApp } = this.props

    if (!this.inputActive()) {
      paste(e, app, updateApp)
    }
  }

  render() {
    return (
      <DocumentEvents
        onCopy={this.handleCopy}
        onCut={this.handleCut}
        onPaste={this.handlePaste}
      />
    )
  }
}

const mapStateToProps = (state, { appId }) => ({
  app: getApp(state, appId),
})

export default connect(mapStateToProps, { cut, copy, paste, updateApp })(
  Clipboard
)
