import React, { useCallback } from 'react'
import classNames from 'classnames'

import { CONDITION } from 'utils/permissions'
import Icon from 'components/Shared/Icon'
import ToggleCheckbox from 'components/Shared/Forms/ToggleCheckbox'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'

import './CollectionAccessSelector.scss'
import Tooltip from 'components/Shared/Tooltip'

// A special condition to indicate access is restricted to certain relationships
const CONDITION_SOME_LOGGED_IN = 'SOME_LOGGED_IN'

const COLLECTION_CONDITIONS = [
  CONDITION.ANYONE,
  CONDITION.LOGGED_IN,
  CONDITION_SOME_LOGGED_IN,
  CONDITION.NONE,
]

// Used in drop-down lists (title casing)
const CONDITION_LABELS = {
  [CONDITION.ANYONE]: 'Everyone',
  [CONDITION.LOGGED_IN]: 'All Logged In Users',
  [CONDITION_SOME_LOGGED_IN]: 'Some Logged In Users',
  [CONDITION.NONE]: 'Nobody',
}

export default function CollectionAccessSelector({
  relationshipPaths,
  currentCondition,
  selectedPaths,
  onChange,
}) {
  const handleConditionChanged = useCallback(
    value => {
      if (value === CONDITION_SOME_LOGGED_IN) {
        onChange(CONDITION.LOGGED_IN, [])
      } else {
        onChange(value, null)
      }
    },
    [onChange]
  )

  const handlePathChecked = useCallback(
    checkedPath => {
      onChange(currentCondition, [...selectedPaths, checkedPath])
    },
    [onChange, currentCondition, selectedPaths]
  )

  const handlePathUnchecked = useCallback(
    uncheckedPath => {
      const updatedPaths = selectedPaths.filter(prev => prev !== uncheckedPath)

      onChange(currentCondition, updatedPaths)
    },
    [onChange, currentCondition, selectedPaths]
  )

  const handleSomeLoggedInCloseClicked = useCallback(() => {
    onChange(CONDITION.LOGGED_IN, null)
  }, [onChange])

  let conditionOptions = COLLECTION_CONDITIONS.map(condition => ({
    value: condition,
    label: CONDITION_LABELS[condition],
  }))

  if (relationshipPaths.length === 0) {
    // Do not show Some Logged In Users if no paths are available
    conditionOptions = conditionOptions.filter(
      ({ value }) => value !== CONDITION_SOME_LOGGED_IN
    )
  }

  const getLabel = value => CONDITION_LABELS[value]

  let content = (
    <WrappedSelect
      options={conditionOptions}
      value={currentCondition}
      label={getLabel}
      placeholder="Select Access"
      onChange={handleConditionChanged}
      rowHeight={44}
    />
  )

  const someLoggedIn = currentCondition === CONDITION.LOGGED_IN && selectedPaths

  if (someLoggedIn) {
    content = (
      <SomeLoggedInUsers
        relationshipPaths={relationshipPaths}
        selectedPaths={selectedPaths}
        onCheckPath={handlePathChecked}
        onUncheckPath={handlePathUnchecked}
        onCloseClick={handleSomeLoggedInCloseClicked}
      />
    )
  }

  return (
    <div
      className={classNames([
        'collection-access-selector',
        {
          expanded: someLoggedIn,
        },
      ])}
    >
      {content}
    </div>
  )
}

function SomeLoggedInUsers({
  relationshipPaths,
  selectedPaths,
  onCheckPath,
  onUncheckPath,
  onCloseClick,
}) {
  const handlePathToggled = path => checked =>
    checked ? onCheckPath(path) : onUncheckPath(path)

  const pathRows = relationshipPaths.map(({ path, readablePath }) => {
    const checked = selectedPaths?.includes(path)

    const ConditionalTooltip = ({ children }) =>
      shouldDisplayTooltip(readablePath) ? (
        <Tooltip tooltip={readablePath} hideArrow placement="bottom-start">
          {children}
        </Tooltip>
      ) : (
        children
      )

    return (
      <div key={path} className="some-logged-in-users__path-row">
        <Icon
          className="some-logged-in-users__path-row-icon"
          type="relationship-many"
        />
        <div className="some-logged-in-users__path-row-label">
          <ConditionalTooltip>
            <p className="some-logged-in-users__path-row-text">
              {readablePath}
            </p>
          </ConditionalTooltip>
        </div>
        <ToggleCheckbox value={checked} onChange={handlePathToggled(path)} />
      </div>
    )
  })

  return (
    <div className="some-logged-in-users">
      <div className="some-logged-in-users__top-bar">
        <p className="some-logged-in-users__label">Some Logged In Users</p>
        <Icon
          className="some-logged-in-users__close-button"
          type="clear "
          onClick={onCloseClick}
        />
      </div>
      <div className="some-logged-in-users__path-rows">{pathRows}</div>
    </div>
  )
}

function shouldDisplayTooltip(text) {
  // The goal is to only show the tooltip when the path text is long and truncated.
  // This is just a rough heuristic.
  return text && text.length > 20
}
