import io from 'socket.io-client'
import { isMakerDeveloper } from './auth'

const devServerURL = process.env.REACT_APP_DEV_SERVER
const baseURL = `${devServerURL}/listener`

let socket = null
let libraries = []
let resolve

const readyPromise = new Promise((_resolve, _reject) => {
  resolve = _resolve
})

export const localLibrariesReady = () => readyPromise

export const librarySocket = sessionToken => {
  if (!sessionToken) {
    return null
  }

  if (!isMakerDeveloper()) {
    return null
  }

  if (!socket) {
    restartDeveloperSocket(sessionToken)
  }

  return socket
}

export const restartDeveloperSocket = sessionToken => {
  if (socket) {
    socket.disconnect()
    socket = null
  }

  if (!isMakerDeveloper()) {
    return resolve()
  }

  socket = io(baseURL, {
    transports: ['websocket', 'polling'],
    query: { sessionToken },
  })

  socket.on('availableLibraries', libs => {
    libraries = libs
    resolve()
  })

  socket.on('connect_error', () => {
    resolve()

    if (process.env.NODE_ENV !== 'production') {
      socket.disconnect()
    }
  })
}

export const getLocalLibraries = () => {
  return libraries || []
}

// Not currently used, as developer mode is *always* enabled
export const getDevModeEnabled = () => {
  return !!window.localStorage.getItem('developerMode')
}

export const toggleDevMode = () => {
  const devMode = getDevModeEnabled()

  if (devMode) {
    window.localStorage.removeItem('developerMode')
  } else {
    window.localStorage.setItem('developerMode', true)
  }

  return !devMode
}
