import React from 'react'

const CUSTOM_LAYOUT_HELP_URL =
  'https://help.adalo.com/design/designing-your-app/using-the-layout-tab/custom-layout-settings'

const CustomLayoutHelp = () => {
  return (
    <p className="responsive-help-text">
      Use Custom Layout Settings to tweak your design on each breakpoint.&nbsp;
      <a
        href={CUSTOM_LAYOUT_HELP_URL}
        target="_blank"
        rel="noreferrer"
        className="responsive-help-link"
      >
        Learn more.
      </a>
    </p>
  )
}

export default CustomLayoutHelp
