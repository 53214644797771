import { memo } from 'react'
import deepEqual from 'deep-equal'

import { isEqual } from 'lodash'
import { SortableContainer, SortableElement } from '@adalo/react-sortable-hoc'
import { dataTypes, FormatKeys, formatterOptions } from '@adalo/constants'

import { THEMES } from 'constants.js'
import { IconButton } from 'components/Shared/Icon'
import { GroupedAccordion } from 'components/Shared/Accordion'
import PrefixSuffix from 'components/Shared/PrefixSuffix'
import ToggleField from 'components/Shared/Forms/ToggleField'
import { TableFields } from 'components/Editor/XanoExternalDatabaseModal/lib/user'

import type { EditorObject, TableField } from 'utils/responsiveTypes'
import SimpleTextControl from '../SimpleTextControl'
import MenuControl from '../MenuControl'

import TableMenu from './TableMenu'
import {
  updateBindingType,
  updateBindingFirstColumn,
  updateBindingPrefixSuffix,
  updateLabel,
  ACCORDION_FIELD_GROUP,
  makeUniqueFieldId,
  getFieldColumnAlignment,
} from './utils'
import {
  COLUMN_ALIGNMENT_OPTIONS,
  COLUMN_WIDTH_OPTIONS,
  ID_FIELD,
} from './consts'
import type { AccordionGroup } from './types'

export type FormatterOptions = keyof typeof formatterOptions

interface FieldItemProps {
  object: EditorObject
  field: TableField
  fieldIndex: number
  onChange: (field: TableField, index: number) => void
  onDelete: (index: number) => void
  tableFields: TableFields
}

const FieldItem = memo((props: FieldItemProps): JSX.Element => {
  const { field, fieldIndex, onChange, onDelete, tableFields, object } = props

  const handleDelete = () => {
    onDelete(fieldIndex)
  }

  const renderAdvanced = () => {
    return (
      <PrefixSuffix
        entity={field.binding}
        onUpdate={(suffix: TableField['binding']) =>
          updateBindingPrefixSuffix(field, suffix, onChange, fieldIndex)
        }
      />
    )
  }

  const renderChildren = () => {
    const bindingFirstColumn = field.binding?.format?.shouldFreezeFirstColumn
    const bindingColumnWidth = field.binding?.format?.columnWidth
    const bindingColumnAlignment = getFieldColumnAlignment(field)

    return (
      <div>
        <SimpleTextControl
          displayName="Column Header Text"
          value={field.label}
          name="label"
          onChange={(currentField: TableField) =>
            updateLabel(field, currentField, onChange, fieldIndex)
          }
        />
        {field.fieldId !== ID_FIELD && (
          <TableMenu
            field={field}
            updateBindingType={option =>
              updateBindingType(field, option, onChange, fieldIndex)
            }
          />
        )}

        <MenuControl
          options={COLUMN_WIDTH_OPTIONS}
          name="columnWidth"
          displayName="Column Width"
          value={bindingColumnWidth}
          onChange={(option: FormatKeys) =>
            updateBindingType(field, option, onChange, fieldIndex)
          }
          comparator={deepEqual}
          placeholder="Standard"
          menuTheme={THEMES.DATA}
        />

        <MenuControl
          options={COLUMN_ALIGNMENT_OPTIONS}
          name="columnAlignment"
          displayName="Alignment"
          value={bindingColumnAlignment}
          onChange={(option: FormatKeys) =>
            updateBindingType(field, option, onChange, fieldIndex)
          }
          comparator={deepEqual}
          placeholder="Center"
          menuTheme={THEMES.DATA}
        />
        {fieldIndex === 0 && (
          <ToggleField
            label="Freeze First Column"
            value={bindingFirstColumn}
            inputValue={bindingFirstColumn ? 'On' : 'Off'}
            onChange={(value: boolean) =>
              updateBindingFirstColumn(field, value, onChange, fieldIndex)
            }
            boxed
            hideLabelOnDisabled
          />
        )}
      </div>
    )
  }

  const renderTitle = () => {
    const collectionField = tableFields[field.fieldId]

    return (
      <div className="form-inspect-field-title">
        <span>{(collectionField && field.label) || '[Deleted Column]'}</span>
        <IconButton type="trash-small" onClick={handleDelete} />
      </div>
    )
  }

  if (!field || !tableFields) {
    throw new Error('Field and tableFields are required')
  }

  const renderAdvancedOptions =
    field.binding.source.dataType === dataTypes.NUMBER &&
    field.fieldId !== ID_FIELD
      ? renderAdvanced
      : undefined

  const accordionItemId = {
    key: object.id,
    subject: makeUniqueFieldId(field),
  } as AccordionGroup

  return (
    <div className="form-inspect-field-wrapper">
      <GroupedAccordion
        boxed
        className="form-inspect-field"
        group={ACCORDION_FIELD_GROUP}
        itemId={JSON.stringify(accordionItemId)}
        title={renderTitle()}
        renderChildren={renderChildren}
        renderAdvanced={renderAdvancedOptions}
      />
    </div>
  )
}, isEqual)

interface FieldItemsListProps {
  object: EditorObject
  items: TableField[]
  tableFields: TableFields
  onChange: (field: TableField, index: number) => void
  onDelete: (index: number) => void
}

const FieldItemsList = memo((props: FieldItemsListProps): JSX.Element => {
  const { object, items, tableFields, onChange, onDelete } = props

  return (
    <div>
      {items.map((field, i) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <SortableFieldItem
          object={object}
          key={makeUniqueFieldId(field)}
          field={field}
          fieldIndex={i}
          index={i}
          onChange={onChange}
          onDelete={onDelete}
          tableFields={tableFields}
        />
      ))}
    </div>
  )
}, isEqual)

const SortableFieldItem = SortableElement(FieldItem)

export const SortableFieldItemsList = SortableContainer(FieldItemsList)
