import React, { useEffect, useMemo, useState } from 'react'

import Stat from 'components/Shared/Stat/index.tsx'
import Icon from 'components/Shared/Icon'

import { getOrganizationBilling } from 'ducks/organizations'

import {
  getActionsSpendingLimit,
  getLimit,
  getTotalActions,
  hasBusinessPlan,
} from 'utils/billing.ts'
import { scrollToHash } from 'utils/dom'

import { generateUsageStats } from './generateStats.ts'

import './UsageStats.scss'

export const generateStatsIcons = ({
  planType,
  pricingPlans,
  spendingLimit = null,
  totalActions,
}) => {
  const icons = {}

  const planLimit = getLimit(planType, pricingPlans, 'actions')
  const actionsLimit = planLimit + spendingLimit
  const limitedSpending = spendingLimit !== null

  const showIcons = limitedSpending

  if (showIcons) {
    if (totalActions >= actionsLimit) {
      icons['app-actions'] = (
        <Icon className="flipped" small color="orange" type="info" />
      )
    } else if (totalActions / actionsLimit >= 0.75) {
      icons['app-actions'] = <Icon small color="yellow" type="warning" />
    }
  }

  return { planLimit, actionsLimit, icons }
}

const UsageStats = props => {
  const [spendingLimit, setSpendingLimit] = useState(null)

  useEffect(() => {
    scrollToHash()
  }, [])

  const { actionsByApp, organization, pricingPlans } = props

  useEffect(() => {
    ;(async () => {
      const billing = await getOrganizationBilling(organization.id)

      const actionsSpendingLimit = getActionsSpendingLimit(
        billing?.spendingLimit,
        billing?.overageChargePlanType
      )

      setSpendingLimit(actionsSpendingLimit)
    })()
  }, [organization, spendingLimit])

  const { planType } = organization

  const totalActions = getTotalActions(actionsByApp)

  const { planLimit, actionsLimit, icons } = useMemo(
    () =>
      generateStatsIcons({
        planType,
        pricingPlans,
        spendingLimit,
        totalActions,
      }),
    [actionsByApp, organization, spendingLimit]
  )

  const { count: pubCount, limit: pubLimit } =
    organization?.addons?.publishedApps || {}

  const testCount = organization?.testAppsCount || 0
  const testLimit = 'unlimited'

  const seatCount =
    organization?.addons?.seats?.count || organization?.Users?.length || 0

  const seatLimit =
    organization?.addons?.seats?.limit ||
    getLimit(planType, pricingPlans, 'seats')

  const seatLimitText = !hasBusinessPlan(planType) ? seatLimit : 'unlimited'

  if (
    Number.isNaN(parseFloat(planLimit)) ||
    Number.isNaN(parseFloat(pubCount)) ||
    Number.isNaN(parseFloat(pubLimit))
  ) {
    return null
  }

  const usageStats = generateUsageStats(
    totalActions,
    actionsLimit,
    pubCount,
    pubLimit,
    testCount,
    testLimit,
    seatCount,
    seatLimitText,
    icons
  )

  return (
    <div className="usage-stats">
      {usageStats.map(statInfo => (
        <Stat key={statInfo.title} {...statInfo} />
      ))}
    </div>
  )
}

export default UsageStats
