import DeviceType from 'ducks/editor/types/DeviceType'
import { EditorObject } from 'utils/responsiveTypes'
import hiddenOnDevice from './hiddenOnDevice'

export default function isVisible(
  object: EditorObject,
  deviceType: DeviceType | undefined
): boolean {
  const hiddenGlobally: boolean =
    object['hidden'] === undefined ? false : (object['hidden'] as boolean)

  return !hiddenGlobally && !hiddenOnDevice(object, deviceType)
}
