import {
  COMPONENT,
  GROUP,
  LAYOUT_SECTION,
  LIST,
  SECTION,
  SHAPE,
} from '@adalo/constants'
import { getDeviceType, getObject, pathLength, subPath } from '@adalo/utils'
import {
  getParentComponent,
  getComponent,
  getObjectList,
  getMap,
} from 'ducks/editor/objects'

import { ObjectList } from 'ducks/editor/types/ObjectList'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import { getParent } from 'ducks/editor/device-layouts/utils'
import { getCategoriesAndTags } from 'ducks/layoutSections/utils'
import { PrebuiltBuilderContentData } from 'ducks/layoutSections/types'
import { defaults } from 'utils/objects'

import { MOST_USED_SECTIONS_CATEGORY_LABEL } from 'components/Editor/AddObject/ResponsiveAddMenu/sectionHelpers'
import getDeviceObject from '../getDeviceObject'
import getSpecificSharedDevices from '../getSpecificSharedDevices'
import usesSharedLayout from '../objects/usesSharedLayout'
import { DeviceValue, EditorObject } from '../responsiveTypes'

export enum LayoutSectionPurpose {
  LAYOUT_HELPER = 'layout_helper',
  CONTAINER = 'container',
}

const sectionElementPurposes = new Set<string>([
  LayoutSectionPurpose.LAYOUT_HELPER,
  LayoutSectionPurpose.CONTAINER,
])

export const isContainerType = [GROUP, SHAPE, LIST, SECTION] as string[]

export type State = {
  list: EditorObject[]
  map: Record<string, string>
}

export type GlobalState = {
  editor: {
    objects: {
      present: {
        list: ObjectList
        map: ObjectPathMap
      }
    }
  }
}

export interface FormattedSection extends PrebuiltBuilderContentData {
  label: string
  image?: string | undefined
}
export interface SectionCategory {
  label: string
  children: FormattedSection[]
}

export const defaultSection: FormattedSection = {
  label: 'Section',
  primaryTags: '',
  secondaryTags: 'Empty,Card,Section',
  body: defaults[LAYOUT_SECTION],
  id: 1,
  name: 'Section',
  type: 'layout-section',
  createdAt: new Date(),
  updatedAt: new Date(),
  categories: MOST_USED_SECTIONS_CATEGORY_LABEL,
}

export const resizeSectionElements = (
  child: EditorObject,
  parentObj: EditorObject,
  diffHeight: number,
  screenDevice: DeviceValue
): EditorObject => {
  const newChild = { ...child }

  // Update device-specific props if we have them
  if (child[screenDevice] && typeof child[screenDevice]?.height === 'number') {
    const childHeight = child[screenDevice]?.height || child.height

    newChild[screenDevice] = {
      ...newChild[screenDevice],
      height: diffHeight + childHeight,
    }
  }

  const updateChildShared = usesSharedLayout(parentObj, screenDevice)

  // Update the shared height and any devices on which the section is using shared layout
  if (updateChildShared) {
    const childSharedDevices = getSpecificSharedDevices(parentObj)

    for (const childSharedDevice of childSharedDevices) {
      if (child[childSharedDevice]?.height) {
        const { height: childHeight } = getDeviceObject(
          child,
          childSharedDevice
        )
        newChild[childSharedDevice] = {
          ...newChild[childSharedDevice],
          height: childHeight + diffHeight,
        }
      }
    }
    newChild.height = child.height + diffHeight
  } else if (!child[screenDevice]?.height) {
    // If the section has custom layout but the layout helper or section doesn't, create some custom props for the current device
    const deviceChild = child[screenDevice] || {}

    newChild[screenDevice] = {
      ...deviceChild,
      height: child.height + diffHeight,
    }
  }

  return newChild
}

export const isSectionElement = (obj: EditorObject): boolean => {
  if (
    obj?.type === SECTION &&
    obj?.purpose &&
    sectionElementPurposes.has(obj?.purpose)
  ) {
    return true
  }

  return false
}

export const getSectionFromSectionElement = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  obj: EditorObject
): EditorObject => {
  if (!isSectionElement(obj)) {
    return obj
  }

  let section = obj

  while (section && isSectionElement(section)) {
    section = getParent(list, pathMap, section.id)

    if (section?.type === LAYOUT_SECTION) {
      return section
    }
  }

  throw new Error(
    'Could not find a section for the section element, check object hierarchy'
  )
}

export const isLayoutHelperSectionElement = (obj: EditorObject): boolean =>
  isSectionElement(obj) && obj?.purpose === LayoutSectionPurpose.LAYOUT_HELPER

export const isContainerSectionElement = (obj: EditorObject): boolean =>
  isSectionElement(obj) && obj?.purpose === LayoutSectionPurpose.CONTAINER

export const isEditableSectionElement = (obj: EditorObject): boolean =>
  isContainerSectionElement(obj) || obj.type === LAYOUT_SECTION

// TODO(dyego): This function can possibly be simplified by removing redundancy
export const shouldReparentComponent = (
  object: EditorObject,
  parentObject: EditorObject
): boolean => {
  if (!object || !parentObject) {
    return true
  }

  if (object.type === LAYOUT_SECTION && parentObject.type !== COMPONENT) {
    return false
  }

  if (isLayoutHelperSectionElement(parentObject)) {
    return false
  }

  if (isLayoutHelperSectionElement(object)) {
    return false
  }

  if (object.type === LAYOUT_SECTION && parentObject.type === LAYOUT_SECTION) {
    return false
  }

  if (object.type === LAYOUT_SECTION && isSectionElement(parentObject)) {
    return false
  }

  return true
}

export const getContainerFromLayoutHelper = (
  layoutHelper: EditorObject
): EditorObject => {
  const { children } = layoutHelper

  if (
    layoutHelper.type !== SECTION ||
    layoutHelper.purpose !== LayoutSectionPurpose.LAYOUT_HELPER ||
    !children
  ) {
    throw new Error('Invalid layout helper')
  }

  for (const child of children) {
    if (
      child.type === SECTION &&
      child.purpose === LayoutSectionPurpose.CONTAINER
    ) {
      return child
    }
  }

  throw new Error('Layout helper has no valid containers')
}

export const getLayoutHelperFromSection = (
  section: EditorObject
): EditorObject => {
  const { children } = section

  if (section.type !== LAYOUT_SECTION || !children) {
    throw new Error('Invalid section component')
  }

  for (const child of children) {
    if (
      child.type === SECTION &&
      child.purpose === LayoutSectionPurpose.LAYOUT_HELPER
    ) {
      return child
    }
  }

  throw new Error('Section component has no valid children')
}

export const getContainerFromSection = (
  section: EditorObject
): EditorObject => {
  const layoutHelper = getLayoutHelperFromSection(section)

  return getContainerFromLayoutHelper(layoutHelper)
}

export const getContainerChildrenFromSection = (
  section: EditorObject
): EditorObject[] | null | undefined => {
  try {
    const container = getContainerFromSection(section)

    return container.children
  } catch {
    return null
  }
}

export const getSectionFromLayoutHelper = (
  state: State,
  path: string
): string | null => {
  const object = getObject(state.list, path) as EditorObject | null

  if (!object) {
    return null
  }

  if (isLayoutHelperSectionElement(object)) {
    const layoutHelperParentPath = subPath(path, pathLength(path) - 1) as string

    const layoutHelperParent = getObject(
      state.list,
      layoutHelperParentPath
    ) as EditorObject | null

    return layoutHelperParent?.id ?? null
  }

  return null
}

export const getSectionFromContainer = (
  list: EditorObject[],
  pathMap: ObjectPathMap,
  object: EditorObject
): EditorObject | null => {
  if (
    !object ||
    object.type !== SECTION ||
    object.purpose !== LayoutSectionPurpose.CONTAINER
  ) {
    return null
  }

  const path = pathMap[object.id]
  const sectionPath = subPath(path, pathLength(path) - 2) as string
  const section = getObject(list, sectionPath) as EditorObject | null

  return section
}

export const isChildOfContainer = (objectId: string, state: State): boolean => {
  const path = state.map[objectId] as string
  const parentPath = subPath(path, pathLength(path) - 1) as string
  const parent = getObject(state.list, parentPath) as EditorObject | null

  if (!parent) {
    return false
  }

  if (isContainerType.includes(parent.type) && !isSectionElement(parent)) {
    return true
  }

  return isChildOfContainer(parent.id, state)
}

export const isDirectChildOfSectionContainer = (
  objectId: string,
  state: State
): boolean => {
  const path = state.map[objectId] as string
  const parentPath = subPath(path, pathLength(path) - 1) as string
  const parent = getObject(state.list, parentPath) as EditorObject | null

  if (!parent) {
    return false
  }

  if (isContainerSectionElement(parent)) {
    return true
  }

  return false
}

export const selectSectionChildNodes = (
  objects: EditorObject[],
  state: GlobalState
): EditorObject[] => {
  let flattened = new Map<string, EditorObject>()

  const component =
    objects.length > 0
      ? (getComponent(state, objects[0]?.id) as EditorObject)
      : null

  const traverseNodes = (object: EditorObject, isChild = false) => {
    // anything that is not a layout helper should be added since layout helper
    // should be invisible to makers.
    if (object?.purpose !== LayoutSectionPurpose.LAYOUT_HELPER) {
      const componentWidth = component?.width ?? 0

      const device = getDeviceType(componentWidth)

      const objectDevice = getDeviceObject(object, device)

      const newNode = isChild
        ? {
            ...object,
            id: object.id,
            x: (objectDevice.x ?? object.x) + (component?.x ?? 0),
            y: (objectDevice.y ?? object.y) + (component?.y ?? 0),
            width: objectDevice.width ?? object.width,
            height: objectDevice.height ?? object.height,
          }
        : object

      flattened.set(newNode.id, newNode)
    }

    // if it's a container we perform an early return
    // because we don't to highlight anything inside of it
    // if we're not actually hovering the container's children.
    if (object?.purpose === LayoutSectionPurpose.CONTAINER) return

    const parent = getParentComponent(state, object.id)

    // if the parent node is container, it means we reached the first
    // child of it, we shouldn't go deeper.
    if (
      parent?.purpose !== LayoutSectionPurpose.CONTAINER &&
      object.children &&
      object.children.length > 0
    ) {
      for (const child of object.children) {
        traverseNodes(child, true)
      }
    } else if (parent?.purpose === LayoutSectionPurpose.CONTAINER) {
      // we need to remove the "teal" background from the container
      // when highlighting it's children
      const newFlattened = new Map<string, EditorObject>()

      for (const node of flattened.values()) {
        const metadata = node?.metadata

        if (metadata) {
          if (
            node?.purpose === LayoutSectionPurpose.CONTAINER &&
            metadata['hasParentNode']
          ) {
            newFlattened.set(node.id, node)

            continue
          }
        }

        if (!metadata && node?.purpose === LayoutSectionPurpose.CONTAINER) {
          continue
        }

        newFlattened.set(node.id, node)
      }

      flattened = newFlattened
    }
  }

  for (const object of objects) {
    const list = getObjectList(state) as EditorObject[]
    const map = getMap(state) as Record<string, string>

    const isContainerChild = isChildOfContainer(object.id, { list, map })

    if (isContainerChild) {
      return Array.from(flattened.values())
    }

    traverseNodes(object)
  }

  return Array.from(flattened.values())
}

export const getContainerFromObject = (
  objectId: string,
  state: State
): EditorObject | null => {
  const path = state.map[objectId] as string

  const parentPath = subPath(path, pathLength(path) - 1) as string
  const parent = getObject(state.list, parentPath) as EditorObject | null

  if (!parent) {
    return null
  }

  if (isContainerType.includes(parent.type) && !isSectionElement(parent)) {
    const grandParent = getParent(state.list, state.map, parent.id)

    // lists contain sections as their children which should not be selected.
    if (parent.type === SECTION && grandParent.type === LIST) {
      return grandParent
    }

    return parent
  }

  return getContainerFromObject(parent.id, state)
}

export const isChildOfSection = (objectId: string, state: State): boolean => {
  const path = state.map[objectId] as string

  const parentPath = subPath(path, pathLength(path) - 1) as string
  const parent = getObject(state.list, parentPath) as EditorObject | null

  if (!parent) {
    return false
  }

  if (
    sectionElementPurposes.has(parent?.purpose ?? '') ||
    parent.type === LAYOUT_SECTION
  ) {
    return true
  }

  return isChildOfSection(parent?.id, state)
}

export const selectSectionChildParentNodes = (
  objects: EditorObject[],
  state: GlobalState
): EditorObject[] => {
  const flattened = new Map<string, EditorObject>()

  const component =
    objects.length > 0
      ? (getComponent(state, objects[0]?.id) as EditorObject)
      : null

  const traverseNodes = (object: EditorObject, isParent = false) => {
    const componentWidth = component?.width ?? 0
    const device = getDeviceType(componentWidth)
    const objectDevice = getDeviceObject(object, device)

    const newNode = isParent
      ? {
          ...object,
          id: object.id,
          x: (objectDevice.x ?? object.x) + (component?.x ?? 0),
          y: (objectDevice.y ?? object.y) + (component?.y ?? 0),
          width: objectDevice.width ?? object.width,
          height: objectDevice.height ?? object.height,
        }
      : object

    if (newNode.type === LAYOUT_SECTION || isSectionElement(newNode)) {
      flattened.set(newNode.id, newNode)
    }

    const newState = {
      list: getObjectList(state) as EditorObject[],
      map: getMap(state) as Record<string, string>,
    } as State

    const isChildSection = isChildOfSection(object.id, newState)

    const parent = getParentComponent(state, object.id)
    if (isChildSection && parent) {
      traverseNodes(parent, true)
    }
  }

  for (const object of objects) {
    traverseNodes(object)
  }

  return Array.from(flattened.values())
}

export const getSectionFromChild = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  child?: EditorObject
): EditorObject | null => {
  if (!child) {
    return null
  }

  if (child.type === LAYOUT_SECTION) {
    return child
  }

  let parent = null
  try {
    parent = getParent(list, pathMap, child.id)
  } catch (e) {
    return null
  }

  return getSectionFromChild(list, pathMap, parent)
}

export const capitalizeWords = (rawWords: string): string => {
  const trimmed = rawWords.trim()
  let words = trimmed.split(' ')

  words = words.map((word: string): string => {
    if (!word[0]) return ''

    return word[0].toUpperCase() + word.substring(1)
  })

  return words.join(' ')
}

export const formatPrimaryTags = (tags?: string): string[] => {
  if (!tags) return []

  const tagArray = tags.split(',')

  return tagArray.map(capitalizeWords)
}

export const categorizeSections = (
  prebuiltLayoutSections: PrebuiltBuilderContentData[],
  mobileOnly: boolean
): Map<string, SectionCategory> => {
  const { categories } = getCategoriesAndTags(prebuiltLayoutSections)
  // Sort categories alphabetically, but make sure 'Most Used Sections' is always first
  const categoryTitles = categories.sort((a, b) => {
    if (a === MOST_USED_SECTIONS_CATEGORY_LABEL) return -1
    if (b === MOST_USED_SECTIONS_CATEGORY_LABEL) return 1

    return a.localeCompare(b)
  })
  const categoriesMap = new Map(
    categoryTitles.map((item: string): [string, SectionCategory] => [
      item,
      { label: item, children: [] },
    ])
  )

  const layoutSections = [...prebuiltLayoutSections, defaultSection]

  for (const section of layoutSections) {
    const formattedSection = {
      label: section.name,
      image: mobileOnly ? section.mobileThumbnail : section.responsiveThumbnail,
      ...section,
    }

    if (section.categories) {
      const sectionCategories = section.categories
        .split(',')
        .map(capitalizeWords)

      for (const categoryName of sectionCategories) {
        const categoryTitle =
          categoryName === 'Most Used'
            ? MOST_USED_SECTIONS_CATEGORY_LABEL
            : categoryName

        const category = categoriesMap.get(categoryTitle)

        if (category) {
          category.children.push(formattedSection)
        }
      }
    }
  }

  return categoriesMap
}

export const getFormattedSectionsWithoutCategory = (
  prebuiltLayoutSections: PrebuiltBuilderContentData[],
  mobileOnly: boolean
): FormattedSection[] => {
  const uncategorizedSections = prebuiltLayoutSections
    .filter(s => !s.categories)
    .map(section => ({
      label: section.name,
      image: mobileOnly ? section.mobileThumbnail : section.responsiveThumbnail,
      ...section,
    }))

  return uncategorizedSections
}
