import {
  PrebuiltBuilderContentData,
  PrebuiltBuilderContentError,
} from 'ducks/layoutSections/types'
import { useCallback, useState } from 'react'
import { getAuthToken } from 'utils/auth'
import { baseURL } from 'utils/io/constants'

const apiUrl = `${baseURL}/prebuilt-builder-contents`

const BUILDER_CONTENT_TYPE = 'layout-section'

export type CreateParams = Partial<
  Omit<PrebuiltBuilderContentData, 'id' | 'type' | 'createdAt' | 'updatedAt'>
>

const getHeaders = () => ({
  'x-proton-auth': getAuthToken() ?? '',
  'Content-Type': 'application/json',
})

type CreateOrUpdateLayoutSectionResponse =
  | PrebuiltBuilderContentError
  | PrebuiltBuilderContentData

type CreateLayoutSectionBuilderContent = (
  values: CreateParams
) => Promise<Partial<PrebuiltBuilderContentData>>

type UseCreateLayoutSectionBuilderContent = () => {
  loading: boolean
  error?: Error | null
  createLayoutSectionBuilderContent: CreateLayoutSectionBuilderContent
}

export const useCreateLayoutSectionBuilderContent: UseCreateLayoutSectionBuilderContent =
  () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const createLayoutSectionBuilderContent: CreateLayoutSectionBuilderContent =
      useCallback(async values => {
        setLoading(true)
        try {
          const body = JSON.stringify({ ...values, type: BUILDER_CONTENT_TYPE })
          const headers = getHeaders()
          const payload = { method: 'POST', headers, body }
          const res = await fetch(apiUrl, payload)
          const data = (await res.json()) as CreateOrUpdateLayoutSectionResponse
          if ('error' in data) {
            throw new Error(data.error)
          }

          return data as PrebuiltBuilderContentData
        } catch (err: unknown) {
          setError(err as Error)
          throw err
        } finally {
          setLoading(false)
        }
      }, [])

    return { createLayoutSectionBuilderContent, loading, error }
  }

type UpdateLayoutSectionBuilderContent = (
  values: CreateParams & { id: number }
) => Promise<void>

type UseUpdateLayoutSectionBuilderContent = () => {
  loading: boolean
  error?: Error | null
  updateLayoutSectionBuilderContent: UpdateLayoutSectionBuilderContent
}

export const useUpdateLayoutSectionBuilderContent: UseUpdateLayoutSectionBuilderContent =
  () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const updateLayoutSectionBuilderContent: UpdateLayoutSectionBuilderContent =
      useCallback(async values => {
        setLoading(true)
        try {
          const targetId = values.id
          if (!targetId) {
            throw new Error(
              `No target id found for updateLayoutSectionBuilderContent`
            )
          }
          const body = JSON.stringify({ ...values, type: BUILDER_CONTENT_TYPE })
          const headers = getHeaders()
          const payload = { method: 'PUT', headers, body }
          const updateUrl = `${apiUrl}/${targetId}`
          const res = await fetch(updateUrl, payload)
          if (res.status === 200) {
            // Everything OK
            return
          }

          const data = (await res.json()) as CreateOrUpdateLayoutSectionResponse
          if ('error' in data) {
            throw new Error(data.error)
          }
        } catch (err) {
          setError(err as Error)
          throw err
        } finally {
          setLoading(false)
        }
      }, [])

    return { updateLayoutSectionBuilderContent, loading, error }
  }
