import { LABEL, LINE } from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'

import TextControl from './TextControl'
import GenericInspectRow from './GenericRow'

type SizeControlProps = {
  object: {
    width: number
    height: number
    type: string
  }
  onChange: (arg: EditorObject['attributes']) => void
}

export const SizeControl: React.FC<SizeControlProps> = ({
  object,
  onChange,
}) => {
  return (
    <GenericInspectRow className="default-size-control" key="sizeControl">
      <TextControl
        gray
        type="number"
        name="width"
        value={object.width}
        onChange={onChange}
        title="Width"
      />
      <TextControl
        gray
        type="number"
        name="height"
        value={object.height}
        onChange={onChange}
        title="Height"
        disabled={object.type === LABEL || object.type === LINE}
      />
    </GenericInspectRow>
  )
}
