import React from 'react'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { getThirdPartyApiKeyFor } from 'ducks/thirdPartyApiKeys'
import { getActiveModal } from 'ducks/editor/modals'

import { getTrialState } from 'ducks/organizations'
import useHandleTrialOrUpgrade from 'hooks/useHandleTrialOrUpgrade'

import Tooltip from '../Tooltip'
import Icon from '../Icon'

import { UPGRADE, BEFORE, DURING } from '../../../constants'

import './GoogleHelpLink.scss'

const LinkText = ({ isInvalid }) => {
  if (isInvalid) {
    return (
      <>
        The&nbsp;<strong className="help-link__link">provided</strong>
        &nbsp;Google API key is invalid.
      </>
    )
  }

  return (
    <>
      <strong className="help-link__link">Add&nbsp;</strong>a Google API Key
    </>
  )
}

const TooltipText = ({ isInvalid, onClick }) => {
  if (isInvalid) {
    return (
      <p>
        Your{' '}
        <span className="tooltip-link" onClick={onClick}>
          Google API key
        </span>{' '}
        is invalid.
      </p>
    )
  }

  return (
    <p>
      Add your Google API key{' '}
      <span className="tooltip-link" onClick={onClick}>
        here
      </span>
      .
    </p>
  )
}

const HelpLink = ({ history, isError, match, isTooltip, children }) => {
  const { appId } = match.params

  const googleApiKey = useSelector(state =>
    getThirdPartyApiKeyFor(state, appId, 'google')
  )

  const activeModal = useSelector(getActiveModal)

  if (googleApiKey?.isValid) {
    return null
  }

  const isInvalid = googleApiKey && !googleApiKey.isValid

  const onClick = () => {
    if (activeModal) {
      activeModal.reject()
    }

    history.push(`/apps/${appId}/app-settings?active=apikeys`)
  }

  if (isTooltip) {
    return <TooltipText onClick={onClick} isInvalid={isInvalid} />
  }

  return (
    <p
      onClick={onClick}
      className={`help-link ${isError ? 'help-link--error' : ''}`}
    >
      {children || <LinkText isInvalid={isInvalid} />}
    </p>
  )
}

const WrappedHelpLink = withRouter(HelpLink)

export const GoogleTooltip = (
  <Tooltip tooltip={<WrappedHelpLink isTooltip />}>
    <Icon type="warning" small />
  </Tooltip>
)

export const GoogleUpgradeLink = ({ appId }) => {
  const { trialState } = useSelector(getTrialState)

  const handleTrialOrUpgrade = useHandleTrialOrUpgrade({
    appId,
    trialState,
    type: 'geolocation',
    isPaidOrg: false,
  })

  let message = null

  switch (trialState) {
    case BEFORE:
      message = (
        <span>
          Try out the Geolocation Feature by starting the&nbsp;
          <strong className="help-link__link">Free Trial</strong>
        </span>
      )

      break
    case DURING:
      message = (
        <span>
          <strong className="help-link__link">{UPGRADE}</strong> now to make
          sure all of your location properties stay up and running
        </span>
      )

      break
    default:
      message = (
        <span>
          Please&nbsp;<strong className="help-link__link">{UPGRADE}</strong>
          &nbsp;for full access to the Geolocation Feature
        </span>
      )
  }

  return (
    <p className="help-link" onClick={handleTrialOrUpgrade}>
      {message}
    </p>
  )
}

export default WrappedHelpLink
