import { useCallback, useEffect, useMemo, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { requestAppBuilds } from 'utils/io'
import { isLegacyPlanType } from 'utils/billing'

import {
  getAppBuildsState,
  startAppBuild as startAppBuildAction,
} from 'ducks/apps/builds'
import { getApp, startWebPublish, updateApp } from 'ducks/apps'
import { getCurrentOrganization } from 'ducks/organizations'

import WebPublish from './WebPublish'

interface Props {
  appId: string
  getAppHost: (domainId?: string | null) => string
}

const usePollBuilds = (appId: string) => {
  const fetchBuilds = () => requestAppBuilds(appId, 'web', { order: 'DESC' })

  useEffect(() => {
    fetchBuilds()

    const buildRefreshInterval = setInterval(fetchBuilds, 5000)

    return () => {
      if (buildRefreshInterval) {
        clearInterval(buildRefreshInterval)
      }
    }
  }, [])

  // Used to refetch builds so not to wait until the next build polling
  const refetchBuilds = useCallback(
    () => setTimeout(fetchBuilds, 500),
    [fetchBuilds]
  )

  return { refetchBuilds }
}

const ConnectedWebPublish = ({ appId, getAppHost }: Props): ReactElement => {
  const dispatch = useDispatch()

  const organization = useSelector(state => getCurrentOrganization(state))

  const app = useSelector(state => getApp(state, appId))
  const buildsState = useSelector(state =>
    getAppBuildsState(state, appId, 'web')
  )

  const appPublished = Boolean(app && app.published)
  // web publishing is always enabled for users in legacy plans
  const hasLegacyPlan = isLegacyPlanType(organization?.planType ?? 'free')

  const publishingEnabled =
    organization?.active && (appPublished || hasLegacyPlan)

  const { refetchBuilds } = usePollBuilds(appId)

  const startAppBuild = useCallback(() => {
    if (publishingEnabled) {
      if (hasLegacyPlan && !appPublished) {
        // if the app isn't set as published but they are on a legacy plan, it should be set as published before the first build
        dispatch(updateApp(appId, { published: true }))
      }
      const version = moment().format('YYYYMMDDhhmmss')
      dispatch(startAppBuildAction(appId, 'web', version))
      dispatch(startWebPublish(appId))
      refetchBuilds()
    } else {
      console.warn(
        `Attempted to start web app build ${appId} without publishing enabled`
      )
    }
  }, [appId, publishingEnabled, hasLegacyPlan])

  const loadingBuilds: boolean = buildsState ? buildsState.loading : true

  const { buildList, buildDisabled } = useMemo(() => {
    const builds =
      buildsState?.list?.map((build, index: number) => {
        const versionText = moment(build.startedAt).format('MM/DD/YYYY')

        let { completedAt, status } = build

        if (status === 'built' && index === 0) {
          const completedAtDate = moment(completedAt)
          const secondsSinceCompleted = moment().diff(
            completedAtDate,
            'seconds'
          )
          // Since web builds are set to 'built' upon creation, builds that were created 5s ago are set to 'building'
          // so users get to see the loader & get UI feedback
          if (secondsSinceCompleted < 5) {
            status = 'building'
            completedAt = undefined
          }
        }

        return { ...build, versionText, completedAt, status }
      }) ?? []

    const disabled =
      (!appPublished && !hasLegacyPlan) ||
      loadingBuilds ||
      builds?.some(
        build => build.status === 'queued' || build.status === 'building'
      )

    return { buildList: builds, buildDisabled: disabled }
  }, [appPublished, loadingBuilds, buildsState, hasLegacyPlan])

  const protocol = window.location.protocol
  const url = getAppHost(app?.DomainId)
  const appPublishedOnUrl = `${protocol}//${url}${app?.path ?? ''}`

  return (
    <WebPublish
      appId={appId}
      publishingEnabled={publishingEnabled}
      loadingBuilds={loadingBuilds}
      buildList={buildList}
      buildDisabled={buildDisabled}
      appPublishedOnUrl={appPublishedOnUrl}
      onPublishClick={startAppBuild}
    />
  )
}

export default ConnectedWebPublish
