import React from 'react'
import { connect } from 'react-redux'

import { getShowConnections, toggleConnections } from 'ducks/settings'

import ToggleField from 'components/Shared/Forms/ToggleField'

const DisplaySettings = props => {
  const { showConnections, toggleConnections } = props

  return (
    <ToggleField
      label="Do you want to see how your screens are linked?"
      helpText="Toggle this on and off at any time by pressing Shift + L"
      value={showConnections}
      inputValue="Display Screen Connections"
      onChange={toggleConnections}
    />
  )
}

const mapStateToProps = state => ({
  showConnections: getShowConnections(state),
})

const mapDispatchToProps = {
  toggleConnections,
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySettings)
