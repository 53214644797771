import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getApp } from 'ducks/apps'

import Button from 'components/Shared/Button'

import { PREVIEW_URL } from 'constants.js'

const PublishURL = ({ getAppHost, appId }) => {
  const domainId = useSelector(state => getApp(state, appId)?.DomainId)
  const protocol = window.location.protocol

  const appHost = domainId
    ? `${protocol}//${getAppHost(domainId)}`
    : `${PREVIEW_URL}/${appId}`

  return (
    <div className="web-publish-url-box">
      <div className="settings-form-row">
        <label>Published On</label>
        <p className="settings-form-static-value">{appHost}</p>
      </div>
      <div className="web-publish-open-url">
        <Button green to={appHost} target="_blank">
          Open
        </Button>
      </div>
    </div>
  )
}

PublishURL.propTypes = {
  appId: PropTypes.string.isRequired,
}

export default PublishURL
