import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Accordion from 'components/Editor/ComponentsAccordion/Accordion'
import { useFlags } from 'flags'
import TabItems from './TabItems'

function filter(categories, searchString) {
  searchString = searchString.toLowerCase()

  return categories
    .map(category => {
      const { children, options } = category

      if (options) {
        return { ...category, options: filter(options, searchString) }
      }

      return {
        ...category,
        children: children?.filter(component =>
          component.label.toLowerCase().includes(searchString)
        ),
      }
    })
    .filter(category => {
      const { options } = category

      if (options) {
        return options?.filter(item => item?.children?.length > 0)?.length > 0
      }

      return category?.children?.length > 0
    })
}

const Tab = ({
  defaultOpen,
  options,
  searchPlaceholder,
  emptyMessage,
  renderItem,
  className,
  sections,
  platform,
  showMore,
}) => {
  const [search, setSearch] = useState('')
  const { hasNewAddMenuWithSections } = useFlags()
  const hideSearch = hasNewAddMenuWithSections && platform === 'responsive'

  // merge sections and options
  const mergedOptions = [...options, ...sections]

  const handleSearch = event => setSearch(event.target.value)
  const results = search ? filter(mergedOptions, search) : mergedOptions

  return (
    <Accordion.Group defaultOpen={defaultOpen} openAll={!!search}>
      {!hideSearch && (
        <div className="editor-add-panel-search">
          <i className="material-icons">search</i>
          <input
            placeholder={searchPlaceholder}
            value={search}
            onChange={handleSearch}
            autoFocus
          />
        </div>
      )}
      <TabItems
        items={results}
        emptyMessage={emptyMessage}
        renderItem={renderItem}
        isSearching={!!search}
        className={className}
        showMore={showMore}
      />
    </Accordion.Group>
  )
}

Tab.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  defaultOpen: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchPlaceholder: PropTypes.string,
  emptyMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  renderItem: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      callout: PropTypes.shape({
        title: PropTypes.string,
        body: PropTypes.string,
        headerImage: PropTypes.string,
        button: PropTypes.shape({
          icon: PropTypes.string,
          children: PropTypes.string.isRequired,
        }),
      }),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ),
  platform: PropTypes.string,
  showMore: PropTypes.func,
}

Tab.defaultProps = {
  searchPlaceholder: 'Search...',
  sections: [],
}

export default React.memo(Tab)
