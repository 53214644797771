import { useEffect, useState } from 'react'
import series from 'async/series'

import { adaloBackendAxios } from 'utils/io/http/axios'
import Page from 'components/Shared/Page'
import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'

import './Admin.scss'

const ONE_MEGABYTE = 1048576

const sizeOf = bytes => {
  if (!bytes) {
    return null
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024))

  return `${(bytes / 1024 ** e).toFixed(2)} ${' KMGTP'.charAt(e)}B`
}

const THRESHOLD = {
  app: {
    small: ONE_MEGABYTE * 30,
    medium: ONE_MEGABYTE * 60,
  },
  screen: {
    small: ONE_MEGABYTE * 10,
    medium: ONE_MEGABYTE * 20,
  },
}

const getStyleForSize = (size, context) => {
  if (size < THRESHOLD[context].small) {
    return { color: 'green' }
  }

  if (size < THRESHOLD[context].medium) {
    return { color: 'orange' }
  }

  return { color: 'red', fontWeight: 'bold' }
}

const AppScreens = ({ match }) => {
  const [appScreens, setAppScreens] = useState(null)
  const [totalSize, setTotalSize] = useState(null)
  const [
    totalSizeWithoutAbsolutePosition,
    setTotalSizeWithoutAbsolutePosition,
  ] = useState(null)
  const [loadingError, setLoadingError] = useState(null)
  const [isCompiling, setIsCompiling] = useState(false)

  useEffect(() => {
    Promise.all([
      adaloBackendAxios.get(`/apps/${match.params.appId}/admin/app-screens`),
    ])
      .then(([appScreens]) => {
        setAppScreens(appScreens.data.screens)
        setTotalSize(appScreens.data.totalSize)
        setTotalSizeWithoutAbsolutePosition(
          appScreens.data.totalSizeWithoutAbsolutePosition
        )
      })
      .catch(err => setLoadingError(err.response?.data?.message ?? err.message))
  }, [match.params.appId])

  const hasRequiredData =
    appScreens !== null &&
    totalSize !== null &&
    loadingError === null &&
    totalSizeWithoutAbsolutePosition !== null

  if (loadingError) {
    return (
      <Page
        className="admin-users-page"
        title={`App Screen Info: ${match.params.appId}`}
      >
        <h1>App Screen Info: {match.params.appId}</h1>
        <hr />
        <p style={{ color: 'red' }}>
          Error retrieving App ID={match.params.appId}: {loadingError}
        </p>
      </Page>
    )
  }

  if (!hasRequiredData || isCompiling) {
    return (
      <EmptyState greedy>
        <Loading large />
      </EmptyState>
    )
  }

  return (
    <Page
      className="admin-users-page"
      title={`App Screen Info: ${match.params.appId}`}
    >
      <h1>App Screen Info</h1>
      <div
        style={{
          paddingBottom: '25px',
          marginTop: '25px',
          marginBottom: '25px',
          borderBottom: '1px solid lightGray',
        }}
      >
        <ul>
          <li>App ID: {match.params.appId}</li>
          <li>
            Total Size:{' '}
            <span style={getStyleForSize(totalSize, 'app')}>
              {sizeOf(totalSize)}
            </span>
          </li>
          <li>
            Total Size w/o absolutePosition:{' '}
            <span
              style={getStyleForSize(totalSizeWithoutAbsolutePosition, 'app')}
            >
              {sizeOf(totalSizeWithoutAbsolutePosition)}
            </span>
          </li>
        </ul>
        <button
          disabled={isCompiling}
          onClick={() => {
            setIsCompiling(true)
            const jobs = Object.entries(appScreens).map(([id]) => cb => {
              adaloBackendAxios
                .get(`/apps/${match.params.appId}/recompile/${id}`)
                .then(() => cb())
                .catch(err => cb(err))
            })
            series(jobs, err => {
              if (err) {
                setIsCompiling(false)
                setLoadingError(err.response?.data?.message ?? err.message)
              } else {
                window.location.reload()
              }
            })
          }}
        >
          Recompile screens
        </button>
      </div>
      <table style={{ width: '800px' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>ID</th>
            <th style={{ textAlign: 'left' }}>Name</th>
            <th style={{ textAlign: 'left' }}>Size</th>
            <th style={{ textAlign: 'left' }}>Size w/o absolutePosition</th>
            <th style={{ textAlign: 'left' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(appScreens).map(
            ([id, { name, size, sizeWithoutAbsolutePosition }]) => {
              const sizeWithoutAbsolutePositionValue =
                size === sizeWithoutAbsolutePosition
                  ? 'SAME'
                  : sizeOf(sizeWithoutAbsolutePosition)

              return (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{name}</td>
                  <td style={getStyleForSize(size, 'screen')}>
                    {sizeOf(size)}
                  </td>
                  <td
                    style={getStyleForSize(
                      sizeWithoutAbsolutePosition,
                      'screen'
                    )}
                  >
                    {sizeWithoutAbsolutePositionValue}
                  </td>
                  <td>
                    <button
                      disabled={isCompiling}
                      onClick={() => {
                        setIsCompiling(true)
                        adaloBackendAxios
                          .get(`/apps/${match.params.appId}/recompile/${id}`)
                          .then(() => {
                            window.location.reload()
                          })
                          .catch(err => {
                            setIsCompiling(false)
                            setLoadingError(
                              err.response?.data?.message ?? err.message
                            )
                          })
                      }}
                    >
                      Recompile screen
                    </button>
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </Page>
  )
}

export default AppScreens
