import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Checkout from './Checkout'
import Feed from './Feed'
import Details from './Details'

const Marketplace = () => {
  return (
    <Switch>
      <Route exact path="/apps/:appId/marketplace" component={Feed} />
      <Route
        exact
        path="/apps/:appId/marketplace/checkout"
        component={Checkout}
      />
      <Route path="/apps/:appId/marketplace/:libraryId" component={Details} />
    </Switch>
  )
}

export default Marketplace
