import { DefaultRootState } from 'react-redux'
import { sortBy } from 'lodash'
import { LAYOUT_SECTION } from '@adalo/constants'

import Button from 'components/Shared/Button'

import { App } from 'ducks/apps'
import { StoreState, getPrebuiltLayoutSections } from 'ducks/layoutSections'
import {
  PrebuiltBuilderContentPlatformTarget,
  PrebuiltBuilderContentStatus,
} from 'ducks/layoutSections/types'
import { UserState, getCurrentUser } from 'ducks/users'

import { assetURL } from 'utils/assets'
import {
  FormattedSection,
  formatPrimaryTags,
  SectionCategory,
  defaultSection,
} from 'utils/layoutSections'

import SectionImage from '../icons/layout-section.png'

import { FormattedFeatureTemplate } from './featureHelpers'
import { Component, Library } from './componentHelpers'

interface Props extends FormattedSection {
  handleSelect: (
    value: Record<string, unknown>,
    position: [number, number]
  ) => void
  handleSelectChip: (tag: string) => void
  isAdmin?: boolean
}

export function isFormattedSection(
  item: Component | Library | FormattedSection | FormattedFeatureTemplate
): item is FormattedSection {
  return (item as FormattedSection).primaryTags !== undefined
}

export const getFormattedSections = (
  state: DefaultRootState,
  app: App
): FormattedSection[] => {
  const user = getCurrentUser(state as UserState)
  const adaloAdmin = user?.admin || false

  const { layoutMode = 'responsive' } = app?.webSettings || {}
  const mobileOnly = layoutMode === 'mobile'

  const opts = {
    platformTarget: layoutMode as PrebuiltBuilderContentPlatformTarget,
    includeUnlisted: adaloAdmin,
  }

  const prebuiltLayoutSections = getPrebuiltLayoutSections(
    state as StoreState,
    opts
  )

  const formattedSections: FormattedSection[] = [
    ...prebuiltLayoutSections,
    defaultSection,
  ].map(section => {
    return {
      label: section.name,
      image: mobileOnly ? section.mobileThumbnail : section.responsiveThumbnail,
      ...section,
    }
  })

  return formattedSections
}

export const renderSectionOption = ({
  image,
  label,
  body,
  status,
  primaryTags,
  isAdmin,
  handleSelect,
  handleSelectChip,
}: Props): JSX.Element => {
  const source = image ? assetURL(image) : SectionImage
  const tags = formatPrimaryTags(primaryTags)
  const value = { type: LAYOUT_SECTION, options: body }

  // Only show status if it's an admin and the status is not listed
  const showStatus =
    isAdmin && status && status !== PrebuiltBuilderContentStatus.LISTED

  return (
    <div className="prebuilt-section-item">
      <div
        role="button"
        tabIndex={0}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          handleSelect(value, [e.clientX, e.clientY])
        }
      >
        <img
          className="prebuilt-section-image"
          src={source}
          alt={label}
          style={{
            width: '100%',
            border: '1px solid gray',
            padding: '3px',
            borderRadius: '7px',
          }}
          draggable={false}
        />
      </div>
      <div className="prebuilt-section-tags-container">
        {showStatus && (
          <div className="prebuilt-section-unlisted-indicator">{status}</div>
        )}
        {tags.map(tag => (
          <Button
            gray
            fitContent
            className="prebuilt-section-tag"
            onClick={() => handleSelectChip(tag)}
          >
            {tag}
          </Button>
        ))}
      </div>
    </div>
  )
}

const cleanCategoryName = (name: string) =>
  name
    .replace(/[^\w\d\s]/g, '')
    .replace(/\s/g, '')
    .toLocaleLowerCase()

export const MOST_USED_SECTIONS_CATEGORY_LABEL = 'Most Used Sections'

const PRESET_CATEGORIES_ORDER = [
  MOST_USED_SECTIONS_CATEGORY_LABEL,
  'Headers',
  'Heroes',
  'Minimal Lists',
  'Detailed Lists',
  'Table Lists',
  'Create & Edit',
  'Info',
  'Account & Profile',
  'Dashboards',
  'Secondary Navigation',
  'Empty States',
].map(cleanCategoryName)

export const sortCategoriesByPresetOrder = (
  categories: SectionCategory[]
): SectionCategory[] =>
  sortBy(categories, ({ label }) => {
    const cleanLabel = cleanCategoryName(label)
    const index = PRESET_CATEGORIES_ORDER.findIndex(
      p => p.includes(cleanLabel) || cleanLabel.includes(p)
    )

    return index === -1 ? Number.POSITIVE_INFINITY : index
  })
