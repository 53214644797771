import { NAVIGATION_BACK, actionTypes } from '@adalo/constants'

import { selectObject } from '../ducks/editor/objects'
import { getTable } from '../ducks/apps/datasources'
import { getObjectName } from './naming'
import { singularize } from './strings'

const TABLE_ACTION_TYPES = [
  actionTypes.CREATE_OBJECT,
  actionTypes.UPDATE_OBJECT,
  actionTypes.DELETE_OBJECT,
]

export const actionSubtitle = (state, appId, action) => {
  if (!action) return null
  if (!action.actionType) return null

  if (action.actionType === actionTypes.NAVIGATE) {
    const { target } = action.options

    if (target === NAVIGATION_BACK) {
      return '← Back'
    }

    const component = selectObject(state, target)

    return component ? getObjectName(component) : 'Empty'
  }

  if (TABLE_ACTION_TYPES.includes(action.actionType)) {
    let { datasourceId, tableId, bindingId } = action.options

    if (bindingId) {
      datasourceId = bindingId.datasourceId
      tableId = bindingId.tableId
    }

    const table = getTable(state, appId, datasourceId, tableId)

    return (table && singularize(table.name)) || 'Empty'
  }
}
