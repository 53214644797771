import { useHistory, withRouter } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { InjectedFormProps, reduxForm, reset } from 'redux-form'
import { v4 as uuid } from 'uuid'
import Lottie from 'react-lottie'

import {
  DBAssistantState,
  DBAssistantStatus,
  getAppMagicStart,
  SuggestedSchemaTable,
  updateAppMagicStartStatus,
  resetAppMagicStart,
} from 'ducks/dbAssistant'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import { requestApp } from 'ducks/apps'

import {
  generateDatabaseSchema,
  acceptDatabaseSchema,
  getLimits,
  initializeDbAssistant,
} from 'utils/io/dbAssistant'

import Header from '../components/Header'
import PromptInput from '../components/PromptInput'
import SchemaPreview from '../components/Steps/SchemaPreview'
import { DBAssistant } from '../Panel'

import loadingStars from '../assets/loadingstars.json'
import happyBuilding from '../assets/happybuildingstars.json'

import PromptTips from './PromptTips'

import './styles.scss'

interface MagicStartProps {
  appId: string
  datasource: Record<string, unknown>
}

type FormValues = {
  prompt: string
}

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet',
    filterSize: {
      width: '200%',
      height: '200%',
      x: '-50%',
      y: '-50%',
    },
  },
}

const loadingStarsOptions = {
  ...lottieOptions,
  animationData: loadingStars,
}

const happyBuildingOptions = {
  ...lottieOptions,
  animationData: happyBuilding,
}

const PROMPT_MAX_LENGTH = 500
const REDUX_FORM_NAME = 'magicStart'

const MagicStart: React.FC<
  MagicStartProps & InjectedFormProps<FormValues, MagicStartProps>
> = ({ appId, datasource }): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()

  const appMagicStart = useSelector(state =>
    getAppMagicStart(state as DBAssistantState, appId)
  )

  const [loading, setLoading] = useState(false)
  const [prompt, setPrompt] = useState('')

  const [schemaReady, setSchemaReady] = useState(false)
  const [schemaAccepted, setSchemaAccepted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (appMagicStart?.status === DBAssistantStatus.in_progress) {
      setLoading(true)
      setErrorMessage('')
      setSchemaReady(false)
    }

    if (appMagicStart?.status === DBAssistantStatus.done) {
      setLoading(false)
      setErrorMessage('')
      setSchemaReady(true)
    }

    if (appMagicStart?.status === DBAssistantStatus.failed) {
      setLoading(false)
      setSchemaReady(false)

      if (appMagicStart?.errorMessage) {
        setErrorMessage(appMagicStart.errorMessage)
      }
    }
  }, [appMagicStart])

  useEffect(() => {
    getLimits(appId).catch(console.error)
  }, [])

  const handleStartOver = () => {
    setSchemaReady(false)
    setSchemaAccepted(false)
    setErrorMessage('')
    initializeDbAssistant(appId).catch(console.error)
    dispatch(reset(REDUX_FORM_NAME))
  }

  const handleClickBack = () => {
    if (appMagicStart?.status !== DBAssistantStatus.in_progress) {
      handleStartOver()
    }

    return history.push(`/apps/${appId}/data`)
  }

  const handleClickAddToDatabase = async (tables: SuggestedSchemaTable[]) => {
    setSchemaAccepted(true)

    if (!datasource) {
      throw new Error('No default datasource found')
    }

    if (!appMagicStart || !appMagicStart.conversationId) {
      throw new Error('No conversationId found')
    }

    const result = await acceptDatabaseSchema({
      appId,
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
      datasourceId: datasource?.['id'] as string,
      conversationId: appMagicStart.conversationId,
      action: 'updateSchema',
      tables,
    })

    if (result === 'dbAssistantAcceptSchema_success') {
      dispatch(requestApp(appId))
    }

    setTimeout(() => {
      dispatch(resetAppMagicStart(appId))
      handleClickBack()
    }, 3000)
  }

  const handleGenerateDatabase = async () => {
    await generateDatabaseSchema({
      appId,
      userPrompt: prompt,
      conversationId: appMagicStart?.conversationId || uuid(),
    })
  }

  const handleClickGenerateAgain = () => {
    setSchemaReady(false)
    setSchemaAccepted(false)
    setErrorMessage('')

    dispatch(updateAppMagicStartStatus(appId, DBAssistantStatus.idle))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value)
    const maxLengthReached = e.target.value.length >= PROMPT_MAX_LENGTH

    if (maxLengthReached) {
      setErrorMessage(`You've reached the maximum number of characters`)
    } else {
      setErrorMessage('')
    }
  }

  const handleAllChangesRemoved = () => {
    setErrorMessage(
      'There are no remaining changes to apply. Start over to try again'
    )
  }

  if (schemaAccepted && !errorMessage) {
    return (
      <div className="magic-start-setup-complete">
        <Lottie height={72} width={72} options={happyBuildingOptions} />
        <h2>Happy Building!</h2>
      </div>
    )
  }

  return (
    <DBAssistant className="magic-start">
      <Header title="Magic Start" onClickBack={handleClickBack} />
      <div className="magic-database-setup-content">
        <div className="magic-database-setup-title">
          <p>
            Hey there! Let’s set up your database together. Just explain your
            app in detail, and I’ll suggest a starting database!
          </p>
          <p>What kind of app are you building?</p>
        </div>
        <PromptInput
          value={prompt}
          placeholder="An app for ..."
          onChange={handleChange}
          onClick={handleGenerateDatabase}
          submitted={schemaReady || loading}
          maxLength={PROMPT_MAX_LENGTH}
          disableButton={
            prompt.length === 0 || prompt.length >= PROMPT_MAX_LENGTH
          }
          disableInput={loading || schemaReady}
          errorMessage={errorMessage}
          buttonText="Generate Preview"
        />
        {!schemaReady && !loading ? <PromptTips /> : null}
        {loading ? (
          <Lottie height={42} width={42} options={loadingStarsOptions} />
        ) : null}
        {schemaReady ? (
          <div className="suggested-collections-section">
            {schemaReady ? (
              <SchemaPreview
                limits={appMagicStart?.limits}
                schema={appMagicStart?.schema}
                onAccept={handleClickAddToDatabase}
                errorMessage={errorMessage}
                handleGoBack={handleClickGenerateAgain}
                feature="magicStart"
                handleAllChangesRemoved={handleAllChangesRemoved}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </DBAssistant>
  )
}

const WrappedMagicStart = reduxForm<FormValues, MagicStartProps>({
  form: REDUX_FORM_NAME,
  destroyOnUnmount: false,
})(MagicStart)

const mapStateToProps = (
  state: unknown,
  { match }: { match: { params: { appId: string } } }
) => {
  const { appId } = match.params

  return {
    appId: match.params.appId,
    datasource: getDefaultDatasource(state, appId) as Record<string, unknown>,
  }
}

export default withRouter(connect(mapStateToProps)(WrappedMagicStart))
