import React from 'react'
import PropTypes from 'prop-types'
import { actionTypes } from '@adalo/constants'

import AuthErrorMessage from './AuthErrorMessage'
import ConditionalAction from './ConditionalAction'
import ExternalLinkOptions from './ExternalLink'

const extensions = {
  [actionTypes.EXTERNAL_LINK]: ExternalLinkOptions,
}

const AdvancedOptions = ({
  action,
  field,
  formName,
  objectId,
  actionId,
  reference,
  hideConditional,
  dummyAction,
}) => {
  const actionType = dummyAction ? dummyAction?.reference : action?.actionType

  const { AUTHENTICATE, SIGNUP } = actionTypes
  const authActions = [AUTHENTICATE, SIGNUP, 'login']
  const ExtensionClass = extensions[actionType]

  return (
    <div className="form-inspect-field-wrapper">
      {!hideConditional && (
        <ConditionalAction
          field={field}
          formName={formName}
          objectId={objectId}
          actionId={actionId}
          reference={reference}
        />
      )}
      {authActions.includes(actionType) && (
        <AuthErrorMessage
          action={action}
          field={field}
          objectId={objectId}
          reference={reference}
          dummyAction={dummyAction}
        />
      )}
      {ExtensionClass && <ExtensionClass action={action} field={field} />}
    </div>
  )
}

AdvancedOptions.propTypes = {
  action: PropTypes.object,
  field: PropTypes.string,
  formName: PropTypes.string,
  objectId: PropTypes.string,
  reference: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideConditional: PropTypes.bool,
  dummyAction: PropTypes.object,
}

export default AdvancedOptions
