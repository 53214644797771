import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import { dataTypes, formatterOptions } from '@adalo/constants'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import { buildFormula } from 'utils/formulas'
import { isRole } from 'utils/libraries'
import {
  getLibraryBindingSuggestions,
  MenuOption,
  Submenu,
} from 'ducks/recommender'
import { getDatasources } from 'ducks/apps/datasources'
import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'

import {
  getCurrentAppId,
  getComponent,
  selectObject,
} from 'ducks/editor/objects'

import { THEMES } from '../../../constants'

import EntityTextarea from '../Forms/EntityTextarea'
import { IconButton } from '../Icon'
import BindingEntity from './BindingEntity'
import SymbolEntity from './SymbolEntity'
import './FormulaControl.css'

const DISABLE_CHIP_DATATYPES = new Set([dataTypes.DATE, dataTypes.DATE_ONLY])

class FormulaControl extends Component {
  static defaultProps = {
    dataType: dataTypes.NUMBER,
  }

  handleChange = newValue => {
    const { name, onChange, dataType, componentId, actionId } = this.props

    const transformedValue = buildFormula(
      newValue,
      dataType,
      componentId,
      actionId
    )

    onChange({ [name]: transformedValue })
  }

  shouldDisableChip = entity => {
    const { dataType } = this.props

    if (DISABLE_CHIP_DATATYPES.has(dataType)) {
      return true
    }

    /** @type {string | null} */
    const sourceDataType = _get(entity, 'source.dataType', null)
    /** @type {string | null} */
    const sourceSourceDataType = _get(entity, 'source.source.dataType', null)

    return DISABLE_CHIP_DATATYPES.has(sourceDataType ?? sourceSourceDataType)
  }

  renderEntity = (entity, { onDelete, onUpdate }) => {
    const { appId, componentId, objectId, role } = this.props
    let { reference } = this.props
    reference = isRole(role, 'listItem') ? reference : null

    if (!entity) {
      return null
    }

    if (entity.type === 'symbol' || entity.type === 'expression') {
      return <SymbolEntity entity={entity} />
    }

    return (
      <BindingEntity
        entity={entity}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        reference={reference}
        onDelete={onDelete}
        onUpdate={onUpdate}
        disableChip={this.shouldDisableChip(entity)}
      />
    )
  }

  handleAddEntity = binding => {
    if (binding.type === 'expression') {
      return this.textarea.addEntity(binding)
    }

    const { dataType } = binding.source
    const options = formatterOptions[dataType] || []
    const option = options[0]
    const format = option ? { type: option.value } : undefined

    this.textarea.addEntity({
      ...binding,
      format,
    })
  }

  textareaRef = textarea => {
    this.textarea = textarea
  }

  handleMouseDown = e => {
    e.preventDefault()
  }

  getOptions = () => () => {
    const { bindingOptions } = this.props

    const options = bindingOptions()

    if (options.length > 0) {
      options.push(null)
    }

    options.push(
      new Submenu('Functions', [
        new MenuOption(
          'ROUND',
          { type: 'expression', symbol: 'ROUND(' },
          'round'
        ),
        new MenuOption(
          'INT',
          { type: 'expression', symbol: 'INT(' },
          'integer'
        ),
        new MenuOption(
          'ABS',
          { type: 'expression', symbol: 'ABS(' },
          'absolute-value'
        ),
        new MenuOption(
          'SQRT',
          { type: 'expression', symbol: 'SQRT(' },
          'square-root'
        ),
        new MenuOption(
          'EXP(base, exp)',
          { type: 'expression', symbol: 'EXP(' },
          'exponent'
        ),
        new MenuOption(
          'RAND(0, 1)',
          { type: 'expression', symbol: 'RAND(' },
          'random'
        ),
        new MenuOption(
          'LOG',
          { type: 'expression', symbol: 'LOG(' },
          'logarithm'
        ),
        {
          label: 'MI(latA, lngA, latB, lngB)',
          value: { type: 'expression', symbol: 'MILES(' },
          icon: 'distance',
          title: 'MILES(latA, lngA, latB, lngB)',
        },
        {
          label: 'KM(latA, lngA, latB, lngB)',
          value: { type: 'expression', symbol: 'KILOMETERS(' },
          icon: 'distance',
          title: 'KILOMETERS(latA, lngA, latB, lngB)',
        },
      ])
    )

    return options
  }

  render() {
    let { contained, displayName, placeholder, value } = this.props

    let valid = true

    if (value && value.type === 'formula') {
      valid = value.valid
      value = value.formula
    }

    if (typeof value === 'number') {
      value = [String(value)]
    }

    value = value || []

    if (!Array.isArray(value)) {
      value = ['', value, '']
    }

    return (
      <div
        className={classNames('bindable-text-control', 'formula-control', {
          'formula-control-contained': contained,
          'formula-control-invalid': !valid,
        })}
      >
        <div className="bindable-text-control-header">
          <p className="bindable-text-control-label">{displayName}</p>
          <div className="bindable-text-control-trigger-wrapper">
            <MultiMenuTrigger
              menu={this.getOptions()}
              onSelect={this.handleAddEntity}
              className="bindable-text-control-trigger"
              menuClassName="bindable-text-control-menu"
              rowHeight={40}
              width={260}
              menuTheme={THEMES.DATA}
            >
              <IconButton
                orange
                type="magic-number"
                onMouseDown={this.handleMouseDown}
                className="bindable-text-control-trigger-icon"
              />
            </MultiMenuTrigger>
          </div>
        </div>
        <EntityTextarea
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
          renderEntity={this.renderEntity}
          childRef={this.textareaRef}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const {
    objectId,
    role,
    reference,
    actionId,
    helpers,
    hideParentListOptions,
  } = props
  const appId = getCurrentAppId(state)
  const componentId = getComponent(state, objectId)?.id

  const allowedDataTypes = [
    dataTypes.NUMBER,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
  ]

  const object = selectObject(state, objectId)
  const { dataBinding } = object

  const binding = (isRole(role, 'listItem') && reference) || dataBinding

  return {
    appId,
    componentId,
    objectId,
    datasources: getDatasources(state, appId),
    bindingOptions: getLibraryBindingSuggestions(
      state,
      appId,
      componentId,
      objectId,
      allowedDataTypes,
      binding,
      actionId,
      undefined,
      helpers,
      hideParentListOptions
    ),
  }
}

export default withPremiumFeatureHandler(
  connect(mapStateToProps)(FormulaControl)
)
