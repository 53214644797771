import { ForwardedRef, forwardRef, useContext } from 'react'
import styled from 'styled-components'
import TableThemeContext from './TableThemeContext'

const Container = styled('div')<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  color: ${props => props.$backgroundColor};
`

const TableRow = (
  { children }: { children?: JSX.Element[] | JSX.Element },
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element | null => {
  const { backgroundColor } = useContext(TableThemeContext)

  return (
    <Container $backgroundColor={backgroundColor} ref={ref}>
      {children}
    </Container>
  )
}

export default forwardRef(TableRow)
