export const get = (obj, key) => {
  let val = obj
  const keys = key.split('.')

  for (const key of keys) {
    if (!val) {
      return undefined
    }

    val = val[key]
  }

  return val
}
