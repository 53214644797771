import React from 'react'
import Button from 'components/Shared/Button'
import './index.scss'

const Card = ({
  iconSrc,
  iconBackground,
  title,
  description,
  learnMoreLink,
  isNew = false,
}) => {
  const learnMore = learnMoreLink ? (
    <Button
      to={learnMoreLink}
      target="_blank"
      text
      gray
      small
      className="f12 semibold mrn-2"
    >
      LEARN MORE
    </Button>
  ) : null

  let newTag = null

  if (isNew) {
    newTag = (
      <div className="radius-8 color-green bg-light-green py-1 ph-3 semibold f12">
        NEW
      </div>
    )
  }

  return (
    <div className="border-gray pa-4 radius-3">
      <div className="flex justify-between align-center">
        <div
          className="size-50px radius-full"
          style={{ background: iconBackground }}
        >
          <img alt="" src={iconSrc} className="size-50px pa-3" />
        </div>
        {learnMore}
      </div>
      <div className="flex justify-between align-center mt-4">
        <p className="f21 semibold"> {title} </p>
        {newTag}
      </div>
      <p className="f12 color-gray mt-3"> {description} </p>
    </div>
  )
}

export default Card
