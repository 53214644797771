import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { useSelector } from 'react-redux'

import { isFeatureEnabled } from 'ducks/organizations'

import { isMaliciousHTML, MALICIOUS_ERROR_MESSAGE } from 'utils/validation'

import PasswordInput from 'components/Shared/Forms/PasswordInput'
import { SettingsFormSubmit } from 'components/Shared/Forms/SettingsFormField'
import FreeTrial from 'components/Shared/FreeTrial'
import Button from 'components/Shared/Button'

const validate = values => {
  const errors = {}

  if (isMaliciousHTML(values?.google?.key)) {
    errors.google = { key: MALICIOUS_ERROR_MESSAGE }
  }

  return errors
}

const GoogleApiKeyForm = ({
  handleSubmit,
  dirty,
  submitting,
  appId,
  initialValues,
  handleDelete,
}) => {
  const isEmpty = !initialValues || !initialValues.google
  const isEnabled = useSelector(state => isFeatureEnabled(state, 'geolocation'))

  if (!isEnabled && isEmpty) {
    return <FreeTrial appId={appId} type="googleApiKey" colorType="api" />
  }

  return (
    <>
      <form
        className="api-keys__form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Field
          component={PasswordInput}
          hasToggle
          name="google.key"
          label="Google Maps API Key"
          placeholder="Add key..."
          disabled={!isEnabled && !isEmpty}
          tooltip={
            <>
              You need a Google API key to use location features. Need help
              creating one? Check out our help doc on how to{' '}
              <a
                href="https://help.adalo.com/component-basics/marketplace-components/maps#1-setup-your-google-api-key"
                target="_blank"
                rel="noopener noreferrer"
              >
                create your Google API Key.
              </a>
            </>
          }
        />
        <div className="api-keys__control">
          <Button type="button" onClick={() => handleDelete('google')} flat>
            Delete API Key
          </Button>
          <SettingsFormSubmit
            enabled={dirty && !submitting && isEnabled}
            title="Save Key"
          />
        </div>
      </form>

      <FreeTrial appId={appId} type="googleApiKey" colorType="api" />
    </>
  )
}

export default reduxForm({
  form: 'GoogleApiKey',
  validate,
  enableReinitialize: true,
})(GoogleApiKeyForm)
