import { createContext, useContext, useMemo } from 'react'
import { connect } from 'react-redux'

import Document from 'ducks/editor/model/Document'
import ObjectNode from 'ducks/editor/model/ObjectNode'
import ObjectType from 'ducks/editor/types/ObjectType'
import { getMap, getObjectList, getMagicLayout } from 'ducks/editor/objects'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import mapScreenToDocument from 'ducks/editor/instructions/mapScreenToDocument'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { getObjectName } from 'utils/naming'
import { EditorObject } from 'utils/responsiveTypes'
import DebugOptionsContext from './DebugOptionsContext'

import './SelectedObjectSection.scss'

interface UtilFunctions {
  magicLayout: boolean
  getScreenDocument: (screenId: string) => Document
}

const UtilContext = createContext<UtilFunctions | undefined>(undefined)

const serializeObject = (obj: EditorObject, omitChildren: boolean): string => {
  const copy = { ...obj }
  if (omitChildren) {
    delete copy.children
  }

  return JSON.stringify(copy, undefined, 2)
}

const serializeDocument = (
  node: ObjectNode<ObjectType>,
  omitChildren: boolean
): string => {
  // Drop all parent references as JSON.stringify will throw error on cycles.
  const SKIP_KEYS = ['parent', 'screen']
  if (omitChildren) {
    SKIP_KEYS.push('children')
  }

  return JSON.stringify(
    node,
    (key, value) => {
      if (SKIP_KEYS.includes(key)) {
        return undefined
      }

      return value as unknown
    },
    2
  )
}

interface ObjectDetailsProps {
  object: EditorObject
  defaultOpen?: boolean
}

function ObjectDetails({ object, defaultOpen = false }: ObjectDetailsProps) {
  const { magicLayout, getScreenDocument } = useContext(UtilContext) || {}
  if (getScreenDocument === undefined) {
    throw new Error(`UtilContext is missing.`)
  }
  const { jsonIncludeChildren } = useContext(DebugOptionsContext)
  const { id, children } = object
  const name = getObjectName(object)
  const idPrefix = `${id.substring(0, 6)}...`
  const omitChildren = !jsonIncludeChildren

  const json = useMemo(
    () => serializeObject(object, omitChildren),
    [object, jsonIncludeChildren]
  )

  let documentJson: string | undefined

  if (magicLayout) {
    const document = getScreenDocument(id)
    const node = document.getObjectById(id)
    if (node !== undefined) {
      documentJson = serializeDocument(node, omitChildren)
    }
  }

  const hasChildren = children && children.length > 0
  let childList = null
  if (hasChildren) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    childList = <ObjectList objects={children} />
  }

  return (
    <details className="ObjectDetails" open={defaultOpen}>
      <summary className="summary">{`${name} (${idPrefix})`}</summary>

      <details open={!hasChildren}>
        <summary>JSON</summary>

        <pre className="object-json">{json}</pre>
      </details>
      <details open={!!documentJson && !hasChildren}>
        <summary>Document</summary>
        <pre className="object-json">
          {documentJson ?? 'No Document Available'}
        </pre>
      </details>
      {childList}
    </details>
  )
}

interface ObjectListProps {
  objects: EditorObject[]
  // Automatically expands the object details when there is exactly one element in the list.
  autoExpand?: boolean
}

function ObjectList({ objects, autoExpand = false }: ObjectListProps) {
  const defaultOpen = autoExpand && objects.length === 1

  return (
    <div className="ObjectList">
      {objects.map(obj => (
        <ObjectDetails key={obj.id} object={obj} defaultOpen={defaultOpen} />
      ))}
    </div>
  )
}

interface Props {
  magicLayout: boolean
  selectedObjects: EditorObject[]
  getScreenDocument: (screenId: string) => Document
}

function SelectedObjectSection({
  magicLayout,
  selectedObjects,
  getScreenDocument,
}: Props): React.ReactElement<Props> {
  let selection = null
  if (selectedObjects.length === 0) {
    selection = <p>No Objects Selected</p>
  } else {
    selection = <ObjectList objects={selectedObjects} autoExpand />
  }

  return (
    <UtilContext.Provider value={{ magicLayout, getScreenDocument }}>
      <div className="SelectedObjectSection">
        <h2>Selected Objects</h2>
        {selection}
      </div>
    </UtilContext.Provider>
  )
}

type MappedProps = Omit<Props, 'selectedObjects'>
const mapStateToProps = (state: unknown): MappedProps => {
  const magicLayout: boolean = getMagicLayout(state)

  const objectList: EditorObject[] = getObjectList(state)
  const pathMap: ObjectPathMap = getMap(state)

  const getScreenDocument = (objectId: string): Document => {
    const screen: EditorObject = getParentScreen(objectList, pathMap, objectId)

    return mapScreenToDocument(objectList, pathMap, screen.id)
  }

  return {
    magicLayout,
    getScreenDocument,
  }
}

const ConnectedSelectedObjectSection = connect<MappedProps>(mapStateToProps)(
  SelectedObjectSection
)

export default ConnectedSelectedObjectSection
