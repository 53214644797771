import React, { Component } from 'react'
import { Field, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { dataTypes, actionTypes } from '@adalo/constants'

import { getLabel } from 'utils/sources'
import { getFieldOptions } from 'ducks/recommender'
import { selectObject } from 'ducks/editor/objects'

import { getDatasource, getOrderedFields } from 'ducks/apps/datasources'

import BindableTextControl, {
  FormulaControl,
} from 'components/Shared/BindableTextControl'

import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'

import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'

class FieldItem extends Component {
  getField = () => {
    const { datasource, tableId, fieldId } = this.props

    if (!datasource || !datasource.tables || !datasource.tables[tableId]) {
      return
    }

    const table = datasource.tables[tableId]
    const field = table.fields[fieldId]

    return field
  }

  render() {
    let {
      actionType,
      objectId,
      reference,
      actionId,
      placeholder,
      fieldId,
      value,
      onChange,
      options,
      getLabel,
      menuTheme,
    } = this.props

    const name = fieldId
    const field = this.getField()
    const role = reference ? 'listItem' : null
    placeholder = placeholder || 'Empty'

    if (!field) {
      return null
    }

    switch (field.type) {
      case dataTypes.NUMBER:
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return (
          <FormulaControl
            dataType={field.type}
            displayName={field.name}
            objectId={objectId}
            role={role}
            reference={reference}
            actionId={actionId}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value && value}
          />
        )
      case dataTypes.TEXT:
      case dataTypes.PASSWORD:
        return (
          <BindableTextControl
            displayName={field.name}
            objectId={objectId}
            role={role}
            reference={reference}
            actionId={actionId}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
          />
        )
      default:
        if (
          field.type.type &&
          field.type.type.includes('manyToMany') &&
          actionType === actionTypes.CREATE_OBJECT
        ) {
          return null
        }

        return (
          <MenuControl
            displayName={field.name}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            options={options}
            getLabel={getLabel}
            menuTheme={menuTheme}
          />
        )
    }
  }
}

const fieldItemMapState = (state, ownProps) => {
  let options = []

  const {
    appId,
    datasourceId,
    tableId,
    componentId,
    objectId,
    actionId,
    reference,
    fieldId,
    formName,
    parentField,
    helpers,
  } = ownProps

  const object = selectObject(state, objectId)

  if (fieldId) {
    options = getFieldOptions(
      state,
      appId,
      componentId,
      objectId,
      datasourceId,
      tableId,
      fieldId,
      actionId,
      reference || object?.dataBinding,
      helpers
    )
  }

  const formValues = getFormValues(formName)(state)
  const target = parentField.split(/[[\]]{1,2}/).filter(el => el != null)
  const [actions, actionsIndex] = target

  // grab the action type
  const actionType =
    formValues &&
    formValues[actions] &&
    formValues[actions][actionsIndex] &&
    formValues[actions][actionsIndex].actionType

  return {
    actionType,
    options,
    getLabel: source => getLabel(state, source, appId, componentId, true),
  }
}

const WrappedFieldItem = withPremiumFeatureHandler(
  connect(fieldItemMapState)(FieldItem)
)

class FieldsGroup extends Component {
  handleChange = newValue => {
    const fieldId = Object.keys(newValue)[0]

    if (
      newValue[fieldId].type === 'formula' &&
      newValue[fieldId].formula.length === 1 &&
      newValue[fieldId].formula[0] === ''
    ) {
      newValue[fieldId] = ''
    }

    const {
      input: { onChange },
    } = this.props

    onChange(this.serialize(newValue))
  }

  getValue = fieldId => {
    const {
      input: { value },
    } = this.props

    if (!Array.isArray(value)) {
      return
    }

    const val = value.filter(itm => itm && itm.fieldId === fieldId)[0]

    return val && val.source
  }

  serialize = newValue => {
    let {
      input: { value },
    } = this.props

    if (!Array.isArray(value)) {
      value = []
    }

    const fieldId = Object.keys(newValue)[0]
    const result = value.filter(itm => itm && itm.fieldId !== fieldId)

    if (
      newValue[fieldId] ||
      newValue[fieldId] === false ||
      newValue[fieldId] === 0
    ) {
      result.push({ fieldId, source: newValue[fieldId] })
    }

    return result
  }

  render() {
    const { orderedFields } = this.props

    return (
      <div>
        {orderedFields.map(fieldId => (
          <WrappedFieldItem
            {...this.props}
            key={fieldId}
            fieldId={fieldId}
            onChange={this.handleChange}
            value={this.getValue(fieldId)}
          />
        ))}
      </div>
    )
  }
}

class Fields extends Component {
  getTable = () => {
    const { datasource, tableId } = this.props

    if (datasource) {
      return datasource.tables[tableId]
    }
  }

  render() {
    const { parentField } = this.props

    const table = this.getTable()

    if (!table) {
      return null
    }

    const orderedFields = getOrderedFields(table)

    return (
      <div className="action-item-fields form-inspect-field-wrapper">
        <Field
          {...this.props}
          name={`${parentField}.options.fields`}
          component={FieldsGroup}
          orderedFields={orderedFields}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId, datasourceId } = props

  return {
    datasource: getDatasource(state, appId, datasourceId),
  }
}

export default connect(mapStateToProps)(Fields)
