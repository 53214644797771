import React, { Component } from 'react'

export default class Field extends Component {
  handleKeyDown = e => {
    e.stopPropagation()
  }

  handleChange = e => {
    const value = e.currentTarget.value
    const { name, onChange } = this.props

    onChange({ [name]: value })
  }

  render() {
    const { label, value } = this.props

    return (
      <div className="entity-detail-popout-section">
        <p className="entity-detail-popout-label">{label}</p>
        <input
          className="entity-detail-popout-input"
          type="text"
          value={value || ''}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    )
  }
}
