import React, { Component } from 'react'
import classNames from 'classnames'
import Icon from '../Icon'
import './IconOption.css'

export default class IconOption extends Component {
  render() {
    const { icon, label, className } = this.props

    return (
      <div className={classNames('select-icon-option', className)}>
        <Icon type={icon} />
        <span>{label}</span>
      </div>
    )
  }
}
