import { useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import useDebugMode from 'hooks/useDebugMode'

import { getSelectedObjects } from 'ducks/editor/objects'
import { EditorObject } from 'utils/responsiveTypes'

import SelectedObjectSection from './SelectedObjectSection'
import DebugOptionsContext from './DebugOptionsContext'
import DebugOptions, { DEFAULT_DEBUG_OPTIONS } from './DebugOptions'
import OptionsSection from './OptionsSection'

import './DebugPanel.scss'

// Reference: https://graphemica.com/%E2%96%B2
const UP_ARROW = '\u25B2'
// Reference: https://graphemica.com/%E2%96%BC
const DOWN_ARROW = '\u25BC'

interface GripProps {
  expanded: boolean
  onClick: () => void
}

function Grip({ expanded, onClick }: GripProps) {
  const icon = expanded ? DOWN_ARROW : UP_ARROW

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="Grip" onClick={onClick}>
      {`${icon} Debug`}
    </div>
  )
}

interface Props {
  selectedObjects: EditorObject[]
}

function DebugPanel({ selectedObjects }: Props): React.ReactElement | null {
  const debug = useDebugMode()
  const [expanded, setExpanded] = useState(false)
  const [options, setOptions] = useState(DEFAULT_DEBUG_OPTIONS)

  const handleGripClicked = () => setExpanded(!expanded)
  const handleOptionsChanged = (value: DebugOptions) => setOptions(value)

  if (!debug) {
    return null
  }

  const selectionEmpty = selectedObjects.length === 0
  const active = !selectionEmpty || expanded

  let content = null
  if (expanded) {
    content = (
      <div className="content">
        <OptionsSection
          defaultOptions={options}
          onChange={handleOptionsChanged}
        />
        <SelectedObjectSection selectedObjects={selectedObjects} />
      </div>
    )
  }

  return (
    <DebugOptionsContext.Provider value={options}>
      <div
        className={classnames('DebugPanel', {
          active,
          expanded,
        })}
      >
        <Grip expanded={expanded} onClick={handleGripClicked} />
        {content}
      </div>
    </DebugOptionsContext.Provider>
  )
}

type MappedProps = Pick<Props, 'selectedObjects'>
const mapStateToProps = (state: unknown): MappedProps => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedObjects: EditorObject[] = getSelectedObjects(state) || []

  return {
    selectedObjects,
  }
}

const ConnectedDebugPanel = connect<MappedProps>(mapStateToProps)(DebugPanel)

export default ConnectedDebugPanel
