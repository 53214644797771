import { Action } from 'redux'

export enum CurrentTab {
  Setup = 'setup',
  Layout = 'layout',
}

enum ActionTypes {
  SET_CURRENT_TAB = 'SET_CURRENT_TAB',
}

interface SetCurrentTabAction extends Action {
  type: ActionTypes.SET_CURRENT_TAB
  currentTab: CurrentTab
}

type MagicLayoutAction = SetCurrentTabAction

interface State {
  currentTab: CurrentTab
}

export const INITIAL_STATE: State = {
  currentTab: CurrentTab.Setup,
}

// Reducers
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: MagicLayoutAction): State => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_TAB:
      return { ...state, currentTab: action.currentTab }

    default:
      return state
  }
}

// Actions
export const setCurrentTab = (currentTab: CurrentTab): SetCurrentTabAction => ({
  type: ActionTypes.SET_CURRENT_TAB,
  currentTab,
})

// Selectors
export const getCurrentTab = (state?: { magicLayout: State }): CurrentTab =>
  state?.magicLayout?.currentTab || CurrentTab.Setup
