import React from 'react'
import classNames from 'classnames'

// components
import Icon from 'components/Shared/Icon'

import './PaymentForm.scss'

const PaymentMethods = ({
  paymentMethods,
  defaultPaymentMethod,
  input,
  meta,
}) => {
  React.useEffect(() => {
    if (!input.value && paymentMethods.length > 0) {
      const initialPaymentMethodId = paymentMethods[0].id

      if (defaultPaymentMethod) {
        const methodExists = paymentMethods.find(
          pm => pm.id === defaultPaymentMethod
        )

        if (methodExists) {
          input.onChange(defaultPaymentMethod)
        } else {
          input.onChange(initialPaymentMethodId)
        }
      } else {
        input.onChange(initialPaymentMethodId)
      }
    }
  }, [input.value, paymentMethods.length])

  return (
    <div className="payment-form-methods">
      {paymentMethods.length > 0 ? (
        paymentMethods.map(pm => (
          <PaymentMethod key={pm.id} {...pm} input={input} />
        ))
      ) : (
        <>
          <PaymentMethod isSkeleton />
          <PaymentMethod isSkeleton />
          <PaymentMethod isSkeleton />
        </>
      )}
    </div>
  )
}

const PaymentMethod = ({ name, id, card, isSkeleton = false, input }) => {
  if (isSkeleton) {
    return (
      <div className="payment-form-card skeleton">
        <Icon type="credit-card" />
        <div className="payment-form-card-info">
          <div className="payment-form-card-row" />
          <div className="payment-form-card-row" />
        </div>
      </div>
    )
  }

  const isSelected = input.value === id

  const handleChange = () => {
    input.onChange(id)
  }

  const month = React.useMemo(
    () =>
      String(card.exp_month).length === 1
        ? `0${card.exp_month}`
        : card.exp_month,
    [card.exp_month]
  )

  const year = React.useMemo(
    () => String(card.exp_year).substring(2),
    [card.exp_yaer]
  )

  return (
    <div
      className={classNames('payment-form-card', {
        'payment-form-card-selected': isSelected,
      })}
      onClick={handleChange}
    >
      <Icon type="credit-card" />
      <div className="payment-form-card-info">
        <div className="payment-form-card-row">
          <strong>
            {card.brand.toUpperCase()} card ending in {card.last4}
          </strong>
        </div>
        <div className="payment-form-card-row">
          {name} | Expires {month}/{year}
        </div>
      </div>

      <Icon type="check-circle" className="payment-form-card-check" />
    </div>
  )
}

export default PaymentMethods
