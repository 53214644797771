import { COMPONENT, GROUP, LIBRARY_COMPONENT } from '@adalo/constants'
import DeviceType from 'ducks/editor/types/DeviceType'
import hiddenOnDevice from 'utils/objects/hiddenOnDevice'
import { EditorObject } from 'utils/responsiveTypes'
import { FIXED_POSITION_COMPONENTS } from 'utils/libraries'
import { hasDeviceLayoutEnabled } from '../utils'
import conditionKey from './conditionKey'

export type PropName =
  | 'height'
  | 'horizontalConstraints'
  | 'pushGraph'
  | 'visibility'
  | 'width'
  | 'xOffset'
  | 'yOffset'
  | 'positioning'
  | 'minWidth'
  | 'maxWidth'
  | 'minWidthEnabled'
  | 'maxWidthEnabled'
  | 'left'
  | 'right'

export default class ConditionResolver {
  private knownResolutions: Map<string, boolean> = new Map()

  constructor(private device: DeviceType) {}

  public requiresDeviceProp(
    propName: PropName,
    object: EditorObject,
    parentProvider: (object: EditorObject) => EditorObject,
    // Used to prevent infinite recursion
    breadcrumbs: Set<string> = new Set()
  ): boolean {
    const key = conditionKey(object, propName)

    if (breadcrumbs.has(key)) {
      // Bail early if this is a circular reference.
      return false
    }
    breadcrumbs.add(key)
    try {
      const previousResolution = this.knownResolutions.get(key)
      if (previousResolution !== undefined) {
        return previousResolution
      }
      let result

      if (propName === 'height') {
        result = this.requiresDeviceHeight(object, parentProvider, breadcrumbs)
      } else if (propName === 'horizontalConstraints') {
        result = this.requiresDeviceHorizontalConstraints(object)
      } else if (propName === 'pushGraph') {
        result = this.requiresDevicePushGraph(
          object,
          parentProvider,
          breadcrumbs
        )
      } else if (propName === 'visibility') {
        result = this.requiresDeviceVisibility(object)
      } else if (propName === 'width') {
        result = this.requiresDeviceWidth(object, parentProvider, breadcrumbs)
      } else if (propName === 'xOffset') {
        result = this.requiresDeviceXOffset(object, parentProvider, breadcrumbs)
      } else if (propName === 'yOffset') {
        result = this.requiresDeviceYOffset(object, parentProvider, breadcrumbs)
      } else if (propName === 'positioning') {
        result = this.requiresPositioning(object)
      } else if (
        propName === 'left' ||
        propName === 'right' ||
        propName === 'minWidth' ||
        propName === 'maxWidth' ||
        propName === 'minWidthEnabled' ||
        propName === 'maxWidthEnabled'
      ) {
        result = this.requiresWidthConstraint(object)
      } else {
        throw new Error(`Unknown propName: ${propName as string}`)
      }

      this.knownResolutions.set(key, result)

      return result
    } finally {
      breadcrumbs.delete(key)
    }
  }

  private hasDeviceLayoutEnabled(object: EditorObject): boolean {
    return hasDeviceLayoutEnabled(object, this.device)
  }

  private requiresDeviceHeight(
    object: EditorObject,
    parentProvider: (object: EditorObject) => EditorObject,
    // Used to prevent infinite recursion
    breadcrumbs: Set<string> = new Set()
  ) {
    const { type, children = [], libraryName } = object

    if (type === COMPONENT) {
      return true
    }

    if (
      type === LIBRARY_COMPONENT &&
      FIXED_POSITION_COMPONENTS.includes(libraryName as string)
    ) {
      return true
    }

    if (this.hasDeviceLayoutEnabled(object)) {
      return true
    }

    if (this.requiresDeviceProp('width', object, parentProvider, breadcrumbs)) {
      return true
    }

    for (const child of children) {
      if (
        this.requiresDeviceProp('yOffset', child, parentProvider, breadcrumbs)
      ) {
        return true
      }

      if (
        this.requiresDeviceProp('height', child, parentProvider, breadcrumbs)
      ) {
        return true
      }

      if (
        this.requiresDeviceProp(
          'visibility',
          child,
          parentProvider,
          breadcrumbs
        )
      ) {
        return true
      }
    }

    return false
  }

  private requiresDeviceHorizontalConstraints(object: EditorObject) {
    const { type } = object

    if (type === COMPONENT) {
      return false
    }

    // This is the only situation that causes device-specific constraints to be set
    return this.hasDeviceLayoutEnabled(object)
  }

  private requiresDevicePushGraph(
    object: EditorObject,
    parentProvider: (object: EditorObject) => EditorObject,
    // Used to prevent infinite recursion
    breadcrumbs: Set<string> = new Set()
  ) {
    const { children = [] } = object

    for (const child of children) {
      if (
        this.requiresDeviceProp('height', child, parentProvider, breadcrumbs)
      ) {
        return true
      }

      if (
        this.requiresDeviceProp('width', child, parentProvider, breadcrumbs)
      ) {
        return true
      }

      if (
        this.requiresDeviceProp('xOffset', child, parentProvider, breadcrumbs)
      ) {
        return true
      }

      if (
        this.requiresDeviceProp('yOffset', child, parentProvider, breadcrumbs)
      ) {
        return true
      }

      if (
        this.requiresDeviceProp(
          'visibility',
          child,
          parentProvider,
          breadcrumbs
        )
      ) {
        return true
      }
    }

    return false
  }

  private requiresDeviceVisibility(object: EditorObject) {
    return hiddenOnDevice(object, this.device)
  }

  private requiresDeviceWidth(
    object: EditorObject,
    parentProvider: (object: EditorObject) => EditorObject,
    // Used to prevent infinite recursion
    breadcrumbs: Set<string> = new Set()
  ) {
    const { type, children = [], libraryName } = object

    if (type === COMPONENT) {
      return false
    }

    if (
      type === LIBRARY_COMPONENT &&
      FIXED_POSITION_COMPONENTS.includes(libraryName as string)
    ) {
      return true
    }

    if (this.hasDeviceLayoutEnabled(object)) {
      return true
    }

    if (
      this.requiresDeviceProp(
        'horizontalConstraints',
        object,
        parentProvider,
        breadcrumbs
      )
    ) {
      return true
    }

    const parent = parentProvider(object)

    if (this.requiresDeviceProp('width', parent, parentProvider, breadcrumbs)) {
      return true
    }

    if (type === GROUP) {
      for (const child of children) {
        if (
          this.requiresDeviceProp('width', child, parentProvider, breadcrumbs)
        ) {
          return true
        }

        if (
          this.requiresDeviceProp('xOffset', child, parentProvider, breadcrumbs)
        ) {
          return true
        }

        if (
          this.requiresDeviceProp(
            'visibility',
            child,
            parentProvider,
            breadcrumbs
          )
        ) {
          return true
        }
      }
    }

    return false
  }

  private requiresDeviceXOffset(
    object: EditorObject,
    parentProvider: (object: EditorObject) => EditorObject,
    // Used to prevent infinite recursion
    breadcrumbs: Set<string> = new Set()
  ) {
    const { type, children = [] } = object

    if (type === COMPONENT) {
      return false
    }

    if (this.hasDeviceLayoutEnabled(object)) {
      return true
    }

    if (
      this.requiresDeviceProp(
        'horizontalConstraints',
        object,
        parentProvider,
        breadcrumbs
      )
    ) {
      return true
    }

    const parent = parentProvider(object)
    if (
      this.requiresDeviceProp('xOffset', parent, parentProvider, breadcrumbs)
    ) {
      return true
    }

    if (this.requiresDeviceProp('width', parent, parentProvider, breadcrumbs)) {
      return true
    }

    if (type === GROUP) {
      for (const child of children) {
        if (
          this.requiresDeviceProp('xOffset', child, parentProvider, breadcrumbs)
        ) {
          return true
        }

        if (
          this.requiresDeviceProp(
            'visibility',
            child,
            parentProvider,
            breadcrumbs
          )
        ) {
          return true
        }
      }
    }

    return false
  }

  private requiresDeviceYOffset(
    object: EditorObject,
    parentProvider: (object: EditorObject) => EditorObject,
    // Used to prevent infinite recursion
    breadcrumbs: Set<string> = new Set()
  ) {
    const { type, children = [] } = object

    if (type === COMPONENT) {
      return false
    }

    if (this.hasDeviceLayoutEnabled(object)) {
      return true
    }

    const parent = parentProvider(object)
    if (
      this.requiresDeviceProp('yOffset', parent, parentProvider, breadcrumbs)
    ) {
      return true
    }

    if (
      this.requiresDeviceProp('pushGraph', parent, parentProvider, breadcrumbs)
    ) {
      return true
    }

    if (type === GROUP) {
      for (const child of children) {
        if (
          this.requiresDeviceProp('yOffset', child, parentProvider, breadcrumbs)
        ) {
          return true
        }

        if (
          this.requiresDeviceProp(
            'visibility',
            child,
            parentProvider,
            breadcrumbs
          )
        ) {
          return true
        }
      }
    }

    return false
  }

  private requiresPositioning(object: EditorObject) {
    const { type } = object

    if (type === COMPONENT) {
      return false
    }

    return this.hasDeviceLayoutEnabled(object)
  }

  private requiresWidthConstraint(object: EditorObject) {
    const { type } = object

    if (type === COMPONENT) {
      return false
    }

    return this.hasDeviceLayoutEnabled(object)
  }
}
