import React, { Component } from 'react'

import Accordion from '../../../Shared/Accordion'
import ParamOption from './Param'

export default class Params extends Component {
  render() {
    const {
      params,
      appId,
      componentId,
      objectId,
      actionId,
      field,
      reference,
      options,
    } = this.props

    const paramValues = (options && options.params) || {}

    if (!params || params.length === 0) {
      return null
    }

    return (
      <Accordion
        title="Parameters"
        renderChildren={() => (
          <div>
            {params.map(param => (
              <ParamOption
                key={param.id}
                appId={appId}
                componentId={componentId}
                objectId={objectId}
                actionId={actionId}
                param={param}
                field={field}
                value={paramValues[param.id]}
                reference={reference}
              />
            ))}
          </div>
        )}
      />
    )
  }
}
