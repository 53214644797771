import { sortDirections, dataTypes } from '@adalo/constants'

export const getSortLabel = (type, direction) => {
  if (direction === sortDirections.ASC) {
    switch (type) {
      case dataTypes.TEXT:
        return 'A to Z'
      case dataTypes.NUMBER:
        return 'Low to High'
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return 'Oldest to Newest'
      case dataTypes.BOOLEAN:
        return 'False first'
      case dataTypes.LOCATION:
        return 'Closest to Farthest'
      default:
        return 'ascending'
    }
  } else {
    switch (type) {
      case dataTypes.TEXT:
        return 'Z to A'
      case dataTypes.NUMBER:
        return 'High to Low'
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return 'Newest to Oldest'
      case dataTypes.BOOLEAN:
        return 'True first'
      case dataTypes.LOCATION:
        return 'Farthest to Closest'
      default:
        return 'descending'
    }
  }
}
