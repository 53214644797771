/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable operator-assignment */
import { cloneDeep } from 'lodash'
import { DeviceType } from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'
import traverseChildren from './traverseChildren'

type PrebuiltObjectBody = Partial<EditorObject> & {
  contentChildren?: EditorObject['children']
}

const getFakeId = () =>
  `fake-id-${String(Math.random()).split('.')[1] as string}`

// Positions need to be normalized to 0,0. Otherwise ghosts will look offset and,
// the height of the object may get messed up when dropping from the sidebar.
const NORMALIZE_POSITIONS_TO_0 = true
/**
 *
 * Takes an EditorObject and returns an object that can be used as an input for the `createObject` action
 */
const getPrebuiltLayoutSectionBodyFromEditorObject = (
  obj: EditorObject
): PrebuiltObjectBody => {
  const initialX = obj.x ?? 0
  const initialY = obj.y ?? 0

  const initialCoordinatesByDevice: { [key: string]: any } = {}

  const devices = [DeviceType.DESKTOP, DeviceType.TABLET, DeviceType.MOBILE]
  for (const device of devices) {
    const initialDeviceX = obj[device]?.x
    const initialDeviceY = obj[device]?.y
    if (initialDeviceX !== undefined && initialDeviceY !== undefined) {
      initialCoordinatesByDevice[device] = {
        x: initialDeviceX,
        y: initialDeviceY,
      }
    }
  }

  let prebuiltObjBody: PrebuiltObjectBody = cloneDeep(obj)

  const shouldUpdatePositions =
    (initialX !== 0 || initialY !== 0) && NORMALIZE_POSITIONS_TO_0

  const shouldRemoveIds = true

  const idMap: Record<string, string> = {}

  traverseChildren(prebuiltObjBody, (innerObj: any) => {
    if (shouldRemoveIds && innerObj.id) {
      const fakeId = getFakeId()
      idMap[innerObj.id] = fakeId
      innerObj.id = fakeId
    }

    // Translate children
    if (shouldUpdatePositions) {
      if (innerObj?.x !== undefined && innerObj?.y !== undefined) {
        innerObj.x = innerObj.x - initialX
        innerObj.y = innerObj.y - initialY
      }
    }

    if (NORMALIZE_POSITIONS_TO_0) {
      for (const device of devices) {
        if (initialCoordinatesByDevice[device]) {
          if (
            innerObj[device]?.x !== undefined &&
            innerObj[device]?.y !== undefined
          ) {
            innerObj[device].x =
              innerObj[device].x - initialCoordinatesByDevice[device].x
            innerObj[device].y =
              innerObj[device].y - initialCoordinatesByDevice[device].y
          }
        }
      }
    }
  })

  if (Object.entries(idMap).length > 0) {
    let prebuiltObjBodyString = JSON.stringify(prebuiltObjBody)

    for (const [find, replace] of Object.entries(idMap)) {
      prebuiltObjBodyString = prebuiltObjBodyString.replace(
        new RegExp(find, 'g'),
        replace
      )
    }

    prebuiltObjBody = JSON.parse(prebuiltObjBodyString) as PrebuiltObjectBody
  }

  return prebuiltObjBody
}

export default getPrebuiltLayoutSectionBodyFromEditorObject
