import React, { Component } from 'react'
import classNames from 'classnames'

import './WrappedInput.css'

export default class WrappedInput extends Component {
  state = {
    focused: false,
  }

  handleChange = value => {
    const { input, onChangeValue } = this.props
    const { onChange } = input

    onChange(value)

    if (onChangeValue) {
      onChangeValue(value)
    }
  }

  handleFocus = e => {
    const { input } = this.props
    const { onFocus } = input

    if (onFocus) {
      onFocus(e)
    }

    this.setState({ focused: true })
  }

  handleBlur = e => {
    const { input } = this.props
    const { onBlur } = input

    if (onBlur) {
      onBlur(e)
    }

    this.setState({ focused: false })
  }

  stopPropagation = e => {
    e.stopPropagation()
  }

  forceFocus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  componentDidMount() {
    const { autoSelect } = this.props

    if (!autoSelect || !this.input) {
      return
    }

    this.input.focus()
    this.input.select()
  }

  inputRef = input => {
    this.input = input
  }

  render() {
    const { focused } = this.state

    const {
      meta,
      label,
      large,
      input,
      className,
      prefix,
      suffix,
      helpLink,
      ...props
    } = this.props
    const { disabled } = this.props

    const { touched, error } = meta

    return (
      <div
        onMouseDown={this.forceFocus}
        onMouseUp={this.forceFocus}
        className={classNames('wrapped-input', className, {
          'wrapped-input-error': touched && error,
          'wrapped-input-focused': focused,
          'wrapped-input-lg': large,
          'wrapped-input-disabled': disabled,
        })}
      >
        {label ? <label>{label}</label> : null}
        <div className="wrapped-input-inner-wrapper">
          {prefix ? <div className="wrapped-input-prefix">{prefix}</div> : null}
          <div className="wrapped-input-input">
            <input
              {...props}
              {...input}
              onChange={this.handleChange}
              onMouseDown={this.stopPropagation}
              onMouseUp={this.stopPropagation}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={this.inputRef}
            />
          </div>
          {suffix ? <div className="wrapped-input-suffix">{suffix}</div> : null}
        </div>
        {input.value && !error && helpLink}
        {touched && error ? (
          <div className="wrapped-input-error-message">{error}</div>
        ) : null}
      </div>
    )
  }
}
