import { EditorObject } from 'utils/responsiveTypes'
import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface DistributeHorizontalInstruction {
  operation: 'distributeHorizontal'
  options: ActionOptions
}

export const distributeHorizontalHandler = ({
  bounds, // eslint-disable-line @typescript-eslint/no-unused-vars
  objects,
}: HandlerOptions): LayoutInstruction[] => {
  const operations: LayoutInstruction[] = []

  if (objects.length < 3) {
    return operations
  }

  // sort objects from left to right
  objects.sort((a, b) => a.x - b.x)

  const leftAnchor = objects[0] as EditorObject
  const rightAnchor = objects[objects.length - 1] as EditorObject

  const middleObjects = objects.slice(1, -1)

  const distanceBetweenAnchors = rightAnchor.x - (leftAnchor.x + leftAnchor.width) // prettier-ignore
  const middleObjectWidth = middleObjects.reduce((sum, obj) => sum + obj.width, 0) // prettier-ignore
  const gap = (distanceBetweenAnchors - middleObjectWidth) / (objects.length - 1) // prettier-ignore
  let currentX = leftAnchor.x + leftAnchor.width + gap

  for (const object of middleObjects) {
    operations.push(moveElement(object.id, currentX, object.y))

    currentX += object.width + gap
  }

  return operations
}

const distributeHorizontal = (
  objectIds: string[]
): DistributeHorizontalInstruction => ({
  operation: 'distributeHorizontal',
  options: { objectIds },
})

export default distributeHorizontal
