/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { v4 as uuid } from 'uuid'
import { DeviceType as DeviceTypeConst, DeviceWidth } from '@adalo/constants'
import { getDeviceType, update } from '@adalo/utils'
import {
  applyInstructions,
  enableDeviceSpecificLayout,
  moveElement,
  resizeElement,
  resizeScreen,
} from 'ducks/editor/instructions'
import { ObjectList } from 'ducks/editor/types/ObjectList'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import DeviceType from 'ducks/editor/types/DeviceType'
import { changeObjectParent } from 'utils/positioning'
import { EditorObject } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import { getLayoutHelperFromSection } from '..'
import getLayoutSectionFitForScreen from './getLayoutSectionFitForScreen'
import { buildDefaultLayoutSection } from './buildDefaultLayoutSection'

const devices = Object.values(DeviceTypeConst)

const SCREEN_SIZES = {
  mobile: DeviceWidth.MOBILE_DEFAULT_WIDTH,
  tablet: DeviceWidth.TABLET_DEFAULT_WIDTH,
  desktop: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
}

type Result = { list: ObjectList; pathMap: ObjectPathMap }

/**
 * Creates a new empty layout section and adjust it for the parent screen
 */
const createEmptyLayoutSection = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  screen: EditorObject,
  object: EditorObject
): Result => {
  let updatedList = list
  let updatedPathMap = pathMap
  const outerSectionId = uuid()
  const innerSectionId = uuid()

  const { width: screenWidth } = screen
  const currentDevice = getDeviceType(screenWidth)

  const deviceScreen = getDeviceObject(screen, currentDevice)
  const screenHeight = deviceScreen.height

  const newObject = buildDefaultLayoutSection(object, {
    screenWidth,
    outerSectionId,
    innerSectionId,
  }) as EditorObject

  const list0 = update(list, pathMap[newObject.id], newObject) as ObjectList

  const [list1, map1] = changeObjectParent(
    list0,
    pathMap,
    outerSectionId,
    newObject.id
  )

  const [list2, map2] = changeObjectParent(
    list1,
    map1,
    innerSectionId,
    outerSectionId
  )

  updatedList = list2
  updatedPathMap = map2

  const fitForScreen = getLayoutSectionFitForScreen(screen)
  const hasDeviceSpecificFit = Object.keys(fitForScreen).some(k =>
    devices.includes(k as DeviceType)
  )

  if (hasDeviceSpecificFit) {
    const outerSection = getLayoutHelperFromSection(newObject)
    const instructions = []
    for (const device of devices) {
      if (fitForScreen) {
        const x = fitForScreen[device]?.x
        const width = fitForScreen[device]?.width

        if (x || width) {
          // Need to enable device-specific layout before running a move or resize instruction
          instructions.push(enableDeviceSpecificLayout(newObject.id, device))
        }

        const screenWidthForDevice =
          device === currentDevice ? screenWidth : SCREEN_SIZES[device]

        const heightForDevice = newObject[device]?.height ?? newObject.height

        instructions.push(
          resizeScreen(screen.id, screenWidthForDevice, screenHeight)
        )

        if (x) {
          instructions.push(moveElement(newObject.id, x, newObject.y))
          const outerSectionY = outerSection[device]?.y ?? outerSection.y
          instructions.push(moveElement(outerSectionId, x, outerSectionY))
        }

        if (width) {
          instructions.push(resizeElement(newObject.id, width, heightForDevice))
        }
      }
    }

    // set screen back to original size
    instructions.push(resizeScreen(screen.id, screenWidth, screenHeight))
    const result = applyInstructions(
      { list: updatedList, pathMap: updatedPathMap, selection: [] },
      instructions
    )
    updatedList = result.list
    updatedPathMap = result.pathMap
  }

  return { list: updatedList, pathMap: updatedPathMap }
}

export default createEmptyLayoutSection
