interface ObjectWithChildren {
  children?: ObjectWithChildren[]
}

function traverseChildren<T extends ObjectWithChildren>(
  object: T | T[],
  callback: (o: T) => void
): void {
  // Array
  if (Array.isArray(object)) {
    for (const obj of object) {
      traverseChildren(obj, callback)
    }

    return
  }

  // Non-object
  if (!object || typeof object !== 'object') {
    return
  }

  if (Array.isArray(object.children) && object.children.length > 0) {
    traverseChildren(object.children as T[], callback)
  }

  callback(object)
}

export default traverseChildren
