const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS'

const INITIAL_STATE = {}

// Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FEATURE_FLAGS: {
      const { flags } = action

      return flags
    }

    default:
      return state
  }
}

// Actions
export const setFeatureFlags = flags => ({
  type: SET_FEATURE_FLAGS,
  flags,
})

// Selectors
export const getFeatureFlag = (state, featureFlag) =>
  state.featureFlags[featureFlag]
