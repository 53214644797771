import React from 'react'

import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'

import './DropdownLabel.scss'

export default function DropdownLabel({ Title, tooltip }) {
  return (
    <label className="permissions-dropdown-label">
      <span className="permissions-dropdown-label__title">
        {Title && <Title />}
      </span>
      {tooltip && (
        <Tooltip hideArrow placement="bottom" tooltip={tooltip}>
          <Icon
            className="permissions-dropdown-label__tooltip"
            type="help-small"
            small
          />
        </Tooltip>
      )}
    </label>
  )
}
