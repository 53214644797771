import React from 'react'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

// components
import Icon from 'components/Shared/Icon'

// utils
import { capitalize } from 'utils/type'

const MenuOption = value => ({
  value,
  label: `Align ${capitalize(value)}`,
  icon: `text-align-${value}`,
})

const options = [MenuOption('left'), MenuOption('center'), MenuOption('right')]

const TextAlignPicker = ({ onChange, name, value }) => {
  const handleSelection = value => {
    return onChange({ [name]: value })
  }

  return (
    <MultiMenuTrigger menu={options} onSelect={handleSelection} rowHeight={40}>
      <Icon type={`text-align-${value}`} />
    </MultiMenuTrigger>
  )
}

TextAlignPicker.defaultProps = {
  value: 'left',
}

export default TextAlignPicker
