import { comparators, comparatorLabels, dataTypes } from '@adalo/constants'
import { MenuOption } from 'ducks/recommender'

const comparatorMapping = {
  [dataTypes.NUMBER]: [
    comparators.EQUAL,
    comparators.NOT_EQUAL,
    comparators.GREATER_THAN,
    comparators.LESS_THAN,
    comparators.GREATER_THAN_OR_EQUAL,
    comparators.LESS_THAN_OR_EQUAL,
    comparators.BETWEEN,
  ],
  [dataTypes.DATE]: [
    comparators.GREATER_THAN,
    comparators.LESS_THAN,
    comparators.BETWEEN,
  ],
  [dataTypes.DATE_ONLY]: [
    comparators.EQUAL,
    comparators.GREATER_THAN,
    comparators.LESS_THAN,
    comparators.BETWEEN,
  ],
  [dataTypes.LOCATION]: [
    comparators.DISTANCE_LESS_THAN,
    comparators.DISTANCE_GREATER_THAN,
  ],
  [dataTypes.TEXT]: [
    comparators.EQUAL,
    comparators.NOT_EQUAL,
    comparators.CONTAINS,
  ],
  [dataTypes.BOOLEAN]: [comparators.TRUE, comparators.FALSE],
  [dataTypes.LIST]: [comparators.LIST_CONTAINS, comparators.LIST_NOT_CONTAINS],
}

const comparatorIconMapping = {
  [comparators.EQUAL]: 'equal-to',
  [comparators.NOT_EQUAL]: 'not-equal-to',
  [comparators.GREATER_THAN]: {
    [dataTypes.DATE]: 'after',
    [dataTypes.DATE_ONLY]: 'after',
    [dataTypes.NUMBER]: 'greater-than',
  },
  [comparators.LESS_THAN]: {
    [dataTypes.DATE]: 'before',
    [dataTypes.DATE_ONLY]: 'before',
    [dataTypes.NUMBER]: 'less-than',
  },
  [comparators.GREATER_THAN_OR_EQUAL]: 'greater-or-equal-to',
  [comparators.LESS_THAN_OR_EQUAL]: 'less-than-or-equal-to',
  [comparators.BETWEEN]: 'between',
  [comparators.DISTANCE_LESS_THAN]: 'within',
  [comparators.DISTANCE_GREATER_THAN]: 'not-within',
  [comparators.TRUE]: 'checkbox',
  [comparators.FALSE]: 'checkbox-empty',
  [comparators.LIST_CONTAINS]: 'contains',
  [comparators.CONTAINS]: 'contains',
  [comparators.LIST_NOT_CONTAINS]: 'not-contains',
}

const defaultMapping = [comparators.EQUAL, comparators.NOT_EQUAL]

const literalComparators = new Set([comparators.TRUE, comparators.FALSE])

const listComparators = new Set([
  comparators.LIST_CONTAINS,
  comparators.LIST_NOT_CONTAINS,
])

export const getComparatorOptions = dataType => {
  const options = comparatorMapping[dataType] || defaultMapping

  let labels = comparatorLabels

  if (dataType === dataTypes.DATE || dataType === dataTypes.DATE_ONLY) {
    labels = {
      ...comparatorLabels,
      [comparators.GREATER_THAN]: 'Is after',
      [comparators.LESS_THAN]: 'Is before',
    }
  }

  return options.map(
    value =>
      new MenuOption(
        labels[value],
        value,
        comparatorIconMapping[value][dataType] || comparatorIconMapping[value]
      )
  )
}

export const isLiteralComparator = comparator => {
  return literalComparators.has(comparator)
}

/**
 * @typedef {import('@adalo/constants').DataType} DataType
 *
 * @param {import('@adalo/constants').Comparator | undefined} comparator
 * @param {import('@adalo/constants').BindingSource | undefined} source
 * @returns {Array<{type: DataType, tableId: string} | DataType>}
 */

export const getComparisonDataTypes = (comparator, source) => {
  if (!source || !comparator) {
    return []
  }

  if (listComparators.has(comparator)) {
    return [{ type: dataTypes.OBJECT, tableId: source.tableId }]
  }

  return [source.dataType]
}
