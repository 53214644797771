import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import '@protonapp/react-multi-menu/styles.css'

import { getLabel } from '../../../utils/sources'
import { updateObject } from '../../../ducks/editor/objects'
import { getBindingSuggestions } from '../../../ducks/recommender'
import EmptyState from '../../Shared/EmptyState'
import Button from '../../Shared/Button'
import Icon from '../../Shared/Icon'
import Binding from './Binding'
import './DataBinding.scss'

class DataBinding extends Component {
  handleSelect = binding => {
    if (!binding) {
      return
    }

    const { updateObject, object } = this.props

    updateObject(object.id, { dataBinding: binding })
  }

  handleRemove = () => {
    const { object, updateObject } = this.props

    updateObject(object.id, { dataBinding: undefined })
  }

  getMenuItems() {
    const { bindingOptions } = this.props

    return bindingOptions
  }

  render() {
    const {
      bindingOptions,
      sourceOptions,
      dataBinding,
      appId,
      componentId,
      object,
      label,
    } = this.props

    return (
      <div className="data-binding-panel">
        {dataBinding ? (
          [
            <Binding
              key={object.id}
              appId={appId}
              componentId={componentId}
              objectId={object.id}
              binding={dataBinding}
              bindingOptions={sourceOptions}
              onChange={this.handleSelect}
              label={label}
              onRemove={this.handleRemove}
            />,
          ]
        ) : (
          <EmptyState>
            <MultiMenuTrigger
              menu={bindingOptions}
              onSelect={this.handleSelect}
            >
              <Button>
                <span>Add Connection</span>
                <Icon type="expand-vertical" />
              </Button>
            </MultiMenuTrigger>
          </EmptyState>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, componentId, object }) => {
  const binding = object.dataBinding
  let label

  if (binding) {
    label = getLabel(state, binding.source, appId, componentId)
  }

  return {
    label,
    bindingOptions: getBindingSuggestions(state, appId, componentId, object.id),
    sourceOptions: getBindingSuggestions(
      state,
      appId,
      componentId,
      object.id,
      true,
      binding && binding.bindingType
    ),
    dataBinding: binding,
  }
}

export default connect(mapStateToProps, { updateObject })(DataBinding)
