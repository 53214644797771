import ObjectType from '../types/ObjectType'
import ObjectNode from './ObjectNode'
import ObjectTreeVisitor from './ObjectTreeVisitor'
import ScreenNode from './ScreenNode'

class ObjectSearchVisitor extends ObjectTreeVisitor<ObjectNode<ObjectType>[]> {
  constructor(
    private readonly test: (node: ObjectNode<ObjectType>) => boolean
  ) {
    super()
  }

  public override visitObjectNode<T extends ObjectType>(
    node: ObjectNode<T>
  ): ObjectNode<ObjectType>[] {
    const result = []
    if (this.test(node)) {
      result.push(node)
    }

    const childResults = this.visitChildren(node)

    return [...result, ...childResults]
  }

  // eslint-disable-next-line class-methods-use-this
  getDefaultResult() {
    return []
  }

  // eslint-disable-next-line class-methods-use-this
  aggregateResults(
    a: ObjectNode<ObjectType>[],
    b: ObjectNode<ObjectType>[]
  ): ObjectNode<ObjectType>[] {
    return [...a, ...b]
  }
}

export default class Document {
  constructor(private readonly screen: ScreenNode) {
    if (screen.getDocument() !== undefined) {
      throw new Error(`Screen cannot be added to more than one document.`)
    }

    screen.setDocument(this)
  }

  public getScreen(): ScreenNode {
    return this.screen
  }

  public getObjectById(objectId: string): ObjectNode<ObjectType> | undefined {
    if (this.screen === undefined) {
      return undefined
    }

    const visitor = new ObjectSearchVisitor(({ id }) => id === objectId)
    const [result] = this.screen.visit(visitor)

    return result
  }
}
