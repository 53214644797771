import DeviceType from 'ducks/editor/types/DeviceType'
import { DeviceType as DeviceTypeValues } from '@adalo/constants'

export const getLayoutSettingsGroup = (objectId: string): string =>
  `${objectId}-device-layout`

export const getLayoutSettingsItemId = (
  objectId: string,
  device: DeviceType | 'shared'
): string => `${objectId}-${device}`

const devices = Object.values(DeviceTypeValues)

const isValidLayoutSettingsSuffix = (t: string): t is DeviceType | 'shared' =>
  [...devices, 'shared'].includes(t)

export const getLayoutSettingsItemIdDevice = (
  itemId?: string
): DeviceType | 'shared' | undefined => {
  const device = itemId?.split?.('-')?.pop() ?? ''
  if (!isValidLayoutSettingsSuffix(device)) {
    console.warn(
      `Invalid layout settings tab item id: ${itemId ?? 'undefined'}`
    )

    return undefined
  }

  return device
}
