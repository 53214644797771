import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adaloBackendAxios } from 'utils/io/http/axios'
import { v4 as uuid } from 'uuid'
import Textarea from 'react-textarea-autosize'
import Loading from 'components/Shared/Loading'
import { IconButton } from 'components/Shared/Icon'
import GoogleHelpLink, {
  GoogleUpgradeLink,
} from 'components/Shared/GoogleHelpLink'
import Select from 'react-select'

import {
  invalidateThirdPartyApiKey,
  getThirdPartyApiKeyFor,
} from 'ducks/thirdPartyApiKeys'
import { getCurrentUser } from 'ducks/users/index.ts'
import { isFeatureEnabled } from 'ducks/organizations'

import PropTypes from 'prop-types'

import { DEBOUNCE_TIME, COUNTRY_CODES, US_STATES } from '../../../../constants'

import Predictions from './Predictions'

import './GooglePlacesInput.scss'

const INVALID_API_KEY_ERROR = 'The provided API key is invalid.'

const SELECT_MAP = ({ name }) => ({ label: name, value: name })

const REGION_OPTIONS = {
  'United States': {
    name: 'State',
    options: US_STATES.map(SELECT_MAP),
  },
}

const SELECT_STYLES = {
  minHeight: 50,
  borderRadius: '6px',
  marginBottom: '3px',
  paddingLeft: '8px',
  fontSize: '14px',
}

const GooglePlacesInput = ({
  input: { name: fieldId, onChange, value: location },
  appId,
  panelView,
  inFormField,
}) => {
  const dispatch = useDispatch()

  const googleApiKey = useSelector(state =>
    getThirdPartyApiKeyFor(state, appId, 'google')
  )

  const currentUser = useSelector(getCurrentUser)

  const geolocationFlag = useSelector(state =>
    isFeatureEnabled(state, 'geolocation')
  )

  const isLocationDisabled = !geolocationFlag

  const isAdmin = !!(currentUser && currentUser.admin)

  // Prop-Derived State
  const isDisabled =
    !googleApiKey || !googleApiKey.isValid || isLocationDisabled

  // Component State
  const [placePredictions, setPlacePredictions] = useState([])
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const [error, setError] = useState('')
  const [sessionToken, setSessionToken] = useState(uuid())
  const [isAddressValid, setIsAddressValid] = useState(true)
  const [searchValue, setSearchValue] = useState(location?.fullAddress || '')
  const [isAddressMode, setIsAddressMode] = useState(false)
  const [placeholder, setPlaceholder] = useState('Search for an address...')

  const hasSearchValue = searchValue && searchValue.length

  // References
  const inputRef = useRef()
  const predictionsRef = useRef()
  const predictionsTimeout = useRef()

  // Actions
  const closePredictions = () => {
    setPlacePredictions([])

    if (searchValue && !searchValue.trim()) {
      setSearchValue('')
    }

    if (!isAddressValid) {
      setIsAddressMode(false)
      setError('Location not found')
    }
  }

  const fetchPredictions = formattedValue => async () => {
    try {
      setIsGoogleLoading(true)

      const { data } = await adaloBackendAxios.get(
        `/proxy-api/google/${appId}`,
        {
          params: {
            search: formattedValue,
            token: sessionToken,
          },
        }
      )

      setPlacePredictions(data)
    } catch (err) {
      const errorMessage = err.response.data.message
      setError(errorMessage)
      setPlaceholder('Oops. Something went wrong...')
      setIsAddressValid(true)

      if (location) {
        setSearchValue(location.fullAddress)
      } else {
        setSearchValue('')
      }

      if (errorMessage === INVALID_API_KEY_ERROR) {
        dispatch(invalidateThirdPartyApiKey(appId, 'google'))
      }
    } finally {
      setIsGoogleLoading(false)
    }
  }

  const refreshPredictions = async ({ target: { value } }) => {
    clearTimeout(predictionsTimeout.current)

    setSearchValue(value)
    setIsAddressValid(false)
    setError('')

    const formattedValue = value.trim()

    if (formattedValue) {
      predictionsTimeout.current = setTimeout(
        fetchPredictions(formattedValue),
        DEBOUNCE_TIME
      )
    } else {
      onChange(null)
      setPlacePredictions([])
      setIsAddressValid(true)
      setIsAddressMode(false)
    }
  }

  const choosePrediction = async ({ id: placeId }) => {
    try {
      const { data } = await adaloBackendAxios.get(`/google`, {
        params: {
          placeId,
          token: sessionToken,
          appId,
        },
      })

      setIsAddressValid(true)

      if (isAddressMode) {
        setSearchValue(data.addressElements.address1)
      } else {
        setSearchValue(data.fullAddress)
      }

      onChange(data)
    } catch (err) {
      setError(err.response.data.message)
    }

    // start a new Google Places session after a place was selected and a place details request was made
    setSessionToken(uuid())
    setPlacePredictions([])
  }

  const tuneAddress = field => input => {
    onChange({
      ...location,
      addressElements: {
        ...location.addressElements,
        // value is on the target prop for input fields
        // and on the input object for select fields
        [field]: input.target?.value || input.value,
      },
    })
  }

  const toggleAddressMode = () => {
    if (isAddressMode) {
      setIsAddressMode(false)
      setSearchValue(location.fullAddress)
    } else {
      setIsAddressMode(true)
      setSearchValue(location.addressElements.address1 || location.fullAddress)
    }
  }

  // Closes menu if you click outside of it or if the window is defocused
  useEffect(() => {
    const clickedOutsidePredictions = e => {
      if (
        !inputRef.current?.contains(e.target) &&
        !predictionsRef.current?.contains(e.target)
      ) {
        closePredictions()
      }
    }

    const clickedOutsideWindow = () => closePredictions()

    if (hasSearchValue) {
      document.addEventListener('mousedown', clickedOutsidePredictions)
      window.addEventListener('blur', clickedOutsideWindow)
    }

    return () => {
      document.removeEventListener('mousedown', clickedOutsidePredictions)
      window.removeEventListener('blur', clickedOutsideWindow)
    }
  }, [isAddressValid, searchValue, hasSearchValue])

  // Interface Elements
  let addressComponents = null
  let inputIcon = null
  let errorWithHelpLink = null

  if (isAddressMode) {
    let regionInput = null

    if (REGION_OPTIONS[location.addressElements.country]) {
      regionInput = (
        <Select
          options={REGION_OPTIONS[location.addressElements.country].options}
          value={{
            label: location.addressElements.region,
            value: location.addressElements.region,
          }}
          placeholder={REGION_OPTIONS[location.addressElements.country].name}
          onChange={tuneAddress('region')}
          styles={{
            control: base => ({
              ...base,
              ...SELECT_STYLES,
              border: inFormField ? '1px solid #ffffff' : '1px solid #e0e0e0',
            }),
          }}
        />
      )
    } else {
      regionInput = (
        <Textarea
          className="data-object-form-input-input"
          value={location.addressElements.region}
          placeholder="Region"
          onChange={tuneAddress('region')}
        />
      )
    }

    addressComponents = (
      <>
        <Select
          options={COUNTRY_CODES.map(SELECT_MAP)}
          value={{
            label: location.addressElements.country,
            value: location.addressElements.country,
          }}
          placeholder="Country"
          onChange={tuneAddress('country')}
          styles={{
            control: base => ({
              ...base,
              ...SELECT_STYLES,
              border: inFormField ? '1px solid #ffffff' : '1px solid #e0e0e0',
            }),
          }}
        />
        <Textarea
          className="data-object-form-input-input"
          value={location.addressElements.address2}
          placeholder="Apt/Suite/Unit"
          onChange={tuneAddress('address2')}
        />
        <Textarea
          className="data-object-form-input-input"
          value={location.addressElements.city}
          placeholder="City"
          onChange={tuneAddress('city')}
        />
        <Textarea
          className="data-object-form-input-input"
          value={location.addressElements.postalCode}
          placeholder="Postal Code"
          onChange={tuneAddress('postalCode')}
        />
        {regionInput}
      </>
    )
  }

  if (isGoogleLoading) {
    inputIcon = <Loading small />
  } else if (location && isAddressValid && isAdmin && !isDisabled) {
    inputIcon = <IconButton type="tune" onClick={toggleAddressMode} />
  }

  if (error) {
    switch (error) {
      case INVALID_API_KEY_ERROR:
        errorWithHelpLink = <GoogleHelpLink isError />

        break
      default:
        errorWithHelpLink = <div className="settings-form-error">{error}</div>
    }
  } else if (isLocationDisabled) {
    errorWithHelpLink = <GoogleUpgradeLink appId={appId} />
  } else {
    errorWithHelpLink = <GoogleHelpLink />
  }

  // Classes
  let inputClass = 'autocomplete-input__input-container'

  const inputShouldBe = modifier =>
    `${inputClass} autocomplete-input__input-container--${modifier}`

  if (placePredictions.length) {
    inputClass = inputShouldBe('elevated')
  }

  if (isDisabled) {
    inputClass = inputShouldBe('disabled')
  }

  if (error) {
    inputClass = inputShouldBe('invalid')
  }

  if (panelView) {
    inputClass = inputShouldBe('panel')
  }

  return (
    <>
      <div id={fieldId} className="autocomplete-input" ref={inputRef}>
        <div className={inputClass}>
          <Textarea
            className="autocomplete-input__input"
            value={searchValue}
            onChange={refreshPredictions}
            placeholder={placeholder}
            disabled={isDisabled}
            cacheMeasurements
          />
          {inputIcon}
        </div>
      </div>
      {placePredictions.length ? (
        <Predictions
          ref={predictionsRef}
          predictions={placePredictions}
          choosePrediction={choosePrediction}
          closePredictions={closePredictions}
          inputId={fieldId}
          panelView={panelView}
          inFormField={inFormField}
        />
      ) : null}
      {errorWithHelpLink}
      {addressComponents}
    </>
  )
}

GooglePlacesInput.propTypes = {
  appId: PropTypes.string.isRequired,
}

export default GooglePlacesInput
