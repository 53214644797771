import React, { Component } from 'react'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { connect } from 'react-redux'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import { WrappedBindableTextControl } from 'components/Shared/BindableTextControl'
import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'
import Stepper from 'components/Shared/Stepper'
import Icon from 'components/Shared/Icon'

import AuthFields from './AuthFields'
import Endpoints from './Endpoints'
import TestConnection from './TestConnection'
import { validate } from './validation'

export const FORM_NAME = 'addAPIForm'

const NameAndURL = () => {
  return (
    <>
      <Field
        name="name"
        label="Collection Name"
        placeholder="Tasks"
        component={WrappedInput}
        autoComplete="off"
      />
      <Field
        name="baseURL"
        label="API Base URL"
        placeholder="https://api.example.com/v1/tasks"
        component={WrappedBindableTextControl}
        autoComplete="off"
        disableFormulas
        externalAPIField
        enableTestValue
        apiBaseURL
        fromModal
      />
      <FieldArray name="auth" component={AuthFields} />
    </>
  )
}

class AddAPIForm extends Component {
  renderContent() {
    const { step } = this.props

    if (step === 0) {
      return <NameAndURL />
    } else if (step === 1) {
      return <Endpoints />
    } else {
      return <Field name="testResults" component={TestConnection} />
    }
  }

  render() {
    const { handleSubmit, step, handleBack, isUpdate, formValues } = this.props

    let saveText = isUpdate ? 'Done' : 'Create Collection'

    let disabled = !isUpdate && step === 2

    if (formValues && step === 2) {
      const { testResults } = formValues

      if (testResults?.success === true) {
        saveText = 'Save Collection'
        disabled = false
      } else if (testResults?.success === false) {
        saveText = isUpdate ? 'Cancel' : 'Create Collection'
        disabled = !isUpdate
      }
    }

    const stepLabels = {
      0: 'CANCEL',
      1: 'EDIT NAME & URL',
      2: 'EDIT ENDPOINTS',
    }

    return (
      <form onSubmit={handleSubmit}>
        <Stepper
          color="orange"
          steps={['Name & URL', 'Endpoints', 'Test Connection']}
          currentStep={step}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Actions
          leftButtons={[
            <Button text onClick={handleBack} type="button">
              {step === 0 ? (
                stepLabels[step]
              ) : (
                <>
                  <Icon type="arrow-back" small /> {stepLabels[step]}
                </>
              )}
            </Button>,
          ]}
        >
          <Button orange type="submit" disabled={disabled}>
            {step === 2 ? saveText : 'Next'}
          </Button>
        </Modal.Actions>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  formValues: state.form[FORM_NAME]?.values,
})

const ReduxFormWrapper = reduxForm({
  form: FORM_NAME,
  validate,
})(AddAPIForm)

export default connect(mapStateToProps)(ReduxFormWrapper)
