import { EditorObject } from 'utils/responsiveTypes'
import { responsivePositioningOptions } from '@adalo/constants'

const { FIXED_ON_SCROLL } = responsivePositioningOptions

const usesAbsolutePosition = (object: EditorObject): boolean => {
  const { responsivity: { verticalPositioning } = {} } = object

  return verticalPositioning === FIXED_ON_SCROLL
}

export default usesAbsolutePosition
