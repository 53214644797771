import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import TrialModal from 'components/Shared/TrialWarning/TrialModal'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import { fetchOrganizations } from 'ducks/organizations'
import { getLastApp } from 'ducks/apps'
import {
  getAnnualToggle,
  fetchSubscription,
  getSubscription,
  setAnnualFlag,
  setUpdateCardFlag,
  setUpdatePaymentSettingsFlag,
  getUpdatePaymentSettingsFlag,
  setPlanSelectedFlag,
  setSelectedPlanValue,
  getSwitchToYearlyFlag,
} from 'ducks/trialWarning'

import { createStripeCustomerPortal } from 'utils/billing.ts'

import './PaymentMethods.scss'

class PaymentMethods extends Component {
  componentDidMount() {
    const { organization, fetchSubscription } = this.props
    fetchSubscription(organization?.id)
  }

  showPaymentSettings = () => {
    const {
      subscription,
      setAnnualFlag,
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setSelectedPlanValue,
    } = this.props

    const { planInterval, planType } = subscription

    if (planInterval === 'month') {
      setAnnualFlag(false)
    } else {
      setAnnualFlag(true)
    }

    setSelectedPlanValue(planType)
    setUpdateCardFlag(true)
    setUpdatePaymentSettingsFlag(true)
  }

  customerPortalRedirect = async () => {
    const { organization, appId } = this.props

    await createStripeCustomerPortal(organization.id, appId)
  }

  handleClickBackChangePlan = () => {
    const {
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setPlanSelectedFlag,
      setSelectedPlanValue,
    } = this.props

    setUpdatePaymentSettingsFlag(false)
    setUpdateCardFlag(false)
    setPlanSelectedFlag(false)
    setSelectedPlanValue('')
  }

  renderPaymentSettingsForm() {
    const { fetchOrganizations, appId, subscription, annual, organization } =
      this.props

    const defaultPlan = 'professional2023'

    const planType = subscription?.planType || defaultPlan

    return (
      <TrialModal
        organizationId={organization?.id}
        forceRefresh={fetchOrganizations}
        appId={appId}
        initialValues={{ plan: planType, annual }}
        onClickBackChangePlan={this.handleClickBackChangePlan}
      />
    )
  }

  render() {
    const { subscription, updatePaymentSettings } = this.props

    const { cardNumber, cardholder, cardExpMonth, cardExpYear, cardBrand } =
      subscription || {}

    const expDate = moment(`${cardExpMonth}-15-${cardExpYear}`).format(
      'MM/YYYY'
    )

    const holderName = cardholder || 'Cardholder'

    const hasCard =
      subscription &&
      subscription?.configured &&
      cardNumber &&
      cardBrand &&
      expDate

    return (
      <div id="payment-methods">
        <div className="plan-services-header">
          <h4>Payment Methods</h4>
          <a
            onClick={this.showPaymentSettings}
            className="settings-link-button"
            data-adalo-id="update-card-billing"
          >
            {hasCard ? 'Update Card' : 'Add Card'}
          </a>
        </div>
        {hasCard ? (
          <div className="saved-card-box">
            <div className="card-info">
              <div className="card-icon">
                <Icon big type="credit-card" />
              </div>
              <div>
                <h4>
                  {cardBrand?.toUpperCase()} **** **** {cardNumber}
                </h4>
                <p>
                  {holderName} | Expires {expDate}
                </p>
              </div>
            </div>
            <Icon big color="teal" type="check-circle" />
          </div>
        ) : (
          <div className="no-saved-card-box">
            <div className="card-info">
              <div className="card-icon">
                <Icon big type="credit-card" />
              </div>
              <p>No card saved</p>
            </div>
          </div>
        )}
        <Button
          small
          outlined
          teal
          onClick={this.customerPortalRedirect}
          data-adalo-id="view-invoices-billing"
        >
          View Invoices
        </Button>
        {updatePaymentSettings && this.renderPaymentSettingsForm()}
      </div>
    )
  }
}

const mapStateToProps = (state, { match, organization }) => ({
  appId: match.params.appId,
  lastAppId: getLastApp(state),
  subscription: getSubscription(state, organization?.id),
  annual: getAnnualToggle(state),
  updatePaymentSettings: getUpdatePaymentSettingsFlag(state),
  switchToYearly: getSwitchToYearlyFlag(state),
})

const connected = connect(mapStateToProps, {
  fetchSubscription,
  fetchOrganizations,
  setAnnualFlag,
  setUpdateCardFlag,
  setUpdatePaymentSettingsFlag,
  setPlanSelectedFlag,
  setSelectedPlanValue,
})(PaymentMethods)

export default withRouter(connected)
