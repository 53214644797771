import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

function calculateProgressStyle(radius, percentage) {
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * percentage) / 100

  const progressStyle = {
    strokeDasharray: dashArray,
    strokeDashoffset: dashOffset,
  }

  return progressStyle
}

const CircularProgressBar = ({ sqSize, strokeWidth, showText, percentage }) => {
  const radius = (sqSize - strokeWidth) / 2
  const viewBox = `0 0 ${sqSize} ${sqSize}`
  const halfSqSize = sqSize / 2
  const strokeWidthPx = `${strokeWidth}px`

  const progressStyle = useMemo(
    () => calculateProgressStyle(radius, percentage),
    [radius, percentage]
  )

  // Start progress marker at 12 O'Clock
  const progressTransform = `rotate(-90 ${halfSqSize} ${halfSqSize})`

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={halfSqSize}
        cy={halfSqSize}
        r={radius}
        strokeWidth={strokeWidthPx}
      />
      <circle
        className="circle-progress"
        cx={halfSqSize}
        cy={halfSqSize}
        r={radius}
        strokeWidth={strokeWidthPx}
        transform={progressTransform}
        style={progressStyle}
      />
      {showText ? (
        <text
          className="circle-text"
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          {percentage}%
        </text>
      ) : null}
    </svg>
  )
}

CircularProgressBar.propTypes = {
  sqSize: PropTypes.number,
  percentage: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number,
  showText: PropTypes.bool,
}

CircularProgressBar.defaultProps = {
  sqSize: 200,
  strokeWidth: 10,
  showText: true,
}

export default React.memo(CircularProgressBar)
