export const freeFontOptions = [
  { label: 'Source Sans Pro', value: 'Source Sans Pro' },
  { label: 'Oswald', value: 'Oswald' },
  { label: 'Merriweather', value: 'Merriweather' },
]

export const popularHeadingFonts = [
  { label: 'Playfair Display', value: 'Playfair Display' },
  { label: 'Source Serif Pro', value: 'Source Serif Pro' },
  { label: 'Oswald', value: 'Oswald' },
  { label: 'Merriweather', value: 'Merriweather' },
  { label: 'Roboto Slab', value: 'Roboto Slab' },
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Spectral', value: 'Spectral' },
  { label: 'Oleo Script', value: 'Oleo Script' },
  { label: 'Gentium Basic', value: 'Gentium Basic' },
  { label: 'Lora', value: 'Lora' },
  { label: 'Caveat', value: 'Caveat' },
  { label: 'Libre Baskerville', value: 'Libre Baskerville' },
  { label: 'BioRhyme', value: 'BioRhyme' },
  { label: 'Ubuntu', value: 'Ubuntu' },
  { label: 'Rubik', value: 'Rubik' },
]

export const popularBodyFonts = [
  { label: 'Open Sans', value: 'Open Sans' },
  { label: 'Lato', value: 'Lato' },
  { label: 'Raleway', value: 'Raleway' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Old Standard TT', value: 'Old Standard TT' },
  { label: 'Poppins', value: 'Poppins' },
  { label: 'Alegreya Sans', value: 'Alegreya Sans' },
  { label: 'PT Sans', value: 'PT Sans' },
  { label: 'PT Serif', value: 'PT Serif' },
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Quicksand', value: 'Quicksand' },
  { label: 'Noto Sans JP', value: 'Noto Sans JP' },
  { label: 'Inconsolata', value: 'Inconsolata' },
  { label: 'Nunito Sans', value: 'Nunito Sans' },
  { label: 'Noto Sans', value: 'Noto Sans' },
]

export const fontPairings = {
  ABeeZee: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Roboto Slab'],
  Abel: ['Open Sans', 'Roboto', 'Montserrat', 'Oswald', 'Lato'],
  'Abhaya Libre': ['Open Sans', 'Roboto', 'Montserrat', 'Roboto Slab', 'Lato'],
  'Abril Fatface': ['Open Sans', 'Roboto', 'Raleway', 'Oswald', 'Lato'],
  Aclonica: [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Playfair Display',
  ],
  Acme: ['Montserrat', 'Open Sans', 'Roboto', 'Lato', 'Raleway'],
  Actor: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  Adamina: ['Roboto', 'Open Sans', 'Roboto Slab', 'Poppins', 'Lato'],
  'Advent Pro': [
    'Open Sans',
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Roboto Condensed',
  ],
  'Aguafina Script': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'ABeeZee',
  ],
  Akronim: ['Roboto', 'Open Sans', 'Aladin', 'Acme', 'Alef'],
  Aladin: ['Roboto', 'Open Sans', 'Roboto Slab', 'Raleway', 'Lato'],
  Alata: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Lato'],
  Alatsi: ['Roboto', 'Alata', 'Open Sans', 'Roboto Slab', 'Aladin'],
  Aldrich: ['Open Sans', 'Abril Fatface', 'Raleway', 'Advent Pro', 'Amatic SC'],
  Alef: ['Roboto', 'Open Sans', 'Montserrat', 'Oswald', 'Abel'],
  Alegreya: ['Roboto', 'Lato', 'Montserrat', 'Ubuntu', 'Lora'],
  'Alegreya SC': ['Roboto', 'Open Sans', 'Lato', 'Alegreya', 'Alegreya Sans'],
  'Alegreya Sans': [
    'Open Sans',
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Vollkorn',
  ],
  Aleo: ['Roboto', 'Montserrat', 'Abril Fatface', 'Lato', 'Playfair Display'],
  'Alex Brush': ['Open Sans', 'Roboto', 'Lobster', 'Lora', 'Roboto Slab'],
  Alice: ['Open Sans', 'Roboto', 'Raleway', 'Montserrat', 'Lato'],
  'Alike Angular': [
    'Roboto',
    'Open Sans',
    'Abril Fatface',
    'Roboto Slab',
    'Anton',
  ],
  Allerta: ['Raleway', 'Open Sans', 'Roboto', 'Lato', 'Montserrat'],
  Almendra: ['Roboto', 'Open Sans', 'Roboto Slab', 'Alegreya', 'Advent Pro'],
  Almarai: ['Oswald', 'Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab'],
  'Almendra Display': [
    'Roboto',
    'Open Sans',
    'Codystar',
    'Alegreya SC',
    'Aldrich',
  ],
  'Almendra SC': [
    'Roboto',
    'Amarante',
    'Roboto Slab',
    'Almendra Display',
    'Almendra',
  ],
  Amarante: [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Abril Fatface',
    'Montserrat',
  ],
  Amaranth: ['Lato', 'Roboto', 'Open Sans', 'Source Sans Pro', 'Montserrat'],
  'Amatic SC': [
    'Open Sans',
    'Oswald',
    'Pacifico',
    'Josefin Slab',
    'Merriweather',
  ],
  Amethysta: ['Roboto', 'Open Sans', 'Roboto Slab', 'Amaranth', 'Amatic SC'],
  Amiko: ['Source Sans Pro', 'Roboto', 'Montserrat', 'Open Sans', 'Oswald'],
  Amiri: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Lato'],
  Amita: ['Roboto', 'Open Sans', 'Roboto Slab', 'Lato', 'Montserrat'],
  Anaheim: ['Open Sans', 'Roboto', 'Roboto Slab', 'Andika', 'Aldrich'],
  Andada: ['Roboto', 'Oswald', 'Andika', 'Roboto Slab', 'Lato'],
  Andika: ['Lobster', 'EB Garamond', 'Arimo', 'Yeseva One', 'Didact Gothic'],
  Angkor: ['Roboto', 'Open Sans', 'Roboto Slab', 'Abril Fatface', 'Abel'],
  'Annie Use Your Telescope': [
    'Roboto Slab',
    'Roboto',
    'Open Sans',
    'Roboto Condensed',
    'Cabin',
  ],
  'Anonymous Pro': [
    'Alegreya',
    'Montserrat',
    'Roboto Slab',
    'Pacifico',
    'Ubuntu',
  ],
  Antic: ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Roboto Slab'],
  'Antic Didone': [
    'Open Sans',
    'Montserrat',
    'Source Sans Pro',
    'Lato',
    'Alegreya',
  ],
  'Antic Slab': ['Open Sans', 'PT Sans', 'Oswald', 'Roboto', 'Indie Flower'],
  Anton: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Lobster'],
  Arapey: ['Montserrat', 'Roboto', 'Open Sans', 'Lato', 'Old Standard TT'],
  Arbutus: ['Roboto', 'Open Sans', 'Archivo Black', 'Roboto Slab', 'Anton'],
  'Arbutus Slab': ['Open Sans', 'Roboto', 'Lato', 'Oswald', 'Raleway'],
  'Architects Daughter': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Lato',
    'Shadows Into Light',
  ],
  Archivo: ['Montserrat', 'Roboto', 'Ubuntu', 'Open Sans', 'Poppins'],
  'Archivo Black': [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Lato',
    'Source Sans Pro',
  ],
  'Archivo Narrow': [
    'Open Sans',
    'PT Serif',
    'Playfair Display',
    'Open Sans Condensed',
    'Barlow',
  ],
  'Aref Ruqaa': [
    'Roboto',
    'Montserrat',
    'Aclonica',
    'Raleway',
    'Playfair Display',
  ],
  'Arima Madurai': ['Roboto', 'Open Sans', 'Roboto Slab', 'Raleway', 'Lato'],
  Arimo: ['Open Sans', 'Roboto', 'Montserrat', 'Oswald', 'Merriweather'],
  Arizonia: ['Open Sans', 'Oswald', 'Pacifico', 'Merriweather', 'Josefin Slab'],
  Armata: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Oswald'],
  Arsenal: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Raleway'],
  Artifika: ['Roboto', 'Open Sans', 'Roboto Slab', 'Lato', 'Montserrat'],
  Arvo: ['Open Sans', 'Roboto', 'Lato', 'Source Sans Pro', 'Montserrat'],
  Arya: ['Roboto', 'Open Sans', 'Anton', 'Roboto Slab', 'Abel'],
  Asap: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Pacifico'],
  'Asap Condensed': ['Roboto', 'Open Sans', 'Asap', 'Montserrat', 'Poppins'],
  Asar: ['Roboto', 'Open Sans', 'Abril Fatface', 'Roboto Slab', 'Asap'],
  Asset: ['Roboto', 'Astloch', 'Open Sans', 'Abril Fatface', 'Aclonica'],
  Assistant: [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Condensed',
    'Playfair Display',
  ],
  Asul: ['Roboto', 'Open Sans', 'Montserrat', 'Abril Fatface', 'Arvo'],
  Astloch: ['Roboto', 'Raleway', 'Open Sans', 'Amatic SC', 'Abril Fatface'],
  Athiti: ['Roboto', 'Open Sans', 'Montserrat', 'Pattaya', 'Raleway'],
  Atma: ['Roboto', 'Open Sans', 'Roboto Slab', 'Poppins', 'Raleway'],
  'Atomic Age': ['Roboto', 'Open Sans', 'Abril Fatface', 'Aubrey', 'Abel'],
  Audiowide: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Raleway'],
  Aubrey: ['Abel', 'Open Sans', 'Abril Fatface', 'Amaranth', 'Aclonica'],
  'Autour One': ['Roboto', 'Audiowide', 'Open Sans', 'Armata', 'Roboto Slab'],
  Average: ['Open Sans', 'Roboto', 'Pacifico', 'Montserrat', 'Raleway'],
  'Averia Gruesa Libre': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Amatic SC',
  ],
  'Averia Sans Libre': [
    'Open Sans',
    'Oswald',
    'Amatic SC',
    'Allura',
    'Arizonia',
  ],
  'Averia Serif Libre': [
    'Open Sans',
    'Roboto',
    'PT Sans',
    'Montserrat',
    'Roboto Condensed',
  ],
  B612: ['Roboto', 'Open Sans', 'Abril Fatface', 'Montserrat', 'Bad Script'],
  'B612 Mono': [
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Charmonman',
    'Anonymous Pro',
  ],
  'Average Sans': ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Lato'],
  'Averia Libre': [
    'Roboto',
    'Open Sans',
    'Averia Sans Libre',
    'Roboto Slab',
    'Averia Gruesa Libre',
  ],
  'Bad Script': ['Open Sans', 'Raleway', 'Lato', 'Lora', 'Pacifico'],
  Bahiana: [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Bad Script',
    'Abril Fatface',
  ],
  Bahianita: ['Roboto', 'Bahiana', 'Roboto Slab', 'Bai Jamjuree', 'Bad Script'],
  'Bai Jamjuree': ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Oswald'],
  'Baloo 2': [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Montserrat',
    'Baloo Bhai 2',
  ],
  'Baloo Bhai 2': [
    'Roboto',
    'Roboto Slab',
    'Baloo Bhaina 2',
    'Baloo 2',
    'Baloo Chettan 2',
  ],
  'Baloo Bhaina 2': [
    'Roboto',
    'Baloo Bhai 2',
    'Roboto Slab',
    'Baloo Chettan 2',
    'Baloo 2',
  ],
  'Baloo Chettan 2': [
    'Roboto',
    'Roboto Slab',
    'Baloo Bhaina 2',
    'Baloo 2',
    'Baloo Bhai 2',
  ],
  'Baloo Da 2': [
    'Roboto',
    'Roboto Slab',
    'Baloo Chettan 2',
    'Baloo Paaji 2',
    'Baloo Bhaina 2',
  ],
  'Baloo Paaji 2': [
    'Roboto',
    'Roboto Slab',
    'Baloo Da 2',
    'Baloo Tamma 2',
    'Baloo Chettan 2',
  ],
  'Baloo Tamma 2': [
    'Roboto Slab',
    'Open Sans',
    'Montserrat',
    'Roboto Condensed',
    'Lato',
  ],
  'Baloo Tammudu 2': [
    'Roboto',
    'Roboto Slab',
    'Baloo Tamma 2',
    'Baloo Thambi 2',
    'Baloo Paaji 2',
  ],
  'Baloo Thambi 2': [
    'Roboto',
    'Roboto Slab',
    'Baloo Tammudu 2',
    'Balthazar',
    'Balsamiq Sans',
  ],
  'Balsamiq Sans': [
    'Roboto',
    'Roboto Slab',
    'Noto Serif',
    'Montserrat',
    'Barlow',
  ],
  Balthazar: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Lato'],
  Bangers: [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Pacifico',
    'Playfair Display',
  ],
  Barlow: [
    'Open Sans',
    'Roboto',
    'Playfair Display',
    'PT Serif',
    'Archivo Narrow',
  ],
  'Barlow Condensed': [
    'Open Sans',
    'Barlow',
    'Playfair Display',
    'Open Sans Condensed',
    'PT Serif',
  ],
  'Barlow Semi Condensed': [
    'Barlow',
    'Barlow Condensed',
    'Roboto',
    'Open Sans',
    'Montserrat',
  ],
  Barriecito: [
    'Roboto',
    'Barrio',
    'Roboto Slab',
    'Barlow Semi Condensed',
    'Basic',
  ],
  Barrio: ['Roboto', 'Open Sans', 'Roboto Slab', 'Amatic SC', 'Montserrat'],
  Basic: ['Lobster', 'Jura', 'Josefin Slab', 'Forum', 'Jockey One'],
  Baskervville: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Lato'],
  'Be Vietnam': [
    'Roboto',
    'Source Sans Pro',
    'Montserrat',
    'Open Sans',
    'Roboto Slab',
  ],
  'Bebas Neue': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Lato',
    'Source Sans Pro',
  ],
  Bellefair: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  Bellota: ['Roboto', 'Bellota Text', 'Bebas Neue', 'Belgrano', 'BenchNine'],
  BenchNine: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  'Beth Ellen': [
    'Roboto',
    'Roboto Slab',
    'Berkshire Swash',
    'Open Sans',
    'Bentham',
  ],
  'Berkshire Swash': [
    'Open Sans',
    'Lato',
    'Roboto',
    'Raleway',
    'Abril Fatface',
  ],
  Bevan: ['Open Sans', 'Roboto', 'Oswald', 'Arvo', 'Pacifico'],
  'Big Shoulders Display': [
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Big Shoulders Text',
    'Roboto Condensed',
  ],
  'Big Shoulders Text': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Roboto Condensed',
  ],
  'Bigelow Rules': [
    'Open Sans',
    'Bowlby One SC',
    'Lobster',
    'Oswald',
    'Roboto',
  ],
  'Bigshot One': [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Abril Fatface',
    'Montserrat',
  ],
  Bilbo: ['Open Sans', 'Roboto', 'Abril Fatface', 'Amatic SC', 'Allura'],
  'Bilbo Swash Caps': [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Roboto Slab',
    'Cabin Sketch',
  ],
  BioRhyme: [
    'Montserrat',
    'Roboto Slab',
    'Crimson Text',
    'Space Mono',
    'Eczar',
  ],
  'BioRhyme Expanded': [
    'Roboto',
    'BioRhyme',
    'Roboto Slab',
    'Open Sans',
    'Biryani',
  ],
  Biryani: ['Roboto', 'Open Sans', 'Raleway', 'Montserrat', 'Lato'],
  Bitter: ['Open Sans', 'Roboto', 'Source Sans Pro', 'Lato', 'Raleway'],
  'Black And White Picture': [
    'Black Han Sans',
    'Cute Font',
    'East Sea Dokdo',
    'Do Hyeon',
    'Gamja Flower',
  ],
  'Black Han Sans': [
    'Nanum Gothic',
    'Do Hyeon',
    'Jua',
    'Nanum Myeongjo',
    'Gothic A1',
  ],
  'Black Ops One': ['Open Sans', 'Roboto', 'Lobster', 'Lato', 'Oswald'],
  Blinker: ['Roboto', 'Montserrat', 'Lato', 'Oswald', 'Abril Fatface'],
  Bokor: ['Roboto', 'Roboto Slab', 'Open Sans', 'Alegreya', 'Abril Fatface'],
  Bonbon: ['Cabin', 'Roboto Slab', 'Montserrat', 'Amatic SC', 'Archivo Narrow'],
  Boogaloo: ['Open Sans', 'Roboto', 'Ubuntu', 'Lobster', 'Permanent Marker'],
  'Bowlby One': ['Open Sans', 'Roboto', 'Arvo', 'Codystar', 'Cabin'],
  'Bowlby One SC': [
    'Open Sans',
    'Oswald',
    'Lobster',
    'Roboto',
    'Yanone Kaffeesatz',
  ],
  Brawler: ['Lato', 'Open Sans', 'Rokkitt', 'Roboto', 'Amaranth'],
  'Bree Serif': ['Open Sans', 'Roboto', 'Lato', 'Source Sans Pro', 'Raleway'],
  'Bubblegum Sans': [
    'Open Sans',
    'Roboto',
    'Lato',
    'Montserrat',
    'Abril Fatface',
  ],
  'Bubbler One': ['Roboto', 'Open Sans', 'Lato', 'Josefin Sans', 'Montserrat'],
  Buda: ['Roboto', 'Lato', 'Raleway', 'Cabin', 'Arimo'],
  Buenard: ['Open Sans', 'Lato', 'Roboto Slab', 'Montserrat', 'Bree Serif'],
  Bungee: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Roboto Slab'],
  'Bungee Hairline': [
    'Roboto',
    'Open Sans',
    'Bungee',
    'Roboto Slab',
    'Bungee Outline',
  ],
  'Bungee Inline': ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Lato'],
  'Bungee Outline': [
    'Roboto',
    'Open Sans',
    'Bungee Inline',
    'Roboto Slab',
    'Bungee Shade',
  ],
  'Bungee Shade': ['Roboto', 'Open Sans', 'Montserrat', 'Monoton', 'Anton'],
  Butcherman: ['Roboto', 'Open Sans', 'Oswald', 'Source Sans Pro', 'Lobster'],
  'Butterfly Kids': [
    'Roboto',
    'Open Sans',
    'Abril Fatface',
    'Cabin',
    'Amatic SC',
  ],
  Cabin: [
    'Open Sans',
    'Playfair Display',
    'Roboto',
    'Source Sans Pro',
    'Raleway',
  ],
  'Cabin Condensed': ['Cabin', 'Quicksand', 'Viga', 'Sniglet', 'Roboto'],
  'Cabin Sketch': [
    'Open Sans',
    'Oswald',
    'Amatic SC',
    'Pacifico',
    'Sacramento',
  ],
  'Caesar Dressing': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Monoton',
    'Graduate',
  ],
  Cagliostro: ['Roboto', 'Open Sans', 'Lato', 'Roboto Slab', 'Bevan'],
  Cairo: ['Roboto', 'Montserrat', 'Open Sans', 'Oxygen', 'Pacifico'],
  Caladea: [
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Calistoga',
    'Abril Fatface',
  ],
  Calistoga: [
    'Voces',
    'Baloo Chettan',
    'Roboto',
    'DM Serif Display',
    'Open Sans',
  ],
  Calligraffitti: ['Open Sans', 'Roboto', 'Raleway', 'Dancing Script', 'Lato'],
  Cambay: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Lato'],
  Cambo: ['Roboto', 'Open Sans', 'Lato', 'Roboto Slab', 'Montserrat'],
  Candal: ['Open Sans', 'Roboto', 'Raleway', 'Abril Fatface', 'Indie Flower'],
  Cantarell: ['Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans'],
  'Cantata One': ['Roboto', 'Open Sans', 'Lato', 'Raleway', 'Oswald'],
  'Cantora One': ['Open Sans', 'Cabin', 'Cardo', 'Oswald', 'Carrois Gothic SC'],
  Capriola: ['Open Sans', 'Roboto', 'Lato', 'Oswald', 'Raleway'],
  Cardo: ['Open Sans', 'Montserrat', 'Lato', 'Raleway', 'Pacifico'],
  Carme: ['Open Sans', 'Roboto', 'Lato', 'Oswald', 'Montserrat'],
  'Carrois Gothic': ['Open Sans', 'Roboto', 'Montserrat', 'Raleway', 'Lato'],
  'Carrois Gothic SC': [
    'Open Sans',
    'Raleway',
    'Oswald',
    'Bowlby One SC',
    'Calligraffitti',
  ],
  'Carter One': [
    'Roboto',
    'Open Sans',
    'Josefin Sans',
    'Raleway',
    'Montserrat',
  ],
  Catamaran: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Oswald'],
  Caudex: ['Jura', 'Enriqueta', 'Josefin Slab', 'Open Sans', 'Anton'],
  Caveat: ['Open Sans', 'Lato', 'Montserrat', 'Raleway', 'Oswald'],
  'Caveat Brush': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  'Cedarville Cursive': [
    'Open Sans',
    'Roboto',
    'Raleway',
    'Reenie Beanie',
    'Dancing Script',
  ],
  'Ceviche One': [
    'Open Sans',
    'Freckle Face',
    'Hammersmith One',
    'Audiowide',
    'Montserrat',
  ],
  'Chakra Petch': [
    'Roboto',
    'Open Sans',
    'Charmonman',
    'Bai Jamjuree',
    'Montserrat',
  ],
  Changa: ['Roboto', 'Cairo', 'Open Sans', 'Montserrat', 'Reem Kufi'],
  'Changa One': [
    'Open Sans',
    'Roboto',
    'Merriweather',
    'Abel',
    'Playfair Display',
  ],
  Chango: [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Roboto Condensed',
    'Roboto Slab',
  ],
  Charm: ['Montserrat', 'Oswald', 'Lato', 'Abel', 'Roboto'],
  Charmonman: ['Roboto', 'Montserrat', 'Open Sans', 'Charm', 'Chakra Petch'],
  Chathura: ['Roboto', 'Open Sans', 'Roboto Slab', 'Advent Pro', 'Alegreya'],
  'Chau Philomene One': [
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Abril Fatface',
  ],
  'Chela One': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Abril Fatface',
  ],
  'Chelsea Market': [
    'Anton',
    'Lobster',
    'EB Garamond',
    'Caudex',
    'Josefin Slab',
  ],
  Chenla: [
    'Roboto',
    'Roboto Slab',
    'Cherry Cream Soda',
    'Abril Fatface',
    'Montserrat',
  ],
  'Cherry Cream Soda': ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Chewy'],
  'Cherry Swash': [
    'Roboto',
    'Open Sans',
    'Source Sans Pro',
    'Lato',
    'Montserrat',
  ],
  Chewy: ['Open Sans', 'Playfair Display', 'Lobster', 'Montserrat', 'Lato'],
  Chicle: ['Open Sans', 'Raleway', 'Oswald', 'Cinzel', 'Ubuntu'],
  Chilanka: ['Roboto', 'Roboto Slab', 'Open Sans', 'Chivo', 'Montserrat'],
  Chivo: ['Comfortaa', 'Montserrat', 'Roboto', 'Roboto Slab', 'Open Sans'],
  Chonburi: ['Roboto', 'Open Sans', 'Itim', 'Kanit', 'Montserrat'],
  Cinzel: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Raleway'],
  'Cinzel Decorative': [
    'Open Sans',
    'Raleway',
    'Lato',
    'Roboto',
    'Playfair Display',
  ],
  'Clicker Script': [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Amatic SC',
    'Great Vibes',
  ],
  Coda: ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  'Coda Caption': ['Roboto', 'Open Sans', 'Coda', 'Anton', 'Lato'],
  Codystar: [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Libre Baskerville',
    'Quicksand',
  ],
  Coiny: ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Lato'],
  Combo: ['Roboto', 'Comfortaa', 'Open Sans', 'Roboto Slab', 'Coda'],
  Comfortaa: [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Oswald',
    'Playfair Display',
  ],
  'Comic Neue': ['Roboto', 'Roboto Slab', 'Poppins', 'Comfortaa', 'Open Sans'],
  'Coming Soon': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Pacifico'],
  'Concert One': ['Montserrat', 'PT Sans', 'Work Sans', 'Noto Sans', 'Ubuntu'],
  Condiment: ['Open Sans', 'Raleway', 'Dancing Script', 'Roboto', 'Oswald'],
  Content: ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Lato'],
  'Contrail One': ['Open Sans', 'Roboto', 'Oswald', 'Lato', 'Raleway'],
  Convergence: ['Roboto', 'Antic Slab', 'Kaushan Script', 'Lato', 'Montserrat'],
  Cookie: ['Lato', 'Roboto', 'Open Sans', 'Raleway', 'Montserrat'],
  Copse: ['Open Sans', 'Oswald', 'Montserrat', 'Arvo', 'Merriweather'],
  Corben: ['Anton', 'Open Sans', 'EB Garamond', 'Patrick Hand', 'Play'],
  Cormorant: [
    'Montserrat',
    'PT Sans',
    'Roboto',
    'Libre Franklin',
    'Roboto Slab',
  ],
  'Cormorant Garamond': [
    'Roboto',
    'Montserrat',
    'Lato',
    'Open Sans',
    'Playfair Display',
  ],
  'Cormorant Infant': [
    'Roboto',
    'Cormorant Garamond',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
  ],
  'Cormorant SC': [
    'Open Sans',
    'Cormorant Garamond',
    'Roboto',
    'Roboto Slab',
    'Cormorant Infant',
  ],
  'Cormorant Unicase': [
    'Cormorant Garamond',
    'Cormorant Infant',
    'Open Sans',
    'Playfair Display',
    'Playfair Display SC',
  ],
  'Cormorant Upright': [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Dosis',
    'Montserrat',
  ],
  Courgette: ['Open Sans', 'Roboto', 'Raleway', 'Montserrat', 'Lato'],
  'Courier Prime': [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Roboto Slab',
    'Poppins',
  ],
  Cousine: [
    'Lobster',
    'Open Sans',
    'Roboto',
    'Source Sans Pro',
    'Roboto Condensed',
  ],
  Coustard: ['Open Sans', 'Lato', 'Montserrat', 'Lora', 'Roboto'],
  'Covered By Your Grace': [
    'Open Sans',
    'Roboto',
    'Shadows Into Light',
    'Dancing Script',
    'Ubuntu',
  ],
  'Crafty Girls': ['Open Sans', 'Roboto', 'Raleway', 'Lobster', 'Lato'],
  Creepster: ['Abril Fatface', 'Lobster', 'Arvo', 'Oswald', 'Source Sans Pro'],
  'Crete Round': ['Raleway', 'Lato', 'Open Sans', 'Roboto', 'Oswald'],
  'Crimson Pro': [
    'Roboto',
    'Montserrat',
    'Lato',
    'Poppins',
    'Roboto Condensed',
  ],
  'Crimson Text': ['Montserrat', 'Open Sans', 'Lato', 'Roboto', 'Raleway'],
  'Croissant One': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Raleway',
  ],
  Crushed: ['Lato', 'Roboto', 'Open Sans', 'Raleway', 'Dancing Script'],
  Cuprum: ['Open Sans', 'Roboto', 'Lobster', 'Pacifico', 'Lato'],
  'Cute Font': [
    'Do Hyeon',
    'Gamja Flower',
    'Gugi',
    'Hi Melody',
    'Black Han Sans',
  ],
  Cutive: ['Raleway', 'Open Sans', 'Roboto', 'Lato', 'Montserrat'],
  'Cutive Mono': [
    'Open Sans',
    'Roboto',
    'Raleway',
    'Roboto Slab',
    'Libre Baskerville',
  ],
  'DM Mono': [
    'Roboto',
    'Cutive Mono',
    'Roboto Slab',
    'Montserrat',
    'B612 Mono',
  ],
  'DM Sans': [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Source Sans Pro',
    'Poppins',
  ],
  'DM Serif Display': [
    'Roboto',
    'Open Sans',
    'Poppins',
    'Roboto Slab',
    'Roboto Condensed',
  ],
  'DM Serif Text': [
    'Roboto',
    'Montserrat',
    'Lato',
    'Oswald',
    'Playfair Display',
  ],
  Damion: ['Roboto', 'Montserrat', 'Oswald', 'Open Sans', 'Lato'],
  'Dancing Script': ['Lato', 'Open Sans', 'Roboto', 'Raleway', 'Montserrat'],
  Dangrek: ['Roboto', 'Dancing Script', 'Damion', 'Roboto Slab', 'Montserrat'],
  'Darker Grotesque': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Lato',
  ],
  'David Libre': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Playfair Display',
    'Roboto Condensed',
  ],
  'Dawning of a New Day': [
    'Open Sans',
    'Roboto',
    'Zeyada',
    'Dancing Script',
    'Reenie Beanie',
  ],
  'Days One': ['Dosis', 'Anton', 'Quicksand', 'Arimo', 'Pacifico'],
  Dekko: ['Roboto', 'Open Sans', 'Lato', 'Roboto Slab', 'Montserrat'],
  Delius: ['Open Sans', 'Montserrat', 'Muli', 'Roboto', 'Cinzel'],
  'Delius Swash Caps': [
    'Dancing Script',
    'Open Sans',
    'Roboto',
    'Lato',
    'Delius',
  ],
  'Delius Unicase': [
    'Krona One',
    'Russo One',
    'Ubuntu Condensed',
    'Days One',
    'Didact Gothic',
  ],
  'Della Respira': ['Roboto', 'Open Sans', 'Roboto Slab', 'Lato', 'Montserrat'],
  'Denk One': ['Roboto', 'Monoton', 'Creepster', 'Open Sans', 'Faster One'],
  Devonshire: [
    'Open Sans',
    'Oswald',
    'Bowlby One SC',
    'Fjalla One',
    'Bigelow Rules',
  ],
  Dhurjati: ['Roboto', 'Didact Gothic', 'Open Sans', 'Devonshire', 'Denk One'],
  'Didact Gothic': ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  Diplomata: [
    'Open Sans',
    'Montserrat',
    'Alegreya',
    'Josefin Slab',
    'Comfortaa',
  ],
  'Diplomata SC': [
    'Open Sans',
    'Dancing Script',
    'Raleway',
    'Libre Baskerville',
    'Rock Salt',
  ],
  'Do Hyeon': [
    'Nanum Gothic',
    'Black Han Sans',
    'Jua',
    'Gothic A1',
    'Nanum Myeongjo',
  ],
  Dokdo: [
    'Do Hyeon',
    'East Sea Dokdo',
    'Gaegu',
    'Black Han Sans',
    'Gamja Flower',
  ],
  Domine: ['Montserrat', 'Open Sans', 'Roboto', 'Playfair Display', 'Raleway'],
  'Donegal One': ['Roboto', 'Open Sans', 'Montserrat', 'Dosis', 'Domine'],
  'Doppio One': ['Roboto', 'Open Sans', 'Montserrat', 'Oswald', 'Lato'],
  Dorsa: ['Raleway', 'Amatic SC', 'Changa One', 'Jockey One', 'Paytone One'],
  Dosis: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  'Dr Sugiyama': ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Lato'],
  'Duru Sans': ['Open Sans', 'Roboto', 'Source Sans Pro', 'Oswald', 'Lobster'],
  Dynalight: [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Roboto Slab',
    'Source Sans Pro',
  ],
  'EB Garamond': [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Lora',
    'Playfair Display',
  ],
  'Eagle Lake': ['Roboto', 'EB Garamond', 'Lato', 'Raleway', 'Dancing Script'],
  'East Sea Dokdo': [
    'Do Hyeon',
    'Black Han Sans',
    'Gaegu',
    'Gamja Flower',
    'Cute Font',
  ],
  Eater: [
    'Oswald',
    'Lobster',
    'Lobster Two',
    'Abril Fatface',
    'Source Sans Pro',
  ],
  Economica: ['Open Sans', 'Lato', 'Montserrat', 'Raleway', 'Dosis'],
  Eczar: ['Montserrat', 'Rubik', 'Ubuntu', 'Vollkorn', 'Alegreya'],
  'El Messiri': [
    'Roboto',
    'Open Sans',
    'Lobster',
    'Roboto Condensed',
    'Josefin Sans',
  ],
  Electrolize: ['Oswald', 'Open Sans', 'Roboto', 'Lato', 'Roboto Slab'],
  Elsie: ['Open Sans', 'Roboto', 'Lato', 'Oswald', 'Great Vibes'],
  'Elsie Swash Caps': [
    'Roboto',
    'Open Sans',
    'Elsie',
    'Roboto Slab',
    'Abril Fatface',
  ],
  'Emblema One': ['Roboto', 'Open Sans', 'Roboto Slab', 'Creepster', 'PT Sans'],
  'Emilys Candy': ['Open Sans', 'Roboto', 'Oswald', 'Raleway', 'Lato'],
  'Encode Sans': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Roboto Slab'],
  'Encode Sans Condensed': [
    'Roboto',
    'Lato',
    'Oswald',
    'Open Sans',
    'Montserrat',
  ],
  'Encode Sans Expanded': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Lato',
    'Raleway',
  ],
  'Encode Sans Semi Condensed': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Lato',
  ],
  'Encode Sans Semi Expanded': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Lato',
    'Roboto Condensed',
  ],
  Engagement: ['Open Sans', 'Lato', 'Oswald', 'Roboto', 'Ubuntu'],
  Englebert: ['Lato', 'Roboto', 'Open Sans', 'Montserrat', 'Oswald'],
  Enriqueta: ['Jura', 'Open Sans', 'Caudex', 'Lobster', 'Josefin Slab'],
  Epilogue: ['Roboto', 'Montserrat', 'Open Sans', 'Source Sans Pro', 'Lato'],
  'Erica One': ['Open Sans', 'Raleway', 'Condiment', 'Quicksand', 'Rock Salt'],
  Esteban: ['Poppins', 'Roboto', 'Raleway', 'Abel', 'Lora'],
  'Euphoria Script': [
    'Alex Brush',
    'Pacifico',
    'Lobster',
    'Poiret One',
    'Rochester',
  ],
  Ewert: [
    'Open Sans',
    'Shadows Into Light',
    'Dancing Script',
    'Kaushan Script',
    'Allura',
  ],
  Exo: ['Open Sans', 'Roboto', 'Roboto Condensed', 'Lato', 'Montserrat'],
  'Exo 2': ['Open Sans', 'Roboto', 'Roboto Slab', 'Montserrat', 'PT Sans'],
  'Expletus Sans': [
    'Roboto',
    'Lato',
    'Raleway',
    'Montserrat',
    'Playfair Display',
  ],
  Fahkwang: ['Roboto', 'Charmonman', 'Chakra Petch', 'Bai Jamjuree', 'Kanit'],
  'Fanwood Text': ['Quattrocento', 'Open Sans', 'Roboto', 'Montserrat', 'Lato'],
  Farro: [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Montserrat',
    'Roboto Condensed',
  ],
  Farsan: ['Roboto', 'Anton', 'Open Sans', 'Montserrat', 'Arima Madurai'],
  Fascinate: ['Roboto', 'Open Sans', 'Montserrat', 'Finger Paint', 'Fauna One'],
  'Fascinate Inline': [
    'Open Sans',
    'Pacifico',
    'Raleway',
    'Cinzel',
    'Tangerine',
  ],
  'Faster One': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  Fasthand: ['Roboto', 'Open Sans', 'Roboto Slab', 'Fauna One', 'Faster One'],
  'Fauna One': ['Roboto', 'Open Sans', 'Roboto Slab', 'Raleway', 'Oswald'],
  Faustina: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Playfair Display'],
  Federant: ['Open Sans', 'Federo', 'Roboto', 'Abril Fatface', 'Lato'],
  Federo: [
    'Andika',
    'Crafty Girls',
    'EB Garamond',
    'Marko One',
    'Marck Script',
  ],
  Felipa: ['Open Sans', 'Roboto', 'Dancing Script', 'Allura', 'Abril Fatface'],
  Fenix: ['Roboto', 'Open Sans', 'Raleway', 'Dosis', 'Exo'],
  'Finger Paint': [
    'Open Sans',
    'Lato',
    'Rock Salt',
    'Dancing Script',
    'Cinzel',
  ],
  'Fira Code': ['Montserrat', 'Heebo', 'Roboto', 'Noto Serif', 'Poppins'],
  'Fira Mono': [
    'Open Sans',
    'Roboto',
    'Roboto Condensed',
    'Playfair Display',
    'Merriweather',
  ],
  'Fira Sans': ['Roboto', 'Oxygen', 'Ubuntu', 'Droid Sans', 'Cantarell'],
  'Fira Sans Condensed': ['Roboto', 'Mukta', 'Open Sans', 'Montserrat'],
  'Fira Sans Extra Condensed': [
    'Roboto',
    'Open Sans',
    'Fira Sans',
    'Lato',
    'Montserrat',
  ],
  'Fjalla One': ['Roboto', 'Merriweather', 'Open Sans', 'Lato', 'Montserrat'],
  'Fjord One': ['Roboto', 'Open Sans', 'Arvo', 'Lora', 'Fauna One'],
  Flamenco: ['Roboto', 'Lato', 'Raleway', 'Josefin Sans', 'Josefin Slab'],
  Flavors: ['Open Sans', 'Finger Paint', 'Lobster', 'Lato', 'PT Sans'],
  Fondamento: ['Open Sans', 'Roboto', 'Lato', 'Josefin Sans', 'Oswald'],
  'Fontdiner Swanky': [
    'Roboto',
    'Raleway',
    'Lato',
    'Open Sans',
    'Crafty Girls',
  ],
  Forum: ['Lobster', 'Jura', 'EB Garamond', 'Play', 'Marck Script'],
  'Francois One': [
    'Open Sans',
    'Merriweather',
    'Pacifico',
    'Oswald',
    'Josefin Slab',
  ],
  'Frank Ruhl Libre': [
    'Montserrat',
    'Rubik',
    'Libre Baskerville',
    'Roboto',
    'Oxygen',
  ],
  'Freckle Face': ['Open Sans', 'Roboto', 'Lobster', 'Montserrat', 'Audiowide'],
  'Fredericka the Great': [
    'Open Sans',
    'Josefin Slab',
    'Oswald',
    'Pacifico',
    'Sacramento',
  ],
  'Fredoka One': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Pacifico'],
  Freehand: ['Roboto', 'Lato', 'Open Sans', 'Montserrat', 'Roboto Slab'],
  Fresca: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Quicksand'],
  Frijole: ['Roboto', 'Open Sans', 'Lobster', 'Montserrat', 'Lato'],
  Fruktur: ['Roboto', 'Open Sans', 'Abril Fatface', 'Montserrat', 'Anton'],
  'Fugaz One': [
    'Open Sans',
    'Material Icons Extended',
    'Roboto',
    'Roboto Condensed',
    'Oswald',
  ],
  'GFS Didot': ['Roboto', 'Open Sans', 'Oswald', 'Raleway', 'PT Sans'],
  'GFS Neohellenic': ['Roboto', 'Lato', 'Raleway', 'Anonymous Pro', 'Cabin'],
  Gabriela: ['Open Sans', 'Roboto', 'Lato', 'Roboto Slab', 'Montserrat'],
  Gaegu: ['Gamja Flower', 'Gugi', 'Black Han Sans', 'Hi Melody', 'Gothic A1'],
  Gafata: ['Roboto', 'Open Sans', 'Roboto Slab', 'Lato', 'Montserrat'],
  Galada: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Roboto Slab'],
  Galdeano: ['Roboto', 'Open Sans', 'Roboto Slab', 'Gafata', 'Abel'],
  Galindo: ['Roboto', 'Open Sans', 'Lato', 'Roboto Slab', 'Montserrat'],
  'Gamja Flower': ['Gaegu', 'Do Hyeon', 'Jua', 'Gothic A1', 'Gugi'],
  Gayathri: [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Gamja Flower',
  ],
  Gelasio: ['Roboto', 'Open Sans', 'Source Sans Pro', 'Montserrat', 'Lato'],
  'Gentium Basic': [
    'Open Sans',
    'Raleway',
    'Montserrat',
    'Playfair Display',
    'Lora',
  ],
  'Gentium Book Basic': [
    'Open Sans',
    'Lato',
    'Merriweather',
    'Roboto',
    'Source Sans Pro',
  ],
  Geo: [
    'Six Caps',
    'Changa One',
    'Ubuntu Mono',
    'Open Sans',
    'UnifrakturMaguntia',
  ],
  Geostar: ['Open Sans', 'Roboto', 'Lato', 'Roboto Slab', 'Oswald'],
  'Geostar Fill': [
    'Open Sans',
    'Josefin Slab',
    'Oswald',
    'Bangers',
    'Dancing Script',
  ],
  'Germania One': ['Roboto', 'Open Sans', 'Nosifer', 'Faster One', 'Denk One'],
  Gidugu: ['Roboto', 'Montserrat', 'Abel', 'Roboto Slab', 'Lato'],
  'Gilda Display': ['Roboto', 'Roboto Slab', 'Open Sans', 'Lato', 'Montserrat'],
  Girassol: [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Montserrat',
    'Roboto Condensed',
  ],
  'Give You Glory': [
    'Open Sans',
    'Roboto',
    'Dancing Script',
    'Abril Fatface',
    'Raleway',
  ],
  'Glass Antiqua': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Josefin Sans'],
  Glegoo: ['Open Sans', 'Roboto', 'Lora', 'Lato', 'Raleway'],
  'Gloria Hallelujah': [
    'Open Sans',
    'Roboto',
    'Raleway',
    'Source Sans Pro',
    'Shadows Into Light',
  ],
  'Goblin One': ['Open Sans', 'Roboto', 'Bevan', 'Merriweather', 'Raleway'],
  'Gochi Hand': ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Oswald'],
  Gorditas: ['Roboto', 'Open Sans', 'Roboto Slab', 'Lato', 'Gochi Hand'],
  'Gothic A1': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Source Sans Pro'],
  Gotu: ['Roboto', 'Roboto Slab', 'Montserrat', 'Open Sans', 'Graduate'],
  'Goudy Bookletter 1911': [
    'Open Sans',
    'Roboto',
    'Droid Sans',
    'Raleway',
    'Lato',
  ],
  Graduate: ['Open Sans', 'Lato', 'Roboto', 'Ubuntu', 'Cinzel Decorative'],
  'Grand Hotel': ['Open Sans', 'Roboto', 'Montserrat', 'Lobster', 'Oswald'],
  Grandstander: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Oswald'],
  'Gravitas One': [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Playfair Display',
    'Arvo',
  ],
  'Great Vibes': ['Open Sans', 'Roboto', 'Montserrat', 'Raleway', 'Pacifico'],
  Grenze: ['Roboto', 'Roboto Slab', 'Great Vibes', 'Open Sans', 'Montserrat'],
  'Grenze Gotisch': [
    'Roboto',
    'Roboto Slab',
    'Great Vibes',
    'Grenze',
    'Griffy',
  ],
  Griffy: [
    'Spicy Rice',
    'Open Sans',
    'Raleway',
    'Henny Penny',
    'Dancing Script',
  ],
  Gruppo: ['Open Sans', 'Lato', 'Roboto', 'Raleway', 'Montserrat'],
  Gudea: ['Roboto', 'Lora', 'Open Sans', 'Old Standard TT', 'Poiret One'],
  Gugi: ['Gaegu', 'Black Han Sans', 'Gamja Flower', 'Do Hyeon', 'Cute Font'],
  Gupter: [
    'Montserrat',
    'Open Sans',
    'Roboto Slab',
    'Noto Serif',
    'Roboto Condensed',
  ],
  Gurajada: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Roboto Slab'],
  Habibi: ['Open Sans', 'Lato', 'Roboto Slab', 'Montserrat', 'Abril Fatface'],
  Halant: ['Open Sans', 'Montserrat', 'Raleway', 'Roboto Slab', 'Lato'],
  'Hammersmith One': [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Pacifico',
    'Bitter',
  ],
  Hanalei: ['Roboto', 'Open Sans', 'Hanalei Fill', 'Comfortaa', 'Jura'],
  'Hanalei Fill': [
    'Roboto',
    'Oswald',
    'Fjalla One',
    'Lobster',
    'Bowlby One SC',
  ],
  Handlee: ['Open Sans', 'Roboto', 'Lato', 'Lora', 'Lobster'],
  Hanuman: ['Roboto', 'Open Sans', 'Lato', 'Roboto Slab', 'Montserrat'],
  'Happy Monkey': ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Amatic SC'],
  Harmattan: ['Roboto', 'El Messiri', 'Katibeh', 'Cairo', 'Amiri'],
  'Headland One': [
    'Open Sans',
    'Oswald',
    'Raleway',
    'Henny Penny',
    'Herr Von Muellerhoff',
  ],
  Heebo: ['Roboto', 'Open Sans', 'Montserrat'],
  'Henny Penny': ['Open Sans', 'Lato', 'Lobster', 'Ubuntu', 'Cinzel'],
  'Hepta Slab': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Quicksand',
  ],
  'Herr Von Muellerhoff': [
    'Open Sans',
    'Roboto',
    'Cabin',
    'Montserrat',
    'Lato',
  ],
  'Hi Melody': ['Gothic A1', 'Gamja Flower', 'Gaegu', 'Gugi', 'Do Hyeon'],
  Hind: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Poppins'],
  'Hind Guntur': ['Roboto', 'Montserrat', 'Hind', 'Raleway', 'Open Sans'],
  'Hind Madurai': ['Roboto', 'Open Sans', 'Lato'],
  'Hind Siliguri': ['Roboto', 'Open Sans', 'Montserrat'],
  'Hind Vadodara': [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Lato',
    'Hind Siliguri',
  ],
  'Holtwood One SC': ['Roboto', 'Lato', 'Raleway', 'Montserrat', 'Oswald'],
  'Homemade Apple': ['Open Sans', 'Lato', 'Montserrat', 'Roboto', 'Raleway'],
  Homenaje: ['Open Sans', 'Oswald', 'Lato', 'Roboto', 'Montserrat'],
  'IBM Plex Mono': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Roboto Condensed',
    'PT Sans',
  ],
  'IBM Plex Sans': ['Open Sans', 'Merriweather', 'Merriweather Sans', 'Roboto'],
  'IBM Plex Sans Condensed': [
    'IBM Plex Sans',
    'Roboto',
    'Open Sans',
    'Source Sans Pro',
    'Montserrat',
  ],
  'IBM Plex Serif': [
    'Roboto',
    'IBM Plex Sans',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
  ],
  'IM Fell DW Pica': [
    'Open Sans',
    'Josefin Sans',
    'Inconsolata',
    'Lobster',
    'Raleway',
  ],
  'IM Fell DW Pica SC': [
    'Josefin Sans',
    'Raleway',
    'Lato',
    'Reenie Beanie',
    'Yanone Kaffeesatz',
  ],
  'IM Fell Double Pica': [
    'Open Sans',
    'Montserrat',
    'Raleway',
    'Lato',
    'EB Garamond',
  ],
  'IM Fell Double Pica SC': [
    'IM Fell Double Pica',
    'IM Fell DW Pica SC',
    'IM Fell English',
    'IM Fell English SC',
    'Lato',
  ],
  'IM Fell English': ['Open Sans', 'Roboto', 'Lato', 'Arimo', 'Raleway'],
  'IM Fell English SC': [
    'Raleway',
    'Lato',
    'Josefin Sans',
    'Josefin Slab',
    'Open Sans',
  ],
  'IM Fell French Canon': [
    'Open Sans',
    'Oswald',
    'Josefin Slab',
    'Merriweather',
    'Open Sans Condensed',
  ],
  'IM Fell French Canon SC': [
    'IM Fell French Canon',
    'IM Fell English SC',
    'Lato',
    'IM Fell Great Primer SC',
    'IM Fell Great Primer',
  ],
  'IM Fell Great Primer': [
    'IM Fell Great Primer SC',
    'Lato',
    'Raleway',
    'Open Sans',
    'IM Fell French Canon SC',
  ],
  'IM Fell Great Primer SC': [
    'Josefin Sans',
    'IM Fell English SC',
    'Raleway',
    'Lato',
    'Josefin Slab',
  ],
  'Ibarra Real Nova': [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Roboto Slab',
    'Lato',
  ],
  Iceberg: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Dosis'],
  Iceland: ['Open Sans', 'Roboto', 'Montserrat', 'Raleway', 'Lato'],
  Imprima: ['Open Sans', 'Lato', 'Oswald', 'Ubuntu', 'Cabin'],
  Inconsolata: ['Montserrat', 'Merriweather', 'Open Sans', 'Lato', 'Roboto'],
  Inder: ['Roboto', 'Open Sans', 'Raleway', 'Oswald', 'Roboto Slab'],
  'Indie Flower': ['Open Sans', 'Lato', 'Oswald', 'Roboto', 'Raleway'],
  Inika: ['Open Sans', 'Lato', 'Montserrat', 'Roboto Slab', 'Raleway'],
  'Inknut Antiqua': [
    'Montserrat',
    'Rubik',
    'PT Serif',
    'Archivo Narrow',
    'Cardo',
  ],
  'Inria Sans': ['Roboto', 'Roboto Slab', 'Inria Serif', 'Inter', 'Open Sans'],
  'Inria Serif': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Noto Serif',
  ],
  Inter: ['Roboto Slab', 'Ubuntu', 'Work Sans', 'Nunito', 'Pacifico'],
  'Irish Grover': [
    'Roboto',
    'Indie Flower',
    'Josefin Sans',
    'Rock Salt',
    'Lobster',
  ],
  'Istok Web': ['Open Sans', 'Lora', 'Roboto', 'EB Garamond', 'Pacifico'],
  Italiana: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Playfair Display'],
  Italianno: ['Open Sans', 'Roboto', 'Raleway', 'Great Vibes', 'Amatic SC'],
  Itim: ['Roboto', 'Open Sans', 'Montserrat', 'Chonburi', 'Quicksand'],
  'Jacques Francois': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Lato',
  ],
  'Jacques Francois Shadow': [
    'Open Sans',
    'Kaushan Script',
    'Open Sans Condensed',
    'Allura',
    'Special Elite',
  ],
  Jaldi: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Condensed', 'Lato'],
  'Jim Nightshade': [
    'Open Sans',
    'Sacramento',
    'Great Vibes',
    'Alex Brush',
    'Dancing Script',
  ],
  'Jockey One': ['Jura', 'Spinnaker', 'Lobster', 'Anton', 'Josefin Slab'],
  'Jolly Lodger': [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Josefin Sans',
    'Jockey One',
  ],
  Jomhuria: ['Roboto', 'Amiri', 'Lalezar', 'Lemonada', 'Aref Ruqaa'],
  Jomolhari: [
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Open Sans',
    'Roboto Condensed',
  ],
  'Josefin Sans': ['Open Sans', 'Roboto', 'Montserrat', 'Raleway', 'Lato'],
  'Josefin Slab': ['Open Sans', 'Oswald', 'Merriweather', 'Pacifico', 'Roboto'],
  Jost: ['Roboto', 'Open Sans', 'Poppins', 'Montserrat', 'Roboto Slab'],
  'Joti One': ['Open Sans', 'Roboto', 'Jockey One', 'Jura', 'Lobster'],
  Jua: [
    'Nanum Gothic',
    'Do Hyeon',
    'Black Han Sans',
    'Nanum Myeongjo',
    'Gamja Flower',
  ],
  Judson: ['Oswald', 'Roboto', 'Ubuntu', 'Lato', 'Bitter'],
  Julee: ['Roboto', 'Lato', 'Josefin Sans', 'Open Sans', 'Raleway'],
  'Julius Sans One': ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Raleway'],
  Junge: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Oswald'],
  Jura: ['Lobster', 'Open Sans', 'Roboto', 'EB Garamond', 'Spinnaker'],
  'Just Another Hand': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  'Just Me Again Down Here': [
    'Roboto',
    'Open Sans',
    'Oswald',
    'Lato',
    'Raleway',
  ],
  K2D: ['Roboto', 'Open Sans', 'Montserrat', 'Kanit', 'Niramit'],
  Kadwa: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Roboto Slab'],
  Kalam: ['Open Sans', 'Lato', 'Roboto', 'Montserrat', 'Raleway'],
  Kameron: ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Lora'],
  Kanit: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Lato'],
  Kantumruy: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Abel'],
  Karla: ['Montserrat', 'Roboto', 'Lato', 'Lora', 'Vollkorn'],
  Karma: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Raleway'],
  Katibeh: ['Roboto', 'Harmattan', 'Scheherazade', 'Lobster', 'Amiri'],
  'Kaushan Script': [
    'Open Sans',
    'Oswald',
    'Merriweather',
    'Open Sans Condensed',
    'Pacifico',
  ],
  Kavivanar: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Amatic SC'],
  Kavoon: ['Open Sans', 'Roboto', 'Monoton', 'Sacramento', 'Creepster'],
  'Kdam Thmor': [
    'Roboto',
    'Open Sans',
    'Kaushan Script',
    'Kavoon',
    'Montserrat',
  ],
  'Keania One': ['Roboto', 'Open Sans', 'Raleway', 'Lato', 'Josefin Sans'],
  'Kelly Slab': ['Play', 'Jura', 'Forum', 'Marck Script', 'Anton'],
  Kenia: ['Lobster', 'Open Sans', 'Josefin Sans', 'Righteous', 'Allan'],
  Khand: ['Open Sans', 'Roboto', 'Roboto Slab', 'Montserrat', 'Lato'],
  Khmer: ['Roboto', 'Lato', 'Montserrat', 'Open Sans', 'Roboto Slab'],
  Khula: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  'Kirang Haerang': ['Jua', 'Gugi', 'Gamja Flower', 'Do Hyeon', 'Hi Melody'],
  'Kite One': ['Open Sans', 'Lato', 'Raleway', 'Anton', 'Oswald'],
  Knewave: [
    'Roboto',
    'Oswald',
    'Playfair Display',
    'Kaushan Script',
    'Montserrat',
  ],
  KoHo: ['Roboto', 'Niramit', 'Kodchasan', 'Open Sans', 'Kanit'],
  Kodchasan: ['Roboto', 'Open Sans', 'Montserrat', 'Oswald', 'KoHo'],
  Kosugi: [
    'Kosugi Maru',
    'M PLUS 1p',
    'Roboto',
    'Noto Sans JP',
    'M PLUS Rounded 1c',
  ],
  'Kosugi Maru': [
    'Roboto',
    'Noto Sans JP',
    'M PLUS Rounded 1c',
    'Lato',
    'Noto Serif JP',
  ],
  'Kotta One': ['Raleway', 'Lobster', 'Shadows Into Light', 'Oswald', 'Marvel'],
  Koulen: ['Roboto', 'Lato', 'Montserrat', 'Battambang', 'Kreon'],
  Kranky: [
    'Lobster',
    'Pacifico',
    'Anonymous Pro',
    'Lora',
    'Open Sans Condensed',
  ],
  Kreon: ['Open Sans', 'Oswald', 'Pacifico', 'Playfair Display', 'Roboto'],
  Kristi: ['Roboto', 'Open Sans', 'Raleway', 'Lato', 'Calligraffitti'],
  'Krona One': [
    'Open Sans Condensed',
    'Lobster',
    'Noto Serif',
    'Exo',
    'PT Sans Narrow',
  ],
  Krub: ['Roboto', 'Open Sans', 'Montserrat', 'Lora', 'Raleway'],
  Kufam: ['Roboto', 'Montserrat', 'Lato', 'Raleway', 'Oswald'],
  'Kulim Park': ['Roboto', 'Open Sans', 'Roboto Slab', 'Lato', 'Montserrat'],
  'Kumar One': ['Roboto', 'Abel', 'Open Sans', 'Chathura', 'Anton'],
  'Kumar One Outline': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Condensed',
    'Rubik',
  ],
  'Kumbh Sans': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Poppins'],
  Kurale: [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Roboto Condensed',
    'Ruslan Display',
  ],
  'La Belle Aurore': [
    'Open Sans',
    'Roboto',
    'Raleway',
    'Pinyon Script',
    'Shadows Into Light',
  ],
  Lacquer: ['Roboto', 'Lato', 'Montserrat', 'Roboto Slab', 'Open Sans'],
  Laila: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Roboto Slab'],
  'Lakki Reddy': ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Laila'],
  Lalezar: ['Roboto Condensed', 'Open Sans', 'Roboto', 'Montserrat', 'Lobster'],
  Lancelot: ['Open Sans', 'Lato', 'Roboto', 'Raleway', 'Oswald'],
  Lateef: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Lobster'],
  Lato: ['Roboto', 'Open Sans', 'Oswald', 'Raleway', 'Montserrat'],
  'League Script': ['Lato', 'Roboto', 'Open Sans', 'Montserrat', 'Raleway'],
  'Leckerli One': ['Lobster', 'Pacifico', 'Lora', 'Abel', 'Open Sans'],
  Ledger: [
    'Comfortaa',
    'Material Icons Extended',
    'Marck Script',
    'Lobster',
    'Didact Gothic',
  ],
  Lekton: ['Oswald', 'Raleway', 'Roboto', 'Open Sans', 'Lato'],
  Lemon: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Roboto Slab'],
  Lemonada: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Lobster'],
  'Lexend Deca': ['Roboto', 'Montserrat', 'Lato', 'Open Sans', 'Oswald'],
  'Lexend Exa': ['Roboto', 'Montserrat', 'Lexend Deca', 'Lexend Giga', 'Lato'],
  'Lexend Giga': [
    'Roboto',
    'Lexend Exa',
    'Lexend Deca',
    'Lexend Mega',
    'Montserrat',
  ],
  'Lexend Mega': [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Lexend Giga',
    'Lexend Peta',
  ],
  'Lexend Peta': [
    'Lexend Mega',
    'Lexend Zetta',
    'Roboto Slab',
    'Lexend Giga',
    'Montserrat',
  ],
  'Lexend Tera': [
    'Lexend Zetta',
    'Lexend Mega',
    'Roboto Slab',
    'Lexend Giga',
    'Montserrat',
  ],
  'Lexend Zetta': ['Roboto', 'Montserrat', 'Open Sans', 'Lato', 'Lora'],
  'Libre Barcode 128': [
    'Roboto',
    'Open Sans',
    'Libre Barcode 128 Text',
    'Roboto Slab',
    'Libre Barcode 39',
  ],
  'Libre Barcode 128 Text': [
    'Roboto',
    'Open Sans',
    'Libre Barcode 39 Extended Text',
    'Libre Barcode 128',
    'Roboto Slab',
  ],
  'Libre Barcode 39': [
    'Roboto',
    'Open Sans',
    'Libre Barcode 128 Text',
    'Lato',
    'Montserrat',
  ],
  'Libre Barcode 39 Extended': [
    'Roboto',
    'Libre Barcode 39',
    'Libre Barcode 39 Extended Text',
    'Libre Barcode 128 Text',
    'Roboto Slab',
  ],
  'Libre Barcode 39 Extended Text': [
    'Roboto',
    'Montserrat',
    'Lato',
    'Poppins',
    'Hind',
  ],
  'Libre Barcode 39 Text': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Libre Baskerville',
  ],
  'Libre Baskerville': ['Open Sans', 'Roboto', 'Montserrat', 'Raleway', 'Lato'],
  'Libre Caslon Display': [
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Playfair Display',
    'Open Sans',
  ],
  'Libre Caslon Text': [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Roboto Slab',
    'Poppins',
  ],
  'Libre Franklin': [
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Roboto',
    'Poppins',
  ],
  'Life Savers': ['Open Sans', 'Roboto', 'Oswald', 'Sacramento', 'Ubuntu'],
  'Lilita One': ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  'Lily Script One': ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  Limelight: ['Open Sans', 'Lato', 'Roboto', 'Montserrat', 'Josefin Sans'],
  'Linden Hill': ['Open Sans', 'Roboto', 'Oswald', 'Raleway', 'Lora'],
  Literata: ['Muli', 'Open Sans', 'Roboto', 'Source Sans Pro', 'Lora'],
  'Liu Jian Mao Cao': [
    'Roboto',
    'Roboto Slab',
    'Playfair Display',
    'Open Sans',
    'Pacifico',
  ],
  Livvic: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  Lobster: ['Open Sans', 'Roboto', 'Oswald', 'Montserrat', 'Lato'],
  'Lobster Two': ['Source Sans Pro', 'Open Sans', 'Roboto', 'Lato', 'Arvo'],
  'Londrina Outline': ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Oswald'],
  'Londrina Shadow': [
    'Open Sans',
    'Lobster Two',
    'Oswald',
    'Raleway',
    'Pacifico',
  ],
  'Londrina Sketch': [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Cabin Sketch',
    'Codystar',
  ],
  'Londrina Solid': ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Raleway'],
  'Long Cang': [
    'Roboto',
    'Lora',
    'Roboto Slab',
    'Montserrat',
    'Loved by the King',
  ],
  Lora: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  'Love Ya Like A Sister': [
    'Open Sans',
    'Oswald',
    'Pacifico',
    'Josefin Slab',
    'Merriweather',
  ],
  'Loved by the King': [
    'Open Sans',
    'Oswald',
    'Raleway',
    'Yanone Kaffeesatz',
    'Roboto',
  ],
  'Lovers Quarrel': ['Raleway', 'Cinzel', 'Great Vibes', 'Oswald', 'Roboto'],
  'Luckiest Guy': ['Open Sans', 'Lato', 'Roboto', 'Raleway', 'Oswald'],
  Lusitana: ['Lato', 'Open Sans', 'Raleway', 'PT Sans', 'Oswald'],
  Lustria: ['Open Sans', 'Lato', 'Arimo', 'Roboto', 'Raleway'],
  'M PLUS 1p': ['Roboto', 'Lato', 'Open Sans', 'Montserrat', 'Source Sans Pro'],
  'M PLUS Rounded 1c': [
    'Noto Sans JP',
    'Roboto',
    'M PLUS 1p',
    'Noto Serif JP',
    'Lato',
  ],
  'Ma Shan Zheng': [
    'Roboto',
    'Roboto Slab',
    'Montserrat',
    'Open Sans',
    'M PLUS Rounded 1c',
  ],
  Macondo: ['Roboto', 'Open Sans', 'Raleway', 'Oswald', 'Lobster'],
  'Macondo Swash Caps': [
    'Open Sans',
    'Raleway',
    'Alex Brush',
    'Lobster',
    'Kaushan Script',
  ],
  Mada: ['Roboto', 'Open Sans', 'Poppins', 'Roboto Slab', 'Playfair Display'],
  Magra: ['Roboto', 'Open Sans', 'Gudea', 'Oswald', 'Open Sans Condensed'],
  'Maiden Orange': ['Oswald', 'Open Sans', 'Roboto', 'Lato', 'Raleway'],
  Maitree: ['Open Sans', 'Roboto', 'Kanit', 'Prompt', 'Mitr'],
  'Major Mono Display': [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Roboto Slab',
    'Playfair Display',
  ],
  Mako: ['Open Sans', 'Lato', 'Roboto', 'Raleway', 'Lobster'],
  Mali: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Oswald'],
  Mallanna: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Roboto Slab'],
  Mandali: ['Roboto', 'Roboto Slab', 'Open Sans', 'Lato', 'Montserrat'],
  Manjari: ['Roboto', 'Montserrat', 'Open Sans', 'Lato', 'Raleway'],
  Manrope: ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Poppins'],
  Mansalva: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Oswald'],
  Manuale: ['Suez One', 'Roboto', 'Montserrat', 'Roboto Slab', 'Lato'],
  Marcellus: ['Open Sans', 'Roboto', 'Lato', 'Oswald', 'Montserrat'],
  'Marcellus SC': [
    'Roboto',
    'Montserrat',
    'Raleway',
    'Quattrocento Sans',
    'Titillium Web',
  ],
  'Marck Script': ['Open Sans', 'Lobster', 'Anton', 'Josefin Slab', 'Roboto'],
  Margarine: ['Open Sans', 'Oswald', 'Lobster', 'Ubuntu', 'Fjalla One'],
  'Markazi Text': ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Lato'],
  'Marko One': ['Federo', 'Pacifico', 'Nunito', 'Lobster', 'Marmelad'],
  Marmelad: [
    'Lobster',
    'Philosopher',
    'Satisfy',
    'Open Sans Condensed',
    'Lora',
  ],
  Martel: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Raleway'],
  'Martel Sans': ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  Marvel: ['Raleway', 'Lobster', 'Open Sans', 'Play', 'Shadows Into Light'],
  Mate: ['Open Sans', 'Lato', 'Roboto', 'Montserrat', 'Playfair Display'],
  'Mate SC': ['Open Sans', 'Roboto', 'Lato', 'Source Sans Pro', 'Montserrat'],
  'Maven Pro': [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Lato',
    'Playfair Display',
  ],
  McLaren: ['Roboto', 'Open Sans', 'Source Sans Pro', 'Ubuntu', 'Montserrat'],
  Meddon: ['Open Sans', 'Roboto', 'Dancing Script', 'Raleway', 'Indie Flower'],
  MedievalSharp: ['Roboto', 'Megrim', 'Open Sans', 'Oswald', 'Michroma'],
  'Medula One': ['Open Sans', 'Roboto', 'Codystar', 'Raleway', 'Lato'],
  'Meera Inimai': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Merriweather',
  ],
  Megrim: ['Open Sans', 'Raleway', 'Roboto', 'Lato', 'Oswald'],
  'Meie Script': ['Open Sans', 'Roboto', 'Lato', 'Offside', 'Monoton'],
  Merienda: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Poppins'],
  'Merienda One': ['Rokkitt', 'Pacifico', 'Lobster', 'Quicksand', 'Lora'],
  Merriweather: [
    'Open Sans',
    'Merriweather Sans',
    'IBM Plex Sans',
    'Roboto',
    'Montserrat',
  ],
  'Merriweather Sans': [
    'Open Sans',
    'Merriweather',
    'IBM Plex Sans',
    'Roboto',
    'Lato',
  ],
  Metal: [
    'Roboto',
    'Montserrat',
    'Merriweather',
    'Roboto Slab',
    'Merriweather Sans',
  ],
  'Metal Mania': ['Roboto', 'Lato', 'Montserrat', 'Merriweather', 'Raleway'],
  Metamorphous: ['Berkshire Swash', 'Open Sans', 'Lato', 'Roboto', 'Raleway'],
  Metrophobic: ['Open Sans', 'Roboto', 'Lato', 'PT Sans', 'Montserrat'],
  Michroma: ['Roboto', 'Merriweather', 'Open Sans', 'Montserrat', 'Lato'],
  Milonga: ['Roboto', 'Open Sans', 'Oswald', 'Lobster', 'Sacramento'],
  Miltonian: [
    'Miltonian Tattoo',
    'Michroma',
    'Metrophobic',
    'Monofett',
    'Merriweather',
  ],
  'Miltonian Tattoo': ['Miltonian', 'Roboto', 'Michroma', 'Monofett', 'Lato'],
  Mina: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Roboto Slab'],
  Miniver: ['Roboto', 'Open Sans', 'Josefin Sans', 'Lato', 'Playball'],
  'Miriam Libre': ['Open Sans', 'Montserrat', 'Roboto', 'Rubik', 'Heebo'],
  Mirza: ['Roboto', 'Cairo', 'El Messiri', 'Open Sans', 'Dosis'],
  'Miss Fajardose': [
    'Monsieur La Doulaise',
    'Open Sans',
    'Oswald',
    'Great Vibes',
    'Rouge Script',
  ],
  Modak: ['Lato', 'Lobster', 'Indie Flower', 'Source Sans Pro', 'Cabin'],
  'Modern Antiqua': ['Open Sans', 'Roboto', 'Oswald', 'Lobster', 'PT Sans'],
  Mogra: ['Roboto', 'Open Sans', 'Montserrat', 'Monoton', 'Lobster'],
  Mitr: ['Prompt', 'Roboto', 'Kanit', 'Open Sans', 'Pridi'],
  Molengo: ['Open Sans', 'Lato', 'Roboto', 'Montserrat', 'Raleway'],
  Molle: ['Lato', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Raleway'],
  Monda: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Raleway'],
  Monofett: [
    'Open Sans',
    'Lato',
    'Rock Salt',
    'Dancing Script',
    'Architects Daughter',
  ],
  Monoton: ['Roboto', 'Montserrat', 'Playfair Display', 'Open Sans', 'Lobster'],
  'Monsieur La Doulaise': [
    'Open Sans',
    'Oswald',
    'Miss Fajardose',
    'Muli',
    'Roboto',
  ],
  Montaga: [
    'Montserrat',
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Montserrat Alternates',
  ],
  Montez: ['Roboto', 'Open Sans', 'Dancing Script', 'Montserrat', 'Amatic SC'],
  Montserrat: ['Roboto', 'Open Sans', 'Raleway', 'Oswald', 'Lato'],
  'Montserrat Alternates': [
    'Montserrat',
    'Roboto',
    'Open Sans',
    'Raleway',
    'Lato',
  ],
  'Montserrat Subrayada': [
    'Montserrat',
    'Roboto',
    'Open Sans',
    'Raleway',
    'Lato',
  ],
  Moul: [
    'Roboto',
    'Montserrat',
    'Moulpali',
    'Roboto Slab',
    'Montserrat Alternates',
  ],
  Moulpali: [
    'Roboto',
    'Montserrat',
    'Moul',
    'Montserrat Subrayada',
    'Roboto Slab',
  ],
  'Mountains of Christmas': [
    'Open Sans',
    'Lato',
    'Lobster',
    'Arvo',
    'Dancing Script',
  ],
  'Mouse Memoirs': ['Roboto', 'Open Sans', 'Raleway', 'Montserrat', 'Lato'],
  'Mr Bedfort': [
    'Roboto',
    'Open Sans',
    'Abril Fatface',
    'Montez',
    'Alex Brush',
  ],
  'Mr Dafoe': ['Open Sans', 'Roboto', 'Oswald', 'Montserrat', 'Pacifico'],
  'Mr De Haviland': [
    'Lobster',
    'Marck Script',
    'Niconne',
    'Patrick Hand',
    'Josefin Slab',
  ],
  'Mrs Saint Delafield': ['Open Sans', 'Roboto', 'Raleway', 'Oswald', 'Lato'],
  'Mrs Sheppards': ['Open Sans', 'Roboto', 'Montserrat', 'Oswald', 'Raleway'],
  Mukta: ['Roboto', 'Montserrat', 'Raleway', 'Titillium Web'],
  'Mukta Mahee': [
    'Roboto',
    'Open Sans',
    'Playfair Display',
    'Montserrat',
    'Mukta',
  ],
  'Mukta Malar': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  'Mukta Vaani': ['Roboto', 'Oswald', 'Teko', 'Montserrat', 'Playfair Display'],
  Mulish: ['Open Sans', 'Montserrat', 'Lato', 'Poppins', 'Source Sans Pro'],
  MuseoModerno: [
    'Roboto',
    'Roboto Slab',
    'Mystery Quest',
    'Mukta Vaani',
    'Nanum Brush Script',
  ],
  'Mystery Quest': [
    'Open Sans',
    'Raleway',
    'Quicksand',
    'Oswald',
    'Montserrat',
  ],
  NTR: ['Montserrat', 'Roboto', 'Lato', 'Open Sans', 'Quattrocento Sans'],
  'Nanum Brush Script': [
    'Nanum Gothic',
    'Nanum Pen Script',
    'Nanum Gothic Coding',
    'Nanum Myeongjo',
    'Black Han Sans',
  ],
  'Nanum Gothic': ['Roboto', 'Open Sans', 'Noto Sans KR', 'Lato', 'Montserrat'],
  'Nanum Gothic Coding': [
    'Nanum Gothic',
    'Nanum Myeongjo',
    'Gothic A1',
    'Nanum Brush Script',
    'Nanum Pen Script',
  ],
  'Nanum Myeongjo': [
    'Nanum Gothic',
    'Roboto',
    'Montserrat',
    'Noto Sans KR',
    'Black Han Sans',
  ],
  'Nanum Pen Script': [
    'Nanum Myeongjo',
    'Nanum Brush Script',
    'Nanum Gothic Coding',
    'Black Han Sans',
    'Nanum Gothic',
  ],
  Neucha: [
    'Open Sans',
    'Open Sans Condensed',
    'Lobster',
    'Bad Script',
    'Roboto',
  ],
  Neuton: ['Montserrat', 'Roboto', 'Open Sans', 'Pacifico', 'Lato'],
  'News Cycle': ['Roboto', 'Roboto Condensed', 'Open Sans', 'Oswald', 'Lato'],
  'New Rocker': [
    'Roboto',
    'Open Sans',
    'Sacramento',
    'Raleway Dots',
    'Mouse Memoirs',
  ],
  Niconne: [
    'Lobster',
    'Fredericka the Great',
    'Josefin Slab',
    'Open Sans',
    'Anton',
  ],
  Niramit: ['Roboto', 'Open Sans', 'Montserrat', 'KoHo', 'K2D'],
  'Nixie One': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  Nokora: ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Norican'],
  Norican: ['Roboto', 'Oswald', 'Montserrat', 'Raleway', 'Lato'],
  Nosifer: ['Roboto', 'Open Sans', 'Oswald', 'Titillium Web', 'Monoton'],
  Notable: ['Montserrat', 'Noto Sans', 'Pacifico', 'Neuton', 'Fjalla One'],
  'Nothing You Could Do': [
    'Montserrat',
    'Open Sans',
    'Roboto',
    'Great Vibes',
    'Reenie Beanie',
  ],
  'Noticia Text': ['Lobster', 'Open Sans', 'Play', 'Roboto', 'EB Garamond'],
  'Noto Sans': [
    'Roboto',
    'Open Sans',
    'Noto Serif',
    'Source Sans Pro',
    'Inconsolata',
  ],
  'Noto Sans HK': [
    'Noto Sans JP',
    'Noto Sans KR',
    'Noto Sans',
    'Noto Sans SC',
    'Lato',
  ],
  'Noto Sans JP': ['Roboto', 'Noto Sans', 'Open Sans'],
  'Noto Sans KR': ['Roboto', 'Montserrat', 'Noto Sans JP', 'Open Sans', 'Lato'],
  'Noto Sans SC': [
    'Noto Sans JP',
    'Noto Sans TC',
    'Noto Sans KR',
    'Noto Sans',
    'Lato',
  ],
  'Noto Sans TC': [
    'Roboto',
    'Lato',
    'Noto Sans',
    'Noto Sans JP',
    'Noto Sans KR',
  ],
  'Noto Serif': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Roboto Slab',
    'Noto Sans',
  ],
  'Noto Serif JP': [
    'Noto Sans JP',
    'Lato',
    'Open Sans',
    'Roboto',
    'Montserrat',
  ],
  'Noto Serif KR': [
    'Noto Sans KR',
    'Nanum Gothic',
    'Nanum Myeongjo',
    'Nanum Pen Script',
    'Do Hyeon',
  ],
  'Noto Serif SC': [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Lato',
    'Playfair Display',
  ],
  'Noto Serif TC': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Noto Sans TC',
    'Lato',
  ],
  'Nova Cut': ['Oswald', 'Roboto', 'Nova Flat', 'Nova Oval', 'Nova Mono'],
  'Nova Flat': ['Nova Oval', 'Nova Round', 'Nunito', 'Nova Slim', 'Pacifico'],
  'Nova Mono': ['Open Sans', 'Lato', 'Dancing Script', 'Ubuntu', 'PT Sans'],
  'Nova Oval': ['Nova Flat', 'Nova Slim', 'Nunito', 'Pacifico', 'Rokkitt'],
  'Nova Round': ['Nunito', 'Play', 'Nova Slim', 'PT Sans Narrow', 'Lobster'],
  'Nova Script': ['Roboto', 'Orbitron', 'Nunito', 'Raleway', 'Pacifico'],
  'Nova Slim': ['Nova Round', 'Nunito', 'Nova Oval', 'Nova Flat', 'Rokkitt'],
  'Nova Square': ['Open Sans', 'Roboto', 'Oswald', 'Lato', 'Abel'],
  Numans: ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  Nunito: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Poppins'],
  'Nunito Sans': ['Roboto', 'Montserrat', 'Open Sans', 'Lato', 'Raleway'],
  'Odibee Sans': [
    'Roboto',
    'Open Sans',
    'Nunito',
    'Noto Serif',
    'Roboto Condensed',
  ],
  'Odor Mean Chey': ['Roboto', 'Montserrat', 'Open Sans', 'Lato', 'Nunito'],
  Offside: [
    'Open Sans',
    'Oswald',
    'Merriweather',
    'Open Sans Condensed',
    'Josefin Slab',
  ],
  'Old Standard TT': ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Ubuntu'],
  Oldenburg: [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Oleo Script',
  ],
  'Oleo Script': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  'Oleo Script Swash Caps': [
    'Open Sans',
    'Roboto',
    'Montserrat',
    'Raleway',
    'Lato',
  ],
  'Open Sans': ['Roboto', 'Lato', 'Montserrat', 'Merriweather', 'Raleway'],
  'Open Sans Condensed': [
    'Open Sans',
    'Roboto',
    'Oswald',
    'Lato',
    'Playfair Display',
  ],
  Oranienbaum: ['Play', 'Pacifico', 'Quicksand', 'Ubuntu Mono', 'Abel'],
  Orbitron: ['Raleway', 'Roboto', 'Open Sans', 'Lato', 'Montserrat'],
  Oregano: ['Open Sans', 'Roboto', 'Oswald', 'Lobster', 'Roboto Condensed'],
  Orienta: ['Open Sans', 'Roboto', 'Roboto Slab', 'Montserrat', 'Oswald'],
  'Original Surfer': [
    'Noto Serif',
    'Oranienbaum',
    'Open Sans Condensed',
    'Pacifico',
    'Abel',
  ],
  Oswald: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Source Sans Pro'],
  'Over the Rainbow': [
    'Open Sans',
    'Oswald',
    'Amatic SC',
    'Sacramento',
    'Allura',
  ],
  Overlock: ['Lobster', 'Open Sans', 'Josefin Slab', 'EB Garamond', 'Signika'],
  'Overlock SC': ['Roboto', 'Open Sans', 'Oswald', 'PT Sans', 'Lato'],
  Overpass: [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Playfair Display',
    'Raleway',
  ],
  'Overpass Mono': ['Roboto', 'Open Sans', 'Lato', 'Raleway', 'PT Sans'],
  Ovo: ['Roboto', 'Roboto Slab', 'Open Sans', 'Lato', 'Montserrat'],
  Oxanium: ['Roboto', 'Open Sans', 'Roboto Slab', 'Oxygen', 'Ovo'],
  Oxygen: ['Roboto', 'Ubuntu', 'Droid Sans', 'Cantarell', 'Fira Sans'],
  'Oxygen Mono': [
    'Roboto',
    'Open Sans',
    'PT Sans',
    'Oswald',
    'Source Sans Pro',
  ],
  'PT Mono': ['Open Sans', 'PT Sans', 'PT Serif', 'Lobster', 'Lora'],
  'PT Sans': ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'PT Serif'],
  'PT Sans Caption': [
    'PT Sans',
    'Open Sans',
    'Roboto',
    'PT Sans Narrow',
    'Open Sans Condensed',
  ],
  'PT Sans Narrow': ['Open Sans', 'PT Sans', 'Roboto', 'Oswald', 'Roboto Slab'],
  'PT Serif': ['Open Sans', 'Roboto', 'PT Sans', 'Playfair Display', 'Oswald'],
  'PT Serif Caption': [
    'PT Sans Caption',
    'PT Sans Narrow',
    'Lora',
    'Lobster',
    'Play',
  ],
  Pacifico: ['Open Sans', 'Roboto', 'Oswald', 'Montserrat', 'Merriweather'],
  Padauk: ['Roboto', 'Open Sans', 'Pacifico', 'Montserrat', 'Raleway'],
  Palanquin: ['Roboto', 'Lato', 'Montserrat', 'Playfair Display', 'Oswald'],
  'Palanquin Dark': ['Hind', 'Roboto', 'Playfair Display', 'Oswald', 'Nunito'],
  Pangolin: ['Open Sans', 'Roboto', 'Oswald', 'Merriweather', 'Caveat'],
  Paprika: ['Roboto', 'Open Sans', 'Roboto Slab', 'Raleway', 'Oswald'],
  Parisienne: ['Open Sans', 'Roboto', 'Oswald', 'Lobster', 'Roboto Condensed'],
  'Passero One': ['Roboto', 'Lato', 'Raleway', 'Anton', 'Bangers'],
  'Passion One': [
    'Roboto',
    'Open Sans',
    'Oswald',
    'Merriweather',
    'Playfair Display',
  ],
  'Pathway Gothic One': [
    'Open Sans',
    'Roboto',
    'Lato',
    'Raleway',
    'Montserrat',
  ],
  'Patrick Hand': ['Open Sans', 'Lobster', 'Roboto', 'Lato', 'Source Sans Pro'],
  'Patrick Hand SC': ['Roboto', 'Raleway', 'Lato', 'Oswald', 'Dancing Script'],
  Pattaya: ['Roboto', 'Open Sans', 'Montserrat', 'Athiti', 'Pacifico'],
  'Patua One': ['Roboto', 'Open Sans', 'Lato', 'Raleway', 'Montserrat'],
  Pavanam: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Raleway'],
  'Paytone One': ['Raleway', 'Open Sans', 'Roboto', 'Questrial', 'Oswald'],
  Peddana: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Poppins'],
  Peralta: ['Roboto', 'Oswald', 'Open Sans', 'Raleway', 'Montserrat'],
  'Permanent Marker': [
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Playfair Display',
  ],
  'Petit Formal Script': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Oswald',
    'Pacifico',
  ],
  Petrona: ['Roboto', 'Open Sans', 'Lato', 'Playfair Display', 'Raleway'],
  Philosopher: ['Roboto', 'Open Sans', 'Lora', 'Montserrat', 'Lobster'],
  Piedra: ['Open Sans', 'Roboto', 'Philosopher', 'Pacifico', 'Oswald'],
  'Pinyon Script': ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Oswald'],
  'Pirata One': ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Oswald'],
  Plaster: ['Roboto', 'Open Sans', 'Playball', 'Roboto Slab', 'Montserrat'],
  Play: ['Open Sans', 'Lobster', 'Roboto', 'Oswald', 'Montserrat'],
  Playball: ['Roboto', 'Lato', 'Raleway', 'Open Sans', 'Montserrat'],
  'Playfair Display': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  'Playfair Display SC': [
    'Playfair Display',
    'Montserrat',
    'Roboto',
    'Open Sans',
    'Oswald',
  ],
  Podkova: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Lato'],
  'Poiret One': ['Open Sans', 'Roboto', 'Lobster', 'Lora', 'Pacifico'],
  'Poller One': ['Roboto', 'Open Sans', 'Raleway', 'Rock Salt', 'Montserrat'],
  Poly: ['Montserrat', 'Lato', 'Open Sans', 'Raleway', 'Roboto'],
  Pompiere: ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Ubuntu'],
  'Pontano Sans': ['Roboto', 'Open Sans', 'Oswald', 'Montserrat', 'Raleway'],
  'Poor Story': [
    'Nanum Gothic Coding',
    'Gothic A1',
    'Black Han Sans',
    'Stylish',
    'Black And White Picture',
  ],
  Poppins: [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Roboto Slab',
    'Playfair Display',
  ],
  'Port Lligat Sans': [
    'Roboto',
    'Oswald',
    'Pacifico',
    'Open Sans',
    'Port Lligat Slab',
  ],
  'Port Lligat Slab': [
    'Poppins',
    'Roboto',
    'Open Sans',
    'Raleway',
    'Montserrat',
  ],
  'Pragati Narrow': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Lato',
  ],
  Prata: ['Roboto', 'Open Sans', 'Montserrat', 'Playfair Display', 'Oswald'],
  Preahvihear: [
    'Roboto',
    'Roboto Slab',
    'Prata',
    'Port Lligat Slab',
    'Port Lligat Sans',
  ],
  'Press Start 2P': [
    'Lobster',
    'Noto Serif',
    'Playfair Display SC',
    'Quicksand',
    'Russo One',
  ],
  Pridi: ['Roboto', 'Prompt', 'Open Sans', 'Kanit', 'Mitr'],
  'Princess Sofia': [
    'Roboto',
    'Sacramento',
    'Rock Salt',
    'Tangerine',
    'Raleway',
  ],
  Prociono: ['Roboto', 'Lato', 'Raleway', 'Abril Fatface', 'Roboto Slab'],
  Prompt: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Oswald'],
  'Prosto One': [
    'Lobster',
    'Open Sans Condensed',
    'Lora',
    'Ubuntu Condensed',
    'PT Sans Narrow',
  ],
  'Proza Libre': ['Roboto Slab', 'Montserrat', 'Poppins', 'Rubik', 'PT Sans'],
  'Public Sans': ['Roboto', 'Open Sans', 'Lato', 'Poppins', 'PT Sans'],
  Puritan: ['Open Sans', 'Roboto', 'Raleway', 'Yanone Kaffeesatz', 'PT Sans'],
  'Purple Purse': [
    'Roboto',
    'Open Sans',
    'Noto Serif',
    'Romanesco',
    'Rammetto One',
  ],
  Quando: ['Roboto', 'Open Sans', 'Raleway', 'Lato', 'Montserrat'],
  Quantico: ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  Quattrocento: [
    'Oswald',
    'Open Sans',
    'Quattrocento Sans',
    'Montserrat',
    'Lora',
  ],
  'Quattrocento Sans': [
    'Montserrat',
    'Roboto',
    'Raleway',
    'Titillium Web',
    'Open Sans',
  ],
  Questrial: ['Roboto', 'Raleway', 'Montserrat', 'Lato', 'Oswald'],
  Quicksand: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  Quintessential: [
    'Open Sans',
    'Roboto',
    'Raleway',
    'Roboto Slab',
    'Montserrat',
  ],
  Qwigley: ['Open Sans', 'Roboto', 'Oswald', 'Josefin Slab', 'Quicksand'],
  'Racing Sans One': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Oswald',
    'Source Sans Pro',
  ],
  Radley: ['Open Sans', 'Montserrat', 'Roboto', 'Lato', 'Raleway'],
  Rajdhani: ['Roboto', 'Open Sans', 'Poppins', 'Raleway', 'Montserrat'],
  Rakkas: ['Montserrat', 'Cairo', 'Roboto Slab', 'Work Sans', 'Nunito'],
  Raleway: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Oswald'],
  'Raleway Dots': ['Raleway', 'Open Sans', 'Roboto', 'Montserrat', 'Lato'],
  Ramabhadra: ['Roboto', 'Raleway', 'Roboto Slab', 'Open Sans', 'Montserrat'],
  Ramaraja: ['Roboto', 'Raleway', 'Ramabhadra', 'Rambla', 'Rammetto One'],
  Rambla: ['Oswald', 'Muli', 'Roboto', 'Open Sans', 'Raleway'],
  'Rammetto One': ['Roboto', 'Open Sans', 'Lobster', 'Oswald', 'Roboto Slab'],
  Ranchers: ['Open Sans', 'Raleway', 'Lato', 'Ribeye Marrow', 'Oswald'],
  Rancho: ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Pacifico'],
  Ranga: ['Roboto', 'Raleway', 'Open Sans', 'Lato', 'Oswald'],
  Rasa: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Muli'],
  Rationale: ['Open Sans', 'Roboto', 'Oswald', 'Raleway', 'Philosopher'],
  'Ravi Prakash': [
    'Roboto',
    'Righteous',
    'Ranchers',
    'Roboto Slab',
    'Rammetto One',
  ],
  Recursive: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Lato'],
  'Red Hat Display': [
    'Red Hat Text',
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Montserrat',
  ],
  'Red Hat Text': [
    'Roboto',
    'Montserrat',
    'Lato',
    'Playfair Display',
    'Nunito',
  ],
  'Red Rose': ['Roboto', 'Montserrat', 'Open Sans', 'Lato', 'Poppins'],
  Redressed: ['Roboto', 'Open Sans', 'Raleway', 'Rochester', 'Roboto Slab'],
  'Reem Kufi': ['Roboto', 'Open Sans', 'Montserrat', 'Cairo', 'Changa'],
  'Reenie Beanie': [
    'Roboto',
    'Open Sans',
    'Indie Flower',
    'Shadows Into Light',
    'Ubuntu',
  ],
  Revalia: ['Roboto', 'Raleway', 'Reenie Beanie', 'Open Sans', 'Rock Salt'],
  'Rhodium Libre': [
    'Roboto',
    'Roboto Slab',
    'Ribeye',
    'Roboto Condensed',
    'Ribeye Marrow',
  ],
  Ribeye: [
    'Roboto',
    'Righteous',
    'Open Sans',
    'Roboto Slab',
    'Roboto Condensed',
  ],
  'Ribeye Marrow': ['Open Sans', 'Raleway', 'Roboto', 'Oswald', 'Henny Penny'],
  Righteous: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Raleway'],
  Risque: [
    'Roboto',
    'Roboto Slab',
    'Roboto Condensed',
    'Rochester',
    'Rock Salt',
  ],
  Roboto: ['Open Sans', 'Noto Sans JP', 'Lato'],
  'Roboto Condensed': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Lato',
  ],
  'Roboto Mono': ['Roboto', 'Open Sans', 'Roboto Slab', 'Lato', 'Montserrat'],
  'Roboto Slab': [
    'Roboto',
    'Open Sans',
    'Roboto Condensed',
    'Montserrat',
    'Lato',
  ],
  Rochester: ['Roboto', 'Rokkitt', 'Open Sans', 'Quicksand', 'Alex Brush'],
  'Rock Salt': ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Dancing Script'],
  Rokkitt: ['Open Sans', 'Lato', 'Raleway', 'Ubuntu', 'Source Sans Pro'],
  Romanesco: [
    'Open Sans',
    'Sacramento',
    'Oswald',
    'Open Sans Condensed',
    'Francois One',
  ],
  'Ropa Sans': ['Roboto Slab', 'Montserrat', 'Poppins', 'Roboto', 'Rubik'],
  Rosario: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  Rosarivo: ['Roboto', 'Roboto Slab', 'Open Sans', 'Ropa Sans', 'Rokkitt'],
  'Rouge Script': ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Dancing Script'],
  Rowdies: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Poppins'],
  'Rozha One': ['Roboto', 'Montserrat', 'Playfair Display', 'Oswald', 'Anton'],
  Rubik: ['Roboto', 'Open Sans', 'Montserrat', 'Poppins', 'Lato'],
  'Rubik Mono One': [
    'Open Sans',
    'Montserrat',
    'Playfair Display',
    'Pacifico',
    'Oswald',
  ],
  Ruda: ['Open Sans', 'Oswald', 'Roboto', 'Lato', 'Roboto Slab'],
  Rufina: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  'Ruge Boogie': [
    'Roboto',
    'Rock Salt',
    'Rouge Script',
    'Rosario',
    'Sacramento',
  ],
  Ruluko: ['Roboto', 'Open Sans', 'Raleway', 'Rufina', 'Montserrat'],
  'Rum Raisin': ['Roboto', 'Open Sans', 'Russo One', 'Audiowide', 'Ropa Sans'],
  'Ruslan Display': [
    'Lobster',
    'Russo One',
    'Open Sans Condensed',
    'Philosopher',
    'Neucha',
  ],
  'Russo One': ['Roboto', 'PT Sans', 'Open Sans', 'Lobster', 'Pacifico'],
  Ruthie: [
    'Sacramento',
    'Open Sans',
    'Rochester',
    'Rock Salt',
    'Shadows Into Light',
  ],
  Rye: ['Roboto', 'Raleway', 'Oswald', 'Lato', 'Montserrat'],
  Sacramento: [
    'Open Sans',
    'Pacifico',
    'Josefin Slab',
    'Oswald',
    'Merriweather',
  ],
  Sahitya: ['Roboto', 'Roboto Slab', 'Sacramento', 'Sail', 'Roboto Condensed'],
  Sail: ['Roboto', 'Montserrat', 'Playfair Display', 'Josefin Slab', 'Oswald'],
  Saira: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Roboto Condensed'],
  'Saira Condensed': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Saira',
  ],
  'Saira Extra Condensed': [
    'Open Sans',
    'Poppins',
    'Playfair Display',
    'Roboto',
    'Montserrat',
  ],
  'Saira Semi Condensed': [
    'Roboto',
    'Open Sans',
    'PT Sans Narrow',
    'Lato',
    'Roboto Slab',
  ],
  'Saira Stencil One': [
    'Roboto',
    'Roboto Slab',
    'Roboto Mono',
    'Salsa',
    'Rochester',
  ],
  Salsa: [
    'Rokkitt',
    'Rochester',
    'Ruslan Display',
    'Satisfy',
    'PT Sans Narrow',
  ],
  Sanchez: ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Oswald'],
  Sancreek: ['Open Sans', 'Roboto', 'Oswald', 'Raleway', 'Rock Salt'],
  Sansita: [
    'Roboto',
    'Open Sans',
    'Roboto Condensed',
    'Raleway',
    'Roboto Slab',
  ],
  Sarabun: ['Roboto', 'Open Sans', 'Kanit', 'Montserrat', 'Prompt'],
  Sarala: ['Adamina', 'Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat'],
  Sarina: ['Lobster', 'Play', 'Anton', 'Signika', 'Spinnaker'],
  Sarpanch: [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Roboto Condensed',
    'Raleway',
  ],
  Satisfy: ['Open Sans', 'Roboto', 'Lato', 'Pacifico', 'Raleway'],
  'Sawarabi Gothic': [
    'Sawarabi Mincho',
    'Roboto',
    'Noto Sans JP',
    'Lato',
    'Noto Serif JP',
  ],
  'Sawarabi Mincho': [
    'Roboto',
    'Noto Sans JP',
    'Noto Serif JP',
    'Sawarabi Gothic',
    'Lato',
  ],
  Scada: ['Noto Serif', 'Lora', 'Arimo', 'Noto Sans', 'Cuprum'],
  Scheherazade: ['Roboto', 'Open Sans', 'Lateef', 'Amiri', 'Montserrat'],
  Schoolbell: ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Rock Salt'],
  'Scope One': ['Roboto', 'Montserrat', 'Trocchi', 'Roboto Slab', 'Lato'],
  'Seaweed Script': [
    'Open Sans',
    'Kaushan Script',
    'Oswald',
    'Pacifico',
    'Merriweather',
  ],
  'Secular One': ['Open Sans', 'Montserrat', 'Rubik', 'Assistant', 'Heebo'],
  'Sedgwick Ave': [
    'Roboto',
    'Montserrat',
    'Raleway',
    'Shadows Into Light',
    'Playfair Display',
  ],
  'Sedgwick Ave Display': [
    'Roboto',
    'Shadows Into Light',
    'Montserrat',
    'Sedgwick Ave',
    'Roboto Slab',
  ],
  Sen: ['Roboto', 'Roboto Slab', 'Open Sans', 'Montserrat', 'Roboto Mono'],
  Sevillana: [
    'Roboto',
    'Shadows Into Light',
    'Open Sans',
    'Roboto Slab',
    'Seymour One',
  ],
  'Seymour One': [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Roboto Condensed',
    'Russo One',
  ],
  'Shadows Into Light': [
    'Open Sans',
    'Roboto',
    'Raleway',
    'Montserrat',
    'Lato',
  ],
  'Shadows Into Light Two': [
    'Open Sans',
    'Roboto',
    'Raleway',
    'Lato',
    'Source Sans Pro',
  ],
  Shanti: ['Raleway', 'Orbitron', 'Allerta', 'Six Caps', 'Paytone One'],
  Share: ['Roboto', 'Open Sans', 'Lato', 'Roboto Condensed', 'Source Sans Pro'],
  'Share Tech': ['Open Sans', 'Montserrat', 'Roboto', 'Lato', 'Roboto Slab'],
  'Share Tech Mono': ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Oswald'],
  Shojumaru: ['Roboto', 'Ubuntu', 'Roboto Slab', 'Lato', 'Montserrat'],
  'Short Stack': [
    'Open Sans',
    'Roboto',
    'Gloria Hallelujah',
    'Lato',
    'Raleway',
  ],
  Shrikhand: [
    'Roboto',
    'Montserrat',
    'Open Sans',
    'Raleway',
    'Playfair Display',
  ],
  Siemreap: ['Roboto', 'Roboto Slab', 'Signika', 'Roboto Condensed', 'Rokkitt'],
  'Sigmar One': ['Open Sans', 'Raleway', 'Oswald', 'Roboto', 'Pacifico'],
  Signika: ['Roboto', 'Open Sans', 'Arvo', 'Montserrat', 'Anton'],
  'Signika Negative': [
    'Open Sans',
    'Roboto',
    'Lato',
    'Source Sans Pro',
    'Montserrat',
  ],
  Simonetta: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Source Sans Pro'],
  'Single Day': [
    'Song Myung',
    'Sunflower',
    'Stylish',
    'Yeon Sung',
    'Poor Story',
  ],
  Sintony: ['Montserrat', 'Open Sans', 'Roboto', 'Lato', 'Oswald'],
  'Sirin Stencil': [
    'Open Sans',
    'Dancing Script',
    'Lato',
    'Rock Salt',
    'Finger Paint',
  ],
  'Six Caps': ['Open Sans', 'Oswald', 'Roboto', 'Lobster', 'Montserrat'],
  Skranji: ['Roboto', 'Open Sans', 'Oswald', 'Roboto Slab', 'Lato'],
  'Slabo 13px': [
    'Ultra',
    'Material Icons Extended',
    'Open Sans',
    'Lora',
    'Lato',
  ],
  'Slabo 27px': ['Roboto', 'Lato', 'Montserrat', 'Open Sans'],
  Slackey: [
    'Lobster',
    'Raleway',
    'Yanone Kaffeesatz',
    'Fontdiner Swanky',
    'Lato',
  ],
  Smokum: ['Rock Salt', 'Open Sans', 'Raleway', 'UnifrakturMaguntia', 'Roboto'],
  Smythe: ['Roboto', 'Rokkitt', 'Rock Salt', 'Six Caps', 'Sniglet'],
  Sniglet: ['Cabin', 'Quicksand', 'Viga', 'Cabin Condensed', 'Roboto'],
  Snippet: [
    'Quicksand',
    'Rochester',
    'Didact Gothic',
    'Rokkitt',
    'Marck Script',
  ],
  'Snowburst One': [
    'Roboto',
    'Open Sans',
    'Pacifico',
    'Sacramento',
    'Montserrat',
  ],
  'Sofadi One': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Sofia',
    'Snowburst One',
  ],
  Sofia: ['Quicksand', 'Lobster', 'Lora', 'Bad Script', 'Satisfy'],
  Solway: [
    'Roboto',
    'Roboto Slab',
    'Roboto Condensed',
    'Open Sans',
    'Montserrat',
  ],
  'Song Myung': ['Stylish', 'Roboto', 'Gothic A1', 'Sunflower', 'Do Hyeon'],
  'Sonsie One': ['Open Sans', 'Roboto', 'Krona One', 'Titan One', 'Rum Raisin'],
  Sora: ['Roboto', 'Open Sans', 'Montserrat', 'Poppins', 'Lato'],
  'Sorts Mill Goudy': [
    'Open Sans',
    'Montserrat',
    'Roboto',
    'Source Sans Pro',
    'Oswald',
  ],
  'Source Code Pro': [
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Source Sans Pro',
  ],
  'Source Sans Pro': ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Oswald'],
  'Source Serif Pro': [
    'Source Sans Pro',
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
  ],
  'Space Mono': [
    'Montserrat',
    'Work Sans',
    'Libre Baskerville',
    'Poppins',
    'Roboto Slab',
  ],
  Spartan: ['Roboto', 'Open Sans', 'Montserrat', 'Roboto Slab', 'Lato'],
  'Special Elite': [
    'Oswald',
    'Merriweather',
    'Open Sans Condensed',
    'Pacifico',
    'Josefin Slab',
  ],
  Spectral: ['Rubik', 'Montserrat', 'Poppins', 'Roboto Slab', 'Work Sans'],
  'Spectral SC': ['Montserrat', 'Oswald', 'Lato', 'Raleway', 'Roboto'],
  'Spicy Rice': ['Griffy', 'Raleway', 'Oswald', 'Cabin', 'Pacifico'],
  Spinnaker: ['Jura', 'Josefin Slab', 'Lobster', 'Jockey One', 'Open Sans'],
  Spirax: ['Roboto', 'Montserrat', 'Open Sans', 'Raleway', 'Oswald'],
  'Squada One': ['Open Sans', 'Roboto', 'Oswald', 'Lato', 'Raleway'],
  'Sree Krushnadevaraya': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Squada One',
    'Spirax',
  ],
  Sriracha: ['Roboto', 'Open Sans', 'Comfortaa', 'Kanit', 'Prompt'],
  Srisakdi: ['Roboto', 'Sriracha', 'Krub', 'Montserrat', 'Raleway'],
  Staatliches: [
    'Open Sans',
    'Montserrat',
    'Lato',
    'Raleway',
    'Source Sans Pro',
  ],
  Stalemate: ['Roboto', 'Open Sans', 'Raleway', 'Abril Fatface', 'Amatic SC'],
  'Stalinist One': [
    'Roboto',
    'Open Sans',
    'Lobster',
    'Poiret One',
    'Roboto Slab',
  ],
  'Stardos Stencil': ['Open Sans', 'Raleway', 'Roboto', 'Lato', 'Montserrat'],
  'Stint Ultra Condensed': [
    'Roboto',
    'Lato',
    'Open Sans Condensed',
    'Yanone Kaffeesatz',
    'Permanent Marker',
  ],
  'Stint Ultra Expanded': [
    'Lobster',
    'Didact Gothic',
    'Open Sans Condensed',
    'Quicksand',
    'Syncopate',
  ],
  Stoke: ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Raleway'],
  Strait: [
    'Open Sans',
    'Amatic SC',
    'Oswald',
    'Open Sans Condensed',
    'Merriweather',
  ],
  Stylish: ['Sunflower', 'Song Myung', 'Do Hyeon', 'Hi Melody', 'Roboto'],
  'Sue Ellen Francisco': [
    'Montserrat',
    'Amatic SC',
    'Pacifico',
    'Indie Flower',
    'Shadows Into Light',
  ],
  'Suez One': ['Roboto', 'Montserrat', 'Anton', 'Lobster', 'Libre Baskerville'],
  'Sulphur Point': [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Montserrat',
    'Roboto Condensed',
  ],
  Sumana: ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Raleway'],
  Sunflower: [
    'Nanum Myeongjo',
    'Stylish',
    'Gothic A1',
    'Do Hyeon',
    'Nanum Gothic Coding',
  ],
  Sunshiney: ['Rock Salt', 'Schoolbell', 'Syncopate', 'Tangerine', 'Ubuntu'],
  'Supermercado One': [
    'Open Sans',
    'Raleway',
    'Sue Ellen Francisco',
    'Abril Fatface',
    'Sunshiney',
  ],
  Sura: ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Suranna'],
  Suranna: ['Roboto', 'Open Sans', 'Montserrat', 'Playfair Display', 'Lato'],
  Suravaram: [
    'Roboto',
    'Open Sans',
    'Sura',
    'Swanky and Moo Moo',
    'Suwannaphum',
  ],
  Suwannaphum: ['Roboto', 'Roboto Slab', 'Taprom', 'Tangerine', 'Montserrat'],
  'Swanky and Moo Moo': [
    'Open Sans',
    'Raleway',
    'Lato',
    'Yeseva One',
    'Nixie One',
  ],
  Syncopate: ['Anton', 'Crafty Girls', 'Open Sans', 'Roboto', 'Lobster'],
  Syne: ['Roboto', 'Open Sans', 'Montserrat', 'Source Sans Pro', 'Oswald'],
  Tajawal: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Oswald'],
  Tangerine: ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Ubuntu'],
  Taprom: ['Roboto', 'Roboto Slab', 'Tangerine', 'Montserrat', 'Suwannaphum'],
  Tauri: ['Open Sans', 'Montserrat', 'Roboto Slab', 'Raleway', 'Lato'],
  Taviraj: ['Roboto', 'Open Sans', 'Montserrat', 'Trirong', 'Kanit'],
  Teko: ['Open Sans', 'Montserrat', 'Oswald', 'Hind', 'Anton'],
  Telex: ['Lato', 'Open Sans', 'Dancing Script', 'Roboto', 'Montserrat'],
  'Tenali Ramakrishna': [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Tenor Sans',
    'Teko',
  ],
  'Tenor Sans': [
    'Quicksand',
    'Bad Script',
    'Pacifico',
    'Didact Gothic',
    'Abel',
  ],
  'Text Me One': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Lato',
    'Roboto Condensed',
  ],
  Thasadith: ['Roboto', 'Open Sans', 'Sarabun', 'Montserrat', 'Trirong'],
  'The Girl Next Door': ['Roboto', 'Open Sans', 'Lobster', 'Raleway', 'Oswald'],
  Tienne: ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Oswald'],
  Tillana: ['Roboto', 'Open Sans', 'Montserrat', 'Work Sans', 'Ubuntu'],
  Timmana: ['Roboto', 'Open Sans', 'Oswald', 'Roboto Slab', 'Raleway'],
  Tinos: ['Open Sans', 'Arimo', 'Roboto', 'Lobster', 'Cousine'],
  'Titan One': [
    'Open Sans',
    'Roboto',
    'Oswald',
    'Source Sans Pro',
    'Roboto Slab',
  ],
  'Titillium Web': ['Roboto', 'Montserrat', 'Open Sans', 'Raleway', 'Lato'],
  Tomorrow: [
    'Roboto',
    'Roboto Slab',
    'Titillium Web',
    'Open Sans',
    'Trade Winds',
  ],
  'Trade Winds': [
    'Open Sans',
    'Roboto',
    'Rock Salt',
    'UnifrakturMaguntia',
    'Sacramento',
  ],
  Trirong: ['Open Sans', 'Roboto', 'Kanit', 'Taviraj', 'Montserrat'],
  Trocchi: ['Open Sans', 'Roboto', 'Montserrat', 'Raleway', 'Noto Sans'],
  Trochut: [
    'Alegreya',
    'Ubuntu Mono',
    'Quicksand',
    'Anonymous Pro',
    'Syncopate',
  ],
  Trykker: ['Roboto', 'Open Sans', 'Montserrat', 'Trocchi', 'Roboto Slab'],
  'Tulpen One': [
    'Roboto',
    'Open Sans',
    'Raleway',
    'Oswald',
    'Roboto Condensed',
  ],
  'Turret Road': [
    'Roboto',
    'Ubuntu',
    'Roboto Slab',
    'Ubuntu Condensed',
    'Ubuntu Mono',
  ],
  Ubuntu: ['Roboto', 'Open Sans', 'Droid Sans', 'Oxygen', 'Cantarell'],
  'Ubuntu Condensed': ['Open Sans', 'Roboto', 'Lobster', 'Oswald', 'Lora'],
  'Ubuntu Mono': [
    'Lobster',
    'Open Sans',
    'Roboto',
    'Rokkitt',
    'Playfair Display',
  ],
  Ultra: [
    'Slabo 13px',
    'Material Icons Extended',
    'Open Sans',
    'Roboto',
    'Pacifico',
  ],
  'Uncial Antiqua': [
    'Open Sans',
    'Roboto Slab',
    'Montserrat',
    'Underdog',
    'Unica One',
  ],
  Underdog: ['Lobster', 'Jura', 'Noto Serif', 'Forum', 'PT Sans Narrow'],
  'Unica One': ['Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Raleway'],
  UnifrakturCook: ['Open Sans', 'Roboto', 'Pacifico', 'Arvo', 'Montserrat'],
  UnifrakturMaguntia: [
    'Open Sans',
    'Roboto',
    'Lobster',
    'Quicksand',
    'Montserrat',
  ],
  Unkempt: ['Pacifico', 'Satisfy', 'Lobster', 'Crafty Girls', 'Cuprum'],
  Unlock: ['Roboto', 'Open Sans', 'Roboto Slab', 'Montserrat', 'Lato'],
  Unna: ['Roboto', 'Open Sans', 'Source Sans Pro', 'Oswald', 'Muli'],
  VT323: ['Open Sans', 'Lobster', 'Ubuntu', 'PT Sans', 'Architects Daughter'],
  'Vampiro One': [
    'Merriweather',
    'Roboto',
    'Montserrat',
    'Varela Round',
    'Lato',
  ],
  Varela: ['Montserrat', 'Roboto Slab', 'Ubuntu', 'Open Sans', 'Nunito'],
  'Varela Round': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  Varta: ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Roboto Condensed'],
  'Vast Shadow': ['Open Sans', 'Oswald', 'Pacifico', 'Raleway', 'Roboto'],
  'Vesper Libre': ['Roboto', 'Montserrat', 'Ubuntu', 'Vollkorn', 'Lora'],
  'Viaoda Libre': [
    'Roboto',
    'Roboto Slab',
    'Vesper Libre',
    'Vibes',
    'Vast Shadow',
  ],
  Vibes: ['Roboto', 'Vibur', 'Vidaloka', 'Vast Shadow', 'Vesper Libre'],
  Vibur: ['Open Sans', 'Merriweather', 'Josefin Slab', 'Oswald', 'Pacifico'],
  Vidaloka: ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Lato'],
  Viga: ['Quicksand', 'Cabin', 'Cabin Condensed', 'Sniglet', 'Roboto'],
  Voces: ['Lora', 'Lobster', 'Alegreya', 'Anton', 'Poiret One'],
  Volkhov: ['Open Sans', 'Roboto Condensed', 'Montserrat', 'Lato', 'Raleway'],
  Vollkorn: ['Open Sans', 'Roboto', 'Montserrat', 'PT Sans', 'Lora'],
  'Vollkorn SC': [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Playfair Display',
    'Lato',
  ],
  Voltaire: [
    'Open Sans',
    'Roboto',
    'Oswald',
    'Open Sans Condensed',
    'Pacifico',
  ],
  'Waiting for the Sunrise': [
    'Open Sans',
    'Raleway',
    'Rock Salt',
    'Dancing Script',
    'Roboto',
  ],
  Wallpoet: [
    'Roboto',
    'Montserrat',
    'Josefin Sans',
    'The Girl Next Door',
    'Open Sans',
  ],
  'Walter Turncoat': ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Ubuntu'],
  Warnes: ['Roboto', 'Sacramento', 'Clicker Script', 'Italianno', 'Open Sans'],
  Wellfleet: [
    'Roboto',
    'Open Sans',
    'Roboto Slab',
    'Raleway',
    'Yanone Kaffeesatz',
  ],
  'Wendy One': ['Roboto', 'Open Sans', 'Montserrat', 'Lato', 'Raleway'],
  'Wire One': ['Open Sans', 'Roboto', 'Oswald', 'Montserrat', 'Merriweather'],
  'Work Sans': ['Roboto', 'Montserrat', 'Open Sans', 'Poppins', 'Lato'],
  'Yanone Kaffeesatz': ['Open Sans', 'Roboto', 'Lato', 'Raleway', 'Montserrat'],
  Yantramanav: ['Open Sans', 'Montserrat', 'Raleway', 'Roboto', 'Oswald'],
  'Yatra One': [
    'Ubuntu',
    'Montserrat',
    'PT Serif',
    'Noto Sans',
    'Indie Flower',
  ],
  Yellowtail: ['Open Sans', 'Montserrat', 'Roboto', 'Oswald', 'Lato'],
  'Yeon Sung': [
    'Stylish',
    'Song Myung',
    'Poor Story',
    'Do Hyeon',
    'Black Han Sans',
  ],
  'Yeseva One': ['Roboto', 'Open Sans', 'Lobster', 'Lora', 'Pacifico'],
  Yesteryear: ['Open Sans', 'Roboto', 'Raleway', 'Lato', 'Abril Fatface'],
  Yrsa: ['Source Sans Pro', 'Open Sans', 'Roboto', 'Lato', 'Alegreya'],
  'ZCOOL KuaiLe': [
    'ZCOOL QingKe HuangYou',
    'ZCOOL XiaoWei',
    'Roboto',
    'Open Sans',
    'Roboto Slab',
  ],
  'ZCOOL QingKe HuangYou': [
    'Open Sans',
    'Abril Fatface',
    'Montserrat',
    'ZCOOL XiaoWei',
    'ZCOOL KuaiLe',
  ],
  'ZCOOL XiaoWei': ['Open Sans', 'Roboto', 'Montserrat', 'Lato', 'Cookie'],
  Zeyada: [
    'Open Sans',
    'Roboto',
    'Cedarville Cursive',
    'Raleway',
    'Shadows Into Light',
  ],
  'Zhi Mang Xing': [
    'Roboto',
    'Roboto Slab',
    'Zeyada',
    'Zilla Slab',
    'Open Sans',
  ],
  'Zilla Slab': ['Roboto', 'Open Sans', 'Montserrat', 'Raleway', 'Lato'],
  'Zilla Slab Highlight': [
    'Roboto',
    'Roboto Slab',
    'Open Sans',
    'Montserrat',
    'Lato',
  ],
  'Alegreya Sans SC': [
    'Roboto',
    'Open Sans',
    'Lato',
    'Alegreya Sans',
    'Alegreya',
  ],
  'Alfa Slab One': ['Lato', 'Roboto', 'Open Sans', 'Montserrat', 'Raleway'],
  Alike: ['Roboto', 'Open Sans', 'Abril Fatface', 'Montserrat', 'Oswald'],
  Allan: ['Open Sans', 'Roboto', 'Lobster', 'Josefin Sans', 'Corben'],
  'Allerta Stencil': ['Open Sans', 'Roboto', 'Lato', 'Roboto Slab', 'Allerta'],
  Allura: [
    'Open Sans',
    'Amatic SC',
    'Pacifico',
    'Open Sans Condensed',
    'Josefin Slab',
  ],
  Battambang: ['Roboto', 'Roboto Slab', 'Bayon', 'Abril Fatface', 'Abel'],
  Baumans: ['Lobster', 'Alex Brush', 'Pacifico', 'Exo', 'Quicksand'],
  Bayon: ['Roboto', 'Battambang', 'Roboto Slab', 'Open Sans', 'Abel'],
  Belgrano: ['Open Sans', 'Roboto', 'Source Sans Pro', 'Lato', 'Oswald'],
  Belleza: ['Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Raleway'],
  'Bellota Text': [
    'Roboto',
    'Roboto Slab',
    'Bellota',
    'Bebas Neue',
    'Abril Fatface',
  ],
  Bentham: ['Open Sans', 'Lato', 'Raleway', 'Oswald', 'Lobster'],
}
