import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentEvents from 'react-document-events'
import { SHAPE } from '@adalo/constants'
import { createPath } from '@adalo/utils'

import { setCurrentTab, CurrentTab } from 'ducks/magicLayout'
import { updateShape } from '../../../../utils/shapes'
import { unScale, scale } from '../../../../utils/zoom'
import { ESC } from '../../../../utils/keyboard'

import {
  createObject,
  updateObject,
  selectObject,
  getComponentId,
} from '../../../../ducks/editor/objects'

import {
  getEditingShape,
  setEditingShape,
  getDraggingOutControl,
  getDraggingInControl,
  setDraggingOutControl,
  setDraggingInControl,
  endDrag,
  getSelectedPoint,
  setSelectedPoint,
  getDraggingSelectedPoint,
  setDraggingSelectedPoint,
} from '../../../../ducks/editor/shapeEditing'

import { resetTool } from '../../../../ducks/editor/tools'
import Point from './Point'
import './ShapeEditor.css'

class ShapeEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lastCursorPosition: null,
    }
  }

  handleKeyDown = e => {
    const { setEditingShape, resetTool } = this.props

    if (e.which === ESC) {
      setEditingShape(null)
      resetTool()
    }
  }

  handleSelectPoint = index => () => {
    const { setSelectedPoint } = this.props
    const closePointIndex = this.getClosePointIndex()

    if (index === closePointIndex) {
      const { setDraggingOutControl } = this.props

      this.updateObject({ isClosed: true }, 'closePath')

      this.setState({
        lastCursorPosition: null,
      })

      setDraggingOutControl()
    }

    setSelectedPoint(index, true)
  }

  updateObject = (changes, undoKey) => {
    const { object, updateObject } = this.props
    const newObj = { ...object, ...changes }

    if (undoKey) {
      this._lastUndoKey = undoKey
    }

    updateObject(object.id, newObj, this._lastUndoKey || 'vector')
  }

  handleMouseDown = e => {
    const {
      object,
      createObject,
      resetTool,
      setDraggingOutControl,
      setSelectedPoint,
      setEditingShape,
      setCurrentTab,
    } = this.props

    const { selectedPoint } = this.props

    const [x, y] = this.unScalePoint([e.clientX, e.clientY])

    const newPoint = { point: [x, y] }

    if (!object) {
      // Creating Object

      createObject({
        x,
        y,
        type: SHAPE,
        width: 1,
        height: 1,
        points: [{ point: [0, 0] }],
      })

      resetTool()
      setCurrentTab(CurrentTab.Setup)

      this.setState({
        lastCursorPosition: null,
      })

      setDraggingOutControl()
      setSelectedPoint(0)
    } else {
      // Updating Object

      if (!this.canCreatePoint()) {
        setEditingShape(null)
      } else if (selectedPoint === object.points.length - 1) {
        // Drawing path forward
        const points = object.points.slice()
        points.push(newPoint)

        this.setState({ lastCursorPosition: null })

        setDraggingOutControl()
        setSelectedPoint(selectedPoint + 1)

        this.updateObject({ points }, `addPoint-${+new Date()}`)
      } else if (selectedPoint === 0) {
        // Drawing path backward
        const points = object.points.slice()
        points.unshift(newPoint)

        this.updateObject({ points }, `addPoint-${+new Date()}`)

        this.setState({
          lastCursorPosition: null,
        })

        setDraggingOutControl()
      } else {
        // Done editing
        setEditingShape(null)
      }
    }
  }

  handleMouseMove = e => {
    const lastCursorPosition = this.unScalePoint([e.clientX, e.clientY])

    const {
      object,
      draggingInControl,
      draggingOutControl,
      draggingSelectedPoint,
    } = this.props

    const { selectedPoint } = this.props
    const points = (object && object.points) || []

    if (points[selectedPoint] && (draggingInControl || draggingOutControl)) {
      let { points } = object
      points = points.slice()

      const key = draggingInControl ? 'inControl' : 'outControl'
      const otherKey = draggingInControl ? 'outControl' : 'inControl'
      const [pointX, pointY] = points[selectedPoint].point

      const otherPoint = [
        pointX - (lastCursorPosition[0] - pointX),
        pointY - (lastCursorPosition[1] - pointY),
      ]

      points[selectedPoint] = {
        ...points[selectedPoint],
        [key]: lastCursorPosition,
        [otherKey]: otherPoint,
      }

      this.updateObject({ points })

      this.setState({ lastCursorPosition: null })
    } else if (draggingSelectedPoint) {
      const points = object.points.slice()

      const point = points[selectedPoint]
      const diffX = lastCursorPosition[0] - point.point[0]
      const diffY = lastCursorPosition[1] - point.point[1]

      const newPoint = {}
      const keys = ['point', 'inControl', 'outControl']

      keys.forEach(k => {
        if (point[k]) {
          newPoint[k] = [point[k][0] + diffX, point[k][1] + diffY]
        }
      })

      points[selectedPoint] = newPoint

      this.updateObject({ points }, `dragPoint-${selectedPoint}`)

      this.setState({ lastCursorPosition: null })
    } else {
      this.setState({ lastCursorPosition })
    }
  }

  handleMouseLeave = () => {
    this.setState({
      lastCursorPosition: null,
    })
  }

  handleMouseUp = () => {
    const { object, updateObject, endDrag, setDraggingSelectedPoint } =
      this.props

    endDrag()
    setDraggingSelectedPoint(false)

    if (object && object.points && object.points.length > 1) {
      const newObj = updateShape(object)
      updateObject(object.id, newObj, this._lastUndoKey)
    }
  }

  canCreatePoint = () => {
    const { object, selectedPoint } = this.props

    if (!object) {
      return true
    }

    if (object.isClosed) {
      return false
    }

    return selectedPoint === 0 || selectedPoint === object.points.length - 1
  }

  getEndPoint = () => {
    const { object, selectedPoint } = this.props

    return object && this.canCreatePoint() && object.points[selectedPoint]
  }

  getClosePointIndex = () => {
    const { object, selectedPoint } = this.props

    if (!object || object.points.length === 0 || object.isClosed) {
      return null
    }

    if (selectedPoint === 0) {
      return object.points.length - 1
    }

    if (selectedPoint === object.points.length - 1) {
      return 0
    }

    return null
  }

  scalePoint = point => {
    const { zoom, component, object } = this.props

    if (!point) {
      return point
    }

    let [x, y] = point
    let absolutePoint = point

    if (object) {
      x = x * object.width + object.x
      y = y * object.height + object.y
    }

    if (component) {
      absolutePoint = [x + component.x, y + component.y]
    }

    return scale(absolutePoint, zoom)
  }

  unScalePoint = point => {
    const { object, component, zoom } = this.props

    let [x, y] = unScale(point, zoom)

    if (component) {
      x = x - component.x
      y = y - component.y
    }

    x = Math.round(x)
    y = Math.round(y)

    if (object) {
      x = (x - object.x) / object.width
      y = (y - object.y) / object.height
    }

    return [x, y]
  }

  render() {
    const { lastCursorPosition } = this.state

    const {
      selectedPoint,
      object,
      setDraggingInControl,
      setDraggingOutControl,
    } = this.props

    let cursorClass = null
    const canCreate = this.canCreatePoint()

    let currentPath = null
    const endPoint = this.getEndPoint()

    if (lastCursorPosition && endPoint) {
      const newPoint = { point: lastCursorPosition }
      currentPath = createPath([endPoint, newPoint], false, this.scalePoint)
    }

    let path = ''

    if (object) {
      path = createPath(object.points || [], object.isClosed, this.scalePoint)
    }

    const closePointIndex = this.getClosePointIndex()

    if (canCreate) {
      cursorClass = 'cursor-pen-plus'
    }

    return (
      <g
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
        className={cursorClass}
        pointerEvents="all"
      >
        <DocumentEvents onKeyDown={this.handleKeyDown} />
        <rect
          x={0}
          y={0}
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ fill: 'none' }}
        />
        <path className="shape-editor-line" d={path} />
        {currentPath && (
          <path className="shape-editor-active-line" d={currentPath} />
        )}
        {object && (
          <g className="shape-editor-points">
            {object.points.map((pt, i) => (
              <Point
                point={pt}
                key={i} // eslint-disable-line react/no-array-index-key
                onSelect={this.handleSelectPoint(i)}
                selected={selectedPoint === i}
                isClosePoint={i === closePointIndex}
                scalePoint={this.scalePoint}
                setDraggingInControl={setDraggingInControl}
                setDraggingOutControl={setDraggingOutControl}
              />
            ))}
          </g>
        )}
      </g>
    )
  }
}

const mapStateToProps = state => ({
  object: selectObject(state, getEditingShape(state)),
  component: selectObject(state, getComponentId(state, getEditingShape(state))),
  draggingOutControl: getDraggingOutControl(state),
  draggingInControl: getDraggingInControl(state),
  selectedPoint: getSelectedPoint(state),
  draggingSelectedPoint: getDraggingSelectedPoint(state),
})

export default connect(mapStateToProps, {
  resetTool,
  createObject,
  updateObject,
  setEditingShape,
  setDraggingOutControl,
  setDraggingInControl,
  endDrag,
  setSelectedPoint,
  setDraggingSelectedPoint,
  setCurrentTab,
})(ShapeEditor)
