import React from 'react'
import classNames from 'classnames'
import { borderPosition, borderStyle } from '@adalo/constants'
import BaseShape from '../BaseShape'

export default class Line extends BaseShape {
  getRenderOffset() {
    if (this.props.borderStyle !== borderStyle.NONE) {
      switch (this.props.borderPosition) {
        case borderPosition.INSIDE:
          return 0.5 * this.getBorderWidth()
        case borderPosition.OUTSIDE:
          return -0.5 * this.getBorderWidth()
      }
    }

    return 0
  }

  render() {
    let {
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      selected,
      borderRadius,
      zoom,
      width,
      height,
      hideShadows,
      children,
      editorResizingProps,
      left,
      right,
      minWidth,
      maxWidth,
      ...styles
    } = this.props

    const borderProps = this.getBorderProps()
    const renderOffset = this.getRenderOffset()
    xScaled += renderOffset
    yScaled += renderOffset
    if (!editorResizingProps?.relativeWidth) {
      widthScaled -= 2 * renderOffset
    }
    heightScaled -= 2 * renderOffset

    return (
      <g
        className={classNames('section', { 'section-selected': selected })}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        transform={`translate(${xScaled}, ${yScaled})`}
        style={styles}
      >
        <line {...borderProps} x={0} y={0} x2={widthScaled} y2={0} />
        <line
          {...borderProps}
          strokeDasharray="none"
          stroke="transparent"
          x={0}
          y={0}
          x2={widthScaled}
          y2={0}
        />
        {children}
      </g>
    )
  }
}
