import InstructionState from '../types/InstructionState'
import {
  disableDeviceSpecificLayoutHandler,
  DisableDeviceSpecificLayoutInstruction,
} from './disableDeviceSpecificLayout'
import {
  enableDeviceSpecificLayoutHandler,
  EnableDeviceSpecificLayoutInstruction,
} from './enableDeviceSpecificLayout'
import {
  ResetSharedLayoutInstruction,
  resetSharedLayoutHandler,
} from './resetSharedLayout'
import { moveElementHandler, MoveElementInstruction } from './moveElement'
import { moveScreenHandler, MoveScreenInstruction } from './moveScreen'
import { resizeElementHandler, ResizeElementInstruction } from './resizeElement'
import { resizeScreenHandler, ResizeScreenInstruction } from './resizeScreen'
import { groupObjectsHandler, GroupObjectsInstruction } from './groupObjects'
import {
  groupObjectsToListHandler,
  GroupObjectsToListInstruction,
} from './groupObjectsToList'
import {
  ungroupObjectsHandler,
  UngroupObjectsInstruction,
} from './ungroupObjects'
import {
  toggleDeviceVisibilityHandler,
  ToggleDeviceVisibilityInstruction,
} from './toggleDeviceVisibility'
import {
  toggleFixedPositionHandler,
  ToggleFixedPositionInstruction,
} from './toggleFixedPosition'
import {
  updateCustomListDeviceColumnsHandler,
  UpdateCustomListDeviceColumnsInstruction,
} from './updateCustomListDeviceColumns'
import {
  enableDeviceSpecificCustomListColumnsHandler,
  EnableDeviceSpecificCustomListColumnsInstruction,
} from './enableDeviceSpecificCustomListColumns'
import {
  disableDeviceSpecificCustomListColumnsHandler,
  DisableDeviceSpecificCustomListColumnsInstruction,
} from './disableDeviceSpecificCustomListColumns'
import {
  UpdateElementWidthConstraintsInstruction,
  updateElementWidthConstraintsHandler,
} from './updateElementWidthConstraints'
import { updateElementHandler, UpdateElementInstruction } from './updateElement'
import {
  updateElementMarginsHandler,
  UpdateElementMarginsInstruction,
} from './updateElementMargins'
import {
  reorderSectionHandler,
  ReorderSectionInstruction,
} from './reorderSection'
import { snapToBottomHandler, SnapToBottomInstruction } from './snapToBottom'
import {
  fitScreenHeightToComponentsHandler,
  FitScreenHeightToComponentsInstruction,
} from './fitScreenHeightToComponents'

export type Instruction =
  | MoveElementInstruction
  | MoveScreenInstruction
  | ResizeScreenInstruction
  | ResizeElementInstruction
  | EnableDeviceSpecificLayoutInstruction
  | DisableDeviceSpecificLayoutInstruction
  | ResetSharedLayoutInstruction
  | ToggleDeviceVisibilityInstruction
  | GroupObjectsInstruction
  | GroupObjectsToListInstruction
  | UngroupObjectsInstruction
  | ToggleFixedPositionInstruction
  | UpdateCustomListDeviceColumnsInstruction
  | EnableDeviceSpecificCustomListColumnsInstruction
  | DisableDeviceSpecificCustomListColumnsInstruction
  | UpdateElementWidthConstraintsInstruction
  | UpdateElementInstruction
  | UpdateElementMarginsInstruction
  | ReorderSectionInstruction
  | SnapToBottomInstruction
  | FitScreenHeightToComponentsInstruction

export const getTouchedFromInstruction = (
  instructions: Instruction[]
): string[] => {
  return [
    ...new Set(
      instructions.flatMap(instruction => {
        const { operation, options } = instruction

        switch (operation) {
          case 'moveElement':
          case 'resizeElement':
          case 'enableDeviceSpecificLayout':
          case 'disableDeviceSpecificLayout':
          case 'resetSharedLayout':
          case 'toggleDeviceVisibility':
          case 'toggleFixedPosition':
          case 'updateElementWidthConstraints':
          case 'updateElement':
          case 'updateElementMargins':
          case 'reorderSection':
            return [options.objectId]
          case 'groupObjects':
            return options.objectIds
          default:
            return []
        }
      })
    ),
  ]
}

const applyInstruction = (
  instructionState: InstructionState,
  instruction: Instruction
): InstructionState => {
  const { operation, options } = instruction

  switch (operation) {
    case 'moveElement': {
      return moveElementHandler(instructionState, options)
    }
    case 'moveScreen': {
      return moveScreenHandler(instructionState, options)
    }
    case 'resizeScreen': {
      return resizeScreenHandler(instructionState, options)
    }
    case 'resizeElement': {
      return resizeElementHandler(instructionState, options)
    }
    case 'enableDeviceSpecificLayout': {
      return enableDeviceSpecificLayoutHandler(instructionState, options)
    }
    case 'disableDeviceSpecificLayout': {
      return disableDeviceSpecificLayoutHandler(instructionState, options)
    }
    case 'resetSharedLayout': {
      return resetSharedLayoutHandler(instructionState, options)
    }
    case 'toggleDeviceVisibility': {
      return toggleDeviceVisibilityHandler(instructionState, options)
    }
    case 'groupObjects': {
      return groupObjectsHandler(instructionState, options)
    }
    case 'groupObjectsToList': {
      return groupObjectsToListHandler(instructionState, options)
    }
    case 'ungroupObjects': {
      return ungroupObjectsHandler(instructionState, options)
    }
    case 'toggleFixedPosition': {
      return toggleFixedPositionHandler(instructionState, options)
    }
    case 'updateCustomListDeviceColumns': {
      return updateCustomListDeviceColumnsHandler(instructionState, options)
    }
    case 'enableDeviceSpecificCustomListColumns': {
      return enableDeviceSpecificCustomListColumnsHandler(
        instructionState,
        options
      )
    }
    case 'disableDeviceSpecificCustomListColumns': {
      return disableDeviceSpecificCustomListColumnsHandler(
        instructionState,
        options
      )
    }
    case 'updateElementWidthConstraints': {
      return updateElementWidthConstraintsHandler(instructionState, options)
    }
    case 'updateElement': {
      return updateElementHandler(instructionState, options)
    }
    case 'updateElementMargins': {
      return updateElementMarginsHandler(instructionState, options)
    }
    case 'reorderSection': {
      return reorderSectionHandler(instructionState, options)
    }
    case 'snapToBottom': {
      return snapToBottomHandler(instructionState, options)
    }
    case 'fitScreenHeightToComponents': {
      return fitScreenHeightToComponentsHandler(instructionState, options)
    }
    default:
      throw new Error(`Unknown operation: ${operation as string}`)
  }
}

const applyInstructions = (
  instructionState: InstructionState,
  instructions: Instruction[]
): InstructionState => {
  let updated = instructionState

  for (const instruction of instructions) {
    updated = applyInstruction(updated, instruction)
  }

  return updated
}

export default applyInstructions
