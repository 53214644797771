import LayoutContext from '../layout/LayoutContext'
import LayoutObject from '../layout/LayoutObject'
import cellId from './cellId'
import SizedGrid from './SizedGrid'

const calculateCellContextIndex = (
  grid: SizedGrid,
  containerContext: LayoutContext
): Record<string, LayoutContext> => {
  const { rows, columns } = grid

  let xOffset = containerContext.containerOffsetX
  let yOffset = containerContext.containerOffsetY

  const result: Record<string, LayoutContext> = {}
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
    const { exactSize: rowSize } = rows[rowIndex] || {}
    if (rowSize === undefined) {
      throw new Error(`Row size is missing for index: ${rowIndex}`)
    }

    // Reset xOffset at the start of each row
    xOffset = containerContext.containerOffsetX

    for (let columnIndex = 0; columnIndex < columns.length; columnIndex += 1) {
      const { exactSize: columnSize } = columns[columnIndex] || {}
      if (columnSize === undefined) {
        throw new Error(`Column size is missing for index: ${columnIndex}`)
      }

      const cellContext: LayoutContext = {
        ...containerContext,
        containerOffsetX: xOffset,
        containerOffsetY: yOffset,
        containerWidth: columnSize,
        containerHeight: rowSize,
      }

      const key = cellId(rowIndex, columnIndex)
      result[key] = cellContext

      xOffset += columnSize
    }

    yOffset += rowSize
  }

  return result
}

const layoutItems = (
  grid: SizedGrid,
  containerContext: LayoutContext
): LayoutObject[] => {
  // Create index of track sizes (rows and columns)
  const contextIndex = calculateCellContextIndex(grid, containerContext)

  const result: LayoutObject[] = []

  for (const { element, row, column } of grid.items) {
    const contextKey = cellId(row, column)
    const context = contextIndex[contextKey]
    if (context === undefined) {
      throw new Error(
        `context missing for cell. (Row: ${row}; Column: ${column})`
      )
    }

    const layout = element.layout(context)

    result.push(layout)
  }

  return result
}

export default layoutItems
