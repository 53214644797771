import classNames from 'classnames'
import BodyClass from 'components/Shared/BodyClass'

type ErrorBannerProps = {
  error?: null | string
}

const ErrorBanner = ({ error }: ErrorBannerProps) => (
  <div className="auth-page-error">{error}</div>
)

type AuthPageProps = {
  className: string
  children: JSX.Element
  error?: null | string
}

// TODO(enzo): replace all usages of the js AuthPage with the ts one

const AuthPage = (props: AuthPageProps): JSX.Element => {
  const { className, children, error } = props

  const pageClassName = classNames('auth-page-v2', className, {
    'auth-page-has-error': error,
  })

  const bodyClassName = 'auth-page-body-v2'

  return (
    <div className={pageClassName}>
      <BodyClass className="auth-screen" />
      {error && <ErrorBanner error={error} />}
      <div className={bodyClassName}>{children}</div>
    </div>
  )
}

export default AuthPage
