import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setCurrentTab, CurrentTab } from 'ducks/magicLayout'
import { scale, unScale } from '../../../utils/zoom'
import { getSnapValue } from '../../../utils/snapping'

import {
  getXGrid,
  getYGrid,
  setXSnap,
  setYSnap,
  resetSnaps,
} from '../../../ducks/editor/snapping'

import { selectObject, createObject } from '../../../ducks/editor/objects'
import { resetTool } from '../../../ducks/editor/tools'
import { ComponentPreviewSub } from './ComponentPreview'
import './AddComponentInstance.css'

class AddComponentInstance extends Component {
  state = {
    mouseOver: false,
    xSnapped: 0,
    ySnapped: 0,
  }

  handleMouseDown = e => {
    const {
      createObject,
      resetTool,
      resetSnaps,
      component,
      options,
      setCurrentTab,
    } = this.props

    const { xSnapped, ySnapped, mouseOver } = this.state

    if (!component || !mouseOver) {
      return
    }

    createObject({
      ...options,
      x: xSnapped,
      y: ySnapped,
      width: component.width,
      height: component.height,
      name: component.name,
    })

    resetTool()
    resetSnaps()
    setCurrentTab(CurrentTab.Setup)
  }

  getSnappedPoints = (x, y) => {
    const { component, xGrid, yGrid, zoom, setXSnap, setYSnap } = this.props
    const { width, height } = component

    if (!xGrid || !yGrid) {
      return [x, y, width, height]
    }

    let xDiff = 0
    let yDiff = 0

    const xCoords = {
      left: x,
      center: x + width / 2,
      right: x + width,
    }

    const yCoords = {
      top: y,
      center: y + height / 2,
      bottom: y + height,
    }

    const xSnap = getSnapValue(xGrid, xCoords, zoom)
    const ySnap = getSnapValue(yGrid, yCoords, zoom)

    if (xSnap) {
      const key = Object.keys(xSnap)[0]
      xDiff = xSnap[key] - xCoords[key]
      setXSnap(xSnap[key])
    } else {
      setXSnap(null)
    }

    if (ySnap) {
      const key = Object.keys(ySnap)[0]
      yDiff = ySnap[key] - yCoords[key]
      setYSnap(ySnap[key])
    } else {
      setYSnap(null)
    }

    return [x + xDiff, y + yDiff]
  }

  handleMouseMove = e => {
    const { zoom, component } = this.props

    let [x, y] = unScale([e.clientX, e.clientY], zoom)
    x = Math.round(x - component.width / 2)
    y = Math.round(y - component.height / 2)
    const [xSnapped, ySnapped] = this.getSnappedPoints(x, y)

    this.setState({
      xSnapped,
      ySnapped,
      mouseOver: true,
    })
  }

  handleMouseLeave = () => {
    this.setState({ mouseOver: false })
  }

  renderComponent = () => {
    const { zoom, component } = this.props
    const { xSnapped, ySnapped } = this.state
    const [x, y] = scale([xSnapped, ySnapped], zoom)

    if (!component) {
      return null
    }

    const newZoom = {
      ...zoom,
      offset: [0, 0],
    }

    return (
      <g transform={`translate(${x}, ${y})`} opacity={0.5}>
        <ComponentPreviewSub objects={component.children} zoom={newZoom} />
      </g>
    )
  }

  render() {
    const { component, resetTool } = this.props
    const { mouseOver } = this.state

    if (!component) {
      resetTool()
    }

    const width = window.innerWidth
    const height = window.innerHeight

    return (
      <g className="add-component-instance">
        <rect
          onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          onMouseLeave={this.handleMouseLeave}
          onMouseEnter={this.handleMouseMove}
          x={0}
          y={0}
          width={width}
          height={height}
          className="add-component-backdrop"
        />
        {mouseOver ? this.renderComponent() : null}
      </g>
    )
  }
}

const mapStateToProps = (state, { options }) => ({
  component: selectObject(state, options && options.componentId),
  xGrid: getXGrid(state),
  yGrid: getYGrid(state),
})

export default connect(mapStateToProps, {
  createObject,
  resetTool,
  setXSnap,
  setYSnap,
  resetSnaps,
  setCurrentTab,
})(AddComponentInstance)
