import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { calcNextVersion } from 'utils/versions'
import NewVersionForm from './NewVersionForm'
import { PublishSettings } from './PublishSettingsModal'

const formId = 'new-version-form'

function NewVersionModal({ onClose, onSubmit, target, lastVersion }) {
  const handleSubmit = useCallback(
    values => {
      onSubmit(values)
      onClose()
    },
    [onSubmit, onClose]
  )

  const nextVersion = useMemo(() => calcNextVersion(lastVersion), [lastVersion])

  return (
    <PublishSettings
      title="Select Version Number"
      onClose={onClose}
      submitText="Start Build"
      formId={formId}
    >
      <NewVersionForm
        target={target}
        formId={formId}
        onClose={onClose}
        initialValues={{
          version: nextVersion,
        }}
        lastVersion={lastVersion}
        nextVersion={nextVersion}
        onSubmit={handleSubmit}
      />
    </PublishSettings>
  )
}

NewVersionModal.propTypes = {
  target: PropTypes.oneOf(['android', 'ios']).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  lastVersion: PropTypes.string,
}

export default NewVersionModal
