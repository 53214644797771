const START_TRIAL_TEXT = 'Start Free Trial'
const OVER_TRIAL_TEXT = 'Your Free Trial is Over'
const DURING_TRIAL_ICON = '⏳'
const UPGRADE_TEXT = 'Upgrade Now'

export const getDaysLeftText = days => {
  const parsedDays = parseInt(days)

  if (Number.isNaN(parsedDays)) {
    return 'No Days Left'
  }

  if (parsedDays === 0) {
    return 'Less Than 1 Day Left'
  }

  return `${parsedDays} ${parsedDays > 1 ? 'Days' : 'Day'} Left`
}

export const generateText = (days, type, trialState) => {
  const DURING_TRIAL_HEADER = `Free Trial | ${getDaysLeftText(days)}`

  const copies = {
    customFonts: {
      before: {
        header: 'Need more cool fonts? We got you!',
        body: 'Try out our custom fonts to see which one will work best for your app',
        button: START_TRIAL_TEXT,
      },
      during: {
        header: DURING_TRIAL_HEADER,
        body: 'Upgrade now to make sure all of your fonts will still be available',
        button: UPGRADE_TEXT,
        icon: DURING_TRIAL_ICON,
      },
      after: {
        header: OVER_TRIAL_TEXT,
        body: 'Access to your Custom Fonts has been paused. Upgrade now to continue using it.',
        button: UPGRADE_TEXT,
        icon: '🖋',
      },
      upgrade: {
        header: 'Need more cool fonts? We got you!',
        body: 'Make your app pop! Upgrade now to access over 1,000 Google Fonts!',
        button: UPGRADE_TEXT,
      },
    },
    externalCollection: {
      before: {
        header: 'Connect to External Data Sources',
        body: `Integrate your app's database with external sources like Airtable, Google, or any custom API.`,
        button: START_TRIAL_TEXT,
      },
      during: {
        header: DURING_TRIAL_HEADER,
        body: 'Upgrade now to make sure all of your external collections stay up and running.',
        button: UPGRADE_TEXT,
        icon: DURING_TRIAL_ICON,
      },
      after: {
        header: OVER_TRIAL_TEXT,
        body: 'Access to your External Collections has been paused. Upgrade now to continue using it.',
        button: UPGRADE_TEXT,
        icon: '🗂',
      },
      upgrade: {
        header: 'Connect to External Data Sources',
        body: `Integrate your app's database with external sources like Airtable, Google, or any custom API.`,
        button: UPGRADE_TEXT,
      },
    },
    api: {
      before: {
        header: 'Get Access To Your App’s API Key',
        body: 'Use your App’s API to send & receive custom API requests.',
        button: START_TRIAL_TEXT,
      },
      during: {
        header: DURING_TRIAL_HEADER,
        body: 'Upgrade now to continue to send & receive custom API requests.',
        button: UPGRADE_TEXT,
        icon: DURING_TRIAL_ICON,
      },
      after: {
        header: OVER_TRIAL_TEXT,
        body: 'Access to your App’s API has been paused. Upgrade now to continue using it.',
        button: UPGRADE_TEXT,
        icon: '🔗',
      },
      upgrade: {
        header: 'Get Access To Your App’s API Key',
        body: 'Use your App’s API to send & receive custom API requests.',
        button: UPGRADE_TEXT,
      },
    },
    designVersions: {
      before: {
        header: 'Save Your Design Versions',
        body: 'Create and restore versions of your app’s design without affecting your database, user data, or existing native builds.',
        button: START_TRIAL_TEXT,
      },
      during: {
        header: DURING_TRIAL_HEADER,
        body: 'Create and restore versions of your app’s design without affecting your database, user data, or existing native builds.',
        button: UPGRADE_TEXT,
        icon: DURING_TRIAL_ICON,
      },
      after: {
        header: OVER_TRIAL_TEXT,
        body: 'Access to your Design Versions has been paused. Upgrade now to continue using it.',
        button: UPGRADE_TEXT,
        icon: '💾',
      },
      upgrade: {
        header: 'Save Your Design Versions',
        body: 'Create and restore versions of your app’s design without affecting your database, user data, or existing native builds.',
        button: UPGRADE_TEXT,
      },
    },
    geolocation: {
      before: {
        header: 'Get Access to the Geolocation Feature',
        body: 'Utilize this popular feature to access your users’ locations to customize their experience.',
        button: START_TRIAL_TEXT,
      },
      during: {
        header: DURING_TRIAL_HEADER,
        body: 'Upgrade now to make sure all of your location properties stay up and running',
        button: UPGRADE_TEXT,
        icon: DURING_TRIAL_ICON,
      },
      after: {
        header: OVER_TRIAL_TEXT,
        body: 'Access to Geolocation Feature has been paused. Upgrade now to continue using it.',
        button: UPGRADE_TEXT,
        icon: '📍',
      },
      upgrade: {
        header: 'Get Access to the Geolocation Feature',
        body: 'Utilize this popular feature to access your users’ locations to customize their experience.',
        button: UPGRADE_TEXT,
      },
    },
    googleApiKey: {
      before: {
        header: 'Get Access to the Geolocation Feature',
        body: 'Utilize this popular feature to access your users’ locations to customize their experience.',
        button: START_TRIAL_TEXT,
      },
      during: {
        header: DURING_TRIAL_HEADER,
        body: 'Upgrade now to make sure all of your geolocation features stay up and running',
        button: UPGRADE_TEXT,
        icon: DURING_TRIAL_ICON,
      },
      after: {
        header: OVER_TRIAL_TEXT,
        body: 'Upgrade now to make sure all of your geolocation features stay up and running',
        button: UPGRADE_TEXT,
        icon: '🔗',
      },
      upgrade: {
        header: 'Get Access to the Geolocation Feature',
        body: 'Utilize this popular feature to access your users’ locations to customize their experience.',
        button: UPGRADE_TEXT,
      },
    },
    customActions: {
      before: {
        header: 'Get Access to Custom Actions',
        body: 'Integrate your app’s database with external sources like Airtable, Google, or any custom API.',
        button: START_TRIAL_TEXT,
      },
      during: {
        header: DURING_TRIAL_HEADER,
        body: 'Upgrade now to make sure all of your custom actions stay up and running',
        button: UPGRADE_TEXT,
        icon: DURING_TRIAL_ICON,
      },
      after: {
        header: OVER_TRIAL_TEXT,
        body: 'Access to your Custom Actions has been paused. Upgrade now to continue using it.',
        button: UPGRADE_TEXT,
        icon: '⚡️',
      },
      upgrade: {
        header: 'Get Access to Custom Actions',
        body: 'Trigger unique actions to extend the functionality available in your app.',
        button: UPGRADE_TEXT,
      },
    },
    externalDatabase: {
      upgrade: {
        header: 'Get Access to External Databases',
        body: 'Add even more power to your app by exploring our new External Users with Xano.',
        button: UPGRADE_TEXT,
      },
    },
  }

  return copies[type][trialState]
}
