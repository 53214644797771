type Props = {
  errorMessage?: string
}

const ErrorMessage = ({ errorMessage }: Props): JSX.Element | null => {
  if (!errorMessage) {
    return null
  }

  return (
    <div className="db-assistant-error">
      <span>{errorMessage}</span>
    </div>
  )
}

export default ErrorMessage
