import { useHistory, withRouter } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, connect, useSelector } from 'react-redux'
import { InjectedFormProps, reduxForm, reset } from 'redux-form'
import { v4 as uuid } from 'uuid'
import Lottie from 'react-lottie'

import { getDefaultDatasource } from 'ducks/apps/datasources'
import {
  DBAssistantState,
  DBAssistantStatus,
  MagicAddFeatureType,
  SuggestedSchemaTable,
  getAppMagicAdd,
  resetAppMagicAdd,
} from 'ducks/dbAssistant'
import { requestApp } from 'ducks/apps'

import {
  getFeatureList,
  updateDatabaseSchema,
  acceptDatabaseSchema,
  getLimits,
  initializeDbAssistant,
} from 'utils/io/dbAssistant'

import Header from '../components/Header'
import FeatureList from '../components/Steps/FeatureList'
import FeaturePrompting from '../components/Steps/FeaturePrompting'
import SchemaPreview from '../components/Steps/SchemaPreview'
import { DBAssistant } from '../Panel'

import loadingStars from '../assets/loadingstars.json'
import happyBuilding from '../assets/happybuildingstars.json'

import './styles.scss'

interface MagicAddProps {
  appId: string
  datasource: Record<string, unknown>
}

enum Step {
  StepFeatureList = 1,
  StepFeaturePrompting = 2,
  StepSchemaPreview = 3,
}

type FormValues = {
  prompt: string
}

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet',
    filterSize: {
      width: '200%',
      height: '200%',
      x: '-50%',
      y: '-50%',
    },
  },
}

export const PROMPT_MAX_LENGTH = 500

const REDUX_FORM_NAME = 'magicAdd'

const MagicAdd: React.FC<
  MagicAddProps & InjectedFormProps<FormValues, MagicAddProps>
> = ({ appId, datasource }): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const appMagicAdd = useSelector(state =>
    getAppMagicAdd(state as DBAssistantState, appId)
  )

  const [loading, setLoading] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [step, setStep] = useState<Step>(Step.StepFeatureList)
  const [feature, setFeature] = useState<MagicAddFeatureType | null>(null)
  const [schemaAccepted, setSchemaAccepted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleStartOver = () => {
    setLoading(false)
    setErrorMessage('')
    setStep(Step.StepFeatureList)
    initializeDbAssistant(appId).catch(console.error)
    dispatch(reset(REDUX_FORM_NAME))
  }

  const handleClickBack = () => {
    if (appMagicAdd?.status !== DBAssistantStatus.in_progress) {
      handleStartOver()
    }

    return history.push(`/apps/${appId}/data`)
  }

  useEffect(() => {
    handleStartOver()
  }, [appId])

  const handleAllChangesRemoved = () => {
    setErrorMessage(
      'There are no remaining changes to apply. Start over to try again'
    )
  }

  useEffect(() => {
    if (!appMagicAdd?.status) return

    if (appMagicAdd.status === DBAssistantStatus.in_progress) {
      setLoading(true)
      setErrorMessage('')
    }

    if (appMagicAdd.status === DBAssistantStatus.done) {
      setLoading(false)
      setErrorMessage('')
      setStep(Step.StepSchemaPreview)
    }

    if (appMagicAdd.status === DBAssistantStatus.failed) {
      setLoading(false)

      if (appMagicAdd?.errorMessage) {
        setErrorMessage(appMagicAdd.errorMessage)
      }
    }
  }, [appMagicAdd])

  useEffect(() => {
    getLimits(appId).catch(console.error)
    getFeatureList(appId).catch(console.error)
  }, [])

  const handleSelectFeature = (feat: MagicAddFeatureType) => {
    setFeature(feat)
    setStep(Step.StepFeaturePrompting)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value)
    const maxLengthReached = e.target.value.length >= PROMPT_MAX_LENGTH

    if (maxLengthReached) {
      setErrorMessage(`You've reached the maximum number of characters`)
    } else {
      setErrorMessage('')
    }
  }

  const handleGenerateDatabase = async () => {
    await updateDatabaseSchema({
      appId,
      userPrompt: prompt,
      conversationId: appMagicAdd?.conversationId || uuid(),
      feature: feature?.description || '',
    })
  }

  const handleClickGenerateAgain = () => {
    setLoading(false)
    setErrorMessage('')
    setStep(Step.StepFeaturePrompting)
  }

  const handleAcceptDatabase = async (tables: SuggestedSchemaTable[]) => {
    setSchemaAccepted(true)

    if (!datasource) {
      throw new Error('No default datasource found')
    }

    if (!appMagicAdd || !appMagicAdd.conversationId) {
      throw new Error('No conversationId found')
    }

    const result = await acceptDatabaseSchema({
      appId,
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
      datasourceId: datasource?.['id'] as string,
      conversationId: appMagicAdd.conversationId,
      action: 'updateSchema',
      tables,
    })

    if (result === 'dbAssistantAcceptSchema_success') {
      dispatch(requestApp(appId))
    }

    setTimeout(() => {
      dispatch(resetAppMagicAdd(appId))
      handleClickBack()
    }, 3000)
  }

  const renderContent = () => {
    switch (step) {
      case Step.StepFeatureList:
        return (
          <FeatureList
            features={appMagicAdd?.features}
            onSelect={handleSelectFeature}
          />
        )
      case Step.StepFeaturePrompting:
        return (
          <>
            <FeaturePrompting
              prompt={prompt}
              errorMessage={errorMessage}
              feature={feature}
              handleGoBack={handleStartOver}
              handleClick={handleGenerateDatabase}
              handleChange={handleInputChange}
              submitted={loading}
              disableInput={loading}
              disableButton={
                prompt.length === 0 || prompt.length >= PROMPT_MAX_LENGTH
              }
            />
            {loading ? (
              <Lottie
                height={42}
                width={42}
                options={{
                  ...lottieOptions,
                  animationData: loadingStars,
                }}
              />
            ) : null}
          </>
        )
      case Step.StepSchemaPreview: {
        if (schemaAccepted && !errorMessage) {
          return (
            <div className="magic-start-setup-complete">
              <Lottie
                height={72}
                width={72}
                options={{
                  ...lottieOptions,
                  animationData: happyBuilding,
                }}
              />
              <h2>Happy Building!</h2>
            </div>
          )
        }

        // This shouldn't happen, but it's set to satisfy typescript's type-checking
        if (!appMagicAdd?.limits || !appMagicAdd?.schema) {
          return null
        }

        return (
          <SchemaPreview
            limits={appMagicAdd.limits}
            schema={appMagicAdd.schema}
            onAccept={tables => handleAcceptDatabase(tables)}
            errorMessage={errorMessage}
            feature="magicAdd"
            handleGoBack={handleClickGenerateAgain}
            handleAllChangesRemoved={handleAllChangesRemoved}
          />
        )
      }
      default:
        return null
    }
  }

  return (
    <DBAssistant className="magic-add">
      <Header title="Magic Add" onClickBack={handleClickBack} />
      <div className="magic-add-setup__content">{renderContent()}</div>
    </DBAssistant>
  )
}

const WrappedMagicAdd = reduxForm<FormValues, MagicAddProps>({
  form: REDUX_FORM_NAME,
  destroyOnUnmount: false,
})(MagicAdd)

const mapStateToProps = (
  state: unknown,
  { match }: { match: { params: { appId: string } } }
) => {
  const { appId } = match.params

  return {
    appId: match.params.appId,
    datasource: getDefaultDatasource(state, appId) as Record<string, unknown>,
  }
}

export default withRouter(connect(mapStateToProps)(WrappedMagicAdd))
