import AdaloLogotype from './AdaloLogotype'
import './Onboarding.scss'

const FormLayout: React.FC = ({ children }) => {
  return (
    <div className="onboarding-form-container">
      <AdaloLogotype />
      {children}
    </div>
  )
}

export default FormLayout
