import React, { Component } from 'react'
import { Field } from 'redux-form'

import { formatterOptions, dataTypes } from '@adalo/constants'

import WrappedSelect from '../../../Shared/Forms/WrappedSelect'
import FilteringExtension from '../../DataBinding/FilteringFormExtension'

export default class DateFormExtension extends Component {
  render() {
    const { binding } = this.props
    const parentSource = binding.source.source

    const showFilter = parentSource && parentSource.dataType === dataTypes.LIST

    return (
      <div>
        <div className="action-item-split-row">
          <div>
            <label>Number Format</label>
            <Field
              autoSelect
              className="small white"
              name="format.type"
              component={WrappedSelect}
              options={formatterOptions[dataTypes.NUMBER]}
              placeholder="Auto"
            />
          </div>
        </div>
        {showFilter ? <FilteringExtension {...this.props} /> : null}
      </div>
    )
  }
}
