import { getId } from '@adalo/utils'

import {
  restEndpointTypes,
  endpointTypes,
  endpointTypeNames,
  defaultMethods,
  httpMethods,
} from '@adalo/constants'

const DETAIL_METHODS = [
  endpointTypes.DETAIL,
  endpointTypes.UPDATE,
  endpointTypes.DELETE,
]

const LIST_METHODS = [endpointTypes.LIST]

export const getDefaultEndpoints = () => {
  return restEndpointTypes.map(type => ({
    id: getId(),
    type,
    enabled: true,
  }))
}

export const sluggify = str => {
  return str.toLowerCase().replace(/[^a-z\d]+/g, '-')
}

export const isListEndpoint = endpoint => {
  return endpoint.isList || LIST_METHODS.includes(endpoint.type)
}

export const getCollection = (datasource, collectionId) => {
  return datasource.collections[collectionId]
}

export const getEndpoint = (collection, endpointId) => {
  return collection.endpoints.filter(e => e.id === endpointId)[0]
}

export const getCollectionField = (collection, fieldId) => {
  return collection.fields.filter(f => f.id === fieldId)[0]
}

export const getEndpointLabel = endpoint => {
  if (!endpoint) {
    return 'Untitled'
  }

  return endpoint.name || endpointTypeNames[endpoint.type]
}

export const getEndpointMethod = (endpoint = {}) => {
  let method = httpMethods.GET
  method = endpoint.method || defaultMethods[endpoint.type] || method

  return method
}

export const getEndpointURL = (collection, endpoint) => {
  let url = `/${collection.baseURL}/`

  if (DETAIL_METHODS.includes(endpoint.type)) {
    url = `${url}:id`
  }

  if (endpoint.url) {
    url = endpoint.url
  }

  return url
}

export const getEndpointSubtitle = (collection, endpoint) => {
  const url = getEndpointURL(collection, endpoint)

  return `${url}`
}

export const getDataEndpoints = (collection, endabledOnly = true) => {
  return collection.endpoints.filter(endpoint => {
    if (!endpoint.enabled) {
      return false
    }

    return getEndpointMethod(endpoint) === httpMethods.GET
  })
}

export const getEndpointOptions = datasource => {
  const options = []

  for (const collectionId of Object.keys(datasource.collections)) {
    const collection = datasource.collections[collectionId]

    options.push({
      label: collection.name || 'Untitled',
      children: getCollectionEndpoints(collectionId, collection, {
        skipGets: true,
      }),
    })
  }

  return options
}

export const getCollectionEndpoints = (collectionId, collection, opts = {}) => {
  const { includeDisabled, skipGets } = opts

  let endpoints = collection.endpoints

  if (!includeDisabled) {
    endpoints = endpoints.filter(endpoint => endpoint.enabled)
  }

  if (skipGets) {
    endpoints = endpoints.filter(e => getEndpointMethod(e) !== httpMethods.GET)
  }

  return endpoints
    .filter(endpoint => endpoint.enabled)
    .map(endpoint => ({
      label: getEndpointLabel(endpoint),
      value: { collectionId, endpointId: endpoint.id },
    }))
}

export const getCollectionEndpointLabel = (
  datasource,
  collectionId,
  endpointId
) => {
  const collection = datasource.collections[collectionId] || { endpoints: [] }

  const collectionLabel = collection.name || 'Untitled'
  const endpoint = collection.endpoints.filter(e => e.id === endpointId)[0]
  const endpointLabel = getEndpointLabel(endpoint)

  return `${collectionLabel} > ${endpointLabel}`
}

export const getEndpointParams = (collection, endpoint) => {
  if (endpoint.type === endpointTypes.CUSTOM) {
    return endpoint.params || []
  }

  return collection.fields.filter(field => field.id !== 'id')
}
