import { DeviceWidth, LAYOUT_SECTION, SECTION } from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import getDeviceObject, { getDeviceObjectRecursive } from '../getDeviceObject'
import { EditorObject } from '../responsiveTypes'
import { RecursivePartial } from '../utilityTypes'
import getLayoutSectionFitForScreen from '../layoutSections/modifiers/getLayoutSectionFitForScreen'
import { isPrebuiltLayoutSection } from '../layoutSections/prebuilts'
import {
  LAYOUT_SECTION_LEFT_RIGHT_PADDING,
  LAYOUT_SECTION_TOP_BOTTOM_PADDING,
} from '../layoutSections/modifiers/buildDefaultLayoutSection'
import {
  LayoutSectionPurpose,
  getContainerFromSection,
} from '../layoutSections'
import getObjectResizedInScreen from '../objects/getObjectResizedInScreen'
import getGhostCache from './cache'
import {
  LAYOUT_HELPER_GHOST_STYLE,
  LAYOUT_SECTION_GHOST_STYLE,
  LAYOUT_SECTION_CONTAINER_GHOST_STYLE,
} from './styles'

type LayoutSectionFit = Partial<ReturnType<typeof getLayoutSectionFitForScreen>>
type GhostObject = RecursivePartial<EditorObject>

type BaseLayout = { x: number; y: number; width: number; height: number }

const ghostCache = getGhostCache<GhostObject>()

const getDefaultLayoutSectionGhost = (
  object: EditorObject,
  layout: BaseLayout
): GhostObject => {
  const { x, y, width, height } = layout

  const innerX = x + LAYOUT_SECTION_LEFT_RIGHT_PADDING
  const innerY = LAYOUT_SECTION_TOP_BOTTOM_PADDING
  const innerWidth = width - LAYOUT_SECTION_LEFT_RIGHT_PADDING * 2
  const innerHeight = height - LAYOUT_SECTION_TOP_BOTTOM_PADDING * 2

  const ghost: GhostObject = {
    ...object,
    y,
    x,
    width,
    height,
    ...LAYOUT_SECTION_GHOST_STYLE,
    children: [
      {
        ...object,
        type: SECTION,
        purpose: LayoutSectionPurpose.LAYOUT_HELPER,
        x: innerX,
        y: innerY,
        width: innerWidth,
        height: innerHeight,
        ...LAYOUT_HELPER_GHOST_STYLE,
        children: [
          {
            ...object,
            type: SECTION,
            purpose: LayoutSectionPurpose.CONTAINER,
            x: innerX,
            y: innerY,
            width: innerWidth,
            height: innerHeight,
            ...LAYOUT_SECTION_CONTAINER_GHOST_STYLE,
            children: [],
          },
        ],
      },
    ],
  }

  return ghost
}

const REPLACEABLE_BACKGROUNDS = ['#f5f5f5', 'transparent', '@background']
const shouldReplaceBackgroundColor = (object: GhostObject) => {
  const color = object.backgroundColor as string | undefined

  return !color || REPLACEABLE_BACKGROUNDS.includes(color.toLowerCase())
}

const DEFAULT_SCREEN_HEIGHT = 1856

const getPrebuiltLayoutSectionGhost = (
  object: EditorObject,
  layout: BaseLayout,
  parent?: EditorObject
): GhostObject => {
  const parentWidth = parent?.width ?? DeviceWidth.TABLET_DEFAULT_WIDTH
  const parentHeight = parent?.height ?? DEFAULT_SCREEN_HEIGHT
  const parentDimensions = { width: parentWidth, height: parentHeight }

  // Runs instructions to get a new object, resized into a screen of the target dimensions
  const objectInParent = getObjectResizedInScreen(
    object,
    parentDimensions,
    layout
  )

  const device = getDeviceType(parentWidth)
  const deviceObject = getDeviceObjectRecursive(objectInParent, device)

  // Apply border & background styles to the ghost
  Object.assign(deviceObject, LAYOUT_SECTION_GHOST_STYLE)

  const container = getContainerFromSection(deviceObject)
  if (container) {
    if (shouldReplaceBackgroundColor(container)) {
      container.backgroundStyle = LAYOUT_SECTION_CONTAINER_GHOST_STYLE.backgroundStyle // prettier-ignore
      container.backgroundColor = LAYOUT_SECTION_CONTAINER_GHOST_STYLE.backgroundColor // prettier-ignore
    }
    container.borderColor = LAYOUT_SECTION_CONTAINER_GHOST_STYLE.borderColor
    container.borderStyle = LAYOUT_SECTION_CONTAINER_GHOST_STYLE.borderStyle
  }

  return deviceObject
}

type GetObjectGhost = (
  obj: GhostObject,
  opt: EditorObject,
  parent?: EditorObject
) => GhostObject

export const getObjectGhost: GetObjectGhost = (object, options, parent) => {
  if (object.type !== LAYOUT_SECTION) {
    return object
  }

  let fitForScreen: LayoutSectionFit = {}
  if (parent) {
    fitForScreen = getLayoutSectionFitForScreen(parent)
  }

  const isPrebuilt = isPrebuiltLayoutSection(options)
  const width = fitForScreen.width ?? DeviceWidth.TABLET_DEFAULT_WIDTH
  // object width will be 100% of screen, so we can use it to get the device type
  const device = getDeviceType(width)
  const deviceObject = getDeviceObject(object as EditorObject, device)

  const height = deviceObject?.height

  // use shared x & y since they're set by the dragging component
  const x = (object.x ?? 0) + (fitForScreen?.x ?? 0)
  const y = object.y ?? 0

  const baseLayout = { x, y, width, height }

  if (!isPrebuilt) {
    const ghost = getDefaultLayoutSectionGhost(options, baseLayout)

    return ghost
  }

  let ghost = ghostCache.get(options.id, width)

  if (!ghost) {
    // creating ghosts for prebuilt objects is expensive, thus we cache them
    ghost = getPrebuiltLayoutSectionGhost(options, baseLayout, parent)

    ghostCache.set(options.id, width, ghost)
  }

  return ghost
}

export default getDeviceObject
