import { capitalizeWords } from 'utils/layoutSections'
import { MOST_USED_SECTIONS_CATEGORY_LABEL } from 'components/Editor/AddObject/ResponsiveAddMenu/sectionHelpers'
import { PrebuiltBuilderContentData } from './types'

type GetCategoriesAndTags = (data: PrebuiltBuilderContentData[]) => {
  categories: string[]
  primaryTags: string[]
  secondaryTags: string[]
}

export const getCategoriesAndTags: GetCategoriesAndTags = data => {
  const categories = new Set<string>()
  const primaryTags = new Set<string>()
  const secondaryTags = new Set<string>()

  data.forEach(section => {
    if (section.categories) {
      section.categories.split(',').forEach(category => {
        let title = capitalizeWords(category)

        if (title === 'Most Used') {
          title = MOST_USED_SECTIONS_CATEGORY_LABEL
        }

        categories.add(title)
      })
    }

    if (section.primaryTags) {
      section.primaryTags.split(',').forEach(tag => primaryTags.add(tag))
    }

    if (section.secondaryTags) {
      section.secondaryTags.split(',').forEach(tag => secondaryTags.add(tag))
    }
  })

  if (!categories.has(MOST_USED_SECTIONS_CATEGORY_LABEL)) {
    categories.add(MOST_USED_SECTIONS_CATEGORY_LABEL)
  }

  return {
    categories: Array.from(categories),
    primaryTags: Array.from(primaryTags),
    secondaryTags: Array.from(secondaryTags),
  }
}
