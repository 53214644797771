import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { reducer as formReducer } from 'redux-form'

import * as reducers from './ducks'

// devtools
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true,
      })
    : compose

const axiosErrorMiddleware = () => next => action => {
  if (!(action.payload instanceof Promise)) {
    return next(action)
  }

  return next(action).catch(error => {
    if (error?.isAxiosError || error?.isNetworkError) {
      console.warn('Network call:', error.message, error.config?.url)

      return error
    }
    throw error
  })
}
const middleware = applyMiddleware(
  thunkMiddleware,
  axiosErrorMiddleware,
  promiseMiddleware()
)

export default createStore(
  combineReducers({ ...reducers, form: formReducer }),
  composeEnhancers(middleware)
)

export const createStoreForTesting = (initialState = {}) => {
  return createStore(
    combineReducers({ ...reducers, form: formReducer }),
    initialState,
    composeEnhancers(middleware)
  )
}
