import React, { createContext, useContext, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

// utils
import { abbreviatedFormat } from 'utils/numbers'
import { formatToCurrency } from 'utils/currency'

// ducks
import {
  fetchOrgsLicenses,
  fetchAppLicenses,
  fetchOrgLicenses,
} from 'ducks/marketplace/licenses'
import { removeLibrary, requestLibrary } from 'ducks/editor/libraries'

import Icon, { IconButton } from 'components/Shared/Icon'
import Chip from 'components/Shared/Chip'
import Loading from 'components/Shared/Loading'

import './developers.scss'
import { adaloBackendAxios, marketplaceAxios } from 'utils/io/http/axios'

export const LibraryContext = createContext({})

const Library = ({ library, orgLicenses, organizations, app }) => {
  const { id, displayName } = library

  return (
    <LibraryContext.Provider
      key={id}
      value={{ library, organizations, orgLicenses, app }}
    >
      <div className="developer-libraries-item">
        <LibraryLogo />
        <div className="developer-libraries-item-content">
          <LibraryName>
            {displayName}

            {library.target ? <Chip>{library.target}</Chip> : null}
          </LibraryName>
          <LibraryInstalls />
        </div>
        <span className="developer-libraries-item-status">
          {library.public ? <LibraryStatus /> : <LibraryActions />}
        </span>
      </div>
    </LibraryContext.Provider>
  )
}

export default Library

const LibraryName = ({ children, name }) => {
  const { library } = useContext(LibraryContext)
  const { appId } = useParams()

  const value = children || name

  if (library.public && library.listed) {
    return (
      <Link to={`/apps/${appId}/marketplace/${library.id}`}>
        <h3>{value}</h3>
      </Link>
    )
  }

  return <h3>{value}</h3>
}

const LibraryInstalls = () => {
  const { library } = useContext(LibraryContext)

  const installs = useMemo(
    () => abbreviatedFormat(library.installs),
    [library.installs]
  )

  if (!library.public) return null

  const generateText = () => {
    if (installs === '0') {
      if (library.price) {
        return 'No Purchases Yet...'
      }

      return 'No Installs Yet...'
    }

    const prefix = library.price ? 'Purchased' : 'Installed'

    const suffix = installs === '1' ? 'time' : 'times'

    return `${prefix} ${installs} ${suffix}!`
  }

  return (
    <div className="developer-libraries-item-content-status">
      {library.price ? (
        <Icon color="teal" type="dollar" iconStyle="circle" small />
      ) : null}
      {generateText()}
    </div>
  )
}

const LibraryLogo = props => {
  const { library } = useContext(LibraryContext)

  if (!library.logo) {
    return <span {...props} style={{ height: 48, width: 48 }} />
  }

  return (
    <img {...props} src={library.logo} alt={`${library.displayName} logo`} />
  )
}

const LibraryStatus = () => {
  const { library } = useContext(LibraryContext)

  const isListed = library.listed

  if (library.price) {
    return (
      <Chip color="teal" disabled={!isListed} hideBackground={!isListed}>
        {formatToCurrency(library.price)}
      </Chip>
    )
  }

  return null
}

const LibraryActions = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const { library, organizations, orgLicenses, app } =
    useContext(LibraryContext)

  const orgIds = useMemo(
    () => organizations.map(org => org.id),
    [organizations.length]
  )

  const getOptions = () => {
    const install = []
    const uninstall = []

    for (const org of organizations) {
      const licenses = orgLicenses[org.id]

      if (!licenses?.length) {
        install.push({
          label: org.name,
          value: { type: 'install', orgId: org.id },
        })

        continue
      }

      const isInstalled = licenses.some(license => license.id === library.id)

      if (isInstalled) {
        uninstall.push({
          label: org.name,
          value: { type: 'uninstall', orgId: org.id },
        })
      } else {
        install.push({
          label: org.name,
          value: { type: 'install', orgId: org.id },
        })
      }
    }

    return [install, uninstall]
  }

  const [installed, notInstalled] = getOptions()
  const install = { label: 'Add To', children: installed }
  const uninstall = { label: 'Remove From', children: notInstalled }
  const options = [install, uninstall]

  const handleSelect = async value => {
    setIsLoading(true)
    const { orgId, type } = value
    const marketplaceInstallURL = `/library/${library.id}/install`

    try {
      if (type === 'install') {
        // Add license to the registry
        await adaloBackendAxios.post(marketplaceInstallURL, {
          orgId,
        })

        if (app) {
          // get the latest version of the requested library
          const versionURL = `/api/libraries/${library.id}/versions`
          const { data } = await marketplaceAxios.get(versionURL)
          const { latest } = data

          // request library at latest version
          await dispatch(requestLibrary(library.name, latest))
        }
      } else {
        // decrement install count on marketplace
        await adaloBackendAxios.delete(marketplaceInstallURL, {
          headers: {
            orgId,
          },
        })

        if (app) {
          // remove library from redux
          dispatch(removeLibrary(library, app?.librariesUsed))
        }
      }

      if (app) {
        await dispatch(fetchAppLicenses(app?.id))
        await dispatch(fetchOrgLicenses(app?.OrganizationId))
      }

      await dispatch(fetchOrgsLicenses(orgIds))
    } catch (error) {
      // do nothing
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Loading small />
  }

  return (
    <MultiMenuTrigger menu={options} onSelect={handleSelect} rowHeight={32}>
      <IconButton type="more-vert" />
    </MultiMenuTrigger>
  )
}
