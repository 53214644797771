import React, { Component } from 'react'
import classNames from 'classnames'

import './FormattedText.css'

export default class FormattedText extends Component {
  render() {
    const { children, className } = this.props

    return (
      <div className={classNames('formatted-text', className)}>{children}</div>
    )
  }
}
