import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getOrganizations,
  updateOrganizationName,
  fetchOrganizations,
} from '../../ducks/organizations'

import WrappedSelect from '../Shared/Forms/WrappedSelect'
import EmptyState from '../Shared/EmptyState'
import Loading from '../Shared/Loading'

import TeamSetup from './TeamSetup'
import './Auth.scss'

class InitialTeamSub extends Component {
  render() {
    const { organization, onReload, updateOrganizationName, history } =
      this.props

    return (
      <TeamSetup
        updateOrganizationName={updateOrganizationName}
        organization={organization}
        onReload={onReload}
        history={history}
      />
    )
  }
}

class InitialTeam extends Component {
  state = {
    selectedOrganization: null,
  }

  getOrganization = () => {
    let { organizations } = this.props
    const { selectedOrganization } = this.state

    if (organizations.length > 1) {
      organizations = organizations.filter(org => {
        return org.id === selectedOrganization
      })
    }

    return organizations[0]
  }

  handleChangeOrg = selectedOrganization => {
    this.setState({ selectedOrganization })
  }

  getOrganizationOptions = () => {
    const { organizations } = this.props

    return organizations.map(org => ({
      label: org.name,
      value: org.id,
    }))
  }

  fetchData = () => {
    const { fetchOrganizations } = this.props

    fetchOrganizations()
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const { selectedOrganization } = this.state
    const { organizations, updateOrganizationName, history } = this.props
    const org = this.getOrganization()

    return (
      <div className="team-settings">
        {organizations.length > 1 ? (
          <div className="team-select-menu">
            <WrappedSelect
              autoSelect
              options={this.getOrganizationOptions()}
              value={selectedOrganization}
              onChange={this.handleChangeOrg}
            />
          </div>
        ) : null}
        {org ? (
          [
            <InitialTeamSub
              history={history}
              key={org.id}
              organization={org}
              updateOrganizationName={updateOrganizationName}
              onReload={this.fetchData}
            />,
          ]
        ) : (
          <EmptyState>
            <Loading />
          </EmptyState>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  organizations: getOrganizations(state),
})

export default connect(mapStateToProps, {
  updateOrganizationName,
  fetchOrganizations,
})(InitialTeam)
