import {
  LABEL,
  SECTION,
  ELLIPSE,
  SHAPE,
  LAYOUT_SECTION,
  IMAGE,
} from '@adalo/constants'

export const hotkeyMapping = {
  [SECTION]: 'r',
  [ELLIPSE]: 'o',
  [LABEL]: 't',
  [SHAPE]: 'v',
  [LAYOUT_SECTION]: 's',
  [IMAGE]: 'i',
  screen: 'n',
  Button: 'b',
  SimpleList: 'l',
}
