import { DeviceType } from '@adalo/constants'

import { EditorObject } from 'utils/responsiveTypes'
import usesSharedLayout from './usesSharedLayout'

export const hasSomeDeviceSpecificLayout = (object: EditorObject): boolean =>
  // undefined is for the shared layout
  [...Object.values(DeviceType), undefined].some(
    device => !usesSharedLayout(object, device)
  )

export const hasSomeSharedLayout = (object: EditorObject): boolean =>
  Object.values(DeviceType).some(device => usesSharedLayout(object, device))
