import React, { Component } from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import { dataTypes } from '@adalo/constants'

import { getLabel } from 'utils/sources'

import { getSources } from 'ducks/recommender'

import SelectableInput from 'components/Shared/Forms/SelectableInput'

const SHARE_DATA_TYPES = [
  dataTypes.TEXT,
  dataTypes.DATE,
  dataTypes.NUMBER,
  dataTypes.IMAGE,
  dataTypes.FILE,
]

class ShareOptions extends Component {
  render() {
    const { field, options, getLabel, menuTheme } = this.props

    return (
      <div>
        <div className="action-item-split-row">
          <Field
            component={SelectableInput}
            name={`${field}.options.source`}
            displayName="Source"
            options={options}
            comparator={deepEqual}
            getLabel={getLabel}
            boxed
            menuTheme={menuTheme}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId, componentId, objectId, actionId, reference } = props

  const options = getSources(
    state,
    appId,
    componentId,
    objectId,
    SHARE_DATA_TYPES,
    false,
    actionId,
    reference
  )

  return {
    options,
    getLabel: source => getLabel(state, source, appId, componentId),
  }
}

export default connect(mapStateToProps)(ShareOptions)
