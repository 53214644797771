import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { assetURL } from 'utils/assets'
import { isSupportedOnWeb } from 'utils/platform'
import { getApp, getAppPlatform } from 'ducks/apps'
import { NEW_SCREEN_MODAL, showModal } from 'ducks/editor/modals'
import {
  SCREEN_TEMPLATES_DEFAULT_OPEN,
  ensureScreenTemplatesAreLoaded,
  getCategorizedScreenTemplates,
} from 'ducks/editor/screenTemplates'
import { getFeatureFlag } from 'ducks/featureFlags'

import Tab from './Tab'
import { ComponentItem } from '../ComponentsAccordion'

class ScreensTab extends Component {
  handleSelect = async id => {
    const { appId, history, showModal } = this.props

    try {
      await showModal(NEW_SCREEN_MODAL, { zoom: true, template: id })
      history.push(`/apps/${appId}/screens/add/component`)
    } catch (err) {
      // Do something
    }
  }

  renderItem = ({ id, name, thumbnail, iconClassName, mobileThumbnail }) => {
    const { platform, mobileOnly } = this.props
    const iconStyles = {}

    if (thumbnail) {
      iconStyles.backgroundImage = `url("${assetURL(thumbnail)}")`
    }

    if (mobileOnly && mobileThumbnail) {
      iconStyles.backgroundImage = `url("${assetURL(mobileThumbnail)}")`
    }

    return (
      <ComponentItem
        key={id}
        name={name}
        isMobileScreen={platform === 'mobile'}
        iconClassName={iconClassName}
        iconStyles={iconStyles}
        onClick={() => this.handleSelect(id)}
      />
    )
  }

  getOptions() {
    const { categorizedTemplates } = this.props

    return categorizedTemplates.map(category => ({
      label: category.title,
      children: category.options.map(template => ({
        label: template.name,
        ...template,
      })),
    }))
  }

  componentDidMount() {
    const { ensureScreenTemplatesAreLoaded, platform } = this.props
    ensureScreenTemplatesAreLoaded(platform)
  }

  render() {
    const { platform } = this.props

    return (
      <Tab
        key="screen"
        isMobileScreen={platform === 'mobile'}
        className={classNames('editor-add-screens-tab', {
          'editor-add-mobile-screens-tab': platform === 'mobile',
          'editor-add-web-screens-tab': isSupportedOnWeb(platform),
        })}
        defaultOpen={SCREEN_TEMPLATES_DEFAULT_OPEN}
        searchPlaceholder="Search Screens..."
        emptyMessage="No screens found"
        onSelect={this.handleSelect}
        options={this.getOptions()}
        renderItem={this.renderItem}
        platform={platform}
      />
    )
  }
}

function mapStateToProps(state, { appId }) {
  const platform = getAppPlatform(state, appId)
  const categorizedTemplates = getCategorizedScreenTemplates(state, platform)
  const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')
  const app = getApp(state, appId)
  const mobileOnly =
    hasNewMobileOnlyApp && app?.webSettings?.layoutMode === 'mobile'

  return {
    platform,
    categorizedTemplates,
    mobileOnly,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    showModal,
    ensureScreenTemplatesAreLoaded,
  })(ScreensTab)
)
