import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

// UTILS
import { getLabel } from 'utils/sources'

// DUCKS
import { getSources } from 'ducks/recommender'

// COMPONENTS
import SelectableInput from 'components/Shared/Forms/SelectableInput'
import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'

const ComparisonField = ({
  name,
  label,
  options,
  placeholder,
  boxed,
  menuTheme,
}) => {
  return (
    <Field
      component={SelectableInput}
      name={name}
      options={options}
      getLabel={() => label}
      placeholder={placeholder}
      rowHeight={40}
      boxed={boxed}
      menuTheme={menuTheme}
    />
  )
}

const mapStateToProps = (state, props) => {
  const {
    value,
    appId,
    componentId,
    objectId,
    dataTypes,
    actionId,
    reference,
    helpers,
  } = props

  const options = getSources(
    state,
    appId,
    componentId,
    objectId,
    dataTypes,
    false,
    actionId,
    reference,
    undefined,
    undefined,
    undefined,
    undefined,
    helpers
  )

  let label = ''

  if (value && typeof value === 'object') {
    label = getLabel(state, value, appId, componentId)
  }

  return {
    label,
    options,
  }
}

export default withPremiumFeatureHandler(
  connect(mapStateToProps)(ComparisonField)
)
