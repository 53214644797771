import React from 'react'

import { useSelector, useStore } from 'react-redux'
import IconOption from 'components/Shared/IconOption'
import { dataTypes, locationTypes, sourceTypes } from '@adalo/constants'
import { getLabel, buildDeviceLocationSource } from 'utils/sources'

import { getCurrentAppId, getComponent } from 'ducks/editor/objects'

import GooglePlacesInput from 'components/Shared/Forms/GooglePlacesInput'
import { getLibraryBindingSuggestions } from 'ducks/recommender'

import MenuControl from './MenuControl'

import { THEMES } from '../../../../constants'

const FALLBACK_MENU_OPTIONS = [
  { label: "Don't show anything", value: false },
  { label: 'Show a placeholder location', value: true },
]

const LocationControl = props => {
  const { onChange, defaultValue, objectId } = props

  const appId = useSelector(getCurrentAppId)
  const componentId = useSelector(state => getComponent(state, objectId).id)

  const databaseOptions = useSelector(state =>
    getLibraryBindingSuggestions(state, appId, componentId, objectId, [
      dataTypes.LOCATION,
    ])
  )

  const options = [
    {
      label: <IconOption icon="none" label="None" />,
      value: '',
    },
    {
      label: <IconOption icon="location" label="Custom Location" />,
      value: locationTypes.CUSTOM,
    },
    null,
    ...databaseOptions(),
  ]

  // Change Handlers
  const onChangeHandler = ({ type }) => {
    const newDefaultValue = {
      hasFallback: defaultValue ? defaultValue.hasFallback : false,
      fallbackValue: defaultValue ? defaultValue.fallbackValue : null,
    }

    if (type === locationTypes.CUSTOM) {
      return onChange({
        defaultValue: {
          ...newDefaultValue,
          defaultType: locationTypes.CUSTOM,
          value: '',
        },
      })
    } else if (type.source?.type === sourceTypes.DEVICE_LOCATION) {
      return onChange({
        defaultValue: {
          ...newDefaultValue,
          defaultType: locationTypes.DEVICE,
          value: buildDeviceLocationSource({
            dataType: dataTypes.LOCATION,
          }),
        },
      })
    } else if (type.source?.type === sourceTypes.FIELD) {
      return onChange({
        defaultValue: {
          ...newDefaultValue,
          defaultType: locationTypes.DATABASE,
          value: type,
        },
      })
    } else if (type.source?.type === sourceTypes.INPUT) {
      return onChange({
        defaultValue: {
          ...newDefaultValue,
          defaultType: locationTypes.INPUT,
          value: type,
        },
      })
    }

    return onChange({ defaultValue: null })
  }

  const fallbackLocationOptionHandler = ({ hasFallback }) => {
    onChange({
      defaultValue: {
        defaultType: defaultValue.defaultType,
        hasFallback,
        fallbackValue: '',
        value: defaultValue.value,
      },
    })
  }

  const fallbackLocationChangeHandler = value => {
    onChange({
      defaultValue: {
        defaultType: defaultValue.defaultType,
        hasFallback: true,
        fallbackValue: value,
        value: defaultValue.value,
      },
    })
  }

  // Interfaces
  let label = ''
  let locationInput = null
  let fallbackMenu = null
  let fallbackLocationInput = null

  // hooks cannot go inside conditionals, so access state here and ignore it when not needed
  const state = useStore().getState()

  if (defaultValue) {
    switch (defaultValue.defaultType) {
      case locationTypes.DEVICE:
        label = 'Current Device Location'

        break
      case locationTypes.CUSTOM:
        label = 'Custom Location'

        break
      case locationTypes.DATABASE:
      case locationTypes.INPUT:
        label = getLabel(state, defaultValue.value, appId, componentId)

        break
      default:
        label = 'Unknown Type'
    }
  }

  if (defaultValue && defaultValue.defaultType !== locationTypes.CUSTOM) {
    let displayName

    if (defaultValue?.defaultType === locationTypes.DEVICE) {
      displayName = 'If device location is unavailable...'
    } else {
      displayName = "If there's no location..."
    }

    fallbackMenu = (
      <MenuControl
        displayName={displayName}
        name="hasFallback"
        value={defaultValue.hasFallback}
        onChange={fallbackLocationOptionHandler}
        options={FALLBACK_MENU_OPTIONS}
      />
    )
  }

  if (
    defaultValue &&
    defaultValue.defaultType !== locationTypes.CUSTOM &&
    defaultValue.hasFallback
  ) {
    fallbackLocationInput = (
      <GooglePlacesInput
        panelView
        appId={appId}
        input={{
          name: 'defaultValue',
          onChange: fallbackLocationChangeHandler,
          value: defaultValue.fallbackValue,
        }}
      />
    )
  }

  if (defaultValue?.defaultType === locationTypes.CUSTOM) {
    locationInput = (
      <GooglePlacesInput
        panelView
        appId={appId}
        input={{
          name: 'defaultValue',
          onChange: value =>
            onChange({
              defaultValue: { defaultType: locationTypes.CUSTOM, value },
            }),
          value: defaultValue.value,
        }}
      />
    )
  }

  return (
    <div className="library-image-control">
      <MenuControl
        options={options}
        name="type"
        displayName="Default Value"
        value={defaultValue}
        onChange={onChangeHandler}
        getLabel={() => label}
        menuTheme={THEMES.DATA}
        placeholder="Add default value..."
      />
      {locationInput}
      {fallbackMenu}
      {fallbackLocationInput}
    </div>
  )
}

export default LocationControl
