import { MagicAddFeatureType } from 'ducks/dbAssistant'

import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'

import PromptInput from '../PromptInput'
import { PROMPT_MAX_LENGTH } from '../../MagicAdd'

type Props = {
  feature: MagicAddFeatureType | null
  handleGoBack: () => Promise<void> | void
  handleClick: () => Promise<void> | void
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  prompt: string

  errorMessage: string
  disableButton: boolean
  submitted: boolean
  disableInput: boolean
}

export default function FeaturePrompting({
  feature,
  handleGoBack,
  handleClick,
  handleChange,
  prompt,
  errorMessage,
  disableButton,
  submitted,
  disableInput,
}: Props): JSX.Element | null {
  return (
    <div className="magic-add-setup__feature-prompting">
      <div className="magic-add-setup-title feature-description">
        <h2>{feature?.description}</h2>
        <p>Consider the following questions...</p>
        <ul>
          {feature?.promptTips.map(question => (
            <li key={question}>{question}</li>
          ))}
        </ul>
      </div>
      <PromptInput
        value={prompt}
        placeholder={feature?.placeholder}
        onChange={handleChange}
        onClick={handleClick}
        submitted={submitted}
        maxLength={PROMPT_MAX_LENGTH}
        disableButton={disableButton}
        disableInput={disableInput}
        errorMessage={errorMessage}
        buttonText="Generate Preview"
      />
      {!submitted ? (
        <div className="magic-add-setup__feature-prompting__back-button-container">
          <Button fitContent square text onClick={handleGoBack}>
            <Icon small type="arrow-back" />
            Add something else
          </Button>
        </div>
      ) : null}
    </div>
  )
}
