export const capitalize = str => {
  if (!str) return str

  const first = str.substring(0, 1).toUpperCase()
  const rest = str.substring(1)

  return `${first}${rest}`
}

export const fontWeightOptions = [
  { value: '300', label: 'Light' },
  { value: '400', label: 'Normal' },
  { value: '500', label: 'Medium' },
  { value: '600', label: 'Semi Bold' },
  { value: '700', label: 'Bold' },
  { value: '800', label: 'Extra Bold' },
  { value: '900', label: 'Extra Black' },
]

export const getFontWeightName = value => {
  if (typeof value !== 'string') value = `${value}`

  switch (value.toLowerCase()) {
    case '100':
      return 'Thin'
    case '200':
      return 'Extra Light'
    case '300':
      return 'Light'
    case '400':
    case 'normal':
    case 'regular':
      return 'Normal'
    case '500':
      return 'Medium'
    case '600':
      return 'Semi Bold'
    case '700':
    case 'bold':
      return 'Bold'
    case '800':
      return 'Extra Bold'
    case '900':
      return 'Extra Black'
  }
}

/**
 * Returns a font family name from a font target value
 * @param {string} [value]
 * @param {object} branding - App branding object
 * @return {string|null}
 * @example
 * const branding = { fonts: { body: { family: 'Roboto' } } }
 * return normalizeFont(value, branding)
 */
export const normalizeFont = (value, branding = {}) => {
  const { fonts = {} } = branding

  if (!value || typeof value !== 'string' || !fonts) return null

  // the value "system" is the legacy fontFamily value
  if (value === 'system') {
    return fonts?.body?.family || null
  }

  const match = /^@(heading|body)?$/.exec(value)

  if (!match || !fonts[match[1]]) return null

  return fonts[match[1]].family
}

export const defaultFonts = '-apple-system, "SF Pro Text", sans-serif'
export const defaultSystemFontLabel = 'Standard System Font'

export const getFontFamily = (value, branding = {}) => {
  const font = normalizeFont(value, branding)

  if (!font || font === 'default') return defaultFonts

  return `${font}, ${defaultFonts}`
}

export const getFontFamilyOptions = (branding = {}) => {
  let body = normalizeFont('@body', branding)
  let heading = normalizeFont('@heading', branding)

  if (!body || body === 'default') body = defaultSystemFontLabel
  if (!heading || heading === 'default') heading = defaultSystemFontLabel

  return [
    { label: body, value: '@body' },
    { label: heading, value: '@heading' },
  ]
}

export const getFontWeightOptions = (value, branding = {}) => {
  const { fonts = {} } = branding

  switch (value) {
    case '@body':
    case '@heading':
    case 'system': {
      if (value === 'system') value = '@body'

      // remove "@" from value
      value = value.substring(1)

      const customFontWeights = fonts?.[value]?.variants

      if (!customFontWeights) return fontWeightOptions

      return customFontWeights.map(f => {
        return { label: getFontWeightName(f), value: f }
      })
    }

    default: {
      return fontWeightOptions
    }
  }
}
