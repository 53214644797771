import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Modal from 'components/Shared/Modal'

import Columns from './Columns'
import validation from './Validation'

const FORM_NAME = 'newTableForm'

//shared between columns and the form, can be extracted into a form util

//only thing we need to validate is columns have names set
//since they don't autofocus (would cause bad UX for table name)
//meaning they can be submitted without field level validation triggering
const validate = values => {
  const errors = {}

  if (values && values.columns) {
    values.columns.forEach(column => {
      if (validation(column.name) !== undefined) {
        errors._error = 'Properties name is required'
      }
    })
  }

  return errors
}

class NewTableForm extends Component {
  render() {
    const { datasourceId, handleSubmit, tables, values, onCancel } = this.props

    return (
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="new-table-form"
      >
        <Modal.Header title="New Collection" color="orange" />
        <Modal.Content>
          <Field
            autoFocus
            name="tableName"
            label="Collection Name"
            component={WrappedInput}
            validate={[validation]}
            large={false}
          />

          <label>Properties</label>
          <FieldArray
            name="columns"
            component={Columns}
            tables={tables}
            datasourceId={datasourceId}
            tableName={values && values.tableName}
          />
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button type="button" text onClick={onCancel}>
            Cancel
          </Modal.Button>
          <Modal.Button type="submit" orange>
            Create
          </Modal.Button>
        </Modal.Actions>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  values: getFormValues(FORM_NAME)(state),
})

const connected = connect(mapStateToProps)(NewTableForm)

export default reduxForm({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: false,
})(connected)
