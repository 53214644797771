import React from 'react'
import Modal from 'components/Shared/Modal'

const MarketplaceComponentModal = ({
  library,
  className,
  closeModal,
  ...otherProps
}) => {
  if (!library) {
    return null
  }

  const {
    displayName,
    author,
    description,
    demoAppId,
    details,
    price,
    supportURL,
    requiresThirdPartySubscription,
  } = library

  return (
    <Modal onClose={closeModal} {...otherProps}>
      <Modal.Header title={displayName} color="teal" goBack={closeModal} />
      <Modal.Content>
        <form>
          <label htmlFor="author">Author:</label>
          <h3 name="author">{author}</h3>
          <label htmlFor="description">Description:</label>
          <h3 name="description">{description || <a>Missing!</a>}</h3>
          <label htmlFor="demoApp">Demo App:</label>
          <h3>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://app.adalo.com/apps/${demoAppId}`}
              name="demoApp"
            >
              {demoAppId || 'Missing!'}
            </a>
          </h3>
          <label htmlFor="details">Details:</label>
          <h3 name="details">{details || <a>Missing!</a>}</h3>
          <label htmlFor="price">Price:</label>
          <h3 name="price">${price / 100}</h3>
          <label htmlFor="supportURL">Support URL:</label>
          <h3 name="supportURL">
            <a target="_blank" rel="noreferrer" href={supportURL}>
              {supportURL || 'Missing!'}
            </a>
          </h3>
          <label htmlFor="requiresThirdPartySubscription">
            Requires Third Party Subscription:
          </label>
          <h3 name="requiresThirdPartySubscription">
            {requiresThirdPartySubscription ? 'Yes' : 'No'}
          </h3>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export default MarketplaceComponentModal
