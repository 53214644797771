import React from 'react'
import moment from 'moment'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  Tooltip,
  ReferenceLine,
} from 'recharts'
import { useFlags } from 'flags'

import { isCurrentPlanType } from 'utils/billing.ts'

import AdaloTooltip from 'components/Shared/Tooltip'
import EmptyState from 'components/Shared/EmptyState'
import Icon from 'components/Shared/Icon'

import Legend, { LegendIcon } from './Legend'

import './AppActionsCharts.scss'

export const findMax = data => {
  return Math.max(
    ...data.map(entry => entry.totalCycleActionCount + entry.estimates)
  )
}

const findDomainMax = (data, planLimits, planType) => {
  let max = findMax(data)

  const currentLimit = planLimits?.currentPlan?.limit
  const nextLimit = planLimits?.nextPlan?.limit

  if (planLimits) {
    if (max > currentLimit) {
      if (planLimits.nextPlan && planType !== 'free') {
        if (max <= nextLimit) {
          max = nextLimit
        }
      }
    } else {
      max = currentLimit
    }
  }

  return max
}

export const generateTicks = (max, granularity) => {
  const ticks = []
  let tick = 0

  if (granularity === 0) {
    return ticks
  }

  while (tick <= max) {
    ticks.push(tick)
    tick += granularity
    tick = Math.round(tick * 10) / 10
  }

  return ticks
}

const formatTick = tick => {
  if (tick < 1000) {
    return tick
  } else if (tick < 1000000) {
    return `${Math.round(tick / 1000)}k`
  } else {
    return `${(Math.round(tick / 100000) / 10).toFixed(1)}m`
  }
}

const roundToNearest = (num, nearest) => {
  return Math.ceil(num / nearest) * nearest
}

const getPlanLimits = (planType, pricingPlans, cycles, hasPlanLimits) => {
  const currentPlan = { displayName: 'Current Plan', limit: 0 }
  let currentPlanLimit = 0
  let nextPlan = null
  let nextPlanLimit = 0
  let nextPlanDisplayName = ''

  const max = findMax(cycles)

  if (hasPlanLimits) {
    currentPlanLimit = pricingPlans[planType].limits.actions
    currentPlan.limit = currentPlanLimit

    let nextPlanUp = pricingPlans[planType].nextPlanUp

    if (nextPlanUp === 'business2022') {
      nextPlanUp = 'business2023'
    }

    if (nextPlanUp === 'professional') {
      nextPlanUp = 'professional2023'
    }

    if (nextPlanUp && max >= currentPlanLimit) {
      nextPlanLimit = pricingPlans[nextPlanUp].limits.actions
      nextPlanDisplayName = pricingPlans[nextPlanUp].planDisplayName
      nextPlan = { displayName: nextPlanDisplayName, limit: nextPlanLimit }
    }

    return { currentPlan, nextPlan }
  } else {
    return null
  }
}

const getNumberDigits = integer => {
  return integer.toString().length
}

const getGranularity = max => {
  const digits = getNumberDigits(max)
  const power = digits > 1 ? digits - 1 : 1
  const maxRounded = roundToNearest(max, 10 ** power)

  return maxRounded / 10
}

const BarTooltip = ({ active, payload }) => {
  if (active && payload?.length > 0) {
    const totalCycleActionCount =
      payload[0]?.payload?.totalCycleActionCount +
        payload[0]?.payload?.estimates || 0

    return (
      <div className="billing-usage-chart-tooltip">
        <div className="billing-usage-chart-tooltip-label">
          <LegendIcon color="#c43376" borderColor="#83043e" shape="circle" />
          <h5>Within Plan</h5>
        </div>
        <p className="billing-usage-chart-tooltip-value">
          {payload[0]?.value?.toLocaleString('en-US')}
        </p>
        <div className="billing-usage-chart-tooltip-label">
          <LegendIcon color="#7b0d3f" borderColor="#490826" shape="circle" />
          <h5>Overages</h5>
        </div>
        <p className="billing-usage-chart-tooltip-value">
          {payload[1]?.value?.toLocaleString('en-US')}
        </p>
        {payload[2]?.value && payload[2]?.value > 0 ? (
          <>
            <div className="billing-usage-chart-tooltip-label">
              <LegendIcon
                color="rgba(240, 229, 234, 1)"
                borderColor="#83043e"
                shape="circle"
              />
              <h5>Estimated</h5>
            </div>
            <p className="billing-usage-chart-tooltip-value">
              {payload[2]?.value?.toLocaleString('en-US')}
            </p>
          </>
        ) : null}
        <hr />
        <h5 className="billing-usage-chart-tooltip-label">
          Total App Actions:
        </h5>
        <p className="billing-usage-chart-tooltip-value">
          {totalCycleActionCount.toLocaleString('en-US') || 0}
        </p>
      </div>
    )
  }

  return null
}

const LegacyBarTooltip = ({ active, payload }) => {
  if (active && payload?.length > 0) {
    const totalCycleActionCount =
      payload[0]?.payload?.totalCycleActionCount +
        payload[0]?.payload?.estimates || 0

    return (
      <div className="billing-usage-chart-tooltip">
        {payload[2]?.value && payload[2]?.value > 0 ? (
          <>
            <div className="billing-usage-chart-tooltip-label">
              <LegendIcon
                color="rgba(240, 229, 234, 1)"
                borderColor="#83043e"
                shape="circle"
              />
              <h5>Estimated</h5>
            </div>
            <p className="billing-usage-chart-tooltip-value">
              {payload[2]?.value?.toLocaleString('en-US')}
            </p>
            <div className="billing-usage-chart-tooltip-label">
              <LegendIcon
                color="#c43376"
                borderColor="#83043e"
                shape="circle"
              />
              <h5>App Actions</h5>
            </div>
            <p className="billing-usage-chart-tooltip-value">
              {payload[0]?.value?.toLocaleString('en-US')}
            </p>
            <hr />
          </>
        ) : null}
        <h5 className="billing-usage-chart-tooltip-label">
          Total App Actions:
        </h5>
        <p className="billing-usage-chart-tooltip-value">
          {totalCycleActionCount.toLocaleString('en-US') || 0}
        </p>
      </div>
    )
  }

  return null
}

const PlanLabel = props => {
  const { value, viewBox, chipFill } = props
  const x = 104
  const y = viewBox.y - 9

  return (
    <g>
      <rect
        x={x}
        y={y}
        width="90"
        height="18"
        rx="10"
        ry="10"
        fill={chipFill}
      />
      <text
        x={x}
        y={y}
        dy={12}
        dx={45}
        fill="#333"
        fontSize={10}
        fontWeight={600}
        textAnchor="middle"
      >
        {value}
      </text>
    </g>
  )
}

const AppActionsOverTimeChart = props => {
  const { organization, pricingPlans, cycles } = props

  const { hasFreePlanStats } = useFlags()

  if (!organization) {
    return null
  }

  const { planType } = organization

  const isNewPlan = isCurrentPlanType(planType)

  const hasPlanLimits = isNewPlan || (!organization.active && hasFreePlanStats)

  const planLimits = getPlanLimits(
    planType,
    pricingPlans,
    cycles,
    hasPlanLimits
  )

  const max = findDomainMax(cycles, planLimits, planType)
  const granularity = getGranularity(max)
  const domainMax = roundToNearest(max, granularity) + granularity
  const ticks = generateTicks(domainMax, granularity)
  const showNextPlanLimit =
    planLimits && planLimits.nextPlan && hasPlanLimits && planType !== 'free'

  const longestLabelLength = ticks.reduce((acc, cur) => {
    if (domainMax > 10000) {
      return formatTick(cur).length > acc ? formatTick(cur).length : acc
    } else {
      return cur.toString().length > acc ? cur.toString().length : acc
    }
  }, 0)

  return (
    <div id="actions-over-time" className="billing-usage-chart-container">
      <div className="billing-usage-chart-header">
        <h3 className="billing-usage-chart-header-text">
          App Actions Over Time
          <span className="actions-over-time-tooltip">
            <AdaloTooltip
              tooltip={
                <p>
                  See how your apps have performed over time and estimate how
                  many actions you'll use next month. Estimates are based on
                  past trends and may not reflect future performance.{' '}
                  <a
                    href="https://help.adalo.com/action-basics/app-actions-dashboard"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              }
              placement="bottom-start"
              hideArrow
            >
              <Icon type="help" />
            </AdaloTooltip>
          </span>
        </h3>
        <div className="billing-usage-chart-bill-period">
          <Icon small type="calendar-blank" color="darkerGray" />
          <p>
            Resets Monthly on the{' '}
            {organization.billingStartDate
              ? moment(organization.billingStartDate).format('Do')
              : moment(organization.createdAt).format('Do')}
          </p>
        </div>
      </div>
      <div className="billing-usage-chart">
        {granularity > 0 ? (
          <ResponsiveContainer width="100%" height={225}>
            <BarChart width={500} height={225} data={cycles}>
              <XAxis
                dataKey="date"
                tickLine={false}
                tick={{ fontWeight: 500, fontSize: '11px' }}
              />
              <YAxis
                type="number"
                domain={[0, domainMax]}
                ticks={ticks}
                interval={0}
                tickFormatter={domainMax < 10000 ? null : formatTick}
                tickLine={false}
                axisLine={false}
                tick={{ fontWeight: 500, fontSize: '9px' }}
                width={longestLabelLength * 7}
              />
              <Tooltip
                content={hasPlanLimits ? <BarTooltip /> : <LegacyBarTooltip />}
                isAnimationActive={false}
                offset={-78}
                active
                cursor={false}
                wrapperStyle={{ height: '100%' }}
              />
              <CartesianGrid vertical={false} />
              <Bar
                dataKey="numberOfActions"
                barSize={84}
                fill="#c43376"
                stackId="a"
              >
                {cycles.map(entry => {
                  return (
                    <Cell
                      key={`cell-${entry.date}`}
                      radius={
                        (entry.overages && entry.overages > 0) ||
                        (entry.estimates && entry.estimates > 0)
                          ? 0
                          : [10, 10, 0, 0]
                      }
                    />
                  )
                })}
              </Bar>
              <Bar dataKey="overages" barSize={84} fill="#7b0d3f" stackId="a">
                {cycles.map(entry => (
                  <Cell
                    key={`cell-${entry.date}`}
                    radius={
                      entry.estimates && entry.estimates ? 0 : [10, 10, 0, 0]
                    }
                  />
                ))}
              </Bar>
              <Bar
                dataKey="estimates"
                barSize={84}
                fill="rgba(240, 229, 234, 1)"
                stackId="a"
              >
                {cycles.map(entry => (
                  <Cell key={`cell-${entry.date}`} radius={[10, 10, 0, 0]} />
                ))}
              </Bar>
              {planLimits && planLimits.currentPlan && hasPlanLimits ? (
                <ReferenceLine
                  y={planLimits.currentPlan.limit}
                  stroke="#FECC1D"
                  isFront
                  label={<PlanLabel value="Current Plan" chipFill="#FECC1D" />}
                />
              ) : null}
              {showNextPlanLimit ? (
                <ReferenceLine
                  y={planLimits.nextPlan.limit}
                  stroke="#FECC1D"
                  isFront
                  label={
                    <PlanLabel
                      value={planLimits.nextPlan.displayName}
                      chipFill="#FECC1D"
                    />
                  }
                />
              ) : null}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <EmptyState modalWidth className="app-actions-over-time-empty-state">
            <h2 className="empty-state-header">No Data</h2>
            <p className="empty-state-message">
              You don't have any app actions yet
            </p>
          </EmptyState>
        )}
      </div>
      {hasPlanLimits ? (
        <Legend
          payload={[
            {
              label: 'App Actions Within Plan',
              color: '#c43376',
              borderColor: '#83043e',
              iconShape: 'circle',
            },
            {
              label: 'Overages',
              color: '#7b0d3f',
              borderColor: '#490826',
              iconShape: 'circle',
            },
            {
              label: 'Estimated',
              color: 'rgba(240, 229, 234, 1)',
              borderColor: '#83043e',
              iconShape: 'circle',
            },
          ]}
          className="billing-usage-chart-legend"
        />
      ) : (
        <Legend
          payload={[
            {
              label: 'App Actions',
              color: '#c43376',
              borderColor: '#83043e',
              iconShape: 'circle',
            },
            {
              label: 'Estimated',
              color: 'rgba(240, 229, 234, 1)',
              borderColor: '#83043e',
              iconShape: 'circle',
            },
          ]}
          className="billing-usage-chart-legend"
        />
      )}
    </div>
  )
}

export default AppActionsOverTimeChart
