import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'

import { getSortOptions, getSources } from 'ducks/recommender'
import { getCurrentAppId, getComponent } from 'ducks/editor/objects'
import { dataTypes, sourceTypes } from '@adalo/constants'
import { getLabel } from 'utils/sources'
import GooglePlacesInput from 'components/Shared/Forms/GooglePlacesInput'
import MenuControl from '../Libraries/MenuControl'

class SelectSort extends Component {
  handleChange = ({ sort }) => {
    const { onChange } = this.props

    onChange(sort)
  }

  handleChangeLocationSource = location => {
    const { onChangeLocationSource } = this.props
    onChangeLocationSource(location)
  }

  handleChangeLocationFallback = location => {
    const { onChangeLocationFallback } = this.props
    onChangeLocationFallback(location)
  }

  getLocationLabel = source => {
    const { getLabel } = this.props

    return getLabel(source)
  }

  render() {
    const {
      value,
      referenceValue,
      fallbackValue,
      options,
      locationOptions,
      appId,
    } = this.props

    return (
      <>
        <MenuControl
          name="sort"
          displayName="Sorting"
          options={options}
          value={value}
          onChange={this.handleChange}
          comparator={deepEqual}
        />
        {value && value.type === dataTypes.LOCATION ? (
          <>
            <MenuControl
              name="source"
              displayName="Sort in relation to..."
              options={locationOptions}
              getLabel={this.getLocationLabel}
              value={referenceValue}
              onChange={this.handleChangeLocationSource}
              comparator={deepEqual}
              placeholder="Select..."
            />
            {referenceValue ? (
              <>
                <div className="library-inspect-menu-control">
                  {referenceValue.type ===
                  sourceTypes.CUSTOM_LOCATION ? null : (
                    <p>Fallback address</p>
                  )}
                  <GooglePlacesInput
                    panelView={false}
                    appId={appId}
                    input={{
                      name: 'fallback',
                      onChange: this.handleChangeLocationFallback,
                      value: fallbackValue,
                    }}
                    inFormField
                  />
                </div>
                {referenceValue.type === sourceTypes.CUSTOM_LOCATION ? null : (
                  <p className="form-inspect-fields-help-text">
                    The fallback address will be used if the referenced sorting
                    location cannot be found.
                  </p>
                )}
              </>
            ) : null}
          </>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state, { binding, objectId }) => {
  const appId = getCurrentAppId(state)
  const componentId = getComponent(state, objectId).id

  return {
    options: getSortOptions({ state, binding, appId: getCurrentAppId(state) }),
    locationOptions: getSources(
      state,
      appId,
      componentId,
      objectId,
      [dataTypes.LOCATION],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ),
    getLabel: source => getLabel(state, source, appId, componentId, true),
    appId,
  }
}

export default connect(mapStateToProps)(SelectSort)
