import { getId } from '@adalo/utils'
import { bindingTypes, comparators, sourceTypes } from '@adalo/constants'
import update from 'immutability-helper'

import { deepFilter } from './operators'
import { getDatasourceId } from './sources'

export const buildBinding = (bindingType, source, formatter, property) => {
  const result = {
    id: getId(),
    type: 'binding',
    bindingType,
    source,
  }

  if (formatter) {
    result.format = formatter
  }

  if (bindingType === bindingTypes.VISIBILITY) {
    result.comparator = comparators.EQUAL
  }

  return result
}

// Cleans up dataBindings / actions during a paste operation
// to avoid invalid IDs sticking around.
export const removeInvalidBindings = (obj, datasourceIds) => {
  // Remove bindings
  let newObj = deepFilter(obj, itm => {
    if (itm && itm.type === 'binding' && itm.source) {
      const datasourceId = getDatasourceId(itm.source)

      if (datasourceId && !datasourceIds.includes(datasourceId)) {
        return false
      }
    }

    return true
  })

  // Remove dangling sources (from actions, etc.)
  newObj = deepFilter(newObj, itm => {
    if (itm && itm.type in sourceTypes) {
      const datasourceId = getDatasourceId(itm)

      if (datasourceId && !datasourceIds.includes(datasourceId)) {
        return false
      }
    }

    return true
  })

  return newObj
}

// Resolves bindings by looking for a test value. Returns string.
export const getBindingTestValue = (binding, label = false) => {
  if (!binding) {
    return ''
  }

  if (!isBinding(binding)) {
    return binding
  }

  let resultString = ''

  for (const expr of binding) {
    if (typeof expr === 'string') {
      resultString += expr
    } else if (typeof expr === 'object') {
      if (label && expr.label) {
        resultString += expr.label
      } else if (expr.testValue) {
        resultString += expr.testValue
      }
    }
  }

  return resultString
}

export const isBinding = binding => {
  return Array.isArray(binding)
}

export const stripTrailingSlash = urlBinding => {
  if (!isBinding(urlBinding)) {
    if (urlBinding.match(/\/$/)) {
      return urlBinding.substring(0, urlBinding.length - 1)
    }
  } else {
    if (urlBinding[urlBinding.length - 1].match(/\/$/)) {
      urlBinding[urlBinding.length - 1] = urlBinding[
        urlBinding.length - 1
      ].substring(0, urlBinding[urlBinding.length - 1].length - 1)
    }
  }

  return urlBinding
}

export const appendURLBinding = (urlBinding, appendStr) => {
  let newURLBinding = urlBinding

  if (!isBinding(urlBinding)) {
    return `${urlBinding}${appendStr}`
  } else {
    const index = urlBinding.length - 1

    newURLBinding = update(urlBinding, {
      [index]: { $set: urlBinding[urlBinding.length - 1] + appendStr },
    })
  }

  return newURLBinding
}

export const getBindingLabel = urlBinding => {
  if (!isBinding(urlBinding)) {
    return urlBinding
  } else {
    return getBindingTestValue(urlBinding, true)
  }
}

export const replaceSpaces = binding => {
  if (!isBinding(binding)) {
    return replaceSpace(binding)
  } else {
    const newBinding = [...binding]

    for (let index = 0; index < newBinding.length; index += 1) {
      const expr = newBinding[index]

      if (typeof expr === 'string') {
        newBinding[index] = replaceSpace(expr)
      }
    }

    return newBinding
  }
}

const replaceSpace = str => {
  const encoded = encodeURI(str)
  const fixedSpaces = encoded.replace('%C2%A0', '%20')

  return decodeURI(fixedSpaces)
}
