import update from 'immutability-helper'

const MODAL = 'EDITOR_MODAL'

const INITIAL_STATE = []

export const NEW_SCREEN_MODAL = 'newScreen'
export const NEW_TABLE = 'newTable'
export const NEW_RELATIONSHIP = 'newRelationship'
export const TWO_FACTOR_MODAL = '2FAModal'
export const CUSTOM_ACTION_MODAL = 'customActionModal'
export const INTEGRATION_TRIAL_MODAL = 'integrationTrialModal'
export const FREE_TRIAL_MODAL = 'FREE_TRIAL_MODAL'
export const TERMS_OF_SERVICE_MODAL = 'termsOfService'
export const DESIGN_VERSION_MODAL = 'designVersionModal'
export const CONFIRM_RESTORE_MODAL = 'confirmRestoreModal'
export const UNPUBLISH_APP_MODAL = 'unpublishAppModal'
export const PUBLISHED_APPS_ADDON_MODAL = 'publishedAppsAddonModal'
export const CANCEL_SUBSCRIPTION_MODAL = 'cancelSubscriptionModal'
export const CHOOSE_ZAP_MODAL = 'chooseZapModal'
export const FEATURE_TEMPLATE_MODAL = 'featureTemplateModal'
export const FEATURE_TEMPLATE_DETAILS_MODAL = 'featureTemplateDetailsModal'
export const CHURN_FORM_MODAL = 'churnFormModal'

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${MODAL}_PENDING`) {
    const newState = update(state, { $push: [action.meta] })

    return newState
  }

  if (
    action.type === `${MODAL}_FULFILLED` ||
    action.type === `${MODAL}_REJECTED`
  ) {
    const index = state.indexOf(action.meta)
    const newState = update(state, { $splice: [[index, 1]] })

    return newState
  }

  if (action.type === `${MODAL}_REMOVE`) {
    return INITIAL_STATE
  }

  return state
}

// Actions

export const showModal = (modalType, opts = {}) => {
  let resolve
  let reject

  const promise = new Promise((resolvePromise, rejectPromise) => {
    resolve = resolvePromise
    reject = rejectPromise
  })

  return {
    type: MODAL,
    meta: { modalType, resolve, reject, opts },
    payload: promise,
  }
}

export const removeModal = modal => ({
  type: `${MODAL}_REMOVE`,
  meta: modal,
})

// Selectors

export const getActiveModal = state => {
  const current = state.editor.modals.length - 1

  return state.editor.modals[current]
}

export const getModals = state => {
  return state.editor.modals
}
