import { COMPONENT } from '@adalo/constants'
import { getParentIds } from 'utils/positioning'
import { EditorObject } from 'utils/responsiveTypes'
import getObject from './getObject'
import { ObjectList } from '../../types/ObjectList'
import { ObjectPathMap } from '../../types/ObjectPathMap'

// NOTE: we also have `getContainingScreen` within the same folder
// TODO(michael-adalo): we should consoldiate these two functions
// however bear in mind that `getContainingScreen` throws an error if the object or screen cannot be found
// while `getParentScreen` returns null if the parent is not found

const getParentScreen = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  objectId: string
): EditorObject | null => {
  try {
    const object = getObject(objectList, pathMap, objectId)

    if (object.type === COMPONENT) {
      return object
    }

    const currentParentIds = getParentIds(
      objectList,
      pathMap,
      objectId
    ) as string[]

    const currentParentId = currentParentIds[0] || null
    if (currentParentId === null) {
      throw new Error('No parent found')
    }

    return getObject(objectList, pathMap, currentParentId)
  } catch (e) {
    return null
  }
}

export default getParentScreen
