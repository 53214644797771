import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindingTypes } from '@adalo/constants'
import { reset } from 'redux-form'

import { showModal, NEW_TABLE } from 'ducks/editor/modals'
import { addBindings } from 'ducks/recommender'

import { buildTableSource } from 'utils/sources'

//TODO: does this belong under Libraries? style it in its own stylesheet?
class NewTableOption extends Component {
  async createNewTable() {
    const { handleChangeTable, showModal } = this.props

    try {
      const { tableName, tableId, datasourceId } = (await showModal(NEW_TABLE))
        .value

      // TODO: is there a better way to set the bindings, etc for the source?
      // TODO: potential race condition if it sets the source before
      // it's been created? would this cause issues?
      const tableSource = buildTableSource({ datasourceId, tableId })

      const options = addBindings(
        [
          {
            label: tableName,
            value: tableSource,
          },
        ],
        bindingTypes.LIBRARY_PROP
      )

      if (handleChangeTable) handleChangeTable({ source: options[0].value })
    } catch (err) {
      const { reset } = this.props
      reset('newTableForm')
      console.log('Error:', err)
    }
  }

  render() {
    return (
      <div
        style={{
          fontStyle: 'italic',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={this.createNewTable.bind(this)}
      >
        New Collection...
      </div>
    )
  }
}

export default connect(null, { showModal, reset })(NewTableOption)
