import { Action } from 'redux'

import { adaloBackendAxios, AxiosResponse } from 'utils/io/http/axios'
import { DatasourceTables } from '../components/Editor/XanoExternalDatabaseModal/lib/user'

enum ActionTypes {
  GET_FEATURE_TEMPLATES = 'GET_FEATURE_TEMPLATES',
  GET_FEATURE_TEMPLATE_BY_ID = 'GET_FEATURE_TEMPLATE_BY_ID',
  SET_FEATURE_TEMPLATE_FEED_SCROLL = 'SET_FEATURE_TEMPLATE_FEED_SCROLL',
}

export type FeatureTemplate = {
  id: string
  name: string
  category: string
  summary: string
  description: string
  preview: string
  image: string
  demo: string
  demoMobile: string
  icon: string | null
  timesUsed: number
  createdAt: Date
  updatedAt: Date
  AppId: string
  collections: DatasourceTables
}

interface ActionResponse extends Omit<Action, 'payload'> {
  payload: {
    data: FeatureTemplate[] | FeatureTemplate
    scroll?: number
  }
}

interface State {
  list: FeatureTemplate[] | null
  current: FeatureTemplate | null
  feedScroll: number
}

const INITIAL_STATE = {
  list: null,
  current: null,
  feedScroll: 0,
} as State

// Reducers
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: ActionResponse): State => {
  switch (action.type) {
    case `${ActionTypes.GET_FEATURE_TEMPLATES}_FULFILLED`:
      if (!Array.isArray(action.payload.data)) {
        return state
      }

      return { ...state, list: action.payload.data }

    case `${ActionTypes.GET_FEATURE_TEMPLATE_BY_ID}_FULFILLED`:
      if (Array.isArray(action.payload.data)) {
        return state
      }

      return { ...state, current: action.payload.data }

    case ActionTypes.SET_FEATURE_TEMPLATE_FEED_SCROLL: {
      const { payload } = action

      return {
        ...state,
        feedScroll: payload.scroll || 0,
      }
    }

    default:
      return state
  }
}

export const fetchFeatureTemplates = (
  appId: string
): {
  type: ActionTypes
  payload: Promise<AxiosResponse<unknown>>
} => {
  const url = `/feature-templates?appId=${appId}`

  return {
    type: ActionTypes.GET_FEATURE_TEMPLATES,
    payload: adaloBackendAxios.get(url),
  }
}

export const fetchFeatureTemplateDetails = (
  id: string
): {
  type: ActionTypes
  payload: Promise<AxiosResponse<unknown>>
} => {
  const url = `/feature-templates/${id}`

  return {
    type: ActionTypes.GET_FEATURE_TEMPLATE_BY_ID,
    payload: adaloBackendAxios.get(url),
  }
}

// Selectors
export const getFeatureTemplates = (state?: {
  featureTemplates: State
}): FeatureTemplate[] | null => state?.featureTemplates?.list || null

export const getFeatureTemplateDetails = (state?: {
  featureTemplates: State
}): FeatureTemplate | null => state?.featureTemplates?.current || null

export const getFeatureTemplateCollections = (state?: {
  featureTemplates: State
}): FeatureTemplate['collections'] | null =>
  state?.featureTemplates?.current?.collections || null

export const getFeatureTemplateFeedScroll = (state: {
  featureTemplates: State
}): number | undefined => {
  return state?.featureTemplates?.feedScroll
}

export const setFeatureTemplateFeedScroll = (
  scroll: number
): {
  type: ActionTypes.SET_FEATURE_TEMPLATE_FEED_SCROLL
  payload: {
    scroll: number
  }
} => ({
  type: ActionTypes.SET_FEATURE_TEMPLATE_FEED_SCROLL,
  payload: {
    scroll,
  },
})
