import { memo } from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import type { Zoom } from 'utils/canvasTypes'
import type { Branding } from 'utils/colorsTypes'

import BaseObject from './BaseObject'
import GroupWrapper from './Group/GroupWrapper'
import TablePreview from './Table/Table'
import TableRenderHelperContext from './Table/TableRenderHelperContext'
import {
  getFocussedField,
  getRenderEmptyState,
} from '../Inspect/Libraries/Table/utils'
import { ANIMATION_TIMING } from './Table/layout'
import { makeTheme } from './Table/utils'
import type { TableTheme } from './Table/types'
import TableThemeContext from './Table/TableThemeContext'
import type { TableEditorObject } from '../Inspect/Libraries/Table/types'

type Props = {
  object: TableEditorObject
  xScaled: number
  yScaled: number
  widthScaled: number
  heightScaled: number
  borderWidth: number
  editorResizingProps: {
    relativeWidth?: number
    parentInitialWidth?: number
    relativePosition?: number
    x: number
    relativeX: number
    horizontalPositioning: string
    initialWidth: number
  }
  opacity: number
  zoom: Zoom
  renderEmptyState: boolean
  currentFocussedField: string | null
  theme: TableTheme
}

type OwnProps = {
  object: TableEditorObject
  branding: Branding
  zoom: Zoom
}

// eslint-disable-next-line react/prefer-stateless-function
class Table extends BaseObject<Props> {
  // we pass a lot of props canvas components, many of which Table doesn't use
  override shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    const {
      object,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      borderWidth,
      editorResizingProps,
      opacity,
      zoom,
      renderEmptyState,
      currentFocussedField,
      theme,
    } = this.props

    const previous: Props = {
      object,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      borderWidth,
      editorResizingProps,
      opacity,
      zoom,
      renderEmptyState,
      currentFocussedField,
      theme,
    }

    const next: Props = {
      object: nextProps.object,
      xScaled: nextProps.xScaled,
      yScaled: nextProps.yScaled,
      widthScaled: nextProps.widthScaled,
      heightScaled: nextProps.heightScaled,
      borderWidth: nextProps.borderWidth,
      editorResizingProps: nextProps.editorResizingProps,
      opacity: nextProps.opacity,
      zoom: nextProps.zoom,
      renderEmptyState: nextProps.renderEmptyState,
      currentFocussedField: nextProps.currentFocussedField,
      theme: nextProps.theme,
    }

    return !isEqual(previous, next)
  }

  override render(): JSX.Element {
    const {
      object,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      editorResizingProps,
      opacity,
      zoom,
      renderEmptyState,
      currentFocussedField,
      theme,
    } = this.props

    return (
      <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
        <g className="group" style={{ opacity }}>
          <g
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDoubleClick}
          >
            {/* Make the element clickable */}
            <rect
              x={xScaled}
              y={yScaled}
              width={widthScaled}
              height={heightScaled}
              fill="none"
              stroke="none"
            />
          </g>
          <foreignObject
            x={xScaled}
            y={yScaled}
            width={widthScaled}
            height={heightScaled}
            style={{
              pointerEvents: 'none',
              overflow: 'visible',
            }}
          >
            <TableThemeContext.Provider value={theme}>
              <TableRenderHelperContext.Provider
                value={{
                  zoomScale: zoom.scale,
                  renderEmptyState,
                  currentFocussedField,
                  animationTiming: ANIMATION_TIMING,
                }}
              >
                <TablePreview object={object} />
              </TableRenderHelperContext.Provider>
            </TableThemeContext.Provider>
          </foreignObject>
        </g>
      </GroupWrapper>
    )
  }
}

const mapStateToProps = (
  state: unknown,
  { object, branding, zoom }: OwnProps
) => ({
  renderEmptyState: getRenderEmptyState(state, object),
  currentFocussedField: getFocussedField(state, object),
  theme: makeTheme(object, branding, zoom.scale),
})

export default connect(mapStateToProps)(memo(Table))
