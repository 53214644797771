import { DeviceType } from '@adalo/constants'
import { DeviceValue } from 'utils/responsiveTypes'
import resetChildrenToDevice from 'utils/operations/resetChildrenToDevice'
import {
  getSectionFromContainer,
  isContainerSectionElement,
} from 'utils/layoutSections'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import { hasDeviceLayoutEnabled } from '../device-layouts/utils'
import { disableDeviceSpecificLayoutHandler } from './disableDeviceSpecificLayout'
import updateChangedObject from './updateChangedObject'

export interface ResetSharedLayoutOptions {
  objectId: string
  deviceToResetTo: DeviceValue
  skipOtherDevices: boolean
}

export interface ResetSharedLayoutInstruction {
  operation: 'resetSharedLayout'
  options: ResetSharedLayoutOptions
}

/**
 * Removes all device-specific layout from the object and sets the shared layout to the layout of the specified device.
 * Sets the initial device to the specified device.
 *
 * If `skipOtherDevices` is set, only the deviceToResetTo will have it's device specific layout disabled
 */
export const resetSharedLayoutHandler = (
  state: InstructionState,
  options: ResetSharedLayoutOptions
): InstructionState => {
  const { list, pathMap } = state
  const { deviceToResetTo, skipOtherDevices = false } = options
  let { objectId } = options

  let object = getObject(list, pathMap, objectId)

  if (isContainerSectionElement(object)) {
    const section = getSectionFromContainer(list, pathMap, object)

    if (section) {
      object = section
      objectId = section.id
    }
  }

  let newList = list
  let newSharedLayout = {}

  if (hasDeviceLayoutEnabled(object, deviceToResetTo)) {
    newSharedLayout = object[deviceToResetTo] ?? {}
  }

  newList = updateChangedObject(
    list,
    pathMap,
    resetChildrenToDevice(
      {
        ...object,
        ...newSharedLayout,
        initialDevice: deviceToResetTo,
      },
      deviceToResetTo
    ),
    undefined
  )

  const devicesToReset = skipOtherDevices
    ? [deviceToResetTo]
    : Object.values(DeviceType)

  for (const device of devicesToReset) {
    if (hasDeviceLayoutEnabled(object, device)) {
      const instructionState = { ...state, list: newList }
      ;({ list: newList } = disableDeviceSpecificLayoutHandler(
        instructionState,
        { objectId, device }
      ))
    }
  }

  let updatedObject = getObject(newList, pathMap, objectId)
  updatedObject = {
    ...updatedObject,
    ...newSharedLayout,
    initialDevice: deviceToResetTo,
  }

  // TODO(enzo): It looks like sometimes we leave behind an object's device-specific layout attributes,
  // even when that device's shared settings are set to true. We ought to look into that.
  delete updatedObject[deviceToResetTo]

  newList = updateChangedObject(newList, pathMap, updatedObject, undefined)

  return { ...state, list: newList }
}

const resetSharedLayout = (
  objectId: string,
  deviceToResetTo: DeviceValue,
  skipOtherDevices = false
): ResetSharedLayoutInstruction => ({
  operation: 'resetSharedLayout',
  options: { objectId, deviceToResetTo, skipOtherDevices },
})

export default resetSharedLayout
