import getDeviceObject from 'utils/getDeviceObject'
import { DeviceValue, EditorObject } from 'utils/responsiveTypes'

export const getDeviceChildren = (
  children: EditorObject[],
  deviceType: DeviceValue
): EditorObject[] => {
  const deviceChildren = []
  for (const child of children) {
    deviceChildren.push(getDeviceObject(child, deviceType))
  }

  return deviceChildren
}
