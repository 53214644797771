import { COMPONENT } from '@adalo/constants'

import {
  deepMap,
  getObjectsInRect,
  subPath,
  getObject,
  getBoundingBox,
  optimize,
  getDeviceType,
  pathLength,
} from '@adalo/utils'
import getDeviceObject from './getDeviceObject'
import hiddenOnDevice from './objects/hiddenOnDevice'
import { applyScaleRounding, scale, scaleValue } from './zoom'

const identity = obj => obj

export const createRect = (point1, point2, shouldRound = false) => {
  const round = shouldRound ? Math.round : identity

  return {
    x: round(Math.min(point1[0], point2[0])),
    y: round(Math.min(point1[1], point2[1])),
    width: round(Math.abs(point2[0] - point1[0])),
    height: round(Math.abs(point2[1] - point1[1])),
  }
}

// Returns ID of best parent for OBJ
// If possible, returns top-most fully-containing parent
// If sticks to the partially contained parent when it's the current parent
// When mouse and zoom are provided it uses that to determine the best parent
// Otherwise, uses center-offset distance as tie-break
export const getBestParent = (
  obj,
  parents,
  map,
  currentParent,
  zoom,
  mouseCoords,
  list
) => {
  if (obj.type === COMPONENT) {
    return null
  }

  let fullObject = null

  if (list) {
    fullObject = getObject(list, map[obj.id])
  }

  const matches = getObjectsInRect(parents, obj)

  const matchObjects = parents.filter(({ id }) => {
    return matches.indexOf(id) !== -1
  })

  let bestCursorParentId = null

  if (zoom && mouseCoords) {
    matchObjects.forEach(parent => {
      const hasObjectSelected = selectObjectFromMouseCoords(
        parent,
        zoom,
        mouseCoords
      )

      let newPathLength
      let currentPathLength

      if (map && bestCursorParentId && parent?.id) {
        newPathLength = pathLength(map[parent.id])
        currentPathLength = pathLength(map[bestCursorParentId])
      }

      const hasMoreDepth =
        newPathLength >= currentPathLength || !bestCursorParentId

      if (hasObjectSelected && hasMoreDepth) {
        bestCursorParentId = parent.id
      }
    })
  }

  let fullyContainedId = null

  let bestDist = Infinity
  let bestId = null

  for (let i = 0; i < matchObjects.length; i += 1) {
    const matchObj = matchObjects[i]
    const intersection = getIntersection(obj, matchObj)

    // Determine whether fully-contained
    if (getArea(intersection) === getArea(obj)) {
      if (map) {
        const newPathLength = pathLength(map[matchObj.id])
        const currentPathLength = pathLength(map[fullyContainedId])

        if (newPathLength >= currentPathLength) {
          fullyContainedId = matchObj.id
        }
      } else {
        fullyContainedId = matchObj.id
      }
    } else if (currentParent && bestCursorParentId !== currentParent?.id) {
      // if  current parent was fully contained before but now it became partially contained
      // we should keep the same parent, otherwise we set it to the next parent that fully contains the object.
      // we only do this if the object being dragged is
      // fully outside of the current parent
      // we only want to adhere to this rule when the possible parent based on
      // current parent position has more or equal depth than the mouse cursor parent
      if (map) {
        const bestCursorParentMap = map[bestCursorParentId]
        const currentParentIdMap = map[currentParent?.id]

        if (bestCursorParentMap && currentParentIdMap) {
          const isFullyOutsideOfCurrentParent =
            getObjectsInRect([currentParent], fullObject).length === 0

          // we should reparent to sibling objects
          // when the object is completely outside
          // the current parent
          if (isFullyOutsideOfCurrentParent) {
            fullyContainedId = bestCursorParentId
          } else {
            // if it's not fully outisde of it's current parent
            // we want to keep the same parent
            fullyContainedId = currentParent.id
          }
        }
      }
    }

    // Otherwise, calculate offset dist
    const dist = getCenterOffset(matchObj, obj)

    if (dist < bestDist) {
      bestId = matchObj.id
      bestDist = dist
    }
  }

  if (map) {
    const bestCursorParentMap = map[bestCursorParentId]
    const fullyContainedIdMap = map[fullyContainedId]

    if (bestCursorParentMap && fullyContainedIdMap) {
      const bestCursorParentPathLength = pathLength(bestCursorParentMap)
      const fullyContainedPathLength = pathLength(fullyContainedIdMap)

      if (bestCursorParentPathLength > fullyContainedPathLength) {
        return bestCursorParentId ?? (fullyContainedId || bestId)
      }
    }
  }

  return fullyContainedId || bestCursorParentId || bestId
}

export const selectObjectFromMouseCoords = (object, zoom, mouseCoords) => {
  const { mouseX, mouseY } = mouseCoords

  const [xScaled, yScaled] = scale([object.x, object.y], zoom)
  const widthScaled = scaleValue(object.width, zoom) || 0
  const heightScaled = scaleValue(object.height, zoom) || 0

  const left = applyScaleRounding(xScaled)
  const top = applyScaleRounding(yScaled)
  const right = applyScaleRounding(xScaled + widthScaled)
  const bottom = applyScaleRounding(yScaled + heightScaled)

  return mouseX >= left && mouseX <= right && mouseY >= top && mouseY <= bottom
}

export const getArea = obj => {
  return Math.round(obj.width * obj.height)
}

export const getCenterOffset = (obj1, obj2) => {
  const c1 = [obj1.x + obj1.width / 2, obj1.y + obj1.height / 2]
  const c2 = [obj2.x + obj2.width / 2, obj2.y + obj2.height / 2]

  return getDist(c1, c2)
}

export const getDist = (point1, point2) => {
  if (!point1 || !point2) {
    return NaN
  }

  const [x1, y1] = point1
  const [x2, y2] = point2

  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
}

// Returns box (x, y, width, height) of intersection
export const getIntersection = (obj1, obj2) => {
  if (getObjectsInRect([obj1], obj2).length === 0) {
    return null
  }

  const x1 = Math.max(obj1.x, obj2.x)
  const y1 = Math.max(obj1.y, obj2.y)
  const x2 = Math.min(obj1.x + obj1.width, obj2.x + obj2.width)
  const y2 = Math.min(obj1.y + obj1.height, obj2.y + obj2.height)

  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
  }
}

export const getSelectionFromRect = (objects, rect) => {
  let children = []

  if (!rect) {
    return []
  }

  objects.forEach(obj => {
    const deviceType = getDeviceType(obj.width)

    children = children.concat(
      (obj.children || [])
        .filter(child => !hiddenOnDevice(child, deviceType))
        .map(child => {
          const {
            x: childX,
            y: childY,
            width,
            height,
          } = getDeviceObject(child, deviceType)

          return {
            ...child,
            x: childX + obj.x,
            y: childY + obj.y,
            width,
            height,
          }
        })
    )
  })

  return getObjectsInRect(children, rect)
}

export const getInsertPosition = list => {
  let minY = Infinity
  let maxX = -Infinity

  if (list.length === 0) {
    return { x: 0, y: 0 }
  }

  list.forEach(screen => {
    if (screen.x + screen.width > maxX) {
      maxX = screen.x + screen.width
    }

    if (screen.y < minY) {
      minY = screen.y
    }
  })

  return {
    x: maxX + 100,
    y: minY,
  }
}

export const getAbsoluteBbox = (object, list, map, device = undefined) => {
  const path = map?.[object?.id]

  if (!path) {
    return object
  }

  const componentPath = subPath(path, 1)

  if (componentPath === path) {
    return object
  }

  const component = getObject(list, componentPath)
  const { x, y, width, height } = getDeviceObject(object, device)

  return {
    id: object.id,
    type: object.type,
    x: x + component.x,
    y: y + component.y,
    width,
    height,
  }
}

export const translate = (bbox, offsetX, offsetY, reverse = false) => {
  if (reverse) {
    offsetX = -offsetX
    offsetY = -offsetY
  }

  return {
    ...bbox,
    x: bbox.x + offsetX,
    y: bbox.y + offsetY,
  }
}

export const toRelativeCoords = (objects, parentId, screenBbox, list, map) => {
  const bbox = getBoundingBox(objects)

  let offsetX = 0
  let offsetY = 0

  let parentObj = getObject(list, map[parentId])
  parentObj = { ...parentObj, x: 0, y: 0 }

  if (getIntersection(bbox, parentObj)) {
    // Do nothing
  } else {
    let parentBbox = getIntersection(parentObj, screenBbox)
    parentBbox = parentBbox || parentObj

    const parentOffsetX = parentBbox.x - parentObj.x
    const parentOffsetY = parentBbox.y - parentObj.y

    offsetX =
      bbox.x -
      Math.max(
        0,
        Math.floor((parentBbox.width - bbox.width) / 2 + parentOffsetX)
      )

    offsetY =
      bbox.y -
      Math.max(
        0,
        Math.floor((parentBbox.height - bbox.height) / 2 + parentOffsetY)
      )
  }

  const result = deepMap(objects, obj => ({
    ...obj,
    x: obj.x - offsetX,
    y: obj.y - offsetY,
  }))

  return result
}

export const getPasteParent = (object, screenBbox, list, map) => {
  if (list.length === 0) {
    return null
  }

  const naturalParentId = getBestParent(object, list)
  const naturalParent = naturalParentId && getObject(list, map[naturalParentId])

  if (naturalParent && isFullyContained(object, screenBbox)) {
    return naturalParentId
  }

  // Otherwise return the center-most parent

  const bestParent = optimize(list, component => {
    return -getCenterOffset(component, screenBbox)
  })

  return bestParent.id
}

export const isFullyContained = (childObj, parentObj) => {
  return (
    parentObj.x <= childObj.x &&
    parentObj.y <= childObj.y &&
    parentObj.x + parentObj.width >= childObj.x + childObj.width &&
    parentObj.y + parentObj.height >= childObj.y + childObj.height
  )
}

const ALIGN_CONFIG = {
  left: ['x'],
  right: ['x', 'width'],
  top: ['y'],
  bottom: ['y', 'height'],
  'vertical-center': ['y', 'height_half'],
  'horizontal-center': ['x', 'width_half'],
}

export const alignToRect = (object, absoluteObject, rect, direction) => {
  const config = ALIGN_CONFIG[direction]

  if (!config) {
    return object
  }

  let diff = 0

  config.forEach(itm => {
    const key = itm.split('_')[0]
    let result = absoluteObject[key] - rect[key]

    if (itm.match(/_half$/)) {
      result = Math.round(result / 2)
    }

    diff += result
  })

  return {
    ...object,
    [config[0]]: object[config[0]] - diff,
  }
}
