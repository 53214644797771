import { Buffer } from 'buffer'
import { comparators } from '@adalo/constants'

import { getPrimaryField } from './includes'

export const getSearchText = object => {
  let data = ''

  Object.keys(object).forEach(k => {
    const fragment = object[k]

    if (['string', 'number'].indexOf(typeof fragment) === -1) {
      return
    }

    data += `${fragment} `
  })

  return data.trim().toLowerCase()
}

export const filterByQuery = (list, query, accessor) => {
  let values = accessor ? list.map(v => accessor(v)) : list
  query = query.trim()

  // skip single pass in empty case
  if (!query) {
    return values
  }

  const terms = query.split(/\s+/)

  for (const term of terms) {
    values = values.filter(itm => itm.includes(term))
  }

  return values
}

/**
 * Returns `window.atob` base64 string to use in column_filter query
 * @param {*} table
 * @param {*} value
 */
export const buildSearchFilter = (table, value) => {
  if (!table) return null

  const field = getPrimaryField(table)
  const type = comparators.CONTAINS

  const obj = { field, type, value }
  const opts = Buffer.from(JSON.stringify([obj])).toString('base64')

  return opts
}
