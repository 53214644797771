import React, { Component } from 'react'
import { Field } from 'redux-form'

import WrappedCheckbox from '../../../Shared/Forms/WrappedCheckbox'

export default class DefaultCheckbox extends Component {
  render() {
    return (
      <Field
        component={WrappedCheckbox}
        name="includeDefault"
        label="Leave default value"
      />
    )
  }
}
