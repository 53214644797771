import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change } from 'redux-form'

import {
  bindingTypes,
  bindingTypeNames,
  dataTypes,
  comparators,
} from '@adalo/constants'

import WrappedSelect from '../../Shared/Forms/WrappedSelect'
import { allowedBindingTypes, dataTypeAllowed } from '../../../utils/objects'
import ListExtension from './FormExtensions/List'
import DateExtension from './FormExtensions/Date'
import NumberExtension from './FormExtensions/Number'
import VisibilityExtension from './FormExtensions/Visibility'
import AdvancedTextExtension from './FormExtensions/AdvancedText'
import ImageExtension from './FormExtensions/Image'
import ParamSelector from './ParamSelector'

class BindingForm extends Component {
  bindingTypeOptions() {
    let { object, bindingTypes } = this.props

    if (!object) {
      return []
    }

    if (!bindingTypes) {
      bindingTypes = allowedBindingTypes[object.type]
    }

    return Object.keys(bindingTypeNames)
      .map(value => ({
        value,
        label: bindingTypeNames[value],
      }))
      .filter(bindingType => {
        return bindingTypes.includes(bindingType.value)
      })
  }

  handleChangeType = newType => {
    window.setTimeout(() => {
      const { binding, form, change, onChangeManually } = this.props
      const { source } = binding

      let newBinding = binding

      if (binding.bindingType === bindingTypes.VISIBILITY) {
        change(form, 'comparator', comparators.EQUAL)
        newBinding = { ...newBinding, comparator: comparators.EQUAL }
      }

      if (source && !dataTypeAllowed([newType], source.dataType)) {
        change(form, 'source', null)
        newBinding = { ...newBinding, source: null }
      }

      if (newBinding !== binding) {
        onChangeManually(newBinding)
      }
    }, 0)
  }

  renderExtension() {
    const { appId, componentId, binding, object } = this.props

    const childProps = { appId, componentId, object, binding }

    if (!binding.source) {
      return null
    }

    switch (binding.bindingType) {
      case bindingTypes.VISIBILITY:
        return <VisibilityExtension {...childProps} />

      case bindingTypes.LIST:
        return <ListExtension {...childProps} />

      case bindingTypes.SET_IMAGE:
        return <ImageExtension {...childProps} />

      case bindingTypes.SET_TEXT: {
        const result = []

        if (binding.source.dataType === dataTypes.DATE) {
          result.push(<DateExtension {...childProps} key="date" />)
        }

        if (binding.source.dataType === dataTypes.NUMBER) {
          result.push(<NumberExtension {...childProps} key="number" />)
        }

        result.push(
          <AdvancedTextExtension
            key="advanced"
            numeric={binding.source.dataType === dataTypes.NUMBER}
          />
        )

        return result
      }

      default:
        return null
    }
  }

  render() {
    const {
      appId,
      componentId,
      object,
      binding,
      label,
      bindingOptions,
      handleSubmit,
    } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Field
          className="naked small hug-text binding-type-select"
          name="bindingType"
          component={WrappedSelect}
          options={this.bindingTypeOptions()}
          onChangeValue={this.handleChangeType}
        />
        <div className="action-item-split-row">
          <div>
            {binding.bindingType === bindingTypes.VISIBILITY ? (
              <label>Will be visible if...</label>
            ) : (
              <label>Data Source</label>
            )}
            <Field
              className="small white"
              name="source"
              getLabel={() => label}
              options={bindingOptions}
              component={WrappedSelect}
            />
          </div>
        </div>
        <ParamSelector
          appId={appId}
          componentId={componentId}
          object={object}
          binding={binding}
        />
        {this.renderExtension()}
      </form>
    )
  }
}

export default connect(null, { change })(reduxForm({})(BindingForm))
