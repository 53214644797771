import { adaloBackendAxios } from 'utils/io/http/axios'

const REQUEST_APPS = 'REQUEST_USER_APPS'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${REQUEST_APPS}_FULFILLED`) {
    return {
      ...state,
      [action.meta.userId]: action.payload.data,
    }
  }

  return state
}

// Actions

export const requestUserApps = userId => ({
  type: REQUEST_APPS,
  payload: adaloBackendAxios.get(`/users/${userId}/apps`),
  meta: { userId },
})

// Selector

export const getUserApps = (state, userId) => {
  return state.admin.apps[userId] || []
}
