import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  LAYOUT_SECTION,
  resizingOptions,
  responsivePositioningOptions,
  DeviceWidth,
} from '@adalo/constants'
import { useFlags } from 'flags'
import {
  enableDeviceSpecificLayout,
  moveElement,
  resizeElement,
} from 'ducks/editor/instructions'
import { Instruction } from 'ducks/editor/instructions/applyInstructions'
import { runInstructions } from 'ducks/editor/objects'
import DeviceType from 'ducks/editor/types/DeviceType'

import getDeviceObject from 'utils/getDeviceObject'
import { EditorObject } from 'utils/responsiveTypes'
import { getContainerFromSection, isSectionElement } from 'utils/layoutSections'

import SingleNumberControl from 'components/Editor/Inspect/SingleNumberControl'
import {
  updateElementMaxWidthConstraints,
  updateElementMinWidthConstraints,
} from 'ducks/editor/instructions/updateElementWidthConstraints'
import { MAXIMUM_MAX_WIDTH, MINIMUM_MIN_WIDTH } from 'utils/defaultLayout'
import { getWidthConstraints } from 'utils/objects/widthConstraints'

const { SCALES_WITH_PARENT } = resizingOptions
const { CENTER, LEFT_AND_RIGHT } = responsivePositioningOptions

interface AdvancedLayoutProps {
  object: EditorObject
  device: DeviceType
}

export function LayoutAdvancedSettings(
  props: AdvancedLayoutProps
): JSX.Element {
  const { hasMinMaxWidth } = useFlags()
  const { object, device } = props
  const {
    width,
    minWidth,
    minWidthEnabled = true,
    maxWidth,
    maxWidthEnabled,
    height,
    x,
    y,
    id,
    type,
    initialDevice,
    responsivity,
  } = getDeviceObject(object, device)
  const isLayoutSection = type === LAYOUT_SECTION
  const dispatch = useDispatch()

  let verticalPadding = 0
  let horizontalPadding = 0

  if (isLayoutSection && object.children) {
    const container = getContainerFromSection(object)
    const { x: childX, y: childY } = getDeviceObject(container, device)
    verticalPadding = childY - y
    horizontalPadding = childX - x
  }

  const isScalesAnchorCenter =
    responsivity?.horizontalPositioning === CENTER &&
    responsivity?.horizontalScaling === SCALES_WITH_PARENT
  const isScalesAnchorLeftAndRight =
    responsivity?.horizontalPositioning === LEFT_AND_RIGHT &&
    responsivity?.horizontalScaling === SCALES_WITH_PARENT
  const displayMinMaxWidthControls =
    !!hasMinMaxWidth && (isScalesAnchorCenter || isScalesAnchorLeftAndRight)
  const displayWidthHeightControls =
    object.type === LAYOUT_SECTION || isSectionElement(object)

  const handleChange = ({
    width: newWidth = width,
    height: newHeight = height,
  }: {
    width?: number
    minWidth?: number
    maxWidth?: number
    height?: number
  }) => {
    if (newWidth < 0 || newHeight < 0) {
      return
    }

    const { constrainMinWidth, constrainMaxWidth } = getWidthConstraints(
      {
        minWidth,
        minWidthEnabled,
        maxWidth,
        maxWidthEnabled,
      } as EditorObject,
      newWidth
    )

    let adjustedWidth = newWidth
    if (constrainMinWidth === true) {
      adjustedWidth = minWidth
    } else if (constrainMaxWidth === true) {
      adjustedWidth = maxWidth
    }

    const horizontalCenter = x + width / 2
    const newX = isLayoutSection ? x : horizontalCenter - adjustedWidth / 2

    const verticalCenter = y + height / 2
    const newY = isLayoutSection ? y : verticalCenter - newHeight / 2

    const instructions: Instruction[] = []

    if (initialDevice && initialDevice !== device && !object[device]) {
      instructions.push(enableDeviceSpecificLayout(id, device))
    }

    instructions.push(resizeElement(id, adjustedWidth, newHeight))
    instructions.push(moveElement(id, newX, newY))

    dispatch(runInstructions(instructions))
  }

  const handleMinChange = ({
    minWidth: newMinWidth = minWidth,
    minWidthEnabled: newMinWidthEnabled = minWidthEnabled,
  }) => {
    dispatch(
      runInstructions([
        updateElementMinWidthConstraints(id, newMinWidth, newMinWidthEnabled),
      ])
    )
  }

  const handleMaxChange = ({
    maxWidth: newMaxWidth = maxWidth,
    maxWidthEnabled: newMaxWidthEnabled = maxWidthEnabled,
  }) => {
    dispatch(
      runInstructions([
        updateElementMaxWidthConstraints(id, newMaxWidth, newMaxWidthEnabled),
      ])
    )
  }

  useEffect(() => {
    if (
      displayMinMaxWidthControls &&
      minWidth === undefined &&
      maxWidth === undefined
    ) {
      dispatch(
        runInstructions([
          updateElementMinWidthConstraints(id, 1, true),
          updateElementMaxWidthConstraints(id, DeviceWidth.DESKTOP_MAX_WIDTH, false), // prettier-ignore
        ])
      )
    }
  }, [displayMinMaxWidthControls, id, width, minWidth, maxWidth])

  const handlePaddingChange = ({
    horizontalPadding: newHorizontalPadding = horizontalPadding,
    verticalPadding: newVerticalPadding = verticalPadding,
  }: {
    horizontalPadding?: number
    verticalPadding?: number
  }) => {
    if (
      newHorizontalPadding < 0 ||
      newVerticalPadding < 0 ||
      newHorizontalPadding > width / 2 ||
      newVerticalPadding > height / 2
    ) {
      return
    }

    const container = getContainerFromSection(object)
    const { id: childId, initialDevice: childInitialDevice } = container

    const newX = x + newHorizontalPadding
    const newY = y + newVerticalPadding
    const newWidth = width - 2 * newHorizontalPadding
    const newHeight = height - 2 * newVerticalPadding

    const { constrainMinWidth, constrainMaxWidth } = getWidthConstraints(
      getDeviceObject(container, device),
      newWidth
    )

    const { minWidth: containerMinWidth, maxWidth: containerMaxWidth } =
      getDeviceObject(container, device)

    let adjustedWidth = newWidth
    let adjustedX = newX
    if (constrainMinWidth) {
      adjustedWidth = containerMinWidth
      adjustedX = x + (width - containerMinWidth) / 2
    } else if (constrainMaxWidth) {
      adjustedWidth = containerMaxWidth
      adjustedX = x + (width - containerMaxWidth) / 2
    }

    const instructions: Instruction[] = []

    if (
      childInitialDevice &&
      childInitialDevice !== device &&
      !container[device]
    ) {
      instructions.push(enableDeviceSpecificLayout(childId, device))
    }

    instructions.push(resizeElement(childId, adjustedWidth, newHeight))
    instructions.push(moveElement(childId, adjustedX, newY))

    dispatch(runInstructions(instructions))
  }

  return (
    <div className="form-inspect-field-wrapper">
      {displayWidthHeightControls && (
        <div className="advanced-styles-row">
          <SingleNumberControl
            title="Width"
            name="width"
            minValue={
              typeof minWidth === 'number' && minWidthEnabled === true
                ? minWidth
                : Number.NEGATIVE_INFINITY
            }
            maxValue={
              typeof maxWidth === 'number' && maxWidthEnabled === true
                ? maxWidth
                : Number.POSITIVE_INFINITY
            }
            value={Math.round(width)}
            onChange={handleChange}
            submitViaBlurOrEnter
          />
          <SingleNumberControl
            title="Height"
            name="height"
            value={Math.round(height)}
            onChange={handleChange}
            submitViaBlurOrEnter
          />
        </div>
      )}
      {isLayoutSection && (
        <div className="advanced-styles-row">
          <SingleNumberControl
            title="Left & Right Space"
            name="horizontalPadding"
            value={Math.round(horizontalPadding)}
            onChange={handlePaddingChange}
            submitViaBlurOrEnter
          />
          <SingleNumberControl
            title="Top & Bottom Space"
            name="verticalPadding"
            value={Math.round(verticalPadding)}
            onChange={handlePaddingChange}
            submitViaBlurOrEnter
          />
        </div>
      )}
      {displayMinMaxWidthControls && (
        <div className="advanced-styles-row">
          <SingleNumberControl
            title="Min Width"
            name="minWidth"
            value={minWidth}
            minValue={MINIMUM_MIN_WIDTH}
            maxValue={
              typeof maxWidth === 'number' && maxWidthEnabled === true
                ? maxWidth - 1
                : MAXIMUM_MAX_WIDTH - 1
            }
            onChange={handleMinChange}
            submitViaBlurOrEnter
          />
          <SingleNumberControl
            title="Max Width"
            name="maxWidth"
            disabled={!maxWidthEnabled}
            value={maxWidth}
            minValue={
              typeof minWidth === 'number' && minWidthEnabled === true
                ? minWidth + 1
                : MINIMUM_MIN_WIDTH + 1
            }
            maxValue={MAXIMUM_MAX_WIDTH}
            onChange={handleMaxChange}
            submitViaBlurOrEnter
            toggleValue={maxWidthEnabled}
            toggleDisabledPlaceholder="No Max"
            onToggleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (
                e.target.checked === true &&
                typeof maxWidth === 'number' &&
                typeof minWidth === 'number' &&
                minWidthEnabled === true &&
                maxWidth <= minWidth
              ) {
                handleMaxChange({
                  maxWidthEnabled: true,
                  maxWidth: minWidth + 1,
                })
              } else {
                handleMaxChange({ maxWidthEnabled: e.target.checked })
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
