import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { isEqual as _isEqual } from 'lodash'

import { relativeDate } from 'utils/datetime'
import {
  requestBuilds,
  getBuilds,
  restartBuild,
  restartFailedBuilds,
} from 'ducks/admin/builds'
import Page from 'components/Shared/Page'
import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'

const sizeOf = bytes => {
  if (!bytes) {
    return null
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024))

  return `${(bytes / 1024 ** e).toFixed(2)} ${' KMGTP'.charAt(e)}B`
}

const Build = ({ build }) => {
  const [restarting, setRestarting] = useState(false)
  const dispatch = useDispatch()

  const handleRestart = async () => {
    try {
      setRestarting(true)
      await dispatch(restartBuild(build.id))
    } catch (err) {
      console.error(`Could not restart: ${err}`)
    } finally {
      setRestarting(false)
    }
  }

  const copyBuildId = () => {
    navigator.clipboard.writeText(build.id)
  }

  const appLink =
    build.status === 'failed'
      ? `/apps/${build.AppId}/screens/builds/${build.id}`
      : `/apps/${build.AppId}/publish`

  return (
    <div data-build-id={build.id} className="admin-user-item">
      <p style={{ flexBasis: '10px' }}>
        <a onClick={copyBuildId}>
          <span role="img" aria-label="Build Id">
            🆔
          </span>
        </a>
      </p>
      <h5 style={{ flexBasis: '200px' }}>
        <Link to={appLink}>{build.App?.name}</Link>
      </h5>
      <p style={{ flexBasis: '10px' }}>
        {build.target === 'android' ? (
          <span role="img" aria-label="Android">
            🤖
          </span>
        ) : (
          <span role="img" aria-label="iOS">
            🍎
          </span>
        )}
      </p>
      <p style={{ flexBasis: '100px' }}>
        {build.version} ({build.versionCode})
      </p>
      <p style={{ flexBasis: '10px' }}>
        {build.codemagicBuildId ? (
          <a
            href={`https://codemagic.io/app/63fe7708632b94026b657096/build/${build.codemagicBuildId}`}
            target="_blank"
            rel="noreferrer"
          >
            <span role="img" aria-label="CodeMagic">
              🎩
            </span>
          </a>
        ) : (
          <span role="img" aria-label="CodeMagic">
            -
          </span>
        )}
      </p>
      <p style={{ flexBasis: '100px' }}>{relativeDate(build.updatedAt)}</p>
      <p style={{ flexBasis: '60px' }}>{sizeOf(build.jsonBundleSize)}</p>
      <p style={{ flexBasis: '60px' }} className="admin-build-status-wrapper">
        <span
          className={classNames(
            'admin-build-status',
            `admin-build-${build.status}`
          )}
        >
          {build.status}
        </span>
        {build.status === 'failed' && (
          <a
            className="deemphasize"
            style={{
              fontSize: 25,
            }}
            onClick={handleRestart}
          >
            {restarting ? '☑' : '↺'}
          </a>
        )}
      </p>
      <p style={{ flexBasis: '100px', wordBreak: 'break-word' }}>
        {build.status === 'failed' &&
          build.iosErrorSummary &&
          build.iosErrorSummary.substring(0, 100)}
      </p>
    </div>
  )
}

const Builds = () => {
  const dispatch = useDispatch()
  const target =
    (window.location.hash && window.location.hash.substring(1)) || 'all'
  const [hoursBack, setHoursBack] = useState(24)

  useEffect(() => {
    dispatch(requestBuilds())
  }, [])

  const builds = useSelector(state => getBuilds(state), _isEqual)

  const triggerRestart = () => {
    if (!hoursBack) {
      return
    }

    const timeFrom = new Date(
      Date.now() - hoursBack * 60 * 60 * 1000
    ).toISOString()

    dispatch(restartFailedBuilds(timeFrom, target === 'all' ? null : target))
  }

  const filteredBuilds =
    target && target !== 'all'
      ? builds.filter(b => b.target === target)
      : builds

  if (builds.length === 0) {
    return (
      <EmptyState greedy>
        <Loading large />
      </EmptyState>
    )
  }

  return (
    <Page className="admin-users-page" title="Builds">
      <p>
        <Link to="/admin/users">← Back to Users</Link>
      </p>
      <h1>Builds</h1>
      <div>
        <div className="admin-build-options">
          <span>Filter by platform:</span>
          <a href="#">All</a>
          <a href="#android">
            <span role="img" aria-label="Android">
              🤖
            </span>
          </a>
          <a href="#ios">
            <span role="img" aria-label="iOS">
              🍎
            </span>
          </a>
        </div>
        <span>
          <a onClick={triggerRestart}>Restart</a> all unique builds that have
          failed in the last{' '}
          <input
            type="number"
            min={1}
            max={48}
            value={hoursBack}
            onChange={e => setHoursBack(e.target.value)}
          />{' '}
          hours
        </span>
        {filteredBuilds.map(build => (
          <Build key={build.id} build={build} />
        ))}
      </div>
    </Page>
  )
}

export default Builds
