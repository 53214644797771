import { FeatureTemplate } from 'ducks/featureTemplates'

type DetailsHeaderProps = {
  feature: FeatureTemplate
  button: JSX.Element
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({ feature, button }) => {
  const { name, icon, summary } = feature

  return (
    <div className="feature-item__details-header">
      <div className="feature-item__details-header-info">
        <img src={icon as string} alt={`${name} logo`} />
        <h2>
          {name}
          <span>{summary}</span>
        </h2>
      </div>
      <div className="feature-item__details-header-price">{button}</div>
    </div>
  )
}

export default DetailsHeader
