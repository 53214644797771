import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Shared/Button'

import MobilePublishStep from './MobilePublishStep'

const MobilePublishSummary = props => {
  const {
    handleCredentialValidation,
    handleAppSettingsValidation,
    provider,
    onUpdateSettings,
  } = props

  const getProviderName = () => {
    switch (provider) {
      case 'ios':
        return 'Apple'
      case 'android':
        return 'Android'
      default:
        return null
    }
  }

  return (
    <div className="mobile-publish-summary">
      <div className="mobile-publish-steps">
        <MobilePublishStep
          step={`${getProviderName()} Credentials`}
          validation={handleCredentialValidation}
        />
        <MobilePublishStep
          step="App Settings"
          validation={handleAppSettingsValidation}
        />
      </div>
      <Button outlined onClick={onUpdateSettings}>
        Update Settings
      </Button>
    </div>
  )
}

MobilePublishSummary.propTypes = {
  provider: PropTypes.oneOf(['ios', 'android']).isRequired,
  onUpdateSettings: PropTypes.func.isRequired,
  handleCredentialValidation: PropTypes.func.isRequired,
  handleAppSettingsValidation: PropTypes.func.isRequired,
}

export default MobilePublishSummary
