import classNames from 'classnames'
import Icon from 'components/Shared/Icon'

type FilterOptionProps = {
  label: string
  icon: string
  selected: boolean
  onClick: () => void
}

const FilterOption = ({
  label,
  icon,
  selected,
  onClick,
}: FilterOptionProps): JSX.Element => {
  const className = classNames('feature-templates__filter__option', {
    selected,
  })

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={className} onClick={onClick}>
      <Icon type={icon} color={selected ? 'teal' : null} />
      <span className="feature-templates__filter__label">{label}</span>
    </div>
  )
}

export default FilterOption
