import { ReactElement } from 'react'
import Button from 'components/Shared/Button'
import { useDisableRapidClick } from 'utils/buttons'
import useDebugMode from 'hooks/useDebugMode'

import BuildList from '../BuildList'

import './WebPublish.scss'

interface Props {
  appId: string
  publishingEnabled: boolean
  onPublishClick: () => void
  buildList: Record<string, unknown>[]
  loadingBuilds: boolean
  buildDisabled: boolean
  appPublishedOnUrl: string
}

export default function WebPublish({
  appId,
  publishingEnabled,
  onPublishClick,
  buildList,
  loadingBuilds,
  buildDisabled,
  appPublishedOnUrl,
}: Props): ReactElement {
  const debug = useDebugMode()
  const rapidClick = useDisableRapidClick()

  const publishDisabled = (buildDisabled || !publishingEnabled) && !debug

  const onClick = () => {
    rapidClick.onClick()
    if (publishingEnabled) {
      onPublishClick()
    }
  }

  return (
    <div className="web-publish-settings">
      <h2> Publishing</h2>

      <Button
        fluid
        outlined
        green
        icon="plus-small"
        onClick={onClick}
        disabled={rapidClick.disabled || publishDisabled}
      >
        Publish latest changes
      </Button>
      <BuildList
        appId={appId}
        list={buildList}
        loading={loadingBuilds}
        appUrl={appPublishedOnUrl}
      />
    </div>
  )
}
