import { FORM } from '@adalo/constants'

import { EditorObject } from 'utils/responsiveTypes'
import { calculateLayout } from 'utils/forms'

import LayoutContext from './layout/LayoutContext'
import LayoutObject from './layout/LayoutObject'
import ObjectNode from './ObjectNode'

export default class FormNode extends ObjectNode<typeof FORM> {
  private readonly form: EditorObject

  constructor(id: string, form: EditorObject) {
    super(id, FORM)

    this.form = form
  }

  public override layout(context: LayoutContext): LayoutObject<typeof FORM> {
    const objectLayout = super.layout(context)

    const { width, left, right } = objectLayout

    const { height } = calculateLayout({ ...this.form, width })

    return {
      id: objectLayout.id,
      type: objectLayout.type,
      x: objectLayout.x,
      y: objectLayout.y,
      children: objectLayout.children,
      height,
      width,
      left,
      right,
    }
  }
}
