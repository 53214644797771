import { dataTypes } from '@adalo/constants'

export const getFieldIcon = type => {
  if (typeof type === 'string') {
    switch (type) {
      case dataTypes.BOOLEAN:
        return 'check'
      case dataTypes.DATE:
      case 'datetime':
        return 'date-and-time-range'
      case dataTypes.DATE_ONLY:
        return 'date-range'
      case dataTypes.FILE:
        return 'attach-file'
      case dataTypes.IMAGE:
        return 'photo'
      case dataTypes.NUMBER:
        return 'filter-7'
      case dataTypes.TEXT:
      case 'string':
        return 'text-format'
      case dataTypes.PASSWORD:
        return 'lock'
      case dataTypes.LOCATION:
        return 'location'
      case `${dataTypes.LOCATION}-disabled`:
        return 'location-disabled'
      case dataTypes.OBJECT:
      default:
        return null
    }
  }

  if (typeof type === 'object') {
    switch (type.type) {
      case 'manyToMany':
      case 'manyToManyReverse':
      case 'hasMany':
      case 'hasManyRelation':
        return 'relationship-many'
      case 'belongsTo':
      default:
        return 'relationship-single'
    }
  }

  return null
}

const getIconByDataType = dataType => {
  switch (dataType) {
    case dataTypes.TEXT:
      return 'text-format'
    case dataTypes.NUMBER:
      return 'filter-7'
    case dataTypes.BOOLEAN:
      return 'checkbox'
    case dataTypes.DATE:
      return 'date-and-time-range'
    case dataTypes.DATE_ONLY:
      return 'date-range'
    case dataTypes.IMAGE:
      return 'photo'
    case dataTypes.FILE:
      return 'attach-file'
    case dataTypes.LOCATION:
      return 'location'
    default:
      return null
  }
}

export const getIcon = (name, label) => {
  let icon = getIconByDataType(name)

  if (label) {
    switch (label) {
      case 'Collection':
      case 'manyToMany':
      case 'manyToManyReverse':
      case 'hasMany':
        icon = 'relationship-many'

        break
      case 'Reference':
      case 'belongsTo':
      case 'Relationship':
        icon = 'relationship-single'

        break
      default:
        break
    }
  }

  return icon
}

export const getScreenIcon = (
  screenId,
  authenticationScreenId,
  homeScreenId
) => {
  switch (screenId) {
    case authenticationScreenId:
      return 'user-login'
    case homeScreenId:
      return 'home'
    default:
      return 'normal-screen'
  }
}
