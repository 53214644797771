import React, { Component } from 'react'
import { COMPONENT, LAYOUT_SECTION } from '@adalo/constants'
import classnames from 'classnames'
import { connect } from 'react-redux'

import { getParentComponent, selectObject } from 'ducks/editor/objects'
import { scale, scaleValue } from 'utils/zoom'
import { CONTAINER_TYPES } from 'utils/positioning'
import { getHoverSelection, getSelection } from 'ducks/editor/selection'
import {
  isContainerSectionElement,
  isLayoutHelperSectionElement,
} from 'utils/layoutSections'

class Box extends Component {
  render() {
    const {
      zoom,
      isHover,
      isChildObject,
      magicLayout,
      parentSelection,
      objectIsSelected,
      hoveredObject,
      object,
    } = this.props

    if (!object) {
      return null
    }

    const [xScaled, yScaled] = scale([object.x, object.y], zoom)
    const widthScaled = scaleValue(object.width, zoom) || 0
    const heightScaled = scaleValue(object.height, zoom) || 0

    let left = Math.round(xScaled + 0.5) - 0.5
    let top = Math.round(yScaled + 0.5) - 0.5
    let right = Math.round(xScaled + widthScaled + 0.5) - 0.5
    let bottom = Math.round(yScaled + heightScaled + 0.5) - 0.5

    if (isHover && object.type === COMPONENT) {
      left = left - 3.5
      top = top - 3.5
      right = right + 3.5
      bottom = bottom + 3.5
    }

    const width = right - left
    const height = bottom - top
    const { isNaN } = Number

    if (objectIsSelected) {
      return null
    }

    if (isNaN(left) || isNaN(top) || isNaN(width) || isNaN(height)) {
      return null
    }

    if (isLayoutHelperSectionElement(object)) {
      return null
    }

    if (isContainerSectionElement(object)) {
      const isContainerHover = hoveredObject?.id === object.id
      const shouldShowThickBorder =
        (isHover && object.type === COMPONENT) || isContainerHover

      return (
        <g className="hover-selection" transform={`translate(${left}, ${top})`}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            stroke="#00A996"
            strokeWidth="1"
            strokeOpacity={isContainerHover ? 1 : 0.5}
            strokeDasharray="5,2"
            className={classnames('hover-selection-box', {
              thick: shouldShowThickBorder,
              teal: magicLayout,
            })}
          />
        </g>
      )
    }

    if (object.type === LAYOUT_SECTION) {
      const isLayoutSectionHover =
        hoveredObject?.id === object.id ||
        isLayoutHelperSectionElement(hoveredObject)

      const shouldShowThickBorder =
        (isHover && object.type === COMPONENT) || isLayoutSectionHover

      return (
        <g className="hover-selection" transform={`translate(${left}, ${top})`}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            strokeOpacity={isLayoutSectionHover ? 1 : 0.5}
            className={classnames('hover-selection-box', {
              thick: shouldShowThickBorder,
              teal: magicLayout,
              'child-object-bounding-box': magicLayout && isChildObject,
              'possible-parent-bounding-box': parentSelection,
            })}
          />
        </g>
      )
    }

    return (
      <g className="hover-selection" transform={`translate(${left}, ${top})`}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          className={classnames('hover-selection-box', {
            thick: isHover && object.type === COMPONENT,
            teal: magicLayout,
            'child-object-bounding-box': magicLayout && isChildObject,
            'possible-parent-bounding-box': parentSelection,
          })}
        />
      </g>
    )
  }
}

const mapStateToProps = (state, { object }) => {
  const parent = getParentComponent(state, object.id)
  const objectIsSelected = getSelection(state).indexOf(object.id) !== -1

  const hoverSelection = getHoverSelection(state)
  const hoveredObject = selectObject(state, hoverSelection[0])

  return {
    isChildObject: CONTAINER_TYPES.includes(parent?.type),
    hoveredObject,
    objectIsSelected,
  }
}

export default connect(mapStateToProps)(Box)
