import React, { Component } from 'react'
import classNames from 'classnames'

import TextControl from './TextControl'

export default class SingleNumberControl extends Component {
  render() {
    let {
      name,
      value,
      defaultValue,
      onChange,
      onFocus,
      onBlur,
      label,
      disabled,
      placeholder,
      allowEmpty,
      minValue,
      maxValue,
      suffix,
      gray,
      title,
      toggleValue,
      toggleDisabledPlaceholder,
      onToggleChange,
      submitViaBlurOrEnter,
    } = this.props

    if (value === undefined) {
      value = defaultValue
    }

    return (
      <div
        className={classNames('single-number-control', {
          'with-title': Boolean(title),
        })}
      >
        {title ? (
          <label className="single-number-control__title">{title}</label>
        ) : null}
        <TextControl
          gray={gray}
          allowEmpty={allowEmpty}
          disabled={disabled}
          type="number"
          name={name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          minValue={minValue}
          maxValue={maxValue}
          suffix={suffix}
          toggleValue={toggleValue}
          toggleDisabledPlaceholder={toggleDisabledPlaceholder}
          onToggleChange={onToggleChange}
          submitViaBlurOrEnter={submitViaBlurOrEnter}
        />
        {label ? (
          <label className="single-number-control-label">{label}</label>
        ) : null}
      </div>
    )
  }
}
