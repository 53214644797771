import './ProgressBar.css'

type Props = {
  // POSITION is a number from 0 - 1
  position: number
}

const ProgressBar: React.FC<Props> = props => {
  const { position } = props

  const styles = {
    width: `${(position * 100).toFixed(0)}%`,
  }

  return (
    <div className="progress-bar">
      <div className="progress-bar-indicator" style={styles} />
    </div>
  )
}

export default ProgressBar
