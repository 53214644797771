import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import Button from '../Shared/Button'
import WizardFooter from './WizardFooter'
import { FORM_NAME } from './shared'
import PlatformToggle from './PlatformToggle'

const WizardFormPlatformPage = props => {
  const { handleSubmit, handlePreviousPage, primaryPlatform } = props

  return (
    <>
      <form
        id="new-app-platform-form"
        className="new-app-form"
        onSubmit={handleSubmit}
      >
        <div className="new-app-platform-label">
          Where do you want your users to access your app?
        </div>
        <Field name="primaryPlatform" component={PlatformToggle} />
      </form>
      <WizardFooter>
        {handlePreviousPage ? (
          <Button className="previous" onClick={handlePreviousPage}>
            Back
          </Button>
        ) : null}
        <Button
          className="next align-right"
          type="submit"
          disabled={!primaryPlatform}
          form="new-app-platform-form"
          data-adalo-id="new-app-platform-form-next"
        >
          Next
        </Button>
      </WizardFooter>
    </>
  )
}

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME)

  return {
    primaryPlatform: selector(state, 'primaryPlatform'),
  }
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(mapStateToProps)(WizardFormPlatformPage))
