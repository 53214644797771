import {
  getLineHeight,
  getTextLines,
  measureTextHeight,
  measureTextWidth,
} from './textMetrics'

const TRUNCATION_SUFFIX = '...'

export interface TextCalculatedProperties {
  lines: string[]
  height: number
}
export interface TextParameters {
  text: string
  fontSize: number
  evaluatedFontFamily: string
  fontWeight: number
  fontStyle: string
  multiline: boolean // single line
  autoWidth: boolean // ignore width property
  maxLength: number // cut text off
  width: number
}

export const wrapMultilineText = (textParams: TextParameters): string[] => {
  const { text } = textParams

  return getTextLines(text, textParams)
}

export const calculateMultilineTextHeight = (
  textParams: TextParameters
): number => {
  const { text, fontSize } = textParams

  const lineHeight = getLineHeight(fontSize)

  const measuredHeight = measureTextHeight(text, textParams)

  const height = lineHeight > measuredHeight ? lineHeight : measuredHeight

  return height
}

export const truncateSingleLineText = (
  parameters: TextParameters
): string[] => {
  const { width } = parameters
  const lines = wrapMultilineText(parameters)

  let displayText = lines[0] ?? ''
  const newWidth = measureTextWidth(displayText, parameters)

  let truncated = false
  if (lines.length > 1) {
    // Multiline text is always truncated
    truncated = true
  }

  if (newWidth > width) {
    truncated = true
  }

  if (!truncated) {
    // No ellipses required. Return exact text
    return [displayText]
  }

  const suffix = TRUNCATION_SUFFIX
  const dotsWidth = measureTextWidth(suffix, parameters)

  if (dotsWidth + newWidth > width && displayText) {
    const textSeparatedBySpaces = displayText.split(' ')
    textSeparatedBySpaces.pop()
    displayText = textSeparatedBySpaces.join(' ')
  }

  return [`${displayText}${suffix}`]
}

export const calculateResponsiveTextLines = (
  parameters: TextParameters
): string[] => {
  const { autoWidth, multiline, maxLength, text } = parameters
  if (autoWidth && !multiline) {
    return [text]
  }

  let displayText = text
  if (maxLength) {
    displayText = displayText.slice(0, maxLength)
  }

  const textParams: TextParameters = {
    ...parameters,
    text: displayText,
  }

  if (multiline) {
    return wrapMultilineText(textParams)
  }

  return truncateSingleLineText(textParams)
}

export const calculateResponsiveTextHeight = (
  parameters: TextParameters
): number => {
  const { multiline, fontSize, maxLength, text } = parameters
  const lineHeight = getLineHeight(fontSize)

  if (!multiline) {
    return lineHeight
  }

  let displayText = text
  if (maxLength) {
    displayText = displayText.slice(0, maxLength)
  }

  const textParams: TextParameters = {
    fontSize,
    evaluatedFontFamily: parameters.evaluatedFontFamily,
    fontWeight: parameters.fontWeight,
    fontStyle: parameters.fontStyle,
    multiline,
    autoWidth: parameters.autoWidth,
    maxLength,
    width: parameters.width,
    text: displayText,
  }

  return calculateMultilineTextHeight(textParams)
}
