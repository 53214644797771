import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { getCurrentUser, updateCurrentUser } from 'ducks/users'
import { updateApp } from 'ducks/apps'

import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import Modal from 'components/Shared/Modal'

import Form from './Form'

import './styles.scss'

export type FormValues = {
  appUsage: string | undefined
  withHelp: string | undefined
}

const AppQuestionsModal = (): JSX.Element => {
  const history = useHistory()
  const params = useParams<{ appId: string }>()
  const dispatch = useDispatch()

  const [isWebApp, setIsWebApp] = useState(false)
  const [disableSnooze, setDisableSnooze] = useState(false)
  const [loading, setLoading] = useState(false)
  const [appUsage, setAppUsage] = useState('')
  const [withHelp, setWithHelp] = useState('')

  const currentUser = useSelector(getCurrentUser)

  useEffect(() => {
    if (history.location.pathname.includes('existing-app-questions')) {
      setIsWebApp(true)
    }
  }, [history])

  useEffect(() => {
    const appQuestionsSnooze = localStorage.getItem('appQuestionsSnooze')

    if (appQuestionsSnooze) {
      const { amount } = JSON.parse(appQuestionsSnooze) as {
        amount: number
      }

      if (amount >= 3) {
        setDisableSnooze(true)
      }
    }
  }, [disableSnooze, localStorage])

  const disableButton = useMemo(() => {
    if (!appUsage) {
      return true
    }

    if (
      appUsage !== 'test' &&
      !withHelp &&
      currentUser?.persona !== 'Freelancer/Agency'
    ) {
      return true
    }

    return false
  }, [appUsage, withHelp])

  const handleModalSnooze = () => {
    const appQuestionsSnooze = localStorage.getItem('appQuestionsSnooze')

    if (appQuestionsSnooze) {
      const { amount } = JSON.parse(appQuestionsSnooze) as {
        date: string
        amount: number
      }

      localStorage.setItem(
        'appQuestionsSnooze',
        JSON.stringify({ date: moment().format(), amount: amount + 1 })
      )
    } else {
      localStorage.setItem(
        'appQuestionsSnooze',
        JSON.stringify({ date: moment().format(), amount: 1 })
      )
    }

    return history.push(`/apps/${params?.appId}/screens`)
  }

  const handleSubmit = (values: FormValues) => {
    setLoading(true)

    if (values && typeof values === 'object') {
      const { withHelp: withHelpValue, appUsage: appUsageValue } = values

      const userId = currentUser?.id

      try {
        if (appUsageValue) {
          const properties = {
            type: appUsageValue,
          } as {
            [key: string]: string | boolean
          }

          if (withHelpValue === 'Yes') {
            properties['builtWithFreelancer'] = true
          }

          dispatch(updateApp(params?.appId, properties))
        }

        if (userId && withHelpValue) {
          if (withHelpValue === 'freelancer') {
            dispatch(
              updateCurrentUser(userId, { persona: 'Freelancer/Agency' })
            )
          } else {
            dispatch(updateCurrentUser(userId, { client: withHelpValue }))
          }
        }

        setLoading(false)

        return history.push(`/apps/${params?.appId}/screens`)
      } catch (error) {
        console.error('FAILED TO SUBMIT USER QUESTIONS FORM', error)
        setLoading(false)
      }
    }
  }

  return (
    <Modal className="app-questions-modal" size="sm">
      <Modal.Header>
        {isWebApp ? (
          <h1>
            <Icon type="hand-shaking" />
            Tell us a bit more about your app!
          </h1>
        ) : (
          <h1>
            Your Build is Queued!
            <Icon type="clap" />
          </h1>
        )}
      </Modal.Header>
      <Modal.Content>
        <div className="app-questions-modal__form">
          {isWebApp ? (
            <p>
              To better understand how you want to use Adalo, we have a few
              quick questions.
            </p>
          ) : (
            <p>
              While you're waiting for your build to finish, we have a few quick
              questions.
            </p>
          )}
          <Form
            onSubmit={handleSubmit}
            setAppUsage={setAppUsage}
            appUsage={appUsage}
            setWithHelp={setWithHelp}
            currentUser={currentUser}
          />
        </div>
      </Modal.Content>
      <Modal.Actions
        leftButtons={
          isWebApp &&
          !disableSnooze && [
            <Button text onClick={handleModalSnooze}>
              Ask me later
            </Button>,
          ]
        }
      >
        <Button
          green
          onClick={() => handleSubmit({ appUsage, withHelp })}
          loading={loading}
          disabled={disableButton}
        >
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AppQuestionsModal
