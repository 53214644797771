import React from 'react'
import classNames from 'classnames'

import './Alert.scss'

type AlertProps = {
  color:
    | 'dark-pink'
    | 'purple'
    | 'yellow'
    | 'orange'
    | 'green'
    | 'teal'
    | 'grey'
  // eslint-disable-next-line react/require-default-props
  className?: string
}

const Alert: React.FC<AlertProps> = ({
  className,
  color,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(
        'adalo-alert',
        {
          [`adalo-alert-${color}`]: color,
        },
        className
      )}
    >
      {children}
    </div>
  )
}

export default Alert
