import React from 'react'
import { inflect } from 'inflection'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

import AdaloToolTip from 'components/Shared/Tooltip'
import EmptyState from 'components/Shared/EmptyState'
import Icon from 'components/Shared/Icon'

import {
  getLimit,
  getDaysLeftInCycle,
  getCycleStartAndEndDates,
} from 'utils/billing.ts'

import { LegendIcon } from '../AppActionsOverTimeChart/Legend'

import '../AppActionsOverTimeChart/AppActionsCharts.scss'

const getBarMax = (planLimit, totalActions) => {
  if (planLimit && totalActions < planLimit) {
    return planLimit
  } else {
    if (totalActions === 0) {
      return 1
    }

    return totalActions
  }
}

const colors = ['#004F3D', '#007B69', '#009885', '#7ACBC1', '#B0DED9']

const AppActionsByAppChart = props => {
  const { organization, pricingPlans, actionsByApp, currentCycle } = props

  if (!organization || !currentCycle) {
    return null
  }

  const { planType } = organization

  const { cycleStartDate, cycleEndDate } =
    getCycleStartAndEndDates(organization)

  const daysLeftInCycle = getDaysLeftInCycle(organization)
  const totalActions = currentCycle.totalCycleActionCount
  const planLimit = getLimit(planType, pricingPlans, 'actions')
  const isOnLegacyPlan = planLimit === undefined
  const barMax = getBarMax(planLimit, totalActions)

  const data = { unpublished: 0, other: 0 }

  const displayedApps = []

  const otherAppsObject = {
    id: 'other',
    name: 'Other Apps',
    actionCount: 0,
    published: true,
  }

  let unpublishedApp = {}

  actionsByApp.forEach(app => {
    data[app.id] = app.actionCount

    if (app.id === 'unpublished') {
      unpublishedApp = app
      unpublishedApp.name = 'All Test Apps'
    } else if (app.id === 'other') {
      otherAppsObject.actionCount = app.actionCount
    } else {
      displayedApps.push(app)
    }
  })

  const barChartApps = [...displayedApps]

  if (otherAppsObject.actionCount > 0) {
    barChartApps.push(otherAppsObject)
  }

  if (unpublishedApp.actionCount > 0) {
    barChartApps.push(unpublishedApp)
  }

  if (totalActions < barMax || totalActions === 0) {
    if (totalActions === 0 && barMax === 0) {
      data.filler = 1
    } else {
      data.filler = barMax - totalActions
    }
  }

  let colorIndex = 0

  const legendPayload = barChartApps
    .map(app => {
      if (app.actionCount > 0) {
        let color

        if (app.id === 'unpublished') {
          color = '#C8C8C8'
        } else if (app.id === 'other') {
          if (isOnLegacyPlan || data.unpublished === 0) {
            color = '#C8C8C8'
          } else {
            color = '#9E9E9E'
          }
        } else {
          color = colors[colorIndex % colors.length]
        }

        colorIndex += 1

        return {
          label: app.name,
          value: app.actionCount,
          color,
          published: isOnLegacyPlan ? false : app.published,
        }
      }
    })
    .filter(data => data !== undefined)

  const tooltipData = {}

  const BarTooltip = ({ active, payload }) => {
    if (active && payload?.length > 0) {
      let payloadData
      const { appName, appId, actionCount } = tooltipData

      for (const data of payload) {
        if (data.dataKey === appId) {
          payloadData = data

          break
        }
      }

      if (!payloadData) {
        return null
      }

      const { fill: legendColor } = payloadData

      return (
        <div className="billing-usage-chart-tooltip">
          <div className="billing-usage-chart-tooltip-label">
            <LegendIcon
              color={legendColor}
              borderColor={legendColor}
              shape="circle"
            />
            <h5>{appName}</h5>
          </div>
          <p className="billing-usage-chart-tooltip-value">
            {actionCount.toLocaleString()}
          </p>
        </div>
      )
    }

    return null
  }

  return (
    <div id="actions-by-app" className="app-actions-by-app-chart-container">
      <div className="billing-usage-chart-header">
        <h3 className="billing-usage-chart-header-text">
          App Actions By App
          <span className="actions-by-app-tooltip">
            <AdaloToolTip
              tooltip={
                <p>
                  App Actions are the actions taken only by the end users of
                  your app (actions taken in the Previewer don't count). This
                  section helps you see which apps are using the most actions.{' '}
                  <a
                    href="https://help.adalo.com/action-basics/app-actions-dashboard"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              }
              placement="bottom-start"
              hideArrow
            >
              <Icon type="help" />
            </AdaloToolTip>
          </span>
        </h3>
        <CurrentCycleIndicator
          isOnLegacyPlan={isOnLegacyPlan}
          cycleStartDate={cycleStartDate}
          cycleEndDate={cycleEndDate}
        />
      </div>
      {totalActions > 0 && (
        <>
          <div className="app-actions-by-app-chart-legend legend-container">
            <ul className="app-action-by-app-legend-ul">
              {legendPayload.map(({ label, value, color, published }) => (
                <LegendItem
                  key={label}
                  label={label}
                  value={value}
                  color={color}
                  published={published}
                />
              ))}
            </ul>
          </div>
          <div className="days-left-in-cycle-label-wrapper">
            <span className="days-left-in-cycle-label">
              {`${daysLeftInCycle} ${inflect(
                'day',
                daysLeftInCycle
              )} left in cycle`}
            </span>
          </div>
        </>
      )}
      <div className="app-actions-by-app-chart">
        {totalActions > 0 ? (
          <ResponsiveContainer width="100%" height={46}>
            <BarChart data={[data]} layout="vertical" width={550} height={28}>
              <XAxis type="number" domain={[0, barMax]} hide />
              <YAxis
                type="category"
                width={150}
                padding={{ left: 20 }}
                dataKey="name"
                hide
              />
              <Tooltip
                content={<BarTooltip />}
                isAnimationActive={false}
                active
                cursor={false}
                wrapperStyle={{ height: '100%' }}
              />

              {barChartApps &&
                totalActions !== 0 &&
                barChartApps.map((app, i) => {
                  let color

                  if (app.id === 'unpublished') {
                    color = '#C8C8C8'
                  } else if (app.id === 'other') {
                    if (isOnLegacyPlan || data.unpublished === 0) {
                      color = '#C8C8C8'
                    } else {
                      color = '#9E9E9E'
                    }
                  } else {
                    color = colors[i % colors.length]
                  }

                  const isRightMostBar =
                    !(
                      i !== barChartApps.length - 1 &&
                      data[barChartApps[i + 1].id] !== 0
                    ) && (data.filler ? data.filler === 0 : true)

                  const isLeftMostBar = i === 0

                  const hasSpacer =
                    i !== barChartApps.length - 1 &&
                    data[barChartApps[i + 1].id] !== 0

                  return (
                    <Bar
                      key={app.id}
                      name={app.name}
                      dataKey={app.id}
                      stackId="a"
                      fill={color}
                      shape={AppBar(
                        isRightMostBar,
                        isLeftMostBar,
                        app,
                        tooltipData,
                        hasSpacer
                      )}
                    />
                  )
                })}

              {data.filler && (
                <Bar
                  stackId="a"
                  dataKey="filler"
                  fill="#ffffff"
                  shape={AppBar(
                    true,
                    totalActions === 0,
                    { name: 'filler' },
                    tooltipData,
                    false
                  )}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <EmptyState modalWidth>
            <h2 className="empty-state-header">No Data</h2>
            <p className="empty-state-message">
              You don't have any app actions yet
            </p>
          </EmptyState>
        )}
      </div>
      {totalActions > 0 && (
        <div className="app-action-by-app-bar-label-wrapper">
          <span className="action-count-text">0</span>
          {isOnLegacyPlan ? (
            <span>
              <span className="action-count-label">{`${totalActions.toLocaleString()} `}</span>
              <span className="action-count-text">Total</span>
            </span>
          ) : (
            <div>
              <span className="action-count-label">
                {totalActions.toLocaleString()}
              </span>
              <span className="action-count-text">
                {' '}
                / {planLimit.toLocaleString() || barMax}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const AppBar = (rightMost, leftMost, app, tooltip, hasSpacer) => {
  return props => {
    const { fill, x, y, width, height } = props

    const { name: appName, id: appId, actionCount } = app

    const borderThickness = 1.5
    const cornerRadius = 2
    const spacerThickness = 1
    const spacerColor = '#ffffff'
    const barWidth = width - spacerThickness

    return (
      <g
        onMouseOver={() => {
          tooltip.appName = appName
          tooltip.appId = appId
          tooltip.actionCount = actionCount
        }}
        onFocus={() => {
          return null
        }}
      >
        {hasSpacer ? (
          <>
            <path
              d={`M${x} ${y} L${x + barWidth} ${y} L${x + barWidth} ${
                y + height
              } L${x} ${y + height}`}
              fill={fill}
            />
            <path
              d={`M${x + barWidth} ${y} L${
                x + barWidth + spacerThickness
              } ${y} L${x + barWidth + spacerThickness} ${y + height} L${
                x + barWidth
              } ${y + height}`}
              fill={spacerColor}
            />
          </>
        ) : (
          <path
            d={`M${x} ${y} L${x + width} ${y} L${x + width} ${
              y + height
            } L${x} ${y + height}`}
            fill={fill}
          />
        )}

        {rightMost ? (
          leftMost ? (
            <path
              d={`M${x + cornerRadius} ${y} L${
                x + width - cornerRadius
              } ${y} A${cornerRadius} ${cornerRadius} 0 0 1 ${x + width} ${
                y + cornerRadius
              } L${x + width} ${
                y + height - cornerRadius
              } A${cornerRadius} ${cornerRadius} 0 0 1 ${
                x + width - cornerRadius
              } ${y + height} L${x + cornerRadius} ${
                y + height
              } A${cornerRadius} ${cornerRadius} 0 0 1 ${x} ${
                y + height - cornerRadius
              } L${x} ${
                y + cornerRadius
              } A${cornerRadius} ${cornerRadius} 0 0 1 ${
                x + cornerRadius
              } ${y} `}
              stroke="#13B597"
              strokeWidth={borderThickness}
              fill="none"
            />
          ) : (
            <path
              d={`M${x} ${y} L${
                x + width - cornerRadius
              } ${y} A ${cornerRadius} ${cornerRadius} 0 0 1 ${x + width} ${
                y + cornerRadius
              } L${x + width} ${
                y + height - cornerRadius
              } A ${cornerRadius} ${cornerRadius} 0 0 1 ${
                x + width - cornerRadius
              } ${y + height} L${x} ${y + height}`}
              stroke="#13B597"
              strokeWidth={borderThickness}
              fill="none"
            />
          )
        ) : leftMost ? (
          <path
            d={`M${x + width} ${y} L${
              x + cornerRadius
            } ${y} A ${cornerRadius} ${cornerRadius} 0 0 0 ${x} ${
              y + cornerRadius
            } L${x} ${
              y + height - cornerRadius
            } A ${cornerRadius} ${cornerRadius} 0 0 0 ${x + cornerRadius} ${
              y + height
            } L${x + width} ${y + height}`}
            stroke="#13B597"
            strokeWidth={borderThickness}
            fill="none"
          />
        ) : (
          <>
            <path
              d={`M${x} ${y} L${x + width} ${y}`}
              stroke="#13B597"
              strokeWidth={borderThickness}
              fill="none"
            />
            <path
              d={`M${x} ${y + height} L${x + width} ${y + height}`}
              stroke="#13B597"
              strokeWidth={borderThickness}
              fill="none"
            />
          </>
        )}
      </g>
    )
  }
}

const LegendItem = props => {
  const { label, value, color, published } = props

  return (
    <li className="app-action-by-app-legend-li">
      <div className="legend-item">
        <LegendIcon color={color} borderColor={color} shape="circle" />
        <span className="legend-label app-name-label">{`${label}`}</span>
        {published ? (
          <Icon
            small
            type="done-all"
            color="green"
            iconStyle="circle"
            className="published-icon"
          />
        ) : null}
        <span className="legend-label app-actions-label">{`(${value.toLocaleString()})`}</span>
      </div>
    </li>
  )
}

const CurrentCycleIndicator = ({
  isOnLegacyPlan,
  cycleStartDate,
  cycleEndDate,
}) => {
  if (!isOnLegacyPlan) {
    return (
      <AdaloToolTip
        tooltip={
          <div className="current-cycle-tooltip-text-container">
            <span className="current-cycle-tooltip-text">
              {`Your current billing cycle began on ${cycleStartDate.format(
                'MMMM Do'
              )} and will end on ${cycleEndDate.format('MMMM Do')}.`}
            </span>
          </div>
        }
        placement="top"
        containerClassname="current-cycle-tooltip-container"
      >
        <div className="billing-usage-chart-bill-period">
          <Icon
            small
            type="calendar-blank"
            color="darkerGray"
            className="calendar-icon"
          />
          <p>Current Cycle</p>
        </div>
      </AdaloToolTip>
    )
  } else {
    return (
      <div className="billing-usage-chart-bill-period">
        <Icon
          small
          type="calendar-blank"
          color="darkerGray"
          className="calendar-icon"
        />
        <p>Current Cycle</p>
      </div>
    )
  }
}

export default AppActionsByAppChart
