import { useState, useMemo, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import QS from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import Button from 'components/Shared/Button'
import EmptyState from 'components/Shared/EmptyState'
import Modal from 'components/Shared/Modal'
import SearchBar from 'components/Shared/SearchBar'
import Loading from 'components/Shared/Loading'
import TemplateItem from 'components/Shared/TemplateItem'

import {
  getFeatureTemplates,
  getFeatureTemplateFeedScroll,
  setFeatureTemplateFeedScroll,
  fetchFeatureTemplateDetails,
} from 'ducks/featureTemplates'
import { getCurrentUser } from 'ducks/users'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import { getCurrentOrganization } from 'ducks/organizations'
import { FEATURE_TEMPLATE_DETAILS_MODAL, showModal } from 'ducks/editor/modals'

import { forceSaveComponents } from '../../../utils/io'

import noResultsPic from './feature-template-empty-state.png'

import FilterOption from './Sidebar'

import './styles.scss'

type FeatureTemplateModalProps = {
  onClose: () => void
  appId: string
  linkingObjectId?: string
}

const SORTING_POPULAR_LABEL = 'Most Popular'
const SORTING_RECENTLY_CREATED_LABEL = 'Recently Added'

const SORTING_POPULAR = 'POPULAR'
const SORTING_RECENTLY_CREATED = 'RECENTLY_CREATED'

type FilterOptionType = {
  label: string
  value: string
  icon: string
}

const DEFAULT_FILTER_OPTION = {
  label: 'View All',
  value: 'all',
  icon: 'article',
}

const filterOptions = [
  { label: 'View All', value: 'all', icon: 'article' },
  { label: 'Browsing', value: 'browsing', icon: 'text-search' },
  { label: 'Social', value: 'social', icon: 'account-group' },
  { label: 'Assigning', value: 'assigning', icon: 'ticket' },
  { label: 'Purchasing', value: 'purchasing', icon: 'credit-card' },
  { label: 'Booking', value: 'booking', icon: 'calendar-check' },
  { label: 'Admin', value: 'admin', icon: 'settings' },
] as FilterOptionType[]

const FeatureTemplateModal = (
  props: FeatureTemplateModalProps
): JSX.Element => {
  const { onClose, appId, linkingObjectId } = props
  const [category, setCategory] = useState<FilterOptionType>(
    DEFAULT_FILTER_OPTION
  )
  const [sorting, setSorting] = useState(SORTING_POPULAR)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const features = useSelector(getFeatureTemplates)

  const history = useHistory()
  const location = useLocation()

  const datasources = useSelector(state => getDefaultDatasource(state, appId))
  const user = useSelector(getCurrentUser)
  const organization = useSelector(getCurrentOrganization)

  const { search: query } = QS.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const dispatch = useDispatch()

  const datasourceId = datasources.id

  const requestLink = `https://form.jotform.com/232615921132145?e=${
    user?.email || ''
  }&p=${user?.persona || ''}&s=${search}&m=${Boolean(
    organization?.active
  ).toString()}`

  useEffect(() => {
    if (typeof query === 'string') {
      setSearch(query || '')
    }

    history.push(`/apps/${appId}/feature-templates`)
    setLoading(true)
  }, [])

  if (query && typeof query === 'string' && !search) {
    setSearch(query)
  }

  useEffect(() => {
    if (Array.isArray(features)) setLoading(false)
  }, [features])

  const featuresLabel = useMemo(() => {
    return category.value.charAt(0).toUpperCase() + category.value.slice(1)
  }, [category])

  const sortingLabel = useMemo(() => {
    switch (sorting) {
      default:
      case SORTING_POPULAR:
        return SORTING_POPULAR_LABEL
      case SORTING_RECENTLY_CREATED:
        return SORTING_RECENTLY_CREATED_LABEL
    }
  }, [sorting])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      history.replace({ search: QS.stringify({ search: e.target.value }) })
    } else {
      history.replace(`/apps/${appId}/feature-templates`)
    }

    setSearch(e.target.value)
  }

  const renderNoResults = () => (
    <EmptyState className="feature-templates__no-results">
      <img src={noResultsPic} alt="No Results" width="50%" />
      <div>
        <span role="img" aria-label="Thinking emoji">
          🤔
        </span>
        <h1>We're Coming Up Blank</h1>
      </div>
      <Button to={requestLink} target="_blank">
        Request a Feature Template
      </Button>
    </EmptyState>
  )

  const options = [
    { label: SORTING_POPULAR_LABEL, value: SORTING_POPULAR },
    { label: SORTING_RECENTLY_CREATED_LABEL, value: SORTING_RECENTLY_CREATED },
  ]

  const renderSideBar = () => {
    return (
      <div className="feature-templates__sidebar">
        {filterOptions.map(option => (
          <FilterOption
            {...option}
            key={option.value}
            selected={category.value === option.value}
            onClick={() => setCategory(option)}
          />
        ))}
      </div>
    )
  }

  const filteredFeatures = useMemo(() => {
    if (!features) return []

    const categoryFiltered = features.filter(feature =>
      category.value === DEFAULT_FILTER_OPTION.value
        ? category
        : feature.category.includes(category.value)
    )

    const searchFiltered = search.length
      ? categoryFiltered.filter(
          feature =>
            feature.name.toLowerCase().includes(search.toLowerCase()) ||
            feature.summary.toLowerCase().includes(search.toLowerCase())
        )
      : categoryFiltered

    const sortingFiltered = searchFiltered.sort((a, b) => {
      switch (sorting) {
        default:
        case SORTING_POPULAR:
          return b.timesUsed - a.timesUsed
        case SORTING_RECENTLY_CREATED:
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
      }
    })

    return sortingFiltered
  }, [features, category, search, sorting])

  const renderFeatures = () => {
    if (loading) {
      return <Loading small={false} expanded color={null} />
    }

    if (!filteredFeatures.length) {
      return renderNoResults()
    }

    return filteredFeatures.map(feature => {
      const handleClick = () => {
        dispatch(fetchFeatureTemplateDetails(feature.id))
        const modalProps = {
          feature,
          appId,
          datasourceId,
          linkingObjectId,
          origin,
        }

        dispatch(showModal(FEATURE_TEMPLATE_DETAILS_MODAL, modalProps))
      }

      return (
        <TemplateItem key={feature.id} item={feature} onClick={handleClick} />
      )
    })
  }
  const scrollPosition = useSelector(getFeatureTemplateFeedScroll)
  const feedRef = useRef<HTMLDivElement | null>(null)

  // TODO @danicunhac fix scroll not persisting with search applied
  const handleScroll = () => {
    const position = feedRef.current?.scrollTop as number
    dispatch(setFeatureTemplateFeedScroll(position))
  }

  useEffect(() => {
    if (
      scrollPosition !== null &&
      feedRef.current &&
      scrollPosition !== feedRef.current.scrollTop
    ) {
      feedRef.current.scrollTo({
        top: scrollPosition as number,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [feedRef.current !== null])

  const renderContent = () => (
    <div className="feature-templates__container">
      {renderSideBar()}
      <div className="feature-templates__vertical-line" />
      <div
        className="feature-templates__feed"
        onScroll={handleScroll}
        ref={feedRef}
      >
        <div className="feature-templates__feed-header">
          <h2>{featuresLabel} Templates</h2>

          <MultiMenuTrigger menu={options} onSelect={setSorting} rowHeight={32}>
            <Button
              black
              small
              text
              icon="sort"
              iconSide="right"
              iconSize="small"
            >
              {sortingLabel}
            </Button>
          </MultiMenuTrigger>
        </div>

        <div
          className={classNames({
            'feature-templates__feed-items':
              // Show as a grid if it ain't loading and there are items
              !loading && filteredFeatures.length,
          })}
        >
          {renderFeatures()}
        </div>
      </div>
    </div>
  )

  // The reason we perform this operation here as opposed to right before cloning a template
  // is because these components will yet be pushed to queue and then processed
  // meaning we need some time for this operation to finish prior to the actual
  // process of adding a template to our app.
  forceSaveComponents()

  return (
    <div>
      <Modal.Header>
        <h1>Feature Templates</h1>
        <SearchBar
          className="feature-template-search"
          placeholder="Search Templates..."
          value={search}
          onChange={handleSearch}
        />
      </Modal.Header>
      <Modal.Content classNames="feature-templates__modal-content">
        {renderContent()}
      </Modal.Content>
      <Modal.Actions
        leftButtons={[
          <Button
            icon="help"
            iconSize="small"
            teal
            outlined
            to={requestLink}
            target="_blank"
          >
            Request a Feature Template
          </Button>,
        ]}
      >
        <Modal.Button onClick={onClose}>Done</Modal.Button>
      </Modal.Actions>
    </div>
  )
}

export default FeatureTemplateModal
