import React, { Component } from 'react'

export default class GenericWrapper extends Component {
  render() {
    const { xScaled, yScaled, children } = this.props

    const transform = `translate(${xScaled} ${yScaled})`

    return <g transform={transform}>{children}</g>
  }
}
