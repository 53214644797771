import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { isMatch, throttle, pickBy } from 'lodash'
import { ALIGN_CENTER, ALIGN_RIGHT } from '@adalo/constants'
import { isFirefox } from 'utils/browsers'
import { getLines, getValueParagraphs } from 'utils/text'
import {
  calculateResponsiveTextHeight,
  calculateResponsiveTextLines,
} from 'utils/responsiveText'
import { scaleValue } from 'utils/zoom'
import { getFontFamily } from 'utils/type'
import { updateObject } from 'ducks/editor/objects'
import { getEditingText } from 'ducks/editor/textEditing'

import BaseObject from '../BaseObject'
import './Label.css'

const THROTTLE_MS = 50

const UNQUOTED_FONT_FAMILY_WITH_SPACES_REGEX = /^[^"].*\s.*[^"]$/

class Label extends BaseObject {
  constructor(props) {
    super(props)

    const { layoutText } = props

    this.state = {
      ...this.state,
      displayText: layoutText || [''],
      displayHeight: 0,
    }
    this.throttleUpdateTextAndHeight = throttle(
      this.updateTextAndHeight,
      THROTTLE_MS,
      { trailing: true, leading: true }
    )
  }

  doubleClickHandler() {
    if (this.isSelected()) {
      return this.props.onEdit()
    }

    return super.doubleClickHandler()
  }

  getBorderProps() {
    const { id } = this.props

    // if no id is set then this label is not yet part of the app, render a
    // border to help the user see the size of the component
    if (!id) {
      return {
        strokeWidth: 2,
        stroke: '#e0e0e0',
        rx: 4,
        ry: 4,
      }
    }
  }

  handleFontFamily = () => {
    const { branding, fontFamily } = this.props

    return getFontFamily(fontFamily, branding)
  }

  getFonts = () => {
    let { fontStyle, fontWeight, fontSize } = this.props

    if (!fontStyle) {
      fontStyle = 'normal'
    }
    if (!fontWeight) {
      fontWeight = 'normal'
    }
    if (!fontSize) {
      fontSize = 16
    }

    const resolvedFontFamily = this.handleFontFamily()

    const families =
      resolvedFontFamily?.split(',')?.map(familyName => familyName.trim()) || []

    const fontPromises = []
    for (let family of families) {
      if (UNQUOTED_FONT_FAMILY_WITH_SPACES_REGEX.test(family)) {
        family = `"${family}"`
      }

      const fontName = `${fontStyle} ${fontWeight} ${fontSize}px ${family}`

      const fontPromise = document.fonts.load(fontName)
      fontPromises.push(fontPromise)
    }

    return Promise.all(fontPromises)
  }

  calculateLegacyLayoutText() {
    const { object } = this.props

    return {
      displayText: getLines(this.props).map(line => line.trim()),
      displayHeight: object.height,
    }
  }

  calculateResponsiveLayoutText() {
    const {
      text,
      fontStyle,
      fontWeight,
      width,
      fontSize,
      multiline,
      autoWidth,
      maxLength,
    } = this.props

    const newText = getValueParagraphs(text).join('\n')

    const fontFamily = this.handleFontFamily()

    const textParams = {
      text: newText,
      fontStyle,
      evaluatedFontFamily: fontFamily,
      fontWeight,
      width,
      fontSize,
      multiline,
      autoWidth,
      maxLength,
    }
    const displayText = calculateResponsiveTextLines(textParams)
    const displayHeight = calculateResponsiveTextHeight(textParams)

    return {
      displayText,
      displayHeight,
    }
  }

  async updateTextAndHeight() {
    const { inResponsiveApp, updateObjectData, object, deviceType } = this.props

    let layoutResult
    if (!inResponsiveApp) {
      layoutResult = this.calculateLegacyLayoutText()
    } else {
      // Wait for fonts to finish loading. Otherwise, text measurements will be based on the wrong font and incorrect.
      await this.getFonts()

      layoutResult = this.calculateResponsiveLayoutText()
    }
    const { displayText, displayHeight } = layoutResult

    if (inResponsiveApp && object.height !== displayHeight) {
      let updatedObject = {
        layoutText: displayText,
        inResponsiveApp: true,
        calculatedFontFamily: this.handleFontFamily(),
      }

      if (object[deviceType]?.height) {
        updatedObject = {
          ...updatedObject,
          [deviceType]: {
            ...object[deviceType],
            height: displayHeight,
          },
        }
      } else {
        updatedObject.height = displayHeight
      }

      updateObjectData(object.id, updatedObject)
    }

    const prevFontFamily = this.handleFontFamily()

    this.setState({
      displayText,
      displayHeight,
      prevFontFamily,
    })
  }

  componentDidMount() {
    this.updateTextAndHeight()
  }

  componentDidUpdate(prevProps) {
    const {
      text,
      fontStyle,
      fontWeight,
      width,
      fontSize,
      multiline,
      autoWidth,
      maxLength,
    } = this.props

    // removes fields with undefined as a value
    // no need to check on those
    const textProps = pickBy(
      {
        text,
        fontStyle,
        fontWeight,
        fontFamily: this.handleFontFamily(),
        width,
        fontSize,
        multiline,
        autoWidth,
        maxLength,
      },
      v => v !== undefined
    )

    const toVerify = { ...prevProps, fontFamily: this.state.prevFontFamily }

    const shouldRecalculateText = !isMatch(toVerify, textProps)

    if (shouldRecalculateText) {
      this.throttleUpdateTextAndHeight()
    }
  }

  render() {
    const {
      branding,
      text,
      layoutText,
      x,
      y,
      width,
      height,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      selected,
      editing,
      fontSize: originalFontSize,
      textAlignment,

      elementRef,
      onChange,
      onSelect,
      zoom,
      left,
      right,
      minWidth,
      maxWidth,
      ...styles
    } = this.props

    const fontSize = scaleValue(originalFontSize, zoom)

    let textAnchor = 'start'

    let xAdjusted = xScaled

    styles.fontFamily = this.handleFontFamily()

    if (editing) {
      styles.opacity = 0
    }

    if (textAlignment === ALIGN_CENTER) {
      textAnchor = 'middle'
      xAdjusted += widthScaled / 2
    } else if (textAlignment === ALIGN_RIGHT) {
      textAnchor = 'end'
      xAdjusted += widthScaled
    }

    const color = this.getColor(styles.color || '@text')

    const lineHeight = scaleValue(Math.ceil(originalFontSize * 1.15), zoom)
    const { displayHeight, displayText } = this.state
    const displayHeightScaled = scaleValue(displayHeight, zoom) || lineHeight

    return (
      <g
        className={classNames('label', {
          'label-editing': editing,
        })}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        style={{
          ...styles,
          fontSize: `${fontSize}px`,
        }}
      >
        {/* Click target for selection */}
        <rect
          x={xScaled}
          y={yScaled}
          width={widthScaled}
          height={displayHeightScaled}
          fill="transparent"
          {...this.getBorderProps()}
        />
        <text
          className="label-text"
          y={yScaled}
          x={xAdjusted}
          fill={color}
          textAnchor={textAnchor}
          style={{ ...styles }}
        >
          {displayText.map((line, i) => (
            <tspan
              key={i} // eslint-disable-line
              alignmentBaseline="text-before-edge"
              x={xAdjusted}
              y={yScaled + i * lineHeight + (isFirefox() ? fontSize : 0) || 0}
            >
              {line}
            </tspan>
          ))}
        </text>
      </g>
    )
  }
}

const mapStateToProps = (state, props) => ({
  editing: getEditingText(state) === props.object?.id,
})

export default connect(mapStateToProps, {
  updateObjectData: updateObject,
})(Label)

Label.defaultProps = {
  yScaled: 0,
  y: 0,
}
