import { SHAPE } from '@adalo/constants'
import { createPath } from '@adalo/utils'

const SVG_NS = 'http://www.w3.org/2000/svg'

export const updateShape = shapeObj => {
  const bbox = getShapeBbox(shapeObj)

  const x = bbox.x * shapeObj.width + shapeObj.x
  const y = bbox.y * shapeObj.height + shapeObj.y
  const width = bbox.width * shapeObj.width
  const height = bbox.height * shapeObj.height

  const dx = (x - shapeObj.x) / shapeObj.width
  const dy = (y - shapeObj.y) / shapeObj.height
  const scaleX = shapeObj.width / width
  const scaleY = shapeObj.height / height

  const points = shapeObj.points.map(({ point, inControl, outControl }) => {
    return {
      point: transformPoint(point, dx, dy, scaleX, scaleY),
      inControl: transformPoint(inControl, dx, dy, scaleX, scaleY),
      outControl: transformPoint(outControl, dx, dy, scaleX, scaleY),
    }
  })

  return {
    ...shapeObj,
    x,
    y,
    width,
    height,
    points,
  }
}

export const transformPoint = (point, dx, dy, sx, sy) => {
  if (!point) {
    return point
  }

  const [x, y] = point

  return [(x - dx) * sx, (y - dy) * sy]
}

export const getShapeBbox = shapeObject => {
  const { points, isClosed } = shapeObject

  if (shapeObject.type !== SHAPE) {
    return null
  }

  return measureShape(points, isClosed)
}

export const measureShape = (points, isClosed) => {
  if (!points || points.length === 0) {
    return { x: 0, y: 0, width: 0, height: 0 }
  }

  const pathData = createPath(points, isClosed)

  const svg = document.createElementNS(SVG_NS, 'svg')
  svg.zIndex = 20000
  svg.style.position = 'fixed'
  svg.style.left = 0
  svg.style.top = 0
  svg.style.opacity = 0
  svg.style.pointerEvents = 'none'
  svg.style.width = '1000px'
  svg.style.height = '1000px'

  const path = document.createElementNS(SVG_NS, 'path')
  path.setAttributeNS(null, 'd', pathData)

  document.body.appendChild(svg)
  svg.appendChild(path)

  const rect = path.getBoundingClientRect()

  window.setTimeout(() => {
    document.body.removeChild(svg)
  }, 0)

  const { left, top, width, height } = rect

  return { width, height, x: left, y: top }
}
