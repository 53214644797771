import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import './Selector.scss'

const Item = props => {
  const { input, value, children } = props
  const { name, onChange } = input

  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (input) {
      if (input.value === value) setSelected(true)
      if (input.value !== value) setSelected(false)
    }
  }, [input])

  return (
    <label
      htmlFor={value}
      className={classNames('form-selector-selector', { selected })}
    >
      <div className="form-selector-selector-content">{children}</div>
      <input
        id={value}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
        className="form-selector-radio"
      />
    </label>
  )
}

export default Item
