import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import CircularProgressBar from './CircularProgressBar'

export default class Build extends Component {
  static propTypes = {
    startedAt: PropTypes.string,
    completedAt: PropTypes.string,
    version: PropTypes.string.isRequired,
    versionText: PropTypes.string,
    status: PropTypes.oneOf(['queued', 'building', 'built', 'failed']),
    statusText: PropTypes.string,
    binaryURL: PropTypes.string,
    minutesToBuildEstimate: PropTypes.number,
    appUrl: PropTypes.string,
    debug: PropTypes.bool,
  }

  update = () => {
    this.forceUpdate()
  }

  componentDidMount() {
    this.interval = window.setInterval(this.update, 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  render() {
    let {
      startedAt,
      completedAt,
      version,
      status,
      versionText,
      statusText,
      binaryURL,
      appId,
      id,
      minutesToBuildEstimate,
      appUrl,
      debug,
      iosErrorSummary,
    } = this.props

    let relativeDate

    const elapsedTime = (+new Date() - new Date(startedAt)) / (1000 * 60)

    const minutesToBuild = minutesToBuildEstimate ?? 15
    const initialProgress = Math.min(100, (elapsedTime * 100) / minutesToBuild)

    let displayDate = relativeDate
    let appLink
    let appUrlDetail
    if (status === 'queued') {
      statusText = 'Queued'
    }

    if (status === 'building') {
      const duration = moment.duration(
        moment(startedAt).diff(moment().subtract(minutesToBuild, 'minutes'))
      )

      const seconds = `${duration.seconds() % 60}`.padStart(2, '0')
      statusText = 'Building'
      displayDate = `${duration.minutes()}:${seconds} remaining`
    }

    if (status === 'built') {
      const dateText = moment(completedAt).fromNow()

      displayDate = `Finished building ${dateText}`
      if (appUrl) {
        appLink = (
          <a href={appUrl} target="_blank" rel="noreferrer">
            <Icon type="open-in-new" small color="green" />
            Open
          </a>
        )
        appUrlDetail = <div className="mobile-build-app-url">{appUrl}</div>
      }
    }

    if (status === 'failed') {
      statusText = 'Failed'
    }

    return (
      <div className={`mobile-build mobile-build-state-${status}`}>
        <div className="mobile-build-info">
          <div className="mobile-build-version">
            {versionText ?? `Version ${version}`}
            {appLink}
          </div>
          {appUrlDetail}
          <div className="mobile-build-details">
            {status !== 'built' && statusText ? statusText : null}
            {status !== 'built' && statusText && displayDate ? ', ' : null}
            {displayDate || null}
          </div>
        </div>
        <div className="mobile-build-right">
          {status === 'building' && (
            <CircularProgressBar
              sqSize={30}
              strokeWidth={2}
              showText={false}
              percentage={initialProgress}
            />
          )}
          {status === 'failed' && (
            <Button small text to={`/apps/${appId}/screens/builds/${id}`}>
              <p>error logs</p>
            </Button>
          )}
          {typeof binaryURL === 'string' && (
            <a href={binaryURL}>
              <i className="material-icons">save_alt</i>
            </a>
          )}
        </div>
        {debug && <CopyBuildId buildId={id} />}
        {typeof iosErrorSummary === 'string' && iosErrorSummary.length !== 0 && (
          <div className="mobile-build-error">
            <div />
            <div className="mobile-build-error-summary-container">
              <p className="mobile-build-error-summary">{iosErrorSummary}</p>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const CopyBuildId = ({ buildId }) => {
  const copyBuildId = () => {
    navigator.clipboard.writeText(buildId)
  }

  return (
    <a onClick={copyBuildId} className="mobile-build-debug-id">
      <span role="img" aria-label="Build Id">
        🆔
      </span>
    </a>
  )
}
