import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useFlags } from 'flags'

import Button from 'components/Shared/Button'

import { getApp } from 'ducks/apps'
import {
  getAppBuildsState,
  ensureAppBuildsAreLoaded,
  startAppBuild,
} from 'ducks/apps/builds'
import { getCurrentUser } from 'ducks/users'
import useDebugMode from 'hooks/useDebugMode'
import { requestAppBuilds } from 'utils/io'

import MobilePublishSummary from '../MobilePublishSummary'
import IOSSettingsModal from './SettingsModal'
import BuildList from '../BuildList/BuildList'
import NewVersionModal from '../NewVersionModal'
import VerifyCredentialsModal from './VerifyCredentialsModal'

const IOSPublish = props => {
  const history = useHistory()
  const debug = useDebugMode()

  const { hasBuildsWithCodemagic } = useFlags()

  const [currentModal, setCurrentModal] = useState(null)
  const [skipBuildEnqueue, setSkipBuildEnqueue] = useState(false)

  const currentUser = useSelector(getCurrentUser)

  const handleStartBuild = async ({ version }) => {
    const { appId, startAppBuild, hasAppType } = props

    startAppBuild(appId, 'ios', version, skipBuildEnqueue && currentUser?.admin)

    if (!hasAppType && currentUser?.persona && !currentUser?.admin) {
      history.push(`/apps/${appId}/screens/native-app-questions`)
    }
  }

  const handleNewBuild = () => {
    setCurrentModal(
      hasBuildsWithCodemagic
        ? ['settings', 'version']
        : ['settings', 'credentials', 'version']
    )
  }

  const handleOpenSettings = () => {
    setCurrentModal(['settings'])
  }

  const handleCloseModal = () => {
    setCurrentModal(null)
  }

  const handleSaveSettings = skipEnqueue => {
    if (skipEnqueue !== undefined) {
      setSkipBuildEnqueue(skipEnqueue)
    }

    if (currentModal?.length > 1) {
      // Skip the credentials step if the user has skipEnqueue enabled
      const nextModal =
        skipEnqueue && currentUser?.admin ? ['version'] : currentModal.slice(1)
      setCurrentModal(nextModal)
    } else {
      setCurrentModal(null)
    }
  }

  const handleContinue = () => {
    if (!currentModal || !currentModal.length > 1) {
      setCurrentModal(null)
    } else {
      setCurrentModal(currentModal.slice(1))
    }
  }

  const handleCredentialValidation = () => {
    const { iosSettings } = props

    if (!iosSettings) return false

    if (!hasBuildsWithCodemagic) {
      const { bundleId, appSpecificPassword, applePassword, appleUsername } =
        iosSettings

      return bundleId && appSpecificPassword && applePassword && appleUsername
    }
    const { bundleId, appleIssuerId, appleApiKeyId, appleApiKey } = iosSettings

    return bundleId && appleIssuerId && appleApiKeyId && appleApiKey
  }

  const handleAppSettingsValidation = () => {
    const { iosSettings } = props
    const { displayName, launchScreenColor, launchScreenIcon } = iosSettings

    if (!displayName || !launchScreenColor || !launchScreenIcon) return false

    return true
  }

  const requestData = () => {
    const { appId } = props

    requestAppBuilds(appId, 'ios', { order: 'DESC' })
  }

  useEffect(() => {
    const { appId, ensureAppBuildsAreLoaded } = props

    ensureAppBuildsAreLoaded(appId, 'ios')

    const timer = window.setInterval(requestData, 5000)

    return () => {
      window.clearTimeout(timer)
    }
  }, [])

  const {
    appId,
    iosSettings,
    lastVersion,
    buildList,
    disableButton,
    loadingBuilds,
  } = props

  const currentModalName = currentModal && currentModal[0]
  const disabled = disableButton && !debug
  const initialValues = { launchScreenColor: '#ffffff', ...iosSettings }

  return (
    <div>
      <div className="mobile-publish-settings">
        <h2>iOS Export Settings</h2>
        <MobilePublishSummary
          provider="ios"
          onUpdateSettings={handleOpenSettings}
          handleCredentialValidation={handleCredentialValidation}
          handleAppSettingsValidation={handleAppSettingsValidation}
        />
        <h2>TestFlight Builds</h2>
        <Button
          fluid
          outlined
          green
          icon={disabled ? '' : 'plus-small'}
          onClick={handleNewBuild}
          disabled={disabled}
        >
          {disableButton
            ? 'Build In Progress...'
            : 'Start New TestFlight Build'}
        </Button>
        <BuildList appId={appId} list={buildList} loading={loadingBuilds} />
        {currentModalName === 'settings' ? (
          <IOSSettingsModal
            appId={appId}
            onClose={handleCloseModal}
            onSubmit={handleSaveSettings}
            publishing={currentModal?.length > 1}
            initialValues={initialValues}
            iosSettings={iosSettings}
            setSkipBuildEnqueue={setSkipBuildEnqueue}
          />
        ) : currentModalName === 'credentials' ? (
          <VerifyCredentialsModal
            appId={appId}
            onClose={handleCloseModal}
            onBack={handleNewBuild}
            onContinue={handleContinue}
          />
        ) : currentModalName === 'version' ? (
          <NewVersionModal
            target="ios"
            lastVersion={lastVersion}
            onClose={handleCloseModal}
            onSubmit={handleStartBuild}
          />
        ) : null}
      </div>
    </div>
  )
}

function mapStateToProps(state, { appId }) {
  const app = getApp(state, appId)
  const iosSettings = {
    ...(app &&
      app.iosSettings && {
        ...app.iosSettings,
      }),
  }
  const buildsState = getAppBuildsState(state, appId, 'ios')

  const buildList = buildsState && buildsState.list

  const disableButton =
    buildList &&
    buildList.find(
      build =>
        (build.status === 'queued' || build.status === 'building') &&
        moment(build.createdAt).diff(Date.now(), 'hours') > -2
    )

  return {
    hasAppType: app.type,
    iosSettings,
    loadingBuilds: buildsState ? buildsState.loading : true,
    buildList,
    lastVersion: buildsState && buildsState.lastVersion,
    disableButton,
  }
}

export default connect(mapStateToProps, {
  ensureAppBuildsAreLoaded,
  startAppBuild,
})(IOSPublish)
