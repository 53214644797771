import React, { Component } from 'react'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'

export function withStripePromise(StripeComponent) {
  return class extends Component {
    state = {
      stripePromise: null,
    }

    componentDidMount() {
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

      if (stripePromise) {
        this.setState({ stripePromise })
      }
    }

    render() {
      const { stripePromise } = this.state

      return (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <StripeComponent
                {...this.props}
                stripe={stripe}
                elements={elements}
              />
            )}
          </ElementsConsumer>
        </Elements>
      )
    }
  }
}
