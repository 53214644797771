import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

// components
import Icon from 'components/Shared/Icon'

// stylesheets
import './chip.scss'

const Chip = ({
  children,
  className,
  size,
  color,
  hideBackground,
  disabled,
  ...props
}) => {
  const { to, href } = props

  const type = to ? Link : href ? 'a' : 'div'

  const externalLinkProps = href
    ? { target: '_blank', rel: 'noreferrer noopener' }
    : {}

  return React.createElement(
    type,
    {
      ...props,
      ...externalLinkProps,
      className: classNames(
        'chip',
        size && `chip-${size}`,
        color && `chip-${color}`,
        hideBackground && 'chip-no-background',
        disabled && 'chip-disabled',
        className
      ),
    },
    children
  )
}

Chip.Icon = props => <Icon {...props} />

Chip.Content = ({ children }) => {
  return <div className="chip-content">{children}</div>
}

export default Chip
