import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Icon from '../../Shared/Icon'
import ColorDropdown from '../Inspect/ColorDropdown'
import { getAppBranding, updateApp } from '../../../ducks/apps'
import { getAccordionState } from '../../../ducks/accordions'
import Item from '../../Shared/Panel/Item'
import Fonts from '../../AppDetail/Fonts'

export const BRANDING_GROUP = 'branding'

class BrandingPanel extends Component {
  handleChange = values => {
    const {
      match: {
        params: { appId },
      },
      branding: oldBranding,
      updateApp,
    } = this.props

    const branding = { ...oldBranding, ...values }
    updateApp(appId, { branding })
  }

  render() {
    const { branding, isMounted } = this.props

    return (
      <div className="app-branding-panel">
        <h2 className="left-panel-title">
          <Icon type="branding" />
          {' Branding'}
        </h2>

        <Item
          title="Colors"
          group={BRANDING_GROUP}
          fluid
          defaultExpanded={!isMounted}
          itemId="colors"
        >
          <ColorDropdown
            branding={branding}
            value={branding.primary}
            normalize={false} // prevent convertion to and from @primary and such
            name="primary"
            title="Primary Color"
            onChange={this.handleChange}
          />
          <ColorDropdown
            branding={branding}
            value={branding.secondary}
            normalize={false}
            name="secondary"
            title="Secondary Color"
            onChange={this.handleChange}
          />
          <ColorDropdown
            branding={branding}
            value={branding.background}
            normalize={false}
            name="background"
            title="Default Background Color"
            onChange={this.handleChange}
          />
          <ColorDropdown
            branding={branding}
            value={branding.text}
            normalize={false}
            name="text"
            title="Default Text Color"
            onChange={this.handleChange}
          />
        </Item>
        <Item title="Fonts" group={BRANDING_GROUP} fluid itemId="fonts">
          <Fonts />
        </Item>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  branding: getAppBranding(state, ownProps.match.params.appId),
  isMounted: getAccordionState(state, BRANDING_GROUP),
})

export default withRouter(
  connect(mapStateToProps, { updateApp })(BrandingPanel)
)
