import React from 'react'
import PropTypes from 'prop-types'

import Params from 'components/Editor/Params'
import InspectPanel from 'components/Editor/Inspect'
import { PanelItem } from 'components/Shared/Panel'
import Layers from 'components/Editor/Layers'

import ScreenAction from '../ScreenAction'
import ScreenTemplateCollapsible from './ScreenTemplateCollapsible'

import './ScreenControl.css'

const ScreenControl = props => {
  const {
    screenTemplateExpanded,
    appId,
    object,
    onCollapseScreenTemplate,
    onShowScreenTemplates,
  } = props

  const { id } = object
  const GROUP = `${id}-screen`

  return (
    <>
      <ScreenTemplateCollapsible
        expanded={screenTemplateExpanded}
        appId={appId}
        object={object}
        onClose={onCollapseScreenTemplate}
        onExpand={onShowScreenTemplates}
      />
      <PanelItem title="Components" group={GROUP} fluid defaultExpanded>
        <Layers objectId={id} />
      </PanelItem>
      <PanelItem title="Actions" group={GROUP} fluid>
        <ScreenAction object={object} />
      </PanelItem>
      <PanelItem title="Available Data" group={GROUP} fluid>
        <Params component={object} />
      </PanelItem>
      <InspectPanel />
    </>
  )
}

ScreenControl.propTypes = {
  screenTemplateExpanded: PropTypes.bool.isRequired,
  appId: PropTypes.string.isRequired,
  object: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onCollapseScreenTemplate: PropTypes.func.isRequired,
  onShowScreenTemplates: PropTypes.func.isRequired,
}

export default ScreenControl
