import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

// ducks
import { getCurrentUser } from 'ducks/users/index.ts'

// components
import Button from 'components/Shared/Button'

import { DetailsContext } from './index'

const DetailsHeader = () => {
  const { library, installLabel, handleAction, isLoading, price, isInstalled } =
    useContext(DetailsContext)

  const currentUser = useSelector(state => getCurrentUser(state))

  const { displayName, logo, author } = library

  return (
    <div className="marketplace-library-details-header">
      <div className="marketplace-library-details-header-info">
        <h2>
          <img src={logo} alt={`${displayName} logo`} /> {displayName}
        </h2>
        <span>Made with ♡ by {author}</span>
      </div>
      <div className="marketplace-library-details-header-price">
        <Price library={library} value={price} isInstalled={isInstalled} />
        <Button
          onClick={handleAction}
          loading={isLoading}
          disabled={isLoading || (isInstalled && !currentUser.admin)}
          teal
          secondary={isInstalled}
        >
          {installLabel}
        </Button>
      </div>
    </div>
  )
}

export default DetailsHeader

const Price = ({ library, value, isInstalled }) => {
  if (!library.price) return null

  return <span className={classNames({ installed: isInstalled })}>{value}</span>
}
