import React from 'react'
import classNames from 'classnames'

import './Stars.scss'

const Stars = ({ cardStyle = 'free' }) => {
  return (
    <svg
      className={classNames(['Stars', `Stars--${cardStyle}`])}
      viewBox="0 0 30 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="Stars-star"
        d="M6.48171 1.69457L6.53677 5.27221L9.41838 7.21775L5.98529 7.27513L4.11836 10.2781L4.0633 6.70043L1.18168 4.75489L4.61477 4.69751L6.48171 1.69457Z"
      />
      <path
        className="Stars-star"
        d="M24.7555 7.82064L22.1001 12.9028L24.6706 18.0323L19.7937 15.265L14.8715 17.9437L17.527 12.8615L14.9565 7.73207L19.8334 10.4993L24.7555 7.82064Z"
      />
      <path
        className="Stars-star"
        d="M20.5137 24.5721L20.5607 27.6244L23.0192 29.2843L20.0902 29.3332L18.4974 31.8952L18.4504 28.8429L15.9919 27.1831L18.9209 27.1341L20.5137 24.5721Z"
      />
    </svg>
  )
}

export default Stars
