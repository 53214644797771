import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'

export default class Dropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  state = { expanded: false }

  handleTitleClick = e => {
    e.preventDefault()
    this.setState(state => ({ expanded: !state.expanded }))
  }

  handleClose = () => {
    this.setState({ expanded: false })
  }

  render() {
    const { children, className, title } = this.props
    const { expanded } = this.state

    return (
      <div
        className={classNames('navbar-dropdown-container', className, {
          expanded,
        })}
      >
        {expanded ? <DocumentEvents onClick={this.handleClose} /> : null}
        <a
          className="navbar-dropdown-title"
          onClick={this.handleTitleClick}
          href="#"
        >
          {title}
        </a>
        {expanded ? (
          <div className="navbar-dropdown-children">
            {typeof children === 'function' ? children() : children}
          </div>
        ) : null}
      </div>
    )
  }
}
