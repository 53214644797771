import {
  backgroundStyle,
  borderStyle,
  DeviceBreakpoint,
  DeviceWidth,
  LAYOUT_SECTION,
  positioning,
  resizingOptions,
  responsivePositioningOptions,
  SECTION,
} from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import ObjectBuilder from 'utils/ObjectBuilder'
import { defaults } from 'utils/objects'
import { EditorObject } from 'utils/responsiveTypes'
import { LayoutSectionPurpose } from '..'

export * from './utils'

export const LAYOUT_SECTION_TOP_BOTTOM_PADDING = 38
export const LAYOUT_SECTION_LEFT_RIGHT_PADDING = 36
const DEFAULT_CONTAINER_MIN_WIDTH = 250
const DEFAULT_SECTION_MIN_WIDTH = 250 + LAYOUT_SECTION_LEFT_RIGHT_PADDING * 2

const INITIAL_POSITION = 0
const DEFAULT_HEIGHT = 410

// TODO(michael-adalo): we can remove this function in the future if we know
// that the layout section will always have a fixed height
export const layoutSectionHeightPerScreen = (width: number): number => {
  if (width <= DeviceBreakpoint.MOBILE_BREAKPOINT) {
    return DEFAULT_HEIGHT
  }

  if (width <= DeviceBreakpoint.TABLET_BREAKPOINT) {
    return DEFAULT_HEIGHT
  }

  return DEFAULT_HEIGHT
}

type Data = {
  screenWidth: number
  innerSectionId: string
  outerSectionId: string
}

export const buildDefaultLayoutSection = (
  object: EditorObject | null,
  data: Data
): EditorObject | void => {
  if (!object) return
  if (object.type !== LAYOUT_SECTION) return object

  const { outerSectionId, innerSectionId, screenWidth } = data

  const DEFAULT_OUTER_SECTION = {
    ...defaults[SECTION],
    id: outerSectionId,
    type: SECTION,
    backgroundStyle: 'none',
    name: 'Layout Helper',
    purpose: LayoutSectionPurpose.LAYOUT_HELPER,
    shared: {
      desktop: true,
      mobile: true,
      tablet: true,
    },
  }

  const DEFAULT_INNER_SECTION = {
    ...defaults[SECTION],
    id: innerSectionId,
    type: SECTION,
    name: 'Container',
    borderStyle: borderStyle.NONE,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    purpose: 'container',
    backgroundColor: '@background',
    backgroundStyle: backgroundStyle.NONE,
    shared: {
      desktop: true,
      mobile: true,
      tablet: true,
    },
    shadow: {
      enabled: true,
      color: '#00000020',
      x: 0,
      y: 2,
      size: 4,
    },
    borderRadius: 10,
  }

  // temporary defaults to render an initial version of the component
  const newObject = { ...object, backgroundColor: '@background' }

  newObject.width = screenWidth
  newObject.height = layoutSectionHeightPerScreen(screenWidth)

  const device = getDeviceType(screenWidth)

  const outerSectionY = newObject.y + LAYOUT_SECTION_TOP_BOTTOM_PADDING
  const outerSectionHeight =
    newObject.height - LAYOUT_SECTION_TOP_BOTTOM_PADDING * 2

  const layoutSectionBuilder = ObjectBuilder.merge(
    newObject.id,
    newObject.type,
    newObject
  )
    .withPosition(INITIAL_POSITION, newObject.y)
    .withSize(newObject.width, newObject.height)
    .withPushGraph({ nodeIds: [outerSectionId], edges: [] })
    .withResponsivity({
      horizontalPositioning: responsivePositioningOptions.LEFT_AND_RIGHT,
      horizontalScaling: resizingOptions.SCALES_WITH_PARENT,
      verticalPositioning: responsivePositioningOptions.TOP,
      verticalScaling: resizingOptions.FIXED,
    })
    .withMinMaxWidth(
      DEFAULT_SECTION_MIN_WIDTH,
      true,
      DeviceWidth.DESKTOP_MAX_WIDTH,
      false
    )

  // LAYOUT HELPER
  const outerSectionBuilder = ObjectBuilder.merge(
    DEFAULT_OUTER_SECTION.id,
    SECTION,
    DEFAULT_OUTER_SECTION
  )
    .withInitialDevice(device)
    .withPosition(INITIAL_POSITION, outerSectionY)
    .withSize(newObject.width, outerSectionHeight)
    .withResponsivity({
      horizontalPositioning: responsivePositioningOptions.CENTER,
      horizontalScaling: resizingOptions.SCALES_WITH_PARENT,
      verticalPositioning: responsivePositioningOptions.TOP,
      verticalScaling: resizingOptions.FIXED,
    })
    .withPositioning(positioning.FIXED)
    .withPushGraph({
      nodeIds: [innerSectionId],
      edges: [],
    })

  // CONTAINER
  const innerSectionBuilder = ObjectBuilder.merge(
    DEFAULT_INNER_SECTION.id,
    SECTION,
    DEFAULT_INNER_SECTION
  )
    .withInitialDevice(device)
    .withPosition(
      INITIAL_POSITION + LAYOUT_SECTION_LEFT_RIGHT_PADDING,
      newObject.y + LAYOUT_SECTION_TOP_BOTTOM_PADDING
    )
    .withSize(
      newObject.width - LAYOUT_SECTION_LEFT_RIGHT_PADDING * 2,
      newObject.height - LAYOUT_SECTION_TOP_BOTTOM_PADDING * 2
    )
    .withResponsivity({
      horizontalPositioning: responsivePositioningOptions.LEFT_AND_RIGHT,
      horizontalScaling: resizingOptions.SCALES_WITH_PARENT,
      verticalPositioning: responsivePositioningOptions.TOP,
      verticalScaling: resizingOptions.FIXED,
    })
    .withPositioning(positioning.FIXED)
    .withMinMaxWidth(
      DEFAULT_CONTAINER_MIN_WIDTH,
      true,
      DeviceWidth.DESKTOP_MAX_WIDTH,
      false
    )
    .withPushGraph({
      nodeIds: [],
      edges: [],
    })

  outerSectionBuilder.withChildren([
    innerSectionBuilder.withChildren([]).build(),
  ])
  layoutSectionBuilder.withChildren([outerSectionBuilder.build()])

  const layoutSection = layoutSectionBuilder.build()

  return layoutSection
}
