import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { Organization } from 'ducks/users/index'
import type { PlanType } from '@adalo/constants'

import {
  fetchOrganizations,
  fetchOrganization,
  getCurrentOrganizationId,
} from 'ducks/organizations'

import { requestApp } from 'utils/io'

import { getPlanChanged } from 'ducks/billing'

import { withRouter } from 'react-router-dom'
import Modal from '../Modal'
import Button from '../Button'

import {
  setPlanSelectedFlag,
  setSelectedPlanValue,
  setUpdateCardFlag,
} from '../../../ducks/trialWarning'
import unsubscribeCompleteLogo from '../Icon/icons/sad-face.png'

interface FetchDataInput {
  appId: string
}

interface UnsubscribeCompleteInput {
  history: {
    push: { (url: string): void }
  }
  match: {
    params: {
      appId: string
    }
  }
}

const FetchData = ({ appId }: FetchDataInput) => {
  const dispatch = useDispatch()
  const currentOrganizationId = useSelector(
    getCurrentOrganizationId
  ) as Organization
  const planChanged = useSelector(getPlanChanged) as PlanType

  useEffect(() => {
    requestApp(appId)
  }, [appId])

  useEffect(() => {
    dispatch(fetchOrganizations())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchOrganization(currentOrganizationId))
  }, [currentOrganizationId, dispatch])

  useEffect(() => {
    dispatch(setPlanSelectedFlag(false))
    dispatch(setSelectedPlanValue(''))
    dispatch(setUpdateCardFlag(false))
  }, [planChanged, dispatch])

  return null
}

const UnsubscribeComplete = ({ history, match }: UnsubscribeCompleteInput) => {
  const { appId } = match.params

  const handleClose = () => {
    if (appId) {
      history.push(`/apps/${appId}/screens`)
    } else {
      history.push(`/`)
    }
  }

  return (
    <Modal size="sm">
      <Modal.Content style={{ textAlign: 'center' }}>
        <Modal.Container>
          <img
            className="unsubscribeCompleteLogo"
            src={unsubscribeCompleteLogo}
            alt=""
          />
          <h1 className="trial-signup-unsubscribe-message">
            We're sad to see you go.
          </h1>
          <p>You can resubscribe in your Account Settings at any time.</p>
        </Modal.Container>
      </Modal.Content>
      <Modal.Actions>
        <Button large onClick={handleClose}>
          Done
        </Button>
      </Modal.Actions>
      <FetchData appId={appId} />
    </Modal>
  )
}

// TODO(itsdaiego) fix tsserver error
export default withRouter(UnsubscribeComplete)
