import { useSelector } from 'react-redux'
import { getDeviceType } from '@adalo/utils'
import { getParentScreenComponent } from 'ducks/editor/objects'
import { EditorObject } from 'utils/responsiveTypes'

/*
 * For a given objectId, get which device it's parent screen is set to
 */
const useObjectScreenDevice = (
  objectId: string
): 'mobile' | 'tablet' | 'desktop' => {
  const screen = useSelector(
    state => getParentScreenComponent(state, objectId) as EditorObject
  )

  const screenDevice = getDeviceType(screen?.width)

  return screenDevice
}

export default useObjectScreenDevice
