import { getId } from '@adalo/utils'
import { SELECT_PARENT } from '../selection'
import { getPanning } from './selectors'

export const REQUEST_DATA = Symbol('REQUEST_DATA')
export const SET_DATA = Symbol('SET_DATA')
export const CREATE_OBJECT = Symbol('CREATE_OBJECT')
export const UPDATE_OBJECT = Symbol('UPDATE_OBJECT')
export const UPDATE_OBJECTS = Symbol('UPDATE_OBJECTS')
export const CHANGE_OBJECT_TYPE = Symbol('CHANGE_OBJECT_TYPE')
export const RESIZE_OBJECT = Symbol('RESIZE_OBJECT')
export const POSITION_OBJECTS = Symbol('POSITION_OBJECTS')
export const DELETE_OBJECT = Symbol('DELETE_OBJECT')
export const REORDER_OBJECTS = Symbol('REORDER_OBJECTS')
export const REORDER_OBJECTS_MOVE_LAST = Symbol('REORDER_OBJECTS_MOVE_LAST')
export const REORDER_OBJECTS_MOVE_FIRST = Symbol('REORDER_OBJECTS_MOVE_FIRST')
export const REORDER_OBJECTS_MOVE_UP = Symbol('REORDER_OBJECTS_MOVE_UP')
export const REORDER_OBJECTS_MOVE_DOWN = Symbol('REORDER_OBJECTS_MOVE_DOWN')
export const GROUP_OBJECTS = Symbol('GROUP_OBJECTS')
export const GROUP_OBJECTS_TO_LIST = Symbol('GROUP_OBJECTS_TO_LIST')
export const UNGROUP_OBJECTS = Symbol('UNGROUP_OBJECTS')
export const SET_PAGE_SIZE = Symbol('SET_PAGE_SIZE')
export const ALIGN_OBJECTS = Symbol('ALIGN_OBJECTS')
export const ZOOM = Symbol('ZOOM')
export const RESET_ZOOM = Symbol('RESET_ZOOM')
export const PAN = Symbol('PAN')
export const SET_LIBRARY_GLOBAL = Symbol('SET_LIBRARY_GLOBAL')
export const RUN_INSTRUCTIONS = Symbol('RUN_INSTRUCTIONS')
export const RUN_SELECTION_INSTRUCTION = Symbol('RUN_SELECTION_INSTRUCTION')

export const requestData = appId => ({
  type: REQUEST_DATA,
  appId,
})

export const setData = (appId, data, app) => dispatch => {
  dispatch({
    type: SET_DATA,
    appId,
    data,
    app,
  })

  window.setTimeout(() => {
    dispatch({ type: '@@redux-undo/CLEAR_HISTORY' })
  }, 0)
}

export const createObject =
  (object, path = null, silent = false, mouseCoords = {}, parentId) =>
  (dispatch, getState) =>
    dispatch({
      type: CREATE_OBJECT,
      object,
      path,
      silent,
      id: getId(),
      mouseCoords,
      globalState: getState(),
      parentId,
    })

export const updateObject =
  (id, object, undoGroupKey) => (dispatch, getState) =>
    dispatch({
      type: UPDATE_OBJECT,
      id,
      object,
      undoGroup: undoGroupKey && `${undoGroupKey}-${id}`,
      globalState: getState(),
    })

/**
 *
 * @param {import('utils/responsiveTypes').EditorObject[]} objects
 * @returns
 */
export const updateObjects = objects => (dispatch, getState) =>
  dispatch({
    type: UPDATE_OBJECTS,
    objects,
    globalState: getState(),
  })

// TODO (michael-adalo): appears to be unused?
export const changeObjectType = (id, newType) => ({
  type: CHANGE_OBJECT_TYPE,
  id,
  newType,
})

export const resizeObject =
  (id, object, skipSave = false, resetAccordion = false) =>
  (dispatch, getState) =>
    dispatch({
      type: RESIZE_OBJECT,
      id,
      object,
      skipSave,
      globalState: getState(),
      resetAccordion,
    })

export const positionObjects =
  (ids, offset, shiftKey, shouldSave = true) =>
  (dispatch, getState) =>
    dispatch({
      type: POSITION_OBJECTS,
      ids,
      offset,
      shiftKey,
      shouldSave,
      globalState: getState(),
    })

export const alignObjects = direction => ({
  type: ALIGN_OBJECTS,
  direction,
})

export const deleteObject = id => ({
  type: DELETE_OBJECT,
  id,
})

export const reorderObjects = (ids, dropTarget, options) => ({
  type: REORDER_OBJECTS,
  ids,
  dropTarget,
  options,
})

export const reorderObjectsMoveFirst = ids => ({
  type: REORDER_OBJECTS_MOVE_FIRST,
  ids,
})

export const reorderObjectsMoveLast = ids => ({
  type: REORDER_OBJECTS_MOVE_LAST,
  ids,
})

export const reorderObjectsMoveUp = ids => ({
  type: REORDER_OBJECTS_MOVE_UP,
  ids,
})

export const reorderObjectsMoveDown = ids => ({
  type: REORDER_OBJECTS_MOVE_DOWN,
  ids,
})

export const groupObjectsToList = ids => ({
  type: GROUP_OBJECTS_TO_LIST,
  ids,
})

export const groupObjects = ids => ({
  type: GROUP_OBJECTS,
  ids,
})

export const ungroupObjects = ids => ({
  type: UNGROUP_OBJECTS,
  ids,
})

export const setZoom = (scale, offset, relativeScale, relativeOffset) => ({
  type: ZOOM,
  scale,
  offset,
  relativeScale,
  relativeOffset,
})

export const resetZoom = () => ({ type: RESET_ZOOM })

export const selectParent = id => ({
  type: SELECT_PARENT,
  objectId: id,
})

export const setPan = value => (dispatch, getState) => {
  const state = getState()
  const panning = getPanning(state)

  if (value === true && panning === true) return false

  return dispatch({
    type: PAN,
    value,
  })
}

export const setLibraryGlobals = (libraryName, componentName, changes) => ({
  type: SET_LIBRARY_GLOBAL,
  libraryName,
  componentName,
  changes,
})

export const runInstructions = instructions => (dispatch, getState) =>
  dispatch({
    type: RUN_INSTRUCTIONS,
    instructions,
    globalState: getState(),
  })

export const runSelectionInstruction = instruction => (dispatch, getState) =>
  dispatch({
    type: RUN_SELECTION_INSTRUCTION,
    instruction,
    globalState: getState(),
  })
