import React from 'react'
import { connect } from 'react-redux'

import { showModal, setPaymentRouteType } from 'ducks/trialWarning'

import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import './FreeFlow.scss'

const header = 'Take your app to the next level with premium features'

const perks = [
  'Personalize your app with custom fonts',
  `Get your app in front of your audience by publishing to iOS, Android, or
the web`,
  `Access your users' location to create a personalized user experience`,
  `Utilize custom actions to supercharge your app's functionality`,
  `Save design versions of your app to perfect your design ...and so much
more!`,
]

const FreePlanFlow = ({ showModal, setPaymentRouteType }) => {
  const handleShowModal = () => {
    setPaymentRouteType('upgrade')
    showModal()
  }

  return (
    <div className="free-plan-container">
      <h3 className="free-plan-header">
        <span className="free-plan-rocket" role="img" aria-label="Rocket">
          🚀
        </span>
        {header}
      </h3>
      <ul className="free-plan-perks">
        {perks.map(text => (
          <Perk text={text} key={text} />
        ))}
      </ul>
      <Button onClick={handleShowModal} data-adalo-id="upgrade-billing">
        See plan options
      </Button>
    </div>
  )
}

const Perk = ({ text }) => (
  <li className="free-plan-perk">
    <Icon className="free-plan-pink-check" color="darkPink" type="list-check" />
    {text}
  </li>
)

export default connect(null, { setPaymentRouteType, showModal })(FreePlanFlow)
