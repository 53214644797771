interface ExtCanvasRenderingContext2D extends CanvasRenderingContext2D {
  webkitBackingStorePixelRatio: number
  mozBackingStorePixelRatio: number
  msBackingStorePixelRatio: number
  oBackingStorePixelRatio: number
  backingStorePixelRatio: number
}
const ctxCache = new Map<string, ExtCanvasRenderingContext2D>()
const getContext2d = (font: string): ExtCanvasRenderingContext2D => {
  if (ctxCache.has(font)) {
    return ctxCache.get(font) as ExtCanvasRenderingContext2D
  }

  try {
    const ctx = document
      .createElement('canvas')
      .getContext('2d') as ExtCanvasRenderingContext2D
    if (!ctx) {
      throw new Error('Error creating canvas')
    }
    const dpr = window.devicePixelRatio || 1
    const bsr =
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1
    ctx.font = font
    ctx.setTransform(dpr / bsr, 0, 0, dpr / bsr, 0, 0)

    ctxCache.set(font, ctx)

    return ctx
  } catch (error: unknown) {
    throw new Error(`Canvas support required ${error as string}`)
  }
}

export const measureWidthWithFont = (text: string, font: string): number =>
  getContext2d(font).measureText(text).width
