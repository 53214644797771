/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'

import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import FreeTrialBanner from 'components/Shared/TrialWarning/FreeTrialBanner'

import {
  STATUS_IDLE,
  STATUS_SAVING,
  getApp,
  getAppScreenSaveStatus,
  getAppWebPublishStatus,
} from 'ducks/apps'
import { getDomains, requestDomains } from 'ducks/domains'
import { getPreviewUrl, getPublishUrl } from 'ducks/domains/utils'
import { showModal, setPaymentRouteType } from 'ducks/trialWarning'
import { resetSelection } from 'ducks/editor/selection'
import { getCurrentUser } from 'ducks/users'

import { PUBLISH } from 'utils/tabs'
import { useWebBuildList } from 'utils/builds/hooks'
import { forceSaveComponents, requestAppBuilds } from 'utils/io'
import useClickOutside from 'hooks/useClickOutside'

import rocketSrc from './assets/rocket.png'
import './ViewApp.scss'

const useViewApp = (appId: string) => {
  const dispatch = useDispatch()
  const app = useSelector(state => getApp(state, appId))
  const domains = useSelector(state => getDomains(state, app?.OrganizationId))
  const currentUser = useSelector(getCurrentUser)

  const { buildList, latestBuild } = useWebBuildList(appId)

  useEffect(() => {
    if (app?.OrganizationId) {
      dispatch(requestDomains(app.OrganizationId))
    }
  }, [appId, app?.OrganizationId])

  useEffect(() => {
    requestAppBuilds(appId, 'web', { order: 'DESC' })
  }, [appId])

  const publishUrl = getPublishUrl({ app, domains })
  const previewUrl = getPreviewUrl({
    app,
    isDeveloper: Boolean(currentUser?.developer),
  })
  const isActiveOrg = app?.Organization?.active
  const appIsPublished = Boolean(app?.published)

  let publishMessage = `All changes saved`
  if (appIsPublished && buildList.length > 0 && latestBuild?.completedAt) {
    const dateText = moment(latestBuild?.completedAt).fromNow()

    publishMessage = `Published ${dateText}`
  }

  return {
    app,
    publishUrl,
    previewUrl,
    publishMessage,
    isActiveOrg,
  }
}

type ViewAppOptionProps = {
  title: string
  description: string
  hoverDescription?: string | undefined
  url?: string | undefined
  disabled?: boolean
  iconType?: string
  showHover?: boolean
  openInNewTab?: boolean
}

const ViewAppOption: React.FC<ViewAppOptionProps> = props => {
  const {
    title,
    description,
    url,
    disabled,
    hoverDescription,
    iconType,
    showHover = true,
    openInNewTab = true,
  } = props

  const [hover, setHover] = useState(false)

  const anchorClass = classNames('view-app-dropdown-item', {
    'view-app-dropdown-item--disabled': disabled,
  })

  const currentDescription =
    hover && hoverDescription && showHover ? hoverDescription : description

  const dataAdaloId =
    title === 'Staging Preview' ? 'view-app-previewer' : 'view-app-share'

  const renderContent = () => (
    <>
      <div className="view-app-dropdown-item-text">
        <h3>{title}</h3>
        <p>{currentDescription}</p>
      </div>

      <div className="view-app-dropdown-item-icon">
        <Icon type={iconType ?? 'launch'} color="teal" />
      </div>
    </>
  )

  if (!openInNewTab && url) {
    return (
      <Link
        className={anchorClass}
        data-adalo-id={dataAdaloId}
        to={url}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {renderContent()}
      </Link>
    )
  }

  return (
    <a
      className={anchorClass}
      data-adalo-id={dataAdaloId}
      href={url}
      rel="noreferrer"
      target="_blank"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {renderContent()}
    </a>
  )
}

type DropdownProps = {
  appId: string
  publishUrl?: string | undefined
  previewUrl?: string | undefined
  publishMessage?: string | undefined
  appIsPublished?: boolean
  onClickOutside: () => void
  isLegacyApp?: boolean
  isMobileLegacy?: boolean
}

const SAVING_LABEL = 'Saving...'
const SAVED_LABEL = 'All changes saved'

const ViewAppDropdown: React.FC<DropdownProps> = props => {
  const {
    appId,
    publishUrl,
    previewUrl,
    publishMessage = SAVED_LABEL,
    appIsPublished,
    onClickOutside,
    isLegacyApp = false,
    isMobileLegacy = false,
  } = props

  const history = useHistory()
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)

  const appScreenSaveStatus = useSelector(state =>
    getAppScreenSaveStatus(state, appId)
  )

  const webPublishStatus = useSelector(state =>
    getAppWebPublishStatus(state, appId)
  )

  useClickOutside(wrapperRef, onClickOutside)

  const goToPublishing = () => {
    dispatch(resetSelection())
    history.push(`/apps/${appId}/${PUBLISH.path}`)
    onClickOutside()
  }

  // Share URL
  const { description: publishDescription, status: publishStatus } =
    useMemo(() => {
      // - if we have a legacy app, we need to wait for a normal save
      // - if we have a responsive app that is not published, we need to wait for a normal save
      if (isLegacyApp === true || !appIsPublished) {
        return {
          description:
            appScreenSaveStatus === STATUS_SAVING ? SAVING_LABEL : SAVED_LABEL,
          status: appScreenSaveStatus,
        }
      }

      // - if we have a responsive app that is published, we need to wait for a build to complete
      return {
        description:
          webPublishStatus === STATUS_SAVING ? SAVING_LABEL : publishMessage,
        status: webPublishStatus,
      }
    }, [
      isLegacyApp,
      appScreenSaveStatus,
      webPublishStatus,
      appIsPublished,
      publishMessage,
    ])

  const publishTitle = useMemo(() => {
    if (isLegacyApp) {
      return 'Share Your App'
    }

    if (appIsPublished) {
      return 'Share Your Published App'
    } else {
      return 'Share Your Test App'
    }
  }, [isLegacyApp, appIsPublished])

  const previewHoverDescription =
    previewUrl
      ?.replace('https://', '')
      .replace('http://', '')
      .replace(/\/.*/, '') ?? 'All changes saved'

  const publishHoverDescription =
    publishUrl?.replace('https://', '').replace('http://', '') ??
    'All changes saved'

  return (
    <div className="view-app-dropdown-wrapper" ref={wrapperRef}>
      <ViewAppOption
        title="Staging Preview"
        description={
          appScreenSaveStatus === STATUS_IDLE ? SAVED_LABEL : SAVING_LABEL
        }
        url={previewUrl}
        hoverDescription={previewHoverDescription}
        iconType="play"
        disabled={appScreenSaveStatus === STATUS_SAVING}
        openInNewTab={!isMobileLegacy}
        showHover={!isMobileLegacy}
      />

      <ViewAppOption
        title={publishTitle}
        description={publishDescription}
        url={publishUrl}
        hoverDescription={publishHoverDescription}
        disabled={publishStatus === STATUS_SAVING}
        showHover={!isLegacyApp || !isMobileLegacy}
      />

      <div
        className="view-app-dropdown-go-to-publishing"
        onClick={() => goToPublishing()}
        data-adalo-id="view-app-publishing"
      >
        <Icon type="done-all" size="small" />
        <p>Go To Publishing</p>
      </div>
    </div>
  )
}

const ViewApp: React.FC = () => {
  const { appId } = useParams<{ appId: string }>()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const { app, previewUrl, publishUrl, isActiveOrg, publishMessage } =
    useViewApp(appId)

  const canOpen = appId && previewUrl
  const appIsPublished = Boolean(app?.published)
  const isLegacyApp =
    app?.primaryPlatform === 'mobile' || app?.primaryPlatform === 'web'

  const onClick = () => {
    setExpanded(true)
    forceSaveComponents()
  }

  const handleShowModal = () => {
    dispatch(setPaymentRouteType('upgrade'))
    dispatch(showModal())
  }

  return (
    <div className="view-app-button-wrapper">
      {!isActiveOrg && (
        <div>
          <FreeTrialBanner
            appId={appId}
            onClick={() => handleShowModal()}
            bottomBar={false}
          />
        </div>
      )}
      <Button
        data-adalo-id="view-app-button"
        className="view-app-button"
        onClick={onClick}
        gradient
        teal
      >
        <img className="view-app-rocket" src={rocketSrc} alt="View App" />
        <p>VIEW APP</p>
      </Button>
      {expanded && canOpen && (
        <ViewAppDropdown
          appId={appId}
          appIsPublished={appIsPublished}
          previewUrl={previewUrl}
          publishUrl={publishUrl}
          publishMessage={publishMessage}
          onClickOutside={() => setExpanded(false)}
          isMobileLegacy={app?.primaryPlatform === 'mobile'}
          isLegacyApp={isLegacyApp}
        />
      )}
    </div>
  )
}

export default ViewApp
