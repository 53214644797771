import { useCallback, useEffect, useMemo } from 'react'
import { requestAppBuilds } from 'utils/io'
import { BuildStatus, BuildTarget, getAppBuildsState } from 'ducks/apps/builds'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { getApp } from 'ducks/apps'

// TODO(enzo): refactor ConnectedWebPublish to use these hooks

type Build = {
  versionText: string
  completedAt: string | undefined
  status: BuildStatus
  id: string
  target: BuildTarget
  version: string
  startedAt: string
}

export const usePollBuilds = (
  appId: string
): {
  refetchBuilds: () => void
} => {
  const fetchBuilds = () => requestAppBuilds(appId, 'web', { order: 'DESC' })

  useEffect(() => {
    fetchBuilds()

    const buildRefreshInterval = setInterval(fetchBuilds, 5000)

    return () => {
      if (buildRefreshInterval) {
        clearInterval(buildRefreshInterval)
      }
    }
  }, [])

  // Used to refetch builds so not to wait until the next build polling
  const refetchBuilds = useCallback(
    () => setTimeout(fetchBuilds, 500),
    [fetchBuilds]
  )

  return { refetchBuilds }
}

export const useWebBuildList = (
  appId: string
): {
  buildList: Build[]
  buildDisabled: boolean
  latestBuild: Build | undefined
} => {
  const app = useSelector(state => getApp(state, appId))
  const buildsState = useSelector(state =>
    getAppBuildsState(state, appId, 'web')
  )

  const loadingBuilds: boolean = buildsState ? buildsState.loading : true
  const appPublished = Boolean(app && app.published)

  const { buildList, buildDisabled } = useMemo(() => {
    const builds =
      buildsState?.list?.map((build, index: number) => {
        const versionText = moment(build.startedAt).format('MM/DD/YYYY')

        let { completedAt, status } = build

        if (status === 'built' && index === 0) {
          const completedAtDate = moment(completedAt)
          const secondsSinceCompleted = moment().diff(
            completedAtDate,
            'seconds'
          )
          // Since web builds are set to 'built' upon creation, builds that were created 5s ago are set to 'building'
          // so users get to see the loader & get UI feedback
          if (secondsSinceCompleted < 5) {
            status = 'building'
            completedAt = undefined
          }
        }

        return { ...build, versionText, completedAt, status }
      }) ?? []

    const disabled =
      !appPublished ||
      loadingBuilds ||
      builds?.some(
        build => build.status === 'queued' || build.status === 'building'
      )

    return { buildList: builds, buildDisabled: disabled }
  }, [loadingBuilds, buildsState, appPublished])

  const latestBuild = useMemo(() => {
    const latestObject = buildList.reduce((latest, current) => {
      if (latest?.completedAt) {
        if (!current?.completedAt) {
          return latest
        }

        const latestDate = new Date(latest?.completedAt)
        const currentDate = new Date(current?.completedAt)

        return currentDate > latestDate ? current : latest
      }

      return current
    }, buildList[0])

    return latestObject
  }, [buildList])

  return { buildList, buildDisabled, latestBuild }
}
