import geolocationIcon from 'components/Shared/Icon/icons/geolocation.svg'
import designVersionsIcon from 'components/Shared/Icon/icons/designVersions.svg'
import collectionsApiIcon from 'components/Shared/Icon/icons/collectionsApi.svg'
import customActionsIcon from 'components/Shared/Icon/icons/customActions.svg'
import premiumFontsIcon from 'components/Shared/Icon/icons/premiumFonts.svg'
import externalCollectionsIcon from 'components/Shared/Icon/icons/externalCollections.svg'

export const FREE_TRIAL_CARDS = [
  {
    title: 'Geolocation',
    iconSrc: geolocationIcon,
    iconBackground: '#EF4C301A',
    description: `Utilize this popular feature to access your users' locations to customize their experience.`,
    learnMoreLink: 'https://help.adalo.com/database/location',
    key: 'geolocation',
    isNew: true,
  },
  {
    title: 'Design Versions',
    iconSrc: designVersionsIcon,
    iconBackground: '#00A6951A',
    description:
      'Make changes, save designs, and restore versions with this feature.',
    learnMoreLink: 'https://help.adalo.com/design/design-versions',
    key: 'design-versions',
    isNew: true,
  },
  {
    title: 'Collections API',
    iconSrc: collectionsApiIcon,
    iconBackground: '#43437A1A',
    description: `Make your Collection data accessible through your app's very own custom API.`,
    learnMoreLink: 'https://help.adalo.com/integrations/the-adalo-api',
    key: 'collection-api',
    isNew: false,
  },
  {
    title: 'Custom Actions',
    iconSrc: customActionsIcon,
    iconBackground: '#FFC00E33',
    description:
      'Trigger unique actions to extend the functionality available in your app.',
    learnMoreLink: 'https://help.adalo.com/integrations/custom-actions',
    key: 'custom-actions',
    isNew: false,
  },
  {
    title: 'Google Fonts',
    iconSrc: premiumFontsIcon,
    iconBackground: '#F9EFF3',
    description:
      'Access over 1000 custom fonts perfect for customizing the look & feel of your app.',
    learnMoreLink: 'https://help.adalo.com/design/branding/fonts',
    key: 'custom-fonts',
    isNew: false,
  },
  {
    title: 'External Collections',
    iconSrc: externalCollectionsIcon,
    iconBackground: '#EF4C301A',
    description:
      'Connect & use the data you already have inside your Adalo-made app.',
    learnMoreLink:
      'https://help.adalo.com/integrations/external-collections-with-apis',
    key: 'external-collections',
    isNew: false,
  },
]

export const TYPES = {
  geolocation: {
    title: 'Geolocation',
    color: 'orange',
    buttonText: 'START USING GEOLOCATION',
    index: 0,
  },
  designVersions: {
    title: 'Design Versions',
    buttonText: 'CREATE FIRST DESIGN VERSION',
    color: 'teal',
    index: 1,
  },
  api: {
    title: 'Collections API',
    buttonText: 'START USING YOUR APP API ',
    color: 'purple',
    index: 2,
  },
  customActions: {
    title: 'Custom Actions',
    color: 'yellow',
    buttonText: 'CREATE NEW CUSTOM ACTION',
    index: 3,
  },
  customFonts: {
    title: 'Custom Fonts',
    buttonText: 'FIND A PERFECT FONT',
    color: 'darkPink',
    index: 4,
  },
  externalCollection: {
    title: 'External Collections',
    buttonText: 'ADD NEW EXTERNAL COLLECTION',
    color: 'orange',
    index: 5,
  },
  googleApiKey: {
    title: 'Geolocation',
    color: 'purple',
    buttonText: 'START USING GEOLOCATION',
    index: 6,
  },
}
