import { DeviceBreakpoint } from '@adalo/constants'

export const isFirefox = () => {
  return /firefox/i.test(navigator.userAgent)
}

export const setDocumentTitle = (title = 'Adalo') => {
  document.title = title
}

export const isMobileTouchDevice = () => {
  const hasTouchScreen =
    'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0

  const isMobile = window.innerWidth < DeviceBreakpoint.MOBILE_BREAKPOINT

  return hasTouchScreen && isMobile
}
