import React from 'react'

import OpenAIApiKey from './OpenAI'
import GoogleApiKey from './Google'

import './ThirdPartyApiKeys.scss'

const ThirdPartyApiKeys = props => {
  return (
    <div className="api-keys__container">
      <OpenAIApiKey {...props} />
      <GoogleApiKey {...props} />
    </div>
  )
}

export default ThirdPartyApiKeys
