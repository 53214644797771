import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import { openAccordion } from 'ducks/accordions'
import ControlField from 'components/Shared/Forms/ControlField'
import SimpleTextControl from 'components/Editor/Inspect/Libraries/SimpleTextControl'
import { WrappedBindableTextControl } from 'components/Shared/BindableTextControl'
import { IconButton } from 'components/Shared/Icon'
import { GroupedAccordion } from 'components/Shared/Accordion'
import Button from 'components/Shared/Button'
import EmptyState from 'components/Shared/EmptyState'

import './AuthFields.scss'

const MENU = [
  { label: 'Header', value: 'header' },
  { label: 'Query Param', value: 'query' },
]

const ACCORDION_NAME = 'api-auth-fields'

const TYPE_NAMES = { header: 'Header', query: 'Query Param' }

export const AuthItemTitle = props => {
  const {
    input: { value },
    onRemove,
  } = props

  const { type, name } = value || {}

  return (
    <>
      <div className="data-accordion-title-text">
        <p>{TYPE_NAMES[type]}</p>
        <p className="data-accordion-subtitle">{name || 'Empty'}</p>
      </div>
      <IconButton type="trash" onClick={onRemove} />
    </>
  )
}

export const AuthItemBody = ({ field }) => (
  <div className="form-inspect-field-wrapper">
    <ControlField
      hideBinding
      name={`${field}.name`}
      component={SimpleTextControl}
      displayName="Name"
      placeholder="Authorization"
    />
    <Field
      name={`${field}.value`}
      component={WrappedBindableTextControl}
      displayName="Value"
      placeholder="••••••••"
      disableFormulas
      externalAPIField
      enableTestValue
      fromModal
    />
  </div>
)

export const AuthItem = ({ field, onRemove, meta }) => (
  <div className="data-accordion-item">
    <GroupedAccordion
      hideCarret
      boxed
      group={ACCORDION_NAME}
      color="orange"
      itemId={field}
      title={
        <Field name={field} component={AuthItemTitle} onRemove={onRemove} />
      }
      renderChildren={() => <AuthItemBody field={field} />}
    />
  </div>
)

class AuthItems extends Component {
  handleAdd = type => {
    const { fields, openAccordion } = this.props

    openAccordion(ACCORDION_NAME, `auth[${fields.length}]`)

    fields.push({ type })
  }

  render() {
    const { fields } = this.props

    return (
      <div className="data-add-api-auth wrapped-input">
        <label>Auth Setup</label>
        {fields.map((field, index) => (
          <AuthItem
            key={field}
            onRemove={() => fields.remove(index)}
            field={field}
          />
        ))}
        {fields.length === 0 && (
          <EmptyState bordered>
            <p>No Auth Parameters</p>
          </EmptyState>
        )}
        <MultiMenuTrigger menu={MENU} onSelect={this.handleAdd}>
          <Button orange listAddButton icon="plus" type="button">
            Add Item
          </Button>
        </MultiMenuTrigger>
      </div>
    )
  }
}

export default connect(null, { openAccordion })(AuthItems)
