import { useFlags } from 'flags'

export default function useDebugMode(): boolean {
  const { hasDebugEnabled } = useFlags()

  if (typeof hasDebugEnabled === 'boolean') {
    return hasDebugEnabled
  } else if (typeof hasDebugEnabled === 'undefined') {
    return false
  } else {
    console.error(
      `[useDebugMode] Unexpected value for hasDebugEnabled flag:`,
      hasDebugEnabled
    )

    return false
  }
}
