import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import { getApp, updateApp, getUpgraded } from 'ducks/apps'

import Form, { FORM_NAME } from './Form'
import PublishURL from './PublishURL'

const PWAPublish = props => {
  const { app, appId, domainId, getAppHost, updateApp, needUpgrade } = props

  const handleSubmit = values => {
    let { path, DomainId } = values
    path = path || ''

    return updateApp(appId, { path, DomainId })
  }

  const appHost = getAppHost(domainId)

  const getInitialValues = () => {
    const DomainId = app?.DomainId
    const path = ''
    const orgId = app?.Organization?.id
    const appId = app?.id

    return {
      DomainId,
      path,
      orgId,
      appId,
    }
  }

  const initialValues = getInitialValues()

  return (
    <div className="pwa-publish-settings">
      <Form
        appId={appId}
        appHost={appHost}
        onSubmit={handleSubmit}
        needUpgrade={needUpgrade}
        initialValues={initialValues}
        platform={app.primaryPlatform}
      />
      <PublishURL appId={appId} getAppHost={getAppHost} />
    </div>
  )
}

PWAPublish.propTypes = {
  appId: PropTypes.string.isRequired,
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, { appId }) => {
  const app = getApp(state, appId)
  const domainId = selector(state, 'DomainId')
  const needUpgrade = !getUpgraded(state, appId)

  return {
    app,
    domainId,
    needUpgrade,
  }
}

const connected = connect(mapStateToProps, { updateApp })(PWAPublish)

export default React.memo(connected)
