import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { relativeDate } from '@adalo/utils'

import { getFeatureFlag } from 'ducks/featureFlags'

import BodyCell from './BodyCell'
import SelectCell from './SelectCell'

class Row extends Component {
  constructor(props) {
    super(props)

    this.bodyCellsRef = createRef()
  }

  getEditLink = () => {
    const {
      appId,
      datasourceId,
      tableId,
      row: { id },
    } = this.props

    return `/apps/${appId}/data/${datasourceId}/${tableId}/edit/${id}`
  }

  render() {
    const {
      datasource,
      table,
      row,
      fields,
      tableLayout,
      position,
      checked,
      handleSelectToggle,
      hasExposedRecordId,
    } = this.props

    const width = this.bodyCellsRef.current
      ? this.bodyCellsRef.current.offsetWidth + 50
      : 0

    return (
      <div className="data-browser-row" style={{ top: position, width }}>
        <SelectCell
          checked={checked}
          handleChange={selected => {
            return handleSelectToggle(row.id, selected)
          }}
        />
        <Link
          to={this.getEditLink()}
          className="data-browser-row-linked"
          id={row.id}
          innerRef={this.bodyCellsRef}
        >
          {fields.map(fieldId => (
            <BodyCell
              key={fieldId}
              fieldId={fieldId}
              field={table.fields[fieldId]}
              value={row[fieldId]}
              row={row}
              width={tableLayout[fieldId]}
              datasource={datasource}
            />
          ))}
          {hasExposedRecordId ? <BodyCell value={row.id} /> : null}
          <BodyCell
            value={relativeDate(row.created_at)}
            width={tableLayout.created_at}
          />
          <BodyCell
            value={relativeDate(row.updated_at)}
            width={tableLayout.updated_at}
          />
        </Link>
      </div>
    )
  }
}

export default connect(state => ({
  hasExposedRecordId: getFeatureFlag(state, 'hasExposedRecordId'),
}))(Row)
