import { EditorObject } from 'utils/responsiveTypes'
import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface DistributeVerticalInstruction {
  operation: 'distributeVertical'
  options: ActionOptions
}

export const distributeVerticalHandler = ({
  bounds, // eslint-disable-line @typescript-eslint/no-unused-vars
  objects,
}: HandlerOptions): LayoutInstruction[] => {
  const operations: LayoutInstruction[] = []

  if (objects.length < 3) {
    return operations
  }

  // sort objects from top to bottom
  objects.sort((a, b) => a.y - b.y)

  const topAnchor = objects[0] as EditorObject
  const bottomAnchor = objects[objects.length - 1] as EditorObject

  const middleObjects = objects.slice(1, -1)

  const distanceBetweenAnchors = bottomAnchor.y - (topAnchor.y + topAnchor.height) // prettier-ignore
  const middleObjectHeight = middleObjects.reduce((sum, obj) => sum + obj.height, 0) // prettier-ignore
  const gap = (distanceBetweenAnchors - middleObjectHeight) / (objects.length - 1) // prettier-ignore
  let currentY = topAnchor.y + topAnchor.height + gap

  for (const object of middleObjects) {
    operations.push(moveElement(object.id, object.x, currentY))

    currentY += object.height + gap
  }

  return operations
}

const distributeVertical = (
  objectIds: string[]
): DistributeVerticalInstruction => ({
  operation: 'distributeVertical',
  options: { objectIds },
})

export default distributeVertical
