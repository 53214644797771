import DeviceType from '../types/DeviceType'
import Document from '../model/Document'
import ObjectNode from '../model/ObjectNode'
import ObjectType from '../types/ObjectType'
import LayoutObject from '../model/layout/LayoutObject'

const findLayoutObject = (
  root: LayoutObject<ObjectType>,
  objectId: string
): LayoutObject<ObjectType> | undefined => {
  if (root.id === objectId) {
    return root
  }

  for (const child of root.children) {
    const result = findLayoutObject(child, objectId)
    if (result !== undefined) {
      return result
    }
  }

  return undefined
}

const layoutParent = (
  document: Document,
  childNode: ObjectNode<ObjectType>,
  device: DeviceType | undefined,
  screenWidth: number,
  screenHeight: number
): LayoutObject<ObjectType> => {
  const parentNode = childNode.getParent()
  if (parentNode === undefined) {
    throw new Error(
      `Parent node could not be found. (Object ID: ${childNode.id})`
    )
  }
  const { id: parentId } = parentNode

  const screenLayout = document.getScreen().layout({
    device,
    containerWidth: screenWidth,
    containerHeight: undefined,
    containerOffsetX: 0,
    containerOffsetY: 0,
    viewportWidth: screenWidth,
    viewportHeight: screenHeight,
    instruction: undefined,
  })

  const parentLayout = findLayoutObject(screenLayout, parentId)
  if (parentLayout === undefined) {
    throw new Error(
      `Could not find parent in layout tree. (Parent ID: ${parentId})`
    )
  }

  return parentLayout
}

export default layoutParent
