export const validate = data => {
  const errors = {}
  const inputs = {}

  if (data.inputs) {
    const example = data.inputs

    for (const itm of example) {
      if (!itm) continue

      inputs[itm.value.id] = itm.exampleText
    }
  }

  if (!data.type) {
    errors.type = 'Please select a type'
  }

  if (!data.name || !data.name.trim()) {
    errors.name = 'Cannot be blank'
  }

  if (
    !data.baseURL ||
    !parseBinding(data.baseURL, inputs).match(/^https?:\/\//)
  ) {
    errors.baseURL = 'Please enter a valid URL'
  }

  if (!data.method) {
    errors.method = 'Please select a method'
  }

  if (data.body && !validJSON(parseBinding(data.body, inputs))) {
    errors.body = 'Please enter a valid JSON body'
  }

  if (!data.testResults || !data.testResults.success) {
    errors.testResults = 'Test must pass before submitting'
  }

  return errors
}

const parseBinding = (value, inputs) => {
  let result = ''

  if (Array.isArray(value)) {
    for (const itm of value) {
      if (typeof itm === 'string') {
        const str = [...itm].map(char => {
          if (char.charCodeAt(0) === 160) {
            return ' '
          } else {
            return char
          }
        })

        result += str.join('')
      } else {
        result += inputs[itm.id]
      }
    }

    return result
  } else {
    return value
  }
}

const validJSON = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}
