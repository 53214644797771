import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { dataTypes } from '@adalo/constants'

import { requestData, openDatabaseSocket } from 'utils/io'
import { pluralize } from 'utils/strings'

import { getTable, getDatasource } from 'ducks/apps/datasources'
import { loadMetrics } from 'ducks/metrics'
import { invalidateThirdPartyApiKey } from 'ducks/thirdPartyApiKeys'

import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'
import Modal from 'components/Shared/Modal'
import Icon from 'components/Shared/Icon'
import GoogleHelpLink from 'components/Shared/GoogleHelpLink'

import Matching from './Matching'

import './CSVImport.scss'

const MIMETYPES = [
  'text/plain',
  'text/csv',
  'application/vnd.ms-excel',
  'text/x-csv',
  'application/csv',
  'application/x-csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
  'application/octet-stream',
]

class CSVImport extends Component {
  state = {
    done: false,
    csvData: null,
    header: null,
    error: null,
  }

  componentDidUpdate(prevProps, prevState) {
    const { done, error } = this.state
    const { match, loadMetrics, invalidateThirdPartyApiKey } = this.props

    const {
      params: { appId, datasourceId },
    } = match

    if (prevState.done !== done && done) {
      loadMetrics(appId, datasourceId)
    }

    if (
      !prevState.error &&
      error &&
      error.includes('The provided API key is invalid.')
    ) {
      invalidateThirdPartyApiKey(appId, 'google')
    }
  }

  getUploadURL = () => {
    const { match } = this.props
    const { datasourceId, tableId } = match.params
    const baseURL = process.env.REACT_APP_DATABASE_URL

    return `${baseURL}/databases/${datasourceId}/tables/${tableId}/import-csv`
  }

  handleGoBack = () => {
    const { history, match } = this.props
    const { appId, datasourceId, tableId } = match.params
    const url = `/apps/${appId}/data/${datasourceId}/${tableId}`

    return history.push(url)
  }

  handleDrop = (files, rejectedFiles) => {
    files = [...files, ...rejectedFiles]
    const file = files[0]

    const reader = new FileReader()

    this.setState({ loading: true })

    reader.onload = async evt => {
      const csvData = reader.result
      const firstRow = csvData.split(/[\r\n]/)[0].substring(0, 10000)

      if (!firstRow) {
        return
      }

      const header = (firstRow || '').split(',')
      this.setState({ csvData, header, loading: false })
    }

    if (file) {
      window.setTimeout(() => {
        reader.readAsText(file)
      }, 100)
    }
  }

  uploadCSV = config => {
    return new Promise((resolve, reject) => {
      const databaseSocket = openDatabaseSocket()
      databaseSocket.emit('upload_csv', config)

      databaseSocket.on('upload_csv_success', () => {
        databaseSocket.close()
        resolve()
      })

      databaseSocket.on('upload_csv_failed', error => {
        databaseSocket.close()
        reject(error)
      })
    })
  }

  handleImport = async mapping => {
    const { csvData } = this.state
    const { match, table } = this.props
    const { appId, datasourceId, tableId } = match.params

    const config = {
      databaseId: datasourceId,
      tableId,
      mapping,
      csv: csvData,
      appId,
    }

    try {
      await this.uploadCSV(config)

      // Reload Data
      requestData({ appId, datasourceId, tableId, table })

      this.setState({ done: true })
    } catch (error) {
      this.setState({ error })
    }
  }

  renderContent() {
    const { csvData, header, loading, done, error } = this.state
    const { table, datasource, match, hasLocationField } = this.props

    if (error) {
      let submessage = null

      const isAPIKeyError =
        error.includes('Missing Google Maps API Key') ||
        error.includes('The provided API key is invalid.')

      if (isAPIKeyError) {
        submessage = <GoogleHelpLink isError />
      } else {
        submessage = 'Please reload the page and try again.'
      }

      return (
        <>
          <Modal.Content>
            <Modal.Container size="sm">
              <div className="csv-import-modal-error">
                <h2>Something went wrong while importing CSV</h2>
                <p>{submessage}</p>
              </div>
            </Modal.Container>
          </Modal.Content>
          <Modal.Actions>
            <Modal.Button orange onClick={() => window.location.reload()}>
              Reload Page
            </Modal.Button>
          </Modal.Actions>
        </>
      )
    }

    if (done) {
      return (
        <>
          <Modal.Content>
            <Modal.Container size="sm">
              <div className="csv-import-modal-done">
                <h2>Imported Successfully!</h2>
              </div>
            </Modal.Container>
          </Modal.Content>
          <Modal.Actions>
            <Modal.Button orange onClick={this.handleGoBack}>
              Done
            </Modal.Button>
          </Modal.Actions>
        </>
      )
    }

    if (loading) {
      return (
        <EmptyState>
          <Loading large />
        </EmptyState>
      )
    }

    if (csvData) {
      return (
        <Matching
          header={header}
          table={table}
          onSubmit={this.handleImport}
          onCancel={this.handleGoBack}
          datasource={datasource}
          tableId={match.params.tableId}
        />
      )
    }

    let googleHelpLink = null

    if (hasLocationField) {
      googleHelpLink = (
        <GoogleHelpLink>
          <Icon type="info" color="orange" />
          &nbsp;
          <strong>
            To import locations, first add your&nbsp;
            <span className="help-link__link">Google Maps API Key</span>
          </strong>
        </GoogleHelpLink>
      )
    }

    return (
      <>
        <Modal.Content>
          <Modal.Container size="xs">
            <div className="csv-import">
              <h2>Import CSV File</h2>
              <p>
                We&apos;ll try our best to match the column headers in your file
                with the properties in your collection; but you&apos;ll be able
                to confirm those before we import the data records.{' '}
                <strong>
                  Make sure your CSV is formatted correctly before importing.{' '}
                  <a
                    href="https://help.adalo.com/database/importing-records-from-a-csv-file"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="csv-import-format-link"
                  >
                    Learn more
                  </a>
                </strong>
              </p>
              {googleHelpLink}
              <Dropzone onDrop={this.handleDrop} accept={MIMETYPES.join(',')}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="csv-import-modal-dropzone"
                    {...getRootProps()}
                    aria-disabled="false"
                    style={{ position: 'relative' }}
                  >
                    <h2>Drag &amp; Drop CSV File Here</h2>
                    <span>or</span>
                    <Modal.Button orange>Select a file</Modal.Button>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </div>
          </Modal.Container>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button text onClick={this.handleGoBack}>
            Cancel
          </Modal.Button>
        </Modal.Actions>
      </>
    )
  }

  renderTitle = () => {
    const { table } = this.props
    if (table.name) return `Import ${pluralize(table.name)}`

    return 'Import CSV'
  }

  render() {
    return (
      <div className="csv-import-modal">
        <Modal.Header
          title={this.renderTitle()}
          color="orange"
          goBack={this.handleGoBack}
        />
        {this.renderContent()}
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId, datasourceId, tableId } = match.params

  const datasource = getDatasource(state, appId, datasourceId)
  const table = getTable(state, appId, datasourceId, tableId)

  const hasLocationField = Object.values(
    datasource.tables[table.tableId].fields
  ).some(({ type }) => type === dataTypes.LOCATION)

  return {
    table,
    datasource,
    hasLocationField,
  }
}

export default connect(mapStateToProps, {
  loadMetrics,
  invalidateThirdPartyApiKey,
})(CSVImport)
