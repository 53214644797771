import { validateEmailForm } from 'components/Auth/utils'
import Button from 'components/Shared/Button'
import { FormRow } from 'components/Shared/Forms/FormRow'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import {
  CurrentUser,
  getCurrentUser,
  UserState,
  validateUserEmail,
} from 'ducks/users'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  DecoratedFormProps,
  Field,
  FormErrors,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from 'redux-form'
import Loading from 'components/Shared/Loading'
import QS from 'qs'
import { connect, DefaultRootState, useDispatch } from 'react-redux'
import { FormEventHandler } from 'react'
import AdaloLogotype from './AdaloLogotype'
import OnboardingLayout from './Layout'

const FORM_NAME = 'emailValidationForm'

type ValidationFormValues = {
  emailValidation: string
}

type Props = {
  currentUser: CurrentUser
  handleSubmit: (
    submit: FormSubmitHandler<ValidationFormValues, Props, string>,
    props?: DecoratedFormProps<ValidationFormValues, Props, string> | undefined,
    valid?: boolean | undefined,
    asyncValidate?: unknown,
    fields?: string[] | undefined
  ) => FormEventHandler<HTMLFormElement> | undefined
}

const UserEmailValidation = ({
  submitFailed,
  error,
  submitting,
  currentUser,
  handleSubmit,
}: Props & InjectedFormProps<ValidationFormValues, Props>) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const queryParams = QS.parse(location.search, { ignoreQueryPrefix: true })
  const { redirect, token } = queryParams
  const redirectQuery = QS.stringify({ redirect }, { addQueryPrefix: true })

  if (!currentUser) {
    return (
      <OnboardingLayout>
        <Loading expanded />
      </OnboardingLayout>
    )
  }

  const navigateToOnboarding = () => {
    if (token) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      history.push(`/onboarding?token=${token}`)
    } else {
      history.push('/onboarding')
    }
  }

  const submitFunction = (values: ValidationFormValues) => {
    const code = values.emailValidation

    dispatch(
      validateUserEmail({
        code,
        email: currentUser?.email,
        callback: navigateToOnboarding,
      })
    )
  }

  return (
    <OnboardingLayout>
      <form onSubmit={handleSubmit(submitFunction)} className="auth-form-v2">
        <div className="auth-form-v2__header">
          <AdaloLogotype />
          <h1>Validate Your Email</h1>
        </div>
        <p>Please enter the validation code sent to your email.</p>
        <div className="auth-form-v2__content">
          <FormRow className="email-validation-form-row">
            <Field
              autoFocus
              name="emailValidation"
              placeholder="123456"
              type="string"
              label="Validation Code"
              component={WrappedInput}
              autoComplete="string"
              maxLength={6}
            />
          </FormRow>
          <div className="auth-form-submission-row">
            <Button square gradient large teal fluid loading={submitting}>
              Let’s Do This!
            </Button>
          </div>
          {error && submitFailed && (
            <div className="auth-form-error">{error}</div>
          )}
        </div>
        <div className="auth-form-v2__footer">
          <div className="auth-form-link-v2">
            {'Already have an account? '}
            <Link to={`/login${redirectQuery}`} className="link-button">
              LOG IN
            </Link>
          </div>
        </div>
      </form>
    </OnboardingLayout>
  )
}

const validate = validateEmailForm as (
  values: ValidationFormValues,
  props: DecoratedFormProps<ValidationFormValues, Props, string>
) => FormErrors<ValidationFormValues, string>

const WrappedEmailValidation = reduxForm<ValidationFormValues, Props>({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: false,
})(UserEmailValidation)

const mapStateToProps = (state: DefaultRootState) => {
  const currentUser = getCurrentUser(state as UserState)

  return {
    currentUser,
  }
}

export default connect(mapStateToProps, { validateUserEmail })(
  WrappedEmailValidation
)
