import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ImageInput from '../../Shared/Forms/ImageInput'

class ImageControl extends Component {
  handleChange = img => {
    const { name, onChange } = this.props

    onChange({ [name]: img })
  }

  render() {
    const { value, match } = this.props
    const { appId } = match.params

    const input = {
      value,
      onChange: this.handleChange,
    }

    return (
      <div className="image-control">
        <ImageInput isAsset appId={appId} input={input} />
      </div>
    )
  }
}

export default withRouter(ImageControl)
