import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import { getTypeOptions } from 'utils/custom-actions'
import { getIcon } from 'utils/icons'
import { openAccordion } from 'ducks/accordions'
import ControlField from 'components/Shared/Forms/ControlField'
import SimpleTextControl from 'components/Editor/Inspect/Libraries/SimpleTextControl'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import Icon, { IconButton } from 'components/Shared/Icon'
import { GroupedAccordion } from 'components/Shared/Accordion'
import Button from 'components/Shared/Button'
import EmptyState from 'components/Shared/EmptyState'

const uuid = require('uuid/v4')

const MENU = [
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' },
]

const ACCORDION_NAME = 'api-request-inputs'

export const InputItemTitle = props => {
  const {
    input: { value },
    onRemove,
  } = props

  const { type, label } = value || {}
  const iconType = getIcon(type)

  return (
    <>
      <Icon type={iconType} />
      <div className="data-accordion-title-text">
        <p>{label}</p>
      </div>
      <IconButton type="trash" onClick={onRemove} />
    </>
  )
}

export const InputItemBody = ({ field }) => (
  <div className="form-inspect-field-wrapper">
    <ControlField
      hideBinding
      name={`${field}.type`}
      component={MenuControl}
      displayName="Type"
      placeholder="Select a Type"
      options={getTypeOptions(MENU)}
    />
    <ControlField
      hideBinding
      name={`${field}.label`}
      component={SimpleTextControl}
      displayName="Name"
      placeholder="Input Name"
    />
    <ControlField
      hideBinding
      name={`${field}.exampleText`}
      component={SimpleTextControl}
      displayName="Example Value"
      placeholder="e.g. 'Testing Custom Action!'"
    />
  </div>
)

export const InputItem = ({ field, onRemove, meta }) => (
  <div className="data-accordion-item">
    <GroupedAccordion
      hideCarret
      boxed
      group={ACCORDION_NAME}
      color="orange"
      itemId={field}
      title={
        <Field name={field} component={InputItemTitle} onRemove={onRemove} />
      }
      renderChildren={() => <InputItemBody field={field} />}
    />
  </div>
)

class InputItems extends Component {
  handleAdd = type => {
    const { fields, openAccordion } = this.props

    openAccordion(ACCORDION_NAME, `inputs[${fields.length}]`)

    fields.push({ label: 'New Input', type, value: { id: uuid() } })
  }

  render() {
    const { fields } = this.props

    return (
      <div className="data-add-api-auth wrapped-input">
        <label>Inputs</label>
        {fields.map((field, index) => (
          <InputItem
            key={field}
            onRemove={() => fields.remove(index)}
            field={field}
          />
        ))}
        {fields.length === 0 && (
          <EmptyState bordered>
            <p>No Inputs</p>
          </EmptyState>
        )}
        <MultiMenuTrigger
          menu={getTypeOptions(MENU)}
          rowHeight={40}
          onSelect={this.handleAdd}
        >
          <Button orange listAddButton icon="plus" type="button">
            Add Item
          </Button>
        </MultiMenuTrigger>
      </div>
    )
  }
}

export default connect(null, { openAccordion })(InputItems)
