import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Link component to use with react-markdown
 */
const MarkdownLink = ({ href, children, linkType }) => {
  switch (linkType) {
    case 'internal':
      return <Link to={href}>{children}</Link>
    case 'external':
    default:
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
  }
}

export default MarkdownLink
