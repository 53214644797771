import React from 'react'
import classNames from 'classnames'

import { getFieldIcon } from 'utils/icons'
import { ACTION, CONDITION, defaultFieldPolicy } from 'utils/permissions'
import { getOrderedFields } from 'ducks/apps/datasources'
import { GroupedAccordion } from 'components/Shared/Accordion'
import Icon from 'components/Shared/Icon'
import SelectMenu from './SelectMenu'

import './UserPropertiesList.scss'
import FieldList from './FieldList'

// Used in drop-down lists (title casing)
const CONDITION_LABELS = {
  [CONDITION.ANYONE]: 'Everyone',
  [CONDITION.LOGGED_IN]: 'Only Logged In Users',
  [CONDITION.OWNER]: 'Only the Record Creator',
  [CONDITION.NONE]: 'Nobody',
}

function fieldRelationshipType(fieldType) {
  return typeof fieldType === 'object' && fieldType?.type
}

function PropertyTitle({
  fieldName,
  unsavedChanges,
  read,
  update,
  isOpen,
  displayBadge,
}) {
  const readLabel = CONDITION_LABELS[read]
  const updateLabel = CONDITION_LABELS[update]

  const subtitle = `${readLabel} can view, ${updateLabel} can edit`

  return (
    <>
      <div className="property-title">
        <p>{fieldName}</p>
        <p className="property-subtitle">{subtitle}</p>
      </div>
      {unsavedChanges && <p className="property-unsaved">Unsaved Changes</p>}
      {displayBadge && (
        <Icon
          className={classNames({
            'icon-badge': true,
            open: isOpen,
          })}
          type="security-badge"
        />
      )}
    </>
  )
}

function PropertyOption({ value, Label, tooltip, disabled, onChange }) {
  const conditionOptions = []

  for (const value of Object.keys(CONDITION)) {
    conditionOptions.push({
      value,
      label: CONDITION_LABELS[value],
    })
  }

  return (
    <div className="property-option">
      <SelectMenu
        value={value}
        options={conditionOptions}
        Label={Label}
        tooltip={tooltip}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

function PropertyBody({ fieldName, fieldType, read, update, onRoleChange }) {
  let readDisabled = false
  let handleReadChanged = value => onRoleChange(ACTION.READ, value)
  let readTooltip = 'Select who can view data within this property'

  if (fieldType === 'password') {
    readDisabled = true
    // We always want to say passwords are unreadable because anything else would imply people can read our hashed passwords which would be confusing.
    // The current policy does not matter at all as our implementation of passwords ensures they are never readable.
    read = CONDITION.NONE
    handleReadChanged = () => {}
    readTooltip = 'Nobody has access to view password data (Adalo default)'
  }

  return (
    <div className="property-options">
      <PropertyOption
        value={read}
        Label={() => (
          <>
            Who can <strong>view</strong>&#x20;{fieldName.toLowerCase()}?
          </>
        )}
        tooltip={readTooltip}
        disabled={readDisabled}
        onChange={handleReadChanged}
      />
      <PropertyOption
        value={update}
        Label={() => (
          <>
            Who can <strong>edit</strong>&#x20;{fieldName.toLowerCase()}?
          </>
        )}
        tooltip="Select who can change data within this property"
        onChange={value => onRoleChange(ACTION.UPDATE, value)}
      />
    </div>
  )
}

function PropertyRow({
  fieldId,
  fieldName,
  fieldType,
  read,
  update,
  unsavedChanges,
  accordionGroup,
  displayBadge,
  onRoleChange,
}) {
  const relationshipType = fieldRelationshipType(fieldType)

  const icon = getFieldIcon(fieldType)

  // We display a faded badge when all permissions are fully open on the property
  const open = read === CONDITION.ANYONE && update === CONDITION.ANYONE

  return (
    <div className="property-row">
      <GroupedAccordion
        hideCarret
        boxed
        icon={icon}
        group={accordionGroup}
        color="orange"
        light
        hideDone
        itemId={fieldId}
        disabled={!!relationshipType}
        title={
          <PropertyTitle
            fieldName={fieldName}
            unsavedChanges={unsavedChanges}
            read={read}
            update={update}
            isOpen={open}
            displayBadge={displayBadge}
          />
        }
        renderChildren={() => (
          <PropertyBody
            fieldName={fieldName}
            fieldType={fieldType}
            read={read}
            update={update}
            onRoleChange={onRoleChange}
          />
        )}
      />
    </div>
  )
}

export default function PermissionsList({
  table,
  policies,
  isUsersTable,
  unsavedFields,
  accordionGroup,
  displayBadges,
  onPolicyChange,
}) {
  const fieldIds = getOrderedFields(table)

  const policyMap = {}

  for (const { fieldId, action, allowed } of policies) {
    policyMap[fieldId] = {
      ...policyMap[fieldId],
      [action]: allowed,
    }
  }

  const rowKeys = fieldIds.filter(fieldId => {
    const { type } = table.fields[fieldId]

    // Hide relationship properties
    return !fieldRelationshipType(type)
  })

  const rows = rowKeys.reduce((cur, fieldId) => {
    const field = table.fields[fieldId]
    const fieldPolicy = policyMap[fieldId]

    const read =
      (fieldPolicy && fieldPolicy[ACTION.READ]) ||
      defaultFieldPolicy(isUsersTable, fieldId, ACTION.READ).allowed

    const update =
      (fieldPolicy && fieldPolicy[ACTION.UPDATE]) ||
      defaultFieldPolicy(isUsersTable, fieldId, ACTION.UPDATE).allowed

    const unsavedChanges = unsavedFields.includes(fieldId)

    const row = (
      <PropertyRow
        key={fieldId}
        fieldId={fieldId}
        fieldName={field.name}
        fieldType={field.type}
        read={read}
        update={update}
        unsavedChanges={unsavedChanges}
        accordionGroup={accordionGroup}
        displayBadge={displayBadges}
        onRoleChange={(action, allowed) =>
          onPolicyChange(fieldId, action, allowed)
        }
      />
    )

    return { ...cur, [fieldId]: row }
  }, {})

  return (
    <FieldList
      heading="Properties"
      itemKeys={rowKeys}
      renderItem={key => rows[key]}
    />
  )
}
