import { borderStyles, TABLE } from '@adalo/constants'
import { createContext } from 'react'
import { defaults } from 'utils/objects'
import type { EditorObject, ThemeShadow } from 'utils/responsiveTypes'

const tableDefault = defaults[TABLE]
if (typeof tableDefault === 'undefined') {
  throw new Error('Table default theme not found')
}

const TableThemeContext = createContext({
  // TODO(michael-adalo): Possibly not used within context
  borderStyle: borderStyles.SOLID as NonNullable<EditorObject['borderStyle']>,
  borderWidth: 1,
  borderColor: '#BDBDBD',
  borderRadius: 0,
  //
  backgroundColor: '#FFFFFF',
  tableRowFontColor: '#424242',
  tableRowLineColor: '#F0F0F0',
  tableColumnFontColor: '#000000',
  tableColumnBackgroundColor: '#EEEEEE',
  tableColumnLineColor: '#BDBDBD',
  tableRowHoverBackgroundColor: '#F5F5F5',
  //
  shadow: tableDefault.shadow as ThemeShadow,
  //
  fontFamily: 'inherit',
  styles: {
    emptyStateTitleText: {
      fontSize: 18,
      fontWeight: '500',
      fontStyle: 'normal',
      fontFamily: '@heading',
      color: '#656565',
    },
    emptyStateBodyText: {
      fontSize: 12,
      fontWeight: '300',
      fontStyle: 'normal',
      fontFamily: '@body',
      color: '#757575',
    },
  },
})

export default TableThemeContext
