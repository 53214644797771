import React from 'react'
import classNames from 'classnames'

import './TabBar.scss'

export default function TabBar({ tabs, activeTab, TabLabel, onChange }) {
  if (!TabLabel) {
    TabLabel = DefaultTabLabel
  }

  return (
    <div className="tab-bar">
      <div className="tab-bar__tabs">
        {tabs.map(({ value, label }) => (
          <Tab
            key={value}
            value={value}
            label={label}
            active={value === activeTab}
            TabLabel={TabLabel}
            onSelect={onChange}
          />
        ))}
      </div>
    </div>
  )
}

function Tab({ value, label, active, TabLabel, onSelect }) {
  const handleSelect = () => {
    if (onSelect && !active) {
      onSelect(value)
    }
  }

  return (
    <div
      className={classNames('tab-bar-tab', { 'tab-bar-tab--active': active })}
      onClick={handleSelect}
    >
      <div className="tab-bar-tab__label">
        <TabLabel>{label}</TabLabel>
      </div>
    </div>
  )
}

function DefaultTabLabel({ children }) {
  return <p>{children}</p>
}
