import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getLaunchComponentId,
  getAuthComponentId,
  setLaunchComponentId,
  setAuthComponentId,
  unsetLaunchComponent,
} from '../../../ducks/apps'

import Icon from '../../Shared/Icon'
import MenuControl from '../Inspect/Libraries/MenuControl'

import './LaunchComponent.css'

const options = {
  home: {
    title: 'Home Screen',
    icon: 'home',
    description: 'First screen if a user is logged in',
  },
  login: {
    title: 'Welcome Screen',
    icon: 'user-login',
    description: 'First screen if a user is NOT logged in',
  },
  default: {
    title: 'Normal Screen',
    icon: 'screen',
  },
}

class LaunchComponentItem extends Component {
  render() {
    const { title, description, icon } = this.props

    return (
      <div className="launch-component-item">
        <Icon type={icon} />
        <div className="launch-component-item-info">
          <h6>{title}</h6>
          <p className="deemphasize">{description}</p>
        </div>
      </div>
    )
  }
}

class LaunchComponent extends Component {
  handleSelectAuth = () => {
    const { setAuthComponentId, appId, componentId } = this.props
    setAuthComponentId(appId, componentId)
  }

  handleSelectLaunch = () => {
    const { setLaunchComponentId, appId, componentId } = this.props
    setLaunchComponentId(appId, componentId)
  }

  handleDeselect = () => {
    const { appId, componentId, unsetLaunchComponent } = this.props

    unsetLaunchComponent(appId, componentId)
  }

  getValue = () => {
    const { isLaunchComponent, isAuthComponent } = this.props

    if (isLaunchComponent) {
      return 'home'
    } else if (isAuthComponent) {
      return 'login'
    }

    return 'default'
  }

  getOpt = () => {
    const { isAuthComponent } = this.props

    const value = this.getValue()
    let opt = options[value] || options.default

    if (value === 'home' && isAuthComponent) {
      opt = {
        ...opt,
        title: `${opt.title}, ${options.login.title}`,
      }
    }

    return opt
  }

  getLabel = opt => {
    const { title, icon } = this.getOpt()

    return <LaunchComponentItem title={title} icon={icon} />
  }

  getOptions = () => {
    return Object.keys(options).map(value => ({
      value,
      label: <LaunchComponentItem {...options[value]} />,
    }))
  }

  handleChange = ({ value }) => {
    if (value === 'login') {
      this.handleSelectAuth()
    } else if (value === 'home') {
      this.handleSelectLaunch()
    } else {
      this.handleDeselect()
    }
  }

  render() {
    const value = this.getValue()

    return (
      <div className="library-inspect-wrapper">
        <MenuControl
          displayName="Screen Navigation Type"
          rowHeight={60}
          value={value}
          options={this.getOptions()}
          getLabel={this.getLabel}
          onChange={this.handleChange}
          name="value"
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, componentId }) => ({
  isLaunchComponent: componentId === getLaunchComponentId(state, appId),
  isAuthComponent: componentId === getAuthComponentId(state, appId),
})

export default connect(mapStateToProps, {
  setLaunchComponentId,
  setAuthComponentId,
  unsetLaunchComponent,
})(LaunchComponent)
