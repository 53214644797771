import { uploadSketchFile as uploadSketch } from '../../utils/io'

const UPLOAD_SKETCH_START = Symbol('UPLOAD_SKETCH_START')
const UPLOAD_SKETCH_SUCCESS = Symbol('UPLOAD_SKETCH_SUCCESS')
const UPLOAD_SKETCH_ERROR = Symbol('UPLOAD_SKETCH_ERROR')
const UPLOAD_SKETCH_PROGRESS = Symbol('UPLOAD_SKETCH_PROGRESS')

// Reducer

export default (state, action) => {
  if (action.type === UPLOAD_SKETCH_START) {
    return {
      ...state,
      sketchUpload: {
        ...state.sketchUpload,
        progress: null,
        uploadInProgress: true,
      },
    }
  }

  if (action.type === UPLOAD_SKETCH_SUCCESS) {
    return {
      ...state,
      loading: true,
      sketchUpload: {
        ...state.sketchUpload,
        uploadInProgress: false,
      },
    }
  }

  if (action.type === UPLOAD_SKETCH_ERROR) {
    const { error } = action

    return {
      ...state,
      sketchUpload: {
        ...state.sketchUpload,
        uploadInProgress: false,
        uploadError: error,
        progress: null,
      },
    }
  }

  if (action.type === UPLOAD_SKETCH_PROGRESS) {
    const { progress } = action

    if (!state.sketchUpload.uploadInProgress) {
      return state
    }

    return {
      ...state,
      sketchUpload: {
        ...state.sketchUpload,
        progress,
      },
    }
  }
}

// Actions

export const uploadSketchFile = (appId, fileString, fileType) => dispatch => {
  dispatch({
    type: UPLOAD_SKETCH_START,
  })

  window.setTimeout(() => {
    console.log('Upload starting...')

    uploadSketch(appId, fileString, fileType, (...result) => {
      console.log('Upload complete!')

      dispatch({
        type: UPLOAD_SKETCH_SUCCESS,
      })
    })
  }, 10)
}

export const uploadError = error => ({
  type: UPLOAD_SKETCH_ERROR,
  error,
})

export const uploadProgress = progress => ({
  type: UPLOAD_SKETCH_PROGRESS,
  progress,
})

// Selectors

export const getUploadInProgress = state => {
  return state.editor.objects.present.sketchUpload.uploadInProgress
}

export const getUploadProgress = state => {
  return state.editor.objects.present.sketchUpload.progress
}
