import { COMPONENT, GROUP, SECTION } from '@adalo/constants'
import { LayoutSectionPurpose } from 'utils/layoutSections'
import { EditorObject } from 'utils/responsiveTypes'

const shouldResizeParent = (
  obj: EditorObject,
  magicLayout = false
): boolean => {
  const condition =
    obj.width === 0 ||
    obj.height === 0 ||
    obj.width === Infinity ||
    (obj.purpose === LayoutSectionPurpose.LAYOUT_HELPER && obj.type === SECTION)

  if (magicLayout) {
    return obj.type !== COMPONENT && condition
  }

  return obj.type !== COMPONENT && (obj.type === GROUP || condition)
}

export default shouldResizeParent
