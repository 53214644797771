import qs from 'qs'
import { defaultAxios } from './io/http/axios'

const extensions = {
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
}

const imageTypes = new Set(Object.values(extensions))

export const isImage = key => {
  key = String(key)

  const parts = key.split('.')
  const extension = parts[parts.length - 1]

  return imageTypes.has(extension)
}

export const uploadURL = (key, height = null, opts = {}) => {
  if (!key) return null

  if (key && typeof key === 'object') {
    key = key.url || key.key
  }

  if (!isImage(key)) {
    return `${process.env.REACT_APP_FILE_UPLOADS_URL}/${key}`
  }

  const params = { orient: '', auto: 'compress', ...opts }

  if (height !== null) {
    params.h = height
  }

  const baseURL = process.env.REACT_APP_IMAGE_UPLOADS_URL

  return `${baseURL}/${key}?${qs.stringify(params)}`
}

export const getFilename = dataURL => {
  const splitData = dataURL.split(',')
  const match = splitData[0].match(/^data:([\w\/]+);.*$/i)

  if (!match) {
    throw new Error('Malformatted dataURL')
  }

  const mimetype = match[1]
  const extension = extensions[mimetype.toLowerCase()]

  if (!extension) {
    throw new Error(`Invalid file type: ${mimetype}`)
  }

  return `file.${extension}`
}

export const uploadImage = async (dataURL, filename = null, extension) => {
  const data = dataURL.split(',')[1]

  if (!filename) {
    filename = getFilename(dataURL)
  }

  const endpointURL = `${process.env.REACT_APP_DATABASE_URL}/uploads`

  const result = await defaultAxios.post(endpointURL, {
    filename,
    data,
    extension,
  })

  return result.data
}
