const validation = value => {
  if (value && value.toLowerCase() === 'users') {
    return '"Users" is a reserved name'
  }

  if (value !== undefined && value !== '') return undefined

  return 'Required'
}

export default validation
