import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

import {
  evaluateLibraryProps,
  getComponentInfo,
  getLibraryComponent,
} from 'utils/libraries'
import { getShortLabel } from 'utils/sources'
import { scaleValue } from 'utils/zoom'
import { normalizeFont } from 'utils/type'

import {
  isLibraryLoading,
  isLibraryReady,
  isLibraryError,
} from 'ducks/editor/libraries'
import { getLibraryGlobals } from 'ducks/editor/objects'
import { getAppBranding, getAppLibraries } from 'ducks/apps'
import { getAccordionState, LIBRARY_INSPECT_GROUP } from 'ducks/accordions'

import LibraryComponentWrapper from './LibraryComponentWrapper'
import LibraryComponentError from './LibraryComponentError'

import './LibraryComponent.scss'

const criticalProps = new Set([
  'object',
  'zoom',
  'libraryVersion',
  'branding',
  'libraryGlobals',
  'openAccordion',
  'isLibraryLoading',
  'isLibraryReady',
  'isLibraryError',
  'deviceType',
  'x',
  'y',
])

class LibraryComponent extends Component {
  shouldComponentUpdate = newProps => {
    for (const prop of criticalProps) {
      // eslint-disable-next-line react/destructuring-assignment
      if (!isEqual(newProps[prop], this.props[prop])) {
        return true
      }
    }

    return false
  }

  render() {
    const {
      object,
      zoom,
      libraryVersion,
      getLabel,
      match,
      x,
      y,
      branding,
      libraryGlobals,
      openAccordion,
      deviceType,
      screenHeight,
      screenWidth,
      reRenderOnMove,
      isLibraryLoading,
      isLibraryReady,
      isLibraryError,
      library,
    } = this.props

    const accordion = openAccordion?.includes(object.id)
      ? openAccordion.split('-')[1]
      : null

    const { appId } = match.params
    const { libraryName, componentName, width, height, editorResizingProps } = object // prettier-ignore

    const boundingWidth = editorResizingProps?.relativeWidth
      ? '100%'
      : scaleValue(width + 40, zoom)
    const boundingHeight = scaleValue(height + 40, zoom)
    const offsetX = x - scaleValue(20, zoom)
    const offsetY = y - scaleValue(20, zoom)

    const widthScaled = editorResizingProps?.relativeWidth
      ? '100%'
      : scaleValue(width, zoom)
    const heightScaled = scaleValue(height, zoom)

    const styleHeight = undefined

    const wrapperProps = {
      boundingHeight,
      boundingWidth,
      offsetX,
      offsetY,
      zoom,
      styleHeight,
      reRenderOnMove,
    }

    if (isLibraryReady === false) {
      if (isLibraryLoading === true) {
        return null
      }

      if (isLibraryError === true) {
        return (
          <LibraryComponentWrapper {...wrapperProps}>
            <LibraryComponentError object={object} />
          </LibraryComponentWrapper>
        )
      }
    }

    // NOTE: getting this value relies on library being set on window.protonLibraries
    const info = getComponentInfo(libraryName, libraryVersion, componentName)

    const props = {
      ...evaluateLibraryProps(info, object.attributes, getLabel, {
        branding,
        libraryGlobals,
      }),
      appId,
      _fonts: {
        body: normalizeFont('@body', branding),
        heading: normalizeFont('@heading', branding),
      },
      _width: width,
      _height: height,
      _getUserValue: () => Promise.resolve(null),
      _setUserValue: (key, value) => Promise.resolve(value),
      openAccordion: accordion,
      _deviceType: deviceType,
      _screenHeight: screenHeight,
      _screenWidth: screenWidth,
      _layoutGuides: {
        top: 0,
        bottom: 0,
      },
    }

    // NOTE: getting this value relies on library being set on window.protonLibraries
    const ComponentClass = getLibraryComponent(
      libraryName,
      libraryVersion,
      componentName
    )

    return (
      <g>
        <LibraryComponentWrapper
          {...wrapperProps}
          styleHeight={info?.resizeY ? height + 40 : undefined}
        >
          {isLibraryReady && !library?.licensed ? (
            <div className="library-component-missing-license" />
          ) : null}
          <ComponentClass {...props} editor />
        </LibraryComponentWrapper>

        <rect
          x={x}
          y={y}
          width={widthScaled}
          height={heightScaled}
          fill="none"
          stroke="none"
        />
      </g>
    )
  }
}

const mapStateToProps = (state, { match, object, libraryVersion }) => {
  const appLibraries = getAppLibraries(state, match.params.appId)

  return {
    isLibraryLoading: isLibraryLoading(state, object.libraryName, libraryVersion), // prettier-ignore
    isLibraryReady: isLibraryReady(state, object.libraryName, libraryVersion),
    isLibraryError: isLibraryError(state, object.libraryName, libraryVersion),
    getLabel: source =>
      getShortLabel(
        state,
        source,
        match.params.appId,
        match.params.componentId
      ),
    branding: getAppBranding(state, match.params.appId),
    libraryGlobals: getLibraryGlobals(
      state,
      object.libraryName,
      object.componentName
    ),
    openAccordion: getAccordionState(state, LIBRARY_INSPECT_GROUP),
    library: appLibraries.find(l => l.name === object.libraryName),
  }
}

export default withRouter(connect(mapStateToProps)(LibraryComponent))
