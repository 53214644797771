import React, { useEffect } from 'react'
import { reduxForm, Field } from 'redux-form'

import SettingsModalField, {
  SettingsModalSubmit,
} from 'components/Shared/Forms/SettingsModalField'

import SimpleToggle from 'components/Shared/Forms/SimpleToggle'

import Button from 'components/Shared/Button'
import { validateSignUpForm as signupValidate } from 'components/Auth/utils'
import {
  isMaliciousHTML,
  MALICIOUS_ERROR_MESSAGE,
} from '../../../utils/validation'

export const FORM_NAME = 'accountForm'

const validate = data => {
  const errors = signupValidate(data)

  if (!data.password) {
    delete errors.password
  }

  if (data.password && !data.oldPassword) {
    errors.oldPassword = 'Required when changing password'
  }

  if (isMaliciousHTML(data.name)) {
    errors.name = MALICIOUS_ERROR_MESSAGE
  }

  if (isMaliciousHTML(data.teamName)) {
    errors.teamName = MALICIOUS_ERROR_MESSAGE
  }

  return errors
}

const AccountForm = ({
  handleSubmit,
  valid,
  dirty,
  submitting,
  formVisible,
  passwordVisible,
  dirtyForm,
  closeForm,
  reset,
}) => {
  const shutForm = () => {
    closeForm()
    reset()
  }

  useEffect(() => {
    dirtyForm(dirty)
  }, [dirty])

  return (
    <form onSubmit={handleSubmit}>
      {formVisible && (
        <div className="settings-form-fields">
          <Field
            component={SettingsModalField}
            name="email"
            label="Email Address"
            placeholder="me@example.com"
            type="email"
          />
          <Field
            component={SettingsModalField}
            name="name"
            label="Your Name"
            placeholder="Jane Doe"
            type="text"
          />
        </div>
      )}
      <Field
        largeHelpText
        component={SimpleToggle}
        name="developer"
        inputValue="Enable Developer Mode"
        helpText={
          <span>
            {'Learn more at '}
            <a
              href="https://developers.adalo.com/"
              target="_blank"
              rel="noreferrer"
            >
              developers.adalo.com
            </a>
          </span>
        }
      />
      {passwordVisible && (
        <React.Fragment>
          <hr />
          <Field
            skipTouch
            component={SettingsModalField}
            name="oldPassword"
            label="Old Password"
            placeholder="••••••••"
            type="password"
          />
          <Field
            component={SettingsModalField}
            name="password"
            label="New Password"
            placeholder="••••••••"
            type="password"
          />
        </React.Fragment>
      )}
      {(passwordVisible || formVisible || dirty) && (
        <div className="setting-modal-button-row">
          <SettingsModalSubmit
            enabled={valid && dirty && !submitting}
            title="Update Account"
          />
          <Button small text onClick={shutForm}>
            Cancel
          </Button>
        </div>
      )}
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(AccountForm)
