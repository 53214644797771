import React from 'react'

const Literal = props => {
  let {
    input: { value },
    formatter,
  } = props

  if (formatter) {
    value = formatter(value)
  }

  return <>{value}</>
}

export default Literal
