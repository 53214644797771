import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentEvents from 'react-document-events'
import { ActionCreators } from 'redux-undo'
import { DeviceWidth, LAYOUT_SECTION } from '@adalo/constants'
import { withRouter } from 'react-router-dom'

import AppMagicLayoutContext from 'components/Editor/AppMagicLayoutContext'

import {
  G,
  Z,
  L,
  W,
  A,
  S,
  D,
  V,
  H,
  ARROW_KEYS,
  ARROW_KEY_OFFSETS,
  DELETE,
  BACKSPACE,
  PLUS_ALL,
  MINUS_ALL,
  ZERO,
  ONE,
  SPACEBAR,
  DOWN_ARROW,
  UP_ARROW,
  PLUS,
  TWO,
  THREE,
} from 'utils/keyboard'
import { hotkeyMapping } from 'utils/hotkeys'
import { commandKeyPressed, altKeyPressed } from 'utils/system'
import { isSectionElement } from 'utils/layoutSections'
import { getLibraryComponentOptions } from 'utils/libraries'

import {
  positionObjects,
  deleteObject,
  groupObjects,
  ungroupObjects,
  setZoom,
  resetZoom,
  setPan,
  reorderObjectsMoveFirst,
  reorderObjectsMoveLast,
  reorderObjectsMoveUp,
  reorderObjectsMoveDown,
  runInstructions,
  runSelectionInstruction,
  getSelectedObjects,
  resizeObject,
} from 'ducks/editor/objects'
import { setTool } from 'ducks/editor/tools'
import {
  getCurrentScreen,
  getSelection,
  resetSelection,
} from 'ducks/editor/selection'
import { getEditingText } from 'ducks/editor/textEditing'
import { getEditingShape } from 'ducks/editor/shapeEditing'
import { toggleConnections } from 'ducks/settings'
import {
  groupObjects as groupObjectsInstruction,
  ungroupObjects as ungroupObjectsInstruction,
} from 'ducks/editor/instructions'
import {
  // screen
  alignToScreenHorizontalCenter,
  alignToScreenVerticalCenter,
  // selection
  alignToSelectionBottom,
  alignToSelectionHorizontalCenter,
  alignToSelectionLeft,
  alignToSelectionRight,
  alignToSelectionTop,
  alignToSelectionVerticalCenter,
} from 'ducks/editor/selection-instructions'
import { NEW_SCREEN_MODAL, showModal } from 'ducks/editor/modals'
import { getFeatureFlag } from 'ducks/featureFlags'
import { getApp } from 'ducks/apps'

import { defaults } from 'utils/objects'

const hotkeyReverseMap = {}

for (const type of Object.keys(hotkeyMapping)) {
  const key = hotkeyMapping[type]
  const code = key.toUpperCase().charCodeAt(0)

  hotkeyReverseMap[code] = type
}

const SCREEN_RESIZE_SHORTCUT_MAP = {
  [ONE]: DeviceWidth.MOBILE_DEFAULT_WIDTH,
  [TWO]: DeviceWidth.TABLET_DEFAULT_WIDTH,
  [THREE]: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
}

class KeyboardEvents extends Component {
  static contextType = AppMagicLayoutContext

  handleKeyDown = e => {
    const key = e.which

    const { editing, setZoom, resetZoom, showModal } = this.props

    if (commandKeyPressed(e)) {
      const { undo, redo } = this.props

      if (key === Z && e.shiftKey) {
        e.preventDefault()
        redo()
      } else if (key === Z) {
        e.preventDefault()
        undo()
      }

      if (key === G) {
        e.preventDefault()

        const {
          groupObjects,
          ungroupObjects,
          selection,
          objects,
          runInstructions,
        } = this.props

        const { hasMagicLayout } = this.context

        const hasSectionElements = objects.some(
          object => isSectionElement(object) || object.type === LAYOUT_SECTION
        )

        if (hasSectionElements) {
          return
        }

        if (e.shiftKey) {
          return hasMagicLayout
            ? runInstructions([ungroupObjectsInstruction(selection)])
            : ungroupObjects(selection)
        }

        return hasMagicLayout
          ? runInstructions([groupObjectsInstruction(selection)])
          : groupObjects(selection)
      }

      if (key === UP_ARROW) {
        e.preventDefault()

        const { reorderObjectsMoveFirst, reorderObjectsMoveUp, selection } =
          this.props

        if (e.shiftKey) {
          reorderObjectsMoveFirst(selection)
        } else {
          reorderObjectsMoveUp(selection)
        }
      }

      if (key === DOWN_ARROW) {
        e.preventDefault()

        const { reorderObjectsMoveLast, reorderObjectsMoveDown, selection } =
          this.props

        if (e.shiftKey) {
          reorderObjectsMoveLast(selection)
        } else {
          reorderObjectsMoveDown(selection)
        }
      }

      if (PLUS_ALL.includes(key)) {
        e.preventDefault()
        setZoom(null, null, Math.sqrt(2))
      }

      if (MINUS_ALL.includes(key)) {
        e.preventDefault()
        setZoom(null, null, 1 / Math.sqrt(2))
      }

      if (key === ZERO) {
        e.preventDefault()
        setZoom(1)
      } else if (key === ONE) {
        e.preventDefault()
        resetZoom()
      }
    } else if (altKeyPressed(e)) {
      const { hasMagicLayout } = this.context
      const { selection, runSelectionInstruction } = this.props

      // https://help.figma.com/hc/en-us/articles/360039956914-Adjust-alignment-rotation-and-position
      if (hasMagicLayout) {
        if (e.shiftKey) {
          // Figma: Hold Shift and click the alignment controls to align multiple objects as a group to their parent frame.
          // This applies to "Align to Screen"
          switch (key) {
            case V:
              e.preventDefault()
              runSelectionInstruction(alignToScreenVerticalCenter(selection))

              break
            case H:
              e.preventDefault()
              runSelectionInstruction(alignToScreenHorizontalCenter(selection))

              break
            default:
            // do nothing
          }
        } else {
          // This applies to "Align to Selection"
          switch (key) {
            case W:
              e.preventDefault()
              runSelectionInstruction(alignToSelectionTop(selection))

              break
            case A:
              e.preventDefault()
              runSelectionInstruction(alignToSelectionLeft(selection))

              break
            case S:
              e.preventDefault()
              runSelectionInstruction(alignToSelectionBottom(selection))

              break
            case D:
              e.preventDefault()
              runSelectionInstruction(alignToSelectionRight(selection))

              break
            case V:
              e.preventDefault()
              runSelectionInstruction(alignToSelectionVerticalCenter(selection))

              break
            case H:
              e.preventDefault()
              runSelectionInstruction(
                alignToSelectionHorizontalCenter(selection)
              )

              break
            default:
            // do nothing
          }

          // This applies to resize screen shortcuts
          if (key === ONE || key === TWO || key === THREE) {
            const { hasMagicLayout } = this.context
            if (hasMagicLayout) {
              const { getCurrentScreen } = this.props
              const currentScreen = getCurrentScreen()

              if (currentScreen) {
                const { resizeObject } = this.props
                resizeObject(currentScreen.id, { width: SCREEN_RESIZE_SHORTCUT_MAP[key] }) // prettier-ignore
              }
            }
          }
        }
      }
    } else if (
      e.target === document.body ||
      e.target?.hasAttribute('data-focusable')
    ) {
      const {
        deleteObject,
        positionObjects,
        selection,
        setTool,
        toggleConnections,
        hasSectionComponent,
        history,
        resetSelection,
        location,
        isResponsiveApp,
        match: {
          params: { appId },
        },
      } = this.props

      const offset = e.shiftKey ? 10 : 1

      if (!editing) {
        if (ARROW_KEYS.includes(key)) {
          const [x, y] = ARROW_KEY_OFFSETS[key]

          if (selection.length > 0) {
            // Positioning objects
            positionObjects(
              selection,
              { x: offset * x, y: offset * y },
              false,
              false
            )
          } else {
            // Scrolling canvas
            setZoom(null, null, null, [x * -100, y * -100])
          }
        }

        if (key === DELETE || key === BACKSPACE) {
          deleteObject(selection)
          e.preventDefault()
        }
      }

      if (key === SPACEBAR) {
        e.preventDefault()
        const { setPan } = this.props
        setPan(true)
      }

      if (key === PLUS) {
        e.preventDefault()

        if (location.pathname.endsWith('add')) {
          return history.push(`/apps/${appId}/screens`)
        }

        resetSelection()

        return history.push(`/apps/${appId}/screens/add`)
      }

      if (e.shiftKey && key === L) return toggleConnections()

      const type = hotkeyReverseMap[key]

      if (type) {
        if (type === LAYOUT_SECTION) {
          if (!isResponsiveApp || !hasSectionComponent) {
            return
          }
        }

        if (type === 'screen') {
          // Preventing default avoids typing in the modal search bar
          e.preventDefault()

          return showModal(NEW_SCREEN_MODAL, { zoom: true })
        }

        if (['Button', 'SimpleList'].includes(type)) {
          const libraryName = '@protonapp/material-components'

          const { type: toolType, object } = getLibraryComponentOptions(
            libraryName,
            type
          )

          return setTool(toolType, object)
        }

        const defaultObject = defaults[type]

        setTool(type, defaultObject ?? {})
      }
    }
  }

  handleKeyUp = e => {
    const key = e.which

    if (e.target === document.body) {
      if (key === SPACEBAR) {
        e.preventDefault()
        const { setPan } = this.props
        setPan(false)
      }

      if (ARROW_KEYS.includes(key)) {
        const { positionObjects, selection } = this.props

        if (selection.length > 0) {
          positionObjects(selection, { x: 0, y: 0 })
        }
      }
    }
  }

  render() {
    return (
      <DocumentEvents
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
      />
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const app = getApp(state, match.params.appId)

  const isResponsiveApp = app?.primaryPlatform === 'responsive'

  return {
    selection: getSelection(state),
    editing: !!(getEditingShape(state) || getEditingText(state)),
    objects: getSelectedObjects(state),
    getCurrentScreen: () => {
      // In the case of apps with a lot of screens and components, calling this for
      // every render can be expensive, therefore we only call it when needed from keyboard events

      const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')
      const mobileOnly =
        hasNewMobileOnlyApp === true && app.webSettings?.layoutMode === 'mobile'

      if (mobileOnly === false) {
        return getCurrentScreen(state)
      }

      return null
    },
    hasSectionComponent: getFeatureFlag(state, 'hasSectionComponent'),
    isResponsiveApp,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    resizeObject,
    deleteObject,
    groupObjects,
    ungroupObjects,
    positionObjects,
    setTool,
    setZoom,
    setPan,
    resetZoom,
    undo: ActionCreators.undo,
    redo: ActionCreators.redo,
    toggleConnections,
    reorderObjectsMoveFirst,
    reorderObjectsMoveLast,
    reorderObjectsMoveUp,
    reorderObjectsMoveDown,
    runInstructions,
    runSelectionInstruction,
    showModal,
    resetSelection,
  })(KeyboardEvents)
)
