import { getParentId } from 'ducks/editor/device-layouts/utils'
import { ObjectList } from 'ducks/editor/types/ObjectList'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import { EditorObject } from './responsiveTypes'

export const hasMultipleContainers = (
  list: ObjectList,
  map: ObjectPathMap,
  objects: EditorObject[]
): boolean => {
  try {
    const parentContainers = new Set(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      objects.map(object => getParentId(list, map, object.id))
    )

    return parentContainers.size !== 1
  } catch (err) {
    return false
  }
}
