import React, { Component } from 'react'
import { connect } from 'react-redux'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { deleteDomain } from 'ducks/domains'
import { notify } from 'ducks/notifications'
import { IconButton } from '../../Shared/Icon'

class CustomDomain extends Component {
  state = { refreshing: false }

  handleDelete = () => {
    const { deleteDomain, organizationId, domain } = this.props

    if (window.confirm(`Are you sure you want to delete ${domain.domain}?`)) {
      deleteDomain(organizationId, domain.id)
    }
  }

  handleRefresh = async () => {
    const { domain, notify } = this.props

    this.setState({ refreshing: true })

    try {
      await adaloBackendAxios.post(`/domains/${domain.id}/refresh-certificate`)

      notify(
        `Refreshing certificate for ${domain.domain}. This can take a few minutes.`
      )
    } catch (err) {
      notify(`An unknown error occurred`)
    }

    this.setState({ refreshing: false })
  }

  render() {
    const { refreshing } = this.state
    const { domain } = this.props

    return (
      <div key={domain.id} className="settings-list-item">
        <div className="team-settings-domain-details">
          <h4>{domain.domain}</h4>
          <p>Custom Domain</p>
        </div>
        <div className="team-settings-domain-action">
          <IconButton
            large
            light
            type="refresh"
            onClick={this.handleRefresh}
            loading={refreshing}
            tooltip="Reset SSL certificate"
          />
          <IconButton
            large
            light
            type="trash-small"
            onClick={this.handleDelete}
            tooltip="Delete"
          />
        </div>
      </div>
    )
  }
}

export default connect(null, { deleteDomain, notify })(CustomDomain)
