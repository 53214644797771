import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import deepEqual from 'deep-equal'
import { getTableId } from '@adalo/utils'

import {
  stripTrailingSlash,
  appendURLBinding,
  replaceSpaces,
} from 'utils/bindings'
import { getFields, mergeProps } from 'utils/external-collections'

import {
  createTable,
  updateTable,
  getDatasource,
  getDefaultDatasource,
  getTable,
} from 'ducks/apps/datasources'

import Modal from 'components/Shared/Modal'

import history from '../../../../history'
import Form, { FORM_NAME } from './Form'
import { ACTIONS, getPath } from './utils'

const DEFAULT_ENDPOINTS = {
  list: { enabled: true, method: 'get' },
  detail: { enabled: true, method: 'get' },
  create: { enabled: true, method: 'post' },
  update: { enabled: true, method: 'put' },
  delete: { enabled: true, method: 'delete' },
}

const getEndpoints = data => {
  let { baseURL, endpoints } = data
  const normalizedURL = stripTrailingSlash(baseURL)

  endpoints = endpoints || DEFAULT_ENDPOINTS

  return {
    list: { ...endpoints.list, url: baseURL },
    detail: {
      ...endpoints.detail,
      url: appendURLBinding(normalizedURL, '/{{id}}'),
    },
    create: { ...endpoints.create, url: baseURL },
    update: {
      ...endpoints.update,
      url: appendURLBinding(normalizedURL, '/{{id}}'),
    },
    delete: {
      ...endpoints.delete,
      url: appendURLBinding(normalizedURL, '/{{id}}'),
    },
  }
}

class AddAPIModal extends Component {
  constructor(props) {
    super(props)

    this.state = { step: 0 }
  }

  // When editing a Ext. Collection we shouuld skip to the last step
  componentDidMount() {
    const { match } = this.props

    const { tableId } = match.params

    const isUpdate = !!tableId

    if (isUpdate) {
      this.setState({ step: 2 })
    }
  }

  handleClose = () => {
    const { match } = this.props
    const { appId } = match.params
    const url = `/apps/${appId}/data`

    history.push(url)
  }

  handleBack = () => {
    const { step } = this.state

    if (step === 0) {
      this.handleClose()
    } else {
      this.setState({ step: step - 1 })
    }
  }

  handleNext = () => {
    const { step } = this.state

    this.setState({ step: Math.min(step + 1, 2) })
  }

  handleChange = data => {
    const prevData = this.prevData || {}
    const { change } = this.props

    if (deepEqual(prevData.testResults, data.testResults)) {
      change(FORM_NAME, 'testResults', null)
    }

    this.prevData = data
  }

  handleSave = data => {
    const { match, datasource, createTable, updateTable } = this.props
    const { appId, datasourceId, tableId } = match.params
    const { results } = data.testResults

    if (!results) {
      return window.alert('An unknown error occurred')
    }

    const resultPropsMerged = mergeProps(results)

    const [fields, orderedFields] = getFields([resultPropsMerged])

    // Call create

    const table = {
      ...data,
      testResults: undefined,
      fields,
      orderedFields,
      tableType: 'api',
    }

    if (tableId) {
      updateTable(appId, datasourceId, tableId, table)
    } else {
      const id = getTableId()

      createTable(appId, datasource.id, { ...table, id })
    }

    this.handleClose()
  }

  handleSubmit = data => {
    const { step } = this.state
    const { change } = this.props

    if (step === 0) {
      // Set endpoint defaults
      change(FORM_NAME, 'endpoints', getEndpoints(data))
    } else if (step === 1) {
      this.fixSpaces(data)

      // Set method paths
      for (const actionName of ACTIONS) {
        const path = getPath(data.endpoints, actionName)

        if (path.length > 0) {
          change(
            FORM_NAME,
            `endpoints.${actionName}.resultsPath`,
            path.join('.')
          )
        }
      }
    }

    if (step === 2) {
      if (data.testResults?.success) {
        this.handleSave(data)
      } else {
        this.handleClose()
      }
    } else {
      this.setState({ step: step + 1 })
    }
  }

  // When using BindableTextControl, some values have non-breaking spaces instead of normal spaces.
  // This fixes those values before making the test connection.
  fixSpaces = data => {
    const { change } = this.props
    // Fix baseURL
    change(FORM_NAME, 'baseURL', replaceSpaces(data.baseURL))

    // Fix endpoints
    const endpointKeys = Object.keys(data.endpoints)

    for (const endpointKey of endpointKeys) {
      data.endpoints[endpointKey].url = replaceSpaces(
        data.endpoints[endpointKey].url
      )
    }

    change(FORM_NAME, 'endpoints', data.endpoints)

    if (data.auth) {
      // Fix auth values
      const newAuth = [...data.auth]

      for (let index = 0; index < newAuth.length; index += 1) {
        newAuth[index].value = replaceSpaces(newAuth[index].value)
      }

      change(FORM_NAME, 'auth', newAuth)
    }
  }

  render() {
    const { step } = this.state
    const { table, match } = this.props
    const { tableId } = match.params

    return (
      <Modal scrolling size="sm" onClose={this.handleClose}>
        <Form
          handleBack={this.handleBack}
          handleNext={this.handleNext}
          step={step}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
          initialValues={table}
          isUpdate={!!tableId}
        />
      </Modal>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId, datasourceId, tableId } = match.params

  const datasource = datasourceId
    ? getDatasource(state, appId, datasourceId)
    : getDefaultDatasource(state, appId)

  const table = tableId && getTable(state, appId, datasourceId, tableId)

  return { datasource, table }
}

export default connect(mapStateToProps, { change, createTable, updateTable })(
  AddAPIModal
)
