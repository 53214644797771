import { subPath, pathLength, getObject } from '@adalo/utils'
import { DEVICE_TYPES } from 'ducks/editor/positioning'
import { isShared, hasDevicePosition } from 'utils/positioning'
import getDeviceObject from 'utils/getDeviceObject'
import updateBounds from './updateBounds'

const updateParentBounds = (
  list,
  map,
  id,
  path,
  shouldUpdate,
  deviceType,
  magicLayout = false
) => {
  path = path || map[id]

  if (!path) {
    return list
  }

  const parentPath = subPath(path, pathLength(path) - 1)

  const child = getObject(list, path)
  const parent = getObject(list, parentPath)

  const object = getDeviceObject(child, deviceType)
  const parentObject = getDeviceObject(parent, deviceType)

  if (!isShared(object, deviceType)) {
    return updateBounds(
      list,
      map,
      parentPath,
      shouldUpdate,
      deviceType,
      magicLayout
    )
  } else {
    for (const device of DEVICE_TYPES) {
      //Update shared and for each device type
      if (isShared(object, device) && hasDevicePosition(parentObject, device)) {
        list = updateBounds(
          list,
          map,
          parentPath,
          shouldUpdate,
          device,
          magicLayout
        )
      }
    }
  }

  return updateBounds(
    list,
    map,
    parentPath,
    shouldUpdate,
    undefined,
    magicLayout
  )
}

export default updateParentBounds
