import React, { useState } from 'react'
import classNames from 'classnames'
import Icon from 'components/Shared/Icon'

const LayoutIcon = ({ icon, shared, onClick, setTooltip }) => {
  const { device, shared: isShared } = icon
  const [hover, setHover] = useState(false)

  const active = isShared === shared

  const useCrossed = shared && !isShared === !hover
  let suffix = useCrossed ? 'off' : 'medium'
  if (!useCrossed && shared && device === 'desktop') {
    suffix = 'large'
  }

  const iconName = `${device}-${suffix}`

  const onHover = () => {
    const preposition = active ? 'off' : 'on'
    const sharedText = shared ? 'Shared' : 'Custom'
    const deviceName = device.charAt(0).toUpperCase() + device.slice(1)

    setTooltip(`Turn ${preposition} ${sharedText} Layout for ${deviceName}`)
    setHover(true)
  }

  return (
    <div
      className={classNames('layout-icon', {
        'layout-icon-active': active,
        'layout-icon-mobile': device === 'mobile',
        'layout-icon-tablet': device === 'tablet',
      })}
      onClick={e => {
        e.stopPropagation()
        onClick(device)
      }}
      onMouseEnter={onHover}
      onMouseLeave={() => setHover(false)}
    >
      <Icon type={iconName} />
    </div>
  )
}

export default LayoutIcon
