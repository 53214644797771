import React from 'react'
import { Field, reduxForm } from 'redux-form'

import Button from 'components/Shared/Button'
import SettingsModalField from 'components/Shared/Forms/SettingsModalField'
import {
  isMaliciousHTML,
  containsLink,
  NO_LINK_MESSAGE,
  MALICIOUS_ERROR_MESSAGE,
} from '../../../utils/validation'

const validate = data => {
  const errors = {}

  if (isMaliciousHTML(data.name)) {
    errors.name = MALICIOUS_ERROR_MESSAGE
  }

  if (containsLink(data.name)) {
    errors.name = NO_LINK_MESSAGE
  }

  return errors
}

const TeamNameForm = ({
  teamFormVisible,
  toggleTeamNameForm,
  closeTeamNameForm,
  dirty,
  submitting,
  handleSubmit,
  isAdmin,
}) => (
  <form onSubmit={handleSubmit}>
    {teamFormVisible && (
      <Field
        autoComplete="off"
        component={SettingsModalField}
        name="name"
        label="Team Name"
        disabled={!isAdmin}
      />
    )}
    {isAdmin && teamFormVisible ? (
      <div className="setting-modal-button-row">
        <Button small disabled={submitting || !dirty} onClick={handleSubmit}>
          Save
        </Button>
        <Button small text onClick={closeTeamNameForm}>
          Cancel
        </Button>
      </div>
    ) : null}
    {isAdmin && !teamFormVisible && (
      <div className="setting-modal-button-row">
        <Button small outlined teal onClick={toggleTeamNameForm}>
          Edit
        </Button>
      </div>
    )}
  </form>
)

export default reduxForm({
  form: 'teamSettings',
  validate,
  enableReinitialize: true,
})(TeamNameForm)
