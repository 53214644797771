// Disable eslint rules for this file because socket.io is not typed on v2.0.3
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { socket } from 'utils/io/socket'
import { Store } from 'redux'
import type {
  MagicAddState,
  MagicStartState,
  SuggestedSchemaTable,
} from 'ducks/dbAssistant'
import {
  DBAssistantStatus,
  updateAppMagicStart,
  updateAppMagicAdd,
} from 'ducks/dbAssistant'

const socketEmit = async (name: string, data: unknown) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      name,
      data,
      (emitData: unknown, message: string, error: Error) => {
        if (error) {
          console.error(error)
          reject(new Error(message))
        } else {
          resolve(emitData)
        }
      }
    )
  })
}

type GenerateDatabaseSchema = {
  appId: string
  userPrompt: string
  conversationId?: string
}

type UpdateDatabaseSchema = {
  appId: string
  userPrompt: string
  feature: string
  conversationId?: string
}

type AcceptDatabaseSchema = {
  appId: string
  datasourceId: string
  conversationId: string
  action: string
  tables?: SuggestedSchemaTable[]
}

interface SchemaInProgress {
  appId: string
  status: DBAssistantStatus
  limits: MagicStartState['limits']
}

interface DBAssistantMagicStart extends MagicStartState {
  appId: string
}

interface DBAssistantMagicAdd extends MagicAddState {
  appId: string
}

export const connectDbAssistantSocket = (store: Store): void => {
  socket.on(
    'dbAssistantInitialized',
    ({ appId, ...payload }: DBAssistantMagicStart | DBAssistantMagicAdd) => {
      store.dispatch(
        updateAppMagicStart(appId, payload as DBAssistantMagicStart)
      )
      store.dispatch(updateAppMagicAdd(appId, payload as DBAssistantMagicAdd))
    }
  )

  socket.on(
    'dbAssistantSchemaInProgress',
    ({ appId, status, limits }: SchemaInProgress) => {
      store.dispatch(updateAppMagicStart(appId, { status, limits }))
    }
  )

  socket.on(
    'dbAssistantSchemaReady',
    ({ appId, ...appMagicStart }: DBAssistantMagicStart) => {
      store.dispatch(updateAppMagicStart(appId, appMagicStart))
    }
  )

  socket.on(
    'dbAssistantSchemaError',
    ({ appId, ...appMagicStart }: DBAssistantMagicStart) => {
      store.dispatch(updateAppMagicStart(appId, appMagicStart))
    }
  )

  socket.on(
    'dbAssistantFeatureList',
    ({ appId, ...appMagicAdd }: DBAssistantMagicAdd) => {
      store.dispatch(updateAppMagicAdd(appId, appMagicAdd))
    }
  )

  const schemaUpdateEvents = [
    'dbAssistantSchemaUpdateError',
    'dbAssistantSchemaUpdateReady',
    'dbAssistantSchemaUpdateInProgress',
  ]

  for (const event of schemaUpdateEvents) {
    socket.on(event, ({ appId, ...appMagicAdd }: DBAssistantMagicAdd) => {
      store.dispatch(updateAppMagicAdd(appId, appMagicAdd))
    })
  }
}

export const initializeDbAssistant = async (appId: string): Promise<unknown> =>
  socketEmit('dbAssistantInitialize', { appId, includeStatus: true })

export const getLimits = async (appId: string): Promise<unknown> =>
  socketEmit('dbAssistantInitialize', { appId, includeStatus: false })

export const generateDatabaseSchema = async (
  data: GenerateDatabaseSchema
): Promise<unknown> => socketEmit('dbAssistantGenerateSchema', data)

export const acceptDatabaseSchema = async (
  data: AcceptDatabaseSchema
): Promise<unknown> => socketEmit('dbAssistantAcceptSchema', data)

export const getFeatureList = async (appId: string): Promise<unknown> =>
  socketEmit('dbAssistantGetFeatureList', { appId })

export const updateDatabaseSchema = async (
  data: UpdateDatabaseSchema
): Promise<unknown> => socketEmit('dbAssistantGenerateUpdateSchema', data)
