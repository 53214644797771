import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import { Submenu } from 'ducks/recommender'
import AppMagicLayoutContext from 'components/Editor/AppMagicLayoutContext'
import { IconButton } from 'components/Shared/Icon'

import { runSelectionInstruction } from 'ducks/editor/objects'
import {
  // screen
  alignToScreenHorizontalCenter,
  alignToScreenVerticalCenter,
  // selection
  alignToSelectionBottom,
  alignToSelectionHorizontalCenter,
  alignToSelectionLeft,
  alignToSelectionRight,
  alignToSelectionTop,
  alignToSelectionVerticalCenter,
  // distribute
  distributeHorizontal,
  distributeVertical,
} from 'ducks/editor/selection-instructions'
import { COMPONENT } from '@adalo/constants'
import { hasMultipleContainers } from 'utils/hasMultipleContainers'
import { getParent } from 'ducks/editor/device-layouts/utils'

class OverflowMenuSelection extends Component {
  static contextType = AppMagicLayoutContext

  getMenu = () => {
    const { objects, runSelectionInstruction, parent } = this.props
    const objectIds = objects.map(object => object.id)
    const parentText = parent.type === COMPONENT ? 'Screen' : 'Parent'

    const menuItems = [
      new Submenu(
        'Align to Selection',
        [
          {
            label: 'Align Left',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(alignToSelectionLeft(objectIds))
            },
            icon: 'align-left',
          },
          {
            label: 'Align Horizontal Centers',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(
                alignToSelectionHorizontalCenter(objectIds)
              )
            },
            icon: 'align-horizontal-center',
          },
          {
            label: 'Align Right',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(alignToSelectionRight(objectIds))
            },
            icon: 'align-right',
          },
          {
            label: 'Align Top',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(alignToSelectionTop(objectIds))
            },
            icon: 'align-top',
          },
          {
            label: 'Align Vertical Centers',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(alignToSelectionVerticalCenter(objectIds))
            },
            icon: 'align-vertical-center',
          },
          {
            label: 'Align Bottom',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(alignToSelectionBottom(objectIds))
            },
            icon: 'align-bottom',
          },
        ],
        true
      ),
      new Submenu(
        `Align to ${parentText}`,
        [
          {
            label: 'Align Horizontally',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(alignToScreenHorizontalCenter(objectIds))
            },
            icon: 'align-horizontal-center',
          },
          {
            label: 'Align Vertically',
            onClick: e => {
              e.stopPropagation()
              runSelectionInstruction(alignToScreenVerticalCenter(objectIds))
            },
            icon: 'align-vertical-center',
          },
        ],
        true
      ),
    ].filter(Boolean)

    if (objectIds.length > 2) {
      menuItems.push(
        new Submenu(
          'Distribute',
          [
            {
              label: 'Distribute Vertical Spacing',
              onClick: e => {
                e.stopPropagation()
                runSelectionInstruction(distributeVertical(objectIds))
              },
              icon: 'distribute-vertical-spacing',
            },
            {
              label: 'Distribute Horizontal Spacing',
              onClick: e => {
                e.stopPropagation()
                runSelectionInstruction(distributeHorizontal(objectIds))
              },
              icon: 'distribute-horizontal-spacing',
            },
          ],
          true
        )
      )
    }

    return menuItems
  }

  render() {
    const { objects, multipleContainers } = this.props
    const { hasMagicLayout } = this.context

    const screenObject = objects.find(object => object.type === COMPONENT)

    if (!hasMagicLayout || screenObject || multipleContainers) {
      return null
    }

    return (
      <div className="right-panel-overflow-menu">
        <MultiMenuTrigger
          menu={this.getMenu()}
          className="right-panel-overflow-menu-trigger"
          rowHeight={40}
          width={205}
          childWidth={300}
        >
          <IconButton type="more-vert" />
        </MultiMenuTrigger>
      </div>
    )
  }
}

const mapStateToProps = (state, { objects }) => {
  const { list, map } = state.editor.objects.present
  const multipleContainers = hasMultipleContainers(list, map, objects)
  const parent =
    objects[0] && !(objects[0].type === COMPONENT)
      ? getParent(list, map, objects[0].id)
      : {}

  return {
    multipleContainers,
    parent,
  }
}

export default withRouter(
  connect(mapStateToProps, { runSelectionInstruction })(OverflowMenuSelection)
)
