import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface AlignToSelectionTopInstruction {
  operation: 'alignToSelectionTop'
  options: ActionOptions
}

export const alignToSelectionTopHandler = ({
  bounds,
  objects,
}: HandlerOptions): LayoutInstruction[] =>
  objects.flatMap(object => [
    // TODO (michael-adalo): see notes in `alignToSelectionVerticalCenter.ts` for why we're doing this
    moveElement(object.id, object.x, object.y),
    moveElement(object.id, object.x, bounds.top),
  ])

const alignToSelectionTop = (
  objectIds: string[]
): AlignToSelectionTopInstruction => ({
  operation: 'alignToSelectionTop',
  options: { objectIds },
})

export default alignToSelectionTop
