const OPEN = Symbol('OPEN')
const CLOSE = Symbol('CLOSE')
const TOGGLE = Symbol('TOGGLE')

const INITIAL_STATE = {}

export const LIBRARY_INSPECT_GROUP = 'library-inspect'

export default (state = INITIAL_STATE, action) => {
  if (action.type === OPEN) {
    const { groupId, instanceId } = action

    if (groupId === LIBRARY_INSPECT_GROUP) {
      localStorage.setItem(LIBRARY_INSPECT_GROUP, instanceId)
    }

    return { ...state, [groupId]: instanceId }
  }

  if (action.type === CLOSE) {
    const { groupId } = action

    if (groupId === LIBRARY_INSPECT_GROUP) {
      localStorage.removeItem(LIBRARY_INSPECT_GROUP)
    }

    return { ...state, [groupId]: null }
  }

  if (action.type === TOGGLE) {
    const { groupId, instanceId } = action

    if (groupId === LIBRARY_INSPECT_GROUP) {
      localStorage.setItem(LIBRARY_INSPECT_GROUP, instanceId)
    }

    return {
      ...state,
      [groupId]: state[groupId] === instanceId ? null : instanceId,
    }
  }

  return state
}

// Actions

export const openAccordion = (groupId, instanceId) => ({
  type: OPEN,
  groupId,
  instanceId,
})

export const closeAccordion = groupId => ({
  type: CLOSE,
  groupId,
})

export const toggleAccordion = (groupId, instanceId) => ({
  type: TOGGLE,
  groupId,
  instanceId,
})

// Selectors

/**
 *
 * @param {*} state
 * @param {*} groupId
 * @returns {string | null | undefined}
 */
export const getAccordionState = (state, groupId) => {
  return state.accordions?.[groupId]
}
