import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface AlignToSelectionBottomInstruction {
  operation: 'alignToSelectionBottom'
  options: ActionOptions
}

export const alignToSelectionBottomHandler = ({
  bounds,
  objects,
}: HandlerOptions): LayoutInstruction[] =>
  objects.flatMap(object => [
    // TODO (michael-adalo): see notes in `alignToSelectionVerticalCenter.ts` for why we're doing this
    moveElement(object.id, object.x, object.y),
    moveElement(object.id, object.x, bounds.bottom - object.height),
  ])

const alignToSelectionBottom = (
  objectIds: string[]
): AlignToSelectionBottomInstruction => ({
  operation: 'alignToSelectionBottom',
  options: { objectIds },
})

export default alignToSelectionBottom
