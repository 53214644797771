import React, { Component } from 'react'
import classNames from 'classnames'
import Icon from 'components/Shared/Icon'

import { singularize, capitalize } from '../../../../utils/strings'

export default class ImplicitParamItem extends Component {
  render() {
    const {
      tableName,
      partial,
      missingComponentNames,
      missingMessage,
      displayFromSource,
    } = this.props

    const name =
      (tableName && `Current ${capitalize(singularize(tableName))}`) || ''

    const message = displayFromSource
      ? missingMessage
      : `${missingMessage} ${missingComponentNames?.join(', ')} `

    return (
      <div
        className={classNames(
          'implicit-params-item',
          partial && 'implicit-params-item-partial'
        )}
      >
        {' '}
        {displayFromSource && <Icon type="relationship" />}{' '}
        <div className="implicit-item-form-item-header">
          <div className="implicit-params-item-name">{name}</div>
          {missingComponentNames ? (
            <div className="implicit-item-type-subtitle">{`${message}`}</div>
          ) : null}
        </div>
      </div>
    )
  }
}
