import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'

import { sourceTypes } from '@adalo/constants'

import { getComparatorOptions } from 'utils/comparators'
import { getFieldLabel } from 'utils/tables'
import { pluralize } from 'utils/strings'
import { getTableInfo, getLabel } from 'utils/sources'
import { getDataType, getComparisonDataType } from 'utils/dataTypes'

import { getTable } from 'ducks/apps/datasources'
import { getFeatureFlag } from 'ducks/featureFlags'
import { getFilterOptions } from 'ducks/recommender'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { IconButton } from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import ComparatorOptions from 'components/Shared/Filters/ComparatorOptions'

import './FilterOptions.scss'

class FilterItem extends Component {
  getLabel = value => {
    const { getLabel, table, hasExposedRecordId } = this.props

    if (typeof value === 'string') {
      return getFieldLabel(table, value, hasExposedRecordId)
    }

    // Must be a source
    return getLabel(value)
  }

  renderFilter = (fieldName, index, filter) => {
    const {
      appId,
      componentId,
      object,
      fieldOptions,
      fields,
      table,
      hasExposedRecordId,
    } = this.props

    const dataType = getDataType(filter, table, hasExposedRecordId)
    const comparisonDataType = getComparisonDataType(
      filter,
      table,
      hasExposedRecordId
    )

    return (
      <div className="filter-options" key={index}>
        <div className="filter-options-header">
          <p>Custom Filter</p>
          <IconButton type="trash-small" onClick={() => fields.remove(index)} />
        </div>
        <div className="action-item-split-row">
          <Field
            className="small white"
            name={`${fieldName}.fieldId`}
            component={WrappedSelect}
            options={fieldOptions}
            placeholder="Select column..."
            getLabel={this.getLabel}
          />
        </div>
        {filter.fieldId && dataType ? (
          <React.Fragment>
            <div className="action-item-split-row">
              <Field
                autoSelect
                className="small white"
                name={`${fieldName}.comparator`}
                component={WrappedSelect}
                options={getComparatorOptions(dataType)}
                placeholder="Select comparison..."
              />
            </div>
            <ComparatorOptions
              fieldName={fieldName}
              fieldClassName="small white"
              appId={appId}
              componentId={componentId}
              objectId={object?.id}
              comparator={filter?.comparator}
              comparatorOptions={filter?.comparatorOptions}
              comparisonDataType={comparisonDataType}
              comparison={filter?.comparison}
              comparison2={filter?.comparison2}
              boxed
            />
          </React.Fragment>
        ) : null}
      </div>
    )
  }

  addFilter = () => {
    const { fields } = this.props

    fields.push({})
  }

  getTableName = () => {
    const { table } = this.props

    if (!table || !table.name) {
      return ''
    }

    return pluralize(table.name)
  }

  render() {
    const { fields, options } = this.props
    const filters = options.filter || []
    let tableName = this.getTableName()

    if (fields.length > 0) {
      tableName = 'Another'
    }

    return (
      <div className="filter-options-items">
        <div>
          {fields.map((field, i) =>
            this.renderFilter(field, i, filters[i] || {})
          )}
        </div>
        <p className="filter-options-add">
          <Button
            listAddButton
            small
            gray
            type="button"
            icon="plus-small"
            onClick={this.addFilter}
          >
            Add {tableName} Filter
          </Button>
        </p>
      </div>
    )
  }
}

const ConnectedFilterItem = connect(state => ({
  hasExposedRecordId: getFeatureFlag(state, 'hasExposedRecordId'),
}))(FilterItem)

class FilteringExtension extends Component {
  renderContent = () => {
    const {
      appId,
      componentId,
      fieldOptions,
      binding,
      name,
      getLabel,
      object,
      table,
    } = this.props

    let { source } = binding

    if (!source) {
      return null
    }

    source = source.type === sourceTypes.COUNT ? source.source : source

    if (!source) {
      return null
    }

    const options = source.options || {}

    let filterPath =
      binding.source.type === sourceTypes.COUNT
        ? 'source.source.options.filter'
        : 'source.options.filter'

    if (name) {
      filterPath = name
    }

    return (
      <FieldArray
        name={filterPath}
        component={ConnectedFilterItem}
        // FilterItem props
        appId={appId}
        binding={binding}
        componentId={componentId}
        fieldOptions={fieldOptions}
        getLabel={getLabel}
        object={object}
        table={table}
        source={source}
        options={options}
      />
    )
  }

  render() {
    return this.renderContent()
  }
}

const mapStateToProps = (state, props) => {
  const { object, binding, appId } = props
  let tableId
  let datasourceId
  let table = null
  const bindingId = binding && binding.id

  if (binding.source) {
    const value = getTableInfo(binding.source)
    tableId = value.tableId
    datasourceId = value.datasourceId

    table = getTable(state, appId, datasourceId, tableId)
  }

  return {
    table,
    getLabel: value => getLabel(state, value, appId, datasourceId, true),
    fieldOptions:
      table &&
      getFilterOptions(
        state,
        appId,
        datasourceId,
        tableId,
        object.id,
        bindingId || object.id
      ),
  }
}

export default connect(mapStateToProps)(FilteringExtension)
