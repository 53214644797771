import { AUTOSAVED_VERSION } from '@adalo/constants'
import { socket } from 'utils/io/socket'
import { forceSaveComponents } from 'utils/io'

const socketEmit = async (name, data) => {
  return new Promise((resolve, reject) => {
    socket.emit(name, data, (data, message, error) => {
      if (error) {
        console.error(error)
        reject(new Error(message))
      } else {
        resolve(data)
      }
    })
  })
}

export const getDesignVersions = async appId => {
  return socketEmit('getDesignVersions', { appId })
}

const saveQueuedChanges = () => {
  return new Promise((resolve, reject) => {
    const onForceSave = err => {
      if (err) {
        reject(err)
      } else {
        resolve()
      }
    }

    forceSaveComponents(onForceSave)
  })
}

export const createDesignVersion = async (appId, versionName, versionNotes) => {
  await saveQueuedChanges()

  return socketEmit('createDesignVersion', { appId, versionName, versionNotes })
}

export const updateDesignVersion = async (
  appId,
  versionId,
  versionName,
  versionNotes
) => {
  return socketEmit('updateDesignVersion', {
    appId,
    versionId,
    versionName,
    versionNotes,
  })
}

export const deleteDesignVersion = async (appId, versionId) => {
  return socketEmit('deleteDesignVersion', { appId, versionId })
}

export const restoreDesignVersion = async (appId, versionId, limitReached) => {
  await saveQueuedChanges()

  const versionName = AUTOSAVED_VERSION.name
  const versionNotes = AUTOSAVED_VERSION.notes

  if (!limitReached) {
    await socketEmit('createDesignVersion', {
      appId,
      versionName,
      versionNotes,
      isAutosave: true,
    })
  }

  return socketEmit('restoreDesignVersion', { appId, versionId })
}
