import { get } from 'lodash'
import { PrimaryPlatform } from 'utils/webSettings'

type DatasourceType = 'database' | 'xano'

const PROTOTYPE_APP_IDS = {
  database: {
    responsive: {
      desktop: process.env['REACT_APP_PROTOTYPE_APP_ID_RESPONSIVE_DESKTOP'] as string, // prettier-ignore
      mobile: process.env['REACT_APP_PROTOTYPE_APP_ID_RESPONSIVE_MOBILE'] as string, // prettier-ignore
    },
    web: process.env['REACT_APP_PROTOTYPE_APP_ID_WEB'] as string,
    mobile: process.env['REACT_APP_PROTOTYPE_APP_ID'] as string,
  },
  xano: {
    responsive: {
      desktop: process.env['REACT_APP_PROTOTYPE_APP_ID_RESPONSIVE_XANO_DESKTOP'] as string, // prettier-ignore
      mobile: process.env['REACT_APP_PROTOTYPE_APP_ID_RESPONSIVE_XANO_MOBILE'] as string, // prettier-ignore
    },
    web: process.env['REACT_APP_PROTOTYPE_APP_ID_WEB_XANO'] as string,
    mobile: process.env['REACT_APP_PROTOTYPE_APP_ID_XANO'] as string,
  },
}

export const getPrototypeAppId = (
  datasourceType: DatasourceType,
  primaryPlatform: PrimaryPlatform,
  templateId: string
): string =>
  get(
    PROTOTYPE_APP_IDS,
    [
      datasourceType,
      primaryPlatform,
      ...(primaryPlatform === 'responsive'
        ? [String(templateId).replace('blank_', '')]
        : []),
    ],
    ''
  ) as string
