import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'

import { isLegacyPlanType } from 'utils/billing.ts'

import {
  getPlanSelectedFlag,
  getUpdatePaymentSettingsFlag,
  getSwitchToYearlyFlag,
} from 'ducks/trialWarning'
import { getOrganization } from 'ducks/organizations'

import PayModal from './PayModal'
import PlanSelectModal from './PlanSelectModal'
import PublishedAppSelectionModal from './PublishedAppSelectionModal'
import TeamMemberSelectionModal from './TeamMemberSelectionModal'
import {
  PlanChangeContext,
  PUBLISHED_APPS_STEP,
  TEAM_MEMBERS_STEP,
} from './PlanChangeContext'

const FORM_NAME = 'trialModalSignup'

const validate = values => {
  const error = {}

  if (!values.name) {
    error.name = 'Please provide your name'
  }

  if (!values.address_country) {
    error.address_country = 'Please select your country from the dropdown'
  }

  if (!values.address_line1) {
    error.address_line1 = 'Please provide the first line of your address'
  }

  if (!values.address_city) {
    error.address_city = 'Please provide your city name'
  }

  if (!values.address_zip) {
    error.address_zip = 'Please provide your postal code'
  }

  return error
}

const SignupForm = ({
  onCancel,
  submitting,
  error,
  metrics,
  datasource,
  onDowngrade,
  handleSubmit,
  onClickBackChangePlan,
  organizationId,
}) => {
  const updatePaymentSettings = useSelector(getUpdatePaymentSettingsFlag)
  const switchToYearly = useSelector(getSwitchToYearlyFlag)
  const planSelected = useSelector(getPlanSelectedFlag)
  const selector = formValueSelector(FORM_NAME)
  const couponCode = useSelector(state => selector(state, 'coupon'))
  const { currentStep, setCurrentStep } = useContext(PlanChangeContext)
  const org = useSelector(state => getOrganization(state, organizationId))

  const isCurrentlyLegacyPlan = useMemo(() => {
    return isLegacyPlanType(org?.planType ?? 'free')
  }, [org?.planType])

  const goToPlanSelection = useCallback(() => {
    setCurrentStep('')
    onClickBackChangePlan()
  }, [])

  const getModal = () => {
    if (currentStep === PUBLISHED_APPS_STEP) {
      return (
        <PublishedAppSelectionModal
          goToPlanSelection={goToPlanSelection}
          isCurrentlyLegacyPlan={isCurrentlyLegacyPlan}
        />
      )
    } else if (currentStep === TEAM_MEMBERS_STEP) {
      return <TeamMemberSelectionModal goToPlanSelection={goToPlanSelection} />
    }

    if (planSelected || updatePaymentSettings || switchToYearly) {
      return (
        <PayModal
          submitting={submitting}
          error={error}
          onCancel={onCancel}
          onSubmit={handleSubmit}
          onClickBackChangePlan={onClickBackChangePlan}
          couponCode={couponCode}
          organizationId={organizationId}
          isCurrentlyLegacyPlan={isCurrentlyLegacyPlan}
        />
      )
    }

    return (
      <PlanSelectModal
        onCancel={onCancel}
        metrics={metrics}
        datasource={datasource}
        onDowngrade={onDowngrade}
        onClickBackChangePlan={onClickBackChangePlan}
        organizationId={organizationId}
      />
    )
  }

  return <form onSubmit={handleSubmit}>{getModal()}</form>
}

export default reduxForm({ validate, form: FORM_NAME })(SignupForm)
