import moment from 'moment'

export const dateTimeOffsets = [
  [-365, '1 year ago', 'date-range'],
  [-180, '6 months ago', 'date-range'],
  [-90, '90 days ago', 'date-range'],
  [-60, '60 days ago', 'date-range'],
  [-30, '30 days ago', 'date-range'],
  [-14, '2 weeks ago', 'date-range'],
  [-7, '1 week ago', 'date-range'],
  [-6, '6 days ago', 'date-range'],
  [-5, '5 days ago', 'date-range'],
  [-4, '4 days ago', 'date-range'],
  [-3, '3 days ago', 'date-range'],
  [-2, '2 days ago', 'date-range'],
  [-1, '1 day ago', 'date-range'],
  null,
  [-0.5, '12 hours ago', 'date-and-time-range'],
  [-0.25, '6 hours ago', 'date-and-time-range'],
  [-0.125, '3 hours ago', 'date-and-time-range'],
  [-0.083333333333333, '2 hours ago', 'date-and-time-range'],
  [-0.041666666666666, '1 hour ago', 'date-and-time-range'],
  [-0.020833333333333, '30 minutes ago', 'date-and-time-range'],
  [-0.010416666666666, '15 minutes ago', 'date-and-time-range'],
  [-0.003472222222222, '5 minutes ago', 'date-and-time-range'],
  [-0.000694444444444, '1 minute ago', 'date-and-time-range'],
  null,
  [0.000694444444444, '1 minute from now', 'date-and-time-range'],
  [0.003472222222222, '5 minutes from now', 'date-and-time-range'],
  [0.010416666666666, '15 minutes from now', 'date-and-time-range'],
  [0.020833333333333, '30 minutes from now', 'date-and-time-range'],
  [0.041666666666666, '1 hour from now', 'date-and-time-range'],
  [0.083333333333333, '2 hours from now', 'date-and-time-range'],
  [0.125, '3 hours from now', 'date-and-time-range'],
  [0.25, '6 hours from now', 'date-and-time-range'],
  [0.5, '12 hours from now', 'date-and-time-range'],
  null,
  [1, 'Tomorrow', 'date-range'],
  [2, '2 days from now', 'date-range'],
  [3, '3 days from now', 'date-range'],
  [4, '4 days from now', 'date-range'],
  [5, '5 days from now', 'date-range'],
  [6, '6 days from now', 'date-range'],
  [7, '1 week from now', 'date-range'],
  [14, '2 weeks from now', 'date-range'],
  [30, '30 days from now', 'date-range'],
  [60, '60 days from now', 'date-range'],
  [90, '90 days from now', 'date-range'],
  [180, '6 months from now', 'date-range'],
  [365, '1 year from now', 'date-range'],
]

const labels = {}

dateTimeOffsets.forEach(offset => {
  if (offset) {
    labels[offset[0]] = offset[1]
  }
})

export const getDateTimeLabel = source => {
  const { startOfDay, offset } = source.options || {}

  if (!offset) {
    return startOfDay ? 'Start of Today' : 'Current Time'
  }

  return labels[`${offset}`]
}

export const formatDate = date => {
  return moment(date).format('M/D/YYYY')
}

export const validateDate = dateString => {
  const dateObj = new Date(dateString)

  if (Number.isNaN(dateObj.getTime())) {
    return undefined
  }

  return dateString
}

export const relativeDate = date => {
  return moment(date).fromNow()
}

export const formatDateOnly = dateString => {
  const justDate = dateString.split('T')[0]
  const dateTime = `${justDate}T00:00:00`

  return moment(dateTime).format('l')
}

export const getDayName = (date, locale) => {
  return date.toLocaleDateString(locale, { weekday: 'short' })
}

export const getMonthAbbr = (date, locale) => {
  return date.toLocaleDateString(locale, { month: 'short' })
}

export const humanizeDate = date => {
  switch (date) {
    case 1:
    case 21:
    case 31:
      return `${date}st`
    case 2:
    case 22:
      return `${date}nd`
    case 3:
    case 23:
      return `${date}rd`
    default:
      return `${date}th`
  }
}
