import { COMPONENT, DeviceWidth } from '@adalo/constants'
import { getDeviceType, update } from '@adalo/utils'
import getDeviceObject from 'utils/getDeviceObject'
import mapScreenToDocument from './mapScreenToDocument'
import mapDocumentToState, {
  DEFAULT_RENDER_DEVICES,
} from './mapDocumentToState'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'

import { ObjectList } from '../types/ObjectList'

export const SCREEN_MIN_WIDTH = DeviceWidth.MOBILE_MIN_WIDTH
export const SCREEN_MAX_WIDTH = DeviceWidth.DESKTOP_MAX_WIDTH
/**
 * The minimum viewport height as enforced by Chrome. Seems like a reasonable, albeit arbitrary, choice.
 */
export const SCREEN_MIN_HEIGHT = 264

export interface ResizeScreenOptions {
  screenId: string
  width: number
  height: number | undefined
}

export const resizeScreenHandler = (
  state: InstructionState,
  options: ResizeScreenOptions
): InstructionState => {
  const { list, pathMap } = state

  const { screenId } = options
  const screen = getObject(list, pathMap, screenId)
  if (screen.type !== COMPONENT) {
    throw new Error(`The object is not a Screen. (Screen ID: ${screenId})`)
  }

  let { width, height } = options

  // Minimum screen size is enforced to prevent breaking elements with relative sizes and positions as the reference
  // width approaches zero.
  if (width < SCREEN_MIN_WIDTH) {
    width = SCREEN_MIN_WIDTH
  } else if (width > SCREEN_MAX_WIDTH) {
    width = SCREEN_MAX_WIDTH
  }

  const viewingDevice = getDeviceType(width)

  if (typeof height !== 'number') {
    const deviceScreen = getDeviceObject(screen, viewingDevice)
    height = deviceScreen.height
  }

  if (height < SCREEN_MIN_HEIGHT) {
    height = SCREEN_MIN_HEIGHT
  }

  const updatedHeight = height !== undefined ? height : screen.height

  const updatedList = mapDocumentToState(
    mapScreenToDocument(list, pathMap, screenId),
    list,
    pathMap,
    width,
    updatedHeight,
    undefined,
    DEFAULT_RENDER_DEVICES,
    'resizeScreen',
    viewingDevice
  )

  const updatedScreen = getObject(updatedList, pathMap, screenId)

  return {
    ...state,
    list: update(list, pathMap[updatedScreen.id], {
      ...updatedScreen,
      [viewingDevice]: {
        ...updatedScreen[viewingDevice],
        height: updatedHeight,
      },
    }) as ObjectList,
  }
}

export interface ResizeScreenInstruction {
  operation: 'resizeScreen'
  options: ResizeScreenOptions
}

const resizeScreen = (
  screenId: string,
  width: number,
  height?: number
): ResizeScreenInstruction => {
  return {
    operation: 'resizeScreen',
    options: {
      screenId,
      width,
      height,
    },
  }
}

export default resizeScreen
