import React from 'react'
import PropTypes from 'prop-types'

import { assetURL } from 'utils/assets'

const LaunchScreenPreview = ({ launchScreenIcon, launchScreenColor }) => {
  return (
    <div
      className="launch-screen-preview"
      style={{ backgroundColor: launchScreenColor }}
    >
      {launchScreenIcon && (
        <div
          className="launch-screen-icon"
          style={{ backgroundImage: `url(${assetURL(launchScreenIcon)})` }}
          alt="launch screen icon"
        />
      )}
    </div>
  )
}

LaunchScreenPreview.propTypes = {
  launchScreenColor: PropTypes.string,
  launchScreenIcon: PropTypes.string,
}

LaunchScreenPreview.defaultProps = {
  launchScreenColor: '#ffffff',
}

export default LaunchScreenPreview
