import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { dataTypes, httpMethods } from '@adalo/constants'

import {
  getEndpoint,
  getEndpointURL,
  getEndpointMethod,
  getEndpointOptions,
  getCollectionEndpointLabel,
} from '../../../../utils/apis'

import { getRouteParams } from '../../../../utils/routes'
import { getDatasource } from '../../../../ducks/apps/datasources'
import WrappedSelect from '../../../Shared/Forms/WrappedSelect'
import APIFields from './APIFields'
import FieldValue from './FieldValue'
import DatasourceSelector from './DatasourceSelector'

class APICallOptions extends Component {
  getEndpointLabel = opt => {
    const { datasource } = this.props

    if (!datasource || !opt) {
      return null
    }

    const { collectionId, endpointId } = opt

    return getCollectionEndpointLabel(datasource, collectionId, endpointId)
  }

  getEndpointOptions = () => {
    const { datasource } = this.props

    if (!datasource) {
      return []
    }

    return getEndpointOptions(datasource)
  }

  getEndpoint = () => {
    const { datasource, options } = this.props

    if (!datasource || !options || !options.endpoint) {
      return []
    }

    const { collectionId, endpointId } = options.endpoint
    const collection = datasource.collections[collectionId]

    return getEndpoint(collection, endpointId)
  }

  getParams = () => {
    const { datasource, options } = this.props

    if (!datasource || !options || !options.endpoint) {
      return []
    }

    const { collectionId, endpointId } = options.endpoint
    const collection = datasource.collections[collectionId]
    const endpoint = getEndpoint(collection, endpointId)

    const params = getRouteParams(getEndpointURL(collection, endpoint))

    return params
  }

  render() {
    const {
      datasource,
      field,
      options,
      appId,
      componentId,
      objectId,
      actionId,
      reference,
    } = this.props

    const params = this.getParams()
    const endpoint = this.getEndpoint()
    const method = getEndpointMethod(endpoint)

    const showFields =
      options.endpoint && method && method !== httpMethods.DELETE

    return (
      <div className="action-item-form-options">
        <DatasourceSelector
          appId={appId}
          name={`${field}.options.datasourceId`}
        />
        <div className="action-item-form-row">
          <label>Endpoint</label>
          <Field
            className="small white"
            name={`${field}.options.endpoint`}
            component={WrappedSelect}
            options={this.getEndpointOptions}
            getLabel={this.getEndpointLabel}
          />
        </div>
        {params.length > 0 ? (
          <div>
            <hr />
            {params.map(param => (
              <div key={param} className="action-item-form-row">
                <label>{param}</label>
                <FieldValue
                  name={`${field}.options.params.${param}`}
                  appId={appId}
                  componentId={componentId}
                  objectId={objectId}
                  allowedDataTypes={[dataTypes.TEXT, dataTypes.NUMBER]}
                  actionId={actionId}
                  reference={reference}
                />
              </div>
            ))}
          </div>
        ) : null}
        {showFields ? (
          <div>
            <hr />
            <APIFields
              field={`${field}.options.params`}
              datasource={datasource}
              collectionId={options.endpoint.collectionId}
              endpointId={options.endpoint.endpointId}
              appId={appId}
              componentId={componentId}
              objectId={objectId}
              actionId={actionId}
              reference={reference}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, options }) => ({
  datasource: getDatasource(state, appId, options.datasourceId),
})

export default connect(mapStateToProps)(APICallOptions)
