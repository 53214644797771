import { update } from '@adalo/utils'
import {
  COMPONENT,
  DeviceType as DeviceTypeConstants,
  resizingOptions,
  responsivePositioningOptions,
} from '@adalo/constants'
import { unset } from 'lodash'
import { EditorObject, LayoutAttributes } from 'utils/responsiveTypes'
import { removeUnusedSize } from 'utils/objects/removeUnusedSize'
import getDeviceObject from 'utils/getDeviceObject'
import usesSharedLayout from 'utils/objects/usesSharedLayout'
import Document from '../model/Document'
import { ObjectList } from '../types/ObjectList'
import { ObjectPathMap } from '../types/ObjectPathMap'
import LayoutObject from '../model/layout/LayoutObject'
import LayoutContext from '../model/layout/LayoutContext'
import DeviceType from '../types/DeviceType'
import ObjectType from '../types/ObjectType'
import getObject from '../objects/helpers/getObject'
import ScreenNode from '../model/ScreenNode'
import updateDeviceLayoutForObject from '../device-layouts'
import { ConditionResolver } from '../device-layouts/conditions'

const { LEFT, CENTER, RIGHT, LEFT_AND_RIGHT } = responsivePositioningOptions
const { SCALES_WITH_PARENT, FIXED } = resizingOptions

export const DEFAULT_RENDER_DEVICES = [
  undefined,
  DeviceTypeConstants.MOBILE,
  DeviceTypeConstants.TABLET,
  DeviceTypeConstants.DESKTOP,
]

function updateObjectFromLayout(
  objects: ObjectList,
  pathMap: ObjectPathMap,
  layoutObject: LayoutObject<ObjectType>,
  context: LayoutContext,
  objectIdFilter: string | undefined = undefined
): ObjectList {
  const { device, viewingDevice } = context
  const { id, type, x, y, width, height, left, right, children } = layoutObject

  const oldObject = getObject(objects, pathMap, id)
  const oldDeviceObject = getDeviceObject(oldObject, device)

  let { shared } = oldObject
  if (!shared) {
    shared = {
      [DeviceTypeConstants.MOBILE]: true,
      [DeviceTypeConstants.TABLET]: true,
      [DeviceTypeConstants.DESKTOP]: true,
    }
  }

  const changes: Partial<LayoutAttributes> = {}

  // sanity-check(device-parent)
  // TODO(michael-adalo): if the object doesn't use any shared layout,
  // we shouldn't need to update x, y, width, height, left, right on the shared object

  // Never update x and y of a screen
  if (type !== COMPONENT) {
    changes.x = x
    changes.y = y
  }

  changes.width = width
  changes.height = height

  const removeProps = []

  if (
    (device === undefined &&
      usesSharedLayout(oldDeviceObject, viewingDevice)) ||
    (device !== undefined && viewingDevice === device)
  ) {
    const {
      horizontalScaling: scaling = FIXED,
      horizontalPositioning: positioning = scaling === FIXED ? LEFT : CENTER,
    } = oldDeviceObject.responsivity || {}

    // Documentation:
    // src/ducks/editor/instructions/CONCEPTS.md
    // Refer to section "Margins"
    if (
      (scaling === SCALES_WITH_PARENT && positioning === CENTER) ||
      (scaling === SCALES_WITH_PARENT && positioning === LEFT_AND_RIGHT)
    ) {
      if (left !== undefined && right !== undefined) {
        changes.left = left
        changes.right = right
        removeProps.push('fixedLeft', 'fixedRight')
      }
    } else if (
      (scaling === FIXED && positioning === LEFT) ||
      (scaling === FIXED && positioning === CENTER)
    ) {
      if (left !== undefined) {
        changes.fixedLeft = left
        removeProps.push('left', 'right', 'fixedRight')
      }
    } else if (scaling === FIXED && positioning === RIGHT) {
      if (right !== undefined) {
        changes.fixedRight = right
        removeProps.push('left', 'right', 'fixedLeft')
      }
    } else {
      throw new Error('Object has unsupported horizontal layout combination')
    }
  }

  const skipUpdate = objectIdFilter && id !== objectIdFilter

  let result: ObjectList = [...objects]

  if (device === undefined) {
    if (!skipUpdate) {
      for (const prop of removeProps) {
        unset(oldObject, prop)
      }

      result = update(result, pathMap[id], {
        ...oldObject,
        ...changes,
      }) as ObjectList
    }
  } else if (type !== COMPONENT) {
    if (!skipUpdate) {
      const resolver = new ConditionResolver(device)
      result = updateDeviceLayoutForObject(
        result,
        pathMap,
        oldObject.id,
        device,
        resolver,
        changes,
        removeProps
      )
    }
  }

  const newObject: EditorObject = getObject(result, pathMap, id)
  // TODO (Tom) can updateDeviceLayoutForObject account for this?
  if (device && newObject[device]) {
    newObject[device] = removeUnusedSize(newObject, newObject[device])

    if (!skipUpdate) {
      result = update(result, pathMap[id], newObject) as ObjectList
    }
  }

  const childStack = [...children]
  while (childStack.length > 0) {
    const child = childStack.shift()
    if (child === undefined) {
      throw new Error(`Failed to get a child off the stack`)
    }

    const childPath = pathMap[child.id]
    if (childPath === undefined) {
      // Some document nodes - namely, list items - will not correspond directly to an editor object.
      // For these nodes, prepend their children onto the stack and then mark the node finished.
      const grandChildren = child.children
      childStack.unshift(...grandChildren)

      continue
    }

    result = updateObjectFromLayout(
      result,
      pathMap,
      child,
      context,
      skipUpdate ? objectIdFilter : undefined
    )
  }

  return result
}

const updateDeviceObject = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  screen: ScreenNode,
  context: LayoutContext,
  objectIdFilter?: string
) => {
  const layout: LayoutObject<ObjectType> = screen.layout(context)

  return updateObjectFromLayout(list, pathMap, layout, context, objectIdFilter)
}

const mapDocumentToState = (
  document: Document,
  oldObjects: ObjectList,
  pathMap: ObjectPathMap,
  screenWidth: number,
  screenHeight: number,
  subtreeRootObject?: string,
  renderDevices: (DeviceType | undefined)[] = DEFAULT_RENDER_DEVICES,
  instruction?: LayoutContext['instruction'],
  viewingDevice?: DeviceType | undefined
): ObjectList => {
  const screen = document.getScreen()

  // Render device-agnostic layout as baseline.
  const context: LayoutContext = {
    device: undefined,
    containerWidth: screenWidth,
    containerHeight: undefined,
    containerOffsetX: 0,
    containerOffsetY: 0,
    viewportWidth: screenWidth,
    viewportHeight: screenHeight,
    instruction,
    viewingDevice,
    subtreeRootObject,
  }

  let result = [...oldObjects]

  for (const device of renderDevices) {
    result = updateDeviceObject(
      result,
      pathMap,
      screen,
      {
        ...context,
        device,
      },
      subtreeRootObject
    )
  }

  // Set the screen height to the passed-in value.
  // This is not needed for other object types but screens can be sized arbitrarilly.
  if (subtreeRootObject === undefined) {
    const screenObj = getObject(result, pathMap, screen.id)

    result = update(result, pathMap[screen.id], {
      ...screenObj,
      height: screenHeight,
    }) as ObjectList
  }

  return result
}

export default mapDocumentToState
