import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, getFormValues, change } from 'redux-form'
import { isEmpty as _isEmpty } from 'lodash'

import { openAccordion } from 'ducks/accordions'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'

import './AuthFields.scss'
import './Outputs.scss'

class OutputItems extends Component {
  buildMenu = () => {
    const {
      values: { testResults, outputs },
    } = this.props

    const { availableOutputs } = testResults

    return Object.keys(availableOutputs)
      .filter(key => {
        return outputs[key]
      })
      .map(key => ({
        label: availableOutputs[key].name,
        value: { ...availableOutputs[key], formattedKey: key },
      }))
  }

  render() {
    const {
      values: { outputs },
    } = this.props

    if (!outputs) {
      return <p>ERROR: No outputs</p>
    }

    return (
      <div className="external-users-auth-menu">
        <label>Which output is the authentication token?</label>
        <Field
          component={MenuControl}
          options={this.buildMenu()}
          name="authTokenField"
          getLabel={val => val.name}
        />

        <label>Which output is the user ID?</label>
        <Field
          component={MenuControl}
          options={this.buildMenu()}
          name="idField"
          getLabel={val => val.name}
        />

        {_isEmpty(outputs) && <p>ERROR: No outputs</p>}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const selector = getFormValues(props.formName)

  return {
    values: selector(state),
  }
}

export default connect(mapStateToProps, { openAccordion, change })(OutputItems)
