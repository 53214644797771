import { COMPONENT } from '@adalo/constants'
import { getObject, getParentPath } from '@adalo/utils'
import DeviceType from 'ducks/editor/types/DeviceType'
import { ObjectList } from 'ducks/editor/types/ObjectList'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import { EditorObject } from 'utils/responsiveTypes'

const isParentVisible = (
  object: EditorObject,
  device: DeviceType,
  list: ObjectList,
  map: ObjectPathMap
): boolean => {
  const parentPath = getParentPath(map[object.id]) as string
  const parent = getObject(list, parentPath) as EditorObject | null

  if (!parent || parent.type === COMPONENT) {
    return true
  }

  if (parent.deviceVisibility && !parent.deviceVisibility[device]) {
    return false
  }

  return isParentVisible(parent, device, list, map)
}

export default isParentVisible
