import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import QS from 'qs'
import classNames from 'classnames'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

// ducks
import {
  getMarketplaceComponents,
  resetCheckoutLibraryId,
  selectMarketplace,
  getFeedScroll,
  setFeedScroll,
  getSelectedCategory,
  setSelectedCategory,
  SORTING_POPULAR,
  setSorting,
  SORTING_RECENTLY_CREATED,
  FILTER_ALL,
  FILTER_FREE,
  FILTER_PAID,
} from 'ducks/marketplace'
import { getApp, requestApp } from 'ducks/apps'
import { fetchOrganizations } from 'ducks/organizations'
import { getLicenses } from 'ducks/marketplace/licenses'
import { getCurrentUser } from 'ducks/users/index.ts'

// shared components
import Chip from 'components/Shared/Chip'
import Button from 'components/Shared/Button'
import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'
import Modal from 'components/Shared/Modal'
import Icon from 'components/Shared/Icon'
import SearchBar from 'components/Shared/SearchBar'

// utils
import filterComponents from 'utils/filter-components'
import { formatToCurrency } from 'utils/currency'

// marketplace hooks
import {
  useLibraryStatus,
  useLibraryHandler,
} from 'components/Editor/Routes/Marketplace/hooks'

import noResultsPic from './no-results.png'

import './feed.scss'

const FeedContext = createContext()

const Feed = () => {
  const dispatch = useDispatch()

  const { appId } = useParams()
  const history = useHistory()
  const location = useLocation()

  const app = useSelector(state => getApp(state, appId))
  const currentUser = useSelector(getCurrentUser)

  const [search, setSearch] = useState('')
  const category = useSelector(getSelectedCategory)

  const { search: query } = QS.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const handleSearch = ({ target: { value } }) => {
    if (value) {
      history.replace({ search: QS.stringify({ search: value }) })
    } else {
      history.replace(`/apps/${appId}/marketplace`)
    }

    setSearch(value)
  }

  const filterByCategory = list => {
    switch (category) {
      case FILTER_ALL:
        return list
      case FILTER_FREE:
        return list.filter(item => item.price === 0)
      case FILTER_PAID:
        return list.filter(item => item.price > 0)
    }
  }

  useEffect(() => {
    setSearch(query || '')
    dispatch(resetCheckoutLibraryId())
  }, [])

  const licenses = useSelector(state =>
    getLicenses(state, app?.OrganizationId, appId)
  )

  const { status, components, sorting } = useSelector(state =>
    selectMarketplace(state)
  )

  const filteredComponents = React.useMemo(() => {
    return filterByCategory(filterComponents(components, search, app))
  }, [components.length, search.length, app?.id, category, sorting])

  useEffect(() => {
    if (app?.OrganizationId) {
      dispatch(getMarketplaceComponents(app.OrganizationId))
    }
  }, [app?.OrganizationId])

  // Scroll
  const scrollPosition = useSelector(getFeedScroll)
  const feedRef = useRef()

  const handleScroll = () => {
    const position = feedRef.current.scrollTop
    dispatch(setFeedScroll(position))
  }

  useEffect(() => {
    if (
      scrollPosition !== null &&
      feedRef.current &&
      scrollPosition !== feedRef.current.scrollTop
    ) {
      feedRef.current.scrollTo({
        top: scrollPosition,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [feedRef.current !== null])

  const renderNoResults = () => (
    <EmptyState className="marketplace-empty">
      <img src={noResultsPic} alt="No Results" width="50%" />
      <h1>Oops, we don't have that yet... but you can help!</h1>
      <Button to="https://adalo.canny.io/component-requests" target="_blank">
        Request a Component
      </Button>
    </EmptyState>
  )

  const SORTING_POPULAR_LABEL = 'Most Popular'
  const SORTING_RECENTLY_CREATED_LABEL = 'Recently Added'

  const options = [
    { label: SORTING_POPULAR_LABEL, value: SORTING_POPULAR },
    { label: SORTING_RECENTLY_CREATED_LABEL, value: SORTING_RECENTLY_CREATED },
  ]

  const sortingLabel = React.useMemo(() => {
    switch (sorting) {
      default:
      case SORTING_POPULAR:
        return SORTING_POPULAR_LABEL
      case SORTING_RECENTLY_CREATED:
        return SORTING_RECENTLY_CREATED_LABEL
    }
  }, [sorting])

  const componentsLabel = React.useMemo(() => {
    switch (category) {
      default:
      case FILTER_ALL:
        return 'All'
      case FILTER_FREE:
        return 'Free'
      case FILTER_PAID:
        return 'Premium'
    }
  }, [category])

  const handleSortingSelect = value => {
    dispatch(setSorting(value))
  }

  const renderList = () => (
    <div
      className={classNames('marketplace-feed')}
      onScroll={handleScroll}
      ref={feedRef}
    >
      <div className="marketplace-feed-header">
        <h2>{componentsLabel} Components</h2>

        <MultiMenuTrigger
          menu={options}
          onSelect={handleSortingSelect}
          rowHeight={32}
        >
          <Button teal small text icon="sort" iconSide="right" iconSize="small">
            {sortingLabel}
          </Button>
        </MultiMenuTrigger>
      </div>
      <FeedContext.Provider value={{ app, licenses, currentUser }}>
        {filteredComponents.map(component => {
          return (
            <Link
              key={component.id}
              to={`/apps/${appId}/marketplace/${component.id}`}
            >
              <FeedItem component={component} />
            </Link>
          )
        })}
      </FeedContext.Provider>
    </div>
  )

  const renderInnerContent = () => {
    if (status === 'loading') {
      return (
        <div className="marketplace-feed-loading">
          <Loading />
        </div>
      )
    } else if (
      (search.length || category !== 'all') &&
      !filteredComponents.length
    ) {
      return renderNoResults()
    } else {
      return renderList()
    }
  }

  const renderSideBar = () => {
    const filterOptions = [
      { label: 'View All', value: FILTER_ALL, icon: 'shop' },
      { label: 'Free', value: FILTER_FREE, icon: 'free' },
      { label: 'Premium', value: FILTER_PAID, icon: 'paid' },
    ]

    return (
      <div className="marketplace-sidebar">
        {filterOptions.map(option => (
          <FilterOption
            {...option}
            key={option.value}
            selected={category === option.value}
            onClick={() => dispatch(setSelectedCategory(option.value))}
          />
        ))}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="marketplace-container">
        {renderSideBar()}
        <div className="marketplace-vertical-line" />
        {renderInnerContent()}
      </div>
    )
  }

  const handleClose = () => {
    dispatch(resetCheckoutLibraryId())
    history.push(`/apps/${appId}/screens`)
  }

  return (
    <Modal size="lg" scrolling fixedHeight top onClose={handleClose}>
      <div>
        <Modal.Header>
          <Icon type="marketplace" color="teal" />
          <h1>Marketplace</h1>

          <SearchBar
            className="marketplace-search"
            placeholder="Search Marketplace..."
            value={search}
            onChange={handleSearch}
          />
        </Modal.Header>
        <Modal.Content classNames="marketplace">
          {renderContent()}
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button
            text
            teal
            outlined
            to="https://developers.adalo.com"
            target="_blank"
            icon="code"
          >
            Create Your Own Component
          </Modal.Button>
          <Modal.Button onClick={handleClose}>Done</Modal.Button>
        </Modal.Actions>
      </div>
    </Modal>
  )
}

const FilterOption = ({ label, icon, selected, onClick }) => {
  const className = classNames('marketplace-filter-option', {
    selected,
  })

  return (
    <div className={className} onClick={onClick}>
      <Icon type={icon} color={selected ? 'teal' : null} />
      <span className="marketplace-filter-label">{label}</span>
    </div>
  )
}

const FeedItem = ({ component }) => {
  const dispatch = useDispatch()

  const { app, currentUser } = useContext(FeedContext)

  const { isInstalled, label } = useLibraryStatus(component.id)

  const { handleLibraryInstall, handleLibraryUninstall, isLoading } =
    useLibraryHandler(component.id)

  const handleButtonClick = async e => {
    e.preventDefault()

    try {
      if (isInstalled) {
        await handleLibraryUninstall()
      } else {
        await handleLibraryInstall()
      }

      await dispatch(requestApp(app?.id))
      await dispatch(fetchOrganizations())
    } catch (err) {
      console.error('Error Installing Library', err)
    }
  }

  return (
    <div className="marketplace-item" key={component.id}>
      <div className="marketplace-item-info">
        <div className="marketplace-item-header">
          <img src={component.logo} alt={`${component.displayName} logo`} />
          <div className="marketplace-item-title">
            <h2>{component.displayName}</h2>
          </div>
        </div>
        <p>{component.description}</p>
      </div>
      <div className="marketplace-item-footer">
        {component.price !== 0 && (
          <Chip size="lg" color="teal">
            <Chip.Content>{`${formatToCurrency(
              component.price
            )}`}</Chip.Content>
          </Chip>
        )}
        <Button
          teal
          small
          secondary={isInstalled}
          outlined={!isInstalled}
          disabled={isLoading || (isInstalled && !currentUser?.admin)}
          loading={isLoading}
          onClick={handleButtonClick}
        >
          {label}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(Feed)
