import React, { Component } from 'react'
import Textarea from 'react-textarea-autosize'
import { sanitize } from 'dompurify'

export default class BindableTextControl extends Component {
  static defaultProps = {
    inputType: 'text',
  }

  handleChange = e => {
    const { name, onChange } = this.props
    const newValue = sanitize(e.currentTarget.value)
    onChange({ [name]: newValue })
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.forceUpdate()
    }, 100)
  }

  render() {
    const {
      displayName,
      hideDisplayName,
      placeholder,
      value,
      inputType,
      disabled,
    } = this.props

    return (
      <div className="library-inspect-text-control">
        {hideDisplayName ? null : <p>{displayName}</p>}
        <div className="library-inspect-text-control-input-wrapper">
          <Textarea
            value={value}
            onChange={this.handleChange}
            placeholder={placeholder}
            className="library-inspect-text-control-input"
            type={inputType}
            aria-label="text-control"
            autoComplete="off"
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
}
