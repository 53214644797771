import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dataTypes, formatterOptions } from '@adalo/constants'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import { buildFormula } from 'utils/formulas'
import { isRole } from 'utils/libraries'

import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'

import {
  getLibraryBindingSuggestions,
  getExternalAPIBindingSuggestions,
  MenuOption,
} from 'ducks/recommender'

import {
  getCurrentAppId,
  getComponent,
  selectObject,
} from 'ducks/editor/objects'

import EntityTextarea from '../Forms/EntityTextarea'
import Icon, { IconButton } from '../Icon'
import BindingEntity from './BindingEntity'

import './BindableTextControl.scss'

import { THEMES } from '../../../constants'

export class NakedBindableTextControl extends Component {
  static defaultProps = {
    disableBinding: false,
    hideBinding: false,
  }

  constructor(props) {
    super(props)

    const { childRef } = props

    this.textareaRef = childRef || React.createRef()
    this.textarea = this.textareaRef
  }

  handleChange = newValue => {
    const { name, onChange } = this.props

    onChange({ [name]: addLabelToFormula(newValue) })
  }

  renderEntity = (entity, { onDelete, onUpdate, onClick }) => {
    let {
      appId,
      componentId,
      objectId,
      role,
      reference,
      disableIcon,
      disableChip,
      getLabel,
      enableTestValue,
      fromModal,
      hideParentListOptions,
    } = this.props

    reference = isRole(role, 'listItem') ? reference : null

    return (
      <BindingEntity
        entity={entity}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        role={role}
        reference={reference}
        getLabel={getLabel}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onClick={onClick}
        disableIcon={disableIcon}
        disableChip={disableChip}
        enableTestValue={enableTestValue}
        fromModal={fromModal}
        hideParentListOptions={hideParentListOptions}
      />
    )
  }

  handleAddEntity = binding => {
    if (binding.type === 'formula' || !binding.source) {
      return this.textarea.current.addEntity(binding, true)
    }

    const { dataType } = binding.source
    const options = formatterOptions[dataType] || []
    const option = options[0]
    const format = option ? { type: option.value } : undefined

    this.textarea.current.addEntity({
      ...binding,
      format,
    })
  }

  handleMouseDown = e => {
    e.preventDefault()
  }

  getOptions = () => () => {
    const { bindingOptions, disableFormulas, externalAPIField } = this.props

    let options = bindingOptions() || []

    if (externalAPIField) {
      options = [
        {
          label: 'Logged In User',
          inline: true,
          children: options[0].children,
        },
      ]
    }

    if (!disableFormulas) {
      if (options.length > 0) {
        options.push(null)
      }

      options.push(
        new MenuOption(
          'New Formula...',
          buildFormula(''),
          <Icon type="magic-number" />
        )
      )
    }

    return addLabelToOptions(options)
  }

  render() {
    let {
      disableBinding,
      hideBinding,
      label,
      displayName,
      placeholder,
      value,
      hideDisplayName,
      meta,
      apiBaseURL,
      sanitization,
    } = this.props

    const { touched, error } = meta || {}

    displayName = displayName || label

    value = value || ''

    if (value && !Array.isArray(value) && typeof value === 'object') {
      value = ['', value, '']
    }

    const icon = (
      <IconButton
        orange
        type="magic-text"
        onMouseDown={this.handleMouseDown}
        className="bindable-text-control-trigger-icon"
      />
    )

    let bindableTextControlClass = 'bindable-text-control'
    let entityTextAreaClass = ''

    if (apiBaseURL) {
      bindableTextControlClass += ' bindable-text-control-api'
      entityTextAreaClass += 'entity-textarea-api'
    }

    return (
      <div className={bindableTextControlClass}>
        <div className="bindable-text-control-header">
          {!hideDisplayName ? <p>{displayName}</p> : null}
          {!hideBinding && !disableBinding ? (
            <div className="bindable-text-control-trigger-wrapper">
              <MultiMenuTrigger
                menu={this.getOptions()}
                onSelect={this.handleAddEntity}
                className="bindable-text-control-trigger"
                menuClassName="bindable-text-control-menu"
                rowHeight={40}
                width={260}
                menuTheme={THEMES.DATA}
              >
                {icon}
              </MultiMenuTrigger>
            </div>
          ) : null}
        </div>

        <EntityTextarea
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
          renderEntity={this.renderEntity}
          sanitization={sanitization}
          childRef={this.textareaRef}
          className={
            touched && error
              ? 'bindable-text-control-error'
              : entityTextAreaClass
          }
        />
      </div>
    )
  }
}

export function addLabelToOptions(opts) {
  return (
    opts &&
    opts.map(opt => {
      if (!opt) {
        return null
      }

      if (opt.children) {
        return {
          ...opt,
          children:
            typeof opt.children === 'function'
              ? () => addLabelToOptions(opt.children())
              : addLabelToOptions(opt.children),
        }
      }

      return {
        ...opt,
        value: {
          ...opt.value,
          label: opt.label,
        },
      }
    })
  )
}

function addLabelToFormula(values) {
  if (Array.isArray(values)) {
    return values.map(value => {
      if (typeof value === 'object' && value.type === 'formula') {
        return { ...value, label: '0' }
      } else {
        return value
      }
    })
  }

  return values
}

const mapStateToProps = (
  state,
  {
    objectId,
    role,
    reference,
    actionId,
    disableBinding,
    externalAPIField,
    helpers,
    hideParentListOptions,
  }
) => {
  const appId = getCurrentAppId(state)
  const componentId = getComponent(state, objectId)?.id

  const object = selectObject(state, objectId)
  const binding = (isRole(role, 'listItem') && reference) || object?.dataBinding

  const props = {
    appId,
    componentId,
    objectId,
  }

  if (!disableBinding) {
    if (externalAPIField) {
      props.bindingOptions = getExternalAPIBindingSuggestions(state, appId, [
        dataTypes.TEXT,
        dataTypes.NUMBER,
        dataTypes.DATE,
        dataTypes.DATE_ONLY,
      ])
    } else {
      props.bindingOptions = getLibraryBindingSuggestions(
        state,
        appId,
        componentId,
        objectId,
        [dataTypes.TEXT, dataTypes.NUMBER, dataTypes.DATE, dataTypes.DATE_ONLY],
        binding,
        actionId,
        undefined,
        helpers,
        hideParentListOptions
      )
    }
  }

  return props
}

export default withPremiumFeatureHandler(
  connect(mapStateToProps)(NakedBindableTextControl)
)
