import { useEffect, useState } from 'react'
import { adaloBackendAxios } from 'utils/io/http/axios'

import Page from 'components/Shared/Page'
import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'

import './Admin.scss'

const DeletedApp = ({ match }) => {
  const [deletedAppInfo, setDeletedAppInfo] = useState(null)
  const [loadingError, setLoadingError] = useState(null)

  useEffect(() => {
    Promise.all([
      adaloBackendAxios.get(`/apps/${match.params.appId}/deleted-app-debug`),
    ])
      .then(([deletedAppInfo]) => {
        setDeletedAppInfo(deletedAppInfo.data)
      })
      .catch(err => setLoadingError(err.response?.data?.message ?? err.message))
  }, [match.params.appId])

  if (!deletedAppInfo && loadingError) {
    return (
      <Page
        className="admin-users-page"
        title={`Deleted App Info: ${match.params.appId}`}
      >
        <h1>Deleted App: {match.params.appId}</h1>
        <hr />
        <p style={{ color: 'red' }}>
          Error retrieving App ID={match.params.appId}: {loadingError}
        </p>
      </Page>
    )
  }

  if (!deletedAppInfo) {
    return (
      <EmptyState greedy>
        <Loading large />
      </EmptyState>
    )
  }

  const { datasourceId, datasource } = deletedAppInfo

  return (
    <Page
      className="admin-users-page"
      title={`Deleted App Info: ${match.params.appId}`}
    >
      <h1>Deleted App</h1>
      <div
        style={{
          paddingBottom: '25px',
          marginTop: '25px',
          marginBottom: '25px',
          borderBottom: '1px solid lightGray',
        }}
      >
        <ul>
          <li>App ID: {match.params.appId}</li>
          <li>Datasource ID: {datasourceId}</li>
        </ul>
      </div>
      <div>
        <h2>Datasource from S3 backup</h2>
        <pre>{datasource}</pre>
      </div>
    </Page>
  )
}

export default DeletedApp
