import React, { useState, useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import Icon from 'components/Shared/Icon'

// ducks
import { getAppBranding } from 'ducks/apps'
import { openAccordion } from 'ducks/accordions'
import { resetSelection } from 'ducks/editor/selection'

// utils
import { normalizeFont } from 'utils/type'

import './FontPicker.scss'

const defaultLabel = 'System Standard Font'

const FontPicker = props => {
  const dispatch = useDispatch()
  const { name, onChange, value, history } = props
  const { appId } = useParams()

  const branding = useSelector(state => getAppBranding(state, appId))

  const [options, setOptions] = useState([])

  const handleSelect = value => onChange({ [name]: value })

  const handleFontOptions = () => {
    let headingValue = normalizeFont('@heading', branding)
    let bodyValue = normalizeFont('@body', branding)

    let headingFontFamily = headingValue
    let bodyFontFamily = bodyValue

    if (!headingValue || headingValue === 'default') {
      headingValue = defaultLabel
      headingFontFamily = null
    }

    if (!bodyValue || bodyValue === 'default') {
      bodyValue = defaultLabel
      bodyFontFamily = null
    }

    return [
      {
        label: (
          <span>
            Heading Font –{' '}
            <span style={{ fontFamily: headingFontFamily }}>
              {headingValue}
            </span>
          </span>
        ),
        value: '@heading',
      },
      {
        label: (
          <span>
            Body Font –{' '}
            <span style={{ fontFamily: bodyFontFamily }}>{bodyValue}</span>
          </span>
        ),
        value: '@body',
      },
      null,
      {
        label: (
          <div className="font-picker-change-fonts">
            <Icon type="color-lens" color="darkPink" /> CHANGE FONTS
          </div>
        ),
        onClick: setBrandingTab,
        value,
      },
    ]
  }

  const setBrandingTab = () => {
    dispatch(openAccordion('branding', 'fonts'))
    dispatch(resetSelection())
    history.push(`/apps/${appId}/branding`)
  }

  useEffect(() => {
    setOptions(handleFontOptions())
  }, [branding])

  let label = normalizeFont(value, branding)
  if (!label || label === 'default') label = defaultLabel

  return (
    <MultiMenuTrigger
      menu={options}
      onSelect={handleSelect}
      rowHeight={40}
      width={260}
      className="font-picker-wrapper"
    >
      <span title={label}>{label}</span>
    </MultiMenuTrigger>
  )
}

FontPicker.propTypes = {}

FontPicker.defaultProps = {
  value: 'default',
}

export default withRouter(FontPicker)
