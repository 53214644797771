import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  updateOrganizationSeenEndTrial,
  getCurrentOrganization,
} from 'ducks/organizations'
import Session from 'utils/session'
import { SEEN_END_TRIAL_SESSION_KEY } from '../constants'

const useSetSeenTrialEndModal = seenEndIntegrationTrial => {
  const dispatch = useDispatch()

  const organization = useSelector(getCurrentOrganization)

  const setSeenIntegrationEndModal = () => {
    organization.seenEndIntegrationTrial = seenEndIntegrationTrial
    const { id } = organization
    Session.set(`${id}-${SEEN_END_TRIAL_SESSION_KEY}`, seenEndIntegrationTrial)
    dispatch(updateOrganizationSeenEndTrial(id, seenEndIntegrationTrial))
  }

  useEffect(() => {
    if (organization) {
      setSeenIntegrationEndModal()
    }
  }, [organization?.id])
}

export default useSetSeenTrialEndModal
