import { Field } from 'redux-form'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

import SelectList from 'components/Shared/Forms/SelectList'

import smallBusinessOperator from '../assets/onboarding-graphics-small-business-operator.png'
import enterpriseEmployee from '../assets/onboarding-graphics-enterprise-employee.png'
import aspiringFounder from '../assets/onboarding-graphics-aspiring-founder.png'
import freelancerAgency from '../assets/onboarding-graphics-freelancer-agency.png'
import education from '../assets/onboarding-graphics-education.png'
import maker from '../assets/onboarding-graphics-maker.png'

import { AUDIENCE_OPTIONS } from './constants'

export const getAudienceInferredPrimaryUse = (
  audience: string
): string | undefined =>
  AUDIENCE_OPTIONS.find(o => o.value === audience)?.primaryUse

type Props = {
  setAudience: (value: string) => void
}

const AudienceStep = (props: Props): JSX.Element => {
  const { setAudience } = props

  return (
    <>
      <h3>Who are you building apps for?</h3>
      <div className="onboarding-audience-list">
        <Field
          id="audience"
          name="audience"
          label="Select one to describe what you’ll do most of the time."
          className="audience"
          type="text"
          component={SelectList}
          options={AUDIENCE_OPTIONS}
          onChangeValue={(value: string) => setAudience(value)}
        />
      </div>
    </>
  )
}

type AudienceStepGraphicProps = {
  audience: string
}

export const AudienceStepGraphic: React.FC<AudienceStepGraphicProps> = ({
  audience,
}) => {
  const transitionClassname = 'onboarding-graphic-transition'
  const transitionTimeout = 500

  return (
    <div
      className={classNames('onboarding-graphics__audience', {
        'onboarding-graphics__audience--selected': audience,
      })}
    >
      <CSSTransition
        in={audience === 'Small Business Operator'}
        timeout={transitionTimeout}
        classNames={transitionClassname}
        unmountOnExit
      >
        <img src={smallBusinessOperator} alt="small business operator" />
      </CSSTransition>

      <CSSTransition
        in={audience === 'Enterprise Employee'}
        timeout={transitionTimeout}
        classNames={transitionClassname}
        unmountOnExit
      >
        <img src={enterpriseEmployee} alt="enterprise employee" />
      </CSSTransition>

      <CSSTransition
        in={audience === 'Aspiring Founder'}
        timeout={transitionTimeout}
        classNames={transitionClassname}
        unmountOnExit
      >
        <img src={aspiringFounder} alt="aspiring founder" />
      </CSSTransition>

      <CSSTransition
        in={audience === 'Freelancer/Agency'}
        timeout={transitionTimeout}
        classNames={transitionClassname}
        unmountOnExit
      >
        <img src={freelancerAgency} alt="freelancer agency" />
      </CSSTransition>

      <CSSTransition
        in={audience === 'Education'}
        timeout={transitionTimeout}
        classNames={transitionClassname}
        unmountOnExit
      >
        <img src={education} alt="education" />
      </CSSTransition>

      <CSSTransition
        in={audience === 'Maker'}
        timeout={transitionTimeout}
        classNames={transitionClassname}
        unmountOnExit
      >
        <img src={maker} alt="maker" />
      </CSSTransition>
    </div>
  )
}

export default AudienceStep
