import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { showModal, setPaymentRouteType } from 'ducks/trialWarning'
import { getApp, getUpgraded, updateApp } from 'ducks/apps'
import { getDomains } from 'ducks/domains'
import { fetchOrganization, getCurrentOrganization } from 'ducks/organizations'
import {
  showModal as showEditorModal,
  UNPUBLISH_APP_MODAL,
} from 'ducks/editor/modals'
import { getCurrentUser } from 'ducks/users/index.ts'
import usePlanFeatures from 'hooks/usePlanFeatures'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { isCurrentPlanType } from 'utils/billing.ts'
import { isSupportedOnWeb } from 'utils/platform'
import { requestAppBuilds } from 'utils/io'

import Icon from 'components/Shared/Icon'
import { GroupedAccordion } from 'components/Shared/Accordion'
import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import CalloutCard from 'components/Shared/CalloutCard'
import OverLimitModal from 'components/Shared/OverLimitModal'

import WebPublish from './Web'
import { WebPublish as WebPublishV2 } from './WebV2/index.ts'
import AndroidPublish from './Android/AndroidPublish'
import IOSPublish from './IOS/IOSPublish'
import PWAPublish from './PWA'

import './Publish.scss'

const PublishPanel = () => {
  const dispatch = useDispatch()

  const [showPublishingAddonModal, setShowPublishingAddonModal] =
    useState(false)

  const { appId } = useParams()

  const { getHasFeatureEnabled } = usePlanFeatures()
  const hasMobilePublishing = getHasFeatureEnabled('mobilePublishing')

  const app = useSelector(state => getApp(state, appId))

  const organization = useSelector(getCurrentOrganization)
  const isCurrentEraPlan = isCurrentPlanType(organization?.planType)

  const currentUser = useSelector(getCurrentUser)

  const magicLayout = Boolean(app?.magicLayout)
  // Publish toggle is only showed on new plans, since old plans don't have publishing limits
  const showPublishToggle = isCurrentEraPlan

  const isAdmin = currentUser?.admin
  const publishedAppsCount = organization?.addons?.publishedApps?.count
  const publishedAppsLimit = organization?.addons?.publishedApps?.limit
  const publishedAppLimitReached =
    organization && isCurrentEraPlan && publishedAppsCount >= publishedAppsLimit

  const publishedAppAccordionsDisabled =
    !isAdmin && // Admins can always build
    (organization?.planType === 'free' || // Free users can never build
      (isCurrentEraPlan && !app?.published) || // On new plan if app isn't published it's collapsed
      (publishedAppLimitReached && !app?.published)) // If app publishing limit reached through other apps

  const handlePublished = async status => {
    await dispatch(updateApp(appId, { published: status }))

    // update organization published apps count
    await dispatch(fetchOrganization(organization.id))
  }

  const handleTogglePublished = async () => {
    // handle unpublishing an app
    if (app.published) {
      const { value: shouldUnpublishApp } = await dispatch(
        showEditorModal(UNPUBLISH_APP_MODAL)
      )

      if (shouldUnpublishApp) {
        // update app published status to false
        // set showAdaloAttribution to true for users on free plan
        const webSettings =
          organization?.planType === 'free'
            ? { showAdaloAttribution: true }
            : {}

        await dispatch(updateApp(appId, { published: false, DomainId: null, webSettings })) // prettier-ignore

        // pull latest current org data from backend
        await dispatch(fetchOrganization(organization.id))
      }
    } else if (publishedAppsCount >= publishedAppsLimit) {
      setShowPublishingAddonModal(true)
    } else {
      await handlePublished(true)
    }

    requestAppBuilds(appId, 'web', { order: 'DESC' })
  }

  const handleUpgradeModal = () => {
    dispatch(setPaymentRouteType('upgrade'))
    dispatch(showModal())
  }

  const handlePurchase = async () => {
    try {
      // purchase 1 published apps subscription
      await adaloBackendAxios.put(
        `/organizations/${organization.id}/subscription`,
        { type: 'publishedApps' }
      )
      // set published status to true
      await handlePublished(true)

      // close the published apps addon modal
      setShowPublishingAddonModal(!showPublishingAddonModal)
    } catch (err) {
      throw err
    }
  }

  if (!app) {
    return (
      <EmptyState>
        <Loading large />
      </EmptyState>
    )
  }

  const upgradeButtonProps = {
    ...baseUpgradeButtonProps,
    onClick: handleUpgradeModal,
  }

  let unlockMobilePublishingCalloutCard = null

  if (!isAdmin && !hasMobilePublishing && isCurrentEraPlan) {
    // For users in the starter2023 plan (current era plan), they don't have mobile publishing,
    // they should show a specific callout card to prompt them to upgrade for mobile publishing
    unlockMobilePublishingCalloutCard = (
      <CalloutCard
        color="grey"
        title="Upgrade to Publish Mobile Apps!"
        body="Switch to a higher tier plan to enable iOS and Android publishing."
        button={upgradeButtonProps}
        alignment="left"
      />
    )
  }

  const nativePublishingAccordionDisabled =
    publishedAppAccordionsDisabled || !hasMobilePublishing
  // Native Mobile App publishing panel
  let publishPanelContent = (
    <>
      <PublishingGroupedAccordion
        type="android"
        disabled={nativePublishingAccordionDisabled}
        openOnEnable
      />
      <PublishingGroupedAccordion
        type="ios"
        disabled={nativePublishingAccordionDisabled}
      />
      <PublishingGroupedAccordion
        type="pwa"
        disabled={publishedAppAccordionsDisabled}
      />
      {unlockMobilePublishingCalloutCard}
    </>
  )

  if (isSupportedOnWeb(app.primaryPlatform)) {
    // Desktop Web App publishing panel
    publishPanelContent = <Publishing type="web" />
    if (magicLayout) {
      // Users on legacy plan can't publish responsive web apps (unless they're admins)
      const webAccordionDisabled =
        !isAdmin && (publishedAppAccordionsDisabled || !isCurrentEraPlan)
      const nativeAccordionsDisabled =
        !isAdmin &&
        (publishedAppAccordionsDisabled ||
          !isCurrentEraPlan ||
          !hasMobilePublishing)

      // Responsive Web App publishing panel
      publishPanelContent = (
        <>
          <PublishingGroupedAccordion
            type="web"
            disabled={webAccordionDisabled}
          />

          <PublishingGroupedAccordion
            type="android"
            disabled={nativeAccordionsDisabled}
          />
          <PublishingGroupedAccordion
            type="ios"
            disabled={nativeAccordionsDisabled}
          />
          {unlockMobilePublishingCalloutCard}
        </>
      )
    }
  }

  let upgradeCalloutContent = null
  const shouldShowCalloutCard =
    !isAdmin && (!organization?.active || (magicLayout && !isCurrentEraPlan))

  if (shouldShowCalloutCard) {
    let calloutTitle = 'Upgrade to Publish your Apps!'
    let calloutBody =
      'Switch to a paid plan to start sharing your apps with the world.'
    if (magicLayout && organization?.planType !== 'free' && !isCurrentEraPlan) {
      calloutTitle = 'Upgrade to Publish Responsive Apps!'
      calloutBody =
        "You're currently on a legacy plan. Upgrade to one of our current plans to publish Responsive Apps."
    }

    upgradeCalloutContent = (
      <CalloutCard
        color="grey"
        title={calloutTitle}
        body={calloutBody}
        button={upgradeButtonProps}
        alignment="left"
      />
    )
  }

  const publishToggle = showPublishToggle ? (
    <ToggleButton
      value={app?.published}
      onChange={handleTogglePublished}
      disabled={!organization?.active}
    />
  ) : null

  const publishAppsWarning = showPublishToggle ? (
    <div className="left-panel-header-subtext">
      <span>
        Any apps that share this database will also be marked as published.
      </span>
    </div>
  ) : null

  return (
    <>
      <div className="publish-panel">
        <div className="left-panel-header">
          <div className="left-panel-header-title">
            <Icon type="done-all" color="green" />
            <h2>Publish</h2>
            {publishToggle}
          </div>
          {publishAppsWarning}
        </div>
        <div>
          {upgradeCalloutContent}
          {publishPanelContent}
        </div>
      </div>
      <OverLimitModal
        addOnName="Published App"
        addOnPrice={25}
        purchaseDescription="Add another publishing license to launch this app to your end-users on the App Store, the Play Store, and a custom domain."
        onSuccess={handlePurchase}
        onClose={() => setShowPublishingAddonModal(false)}
        organization={organization}
        show={showPublishingAddonModal}
      />
    </>
  )
}

const baseUpgradeButtonProps = {
  green: true,
  small: true,
  children: 'Upgrade',
  'data-adalo-id': 'upgrade-publish',
}

const GroupedAccordionItemIds = {
  web: 'publish-web',
  android: 'publish-android',
  pwa: 'publish-pwa',
  ios: 'publish-ios',
}

const GroupedAccordionItemTitles = {
  web: 'Web App',
  android: 'Android App',
  pwa: 'Progressive Web App',
  ios: 'iOS App',
}

const PublishingGroupedAccordion = ({
  title,
  type,
  itemId,
  ...groupAccordionProps
}) => (
  <GroupedAccordion
    group="publish-accordions"
    itemId={GroupedAccordionItemIds[type]}
    title={GroupedAccordionItemTitles[type]}
    className="library-inspect-accordion"
    renderChildren={() => <Publishing type={type} />}
    {...(groupAccordionProps ?? {})}
  />
)

const Publishing = ({ type }) => {
  const { appId } = useParams()

  const app = useSelector(state => getApp(state, appId))
  const organization = useSelector(getCurrentOrganization)
  const domains = useSelector(state => getDomains(state, app?.Organization?.id))
  const magicLayout = Boolean(app?.magicLayout)

  const needsUpgrade = !useSelector(state => !!getUpgraded(state, appId))

  const isCurrentEraPlan = isCurrentPlanType(organization?.planType)
  const isFreeUser =
    !organization?.planType || organization?.planType === 'free'

  // Custom domains disabled for free users and users on legacy plan's with responsive web apps
  const customDomainsDisabled = isFreeUser || (!isCurrentEraPlan && magicLayout)

  const getAppHost = useCallback(
    (domainId, fallbackToOrgSubdomain) => {
      // assign domainId if one is not provided
      // choose to fallback to the org subdomain if no domain passed as param, and don't want to use the domain currently set (to show in UI labels)
      if (!domainId && !fallbackToOrgSubdomain) domainId = app?.DomainId

      const domain = domains?.find(d => d.id === domainId)

      if (domain) {
        return `${domain?.domain}/`
      } else {
        const baseURL = process.env.REACT_APP_WEB_BASE_URL

        const subdomain =
          app?.Organization?.subdomain ?? organization?.subdomain ?? ''

        return `${subdomain}.${baseURL}/`
      }
    },
    [app?.id, app?.DomainId, domains?.length, organization]
  )

  switch (type) {
    case 'android':
      return <AndroidPublish appId={appId} />
    case 'ios':
      return <IOSPublish appId={appId} />
    case 'pwa':
      return <PWAPublish appId={appId} getAppHost={getAppHost} />
    case 'web':
      return (
        <>
          {magicLayout && (
            <WebPublishV2 appId={appId} getAppHost={getAppHost} />
          )}
          <WebPublish
            appId={appId}
            getAppHost={getAppHost}
            needsUpgrade={needsUpgrade}
            showWebSettings={magicLayout}
            customDomainsDisabled={customDomainsDisabled}
          />
        </>
      )

    default:
      return null
  }
}

export default PublishPanel
