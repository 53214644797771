import React from 'react'
import { imageResize } from '@adalo/constants'

import { scaleValue } from 'utils/zoom'
import { assetURL } from 'utils/assets'
import { isEditableSectionElement } from 'utils/layoutSections'

import Section from '../Section'
import Rect from '../Section/Rect'
import Shadow from '../Shadow'

import Icon from '../ImageUpload/uploaded-image.svg'
import DynIcon from '../ImageUpload/dynamic-image.svg'
import GroupWrapper from '../Group/GroupWrapper'

import './Image.css'

const DynamicPlaceholder = {
  source: DynIcon,
}

const UploadedPlaceholder = {
  source: Icon,
}

export default class Image extends Section {
  getURL = () => {
    const { imageType, imageId, imageBinding, imageURL, filename1x } =
      this.props

    const order = ['2x', '1x', '3x']

    let filename = null

    if (filename1x && filename1x.url) {
      filename = filename1x.url

      return assetURL(filename)
    }

    // placeholder image
    let placeholderImageEnabled = null
    let placeholderImage = null

    if (
      imageBinding &&
      typeof imageBinding.options !== 'undefined' &&
      !filename1x
    ) {
      const { options } = imageBinding

      if (typeof options.placeholderImageEnabled !== 'undefined') {
        placeholderImageEnabled = options.placeholderImageEnabled
      }

      if (typeof options.placeholderImage !== 'undefined') {
        placeholderImage = options.placeholderImage
      }
    }

    if (imageId) {
      filename = `${imageId}@2x.png`
    } else if (placeholderImageEnabled) {
      filename = placeholderImage
    } else if (!imageBinding && filename1x) {
      for (const size of order) {
        const key = `filename${size}`

        if (this.props[key]) {
          filename = this.props[key]

          break
        }
      }
    }

    if (imageType === 'url' && imageURL) {
      // check if url is magic text
      if (Array.isArray(imageURL)) return null

      return imageURL
    }

    if (!filename) return null

    return assetURL(filename)
  }

  getMaskId() {
    const { id } = this.props

    return `image-mask-${id}`
  }

  renderPlaceholder() {
    const {
      id,
      zoom,
      width,
      height,
      widthScaled,
      heightScaled,
      xScaled,
      yScaled,
      imageType,
      editorResizingProps,
    } = this.props

    const placeholder =
      imageType === 'dynamic' || imageType === 'url'
        ? DynamicPlaceholder
        : UploadedPlaceholder

    const minDim = Math.min(width, height)
    const size = Math.max(16, Math.min(80, minDim * 0.7))

    const iconWidth = scaleValue(size, zoom)
    const iconHeight = scaleValue(size, zoom)

    const src = this.getURL()
    const maskId = this.getMaskId()

    const resizeType =
      this.props.imageResize === imageResize.CONTAIN ? 'meet' : 'slice'

    if (src) {
      return (
        <g mask={`url(#${maskId})`}>
          <image
            href={src}
            x={xScaled}
            y={yScaled}
            width={widthScaled}
            height={heightScaled}
            preserveAspectRatio={`xMidYMid ${resizeType}`}
          />
        </g>
      )
    }

    let imageX = '50%'
    let wrapperX = 0
    if (editorResizingProps?.relativeWidth) {
      wrapperX = -iconWidth / 2
    } else {
      imageX = widthScaled / 2 - iconWidth / 2
    }

    return (
      <svg x={wrapperX} y="0" width="100%" height="100%" overflow="visible">
        <g
          transform={`translate(${xScaled}, ${yScaled})`}
          className="image-upload-object"
        >
          <image
            href={placeholder.source}
            width={iconWidth}
            height={iconHeight}
            x={imageX}
            y={heightScaled / 2 - iconHeight / 2}
            clipPath={`url(#clip-${id})`}
          />
        </g>
      </svg>
    )
  }

  render() {
    const {
      id,
      width,
      height,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      borderRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
      opacity,
      zoom,
      imageType,
      object,
      editorResizingProps,
      shadow,
      hideShadows,
      children,
    } = this.props

    const borderProps = this.getBorderProps()
    const backgroundColor = this.getBackgroundColor()

    const resizeType =
      this.props.imageResize === imageResize.CONTAIN ? 'meet' : 'slice'

    const src = this.getURL()
    const maskId = this.getMaskId()
    const showPlaceholder = imageType === 'dynamic' || !src
    const showPlaceholderImage = imageType === 'dynamic' && src

    const displayShadow =
      isEditableSectionElement(object) &&
      shadow &&
      shadow.enabled &&
      !hideShadows

    const defaultRectProps = {
      width,
      height,
      zoom,
      widthScaled,
      heightScaled,
      borderRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
    }

    return (
      <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
        <g className="group" style={{ opacity }}>
          <g
            className="image"
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDoubleClick}
          >
            <defs>
              <clipPath id={`clip-${id}`}>
                <Rect {...defaultRectProps} x={0} y={0} />
              </clipPath>
            </defs>
            <mask id={maskId}>
              <Rect
                {...defaultRectProps}
                x={xScaled}
                y={yScaled}
                fill="#fff"
                stroke="none"
              />
            </mask>
            {displayShadow ? (
              <g transform={`translate(${xScaled}, ${yScaled})`}>
                <Shadow {...this.getShadowProps()}>
                  <Rect
                    {...defaultRectProps}
                    {...borderProps}
                    x={0}
                    y={0}
                    fill={backgroundColor}
                  />
                </Shadow>
              </g>
            ) : null}
            <Rect
              {...defaultRectProps}
              x={xScaled}
              y={yScaled}
              fill={backgroundColor}
              stroke="none"
            />
            <Rect
              {...defaultRectProps}
              {...borderProps}
              x={xScaled}
              y={yScaled}
              fill={
                !showPlaceholder || showPlaceholderImage ? 'none' : '#eaeaea'
              }
            />
            {showPlaceholder ? (
              this.renderPlaceholder()
            ) : (
              <g mask={`url(#${maskId})`}>
                <image
                  href={src}
                  x={xScaled}
                  y={yScaled}
                  width={widthScaled}
                  height={heightScaled}
                  preserveAspectRatio={`xMidYMid ${resizeType}`}
                />
              </g>
            )}
          </g>
          {children}
        </g>
      </GroupWrapper>
    )
  }
}
