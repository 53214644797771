import React, { Component } from 'react'
import classNames from 'classnames'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import Icon from 'components/Shared/Icon'

import Option from './MenuOption'

export default class PanelMenu extends Component {
  static defaultProps = {
    placeholder: 'Select...',
    options: [],
    value: null,
  }

  handleSelect = val => {
    const { onChange } = this.props

    onChange(val)
  }

  elementRef = el => (this.element = el)

  render() {
    const { open, options, placeholder, value } = this.props

    const selectedOption = options.filter(
      o => value && o && o.value === value
    )[0]

    return (
      <MultiMenuTrigger menu={options} onSelect={this.handleSelect}>
        <div
          className={classNames('panel-menu', { open })}
          ref={this.elementRef}
        >
          <div className="panel-menu-selection">
            {selectedOption ? (
              <Option option={selectedOption} />
            ) : (
              <div className="panel-menu-placeholder">{placeholder}</div>
            )}
            <Icon type="expand" className="panel-menu-expand-arrow" />
          </div>
        </div>
      </MultiMenuTrigger>
    )
  }
}
