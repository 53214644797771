import DefaultTooltip from 'components/Shared/Tooltip'

const IMAGE_BASE_URL =
  process.env['REACT_APP_IMAGE_BASE_URL'] ||
  'https://proton-resources-production.imgix.net'

export const SAMPLE_FIELDS = {
  mainCollectionName: 'Things',
  fields: [
    { label: 'Name', type: 'text-format' },
    { label: 'Main Photo', type: 'photo' },
    { label: 'Short Description', type: 'text-format' },
    { label: 'Long Description', type: 'text-format' },
  ],
  data: [
    {
      name: 'Thing 1',
      image: {
        url: '526b44ac3f9fec3a865319ca9acc646b2851a2104075913205367606c09f5961.png',
        width: 1472,
        height: 1606,
        filename: 'Screen Shot 2023-11-14 at 2.35.56 PM.png',
      },
      shortDescription: 'A joyful and whimsical creation.',
      longDescription: `Thing 1 is a wonderfully whimsical creation that's sure to bring smiles and giggles to kids of all ages. It's like a playful puzzle of vibrant colors, friendly shapes, and delightful textures all mixed together in a delightful, charming way. Imagine a happy accident in a toy factory where all the best parts came together to create something truly special. Thing 1 has a magical knack for spreading joy and wonder wherever it goes. 
      Its mere presence can turn an ordinary day into an extraordinary adventure filled with laughter and happiness. Some say it was inspired by a group of friendly forest creatures who wanted to create something fun for kids to enjoy, while others believe it's a result of a mischievous fairy's enchanting spell.`,
    },
    {
      name: 'Thing 2',
      image: {
        url: '1ee30dbf7f50dc3d60c295825ed80a879738509b7448f6c5c5a3b98c158b1250.png',
        width: 1098,
        height: 1630,
        filename: 'Screen Shot 2023-11-14 at 2.36.21 PM.png',
      },
      shortDescription: 'Zany, hilarious friend for endless laughter.',
      longDescription: `Thing 2 is an uproarious and comically quirky character that will have kids of all ages bursting with laughter. Picture a delightful mishmash of zany colors, goofy shapes, and playful patterns, all magically woven together into a lovable, silly creature. It's like a hilarious science experiment gone perfectly awry or the result of a whimsical wizard's attempt at creating the world's funniest friend.

      No matter its origin, Thing 2 is the embodiment of pure, unadulterated fun. It's a reminder that laughter is one of life's greatest treasures, and the best way to enjoy it is by sharing it with friends. So, if you ever cross paths with Thing 2, be prepared to embrace the absurd, let loose, and laugh until your cheeks hurt because it's the ultimate ambassador of all things funny and fabulous in the world.`,
    },
    {
      name: 'Thing 3',
      image: {
        url: 'd0da19519e11ade32c94322acb68fc915f4e0b28f25459d2694d4b3171828a77.png',
        width: 2352,
        height: 1566,
        filename: 'Screen Shot 2023-11-14 at 2.36.49 PM.png',
      },
      shortDescription: 'Whimsical laughter-maker, a cosmic jester.',
      longDescription: `Thing 3 is a whimsical wonder that will tickle the funny bones of kids and grown-ups alike. Imagine a mishmash of goofball colors, quirky shapes, and playful patterns all melded together into a delightful and charming character. It's like a cosmic prank gone spectacularly right or the brainchild of a fun-loving inventor with an affinity for laughter. 
      Thing 3 is a joyful reminder that the world is filled with delightful surprises and that laughter is the best medicine for any situation. So, if you ever encounter Thing 3, be ready to embrace the absurd, share smiles with friends, and let your imagination soar because it's the embodiment of all things funny and fantastic in the universe.`,
    },
    {
      name: 'Thing 4',
      image: {
        url: '711ea86ca862b99e9a61716b7d973771b6c988078be860e7fce9e9c78d61de79.png',
        width: 1356,
        height: 1868,
        filename: 'Screen Shot 2023-11-14 at 2.37.06 PM.png',
      },
      shortDescription: 'Silly, whimsical laughter-maker, a pure delight',
      longDescription: `Thing 4 is a delightfully wacky creation that's bound to tickle the funny bone of kids and adults alike. Picture a mishmash of goofy colors, quirky shapes, and playful patterns all ingeniously woven together to form an endearingly silly character. It's like a whimsical chef's experiment gone hysterically right, or perhaps the result of a mischievous jester's prank on the art world.
       Thing 4 possesses an extraordinary talent for turning everyday moments into uproarious escapades. Its mere presence can transform the most mundane of days into a sidesplitting circus of hilarity and joy. Some say it was inspired by a group of friendly forest critters who wanted to create a laughter-inducing friend, while others believe it's the brainchild of a whimsical inventor with a penchant for amusing oddities.`,
    },
    {
      name: 'Thing 5',
      image: {
        url: '8328e27b426a4358efe7e86c67606e5e4efb0921b907ef45bfaa72537bcaedb4.png',
        width: 1052,
        height: 1034,
        filename: 'Screen Shot 2023-11-14 at 2.48.13 PM.png',
      },
      shortDescription: 'Hilarious, quirky buddy, laughter galore guaranteed.',
      longDescription: `Thing 5 possesses an uncanny ability to transform even the most ordinary moments into extraordinary adventures filled with laughter and joy. Its mere presence can turn a dull day into a riotous carnival of mirth and merriment. Some say it was born from a secret recipe concocted by a group of whimsical animals who just wanted to spread happiness, while others believe it's the brainchild of a madcap artist with a flair for the comically absurd.
       Regardless of its origin, Thing 5 is a heartwarming reminder that life is at its best when it's filled with laughter and unexpected surprises. So, if you ever encounter Thing 5, get ready to embrace the hilarious, share smiles with friends, and let your imagination run wild, because it's the embodiment of all things funny and fantastically fun in the world.`,
    },
  ],
}

type TooltipContent = 'records' | 'collections' | 'properties'

const TooltipContentDescription: { [key in TooltipContent]: string } = {
  records:
    'Records are individual items within a Collection. Think of them like rows in a spreadsheet.',
  collections:
    "Collections are like the 'nouns' of your app, representing different types of data such as Users, Orders, Restaurants, etc. Think of them like a spreadsheet table.",
  properties:
    'Properties are like adjectives that describe each item. Think of them like the column headings in a spreadsheet.',
}

const Tooltip: React.FC<{ content: TooltipContent }> = ({
  content,
  children,
}) => (
  <DefaultTooltip
    tooltip={TooltipContentDescription[content]}
    placement="right-end"
  >
    {children}
  </DefaultTooltip>
)

export const DATA_OPTIONS = [
  {
    dataAdaloId: 'onboarding-data-sample',
    label: 'Start with Sample Data',
    value: 'sample',
    description: 'You’ll be able to add your own data later.',
    dropdownContent: (
      <div className="dropdown-content">
        <p>
          5{' '}
          <Tooltip content="records">
            <span className="keyword">Records</span>
          </Tooltip>{' '}
          have been added to your database{' '}
          <Tooltip content="collections">
            <span className="keyword">Collection</span>
          </Tooltip>
        </p>
        <p>
          Each record has 4{' '}
          <Tooltip content="properties">
            <span className="keyword">Properties</span>
          </Tooltip>
          : Name, Main Photo, Short Description, and Long Description.
        </p>
      </div>
    ),
  },
  {
    dataAdaloId: 'onboarding-data-csv',
    label: 'Import My Data From a CSV',
    value: 'csv',
    description: 'To do this, you’ll simply upload a CSV file of your data.',
    dropdownContent: (
      <div className="dropdown-content">
        <p>
          {/* TODO @danicunhac: uncomment this when we have the CSV available for download */}
          {/* Download this{' '}
            <a
              className="onboarding-text-link"
              href="https://app.adalo.com"
              target="_blank"
              rel="noreferrer"
            >
              Sample Sheet
            </a>{' '}
            to see the format. You will upload your CSV inside of the database tab
            after you create your app. */}
          You can upload your CSV on the database tab after you create your app.
        </p>
        <p>
          5 sample{' '}
          <Tooltip content="records">
            <span className="keyword">Records</span>
          </Tooltip>{' '}
          have been added for now.
        </p>
      </div>
    ),
  },
  {
    dataAdaloId: 'onboarding-data-external',
    label: 'Sync My Data From Existing Software',
    value: 'integration',
    description: (
      <span>
        Here’s a{' '}
        <a
          className="onboarding-text-link"
          href="https://www.adalo.com/external-collections"
          target="_blank"
          rel="noreferrer"
        >
          List of Integrations
        </a>{' '}
        you can sync your data from.
      </span>
    ),
    dropdownContent: (
      <div className="dropdown-content">
        <p>
          To complete your integration you will use the External Collections
          feature. Here’s a{' '}
          <a
            className="onboarding-text-link"
            href="https://help.adalo.com/integrations/external-collections-with-apis"
            target="_blank"
            rel="noreferrer"
          >
            Help Doc
          </a>{' '}
          for how to do that after creating your app.
        </p>
        <p>
          5 sample{' '}
          <Tooltip content="records">
            <span className="keyword">Records</span>
          </Tooltip>{' '}
          have been added for now.
        </p>
      </div>
    ),
  },
]

export const SAMPLE_APP_DATA = {
  previewIcon: `${IMAGE_BASE_URL}/d59e2a7ea522989500770318df0772595be8ae67cad62885579be5026ae8a50c.png`,
  previewBanner: `${IMAGE_BASE_URL}/96c774e5472eac1d613ec8904d4c4ddb743cc56709f9e3b87475f5d59cd77744.png`,
}

export const AUDIENCE_OPTIONS = [
  {
    value: 'Small Business Operator',
    label: 'My existing customers & community members',
    primaryUse: 'Work',
  },
  {
    value: 'Enterprise Employee',
    label: 'My co-workers and/or employees — I’m building internal apps',
    primaryUse: 'Work',
  },
  {
    value: 'Aspiring Founder',
    label: 'My future users — I have a startup idea!',
    primaryUse: 'Maker',
  },
  {
    value: 'Freelancer/Agency',
    label: 'My clients — I’m a freelancer',
    primaryUse: 'Maker',
  },
  {
    value: 'Education',
    label: 'My class — I’m using Adalo for educational purposes',
    primaryUse: 'Education',
  },
  {
    value: 'Maker',
    label: 'Myself — I’m just playing around',
    primaryUse: 'Maker',
  },
]
