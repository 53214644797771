import React, { useState, createContext } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'

import { getOrgApps } from 'ducks/apps'
import { getPricingPlans } from 'ducks/billing'
import { getSelectedPlanValue } from 'ducks/trialWarning'
import { getCurrentOrganization } from 'ducks/organizations'

import { getPlanName } from 'utils/billing.ts'

export const PUBLISHED_APPS_STEP = 'PUBLISHED_APPS_STEP'
export const TEAM_MEMBERS_STEP = 'TEAM_MEMBERS_STEP'
export const PAYMENT_STEP = 'PAYMENT_STEP'

export const PUBLISHED_APP_BY_PLAN = {
  Starter: 1,
  Professional: 2,
  Team: 5,
  Business: 10,
}

export const PRICE_PER_APP_ADDON = 25

export const EDITORS_BY_PLAN = {
  Starter: 1,
  Professional: 5,
  Team: 10,
  // Business plans have unlimited app editors, so we do not show the team member selection screen
}

export const PRICE_PER_TEAM_MEMBER_ADDON = 15

export const PlanChangeContext = createContext({
  setCurrentStep: () => {},
  addTeamMember: () => {},
  removeTeamMember: () => {},
  changeAppList: () => {},
  resetPlanChangeContext: () => {},
  currentStep: '',
  appList: [],
  teamMembersToRemove: [],
  additionalPublishedApps: 0,
  additionalTeamMembers: 0,
  includedPublishedApps: 0,
  includedTeamMembers: 0,
  appLimit: 0,
  teamMemberLimit: 0,
  appOverage: 0,
  teamMemberOverage: 0,
  currentTeamMemberCount: 0,
  currentPublishedApps: 0,
})

const PlanChangeContextWrapper = ({ children }) => {
  // Common Logic
  const selectedPlan = useSelector(getSelectedPlanValue)
  const pricingPlans = useSelector(getPricingPlans)
  const selectedPlanName = getPlanName(selectedPlan, pricingPlans)
  // End Common Logic

  // Published Apps Logic
  /*
  We need a separate list of apps with their own published statuses that
  that the user can freely change when they are switching to a new plan.
  The new published statuses will be persisted only on payment submission.
  */
  const appsData = cloneDeep(useSelector(getOrgApps))
  const [appList, setAppList] = useState(appsData)

  const changeAppList = datasourceId => {
    setAppList(currentAppList => {
      const currentApps = currentAppList[datasourceId]
      const updatedApps = currentApps.map(app => ({
        ...app,
        published: !app.published,
      }))

      return {
        ...currentAppList,
        [datasourceId]: updatedApps,
      }
    })
  }

  const appLimit = PUBLISHED_APP_BY_PLAN[selectedPlanName] || 0

  /*
  Add up published apps - if any app belonging to a datasource is published,
  all of them should be published, so add the full length to the accumulator 
  */
  const currentPublishedApps = Object.values(appsData).reduce(
    (acc, apps) => (apps.some(app => app.published) ? acc + 1 : acc),
    0
  )

  const numberOfPublishdApps = Object.values(appList).reduce(
    (acc, apps) => (apps.some(app => app.published) ? acc + 1 : acc),
    0
  )
  const includedPublishedApps = Math.min(numberOfPublishdApps, appLimit)
  const additionalPublishedApps = numberOfPublishdApps - includedPublishedApps
  const appOverage = additionalPublishedApps
  //End Published Apps Logic

  // Team Member Logic
  /*
  We need a list of team members that will be removed from the new plan.
  By default all team members are added to the new plan and the user
  needs to manually remove each team member they no longer want.
  The list of users to remove will be persisted only on payment submission.
  */
  const [teamMembersToRemove, setTeamMembersToRemove] = useState([])

  const { Users: users } = useSelector(getCurrentOrganization)

  const removeTeamMember = user => {
    setTeamMembersToRemove(currentTeamMembers => [...currentTeamMembers, user])
  }

  const addTeamMember = user => {
    setTeamMembersToRemove(currentTeamMembers =>
      currentTeamMembers.filter(teamMember => teamMember.id !== user.id)
    )
  }

  const currentTeamMemberCount = users.length
  const teamMemberLimit = EDITORS_BY_PLAN[selectedPlanName] || 0
  const activeTeamMembers = users.length - teamMembersToRemove.length
  const includedTeamMembers = Math.min(activeTeamMembers, teamMemberLimit)
  const additionalTeamMembers =
    teamMemberLimit === 0 ? 0 : activeTeamMembers - includedTeamMembers
  const teamMemberOverage = additionalTeamMembers
  // End Team Member Logic

  const [currentStep, setCurrentStep] = useState('')

  const resetPlanChangeContext = () => {
    setTeamMembersToRemove([])
    setAppList(appsData)
  }

  const value = {
    setCurrentStep,
    addTeamMember,
    removeTeamMember,
    changeAppList,
    resetPlanChangeContext,
    currentStep,
    appList,
    teamMembersToRemove,
    additionalPublishedApps,
    additionalTeamMembers,
    includedPublishedApps,
    includedTeamMembers,
    appLimit,
    teamMemberLimit,
    appOverage,
    teamMemberOverage,
    numberOfPublishdApps,
    currentTeamMemberCount,
    currentPublishedApps,
  }

  return (
    <PlanChangeContext.Provider value={value}>
      {children}
    </PlanChangeContext.Provider>
  )
}

export default PlanChangeContextWrapper
