import { useMemo } from 'react'
import { Field } from 'redux-form'
import classNames from 'classnames'

import SelectList from 'components/Shared/Forms/SelectList'
import Icon from 'components/Shared/Icon'
import Loading from 'components/Shared/Loading'
import { uploadURL } from 'utils/uploads'
import { DATA_OPTIONS, SAMPLE_FIELDS } from './constants'
import { SampleDataRow } from './types'

export type SampleDataProps = {
  mainCollectionName?: string
  dataOption: 'sample' | 'csv' | 'integration' | string
  setDataOption: (value: 'sample' | 'csv' | 'integration' | string) => void
}

const SampleData = (props: SampleDataProps): JSX.Element => {
  const { mainCollectionName, dataOption, setDataOption } = props

  return (
    <>
      <h3>
        Let&rsquo;s get some &lsquo;{mainCollectionName}&rsquo; data in your
        first app.
      </h3>
      <div className="onboarding-audience-list">
        <Field
          id="dataOption"
          name="dataOption"
          label="How do you want to add your data?"
          className="data-option"
          type="text"
          component={SelectList}
          options={DATA_OPTIONS}
          in={dataOption}
          dropdown
          onChangeValue={(value: string) => setDataOption(value)}
        />
      </div>
    </>
  )
}

type SampleDataGraphicsProps = {
  dataOption: SampleDataProps['dataOption']
  sampleData: SampleDataRow[] | undefined
  mainCollectionName?: string | undefined
  loading?: boolean
}

export const SampleDataGraphics: React.FC<SampleDataGraphicsProps> = (
  props: SampleDataGraphicsProps
) => {
  const { dataOption, loading, sampleData, mainCollectionName } = props

  const showSampleData = dataOption && !loading

  const databaseCollectionTitle = useMemo(() => {
    if (!showSampleData) return 'Database Collection'

    if (!mainCollectionName) return SAMPLE_FIELDS.mainCollectionName

    return mainCollectionName
  }, [showSampleData, mainCollectionName])

  const showRealData = sampleData?.length && dataOption && !loading

  const renderItem = (key: string, value: string | { url: string }) => {
    const itemClassname =
      'onboarding-graphics__sample-data__table__body__row__item'

    if (showSampleData) {
      return (
        <div key={key} className={`${itemClassname} ${itemClassname}--${key}`}>
          {key === 'image' && typeof value === 'object' && value.url ? (
            <img
              src={
                uploadURL(value?.url, null, {
                  width: 200,
                  height: 100,
                  dpr: 10,
                }) as string
              }
              alt="Sample Data"
            />
          ) : (
            <p>{value}</p>
          )}
        </div>
      )
    }

    return (
      <div key={key} className={`${itemClassname} ${itemClassname}--${key}`}>
        {key === 'image' && typeof value === 'object' && (
          <div className="onboarding-graphics__sample-data__table__body__row__item--picture" />
        )}
        {key === 'name' && <p>Name</p>}
        {key === 'shortDescription' && <p>A short description goes here...</p>}
        {key === 'longDescription' && <p>A long description goes here...</p>}
      </div>
    )
  }

  return (
    <div className="onboarding-graphics__sample-data">
      <div className="onboarding-graphics__sample-data__table">
        <div
          className={classNames(
            'onboarding-graphics__sample-data__table__title',
            {
              'onboarding-graphics__sample-data__table__title--selected':
                showSampleData,
            }
          )}
        >
          <h1>{databaseCollectionTitle}</h1>
          {dataOption && loading && <Loading small />}
        </div>
        <div className="onboarding-graphics__sample-data__table__header">
          {SAMPLE_FIELDS.fields.map(field => (
            <div
              key={field.label}
              className="onboarding-graphics__sample-data__table__header__item"
            >
              <Icon type={field.type} />
              {field.label}
            </div>
          ))}
        </div>
        <div
          className={classNames(
            'onboarding-graphics__sample-data__table__body',
            {
              'onboarding-graphics__sample-data__table__body--placeholder':
                !dataOption || loading,
            }
          )}
        >
          {(showRealData ? sampleData : SAMPLE_FIELDS.data).map(
            row =>
              row && (
                <div
                  key={row?.name}
                  className="onboarding-graphics__sample-data__table__body__row"
                >
                  {renderItem('name', row?.name ?? '')}
                  {renderItem('image', row?.image ?? '')}
                  {renderItem('shortDescription', row?.shortDescription ?? '')}
                  {renderItem('longDescription', row?.longDescription ?? '')}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  )
}

export default SampleData
