import {
  COMPONENT,
  ELLIPSE,
  GROUP,
  IMAGE,
  LABEL,
  LIST,
  SECTION,
  responsivePositioningOptions,
  resizingOptions,
} from '@adalo/constants'

import {
  subPath,
  pathLength,
  getObject,
  remove,
  insert,
  remapSiblings,
  getInsertPath,
  getDeviceType,
} from '@adalo/utils'

import { updateObject } from 'ducks/editor/objects'
import { updateObjectWidth } from 'utils/text'
import { isEditableSectionElement } from './layoutSections'
import getDeviceObject from './getDeviceObject'
import { selectObjectFromMouseCoords } from './geometry'

const { LEFT, RIGHT, CENTER, LEFT_AND_RIGHT } = responsivePositioningOptions
const { FIXED, SCALES_WITH_PARENT } = resizingOptions

const PARENT_TYPES = [COMPONENT, LIST, SECTION, IMAGE, ELLIPSE]
/**
 * @typedef {import('ducks/editor/types/DeviceType').DeviceType} DeviceType
 * @type {ReadonlyArray<DeviceType>}
 */
export const CONTAINER_TYPES = [GROUP, LIST, SECTION, IMAGE, ELLIPSE]

/**
 * Returns list of parent elements which an object can be dropped into/dragged out of.
 */
export const getParentIds = (list, map, objectId, useUpdatedGroups) => {
  const path = map[objectId]
  const parentIds = []
  const parentTypes = [...PARENT_TYPES]

  for (let i = 1; i <= pathLength(path) - 1; i += 1) {
    const fragment = subPath(path, i)
    const parentObj = getObject(list, fragment)

    if (useUpdatedGroups) {
      parentTypes.push(GROUP)
    }

    if (parentTypes.includes(parentObj.type)) {
      parentIds.push(parentObj.id)
    }
  }

  return parentIds
}

/**
 * @typedef {{x: number, width: number}} HorizontalLayout
 *
 * @typedef {import('./responsiveTypes').Responsivity} Responsivity
 */

/**
 * @param {Responsivity} childResponsivity
 * @param {HorizontalLayout} childLayout
 * @param {HorizontalLayout} oldParentLayout
 * @param {HorizontalLayout} newParentLayout
 * @returns {HorizontalLayout}
 */
export const mapHorizontalLayout = (
  childResponsivity,
  childLayout,
  oldParentLayout,
  newParentLayout
) => {
  const { horizontalPositioning = LEFT, horizontalScaling = FIXED } =
    childResponsivity
  const { x: childX, width: childWidth } = childLayout
  const { x: oldParentX, width: oldParentWidth } = oldParentLayout
  const { x: newParentX, width: newParentWidth } = newParentLayout

  const childXOffset = childX - oldParentX

  if (
    horizontalScaling === SCALES_WITH_PARENT &&
    horizontalPositioning === CENTER
  ) {
    // Find left gap and width as relative %
    const relativeChildLeftGap = childXOffset / oldParentWidth
    const relativeChildWidth = childWidth / oldParentWidth

    const newChildXOffset = relativeChildLeftGap * newParentWidth
    const newChildWidth = relativeChildWidth * newParentWidth

    return {
      x: newChildXOffset + newParentX,
      width: newChildWidth,
    }
  } else if (
    horizontalScaling === SCALES_WITH_PARENT &&
    horizontalPositioning === LEFT_AND_RIGHT
  ) {
    // Find fixed distance from both left and right sides
    const childRightGap = oldParentWidth - (childXOffset + childWidth)
    const newChildWidth = newParentWidth - (childXOffset + childRightGap)

    return {
      x: childXOffset + newParentX,
      width: newChildWidth,
    }
  } else if (horizontalScaling === FIXED && horizontalPositioning === LEFT) {
    // Find fixed distance from left side and fixed width
    return {
      x: childXOffset + newParentX,
      width: childWidth,
    }
  } else if (horizontalScaling === FIXED && horizontalPositioning === CENTER) {
    // Find % distance to center anchor
    const oldChildAnchorX = childXOffset + childWidth / 2
    const childRelativeX = oldChildAnchorX / oldParentWidth
    const newChildAnchorX = childRelativeX * newParentWidth
    const newChildXOffset = newChildAnchorX - childWidth / 2

    return {
      x: newChildXOffset + newParentX,
      width: childWidth,
    }
  } else if (horizontalScaling === FIXED && horizontalPositioning === RIGHT) {
    const oldChildRightEdge = childX + childWidth
    const oldParentRightEdge = oldParentX + oldParentWidth
    const childRightGap = oldParentRightEdge - oldChildRightEdge

    const newParentRightEdge = newParentX + newParentWidth
    const newChildRightEdge = newParentRightEdge - childRightGap
    const newChildXOffset = newChildRightEdge - childWidth

    return {
      x: newChildXOffset,
      width: childWidth,
    }
  }

  throw new Error(
    `Found unsupported horizontal responsivity (positioning: ${horizontalPositioning}; scaling: ${horizontalScaling})`
  )
}

/**
 *
 * @param {import('ducks/editor/types/ObjectList').ObjectList} list
 * @param {import('ducks/editor/types/ObjectPathMap').ObjectPathMap} map
 * @param {string} id
 * @param {string} parentId
 * @returns {[import('ducks/editor/types/ObjectList').ObjectList, import('ducks/editor/types/ObjectPathMap').ObjectPathMap]}
 */
export const changeObjectParent = (list, map, id, parentId) => {
  const path = map[id]

  /** @type {import('./responsiveTypes').EditorObject} */
  const object = getObject(list, path)

  list = remove(list, path)
  map = remapSiblings(list, map, path)
  const parentPath = map[parentId]
  const newPath = getInsertPath(list, parentPath)
  list = insert(list, newPath, object)
  map = remapSiblings(list, map, newPath)

  return [list, map]
}

export const getObjectAlignment = object => {
  return object?.responsivity?.horizontalPositioning || CENTER
}

export const getWrapperPosition = (object, editorResizingProps) => {
  if (!object) {
    return 0
  }

  const { responsivity, x } = object
  const { horizontalPositioning, horizontalScaling } = responsivity || {}

  switch (horizontalPositioning) {
    case LEFT_AND_RIGHT:
      return x / 2
    case LEFT:
      return 0
    case RIGHT:
      if (horizontalScaling === SCALES_WITH_PARENT) {
        return `${(1 - editorResizingProps.relativeWidth) * 100}%`
      }

      return '100%'
    default:
      return `${editorResizingProps.relativePosition * 100}%`
  }
}

export const getRelativeX = (object, componentWidth, parentLayout) => {
  if (!object || !componentWidth) {
    return 0
  }

  const { responsivity, x, width } = object
  const { horizontalPositioning, horizontalScaling } = responsivity || {}
  const scalingWithParent = horizontalScaling === SCALES_WITH_PARENT
  const parentWidth = parentLayout?.initialWidth || componentWidth

  const xOffset = parentLayout?.x || 0

  switch (horizontalPositioning) {
    case LEFT_AND_RIGHT:
      return 0 - xOffset
    case LEFT:
      return x - xOffset
    case RIGHT:
      if (scalingWithParent) {
        return x - xOffset + width - parentWidth
      }

      return x - xOffset - parentWidth
    default:
      if (scalingWithParent) {
        return 0
      }

      return -width / 2
  }
}

export const getRelativePosition = (object, componentWidth, parentLayout) => {
  if (!object || !componentWidth) {
    return 0
  }

  const parentWidth = parentLayout?.initialWidth || componentWidth
  const parentX = parentLayout?.x || 0

  const { x, width } = object
  const center = x - parentX + width / 2

  return center / parentWidth
}

export const getEditorResizingProps = (
  object,
  componentWidth,
  parentLayout
) => {
  if (!object || !componentWidth) {
    return {}
  }

  const { x, width, responsivity } = object
  const { horizontalScaling } = responsivity || {}
  const alignment = getObjectAlignment(object)

  const editorResizingProps = {
    x,
    relativeX: getRelativeX(object, componentWidth, parentLayout),
    horizontalPositioning: alignment,
    initialWidth: width,
  }

  if (alignment === CENTER) {
    editorResizingProps.relativePosition = getRelativePosition(
      object,
      componentWidth,
      parentLayout
    )
  } else {
    editorResizingProps.parentInitialWidth =
      parentLayout?.initialWidth || componentWidth
  }

  if (horizontalScaling === SCALES_WITH_PARENT) {
    const parentWidth = parentLayout?.initialWidth || componentWidth
    editorResizingProps.relativeWidth = width / parentWidth

    if (alignment === LEFT_AND_RIGHT) {
      const padding = parentWidth - width
      editorResizingProps.padding = padding
      editorResizingProps.relativeWidth = `calc(100% - ${padding / 2}px)`
    }
  }

  return editorResizingProps
}

export const reducer = (previousValue, currentValue) => {
  const currentRight = currentValue.x + currentValue.width

  return {
    left: Math.min(previousValue.left, currentValue.x),
    right: Math.max(previousValue.right, currentRight),
  }
}

export const halveWidth = width => {
  if (typeof width === 'number') {
    return width / 2
  }

  return '50%'
}

export const updateText = object => {
  const { id, type } = object || {}

  if (type === LABEL) {
    const { layoutText, height } = updateObjectWidth(object)
    updateObject(id, { layoutText, height })
    object.layoutText = layoutText
    object.height = height
  }
}

export const isShared = (object, deviceType) => {
  if (!object?.shared || !deviceType) {
    return true
  }

  return object.shared[deviceType]
}

export const hasDevicePosition = (object, deviceType) => {
  if (!object?.[deviceType]) {
    return false
  }

  return (
    typeof object[deviceType].x === 'number' ||
    typeof object[deviceType].y === 'number'
  )
}

/**
 *
 * @param {import('./responsiveTypes').EditorObject} screen
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @param {number} zoom
 * @param {{mouseX?: number, mouseY?: number}} mouseCoords
 * @returns {string[]}
 */
export const getBestParentForNewObject = (
  screen,
  x,
  y,
  width,
  height,
  zoom,
  mouseCoords
) => {
  if (!screen) {
    return []
  }

  // translate the x and y to the screen's coordinate system
  x -= screen.x
  y -= screen.y

  const deviceType = getDeviceType(screen.width)

  const possibleParent = screen.children.find(child => {
    let invalidParentComponent = !CONTAINER_TYPES.includes(child.type)

    if (isEditableSectionElement(child)) {
      invalidParentComponent = false
    }

    const isComponentHidden =
      child.deviceVisibility && !child.deviceVisibility[deviceType]

    if (invalidParentComponent || isComponentHidden) {
      return false
    }

    const {
      x: parentX,
      y: parentY,
      width: parentW,
      height: parentH,
    } = getDeviceObject(child, deviceType)

    const parentMeasures = {
      x: parentX,
      y: parentY,
      width: parentW,
      height: parentH,
    }

    const insideAxisX =
      parentMeasures.x <= x &&
      parentMeasures.x + parentMeasures.width >= x + width

    const insideAxisY =
      parentMeasures.y <= y &&
      parentMeasures.y + parentMeasures.height >= y + height

    if (mouseCoords?.mouseX && mouseCoords?.mouseY) {
      child = {
        width: child.width,
        height: child.height,
        x: child.x + screen.x,
        y: child.y + screen.y,
      }

      const isMouseInsideParentBounds = selectObjectFromMouseCoords(
        child,
        zoom,
        mouseCoords
      )

      return isMouseInsideParentBounds
    } else {
      return insideAxisX && insideAxisY
    }
  })

  if (possibleParent) {
    return [possibleParent.id]
  }

  return []
}
