import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { dataTypes } from '@adalo/constants'

import { getComparatorOptions, getComparisonDataTypes } from 'utils/comparators'

import { selectObject } from 'ducks/editor/objects'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Filters from 'components/Shared/Filters'

import ComparatorOptions from 'components/Shared/Filters/ComparatorOptions'

const Binding = ({
  appId,
  binding,
  componentId,
  object,
  objectId,
  field,
  menuTheme,
  state,
}) => {
  const comparatorOptions = getComparatorOptions(binding?.source?.dataType)

  const [comparisonDataType] = getComparisonDataTypes(
    binding?.comparator,
    binding?.source
  )

  const parentSource = binding?.source?.source
  const showFilter = parentSource?.dataType === dataTypes.LIST
  const filterProps = { appId, binding, componentId, object }

  return (
    <>
      {showFilter && (
        <Filters
          {...filterProps}
          name={`${field}.source.source.options.filter`}
        />
      )}
      <Field
        autoSelect
        name={`${field}.comparator`}
        component={WrappedSelect}
        options={comparatorOptions}
        rowHeight={40}
        menuTheme={menuTheme}
      />
      <ComparatorOptions
        fieldName={field}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        comparator={binding?.comparator}
        comparatorOptions={binding?.comparatorOptions}
        comparisonDataType={comparisonDataType || dataTypes.TEXT}
        comparison={binding?.comparison}
        comparison2={binding?.comparison2}
        boxed
        menuTheme={menuTheme}
      />
    </>
  )
}

Binding.propTypes = {
  appId: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  binding: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
}

const mapStateToProps = (state, props) => {
  const { objectId } = props

  return {
    object: selectObject(state, objectId),
    state,
  }
}

export default connect(mapStateToProps)(Binding)
