import DeviceType from 'ducks/editor/types/DeviceType'
import ObjectType from 'ducks/editor/types/ObjectType'
import LayoutContext from '../layout/LayoutContext'
import Length from '../Length'
import ObjectNode from '../ObjectNode'
import { FlexLength, TrackSizeFunction, TRACK_SIZE_AUTO } from '../styles'
import cellId from './cellId'
import GridItem from './GridItem'
import measureGridSize from './measureGridSize'
import SizedGrid from './SizedGrid'
import SizedTrack from './SizedTrack'

interface UnsizedGrid {
  items: GridItem[]
  rows: TrackSizeFunction[]
  columns: TrackSizeFunction[]
}

interface TrackSizeRange {
  sizeFn: TrackSizeFunction
  baseSize: number
  growthLimit: number
}

const gridAutoTrack = (): TrackSizeFunction => {
  // In future, this may be added as a configurable style. For now we can hard-code it.
  return TRACK_SIZE_AUTO
}

const initializeGridTracks = (
  explicitTrackSizes: TrackSizeFunction[],
  numImplicitTracks: number,
  gapSize: TrackSizeFunction
): TrackSizeFunction[] => {
  const implicitTracks = [...explicitTrackSizes]

  // Append any new implicit tracks
  const autoTrackSize = gridAutoTrack()
  while (implicitTracks.length < numImplicitTracks) {
    implicitTracks.push(autoTrackSize)
  }

  // Inject gutters as tracks
  const result: TrackSizeFunction[] = []
  for (const track of implicitTracks) {
    result.push(track, gapSize)
  }

  // Remove the last gutter
  result.pop()

  return result
}

/**
 * Calculates adjusted track indexes for grid items where gutters are introduced as their own, empty tracks.
 *
 * Each index should be incremented by the number of gutters that precede it (ie, 0 => 0, 1 => 2, 2 => 4, ...)
 */
const shiftTrackIndexForGutters = (standardIdx: number): number => {
  return standardIdx * 2
}

const shiftGridItemForGutters = (item: GridItem): GridItem => {
  const { row, column } = item

  return {
    ...item,
    row: shiftTrackIndexForGutters(row),
    column: shiftTrackIndexForGutters(column),
  }
}

const initializeUnsizedGrid = (
  container: ObjectNode<ObjectType>,
  items: GridItem[],
  implicitRowCount: number,
  implicitColumnCount: number,
  device: DeviceType | undefined
): UnsizedGrid => {
  const { trackSizes: gridRows } = container.getStyle(
    'gridTemplateRows',
    device
  )
  const { trackSizes: gridColumns } = container.getStyle(
    'gridTemplateColumns',
    device
  )

  const rowGap = container.getStyle('rowGap', device)
  const columnGap = container.getStyle('columnGap', device)

  const rows = initializeGridTracks(gridRows, implicitRowCount, rowGap)
  const columns = initializeGridTracks(
    gridColumns,
    implicitColumnCount,
    columnGap
  )

  const shiftedItems = items.map(shiftGridItemForGutters)

  return {
    items: shiftedItems,
    rows,
    columns,
  }
}

const initTrackSize = (
  sizeFn: TrackSizeFunction,
  containerSize: number | undefined
): TrackSizeRange => {
  if (sizeFn instanceof Length) {
    const fixedSize = sizeFn.toExact(containerSize)

    return {
      sizeFn,
      baseSize: fixedSize,
      growthLimit: fixedSize,
    }
  }

  if (sizeFn === TRACK_SIZE_AUTO || sizeFn instanceof FlexLength) {
    return {
      sizeFn,
      baseSize: 0,
      growthLimit: Infinity,
    }
  }

  throw new Error(`Unknown TrackSizeFunction type: ${typeof sizeFn}`)
}

const initTrackSizeRanges = (
  tracks: TrackSizeFunction[],
  containerSize: number | undefined
): TrackSizeRange[] =>
  tracks.map(sizeFn => initTrackSize(sizeFn, containerSize))

const generateColumnContextMap = (
  rowBounds: number[],
  columns: TrackSizeRange[],
  context: LayoutContext
): Record<string, LayoutContext> => {
  const {
    device,
    viewportWidth,
    viewportHeight,
    instruction,
    viewingDevice,
    subtreeRootObject,
  } = context

  const result: Record<string, LayoutContext> = {}

  for (const [rowIdx, rowSize] of rowBounds.entries()) {
    for (const [columnIdx, columnSize] of columns.entries()) {
      const key = cellId(rowIdx, columnIdx)

      // This context should represent the smallest possible column and allow the largest possible row.
      const cellWidth = columnSize.baseSize
      const cellHeight = rowSize === Infinity ? undefined : rowSize

      const cellContext: LayoutContext = {
        device,
        containerWidth: cellWidth,
        containerHeight: cellHeight,
        // Offsets are not relevant for this case. Set to zero to avoid any confusion.
        containerOffsetX: 0,
        containerOffsetY: 0,
        viewportWidth,
        viewportHeight,
        instruction,
        viewingDevice,
        subtreeRootObject,
      }

      result[key] = cellContext
    }
  }

  return result
}
const generateRowContextMap = (
  rows: TrackSizeRange[],
  columnBounds: number[],
  context: LayoutContext
): Record<string, LayoutContext> => {
  const {
    device,
    containerWidth,
    viewportWidth,
    viewportHeight,
    instruction,
    viewingDevice,
    subtreeRootObject,
  } = context
  const result: Record<string, LayoutContext> = {}

  for (const [rowIdx, rowSize] of rows.entries()) {
    for (const [columnIdx, columnSize] of columnBounds.entries()) {
      const key = cellId(rowIdx, columnIdx)

      // This context should represent the smallest possible row and allow the largest possible column.
      const cellWidth = columnSize === Infinity ? containerWidth : columnSize
      const cellHeight = rowSize.baseSize

      const cellContext: LayoutContext = {
        device,
        containerWidth: cellWidth,
        containerHeight: cellHeight,
        // Offsets are not relevant for this case. Set to zero to avoid any confusion.
        containerOffsetX: 0,
        containerOffsetY: 0,
        viewportWidth,
        viewportHeight,
        instruction,
        viewingDevice,
        subtreeRootObject,
      }

      result[key] = cellContext
    }
  }

  return result
}

const resolveColumnContributions = (
  columns: TrackSizeRange[],
  items: GridItem[],
  columnContextMap: Record<string, LayoutContext>
): number[] => {
  const itemsByColumn: Record<number, GridItem[]> = {}
  for (const item of items) {
    const { column } = item
    const trackItems = itemsByColumn[column] ?? []
    trackItems.push(item)
    itemsByColumn[column] = trackItems
  }

  const results: number[] = []
  for (const columnIdx of columns.keys()) {
    const trackItems = itemsByColumn[columnIdx] ?? []
    let maxContribution = 0
    for (const { row, column, element } of trackItems) {
      const cell = cellId(row, column)
      const context = columnContextMap[cell]
      if (context === undefined) {
        throw new Error(
          `Failed to find context in index (Row: ${row}, Column ${column})`
        )
      }

      const { width } = element.layout(context)
      maxContribution = Math.max(maxContribution, width)
    }

    results[columnIdx] = maxContribution
  }

  return results
}

const resolveRowContributions = (
  rows: TrackSizeRange[],
  items: GridItem[],
  rowContextMap: Record<string, LayoutContext>
): number[] => {
  const itemsByRow: Record<number, GridItem[]> = {}
  for (const item of items) {
    const { row } = item
    const trackItems = itemsByRow[row] ?? []
    trackItems.push(item)
    itemsByRow[row] = trackItems
  }

  const results: number[] = []
  for (const rowIdx of rows.keys()) {
    const trackItems = itemsByRow[rowIdx] ?? []
    let maxContribution = 0
    for (const { row, column, element } of trackItems) {
      const cell = cellId(row, column)
      const context = rowContextMap[cell]
      if (context === undefined) {
        throw new Error(
          `Failed to find row context (Row: ${row}, Column: ${column})`
        )
      }

      const { height } = element.layout(context)
      maxContribution = Math.max(maxContribution, height)
    }

    results[rowIdx] = maxContribution
  }

  return results
}

const resolveIntrinsicTrackSizes = (
  trackRanges: TrackSizeRange[],
  contentContributions: number[]
): TrackSizeRange[] => {
  /*
   * This implementation is greatly simplified because items cannot currently span multiple tracks. Reference the CSS
   * track sizing algorithm if you plan on adding support for multi-track spans.
   *
   * Reference: https://drafts.csswg.org/css-grid/#algo-flex-tracks
   */
  const result = trackRanges.map((trackSize, index) => {
    const { sizeFn, baseSize, growthLimit } = trackSize

    // This calculation step only applies to 'auto' or flexible tracks
    if (sizeFn !== TRACK_SIZE_AUTO && !(sizeFn instanceof FlexLength)) {
      return trackSize
    }

    const contentContribution = contentContributions[index] ?? 0
    if (contentContribution <= 0) {
      return trackSize
    }

    return {
      ...trackSize,
      baseSize: Math.min(Math.max(baseSize, contentContribution), growthLimit),
    }
  })

  return result
}

const createSizedTracks = (ranges: TrackSizeRange[]): number[] =>
  ranges.map<number>(trackSize => trackSize.baseSize)

const calculateFreeSpace = (
  trackRanges: TrackSizeRange[],
  containerSize: number | undefined
): number => {
  const aggregateTrackSpace = measureGridSize(
    createSizedTracks(trackRanges).map<SizedTrack>(exactSize => ({ exactSize }))
  )

  // TODO(toby): Account for the `minHeight` style on the container if it is defined.
  const freeSpace =
    containerSize === undefined ? Infinity : containerSize - aggregateTrackSpace

  return freeSpace
}

const calculateFlexFraction = (
  trackRanges: TrackSizeRange[],
  containerSize: number | undefined
): number => {
  const freeSpace = calculateFreeSpace(trackRanges, containerSize)

  if (freeSpace === Infinity || containerSize === undefined) {
    let maxFraction = 0
    for (const track of trackRanges) {
      const { sizeFn, baseSize } = track
      if (sizeFn instanceof FlexLength) {
        const flexFactor = sizeFn.factor
        const trackFraction = baseSize / flexFactor
        maxFraction = Math.max(maxFraction, trackFraction)
      }
    }

    return maxFraction
  }

  const nonFlexTracks = trackRanges.filter(
    ({ sizeFn }) => !(sizeFn instanceof FlexLength)
  )
  const baseNonFlexSize = nonFlexTracks.reduce(
    (sum, { baseSize }) => sum + baseSize,
    0
  )

  const leftoverSpace = containerSize - baseNonFlexSize

  let flexFactorSum = 0
  for (const track of trackRanges) {
    const { sizeFn } = track
    if (sizeFn instanceof FlexLength) {
      flexFactorSum += sizeFn.factor
    }
  }

  return leftoverSpace / flexFactorSum
}

/**
 * Reference: https://drafts.csswg.org/css-grid/#algo-flex-tracks
 */
const expandFlexibleTracks = (
  trackRanges: TrackSizeRange[],
  containerSize: number | undefined
): TrackSizeRange[] => {
  const flexFraction = calculateFlexFraction(trackRanges, containerSize)

  return trackRanges.map(track => {
    const { sizeFn } = track
    if (!(sizeFn instanceof FlexLength)) {
      return track
    }

    const { factor } = sizeFn
    const flexProduct = factor * flexFraction

    return {
      ...track,
      baseSize: flexProduct,
    }
  })
}

const stretchAutoTracks = (
  trackRanges: TrackSizeRange[],
  containerSize: number | undefined
): TrackSizeRange[] => {
  const autoTrackIndices: number[] = []
  trackRanges.forEach(({ sizeFn }, index) => {
    if (sizeFn === TRACK_SIZE_AUTO) {
      autoTrackIndices.push(index)
    }
  })
  if (autoTrackIndices.length === 0) {
    return trackRanges
  }

  const freeSpace = calculateFreeSpace(trackRanges, containerSize)
  if (freeSpace <= 0 || freeSpace === Infinity) {
    return trackRanges
  }

  // Divide free space equally across all auto columns
  const quota = freeSpace / autoTrackIndices.length

  const expandedTracks = trackRanges.map(trackSize => {
    if (trackSize.sizeFn !== TRACK_SIZE_AUTO) {
      return trackSize
    }

    return {
      ...trackSize,
      baseSize: trackSize.baseSize + quota,
    }
  })

  return expandedTracks
}

const resolveTrackSizes = (
  trackRanges: TrackSizeRange[],
  contributions: number[],
  containerSize: number | undefined
): number[] => {
  // Resolve intrinsic track sizes
  let updatedRanges = resolveIntrinsicTrackSizes(trackRanges, contributions)

  // Expand flexible tracks
  updatedRanges = expandFlexibleTracks(updatedRanges, containerSize)

  // Stretch 'auto' tracks
  updatedRanges = stretchAutoTracks(updatedRanges, containerSize)

  return createSizedTracks(updatedRanges)
}

const trackAvailableSpace = (
  sizeFn: TrackSizeFunction,
  containerSize: number | undefined
): number => {
  if (sizeFn instanceof Length) {
    return sizeFn.toExact(containerSize)
  }

  if (sizeFn === TRACK_SIZE_AUTO || sizeFn instanceof FlexLength) {
    return Infinity
  }

  throw new Error(`Unknown TrackSizeFunction type: ${typeof sizeFn}`)
}

const contributionsEqual = (a: number[], b: number[]): boolean => {
  if (a.length !== b.length) {
    throw new Error(`Track contribution lists differ in size`)
  }

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) {
      return false
    }
  }

  return true
}

const createSizedGrid = (
  items: GridItem[],
  sizedRows: number[],
  sizedColumns: number[]
): SizedGrid => ({
  items,
  rows: sizedRows.map(exactSize => ({ exactSize })),
  columns: sizedColumns.map(exactSize => ({ exactSize })),
})

const calculateTrackSizes = (
  container: ObjectNode<ObjectType>,
  items: GridItem[],
  numRows: number,
  numColumns: number,
  context: LayoutContext
): SizedGrid => {
  const { device } = context
  let { containerHeight: gridHeight, containerWidth: gridWidth } = context

  const grid = initializeUnsizedGrid(
    container,
    items,
    numRows,
    numColumns,
    device
  )

  // Calculate column sizes
  let rowSpaceAvailable = grid.rows.map(sizeFn =>
    trackAvailableSpace(sizeFn, gridHeight)
  )
  const columns = initTrackSizeRanges(grid.columns, gridWidth)
  let columnContextMap = generateColumnContextMap(
    rowSpaceAvailable,
    columns,
    context
  )
  const columnContributions = resolveColumnContributions(
    columns,
    grid.items,
    columnContextMap
  )
  let sizedColumns = resolveTrackSizes(columns, columnContributions, gridWidth)

  // Calculate row sizes
  let columnSpaceAvailable = sizedColumns
  const rows = initTrackSizeRanges(grid.rows, gridHeight)
  let rowContextMap = generateRowContextMap(rows, columnSpaceAvailable, context)
  const rowContributions = resolveRowContributions(
    rows,
    grid.items,
    rowContextMap
  )
  let sizedRows = resolveTrackSizes(rows, rowContributions, gridHeight)

  // Update the container height based on the new track information.
  if (gridHeight === undefined) {
    gridHeight = sizedRows.reduce((sum, rowSize) => sum + rowSize, 0)
  }

  // If item contributions changed from row-size calculation, re-resolve size of grid columns (at most once)
  rowSpaceAvailable = sizedRows
  columnContextMap = generateColumnContextMap(
    rowSpaceAvailable,
    columns,
    context
  )
  const secondColumnContributions = resolveColumnContributions(
    columns,
    grid.items,
    columnContextMap
  )
  if (contributionsEqual(columnContributions, secondColumnContributions)) {
    return createSizedGrid(grid.items, sizedRows, sizedColumns)
  }
  sizedColumns = resolveTrackSizes(
    columns,
    secondColumnContributions,
    gridWidth
  )

  // If item contributions change from preceding column-size calculation, re-resolve size of grid rows (at most once)
  columnSpaceAvailable = sizedColumns
  rowContextMap = generateRowContextMap(rows, columnSpaceAvailable, context)
  const secondRowContributions = resolveRowContributions(
    rows,
    grid.items,
    rowContextMap
  )
  if (contributionsEqual(columnContributions, secondColumnContributions)) {
    return createSizedGrid(grid.items, sizedRows, sizedColumns)
  }
  sizedRows = resolveTrackSizes(rows, secondRowContributions, gridHeight)

  return createSizedGrid(grid.items, sizedRows, sizedColumns)
}

export default calculateTrackSizes
