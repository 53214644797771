import { useState, useEffect, useCallback, useRef } from 'react'

// via: https://blog.logrocket.com/create-custom-debounce-hook-react/
export const useDebouncedValue = <T>(value: T, milliSeconds: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, milliSeconds)

    return () => {
      clearTimeout(handler)
    }
  }, [value, milliSeconds])

  return debouncedValue
}

// via: https://gist.github.com/loonywizard/c2813222ff41b7fd1479793414dc4c01#file-react-use-throttle-hook-ts
export function useThrottledValue<T>(value: T, interval = 500): T {
  const [throttledValue, setThrottledValue] = useState<T>(value)
  const lastExecuted = useRef<number>(Date.now())

  useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now()
      setThrottledValue(value)
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now()
        setThrottledValue(value)
      }, interval)

      return () => clearTimeout(timerId)
    }
  }, [value, interval])

  return throttledValue
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericCallback = (...args: any[]) => void

//via: https://medium.com/@chirag.dave/how-to-debounce-throttle-a-callback-with-react-hooks-c9ee017062df
export function useThrottledCallback(
  callback: GenericCallback,
  interval = 200
): GenericCallback {
  const lastCallRef = useRef(0)

  const throttledCallback = useCallback(
    (...args) => {
      const now = Date.now()
      if (now - lastCallRef.current >= interval) {
        lastCallRef.current = now
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        callback(...args)
      }
    },
    [callback, interval]
  )

  return throttledCallback
}
