import styled from 'styled-components'
import type { DefaultCellProps } from './types'

const Text = styled('span')`
  word-break: break-word;
`

const TextCell = ({ field }: DefaultCellProps): JSX.Element => {
  return <Text>{field.label}</Text>
}

export default TextCell
