import { getId, indexOf } from '@adalo/utils'

import { setParams } from '../../utils/io'

const CREATE_PARAM = Symbol('CREATE_PARAM')
const UPDATE_PARAM = Symbol('UPDATE_PARAM')
const DELETE_PARAM = Symbol('DELETE_PARAM')

const EMPTY_ARRAY = []

// Reducer

export default (state, action) => {
  if (
    action.type === CREATE_PARAM ||
    action.type === UPDATE_PARAM ||
    action.type === DELETE_PARAM
  ) {
    let { appId, componentId, paramId, param } = action

    const app = { ...state.apps[appId] }
    const component = { ...app.components[componentId] }
    const params = (component.params || []).slice()
    const index = indexOf(params, ({ id }) => id === paramId)

    if (action.type === CREATE_PARAM) {
      param = { ...param, id: getId() }
      params.push(param)
    } else if (action.type === UPDATE_PARAM) {
      params[index] = param
    } else if (action.type === DELETE_PARAM) {
      params.splice(index, 1)
    }

    // Save to backend
    setParams({ appId, componentId, params })

    component.params = params
    app.components[componentId] = component

    return {
      ...state,
      apps: {
        ...state.apps,
        [appId]: app,
      },
    }
  }
}

// Actions

export const createParam = (appId, componentId, param) => ({
  type: CREATE_PARAM,
  appId,
  componentId,
  param,
})

export const updateParam = (appId, componentId, paramId, param) => ({
  type: UPDATE_PARAM,
  appId,
  componentId,
  paramId,
  param,
})

export const deleteParam = (appId, componentId, paramId) => ({
  type: DELETE_PARAM,
  appId,
  componentId,
  paramId,
})

// Selectors

export const getParams = (state, appId, componentId) => {
  const app = state.apps.apps[appId] || {}
  const component = (app.components || {})[componentId] || {}
  const params = component.params || EMPTY_ARRAY

  return params
}

export const getParam = (state, appId, componentId, paramId) => {
  const params = getParams(state, appId, componentId)

  return params.filter(p => p.id === paramId)[0]
}
