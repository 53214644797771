import React, { useCallback, useContext, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import {
  getDatasource,
  getDatasourceRelationships,
  updateDatasource,
} from 'ducks/apps/datasources'
import { closeAccordion } from 'ducks/accordions'
import {
  collectionPolicy,
  fieldPolicy,
  policyChange,
  applyPolicyChanges,
} from 'utils/permissions'

import Modal from 'components/Shared/Modal'
import TabBar from 'components/Shared/TabBar'

import ButtonBar from './ButtonBar'
import CollectionsList from './CollectionsList'
import UserPropertiesList from './UserPropertiesList'
import ModalHeader from './ModalHeader'

import './CollectionPermissionsModal.scss'
import DatasourceContext from './context/DatasourceContext'
import RelationshipsContext from './context/RelationshipsContext'

const BETA_URL =
  'https://help.adalo.com/database/user-collection-permissions#beta'

const INFO_URL = 'https://help.adalo.com/database/user-collection-permissions'

const ACCORDION_GROUP_USER_PROPERTIES = 'user-properties'
const ACCORDION_GROUP_COLLECTIONS = 'other-collections'

const TAB_USERS = 'users'
const TAB_OTHER_COLLECTIONS = 'other-collections'

const editorUrl = appId => `/apps/${appId}/data`

export function CollectionPermissionsModal({
  enableSave,
  onUserPropertyPolicyChange,
  onCollectionPolicyChange,
  onSaveClick,
  onCancelClick,
  onBackClick,
  initialActiveTab,
  sourceTableId,
}) {
  const [activeTab, setActiveTab] = useState(initialActiveTab)

  const tabs = [
    { value: TAB_USERS, label: 'Users' },
    { value: TAB_OTHER_COLLECTIONS, label: 'All Other Collections' },
  ]

  const currentPage = useMemo(() => {
    switch (activeTab) {
      case TAB_USERS: {
        return <UsersTab onPolicyChange={onUserPropertyPolicyChange} />
      }

      case TAB_OTHER_COLLECTIONS: {
        return (
          <CollectionsList
            collectionAccordionGroup={ACCORDION_GROUP_COLLECTIONS}
            sourceTableId={sourceTableId}
            onChange={onCollectionPolicyChange}
          />
        )
      }

      default:
        // This tab doesn't exist
        return null
    }
  }, [activeTab, onUserPropertyPolicyChange, onCollectionPolicyChange])

  return (
    <Modal size="lg" onClose={onCancelClick}>
      <div className="collection-permissions-modal">
        <ModalHeader
          title="Collection Permissions"
          betaUrl={BETA_URL}
          infoUrl={INFO_URL}
          onBackClick={onBackClick}
        />
        <Modal.Content>
          <div className="collection-permissions-modal__content">
            <TabBar
              tabs={tabs}
              activeTab={activeTab}
              TabLabel={PermissionsPageTabLabel}
              onChange={setActiveTab}
            />
            <div className="collection-permissions-modal__current-tab-content">
              {currentPage}
            </div>
          </div>
        </Modal.Content>
        <ButtonBar
          enableSave={enableSave}
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
        />
      </div>
    </Modal>
  )
}

function PermissionsPageTabLabel({ children }) {
  return <p className="permissions-page-tab-label">{children}</p>
}

function UsersTab({ onPolicyChange }) {
  const datasource = useContext(DatasourceContext)
  const { auth, tables } = datasource

  const usersTableId = auth.table
  const usersTable = tables[usersTableId]

  const policies = usersTable.policies || []

  return (
    <UserPropertiesList
      table={usersTable}
      policies={policies}
      isUsersTable
      unsavedFields={[]} // TODO(toby): Remove this prop after we've removed the Phase 1 security modal.
      accordionGroup={ACCORDION_GROUP_USER_PROPERTIES}
      onPolicyChange={onPolicyChange}
    />
  )
}

function Container({
  datasource,
  relationships,
  match,
  location,
  history,
  updateDatasource,
  closeAccordion,
}) {
  const { appId, datasourceId } = match.params
  const returnUrl = location.state?.returnUrl
  const sourceTableId = location.state?.sourceTableId
  const authTableId = datasource?.auth?.table

  const initialActiveTab =
    sourceTableId === authTableId || !sourceTableId
      ? TAB_USERS
      : TAB_OTHER_COLLECTIONS

  const [unsavedChanges, setUnsavedChanges] = useState([])

  const unsavedDatasource = useMemo(() => {
    return datasource && applyPolicyChanges(datasource, unsavedChanges)
  }, [datasource, unsavedChanges])

  const closeModal = useCallback(() => {
    if (returnUrl) {
      history.push(returnUrl)
    } else {
      history.push(editorUrl(appId))
    }

    closeAccordion(ACCORDION_GROUP_USER_PROPERTIES)
    closeAccordion(ACCORDION_GROUP_COLLECTIONS)
  }, [appId, returnUrl, history, closeAccordion])

  const saveChanges = useCallback(() => {
    // Save the updated datasource
    updateDatasource(appId, datasourceId, unsavedDatasource)

    // Clear unsaved changes
    setUnsavedChanges([])
  }, [updateDatasource, appId, datasourceId, unsavedDatasource])

  const handleUserPropertyPolicyChanged = useCallback(
    (fieldId, action, allowed) => {
      const policy = fieldPolicy(fieldId, action, allowed)
      const change = policyChange(authTableId, policy)

      // Append this change to the list of unsaved changes
      setUnsavedChanges(unsavedChanges => [...unsavedChanges, change])
    },
    [authTableId, unsavedChanges]
  )

  const handleCollectionPolicyChanged = useCallback(
    (tableId, action, allowed, paths) => {
      const policy = collectionPolicy(action, allowed, paths)
      const change = policyChange(tableId, policy)

      // Append this change to the list of unsaved changes
      setUnsavedChanges(unsavedChanges => [...unsavedChanges, change])
    },
    [unsavedChanges]
  )

  const handleSaveClicked = useCallback(() => {
    saveChanges()
    closeModal()
  }, [saveChanges, closeModal])

  const handleCancelClicked = useCallback(() => {
    closeModal()
  }, [closeModal])

  // It's possible to render this modal before datasource is loaded when the route is hit directly.
  if (!datasource) {
    return null
  }

  return (
    <DatasourceContext.Provider value={unsavedDatasource}>
      <RelationshipsContext.Provider value={relationships}>
        <CollectionPermissionsModal
          enableSave={unsavedChanges.length > 0}
          onUserPropertyPolicyChange={handleUserPropertyPolicyChanged}
          onCollectionPolicyChange={handleCollectionPolicyChanged}
          onSaveClick={handleSaveClicked}
          onCancelClick={handleCancelClicked}
          onBackClick={returnUrl && handleCancelClicked}
          initialActiveTab={initialActiveTab}
          sourceTableId={sourceTableId}
        />
      </RelationshipsContext.Provider>
    </DatasourceContext.Provider>
  )
}

const mapStateToProps = (state, { match }) => {
  const { appId, datasourceId } = match.params

  return {
    datasource: getDatasource(state, appId, datasourceId),
    relationships: getDatasourceRelationships(state, appId, datasourceId),
  }
}

const mapDispatchToProps = {
  updateDatasource,
  closeAccordion,
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
