import React, { Component } from 'react'

import { adaloBackendAxios } from 'utils/io/http/axios'
import Tooltip from 'components/Shared/Tooltip'
import { formatTick } from './CumulativeSignupsChart'

import '../Analytics.css'

class Bar extends Component {
  render() {
    const { value, max } = this.props
    const barWidth = 65 * (value / max)
    const remainingWidth = 65 - barWidth

    return (
      <svg width="65" height="12">
        <rect
          width={barWidth}
          height="12"
          style={{ fill: 'rgb(168, 32, 88)', stroke: 'rgb(168, 32, 88)' }}
        />
        <rect
          width={remainingWidth}
          height="12"
          x={barWidth}
          style={{ fill: 'rgb(218, 218, 218)', stroke: 'rgb(218, 218, 218)' }}
        />
      </svg>
    )
  }
}

class VisitedItem extends Component {
  render() {
    const { data, max, width } = this.props
    let screenName = data.screen
    const cutoff = width < 800 ? 18 : 22

    if (data.screen.length > cutoff) {
      screenName = `${screenName.substring(0, cutoff - 1)}...`
    }

    return (
      <div className="visited-item">
        {data.screen.length > cutoff ? (
          <Tooltip
            tooltip={
              <div>
                <span>{data.screen}</span>
              </div>
            }
            placement="top-start"
            hideArrow
          >
            <h2>{screenName}</h2>
          </Tooltip>
        ) : (
          <h2 className="visited-item-name">{screenName}</h2>
        )}

        <div className="visited-item-value">
          <h2 className="visited-item-number">{formatTick(+data.visits, 1)}</h2>
          <Bar value={+data.visits} max={max} />
        </div>
      </div>
    )
  }
}

export default class MostVisited extends Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    await this.getData()
  }

  componentDidUpdate(prevProps) {
    const { start, range } = this.props

    if (start !== prevProps.start || range !== prevProps.range) {
      this.getData()
    }
  }

  getData = async () => {
    let { appId, start, end } = this.props
    const queryType = 'visited'

    start = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    end = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`

    let data = await adaloBackendAxios.post(`/analytics`, {
      appId,
      queryType,
      start,
      end,
    })

    if (data.data.success) {
      data = data.data.data
    } else {
      data = []
    }

    this.setState({ loading: false, data })
  }

  render() {
    const { data, loading } = this.state
    const { width } = this.props
    let max = 1

    if (data && data.length !== 0) {
      max = +data[0].visits
    }

    return (
      <div>
        <div className="module-header-container">
          <h2 className="most-visited-header">Most Visited Screens</h2>
        </div>
        <div
          className="app-analytics-module-container app-analytics-visited-container"
          style={{ width: width / 2 - 40 }}
        >
          <div className="visited-header-container">
            <h2 className="screen-name-text grey-header">Screen Name</h2>
            <h2 className="times-visited-text grey-header">Times Visited</h2>
          </div>
          {loading ? null : (
            <div className="visited-content">
              {data.length === 0 ? (
                <div className="module-empty-state">
                  <div className="module-empty-state-content">
                    <h2>No Screen Data</h2>
                    <span>
                      There isn't any user data for the selected time frame.
                    </span>
                  </div>
                </div>
              ) : (
                data.map(d => <VisitedItem data={d} max={max} width={width} />)
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
