import { requestAppBuilds, requestStartAppBuild } from 'utils/io'
import { getBuildLogs } from 'utils/builds'
import { endWebPublish, getApp } from '.'

const LOAD_LIST = Symbol('APP_BUILDS_LOAD_LIST')
const SET_LOADING = Symbol('SET_LOADING_APP_BUILDS')
const ADD_BUILD = Symbol('ADD_APP_BUILD')
const GET_BUILD_ERROR_LOG = 'GET_BUILD_ERROR_LOG'

function findLastVersion(builds) {
  const versions = builds.map(build => build.version)

  versions.sort((a, b) => {
    const a1 = a.split('.')
    const b1 = b.split('.')
    const len = Math.max(a1.length, b1.length)

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      const _a = +a1[i] || 0
      const _b = +b1[i] || 0

      if (_a === _b) {
        continue
      } else {
        return _a > _b ? 1 : -1
      }
    }

    return 0
  })

  return versions[versions.length - 1]
}

export default function appBuildsReducer(state, action) {
  switch (action.type) {
    case LOAD_LIST: {
      const { appId, target, list } = action.payload
      const app = state.apps[appId]

      return {
        ...state,
        apps: {
          ...state.apps,
          [appId]: {
            ...app,
            builds: {
              ...(app && app.builds),
              [target]: {
                ...(app && app.builds && app.builds[target]),
                lastVersion: findLastVersion(list),
                list,
                loading: false,
                loaded: true,
              },
            },
          },
        },
      }
    }
    case ADD_BUILD: {
      const { appId, target, build } = action.payload
      const app = state.apps[appId]

      if (app && build) {
        return {
          ...state,
          apps: {
            ...state.apps,
            [appId]: {
              ...app,
              builds: {
                ...(app && app.builds),
                [target]: {
                  ...(app && app.builds && app.builds[target]),
                  lastVersion: build.version,
                  list: [
                    ...((app &&
                      app.builds &&
                      app.builds[target] &&
                      app.builds[target].list) ||
                      []),
                    build,
                  ],
                },
              },
            },
          },
        }
      }

      break
    }
    case SET_LOADING: {
      const { appId, target, loading, loaded } = action.payload
      const app = state.apps[appId]

      return {
        ...state,
        apps: {
          ...state.apps,
          [appId]: {
            ...app,
            builds: {
              ...(app && app.builds),
              [target]: {
                ...(app && app.builds && app.builds[target]),
                loading,
                loaded,
              },
            },
          },
        },
      }
    }
    case `${GET_BUILD_ERROR_LOG}_PENDING`: {
      return {
        ...state,
        log: {
          loading: true,
        },
      }
    }
    case `${GET_BUILD_ERROR_LOG}_FULFILLED`: {
      return {
        ...state,
        log: {
          loading: false,
          text: action.payload,
        },
      }
    }
    case `${GET_BUILD_ERROR_LOG}_REJECTED`: {
      return {
        ...state,
        log: {
          loading: false,
          text: 'Temporarily unable to fetch the error logs for this build. Please try again later.',
        },
      }
    }
  }
}

// ACTIONS

export const loadScreenAppBuilds = (appId, target, list) => ({
  type: LOAD_LIST,
  payload: { appId, target, list },
})

export const fetchBuildErrorLog = (appId, buildId) => ({
  type: GET_BUILD_ERROR_LOG,
  payload: getBuildLogs(appId, buildId),
  meta: { appId },
})

// THUNKS

export const ensureAppBuildsAreLoaded = (appId, target) => {
  return (dispatch, getState) => {
    const state = getAppBuildsState(getState(), appId, target)

    if (state && (state.loading || state.loaded)) {
      return
    }

    dispatch({
      type: SET_LOADING,
      payload: { appId, target, loading: true, loaded: false },
    })

    requestAppBuilds(appId, target, { order: 'DESC' })
  }
}

export const startAppBuild = (appId, target, version, skipBuildEnqueue) => {
  return async dispatch => {
    const build = await requestStartAppBuild(
      appId,
      target,
      version,
      skipBuildEnqueue
    )

    dispatch({
      type: ADD_BUILD,
      payload: { appId, target, build },
    })

    dispatch(endWebPublish(appId))
  }
}

// SELECTORS

/**
 *
 * @typedef {'web' | 'ios' | 'android'} BuildTarget
 * @typedef {'queued' | 'building' | 'built' | 'failed'} BuildStatus
 *
 * @typedef {Object} AppBuild
 * @property {string} id
 * @property {BuildTarget} target
 * @property {string} version
 * @property {BuildStatus} status
 * @property {string} startedAt
 * @property { string | undefined} completedAt
 *
 * @typedef {Object} AppBuildsState
 * @property {boolean} loading
 * @property {boolean} loaded
 * @property {string | undefined} lastVersion
 * @property {string | undefined | null } DomainId
 * @property { Array.<AppBuild>| undefined} list
 */

/**
 * @param {object} state
 * @param {string} appId
 * @param {string} target
 * @returns {AppBuildsState | undefined}
 */
export const getAppBuildsState = (state, appId, target) => {
  const app = getApp(state, appId)

  return app?.builds?.[target]
}

export const getAppBuilds = (state, appId, target) => {
  const buildState = getAppBuildsState(state, appId, target)

  return buildState && buildState.list
}

export const getAppBuild = (state, appId, target = null, buildId) => {
  let platforms = ['ios', 'android']

  if (target) {
    platforms = [target]
  }

  let builds = []

  for (const platform of platforms) {
    const buildState = getAppBuildsState(state, appId, platform)
    const platformBuilds = buildState?.list

    if (platformBuilds) {
      builds = builds.concat(platformBuilds)
    }
  }

  return builds?.filter(itm => itm.id === buildId)[0]
}

export const getBuildErrorLog = state => state?.apps?.log
