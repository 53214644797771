import { adaloBackendAxios } from 'utils/io/http/axios'

const SET_EXTERNAL_APIKEY = 'SET_EXTERNAL_APIKEY'
const GET_EXTERNAL_APIKEY = 'GET_EXTERNAL_APIKEY'
const UPDATE_EXTERNAL_APIKEY = 'UPDATE_EXTERNAL_APIKEY'
const UPDATE_MANY_EXTERNAL_APIKEY = 'UPDATE_MANY_EXTERNAL_APIKEY'
const DELETE_EXTERNAL_APIKEY = 'DELETE_EXTERNAL_APIKEY'
const DELETE_MANY_EXTERNAL_APIKEY = 'DELETE_MANY_EXTERNAL_APIKEY'
const INVALIDATE_EXTERNAL_APIKEY = 'INVALIDATE_EXTERNAL_APIKEY'

const INITIAL_STATE = {}

const wrapRequest = async promise => {
  try {
    return await promise
  } catch (err) {
    if (err.response?.data?.message) {
      throw {
        error: err.response.data.message,
        isNetworkError: true,
        config: { url: err.config?.url },
      }
    }

    throw err
  }
}

// Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${SET_EXTERNAL_APIKEY}_FULFILLED`:
    case `${UPDATE_EXTERNAL_APIKEY}_FULFILLED`: {
      const { appId } = action.meta

      const apiKey = {
        ...action.payload.data,
        isValid: true,
      }

      if (state.thirdPartyApiKeys && state.thirdPartyApiKeys[appId]) {
        return {
          ...state,
          [appId]: [...state.thirdPartyApiKeys[appId], apiKey],
        }
      }

      return {
        ...state,
        [appId]: Array.isArray(state[appId])
          ? [...state[appId], apiKey]
          : [apiKey],
      }
    }
    case `${GET_EXTERNAL_APIKEY}_FULFILLED`: {
      const { appId } = action.meta

      const apiKeys = action.payload.data.map(key => ({
        ...key,
        isValid: true,
      }))

      return {
        ...state,
        [appId]: apiKeys,
      }
    }
    case `${DELETE_EXTERNAL_APIKEY}_FULFILLED`: {
      const { appId, keyId: deletedKeyId } = action.meta

      const newThirdPartyApiKey = state[appId].filter(
        ({ id }) => id !== deletedKeyId
      )

      return {
        ...state,
        [appId]: newThirdPartyApiKey,
      }
    }
    case INVALIDATE_EXTERNAL_APIKEY: {
      const { appId, type: invalidApiKeyType } = action.meta

      const invalidApiKey = state[appId].find(
        ({ type }) => type === invalidApiKeyType
      )

      const validApiKeys = state[appId].filter(
        ({ type }) => type !== invalidApiKeyType
      )

      return {
        ...state,
        [appId]: [
          ...validApiKeys,
          {
            ...invalidApiKey,
            isValid: false,
          },
        ],
      }
    }
    // no need to do anything when fulfilled because we load the other API keys when the app is loaded
    case `${UPDATE_MANY_EXTERNAL_APIKEY}_FULFILLED`:
    case `${DELETE_MANY_EXTERNAL_APIKEY}_FULFILLED`:
    default:
      return state
  }
}

// Actions
export const createThirdPartyApiKey = (appId, { type, key }) => ({
  type: SET_EXTERNAL_APIKEY,
  payload: wrapRequest(
    adaloBackendAxios.post(`/apps/${appId}/api-keys`, { type, key })
  ),
  meta: { appId },
})

export const fetchThirdPartyApiKeys = appId => ({
  type: GET_EXTERNAL_APIKEY,
  payload: wrapRequest(adaloBackendAxios.get(`/apps/${appId}/api-keys`)),
  meta: { appId },
})

export const deleteThirdPartyApiKey = (appId, keyId) => ({
  type: DELETE_EXTERNAL_APIKEY,
  payload: wrapRequest(
    adaloBackendAxios.delete(`/apps/${appId}/api-keys/${keyId}`)
  ),
  meta: { appId, keyId },
})

export const deleteManyThirdPartyApiKeys = (appId, appIds) => ({
  type: DELETE_MANY_EXTERNAL_APIKEY,
  payload: wrapRequest(
    adaloBackendAxios.delete(`/apps/${appId}/api-keys`, {
      data: { appIds },
    })
  ),
})

export const updateThirdPartyApiKey = (appId, keyId, key) => ({
  type: UPDATE_EXTERNAL_APIKEY,
  payload: wrapRequest(
    adaloBackendAxios.put(`/apps/${appId}/api-keys/${keyId}`, { key })
  ),
  meta: { appId },
})

export const updateManyThirdPartyApiKeys = (
  originAppId,
  key,
  targetAppIds
) => ({
  type: UPDATE_MANY_EXTERNAL_APIKEY,
  payload: wrapRequest(
    adaloBackendAxios.patch(`${originAppId}/api-keys`, {
      key,
      appIds: targetAppIds,
    })
  ),
})

export const invalidateThirdPartyApiKey = (appId, type) => ({
  type: INVALIDATE_EXTERNAL_APIKEY,
  meta: { appId, type },
})

// Selectors
export const getThirdPartyApiKeys = (state, appId) =>
  state.thirdPartyApiKeys[appId]

export const getThirdPartyApiKeyFor = (state, appId, type) =>
  getThirdPartyApiKeys(state, appId)?.find(key => key.type === type)
