import { createContext } from 'react'
import { ANIMATION_TIMING } from './layout'

// helpers for rendering within builder
const TableRenderHelperContext = createContext<{
  zoomScale: number
  renderEmptyState: boolean
  currentFocussedField: string | null
  animationTiming: number
}>({
  zoomScale: 1,
  renderEmptyState: false,
  currentFocussedField: null,
  animationTiming: ANIMATION_TIMING,
})

export default TableRenderHelperContext
