import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconButton } from 'components/Shared/Icon'
import Button from 'components/Shared/Button'

import {
  ensureAccessTokensAreLoaded,
  generateAccessToken,
  revokeAccessToken,
  getAccessToken,
} from 'ducks/marketplace/accessTokens'
import { getCurrentUser } from 'ducks/users/index.ts'

import { getAuthToken } from 'utils/auth'

export default function AccessToken() {
  const sessionToken = getAuthToken()
  const user = useSelector(state => getCurrentUser(state))
  const accessToken = useSelector(state => getAccessToken(state, user?.id))
  const [hideAccessToken, setHideAccessToken] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ensureAccessTokensAreLoaded(user?.id, sessionToken))
  }, [user?.id])

  const handleGenerateAccessToken = async () => {
    if (accessToken) {
      const confirm = window.confirm(
        `Are you sure you want to regenerate the access token?`
      )

      if (!confirm) {
        return
      }
    }

    await dispatch(generateAccessToken(user?.id, sessionToken))
    setHideAccessToken(false)
  }

  const handleRevokeAccessToken = async () => {
    const confirm = window.confirm(
      `Are you sure you want to delete the access token?`
    )

    if (!confirm) {
      return
    }

    await dispatch(revokeAccessToken(user?.id, sessionToken))
    setHideAccessToken(true)
  }

  return (
    <>
      <div className="developer-libraries-access-token">
        <div className="developer-libraries-access-token header">
          <h2 className="developer-libraries-access-token header title">
            Access Token
          </h2>
        </div>
      </div>
      {accessToken ? (
        <>
          <div className="developer-libraries-access-token-text">
            <div className="developer-libraries-access-token-text-number">
              <form autoComplete="off">
                <input
                  className="developer-libraries-access-token-text-number"
                  name="devAccessToken"
                  id="devAccessTokenInput"
                  value={hideAccessToken ? '••••••••••••••••' : accessToken}
                  onChange={() => null}
                  type="text"
                  spellCheck="false"
                />
              </form>
            </div>
            <div className="developer-libraries-access-token-text-icon">
              <span onClick={() => setHideAccessToken(!hideAccessToken)}>
                <IconButton type="eye" />
              </span>
            </div>
          </div>
          <div className="developer-libraries-access-token-buttons">
            <div className="developer-libraries-access-token-buttons">
              <Button
                text
                small
                className="developer-libraries-access-token-buttons delete"
                onClick={handleRevokeAccessToken}
              >
                Delete Access Token
              </Button>
            </div>
            <div className="developer-libraries-access-token-buttons">
              <Button
                text
                small
                className="developer-libraries-access-token-buttons regenerate"
                onClick={handleGenerateAccessToken}
              >
                Regenerate Access Token
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <p>
              This token can be used to allow other developers to share private
              libraries with you.
            </p>
          </div>
          <div>
            <Button
              onClick={handleGenerateAccessToken}
              className="developer-libraries-access-token-generate-key"
            >
              Generate token
            </Button>
          </div>
        </>
      )}
    </>
  )
}
