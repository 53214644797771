import React from 'react'
import { connect } from 'react-redux'
import { sourceTypes } from '@adalo/constants'
import { noop } from 'lodash/noop'

import { selectObject } from 'ducks/editor/objects'

import Form from './BindingForm'

function getBinding(binding) {
  let { source } = binding

  let options =
    source.type === sourceTypes.COUNT
      ? source.source && source.source.options
      : source.options

  const filter = options && options.filter

  if (filter && !Array.isArray(filter)) {
    options = {
      ...options,
      filter: [filter],
    }

    if (source.type === sourceTypes.COUNT) {
      source = {
        ...source,
        source: {
          ...source.source,
          options,
        },
      }
    } else {
      source = {
        ...source,
        options,
      }
    }

    binding = {
      ...binding,
      source,
    }
  }

  return binding
}

function Binding({
  appId,
  componentId,
  label,
  binding,
  bindingOptions,
  objectId,
  object,
  bindingTypes,
  form,
  onChange,
  displayName,
  hideAdvanced,
  tooltip,
}) {
  const mappedBinding = getBinding(binding)

  return (
    <div className="data-binding">
      <Form
        form={form || `dataBinding-${objectId}`}
        bindingOptions={bindingOptions}
        onChange={onChange}
        onChangeManually={onChange}
        onSubmit={noop}
        initialValues={mappedBinding}
        binding={mappedBinding}
        label={label}
        object={object}
        appId={appId}
        componentId={componentId}
        bindingTypes={bindingTypes}
        displayName={displayName}
        hideAdvanced={hideAdvanced}
        tooltip={tooltip}
      />
    </div>
  )
}

const mapStateToProps = (state, { objectId }) => ({
  object: selectObject(state, objectId),
})

export default connect(mapStateToProps)(Binding)
