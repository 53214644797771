import React, { Component } from 'react'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { sort } from '@adalo/utils'

import Link from './Link'
import Icon from '../../Shared/Icon'
import CreateTable from './CreateTable'

class DatasourceItem extends Component {
  getIcon(collectionId) {
    const { datasource } = this.props

    if (datasource.type === 'api') {
      return 'json'
    }

    const table = datasource.tables[collectionId]

    return table.name === 'Users' ? 'users' : 'table'
  }

  getCollectionName(collectionId) {
    const { datasource } = this.props

    const key = datasource.type === 'apto-backend' ? 'tables' : 'collections'

    return (datasource[key][collectionId].name || '').trim() || 'Untitled'
  }

  getTableIds() {
    const { datasource } = this.props

    const collections =
      datasource.type === 'apto-backend'
        ? datasource.tables
        : datasource.collections

    return sort(Object.keys(collections), id => {
      return collections[id].name || 'untitled'
    })
  }

  getTitle() {
    const { datasource } = this.props

    if (datasource.type === 'api') {
      return 'New API'
    }

    return 'Tables'
  }

  redirectIfNecessary() {
    const { match, datasource, datasourceId, history } = this.props
    const { appId } = match.params

    const routeMatch = window.location.pathname.match(
      /^\/apps\/[\w\-]+\/data\/([\w\-_]+)(\/([\w\-_]+))?/
    )

    const datasourceIdParam = routeMatch && routeMatch[1]
    const tableIdParam = routeMatch && routeMatch[3]

    const redirectTable = this.getTableIds()[0]

    if (datasourceIdParam && (datasource.type === 'api' || tableIdParam)) {
      return
    }

    if (datasource.type === 'api') {
      history.replace(`/apps/${appId}/data/${datasourceId}`)
    } else if (redirectTable) {
      history.replace(`/apps/${appId}/data/${datasourceId}/${redirectTable}`)
    }
  }

  componentDidMount() {
    this.redirectIfNecessary()
  }

  componentDidUpdate() {
    this.redirectIfNecessary()
  }

  renderTitle() {
    const { datasource, appId, datasourceId } = this.props

    const title = this.getTitle()

    if (datasource.type === 'api') {
      return (
        <RouterLink
          to={`/apps/${appId}/data/${datasourceId}`}
          className="datasource-link-header"
        >
          <Icon type="expand-vertical" /> {title}
        </RouterLink>
      )
    }

    return (
      <h2 className="datasource-link-header">
        <Icon type="expand-vertical" /> {title}
      </h2>
    )
  }

  render() {
    const { datasource, appId } = this.props
    const keys = this.getTableIds()

    return (
      <li className="data-datasource-item">
        {this.renderTitle()}
        <ul>
          {keys.map(id => (
            <li key={id}>
              <Link
                onClick={this.handleClickLink}
                to={`/apps/${appId}/data/${datasource.id}/${id}`}
                icon={this.getIcon(id)}
              >
                {this.getCollectionName(id)}
              </Link>
            </li>
          ))}
          <li>
            <CreateTable appId={appId} datasourceId={datasource.id} />
          </li>
        </ul>
      </li>
    )
  }
}

export default withRouter(DatasourceItem)
