import React from 'react'
import Lottie from 'react-lottie'
import animationData from './party.json'

const SuccessfulTrial = () => {
  const options = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie height={210} width={210} options={options} />
}

export default SuccessfulTrial
