import { updateApp } from '../../utils/io'

const CHANGE_COLUMN_WIDTH = Symbol('CHANGE_COLUMN_WIDTH')

export default (state, action) => {
  if (action.type === CHANGE_COLUMN_WIDTH) {
    const { appId, datasourceId, tableId, fieldId, width, skipSave } = action
    const app = state.apps[appId] || {}
    const prev = app.tableLayout || {}

    const tableLayout = {
      ...app.tableLayout,
      [datasourceId]: {
        ...prev[datasourceId],
        [tableId]: {
          ...(prev[datasourceId] && prev[datasourceId][tableId]),
          [fieldId]: width,
        },
      },
    }

    if (!skipSave) {
      updateApp(appId, { tableLayout })
    }

    return {
      ...state,
      apps: {
        [appId]: {
          ...state.apps[appId],
          tableLayout,
        },
      },
    }
  }
}

export const resizeColumn = (opts, skipSave = false) => ({
  ...opts,
  skipSave,
  type: CHANGE_COLUMN_WIDTH,
})

export const getTableLayout = (state, appId, datasourceId, tableId) => {
  const app = state.apps.apps[appId] || {}
  const tableLayout = app.tableLayout || {}
  const datasource = tableLayout[datasourceId] || {}

  return datasource[tableId] || {}
}
