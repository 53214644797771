import { adaloBackendAxios } from 'utils/io/http/axios'

const REQUEST_DOMAINS = 'REQUEST_DOMAINS'
const CREATE_DOMAIN = 'CREATE_DOMAIN'
const DELETE_DOMAIN = 'DELETE_DOMAIN'

const INITIAL_STATE = {}

// Reducer

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${REQUEST_DOMAINS}_FULFILLED`) {
    const { organizationId } = action.meta
    const { data } = action.payload

    return { ...state, [organizationId]: data }
  }

  if (action.type === `${CREATE_DOMAIN}_FULFILLED`) {
    const { organizationId } = action.meta
    const { data } = action.payload
    let domains = state[organizationId] || []
    domains = domains.concat([data])

    return { ...state, [organizationId]: domains }
  }

  if (action.type === `${DELETE_DOMAIN}_FULFILLED`) {
    const { organizationId, domainId } = action.meta
    let domains = state[organizationId] || []
    domains = domains.filter(itm => itm.id !== domainId)

    return { ...state, [organizationId]: domains }
  }

  return state
}

// Actions

export const requestDomains = organizationId => ({
  type: REQUEST_DOMAINS,
  payload: adaloBackendAxios.get(`/organizations/${organizationId}/domains`),
  meta: { organizationId },
})

export const createDomain = (organizationId, domain) => ({
  type: CREATE_DOMAIN,
  payload: adaloBackendAxios.post(`/organizations/${organizationId}/domains`, {
    domain,
  }),
  meta: { organizationId },
})

export const deleteDomain = (organizationId, domainId) => ({
  type: DELETE_DOMAIN,
  payload: adaloBackendAxios.delete(
    `/organizations/${organizationId}/domains/${domainId}`
  ),
  meta: { organizationId, domainId },
})

// Selectors

/**
 * @typedef {import('./Domains').Domain} Domain
 */
/**
 * @param {object} state
 * @param {string | undefined} organizationId
 * @returns {Domain[] | undefined}
 */

export const getDomains = (state, organizationId) => {
  return state.domains[organizationId]
}
