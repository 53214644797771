import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, getFormValues, change } from 'redux-form'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { isEmpty as _isEmpty } from 'lodash'

import { getTypeOptions } from 'utils/custom-actions'
import { getIcon } from 'utils/icons'

import { openAccordion } from 'ducks/accordions'
import ControlField from 'components/Shared/Forms/ControlField'
import SimpleTextControl from 'components/Editor/Inspect/Libraries/SimpleTextControl'
import Icon, { IconButton } from 'components/Shared/Icon'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import { GroupedAccordion } from 'components/Shared/Accordion'
import Button from 'components/Shared/Button'
import EmptyState from 'components/Shared/EmptyState'
import './AuthFields.scss'

import { FORM_NAME } from './Form'

const MENU = [
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' },
]

const ACCORDION_NAME = 'custom-action-output-fields'

export const OutputItemTitle = props => {
  const {
    input: { value },
    onRemove,
  } = props

  const { type, name, sampleValue } = value || {}
  const iconType = getIcon(type)

  return (
    <>
      <Icon type={iconType} />
      <div className="data-accordion-title-text">
        <p>{name || ' '}</p>
        <p className="data-accordion-subtitle">
          {sampleValue || sampleValue === 0 ? sampleValue : 'Empty'}
        </p>
      </div>
      <IconButton type="trash" onClick={onRemove} />
    </>
  )
}

export const OutputItemBody = ({ field }) => {
  return (
    <div className="form-inspect-field-wrapper">
      <ControlField
        hideBinding
        name={`${field}.type`}
        component={MenuControl}
        displayName="Type"
        placeholder="Select a Type"
        options={getTypeOptions(MENU)}
      />
      <ControlField
        hideBinding
        name={`${field}.name`}
        component={SimpleTextControl}
        displayName="Name"
        placeholder="give it a friendly name..."
      />
      <ControlField
        hideBinding
        name={`${field}.key`}
        component={SimpleTextControl}
        displayName="Key"
        disabled
      />
    </div>
  )
}

export const OutputItem = ({ field, onRemove }) => (
  <div className="data-accordion-item">
    <GroupedAccordion
      hideCarret
      boxed
      group={ACCORDION_NAME}
      color="orange"
      itemId={field}
      title={
        <Field name={field} component={OutputItemTitle} onRemove={onRemove} />
      }
      renderChildren={() => <OutputItemBody field={field} />}
    />
  </div>
)

class OutputItems extends Component {
  handleAdd = option => {
    const {
      openAccordion,
      input: { value, onChange },
    } = this.props

    const { formattedKey, ...field } = option

    const updatedList = { ...value, [formattedKey]: field }

    onChange({ ...updatedList })

    openAccordion(ACCORDION_NAME, `outputs.${formattedKey}`)
  }

  handleRemove = index => {
    const {
      change,
      input: { value },
    } = this.props

    const { [index]: deletedField, ...updatedList } = value
    change(FORM_NAME, 'outputs', updatedList)
  }

  buildMenu = () => {
    const {
      values: { testResults, outputs },
    } = this.props

    const { availableOutputs } = testResults

    return Object.keys(availableOutputs)
      .filter(key => {
        return !outputs[key]
      })
      .map(key => ({
        label: availableOutputs[key].name,
        value: { ...availableOutputs[key], formattedKey: key },
      }))
  }

  render() {
    const {
      input: { value: outputs },
    } = this.props

    return (
      <div className="output-section-title">
        <label>
          Magic Text Output Properties - Learn how to use these in subsequent
          actions{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.adalo.com/integrations/custom-actions"
          >
            here!
          </a>
        </label>
        {Object.keys(outputs).map(output => (
          <OutputItem
            key={output}
            onRemove={() => this.handleRemove(output)}
            field={`outputs.${output}`}
          />
        ))}
        {_isEmpty(outputs) && (
          <EmptyState bordered>
            <p>No Outputs</p>
          </EmptyState>
        )}
        <MultiMenuTrigger
          menu={this.buildMenu}
          onSelect={this.handleAdd}
          width={600}
        >
          <Button orange listAddButton icon="plus" type="button">
            Add Item
          </Button>
        </MultiMenuTrigger>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const selector = getFormValues(FORM_NAME)

  return {
    values: selector(state),
  }
}

export default connect(mapStateToProps, { openAccordion, change })(OutputItems)
