import {
  LABEL,
  ALIGN_CENTER,
  ALIGN_RIGHT,
  AUTOSAVED_VERSION,
} from '@adalo/constants'

// utils
import { getFontFamily } from 'utils/type'

export const getHeight = (fontSize, lineCount) => {
  return lineCount * Math.ceil(1.15 * fontSize)
}

export const measureLabel = label => {
  const lines = getValueParagraphs(label.text)

  let maxWidth = 0
  const height = getHeight(label.fontSize, lines.length)

  for (const line of lines) {
    const width = measureFragment(label, line)

    if (width > maxWidth) {
      maxWidth = width
    }
  }

  maxWidth = Math.ceil(maxWidth / 2) * 2

  return { width: maxWidth, height }
}

// Join last / first paragraph as one
export const joinParagraphs = (paragraphs, newParagraphs) => {
  paragraphs = paragraphs.slice()

  const firstNew =
    typeof newParagraphs === 'string'
      ? newParagraphs
      : (newParagraphs && newParagraphs[0]) || ''

  const rest = typeof newParagraphs === 'string' ? [] : newParagraphs.slice(1)

  if (firstNew) {
    if (paragraphs.length === 0) {
      paragraphs.push(firstNew)
    } else {
      paragraphs[paragraphs.length - 1] = `${
        paragraphs[paragraphs.length - 1]
      }${firstNew}`
    }
  }

  return [...paragraphs, ...rest]
}

export const getValueParagraphs = value => {
  let paragraphs = []

  if (typeof value === 'object') {
    value = value.filter(v => v !== '')

    value.forEach(t => {
      if (typeof t === 'string') {
        const array = t.split(/\n/g)
        paragraphs = joinParagraphs(paragraphs, array)
      } else {
        paragraphs = joinParagraphs(paragraphs, t.label || 'lorem ipsum')
      }
    })
  } else {
    paragraphs = (value || '').split(/\n/g)
  }

  return paragraphs
}

export const getLines = label => {
  const lines = []

  if (label.multiline === false) {
    return [getAbbreviation(label)]
  }

  let labelText = label.text

  if (label.maxLength && label.maxLength > 0) {
    labelText = getCharLimitAbbreviation(label)
  }

  const paragraphs = getValueParagraphs(labelText)

  if (label.autoWidth) {
    return paragraphs
  }

  let currentLine = 0

  for (const paragraph of paragraphs) {
    const regex = /\s+/g
    let match
    let lastIndex = 0
    let done = false

    let newParagraph

    if (typeof paragraph === 'object') {
      newParagraph = paragraph.label || 'lorem ipsum'
    } else {
      newParagraph = paragraph
    }

    while (!done) {
      match = regex.exec(newParagraph)

      let word

      if (!match) {
        done = true
        word = newParagraph.substring(lastIndex)
      } else {
        word = newParagraph.substring(lastIndex, match.index)
        lastIndex = match.index
      }

      // If line is empty, always put word there.
      if (!lines[currentLine]) {
        lines[currentLine] = word
      } else {
        const combined = lines[currentLine] + word
        const combinedWidth = measureFragment(label, combined)

        if (combinedWidth > label.width) {
          currentLine += 1
          lines[currentLine] = word.trim()
        } else {
          lines[currentLine] += word
        }
      }
    }

    currentLine += 1
  }

  return lines
}

export const getAbbreviation = label => {
  let labelText = label.text

  if (label?.maxLength > 0) {
    labelText = getCharLimitAbbreviation(label)
  }

  const paragraphs = getValueParagraphs(labelText).map(paragraph => {
    if (typeof paragraph === 'object') {
      return paragraph.label || 'lorem ipsum'
    }

    return paragraph
  })

  const text = paragraphs.join(' ').replace(/[\s\n\r]+/g, ' ')

  if (label.autoWidth) {
    return text
  }

  if (measureFragment(label, text) <= label.width) {
    return text
  }

  for (let i = 1; i < text.length; i += 1) {
    const textFragment = `${text.substring(0, i)}...`

    if (measureFragment(label, textFragment) > label.width) {
      return `${text.substring(0, i - 1)}...`
    }
  }

  return '...'
}

export const getCharLimitAbbreviation = label => {
  let text = label.text

  let displayText = text

  if (typeof text === 'object') {
    const textFragments = []

    text = text.filter(v => v !== '')

    text.forEach(t => {
      if (typeof t === 'string') {
        textFragments.push(t)
      } else {
        textFragments.push(t.label || 'lorem ipsum')
      }
    })

    displayText = textFragments.join('')
  }

  if (displayText.length <= label.maxLength) {
    return displayText
  }

  displayText = `${displayText.substring(0, label.maxLength).trim()}...`

  return displayText
}

let SVG_NODE = null
let TEXT_NODE = null
let isMeasureNodeInitialized = false

export const measureFragment = (label, text) => {
  if (process.env.NODE_ENV === 'test') {
    return text.length * label.fontSize
  }

  if (!isMeasureNodeInitialized) {
    SVG_NODE = document.createElement('svg')
    SVG_NODE.style.zIndex = -20000
    SVG_NODE.style.position = 'fixed'
    SVG_NODE.style.left = 0
    SVG_NODE.style.top = 0
    SVG_NODE.style.opacity = 0
    SVG_NODE.style.pointerEvents = 'none'
    SVG_NODE.width = '1000px'
    SVG_NODE.height = '200px'
    TEXT_NODE = document.createElement('text')
    TEXT_NODE.x = 0
    TEXT_NODE.y = '200px'
    document.body.appendChild(SVG_NODE)
    SVG_NODE.appendChild(TEXT_NODE)
    isMeasureNodeInitialized = true
  }

  TEXT_NODE.style.fontFamily = getFontFamily(label)
  TEXT_NODE.style.fontSize = `${label.fontSize}px`
  TEXT_NODE.style.fontWeight = label.fontWeight || 400
  TEXT_NODE.innerHTML = text

  const rect = TEXT_NODE.getBoundingClientRect()

  return rect.width
}

export const updateObjectWidth = object => {
  if (object.type !== LABEL || object.inResponsiveApp) {
    return object
  }

  const layoutText = getLines(object)

  if (object.autoWidth) {
    const prevWidth = object.width
    const { width, height } = measureLabel(object)

    if (object.textAlignment === ALIGN_CENTER) {
      object.x -= (width - prevWidth) / 2
    } else if (object.textAlignment === ALIGN_RIGHT) {
      object.x -= width - prevWidth
    }

    return { ...object, width, height, layoutText }
  }

  const height = getHeight(object.fontSize, layoutText.length)

  return { ...object, layoutText, height }
}

export const formatUserName = (name, isUpdateModal = false) => {
  if (!name) return ''

  if (name === AUTOSAVED_VERSION.creator.name) {
    if (isUpdateModal) {
      return `${name} :)`
    }

    return name
  }

  const [firstName] = name.split(' ')

  return firstName
}

export const getWarningMessage = (type, params = {}) => {
  switch (type) {
    case 'deleteCollection':
      return params.paying
        ? `Are you sure you want to delete the collection "${params.name}"? This cannot be undone and will not be restored with a Design Version. Please type the collection name to confirm`
        : `Are you sure you want to delete the collection "${params.name}"? Please type the collection name to confirm`
    default:
      return 'Warning'
  }
}
