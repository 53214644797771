import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import {
  LIST,
  listTypes,
  bindingTypes,
  DeviceType as DeviceTypeValues,
} from '@adalo/constants'
import { capitalize as _capitalize } from 'lodash'

import {
  selectParent,
  getCurrentAppId,
  runInstructions,
} from 'ducks/editor/objects'
import {
  updateCustomListDeviceColumns,
  enableDeviceSpecificCustomListColumns,
  disableDeviceSpecificCustomListColumns,
  updateElement,
  moveElement,
  resizeElement,
} from 'ducks/editor/instructions'
import {
  resetSelection,
  setLayersHover,
  setSelection,
} from 'ducks/editor/selection'
import { getApp, getAppLibraries } from 'ducks/apps'
import {
  COLUMN_BREAKPOINTS,
  DEVICE_COLUMN_SETTINGS,
} from 'ducks/editor/objects/helpers/getCustomListProperties'
import { getFeatureFlag } from 'ducks/featureFlags'

import { getObjectName } from 'utils/naming'
import { hasUnlicensedLibrary } from 'utils/libraries'
import { decreaseWidth, increaseWidth } from 'utils/objects/listControl'
import { getTypeNameForObject } from 'utils/objectNames'

import { GroupedAccordion } from 'components/Shared/Accordion'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import NewActionRow from '../Actions/Row'
import SingleNumberControl from './SingleNumberControl'
import GenericInspectRow from './GenericRow'
import CommonListControl from './CommonListControl'
import MenuControl from './Libraries/MenuControl'

class ListControlChild$ extends Component {
  static displayName = 'ListControlChild'
  static propTypes = {
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    setSelection: PropTypes.func.isRequired,
    setLayersHover: PropTypes.func.isRequired,
  }

  handleMouseEnter = () => {
    const { setLayersHover, object } = this.props
    setLayersHover(object)
  }

  handleMouseLeave = () => {
    const { setLayersHover } = this.props
    setLayersHover(null)
  }

  handleClick = () => {
    const { setSelection, object } = this.props
    setSelection(object.id, false)
  }

  render() {
    const { object, libraries } = this.props
    const unlicensed = hasUnlicensedLibrary(object, libraries)

    return (
      <div
        className={classnames('group-child-component', {
          'group-child-component-unlicensed': unlicensed,
        })}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div>
          <div className="group-child-component-type">
            {getTypeNameForObject(object)}
          </div>
          <div className="group-child-component-name">
            {getObjectName(object)}
          </div>
        </div>
        <i className="material-icons">arrow_forward</i>
      </div>
    )
  }
}

const mapStateToProps = (state, { componentId, object }) => {
  const appId = getCurrentAppId(state)
  const libraries = getAppLibraries(state, appId)
  const app = getApp(state, appId)
  const isResponsiveApp = app.primaryPlatform === 'responsive'
  const hasBreakpointSpecificCustomList = getFeatureFlag(
    state,
    'hasBreakpointSpecificCustomList'
  )

  const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')

  const mobileOnly =
    hasNewMobileOnlyApp && app.webSettings?.layoutMode === 'mobile'

  let children = object?.children || []

  if (hasNewMobileOnlyApp) {
    const app = getApp(state, appId)
    if (app?.webSettings?.layoutMode === 'mobile') {
      children = children.filter(
        child => child.deviceVisibility?.mobile !== false
      )
    }
  }

  return {
    libraries,
    app,
    isResponsiveApp,
    hasBreakpointSpecificCustomList,
    mobileOnly,
    children,
  }
}

export const ListControlChild = connect(mapStateToProps, {
  setLayersHover,
  setSelection,
})(ListControlChild$)

class ListControl$ extends Component {
  static displayName = 'ListControl'

  static propTypes = {
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf([LIST]),
    }).isRequired,
    appId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    dataSelectDisplayName: PropTypes.string,
  }

  handleAddComponent = () => {
    const { appId, selectParent, resetSelection, object, history } = this.props
    resetSelection()
    history.push(`/apps/${appId}/screens/add`)
    selectParent(object.id)
  }

  handleListChange = ({ dataBinding }, opts = {}) => {
    const { onChange } = this.props

    onChange({
      dataBinding: {
        ...dataBinding,
        bindingType: bindingTypes.LIST,
      },
    })
  }

  handleColumnCountChange = ({ columnCount }, device) => {
    const { onChange, object, isResponsiveApp, runInstructions } = this.props
    const { columnBreakpoints = COLUMN_BREAKPOINTS.SHARED } = object

    if (
      columnBreakpoints === COLUMN_BREAKPOINTS.CUSTOM &&
      isResponsiveApp &&
      device
    ) {
      const deviceColumns = object.deviceColumns?.[device]
      if (!deviceColumns) {
        throw new Error(`Unknown device: ${device}`)
      }

      return runInstructions([
        updateCustomListDeviceColumns(
          object.id,
          columnCount,
          deviceColumns.rowMargin,
          device
        ),
      ])
    }

    if (columnCount === object.columnCount) {
      return
    }

    const updatedProperties = {
      columnCount,
      listType:
        columnCount == null || columnCount <= 1
          ? listTypes.DEFAULT
          : listTypes.GRID,
    }

    if (isResponsiveApp) {
      const list = {
        columnCount: object.columnCount,
        rowMargin: object.rowMargin,
      }

      const modifier =
        columnCount > object.columnCount ? increaseWidth : decreaseWidth

      const deviceTypes = Object.values(DeviceTypeValues)

      for (const deviceType of deviceTypes) {
        if (
          object.shared?.[deviceType] ||
          !object[deviceType]?.width ||
          object.deviceColumns?.[deviceType]
        ) {
          continue
        }

        updatedProperties[deviceType] = {
          ...object[deviceType],
          width: modifier(object[deviceType], columnCount, list),
        }
      }

      updatedProperties.width = modifier(object, columnCount, list)
    }

    if (isResponsiveApp) {
      return runInstructions([
        updateElement(object.id, updatedProperties),
        resizeElement(object.id, updatedProperties.width, object.height),
        moveElement(object.id, object.x, object.y),
      ])
    }

    onChange({ ...updatedProperties })
  }

  handleMarginChange = ({ rowMargin }, device) => {
    const { onChange, object, isResponsiveApp, runInstructions } = this.props
    const { columnBreakpoints = COLUMN_BREAKPOINTS.SHARED } = object

    if (
      columnBreakpoints === COLUMN_BREAKPOINTS.CUSTOM &&
      isResponsiveApp &&
      device
    ) {
      const deviceColumns = object.deviceColumns?.[device]
      if (!deviceColumns) {
        throw new Error(`Unknown device: ${device}`)
      }

      return runInstructions([
        updateCustomListDeviceColumns(
          object.id,
          deviceColumns.columnCount,
          rowMargin,
          device
        ),
      ])
    }

    if (isResponsiveApp) {
      return runInstructions([
        updateElement(object.id, {
          rowMargin: rowMargin != null ? rowMargin : 0,
        }),
        resizeElement(object.id, object.width, object.height),
        moveElement(object.id, object.x, object.y),
      ])
    }

    onChange({
      rowMargin: rowMargin != null ? rowMargin : 0,
    })
  }

  renderListAccordion = () => {
    const {
      appId,
      componentId,
      object,
      isResponsiveApp,
      runInstructions,
      hasBreakpointSpecificCustomList,
      mobileOnly,
      hideColumnSpacing,
      hideActions,
      hideAdvancedOptions,
      otherControls,
      dataSelectDisplayName,
    } = this.props

    const { columnBreakpoints = COLUMN_BREAKPOINTS.SHARED } = object

    const deviceTypes = mobileOnly
      ? [DeviceTypeValues.MOBILE]
      : [
          DeviceTypeValues.DESKTOP,
          DeviceTypeValues.TABLET,
          DeviceTypeValues.MOBILE,
        ]

    const setDeviceTypeSpacingLabel = deviceType =>
      mobileOnly ? 'Item Spacing' : `${_capitalize(deviceType)} Spacing`

    const setDeviceTypeColumnsLabel = deviceType =>
      mobileOnly ? 'Columns' : `${_capitalize(deviceType)} Columns`

    return (
      <>
        <CommonListControl
          isCustomList
          objectId={object.id}
          value={object.dataBinding}
          name="dataBinding"
          onChange={this.handleListChange}
          displayName={dataSelectDisplayName ?? 'What is this a list of?'}
          hideAdvancedOptions={hideAdvancedOptions}
        />
        {otherControls}
        {/* Display Custom List drop-down menu if feature flag is on */}
        {!mobileOnly &&
          !hideColumnSpacing &&
          hasBreakpointSpecificCustomList &&
          isResponsiveApp && (
            <MenuControl
              name="columnBreakpoints"
              displayName="Columns & Item Spacing"
              options={[
                {
                  label: 'Shared By All Screen Sizes',
                  value: COLUMN_BREAKPOINTS.SHARED,
                },
                { label: 'Custom', value: COLUMN_BREAKPOINTS.CUSTOM },
              ]}
              value={columnBreakpoints}
              onChange={updated => {
                if (updated.columnBreakpoints === columnBreakpoints) {
                  return
                }

                if (updated.columnBreakpoints === COLUMN_BREAKPOINTS.SHARED) {
                  runInstructions([
                    disableDeviceSpecificCustomListColumns(object.id),
                  ])
                }

                if (updated.columnBreakpoints === COLUMN_BREAKPOINTS.CUSTOM) {
                  runInstructions([
                    enableDeviceSpecificCustomListColumns(object.id),
                  ])
                }
              }}
              rowHeight={40}
            />
          )}
        {columnBreakpoints === COLUMN_BREAKPOINTS.SHARED && !hideColumnSpacing && (
          <div className="border-control-split">
            <GenericInspectRow title="Columns">
              <SingleNumberControl
                gray
                name="columnCount"
                value={object.columnCount}
                minValue={1}
                maxValue={4}
                onChange={this.handleColumnCountChange}
              />
            </GenericInspectRow>
            <GenericInspectRow title="Items Spacing">
              <SingleNumberControl
                gray
                name="rowMargin"
                value={object.rowMargin}
                onChange={this.handleMarginChange}
              />
            </GenericInspectRow>
          </div>
        )}
        {/* Display Custom List device-specific column/rowMargin controls if feature flag is on */}
        {hasBreakpointSpecificCustomList &&
          columnBreakpoints === COLUMN_BREAKPOINTS.CUSTOM &&
          Object.values(deviceTypes).map((deviceType, i) => {
            const settings = DEVICE_COLUMN_SETTINGS[deviceType]
            if (!settings) {
              throw new Error(`Unknown device type: ${deviceType}`)
            }

            const deviceColumns = object.deviceColumns?.[deviceType]
            if (!deviceColumns) {
              throw new Error(`Unknown device: ${deviceType}`)
            }

            return (
              <div
                key={deviceType}
                className={classnames(
                  'border-control-split',
                  'custom-list-column',
                  {
                    [`custom-list-column-${deviceType}`]: deviceType,
                  }
                )}
              >
                <GenericInspectRow
                  title={setDeviceTypeColumnsLabel(deviceType)}
                >
                  <SingleNumberControl
                    gray
                    name="columnCount"
                    value={deviceColumns.columnCount}
                    minValue={settings.columns.min}
                    maxValue={settings.columns.max}
                    onChange={({ columnCount }) =>
                      this.handleColumnCountChange({ columnCount }, deviceType)
                    }
                  />
                </GenericInspectRow>
                <GenericInspectRow
                  title={setDeviceTypeSpacingLabel(deviceType)}
                >
                  <SingleNumberControl
                    gray
                    name="rowMargin"
                    value={deviceColumns.rowMargin}
                    onChange={({ rowMargin }) =>
                      this.handleMarginChange({ rowMargin }, deviceType)
                    }
                  />
                </GenericInspectRow>
              </div>
            )
          })}
        {!hideActions ? (
          <NewActionRow
            displayName="Click Actions"
            appId={appId}
            componentId={componentId}
            object={object}
          />
        ) : null}
      </>
    )
  }

  renderChildrenAccordion = () => {
    const { children } = this.props

    return (
      <>
        {children.map(child => (
          <ListControlChild key={child.id} object={child} />
        ))}
        <div className="list-control-add-wrapper">
          <Button
            square
            outlined
            block
            className="list-control-add"
            onClick={this.handleAddComponent}
          >
            <Icon type="plus-small" />
            <span> Add Component</span>
          </Button>
        </div>
      </>
    )
  }

  render() {
    const { object, listTitle, hideComponents, overrideGroupId, itemId } =
      this.props

    return (
      <>
        <GroupedAccordion
          defaultExpanded
          group={overrideGroupId ?? 'form-inspect-field'}
          className="library-inspect-accordion"
          title={listTitle ?? 'List'}
          object={object}
          itemId={itemId}
          renderChildren={this.renderListAccordion}
        />
        {!hideComponents ? (
          <GroupedAccordion
            group="form-inspect-field"
            className="library-inspect-accordion"
            title="Components"
            object={object}
            renderChildren={this.renderChildrenAccordion}
          />
        ) : null}
      </>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, {
    runInstructions,
    selectParent,
    resetSelection,
  })(ListControl$)
)
