import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { makeUniqueFieldId } from 'components/Editor/Inspect/Libraries/Table/utils'
import {
  EditorObject,
  ThemeShadow,
  type TableField,
} from 'utils/responsiveTypes'
import TableRenderHelperContext from './TableRenderHelperContext'
import TableThemeContext from './TableThemeContext'
import TableRow from './TableRow'
import TableRowHeader from './TableRowHeader'
import TableRowSeparator from './TableRowSeparator'
import TableCell from './TableCell'
import Pagination from './Pagination'
import FrozenColumnIndicator from './FrozenColumnIndicator'
import {
  MIN_HEADER_HEIGHT,
  MIN_PAGINATION_HEIGHT,
  MIN_ROW_HEIGHT,
  getSuitableElementHeight,
} from './layout'
import { getColumnStyles, getFieldsForTable, getTableSort } from './utils'

import EmptyState from './EmptyState'

// Hierarchy
// - Table (this component)
//   - TableHeader
//   - TableRow
//     - TableRowSeparator
//     - TableCell
//       - TextCell
//       - ImageCell
//       - LocationCell
//       - BooleanCell

const TableContainer = styled('div')<{
  $zoomScale: number
  $height: number
  $borderRadius: number
  $borderStyle: string
  $borderWidth: number
  $borderColor: string
  $backgroundColor: string
  $shadow: ThemeShadow
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: ${props => (1 / props.$zoomScale) * 100}%;
  height: ${props => props.$height}px;
  transform: translate3d(0, 0, 0) scale(${props => props.$zoomScale});
  transform-origin: left top;
  ${props =>
    props.$borderStyle !== 'none' &&
    `border: ${props.$borderWidth}px ${props.$borderStyle} ${props.$borderColor};`}
  border-radius: ${props => props.$borderRadius || 0}px;
  ${props =>
    props.$shadow.enabled &&
    `box-shadow: ${props.$shadow.color} ${props.$shadow.x}px ${props.$shadow.y}px ${props.$shadow.size}px;`}
  background-color: ${props => props.$backgroundColor};
`

const TableInnerContainer = styled('div')<{
  $tableContentsOffsetX: number
  $animationTiming: number
}>`
  margin-left: ${props => props.$tableContentsOffsetX}px;
  transition: margin-left ${props => props.$animationTiming}s ease;
`

type TableProps = {
  object: EditorObject
}

const Table = ({ object }: TableProps): JSX.Element => {
  const { width, height } = object
  const { fields, isMock: displayingMockFields } = getFieldsForTable(object)
  const sort = getTableSort(object)

  const { zoomScale, renderEmptyState, currentFocussedField, animationTiming } =
    useContext(TableRenderHelperContext)
  const {
    borderStyle,
    borderWidth,
    borderColor,
    borderRadius,
    backgroundColor,
    shadow,
  } = useContext(TableThemeContext)

  const headerRef = useRef<HTMLDivElement>(null)
  const rowRef = useRef<HTMLDivElement>(null)
  const firstCellRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)

  const [frozenColumnWidth, setFrozenColumnWidth] = useState<number | null>(null) // prettier-ignore

  const contentHeight = useMemo(() => {
    const headerHeight = getSuitableElementHeight(headerRef, MIN_HEADER_HEIGHT)
    const paginationHeight = getSuitableElementHeight(paginationRef, MIN_PAGINATION_HEIGHT) // prettier-ignore

    return height - headerHeight - paginationHeight - borderWidth * 2
  }, [width, height, borderWidth, headerRef.current, paginationRef.current])

  const previewRows = useMemo(() => {
    const rowHeight = getSuitableElementHeight(rowRef, MIN_ROW_HEIGHT)
    const rows = Math.floor(contentHeight / rowHeight)

    return Math.max(1, rows)
  }, [contentHeight, rowRef.current])

  useEffect(() => {
    const firstField =
      Array.isArray(fields) &&
      fields.length > 0 &&
      typeof fields[0] === 'object'
        ? fields[0]
        : null
    if (firstCellRef.current && firstField) {
      if (firstField.binding.format?.shouldFreezeFirstColumn === true) {
        setFrozenColumnWidth(firstCellRef.current.clientWidth)
      } else {
        setFrozenColumnWidth(null)
      }
    }
  }, [firstCellRef.current, fields, width])

  const tableContentsOffsetX = useMemo(() => {
    if (
      !Array.isArray(fields) ||
      fields.length === 0 ||
      currentFocussedField === null ||
      displayingMockFields === true
    ) {
      return 0
    }

    const offsets = new Map<string, number>()
    const widths = new Map<string, number>()

    let offset = 0
    for (const field of fields) {
      const fieldId = makeUniqueFieldId(field)

      const { minWidth: columnWidth } = getColumnStyles(field)

      offsets.set(fieldId, offset)
      widths.set(fieldId, columnWidth)

      offset += columnWidth
    }

    const columnOffset = 0 - (offsets.get(currentFocussedField) ?? 0) + (widths.get(currentFocussedField) ?? 0) // prettier-ignore
    const totalColumnWidth = Array.from(widths.values()).reduce((acc, w) => acc + w, 0) // prettier-ignore

    // Value must always be 0 or smaller
    return Math.min(Math.max(width - totalColumnWidth, columnOffset), 0)
  }, [width, fields, displayingMockFields, currentFocussedField])

  const tableContents = useMemo(() => {
    if (renderEmptyState) {
      return <EmptyState object={object} contentHeight={contentHeight} />
    }

    return (
      <>
        <TableRow ref={rowRef}>
          <>
            <TableRowSeparator position="bottom" />
            {fields.map((field: TableField, fieldIndex) => (
              <TableCell
                key={makeUniqueFieldId(field)}
                field={field}
                ref={fieldIndex === 0 ? firstCellRef : undefined}
              />
            ))}
          </>
        </TableRow>
        {[...Array(previewRows - 1).keys()].map(index => (
          <TableRow>
            <>
              {index < previewRows - 2 && (
                <TableRowSeparator position="bottom" />
              )}
              {fields.map((field: TableField) => (
                <TableCell key={makeUniqueFieldId(field)} field={field} />
              ))}
            </>
          </TableRow>
        ))}
      </>
    )
  }, [renderEmptyState, object])

  return (
    <TableContainer
      $zoomScale={zoomScale}
      $height={height}
      $borderRadius={borderRadius}
      $borderStyle={borderStyle}
      $borderWidth={borderWidth}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      $shadow={shadow}
    >
      <TableInnerContainer
        $animationTiming={animationTiming}
        $tableContentsOffsetX={tableContentsOffsetX}
      >
        <TableRowHeader fields={fields} sort={sort} ref={headerRef} />
        {tableContents}
      </TableInnerContainer>
      <Pagination ref={paginationRef} />
      <FrozenColumnIndicator
        tableContentsOffsetX={tableContentsOffsetX}
        width={frozenColumnWidth}
      />
    </TableContainer>
  )
}

export default memo(Table)
