import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFlags } from 'flags'
import { IMAGE, LABEL, LAYOUT_SECTION, SECTION } from '@adalo/constants'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'

import { getApp } from 'ducks/apps'
import { setTool } from 'ducks/editor/tools'
import { NEW_SCREEN_MODAL, showModal } from 'ducks/editor/modals'

import { getLibraryComponentOptions } from 'utils/libraries'
import { defaults } from 'utils/objects'

import './styles.scss'

const SHORTCUTS = {
  [LABEL]: {
    icon: 'text-format',
    title: 'Text',
    hotkey: 'T',
  },
  Button: {
    icon: 'button-shortcut',
    title: 'Button',
    hotkey: 'B',
  },
  [IMAGE]: {
    icon: 'image-shortcut',
    title: 'Image',
    hotkey: 'I',
  },
  SimpleList: {
    icon: 'list-shortcut',
    title: 'Simple List',
    hotkey: 'L',
  },
  [SECTION]: {
    icon: 'rectangle-shortcut',
    title: 'Rectangle',
    hotkey: 'R',
  },
  [LAYOUT_SECTION]: {
    icon: 'section-shortcut',
    title: 'Section',
    hotkey: 'S',
  },
  Screen: {
    icon: 'screen-shortcut',
    title: 'New Screen',
    hotkey: 'N',
  },
}

type ShortcutProps = {
  name: string
}

const Shortcut: React.FC<ShortcutProps> = ({ name }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()

  const { icon, title, hotkey } = SHORTCUTS[name as keyof typeof SHORTCUTS]

  const getObject = () => {
    if (['Button', 'SimpleList'].includes(name)) {
      const libraryName = '@protonapp/material-components'
      const { type, object } = getLibraryComponentOptions(libraryName, name)

      return { type, object }
    }

    return { type: name, object: defaults[name] }
  }

  const tooltip = (
    <div>
      <p className="shortcuts-menu__shortcut__tooltip__text">
        {title} <span>{hotkey}</span>
      </p>
    </div>
  )

  const handleClick = () => {
    const {
      params: { appId },
    } = match as unknown as { params: { appId: string } }

    history.push(`/apps/${appId}/screens`)

    if (name === 'Screen') {
      const promise = dispatch(
        showModal(NEW_SCREEN_MODAL, { zoom: true })
      ) as unknown as Promise<void>
      promise.catch(() => {
        /* modal closed */
      })

      return
    }

    const { type, object } = getObject()

    return dispatch(setTool(type, object ?? {}))
  }

  return (
    <button
      onClick={handleClick}
      type="button"
      className="shortcuts-menu__shortcut"
    >
      <Tooltip
        placement="bottom"
        tooltip={tooltip}
        small
        className="shortcuts-menu__shortcut__tooltip"
        triggerClassname="shortcuts-menu__shortcut__tooltip__trigger"
      >
        <Icon type={icon} />
      </Tooltip>
    </button>
  )
}

const ShortcutsMenu: React.FC = () => {
  const match = useRouteMatch()
  const { hasSectionComponent } = useFlags()

  const { appId } = match.params as { appId: string }

  const app = useSelector(state => getApp(state, appId))

  const isResponsiveApp = app?.primaryPlatform === 'responsive'

  const shortcuts = useMemo(
    () =>
      Object.keys(SHORTCUTS).map(key => {
        if (key === LAYOUT_SECTION) {
          if (!hasSectionComponent || !isResponsiveApp) return null
        }

        return <Shortcut key={key} name={key} />
      }),
    [SHORTCUTS, hasSectionComponent, isResponsiveApp]
  )

  return <div className="shortcuts-menu">{shortcuts}</div>
}

export default ShortcutsMenu
