import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getDatasource,
  updateDatasource,
} from '../../../../ducks/apps/datasources'

import Form from './Form'
import './APISetup.css'

class APISetup extends Component {
  handleChange = values => {
    const { match, updateDatasource } = this.props
    const { appId, datasourceId } = match.params

    updateDatasource(appId, datasourceId, values)
  }

  render() {
    const { datasourceId, datasource } = this.props

    if (!datasource) {
      return null
    }

    return (
      <div className="api-collection">
        <h1>New API</h1>
        <Form
          datasourceId={datasourceId}
          datasource={datasource}
          initialValues={datasource}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId, datasourceId } = match.params

  return {
    datasourceId,
    datasource: getDatasource(state, appId, datasourceId),
  }
}

export default connect(mapStateToProps, { updateDatasource })(APISetup)
