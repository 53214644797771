import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useFlags } from 'flags'
import { PricingPlans } from '@adalo/constants'
import { getPlanName, isLegacyPlanType } from 'utils/billing'
import { getPricingPlans } from 'ducks/billing'
import { getCurrentOrganization } from 'ducks/organizations'

import EmptyState from 'components/Shared/EmptyState'
import Button from 'components/Shared/Button'
import TrialWarning from 'components/Shared/TrialWarning'

import imgEmptyState from '../Canvas/Empty/empty-state.png'
import './Blocked.scss'

const useBlockedContent = () => {
  const history = useHistory()
  const params = useParams<{ appId: string }>()

  const { hasLegacyPlanBuilderBlocking } = useFlags()
  const organization = useSelector(state => getCurrentOrganization(state))
  const planType = organization?.planType ?? 'free'
  const isLegacyPlan = isLegacyPlanType(planType)

  const pricingPlans = useSelector(getPricingPlans) as PricingPlans
  const planName = getPlanName(planType, pricingPlans)

  const showBlockedForLegacy = hasLegacyPlanBuilderBlocking && isLegacyPlan

  const appId = params?.appId

  const handleOpenBilling = () => {
    if (appId) {
      const path = `/apps/${appId}/screens/settings/billing`

      return history.push(path)
    }
  }
  const organizationId = organization?.id
  const organizationName = organization?.name

  return {
    loading: !organization,
    planName,
    showBlockedForLegacy,
    organizationName,
    organizationId,
    appId,
    handleOpenBilling,
  }
}

const Blocked = (): JSX.Element | null => {
  const {
    loading,
    planName,
    showBlockedForLegacy,
    organizationName,
    organizationId,
    appId,
    handleOpenBilling,
  } = useBlockedContent()

  if (loading || !showBlockedForLegacy) {
    if (!showBlockedForLegacy) {
      console.warn(`Attempted to show blocking screen on current plan.`)
    }

    return null
  }

  const title = `Select a New Plan to Continue on ${organizationName}'s Account`

  return (
    <>
      <div className="blocked-wrapper">
        <EmptyState greedy className="blocked-content">
          <img
            src={imgEmptyState}
            alt="browser and mobile screen"
            height={220}
          />
          <h1>{title}</h1>
          <p>
            Your legacy {planName ?? ''} plan is no longer available.{' '}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={handleOpenBilling}>View your usage information</a> and
            select a new subscription to access your app & utilize our latest
            features, like publishing controls!
          </p>

          {/* TrialWarning implements all the logic for showing the PlanSelectModal with all the steps */}
          <TrialWarning
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            organizationId={organizationId}
            appId={appId}
            render={({ handleShowModal }: never) => (
              <Button
                data-adalo-id="blocked-builder-select-new-plan-button"
                teal
                onClick={handleShowModal}
              >
                SELECT NEW PLAN
              </Button>
            )}
          />
        </EmptyState>
      </div>
    </>
  )
}

export default Blocked
