import React, { Component } from 'react'
import GroupWrapper from './GroupWrapper'

export default class Group extends Component {
  render() {
    const { children, object, editorResizingProps } = this.props

    const { opacity } = object

    const styles = {
      opacity,
    }

    return (
      <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
        <g className="group" style={styles}>
          {children}
        </g>
      </GroupWrapper>
    )
  }
}
