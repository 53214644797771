import React from 'react'
import { inputTypes } from '@adalo/constants'

import { fillString } from 'utils/obfuscation'
import { scaleValue } from 'utils/zoom'
import { isFirefox } from 'utils/browsers'
import { getFontFamily } from 'utils/type'

import BindingValue from '../../../Shared/BindingValue'
import BaseShape from '../BaseShape'
import Shadow from '../Shadow'
import Rect from '../Section/Rect'
import '../../../Shared/MaterialIcons/MaterialIcons.css'

export default class Input extends BaseShape {
  renderExtras() {
    return null
  }

  render(propOverrides = {}) {
    const props = { ...this.props, ...propOverrides }

    let {
      id,
      branding,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      borderRadius,
      placeholder,
      placeholderColor,
      defaultValue,
      color,
      padding,
      fontSize,
      fontFamily,
      shadow,
      hideShadows,
      inputType,
      icon,
      zoom,
      multiline,
      x,
      y,
      width,
      height,
      isEnabled = true,
      left,
      right,
      minWidth,
      maxWidth,
      ...styles
    } = props

    let paddingLeft = scaleValue(padding, zoom)

    const paddingTop = multiline
      ? paddingLeft
      : (heightScaled - scaleValue(1.2 * fontSize, zoom)) / 2

    const borderProps = this.getBorderProps()
    const backgroundColor = this.getBackgroundColor()

    let text = defaultValue
    let iconFontSize = null
    let iconPaddingLeft = null
    let iconPaddingTop = null

    if (icon) {
      icon = icon.replace(/\-/g, '_')
      iconFontSize = Math.ceil(fontSize * 1.5)
      iconPaddingTop = Math.ceil((paddingTop * 2) / 3)

      iconPaddingLeft = paddingLeft
      iconFontSize = scaleValue(iconFontSize, zoom)
      paddingLeft += paddingLeft + iconFontSize
    }

    if (text && inputType === inputTypes.PASSWORD) {
      text = fillString('•', text.length || 8)
    }

    fontSize = scaleValue(fontSize, zoom)

    fontFamily = getFontFamily(fontFamily, branding)

    if (!text) {
      text = placeholder
    }

    if (placeholderColor) {
      color = this.getColor(placeholderColor)
    } else {
      color = this.getColor(color)
    }

    const clipPathId = `input-clip-${id}`
    const disabledColor = '#E52222'

    const defaultRectProps = {
      width,
      height,
      zoom,
      widthScaled,
      heightScaled,
    }

    const borderRadiusProps = {
      borderRadius,
    }

    return (
      <g
        className="input"
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        transform={`translate(${xScaled}, ${yScaled})`}
        style={styles}
      >
        <defs>
          <clipPath id={clipPathId}>
            <Rect {...defaultRectProps} {...borderProps} x={0} y={0} />
          </clipPath>
        </defs>
        {shadow && shadow.enabled && !hideShadows ? (
          <Shadow {...this.getShadowProps()}>
            <Rect
              {...defaultRectProps}
              {...borderProps}
              {...borderRadiusProps}
              x={0}
              y={0}
              fill={backgroundColor}
            />
          </Shadow>
        ) : null}
        <Rect
          {...defaultRectProps}
          {...borderProps}
          {...borderRadiusProps}
          x={0}
          y={0}
          fill={isEnabled ? backgroundColor : disabledColor}
          fillOpacity={isEnabled ? 1 : 0.4}
        />
        {icon ? (
          <foreignObject
            x={iconPaddingLeft}
            y={iconPaddingTop}
            height={heightScaled - iconPaddingTop}
            width={heightScaled}
          >
            <i
              className="material-icons"
              style={{ color, fontSize: iconFontSize, display: 'block' }}
            >
              {icon}
            </i>
          </foreignObject>
        ) : null}
        <text
          x={paddingLeft}
          y={paddingTop}
          fill={color}
          fontSize={fontSize}
          clipPath={`url(#${clipPathId})`}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            x={paddingLeft}
            y={paddingTop + (isFirefox() ? fontSize : 0)}
            style={{ fontFamily }}
          >
            <BindingValue binding={text} objectId={id} />
          </tspan>
        </text>
        {this.renderExtras()}
      </g>
    )
  }
}
