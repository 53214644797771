import React from 'react'
import PropTypes from 'prop-types'
import { actionTypes } from '@adalo/constants'

import { capitalize } from 'utils/strings'

import BindableTextControl from 'components/Shared/BindableTextControl'
import ControlField from 'components/Shared/Forms/ControlField'

const AuthErrorMessage = ({
  action,
  field,
  objectId,
  reference,
  dummyAction,
}) => {
  let label
  let name = `${field}.options.errorMessage`
  let placeholder

  if (dummyAction) {
    objectId = dummyAction?.objectId
    reference = dummyAction?.reference
    name = 'errorMessage'

    switch (reference) {
      case 'signup':
        label = 'Duplicate Email Error'
        placeholder = 'User email already exists'

        break
      case 'login':
        label = 'Login Error Message'
        placeholder = 'Invalid Email or Password'

        break
    }
  } else if (action) {
    const { actionType, options } = action
    const { AUTHENTICATE, SIGNUP } = actionTypes

    switch (actionType) {
      case AUTHENTICATE: {
        const { authType } = options
        label = 'Login Error Message'
        placeholder = `Invalid ${capitalize(authType)} or Password`

        break
      }
      case SIGNUP:
        label = 'Duplicate Email Error'
        placeholder = 'Email already exists.'

        break
    }
  }

  return (
    <ControlField
      component={BindableTextControl}
      name={name}
      displayName={label}
      placeholder={placeholder}
      objectId={objectId}
      hideBinding
    />
  )
}

AuthErrorMessage.propTypes = {
  action: PropTypes.object,
  dummyAction: PropTypes.object,
  field: PropTypes.string,
  objectId: PropTypes.string,
}

export default AuthErrorMessage
