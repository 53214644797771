import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'

import { DEFAULT_ZOOM, getTransform } from 'utils/zoom'

import { defaultBranding } from 'utils/colors'
import { getApp, getAppBranding } from 'ducks/apps'
import { getCurrentAppId } from 'ducks/editor/objects'
import { setSnapGridParent } from 'ducks/editor/selection'
import Icon from 'components/Shared/Icon'
import ScreenHeader from './ScreenHeader'

const baseHairline = 0.5
const baseOffset = 4
const baseSize = 8

class PageShadow extends Component {
  state = {
    expanded: false,
  }

  renderIcon() {
    const { isLaunchComponent, isAuthComponent, component } = this.props
    if (component.reusableComponent) {
      return (
        <span className="page-title-icons">
          <Icon type="component-small" />
        </span>
      )
    }

    return (
      <span className="page-title-icons">
        {isLaunchComponent ? <Icon type="home-small" /> : null}
        {isAuthComponent ? <Icon type="user-login-small" /> : null}
      </span>
    )
  }

  setExpanded = value => this.setState({ expanded: value })

  onMouseEnter = () => {
    const { setSnapGridParent, component } = this.props
    setSnapGridParent(component.id)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  renderScreenHeader() {
    const {
      component,
      zoomScale,
      zoomActive,
      onEnterTitle,
      onLeaveTitle,
      onClickTitle,
      isLaunchComponent,
      isAuthComponent,
      app,
      deviceType,
    } = this.props
    const { expanded } = this.state

    const titleDataAdaloId = () => {
      if (isLaunchComponent && isAuthComponent) {
        return 'builder-canvas-app__home-welcome'
      }

      if (isLaunchComponent) {
        return 'builder-canvas-app__home'
      }

      if (isAuthComponent) {
        return 'builder-canvas-app__welcome'
      }

      return ''
    }
    const { width } = component

    return (
      <ScreenHeader
        component={component}
        zoomScale={zoomScale}
        zoomActive={zoomActive}
        onEnterTitle={onEnterTitle}
        onLeaveTitle={onLeaveTitle}
        onClickTitle={onClickTitle}
        app={app}
        deviceType={deviceType}
        expanded={expanded}
        widthScaled={width}
        setExpanded={this.setExpanded}
      >
        <div
          className={classNames('page-title', {
            'component-page-title': component.reusableComponent,
          })}
          onMouseEnter={onEnterTitle}
          onMouseLeave={onLeaveTitle}
          onMouseDown={onClickTitle}
          data-adalo-id={titleDataAdaloId()}
        >
          <span className="page-title-text">{component.name}</span>
          {this.renderIcon()}
        </div>
      </ScreenHeader>
    )
  }

  render() {
    const { component, yOffset, transparent, zoomScale } = this.props

    const { expanded } = this.state

    let { x, y, width, height } = component

    y = y + yOffset
    height = height - yOffset

    const fakeZoom = DEFAULT_ZOOM

    const shadowOpacity = transparent ? 0.1 : 1
    const loFiZoomScale = Math.round(zoomScale * 10) / 10
    const hairline = baseHairline / loFiZoomScale
    const offset = baseOffset / loFiZoomScale
    const size = baseSize / loFiZoomScale
    const shadowStyles = {
      backgroundColor: 'white',
      transform: getTransform(fakeZoom, false, x, y),
      width,
      height,
      boxShadow: `0 ${offset}px ${size}px rgba(0, 0, 0, ${0.2 * shadowOpacity}),
                  0 0 0 ${hairline}px rgba(0, 0, 0, ${0.2 * shadowOpacity})`,
      transition: 'box-shadow 0.1s',
    }

    const titleStyles = {
      transform: getTransform(fakeZoom, false, x, y),
      x,
      y,
      width,
      height,
    }
    if (expanded) {
      titleStyles.zIndex = 3
    }

    return (
      <React.Fragment>
        <div
          className="page-shadow"
          style={shadowStyles}
          // Used to get which screen is being hovered on SnapGrid
          data-component-id={component.id}
        />
        <div
          key={component.id}
          className={classNames('page-title-wrapper', {
            'page-title-wrapper-transparent': transparent,
          })}
          style={titleStyles}
        >
          {this.renderScreenHeader()}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  branding: getAppBranding(state, getCurrentAppId(state)) || defaultBranding,
  app: getApp(state, getCurrentAppId(state)),
})

export default connect(mapStateToProps, { setSnapGridParent })(PageShadow)
