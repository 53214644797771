import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
//import { validateVersionGreaterThan } from 'utils/versions'

const requiredFactory = fieldName => value =>
  value ? undefined : `${fieldName} is required`

const validateVersion = version => {
  if (!version.match(/^[\.0-9]+$/)) {
    return 'Version must contain only numbers and dots (.)'
  }

  if (!version.match(/^[0-9]+(\.[0-9]+)*$/)) {
    return 'Invalid Version'
  }
}

const validateVersioniOS = version => {
  if (version && !version.match(/^[0-9]{1,3}(\.[0-9]{1,3}){0,2}$/)) {
    return 'Version must only have 1-3 numbers, each separated by periods.'
  }
}

const validations = {
  version: [requiredFactory('Version'), validateVersion],
}

const targetNames = {
  ios: 'iOS',
  android: 'Android',
}

const appStoreNames = {
  ios: 'App Store',
  android: 'Google Play store',
}

function NewVersionForm({ formId, target, lastVersion, handleSubmit }) {
  const targetName = targetNames[target]
  const appStoreName = appStoreNames[target]

  const validators = useMemo(() => {
    if (lastVersion) {
      return [
        target === 'ios' ? validateVersioniOS : () => {},
        ...validations.version,
        //version => validateVersionGreaterThan(version, lastVersion),
      ]
    } else {
      return validations.version
    }
  }, [lastVersion])

  return (
    <form className="publish-settings-form" id={formId} onSubmit={handleSubmit}>
      <Field
        component={SettingsFormField}
        name="version"
        label="Version Number"
        type="text"
        validate={validators}
      />
      {lastVersion ? (
        <p>
          The last Adalo {targetName} build was {lastVersion}. To upload a new
          version to the {appStoreName}, you will need to choose a higher number
          than the previously upload version number.
        </p>
      ) : null}
    </form>
  )
}

NewVersionForm.propTypes = {
  formId: PropTypes.string.isRequired,
  lastVersion: PropTypes.string,
  target: PropTypes.oneOf(['android', 'ios']).isRequired,
}

export default reduxForm({
  form: 'NewBuildVersion',
})(NewVersionForm)
