import { LAYOUT_SECTION } from '@adalo/constants'
import { reorderSection } from 'ducks/editor/instructions'
import { runInstructions } from 'ducks/editor/objects'
import DeviceType from 'ducks/editor/types/DeviceType'
import { useDispatch } from 'react-redux'
import getDeviceObject from 'utils/getDeviceObject'
import { EditorObject } from 'utils/responsiveTypes'
import { ReactComponent as DownArrow } from 'components/Shared/Icon/icons/down-arrow.svg'
import { ReactComponent as UpArrow } from 'components/Shared/Icon/icons/up-arrow.svg'
import { ReactComponent as GrabHandle } from 'components/Shared/Icon/icons/grab-handle.svg'
import { setSelection } from 'ducks/editor/selection'
import { beginDrag, endDrag } from 'ducks/editor/positioning'
import { useState } from 'react'
import classNames from 'classnames'

const SCALE_THRESHOLD_NO_REORDER = 0.08
const SCALE_THRESHOLD_SMALL_REORDER = 0.4

type Props = {
  object: EditorObject
  component?: EditorObject
  deviceType?: DeviceType
  widthScaled: number
  heightScaled: number
  light?: boolean
  scale: number
}

export const ReorderButtons = ({
  object,
  component,
  deviceType,
  widthScaled,
  heightScaled,
  light,
  scale,
}: Props): JSX.Element => {
  let hideUpButton = true
  let hideDownButton = true
  const [upArrowColor, setUpArrowColor] = useState('teal')
  const [downArrowColor, setDownArrowColor] = useState('teal')
  const [grabIconColor, setGrabIconColor] = useState(
    light ? '#00a996' : 'white'
  )
  const dispatch = useDispatch()

  if (scale < SCALE_THRESHOLD_NO_REORDER) {
    return <></>
  }

  const small = scale < SCALE_THRESHOLD_SMALL_REORDER

  if (component?.children) {
    const sectionsOnScreen = component.children
      .filter(({ type }) => type === LAYOUT_SECTION)
      .map(section => getDeviceObject(section, deviceType))
      .sort((a, b) => a.y - b.y)

    const index = sectionsOnScreen.findIndex(({ id }) => id === object.id)

    if (index !== 0) {
      hideUpButton = false
    }

    if (index !== sectionsOnScreen.length - 1) {
      hideDownButton = false
    }
  }

  const handleDragStart = (e: React.MouseEvent<Element, MouseEvent>): void => {
    dispatch(setSelection(object.id, e.shiftKey))
    dispatch(beginDrag([e.clientX, e.clientY]))
  }

  const handleDragEnd = (e: React.MouseEvent<Element, MouseEvent>): void => {
    dispatch(
      endDrag({
        mouseX: e.clientX,
        mouseY: e.clientY,
      })
    )
  }

  const handleUpClick = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(runInstructions([reorderSection(object.id, 'up')]))
    setUpArrowColor('teal')
  }

  const handleDownClick = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(runInstructions([reorderSection(object.id, 'down')]))
    setDownArrowColor('teal')
  }

  return (
    <g
      style={{ display: 'flex', width: 30, height: 30 }}
      x={widthScaled}
      y={0}
      width={30}
      height={heightScaled}
      transform={small ? 'scale(0.7)' : undefined}
      // eslint-disable-next-line react/no-unknown-property
      transform-origin={`${widthScaled + 7} ${heightScaled / 2}`}
    >
      {!hideUpButton && (
        <>
          <rect
            onMouseDown={handleUpClick}
            onMouseEnter={() => setUpArrowColor('#FFFFFF')}
            onMouseLeave={() => setUpArrowColor('teal')}
            x={widthScaled + 7}
            y={heightScaled / 2 - 41}
            rx={12}
            ry={12}
            width={24}
            height={24}
            className="section-switch-button"
          />
          <UpArrow
            width={16}
            height={16}
            x={widthScaled + 11}
            y={heightScaled / 2 - 37}
            fill={upArrowColor}
          />
        </>
      )}
      <g onMouseDown={handleDragStart} onMouseUp={handleDragEnd}>
        <rect
          width={24}
          height={24}
          x={widthScaled + 7}
          y={heightScaled / 2 - 12}
          rx={12}
          ry={12}
          className={classNames('section-drag-button', { light })}
          onMouseEnter={() => setGrabIconColor('white')}
          onMouseLeave={() => setGrabIconColor(light ? '#00a996' : 'white')}
        />
        <GrabHandle
          width={20}
          height={20}
          x={widthScaled + 9}
          y={heightScaled / 2 - 10}
          fill={grabIconColor}
        />
      </g>
      {!hideDownButton && (
        <>
          <rect
            onMouseDown={handleDownClick}
            onMouseEnter={() => setDownArrowColor('#FFFFFF')}
            onMouseLeave={() => setDownArrowColor('teal')}
            className="section-switch-button"
            x={widthScaled + 7}
            y={heightScaled / 2 + 17}
            rx={12}
            ry={12}
            width={24}
            height={24}
          />
          <DownArrow
            width={8}
            height={24}
            x={widthScaled + 15}
            y={heightScaled / 2 + 17}
            fill={downArrowColor}
            color={downArrowColor}
            opacity={1}
          />
        </>
      )}
    </g>
  )
}
