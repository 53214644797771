import {
  ColumnStyleWidth,
  ColumnWidths,
  TABLE,
  borderStyles,
  dataTypes,
  ColumnAlignments,
} from '@adalo/constants'
import type {
  BindingSource,
  FontWeight,
  TableField,
  ThemeShadow,
} from 'utils/responsiveTypes'
import { normalizeColor } from 'utils/colors'
import { normalizeFont } from 'utils/type'
import { type Branding } from 'utils/colorsTypes'
import { defaults } from 'utils/objects'
import { EditorObject } from 'utils/responsiveTypes'
import type { TableEditorObject } from 'components/Editor/Inspect/Libraries/Table/types'
import { getFieldColumnAlignment } from 'components/Editor/Inspect/Libraries/Table/utils'
import { getBorderRadiusProps } from '../Section/Rect'
import { TableTheme } from './types'

const tableDefault = defaults[TABLE]
if (typeof tableDefault === 'undefined') {
  throw new Error('Table default theme not found')
}

const getBorderRadius = (object: EditorObject, zoomScale: number): number => {
  const borderRadius = getBorderRadiusProps({
    zoom: { scale: zoomScale },
    width: object.width,
    height: object.height,
    rawBorderRadius: object.borderRadius,
    rawBorderTopLeftRadius: object.borderRadius,
    rawBorderTopRightRadius: object.borderRadius,
    rawBorderBottomLeftRadius: object.borderRadius,
    rawBorderBottomRightRadius: object.borderRadius,
  })

  const borderRadiusSame = Object.values(borderRadius).every(
    value => value === Object.values(borderRadius)[0]
  )
  if (!borderRadiusSame) {
    throw new Error('Table border-radius should be the same on all corners')
  }

  return borderRadius.borderTopLeftRadius
}

export const makeTheme = (
  object: TableEditorObject,
  branding: Branding,
  zoomScale: number
): TableTheme => {
  const {
    borderStyle = borderStyles.SOLID,
    borderWidth = 1,
    borderColor = '#BDBDBD',
    //
    backgroundColor = '#FFFFFF',
    tableRowFontColor = '#424242',
    tableRowLineColor = '#424242',
    tableColumnFontColor = '#000000',
    tableColumnBackgroundColor = '#EEEEEE',
    tableColumnLineColor = '#BDBDBD',
    tableRowHoverBackgroundColor = '#F5F5F5',
    //
    shadow = tableDefault.shadow as ThemeShadow,
    // magic text-based styles
    styles = {
      emptyStateTitleText: {
        fontSize: 18,
        fontWeight: '500',
        fontStyle: 'normal',
        fontFamily: '@heading',
        color: '#656565',
      },
      emptyStateBodyText: {
        fontSize: 12,
        fontWeight: '300',
        fontStyle: 'normal',
        fontFamily: '@body',
        color: '#757575',
      },
    },
  } = object

  return {
    borderStyle,
    borderWidth: borderStyle === borderStyles.NONE ? 0 : borderWidth,
    borderColor: normalizeColor(borderColor, branding),
    borderRadius: getBorderRadius(object, zoomScale),
    //
    backgroundColor: normalizeColor(backgroundColor, branding),
    tableRowFontColor: normalizeColor(tableRowFontColor, branding),
    tableRowLineColor: normalizeColor(tableRowLineColor, branding),
    tableColumnFontColor: normalizeColor(tableColumnFontColor, branding),
    tableColumnBackgroundColor: normalizeColor(tableColumnBackgroundColor, branding), // prettier-ignore
    tableColumnLineColor: normalizeColor(tableColumnLineColor, branding), // prettier-ignore
    tableRowHoverBackgroundColor: normalizeColor(tableRowHoverBackgroundColor, branding), // prettier-ignore
    //
    shadow: {
      ...shadow,
      color: normalizeColor(shadow.color, branding),
    },
    //
    fontFamily: normalizeFont(object.fontFamily, branding) ?? 'inherit',
    styles: {
      emptyStateTitleText: {
        fontSize: styles.emptyStateTitleText.fontSize,
        fontWeight: styles.emptyStateTitleText.fontWeight as FontWeight,
        fontStyle: styles.emptyStateTitleText.fontStyle,
        fontFamily: normalizeFont(
          styles.emptyStateTitleText.fontFamily,
          branding
        ) as string,
        color: normalizeColor(styles.emptyStateTitleText.color, branding),
      },
      emptyStateBodyText: {
        fontSize: styles.emptyStateBodyText.fontSize,
        fontWeight: styles.emptyStateBodyText.fontWeight as FontWeight,
        fontStyle: styles.emptyStateBodyText.fontStyle,
        fontFamily: normalizeFont(
          styles.emptyStateBodyText.fontFamily,
          branding
        ) as string,
        color: normalizeColor(styles.emptyStateBodyText.color, branding),
      },
    },
  }
}

const mockTableFields = () =>
  [
    {
      fieldId: '01a586cd-a687-477e-913d-e5d45c16dcb2',
      label: 'Name',
      type: dataTypes.TEXT,
      binding: {
        source: {
          dataType: dataTypes.TEXT,
          fieldId: '01a586cd-a687-477e-913d-e5d45c16dcb2',
        },
        format: {
          columnWidth: ColumnWidths.wide,
        },
      },
    },
    {
      fieldId: 'f22e7be2-429b-421b-afcd-cd3cb73a6885',
      label: 'Property #1',
      type: dataTypes.TEXT,
      binding: {
        source: {
          dataType: dataTypes.TEXT,
          fieldId: 'f22e7be2-429b-421b-afcd-cd3cb73a6885',
        },
        format: {
          columnWidth: ColumnWidths.standard,
        },
      },
    },
    {
      fieldId: 'a0fc6ae0-a18f-454a-81fd-badbf5121122',
      label: 'Property #2',
      type: dataTypes.TEXT,
      binding: {
        source: {
          dataType: dataTypes.TEXT,
          fieldId: 'a0fc6ae0-a18f-454a-81fd-badbf5121122',
        },
        format: {
          columnWidth: ColumnWidths.standard,
        },
      },
    },
    {
      fieldId: '91a844c3-0448-4c41-96ae-9924da68285b',
      label: 'Property #3',
      type: dataTypes.TEXT,
      binding: {
        source: {
          dataType: dataTypes.TEXT,
          fieldId: '91a844c3-0448-4c41-96ae-9924da68285b',
        },
        format: {
          columnWidth: ColumnWidths.standard,
        },
      },
    },
  ] as TableField[]

export const getFieldsForTable = (
  object: EditorObject
): {
  fields: TableField[]
  isMock: boolean
} => {
  const { dataBinding, fields } = object

  if (
    typeof dataBinding !== 'undefined' &&
    Array.isArray(fields) &&
    fields.length > 0
  ) {
    return {
      fields,
      isMock: false,
    }
  }

  return {
    fields: mockTableFields(),
    isMock: true,
  }
}

const flexMap = {
  [ColumnWidths.narrow]: '1 1 0%',
  [ColumnWidths.standard]: '2 1 0%',
  [ColumnWidths.wide]: '4 1 0%',
} as const

type AlignItems = 'flex-start' | 'center' | 'flex-end'

const alignmentMap: Record<string, AlignItems> = {
  [ColumnAlignments.left]: 'flex-start',
  [ColumnAlignments.center]: 'center',
  [ColumnAlignments.right]: 'flex-end',
}

type ColumnStyles = {
  minWidth: number
  flex: string
  alignItems: AlignItems
}

export const getColumnStyles = (field: TableField): ColumnStyles => {
  const { binding } = field
  const columnWidth = binding.format?.columnWidth ?? ColumnWidths.standard
  const alignment = getFieldColumnAlignment(field)

  return {
    minWidth: ColumnStyleWidth[columnWidth],
    flex: flexMap[columnWidth],
    alignItems: alignmentMap[alignment] ?? 'center',
  }
}

export const getTableSort = (object: EditorObject): BindingSource['sort'] => {
  const { dataBinding } = object

  return dataBinding?.source?.sort ?? null
}
