import { ForwardedRef, forwardRef, useContext } from 'react'
import styled from 'styled-components'
import { dataTypes } from '@adalo/constants'
import TableThemeContext from './TableThemeContext'
import TextCell from './TextCell'
import LocationCell from './LocationCell'
import ImageCell from './ImageCell'
import BooleanCell from './BooleanCell'

import { type DefaultCellProps } from './types'
import { MIN_ROW_HEIGHT } from './layout'
import { getColumnStyles } from './utils'

const Container = styled('div')<{
  $tableRowFontColor: string
  $tableColumnFontFamily: string
  $minWidth: number
  $minHeight: number
  $alignItems: string
  $flex: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.25px;
  color: ${props => props.$tableRowFontColor};
  font-family: ${props => props.$tableColumnFontFamily};
  flex: ${props => props.$flex};
  align-items: ${props => props.$alignItems};
  min-width: ${props => props.$minWidth}px;
  min-height: ${props => props.$minHeight}px;
`

const TableCell = (
  { field }: DefaultCellProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { tableRowFontColor, fontFamily } = useContext(TableThemeContext)
  const fieldType = field.binding.source.dataType

  const { minWidth, flex, alignItems } = getColumnStyles(field)

  return (
    <Container
      $tableRowFontColor={tableRowFontColor}
      $tableColumnFontFamily={fontFamily}
      $minWidth={minWidth}
      $minHeight={MIN_ROW_HEIGHT}
      $flex={flex}
      $alignItems={alignItems}
      ref={ref}
    >
      {fieldType === dataTypes.TEXT && <TextCell field={field} />}
      {fieldType === dataTypes.NUMBER && <TextCell field={field} />}
      {fieldType === dataTypes.DATE && <TextCell field={field} />}
      {fieldType === dataTypes.DATE_ONLY && <TextCell field={field} />}
      {fieldType === dataTypes.FILE && <TextCell field={field} />}
      {fieldType === dataTypes.LOCATION && <LocationCell field={field} />}
      {fieldType === dataTypes.IMAGE && <ImageCell field={field} />}
      {fieldType === dataTypes.BOOLEAN && <BooleanCell />}
    </Container>
  )
}

export default forwardRef(TableCell)
