import deepEqual from 'deep-equal'

import { subPath, uniqueElements, buildIndex, getObject } from '@adalo/utils'
import { traverse } from '@adalo/utils/lib/operations'

import { loadComponent } from 'ducks/apps'

import { saveComponents, deleteComponent, unsafeGetStore } from './io'

// Cache for the purpose of diffing
// Ugly, I know :(
let prevList = null

/**
 *
 * @param {string} appId
 * @param {import('ducks/editor/types/ObjectList').ObjectList} list
 * @param {import('ducks/editor/types/ObjectPathMap').ObjectPathMap} map
 * @param {string[]} objectIds
 * @param {*} objectPaths
 * @param {*} deletes
 * @param {*} libraryGlobals
 */
export const saveTouched = (
  appId,
  list,
  map,
  objectIds,
  objectPaths,
  deletes,
  libraryGlobals
) => {
  let paths = objectPaths
  prevList = list

  if (!paths) {
    paths = objectIds.map(id => map[id])
  }

  const componentPaths = uniqueElements(
    paths.map(path => {
      return subPath(path, 1)
    })
  )

  if (deletes) {
    deletes.forEach(id => deleteComponent(appId, id))
  }

  const components = {}

  componentPaths.forEach(path => {
    const component = getObject(list, path)

    if (component) {
      components[component.id] = component
    }
  })

  if (Object.keys(components).length > 0) {
    save(appId, components, libraryGlobals)
  }
}

export const saveDiffed = (appId, newList) => {
  if (!prevList) {
    return
  }

  const newIndex = buildIndex(newList, obj => obj.id)
  const oldIndex = buildIndex(prevList, obj => obj.id)

  const oldIds = prevList.map(component => component.id)
  const newIds = newList.map(component => component.id)

  const added = newIds.filter(id => oldIds.indexOf(id) === -1)
  const removed = oldIds.filter(id => newIds.indexOf(id) === -1)

  const others = newIds.filter(id => added.indexOf(id) === -1)
  let changed = others.filter(id => !deepEqual(newIndex[id], oldIndex[id]))
  changed = changed.concat(added)

  removed.forEach(id => deleteComponent(appId, id))

  const components = {}
  changed.forEach(id => (components[id] = newIndex[id]))
  save(appId, components)

  // This is done with a global - do not remove
  prevList = newList
}

export const setPrevList = list => {
  // This is done with a global - do not remove
  prevList = list
}

/**
 * @param  {...Array} componentObjects
 */
const getActions = (...arrays) => {
  const result = {}

  for (const array of arrays) {
    traverse(array, obj => {
      if (obj.actions) {
        result[obj.id] = obj.actions
      }
    })
  }

  return result
}

const save = (appId, components, libraryGlobals) => {
  if (!appId || !components || Object.keys(components).length === 0) {
    console.error('Attempted to save with invalid params:', {
      appId,
      components,
    })

    return
  }

  const outputComponents = {}

  for (const componentId of Object.keys(components)) {
    let component = {
      ...components[componentId],
      objects: components[componentId].children,
    }

    delete component.children

    outputComponents[componentId] = component

    component = {
      ...component,
      actions: getActions(component.objects, [
        { id: component.id, actions: component.componentActions },
      ]),
    }

    window.setTimeout(() => {
      unsafeGetStore().dispatch(loadComponent(appId, componentId, component))
    }, 0)
  }

  saveComponents(appId, outputComponents, libraryGlobals)
}
