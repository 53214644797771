import { LIBRARY_COMPONENT } from '@adalo/constants'
import { getComponentInfo } from './libraries'

export const getDefaults = props => {
  const result = {}

  if (props) {
    for (const prop of props) {
      if (prop.global && prop.default) {
        result[prop.name] = prop.default
      }
    }
  }

  return result
}

export const setGlobalDefaults = (libraryGlobals, newObjects) => {
  for (const obj of newObjects) {
    if (obj.type !== LIBRARY_COMPONENT) {
      continue
    }

    const { libraryName, componentName, libraryVersion } = obj

    let manifest = getComponentInfo(libraryName, libraryVersion, componentName)

    if (!manifest) {
      manifest = obj.libraryComponentManifest
    }

    const prevDefault = libraryGlobals?.[libraryName]?.[componentName]
    if (prevDefault) continue

    const result = getDefaults(manifest?.props)

    if (Array.isArray(manifest?.childComponents)) {
      for (const child of manifest?.childComponents) {
        const childResult = getDefaults(child?.props)

        if (Object.keys(childResult).length > 0) {
          result[child.name] = childResult
        }
      }
    }

    libraryGlobals = {
      ...libraryGlobals,
      [libraryName]: {
        ...libraryGlobals[libraryName],
        [componentName]: result,
      },
    }
  }

  return libraryGlobals
}
