import {
  FEATURE_TEMPLATE_DETAILS_MODAL,
  NEW_SCREEN_MODAL,
  showModal,
} from 'ducks/editor/modals'
import {
  FeatureTemplate,
  fetchFeatureTemplateDetails,
  getFeatureTemplates,
} from 'ducks/featureTemplates'
import { DefaultRootState } from 'react-redux'
import { FormattedSection } from 'utils/layoutSections'
import { Dispatch } from 'react'
import TemplateItem from 'components/Shared/TemplateItem'
import { History } from 'history'
import { Component, Library } from './componentHelpers'

export interface FormattedFeatureTemplate {
  label: string
  value: FeatureTemplate
}

interface Props extends FormattedFeatureTemplate {
  appId: string
  datasourceId: string | undefined
  dispatch: Dispatch<unknown>
  history: History
}

interface State {
  list: FeatureTemplate[] | null
  current: FeatureTemplate | null
  feedScroll: number
}

export interface FeatureState extends DefaultRootState {
  featureTemplates: State
}

export function isFormattedFeatureTemplate(
  item: Component | Library | FormattedSection | FormattedFeatureTemplate
): item is FormattedFeatureTemplate {
  return (item as FormattedFeatureTemplate)?.value?.summary !== undefined
}

export const getFormattedFeatures = (
  state: DefaultRootState
): FormattedFeatureTemplate[] => {
  const featureTemplates = getFeatureTemplates(state as FeatureState)
  const formattedTemplates: FormattedFeatureTemplate[] =
    featureTemplates?.map(feature => {
      return {
        label: feature.name,
        value: feature,
      }
    }) || []

  return formattedTemplates
}

export const renderFeatureOption = ({
  value,
  appId,
  datasourceId,
  dispatch,
  history,
}: Props): JSX.Element => {
  const option = value as FeatureTemplate & { layoutMode?: string }

  if (option.layoutMode === 'responsive') {
    const handleClick = () => {
      history.push(`/apps/${appId}/screens`)
      dispatch(
        showModal(NEW_SCREEN_MODAL, {
          appId,
          templateId: option.id,
          zoom: true,
        })
      )
    }

    return (
      <TemplateItem item={option} onClick={handleClick} showFooter={false} />
    )
  }

  const handleClick = () => {
    if (!datasourceId) {
      // app datasources might not be loaded yet.
      return
    }
    dispatch(fetchFeatureTemplateDetails(option.id))
    const modalProps = {
      feature: option,
      appId,
      datasourceId,
      origin: 'FEATURES_TAB',
    }

    dispatch(showModal(FEATURE_TEMPLATE_DETAILS_MODAL, modalProps))
  }

  return <TemplateItem item={option} onClick={handleClick} />
}
