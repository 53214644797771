import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ToggleButton from '../ToggleButton'

import './SimpleToggle.scss'

const SimpleToggle = props => {
  let {
    name,
    label,
    inputValue,
    helpText,
    onChange,
    handleChange,
    onInputChange,
    value,
    hideInput,
    input,
    disabled,
    color,
    inverted,
  } = props

  if (input) {
    name = input.name
    onChange = input.onChange
    value = input.value
  }

  return (
    <div className="settings-form-toggle">
      {label && <label>{label}</label>}
      <div
        className={classNames('simple-toggle-content', {
          'toggle-field-enabled': value,
          'toggle-field-disabled': !value,
        })}
      >
        <ToggleButton
          name={name}
          onChange={onChange || handleChange}
          value={!!value}
          disabled={disabled}
          color={color}
          inverted={inverted}
        />
        {!hideInput && (
          <input
            type="text"
            value={inputValue}
            onChange={onInputChange}
            disabled={!onInputChange}
          />
        )}
      </div>
      {helpText && <span>{helpText}</span>}
    </div>
  )
}

SimpleToggle.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
}

SimpleToggle.defaultProps = {
  disabled: false,
}

export default SimpleToggle
