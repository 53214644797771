import Length from '../Length'
import { FlexLength } from './flex'

export const TRACK_SIZE_AUTO = 'auto' as const
export type AutoTrackSize = typeof TRACK_SIZE_AUTO

export type TrackSizeFunction = Length | FlexLength | AutoTrackSize

export interface TrackList {
  trackSizes: TrackSizeFunction[]
}
