import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFlags } from 'flags'

import ToggleButton from 'components/Shared/Forms/ToggleButton'
import {
  PLAN_FREE,
  PRICE_TYPE_MONTHLY,
  PRICE_TYPE_YEARLY,
  normalizeSubscription,
  getPlanActions,
  isLegacyPlanType,
} from 'utils/billing.ts'
import { getApp } from 'ducks/apps'
import { getOrganization } from 'ducks/organizations'
import { adaloBackendAxios } from 'utils/io/http/axios'

import NewPlanCard from './NewPlanCard'
import usePlanCards from './usePlanCards'

import ARROW_ICON_URL from './AnnualOffer-arrow.svg'
import './PlanOptionsBoard.scss'

const getButtonDisabled = isOrganizationAdmin => !isOrganizationAdmin

const getButtonTooltip = (isOrganizationAdmin, organizationName) => {
  if (isOrganizationAdmin) {
    return null
  }

  if (organizationName) {
    return `You must be an admin of the organization "${organizationName}" in order to upgrade.`
  } else {
    return 'You need to be an admin for this organization to change plans.'
  }
}

const PlanOptionsBoard = ({
  subscription = {},
  defaultAnnual,
  isOrganizationAdmin,
  organizationName,
  onPlanSelect,
}) => {
  const [annual, setAnnual] = useState(defaultAnnual)
  const { hasTeamPlanRecommended, hasPlans2024 } = useFlags()
  const { appId } = useParams()
  const app = useSelector(state => getApp(state, appId))

  const org = useSelector(state =>
    getOrganization(state, app?.Organization?.id)
  )

  const { planType: currentPlanType, planInterval: currentPlanInterval } =
    normalizeSubscription(subscription)
  const planCards = usePlanCards(currentPlanType)

  const onToggle = () => setAnnual(!annual)

  useEffect(() => {
    if (currentPlanType === 'free') {
      const options = {
        orgId: org.id,
        flagName: 'hasPlans2024',
        variation: hasPlans2024,
      }
      adaloBackendAxios.put('/pricing-test', options)
    }
  }, [currentPlanType, org, hasPlans2024])

  const buttonDisabled = getButtonDisabled(isOrganizationAdmin)
  const buttonTooltip = getButtonTooltip(isOrganizationAdmin, organizationName)

  const planActions = getPlanActions(
    currentPlanType,
    currentPlanInterval,
    annual
  )

  const renderPlanCard = cardKey => {
    const { planType: cardPlanType, planTypeMatch } =
      planCards.cards[cardKey] || {}

    const isLegacyPlan = isLegacyPlanType(currentPlanType)

    const interval = annual ? PRICE_TYPE_YEARLY : PRICE_TYPE_MONTHLY

    const switchingInterval =
      cardPlanType !== PLAN_FREE && interval !== currentPlanInterval

    const isCurrentPlan =
      (currentPlanType === cardPlanType || currentPlanType === planTypeMatch) &&
      !switchingInterval

    const action = planActions[cardPlanType]

    const recommended = useMemo(() => {
      if (hasTeamPlanRecommended) {
        return cardKey === 'team'
      }

      return currentPlanType === 'business'
        ? cardKey === 'business'
        : cardKey === 'pro'
    }, [hasTeamPlanRecommended, currentPlanType, cardKey])

    return (
      <NewPlanCard
        recommended={recommended}
        key={cardKey}
        cardKey={cardKey}
        annual={annual}
        current={isCurrentPlan}
        currentPlanType={currentPlanType}
        isLegacyPlan={isLegacyPlan}
        action={action}
        buttonDisabled={buttonDisabled}
        buttonTooltip={buttonTooltip}
        onSelect={() => onPlanSelect(cardPlanType, interval)}
      />
    )
  }

  const orderedPlanCards = planCards.order.map(renderPlanCard)

  return (
    <div className="PlanOptionsBoard">
      <div className="PlanOptionsBoard-intervalToggle">
        <p>Monthly</p>
        <ToggleButton value={annual} onChange={onToggle} />
        <p>Yearly</p>
        <div className="PlanOptionsBoard-annualOffer">
          <img
            className="PlanOptionsBoard-annualOffer-arrow"
            src={ARROW_ICON_URL}
            alt="Arrow pointing to annual toggle"
          />
          <p className="PlanOptionsBoard-annualOffer-text">
            Save 20% on yearly
          </p>
        </div>
      </div>
      <div className="PlanOptionsBoard-cards">{orderedPlanCards}</div>
    </div>
  )
}

export default PlanOptionsBoard
