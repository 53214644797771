export const trackSignupEvent = () => {
  window.fbq('track', 'CompleteRegistration')
}

export const trackStartTrialEvent = () => {
  window.fbq('track', 'StartTrial', {
    currency: 'usd',
    predicted_ltv: 120,
  })
}

export const trackSubscriptionStartEvent = value => {
  window.fbq('track', 'Subscribe', { currency: 'usd', value })
}
