import { WebSettings } from 'ducks/apps/App'

export type PrimaryPlatform = 'mobile' | 'web' | 'responsive'

type GetNewAppWebSettings = {
  sourceWebSettings?: WebSettings
  primaryPlatform?: PrimaryPlatform
  mobileOnly?: boolean
}

interface NewWebAppSettings {
  previewType: PrimaryPlatform | undefined
  showAddToHomeScreen: boolean
  showAdaloAttribution: boolean
}

const getPreviewTypeForPlatform = (platform?: PrimaryPlatform) =>
  platform === 'mobile' ? 'mobile' : 'web'

export const getNewAppWebSettings = (
  params: GetNewAppWebSettings
): NewWebAppSettings => {
  const {
    primaryPlatform = 'web',
    sourceWebSettings,
    mobileOnly = false,
  } = params

  const previewType =
    sourceWebSettings?.previewType ?? getPreviewTypeForPlatform(primaryPlatform)

  const showAddToHomeScreen =
    sourceWebSettings?.showAddToHomeScreen ?? primaryPlatform === 'mobile'

  // Adalo attribution is on by default
  const showAdaloAttribution = true

  const layoutMode = mobileOnly ? 'mobile' : sourceWebSettings?.layoutMode

  const newAppWebSettings = {
    previewType,
    showAddToHomeScreen,
    showAdaloAttribution,
    layoutMode,
  }

  return newAppWebSettings
}
