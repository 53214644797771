import React from 'react'
import PropTypes from 'prop-types'
import TooltipTrigger from 'react-popper-tooltip'
import classNames from 'classnames'

import './tooltip.scss'

const Tooltip = ({
  show = true,
  children,
  tooltip,
  hideArrow = false,
  className = '',
  small = false,
  containerClassname = '',
  triggerClassname = '',
  ...props
}) => {
  if (!show) {
    return children
  }

  return (
    <TooltipTrigger
      {...props}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
        placement,
      }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames(
              'tooltip-container',
              {
                'tooltip-container-small': small,
              },
              className,
              containerClassname
            ),
          })}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                className: 'tooltip-arrow',
                'data-placement': placement,
              })}
            />
          )}
          {typeof tooltip === 'function' ? tooltip() : tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => {
        return (
          <span
            {...getTriggerProps({
              ref: triggerRef,
              className: `${triggerClassname} tooltip-trigger`,
            })}
          >
            {children}
          </span>
        )
      }}
    </TooltipTrigger>
  )
}

Tooltip.propTypes = {
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
    'left-start',
    'left',
    'left-end',
  ]),
  hideArrow: PropTypes.bool,
  containerClassName: PropTypes.string,
}

Tooltip.defaultProps = {
  placement: 'top',
  hideArrow: false,
}

export default Tooltip
