import React, { forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getActiveState, getTrialState } from 'ducks/organizations'
import { generateHandleTrial } from 'hooks/useHandleTrialOrUpgrade'

import { BEFORE, START_TRIAL, UPGRADE } from '../../constants'

function getAppId(location) {
  if (location.match && location.match.appId) {
    return location.match.appId
  }

  const match = location.pathname.match(/^\/apps\/([\w\-]+)(\/|$)/)

  return match && match[1]
}

export const withPremiumFeatureHandler = Component => {
  return forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { trialState } = useSelector(getTrialState)
    const isPaidOrg = useSelector(getActiveState)

    const hoverContent =
      trialState === BEFORE && !isPaidOrg ? START_TRIAL : UPGRADE

    const appId = getAppId(history.location)

    const handleTrial = type =>
      generateHandleTrial({
        appId,
        dispatch,
        trialState,
        type,
        isPaidOrg,
      })

    const helpers = {
      handleTrial,
      hoverContent,
    }

    return <Component {...props} helpers={helpers} ref={ref} />
  })
}
