import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'

import useSetSeenTrialEndModal from 'hooks/useSetSeenTrialEndModal'

import {
  showModal as showUpgradeModal,
  setPaymentRouteType,
} from 'ducks/trialWarning'
import { getApp } from 'ducks/apps'
import { getOrganization, updateOrganizationName } from 'ducks/organizations'

import { getFeatureFlag } from 'ducks/featureFlags'
import freeTrialEndGraphic from './free-trial-end.gif'

import history from '../../../history'

import './style.scss'

const FreeTrialEndModal = ({
  appId,
  showUpgradeModal,
  setPaymentRouteType,
  hasTrialExtension2023,
  organization,
}) => {
  const handleClose = () => {
    const url = `/apps/${appId}/screens`

    history.push(url)
  }

  const handleClickExtend = () => {
    const url = `/apps/${appId}/free-trial-extend`

    history.push(url)
  }

  const handleClickUpgrade = async () => {
    await setPaymentRouteType('upgrade')
    await showUpgradeModal()
  }

  useSetSeenTrialEndModal(true)

  const renderContent = () => {
    return (
      <div className="free-trial-end">
        <img src={freeTrialEndGraphic} className="free-trial-end-gif" alt="" />
        <h1 className="free-trial-end-title">Your Trial has Ended...</h1>
        <p className="free-trial-end-body">
          While you no longer have access to some really great features,
          including Custom Actions, Geolocation, External Collections, the
          Collections API, &amp; Custom Fonts &#8212; you can still keep
          building! Want to keep using these premium features? Upgrade now &amp;
          continue right where you left off.
        </p>
      </div>
    )
  }

  const canExtendTrial = organization?.trialState?.canExtendTrial ?? false

  if (hasTrialExtension2023) {
    const leftButtons = canExtendTrial ? (
      <Button text onClick={handleClose} type="button">
        Done
      </Button>
    ) : null

    return (
      <Modal size="lg">
        <Modal.Content>{renderContent()}</Modal.Content>
        <Modal.Actions leftButtons={leftButtons}>
          {canExtendTrial ? (
            <Button
              text
              onClick={handleClickExtend}
              type="button"
              teal
              outlined
              data-adalo-id="extend-trial-confirm"
            >
              Extend my trial
            </Button>
          ) : (
            <Button text onClick={handleClose} type="button">
              Done
            </Button>
          )}
          <Button onClick={handleClickUpgrade} type="button" teal gradient>
            Upgrade
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <Modal size="lg">
      <Modal.Content>{renderContent()}</Modal.Content>
      <Modal.Actions>
        <Button text onClick={handleClose} type="button">
          Done
        </Button>
        <Button onClick={handleClickUpgrade} type="button" orange>
          Upgrade
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = (state, { match }) => {
  const app = getApp(state, match.params.appId)
  const { OrganizationId } = app || {}

  const organization = getOrganization(state, OrganizationId)

  return {
    appId: match.params.appId,
    app,
    organization,
    hasTrialExtension2023: getFeatureFlag(state, 'hasTrialExtension2023'),
  }
}

export default withRouter(
  connect(mapStateToProps, {
    showUpgradeModal,
    setPaymentRouteType,
    updateOrganizationName,
  })(FreeTrialEndModal)
)
