import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { PREVIEW_URL } from 'constants.js'

// utils
import { getAuthToken } from 'utils/auth'
import { forceSaveComponents } from 'utils/io'
import { isSupportedOnWeb } from 'utils/platform'

// ducks
import { getApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users/index.ts'

// shared components
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import './PreviewButton.css'

const PreviewButton = ({ app, user }) => {
  if (!app || !user) {
    return null
  }

  const getButtonProps = ({ baseUrl, previewRoute }) => {
    if (isSupportedOnWeb(app.primaryPlatform)) {
      const sessionToken = getAuthToken()
      const params = { sessionToken }

      if (!user?.developer) {
        delete params.sessionToken
      }

      const url = `${baseUrl}/${app.id}?${qs.stringify(params)}`

      return {
        to: url,
        target: '_blank',
      }
    }

    return { to: previewRoute }
  }

  const WrappedButton = ({ baseUrl, buttonText, previewRoute }) => (
    <div className="editor-preview-button-wrapper">
      <Button
        {...getButtonProps({ baseUrl, previewRoute })}
        className="editor-preview-button"
        onClick={forceSaveComponents}
      >
        <Icon type="play" /> {buttonText}
      </Button>
    </div>
  )

  return (
    <WrappedButton
      buttonText="Preview"
      baseUrl={PREVIEW_URL}
      previewRoute={`/apps/${app.id}/screens/preview`}
    />
  )
}

const mapStateToProps = (state, { match }) => ({
  app: getApp(state, match.params.appId),
  user: getCurrentUser(state),
})

export default withRouter(connect(mapStateToProps)(PreviewButton))
