import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import useDebugMode from 'hooks/useDebugMode'
import Loading from 'components/Shared/Loading'
import Button from 'components/Shared/Button'

import Build from './Build'

const BuildList = ({ list, loading, appId, appUrl }) => {
  const debug = useDebugMode()

  const empty = !list || list.length === 0
  const [buildList, setBuildList] = useState([])
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    if (!empty) {
      if (showAll) setBuildList(list)
      if (!showAll) setBuildList(list.slice(0, 4))
    }
  }, [empty, list, showAll])

  let content = <Loading />
  if (!loading) {
    if (empty) {
      content = 'No Builds Yet'
    } else {
      content = buildList.map((item, index) => {
        const shouldShowOpenApp = item.target === 'web' && index === 0 && appUrl
        const minutesToBuildEstimate = item.target === 'web' ? 0.12 : 15
        const buildAppUrl = shouldShowOpenApp ? appUrl : undefined
        const key = item.id || item.version

        return (
          <Build
            key={key}
            {...item}
            appId={appId}
            minutesToBuildEstimate={minutesToBuildEstimate}
            appUrl={buildAppUrl}
            debug={debug}
          />
        )
      })
    }
  }

  let showMore = null
  if (list?.length > 4) {
    showMore = (
      <Button text square onClick={() => setShowAll(!showAll)}>
        {!showAll ? 'Show More' : 'Show Less'}
      </Button>
    )
  }

  return (
    <div
      className={classNames('mobile-build-list', {
        'mobile-build-list-empty': empty,
      })}
    >
      {content}
      {showMore}
    </div>
  )
}

BuildList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
}

export default React.memo(BuildList)
