import SliderControl from '../Libraries/SliderControl'

type Props = {
  key: string
  defaultValue: number
  max: number
  min: number
  name: string
  onChange: (value: number) => void
  title: string
  value: number
  percentage?: boolean
}

const LayoutSectionSliderControl: React.FC<Props> = props => {
  return <SliderControl {...props} />
}

export default LayoutSectionSliderControl
