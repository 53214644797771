import { combineReducers } from 'redux'
import apps from './apps'
import users from './users'
import builds from './builds'
import libraries from './libraries'

export default combineReducers({
  apps,
  users,
  builds,
  libraries,
})
