import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { subPath, pathLength, getDeviceType } from '@adalo/utils'
import { COMPONENT, LIBRARY_COMPONENT } from '@adalo/constants'

import { getAppComponent, hasUnlicensedLibrary } from 'utils/libraries'
import { features } from 'utils/objects'
import { getTypeNameForObject } from 'utils/objectNames'
import { getObjectName } from 'utils/naming'

import {
  getCurrentAppId,
  getMap,
  getObjectList,
  selectObject,
} from 'ducks/editor/objects'
import { getApp, getAppLibraries } from 'ducks/apps'
import { CurrentTab, setCurrentTab } from 'ducks/magicLayout.ts'

import { IconButton } from 'components/Shared/Icon'
import Element from 'components/Shared/Element'
import {
  getContainerFromLayoutHelper,
  isChildOfSection,
  isContainerSectionElement,
  isLayoutHelperSectionElement,
} from 'utils/layoutSections'

import { getFeatureFlag } from 'ducks/featureFlags'
import DropTarget from './DropTarget'
import HiddenIconButton from './HiddenIconButton.tsx'
import stopPropagation from './stopPropagation.ts'

export const EMPTY_ARRAY = []

class LayerItem$ extends Component {
  static contextTypes = {
    getDraggingObjects: PropTypes.func,
    getSelection: PropTypes.func,
    getLayers: PropTypes.func,
    execute: PropTypes.func,
  }

  state = { expanded: false, autoExpanded: true }

  handleClick = e => {
    const {
      object: { id },
      setCurrentTab,
    } = this.props

    const { execute } = this.context

    e.stopPropagation()
    setCurrentTab(CurrentTab.Setup)

    return execute('setSelection', id, e.shiftKey)
  }

  handleClickToLayoutSettings = e => {
    const {
      object: { id },
      setCurrentTab,
    } = this.props

    const { execute } = this.context

    e.stopPropagation()
    setCurrentTab(CurrentTab.Layout)

    execute('setSelection', id, e.shiftKey)
  }

  handleDragStart = (e, offset) => {
    const {
      object: { id },
      selected,
    } = this.props

    const { getSelection, execute } = this.context
    const position = [e.clientX, e.clientY]
    const selection = getSelection()

    let ids = [id]

    if (selected) {
      ids = selection
    }

    execute('startDrag', ids, offset, position)
  }

  handleDrag = e => {
    const position = [e.clientX, e.clientY]
    const { execute } = this.context

    execute('drag', position)
  }

  handleDragEnd = e => {
    const { execute, getLayers } = this.context
    const layers = getLayers()

    execute('endDrag')

    if (!layers.dropTarget) {
      return
    }

    execute('reorderObjects', layers.objects, layers.dropTarget, layers.options)
  }

  handleMouseDown = e => {
    e.stopPropagation()
  }

  handleMouseMove = e => {
    const { object } = this.props

    const { expanded } = this.state
    const { getDraggingObjects, execute } = this.context

    if (!this.dragInProgress()) {
      return
    }

    const el = e.currentTarget
    const bbox = el.getBoundingClientRect()

    let dropAfter = e.clientY > bbox.top + bbox.height / 2

    let dropInside

    if (features[object.type].children || isContainerSectionElement(object)) {
      let bottom = bbox.top + (bbox.height * 3) / 4

      if (expanded) {
        bottom = bbox.bottom + 1
      }

      dropInside =
        e.clientY > bbox.top + bbox.height / 4 &&
        e.clientY < bottom - bbox.height / 4
    }

    if (object.type === COMPONENT) {
      const draggingObjects = getDraggingObjects()
      let nonComponent = false

      for (const obj of draggingObjects) {
        if (obj.type !== COMPONENT) {
          nonComponent = true
        }
      }

      if (nonComponent) {
        dropInside = true
        dropAfter = false
      }
    }

    execute('setDropTarget', object.id, { dropAfter, dropInside })
  }

  handleMouseEnter = e => {
    const { object } = this.props

    const { execute } = this.context

    window.setTimeout(() => {
      execute('setLayersHover', object)
    }, 0)
  }

  handleMouseLeave = e => {
    const { execute } = this.context

    execute('setLayersHover', null)
  }

  toggleExpanded = e => {
    e.stopPropagation()

    this.setState(state => ({
      expanded: !state.expanded,
      autoExpanded: false,
    }))
  }

  getFormattedDropTargetPath = () => {
    const { dropTarget, objectIsChildOfSection } = this.props

    return objectIsChildOfSection
      ? subPath(dropTarget, pathLength(dropTarget) - 1)
      : dropTarget
  }

  dragInProgress = () => {
    const { dragInProgress } = this.props

    return dragInProgress
  }

  dropBefore = () => {
    const { path, dropBefore } = this.props

    return path === this.getFormattedDropTargetPath() && dropBefore
  }

  dropAfter = () => {
    const { path, dropAfter } = this.props

    return path === this.getFormattedDropTargetPath() && dropAfter
  }

  dropInside = () => {
    const { path, dropInside } = this.props

    return path === this.getFormattedDropTargetPath() && dropInside
  }

  shouldComponentUpdate(newProps, newState) {
    for (const key in newProps) {
      // eslint-disable-next-line react/destructuring-assignment
      if (newProps[key] !== this.props[key]) {
        return true
      }
    }

    if (newState !== this.state) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    this._deleted = true
  }

  renderTypeHeader = () => {
    const { object } = this.props

    if (!object) return null

    if (object?.type === LIBRARY_COMPONENT) {
      const { displayName } =
        getAppComponent(null, object.libraryName, object.componentName) || {}

      return displayName || 'Missing Component'
    }

    return getTypeNameForObject(object)
  }

  toggleVisibility = e => {
    const { object } = this.props
    const { hidden, id } = object
    const { execute } = this.context

    e.stopPropagation()

    execute('updateObject', id, { hidden: !hidden })
  }

  render() {
    let {
      path,
      object,
      selection,
      hoverSelection,
      dragInProgress,
      dropTarget,
      dropBefore,
      dropAfter,
      dropInside,
      libraries,
      hasMagicLayout,
      screen,
      screenId,
      children,
    } = this.props

    const { hidden, type, deviceVisibility } = object

    const { expanded, autoExpanded } = this.state

    selection = selection.filter(itm => {
      return subPath(itm, pathLength(path)) === path
    })

    if (selection.length === 0) {
      selection = EMPTY_ARRAY
    }

    hoverSelection = hoverSelection.filter(itm => {
      return subPath(itm, pathLength(path)) === path
    })

    if (hoverSelection.length === 0) {
      hoverSelection = EMPTY_ARRAY
    }

    const selected = selection.indexOf(path) !== -1
    const canvasHovered = hoverSelection.indexOf(path) !== -1

    if (selection !== EMPTY_ARRAY && !expanded && autoExpanded) {
      setTimeout(() => {
        if (this._deleted) {
          return
        }

        this.setState({
          expanded: true,
          autoExpanded: true,
        })
      }, 10)
    } else if (expanded && selection === EMPTY_ARRAY && autoExpanded) {
      setTimeout(() => {
        if (this._deleted) {
          return
        }

        this.setState({
          expanded: false,
          autoExpanded: true,
        })
      }, 10)
    }

    const expandEnabled =
      type === COMPONENT || (children && children.length > 0)

    const getDeviceVisibile = screen => {
      if (screen === undefined) {
        return true
      }

      const currentDeviceView = getDeviceType(screen?.width)

      return deviceVisibility ? deviceVisibility[currentDeviceView] : true
    }

    const magicHidden = !getDeviceVisibile(screen)

    // Section rule: it is not possible to reparent a Section Container element
    const dragHandlers = {
      ...(isContainerSectionElement(object) === false && {
        onDragStart: this.handleDragStart,
        onDrag: this.handleDrag,
        onDragEnd: this.handleDragEnd,
      }),
    }

    return (
      <Element
        className={classNames('layer-item', {
          hidden,
          selected,
          expanded,
          'component-layer': type === COMPONENT,
          'canvas-hovered': canvasHovered && !selected,
          'layer-item-unlicensed':
            !expanded && hasUnlicensedLibrary(object, libraries),
        })}
        dragTolerence={10}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        {...dragHandlers}
      >
        <div className="layer-info">
          {this.dropInside() && <DropTarget inside />}

          {expandEnabled ? (
            <IconButton
              type="arrow-drop-down"
              className="layer-expand-icon"
              onClick={this.toggleExpanded}
              onMouseDown={stopPropagation}
            />
          ) : (
            <span className="layer-no-expand" />
          )}

          <div
            className={classNames('layer-info-sub', { hidden, magicHidden })}
            onMouseMove={this.handleMouseMove}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {this.dropBefore() && <DropTarget before />}
            {this.dropAfter() && <DropTarget after />}
            <div className="layer-label">
              <span className="layer-label-subtitle">
                {this.renderTypeHeader()}
              </span>
              <span className="layer-label-title">{getObjectName(object)}</span>
            </div>
            <HiddenIconButton
              hasMagicLayout={hasMagicLayout}
              handleClickToLayoutSettings={this.handleClickToLayoutSettings}
              toggleVisibility={this.toggleVisibility}
              hidden={hidden}
              magicHidden={magicHidden}
            />
          </div>
        </div>
        {expanded && children && children.length > 0 && (
          <div className="layer-item-children">
            {children
              .slice()
              .reverse()
              .map((child, i) => (
                <LayerItem
                  object={child}
                  key={child.id}
                  path={`${path}.${children.length - i - 1}`}
                  selection={selection}
                  hoverSelection={hoverSelection}
                  dragInProgress={dragInProgress}
                  dropTarget={dropTarget}
                  dropBefore={dropBefore}
                  dropAfter={dropAfter}
                  dropInside={dropInside}
                  screenId={screenId}
                />
              ))}
          </div>
        )}
      </Element>
    )
  }
}

const mapStateToProps = (state, { screenId, object }) => {
  const appId = getCurrentAppId(state)

  const hasMagicLayout = state?.apps?.apps[appId]?.magicLayout || false

  const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')
  let children = object?.children || EMPTY_ARRAY

  children = children.map(child => {
    if (isLayoutHelperSectionElement(child)) {
      return getContainerFromLayoutHelper(child)
    }

    return child
  })

  if (hasNewMobileOnlyApp) {
    const app = getApp(state, appId)
    if (app?.webSettings?.layoutMode === 'mobile') {
      children = children.filter(
        child => child.deviceVisibility?.mobile !== false
      )
    }
  }

  return {
    libraries: getAppLibraries(state, appId),
    hasMagicLayout,
    screen: selectObject(state, screenId),
    children,
    objectIsChildOfSection: isChildOfSection(object.id, {
      list: getObjectList(state),
      map: getMap(state),
    }),
  }
}

const LayerItem = connect(mapStateToProps, { setCurrentTab })(LayerItem$)

export default LayerItem
