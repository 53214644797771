import React from 'react'

import './FieldList.scss'

export default function FieldList({ heading, itemKeys, renderItem }) {
  const children = []

  for (const key of itemKeys) {
    const item = renderItem(key)

    children.push(
      <div key={key} className="field-list__row">
        {item}
      </div>
    )
  }

  return (
    <div className="field-list">
      <label className="field-list__label">{heading}</label>
      {children}
    </div>
  )
}
