export type FeatureValue =
  | 'collections-api'
  | 'design-versions'
  | 'custom-fonts'
  | 'custom-actions'
  | 'external-collections'
  | 'geolocation'
  | ''

export const featureValues: FeatureValue[] = [
  'collections-api',
  'design-versions',
  'custom-fonts',
  'custom-actions',
  'external-collections',
  'geolocation',
]

const features = {
  'collections-api': {
    title: 'Collections API',
    buttonText: 'Get App API Key',
    destination: '/app-settings?active=access',
  },
  'design-versions': {
    title: 'Design Versions',
    buttonText: 'Add a Design Version',
    destination: '/versions',
  },
  'custom-fonts': {
    title: 'Custom Fonts',
    buttonText: 'Pick Your Fonts',
    destination: '/branding',
  },
  'custom-actions': {
    title: 'Custom Actions',
    buttonText: 'Add New Custom Action',
    destination: '/screens',
  },
  'external-collections': {
    title: 'External Collections',
    buttonText: 'Add new external collection',
    destination: '/data/new-external-collection',
  },
  geolocation: {
    title: 'Geolocation',
    buttonText: 'Add Your Google Maps API Key',
    destination: '/app-settings?active=apikeys',
  },
  '': { title: '', buttonText: '', destination: '' },
}

export default features
