import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { COMPONENT, LIBRARY_COMPONENT } from '@adalo/constants'
import { traverse } from '@adalo/utils'

import { createName } from 'utils/naming'
import { getNewScreenSize } from 'utils/objects'
import { cloneScreenTemplatesToApp } from 'utils/io'
import { checkComponentsUsed, addComponentUsed } from 'utils/libraries'

import { getApp, templateIdIsBlankType, updateApp } from 'ducks/apps'
import { createObject, selectObjects } from 'ducks/editor/objects'
import { getFeatureFlag } from 'ducks/featureFlags'

import ScreenForm from './ScreenForm'
import Form from './Form'

class NewScreenModal extends Component {
  handleSubmit = async values => {
    const { createObject, onSuccess, opts, app, updateApp } = this.props

    let baseObject
    const template = opts.template || values.template
    const name = values.name || this.getDefaultName()

    const templateId = template.id ?? template
    const transition = template.transition ?? null

    if (templateIdIsBlankType(templateId)) {
      baseObject = {
        ...getNewScreenSize(app.primaryPlatform, app?.magicLayout),
        backgroundColor: '@background',
      }
    } else {
      baseObject = await cloneScreenTemplatesToApp(templateId, app.id, name)
    }

    const { objects } = baseObject

    if (objects) {
      traverse(objects, object => {
        if (object.type === LIBRARY_COMPONENT) {
          const { libraryName, componentName } = object

          if (!checkComponentsUsed(libraryName, componentName, app)) {
            addComponentUsed(libraryName, componentName, app, updateApp)
          }
        }
      })
    }

    const newObject = createObject(
      {
        ...baseObject,
        name,
        type: COMPONENT,
        x: undefined,
        y: undefined,
      },
      null,
      !opts.zoom
    )

    onSuccess({ target: newObject.id, transition })
  }

  getDefaultName = () => {
    const { list } = this.props

    return createName(COMPONENT, null, list)
  }

  render() {
    const { app, onCancel, opts, hasNewAddMenuWithSections } = this.props

    const name = this.getDefaultName()

    if (app?.primaryPlatform === 'responsive' && hasNewAddMenuWithSections) {
      const template = {
        id: opts.templateId ?? 'blank',
      }

      return (
        <ScreenForm
          initialValues={{ template }}
          onSubmit={this.handleSubmit}
          chooseTemplate={!opts.template}
          onCancel={onCancel}
          defaultName={name}
          primaryPlatform={app.primaryPlatform}
        />
      )
    }

    return (
      <Form
        onSubmit={this.handleSubmit}
        chooseTemplate={!opts.template}
        onCancel={onCancel}
        defaultName={name}
        primaryPlatform={app.primaryPlatform}
      />
    )
  }
}

const mapStateToProps = (state, { match }) => {
  return {
    list: selectObjects(state),
    app: getApp(state, match.params.appId),
    hasNewAddMenuWithSections: getFeatureFlag(
      state,
      'hasNewAddMenuWithSections'
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, { createObject, updateApp })(NewScreenModal)
)
