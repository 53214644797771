export const usesCollections = datasource => {
  return datasource?.type !== 'apto-backend'
}

export const getCollection = (datasource, id) => {
  const collections = usesCollections(datasource)

  if (typeof id === 'object') {
    const { collectionId, tableId } = id
    id = collections ? collectionId : tableId
  }

  if (!datasource) {
    return null
  }

  return usesCollections(datasource)
    ? datasource.collections[id]
    : datasource.tables[id]
}

export const hasTimestamps = (datasource, isNotExternalCollection) => {
  return datasource.type === 'apto-backend' && isNotExternalCollection
}
