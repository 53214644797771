import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  backgroundStyle,
  borderStyle,
  positioning,
  positioningOptions,
  inputTypes,
  inputTypeOptions,
  statusBarStyles,
  statusBarStyleOptions,
  imageResize,
  imageResizeOptions,
  INPUT,
  LABEL,
  LINE,
  COMPONENT,
} from '@adalo/constants'

import ToggleButton from 'components/Shared/Forms/ToggleButton'

import { getApp, getAppBranding } from 'ducks/apps'
import { getFeatureFlag } from 'ducks/featureFlags'

import {
  features,
  defaults,
  commonValues,
  OBJECTS_SUPPORTING_BORDER_RADIUS_CONTROL,
} from 'utils/objects'
import { fontWeightOptions, getFontFamilyOptions } from 'utils/type'
import getDeviceObjectFromState from 'utils/getDeviceObjectFromState'
import { getMap, getObjectList, updateObjects } from 'ducks/editor/objects'

import InspectRow from './Row'
import ColorPicker from './ColorPicker'
import SingleNumberControl from './SingleNumberControl'
import BooleanControl from './BooleanControl'
import SlideControl from './SlideControl'
import TextControl from './TextControl'
import TextAlignControl from './TextAlignControl'
import OptionControl from './OptionControl'
import SelectControl from './SelectControl'
import ShadowControl from './ShadowControl'
import ImageControl from './ImageControl'
import ListTypeControl from './ListTypeControl'
import SliderControl from './Libraries/SliderControl'
import BorderControl from './BorderControl'
import { ShadowControl as DefaultShadowControl } from './DefaultShadowControl'
import BackgroundControl from './BackgroundControl'
import { FontControl } from './DefaultFontControl'
import GenericInspectRow from './GenericRow'
import { SizeControl } from './DefaultSizeControl'
import MenuControl from './Libraries/MenuControl'
import BackgroundImageControl from './BackgroundImageControl'
import ColorControl from './Libraries/ColorControl'
import { TableDataStyles } from './TableDataStyles'

class InspectBody extends Component {
  handleChange = value => {
    const { objects, updateObjects } = this.props

    const newObjects = objects.map(({ id }) => ({ ...value, id }))

    updateObjects(newObjects)
  }

  getMaxBorderRadius = object => {
    const width = object.width || 100
    const height = object.height || 100

    const min = Math.min(width, height)

    return Math.floor(min / 2)
  }

  render() {
    const {
      appId,
      objects,
      branding,
      magicLayout,
      getStateDeviceObject,
      hasIndependentBorderRadius,
      hasNewEditStyles,
      featuresSubset,
    } = this.props

    const objectFeatures = commonValues(
      objects.map(obj => {
        if (
          typeof featuresSubset === 'string' &&
          typeof features[obj.type][featuresSubset] === 'object'
        ) {
          return features[obj.type][featuresSubset]
        }

        return features[obj.type]
      })
    )

    const object = commonValues(objects, getStateDeviceObject)

    const hideBorderRadius =
      hasIndependentBorderRadius &&
      OBJECTS_SUPPORTING_BORDER_RADIUS_CONTROL.has(object?.type)

    // TODO @danicunhac: refactor this, it's a mess.
    return (
      <div>
        {!magicLayout && objectFeatures.positioning ? (
          <InspectRow title="Fixed">
            <OptionControl
              name="positioning"
              value={object.positioning}
              defaultValue={positioning.DEFAULT}
              onChange={this.handleChange}
              options={positioningOptions}
            />
          </InspectRow>
        ) : null}
        {hasNewEditStyles && objectFeatures.width && objectFeatures.height && (
          <SizeControl object={object} onChange={this.handleChange} />
        )}
        {!hasNewEditStyles && objectFeatures.width && objectFeatures.height && (
          <InspectRow labeled bindable title="Size">
            <SingleNumberControl
              label="Width"
              name="width"
              value={object.width}
              onChange={this.handleChange}
            />
            <SingleNumberControl
              disabled={object.type === LABEL || object.type === LINE}
              label="Height"
              name="height"
              value={object.height}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.text && (
          <InspectRow bindable title="Text">
            <TextControl
              name="text"
              value={object.text}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.filename1x && (
          <InspectRow title="Image">
            <ImageControl
              name="filename1x"
              value={object.filename1x}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.inputType && (
          <InspectRow title="Input Type">
            <SelectControl
              name="inputType"
              defaultValue={inputTypes.DEFAULT}
              onChange={this.handleChange}
              options={inputTypeOptions}
              value={object.inputType}
            />
          </InspectRow>
        )}
        {objectFeatures.placeholder && (
          <InspectRow title="Placeholder">
            <TextControl
              name="placeholder"
              value={object.placeholder}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.defaultValue && (
          <InspectRow title="Default Value">
            <TextControl
              name="defaultValue"
              value={object.defaultValue}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.maxLength && (
          <InspectRow title="Max Length">
            <SingleNumberControl
              allowEmpty
              name="maxLength"
              value={object.maxLength}
              onChange={this.handleChange}
              placeholder="None"
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.maxLength && (
          <GenericInspectRow
            title="Max Length"
            className="default-padding-control"
          >
            <TextControl
              gray
              type="number"
              name="maxLength"
              value={object.maxLength}
              onChange={this.handleChange}
              placeholder="None"
            />
          </GenericInspectRow>
        )}
        {hasNewEditStyles && objectFeatures.autoFocus && (
          <GenericInspectRow title="Auto-focus" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.autoFocus ? 'On' : 'Off'}
              <ToggleButton
                value={object.autoFocus}
                onChange={() => {
                  this.handleChange({
                    autoFocus: !object.autoFocus,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.autoFocus && (
          <InspectRow title="Auto-focus">
            <BooleanControl
              standalone
              name="autoFocus"
              value={object.autoFocus}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.padding && (
          <GenericInspectRow
            className="default-padding-control"
            title="Padding"
          >
            <TextControl
              gray
              type="number"
              name="padding"
              value={object.padding}
              onChange={this.handleChange}
            />
          </GenericInspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.padding && (
          <InspectRow bindable title="Padding">
            <SingleNumberControl
              name="padding"
              value={object.padding}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {hasNewEditStyles &&
          (objectFeatures.fontSize ||
            objectFeatures.fontWeight ||
            objectFeatures.color ||
            objectFeatures.fontFamily ||
            objectFeatures.placeholderColor) && (
            <FontControl
              object={object}
              onChange={this.handleChange}
              features={objectFeatures}
              fontFamilyOptions={getFontFamilyOptions(branding)}
              fontWeightOptions={fontWeightOptions}
            />
          )}
        {!hasNewEditStyles &&
          (objectFeatures.fontSize ||
            objectFeatures.fontWeight ||
            objectFeatures.color ||
            objectFeatures.fontFamily) && (
            <>
              <InspectRow labeled title="Font">
                {objectFeatures.fontSize && (
                  <SingleNumberControl
                    label="Size"
                    name="fontSize"
                    value={object.fontSize}
                    onChange={this.handleChange}
                  />
                )}

                {objectFeatures.fontWeight && (
                  <SelectControl
                    label="Weight"
                    name="fontWeight"
                    defaultValue={400}
                    onChange={this.handleChange}
                    options={fontWeightOptions}
                    value={object.fontWeight}
                  />
                )}
              </InspectRow>
              <InspectRow>
                {objectFeatures.fontFamily && (
                  <SelectControl
                    label="Font"
                    name="fontFamily"
                    value={object.fontFamily}
                    onChange={this.handleChange}
                    options={getFontFamilyOptions(branding)}
                  />
                )}
              </InspectRow>
            </>
          )}
        {!hasNewEditStyles &&
          objectFeatures.color &&
          !objectFeatures.placeholderColor && (
            <InspectRow title="Color">
              <ColorPicker
                name="color"
                value={object.color}
                onChange={this.handleChange}
              />
              <div />
            </InspectRow>
          )}
        {!hasNewEditStyles &&
          objectFeatures.color &&
          objectFeatures.placeholderColor && (
            <InspectRow labeled title="Color">
              {objectFeatures.color && (
                <ColorPicker
                  name="color"
                  label="Text"
                  value={object.color}
                  onChange={this.handleChange}
                />
              )}
              {objectFeatures.placeholderColor && (
                <ColorPicker
                  name="placeholderColor"
                  label="Placeholder"
                  value={
                    object.placeholderColor || defaults[INPUT].placeholderColor
                  }
                  onChange={this.handleChange}
                />
              )}
            </InspectRow>
          )}
        {objectFeatures.imageResize && (
          <InspectRow title="Resize">
            <OptionControl
              name="imageResize"
              value={object.imageResize}
              defaultValue={imageResize.COVER}
              onChange={this.handleChange}
              options={imageResizeOptions}
            />
          </InspectRow>
        )}
        {(objectFeatures.backgroundStyle || objectFeatures.backgroundColor) && (
          <>
            {hasNewEditStyles && object.type !== COMPONENT && (
              <BackgroundControl
                key="background-control"
                appId={appId}
                object={object}
                boxedToggle
              />
            )}
            {!hasNewEditStyles && (
              <InspectRow title="Background">
                {objectFeatures.backgroundStyle && (
                  <BooleanControl
                    name="backgroundStyle"
                    selectedValue={backgroundStyle.COLOR}
                    deselectedValue={backgroundStyle.NONE}
                    onChange={this.handleChange}
                    value={object.backgroundStyle}
                  />
                )}
                {objectFeatures.backgroundColor && (
                  <ColorPicker
                    name="backgroundColor"
                    value={object.backgroundColor}
                    onChange={this.handleChange}
                  />
                )}
              </InspectRow>
            )}
            {hasNewEditStyles &&
              object.type === COMPONENT && [
                <GenericInspectRow className="background-color-control">
                  <ColorControl
                    value={object.backgroundColor}
                    name="backgroundColor"
                    onChange={this.handleChange}
                    label="Background Color"
                  />
                </GenericInspectRow>,
                <BackgroundImageControl
                  object={object}
                  onChange={this.handleChange}
                  appId={appId}
                />,
              ]}
            {!hasNewEditStyles && object.type === COMPONENT && (
              <InspectRow title="Image">
                <ImageControl
                  name="backgroundImage"
                  value={object.backgroundImage}
                  onChange={this.handleChange}
                />
              </InspectRow>
            )}
            {!hasNewEditStyles && object.backgroundImage && (
              <>
                <InspectRow title="Image Cropping">
                  <SelectControl
                    name="backgroundSize"
                    value={object.backgroundSize}
                    onChange={this.handleChange}
                    options={[
                      { label: 'Crop Image to Fill', value: 'cover' },
                      {
                        label: 'Show Full Image',
                        value: 'contain',
                      },
                    ]}
                    defaultValue="cover"
                  />
                </InspectRow>
                <InspectRow title="Horizontal Position">
                  <SelectControl
                    name="backgroundPositionX"
                    value={object.backgroundPositionX}
                    onChange={this.handleChange}
                    options={[
                      { label: 'Left', value: 'left' },
                      { label: 'Center', value: 'center' },
                      { label: 'Right', value: 'right' },
                    ]}
                    defaultValue="center"
                  />
                </InspectRow>
                <InspectRow title="Vertical Position">
                  <SelectControl
                    name="backgroundPositionY"
                    value={object.backgroundPositionY}
                    onChange={this.handleChange}
                    options={[
                      { label: 'Top', value: 'top' },
                      { label: 'Center', value: 'center' },
                      { label: 'Bottom', value: 'bottom' },
                    ]}
                    defaultValue="center"
                  />
                </InspectRow>
              </>
            )}
          </>
        )}
        {objectFeatures.tableStyles && (
          <TableDataStyles
            object={object}
            branding={branding}
            onChange={this.handleChange}
          />
        )}
        {!hasNewEditStyles &&
          (objectFeatures.borderStyle ||
            objectFeatures.borderColor ||
            objectFeatures.borderWidth) && (
            <InspectRow labeled title="Border">
              <BooleanControl
                name="borderStyle"
                onChange={this.handleChange}
                value={object.borderStyle}
                selectedValue={borderStyle.SOLID}
                deselectedValue={borderStyle.NONE}
              />
              <ColorPicker
                name="borderColor"
                label="Color"
                value={object.borderColor}
                onChange={this.handleChange}
              />
              <SingleNumberControl
                name="borderWidth"
                label="Size"
                value={object.borderWidth}
                onChange={this.handleChange}
              />
            </InspectRow>
          )}
        {hasNewEditStyles && objectFeatures.borderStyle && (
          <BorderControl
            key="border-control"
            appId={appId}
            object={object}
            boxedToggle
          />
        )}
        {!hasNewEditStyles && objectFeatures.multiline && (
          <InspectRow title="Multi-line">
            <BooleanControl
              standalone
              name="multiline"
              value={Boolean(object.multiline)}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.multiline && (
          <GenericInspectRow title="Multi-line" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.multiline ? 'On' : 'Off'}
              <ToggleButton
                value={object.multiline}
                onChange={() => {
                  this.handleChange({
                    multiline: !object.multiline,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.selectable && (
          <InspectRow title="Copyable">
            <BooleanControl
              standalone
              name="selectable"
              value={object.selectable}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.selectable && (
          <GenericInspectRow title="Copyable" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.selectable ? 'On' : 'Off'}
              <ToggleButton
                value={object.selectable}
                onChange={() => {
                  this.handleChange({
                    selectable: !object.selectable,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.opacity && (
          <InspectRow title="Opacity">
            <SlideControl
              percentage
              name="opacity"
              value={object.opacity}
              defaultValue={1}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.opacity && (
          <SliderControl
            sliderBackground
            onChange={this.handleChange}
            value={object.opacity}
            name="opacity"
            max={1}
            min={0}
            defaultValue={1}
            percentage
            title="Opacity"
          />
        )}
        {!hasNewEditStyles && objectFeatures.masonry && (
          <InspectRow title="Masonry Layout">
            <BooleanControl
              standalone
              name="masonry"
              onChange={this.handleChange}
              value={object.masonry}
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.masonry && (
          <GenericInspectRow
            title="Masonry Layout"
            className="toggle-button-block"
          >
            <div className="toggle-button-block-box">
              {object.masonry ? 'On' : 'Off'}
              <ToggleButton
                value={object.masonry}
                onChange={() => {
                  this.handleChange({
                    masonry: !object.masonry,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {!hideBorderRadius && !hasNewEditStyles && objectFeatures.borderRadius && (
          <InspectRow title="Rounding">
            <SlideControl
              name="borderRadius"
              value={object.borderRadius}
              defaultValue={0}
              onChange={this.handleChange}
              min={0}
              max={this.getMaxBorderRadius(object)}
            />
          </InspectRow>
        )}
        {!hideBorderRadius &&
          hasNewEditStyles &&
          objectFeatures.borderRadius && (
            <SliderControl
              sliderBackground
              onChange={this.handleChange}
              value={object.borderRadius}
              name="borderRadius"
              max={this.getMaxBorderRadius(object)}
              min={0}
              defaultValue={0}
              title="Rounding"
            />
          )}
        {objectFeatures.textAlignment && (
          <InspectRow title="Alignment">
            <TextAlignControl
              name="textAlignment"
              value={object.textAlignment}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.autoWidth && (
          <InspectRow title="Auto-width">
            <BooleanControl
              standalone
              name="autoWidth"
              value={object.autoWidth}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.autoWidth && (
          <GenericInspectRow title="Auto-width" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.autoWidth ? 'On' : 'Off'}
              <ToggleButton
                value={object.autoWidth}
                onChange={() => {
                  this.handleChange({
                    autoWidth: !object.autoWidth,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.lineHeight && (
          <InspectRow title="Line Height">
            <SingleNumberControl
              name="lineHeight"
              value={object.lineHeight}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.shadow && (
          <InspectRow labeled tight title="Shadow">
            <ShadowControl
              name="shadow"
              value={object.shadow}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.shadow && (
          <DefaultShadowControl object={object} onChange={this.handleChange} />
        )}
        {!hasNewEditStyles && objectFeatures.statusBarStyle && (
          <InspectRow title="Status Bar">
            <SelectControl
              name="statusBarStyle"
              value={object.statusBarStyle}
              onChange={this.handleChange}
              options={statusBarStyleOptions}
              defaultValue={statusBarStyles.DARK_CONTENT}
              placeholder="Auto"
            />
          </InspectRow>
        )}
        {hasNewEditStyles && objectFeatures.statusBarStyle && (
          <MenuControl
            displayName="Status Bar"
            name="statusBarStyle"
            value={object.statusBarStyle}
            onChange={this.handleChange}
            options={statusBarStyleOptions}
            placeholder="Auto"
            className="font-family-control"
          />
        )}
        {hasNewEditStyles && objectFeatures.reverseScroll && (
          <GenericInspectRow
            title="Reverse-scroll"
            className="toggle-button-block"
          >
            <div className="toggle-button-block-box">
              {object.reverseScroll ? 'On' : 'Off'}
              <ToggleButton
                value={object.reverseScroll}
                onChange={() => {
                  this.handleChange({
                    reverseScroll: !object.reverseScroll,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {!hasNewEditStyles && objectFeatures.reverseScroll && (
          <InspectRow title="Reverse-scroll">
            <BooleanControl
              standalone
              name="reverseScroll"
              value={object.reverseScroll}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.uri && (
          <InspectRow title="URL">
            <TextControl
              name="uri"
              value={object.uri}
              onChange={this.handleChange}
              placeholder="https://example.com"
            />
          </InspectRow>
        )}
        {objectFeatures.listType && (
          <InspectRow title="List Type">
            <ListTypeControl
              name="listType"
              value={object.listType}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.rowMargin && (
          <InspectRow title="Spacing">
            <SingleNumberControl
              name="rowMargin"
              value={object.rowMargin}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params
  const app = getApp(state, appId)
  const branding = getAppBranding(state, appId)
  const map = getMap(state)
  const list = getObjectList(state)
  const getStateDeviceObject = obj => getDeviceObjectFromState(list, map, obj)
  const hasIndependentBorderRadius = getFeatureFlag(
    state,
    'hasIndependentBorderRadius'
  )
  const hasNewEditStyles = getFeatureFlag(state, 'hasNewEditStyles')

  return {
    appId,
    branding,
    magicLayout: app?.magicLayout,
    getStateDeviceObject,
    hasIndependentBorderRadius,
    hasNewEditStyles,
  }
}

export const DisconnectedInspectBody = InspectBody

export default withRouter(
  connect(mapStateToProps, { updateObjects })(InspectBody)
)
