import ObjectType from '../types/ObjectType'
import ObjectNode from './ObjectNode'
import LayoutContext from './layout/LayoutContext'
import LayoutObject from './layout/LayoutObject'
import { Position } from './styles'

const layoutFixedContent = (
  container: ObjectNode<ObjectType>,
  context: LayoutContext
): LayoutObject<ObjectType>[] => {
  const { device, viewportWidth, viewportHeight, subtreeRootObject } = context

  const fixedChildren = container
    .getChildren()
    .filter(child => child.isVisible(context))
    .filter(child => child.getStyle('position', device) === Position.Fixed)

  const childContext: LayoutContext = {
    ...context,
    // Establish a context relative to the viewport
    containerOffsetX: 0,
    containerOffsetY: 0,
    containerWidth: viewportWidth,
    containerHeight: viewportHeight,
    subtreeRootObject,
  }

  return fixedChildren.map(child => child.layout(childContext))
}

export default layoutFixedContent
