// Returns new object with key k equal to obj1[k] && obj2[k] && ...
export const keywiseAnd = objects => {
  const result = { ...objects[0] }

  for (const obj of objects) {
    for (const key of Object.keys(result)) {
      if (!obj[key]) {
        delete result[key]
      }
    }
  }

  return result
}

const truthy = () => true
const isPureObject = obj => !!obj && obj.constructor === Object

export const deepFilter = (obj, filterFunc, exploreChildren) => {
  if (!exploreChildren) {
    exploreChildren = truthy
  }

  const result = {}

  for (const key of Object.keys(obj)) {
    const val = obj[key]

    if (filterFunc(val, key)) {
      if (isPureObject(val) && exploreChildren(val)) {
        result[key] = deepFilter(val, filterFunc, exploreChildren)
      } else if (key === 'children' && exploreChildren(val)) {
        result[key] = val.map(x => deepFilter(x, filterFunc, exploreChildren))
      } else {
        result[key] = val
      }
    }
  }

  return result
}
