import {
  PrebuiltBuilderContentPlatformTarget,
  PrebuiltBuilderContentStatus,
} from 'ducks/layoutSections/types'
import { useLoadPrebuiltLayoutSections } from 'ducks/layoutSections/hooks'
import { EditorObject } from 'utils/responsiveTypes'
import ImageInput from 'components/Shared/Forms/ImageInput'
import RightPanelCollapsible from '../../../RightPanel/RightPanelCollapsible'
import MenuControl from '../../Libraries/MenuControl'
import BindableTextControl from '../../Libraries/SimpleTextControl'
import useManagePrebuiltLayoutSections from './useManagePrebuiltLayoutSection'

import './PrebuiltLayoutSectionCollapsible.scss'

const Status = PrebuiltBuilderContentStatus

const statusOptions = [
  { label: Status.LISTED, value: Status.LISTED },
  { label: Status.UNLISTED, value: Status.UNLISTED },
]

const Target = PrebuiltBuilderContentPlatformTarget

const platformOptions = [
  { label: 'Both', value: Target.BOTH },
  { label: 'Responsive', value: Target.RESPONSIVE },
  { label: 'Mobile', value: Target.MOBILE },
]

type Props = {
  appId: string
  object: EditorObject
  expanded: boolean
  onClose: () => void
  onExpand: () => void
}

const PrebuiltLayoutSectionCollapsible = (props: Props): JSX.Element => {
  const { expanded, object, appId, onClose, onExpand } = props

  const {
    values,
    getChangeHandler,
    submitting,
    getImageChangeHandler,
    onSubmit,
    targetInputOptions,
    onChangeTargetId,
  } = useManagePrebuiltLayoutSections({ object })
  useLoadPrebuiltLayoutSections()

  let footnote = (
    <>
      <p>You're creating a new </p>
      <p> Prebuilt Layout Section</p>
    </>
  )
  let targetSectionClassName = 'prebuilt-create'
  if (values.targetLayoutSectionId) {
    const id = values.targetLayoutSectionId ?? '---'
    footnote = (
      <>
        <p>You're editing </p>
        <p> {`Prebuilt Layout Section ${id}`}</p>
        <p> {values.name}</p>
      </>
    )
    targetSectionClassName = 'prebuilt-update'
  }

  return (
    <RightPanelCollapsible
      expanded={expanded}
      visible={expanded}
      collapsedClassName="right-panel-prebuilt-layout-section-collapsed"
      collapsibleClassName="right-panel-prebuilt-layout-section"
      onSubmit={onSubmit}
      submitLabel="Save"
      onClose={onClose}
      onExpand={onExpand}
    >
      <MenuControl
        value={values.targetLayoutSectionId}
        className={targetSectionClassName}
        name="targetLayoutSectionId"
        displayName="Target Layout Section"
        options={targetInputOptions}
        onChange={onChangeTargetId}
        rowHeight={44}
      />

      <MenuControl
        value={values.status}
        name="status"
        displayName="Publishing status"
        options={statusOptions}
        onChange={getChangeHandler('status')}
        rowHeight={44}
      />

      <BindableTextControl
        displayName="Rank"
        name="rank"
        inputType="number"
        value={values.rank}
        onChange={getChangeHandler('rank')}
        disabled={submitting}
      />
      <BindableTextControl
        displayName="Name"
        name="name"
        value={values.name}
        onChange={getChangeHandler('name')}
        disabled={submitting}
      />
      <BindableTextControl
        displayName="Categories"
        name="categories"
        value={values.categories}
        onChange={getChangeHandler('categories')}
        disabled={submitting}
      />
      <BindableTextControl
        displayName="Primary Tags"
        name="primaryTags"
        value={values.primaryTags}
        onChange={getChangeHandler('primaryTags')}
        disabled={submitting}
      />
      <BindableTextControl
        displayName="Secondary Tags"
        name="secondaryTags"
        value={values.secondaryTags}
        onChange={getChangeHandler('secondaryTags')}
        disabled={submitting}
      />
      <ImageInput
        isAsset
        displayName="Responsive App Type Thumbnail"
        buttons={['view', 'remove']}
        appId={appId}
        input={{
          value: values.responsiveThumbnail,
          name: 'responsiveThumbnail',
          onChange: getImageChangeHandler('responsiveThumbnail'),
        }}
      />
      <ImageInput
        isAsset
        displayName="Mobile App Type Thumbnail"
        buttons={['view', 'remove']}
        appId={appId}
        input={{
          value: values.mobileThumbnail,
          name: 'mobileThumbnail',
          onChange: getImageChangeHandler('mobileThumbnail'),
        }}
      />
      <MenuControl
        value={values.platformTarget}
        name="platformTarget"
        displayName="Available for: "
        options={platformOptions}
        onChange={getChangeHandler('platformTarget')}
        rowHeight={44}
      />
      <div className="prebuilt-footnote">{footnote}</div>
    </RightPanelCollapsible>
  )
}

export default PrebuiltLayoutSectionCollapsible
