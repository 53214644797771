import { marketplaceAxios } from 'utils/io/http/axios'

const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
const REVOKE_ACCESS_TOKEN = 'REVOKE_ACCESS_TOKEN'

// Reducer
export default (state, action) => {
  if (action.type === `${SET_ACCESS_TOKEN}_FULFILLED`) {
    const { data } = action.payload || {}
    const { userId } = action.meta || {}

    return {
      ...state,
      accessTokens: {
        ...state.accessTokens,
        [userId]: data,
      },
    }
  } else if (action.type === `${REVOKE_ACCESS_TOKEN}_FULFILLED`) {
    const { userId } = action.meta || {}

    return {
      ...state,
      accessTokens: {
        ...state.accessTokens,
        [userId]: null,
      },
    }
  }
}

// THUNKS
export const ensureAccessTokensAreLoaded =
  (userId, sessionToken) => (dispatch, getState) => {
    const state = getState()

    if (!userId || !sessionToken || state.marketplace.accessTokens[userId]) {
      return
    }

    dispatch(fetchAccessTokens(userId, sessionToken))
  }

// Actions
export const fetchAccessTokens = (userId, sessionToken) => {
  const url = `/api/accessToken`

  return {
    type: SET_ACCESS_TOKEN,
    payload: marketplaceAxios.get(url),
    meta: {
      userId,
    },
  }
}

export const generateAccessToken = (userId, sessionToken) => {
  const url = `/api/accessToken`

  return {
    type: SET_ACCESS_TOKEN,
    payload: marketplaceAxios.post(url, {}),
    meta: {
      userId,
    },
  }
}

export const revokeAccessToken = (userId, sessionToken) => {
  const url = `/api/accessToken`

  return {
    type: REVOKE_ACCESS_TOKEN,
    payload: marketplaceAxios.delete(url),
    meta: {
      userId,
    },
  }
}

// Selectors
export const getAccessToken = (state, userId) => {
  const { accessTokens } = state.marketplace

  return accessTokens[userId]
}
