import React, { Component } from 'react'
import { Field } from 'redux-form'
import Accordion from '../../../Shared/Accordion'
import DefaultCheckbox from './DefaultCheckbox'

export default class AdvancedText extends Component {
  render() {
    const { numeric } = this.props

    let config = [
      { name: 'prefix', label: 'Prefix' },
      { name: 'suffix', label: 'Suffix' },
    ]

    if (numeric) {
      config = [
        { name: 'prefixSingular', label: 'Singular Prefix' },
        { name: 'prefix', label: 'Plural Prefix' },
        { name: 'suffixSingular', label: 'Singular Suffix' },
        { name: 'suffix', label: 'Plural Suffix' },
      ]
    }

    return (
      <div className="advanced-text-extension">
        <Accordion
          title="Advanced"
          renderChildren={() => (
            <div>
              <DefaultCheckbox />
              {config.map(({ name, label }) => (
                <div className="action-item-form-row" key={name}>
                  <label>{label}</label>
                  <div className="text-control">
                    <Field
                      component="input"
                      placeholder="(None)"
                      name={`format.${name}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        />
      </div>
    )
  }
}
