import { COMPONENT } from '@adalo/constants'
import { DeviceValue, EditorObject, LayoutAttributes } from './responsiveTypes'
import getDeviceObject from './getDeviceObject'

const getResetDevicePosition = (
  object: EditorObject,
  deviceType: DeviceValue,
  parent: EditorObject
): Partial<LayoutAttributes> => {
  if (!object || !deviceType) {
    return object
  }

  let diffX = 0
  let diffY = 0

  if (parent && parent.type !== COMPONENT) {
    const { x, y } = parent

    const { x: deviceX, y: deviceY } = getDeviceObject(parent, deviceType)

    diffX = x - deviceX
    diffY = y - deviceY
  }

  return {
    x: object.x - diffX,
    y: object.y - diffY,
  }
}

export default getResetDevicePosition
