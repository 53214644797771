import React, { Component } from 'react'
import classNames from 'classnames'
import Icon from '../../Shared/Icon'

export default class Incrementer extends Component {
  getDiff = e => {
    return e.shiftKey ? 10 : 1
  }

  handleClickDown = e => {
    const { onChange, value } = this.props

    return onChange(+value - this.getDiff(e))
  }

  handleClickUp = e => {
    const { onChange, value } = this.props

    return onChange(+value + this.getDiff(e))
  }

  render() {
    const { hasToggle } = this.props

    return (
      <div
        className={classNames('incrementer', { hasToggle: hasToggle === true })}
      >
        <div
          className="incrementer-btn incrementer-increase"
          onMouseDown={this.handleClickUp}
        >
          <Icon type="up-arrow-small" />
        </div>
        <div
          className="incrementer-btn incremeneter-decrease"
          onMouseDown={this.handleClickDown}
        >
          <Icon type="down-arrow-small" />
        </div>
      </div>
    )
  }
}
