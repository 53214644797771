import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getOrganizations,
  getCurrentOrganization,
  fetchOrganizations,
  updateOrganizationSubdomain,
} from 'ducks/organizations'

import { getCurrentUser } from 'ducks/users/index.ts'

import EmptyState from '../../Shared/EmptyState'
import Loading from '../../Shared/Loading'
import Button from '../../Shared/Button'
import SubdomainForm from './SubdomainForm'
import CustomDomainForm from './CustomDomainForm'
import CustomDomainList from './CustomDomainList'
import './Domains.css'

class TeamSettingsSub extends Component {
  state = { editing: false }

  handleChangeSubdomain = async ({ subdomain }) => {
    const { organization, updateOrganizationSubdomain } = this.props

    await updateOrganizationSubdomain(organization.id, subdomain)

    this.toggleEditing()
  }

  toggleEditing = () => {
    this.setState(state => ({
      editing: !state.editing,
    }))
  }

  renderInactive() {
    const { organization } = this.props
    const baseURL = process.env.REACT_APP_WEB_BASE_URL

    return (
      <div className="settings-list-item">
        <div className="team-settings-domain-details">
          <h4>{`${organization.subdomain}.${baseURL}`}</h4>
          <p>Adalo Subdomain</p>
        </div>
        {this.userIsAdmin() && (
          <div className="team-settings-domain-action">
            <Button small outlined teal onClick={this.toggleEditing}>
              Edit
            </Button>
          </div>
        )}
      </div>
    )
  }

  userIsAdmin() {
    const { organization, currentUser } = this.props

    // if the User is an admin (this is an internal Adalo admin)
    if (currentUser.admin) {
      return true
    }

    // if the OrganizationUser is an admin (this is a maker who is an admin for their org)
    const members = organization.Users
    const user = members.find(u => u.id === currentUser.id)

    return user?.OrganizationUser.isAdmin
  }

  render() {
    const { editing } = this.state
    const { organization } = this.props

    const initialValues = {
      subdomain: organization.subdomain,
      id: organization.id,
    }

    return (
      <div className="domain-settings-section2">
        <h3 className="settings-header">Domains</h3>
        {this.userIsAdmin() && (
          <div className="settings-section-separation">
            <CustomDomainForm organizationId={organization.id} />
          </div>
        )}
        <div className="domains-list">
          {editing ? (
            <SubdomainForm
              form={`subdomain-${organization.id}`}
              onSubmit={this.handleChangeSubdomain}
              initialValues={initialValues}
              toggleEditing={this.toggleEditing}
            />
          ) : (
            this.renderInactive()
          )}
          <CustomDomainList organizationId={organization.id} />
        </div>
      </div>
    )
  }
}

class TeamSettings extends Component {
  fetchData = () => {
    const { fetchOrganizations } = this.props

    fetchOrganizations()
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const {
      updateOrganizationSubdomain,
      currentOrganization: org,
      currentUser,
    } = this.props

    return (
      <div className="domain-settings">
        {org ? (
          [
            <TeamSettingsSub
              key={org.id}
              organization={org}
              updateOrganizationSubdomain={updateOrganizationSubdomain}
              onReload={this.fetchData}
              currentUser={currentUser}
            />,
          ]
        ) : (
          <EmptyState>
            <Loading />
          </EmptyState>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  currentOrganization: getCurrentOrganization(state),
  organizations: getOrganizations(state),
})

export default connect(mapStateToProps, {
  updateOrganizationSubdomain,
  fetchOrganizations,
})(TeamSettings)
