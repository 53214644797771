import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import deepEqual from 'deep-equal'
import { dataTypes, sourceTypes } from '@adalo/constants'

import {
  getEndpointOptions,
  getCollectionEndpointLabel,
} from '../../../../utils/apis'

import { buildAPIEndpointSource } from '../../../../utils/sources'
import { getEndpointFieldSourcesSub } from '../../../../ducks/recommender'
import WrappedInput from '../../../Shared/Forms/WrappedInput'
import WrappedSelect from '../../../Shared/Forms/WrappedSelect'

const httpsValidator = url => {
  if (!url) {
    return undefined
  }

  return url.match(/^https:\/\//) ? undefined : 'URLs must be HTTPS'
}

class APISetupForm extends Component {
  noop = e => e.preventDefault()

  getEndpointLabel = option => {
    const { datasource } = this.props

    if (!option) {
      return null
    }

    const { collectionId, endpointId } = option

    return getCollectionEndpointLabel(datasource, collectionId, endpointId)
  }

  getEndpoint = (collectionId, endpointId) => {
    const { datasource } = this.props
    const collection = datasource.collections[collectionId] || {}

    const endpoint = collection.endpoints
      ? collection.endpoints.filter(e => e.id === endpointId)[0]
      : []

    return endpoint
  }

  loginTokenOptions = () => {
    const { datasourceId, datasource } = this.props
    const auth = datasource.auth || {}
    const login = auth.login || {}
    const endpoint = login.endpoint || {}
    const { collectionId, endpointId } = endpoint
    const collection = datasource.collections[collectionId] || {}
    const fields = collection.fields || []

    const endpointSource = buildAPIEndpointSource({
      datasourceId,
      collectionId,
      endpointId,
      endpoint: this.getEndpoint(collectionId, endpointId),
    })

    return getEndpointFieldSourcesSub(endpointSource, fields, [dataTypes.TEXT])
  }

  signupTokenOptions = () => {
    const { datasourceId, datasource } = this.props
    const auth = datasource.auth || {}
    const signup = auth.signup || {}
    const endpoint = signup.endpoint || {}
    const { collectionId, endpointId } = endpoint
    const collection = datasource.collections[collectionId] || {}
    const fields = collection.fields || []

    const endpointSource = buildAPIEndpointSource({
      datasourceId,
      collectionId,
      endpointId,
      endpoint: this.getEndpoint(collectionId, endpointId),
    })

    return getEndpointFieldSourcesSub(endpointSource, fields, [dataTypes.TEXT])
  }

  getFieldSourceLabel = source => {
    if (source.source && source.source.type === sourceTypes.API_FIELD) {
      return `${this.getFieldSourceLabel(source.source)} > ${source.fieldId}`
    }

    return source.fieldId
  }

  render() {
    const { datasource } = this.props
    const endpointOptions = getEndpointOptions(datasource)

    return (
      <form onSubmit={this.noop}>
        <Field
          component={WrappedInput}
          name="baseURL"
          label="Base URL"
          placeholder="https://example.com/api/v1/"
          validate={[httpsValidator]}
        />

        <h2>Authentication</h2>
        <div className="api-collection-auth">
          <p>
            The token will be passed into the <code>Authorization</code> header
            in the <strong>Bearer</strong> format, as follows:
          </p>
          <pre>Authorization: Bearer ey8adfZjs7...</pre>
        </div>
        <div className="api-collection-auth">
          <h3>Login</h3>
          <div className="api-collection-auth-item">
            <label>Endpoint</label>
            <Field
              component={WrappedSelect}
              name="auth.login.endpoint"
              options={endpointOptions}
              comparator={deepEqual}
              getLabel={this.getEndpointLabel}
            />
          </div>
          <div className="api-collection-auth-item">
            <label>Token Field</label>
            <Field
              component={WrappedSelect}
              name="auth.login.tokenSource"
              options={this.loginTokenOptions}
              getLabel={this.getFieldSourceLabel}
              comparator={deepEqual}
            />
          </div>
        </div>
        <div className="api-collection-auth">
          <h3>Signup</h3>
          <div className="api-collection-auth-item">
            <label>Endpoint</label>
            <Field
              component={WrappedSelect}
              name="auth.signup.endpoint"
              options={endpointOptions}
              comparator={deepEqual}
              getLabel={this.getEndpointLabel}
            />
          </div>
          <div className="api-collection-auth-item">
            <label>Token Field</label>
            <Field
              component={WrappedSelect}
              name="auth.signup.tokenSource"
              options={this.loginTokenOptions}
              getLabel={this.getFieldSourceLabel}
              comparator={deepEqual}
            />
          </div>
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: 'apiSetupForm',
})(APISetupForm)
