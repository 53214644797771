import { update } from '@adalo/utils'
import { COMPONENT } from '@adalo/constants'
import { ObjectList } from '../types/ObjectList'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'

export interface MoveScreenOptions {
  screenId: string
  x: number
  y: number
}

export interface MoveScreenInstruction {
  operation: 'moveScreen'
  options: MoveScreenOptions
}

export const moveScreenHandler = (
  state: InstructionState,
  options: MoveScreenOptions
): InstructionState => {
  const { list: oldList, pathMap } = state
  const { screenId, x, y } = options

  const oldObject = getObject(oldList, pathMap, screenId)
  if (oldObject.type !== COMPONENT) {
    throw new Error(`Object is not a Screen. (Screen ID: ${screenId})`)
  }

  const newObject = {
    ...oldObject,
    x,
    y,
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars
  const newList: ObjectList = update(oldList, pathMap[screenId], newObject)

  return {
    ...state,
    list: newList,
  }
}

const moveScreen = (
  screenId: string,
  x: number,
  y: number
): MoveScreenInstruction => ({
  operation: 'moveScreen',
  options: {
    screenId,
    x,
    y,
  },
})

export default moveScreen
