import React, { Component } from 'react'
import MultiSelectMenu from '@protonapp/react-multi-menu'
import deepEqual from 'deep-equal'

const OPTIONS = [
  {
    label: 'iPhone 13 Pro',
    subtitle: '390 × 844',
    value: {
      width: 390,
      height: 844,
      os: 'ios',
      offset_top: 47,
      offset_bottom: 34,
    },
  },
  {
    label: 'iPhone 13 Pro Max',
    subtitle: '428 × 926',
    value: {
      width: 428,
      height: 926,
      os: 'ios',
      offset_top: 47,
      offset_bottom: 34,
    },
  },
  {
    label: 'iPhone 13',
    subtitle: '390 × 844',
    value: {
      width: 390,
      height: 844,
      os: 'ios',
      offset_top: 47,
      offset_bottom: 34,
    },
  },
  {
    label: 'iPhone 13 Mini',
    subtitle: '390 × 844',
    value: {
      width: 390,
      height: 844,
      os: 'ios',
      offset_top: 50,
      offset_bottom: 34,
    },
  },
  {
    label: 'iPhone SE',
    subtitle: '375 × 667',
    value: { width: 375, height: 667, os: 'ios' },
  },
  null,
  {
    label: 'Galaxy S20',
    subtitle: '360 × 740',
    value: { width: 360, height: 740, os: 'android' },
  },
  {
    label: 'Pixel 4',
    subtitle: '412 × 824',
    value: { width: 412, height: 824, os: 'android' },
  },
]

export const DEFAULT_SIZE = OPTIONS[0].value

export default class SizeMenu extends Component {
  getOptions() {
    return OPTIONS
  }

  render() {
    const { size, onChange } = this.props

    return (
      <div className="editor-preview-size-menu">
        <MultiSelectMenu
          className="wrapped-select white"
          options={this.getOptions()}
          value={size}
          comparator={deepEqual}
          onChange={onChange}
        />
      </div>
    )
  }
}
