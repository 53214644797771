export const getRouteParams = path => {
  path = String(path || '')

  const pathFragments = path.split('/')

  const paramFragments = pathFragments.filter(fragment => {
    return fragment.match(/^:/)
  })

  return paramFragments.map(f => f.slice(1))
}
