import React, { Component } from 'react'
import classNames from 'classnames'
import Textarea from 'react-textarea-autosize'

import './WrappedTextarea.scss'

export default class WrappedTextArea extends Component {
  state = {
    focused: false,
  }

  handleKeyDown = e => {
    const { form } = e.currentTarget

    if (e.which === 13 && !e.shiftKey && form) {
      e.preventDefault()
      e.stopPropagation()

      form.dispatchEvent(new Event('submit', { cancelable: true }))
    }
  }

  handleFocus = e => {
    const { input } = this.props
    const { onFocus } = input

    if (onFocus) {
      onFocus(e)
    }

    this.setState({ focused: true })
  }

  handleBlur = e => {
    const { input } = this.props
    const { onBlur } = input

    if (onBlur) {
      onBlur(e)
    }

    this.setState({ focused: false })
  }

  inputRef = input => {
    this.input = input
  }

  render() {
    const { input, meta, label, ...props } = this.props

    const { focused } = this.state

    if (label) {
      const { large, className, prefix, suffix } = this.props

      const { touched, error } = meta

      return (
        <div
          className={classNames('wrapped-textarea', className, {
            'wrapped-textarea-error': touched && error,
            'wrapped-textarea-focused': focused,
            'wrapped-textarea-lg': large,
          })}
        >
          {label && <label>{label}</label>}

          <div className="wrapped-textarea-inner-wrapper">
            {prefix ? (
              <div className="wrapped-textarea-prefix">{prefix}</div>
            ) : null}
            <div className="wrapped-textarea-input">
              <Textarea
                ref={this.inputRef}
                {...props}
                {...input}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKeyDown}
              />
            </div>
            {suffix && <div className="wrapped-textarea-suffix">{suffix}</div>}
          </div>
          {touched && error ? (
            <div className="wrapped-input-error-message">{error}</div>
          ) : null}
        </div>
      )
    }

    return <Textarea {...props} {...input} onKeyDown={this.handleKeyDown} />
  }
}
