import { EditorObject } from 'utils/responsiveTypes'
import { type Branding } from 'utils/colorsTypes'

import GenericInspectRow from './GenericRow'
import ColorDropdown from './ColorDropdown'

import './TableDataStyles.scss'

type FontControlProps = {
  object: EditorObject
  branding: Branding
  onChange: (arg: EditorObject) => void
}

export const TableDataStyles = ({
  object,
  branding,
  onChange,
}: FontControlProps): JSX.Element => (
  <>
    <GenericInspectRow className="table-component-data-styles-control single-column">
      <ColorDropdown
        label="Background"
        value={object.backgroundColor}
        name="backgroundColor"
        branding={branding}
        onChange={onChange}
      />
    </GenericInspectRow>
    <GenericInspectRow className="table-component-data-styles-control">
      <ColorDropdown
        label="Column Heading"
        value={object.tableColumnBackgroundColor}
        name="tableColumnBackgroundColor"
        branding={branding}
        onChange={onChange}
      />
      <ColorDropdown
        label="Header Divider"
        value={object.tableColumnLineColor}
        name="tableColumnLineColor"
        branding={branding}
        onChange={onChange}
      />
    </GenericInspectRow>
    <GenericInspectRow className="table-component-data-styles-control">
      <ColorDropdown
        label="Row Hover"
        value={object.tableRowHoverBackgroundColor}
        name="tableRowHoverBackgroundColor"
        branding={branding}
        onChange={onChange}
      />
      <ColorDropdown
        label="Row Line"
        value={object.tableRowLineColor}
        name="tableRowLineColor"
        branding={branding}
        onChange={onChange}
      />
    </GenericInspectRow>
    <GenericInspectRow className="table-component-data-styles-control">
      <ColorDropdown
        label="Headings Font"
        value={object.tableColumnFontColor}
        name="tableColumnFontColor"
        branding={branding}
        onChange={onChange}
      />
      <ColorDropdown
        label="Row Items Font"
        value={object.tableRowFontColor}
        name="tableRowFontColor"
        branding={branding}
        onChange={onChange}
      />
    </GenericInspectRow>
  </>
)
