import {
  subPath,
  pathLength,
  getObject,
  sortPaths,
  uniqueElements,
} from '@adalo/utils'
import getObjectById from 'ducks/editor/objects/helpers/getObject'
import { getSectionFromSectionElement } from './layoutSections'

export const commonBase = paths => {
  let base = paths[0]

  paths.forEach(path => {
    const length = pathLength(base)

    if (subPath(path, length) !== base) {
      base = commonBase([subPath(base, length - 1), subPath(path, length - 1)])
    }
  })

  return base || ''
}

export const isSelectable = (selection, path) => {
  const basePath = commonBase(selection)

  const commonPath = commonBase([basePath, path])

  return pathLength(commonPath) >= pathLength(path) - 1
}

export const isExpandSelectable = (selection, path) => {
  return isSelectable(selection, subPath(path, pathLength(path) - 1))
}

export const getSelectableObject = (selection, path, minLength = 1) => {
  if (selection.length === 0) {
    return subPath(path, minLength)
  }

  const maxLength = pathLength(path)

  let commonLength = 1

  for (const itm of selection) {
    const common = commonBase([itm, path])
    let length = pathLength(common)

    // If selection is a parent of path, don't auto-expand
    if (pathLength(itm) > length) {
      length += 1
    }

    if (length >= maxLength) {
      return path
    }

    if (length > commonLength) {
      commonLength = length
    }
  }

  commonLength = Math.max(minLength, commonLength)

  return subPath(path, commonLength)
}

export const getDoubleClickSelection = (selection, path) => {
  const parentPath = getSelectableObject(selection, path)

  return subPath(path, pathLength(parentPath) + 1)
}

export const sortSelection = (selection, list, map) => {
  const paths = uniqueElements(selection.map(id => map[id])).filter(p => p)

  const ids = sortPaths(paths)
    .map(p => getObject(list, p)?.id)
    .filter(id => id)

  return ids
}

/**
 *
 * @param {import('ducks/editor/types/ObjectList').ObjectList} list
 * @param {import('ducks/editor/types/ObjectPathMap').ObjectPathMap} pathMap
 * @param {string[]} objectIds
 * @returns {string[]}
 */
export const rewriteSelection = (list, pathMap, objectIds) => {
  const newSelection = []

  for (const id of objectIds) {
    const object = getSectionFromSectionElement(
      list,
      pathMap,
      getObjectById(list, pathMap, id)
    )

    newSelection.push(object.id)
  }

  return newSelection
}
