import React, { useContext } from 'react'

// components
import Button from 'components/Shared/Button'

// Details Context
import { DetailsContext } from './index'

import { PREVIEW_URL } from '../../../../../constants'

const DetailsBanner = () => {
  const { library } = useContext(DetailsContext)
  const { icon, demoAppId, displayName } = library

  const alt = `${displayName} component thumbnail`
  const demoURL = demoAppId ? `${PREVIEW_URL}/${demoAppId}` : null

  return (
    <div className="marketplace-library-details-banner">
      <img src={icon} alt={alt} />
      {demoURL ? (
        <Button
          icon="launch"
          iconSize="small"
          to={demoURL}
          target="_blank"
          outlined
          teal
        >
          Demo
        </Button>
      ) : null}
    </div>
  )
}

export default DetailsBanner
