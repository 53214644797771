export const isMac = () => {
  return navigator.appVersion.indexOf('Mac') !== -1
}

export const commandKeyPressed = e => {
  return e.metaKey || e.ctrlKey

  /*
  if (isMac) {
    return e.metaKey
  } else {
    return e.ctrlKey
  }
  */
}

export const altKeyPressed = e => {
  return e.altKey
}
