import { deepMerge } from '@adalo/utils'
import { EditorObject } from 'utils/responsiveTypes'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'

export interface UpdateElementOptions {
  objectId: string
  changes: Partial<EditorObject>
}

export interface UpdateElementInstruction {
  operation: 'updateElement'
  options: UpdateElementOptions
}

export const updateElementHandler = (
  state: InstructionState,
  options: UpdateElementOptions
): InstructionState => {
  const { list, pathMap } = state
  const { objectId, changes } = options

  return {
    ...state,
    list: updateChangedObject(
      list,
      pathMap,
      deepMerge(getObject(list, pathMap, objectId), changes) as EditorObject,
      undefined
    ),
  }
}

const updateElement = (
  objectId: string,
  changes: Partial<EditorObject>
): UpdateElementInstruction => ({
  operation: 'updateElement',
  options: {
    objectId,
    changes,
  },
})

export default updateElement
