import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { cssTransition, toast } from 'react-toastify'
import { useFlags } from 'flags'

import Modal from 'components/Shared/Modal'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import Button from 'components/Shared/Button'

import type { DatasourceTable } from 'components/Editor/XanoExternalDatabaseModal/lib/user'

import {
  FEATURE_TEMPLATE_DETAILS_MODAL,
  removeModal,
} from 'ducks/editor/modals'
import { Submenu } from 'ducks/recommender'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import {
  FeatureTemplate,
  fetchFeatureTemplateDetails,
  getFeatureTemplateCollections,
} from 'ducks/featureTemplates'
import { getApp } from 'ducks/apps'

import { cloneFeatureTemplateToApp } from 'utils/io'
import { pluralize } from 'utils/strings'

import DetailsHeader from './Header'
import DetailsBanner from './Banner'
import DetailsContent from './Content'

import './styles.scss'

type Props = {
  feature: FeatureTemplate
  appId: string
  datasourceId: string
  linkingObjectId?: string
  onClose: () => void
  origin?: 'FEATURES_MODAL' | 'FEATURES_TAB'
}

const CREATE_NEW = 'create-new'

type CollectionHandler = {
  [id: string]: string
}

type ExistingCollectionHandler = Record<string, string>

type CloningOptions = {
  linkingObjectId?: string
  existingCollections?: ExistingCollectionHandler
  addSampleData?: boolean
}

export type FormValues = {
  primaryUse: string
  audience: string | undefined
}

const ItemDetails = (props: Props): JSX.Element => {
  const {
    feature,
    appId,
    datasourceId,
    linkingObjectId,
    onClose,
    origin = 'FEATURES_MODAL',
  } = props

  const history = useHistory()
  const [step, setStep] = useState(0)
  const [collections, setCollections] = useState<CollectionHandler>({})
  const [existingCollectionDict, setExistingCollections] =
    useState<ExistingCollectionHandler>({})
  const [selectedCollection, setSelectedCollection] = useState<{
    [id: string]: {
      name: string
      fields: string[]
      relationshipFields: string[]
    }
  }>({})
  const [addSampleData, setAddSampleData] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const featureTemplateCollections =
    useSelector(getFeatureTemplateCollections) ?? {}
  const datasource = useSelector(state => getDefaultDatasource(state, appId))

  const app = useSelector(state => getApp(state, appId))
  const { hasNewMobileOnlyApp } = useFlags()
  const mobileOnly =
    hasNewMobileOnlyApp && app.webSettings?.layoutMode === 'mobile'

  const datasourceTables = datasource?.tables
  const authTable = datasource?.auth.table
  const externalTypes = ['api', 'xano']
  const formattedDatasourceTables = Object.entries(datasourceTables)
    .filter(
      ([id, table]) => id !== authTable && !externalTypes.includes(table.type)
    )
    .map(([id, table]) => {
      return {
        label: table.name,
        value: id,
      }
    })

  useEffect(() => {
    if (!feature) {
      const featureId = window.location.pathname.split('/').pop() as string

      dispatch(fetchFeatureTemplateDetails(featureId))
    }
  }, [feature])

  useEffect(() => {
    history.push(`/apps/${appId}/feature-templates/${feature.id}`)

    // Set default collection behavior to create new collections
    setCollections(prev => {
      const collectionsMap: CollectionHandler = {}

      Object.entries(featureTemplateCollections).forEach(([id]) => {
        collections[id] = CREATE_NEW
      })

      return { ...prev, ...collectionsMap }
    })
  }, [])

  const performFeatureTemplateCloning = async (copyWithSampleData: boolean) => {
    setLoading(true)

    try {
      const cloningOptions: CloningOptions = {
        existingCollections: existingCollectionDict,
        addSampleData: copyWithSampleData,
      }

      if (linkingObjectId) {
        cloningOptions.linkingObjectId = linkingObjectId
      }

      const instructionsScreenId = (await cloneFeatureTemplateToApp(
        feature.id,
        appId,
        datasourceId,
        cloningOptions
      )) as string

      localStorage.setItem(
        'showFeatureTemplatesToast',
        JSON.stringify({
          name: feature.name,
          id: feature.id,
          preview: feature.preview,
          instructionsScreenId,
        })
      )
    } catch (error) {
      console.error('Error cloning feature template to app', error)
      dispatch(removeModal(FEATURE_TEMPLATE_DETAILS_MODAL))
      toast(
        <>
          <div>
            <h2>Error adding Feature Template.</h2>
            <p>Try again or contact support if the problem persists.</p>
          </div>
          <Button
            yellow
            outlined
            target="_blank"
            to="https://info.adalo.com/submit-a-support-ticket"
          >
            Get Support
          </Button>
        </>,
        {
          position: 'bottom-right',
          className: 'feature-templates-cta__toast',
          bodyClassName: 'feature-templates-cta__toast-body',
          hideProgressBar: true,
          autoClose: 7500,
          transition: cssTransition({
            enter: 'animate__animated animate__fadeInUp',
            exit: 'animate__animated animate__fadeOutDown',
            collapse: false,
          }),
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    const handleFormSubmission = async () => {
      await performFeatureTemplateCloning(addSampleData)
      localStorage.setItem(
        'shouldResizeFeatureTemplateScreens',
        JSON.stringify({ shouldResize: mobileOnly })
      )
    }

    handleFormSubmission()
      .then(() => window.location.reload())
      .catch(error => {
        console.error('Error cloning feature template to app', error)
      })
  }

  const handleClick = () => {
    const handleClickEvent = async (): Promise<boolean | void> => {
      const appCollectionsLength = Object.keys(datasourceTables).length
      const featureCollectionsLength = Object.keys(
        featureTemplateCollections
      ).length

      // Check if datasources only have users collection
      if (appCollectionsLength <= 1 || featureCollectionsLength === 0) {
        const copyWithSampleData = true

        await performFeatureTemplateCloning(copyWithSampleData)
        localStorage.setItem(
          'shouldResizeFeatureTemplateScreens',
          JSON.stringify({ shouldResize: mobileOnly })
        )
      } else {
        return true
      }
    }

    handleClickEvent()
      .then(shouldStepUp => {
        if (shouldStepUp) {
          return setStep(prev => prev + 1)
        }

        window.location.reload()
      })
      .catch(error => {
        console.error('Error cloning feature template to app', error)
      })
  }

  const handleOnChange = (
    id: string,
    selectCollection: string,
    collection: DatasourceTable
  ) => {
    setCollections(prev => ({
      ...prev,
      [id]: selectCollection,
    }))

    setExistingCollections(prev => {
      const existingCollection = Object.entries(prev).filter(
        ([, value]) => value !== id
      )

      if (selectCollection !== CREATE_NEW) {
        return {
          ...Object.fromEntries(existingCollection),
          [selectCollection]: id,
        }
      }

      return Object.fromEntries(existingCollection)
    })

    if (selectCollection === CREATE_NEW) {
      return
    }

    setSelectedCollection(prev => ({
      ...prev,
      [id]: {
        name: datasourceTables[selectCollection]?.name as string,
        relationshipFields: Object.values(collection.fields || {})
          .filter(field => typeof field.type === 'object')
          .map(field => field.name),
        fields: Object.values(collection.fields || {})
          .filter(
            field =>
              typeof field.type !== 'object' &&
              // Check if field already exists in datasource
              !Object.values(
                datasourceTables[selectCollection]?.fields as Record<
                  string,
                  { name: string; type: string }
                >
              ).some(f => f.name === field.name && f.type === field.type)
          )
          .map(field => {
            const fieldType = field.type as string

            return `${field.name} - ${fieldType
              .charAt(0)
              .toUpperCase()
              .concat(fieldType.slice(1))}`
          }),
      },
    }))
  }

  useEffect(() => {
    Object.entries(featureTemplateCollections).forEach(([id, collection]) => {
      const matchedTable = formattedDatasourceTables.find(table => {
        if (collection.name === table.label) {
          return true
        }

        return false
      })

      if (matchedTable) {
        handleOnChange(id, matchedTable.value, collection)
      }
    })
  }, [featureTemplateCollections])

  const getOptionsLabels = (table: string, id: string) => {
    if (existingCollectionDict[table] === id) {
      return `Use Existing '${datasourceTables[table]?.name ?? ''}' Collection`
    }

    if (table === CREATE_NEW) {
      return `Create New '${
        featureTemplateCollections[id]?.name ?? ''
      }' Collection`
    }

    return datasourceTables[table]?.name
  }

  const handleBack = () => {
    onClose()
    history.goBack()
  }

  const getReadableCount = (
    label: string,
    count: number,
    prefix = '',
    suffix = ''
  ) => {
    return `${count} ${prefix}${count > 1 ? pluralize(label) : label}${suffix}`
  }

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Modal.Header>
              <DetailsHeader
                feature={feature}
                button={
                  <Button teal onClick={handleClick} loading={loading}>
                    {loading ? 'Adding...' : 'Add to app'}
                  </Button>
                }
              />
            </Modal.Header>
            <Modal.Content>
              <DetailsBanner feature={feature} appId={appId} />
              <DetailsContent feature={feature} />
            </Modal.Content>
            <Modal.Actions
              leftButtons={
                <Modal.Button text black onClick={handleBack}>
                  Back
                </Modal.Button>
              }
            />
          </>
        )
      case 1:
        return (
          <>
            <form onSubmit={handleSubmit}>
              <Modal.Header
                title={
                  <>
                    This Template Has{' '}
                    {Object.keys(featureTemplateCollections).length} Database
                    Collections
                    <p>How do you want to connect your data?</p>
                  </>
                }
              />
              <Modal.Content>
                {Object.entries(featureTemplateCollections).map(
                  ([id, collection]) => (
                    <div key={id}>
                      <MenuControl
                        displayName={collection.name}
                        getLabel={(table: string) =>
                          getOptionsLabels(table, id)
                        }
                        onChange={({
                          selectCollection,
                        }: {
                          selectCollection: string
                        }) => handleOnChange(id, selectCollection, collection)}
                        name="selectCollection"
                        value={collections[id] ?? CREATE_NEW}
                        options={[
                          {
                            label: `Create New '${collection.name}' Collection`,
                            value: CREATE_NEW,
                          },
                          new Submenu(
                            'Use Existing Collection...',
                            formattedDatasourceTables.filter(table => {
                              return (
                                existingCollectionDict[table.value] === id ||
                                !existingCollectionDict[table.value]
                              )
                            }),
                            null
                          ),
                        ]}
                      />
                      {collections[id] && collections[id] !== CREATE_NEW ? (
                        <span className="selected-collection-properties">
                          {
                            [
                              ...(selectedCollection[id]?.fields || []),
                              ...(selectedCollection[id]?.relationshipFields ||
                                []),
                            ].length
                          }{' '}
                          Properties will be added to existing `
                          {selectedCollection[id]?.name}` Collection.
                          <Tooltip
                            className="selected-collection-properties__tooltip"
                            tooltip={
                              <div>
                                {selectedCollection[id]?.fields?.length ? (
                                  <>
                                    <span>
                                      {getReadableCount(
                                        'Property',
                                        selectedCollection[id]?.fields
                                          ?.length as number,
                                        'New ',
                                        ':'
                                      )}
                                    </span>
                                    <ul>
                                      {selectedCollection[id]?.fields.map(
                                        field => (
                                          <li key={field}>{field}</li>
                                        )
                                      )}
                                    </ul>
                                  </>
                                ) : null}
                                {selectedCollection[id]?.relationshipFields
                                  ?.length ? (
                                  <>
                                    <span>
                                      {getReadableCount(
                                        'Relationship',
                                        selectedCollection[id]
                                          ?.relationshipFields
                                          ?.length as number,
                                        '',
                                        ' (if needed):'
                                      )}
                                    </span>
                                    <ul>
                                      {selectedCollection[
                                        id
                                      ]?.relationshipFields.map(field => (
                                        <li key={field}>{field}</li>
                                      ))}
                                    </ul>
                                  </>
                                ) : null}
                              </div>
                            }
                          >
                            <Icon type="info" color="darkerGray" small />
                          </Tooltip>
                        </span>
                      ) : null}
                    </div>
                  )
                )}

                <div className="include-sample-data">
                  <p>Include sample records</p>
                  <ToggleButton
                    value={addSampleData}
                    onChange={(e: boolean) => setAddSampleData(e)}
                  />
                </div>
              </Modal.Content>

              <Modal.Actions
                leftButtons={
                  <Modal.Button text black onClick={onClose}>
                    Back
                  </Modal.Button>
                }
              >
                <Modal.Button teal type="submit" loading={loading}>
                  {loading ? 'Adding...' : 'Add Feature'}
                </Modal.Button>
              </Modal.Actions>
            </form>
          </>
        )
      default:
        return null
    }
  }

  const className = useMemo(() => {
    return classNames({
      'feature-item__details': step === 0,
      'feature-item__collections': step === 1,
    })
  }, [step])

  // Having the modal wrapper when coming from features tab adds a double wrapper
  // This way we can avoid that
  if (origin === 'FEATURES_TAB') {
    return <div className={className}>{renderContent()}</div>
  }

  return (
    <>
      <Modal
        size={step === 0 ? 'lg' : 'md'}
        onClose={onClose}
        smallPadding={step === 0}
        className={className}
      >
        {renderContent()}
      </Modal>
    </>
  )
}

export default ItemDetails
