// Disable eslint rules for this file because socket.io is not typed on v2.0.3
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect, useState } from 'react'
import { getAppId } from 'utils/location'
import { joinAppRoom } from 'utils/io'
import { socket } from 'utils/io/socket'
import { getInitials, stringToColor } from './utils'

const APP_ROOM_DATA_EVENT = 'appRoomSessions'
const APP_ROOM_UPDATE_EVENT = 'appRoomSessionsUpdated'

export type SessionData = {
  userId: number
  sessionId: string
  name?: string
  email?: string
  isCurrentSession?: boolean
  accentColor?: string
  initials?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseAppRoomSessionData = (data: any, currentSessionId: string) => {
  if (!data || typeof data !== 'object' || !Array.isArray(data.sessions)) {
    console.warn(`Failed to parse app room session data: `, data)

    return null
  }

  const sessions = data.sessions
    .map((session: SessionData) => {
      if (!session.sessionId) {
        console.warn(`Failed to parse app room sessionData`)

        return null
      }

      const isCurrentSession = session.sessionId === currentSessionId
      const accentColor = stringToColor(session.sessionId)
      const initials = getInitials(session.name)

      return { ...session, isCurrentSession, accentColor, initials }
    })
    .filter(Boolean) as SessionData[]

  return sessions
}

type AppRoomSession = {
  currentSessionId: string
  appRoomSessions?: SessionData[] | undefined
  refetch: () => void
}

export const useAppRoomSessions = (): AppRoomSession => {
  const [listenerReady, setListenerReady] = useState(false)
  const [appRoomSessions, setAppRoomSessions] = useState<SessionData[]>()

  const appId = getAppId(window.location)

  const currentSessionId = socket.id as string

  const refetch = useCallback(() => {
    socket.emit('getAppRoomSessions', { appId })
  }, [appId])

  useEffect(() => {
    const onAppSessionsMessage = (data: unknown) => {
      const sessions = parseAppRoomSessionData(data, currentSessionId)

      if (sessions) {
        setAppRoomSessions(sessions)
      }
    }

    const onRoomUpdatedMessage = (data?: { appId?: string }) => {
      if (data?.appId === appId) {
        refetch()
      } else if (data) {
        // Should never happen
        console.warn(`Got room update event from different app: `, data)
      }
    }

    socket.on(APP_ROOM_DATA_EVENT, onAppSessionsMessage)
    socket.on(APP_ROOM_UPDATE_EVENT, onRoomUpdatedMessage)
    setListenerReady(true)

    return () => {
      socket.off(APP_ROOM_DATA_EVENT, onAppSessionsMessage)
      setListenerReady(false)
    }
  }, [refetch])

  useEffect(() => {
    if (listenerReady) {
      joinAppRoom(appId)
      setTimeout(() => refetch(), 3000)
    }
  }, [appId, listenerReady, refetch])

  return { appRoomSessions, currentSessionId, refetch }
}
