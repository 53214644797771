import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { getMap, getObjectList, State } from 'ducks/editor/objects'
import { getSelection, setSelection } from 'ducks/editor/selection'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'

const useClearOtherScreensSelection = (
  screenId: string | undefined
): (() => void) => {
  const dispatch = useDispatch()
  const selection = useSelector((state: State) => getSelection(state))
  const list = useSelector((state: State) => getObjectList(state))
  const pathMap = useSelector((state: State) => getMap(state))

  const clearSelection = useCallback(() => {
    if (screenId && selection && selection.length > 0) {
      const filteredSelection = selection.filter(id => {
        if (!id) {
          return false
        }
        const parentId = getParentScreen(list, pathMap, id)?.id

        return parentId === screenId
      })

      if (!isEqual(filteredSelection, selection)) {
        dispatch(setSelection(filteredSelection))
      }
    }
  }, [JSON.stringify(selection), screenId, list, pathMap])

  return clearSelection
}

export default useClearOtherScreensSelection
