import { Dispatch } from 'react'

import { adaloBackendAxios, isAxiosError } from 'utils/io/http/axios'

import { RESET } from '..'

export const FETCH_AUTH_SIGNUP = 'FETCH_AUTH_SIGNUP'
export const FETCH_AUTH_LOGIN = 'FETCH_AUTH_LOGIN'
export const FETCH_AUTH_ME = 'FETCH_AUTH_ME'

type SignupResponse = {
  requestBody: Record<string, string | number>
  responseBody: Record<string, string | number>
  missingRequestFields: string[]
  missingResponseFields: string[]
}

type SignupDispatch = {
  type: string
  payload: SignupResponse
}

type LoginResponse = {
  missingRequestFields: string[]
  missingResponseFields: string[]
}

type LoginDispatch = {
  type: string
  payload: LoginResponse | null
}

type AuthMeResponse = {
  missingRequestFields: string[]
  missingResponseFields: string[]
  status: number
}

type AuthMeDispatch = {
  type: string
  payload: AuthMeResponse | null
}

// Actions
export const validateAuthSignup =
  (url: string) =>
  async (dispatch: Dispatch<SignupDispatch>): Promise<void> => {
    try {
      const result = await adaloBackendAxios.get<SignupResponse>(
        `/external-databases/xano/validate/signup?${new URLSearchParams({
          url,
        }).toString()}`
      )

      return dispatch({
        type: FETCH_AUTH_SIGNUP,
        payload: result.data,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        return dispatch({
          type: FETCH_AUTH_SIGNUP,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          payload: {
            ...error.response?.data,
            missingEndpoint: error.response?.status === 404,
          },
        })
      }
    }
  }

export const validateAuthLogin =
  (url: string) =>
  async (dispatch: Dispatch<LoginDispatch>): Promise<void> => {
    try {
      const result = await adaloBackendAxios.get<LoginResponse>(
        `/external-databases/xano/validate/login?${new URLSearchParams({
          url,
        }).toString()}`
      )

      return dispatch({
        type: FETCH_AUTH_LOGIN,
        payload: result.data,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        return dispatch({
          type: FETCH_AUTH_LOGIN,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          payload: {
            ...error.response?.data,
            missingEndpoint: error.response?.status === 404,
          },
        })
      }
    }
  }

export const validateAuthMe =
  (url: string) =>
  async (dispatch: Dispatch<AuthMeDispatch>): Promise<void> => {
    try {
      await adaloBackendAxios.get<AuthMeResponse>(
        `/external-databases/xano/validate/user-auth?url=${url}`
      )

      return dispatch({
        type: FETCH_AUTH_ME,
        payload: null,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        return dispatch({
          type: FETCH_AUTH_ME,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          payload: {
            ...error.response?.data,
            missingEndpoint: error.response?.status === 404,
          },
        })
      }
    }
  }

export const resetAuth =
  () =>
  (dispatch: Dispatch<AuthMeDispatch>): void => {
    return dispatch({
      type: RESET,
      payload: null,
    })
  }
