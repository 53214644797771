import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, formValueSelector, change } from 'redux-form'

import { capitalize } from 'utils/strings'
import { getBindingLabel } from 'utils/bindings'
import { GroupedAccordion } from 'components/Shared/Accordion'
import Button from 'components/Shared/Button'
import { IconButton } from 'components/Shared/Icon'
import ControlField from 'components/Shared/Forms/ControlField'
import BindableTextControl from 'components/Shared/BindableTextControl'
import { FORM_NAME } from './Form'
import MenuControl from '../../../Editor/Inspect/Libraries/MenuControl'
import SimpleTextControl from '../../../Editor/Inspect/Libraries/SimpleTextControl'
import Literal from './Literal'

import {
  DEPTH_LEVEL_MIN,
  DEPTH_LEVEL_MAX,
  getReadableDepthForLevel,
  isLevelEnabled,
  getResultsKeyPropsForLevel,
  removeLevelAndConsolidateFormFields,
} from './utils'

import './Endpoints.scss'

const ENDPOINT_NAMES = {
  list: 'Get All',
  detail: 'Get One',
}

const METHOD_OPTIONS = [
  { label: 'GET', value: 'get' },
  { label: 'POST', value: 'post' },
  { label: 'PUT', value: 'put' },
  { label: 'PATCH', value: 'patch' },
  { label: 'DELETE', value: 'delete' },
]

const getLevels = actionName => {
  const formFields = useSelector(state =>
    formValueSelector(FORM_NAME)(state, 'endpoints')
  )

  const dispatch = useDispatch()

  const levels = []

  for (let level = DEPTH_LEVEL_MIN; level <= DEPTH_LEVEL_MAX; level += 1) {
    const previousLevelEnabled = isLevelEnabled(
      actionName,
      formFields,
      level - 1
    )

    if (level === DEPTH_LEVEL_MIN || previousLevelEnabled) {
      levels.push(
        <Field
          key={level}
          component={ResultsKey}
          {...getResultsKeyPropsForLevel(actionName, level)}
          onRemoveLevel={removeLevel =>
            dispatch(
              change(
                FORM_NAME,
                'endpoints',
                removeLevelAndConsolidateFormFields(
                  formFields,
                  actionName,
                  removeLevel
                )
              )
            )
          }
        />
      )
    }
  }

  return levels
}

const ResultsKey = props => {
  const {
    input: { value, onChange },
    fieldName,
    level,
    onRemoveLevel,
  } = props

  const handleRemove = () => onRemoveLevel(level)
  const handleAdd = () => onChange('enabled')

  const readableLevel = getReadableDepthForLevel(level)

  if (value) {
    return (
      <div className="results-key-wrapper">
        <ControlField
          name={fieldName}
          component={SimpleTextControl}
          displayName={`${readableLevel} Results Key`}
          placeholder={`${readableLevel}-level results key`}
        />
        <IconButton type="trash-small" onClick={handleRemove} />
      </div>
    )
  }

  return (
    <Button orange listAddButton onClick={handleAdd} icon="plus-small">
      Add {readableLevel} Results Key
    </Button>
  )
}

const EndpointItem = props => {
  const { name } = props

  const levels = getLevels(name)

  return (
    <div className="data-accordion-item">
      <GroupedAccordion
        boxed
        group="new-api-endpoints"
        color="orange"
        itemId={name}
        title={
          <div>
            <p>{ENDPOINT_NAMES[name] || capitalize(name)}</p>
            <p className="data-accordion-subtitle">
              <Field
                name={`endpoints.${name}.method`}
                component={Literal}
                formatter={method => method.toUpperCase()}
              />{' '}
              <Field
                name={`endpoints.${name}.url`}
                component={Literal}
                formatter={getBindingLabel}
              />
            </p>
          </div>
        }
        renderChildren={() => (
          <div className="form-inspect-field-wrapper">
            <ControlField
              name={`endpoints.${name}.method`}
              options={METHOD_OPTIONS}
              component={MenuControl}
              displayName="Method"
            />
            <ControlField
              name={`endpoints.${name}.url`}
              component={BindableTextControl}
              displayName="URL"
              hideDisplayName
              externalAPIField
              disableFormulas
              enableTestValue
              fromModal
            />
            <ControlField
              name={`endpoints.${name}.resultsKey`}
              component={SimpleTextControl}
              displayName="Results Key"
              placeholder={
                name === 'list'
                  ? 'Is the results array nested under some key?'
                  : 'Is the result object nested under some other key?'
              }
            />
            {levels}
          </div>
        )}
      />
    </div>
  )
}

const Endpoints = () => {
  return (
    <div>
      <EndpointItem name="list" />
      <EndpointItem name="detail" />
      <EndpointItem name="create" />
      <EndpointItem name="update" />
      <EndpointItem name="delete" />
    </div>
  )
}

export default Endpoints
