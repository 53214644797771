import { memo } from 'react'
import deepEqual from 'deep-equal'
import { isEqual } from 'lodash'
import {
  Binding,
  dataTypes,
  formatterOptions,
  numberFormats,
} from '@adalo/constants'
import { THEMES } from 'constants.js'
import { currencies, DEFAULT_CURRENCY } from 'utils/currency'

import type { TableField } from 'utils/responsiveTypes'
import MenuControl from '../MenuControl'
import {
  IMAGE_RATIO_OPTIONS,
  LOCATION_FORMAT_OPTIONS,
  TEXT_LENGTH_OPTIONS,
} from './consts'

type FormatterOptions = keyof typeof formatterOptions

interface TableMenuProps {
  field: TableField
  updateBindingType: (option: Binding['format']) => void
}

const CURRENCY_TYPES = [
  numberFormats.CURRENCY as string,
  numberFormats.ABBREVIATED_CURRENCY as string,
]

const TableMenu = memo(
  ({ field, updateBindingType }: TableMenuProps): JSX.Element | null => {
    const options =
      formatterOptions[field.binding.source.dataType as FormatterOptions]

    const bindingType = field.binding?.format?.type ?? ''
    const bindingCurrency =
      field.binding?.format?.[numberFormats.CURRENCY] ??
      field.binding?.format?.[numberFormats.ABBREVIATED_CURRENCY]
    const bindingTextLength = field.binding?.format?.textLength
    const bindingImageRatio = field.binding?.format?.imageRatio
    const bindingLocationFormat = field.binding?.format?.locationFormat

    switch (field.binding.source.dataType) {
      case dataTypes.NUMBER:
        return (
          <>
            <MenuControl
              options={options}
              name="type"
              displayName="Number Format"
              value={bindingType}
              onChange={updateBindingType}
              comparator={deepEqual}
              placeholder="Default"
              menuTheme={THEMES.DATA}
            />
            {CURRENCY_TYPES.includes(bindingType) && (
              <MenuControl
                options={currencies}
                name="currency"
                displayName="Currency Type"
                value={bindingCurrency}
                onChange={updateBindingType}
                comparator={deepEqual}
                placeholder={DEFAULT_CURRENCY}
                menuTheme={THEMES.DATA}
              />
            )}
          </>
        )
      case dataTypes.TEXT:
        return (
          <MenuControl
            options={TEXT_LENGTH_OPTIONS}
            name="textLength"
            displayName="Text Length"
            value={bindingTextLength}
            onChange={updateBindingType}
            comparator={deepEqual}
            placeholder="Single Line"
            menuTheme={THEMES.DATA}
          />
        )
      case dataTypes.IMAGE:
        return (
          <MenuControl
            options={IMAGE_RATIO_OPTIONS}
            name="imageRatio"
            displayName="Image Aspect Ratio"
            value={bindingImageRatio}
            onChange={updateBindingType}
            comparator={deepEqual}
            placeholder="Landscape"
            menuTheme={THEMES.DATA}
          />
        )
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return (
          <MenuControl
            options={options}
            name="type"
            displayName="Date Format"
            value={bindingType}
            onChange={updateBindingType}
            comparator={deepEqual}
            placeholder="Relative"
            menuTheme={THEMES.DATA}
          />
        )
      case dataTypes.LOCATION:
        return (
          <MenuControl
            options={LOCATION_FORMAT_OPTIONS}
            name="locationFormat"
            displayName="Location Format"
            value={bindingLocationFormat}
            onChange={updateBindingType}
            comparator={deepEqual}
            placeholder="Full Address"
            menuTheme={THEMES.DATA}
          />
        )
      default:
        return null
    }
  },
  isEqual
)

export default TableMenu
