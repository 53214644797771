import { dataTypes } from '@adalo/constants'

export const DEFAULT_COLUMN_WIDTH = 180

export const getFieldLabel = (table, fieldId, hasExposedRecordId = false) => {
  const field = getTableField(table, fieldId, hasExposedRecordId)

  return (field && field.name) || fieldId
}

export const getTableField = (table, fieldId, hasExposedRecordId) => {
  if (hasExposedRecordId && fieldId === 'id') {
    return { name: 'ID', type: dataTypes.NUMBER }
  }
  if (fieldId === 'created_at') {
    return { name: 'Created Date', type: dataTypes.DATE }
  } else if (fieldId === 'updated_at') {
    return { name: 'Updated Date', type: dataTypes.DATE }
  }

  const field = table?.fields?.[fieldId]

  return field
}
