import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DocumentEvents from 'react-document-events'
import { throttle } from 'lodash'
import { sameElements } from '@adalo/utils'

import { unScaleRect } from '../../../utils/zoom'
import { getSelectionFromRect } from '../../../utils/geometry'
import { selectVisible, getZoom } from '../../../ducks/editor/objects'
import { getSelection, setSelection } from '../../../ducks/editor/selection'

import {
  startDrag,
  drag,
  endDrag,
  getMarquee,
} from '../../../ducks/editor/marquee'

import Marquee from './Marquee'

class CanvasBackdrop extends Component {
  constructor(props) {
    super(props)

    this.setMarqueeSelection = throttle(this.setMarqueeSelection, 50, {
      leading: false,
    })
  }

  handleMouseDown = e => {
    const { history, match, startDrag, setSelection } = this.props

    if (e.ctrlKey) {
      return
    }

    startDrag([e.clientX, e.clientY])

    if (!e.shiftKey) {
      const { appId } = match.params
      history.push(`/apps/${appId}/screens`)
    }

    setSelection([], e.shiftKey)
  }

  handleMouseMove = e => {
    const { marquee, drag } = this.props

    if (!marquee) {
      return
    }

    this.setMarqueeSelection(e.shiftKey)

    drag([e.clientX, e.clientY])
  }

  handleMouseUp = e => {
    const { marquee, endDrag } = this.props

    if (!marquee) {
      return
    }

    endDrag()
  }

  setMarqueeSelection = shiftKey => {
    const { marqueeSelection, marquee, selection, setSelection } = this.props

    if (!marquee) {
      return
    }

    if (!sameElements(marqueeSelection, selection)) {
      if (marqueeSelection.length === 0) {
        setSelection(null, shiftKey)
      } else {
        setSelection(marqueeSelection, shiftKey)
      }
    }
  }

  render() {
    const { marquee } = this.props

    return (
      <div className="canvas-backdrop" onMouseDown={this.handleMouseDown}>
        {marquee && <Marquee {...marquee} />}
        {marquee && (
          <DocumentEvents
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  marquee: getMarquee(state),
  selection: getSelection(state),
  marqueeSelection: getSelectionFromRect(
    selectVisible(state),
    unScaleRect(getMarquee(state), getZoom(state))
  ),
})

export default withRouter(
  connect(mapStateToProps, {
    setSelection,
    startDrag,
    drag,
    endDrag,
  })(CanvasBackdrop)
)
