/* eslint-disable no-param-reassign */
import { FIXED_POSITION_COMPONENTS, getAppComponent } from 'utils/libraries'
import { EditorObject, LayoutAttributes } from 'utils/responsiveTypes'

interface Config {
  resizeX?: boolean
  resizeY?: boolean
}

export function removeUnusedSize<
  T extends Partial<LayoutAttributes> | undefined
>(object: EditorObject, deviceObject: T): T {
  const { libraryName, componentName } = object

  if (FIXED_POSITION_COMPONENTS.includes(libraryName as string)) {
    return deviceObject
  }

  const config = (getAppComponent(null, libraryName, componentName) ??
    {}) as Config

  const resizeY = !!config?.resizeY
  const resizeX = 'resizeX' in config ? config?.resizeX : true

  if (deviceObject && !resizeX && !resizeY) {
    delete deviceObject.height
    delete deviceObject.width
  }

  return deviceObject
}
