import React, { Component } from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import { getLabel } from '../../../../utils/sources'
import { getSources } from '../../../../ducks/recommender'
import SelectableInput from '../../../Shared/Forms/SelectableInput'

class ParamItem extends Component {
  render() {
    const { field, param, options, label } = this.props

    return (
      <div className="action-item-form-row">
        <label>{param.name}</label>
        <Field
          component={SelectableInput}
          className="small white"
          placeholder="Empty"
          name={`${field}.options.params.${param.id}`}
          options={options}
          getLabel={() => label}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  options: getSources(
    state,
    props.appId,
    props.componentId,
    props.objectId,
    [props.param.type],
    undefined,
    props.actionId,
    props.reference
  ),
  label:
    props.value && getLabel(state, props.value, props.appId, props.componentId),
})

export default connect(mapStateToProps)(ParamItem)
