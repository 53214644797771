interface NameObject {
  firstName: string
  lastName: string
}

export const parseNames = (input: string): NameObject => {
  const fullName = input || ''
  let firstName = ''
  let lastName = ''
  let secondLastName = ''

  if (fullName.length > 0) {
    const nameTokens =
      fullName.match(
        /[A-ZÁ-ÚÑÜ][a-zá-úñü]+|([aeodlsz]+\s+)+[A-ZÁ-ÚÑÜ][a-zá-úñü]+/g
      ) ||
      fullName.split(' ') ||
      []

    if (nameTokens.length > 3) {
      firstName = nameTokens.slice(0, 2).join(' ')
    } else {
      firstName = nameTokens.slice(0, 1).join(' ')
    }

    if (nameTokens.length > 2) {
      lastName = nameTokens.slice(-2, -1).join(' ')
      secondLastName = nameTokens.slice(-1).join(' ')
    } else {
      lastName = nameTokens.slice(-1).join(' ')
      secondLastName = ''
    }
  }

  if (secondLastName.length > 0) {
    lastName = lastName.concat(' ', secondLastName)
  }

  return { firstName, lastName }
}
