import Button from 'components/Shared/Button'

type Props = {
  onClick: () => void
}

const MarketplaceCallToAction = ({ onClick }: Props): JSX.Element => {
  return (
    <div className="feature-templates-cta__collapsed">
      <Button
        listAddButton
        icon="marketplace"
        onClick={onClick}
        data-adalo-id="marketplace-cta"
      >
        View Component Marketplace
      </Button>
    </div>
  )
}

export default MarketplaceCallToAction
