import lodashMemoize from 'lodash/memoize'

const defaultKeyGetter = (...args) =>
  args.map(JSON.stringify).join(',') + Math.random()

export const memoize = (func, getKey) => {
  getKey = getKey || defaultKeyGetter

  return lodashMemoize(func, getKey)
}
