import { mergeReducers, sort, traverse, optimize } from '@adalo/utils'
import moment from 'moment'
import { get } from 'lodash'

import { COMPONENT_INSTANCE } from '@adalo/constants'

import {
  requestAll,
  requestAllOrgApps,
  requestApp as requestSingle,
  deleteApp as requestDeleteApp,
  updateApp as requestUpdateApp,
  bulkUpdateApps as requestBulkUpdateApps,
  requestTemplates,
  requestTemplateBranding,
  requestAllTeamApps,
} from 'utils/io'

import { getLibraryDependencies } from 'utils/libraries'
import { defaultBranding } from 'utils/colors'
import { buildLinkMap } from 'utils/links'

import datasourcesReducer from './datasources'
import tableLayoutReducer from './tableLayout'
import paramsReducer from './params'
import buildsReducer from './builds'
import trialReducer from './trial'
import { DELETE_OBJECT } from '../editor/objects'
import { BEFORE, DURING, AFTER } from '../../constants'

const LOAD_APP = Symbol('LOAD_APP')
const LOAD_COMPONENT = Symbol('LOAD_COMPONENT')
const LOAD_APPS_LIST = Symbol('LOAD_APPS_LIST')
const LOAD_ORG_APPS_LIST = Symbol('LOAD_ORG_APPS_LIST')
const LOAD_TEAM_APPS_LIST = Symbol('LOAD_TEAM_APPS_LIST')
const SET_LOADING_TEMPLATE_BRANDING = Symbol('SET_LOADING_TEMPLATE_BRANDING')
const SET_LOADING_TEMPLATES = Symbol('SET_LOADING_TEMPLATES')
const LOAD_TEMPLATE_BRANDING = Symbol('LOAD_TEMPLATE_BRANDING')
const LOAD_TEMPLATES_LIST = Symbol('LOAD_TEMPLATES_LIST')
const UPDATE_APP = Symbol('UPDATE_APP')
const DELETE_APP = Symbol('DELETE_APP')
const REQUEST_ALL = Symbol('REQUEST_ALL')
const REQUEST_ALL_ORG_APPS = Symbol('REQUEST_ALL_ORG_APPS')
const REQUEST_ALL_TEAM_APPS = Symbol('REQUEST_ALL_TEAM_APPS')
const REQUEST_APP = Symbol('REQUEST_APP')
const UNSET_LAUNCH_COMPONENT = Symbol('UNSET_LAUNCH_COMPONENT')
const SET_APP_LIBRARIES = 'SET_APP_LIBRARIES'
const RESET_TEAM_APPS_LIST = Symbol('RESET_TEAM_APPS_LIST')
const BULK_UPDATE_APPS = Symbol('BULK_UPDATE_APPS')
const START_APP_SCREEN_EDIT_SAVE = Symbol('START_APP_SCREEN_EDIT_SAVE')
const END_APP_SCREEN_EDIT_SAVE = Symbol('END_APP_SCREEN_EDIT_SAVE')
const START_WEB_PUBLISH = Symbol('START_WEB_PUBLISH')
const END_WEB_PUBLISH = Symbol('END_WEB_PUBLISH')

// exported for tests
export const LOAD_APP_DATASOURCE_RELATIONSHIPS = Symbol('LOAD_APP_DATASOURCE_RELATIONSHIPS') // prettier-ignore

export const BLANK_TEMPLATE_IDS = {
  LEGACY: 'blank',
  RESPONSIVE_DESKTOP: 'blank_desktop',
  RESPONSIVE_MOBILE: 'blank_mobile',
}

const DEFAULT_BLANK_TEMPLATE = {
  id: BLANK_TEMPLATE_IDS.LEGACY,
  name: 'Blank',
  preview: null,
}

export const DEFAULT_TEMPLATES = {
  mobile: [DEFAULT_BLANK_TEMPLATE],
  web: [DEFAULT_BLANK_TEMPLATE],
  responsive: [
    {
      id: BLANK_TEMPLATE_IDS.RESPONSIVE_DESKTOP,
      name: 'Blank Desktop First',
      preview:
        'https://apto-resources-dev.s3.us-west-1.amazonaws.com/Desktop-First-App-Template.png',
      layoutMode: 'responsive',
    },
    {
      id: BLANK_TEMPLATE_IDS.RESPONSIVE_MOBILE,
      name: 'Blank Mobile First',
      preview:
        'https://apto-resources-dev.s3.us-west-1.amazonaws.com/Mobile-Fist-App-Template.png',
      layoutMode: null,
    },
  ],
}

/**
 * @param {string} platform
 * @returns {string}
 */
export const getFirstDefaultTemplateIdForPlatform = platform => {
  const templateId = get(DEFAULT_TEMPLATES, [platform, '0', 'id'])

  if (!templateId) {
    throw new Error(
      `Could not find suitable default template for platform=${platform}`
    )
  }

  return templateId
}

/**
 * @param {string} platform
 * @returns {string}
 */
export const getDefaultTemplateIdForResponsive = platform => {
  const index = platform === 'mobile' ? 1 : 0
  const templateId = get(DEFAULT_TEMPLATES, ['responsive', index, 'id'])

  if (!templateId) {
    throw new Error(
      `Could not find suitable default template for platform=${platform}`
    )
  }

  return templateId
}

/**
 * @param {string} id
 * @returns {boolean}
 */
export const templateIdIsBlankType = id =>
  Object.values(BLANK_TEMPLATE_IDS).includes(id)

const EMPTY_OBJECT = {}

const TEMPLATES_INITIAL_STATE = {
  list: [],
  loading: false,
  loaded: false,
}

const TEMPLATE_BRANDING_INITIAL_STATE = {
  loading: false,
  loaded: false,
}

export const STATUS_SAVING = 'SAVING'
export const STATUS_IDLE = 'IDLE'
const SCREEN_SAVE_STATUS_MAP = new Map([
  [START_APP_SCREEN_EDIT_SAVE, STATUS_SAVING],
  [END_APP_SCREEN_EDIT_SAVE, STATUS_IDLE],
])
const WEB_PUBLISH_STATUS_MAP = new Map([
  [START_WEB_PUBLISH, STATUS_SAVING],
  [END_WEB_PUBLISH, STATUS_IDLE],
])

const INITIAL_STATE = {
  apps: {},
  list: [],
  inboundLinks: {},
  loading: false,
  loaded: false,
  uploadInProgress: false,
  templatesBrandings: {},
  templatesByPlatform: {
    web: TEMPLATES_INITIAL_STATE,
    desktop: TEMPLATES_INITIAL_STATE,
  },
  teamAppsList: [],
  orgAppsList: [],
}

// REDUCER
export default mergeReducers(
  INITIAL_STATE,
  datasourcesReducer,
  tableLayoutReducer,
  paramsReducer,
  buildsReducer,
  trialReducer,

  (state, action) => {
    if (action.type === REQUEST_ALL) {
      // Make call to API
      requestAll()

      return {
        ...state,
        loading: true,
      }
    }

    if (action.type === REQUEST_ALL_ORG_APPS) {
      // Make call to API
      const { orgId } = action

      requestAllOrgApps(orgId)

      return {
        ...state,
        loading: true,
      }
    }

    if (action.type === REQUEST_ALL_TEAM_APPS) {
      const { orgId } = action

      requestAllTeamApps(orgId)

      return {
        ...state,
        loading: true,
      }
    }

    if (action.type === RESET_TEAM_APPS_LIST) {
      return {
        ...state,
        teamAppsList: [],
      }
    }

    if (action.type === REQUEST_APP) {
      const { appId } = action

      // Make call to API
      requestSingle(appId)

      return state
    }

    if (action.type === DELETE_OBJECT) {
      const { id } = action
      const deletingId = id

      for (const appId in state.apps) {
        if (state.apps[appId]?.components?.[id]) {
          const { [deletingId]: removed, ...newComponents } =
            state.apps[appId].components

          return {
            ...state,
            apps: {
              ...state.apps,
              [appId]: {
                ...state.apps[appId],
                components: newComponents,
              },
            },
          }
        }
      }
    }

    if (action.type === LOAD_APP) {
      let { app, mobileOnly } = action

      if (mobileOnly) {
        localStorage.setItem(
          'shouldResizeAppTemplateScreens',
          JSON.stringify({ shouldResize: mobileOnly })
        )
      }

      let linkMap = {}

      const appId = app.id
      app = { ...state.apps[appId], ...app }

      if (app.components) {
        linkMap = buildLinkMap(app)
      }

      return {
        ...state,
        loading: false,
        inboundLinks: {
          ...state.inboundLinks,
          [app.id]: linkMap,
        },
        apps: {
          ...state.apps,
          [app.id]: app,
        },
        list: state.list.map(appFromList => {
          if (appFromList.id === app.id) {
            return { ...appFromList, acessedAt: new Date() }
          } else {
            return appFromList
          }
        }),
      }
    }

    if (action.type === LOAD_COMPONENT) {
      const { appId, componentId, component } = action

      let app = state.apps[appId]

      if (!app) {
        return state
      }

      if (component) {
        app = {
          ...app,
          components: {
            ...app.components,
            [componentId]: component,
          },
        }
      } else {
        app = { ...app, component: { ...app.components } }
        delete app.components[componentId]
      }

      const linkMap = buildLinkMap(app)

      return {
        ...state,
        inboundLinks: {
          ...state.inboundLinks,
          [appId]: linkMap,
        },
        apps: {
          ...state.apps,
          [appId]: app,
        },
      }
    }

    if (action.type === LOAD_APPS_LIST) {
      const { apps, lastApp } = action

      return {
        ...state,
        loading: false,
        loaded: true,
        list: apps.map(app => ({
          ...app,
          acessedAt: new Date(app.acessedAt),
        })),
        lastApp,
      }
    }

    if (action.type === LOAD_ORG_APPS_LIST) {
      const { apps, lastApp } = action

      return {
        ...state,
        loading: false,
        loaded: true,
        orgAppsList: apps,
        lastApp,
      }
    }

    if (action.type === LOAD_TEAM_APPS_LIST) {
      const { apps } = action

      return {
        ...state,
        loading: false,
        loaded: true,
        teamAppsList: apps,
      }
    }

    if (action.type === LOAD_TEMPLATES_LIST) {
      const {
        payload: { templates, primaryPlatform },
      } = action

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [primaryPlatform]: {
            list: [...DEFAULT_TEMPLATES[primaryPlatform], ...templates],
            loading: false,
            loaded: true,
          },
        },
      }
    }

    if (action.type === SET_LOADING_TEMPLATES) {
      const {
        payload: { primaryPlatform, loading, loaded },
      } = action

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [primaryPlatform]: {
            ...TEMPLATES_INITIAL_STATE,
            ...state.templatesByPlatform[primaryPlatform],
            loading,
            loaded,
          },
        },
      }
    }

    if (action.type === LOAD_TEMPLATE_BRANDING) {
      const {
        payload: { templateId, branding },
      } = action

      return {
        ...state,
        templatesBrandings: {
          [templateId]: {
            loaded: true,
            loading: false,
            branding,
          },
        },
      }
    }

    if (action.type === LOAD_APP_DATASOURCE_RELATIONSHIPS) {
      const { appId, relationships } = action

      return {
        ...state,
        apps: {
          ...state.apps,
          [appId]: {
            ...state.apps?.[appId],
            relationships,
          },
        },
      }
    }

    if (action.type === SET_LOADING_TEMPLATE_BRANDING) {
      const {
        payload: { templateId, loading, loaded },
      } = action

      return {
        ...state,
        templatesBrandings: {
          [templateId]: {
            ...TEMPLATE_BRANDING_INITIAL_STATE,
            ...state.templatesBrandings[templateId],
            loading,
            loaded,
          },
        },
      }
    }

    if (action.type === UPDATE_APP) {
      const { appId, data } = action

      const app = {
        ...state.apps[appId],
        ...data,
      }

      requestUpdateApp(appId, data)

      return {
        ...state,
        apps: {
          ...state.apps,
          [appId]: app,
        },
      }
    }

    if (action.type === DELETE_APP) {
      const { appId } = action

      const list = state.list.filter(app => app.id !== appId)

      return {
        ...state,
        list,
      }
    }

    if (action.type === UNSET_LAUNCH_COMPONENT) {
      const { appId, componentId } = action
      let app = state.apps[appId]

      if (!app) {
        return state
      }

      let { launchComponentId, authComponentId } = app

      const ids = Object.keys(app.components).filter(k => k !== componentId)

      const otherComponentId =
        optimize(ids, id => {
          const component = app.components[id]

          return -(component.x + component.y)
        }) || null

      if (launchComponentId === componentId) {
        launchComponentId = otherComponentId
      }

      if (authComponentId === componentId) {
        authComponentId = otherComponentId
      }

      const appChanges = {
        launchComponentId,
        authComponentId,
      }

      requestUpdateApp(appId, appChanges)

      app = {
        ...app,
        ...appChanges,
      }

      return {
        ...state,
        apps: {
          ...state.apps,
          [appId]: app,
        },
      }
    }

    if (action.type === SET_APP_LIBRARIES) {
      const { appId, libraries } = action.payload

      return {
        ...state,
        loading: false,
        apps: {
          ...state.apps,
          [appId]: {
            ...state.apps[appId],
            libraries,
          },
        },
      }
    }

    if (action.type === BULK_UPDATE_APPS) {
      const { apps, organizationId } = action

      requestBulkUpdateApps(organizationId, apps)

      return state
    }

    if (
      action.type === START_APP_SCREEN_EDIT_SAVE ||
      action.type === END_APP_SCREEN_EDIT_SAVE
    ) {
      const { appId } = action
      const screenSaveStatus = SCREEN_SAVE_STATUS_MAP.get(action.type)

      return {
        ...state,
        apps: {
          ...state.apps,
          [appId]: {
            ...state.apps[appId],
            screenSaveStatus,
          },
        },
      }
    }

    if (action.type === START_WEB_PUBLISH || action.type === END_WEB_PUBLISH) {
      const { appId } = action
      const webPublishStatus = WEB_PUBLISH_STATUS_MAP.get(action.type)

      return {
        ...state,
        apps: {
          ...state.apps,
          [appId]: {
            ...state.apps[appId],
            webPublishStatus,
          },
        },
      }
    }
  }
)

// ACTIONS

export const loadApp = (app, mobileOnly) => ({
  type: LOAD_APP,
  app,
  mobileOnly,
})

export const setAppLibraries = (appId, libraries) => {
  return {
    type: SET_APP_LIBRARIES,
    payload: {
      appId,
      libraries,
    },
  }
}

export const loadComponent = (appId, componentId, component) => ({
  type: LOAD_COMPONENT,
  appId,
  componentId,
  component,
})

export const loadAppsList = (apps, lastApp) => ({
  type: LOAD_APPS_LIST,
  apps,
  lastApp,
})

export const loadOrgAppsList = (apps, lastApp) => ({
  type: LOAD_ORG_APPS_LIST,
  apps,
  lastApp,
})

export const loadTeamAppsList = apps => ({
  type: LOAD_TEAM_APPS_LIST,
  apps,
})

export const resetTeamAppsList = () => ({ type: RESET_TEAM_APPS_LIST })

export const loadTemplatesList = (templates, primaryPlatform) => ({
  type: LOAD_TEMPLATES_LIST,
  payload: { templates, primaryPlatform },
})

export const loadTemplateBranding = (templateId, branding) => ({
  type: LOAD_TEMPLATE_BRANDING,
  payload: { templateId, branding },
})

export const loadAppDatasourceRelationships = (appId, relationships) => ({
  type: LOAD_APP_DATASOURCE_RELATIONSHIPS,
  appId,
  relationships,
})

export const requestApps = () => ({ type: REQUEST_ALL })
export const requestOrgApps = orgId => ({ type: REQUEST_ALL_ORG_APPS, orgId })
export const requestTeamApps = orgId => ({ type: REQUEST_ALL_TEAM_APPS, orgId })
export const requestApp = appId => ({ type: REQUEST_APP, appId })

export const setLaunchComponentId = (appId, launchComponentId) => ({
  type: UPDATE_APP,
  appId,
  data: { launchComponentId },
})

export const setAuthComponentId = (appId, authComponentId) => ({
  type: UPDATE_APP,
  appId,
  data: { authComponentId },
})

export const unsetLaunchComponent = (appId, componentId) => ({
  type: UNSET_LAUNCH_COMPONENT,
  appId,
  componentId,
})

export const updateApp = (appId, data) => ({
  type: UPDATE_APP,
  appId,
  data,
})

export const deleteApp = (appId, target, version) => {
  return async dispatch => {
    await requestDeleteApp(appId)

    dispatch({
      type: DELETE_APP,
      appId,
    })
  }
}
export const bulkUpdateApps = (organizationId, apps) => ({
  type: BULK_UPDATE_APPS,
  apps,
  organizationId,
})

/**
 *
 * @param {string} appId
 * @returns
 */
export const startAppScreenEditSave = appId => ({
  type: START_APP_SCREEN_EDIT_SAVE,
  appId,
})

/**
 *
 * @param {string} appId
 * @returns
 */
export const endAppScreenEditSave = appId => ({
  type: END_APP_SCREEN_EDIT_SAVE,
  appId,
})

/**
 *
 * @param {string} appId
 * @returns
 */
export const startWebPublish = appId => ({
  type: START_WEB_PUBLISH,
  appId,
})

/**
 *
 * @param {string} appId
 * @returns
 */
export const endWebPublish = appId => ({
  type: END_WEB_PUBLISH,
  appId,
})

// THUNKS

const DEFAULT_DELAY = process.env.NODE_ENV === 'development' ? 1000 : null

export const ensureTemplatesAreLoaded = (
  primaryPlatform,
  { delay = DEFAULT_DELAY } = {}
) => {
  return (dispatch, getState) => {
    const state = getTemplatesState(getState(), primaryPlatform)

    if (state.loading) {
      return
    }

    if (state.loaded) {
      if (delay) {
        dispatch({
          type: SET_LOADING_TEMPLATES,
          payload: { primaryPlatform, loading: true, loaded: false },
        })

        setTimeout(() => {
          dispatch({
            type: SET_LOADING_TEMPLATES,
            payload: { primaryPlatform, loading: false, loaded: true },
          })
        }, delay)
      }

      return
    }

    dispatch({
      type: SET_LOADING_TEMPLATES,
      payload: { primaryPlatform, loading: true, loaded: false },
    })

    if (delay) {
      setTimeout(() => {
        requestTemplates(primaryPlatform)
      }, delay)
    } else {
      requestTemplates(primaryPlatform)
    }
  }
}

export const ensureTemplateBrandingIsLoaded = (
  templateId,
  { delay = DEFAULT_DELAY } = {}
) => {
  return (dispatch, getState) => {
    const state = getTemplateBrandingState(getState(), templateId)

    if (state.loading) {
      return
    }

    if (state.loaded) {
      if (delay) {
        dispatch({
          type: SET_LOADING_TEMPLATE_BRANDING,
          payload: { templateId, loading: true, loaded: false },
        })

        setTimeout(() => {
          dispatch({
            type: SET_LOADING_TEMPLATE_BRANDING,
            payload: { templateId, loading: false, loaded: true },
          })
        }, delay)
      }

      return
    }

    dispatch({
      type: SET_LOADING_TEMPLATE_BRANDING,
      payload: { templateId, loading: true, loaded: false },
    })

    if (delay) {
      setTimeout(() => {
        requestTemplateBranding(templateId)
      }, delay)
    } else {
      requestTemplateBranding(templateId)
    }
  }
}

// SELECTORS

export const getApps = state => {
  return state.apps.list
}

export const getOrgApps = state => {
  return state.apps.orgAppsList
}

export const getTeamApps = state => {
  return state.apps.teamAppsList
}

/**
 *
 * @param {object} state
 * @returns {App}
 */
export const getLastApp = state => {
  return state.apps.lastApp || state.apps.list[0]?.id
}

export const getAppFromList = (state, appId) => {
  return state.apps.list.filter(({ id }) => id === appId)[0]
}

/**
 * @typedef {import('./App').App} App
 */
/**
 * @param {object} state
 * @param {string} appId
 * @returns {App}
 */
export const getApp = (state, appId) => {
  return state?.apps?.apps?.[appId]
}

export const getAppsLoaded = state => {
  return state.apps.loaded
}

export const getDatasourceType = (state, appId) => {
  const app = getApp(state, appId)

  if (!app) {
    return
  }

  const datasource = Object.values(app.datasources)[0]

  return (datasource && datasource.type) || 'apto-backend'
}

const getComponents = (state, appId) => {
  const app = state.apps.apps[appId]

  if (!app || !app?.components) {
    return []
  }

  let components = Object.keys(app.components).map(id => ({
    ...app.components[id],
    id,
  }))

  components = sort(components, c => c.name)

  return components
}

export const getScreens = (state, appId) => {
  return getComponents(state, appId).filter(c => !c.reusableComponent)
}

export const getReusableComponents = (state, appId) => {
  return getComponents(state, appId).filter(c => c.reusableComponent)
}

export const getComponent = (state, appId, componentId) => {
  const app = state.apps.apps[appId] || {}
  const components = app.components || {}

  return components[componentId]
}

/**
 *
 * @param {*} state
 * @param {*} appId
 * @returns {boolean}
 */
export const getAppDataLoaded = (state, appId) => {
  const app = getApp(state, appId) || {}

  return typeof app.loaded === 'boolean' ? app.loaded : false
}

export const getNestedComponentsMap = (state, appId, component) => {
  let map = {}

  if (!component || !component.objects) {
    return map
  }

  traverse(component.objects, obj => {
    if (obj.type === COMPONENT_INSTANCE) {
      const newComponent = getComponent(state, appId, obj.componentId)
      map[obj.componentId] = newComponent
      map = { ...map, ...getNestedComponentsMap(state, appId, newComponent) }
    }
  })

  return map
}

export const getInboundLinks = (state, appId) => {
  return state.apps.inboundLinks[appId] || EMPTY_OBJECT
}

export const getInboundLinkNames = (state, appId, componentId) => {
  const links = (state.apps.inboundLinks[appId] || EMPTY_OBJECT)[componentId]

  if (!links) {
    return EMPTY_OBJECT
  }

  const result = {}
  const app = getApp(state, appId)

  for (const componentId of links) {
    const component = app.components[componentId]

    result[componentId] = (component && component.name) || 'Deleted Screen'
  }

  return result
}

export const getLaunchComponentId = (state, appId) => {
  const app = state.apps.apps[appId]

  return app && app.launchComponentId
}

export const getAuthComponentId = (state, appId) => {
  const app = state.apps.apps[appId]

  return app && app.authComponentId
}

export const getAppLibraryVersion = (state, appId, libraryName) => {
  const app = state.apps.apps[appId]
  const libraries = getLibraryDependencies(app)

  const library = libraries.filter(l => l.name === libraryName)[0]

  return library?.version
}

export const getAssociatedApps = (state, appId) => {
  const app = getApp(state, appId)
  const associatedAppIds = app.associatedApps || []
  const results = []

  associatedAppIds.forEach(({ id }) => {
    const app = getApp(state, id)

    if (app) {
      results.push(app)
    }
  })

  return results
}

export const getAssociatedAppScreens = (state, appId) => {
  const associatedApps = getAssociatedApps(state, appId)

  return associatedApps.map(app => ({
    id: app.id,
    name: app.name,
    screens: getScreens(state, app.id),
  }))
}

export const getAppBranding = (state, appId) => {
  const app = state.apps.apps[appId]

  return (app && app.branding) || defaultBranding
}

export const getAppPlatform = (state, appId) => {
  const app = state.apps.apps[appId]

  return (app && app.primaryPlatform) || 'mobile'
}

export const getTemplatesState = (state, primaryPlatform) => {
  const {
    apps: {
      templatesByPlatform: { [primaryPlatform]: templateState },
    },
  } = state

  return templateState || TEMPLATES_INITIAL_STATE
}

export const getTemplateBrandingState = (state, templateId) => {
  const {
    apps: {
      templatesBrandings: { [templateId]: brandingState },
    },
  } = state

  return brandingState || TEMPLATE_BRANDING_INITIAL_STATE
}

export const getUpgraded = (state, appId) => {
  const app = getApp(state, appId)
  const active = app?.Organization?.active

  return active
}

const determineTrialState = days => {
  if (days === null) {
    return BEFORE
  } else if (days > 0) {
    return DURING
  } else if (days <= 0) {
    return AFTER
  } else {
    return BEFORE
  }
}

const determineDayMessage = days => {
  if (!days) {
    return null
  } else if (days === 1) {
    return `${days} Day`
  } else {
    return `${days} Days`
  }
}

export const getIsIntegrationTrialOngoing = (state, appId) => {
  const { trialState } = getTrialInformation(state, appId)
  const active = getUpgraded(state, appId)

  return trialState === DURING && !active
}

export const getTrialInformation = (state, appId) => {
  const app = getApp(state, appId)
  const { trialEndDate } = app?.Organization || {}

  const days = trialEndDate ? moment(trialEndDate).diff(moment(), 'days') : null
  const trialState = determineTrialState(days)

  return {
    days: determineDayMessage(days),
    trialState,
  }
}

export const getAppLibraries = (state, appId) => {
  const app = state.apps.apps[appId]

  return app?.libraries || []
}

/**
 *
 * @param {*} state
 * @param {string} appId
 * @returns {string}
 */
export const getAppScreenSaveStatus = (state, appId) => {
  const app = state.apps.apps[appId]

  return app?.screenSaveStatus || STATUS_IDLE
}

/**
 *
 * @param {*} state
 * @param {string} appId
 * @returns {string}
 */
export const getAppWebPublishStatus = (state, appId) => {
  const app = state.apps.apps[appId]

  return app?.webPublishStatus || STATUS_IDLE
}
