import { AxiosResponse } from 'utils/io/http/axios'

import { FETCH_BASE_URL } from '../actions/baseUrl'
import { RESET } from '..'

export type XanoDatabaseState = {
  externalDatabases: {
    xano: {
      validUrl?: boolean
      status: 'pending' | 'ready'
    }
  } | null
}

type BaseUrlResponse = {
  status: number
}

export interface XanoDatabaseAction {
  type: typeof FETCH_BASE_URL
  payload?: Promise<AxiosResponse<BaseUrlResponse>>
}

interface XanoDatabaseActionResponse
  extends Omit<XanoDatabaseAction, 'payload'> {
  payload: {
    status: number
  }
}

export const BASE_URL_INITIAL_STATE = {
  externalDatabases: null,
}

// Reducer
export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = BASE_URL_INITIAL_STATE,
  action: XanoDatabaseActionResponse
): XanoDatabaseState => {
  if (action.type === `${RESET}`) {
    return BASE_URL_INITIAL_STATE
  }

  if (action.type === `${FETCH_BASE_URL}`) {
    return {
      ...state,
      externalDatabases: {
        xano: {
          validUrl: action.payload.status === 200,
          status: 'ready',
        },
      },
    }
  }

  return state
}
