import React from 'react'
import classNames from 'classnames'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'

import DropdownLabel from './DropdownLabel'

import './SelectMenu.scss'

export default function SelectMenu({
  value,
  options,
  Label,
  tooltip,
  disabled,
  onChange,
}) {
  const labels = {}

  for (const { value, label } of options) {
    labels[value] = label
  }

  return (
    <div
      className={classNames('permissions-select', {
        disabled,
      })}
    >
      {(Label || tooltip) && <DropdownLabel Title={Label} tooltip={tooltip} />}
      <WrappedSelect
        options={options}
        value={value}
        placeholder="Select Access"
        onChange={onChange}
        rowHeight={46}
      />
    </div>
  )
}
