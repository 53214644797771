import { cloneDeep } from 'lodash'
import { DefaultRootState } from 'react-redux'

import { AxiosResponse, defaultAxios } from 'utils/io/http/axios'
import { baseCDNURL } from 'utils/io'
import {
  PrebuiltBuilderContentData,
  PrebuiltBuilderContentPlatformTarget,
  PrebuiltBuilderContentStatus,
} from './types'
import { getCategoriesAndTags } from './utils'

enum ActionTypes {
  FETCH_PREBUILT_LAYOUT_SECTIONS = 'FETCH_PREBUILT_LAYOUT_SECTIONS',
}

type Action = {
  type: ActionTypes
  payload: {
    data?: unknown[]
    message?: string
  }
}

interface State {
  loading: boolean
  error?: string | null | undefined
  prebuiltLayoutSections: PrebuiltBuilderContentData[]
  categories: string[]
  primaryTags: string[]
  secondaryTags: string[]
}

const INITIAL_STATE: State = {
  loading: false,
  error: null,
  prebuiltLayoutSections: [],
  categories: [],
  primaryTags: [],
  secondaryTags: [],
}

const getPrebuiltLayoutSectionWithMetadata = (
  section: PrebuiltBuilderContentData
) => {
  const { rawBody } = section

  const body = cloneDeep(rawBody)

  // If a layout section is missing it's snapping options, we need to set them
  if (!body['snappingRules']) {
    body['snappingRules'] = {
      lock: { left: 0, right: 0 },
      snap: true,
      snapType: 'screenEdge',
    }
  }

  body['metadata'] = {
    ...(body['metadata'] ?? {}),
    usesRawBody: true,
    prebuiltLayoutSectionId: section.id,
  }

  return { ...section, body }
}

// Reducer
// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case ActionTypes.FETCH_PREBUILT_LAYOUT_SECTIONS: {
      return { ...state, error: null }
    }
    case `${ActionTypes.FETCH_PREBUILT_LAYOUT_SECTIONS}_PENDING`: {
      return { ...state, loading: true, error: null }
    }
    case `${ActionTypes.FETCH_PREBUILT_LAYOUT_SECTIONS}_FULFILLED`: {
      const data = action.payload?.data
      if (Array.isArray(data)) {
        const prebuiltLayoutSectionsData = data as PrebuiltBuilderContentData[]
        const prebuiltLayoutSections = prebuiltLayoutSectionsData.map(
          getPrebuiltLayoutSectionWithMetadata
        )
        const { categories, primaryTags, secondaryTags } = getCategoriesAndTags(
          prebuiltLayoutSections
        )

        return {
          ...state,
          prebuiltLayoutSections,
          categories,
          primaryTags,
          secondaryTags,
          loading: false,
        }
      }

      return state
    }
    case `${ActionTypes.FETCH_PREBUILT_LAYOUT_SECTIONS}_REJECTED`: {
      const error =
        action.payload.message ?? `Failed to fetch prebuilt layout sections`
      console.warn(`Failed to load layout sections:`, error)

      return { ...state, loading: false, error }
    }
    default:
      return state
  }
}

// Actions

type FetchPrebuiltLayoutSectionAction = {
  type: ActionTypes.FETCH_PREBUILT_LAYOUT_SECTIONS
  payload: Promise<AxiosResponse<unknown>>
}

export const fetchPrebuiltLayoutSections =
  (): FetchPrebuiltLayoutSectionAction => ({
    type: ActionTypes.FETCH_PREBUILT_LAYOUT_SECTIONS,
    payload: defaultAxios.get(
      `${baseCDNURL}/prebuilt-builder-contents/layout-section`
    ),
  })

export type PrebuiltLayoutSectionFilterOptions = {
  includeUnlisted?: boolean
  platformTarget?: PrebuiltBuilderContentPlatformTarget
}

// Selectors

export interface StoreState extends DefaultRootState {
  layoutSections: State
}

type GetPrebuiltLayoutSections = (
  state: StoreState,
  opts?: PrebuiltLayoutSectionFilterOptions
) => PrebuiltBuilderContentData[]

export const getPrebuiltLayoutSections: GetPrebuiltLayoutSections = (
  state,
  opts
) => {
  if (opts?.includeUnlisted && !opts?.platformTarget) {
    return state.layoutSections.prebuiltLayoutSections
  }

  const filteredSections = state.layoutSections.prebuiltLayoutSections.filter(
    s =>
      (opts?.includeUnlisted ||
        s.status === PrebuiltBuilderContentStatus.LISTED) &&
      (!opts?.platformTarget ||
        s.platformTarget === PrebuiltBuilderContentPlatformTarget.BOTH ||
        s.platformTarget === opts.platformTarget)
  )

  return filteredSections
}

type GetUnlistedPrebuiltLayoutSections = (
  state: StoreState
) => PrebuiltBuilderContentData[]

export const getUnlistedPrebuiltLayoutSections: GetUnlistedPrebuiltLayoutSections =
  state => {
    const unlistedSections = state.layoutSections.prebuiltLayoutSections.filter(
      s => s.status === PrebuiltBuilderContentStatus.UNLISTED
    )

    return unlistedSections
  }

export const getCategories = (state: StoreState): string[] =>
  state.layoutSections.categories

export const getPrimaryTags = (state: StoreState): string[] =>
  state.layoutSections.primaryTags

export const getSecondaryTags = (state: StoreState): string[] =>
  state.layoutSections.secondaryTags

export const getTags = (state: StoreState): string[] =>
  Array.from(
    new Set([
      ...state.layoutSections.primaryTags,
      ...state.layoutSections.secondaryTags,
    ])
  )

export const getLoading = (state: StoreState): boolean =>
  state.layoutSections.loading

export const getError = (state: StoreState): string | null | undefined =>
  state.layoutSections.error

export default reducer
