import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'

import {
  LABEL,
  COMPONENT,
  COMPONENT_INSTANCE,
  LIBRARY_COMPONENT,
  FORM,
  LINE,
  GROUP,
  LAYOUT_SECTION,
  DeviceType,
} from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'

import getDeviceObject from 'utils/getDeviceObject'
import { CONTAINER_TYPES } from 'utils/positioning'
import { applyScaleRounding, scale, scaleValue } from 'utils/zoom'
import { getPathData, getArrowPath } from 'utils/arrows'
import { getAppComponent } from 'utils/libraries'
import { getSnapValue } from 'utils/snapping'
import {
  getContainerFromSection,
  getSectionFromChild,
  isContainerSectionElement,
  isDirectChildOfSectionContainer,
  isSectionElement,
} from 'utils/layoutSections'

import {
  getComponent,
  getCurrentAppId,
  getMap,
  getObjectList,
  getObjectPosition,
  getParentComponent,
  getYOffset,
  selectObject,
} from 'ducks/editor/objects'
import { getApp } from 'ducks/apps'
import { getFeatureFlag } from 'ducks/featureFlags'
import { getHoverSelection } from 'ducks/editor/selection'

import { getWidthConstraints } from 'utils/objects/widthConstraints'
import ResizeHandle from './ResizeHandle'
import { ReorderButtons } from './ReorderButtons'

const LEFT = 'LEFT'
const RIGHT = 'RIGHT'
const TOP = 'TOP'
const BOTTOM = 'BOTTOM'

/**
 * @typedef PositionSizeChange
 * @property {number} x
 * @property {number} [y]
 * @property {number} width
 * @property {number} [height]
 */

class BoundingBox extends Component {
  static defaultProps = { resize: true }

  state = {
    activeResize: null,
    resizeStartPoint: null,
    /** @type {PositionSizeChange | null} */
    prevState: null,
    verticalStyle: 'transparent',
    horizontalStyle: 'transparent',
    showIcon: null,
  }

  componentDidUpdate(prevProps) {
    const { component, object } = this.props

    if (!component || !object) {
      return
    }

    if (!prevProps.component || !prevProps.object) {
      return
    }

    const oldDeviceType = prevProps.component
      ? getDeviceType(prevProps.component.width)
      : DeviceType.MOBILE
    const newDeviceType = component
      ? getDeviceType(component.width)
      : DeviceType.MOBILE

    const deviceChanged = oldDeviceType !== newDeviceType
    const objectChanged = prevProps.object.id !== object.id

    if (
      (deviceChanged && object.type !== COMPONENT) ||
      (objectChanged && object.type !== COMPONENT)
    ) {
      this.setState({ prevState: null })
    }
  }

  /**
   *
   * @param {PositionSizeChange} changeObj
   * @param {number} xEffect
   * @returns {PositionSizeChange}
   */
  getLimitedWidthChanges = (changeObj, xEffect) => {
    const { component, object } = this.props
    const { prevState } = this.state

    if (!object || !prevState) {
      return changeObj
    }

    const { width } = changeObj

    const deviceType = component
      ? getDeviceType(component.width)
      : DeviceType.MOBILE
    const deviceObject = getDeviceObject(object, deviceType)
    const { constrainMinWidth, constrainMaxWidth } = getWidthConstraints(
      deviceObject,
      width
    )

    if (constrainMinWidth || constrainMaxWidth) {
      const limitedWidth = constrainMinWidth
        ? deviceObject.minWidth
        : deviceObject.maxWidth

      changeObj.x = prevState.x + xEffect * (prevState.width - limitedWidth)
      changeObj.width = limitedWidth
    }

    return changeObj
  }

  resize =
    (...directions) =>
    e => {
      e.stopPropagation()
      const { object, yOffset, isSectionContainer } = this.props
      const activeResize = {}
      const { x, y, width, height } = object
      const prevState = { x, y, width, height }
      const resizeStartPoint = [e.clientX, e.clientY]

      if (object.type === COMPONENT) {
        prevState.height += yOffset
      }

      directions.forEach(k => (activeResize[k] = true))

      this.setState({
        activeResize,
        prevState,
        resizeStartPoint,
      })

      if (isSectionContainer) {
        if ([LEFT, RIGHT].includes(directions[0])) {
          this.setState({
            horizontalStyle: null,
            verticalStyle: 'opaque',
            showIcon: directions[0],
          })
        } else {
          this.setState({
            verticalStyle: null,
            horizontalStyle: 'opaque',
            showIcon: directions[0],
          })
        }
      }
    }

  handleMouseMove = e => {
    const { prevState, resizeStartPoint, activeResize } = this.state
    const { onChange, zoom, object, magicLayout } = this.props

    if (activeResize) {
      const [startX, startY] = resizeStartPoint
      const newX = e.clientX
      const newY = e.clientY

      let xSign = 1
      let ySign = 1

      if (activeResize.LEFT) {
        xSign = -1
      }

      if (activeResize.TOP) {
        ySign = -1
      }

      const diffX =
        activeResize.LEFT || activeResize.RIGHT
          ? Math.round((newX - startX) / zoom.scale)
          : 0

      const diffY =
        activeResize.TOP || activeResize.BOTTOM
          ? Math.round((newY - startY) / zoom.scale)
          : 0

      let newWidth = Math.max(0, +prevState.width + diffX * xSign)
      let newHeight = Math.max(0, +prevState.height + diffY * ySign)

      let aspectRatio = null
      let lockAspectRatio = false

      if (object.type === LIBRARY_COMPONENT) {
        const { libraryName, componentName } = object

        const componentConfig =
          getAppComponent(null, libraryName, componentName) || {}

        if ('lockAspectRatio' in componentConfig) {
          lockAspectRatio = componentConfig.lockAspectRatio
        }
      }

      if (!isSectionElement(object)) {
        if ((e.shiftKey && this.verticalResizeable()) || lockAspectRatio) {
          const ratios = []
          aspectRatio = prevState.height / prevState.width

          if (activeResize.LEFT || activeResize.RIGHT) {
            ratios.push(newWidth / prevState.width)
          }

          if (activeResize.TOP || activeResize.BOTTOM) {
            ratios.push(newHeight / prevState.height)
          }

          const ratio = Math.max(0, Math.min(...ratios))

          newWidth = prevState.width * ratio
          newHeight = prevState.height * ratio
        }
      }

      let xEffect = 0.5
      let yEffect = 0.5

      if (!e.altKey && !isSectionElement(object)) {
        if (activeResize.TOP) {
          yEffect = 1
        }

        if (activeResize.BOTTOM) {
          yEffect = 0
        }

        if (activeResize.LEFT) {
          xEffect = 1
        }

        if (activeResize.RIGHT) {
          xEffect = 0
        }
      }

      /** @type {PositionSizeChange} */
      let changeObj = {
        x: prevState.x + xEffect * (prevState.width - newWidth),
        width: newWidth,
      }

      if (this.verticalResizeable()) {
        changeObj.y = prevState.y + yEffect * (prevState.height - newHeight)
        changeObj.height = newHeight
      }

      if (activeResize.LEFT || activeResize.RIGHT) {
        changeObj = this.snapXChanges(changeObj, xEffect, aspectRatio, yEffect)
      }

      if (
        (this.verticalResizeable() && activeResize.TOP) ||
        activeResize.BOTTOM
      ) {
        changeObj = this.snapYChanges(changeObj, yEffect, aspectRatio, xEffect)
      }

      if (
        magicLayout &&
        this.verticalResizeable() &&
        !(activeResize.TOP || activeResize.BOTTOM)
      ) {
        delete changeObj.height
      }

      onChange(this.getLimitedWidthChanges(changeObj, xEffect))
    }
  }

  snapXChanges = (changeObj, xEffect, aspectRatio = null, yEffect) => {
    const { object, xGrid, setXSnap, zoom, component } = this.props
    let baseX = 0

    if (object.type !== COMPONENT) {
      baseX = component.x
    }

    const coords = {}

    if (xEffect !== 0) {
      coords.left = changeObj.x + baseX
    }

    if (xEffect !== 1) {
      coords.right = changeObj.x + changeObj.width + baseX
    }

    if (xEffect !== 0.5) {
      coords.center = changeObj.x + changeObj.width / 2 + baseX
    }

    if (Object.keys(coords).length === 0) {
      return changeObj
    }

    const snapResult = getSnapValue(xGrid, coords, zoom, true)

    if (!snapResult) {
      setXSnap(null)

      return changeObj
    }

    const key = Object.keys(snapResult)[0]
    const value = snapResult[key]

    setXSnap(value)

    const diff = value - coords[key]
    const centered = xEffect === 0.5
    changeObj = { ...changeObj }

    if (key === 'left') {
      changeObj.x = value - baseX
      changeObj.width += centered ? 2 * -diff : -diff
    }

    if (key === 'right') {
      changeObj.x += centered ? -diff : 0
      changeObj.width += centered ? 2 * diff : diff
    }

    if (key === 'center') {
      if (xEffect === 0) {
        changeObj.width += 2 * diff
      } else if (xEffect === 1) {
        changeObj.x += 2 * diff
        changeObj.width -= 2 * diff
      }
    }

    if (aspectRatio !== null) {
      const newHeight = changeObj.width * aspectRatio
      const yDiff = -yEffect * (newHeight - changeObj.height)

      changeObj.height = newHeight
      changeObj.y += yDiff
    }

    return changeObj
  }

  snapYChanges = (changeObj, yEffect, aspectRatio = null, xEffect) => {
    const { object, yGrid, setYSnap, zoom, component } = this.props
    let baseY = 0

    if (object.type !== COMPONENT) {
      baseY = component.y
    }

    const coords = {}

    if (yEffect !== 0) {
      coords.top = changeObj.y + baseY
    }

    if (yEffect !== 1) {
      coords.bottom = changeObj.y + changeObj.height + baseY
    }

    if (yEffect !== 0.5) {
      coords.center = changeObj.y + changeObj.height / 2 + baseY
    }

    if (Object.keys(coords).length === 0) {
      return changeObj
    }

    const snapResult = getSnapValue(yGrid, coords, zoom, true)

    if (!snapResult) {
      setYSnap(null)

      return changeObj
    }

    const key = Object.keys(snapResult)[0]
    const value = snapResult[key]

    setYSnap(value)

    const diff = value - coords[key]
    const centered = yEffect === 0.5
    changeObj = { ...changeObj }

    if (key === 'top') {
      changeObj.y = value - baseY
      changeObj.height += centered ? 2 * -diff : -diff
    }

    if (key === 'bottom') {
      changeObj.y += centered ? -diff : 0
      changeObj.height += centered ? 2 * diff : diff
    }

    if (key === 'center') {
      if (yEffect === 0) {
        changeObj.height += 2 * diff
      } else if (yEffect === 1) {
        changeObj.y += 2 * diff
        changeObj.height -= 2 * diff
      }
    }

    if (aspectRatio !== null) {
      const newWidth = (changeObj.height * 1) / aspectRatio
      const xDiff = -xEffect * (newWidth - changeObj.width)

      changeObj.width = newWidth
      changeObj.x += xDiff
    }

    return changeObj
  }

  handleMouseUp = () => {
    const { activeResize } = this.state
    const { onResizeEnd, resetSnaps, isSectionContainer } = this.props

    if (activeResize) {
      resetSnaps()
      this.setState({ activeResize: null })

      if (isSectionContainer) {
        this.setState({
          verticalStyle: 'transparent',
          horizontalStyle: 'transparent',
          showIcon: null,
        })
      }

      if (onResizeEnd) {
        onResizeEnd()
      }
    }
  }

  handleMouseEnter = direction => {
    const { isSectionContainer } = this.props

    if (isSectionContainer) {
      const style = [LEFT, RIGHT].includes(direction)
        ? 'verticalStyle'
        : 'horizontalStyle'

      this.setState({ [style]: 'opaque', showIcon: direction })
    }
  }

  handleMouseLeave = () => {
    const { isSectionContainer } = this.props
    const { activeResize } = this.state

    if (isSectionContainer && !activeResize) {
      this.setState({
        horizontalStyle: 'transparent',
        verticalStyle: 'transparent',
        showIcon: null,
      })
    }
  }

  verticalResizeable = () => {
    const { app, object, magicLayout, hasUpdatedGroups } = this.props

    if (object.type === LIBRARY_COMPONENT || object.type === LINE) {
      const { libraryName, componentName } = object
      const componentConfig = getAppComponent(app, libraryName, componentName)

      return !!(componentConfig && componentConfig.resizeY)
    }

    const notVerticalResizeable = [LABEL, COMPONENT_INSTANCE, FORM, GROUP]

    if (magicLayout && hasUpdatedGroups) {
      notVerticalResizeable.pop()
    }

    return !notVerticalResizeable.includes(object.type)
  }

  horizontalResizeable = () => {
    const { object, app, magicLayout, hasUpdatedGroups } = this.props

    if (object.type === LIBRARY_COMPONENT) {
      const { libraryName, componentName } = object

      const componentConfig =
        getAppComponent(app, libraryName, componentName) || {}

      if ('resizeX' in componentConfig) {
        return !!componentConfig.resizeX
      }
    }

    if (magicLayout && hasUpdatedGroups) {
      return true
    }

    return object.type !== GROUP
  }

  renderLink = () => {
    const { object, link, linkTargets, zoom } = this.props

    if (!link || !linkTargets) {
      return null
    }

    const { width, height } = object
    const absoluteObject = { ...object.absolutePosition, width, height }
    const target = linkTargets.filter(screen => screen.id === link.target)[0]
    const path = getPathData(absoluteObject, target, zoom)
    const arrow = getArrowPath(absoluteObject, target, zoom)

    return (
      <g>
        <path className="link-path" d={path} />
        <path className="link-arrow" d={arrow} />
      </g>
    )
  }

  /**
   *
   * @param {import('utils/responsiveTypes').EditorObject | import('utils/canvasTypes').PositionObject} positioningObject
   * @param {import('utils/canvasTypes').Zoom} zoom
   * @returns {import('utils/canvasTypes').PositionObjectScaled | null}
   */
  getScaledPositioningObject = (positioningObject, zoom) => {
    if (!positioningObject) {
      return null
    }

    const [positioningObjectXScaled, positioningObjectYScaled] = scale(
      [positioningObject.x, positioningObject.y],
      zoom
    )

    return {
      xScaled: applyScaleRounding(positioningObjectXScaled),
      yScaled: applyScaleRounding(positioningObjectYScaled),
      width: applyScaleRounding(scaleValue(positioningObject.width, zoom)),
      height: applyScaleRounding(scaleValue(positioningObject.height, zoom)),
    }
  }

  render() {
    const {
      object,
      zoom,
      resize,
      link,
      component,
      shouldShowGroupBoundingBox,
      parent,
      magicLayout,
      isSectionContainer,
      hoveredObjectParent,
      hasSectionReordering,
      deviceType,
      layoutSection,
      layoutSectionContainerPosition,
      layoutSectionPosition,
    } = this.props

    const { activeDrag, horizontalStyle, verticalStyle, showIcon } = this.state
    const verticalResizeable = this.verticalResizeable()

    const { x, y, width, height } = object.absolutePosition

    let [xScaled, yScaled] = scale([x, y], zoom)

    const x2Scaled = Math.round(xScaled + scaleValue(width, zoom) + 0.5) - 0.5

    const y2Scaled = Math.round(yScaled + scaleValue(height, zoom) + 0.5) - 0.5

    const layoutSectionContainerPositionScaled =
      this.getScaledPositioningObject(layoutSectionContainerPosition, zoom)
    const layoutSectionPositionScaled = this.getScaledPositioningObject(
      layoutSectionPosition,
      zoom
    )

    xScaled = Math.round(xScaled + 0.5) - 0.5
    yScaled = Math.round(yScaled + 0.5) - 0.5

    const widthScaled = x2Scaled - xScaled || 0
    const heightScaled = y2Scaled - yScaled || 0

    let showHandles = !!resize

    if (!this.horizontalResizeable()) {
      showHandles = false
    }

    const hideDiagonal = !verticalResizeable || isSectionContainer

    const isChildObject =
      magicLayout && parent && CONTAINER_TYPES.includes(parent?.type)

    const hoveredObjectIsChildOfContainer =
      hoveredObjectParent && isContainerSectionElement(hoveredObjectParent)

    return (
      <React.Fragment>
        {this.renderLink()}
        <g
          className={classNames('bounding-box', { 'has-link': link })}
          transform={`translate(${xScaled}, ${yScaled})`}
        >
          <DocumentEvents
            enabled={activeDrag}
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
          />
          <rect
            x={-0}
            y={-0}
            width={widthScaled}
            height={heightScaled}
            className={classNames('selection-box', {
              'group-bounding-box': shouldShowGroupBoundingBox,
              teal: magicLayout,
              'child-object-bounding-box': isChildObject,
              'section-element-bounding-box': isSectionContainer,
            })}
          />
          {object.type === LAYOUT_SECTION && (
            <>
              <g opacity={0.5}>
                <rect
                  x={0}
                  y={0}
                  width={widthScaled}
                  height={heightScaled}
                  stroke="#00A996"
                  strokeWidth="1"
                  strokeOpacity={0.5}
                  strokeDasharray="5,2"
                  className={classNames('hover-selection-box', {
                    teal: magicLayout,
                  })}
                />
              </g>
              {hasSectionReordering && (
                <ReorderButtons
                  object={object}
                  component={component}
                  deviceType={deviceType}
                  widthScaled={widthScaled}
                  heightScaled={heightScaled}
                  scale={zoom.scale}
                />
              )}
            </>
          )}
          {layoutSectionContainerPositionScaled &&
            !hoveredObjectIsChildOfContainer && (
              <g
                transform={`translate(${
                  layoutSectionContainerPositionScaled.xScaled - xScaled
                }, ${layoutSectionContainerPositionScaled.yScaled - yScaled})`}
                opacity={0.5}
              >
                {/*TODO(dyego): could be worth to create a separate component --> */}
                <rect
                  x={0}
                  y={0}
                  width={layoutSectionContainerPositionScaled.width}
                  height={layoutSectionContainerPositionScaled.height}
                  stroke="#00A996"
                  strokeWidth="1"
                  strokeOpacity={0.5}
                  strokeDasharray="5,2"
                  className={classNames('hover-selection-box', {
                    teal: magicLayout,
                  })}
                />
              </g>
            )}
          {layoutSectionPositionScaled && (
            <g
              transform={`translate(${
                layoutSectionPositionScaled.xScaled - xScaled
              }, ${layoutSectionPositionScaled.yScaled - yScaled})`}
            >
              <rect
                x={0}
                y={0}
                width={layoutSectionPositionScaled.width}
                height={layoutSectionPositionScaled.height}
                stroke="#00A996"
                strokeWidth="1"
                strokeOpacity={0.5}
                className={classNames('hover-selection-box', {
                  teal: magicLayout,
                })}
                opacity={0.5}
              />
              {hasSectionReordering && (
                <ReorderButtons
                  object={layoutSection}
                  widthScaled={layoutSectionPositionScaled.width}
                  heightScaled={layoutSectionPositionScaled.height}
                  light
                  scale={zoom.scale}
                />
              )}
            </g>
          )}
          {showHandles && (
            <g
              className={classNames('resize-handles', {
                teal: magicLayout,
                'child-object-bounding-box': isChildObject,
              })}
            >
              {widthScaled > 20 && verticalResizeable ? (
                <>
                  <ResizeHandle
                    invisible
                    onMouseDown={this.resize(TOP)}
                    cursor="ns"
                    width={widthScaled}
                    position="top"
                    extraHandleStyle={isSectionContainer && horizontalStyle}
                    showIcon={showIcon === TOP}
                    onMouseEnter={() => this.handleMouseEnter(TOP)}
                    onMouseLeave={this.handleMouseLeave}
                  />
                  <ResizeHandle
                    invisible
                    onMouseDown={this.resize(BOTTOM)}
                    cursor="ns"
                    width={widthScaled}
                    y={heightScaled}
                    position="bottom"
                    extraHandleStyle={isSectionContainer && horizontalStyle}
                    showIcon={showIcon === BOTTOM}
                    onMouseEnter={() => this.handleMouseEnter(BOTTOM)}
                    onMouseLeave={this.handleMouseLeave}
                  />
                </>
              ) : null}
              {heightScaled > 20 || !verticalResizeable ? (
                <>
                  <ResizeHandle
                    invisible={verticalResizeable}
                    onMouseDown={this.resize(LEFT)}
                    cursor="ew"
                    height={heightScaled}
                    position="left"
                    extraHandleStyle={isSectionContainer && verticalStyle}
                    showIcon={showIcon === LEFT}
                    onMouseEnter={() => this.handleMouseEnter(LEFT)}
                    onMouseLeave={this.handleMouseLeave}
                  />
                  <ResizeHandle
                    invisible={verticalResizeable}
                    onMouseDown={this.resize(RIGHT)}
                    cursor="ew"
                    height={heightScaled}
                    x={widthScaled}
                    position="right"
                    extraHandleStyle={isSectionContainer && verticalStyle}
                    showIcon={showIcon === RIGHT}
                    onMouseEnter={() => this.handleMouseEnter(RIGHT)}
                    onMouseLeave={this.handleMouseLeave}
                  />
                </>
              ) : null}

              {hideDiagonal ? null : (
                <React.Fragment>
                  <ResizeHandle
                    onMouseDown={this.resize(LEFT, TOP)}
                    cursor="nwse"
                  />
                  <ResizeHandle
                    onMouseDown={this.resize(RIGHT, TOP)}
                    cursor="nesw"
                    x={widthScaled}
                  />
                  <ResizeHandle
                    onMouseDown={this.resize(LEFT, BOTTOM)}
                    cursor="nesw"
                    y={heightScaled}
                  />
                  <ResizeHandle
                    onMouseDown={this.resize(RIGHT, BOTTOM)}
                    cursor="nwse"
                    x={widthScaled}
                    y={heightScaled}
                  />
                </React.Fragment>
              )}
            </g>
          )}
        </g>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, { object }) => {
  const hoverSelection = getHoverSelection(state)
  const hoveredObject = selectObject(state, hoverSelection[0])
  const hoveredObjectParent = getParentComponent(state, hoveredObject?.id)

  /** @type {import('ducks/editor/types/ObjectList').ObjectList} */
  const objectList = getObjectList(state)
  /** @type {import('ducks/editor/types/ObjectPathMap').ObjectPathMap} */
  const objectPathmap = getMap(state)

  const renderSectionOutline =
    isDirectChildOfSectionContainer(object.id, {
      list: objectList,
      map: objectPathmap,
    }) || isContainerSectionElement(object)

  const layoutSection = renderSectionOutline
    ? getSectionFromChild(objectList, objectPathmap, object)
    : null

  const layoutSectionPosition = renderSectionOutline
    ? getObjectPosition(state, layoutSection?.id) // prettier-ignore
    : null

  const layoutSectionContainerPosition =
    object.type === LAYOUT_SECTION // prettier-ignore
      ? getObjectPosition(state, getContainerFromSection(object)?.id)
      : null

  const component = getComponent(state, object.id)
  const deviceType = component ? getDeviceType(component.width) : DeviceType.MOBILE // prettier-ignore

  return {
    app: getApp(state, getCurrentAppId(state)),
    component,
    deviceType,
    object: getDeviceObject(object, deviceType),
    parent: getParentComponent(state, object.id),
    yOffset: getYOffset(state),
    hasUpdatedGroups: getFeatureFlag(state, 'hasUpdatedGroups'),
    hasSectionReordering: getFeatureFlag(state, 'hasSectionReordering'),
    isSectionContainer: isContainerSectionElement(object),
    hoveredObjectParent,
    layoutSection,
    layoutSectionPosition,
    layoutSectionContainerPosition,
  }
}

export default connect(mapStateToProps)(BoundingBox)
