import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import features, { FeatureValue } from './features'

type Props = {
  feature: FeatureValue
  selectedFeature: FeatureValue
  selectFeature: (feature: FeatureValue) => void
}

const FeatureOption = (props: Props): JSX.Element => {
  const { feature, selectedFeature, selectFeature } = props
  const onClick = () => selectFeature(feature)
  const isActive = feature === selectedFeature
  const { title } = features[feature]
  const color = isActive ? 'teal' : 'midGray'

  return (
    <Button
      onClick={onClick}
      className="premium-feature-option"
      focussed={isActive}
      white
      outlined
    >
      <p className="premium-feature-option-text">{title}</p>
      <Icon type="check-circle" color={color} />
    </Button>
  )
}

export default FeatureOption
