/* eslint-disable no-underscore-dangle */

import { useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import deepEqual from 'deep-equal'

import Icon, { IconButton } from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import Tooltip from 'components/Shared/Tooltip'
import { GroupedAccordion } from 'components/Shared/Accordion'
import type { CollectionActionsType } from 'components/AppDetail/Data/DBAssistant/types'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'

import { closeAccordion } from 'ducks/accordions'
import type {
  SuggestedSchemaTable,
  SuggestedSchemaField,
  MagicFeatureType,
} from 'ducks/dbAssistant'

import { getFieldIcon, getIcon } from 'utils/icons'
import { getOptions } from 'utils/dataTypes'
import ErrorMessage from '../../ErrorMessage'

import {
  updateCollectionName,
  updatePropertyName,
  removeCollection,
  removeCollectionProperty,
} from '../state'

import { THEMES } from '../../../../../../../constants'

import './styles.scss'

const customComparator = (
  optionValue: string,
  value: { type: string } | string
): boolean => {
  if (typeof value === 'object' && value?.type) {
    return deepEqual('relationship', optionValue)
  }

  return deepEqual(value, optionValue)
}

type TypeOptions = {
  label: string
  value: string
  icon: string | null
  dataset: {
    'adalo-id': string
  }
}[]

type Props = {
  limits?: MagicFeatureType['limits']
  schema?: MagicFeatureType['schema']
  onAccept: (tables: SuggestedSchemaTable[]) => Promise<void>
  handleGoBack: () => void
  errorMessage: string
  closeAccordion: (group: string) => void
  feature: 'magicStart' | 'magicAdd'
  handleAllChangesRemoved: () => void
}

const SuggestedFieldWithEditing = ({
  field,
  tableIsNew = false,
  actions,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  closeAccordion,
  typeOptions,
}: {
  field: SuggestedSchemaField
  tableIsNew?: boolean
  actions: CollectionActionsType
  closeAccordion: (group: string) => void
  typeOptions: TypeOptions
}): JSX.Element => {
  const [name, setName] = useState(field.name)
  const [originalName, setOriginalName] = useState(field.name)

  const fieldIconType =
    field.type === 'relationship'
      ? getIcon(field.type, field.relationship?.type)
      : getFieldIcon(field.type)

  const fieldName =
    field.type === 'relationship' && field.isNew ? (
      <Tooltip
        triggerClassname="title"
        key={originalName}
        tooltip={field.relationship?.description as string}
      >
        <p>{originalName}</p>
      </Tooltip>
    ) : (
      <p>{originalName}</p>
    )

  const title = (expanded: boolean) => (
    <>
      {fieldName}
      {expanded === false && tableIsNew === false && field.isNew === true && (
        <Icon small type="adalo-sparkles" className="accordion-title-icon" />
      )}
      {expanded === true && !field.locked && (
        <IconButton
          small
          type="trash"
          className="accordion-title-icon"
          onClick={() =>
            actions.removeCollectionProperty(
              field._id,
              field._relationship_pair_id || ''
            )
          }
        />
      )}
      {expanded === true && field.locked === true && (
        <Icon small type="lock" className="accordion-title-icon" />
      )}
    </>
  )

  const fieldType = useMemo(() => {
    if (field.type === 'relationship') {
      return field.relationship?.collection
    }

    return field.type
  }, [field])

  const renderField = () => {
    const executeSubmit = () => {
      setOriginalName(name)
      actions.updatePropertyName(field._id, name)
    }

    return (
      <GroupedAccordion
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title={title(false)}
        expandedTitle={title(true)}
        group="suggested-collection-fields"
        itemId={field._id}
        disabled={field.isNew === false}
        isEditable
        hideCarret
        description={null}
        icon={fieldIconType}
        iconMedium
        renderChildren={() => (
          <div className="suggested-collection-fields form-inspect-field-wrapper">
            <div className="library-inspect-menu-control">
              <p>Type</p>
              <WrappedSelect
                name="type"
                placeholder="Select Type"
                options={typeOptions}
                comparator={customComparator}
                disabled
                value={fieldType}
                menuTheme={THEMES.DATA}
              />
            </div>
            <div className="library-inspect-text-control">
              <p>Name</p>
              <div className="library-inspect-text-control-input-wrapper">
                <input
                  name="name"
                  placeholder="Property Name"
                  className="library-inspect-text-control-input"
                  onChange={e => setName(e.target.value)}
                  value={name}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      executeSubmit()
                    }
                  }}
                />
              </div>
            </div>
            <div className="actions">
              <Button
                type="button"
                text
                onClick={() => {
                  if (originalName !== name) {
                    setName(originalName)
                  }
                  closeAccordion('suggested-collection-fields')
                }}
              >
                Cancel
              </Button>
              <Button type="button" onClick={() => executeSubmit()} orange>
                Save
              </Button>
            </div>
          </div>
        )}
      />
    )
  }

  return !field.isNew ? (
    <Tooltip
      placement="right"
      tooltip="You cannot edit pre-existing properties."
    >
      {renderField()}
    </Tooltip>
  ) : (
    renderField()
  )
}
const WrappedSuggestedFieldWithEditing = connect(undefined, { closeAccordion })(SuggestedFieldWithEditing) // prettier-ignore

const CollectionTable = ({
  id,
  fields,
  name,
  isLast,
  isNew = false,
  actions,
  typeOptions,
}: {
  id: string
  fields: SuggestedSchemaTable['fields']
  name: SuggestedSchemaTable['name']
  isLast: boolean
  isNew: boolean
  actions: CollectionActionsType
  typeOptions: TypeOptions
}) => {
  const [isEditingCollectionName, setIsEditingCollectionName] = useState(false)
  const [tableName, setTableName] = useState(name)
  const [originalTableName, setOriginalTableName] = useState(name)

  const newFieldCount = fields.filter(field => field.isNew === true).length

  const title = (expanded: boolean) => {
    if (isEditingCollectionName) {
      const executeSubmit = () => {
        setOriginalTableName(tableName)
        actions.updateCollectionName(id, tableName)
        setIsEditingCollectionName(!isEditingCollectionName)
      }

      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'white',
            marginLeft: '24px',
            border: '1px solid black',
          }}
        >
          <input
            name="name"
            placeholder="Collection Name"
            className="library-inspect-text-control-input"
            style={{
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '8px',
              paddingRight: 0,
              height: '26px',
            }}
            onChange={e => setTableName(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                executeSubmit()
              }
            }}
            value={tableName}
          />
          <IconButton small type="check" onClick={() => executeSubmit()} />
          <IconButton
            small
            type="close"
            onClick={() => {
              if (originalTableName !== tableName) {
                setTableName(originalTableName)
              }
              setIsEditingCollectionName(!isEditingCollectionName)
            }}
          />
        </div>
      )
    }

    return (
      <>
        <div className="suggested-collection-name">
          {originalTableName}
          {!isNew && newFieldCount > 0 && (
            <div
              style={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#8F8F8F',
              }}
            >
              {newFieldCount} properties added
            </div>
          )}
        </div>
        {expanded === false && isNew === true && (
          <Icon medium type="adalo-sparkles" className="accordion-title-icon" />
        )}
        {expanded === true && isNew === true && (
          <IconButton
            small
            type="pencil-small"
            className="accordion-title-icon"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation()
              setIsEditingCollectionName(!isEditingCollectionName)
            }}
          />
        )}
      </>
    )
  }

  return (
    <div
      key={originalTableName}
      className={classNames({
        'suggested-collection-wrapper': true,
        last: isLast,
      })}
    >
      <GroupedAccordion
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title={title(false)}
        expandedTitle={title(true)}
        group="suggested-collection"
        itemId={id}
        hideCarret={isEditingCollectionName}
        disableTitleToggle={isEditingCollectionName}
        description={null}
        icon={isEditingCollectionName ? null : 'collections'}
        iconMedium
        renderChildren={() => (
          <div>
            {fields.map(field => (
              <div key={field._id} className="data-accordion-item">
                <WrappedSuggestedFieldWithEditing
                  key={field.name}
                  field={field}
                  tableIsNew={isNew}
                  actions={actions}
                  typeOptions={typeOptions}
                />
              </div>
            ))}
            {isNew && (
              <div className="back-button-container">
                <Button
                  fitContent
                  square
                  text
                  onClick={() => {
                    if (
                      // eslint-disable-next-line no-alert
                      window.confirm(
                        'Are you sure you want to remove this collection?'
                      )
                    ) {
                      actions.removeCollection(id)
                    }
                  }}
                >
                  REMOVE COLLECTION
                </Button>
              </div>
            )}
          </div>
        )}
      />
    </div>
  )
}

const CollectionsList = ({
  title,
  tables,
  actions,
  typeOptions,
}: {
  title: string
  tables: SuggestedSchemaTable[]
  actions: CollectionActionsType
  typeOptions: TypeOptions
}) =>
  tables.length === 0 ? null : (
    <>
      <div className="suggested-collections-section-title">
        <p>{title}</p>
      </div>
      {tables.map(({ _id, fields, name, isNew = false }, index) => (
        <CollectionTable
          key={name}
          id={_id}
          fields={fields}
          name={name}
          isNew={isNew}
          isLast={index + 1 === tables.length}
          actions={actions}
          typeOptions={typeOptions}
        />
      ))}
    </>
  )

function SchemaPreview({
  limits,
  schema,
  onAccept,
  handleGoBack,
  errorMessage,
  feature,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  closeAccordion,
  handleAllChangesRemoved,
}: Props): JSX.Element | null {
  if (!schema || !limits) {
    return null
  }

  const [tables, setTables] = useState<SuggestedSchemaTable[]>(schema.tables)

  const newCollectionsTables = useMemo(
    () => tables.filter(table => table.isNew === true && table.hasNewFields === true), // prettier-ignore
    [tables]
  )

  const updatedCollectionsTables = useMemo(
    () =>
      tables
        .filter(
          table =>
            table.isNew === false &&
            table.fields.filter(field => field.isNew && !field.isHidden)
              .length > 0
        )
        .map(table => {
          const fields = table.fields.filter(field => !field.isHidden)

          return {
            ...table,
            fields: [
              ...fields.filter(field => field.isNew),
              ...fields.filter(field => !field.isNew),
            ],
          }
        }),
    [tables]
  )

  const unchangedCollectionsTables = useMemo(
    () =>
      tables
        .filter(table => table.isNew === false && table.hasNewFields === false)
        .map(table => ({
          ...table,
          fields: table.fields.filter(field => !field.isHidden),
        })),
    [tables]
  )

  const allChangesRemoved = useMemo(() => {
    const hasNewTables = tables.some(table => table.isNew)
    const hasNewTableFields = tables.some(
      table =>
        table.fields.filter(field => field.isNew && !field.isHidden).length > 0
    )

    return !hasNewTables && !hasNewTableFields
  }, [tables])

  useEffect(() => {
    // Handle all suggested changes being removed

    if (allChangesRemoved) {
      handleAllChangesRemoved()
    }
  }, [tables, handleAllChangesRemoved])

  const actions = {
    updateCollectionName: (id: string, name: string) => setTables(updateCollectionName(tables, id, name)), // prettier-ignore
    updatePropertyName: (id: string, name: string) => {
      setTimeout(() => setTables(updatePropertyName(tables, id, name)), 500)
      closeAccordion('suggested-collection-fields')
    },
    removeCollection: (id: string) => setTables(removeCollection(tables, id)),
    removeCollectionProperty: (id: string, relationshipPairId: string) => {
      setTimeout(() => setTables(removeCollectionProperty(tables, id, relationshipPairId)), 500) // prettier-ignore
      closeAccordion('suggested-collection-fields')
    },
  }

  const collectionLists = [
    { title: 'New Collections', tables: newCollectionsTables },
    { title: 'Updated Collections', tables: updatedCollectionsTables },
    { title: 'Unchanged Collections', tables: unchangedCollectionsTables },
  ]

  const isFeatureAvailable =
    feature === 'magicStart'
      ? limits.schemaGenerateAvailable
      : limits.schemaUpdateAvailable

  const typeOptions = useMemo(() => {
    const options = getOptions(tables)

    for (const table of tables) {
      options.push({
        label: table.name,
        value: table.name,
        icon: 'relationship',
        dataset: {
          'adalo-id': table._id,
        },
      })
    }

    return options
  }, [tables])

  return (
    <div className="suggested-collections-section">
      {collectionLists.map(props => (
        <CollectionsList
          {...props}
          key={props.title}
          actions={actions}
          typeOptions={typeOptions}
        />
      ))}

      {tables.length > 0 === true ? (
        <Button
          fitContent
          orange
          gradient
          onClick={() => onAccept(tables)}
          disabled={allChangesRemoved}
        >
          Add To Database
        </Button>
      ) : null}

      <ErrorMessage errorMessage={errorMessage} />

      <div className="magic-start-generate-again">
        {isFeatureAvailable === true ? (
          <div
            className="magic-start-generate-again-button"
            onClick={handleGoBack}
            aria-hidden
          >
            <Icon medium type="refresh" />
            <span>Start Over</span>
          </div>
        ) : null}
        {typeof limits.remaining === 'number' ? (
          <p className="magic-start-generate-again-text">
            You have {limits.remaining} Generations left this month
          </p>
        ) : null}
      </div>
    </div>
  )
}

const WrappedSchemaPreview = connect(undefined, { closeAccordion })(SchemaPreview) // prettier-ignore

export default WrappedSchemaPreview
