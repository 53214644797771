import React, { useEffect, useState, forwardRef, useRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import './Predictions.scss'
import POWERED_BY_GOOGLE from './powered_by_google_on_white.png'

const PREDICTION_TILE_HEIGHT = 40
const OFFSET = 20

const PredictionsPortal = ({ children }) =>
  createPortal(children, document.body)

/**
 * Some very rudimentary positioning and sizing...
 * The prediction box is React Portal-ed, absolutely positioned
 * and its top left corner is aligned with the input's
 * BOTTOM (top + input height) left corner
 * and its width is set to be the same as the input width
 */
const getPositioning = (inputId, panelView) => {
  const input = document.getElementById(inputId)
  const { clientWidth: inputWidth, clientHeight: inputHeight } = input
  const { top, left } = input.getBoundingClientRect()

  if (panelView) {
    return {
      top: top + inputHeight,
      left: left - 16,
      width: inputWidth + 32,
    }
  }

  return {
    top: top + inputHeight,
    left,
    width: inputWidth,
  }
}

const Predictions = forwardRef(
  (
    {
      inputId,
      predictions,
      closePredictions,
      choosePrediction,
      panelView,
      inFormField,
    },
    containerRef
  ) => {
    const [activePredictionIndex, setActivePredictionIndex] = useState(0)
    const [height, setHeight] = useState(0)
    const predictionsRef = useRef()

    /**
     * Calculates the height of the predictions div
     * First calculates where it is in relation to the window's x-axis
     * minus the Powered by Google footer (which should always be visible)
     * and some padding (offset) between it and the bottom of the window
     *
     * Then calculates its optimal height, which should fit all of the predictions
     *
     * Then sets the height to be the minimum of the two calculations
     */
    useEffect(() => {
      const { top: predictionsTop } =
        predictionsRef.current.getBoundingClientRect()

      const newHeight =
        window.innerHeight - predictionsTop - (PREDICTION_TILE_HEIGHT + OFFSET)

      setHeight(
        Math.min(newHeight, predictions.length * PREDICTION_TILE_HEIGHT)
      )
    }, [predictions])

    // Handles keyboard input: scrolling, selecting, and tabbing away
    useEffect(() => {
      const keyScroller = e => {
        switch (e.code) {
          case 'ArrowDown':
            e.preventDefault()

            return setActivePredictionIndex(currentActivePrediction => {
              const nextIndex =
                (currentActivePrediction + 1) % predictions.length

              predictionsRef.current.scrollTop =
                nextIndex % 2 === 0
                  ? nextIndex * PREDICTION_TILE_HEIGHT
                  : (nextIndex - 1) * PREDICTION_TILE_HEIGHT

              return nextIndex
            })
          case 'ArrowUp':
            e.preventDefault()

            return setActivePredictionIndex(currentActivePrediction => {
              const nextIndex =
                currentActivePrediction === 0
                  ? predictions.length - 1
                  : currentActivePrediction - 1

              predictionsRef.current.scrollTop =
                nextIndex % 2 === 0
                  ? (nextIndex - 1) * PREDICTION_TILE_HEIGHT
                  : nextIndex * PREDICTION_TILE_HEIGHT

              return nextIndex
            })
          case 'Enter':
            e.preventDefault()

            return choosePrediction(predictions[activePredictionIndex])
          case 'Tab':
          case 'Escape':
            return closePredictions()
        }
      }

      document.addEventListener('keydown', keyScroller)

      return () => {
        document.removeEventListener('keydown', keyScroller)
      }
    }, [predictions, activePredictionIndex])

    return (
      <PredictionsPortal>
        <div
          className="predictions-container"
          style={getPositioning(inputId, panelView)}
          ref={containerRef}
        >
          <div
            className="predictions-container__body"
            style={{ height }}
            ref={predictionsRef}
          >
            {predictions.map((prediction, index) => (
              <button
                key={prediction.id}
                onClick={() => choosePrediction(prediction)}
                onMouseOver={() => setActivePredictionIndex(index)}
                onFocus={() => {}}
                style={{
                  height: PREDICTION_TILE_HEIGHT,
                  background:
                    index === activePredictionIndex
                      ? inFormField
                        ? '#f1f1f1'
                        : '#ef4c301a'
                      : 'inherit',
                }}
                className="predictions-container__prediction"
              >
                <span className="predictions-container__icon material-icons">
                  location_on
                </span>
                <div className="predictions-container__title-container">
                  {
                    <span className="predictions-container__title predictions-container__title--primary">
                      {prediction.primaryText}
                    </span>
                  }{' '}
                  <span className="predictions-container__title predictions-container__title--secondary">
                    {prediction.secondaryText}
                  </span>
                </div>
              </button>
            ))}
          </div>
          <div className="predictions-container__footer">
            <img src={POWERED_BY_GOOGLE} alt="Powered by Google" width={120} />
          </div>
        </div>
      </PredictionsPortal>
    )
  }
)

Predictions.propTypes = {
  /**
   * A unique identifier for the search input field
   * Used by the predictions container to position itself
   */
  inputId: PropTypes.string.isRequired,
  /**
   * An array of predictions; id and primaryText are required fields
   */
  predictions: PropTypes.arrayOf((propValue, key) => {
    if (!propValue[key].id) {
      return new Error(`"id" is a required field for predictions`)
    }

    if (!propValue[key].primaryText) {
      return new Error(`"primaryText" is a required field for predictions`)
    }
  }).isRequired,
  /**
   * Called when a user clicks outside of the prediction container
   * Closes the prediction menu
   */
  closePredictions: PropTypes.func.isRequired,
  /**
   * Called when a user clicks on a prediction
   * Takes the prediction object as an argument
   */
  choosePrediction: PropTypes.func.isRequired,
}

export default Predictions
