import React from 'react'

const AdaloStarBullet = ({ fill }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style={{ enableBackground: 'new 0 0 24 24' }}
    xmlSpace="preserve"
    className="adalo-bullet"
  >
    <path
      style={{ fill, opacity: fill === '#333' ? 0.7 : 1 }}
      d="M14.9,14.8l7.8-2.4c0.3-0.1,0.3-0.6,0-0.7l-7.8-2.4c-0.1,0-0.2-0.1-0.2-0.2l-2.4-7.7c-0.1-0.3-0.6-0.3-0.7,0L9.2,9.1
		c0,0.1-0.1,0.2-0.2,0.2l-7.6,2.4c-0.3,0.1-0.3,0.6,0,0.7l7.6,2.4c0.1,0,0.2,0.1,0.2,0.2l2.4,7.7c0.1,0.3,0.6,0.3,0.7,0l2.4-7.7
		C14.7,14.9,14.8,14.8,14.9,14.8z"
    />
  </svg>
)

export default AdaloStarBullet
