import React, { Component } from 'react'
import { IconButton } from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import { connect } from 'react-redux'
import {
  deleteApiKey,
  getApiKey,
  createApiKey,
  updateApiKey,
} from 'ducks/apikeys'
import classNames from 'classnames'
import { getTrialState } from 'ducks/organizations'

class ApiKeyItem extends Component {
  state = { hideApiKey: true }

  handleSetApiKey = async () => {
    const { appId, appApiKey, createApiKey, updateApiKey } = this.props

    try {
      if (appApiKey) {
        const confirm = window.confirm(
          `Are you sure you want to regenerate the API Key?`
        )

        if (confirm) {
          await updateApiKey(appId)
          this.setState({ hideApiKey: false })
        }
      } else {
        await createApiKey(appId)
        this.setState({ hideApiKey: false })
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleDeleteApiKey = async () => {
    const { appId, deleteApiKey } = this.props

    try {
      const confirm = window.confirm(
        `Are you sure you want to delete the API Key?`
      )

      if (confirm) {
        await deleteApiKey(appId)
      }
    } catch (err) {
      console.log(err)
    }
  }

  toggleShowApiKey = () => {
    this.setState(state => ({ hideApiKey: !state.hideApiKey }))
  }

  render() {
    const { appApiKey, inIntegrationTrial } = this.props
    const { hideApiKey } = this.state

    return (
      <>
        {appApiKey ? (
          <>
            <div className="app-settings-api-token">
              <div className="app-settings-api-token-number">
                <form autoComplete="off">
                  <input
                    className="app-settings-api-token-number"
                    name="apikey"
                    id="apikayInput"
                    value={hideApiKey ? '••••••••••••••••' : appApiKey}
                    onChange={() => null}
                    type="text"
                    spellCheck="false"
                  />
                </form>
              </div>
              <div className="app-settings-api-token-icon">
                <span onClick={this.toggleShowApiKey}>
                  <IconButton type="eye" />
                </span>
              </div>
            </div>
            <div
              className={`app-settings-api-buttons ${
                inIntegrationTrial ? 'app-settings-trial' : ''
              }`}
            >
              <div className="app-settings-api-buttons">
                <Button
                  text
                  small
                  className="app-settings-api-buttons delete"
                  onClick={this.handleDeleteApiKey}
                >
                  Delete API Key
                </Button>
              </div>
              <div className="app-settings-api-buttons">
                <Button
                  text
                  small
                  className="app-settings-api-buttons regenerate"
                  onClick={this.handleSetApiKey}
                >
                  Regenerate API Key
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <p>
                This is required to use the Adalo API for this app. The Adalo
                API enables 3rd party applications to send push notifications or
                interact with app data.{' '}
                <a
                  href="https://help.adalo.com/integrations/the-adalo-api"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </p>
            </div>
            <div>
              <Button
                onClick={this.handleSetApiKey}
                className={classNames('api-settings-generate-key', {
                  'during-trial': inIntegrationTrial,
                })}
              >
                Generate key
              </Button>
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, { appId }) => {
  const { trialState } = getTrialState(state)

  return {
    appApiKey: getApiKey(state, appId),
    inIntegrationTrial: trialState === 'during',
  }
}

export default connect(mapStateToProps, {
  deleteApiKey,
  createApiKey,
  updateApiKey,
})(ApiKeyItem)
