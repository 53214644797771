import { positioning as positioningValues } from '@adalo/constants'

export type PositioningType =
  typeof positioningValues[keyof typeof positioningValues]

const { FIXED_TOP, FIXED: FIXED_MIDDLE, FIXED_BOTTOM } = positioningValues

/*
 * Duplicated from backend: lib/layout/responsive/position
 */
export const TOP_FIXED_THRESHOLD = 132
export const BOTTOM_FIXED_THRESHOLD = 132

const inferFixedPositionAnchor = (
  objectY: number,
  objectHeight: number,
  screenHeight: number
): PositioningType => {
  const objectBottom = objectY + objectHeight

  // Update positioning for sticky elements
  // Note: this logic mirrors addFixedOnScrollStyles() from backend (lib/layout/responsive/position)
  if (objectY <= TOP_FIXED_THRESHOLD) {
    return FIXED_TOP
  } else if (objectBottom >= screenHeight - BOTTOM_FIXED_THRESHOLD) {
    return FIXED_BOTTOM
  }

  return FIXED_MIDDLE
}

export default inferFixedPositionAnchor
