import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { dataTypes } from '@adalo/constants'
import {
  getCurrentAppId,
  getMap,
  getObjectList,
  updateObject as updateObjectAction,
} from 'ducks/editor/objects'
import { closeAccordion, openAccordion } from 'ducks/accordions'
import { getDefaultDatasource, getOrderedFields } from 'ducks/apps/datasources'
import getContainingScreen from 'ducks/editor/objects/helpers/getContainingScreen'
import type { State } from 'ducks/editor/objects/selectors'

import { type TableField, EditorObject } from 'utils/responsiveTypes'
import ActionRow from 'components/Editor/Actions/Row'
import EmptyState from 'components/Shared/EmptyState'
import StylesAccordion from 'components/Shared/StylesAccordion'
import Icon from 'components/Shared/Icon'
import { GroupedAccordion } from 'components/Shared/Accordion'
import { Datasource } from 'components/Editor/XanoExternalDatabaseModal/lib/user'

import type { AccordionGroup, RelatedField, TableEditorObject } from './types'
import { SortableFieldItemsList } from './TableField'
import {
  ACCORDION_FIELD_GROUP,
  ACCORDION_GROUP,
  ACCORDION_GROUP_DEFAULT_ACCORDION,
  getInitialTableFields,
  tableHasAdaloExternalCollection,
} from './utils'
import { makeTableField } from './bindings'
import SimpleTextControl from '../SimpleTextControl'
import ListControl from '../../ListControl'
import { THEMES } from '../../../../../constants'
import InspectBody from '../../Body'
import {
  addField,
  deleteField,
  getFields,
  getTableFieldsFromDatasource,
  sortFields,
  updateField,
} from './fields'
import PropControl from '../PropControl'

import './Table.scss'

interface TableProps {
  object: TableEditorObject
  appId: string
  component: EditorObject
  datasource: Datasource
  updateObject: (id: string, changes: Partial<EditorObject>) => void
  onChange: (changes: Partial<EditorObject>) => void
}

const Table = ({
  object,
  appId,
  component,
  datasource,
  updateObject,
  onChange,
}: TableProps): JSX.Element => {
  const dispatch = useDispatch()
  // unmount
  useEffect(() => {
    dispatch(openAccordion(ACCORDION_GROUP, ACCORDION_GROUP_DEFAULT_ACCORDION))
    dispatch(closeAccordion(ACCORDION_FIELD_GROUP))

    return () => {
      dispatch(closeAccordion(ACCORDION_GROUP))
      dispatch(closeAccordion(ACCORDION_FIELD_GROUP))
    }
  }, [object.id])

  const update = (changes: Partial<EditorObject>): void =>
    updateObject(object.id, changes)

  const handleAddField = ({
    field,
    related,
  }: {
    field: string
    related: RelatedField
  }): void =>
    update({
      fields: addField(datasource, object, field, related),
    })

  const handleUpdateField = (
    updatedField: TableField,
    fieldIndex: number
  ): void => update({ fields: updateField(object, updatedField, fieldIndex) })

  const handleDeleteField = (index: number): void =>
    update({ fields: deleteField(object, index) })

  const handleSortFields = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }): void => update({ fields: sortFields(object, oldIndex, newIndex) })

  const onChangeDatasource = ({
    dataBinding,
  }: {
    dataBinding: EditorObject['dataBinding']
  }): void => {
    const tableId = dataBinding?.source?.tableId

    if (!tableId) {
      throw new Error('tableId is required')
    }

    const oldTableId = object.dataBinding?.source?.tableId

    // In the case where the Collection hasn't changed, we don't need to reset the fields
    if (tableId === oldTableId) {
      onChange({ dataBinding })

      return
    }

    const table = datasource.tables[tableId]
    if (!table) {
      throw new Error(`Table not found for table id: ${tableId}`)
    }

    const datasourceFields = getInitialTableFields(datasource, tableId)

    const fieldsOrder = getOrderedFields(table)
    const orderedFields = fieldsOrder.filter(id => datasourceFields[id])

    const fields = orderedFields.map(prop =>
      makeTableField({ ...object, dataBinding }, datasourceFields, prop)
    )

    onChange({ dataBinding, fields })
  }

  const { fields } = object

  const handlePageSizeChange = (change: { pageSize?: number | string }) => {
    let newPageSize: number | undefined = Number(change.pageSize) ?? undefined
    if (Number.isNaN(newPageSize) || !newPageSize) {
      newPageSize = undefined
    }

    update({ attributes: { ...object.attributes, pageSize: newPageSize } })
  }

  return (
    <>
      {/* Accordion - Table */}
      <ListControl
        key="table-control"
        overrideGroupId={ACCORDION_GROUP}
        itemId="table-component-data"
        listTitle="Table"
        datasource={datasource}
        hideActions
        hideComponents
        hideColumnSpacing
        hideAdvancedOptions
        componentId={component.id}
        object={object}
        dataSelectDisplayName="What is this a table of?"
        onChange={onChangeDatasource}
        otherControls={
          <>
            {tableHasAdaloExternalCollection(datasource, object) === false && (
              <SimpleTextControl
                inputType="number"
                name="pageSize"
                value={object.attributes.pageSize}
                onChange={handlePageSizeChange}
                displayName="Items per page"
                placeholder=""
              />
            )}
            <div style={{ marginTop: '16px' }}>
              <StylesAccordion
                spaced
                accordionItemId="table-styles"
                title="Table Styles"
                renderChildren={() => (
                  <InspectBody featuresSubset="tableData" objects={[object]} />
                )}
              />
            </div>
          </>
        }
      />

      {/* Accordion - Columns */}
      {typeof object.dataBinding !== 'undefined' && (
        <GroupedAccordion
          className="library-inspect-accordion table-columns"
          group={ACCORDION_GROUP}
          itemId="table-component-columns"
          title="Columns"
          renderChildren={() => (
            <div className="form-inspect-fields-section">
              <p className="form-inspect-fields-title">Table Columns</p>
              <SortableFieldItemsList
                items={fields}
                object={object}
                tableFields={getTableFieldsFromDatasource(datasource, object)}
                onDelete={handleDeleteField}
                onSortEnd={handleSortFields}
                onChange={handleUpdateField}
                distance={5}
              />
              {fields.length === 0 && (
                <EmptyState>No Visible Columns</EmptyState>
              )}
              <MultiMenuTrigger
                className="form-inspect-add-field"
                menu={() =>
                  getFields(
                    object,
                    datasource,
                    getTableFieldsFromDatasource(datasource, object)
                  )
                }
                onSelect={handleAddField}
                menuTheme={THEMES.DATA}
              >
                <Icon type="plus" />
                <span>Add Column</span>
              </MultiMenuTrigger>
            </div>
          )}
        />
      )}

      {/* Accordion - Empty State */}
      <GroupedAccordion
        className="library-inspect-accordion"
        group={ACCORDION_GROUP}
        itemId={JSON.stringify({
          key: 'table-component-empty-state',
          subject: object.id,
        } as AccordionGroup)}
        title="Empty State"
        renderChildren={() => (
          <div className="form-inspect-fields-section">
            <PropControl
              objectId={object.id}
              onChange={(changes: Partial<EditorObject>) => update(changes)}
              value={object.emptyStateTitleText}
              values={object}
              prop={{
                name: 'emptyStateTitleText',
                displayName: 'Title Text',
                type: dataTypes.TEXT,
                styles: object.styles.emptyStateTitleText,
              }}
              disableCurrentRecord
            />

            <PropControl
              objectId={object.id}
              onChange={(changes: Partial<EditorObject>) => update(changes)}
              value={object.emptyStateBodyText}
              values={object}
              prop={{
                name: 'emptyStateBodyText',
                displayName: 'Body Text',
                type: dataTypes.TEXT,
                styles: object.styles.emptyStateBodyText,
              }}
              disableCurrentRecord
            />
          </div>
        )}
      />

      {/* Accordion - Row Actions */}
      <GroupedAccordion
        className="library-inspect-accordion"
        group={ACCORDION_GROUP}
        itemId="table-component-row-actions"
        title="Row Actions"
        renderChildren={() => (
          <div className="form-inspect-fields-section">
            <ActionRow
              displayName="Click Actions"
              appId={appId}
              componentId={component.id}
              object={object}
            />
          </div>
        )}
      />
    </>
  )
}

const mapStateToProps = (
  state: State,
  { object }: { object: TableEditorObject }
) => {
  const appId = getCurrentAppId(state) as string
  const component = getContainingScreen(
    getObjectList(state),
    getMap(state),
    object.id
  )
  const datasource = getDefaultDatasource(state, appId)

  return {
    appId,
    component,
    datasource,
    object,
  }
}

export default connect(mapStateToProps, {
  updateObject: updateObjectAction,
  openAccordion,
  closeAccordion,
})(Table)
