export const componentBlocklist = {
  '@parousya/parousya-with-payments': [
    'ParousyaWithPayments',
    'ParousyaWithPaymentsSignOutButton',
    'ParousyaWithPaymentsData',
    'ParousyaEndAllSessionButton',
  ],
  'iaphub-public': [
    'IaphubPreStart',
    'IaphubStart',
    'IaphubRestoreButton',
    'IaphubProductPrice',
    'IaphubBuyButton',
    'IaphubManageSubscriptionButton',
  ],
  'video-chat-by-daily': [
    'CreateMeetingTokenButton',
    'CreateRoomButton',
    'DeleteRoomButton',
    'UpdateRoomButton',
  ],
}
