import { ComponentItem } from 'components/Editor/ComponentsAccordion/ComponentItem'
import {
  getClassicComponents,
  libComponentRef,
  getIconStyles,
} from '../ComponentsTab'

export interface Component {
  id: string
  label: string
  icon: string
  value: { type: string; options: Record<string, unknown> } | string
  library?: string
  $ref?: {
    library: string
    component: string
  }
  showView?: boolean
  libraryId?: string
  installed?: boolean
  price?: number
}

export interface Library {
  id: string
  name: string
  label: string
  version: string
  icon: string
  value: {
    options: {
      libraryName: string
      libraryVersion: string
      componentName: string
    }
  }
  showView?: boolean
  libraryId?: string //No
  installed?: boolean
  price?: number
}

export interface PrivateLibrary {
  id: string
  name: string
  version: string
  type: 'private'
  displayName?: string | null
  author?: string
  supportURL?: string | null
  price?: number
  licensed?: boolean
}

type LibraryComponentRef = unknown

export const getInternalComponents = ({
  hasVideoComponent,
  hasTableComponent,
}: {
  hasVideoComponent: boolean
  hasTableComponent: boolean
}): LibraryComponentRef[] => [
  ...Object.values(
    getClassicComponents({ hasVideoComponent, hasTableComponent }) as Record<
      string,
      Component
    >
  ),
  libComponentRef('Navigation', 'Side Navigation'),
  libComponentRef('Navigation', 'Top Navigation'),
  libComponentRef('Material Design', 'Bottom Navigation'),
  libComponentRef('Material Design', 'App Bar'),
  libComponentRef('Material Design', 'Simple List'),
  libComponentRef('Material Design', 'Card List'),
  libComponentRef('Material Design', 'Image List'),
  libComponentRef('Material Design', 'Avatar List'),
  libComponentRef('Material Design', 'Horizontal Card List'),
  libComponentRef('Material Design', 'Horizontal Chip List'),
  libComponentRef('Material Design', 'Button'),
  libComponentRef('Material Design', 'Action Button'),
  libComponentRef('Material Design', 'Icon'),
  libComponentRef('Material Design', 'Toggle'),
]

export const renderComponent = (
  component: Component | Library,
  handleSelect: (
    val: Record<string, unknown> | string | undefined,
    position: [number, number]
  ) => void,
  onViewClick: (id: string) => void
): JSX.Element => {
  const { id, label, value, icon, showView, price, installed } = component
  let library = id

  if (component.libraryId) {
    library = component.libraryId
  }

  return (
    <ComponentItem
      key={id || label}
      name={label}
      iconClassName={icon && `editor-add-component-icon-${icon}`}
      iconStyles={getIconStyles(value)}
      onMouseDown={(e: MouseEvent) => {
        if (!showView) {
          handleSelect(value, [e.clientX, e.clientY])
        }
      }}
      showView={showView}
      onViewClick={() => onViewClick(library)}
      installed={installed}
      price={price}
    />
  )
}
