import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import {
  dataTypes,
  bindingTypes,
  sourceTypes,
  sortDirectionOptions,
  SELECT,
} from '@adalo/constants'

import { getTable } from 'ducks/apps/datasources'

import { getFields, getFieldIds } from 'utils/fields'
import { getTableInfo } from 'utils/sources'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Accordion from 'components/Shared/Accordion'

import FilteringOptions from '../../DataBinding/FilteringFormExtension'

class ListFormExtension extends Component {
  getFieldOptions = (includeNone = false) => {
    const { fields } = this.props

    const simpleTypes = [
      dataTypes.TEXT,
      dataTypes.NUMBER,
      dataTypes.DATE,
      dataTypes.BOOLEAN,
    ]

    const options = []

    if (includeNone) {
      options.push({ label: 'None', value: null })
      options.push(null)
    }

    for (const fieldId of getFieldIds(fields)) {
      const field = fields[fieldId]

      if (simpleTypes.indexOf(field.type) !== -1) {
        options.push({
          label: field.name,
          value: fieldId,
        })
      }
    }

    return options
  }

  getField = fieldId => {
    const { fields } = this.props

    return fields[fieldId]
  }

  render() {
    const { appId, componentId, object, binding } = this.props

    const { source } = binding

    if (source.type === sourceTypes.API_ENDPOINT) {
      return null
    }

    const enableField = !!source
    const enableDirection = enableField && source.sort && source.sort.fieldId

    return (
      <div>
        {object.type === SELECT ? (
          <div className="action-item-split-row">
            <div>
              <label>Label Field</label>
              <Field
                autoSelect
                className="small white"
                name="options.labelField"
                component={WrappedSelect}
                options={this.getFieldOptions()}
                placeholder="Auto"
              />
            </div>
          </div>
        ) : null}
        {source.tableId ? (
          <FilteringOptions
            appId={appId}
            componentId={componentId}
            object={object}
            binding={binding}
          />
        ) : null}
        {source.tableId && binding.bindingType === bindingTypes.LIST ? (
          <Accordion
            title="Sorting"
            renderChildren={() => (
              <div className="action-item-split-row">
                <div>
                  <label>Sorting</label>
                  <Field
                    autoSelect
                    className="small white"
                    name="source.sort.fieldId"
                    component={WrappedSelect}
                    options={this.getFieldOptions()}
                    placeholder="Auto"
                    disabled={!enableField}
                  />
                </div>
                <div>
                  <label>Direction</label>
                  <Field
                    autoSelect
                    className="small white"
                    name="source.sort.direction"
                    component={WrappedSelect}
                    options={sortDirectionOptions}
                    disabled={!enableDirection}
                  />
                </div>
              </div>
            )}
          />
        ) : null}
        <Accordion
          title="Advanced"
          renderChildren={() => (
            <React.Fragment>
              <div className="list-form-extension-realtime">
                <Field
                  component="input"
                  name="options.listenForChanges"
                  type="checkbox"
                />
                <label>Watch realtime changes</label>
              </div>
              <div className="list-form-extension-realtime">
                <Field
                  component="input"
                  name="options.paginate"
                  type="checkbox"
                />
                <label>Use Pagination</label>
              </div>
            </React.Fragment>
          )}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { binding, appId } = props
  let fields = []

  if (binding.source) {
    const { datasourceId, tableId } = getTableInfo(binding.source)

    if (!tableId) {
      return { fields: [] }
    }

    const table = getTable(state, appId, datasourceId, tableId)

    fields = getFields(table, state)
  }

  return { fields }
}

export default connect(mapStateToProps)(ListFormExtension)
