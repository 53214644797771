import { FeatureTemplate } from 'ducks/featureTemplates'
import ReactMarkdown from 'react-markdown'

type DetailsContentProps = {
  feature: FeatureTemplate
}

const DetailsContent: React.FC<DetailsContentProps> = ({
  feature,
}): JSX.Element => {
  const { description } = feature

  return (
    <div className="feature-item__details-content">
      <div className="feature-item__details-content-details">
        <ReactMarkdown
          allowedElements={[
            'p',
            'strong',
            'em',
            'ol',
            'ul',
            'li',
            'a',
            'hr',
            'span',
          ]}
          unwrapDisallowed
          skipHtml
        >
          {description}
        </ReactMarkdown>
      </div>
    </div>
  )
}

export default DetailsContent
