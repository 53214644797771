import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { getFeatureFlag } from 'ducks/featureFlags'
import BindableTextControl from '../Libraries/SimpleTextControl'
import ImageInput from '../../../Shared/Forms/ImageInput'
import { createOrUpdateScreenTemplate } from '../../../../utils/io'
import MenuControl from '../Libraries/MenuControl'
import { updateObject } from '../../../../ducks/editor/objects'
import RightPanelCollapsible from '../../RightPanel/RightPanelCollapsible'
import {
  ensureScreenTemplateCategoriesAreLoaded,
  getScreenTemplatesCategories,
  addNewScreenTemplateCategory,
} from '../../../../ducks/editor/screenTemplates'
import { getAppPlatform } from '../../../../ducks/apps'
import { getCurrentUser } from '../../../../ducks/users/index.ts'
import Modal from '../../../Shared/Modal'
import NewCategoryForm from './NewCategoryForm'

const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const modeOptions = [
  { label: 'Mobile', value: 'mobile' },
  { label: 'Responsive', value: 'responsive' },
  { label: 'Both', value: 'both' },
]

function identity(x) {
  return x
}

const ADD_NEW_CATEGORY = Symbol('ADD_NEW_CATEGORY')

class ScreenTemplateCollapsible extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    hasNewMobileOnlyApp: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      newCategoryModal: false,
      submitting: false,
      values: {
        name: '',
        enabled: true,
        layoutMode: null,
      },
    }

    this.state = {
      ...this.state,
      ...ScreenTemplateCollapsible.getDerivedStateFromProps(props, this.state),
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.object) {
      return null
    }

    const newState = { values: { ...state.values } }

    if (props.object.screenTemplate) {
      const screenTemplate = props.object.screenTemplate

      if (screenTemplate.name !== state.prevScreenTemplateName) {
        newState.values.name = screenTemplate.name
        state.prevScreenTemplateName = screenTemplate.name
      }

      if (screenTemplate.enabled !== state.prevEnabled) {
        newState.values.enabled = screenTemplate.enabled
        newState.prevEnabled = screenTemplate.enabled
      }

      if (screenTemplate.category !== state.prevCategory) {
        newState.values.category = screenTemplate.category
        newState.prevCategory = screenTemplate.category
      }

      if (screenTemplate.thumbnail !== state.prevThumbnail) {
        newState.values.thumbnail = screenTemplate.thumbnail
        newState.prevThumbnail = screenTemplate.thumbnail
      }

      if (screenTemplate.mobileThumbnail !== state.prevMobileThumbnail) {
        newState.values.mobileThumbnail = screenTemplate.mobileThumbnail
        newState.prevMobileThumbnail = screenTemplate.mobileThumbnail
      }

      if (screenTemplate.layoutMode !== state.prevLayoutMode) {
        newState.values.layoutMode = screenTemplate.layoutMode
        newState.prevLayoutMode = screenTemplate.layoutMode
      }
    } else if (props.object.name !== state.prevObjectName) {
      newState.values.name = props.object.name
      newState.prevObjectName = props.object.name
    }

    if (Object.keys(newState).length > 1) {
      return newState
    }

    return null
  }

  componentDidMount() {
    const { isAdmin, platform, ensureScreenTemplateCategoriesAreLoaded } =
      this.props

    if (isAdmin) {
      ensureScreenTemplateCategoriesAreLoaded(platform)
    }
  }

  handleChange = values => {
    if (values.category === ADD_NEW_CATEGORY) {
      this.setState({ newCategoryModal: true })

      return
    }

    if (values.layoutMode === 'both') {
      values.layoutMode = null
    }

    this.setState(state => ({ values: { ...state.values, ...values } }))
  }

  handleNewCategorySubmit = ({ name }) => {
    const { platform, addNewScreenTemplateCategory } = this.props
    addNewScreenTemplateCategory(platform, name)
    this.handleCloseModal()
    this.handleChange({ category: name })
  }

  handleImageChange = url => {
    this.handleChange({ thumbnail: url })
  }

  handleMobileImageChange = url => {
    this.handleChange({ mobileThumbnail: url })
  }

  handleDone = async () => {
    const { appId, updateObject, object } = this.props
    const { values } = this.state
    this.setState({ submitting: true })
    let templateId

    try {
      templateId = await createOrUpdateScreenTemplate(appId, object.id, values)

      updateObject(object.id, {
        screenTemplate: {
          id: templateId,
          ...values,
        },
      })
    } finally {
      this.setState({ submitting: false })
    }
  }

  handleCloseModal = () => {
    this.setState({ newCategoryModal: false })
  }

  getCategories = () => {
    const { categories } = this.props

    return [
      ...categories.map(category => ({
        label: category,
        value: category,
      })),
      null,
      {
        label: <em>New Category...</em>,
        value: ADD_NEW_CATEGORY,
      },
    ]
  }

  render() {
    const {
      appId,
      expanded,
      object,
      onClose,
      onExpand,
      isAdmin,
      hasNewMobileOnlyApp,
    } = this.props

    if (!isAdmin) {
      return null
    }

    const { values, submitting, newCategoryModal } = this.state

    return (
      <>
        <RightPanelCollapsible
          expanded={expanded}
          visible={
            (expanded && values.enabled) ||
            (object.screenTemplate && object.screenTemplate.enabled) ||
            false
          }
          collapsedClassName="right-panel-screen-template-collapsed"
          collapsibleClassName="right-panel-screen-template"
          visibilityControl={
            <MenuControl
              value={values.enabled}
              name="enabled"
              displayName="Do you want to make this a screen template?"
              options={booleanOptions}
              onChange={this.handleChange}
              rowHeight={44}
            />
          }
          iconType="magic-visibility"
          collapsedText="Screen Template"
          onClose={onClose}
          onExpand={onExpand}
          onSubmit={this.handleDone}
          submitLabel="Save"
        >
          {values.enabled ? (
            <>
              <MenuControl
                value={values.category}
                getLabel={identity}
                name="category"
                displayName="Category"
                options={this.getCategories}
                onChange={this.handleChange}
                rowHeight={44}
              />
              <BindableTextControl
                displayName="Name"
                name="name"
                value={values.name}
                onChange={this.handleChange}
                disabled={submitting}
              />
              <ImageInput
                isAsset
                displayName="Thumbnail"
                buttons={['view', 'remove']}
                appId={appId}
                input={{
                  value: values.thumbnail,
                  name: 'thumbnail',
                  onChange: this.handleImageChange,
                }}
              />
              {hasNewMobileOnlyApp && (
                <>
                  <ImageInput
                    isAsset
                    displayName="Mobile Thumbnail"
                    buttons={['view', 'remove']}
                    appId={appId}
                    input={{
                      value: values.mobileThumbnail,
                      name: 'mobileThumbnail',
                      onChange: this.handleMobileImageChange,
                    }}
                  />
                  <MenuControl
                    value={values.layoutMode || 'both'}
                    name="layoutMode"
                    displayName="Available for:"
                    options={modeOptions}
                    onChange={this.handleChange}
                    rowHeight={44}
                  />
                </>
              )}
            </>
          ) : null}
        </RightPanelCollapsible>
        {newCategoryModal ? (
          <Modal onClose={this.handleCloseModal}>
            <NewCategoryForm
              onClose={this.handleCloseModal}
              onSubmit={this.handleNewCategorySubmit}
            />
          </Modal>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state, { appId }) => {
  const currentUser = getCurrentUser(state)
  const platform = getAppPlatform(state, appId)
  const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')

  return {
    isAdmin: !!(currentUser && currentUser.admin),
    platform,
    categories: getScreenTemplatesCategories(state, platform),
    hasNewMobileOnlyApp,
  }
}

export default connect(mapStateToProps, {
  updateObject,
  ensureScreenTemplateCategoriesAreLoaded,
  addNewScreenTemplateCategory,
})(ScreenTemplateCollapsible)
