import classNames from 'classnames'

import Button from 'components/Shared/Button'

import './styles.scss'
import Icon from '../Icon'

export type ItemProps = {
  item: {
    id: string
    name: string
    image: string
    summary?: string
  }
  onClick?: () => void
  showFooter?: boolean
  showCheckmark?: boolean
  isSelected?: boolean
}

const Item: React.FC<ItemProps> = props => {
  const {
    item,
    onClick,
    showFooter = true,
    showCheckmark = false,
    isSelected = false,
  } = props
  const { name, summary, id, image } = item

  const handleClick = () => {
    if (onClick && typeof onClick === 'function') {
      return onClick()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  return (
    <div
      role="button"
      className={classNames('template-item', {
        'template-item--selected': isSelected,
      })}
      key={id}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {showCheckmark ? (
        <div className="template-item__checkmark">
          <Icon type="done" />
        </div>
      ) : null}
      <img src={image} alt={name} />
      <div className="template-item__info">
        <div className="template-item__header">
          <div className="template-item__title">
            <h2>{name}</h2>
          </div>
        </div>
        {Boolean(summary) && <p>{summary}</p>}
      </div>
      {showFooter && (
        <div className="template-item__footer">
          {/* TODO @danicunhac: Uncomment this when screenCount is available
        <div className="feature-item-footer__screenCount">
          {screenCount} Screens
        </div> 
        */}
          <Button teal small>
            View
          </Button>
        </div>
      )}
    </div>
  )
}

export default Item
