import { adaloBackendAxios } from 'utils/io/http/axios'

const UPDATE = 'UPDATE_SUBSCRIPTION'
const CONFIGURE_EXISTING = 'CONFIGURE_EXISTING_SUBSCRIPTION'
const FETCH = 'FETCH_SUBSCRIPTION'
export const FETCH_PRICING_PLANS = 'FETCH_PRICING_PLANS'

export const INITIAL_STATE = {
  planChanged: false,
}

// Reducer

export default (state = INITIAL_STATE, action) => {
  const UPDATED_FULFILLED_TYPES = [
    `${CONFIGURE_EXISTING}_FULFILLED`,
    `${UPDATE}_FULFILLED`,
    `${FETCH}_FULFILLED`,
  ]

  if (UPDATED_FULFILLED_TYPES.includes(action?.type)) {
    const { payload, meta } = action

    const planChanged = action?.type === `${CONFIGURE_EXISTING}_FULFILLED`

    return {
      ...state,
      planChanged,
      [meta.organizationId]: payload.data,
    }
  }

  if (action?.type === `${FETCH_PRICING_PLANS}_FULFILLED`) {
    const { payload } = action

    return {
      ...state,
      pricingPlans: payload.data,
    }
  }

  return state
}

// Actions
export const configureSavedSubscription = (orgId, data) => ({
  type: CONFIGURE_EXISTING,
  payload: adaloBackendAxios.put(`/organizations/${orgId}/subscription`, data),
  meta: { organizationId: orgId },
})

export const saveSubscription = (orgId, data) => ({
  type: UPDATE,
  payload: adaloBackendAxios.put(`/organizations/${orgId}/subscription`, data),
  meta: { organizationId: orgId },
})

export const fetchSubscription = orgId => ({
  type: FETCH,
  payload: adaloBackendAxios.get(`/organizations/${orgId}/subscription`),
  meta: { organizationId: orgId },
})

export const cancelSubscription = orgId => ({
  type: UPDATE,
  payload: adaloBackendAxios.delete(`/organizations/${orgId}/subscription`),
  meta: { organizationId: orgId },
})

export const resetSubscription = orgId => ({
  type: UPDATE,
  payload: adaloBackendAxios.put(`/organizations/${orgId}/subscription/reset`),
  meta: { organizationId: orgId },
})

export const fetchPricingPlans = () => ({
  type: FETCH_PRICING_PLANS,
  payload: adaloBackendAxios.get(`/pricing-plans`),
})

// Selectors
export const getPlanChanged = (state, orgId) => {
  return state.billing.planChanged
}

export const getSubscription = (state, orgId) => {
  return state.billing[orgId]
}

export const getPricingPlans = state => {
  return state.billing.pricingPlans
}
