import { EditorObject } from 'utils/responsiveTypes'
import { ObjectList } from '../../types/ObjectList'
import { ObjectPathMap } from '../../types/ObjectPathMap'
import ObjectPath from '../ObjectPath'

/**
 * Finds the screen that holds the given object. Throws an error if the object or screen cannot be found.
 */
const getContainingScreen = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  descendentId: string
): EditorObject => {
  const pathString = pathMap[descendentId]
  if (pathString === undefined) {
    throw new Error(
      `Could not find descendent in path map (Object ID: ${descendentId})`
    )
  }

  const normalizedPath = ObjectPath.fromString(pathString)
  const screenIdx = normalizedPath.get(0)

  const screenObject = objectList[screenIdx]
  if (screenObject === undefined) {
    throw new Error(`Failed to find screen in list. (Path: ${screenIdx})`)
  }

  return screenObject
}

export default getContainingScreen
