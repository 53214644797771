import { useState, useMemo } from 'react'

import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getApp } from 'ducks/apps'
import { getLibraryById, setCheckoutLibraryId } from 'ducks/marketplace'
import {
  fetchAppLicenses,
  fetchOrgLicenses,
  getLicenses,
} from 'ducks/marketplace/licenses'

import { removeLibrary, requestLibrary } from 'ducks/editor/libraries'

import { formatToCurrency } from 'utils/currency'
import { adaloBackendAxios, marketplaceAxios } from 'utils/io/http/axios'

export const useLibraryStatus = libraryId => {
  const { appId } = useParams()

  const app = useSelector(state => getApp(state, appId))

  const licenses = useSelector(state =>
    getLicenses(state, app?.OrganizationId, appId)
  )

  const library = useSelector(state => getLibraryById(state, libraryId))

  const isInstalled = useMemo(
    () => !!licenses.some(l => l.id === libraryId),
    [licenses.length]
  )

  const price = useMemo(
    () => formatToCurrency(library?.price),
    [library?.price]
  )

  const label = useMemo(() => {
    if (isInstalled) {
      if (library?.price) {
        return 'purchased'
      } else {
        return 'installed'
      }
    } else if (library?.price) {
      return 'purchase'
    } else {
      return 'install'
    }
  }, [library?.id, isInstalled])

  return {
    label,
    price,
    isInstalled,
  }
}

export const useLibraryHandler = libraryId => {
  const dispatch = useDispatch()
  const { appId } = useParams()
  const history = useHistory()

  const app = useSelector(state => getApp(state, appId))
  const library = useSelector(state => getLibraryById(state, libraryId))

  const [isLoading, setIsLoading] = useState(false)

  const installURL = useMemo(() => {
    return `/library/${libraryId}/install`
  }, [libraryId])

  const handleLibraryInstall = async () => {
    setIsLoading(true)

    if (library.price) {
      dispatch(setCheckoutLibraryId(libraryId))

      return history.push(`/apps/${appId}/marketplace/checkout`)
    }

    try {
      await adaloBackendAxios.post(installURL, { orgId: app?.OrganizationId })

      // get the latest version of the requested library
      const versionURL = `/api/libraries/${libraryId}/versions`
      const { data } = await marketplaceAxios.get(versionURL)
      const { latest } = data

      // request library at latest version
      await dispatch(requestLibrary(library.name, latest))
      await dispatch(fetchAppLicenses(app?.id))
      await dispatch(fetchOrgLicenses(app?.OrganizationId))
    } catch (err) {
      // do something
    }

    setIsLoading(false)
  }

  const handleLibraryUninstall = async () => {
    setIsLoading(true)

    try {
      await adaloBackendAxios.delete(installURL, {
        headers: {
          orgId: app?.OrganizationId,
          appId: app?.id,
        },
      })

      // remove library from redux
      await dispatch(removeLibrary(library.name, app?.librariesUsed))
      await dispatch(fetchAppLicenses(app?.id))
      await dispatch(fetchOrgLicenses(app?.OrganizationId))
    } catch (err) {
      // do something
    }

    setIsLoading(false)
  }

  return {
    isLoading,
    handleLibraryInstall,
    handleLibraryUninstall,
  }
}
