import { VERSION_FIELD_MAX_LENGTH } from '@adalo/constants'

const maxLength = VERSION_FIELD_MAX_LENGTH || 255

const isMissing = value => {
  if (value !== undefined && value !== '') return false

  return 'Required'
}

const isTooLong = value => {
  if (!value || value.length <= maxLength) return false

  return `Must be ${maxLength} characters or fewer`
}

export { isMissing, isTooLong }
