import { marketplaceAxios } from 'utils/io/http/axios'

const INITIAL_STATE = {
  status: 'idle',
  data: [],
  error: null,
}

const FETCH_LIBRARIES = 'FETCH_LIBRARIES'
const FETCH_LIBRARIES_PENDING = 'FETCH_LIBRARIES_PENDING'
const FETCH_LIBRARIES_FULFILLED = 'FETCH_LIBRARIES_FULFILLED'

const FETCH_LIBRARY = 'FETCH_LIBRARY'
const FETCH_LIBRARY_PENDING = 'FETCH_LIBRARY_PENDING'
const FETCH_LIBRARY_FULFILLED = 'FETCH_LIBRARY_FULFILLED'

const UPDATE_LIBRARY = 'UPDATE_LIBRARY'
const UPDATE_LIBRARY_FULFILLED = 'UPDATE_LIBRARY_FULFILLED'

// reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LIBRARY_PENDING:
    case FETCH_LIBRARIES_PENDING:
      return {
        ...state,
        status: 'pending',
      }
    case FETCH_LIBRARY_FULFILLED:
      return {
        ...state,
        status: 'ready',
        data: [...state.data, ...action.payload.data],
      }
    case FETCH_LIBRARIES_FULFILLED:
      return {
        ...state,
        status: 'ready',
        data: action.payload.data,
      }

    case UPDATE_LIBRARY_FULFILLED: {
      const { id } = action.payload.data
      const { data } = state

      const newData = [...data]

      const libraryIndex = newData.findIndex(l => l.id === id)
      newData[libraryIndex] = action.payload.data

      return {
        ...state,
        data: newData,
      }
    }

    default:
      return state
  }
}

// actions
export const fetchLibraries = search => ({
  type: FETCH_LIBRARIES,
  payload: marketplaceAxios.get(`/api/admin/libraries`, {
    params: { search },
  }),
})

export const fetchLibraryByName = libraryName => ({
  type: FETCH_LIBRARY,
  payload: marketplaceAxios.get(`/api/admin/libraries`, {
    params: { libraryName },
  }),
})

export const updateLibrary = (libraryId, data) => ({
  type: UPDATE_LIBRARY,
  payload: marketplaceAxios.patch(`/api/admin/libraries/${libraryId}/`, data),
})

export const updateLibraryVersion = (libraryId, versionId, data) => ({
  type: UPDATE_LIBRARY,
  payload: marketplaceAxios.patch(
    `/api/admin/libraries/${libraryId}/${versionId}`,
    data
  ),
  data,
})

// selectors
export const selectLibraries = state => {
  return state.admin.libraries
}
