import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getCurrentAppId,
  getComponentId,
} from '../../../../ducks/editor/objects'
import { getAutosaveInputOptions } from '../../../../ducks/recommender'
import { getLabel } from '../../../../utils/sources'
import MenuControl from './MenuControl'

import { THEMES } from '../../../../constants'

class AutosaveInputControl extends Component {
  handleChange = wrappedVal => {
    const { name, onChange } = this.props

    const val = wrappedVal[name]

    if (!val.value) {
      val.value = null
    }

    onChange({ [name]: val })
  }

  render() {
    const { options, getLabel } = this.props

    return (
      <MenuControl
        {...this.props}
        options={options}
        childWidth={320}
        getLabel={getLabel}
        onChange={this.handleChange}
        menuTheme={THEMES.DATA}
      />
    )
  }
}

const mapStateToProps = (state, opts) => {
  const { objectId, reference, dataType } = opts
  const appId = getCurrentAppId(state)
  const componentId = getComponentId(state, objectId)

  return {
    options: getAutosaveInputOptions(
      state,
      appId,
      componentId,
      objectId,
      dataType,
      reference
    ),
    getLabel: opt => getLabel(state, opt, appId, componentId, true),
  }
}

export default connect(mapStateToProps)(AutosaveInputControl)
