import { backgroundStyle, borderStyle } from '@adalo/constants'

export const LAYOUT_SECTION_GHOST_STYLE = {
  backgroundStyle: backgroundStyle.COLOR,
  borderColor: 'rgb(0, 169, 150)',
  borderStyle: borderStyle.SOLID,
}

export const LAYOUT_HELPER_GHOST_STYLE = {
  backgroundStyle: backgroundStyle.NONE,
  borderStyle: borderStyle.NONE,
}

export const LAYOUT_SECTION_CONTAINER_GHOST_STYLE = {
  backgroundStyle: backgroundStyle.COLOR,
  borderColor: 'rgb(0, 169, 150)',
  borderStyle: borderStyle.DASHED,
  backgroundColor: 'rgba(0, 169, 150, 0.1)',
}
