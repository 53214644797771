export const validateCollectionName = name => {
  if (!name || !name.trim()) {
    return 'Can’t be blank'
  }

  if (name.toLowerCase() === 'users') {
    return '"Users" is a reserved name'
  }

  return undefined
}

export const validate = values => {
  const errors = {}

  errors.name = validateCollectionName(values.name)

  return errors
}
