import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Button from 'components/Shared/Button'

import { FormValues } from '.'

const FORM_NAME = 'user-questions-form'

const primaryUseOptions = [
  {
    value: 'Maker',
    label: 'For personal use',
  },
  {
    value: 'Work',
    label: 'For work or a side project',
  },
  {
    value: 'Education',
    label: 'For teaching or taking a class',
  },
]

const audienceOptions = [
  {
    value: 'Freelancer/Agency',
    label: 'My clients; as a freelancer/agency',
  },
  {
    value: 'Enterprise Employee',
    label: 'My co-workers and/or employees',
  },
  {
    value: 'Small Business Operator',
    label: 'My existing customers or community',
  },
  {
    value: 'Aspiring Founder',
    label: 'My future customers; I have a startup idea!',
  },
  {
    value: 'Freelancer/Agency2',
    label: 'Future clients; I might start freelancing',
  },
]

interface Props {
  loading: boolean
  showButton?: boolean
  disableButton: boolean
  primaryUse: string
  setPrimaryUse: (value: string) => void
  setAudience: (value: string) => void
}

const defaultProps = {
  showButton: false,
}

const UserQuestionsForm: React.FC<
  Props & InjectedFormProps<FormValues, Props>
> = props => {
  const {
    handleSubmit,
    loading,
    showButton,
    disableButton,
    primaryUse,
    setPrimaryUse,
    setAudience,
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="primaryUse">How will you primarily use Adalo?</label>
        <Field
          className="primary-use"
          id="primaryUse"
          placeholder="Select one..."
          type="text"
          name="primaryUse"
          component={WrappedSelect}
          options={primaryUseOptions}
          onChangeValue={(value: string) => setPrimaryUse(value)}
        />
      </div>
      {primaryUse === 'Work' ? (
        <div>
          <label htmlFor="audience">Who are you building apps for?</label>
          <Field
            className="audience"
            id="audience"
            placeholder="Select one..."
            type="text"
            name="audience"
            component={WrappedSelect}
            options={audienceOptions}
            onChangeValue={(value: string) => setAudience(value)}
          />
        </div>
      ) : null}
      {showButton ? (
        <Button
          large
          yellow
          type="submit"
          loading={loading}
          disabled={disableButton}
        >
          Go Build Apps!
        </Button>
      ) : null}
    </form>
  )
}

UserQuestionsForm.defaultProps = defaultProps

const WrappedUserQuestionsForm = reduxForm<FormValues, Props>({
  form: FORM_NAME,
})(UserQuestionsForm)

export default WrappedUserQuestionsForm
