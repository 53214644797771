import type React from 'react'
import classNames from 'classnames'

import Icon from '../Icon'
import './index.scss'

interface IStat {
  title: string
  stat: string
  statExtra: string | null
  legacyWarning: boolean
  icon: React.Component | null
}

const Stat = ({
  title,
  stat,
  statExtra = null,
  legacyWarning,
  icon = null,
}: IStat): JSX.Element => (
  <div className="stat-container" key={title}>
    <p className="stat-title">{title}</p>
    <h4
      className={classNames('stat', {
        'legacy-warning': legacyWarning,
        'limit-indicator': !!icon,
      })}
    >
      {stat}
      {statExtra ? <p className="stat-extra">{statExtra}</p> : null}
      {legacyWarning ? <Icon small color="darkerGray" type="warning" /> : null}
      {icon}
    </h4>
  </div>
)

export default Stat
