import { getFeatureFlag } from 'ducks/featureFlags'
import { getApp } from 'ducks/apps'
import {
  requestScreenTemplates,
  requestScreenTemplateCategories,
} from '../../utils/io'

const SET_LOADING = Symbol('SCREEN_TEMPLATES_SET_LOADING')
const SET_CATEGORIES_LOADING = Symbol('SCREEN_TEMPLATES_CATEGORIES_SET_LOADING')
const LOAD_LIST = Symbol('SCREEN_TEMPLATES_LOAD_LIST')
const LOAD_CATEGORIES_LIST = Symbol('SCREEN_TEMPLATES_LOAD_CATEGORIES_LIST')
const CREATED = Symbol('SCREEN_TEMPLATE_CREATED')
const UPDATED = Symbol('SCREEN_TEMPLATE_UPDATED')
const ADD_LOCAL_CATEGORY = Symbol('SCREEN_TEMPLATES_ADD_STATIC_CATEGORY')

const LIST_INITIAL_STATE = {
  list: [],
  localList: [],
  loading: false,
  loaded: false,
}

export const SCREEN_TEMPLATES_DEFAULT_OPEN = ['Simple', 'Most Used'] // Simple for legacy apps, Most Used for responsive

const staticTemplatesByPlatform = {
  responsive: [
    {
      name: 'Blank Screen',
      iconClassName: 'editor-add-component-icon-blank-web-screen',
      id: 'blank',
      categories: ['Most Used'],
      layoutMode: 'responsive',
    },
  ],
  web: [
    {
      name: 'Blank Screen',
      iconClassName: 'editor-add-component-icon-blank-web-screen',
      id: 'blank',
      categories: ['Simple'],
    },
  ],
  mobile: [
    {
      name: 'Blank Screen',
      iconClassName: 'editor-add-component-icon-blank-screen',
      id: 'blank',
      categories: ['Simple'],
    },
  ],
}

const INITIAL_STATE = {
  templatesByPlatform: {
    responsive: {
      ...LIST_INITIAL_STATE,
      list: staticTemplatesByPlatform.responsive,
    },
    web: {
      ...LIST_INITIAL_STATE,
      list: staticTemplatesByPlatform.web,
    },
    mobile: {
      ...LIST_INITIAL_STATE,
      list: staticTemplatesByPlatform.mobile,
    },
  },
  categoriesByPlatform: {
    responsive: {
      ...LIST_INITIAL_STATE,
      localList: ['Most Used'],
    },
    web: {
      ...LIST_INITIAL_STATE,
      localList: ['Simple', 'Navigation', 'List', 'Forms'],
    },
    mobile: {
      ...LIST_INITIAL_STATE,
      localList: ['Simple', 'Navigation', 'List', 'Forms'],
    },
  },
}

const RESPONSIVE_CATEGORY_ORDER = [
  'Most Used',
  'Layout',
  'Lists',
  'Detail',
  'Create',
  'Account Settings',
  'Signup & Login',
  'Welcome & Onboarding',
]

// REDUCER
function immutableUpsert(array, getKey, element) {
  if (!array || array.length === 0) {
    return [element]
  }

  const elementKey = getKey(element)

  const index = array.findIndex(otherEl => getKey(otherEl) === elementKey)

  if (index < 0) {
    return [...array, element]
  }

  const newArray = array.slice()

  newArray[index] = {
    ...array[index],
    ...element,
  }

  return newArray
}

function immutableUniquePush(array, ...elements) {
  if (!array) {
    return elements
  }

  return elements.reduce((newArray, element) => {
    if (newArray.indexOf(element) < 0) {
      if (newArray === array) {
        newArray = array.slice()
      }

      newArray.push(element)
    }

    return newArray
  }, array)
}

function immutableRemoveKey(array, getKey, keyToRemove) {
  if (!array || array.length === 0) {
    return array
  }

  const index = array.findIndex(el => getKey(el) === keyToRemove)

  if (index < 0) {
    return array
  }

  const newArray = array.slice()
  newArray.splice(index, 1)

  return newArray
}

function extractTemplateKey(template) {
  return template.id
}

export default function screenTemplatesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOADING: {
      const { platform, loading, loaded } = action.payload

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [platform]: {
            ...LIST_INITIAL_STATE,
            ...state.templatesByPlatform[platform],
            loading,
            loaded,
          },
        },
      }
    }
    case LOAD_LIST: {
      let { platform, list, mobileOnly, showResponsive } = action.payload

      if (!showResponsive) {
        if (mobileOnly) {
          delete staticTemplatesByPlatform.responsive
        } else {
          staticTemplatesByPlatform.responsive = [
            {
              iconClassName: 'editor-add-component-icon-blank-web-screen',
              id: 'blank',
              categories: ['Most Used'],
              layoutMode: 'responsive',
              name: 'Blank Desktop First',
            },
          ]
        }
      }

      const staticList = staticTemplatesByPlatform[platform] || []

      if (staticList) {
        list = [...staticList, ...list]
      }

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [platform]: {
            ...LIST_INITIAL_STATE,
            ...state.templatesByPlatform[platform],
            list,
            loading: false,
            loaded: true,
          },
        },
      }
    }
    case CREATED: {
      const { platform, template } = action.payload

      const templateState = {
        ...LIST_INITIAL_STATE,
        ...state.templatesByPlatform[platform],
      }

      if (template.enabled && templateState.loaded) {
        return {
          ...state,
          templatesByPlatform: {
            ...state.templatesByPlatform,
            [platform]: {
              ...templateState,
              list: immutableUpsert(
                templateState.list,
                extractTemplateKey,
                template
              ),
            },
          },
        }
      }

      return state
    }
    case UPDATED: {
      const { platform, template } = action.payload

      const templateState = {
        ...LIST_INITIAL_STATE,
        ...state.templatesByPlatform[platform],
      }

      if (!templateState.loaded) {
        return state
      }

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [platform]: {
            ...templateState,
            list: template.enabled
              ? immutableUpsert(
                  templateState.list,
                  extractTemplateKey,
                  template
                )
              : immutableRemoveKey(
                  templateState.list,
                  extractTemplateKey,
                  extractTemplateKey(template)
                ),
          },
        },
      }
    }
    case SET_CATEGORIES_LOADING: {
      const { platform, loading, loaded } = action.payload

      return {
        ...state,
        categoriesByPlatform: {
          ...state.categoriesByPlatform,
          [platform]: {
            ...LIST_INITIAL_STATE,
            ...state.categoriesByPlatform[platform],
            loading,
            loaded,
          },
        },
      }
    }
    case LOAD_CATEGORIES_LIST: {
      const { platform, list } = action.payload

      const categoryState = {
        ...LIST_INITIAL_STATE,
        ...state.categoriesByPlatform[platform],
        list,
      }

      return {
        ...state,
        categoriesByPlatform: {
          ...state.categoriesByPlatform,
          [platform]: {
            ...categoryState,
            list: immutableUniquePush(
              categoryState.list,
              ...categoryState.localList
            ),
            loading: false,
            loaded: true,
          },
        },
      }
    }
    case ADD_LOCAL_CATEGORY: {
      const { platform, category } = action.payload

      const categoryState = {
        ...LIST_INITIAL_STATE,
        ...state.categoriesByPlatform[platform],
      }

      const localList = immutableUniquePush(categoryState.localList, category)

      return {
        ...state,
        categoriesByPlatform: {
          ...state.categoriesByPlatform,
          [platform]: {
            ...categoryState,
            localList,
            list: immutableUniquePush(categoryState.list, ...localList),
          },
        },
      }
    }

    default:
  }

  return state
}

// ACTIONS

export const loadScreenTemplates = (
  platform,
  list,
  mobileOnly,
  showResponsive
) => ({
  type: LOAD_LIST,
  payload: { platform, list, mobileOnly, showResponsive },
})

export const loadScreenTemplateCategories = (platform, list) => ({
  type: LOAD_CATEGORIES_LIST,
  payload: { platform, list },
})

export const screenTemplateCreated = (platform, template) => ({
  type: CREATED,
  payload: { platform, template },
})

export const screenTemplateUpdated = (platform, template) => ({
  type: UPDATED,
  payload: { platform, template },
})

export const addNewScreenTemplateCategory = (platform, category) => ({
  type: ADD_LOCAL_CATEGORY,
  payload: { platform, category },
})

// SELECTORS

/**
 *
 * @param {object} state
 * @param {string} platform
 * @returns {{list: Array<object>, loading: boolean, loaded: boolean}}
 */
export const getScreenTemplatesState = (state, platform) => {
  const platformTemplates =
    state.editor.screenTemplates.templatesByPlatform[platform]

  if (!platformTemplates) {
    return LIST_INITIAL_STATE
  }

  const hasNewAddMenuWithSections = getFeatureFlag(
    state,
    'hasNewAddMenuWithSections'
  )

  // TODO @danicunhac: move this to initial state when we remove the feature flag
  if (platform === 'responsive' && hasNewAddMenuWithSections) {
    const blankScreenIndex = platformTemplates.list.findIndex(
      template => template.name === 'Blank Screen'
    )

    if (blankScreenIndex === -1) {
      platformTemplates.list.unshift({
        name: 'Blank Screen',
        iconClassName: 'editor-add-component-icon-blank-web-screen',
        id: 'blank',
        categories: ['Most Used'],
        layoutMode: 'responsive',
        thumbnail:
          '74c83dee14d348bff4f969ef6a49c881991b57ba3fede2a18fdb7fe704e71d8a.png',
      })
    } else {
      platformTemplates.list[blankScreenIndex].thumbnail =
        '74c83dee14d348bff4f969ef6a49c881991b57ba3fede2a18fdb7fe704e71d8a.png'
    }
  }

  return platformTemplates
}

export const getScreenTemplatesCategoriesState = (state, platform) => {
  return (
    state.editor.screenTemplates.categoriesByPlatform[platform] ||
    LIST_INITIAL_STATE
  )
}

const compareCategories = (a, b) => {
  if (a.title === 'Simple') {
    return -1
  }

  if (b.title === 'Simple') {
    return 1
  }

  if (a.title > b.title) {
    return 1
  }

  if (a.title < b.title) {
    return -1
  }

  // a must be equal to b
  return 0
}

function orderArrayByList(arrayToOrder, orderList) {
  const orderMap = new Map(orderList.map((obj, index) => [obj, index]))

  return arrayToOrder.sort(
    (a, b) => orderMap.get(a.title) - orderMap.get(b.title)
  )
}

function groupTemplatesIntoCategories(templates, platform) {
  const categoriesMap = new Map()

  for (const template of templates) {
    for (const categoryName of template.categories || ['Simple']) {
      let category = categoriesMap.get(categoryName)

      if (!category) {
        category = {
          title: categoryName,
          options: [template],
        }

        categoriesMap.set(categoryName, category)
      } else {
        category.options.push(template)
      }
    }
  }

  let categories = Array.from(categoriesMap.values())

  if (platform === 'responsive') {
    categories = orderArrayByList(categories, RESPONSIVE_CATEGORY_ORDER)
  } else {
    categories.sort(compareCategories)
  }

  return categories
}

export const getCategorizedScreenTemplates = (state, platform) => {
  const templatesState = getScreenTemplatesState(state, platform)
  const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')
  let list = templatesState.list

  const { appId } = state.editor.objects.present
  const app = getApp(state, appId)
  const mobileOnly =
    hasNewMobileOnlyApp && app?.webSettings?.layoutMode === 'mobile'

  if (hasNewMobileOnlyApp && platform === 'responsive') {
    const mode = mobileOnly ? 'mobile' : 'responsive'
    list = list.filter(({ layoutMode }) => !layoutMode || layoutMode === mode)
  }

  return groupTemplatesIntoCategories(list, platform)
}

export const getScreenTemplatesCategories = (state, platform) => {
  const categoriesState = getScreenTemplatesCategoriesState(state, platform)

  return categoriesState.list
}

// THUNKS

const makeEnsureLoadedThunk = options => {
  const { selector, setLoading, request } = options

  return (...args) =>
    (dispatch, getState) => {
      const state = selector(getState(), ...args)

      if (state.loading) {
        return
      }

      dispatch(setLoading(...args))
      request(...args)
    }
}

export const ensureScreenTemplatesAreLoaded = makeEnsureLoadedThunk({
  selector: getScreenTemplatesState,
  setLoading: (platform, mobileOnly) => ({
    type: SET_LOADING,
    payload: { platform, mobileOnly, loading: true, loaded: false },
  }),
  request: platform => requestScreenTemplates(platform),
})

export const ensureScreenTemplateCategoriesAreLoaded = makeEnsureLoadedThunk({
  selector: getScreenTemplatesCategoriesState,
  setLoading: platform => ({
    type: SET_CATEGORIES_LOADING,
    payload: { platform, loading: true, loaded: false },
  }),
  request: platform => requestScreenTemplateCategories(platform),
})
