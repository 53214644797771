import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'

import Link from 'components/Shared/Markdown/Link'

import './HelpText.css'

export default ({ text, header }) => (
  <div
    className={classNames(
      header ? 'library-inspect-help-text-header' : 'library-inspect-help-text'
    )}
  >
    <ReactMarkdown components={{ a: Link }}>{text}</ReactMarkdown>
  </div>
)
