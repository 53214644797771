import React from 'react'

import { halveWidth } from 'utils/positioning'
import BaseShape from './BaseShape'
import GroupWrapper from './Group/GroupWrapper'
import Shadow from './Shadow'

export default class Ellipse extends BaseShape {
  renderPath() {
    const { widthScaled, heightScaled } = this.props
    const width = halveWidth(widthScaled)

    return (
      <ellipse
        {...this.getBorderProps()}
        fill={this.getBackgroundColor()}
        cx={width}
        cy={heightScaled / 2}
        rx={width}
        ry={heightScaled / 2}
      />
    )
  }

  render() {
    const {
      xScaled,
      yScaled,
      hideShadows,
      shadow,
      opacity,
      object,
      children,
      editorResizingProps,
    } = this.props

    return (
      <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
        <g className="group" style={{ opacity }}>
          <g
            className="ellipse"
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDoubleClick}
            transform={`translate(${xScaled} ${yScaled})`}
          >
            {shadow && shadow.enabled && !hideShadows ? (
              <Shadow {...this.getShadowProps()}>{this.renderPath()}</Shadow>
            ) : null}
            {this.renderPath()}
          </g>
          {children}
        </g>
      </GroupWrapper>
    )
  }
}
