import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dataTypes } from '@adalo/constants'

import { getFields, getFieldIds } from '../../../utils/fields'
import { getTable } from '../../../ducks/apps/datasources'
import MenuControl from './Libraries/MenuControl'

class SelectLabelControl extends Component {
  getFieldOptions = (includeNone = false) => {
    const { fields } = this.props

    const simpleTypes = [
      dataTypes.TEXT,
      dataTypes.NUMBER,
      dataTypes.DATE,
      dataTypes.BOOLEAN,
    ]

    const options = []

    if (includeNone) {
      options.push({ label: 'None', value: null })
      options.push(null)
    }

    for (const fieldId of getFieldIds(fields)) {
      const field = fields[fieldId]

      if (simpleTypes.indexOf(field.type) !== -1) {
        options.push({
          label: field.name,
          value: fieldId,
        })
      }
    }

    return options
  }

  handleChange = val => {
    const { value, name, onChange } = this.props

    const newValue = {
      ...value,
      options: {
        ...value.options,
        ...val,
      },
    }

    onChange({ [name]: newValue })
  }

  getValue = () => {
    const { value } = this.props
    const options = value.options || {}

    return options.labelField
  }

  render() {
    return (
      <MenuControl
        autoSelect
        name="labelField"
        displayName="Label Field"
        onChange={this.handleChange}
        value={this.getValue()}
        options={this.getFieldOptions()}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const { value, appId } = props
  const { datasourceId, tableId } = value.source
  const table = getTable(state, appId, datasourceId, tableId)
  const fields = getFields(table, state)

  return {
    fields,
  }
}

export default connect(mapStateToProps)(SelectLabelControl)
