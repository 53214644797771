import { useContext } from 'react'
import styled from 'styled-components'
import { Binding } from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'
import TableThemeContext from './TableThemeContext'

const Container = styled('div')<{ $height: number }>`
  width: 100%;
  height: ${props => props.$height}px;
  align-content: center;
  padding: 16px;
`

const Text = styled('div')<{
  $color: string
  $fontSize: number
  $fontStyle: string
  $fontFamily: string
  $fontWeight: string
}>`
  color: ${props => props.$color};
  font-size: ${props => props.$fontSize}px;
  font-style: ${props => props.$fontStyle};
  font-family: ${props => props.$fontFamily};
  font-weight: ${props => props.$fontWeight};
  width: 100%;
  text-align: center;
`

const formatText = (text: string | [Binding | string]): string => {
  if (typeof text === 'string') {
    return text
  }

  return text
    .map(element => {
      if (typeof element === 'string') {
        return element
      }

      return element.label
    })
    .join('')
}

const EmptyState = ({
  object,
  contentHeight,
}: {
  object: EditorObject
  contentHeight: number
}): JSX.Element => {
  const { styles } = useContext(TableThemeContext)

  return (
    <Container $height={contentHeight}>
      <Text
        $color={styles.emptyStateTitleText.color}
        $fontSize={styles.emptyStateTitleText.fontSize}
        $fontStyle={styles.emptyStateTitleText.fontStyle}
        $fontFamily={styles.emptyStateTitleText.fontFamily}
        $fontWeight={styles.emptyStateTitleText.fontWeight}
      >
        {typeof object.emptyStateTitleText !== 'undefined' &&
          formatText(object.emptyStateTitleText)}
      </Text>
      <Text
        $color={styles.emptyStateBodyText.color}
        $fontSize={styles.emptyStateBodyText.fontSize}
        $fontStyle={styles.emptyStateBodyText.fontStyle}
        $fontFamily={styles.emptyStateBodyText.fontFamily}
        $fontWeight={styles.emptyStateBodyText.fontWeight}
      >
        {typeof object.emptyStateBodyText !== 'undefined' &&
          formatText(object.emptyStateBodyText)}
      </Text>
    </Container>
  )
}

export default EmptyState
