import { DeviceType } from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import usesSharedLayout from 'utils/objects/usesSharedLayout'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import { enableDeviceSpecificLayoutHandler } from './enableDeviceSpecificLayout'
import { moveElementVertically } from './moveElement'
import updateChangedObject from './updateChangedObject'

export interface SnapToBottomOptions {
  snapObjectId: string
  staticObjectId: string
}

export interface SnapToBottomInstruction {
  operation: 'snapToBottom'
  options: SnapToBottomOptions
}

export const snapToBottomHandler = (
  state: InstructionState,
  { snapObjectId, staticObjectId }: SnapToBottomOptions
): InstructionState => {
  let { list: newList, pathMap } = state
  let baseObject: EditorObject = getObject(newList, pathMap, snapObjectId)
  const staticObject: EditorObject = getObject(newList, pathMap, staticObjectId)

  for (const device of Object.values(DeviceType)) {
    if (staticObject[device]) {
      const { y, height } = getDeviceObject(staticObject, device)
      const newY = y + height

      if (usesSharedLayout(baseObject, device)) {
        ;({ list: newList } = enableDeviceSpecificLayoutHandler(
          { ...state, list: newList },
          {
            objectId: snapObjectId,
            device,
          }
        ))
      }

      newList = moveElementVertically(
        newList,
        pathMap,
        snapObjectId,
        device,
        newY
      )

      baseObject = getObject(newList, pathMap, snapObjectId)
      newList = updateChangedObject(newList, pathMap, baseObject, device)
    }
  }

  return {
    ...state,
    list: newList,
  }
}

const snapToBottom = (
  snapObjectId: string,
  staticObjectId: string
): SnapToBottomInstruction => ({
  operation: 'snapToBottom',
  options: { snapObjectId, staticObjectId },
})

export default snapToBottom
