import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { template } from 'utils/templating'
import './SettingsPanel.css'
import { adaloBackendAxios } from 'utils/io/http/axios'

const DEFAULT_HEIGHT = 120

class SettingsPanel extends Component {
  static defaultProps = {
    height: DEFAULT_HEIGHT,
  }

  state = {
    libraryToken: null,
  }

  getURL = () => {
    const { match, settingsURL, object } = this.props
    const { libraryToken } = this.state
    const { appId } = match.params
    const objectId = object && object.id

    const url = template(settingsURL, { appId, objectId })

    const connector = url.match(/\?/) ? '&' : '?'

    return `${url}${connector}authToken=${libraryToken}`
  }

  loadToken = async () => {
    const { library, match } = this.props
    const { appId } = match.params
    const libraryName = window.encodeURIComponent(library)

    const result = await adaloBackendAxios.get(
      `/library-tokens/${appId}?library=${libraryName}`
    )

    const { token } = result.data

    this.setState({ libraryToken: token })
  }

  componentDidMount() {
    this.loadToken()
  }

  render() {
    const { height } = this.props
    const { libraryToken } = this.state
    const url = this.getURL()

    console.log('TOKEN:', libraryToken)

    return (
      <div className="library-inspect-settings-panel">
        <p>Settings</p>
        <div className="library-inspect-settings-panel-inner">
          {libraryToken ? (
            <iframe
              src={url}
              height={height}
              className="library-inspect-settings-panel-frame"
              title="Component Settings"
            />
          ) : (
            <div style={{ height }} />
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(SettingsPanel)
