import { sanitize } from 'dompurify'

/* eslint-disable prefer-promise-reject-errors */
export const validateImage = async (fileBuffer, extension, size = null) => {
  return new Promise((resolve, reject) => {
    const {
      valid: validFileType,
      mimetype,
      extension: extensionPretty,
    } = validateFileType(fileBuffer, extension)

    if (!validFileType) {
      if (extensionPretty) {
        console.log('mimetype:', mimetype)

        reject(
          `File type is not valid. Make sure the file extension matches the file type. The extension was ${extensionPretty} but the file type actually was ${mimetype}.`
        )
      } else {
        reject('Error validating image file type.')
      }
    }

    const img = new Image()

    img.onload = () => {
      if (!size || (img.width <= size && img.height <= size)) resolve()
      else {
        reject(
          `Image size is too big. Make sure the image is less than ${size} x ${size} pixels.`
        )
      }
    }

    img.onerror = error => {
      console.error('Error loading image:', error)

      reject(
        'Image is invalid. Make sure the file you are uploading is actually an image.'
      )
    }

    img.src = fileBuffer
  })
}

export const validateFileType = (fileBuffer, extension) => {
  try {
    const imageString = fileBuffer.split(',')[1]
    const mimetype = getMimetype(imageString)

    if (mimetype !== extension) {
      const mimetypePretty = mimetype.replace('image/', '')
      const extensionPretty = extension.replace('image/', '')

      return {
        valid: false,
        mimetype: mimetypePretty,
        extension: extensionPretty,
      }
    }

    return { valid: true }
  } catch (error) {
    console.error('error validating image file type:', error)

    return {
      valid: false,
      error,
    }
  }
}

const getMimetype = base64String => {
  switch (base64String.charAt(0)) {
    case '/':
      return 'image/jpeg'
    case 'R':
      return 'image/gif'
    case 'i':
      return 'image/png'
    default:
      return 'not a valid image'
  }
}

export const isMaliciousHTML = value => {
  if (!value) {
    return false
  }

  return value !== sanitize(value)
}

export const containsLink = value => {
  return /((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?/i.test(
    value
  )
}

export const MALICIOUS_ERROR_MESSAGE =
  'Cannot contain malicious or incomplete HTML.'

export const NO_LINK_MESSAGE = 'Cannot contain links.'
