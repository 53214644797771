import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import {
  getTemplateBrandingState,
  ensureTemplateBrandingIsLoaded,
  templateIdIsBlankType,
} from 'ducks/apps'
import {
  fetchOrganizations,
  getOrganizations,
  getCurrentOrganization,
} from 'ducks/organizations'
import { getCurrentUser } from 'ducks/users'

import { defaultBranding } from 'utils/colors'

import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import WrappedColorDropdown from '../Shared/Forms/WrappedColorDropdown'
import WrappedSelect from '../Shared/Forms/WrappedSelect'
import Loading from '../Shared/Loading'

import WizardFooter from './WizardFooter'
import { FORM_NAME } from './shared'

const appUsageOptions = [
  { label: "Mostly me, I'm just testing", value: 'test' },
  { label: 'Internal users (coworkers or employees)', value: 'internal' },
  { label: 'Existing customers or community', value: 'external' },
  { label: 'Customers of my startup', value: 'startup' },
  { label: 'Both internal and external users', value: 'two-sided' },
]

const WizardFormBrandingPage = props => {
  const { name, appUsage: initialAppUsage } = props

  const history = useHistory()
  const currentUser = useSelector(getCurrentUser)
  const [appUsage, setAppUsage] = useState(initialAppUsage || '')

  useEffect(() => {
    const {
      fetchOrganizations,
      templateId,
      loadedBranding,
      ensureTemplateBrandingIsLoaded,
    } = props

    fetchOrganizations()

    if (!templateIdIsBlankType(templateId) && !loadedBranding) {
      ensureTemplateBrandingIsLoaded(templateId)
    }
  }, [])

  const getOrganizationOptions = () => {
    const { organizations, currentOrganization } = props

    const formattedOrgs = organizations?.map(org => ({
      label: org.name,
      value: org.id,
    }))

    if (!currentOrganization) {
      return formattedOrgs
    }

    const otherOrgs = formattedOrgs.filter(
      org => org.value !== currentOrganization?.id
    )

    return [
      { label: currentOrganization?.name, value: currentOrganization?.id },
      ...otherOrgs,
    ]
  }

  const {
    handleSubmit,
    handlePreviousPage,
    loadingBranding,
    organizations,
    submitting,
  } = props

  const isFirstAppSetup = useMemo(() => {
    const { pathname } = history.location

    return pathname === '/first-app-setup'
  }, [history.location])

  const disableButton = useMemo(() => {
    if (!name) {
      return true
    }

    if (!isFirstAppSetup && !currentUser?.admin) {
      if (!appUsage) {
        return true
      }
    }

    return false
  }, [name, appUsage, isFirstAppSetup, currentUser])

  const randomizedAppUsageOptions = useMemo(
    () => appUsageOptions.sort(() => Math.random() - 0.5),
    []
  )

  const showAppQuestion = !isFirstAppSetup

  return (
    <>
      <form
        id="new-app-branding-form"
        className="new-app-form"
        onSubmit={handleSubmit}
      >
        <Field
          name="name"
          component={WrappedInput}
          placeholder="i.e. SpaceX Flight Tracker"
          label="App Name"
          type="text"
        />
        {showAppQuestion ? (
          <>
            <Field
              id="appUsage"
              placeholder="Select one..."
              label="Who are the users of this app?"
              name="appUsage"
              component={WrappedSelect}
              options={randomizedAppUsageOptions}
              onChangeValue={value => setAppUsage(value)}
            />
          </>
        ) : null}
        {organizations.length > 1 && (
          <Field
            autoSelect
            label="Team"
            name="organizationId"
            component={WrappedSelect}
            options={getOrganizationOptions()}
          />
        )}
        {loadingBranding ? (
          <Loading expanded />
        ) : (
          <>
            <Field
              name="primaryColor"
              component={WrappedColorDropdown}
              label="Primary Color"
            />
            <Field
              name="secondaryColor"
              component={WrappedColorDropdown}
              label="Secondary Color"
            />
          </>
        )}
      </form>
      <WizardFooter>
        {handlePreviousPage ? (
          <Button className="previous" onClick={handlePreviousPage}>
            Back
          </Button>
        ) : null}
        <Button
          className="align-right next"
          type="submit"
          form="new-app-branding-form"
          disabled={disableButton}
          loading={submitting}
        >
          Create
        </Button>
      </WizardFooter>
    </>
  )
}

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME)
  const templateId = selector(state, 'templateId')
  const primaryColor = selector(state, 'primaryColor')
  const secondaryColor = selector(state, 'secondaryColor')

  const brandingState = templateIdIsBlankType(templateId)
    ? { loading: false, loaded: true, branding: defaultBranding }
    : getTemplateBrandingState(state, templateId)

  return {
    templateId,
    loadingBranding: brandingState.loading || !primaryColor || !secondaryColor,
    loadedBranding: brandingState.loaded,
    name: selector(state, 'name'),
    appUsage: selector(state, 'appUsage'),
    organizations: getOrganizations(state),
    currentOrganization: getCurrentOrganization(state),
  }
}

const ConnectedWizardFormBrandingPage = connect(mapStateToProps, {
  fetchOrganizations,
  ensureTemplateBrandingIsLoaded,
})(WizardFormBrandingPage)

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ConnectedWizardFormBrandingPage)
