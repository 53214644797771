import React from 'react'
import BaseObject from './BaseObject'

export default class WebView extends BaseObject {
  render() {
    let { xScaled, yScaled, widthScaled, heightScaled, opacity } = this.props

    if (typeof opacity === 'undefined') {
      opacity = 1
    }

    return (
      <g
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        opacity={opacity}
      >
        <rect
          fill="#eee"
          x={xScaled}
          y={yScaled}
          width={widthScaled}
          height={heightScaled}
        />
      </g>
    )
  }
}
