import React from 'react'
import AsyncSelect from 'react-select/async'

const Select = props => {
  const styles = {
    menuPortal: base => ({ ...base, zIndex: 20001 }),
    multiValue: base => ({
      ...base,
      borderRadius: 16,
      padding: '2px 8px',
      fontSize: 14,
      '> div:first-of-type': {
        paddingLeft: 3,
        textOverflow: 'ellipsis',
        maxWidth: '32ch',
      },
    }),
    multiValueRemove: base => ({
      ...base,
      marginRight: -6,
      borderRadius: 16,
      ':hover': {
        backgroundColor: '#ccc',
        cursor: 'pointer',
      },
    }),
    control: base => ({
      ...base,
      borderRadius: 6,
      borderColor: '#e0e0e0',
      padding: '16px',
      minHeight: 60,
      fontSize: 14,
      boxShadow: 'none',
      ':hover': {
        borderColor: '#ccc',
        boxShadow: 'none',
        cursor: 'pointer',
      },
      ':focus': {
        borderColor: '#ccc',
        boxShadow: 'none',
      },
      ':active': {
        borderColor: '#ccc',
        boxShadow: 'none',
      },
    }),
    input: base => ({ ...base, borderRadius: 6 }),
    indicatorSeparator: base => ({ ...base, display: 'none' }),
    indicatorsContainer: base => ({ ...base, paddingLeft: 8 }),
    clearIndicator: base => ({ ...base, padding: 0 }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4,
      color: '#333333',
      ':hover': {
        color: '#333333',
      },
    }),
    valueContainer: base => ({ ...base, padding: 0 }),
    menuList: base => ({
      ...base,
      fontSize: 14,
    }),
  }

  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: 'rgba(239, 76, 48, 1)',
      primary25: 'rgba(239, 76, 48, 0.07)',
      primary50: 'rgba(239, 76, 48, 0.1)',
    },
  })

  return (
    <AsyncSelect
      styles={styles}
      theme={theme}
      menuPortalTarget={document.body}
      menuPosition="absolute"
      menuPlacement="auto"
      {...props}
    />
  )
}

export default Select
