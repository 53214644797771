import { EditorObject } from 'utils/responsiveTypes'
import { ObjectList } from '../../types/ObjectList'
import { ObjectPathMap } from '../../types/ObjectPathMap'
import getObjectByPath from './getObjectByPath'
import ObjectPath from '../ObjectPath'

/**
 * Finds the object with the given ID. Throws an error if the object cannot be found.
 */
const getObject = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  objectId: string
): EditorObject => {
  const pathString = pathMap[objectId]
  if (!pathString) {
    throw new Error(
      `Failed to find object in path map. (Object ID: ${objectId})`
    )
  }

  const objectPath = ObjectPath.fromString(pathString)

  return getObjectByPath(objectList, objectPath)
}

export default getObject
