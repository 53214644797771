import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import {
  GROUP,
  COMPONENT,
  LIBRARY_COMPONENT,
  LAYOUT_SECTION,
} from '@adalo/constants'
import { getParent } from 'ducks/editor/device-layouts/utils'

import { getAppLibraries } from 'ducks/apps'
import {
  deleteObject,
  ungroupObjects,
  updateObject,
  groupObjects,
  groupObjectsToList,
  reorderObjectsMoveFirst,
  reorderObjectsMoveLast,
  reorderObjectsMoveUp,
  reorderObjectsMoveDown,
  runInstructions,
  runSelectionInstruction,
} from 'ducks/editor/objects'
import {
  alignToScreenHorizontalCenter,
  alignToScreenVerticalCenter,
} from 'ducks/editor/selection-instructions'
import { getCurrentUser } from 'ducks/users/index.ts'
import { Submenu } from 'ducks/recommender'
import {
  groupObjects as groupObjectsInstruction,
  groupObjectsToList as groupObjectsToListInstruction,
  ungroupObjects as ungroupObjectsInstruction,
} from 'ducks/editor/instructions'
import AppMagicLayoutContext from 'components/Editor/AppMagicLayoutContext'

import Icon, { IconButton } from 'components/Shared/Icon'
import { isSectionElement } from 'utils/layoutSections'

class OverflowMenu extends Component {
  static contextType = AppMagicLayoutContext

  handleChangeVisibility = e => {
    const { onShowVisibility } = this.props

    onShowVisibility(e)
  }

  handleEditName = () => {}

  handleDelete = () => {
    const { object, deleteObject } = this.props

    deleteObject(object.id)
  }

  handleUngroup = () => {
    const { object, ungroupObjects, runInstructions } = this.props
    const { hasMagicLayout } = this.context

    if (hasMagicLayout) {
      runInstructions([ungroupObjectsInstruction([object.id])])
    } else {
      ungroupObjects([object.id])
    }
  }

  handleMakeList = () => {
    const { object, groupObjectsToList, runInstructions } = this.props
    const { hasMagicLayout } = this.context

    if (hasMagicLayout) {
      runInstructions([groupObjectsToListInstruction([object.id])])
    } else {
      groupObjectsToList([object.id])
    }
  }

  handleMakeGroup = () => {
    const { object, groupObjects, runInstructions } = this.props
    const { hasMagicLayout } = this.context

    if (hasMagicLayout) {
      runInstructions([groupObjectsInstruction([object.id])])
    } else {
      groupObjects([object.id])
    }
  }

  handleBringForward = e => {
    const { object, reorderObjectsMoveUp } = this.props
    e.stopPropagation()
    reorderObjectsMoveUp([object.id])
  }

  handleSendBackward = e => {
    const { object, reorderObjectsMoveDown } = this.props
    e.stopPropagation()
    reorderObjectsMoveDown([object.id])
  }

  handleSendBack = e => {
    const { object, reorderObjectsMoveLast } = this.props
    e.stopPropagation()
    reorderObjectsMoveLast([object.id])
  }

  handleBringFront = e => {
    const { object, reorderObjectsMoveFirst } = this.props
    e.stopPropagation()
    reorderObjectsMoveFirst([object.id])
  }

  handleCreateScreenTemplate = () => {
    const { onShowScreenTemplates } = this.props
    onShowScreenTemplates()
  }

  handleCreatePrebuiltLayoutSection = () => {
    const { onToggleCreatePrebuiltSection } = this.props
    onToggleCreatePrebuiltSection()
  }

  handlePurchaseLibrary = () => {
    const { appLibrary, match, history } = this.props
    const { appId } = match.params

    history.push(`/apps/${appId}/marketplace/${appLibrary.id}`)
  }

  objectSupportsMenu = object => isSectionElement(object) === false

  getMenu = () => {
    const {
      object,
      canMakeScreenTemplate,
      canMakePrebuiltLayoutSection,
      appLibrary,
      runSelectionInstruction,
      parent,
    } = this.props
    const { hasMagicLayout } = this.context
    const parentText = parent.type === COMPONENT ? 'Screen' : 'Parent'

    if (
      object.type === LIBRARY_COMPONENT &&
      appLibrary &&
      !appLibrary.licensed
    ) {
      const menuItems = [
        {
          label: (
            <div className="right-panel-overflow-option">
              <Icon type="trash-small" />
              Delete
            </div>
          ),
          onClick: this.handleDelete,
        },
      ]

      if (appLibrary.price) {
        menuItems.splice(0, 0, {
          label: (
            <div className="right-panel-overflow-option">
              <Icon type="launch" />
              Purchase
            </div>
          ),
          onClick: this.handlePurchaseLibrary,
        })
      }

      return menuItems
    }

    const menuItems = [
      canMakePrebuiltLayoutSection && {
        label: (
          <div className="right-panel-overflow-option">
            <Icon type="section-small" />
            Create/Edit Prebuilt Layout Section
          </div>
        ),
        onClick: this.handleCreatePrebuiltLayoutSection,
      },
      object.type !== COMPONENT && {
        label: 'Change Visibility',
        icon: 'eye',
        onClick: this.handleChangeVisibility,
      },
      object.type !== COMPONENT &&
        new Submenu(
          'Arrange',
          [
            {
              label: 'Bring to Front',
              onClick: this.handleBringFront,
              icon: 'bring-to-front',
            },
            {
              label: 'Send to Back',
              onClick: this.handleSendBack,
              icon: 'send-to-back',
            },
            null,
            {
              label: 'Bring Forward',
              onClick: this.handleBringForward,
              icon: 'bring-forward',
            },
            {
              label: 'Send Backward',
              onClick: this.handleSendBackward,
              icon: 'send-backward',
            },
          ],
          true
        ),
      object.type !== COMPONENT &&
        hasMagicLayout &&
        new Submenu(
          `Align to ${parentText}`,
          [
            {
              label: 'Align Horizontally',
              onClick: e => {
                e.stopPropagation()
                runSelectionInstruction(
                  alignToScreenHorizontalCenter([object.id])
                )
              },
              icon: 'align-horizontal-center',
            },
            {
              label: 'Align Vertically',
              onClick: e => {
                e.stopPropagation()
                runSelectionInstruction(
                  alignToScreenVerticalCenter([object.id])
                )
              },
              icon: 'align-vertical-center',
            },
          ],
          true
        ),
      canMakeScreenTemplate && {
        label: (
          <div className="right-panel-overflow-option">
            <Icon type="eye" />
            Make Screen Template
          </div>
        ),
        onClick: this.handleCreateScreenTemplate,
      },

      object.type !== COMPONENT &&
        !isSectionElement(object) &&
        object.type !== LAYOUT_SECTION && {
          label: (
            <div className="right-panel-overflow-option">
              <i className="material-icons">list</i>
              Make List
            </div>
          ),
          onClick: this.handleMakeList,
        },
      object.type === GROUP && {
        label: (
          <div className="right-panel-overflow-option">
            <i className="material-icons">call_split</i>
            Ungroup
          </div>
        ),
        onClick: this.handleUngroup,
      },
      object.type !== COMPONENT &&
        !isSectionElement(object) &&
        object.type !== LAYOUT_SECTION && {
          label: (
            <div className="right-panel-overflow-option">
              <i className="material-icons">merge_type</i>
              Make Group
            </div>
          ),
          onClick: this.handleMakeGroup,
        },
      {
        label: (
          <div className="right-panel-overflow-option">
            <Icon type="trash-small" />
            Delete
          </div>
        ),
        onClick: this.handleDelete,
      },
    ].filter(Boolean)

    return menuItems
  }

  render() {
    const { canMakeScreenTemplate, canMakePrebuiltLayoutSection, object } =
      this.props

    if (!this.objectSupportsMenu(object)) {
      return null
    }

    let width = 200
    if (canMakePrebuiltLayoutSection) {
      width = 340
    } else if (canMakeScreenTemplate) {
      width = 240
    }

    return (
      <div className="right-panel-overflow-menu">
        <MultiMenuTrigger
          menu={this.getMenu()}
          className="right-panel-overflow-menu-trigger"
          rowHeight={40}
          width={width}
          childWidth={205}
        >
          <IconButton type="more-vert" />
        </MultiMenuTrigger>
      </div>
    )
  }
}

const mapStateToProps = (state, { match, object }) => {
  const { list, map } = state.editor.objects.present
  const currentUser = getCurrentUser(state)
  const appLibraries = getAppLibraries(state, match.params.appId)

  const appLibrary = appLibraries.find(
    ({ name }) => name === object.libraryName
  )

  let parent = {}

  try {
    if (object?.type !== COMPONENT) {
      parent = getParent(list, map, object.id)
    }
  } catch (err) {
    console.error(err)
  }

  const canMakeScreenTemplate =
    !!(currentUser && currentUser.admin) &&
    object.type === COMPONENT &&
    !(object.screenTemplate && object.screenTemplate.enabled)

  const canMakePrebuiltLayoutSection =
    !!(currentUser && currentUser.admin) && object.type === LAYOUT_SECTION

  return {
    parent,
    appLibrary,
    canMakeScreenTemplate,
    canMakePrebuiltLayoutSection,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    deleteObject,
    ungroupObjects,
    updateObject,
    groupObjects,
    groupObjectsToList,
    reorderObjectsMoveFirst,
    reorderObjectsMoveLast,
    reorderObjectsMoveUp,
    reorderObjectsMoveDown,
    runInstructions,
    runSelectionInstruction,
  })(OverflowMenu)
)
