import { deepMap, getId, traverseMapObject } from '@adalo/utils'
import { selectors, sourceTypes } from '@adalo/constants'

const updateChildrenPushGraphIds = (children, map) =>
  traverseMapObject(children, itm => {
    if (itm && itm.pushGraph) {
      const updatedEdges = itm.pushGraph.edges.map(edge => ({
        ...edge,
        endNodeId: map[edge.endNodeId],
        startNodeId: map[edge.startNodeId],
      }))

      const updatedNodeIds = itm.pushGraph.nodeIds.map(nodeId => map[nodeId])

      return {
        ...itm,
        pushGraph: { edges: updatedEdges, nodeIds: updatedNodeIds },
      }
    }

    return itm
  })

const updateChildrenObjectIds = (children, map) =>
  traverseMapObject(children, itm => {
    if (itm && itm.type === selectors.LIST_ITEM && map[itm.listObjectId]) {
      return {
        ...itm,
        listObjectId: map[itm.listObjectId],
      }
    }

    if (itm && itm.type === sourceTypes.INPUT && map[itm.objectId]) {
      return {
        ...itm,
        objectId: map[itm.objectId],
      }
    }

    return itm
  })

export const changeChildIds = (object, map = {}) => {
  let children = object.children

  if (!children) {
    return object
  }

  children = deepMap(object.children, itm => {
    const newId = getId()
    map[itm.id] = newId

    return {
      ...itm,
      id: newId,
    }
  })

  children = updateChildrenObjectIds(children, map)

  // Update child ids in pushGraphs
  children = updateChildrenPushGraphIds(children, map)

  return { ...object, children }
}
