import { DEVICE_TYPES } from 'ducks/editor/positioning'
import { DEFAULT_DEVICE_SETTINGS } from 'utils/defaultDeviceSettings'
import { EditorObject, DeviceValue, SharedObject } from 'utils/responsiveTypes'

const getSharedObject = (object: EditorObject): SharedObject => {
  const sharedObject = { ...DEFAULT_DEVICE_SETTINGS }
  const objectSharedSettings = object.shared || { ...DEFAULT_DEVICE_SETTINGS }

  for (const device of DEVICE_TYPES) {
    if (!objectSharedSettings[device as DeviceValue]) {
      sharedObject[device as DeviceValue] = false
    }
  }

  if (object.children) {
    for (const child of object.children) {
      const childSharedObject = getSharedObject(child)

      for (const device of DEVICE_TYPES) {
        if (!childSharedObject[device as DeviceValue]) {
          sharedObject[device as DeviceValue] = false
        }
      }
    }
  }

  return sharedObject
}

export default getSharedObject
