import { DeviceType, LIST, listTypes } from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'
import { enableDeviceSpecificLayoutHandler } from './enableDeviceSpecificLayout'
import { COLUMN_BREAKPOINTS } from '../objects/helpers/getCustomListProperties'

export interface EnableDeviceSpecificCustomListColumnsOptions {
  objectId: string
}

export interface EnableDeviceSpecificCustomListColumnsInstruction {
  operation: 'enableDeviceSpecificCustomListColumns'
  options: EnableDeviceSpecificCustomListColumnsOptions
}

export const enableDeviceSpecificCustomListColumnsHandler = (
  state: InstructionState,
  options: EnableDeviceSpecificCustomListColumnsOptions
): InstructionState => {
  const { list, pathMap, selection } = state
  const { objectId } = options

  let updatedList = [...list]
  const oldObject: EditorObject = getObject(updatedList, pathMap, objectId)
  if (oldObject.type !== LIST) {
    throw new Error(
      `Cannot run this instruction on object type: ${oldObject.type}`
    )
  }

  for (const deviceType of [
    DeviceType.MOBILE,
    DeviceType.TABLET,
    DeviceType.DESKTOP,
  ]) {
    ;({ list: updatedList } = enableDeviceSpecificLayoutHandler(
      { list: updatedList, pathMap, selection },
      { objectId, device: deviceType }
    ))
  }

  const {
    columnCount = 1,
    rowMargin = 8,
    listType = listTypes.DEFAULT,
  } = oldObject
  const defaults = {
    columnCount,
    rowMargin,
    listType,
  }

  const newObject: EditorObject = {
    ...getObject(updatedList, pathMap, objectId),
    columnBreakpoints: COLUMN_BREAKPOINTS.CUSTOM,
    deviceColumns: {
      [DeviceType.MOBILE]: defaults,
      [DeviceType.TABLET]: defaults,
      [DeviceType.DESKTOP]: defaults,
    },
  }

  return {
    ...state,
    list: updateChangedObject(updatedList, pathMap, newObject, undefined),
  }
}

const enableDeviceSpecificCustomListColumns = (
  objectId: string
): EnableDeviceSpecificCustomListColumnsInstruction => ({
  operation: 'enableDeviceSpecificCustomListColumns',
  options: {
    objectId,
  },
})

export default enableDeviceSpecificCustomListColumns
