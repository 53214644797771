import { getSnapGrid } from '../../utils/snapping'

const SET_X_SNAP = Symbol('SET_X_SNAP')
const SET_Y_SNAP = Symbol('SET_Y_SNAP')
const RESET_SNAPS = Symbol('RESET_SNAPS')

// Middleware

export const snappingMiddleware = (newState, prevState) => {
  const newSelection = newState.selection
  const prevSelection = prevState.selection

  if (newSelection?.join('.') !== prevSelection?.join('.')) {
    return {
      ...newState,
      ...getSnapGrid(newState, newState.selection),
      currentXSnap: null,
      currentYSnap: null,
    }
  }
}

// Reducer

export default (state, action) => {
  if ([SET_X_SNAP, SET_Y_SNAP].includes(action.type)) {
    const type = action.type === SET_X_SNAP ? 'X' : 'Y'
    const key = `current${type}Snap`
    const { coord, object } = action

    return {
      ...state,
      [key]: { coord, object },
    }
  }

  if (action.type === RESET_SNAPS) {
    return {
      ...state,
      currentXSnap: null,
      currentYSnap: null,
    }
  }
}

// Actions

export const setXSnap = (coord = null, object = null) => ({
  type: SET_X_SNAP,
  coord,
  object,
})

export const setYSnap = (coord = null, object = null) => ({
  type: SET_Y_SNAP,
  coord,
  object,
})

export const resetSnaps = () => ({ type: RESET_SNAPS })

// Selectors

export const getXGrid = state => state.editor.objects.present.xGrid

export const getYGrid = state => state.editor.objects.present.yGrid

/**
 * @typedef {import('./types/snapping').Snap} Snap
 */

/**
 * @param {*} state
 * @returns {Snap | undefined}
 */
export const getXSnap = state => state.editor.objects.present.currentXSnap

/**
 * @param {*} state
 * @returns {Snap | undefined}
 */
export const getYSnap = state => state.editor.objects.present.currentYSnap
