import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import {
  COMPONENT,
  LABEL,
  SHAPE,
  SECTION,
  ELLIPSE,
  INPUT,
  inputTypeOptions,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  WEB_VIEW,
  SELECT,
  IMAGE,
  dataTypes,
  GROUP,
  LIST,
  LINE,
  LOCATION_INPUT,
  LAYOUT_SECTION,
  TABLE,
  backgroundStyle,
  VIDEO,
} from '@adalo/constants'

import GoogleHelpLink from 'components/Shared/GoogleHelpLink'
import PrerequisiteAlert from 'components/Shared/PrerequisiteAlert'
import FreeTrial from 'components/Shared/FreeTrial'
import { GroupedAccordion } from 'components/Shared/Accordion'

import { getThirdPartyApiKeyFor } from 'ducks/thirdPartyApiKeys'
import {
  selectObjects,
  updateObjects,
  updateObject,
  getComponentId,
  getCurrentAppId,
} from 'ducks/editor/objects'
import { getSelection } from 'ducks/editor/selection'
import { isFeatureEnabled } from 'ducks/organizations'
import { getApp } from 'ducks/apps'
import { getFeatureFlag } from 'ducks/featureFlags'

import { getFieldIds } from 'utils/fields'
import {
  getContainerFromLayoutHelper,
  isContainerSectionElement,
  isEditableSectionElement,
  isLayoutHelperSectionElement,
} from 'utils/layoutSections'
import { OBJECTS_SUPPORTING_BORDER_RADIUS_CONTROL } from 'utils/objects'

import { PanelSection } from '../../Shared/Panel'
import StylesAccordion from '../../Shared/StylesAccordion'
import EmptyState from '../../Shared/EmptyState'
import BindableTextControl from '../../Shared/BindableTextControl'
import NewActionRow from '../Actions/Row'
import Layers from '../Layers'
import InspectBody from './Body'
import BackgroundControl from './BackgroundControl'
import BorderControl from './BorderControl'
import BorderRadiusControl, { getMaxBorderRadius } from './BorderRadiusControl'
import PropControl from './Libraries/PropControl'
import MenuControl from './Libraries/MenuControl'
import DefaultDateControl from './Libraries/DefaultDateControl'
import GenericInspectRow from './GenericRow'
import ListControl, { ListControlChild } from './ListControl'
import SelectLabelControl from './SelectLabelControl'
import SelectDefaultValueControl from './SelectDefaultValueControl'
import MultiSelectionControl from './MultiSelectionControl'
import GroupControl from './GroupControl'
import IconRow from './Libraries/IconRow'
import LocationControl from './Libraries/LocationControl'
import Table from './Libraries/Table'
import LayoutSectionSliderControl from './LayoutSectionSliderControl'
import TextControl from './TextControl'
import ColorDropdown from './ColorDropdown'

import './Inspect.css'

const STYLES_ACCORDION = new Set([
  COMPONENT,
  LABEL,
  SHAPE,
  SECTION,
  LAYOUT_SECTION,
  TABLE,
  ELLIPSE,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  WEB_VIEW,
  SELECT,
  IMAGE,
  VIDEO,
  GROUP,
  LIST,
  LINE,
  LOCATION_INPUT,
])

const AUTO_GROUP_TITLES = new Map([
  [SECTION, 'Rectangle'],
  [ELLIPSE, 'Ellipse'],
  [IMAGE, 'Image'],
])

const OBJECTS_SUPPORTING_BACKGROUND_CONTROL = new Set([SHAPE, SECTION, ELLIPSE])

const OBJECTS_SUPPORTING_BORDER_CONTROL = new Set([
  SHAPE,
  SECTION,
  ELLIPSE,
  LINE,
])

const TEXT_COMPOSED_VALUE_PROP = {
  name: 'text',
  displayName: 'Text',
  type: 'text',
}

const IMAGE_COMPOSED_VALUE_PROP = {
  type: 'image',
  name: 'composedValue',
  displayName: 'Image Source',
}

const DATEPICKER_STYLE_OPTIONS = [
  { label: 'Date & Time Picker', value: dataTypes.DATE },
  { label: 'Date Picker', value: dataTypes.DATE_ONLY },
  { label: 'Date Text Input', value: 'dateTextInput' },
]

class InspectMenu extends Component {
  handleChange = value => {
    const { objects, updateObjects } = this.props

    const newObjects = objects.map(({ id }) => ({ ...value, id }))
    updateObjects(newObjects)

    if (value.children) {
      updateObjects(value.children)
    }
  }

  handleComposedValueChange = newValue => {
    const { composedValue: value } = newValue

    if (value === 'uploaded') {
      this.handleChange({ imageType: 'uploaded', imageBinding: null })
    } else if (value === 'url') {
      this.handleChange({ imageType: 'url', imageBinding: null })
    } else if (typeof value !== 'undefined') {
      this.handleChange({
        imageType: 'dynamic',
        imageBinding: value,
        filename1x: null,
      })
    } else {
      this.handleChange(newValue)
    }
  }

  getFieldOptions(includeNone = false) {
    const { fields } = this.props

    const simpleTypes = [
      dataTypes.TEXT,
      dataTypes.NUMBER,
      dataTypes.DATE,
      dataTypes.BOOLEAN,
    ]

    const options = []

    if (includeNone) {
      options.push({ label: 'None', value: null })
      options.push(null)
    }

    for (const fieldId of getFieldIds(fields)) {
      const field = fields[fieldId]

      if (simpleTypes.indexOf(field.type) !== -1) {
        options.push({
          label: field.name,
          value: fieldId,
        })
      }
    }

    return options
  }

  render() {
    const {
      appId,
      componentId,
      objects,
      googleApiKeyIsValid,
      isLocationEnabled,
      hasUpdatedGroups,
      children,
      hasIndependentBorderRadius,
    } = this.props

    if (!objects || objects.length === 0) {
      return <EmptyState>Nothing Selected</EmptyState>
    }

    const object = objects.length === 1 ? objects[0] : null

    // truthy if there are multiple objects and object is null
    // or if there is a single object and it's in STYLES_ACCORDION
    if (objects.length > 1 || (object && STYLES_ACCORDION.has(object.type))) {
      if (object && isEditableSectionElement(object)) {
        const sectionElementProps = []

        // Background Type - Transparent | Background Color | Background Image
        sectionElementProps.push(
          <MenuControl
            autoSelect
            key="backgroundStyle"
            name="backgroundStyle"
            displayName="Background Type"
            options={[
              { label: 'Transparent', value: 'none' },
              { label: 'Background Color', value: 'color' },
              { label: 'Background Image', value: 'image' },
            ]}
            value={object.backgroundStyle}
            onChange={this.handleChange}
          />
        )

        // Background Type: Background Image
        if (object.backgroundStyle === backgroundStyle.IMAGE) {
          let imageType = object.imageType

          if (!imageType && typeof object.filename1x === 'string') {
            imageType = 'uploaded'
          }

          const value = object.imageType === 'dynamic' ? object.imageBinding : imageType // prettier-ignore

          sectionElementProps.push(
            <PropControl
              key="imageControl"
              canBeUploaded
              objectId={object.id}
              appId={appId}
              onChange={this.handleComposedValueChange}
              value={value}
              prop={IMAGE_COMPOSED_VALUE_PROP}
            />
          )
        }

        // Background Type: Background Color | Background Image
        if (object.backgroundStyle !== backgroundStyle.NONE) {
          sectionElementProps.push(
            <BackgroundControl
              key="background-control"
              appId={appId}
              object={object}
            />,
            <BorderControl
              key="border-control"
              appId={appId}
              object={object}
            />,
            <MenuControl
              key="shadowEnabled"
              name="shadowEnabled"
              options={[
                {
                  label: 'On',
                  value: true,
                },
                {
                  label: 'Off',
                  value: false,
                },
              ]}
              value={object.shadow.enabled}
              displayName="Shadow"
              onChange={({ shadowEnabled }) => {
                this.handleChange({
                  shadow: {
                    ...object.shadow,
                    enabled: shadowEnabled,
                  },
                })
              }}
            />,
            // TODO @danicunhac: Move the default shadow control to a separate component
            <GenericInspectRow
              className="default-shadow-control"
              key="shadowControl"
            >
              <ColorDropdown
                label="Shadow Color"
                value={object.shadow.color}
                name="shadowColor"
                onChange={({ shadowColor }) =>
                  this.handleChange({
                    shadow: {
                      ...object.shadow,
                      color: shadowColor,
                    },
                  })
                }
              />
              <TextControl
                gray
                type="number"
                name="shadowX"
                value={object.shadow.x}
                onChange={({ shadowX }) =>
                  this.handleChange({
                    shadow: {
                      ...object.shadow,
                      x: shadowX,
                    },
                  })
                }
                title="X"
              />
              <TextControl
                gray
                type="number"
                name="shadowY"
                value={object.shadow.y}
                onChange={({ shadowY }) =>
                  this.handleChange({
                    shadow: {
                      ...object.shadow,
                      y: shadowY,
                    },
                  })
                }
                title="Y"
              />
              <TextControl
                gray
                type="number"
                name="size"
                value={object.shadow.size}
                onChange={({ size }) =>
                  this.handleChange({
                    shadow: {
                      ...object.shadow,
                      size,
                    },
                  })
                }
                title="Size"
              />
            </GenericInspectRow>
          )
        }

        // Background Type - Transparent | Background Color | Background Image
        sectionElementProps.push(
          <BorderRadiusControl
            sliderBackground
            key="border-radius-control"
            onChange={this.handleChange}
            value={object.borderRadius}
            name="borderRadius"
            max={getMaxBorderRadius(object)}
            min={0}
          />,
          <LayoutSectionSliderControl
            key="opacity-control"
            percentage
            sliderBackground
            name="opacity"
            title="Opacity"
            value={object.opacity}
            defaultValue={1}
            onChange={this.handleChange}
            min={0}
            max={100}
            gray
          />
        )

        const isContainer = isContainerSectionElement(object)

        return (
          <div
            className={classNames('library-inspect', 'layout-section-props')}
          >
            <GroupedAccordion
              defaultExpanded
              group="form-inspect-field"
              className="library-inspect-accordion"
              title={isContainer ? 'Components' : 'Containers'}
              object={object}
              renderChildren={() => <Layers objectId={object.id} />}
            />
            <GroupedAccordion
              group="form-inspect-field"
              className="library-inspect-accordion"
              title={isContainer ? 'Container Styles' : 'Section Styles'}
              object={object}
              renderChildren={() => (
                <div className="library-inspect">{sectionElementProps}</div>
              )}
            />
            {isContainer && (
              <GroupedAccordion
                group="form-inspect-field"
                className="library-inspect-accordion"
                title="Actions"
                object={object}
                renderChildren={() => (
                  <div className="library-inspect">
                    <NewActionRow
                      key="click-actions"
                      displayName="Click Actions"
                      appId={appId}
                      componentId={componentId}
                      object={object}
                    />
                  </div>
                )}
              />
            )}
          </div>
        )
      }

      const result = []

      if (object && OBJECTS_SUPPORTING_BACKGROUND_CONTROL.has(object.type)) {
        result.push(
          <BackgroundControl
            key="background-control"
            appId={appId}
            object={object}
          />
        )
      }

      if (object && OBJECTS_SUPPORTING_BORDER_CONTROL.has(object.type)) {
        result.push(
          <BorderControl key="border-control" appId={appId} object={object} />
        )
      }

      if (object && object.type === LABEL) {
        result.push(
          <GenericInspectRow
            key="dropdownOptions"
            title=""
            className="default-date-control"
          >
            <PropControl
              objectId={object.id}
              onChange={this.handleChange}
              value={object.text}
              prop={TEXT_COMPOSED_VALUE_PROP}
              // NOTE: this is not actually an aria role, eslint is confused
              // eslint-disable-next-line jsx-a11y/aria-role
              role="text"
              reference="text"
            />
          </GenericInspectRow>
        )
      }

      if (object && object.type === WEB_VIEW) {
        result.push(
          <BindableTextControl
            key="uri"
            displayName="URL"
            name="uri"
            placeholder="https://example.com"
            value={object.uri}
            objectId={object.id}
            onChange={this.handleChange}
          />
        )
      }

      if (object && object.type === DATE_PICKER) {
        result.push(
          <DefaultDateControl
            key="defaultDate"
            name="defaultDate"
            displayName="Default Date"
            object={object}
          />
        )

        result.push(
          <MenuControl
            key="datePickerStyle"
            name="datePickerStyle"
            displayName="Style"
            value={object.datePickerStyle || dataTypes.DATE}
            onChange={this.handleChange}
            options={DATEPICKER_STYLE_OPTIONS}
          />
        )
      }

      if (object && object.type === SELECT) {
        const prop = {
          type: SELECT,
          name: 'select',
          value: object.select,
          objectId: object.id,
        }

        result.push(
          <BindableTextControl
            key="placeholder"
            disableBinding
            hideBinding
            displayName="Placeholder"
            name="placeholder"
            value={object.placeholder}
            objectId={object.id}
            onChange={this.handleChange}
          />
        )

        result.push(
          <GenericInspectRow
            key="dropdownOptions"
            title=""
            className="default-select-control"
          >
            <PropControl
              objectId={object.id}
              onChange={this.handleChange}
              value={object.select}
              prop={prop}
            />
            {object.select ? (
              <>
                <SelectLabelControl
                  appId={appId}
                  value={object.select}
                  onChange={this.handleChange}
                  name="select"
                />
                <SelectDefaultValueControl
                  appId={appId}
                  componentId={componentId}
                  objectId={object.id}
                  value={object?.defaultValue}
                  onChange={this.handleChange}
                />
              </>
            ) : null}
          </GenericInspectRow>
        )
      }

      if (object && object.type === INPUT) {
        result.push(
          <MenuControl
            autoSelect
            key="inputType"
            name="inputType"
            displayName="Type"
            options={inputTypeOptions}
            value={object.inputType}
            onChange={this.handleChange}
          />
        )

        result.push(
          <BindableTextControl
            key="placeholder"
            disableBinding
            hideBinding
            displayName="Placeholder"
            name="placeholder"
            value={object.placeholder}
            objectId={object.id}
            onChange={this.handleChange}
          />
        )

        result.push(
          <BindableTextControl
            key="defaultValue"
            displayName="Default Value"
            name="defaultValue"
            value={object.defaultValue}
            objectId={object.id}
            onChange={this.handleChange}
          />
        )
      }

      if (object && object.type === IMAGE) {
        let imageType = object.imageType

        if (!imageType && typeof object.filename1x === 'string') {
          imageType = 'uploaded'
        }

        const value =
          object.imageType === 'dynamic' ? object.imageBinding : imageType

        result.push(
          <PropControl
            key="imageControl"
            canBeUploaded
            objectId={object.id}
            appId={appId}
            onChange={this.handleComposedValueChange}
            value={value}
            prop={IMAGE_COMPOSED_VALUE_PROP}
          />
        )
      }

      if (object && object.type === VIDEO) {
        const videoOptions = [
          {
            prop: 'videoControls',
            displayName: 'Show Controls',
            tooltip:
              'When set to "Off", the video will play automatically with the sound muted',
          },
          {
            prop: 'videoAutoPlay',
            displayName: 'Auto-play',
            tooltip:
              'When to "On", the video will play automatically with the sound muted',
          },
          {
            prop: 'videoMuted',
            displayName: 'Muted',
            tooltip: undefined,
          },
          {
            prop: 'videoLoop',
            displayName: 'Loop',
            tooltip: undefined,
          },
        ].map(({ prop, displayName, tooltip }) => (
          <MenuControl
            key={prop}
            name={prop}
            options={[
              {
                label: 'On',
                value: true,
              },
              {
                label: 'Off',
                value: false,
              },
            ]}
            value={object[prop]}
            displayName={displayName}
            tooltip={tooltip}
            onChange={props => {
              const changes = {
                [prop]: props[prop],
                ...(prop === 'videoControls' &&
                  props[prop] === false && {
                    videoAutoPlay: true,
                    videoMuted: true,
                  }),
                ...(prop === 'videoAutoPlay' &&
                  props[prop] === true && {
                    videoMuted: true,
                  }),
                ...(prop === 'videoMuted' &&
                  (object.videoControls === false ||
                    object.videoAutoPlay === true) && {
                    videoMuted: true,
                  }),
              }
              this.handleChange(changes)
            }}
          />
        ))

        const imageType =
          object.imageType ||
          (typeof object.filename1x === 'string' ? 'uploaded' : null)
        const value = object.imageType === 'dynamic' ? object.imageBinding : imageType // prettier-ignore

        result.push(
          <PropControl
            key="videoControl"
            appId={appId}
            onChange={this.handleComposedValueChange}
            prop={{
              type: 'video',
              name: 'composedValue',
              displayName: 'Video Source',
              thumbnailControl: (
                <PropControl
                  key="imageControl"
                  canBeUploaded
                  objectId={object.id}
                  appId={appId}
                  onChange={this.handleComposedValueChange}
                  value={value}
                  prop={{
                    type: 'image',
                    name: 'composedValue',
                    displayName: 'Thumbnail',
                    allowPlaceholder: false,
                    allowImageCropping: false,
                  }}
                />
              ),
            }}
          />,
          ...videoOptions
        )
      }

      if (object && object.type === GROUP) {
        result.push(
          <GroupControl
            object={object}
            filteredChildren={children}
            key="group-control"
          />
        )
      }

      if (object && object.type === LIST) {
        result.push(
          <ListControl
            key="list-control"
            appId={appId}
            componentId={componentId}
            object={object}
            onChange={this.handleChange}
          />
        )
      }

      if (object && object.type === TABLE) {
        result.push(
          <Table
            appId={appId}
            componentId={componentId}
            object={object}
            onChange={this.handleChange}
          />
        )
      }

      if (object && object.type === LOCATION_INPUT) {
        if (!isLocationEnabled) {
          return <FreeTrial appId={appId} type="geolocation" />
        }

        result.push(
          <PrerequisiteAlert
            isPrerequisiteMet={googleApiKeyIsValid}
            name="Google Maps API Key"
            footer={<GoogleHelpLink />}
            context="component"
            key="googleApiKey"
          />
        )

        result.push(
          <BindableTextControl
            key="placeholder"
            disableBinding
            hideBinding
            displayName="Placeholder"
            name="placeholder"
            value={object.placeholder}
            objectId={object.id}
            onChange={this.handleChange}
          />
        )

        result.push(
          <IconRow
            key="icon"
            displayName="Icon"
            name="icon"
            value={object.icon}
            onChange={this.handleChange}
          />
        )

        result.push(
          <LocationControl
            key="defaultLocation"
            onChange={this.handleChange}
            defaultValue={object.defaultValue}
            objectId={object.id}
          />
        )
      }

      if (hasIndependentBorderRadius) {
        if (OBJECTS_SUPPORTING_BORDER_RADIUS_CONTROL.has(object?.type)) {
          result.push(
            <BorderRadiusControl
              sliderBackground
              key="border-radius-control"
              onChange={this.handleChange}
              value={object.borderRadius}
              name="borderRadius"
              max={getMaxBorderRadius(object)}
              min={0}
              title="Rounding"
              object={object}
              showAdvanced
            />
          )
        }
      } else if (object?.type === SECTION) {
        result.push(
          <BorderRadiusControl
            sliderBackground={false}
            key="border-radius-control"
            onChange={this.handleChange}
            value={object.borderRadius}
            name="borderRadius"
            max={getMaxBorderRadius(object)}
            min={0}
          />
        )
      }

      if (
        object &&
        new Set([SHAPE, SECTION, ELLIPSE, LABEL, IMAGE, GROUP]).has(object.type)
      ) {
        result.push(
          <NewActionRow
            key="click-actions"
            displayName="Click Actions"
            appId={appId}
            componentId={componentId}
            object={object}
          />
        )
      }

      if (objects.length > 1) {
        result.push(
          <MultiSelectionControl
            objects={objects}
            key="multi-selection-control"
          />
        )
      }

      if (hasUpdatedGroups && object && AUTO_GROUP_TITLES.has(object.type)) {
        const title = AUTO_GROUP_TITLES.get(object.type)

        const renderProps = () => (
          <div className="library-inspect">{result}</div>
        )

        const renderChildren = () => (
          <>
            {children.map(child => (
              <ListControlChild key={child.id} object={child} />
            ))}
          </>
        )

        if (children && children.length > 0) {
          return (
            <div className="library-inspect">
              <GroupedAccordion
                defaultExpanded
                group="form-inspect-field"
                className="library-inspect-accordion"
                title="Components"
                object={object}
                renderChildren={renderChildren}
              />
              <GroupedAccordion
                group="form-inspect-field"
                className="library-inspect-accordion"
                title={title}
                object={object}
                renderChildren={renderProps}
              />
              <StylesAccordion
                spaced
                key="styles"
                renderChildren={() => <InspectBody objects={objects} />}
              />
            </div>
          )
        }
      }

      result.push(
        <StylesAccordion
          spaced
          key="styles"
          renderChildren={() => <InspectBody objects={objects} />}
        />
      )

      if (object && object.type === LOCATION_INPUT) {
        result.push(
          <div className="geolocation-trial-prompt">
            <FreeTrial appId={appId} type="geolocation" />
          </div>
        )
      }

      return <div className="library-inspect">{result}</div>
    }

    return (
      <PanelSection title="Properties">
        <InspectBody objects={objects} />
      </PanelSection>
    )
  }
}

const mapStateToProps = (state, props) => {
  const appId = getCurrentAppId(state)
  const selection = getSelection(state)
  const objects = selectObjects(state, selection)
  const googleApiKey = getThirdPartyApiKeyFor(state, appId, 'google')
  const isLocationEnabled = isFeatureEnabled(state, 'geolocation')
  const hasUpdatedGroups = getFeatureFlag(state, 'hasUpdatedGroups')
  const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')
  const hasIndependentBorderRadius = getFeatureFlag(
    state,
    'hasIndependentBorderRadius'
  )

  const googleApiKeyIsValid = googleApiKey && googleApiKey.isValid
  let object = {}
  let componentId = null

  if (selection.length === 0) return { appId }

  if (selection.length === 1) {
    componentId = getComponentId(state, selection[0])
    object = objects[0]
  }
  let children = object?.children || []

  if (hasNewMobileOnlyApp) {
    const app = getApp(state, appId)
    if (app?.webSettings?.layoutMode === 'mobile') {
      children = children.filter(
        child => child.deviceVisibility?.mobile !== false
      )
    }
  }

  children = children.map(child => {
    if (isLayoutHelperSectionElement(child)) {
      return getContainerFromLayoutHelper(child)
    }

    return child
  })

  return {
    appId,
    componentId,
    objects,
    googleApiKeyIsValid,
    isLocationEnabled,
    hasUpdatedGroups,
    children,
    hasIndependentBorderRadius,
  }
}

export default connect(mapStateToProps, { updateObjects, updateObject })(
  InspectMenu
)
