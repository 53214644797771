import React, { useState } from 'react'
import { LIBRARY_COMPONENT } from '@adalo/constants'

// components
import { IconButton } from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'

// Pickers
import FontPicker from 'components/Editor/Inspect/FontPicker'
import ColorPicker from 'components/Editor/Inspect/ColorPicker'
import TextAlignPicker from 'components/Editor/Inspect/TextAlignPicker'
import TextControl from 'components/Editor/Inspect/TextControl'
import TextWeightPicker from 'components/Editor/Inspect/TextWeightPicker'

const EditStyles = props => {
  const { onChange, object, enabledStyles, values, name } = props

  const isLibrary =
    object.type === LIBRARY_COMPONENT || object.subType === LIBRARY_COMPONENT
  const prop = isLibrary ? values : object

  const enabled = {
    fontFamily: isLibrary ? enabledStyles?.fontFamily : true,
    fontWeight: isLibrary ? enabledStyles?.fontWeight : true,
    fontSize: isLibrary ? enabledStyles?.fontSize : true,
    textAlignment: isLibrary ? enabledStyles?.textAlignment : true,
    color: isLibrary ? enabledStyles?.color : true,
  }

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const handleChange = newValue => {
    if (isLibrary) {
      onChange({ styles: { [name]: newValue } })
    } else {
      onChange(newValue)
    }
  }

  return (
    <div className="entity-text-control-edit-styles">
      <Tooltip
        small
        hideArrow
        tooltip="Formatting"
        placement="bottom"
        className="entity-text-control-tooltip"
      >
        <IconButton
          rounded
          type="color-lens"
          active={isOpen}
          onClick={toggle}
          darkPink
          muted
        />
      </Tooltip>
      <div className="entity-text-control-edit-styles-items">
        {isOpen
          ? Object.keys(enabled).map(key => {
              if (!key || !enabled[key]) return null

              const propStyles = getPropStyles(prop, {
                name,
                isLibrary,
              })

              let value = propStyles?.[key]

              // Catch if the value is actually 0. If it's 0, set the value to null.
              // This can happen if the maker is editing the font size and deletes the numbers.
              if (!value && value !== 0) {
                value = enabled[key]
              }

              if (value === 0) value = null

              const fontFamily = propStyles?.fontFamily

              return (
                <div
                  key={`edit-styles-${key}`}
                  className="entity-text-control-edit-styles-item"
                >
                  <span className="entity-text-control-edit-styles-divider" />
                  {key === 'fontFamily' ? (
                    <Tooltip
                      tooltip="Font"
                      placement="bottom"
                      className="entity-text-control-tooltip"
                      small
                    >
                      <FontPicker
                        name="fontFamily"
                        onChange={handleChange}
                        value={value}
                      />
                    </Tooltip>
                  ) : key === 'fontWeight' ? (
                    <Tooltip
                      tooltip="Text Weight"
                      placement="bottom"
                      className="entity-text-control-tooltip"
                      small
                    >
                      <TextWeightPicker
                        name="fontWeight"
                        onChange={handleChange}
                        value={value}
                        currentFont={fontFamily}
                      />
                    </Tooltip>
                  ) : key === 'fontSize' ? (
                    <Tooltip
                      tooltip="Size"
                      placement="bottom"
                      className="entity-text-control-tooltip"
                      small
                    >
                      <TextControl
                        name="fontSize"
                        onChange={handleChange}
                        value={value}
                        minValue={1}
                        type="number"
                        disableIncrementer
                      />
                    </Tooltip>
                  ) : key === 'textAlignment' ? (
                    <Tooltip
                      tooltip="Alignment"
                      placement="bottom"
                      className="entity-text-control-tooltip"
                      small
                    >
                      <TextAlignPicker
                        name="textAlignment"
                        onChange={handleChange}
                        value={value}
                      />
                    </Tooltip>
                  ) : key === 'color' ? (
                    <Tooltip
                      tooltip="Color"
                      placement="bottom"
                      className="entity-text-control-tooltip"
                      small
                    >
                      <ColorPicker
                        name="color"
                        onChange={handleChange}
                        value={value}
                        values={values}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default EditStyles

function getPropStyles(prop, opts = {}) {
  const { isLibrary, name } = opts

  if (isLibrary) {
    return prop?.styles?.[name]
  } else {
    return prop
  }
}
