import { maxBy } from 'lodash'
import { adaloBackendAxios } from 'utils/io/http/axios'

const REQUEST_BUILDS = 'REQUEST_BUILDS'
const CREATE_BUILD = 'CREATE_BUILD'

const INITIAL_STATE = {
  builds: [],
}

// Reducer
export default (state = INITIAL_STATE, action) => {
  if (action.type === `${REQUEST_BUILDS}_FULFILLED`) {
    const { data: builds } = action.payload

    return { ...state, builds }
  }

  if (action.type === `${CREATE_BUILD}_FULFILLED`) {
    const { data: build } = action.payload

    return { ...state, builds: [build].concat(state.builds) }
  }

  return state
}

// Actions

export const requestBuilds = () => ({
  type: REQUEST_BUILDS,
  payload: adaloBackendAxios.get(`/builds`),
})

export const restartBuild = buildId => (dispatch, getState) => {
  const state = getState()
  const build = getBuild(state, buildId)

  if (!build) {
    window.alert('Could not find build')
  }

  const { AppId: appId, target, version } = build

  return dispatch({
    type: CREATE_BUILD,
    payload: adaloBackendAxios.post(`/builds`, { appId, target, version }),
  })
}

export const restartFailedBuilds =
  (timeFrom, target = null) =>
  (dispatch, getState) => {
    const state = getState()

    const failedBuilds = getBuilds(state).filter(
      build => build.status === 'failed' && build.updatedAt > timeFrom
    )

    const includedTargets = target ? [target] : ['ios', 'android']
    const filteredBuilds = []

    const containsAppBuild = (appId, target) =>
      filteredBuilds.find(b => b?.AppId === appId && b?.target === target)

    for (const build of failedBuilds) {
      for (const target of includedTargets) {
        if (!containsAppBuild(build.AppId, target)) {
          const failedAppBuilds = failedBuilds.filter(
            b => b.AppId === build.AppId && b.target === target
          )

          const latestFailedBuild = maxBy(failedAppBuilds, 'updatedAt')

          if (latestFailedBuild) {
            filteredBuilds.push(latestFailedBuild)
          }
        }
      }
    }

    filteredBuilds.forEach(build => dispatch(restartBuild(build.id)))
  }

// Selectors

export const getBuilds = (state, target = null) => {
  let builds = state.admin.builds.builds

  if (target) {
    builds = builds.filter(build => build.target === target)
  }

  return builds
}

export const getBuild = (state, buildId) => {
  const builds = getBuilds(state)

  return builds.filter(b => b.id === buildId)[0]
}
