import { COMPONENT, positioning } from '@adalo/constants'
import InstructionState from '../types/InstructionState'
import {
  defaultBounds,
  getSelectionBounds,
  getInflowScreenElements,
  getStickyScreenElements,
} from '../getSelectionBounds'
import getObject from '../objects/helpers/getObject'
import { resizeScreenHandler } from './resizeScreen'

export interface FitScreenHeightToComponentsOptions {
  screenId: string
}

export interface FitScreenHeightToComponentsInstruction {
  operation: 'fitScreenHeightToComponents'
  options: FitScreenHeightToComponentsOptions
}

const EXTRA_PADDING = 20

export const fitScreenHeightToComponentsHandler = (
  state: InstructionState,
  options: FitScreenHeightToComponentsOptions
): InstructionState => {
  const { list, pathMap } = state
  const { screenId } = options

  const screen = getObject(list, pathMap, screenId)
  if (screen.type !== COMPONENT) {
    throw new Error(`Supplied screenId='${screenId}' is not a screen`)
  }

  const stickyElementIds = getStickyScreenElements(
    state,
    screenId,
    positioning.FIXED_BOTTOM
  )
  const inflowElementIds = getInflowScreenElements(state, screenId)

  const bounds = getSelectionBounds(state, [
    ...stickyElementIds,
    ...inflowElementIds,
  ])

  const stickyBounds = getSelectionBounds(state, stickyElementIds)
  const stickyElementsHeight =
    stickyBounds.bottom === defaultBounds.bottom ||
    stickyBounds.top === defaultBounds.top
      ? 0
      : stickyBounds.bottom - stickyBounds.top

  if (bounds.bottom > screen.height) {
    return resizeScreenHandler(state, {
      screenId,
      width: screen.width,
      height: bounds.bottom + EXTRA_PADDING + stickyElementsHeight,
    })
  }

  return state
}

const fitScreenHeightToComponents = (
  screenId: string
): FitScreenHeightToComponentsInstruction => ({
  operation: 'fitScreenHeightToComponents',
  options: {
    screenId,
  },
})

export default fitScreenHeightToComponents
