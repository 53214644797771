import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { validateDate, formatDateOnly } from 'utils/datetime'
import 'react-datepicker/dist/react-datepicker.css'
import './WrappedDatePicker.css'

const WrappedDatePicker = props => {
  const { input, showTimeInput } = props
  const { value, onChange } = input

  const handleChange = newValue => {
    if (!newValue) {
      return onChange(null)
    }

    const formattedValue = moment(newValue).seconds(0).format()

    return onChange(formattedValue)
  }

  const getValue = () => {
    const validatedValue = validateDate(value)

    if (!validatedValue) {
      return undefined
    }

    if (showTimeInput) {
      return new Date(validatedValue)
    }

    return new Date(formatDateOnly(validatedValue))
  }

  const styleProps = {}

  if (showTimeInput) {
    styleProps.dateFormat = 'MM/dd/yyyy h:mm aa'
  }

  const selected = getValue()

  return (
    <DatePicker
      {...props}
      {...styleProps}
      input={input}
      selected={selected}
      onChange={handleChange}
      timeInputLabel="Time:"
    />
  )
}

export default WrappedDatePicker
