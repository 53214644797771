import {
  EditorObject,
  ContainerAttributes,
  LayoutAttributes,
} from 'utils/responsiveTypes'
import { COMPONENT } from '@adalo/constants'
import { DeviceVisibility } from './types'
import getObjectByPath from '../objects/helpers/getObjectByPath'
import ObjectPath from '../objects/ObjectPath'
import DeviceType from '../types/DeviceType'
import { ObjectList } from '../types/ObjectList'
import { ObjectPathMap } from '../types/ObjectPathMap'

export const getParent = (
  list: ObjectList,
  map: ObjectPathMap,
  childId: string
): EditorObject => {
  const path = ObjectPath.fromString(map[childId] ?? '')
  const parentPath = path.parentPath()

  return getObjectByPath(list, parentPath)
}

export const getParentId = (
  list: ObjectList,
  map: ObjectPathMap,
  childId: string
): string => {
  const { id: parentId } = getParent(list, map, childId)

  return parentId
}

export const hasDeviceLayoutEnabled = (
  object: EditorObject,
  device: DeviceType | undefined
): boolean => {
  const { type, shared } = object

  if (type === COMPONENT) {
    return false
  }

  if (device === undefined) {
    // Implies we're checking for the shared layout, specifically.
    return false
  }

  if (shared === undefined) {
    // If the `shared` object is missing, this object likely pre-dates device-specific layouts.
    // The default behaviour is to use shared layouts for all devices.
    return false
  }

  const { [device]: usesShared = true } = shared

  return !usesShared
}

export const amendChangesWithDeviceSpecificObject = (
  object: EditorObject,
  device: DeviceType,
  changes: Partial<LayoutAttributes & ContainerAttributes & DeviceVisibility>
): Partial<LayoutAttributes & ContainerAttributes & DeviceVisibility> => ({
  ...object[device],
  ...changes,
})
