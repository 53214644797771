import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import deepEqual from 'deep-equal'

import { IconButton } from '../../Shared/Icon'
import WrappedSelect from '../../Shared/Forms/WrappedSelect'

export default class ParamItem extends Component {
  handleChange = value => {
    const { param, onChange } = this.props

    return onChange({ ...param, ...value })
  }

  handleDelete = () => {
    const { param, onDelete } = this.props

    if (!param || !param.id) {
      return
    }

    onDelete(param.id)
  }

  render() {
    const { param, typeOptions } = this.props

    return (
      <Form
        form={`paramItemForm_${param.id}`}
        param={param}
        onChange={this.handleChange}
        onDelete={this.handleDelete}
        initialValues={param}
        typeOptions={typeOptions}
        onSubmit={() => {}}
      />
    )
  }
}

class ParamItemForm extends Component {
  render() {
    const { typeOptions, onDelete, handleSubmit } = this.props

    return (
      <form className="param-item" onSubmit={handleSubmit}>
        <div className="input-wrapper">
          <Field
            autoComplete="off"
            component="input"
            name="name"
            placeholder="Enter name"
          />
        </div>
        <Field
          autoSelect
          component={WrappedSelect}
          name="type"
          className="small"
          options={typeOptions}
          comparator={deepEqual}
        />
        <IconButton
          className="param-item-delete-icon"
          type="trash-small"
          onClick={onDelete}
        />
      </form>
    )
  }
}

const Form = reduxForm({})(ParamItemForm)
