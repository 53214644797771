import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { authTypes, authTypeOptions } from '@adalo/constants'

import { getDatasources } from 'ducks/apps/datasources'
import ControlField from 'components/Shared/Forms/ControlField'
import BindableTextControl from 'components/Shared/BindableTextControl'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import { THEMES } from '../../../../constants'

class AuthenticateOptions extends Component {
  datasourceOptions() {
    const { datasources } = this.props

    return Object.keys(datasources).map(id => ({
      label: 'App Data',
      value: datasources[id].id,
    }))
  }

  renderField(key, displayName) {
    const { field, objectId, actionId, role, reference, menuTheme } = this.props

    const fieldName = `${field}.options.${key}`

    return (
      <ControlField
        name={fieldName}
        displayName={displayName}
        component={BindableTextControl}
        objectId={objectId}
        actionId={actionId}
        role={role}
        reference={reference}
        menuTheme={menuTheme}
      />
    )
  }

  renderOptions() {
    const { options } = this.props

    switch (options && options.authType) {
      case authTypes.USERNAME:
        return (
          <React.Fragment>
            {this.renderField('username', 'Username')}
            {this.renderField('password', 'Password')}
          </React.Fragment>
        )
      case authTypes.EMAIL:
        return (
          <React.Fragment>
            {this.renderField('email', 'Email')}
            {this.renderField('password', 'Password')}
          </React.Fragment>
        )
      case authTypes.PHONE_PASSWORD:
        return null
    }

    return null
  }

  render() {
    const { field } = this.props

    return (
      <div
        className={classNames(
          'action-form-authenticate',
          'action-item-fields',
          'form-inspect-field-wrapper'
        )}
      >
        <ControlField
          autoSelect
          name={`${field}.options.authType`}
          displayName="Login Type"
          component={MenuControl}
          options={authTypeOptions}
          menuTheme={THEMES.DATA}
        />
        <div style={{ display: 'none' }}>
          <ControlField
            autoSelect
            name={`${field}.options.datasourceId`}
            component={MenuControl}
            options={this.datasourceOptions()}
          />
        </div>
        {this.renderOptions()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId } = props
  const datasources = getDatasources(state, appId)

  return { datasources }
}

export default connect(mapStateToProps)(AuthenticateOptions)
