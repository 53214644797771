import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export enum OnboardingStep {
  Audience = 1,
  AppCategory = 2,
  SampleData = 3,
  AppPlatform = 4,
}

const OnboardingStepAnchor: { [key in OnboardingStep]: string } = {
  [OnboardingStep.Audience]: 'audience',
  [OnboardingStep.AppCategory]: 'category',
  [OnboardingStep.SampleData]: 'data',
  [OnboardingStep.AppPlatform]: 'buildmode',
}

const useOnboardingSteps = (): {
  step: OnboardingStep
  setStep: React.Dispatch<React.SetStateAction<OnboardingStep>>
} => {
  const [step, setStep] = useState<OnboardingStep>(OnboardingStep.Audience)
  const history = useHistory()

  useEffect(() => {
    const anchor = OnboardingStepAnchor[step] ?? ''
    history.push(`/onboarding/#${anchor}`)
  }, [step])

  return { step, setStep }
}

export default useOnboardingSteps
