import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import chroma from 'chroma-js'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import DocumentEvents from 'react-document-events'

import {
  getPresetsForBranding,
  contrastWithBackground,
  getBrandingFromHex,
  normalizeColor,
  normalizeColorName,
} from '../../../utils/colors'
import { RETURN } from '../../../utils/keyboard'

import './ColorDropdown.css'

function stopPropagation(e) {
  e.stopPropagation()
}

export default class ColorDropdown extends Component {
  static propTypes = {
    branding: PropTypes.shape({
      primary: PropTypes.string.isRequired,
      secondary: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    normalize: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    title: PropTypes.string,
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    normalize: true,
    disabled: false,
  }

  state = { isOpen: false, x: 0, y: 0 }

  toggleOpen = e => {
    const position = e.currentTarget.getBoundingClientRect()

    this.setState(({ isOpen: wasOpen }) => {
      const isOpen = !wasOpen
      const { disabled, onBlur, onFocus, value } = this.props

      if (isOpen) {
        if (disabled) {
          return { isOpen: false }
        }

        const width = 220
        const height = 300

        let y = position.top + position.height + 10
        let x = position.left + position.width - width

        if (y + height > window.innerHeight) {
          x = position.x + position.width + 10
          y = window.innerHeight - height - 10
        }

        if (onFocus) {
          onFocus(value)
        }

        return { x, y, isOpen }
      }

      if (onBlur) {
        onBlur(value)
      }

      return { isOpen }
    })
  }

  handleClose = () => {
    this.setState(({ isOpen: wasOpen }) => {
      if (wasOpen) {
        const { onBlur, value } = this.props

        if (onBlur) {
          onBlur(value)
        }
      }

      return { isOpen: false }
    })
  }

  handleChange = value => {
    const { branding, name, normalize, onChange } = this.props

    if (normalize) {
      const normalizedValue = getBrandingFromHex(value, branding)

      if (normalizedValue.startsWith('@')) {
        onChange({ [name]: normalizedValue })
      } else {
        onChange({ [name]: chroma(normalizedValue).hex() })
      }
    } else {
      onChange({ [name]: value.hex })
    }
  }

  handleKeyPress = e => {
    if (e.which === RETURN) {
      this.handleClose()
    }
  }

  renderPicker() {
    const { branding, normalize, value } = this.props
    const normalizedValue = normalize ? normalizeColor(value, branding) : value
    const { x, y } = this.state

    return ReactDOM.createPortal(
      <>
        <div className="color-picker-close" onMouseDown={this.handleClose} />
        <div
          className="color-picker"
          style={{ left: x, top: y }}
          onClick={stopPropagation}
        >
          <SketchPicker
            color={normalizedValue}
            onChangeComplete={this.handleChange}
            presetColors={getPresetsForBranding(branding)}
          />
          <DocumentEvents onKeyPress={this.handleKeyPress} />
        </div>
      </>,
      document.body
    )
  }

  render() {
    const {
      branding,
      normalize,
      title,
      value,
      disabled,
      className,
      ctx,
      label,
    } = this.props

    const { isOpen } = this.state

    let normalizedValue = normalize
      ? normalizeColor(value, branding, ctx)
      : value

    if (normalizedValue === 'transparent') {
      normalizedValue = 'rgba(0,0,0,0)'
    }

    const normalizedValueName = normalize ? normalizeColorName(value) : value

    const combinedClassName = classNames(
      className,
      'color-dropdown',
      contrastWithBackground(normalizedValue) === '#fff'
        ? 'color-dropdown-dark'
        : 'color-dropdown-light',
      chroma.contrast(normalizedValue || '#fff', '#fff') < 1.32 &&
        'color-dropdown-light-border',
      disabled && 'disabled'
    )

    return (
      <div className="color-dropdown__wrapper">
        {Boolean(label) && <label>{label}</label>}
        <div className={combinedClassName} onClick={this.toggleOpen}>
          <div
            className="color-dropdown-inner-wrapper"
            style={{ backgroundColor: normalizedValue }}
          >
            <div className="color-dropdown-texts">
              <div className="color-dropdown-title">
                {title || normalizedValueName}
              </div>
              {title ? (
                <div className="color-dropdown-hex">{normalizedValueName}</div>
              ) : null}
            </div>
            <span className="color-dropdown-icon icon icon-expand-vertical" />
            {isOpen ? this.renderPicker() : null}
          </div>
        </div>
      </div>
    )
  }
}
