import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import QS from 'qs'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { showModal, setPaymentRouteType } from 'ducks/trialWarning'

import Button from 'components/Shared/Button'

import DomainPicker from '../DomainPicker'

const asyncValidate = async app => {
  try {
    const query = QS.stringify({
      path: app.path || '',
      organizationId: app.orgId,
      appId: app.appId,
      domainId: app.DomainId,
    })

    const url = `/organizations/path-availability?${query}`

    await adaloBackendAxios.get(url)
  } catch (err) {
    throw { _error: `This URL is already in use by another app.` }
  }
}

const Form = props => {
  const { appId, handleSubmit, submitting, dirty, error } = props

  const handleShowModal = e => {
    e.preventDefault()
    const { showModal, setPaymentRouteType } = props
    setPaymentRouteType('upgrade')

    return showModal()
  }

  const renderCustomDomain = () => {
    const { needUpgrade } = props

    return needUpgrade ? (
      <div className="settings-form-row">
        <label>Domain</label>
        <div className="">
          <p className="publish-form-note">
            In order to add a custom domain, you need to upgrade to a paid plan.
          </p>
          <div className="settings-form-inline-submit">
            <Button
              green
              type="button"
              onClick={handleShowModal}
              data-adalo-id="upgrade-publish-pwa-custom-domain"
            >
              Upgrade Now
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <DomainPicker appId={appId} />
    )
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="pwa-publish-settings-info">
        <h2>What's a Progressive Web App (PWA)?</h2>
        <p>
          A PWA is a web version of your app that can be saved on your phone's
          home screen.
        </p>
      </div>
      {renderCustomDomain()}
      {dirty && error && (
        <div className="pwa-publish-error wrapped-input-error-message">
          {error}
        </div>
      )}
      <div className="pwa-publish-settings-submit-wrapper">
        <Button
          type="submit"
          green
          loading={submitting}
          disabled={!dirty || submitting}
        >
          Save
        </Button>
      </div>
    </form>
  )
}

Form.propTypes = {
  appId: PropTypes.string.isRequired,
}

const connected = connect(undefined, { showModal, setPaymentRouteType })(Form)

export const FORM_NAME = 'pwa-publish-settings'
export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  asyncValidate,
})(connected)
