const END_EDIT = Symbol('END_EDIT')
const BEGIN_EDIT = Symbol('BEGIN_EDIT')

export default (state, action) => {
  if (action.type === BEGIN_EDIT) {
    return {
      ...state,
      textEditing: action.id,
    }
  }

  if (action.type === END_EDIT) {
    return {
      ...state,
      textEditing: null,
    }
  }

  return state
}

export const setEditingText = id => {
  if (id) {
    return {
      type: BEGIN_EDIT,
      id,
    }
  }

  return { type: END_EDIT }
}

export const getEditingText = state => state.editor.objects.present.textEditing
