import ObjectType from '../types/ObjectType'
import ContentLayoutResult from './ContentLayoutResult'
import LayoutContext from './layout/LayoutContext'
import Length from './Length'
import ObjectNode from './ObjectNode'
import { PushGraphEdge } from './styles'

class EdgeIndex {
  private readonly idxOutgoing = new Map<string, Set<string>>()
  private readonly idxIncoming = new Map<string, Set<string>>()
  private readonly idxDistance = new Map<string, Map<string, Length>>()

  private getOutgoingSet(node: string): Set<string> {
    let result = this.idxOutgoing.get(node)

    if (result === undefined) {
      result = new Set<string>()
      this.idxOutgoing.set(node, result)
    }

    return result
  }

  private getIncomingSet(node: string): Set<string> {
    let result = this.idxIncoming.get(node)

    if (result === undefined) {
      result = new Set<string>()
      this.idxIncoming.set(node, result)
    }

    return result
  }

  private getDistanceMap(start: string): Map<string, Length> {
    let result = this.idxDistance.get(start)
    if (result === undefined) {
      result = new Map<string, Length>()
      this.idxDistance.set(start, result)
    }

    return result
  }

  addEdge(start: string, end: string, distance: Length) {
    const outbound = this.getOutgoingSet(start)
    const inbound = this.getIncomingSet(end)
    const distances = this.getDistanceMap(start)

    outbound.add(end)
    inbound.add(start)
    distances.set(end, distance)
  }

  removeEdge(start: string, end: string) {
    const outbound = this.getOutgoingSet(start)
    const inbound = this.getIncomingSet(end)
    const distances = this.getDistanceMap(start)

    outbound.delete(end)
    inbound.delete(start)
    distances.delete(end)
  }

  getDistance(start: string, end: string): Length | undefined {
    const distances = this.getDistanceMap(start)

    return distances.get(end)
  }

  countIncomingEdges(end: string): number {
    const inbound = this.getIncomingSet(end)

    return inbound.size
  }

  getIncomingEdges(end: string): string[] {
    const inbound = this.getIncomingSet(end)

    return Array.from(inbound)
  }

  countOutgoingEdges(end: string): number {
    const outbound = this.getOutgoingSet(end)

    return outbound.size
  }

  getOutgoingEdges(start: string): string[] {
    const outbound = this.getOutgoingSet(start)

    return Array.from(outbound)
  }

  static build(edges: PushGraphEdge[]): EdgeIndex {
    const result = new EdgeIndex()

    for (const { startNodeId, endNodeId, distance } of edges) {
      result.addEdge(startNodeId, endNodeId, distance)
    }

    return result
  }
}

/**
 * Perform a topological sort on the given graph. Uses Khan's algorithm.
 *
 * https://en.wikipedia.org/wiki/Topological_sorting#Kahn's_algorithm
 */
const topologicalSort = (nodes: string[], edges: PushGraphEdge[]): string[] => {
  const edgeIndex = EdgeIndex.build(edges)

  // Initialize a set of root nodes which have no incoming edges
  const rootNodes = new Set<string>()
  for (const n of nodes) {
    if (edgeIndex.countIncomingEdges(n) === 0) {
      rootNodes.add(n)
    }
  }

  // Initialize an empty list of sorted nodes
  const sortedNodes: string[] = []

  while (rootNodes.size > 0) {
    // Remove a node n from the set of root nodes
    const [n] = rootNodes.values()
    if (n === undefined) {
      throw new Error(
        `Root node set was unexpectedly empty. (${String(rootNodes)})`
      )
    }

    rootNodes.delete(n)

    // Add n to the list of sorted nodes
    sortedNodes.push(n)

    // For each node m with an edge e from n to m
    const dependents = edgeIndex.getOutgoingEdges(n)
    for (const m of dependents) {
      // Remove e
      edgeIndex.removeEdge(n, m)

      if (edgeIndex.countIncomingEdges(m) === 0) {
        // Add m to the set of root nodes
        rootNodes.add(m)
      }
    }
  }

  return sortedNodes
}

const layoutPushGraphContent = (
  container: ObjectNode<ObjectType>,
  context: LayoutContext
): ContentLayoutResult => {
  const {
    device,
    containerWidth: contentWidth,
    containerHeight,
    containerOffsetX: contentOffsetX,
    containerOffsetY: contentOffsetY,
    viewportWidth,
    viewportHeight,
    instruction,
    viewingDevice,
    subtreeRootObject,
  } = context

  let nodeIds = container.getStyle('pushGraphNodes', device)
  const edges = container.getStyle('pushGraphEdges', device)

  nodeIds = topologicalSort(nodeIds, edges)

  // Create a map of child objects, keyed by pushNodeId
  const childMap = new Map<string, ObjectNode<ObjectType>[]>()

  // In flow children are children that are positioned statically and aren't hidden
  const inFlowChildren = container.getInFlowChildren(context)
  for (const child of inFlowChildren) {
    const nodeId = child.getStyle('pushNodeId', device)

    if (nodeId && nodeIds.includes(nodeId)) {
      let nodeObjects = childMap.get(nodeId)
      if (nodeObjects === undefined) {
        nodeObjects = []
        childMap.set(nodeId, nodeObjects)
      }

      nodeObjects.push(child)
    } else {
      // This could degrade gracefully. However, these styles are generated automatically so it is safer to fail fast
      // and expose any bugs in the style mapping.
      throw new Error(
        `Encountered a Graph Item without a valid node ID. (Object ID: ${child.id})`
      )
    }
  }

  // Create a map of bottom edges for each node
  const bottomMap = new Map<string, number>()

  const edgeIndex = EdgeIndex.build(edges)

  const layoutChildren = []
  let contentHeight = 0
  for (const nodeId of nodeIds) {
    // For each incoming edge, calculate the push bar and find the lowest
    let nodeTop = contentOffsetY
    const pushingNodeIds = edgeIndex.getIncomingEdges(nodeId)
    for (const pushingNodeId of pushingNodeIds) {
      const pushingNodeBottom = bottomMap.get(pushingNodeId)
      if (pushingNodeBottom === undefined) {
        throw new Error(
          `A pushing node bottom was not found. (Pushing Node ID: ${pushingNodeId})`
        )
      }

      const edgeDistance = edgeIndex.getDistance(pushingNodeId, nodeId)
      if (edgeDistance === undefined) {
        throw new Error(
          `Could not find distance for edge. (Start Node ID: ${pushingNodeId}; End Node ID: ${nodeId})`
        )
      }

      const pushBar = pushingNodeBottom + edgeDistance.toExact(contentWidth)

      nodeTop = Math.max(nodeTop, pushBar)
    }

    let nodeBottom = nodeTop
    // Layout each child that is associated with this node
    const nodeChildren = childMap.get(nodeId) || []
    for (const nodeChild of nodeChildren) {
      // If multiple children share a node, they should overlap each other.
      // In practice, there should never be more than one child per node.
      const childContext: LayoutContext = {
        device,
        containerHeight,
        containerOffsetX: contentOffsetX,
        containerOffsetY: nodeTop,
        containerWidth: contentWidth,
        viewportWidth,
        viewportHeight,
        instruction,
        viewingDevice,
        subtreeRootObject,
      }

      const childLayout = nodeChild.layout(childContext)
      layoutChildren.push(childLayout)

      const childBottom = childLayout.y + childLayout.height
      nodeBottom = Math.max(nodeBottom, childBottom)
    }

    bottomMap.set(nodeId, nodeBottom)
    contentHeight = Math.max(contentHeight, nodeBottom - contentOffsetY)
  }

  return { children: layoutChildren, contentHeight }
}

export default layoutPushGraphContent
