import { useEffect, useState } from 'react'
import { adaloBackendAxios } from 'utils/io/http/axios'

import Page from 'components/Shared/Page'
import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'

import './Admin.scss'

const Datasource = ({ match }) => {
  const [datasourceValidity, setDatasourceValidity] = useState(null)
  const [apps, setApps] = useState(null)
  const [host, setHost] = useState(null)
  const [metrics, setMetrics] = useState(null)
  const [tableNames, setTableNames] = useState(null)
  const [loadingError, setLoadingError] = useState(null)

  useEffect(() => {
    Promise.all([
      adaloBackendAxios.get(
        `/datasources/${match.params.datasourceId}/validity`
      ),
      adaloBackendAxios.get(
        `/datasources/${match.params.datasourceId}/metrics`
      ),
      adaloBackendAxios.get(`/datasources/${match.params.datasourceId}/apps`),
      adaloBackendAxios.get(`/datasources/${match.params.datasourceId}/host`),
      adaloBackendAxios.get(`/datasources/${match.params.datasourceId}`),
    ])
      .then(([responseValidity, metrics, apps, host, datasource]) => {
        setDatasourceValidity(responseValidity.data)
        setMetrics(metrics.data)
        setApps(apps.data)
        setHost(host.data.host)
        setTableNames(datasource.data)
      })
      .catch(err => setLoadingError(err.message))
  }, [match.params.datasourceId])

  const prerequisitesReady =
    datasourceValidity && metrics && tableNames && apps && host

  if (!prerequisitesReady && loadingError) {
    return (
      <Page
        className="admin-users-page"
        title={`Datasource: ${match.params.datasourceId}`}
      >
        <h1>Datasource: {match.params.datasourceId}</h1>
        <hr />
        <p style={{ color: 'red' }}>
          Error retrieving Datasource ID={match.params.datasourceId}:{' '}
          {loadingError}
        </p>
      </Page>
    )
  }

  if (!prerequisitesReady) {
    return (
      <EmptyState greedy>
        <Loading large />
      </EmptyState>
    )
  }

  return (
    <Page
      className="admin-debug-page"
      title={`Datasource: ${match.params.datasourceId}`}
    >
      <h1>Datasource</h1>
      <div>
        <h2>Basic info</h2>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Datasource ID</th>
              <th>Host</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{match.params.datasourceId}</td>
              <td>{host}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>Apps using datasource</h2>
        <table className="styled-table">
          <thead>
            <tr>
              <th>App ID</th>
              <th>Name</th>
              <th>Datasource Created</th>
              <th>Datasource Updated</th>
              <th>Datasource Deleted</th>
            </tr>
          </thead>
          <tbody>
            {apps.map(({ id, name, createdAt, updatedAt, deletedAt }) => (
              <tr key={id}>
                <td>
                  <a href={`/apps/${id}/data`} target="blank">
                    {id}
                  </a>
                </td>
                <td>{name}</td>
                <td>{createdAt}</td>
                <td>{updatedAt}</td>
                <td>{deletedAt ?? 'n/a'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <h2>Table information</h2>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Table ID</th>
              <th>Name</th>
              <th>Records</th>
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            {datasourceValidity.map(({ tableId, errors }) => (
              <tr key={tableId}>
                <td>{tableId}</td>
                <td>{tableNames[tableId]}</td>
                <td>{metrics[tableId]}</td>
                <td>
                  {errors.length !== 0 && (
                    <ul>
                      {errors.map(error => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  )}
                  {errors.length === 0 && 'No errors'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Page>
  )
}

export default Datasource
