import { getBindingTestValue } from 'utils/bindings'
import { validateCollectionName } from '../Panel/Items/validations'

export const validate = data => {
  const errors = {}

  errors.name = validateCollectionName(data.name)

  const baseURL = getBindingTestValue(data.baseURL)

  if (!baseURL || !baseURL.match(/^https?:\/\//)) {
    errors.baseURL = 'Please enter a valid URL'
  }

  return errors
}
