import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { COMPONENT, transitions } from '@adalo/constants'
import { useHistory } from 'react-router-dom'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Modal from 'components/Shared/Modal'
import Loading from 'components/Shared/Loading'
import TemplateItem from 'components/Shared/TemplateItem'

import {
  ensureScreenTemplatesAreLoaded,
  getScreenTemplatesState,
} from 'ducks/editor/screenTemplates'
import { selectObjects } from 'ducks/editor/objects'

import { EditorObject } from 'utils/responsiveTypes'
import { createName } from 'utils/naming'
import { assetURL } from 'utils/assets'

import './styles.scss'

type Transition = keyof typeof transitions | null

type Template = {
  id: string
  name: string
  thumbnail: string
  transition: Transition
}

type Input = {
  value: Partial<Template>
  onChange: ({ id, transition }: Partial<Template>) => void
}

type TemplatesListProps = {
  templates: Template[]
  input: Input
}

const TemplatesList: React.FC<TemplatesListProps> = ({ templates, input }) => {
  const handleChange = (id: string, transition: Transition) => {
    input.onChange({ id, transition })
  }

  return (
    <div id="screenTemplatesList" className="new-screen-form__templates-list">
      <p className="new-screen-form__templates-list__title">Screen Layout</p>
      <div className="new-screen-form__templates-list__content">
        {templates.map(({ id, name, thumbnail, transition }) => {
          const item = {
            id,
            name,
            image: thumbnail && assetURL(thumbnail),
          }

          return (
            <TemplateItem
              key={id}
              item={item}
              showCheckmark
              showFooter={false}
              onClick={() => handleChange(id, transition)}
              isSelected={input?.value?.id === id}
            />
          )
        })}
      </div>
    </div>
  )
}

type ScreenFormValues = {
  template: string
}

type ScreenFormProps = {
  primaryPlatform: string
  chooseTemplate: boolean
  onCancel: () => void
  defaultName: string
} & InjectedFormProps<ScreenFormValues, ScreenFormProps>

const ScreenForm: React.FC<ScreenFormProps> = ({
  primaryPlatform,
  handleSubmit,
  onCancel,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { list, loaded } = useSelector(state =>
    getScreenTemplatesState(state, primaryPlatform)
  )

  const objects = useSelector(selectObjects) as EditorObject[]

  const defaultName = useMemo(() => {
    return createName(COMPONENT, null, objects)
  }, [objects])

  useEffect(() => {
    dispatch(ensureScreenTemplatesAreLoaded(primaryPlatform))
  }, [primaryPlatform])

  const handleCancel = () => {
    onCancel()
    history.goBack()
  }

  return (
    <form onSubmit={handleSubmit} id="new-screen-form">
      <Modal.Header title="New Screen" />
      <Modal.Content>
        <Field
          autoFocus
          name="name"
          label="Name"
          placeholder={defaultName}
          component={WrappedInput}
          large
        />
        {!list.length && !loaded ? (
          <Loading expanded />
        ) : (
          <Field
            name="template"
            templates={list}
            component={TemplatesList}
            primaryPlatform={primaryPlatform}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button type="button" text onClick={handleCancel}>
          Cancel
        </Modal.Button>
        <Modal.Button form="new-screen-form" disabled={!loaded} type="submit">
          Create Screen
        </Modal.Button>
      </Modal.Actions>
    </form>
  )
}

export default reduxForm<ScreenFormValues, ScreenFormProps>({
  form: 'newScreen',
})(ScreenForm)
