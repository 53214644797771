import React from 'react'
import CanvasObject from './CanvasObject'

function CanvasObjectWrapper({
  object,
  component,
  magicLayout,
  deviceType,
  parentLayout,
  ...otherProps
}) {
  if (!magicLayout) {
    return <CanvasObject {...otherProps} object={object} />
  }

  const screenResizing = component?.screenResizing

  let deviceObject = object
  if (deviceType in object) {
    deviceObject = {
      ...object,
      ...object[deviceType],
    }
  }

  const canvasObject = (
    <CanvasObject
      {...otherProps}
      object={deviceObject}
      deviceType={deviceType}
      magicLayout={magicLayout}
      screenResizing={screenResizing}
      screenHeight={component?.height}
      screenWidth={component?.width}
      component={component}
    />
  )

  return canvasObject
}

export default CanvasObjectWrapper
