import { FC, useContext } from 'react'
import styled from 'styled-components'
import TableThemeContext from './TableThemeContext'
import TableRenderHelperContext from './TableRenderHelperContext'

type FrozenColumnIndicatorProps = {
  tableContentsOffsetX: number
  width: number | null
}

const Indicator = styled('div')<{
  $tableContentsOffsetX: number
  $width: number
  $color: string
  $animationTiming: number
}>`
  width: ${props => props.$width}px;
  height: 100%;
  position: absolute;
  top: 0;
  left: ${props => props.$tableContentsOffsetX}px;
  transition: left ${props => props.$animationTiming}s ease;
  border-right: 1px solid ${props => props.$color};
  box-shadow: 4px 0px 4px 0px #0000000d;
  z-index: 5;
`

const FrozenColumnIndicator: FC<FrozenColumnIndicatorProps> = ({
  width,
  tableContentsOffsetX,
}) => {
  if (width === null) {
    return null
  }

  const { animationTiming } = useContext(TableRenderHelperContext)
  const { borderColor } = useContext(TableThemeContext)

  return (
    <Indicator
      $animationTiming={animationTiming}
      $tableContentsOffsetX={tableContentsOffsetX}
      $width={width}
      $color={borderColor}
    />
  )
}

export default FrozenColumnIndicator
