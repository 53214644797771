import {
  dataTypes,
  ColumnAlignments,
  ColumnWidths,
  ImageRatios,
} from '@adalo/constants'
import { TableField } from 'components/Editor/XanoExternalDatabaseModal/lib/user'

export const ID_FIELD = 'id'

export const INACCESSIBLE_FIELDS = new Set([
  'password',
  'temporary_password',
  'temporary_password_expires_at',
])

export const DEFAULT_FIELDS = new Map<string, TableField>([
  ['id', { name: 'ID', type: dataTypes.NUMBER }],
  ['created_at', { name: 'Created At', type: dataTypes.DATE }],
  ['updated_at', { name: 'Updated At', type: dataTypes.DATE }],
])

export const TEXT_LENGTH_OPTIONS = [
  { value: 'singleLine', label: 'Single Line' },
  { value: 'multiLine', label: 'Two Lines' },
  { value: 'noMax', label: 'No Max' },
] as const

export const COLUMN_WIDTH_OPTIONS = [
  { value: ColumnWidths.narrow, label: 'Narrow' },
  { value: ColumnWidths.standard, label: 'Standard' },
  { value: ColumnWidths.wide, label: 'Wide' },
] as const

export const COLUMN_ALIGNMENT_OPTIONS = [
  { value: ColumnAlignments.left, label: 'Left' },
  { value: ColumnAlignments.center, label: 'Center' },
  { value: ColumnAlignments.right, label: 'Right' },
] as const

export const IMAGE_RATIO_OPTIONS = [
  { value: ImageRatios.landscape, label: 'Landscape' },
  { value: ImageRatios.portrait, label: 'Portrait' },
  { value: ImageRatios.square, label: 'Square' },
  { value: ImageRatios.circle, label: 'Circle' },
] as const

export const LOCATION_FORMAT_OPTIONS = [
  { value: 'fullAddress', label: 'Full Address' },
  { value: 'addressElements.address1', label: 'Street Address' },
  { value: 'addressElements.name', label: 'Name' },
  { value: 'addressElements.city', label: 'City' },
  { value: 'addressElements.region', label: 'State/Region' },
  { value: 'addressElements.country', label: 'Country' },
  { value: 'addressElements.postalCode', label: 'Postal Code' },
  { value: 'coordinates.latitude', label: 'Latitude' },
  { value: 'coordinates.longitude', label: 'Longitude' },
] as const
