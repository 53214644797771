import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import deepEqual from 'deep-equal'
import { dataTypes } from '@adalo/constants'
import { traverse } from '@adalo/utils'

import { getLabel } from '../../../../utils/sources'
import { getRelations } from '../../../../utils/relations'
import { getDatasource } from '../../../../ducks/apps/datasources'
import { getSources, evaluateOptions } from '../../../../ducks/recommender'
import WrappedSelect from '../../../Shared/Forms/WrappedSelect'

class CreateAssociationOptions extends Component {
  getRelationOptions = () => {
    const { relations, options, objectOptions } = this.props
    const { object1 } = options

    if (!object1) {
      return []
    }

    let option = null

    traverse(
      evaluateOptions(objectOptions, 2),
      opt => {
        if (
          deepEqual(
            opt && opt.value && JSON.parse(JSON.stringify(opt.value)),
            JSON.parse(JSON.stringify(object1))
          )
        ) {
          option = opt
        }
      },
      opt => Array.isArray(opt.children)
    )

    if (!option) {
      return []
    }

    const baseName = option.label

    return relations.map(relation => ({
      label: `${baseName} > ${relation.name}`,
      value: relation.fieldId,
    }))
  }

  render() {
    const { field, objectOptions, getLabel } = this.props
    const relationOptions = this.getRelationOptions()

    return (
      <div className="action-item-form-options">
        <div className="action-item-form-row">
          <label>Object 1</label>
          <Field
            className="white small"
            component={WrappedSelect}
            name={`${field}.options.object1`}
            placeholder="Select object..."
            options={objectOptions}
            getLabel={getLabel}
            comparator={deepEqual}
          />
        </div>
        <div className="action-item-form-row">
          <label>Object 2</label>
          <Field
            className="white small"
            component={WrappedSelect}
            name={`${field}.options.object2`}
            placeholder="Select object..."
            options={objectOptions}
            getLabel={getLabel}
            comparator={deepEqual}
          />
        </div>
        <div className="action-item-form-row">
          <label>Collection</label>
          <Field
            autoSelect
            className="white small"
            component={WrappedSelect}
            name={`${field}.options.fieldId`}
            placeholder="Nothing available"
            options={relationOptions}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId, componentId } = props
  let datasource
  const { object1, object2 } = props.options || {}

  if (object1) {
    datasource = getDatasource(state, props.appId, object1.datasourceId)
  }

  return {
    objectOptions: getSources(
      state,
      props.appId,
      props.componentId,
      props.objectId,
      [dataTypes.OBJECT],
      undefined,
      props.actionId,
      props.reference
    ),
    relations: getRelations(datasource, object1, object2),
    getLabel: source => getLabel(state, source, appId, componentId),
  }
}

export default connect(mapStateToProps)(CreateAssociationOptions)
