import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface AlignToSelectionVerticalCenterInstruction {
  operation: 'alignToSelectionVerticalCenter'
  options: ActionOptions
}

export const alignToSelectionVerticalCenterHandler = ({
  bounds,
  objects,
}: HandlerOptions): LayoutInstruction[] => {
  const boundsVerticalCenter = bounds.top + (bounds.bottom - bounds.top) / 2

  return objects.flatMap(object => [
    // TODO (michael-adalo): this first instruction is a hack ensure the object has the correct positioning related properties
    // We've had to perform similar no-op (in appearance) instructions in other areas of the codebase
    // The fix will be in the `moveElementVertically` function of `src/ducks/editor/instructions/moveElement.ts`
    moveElement(object.id, object.x, object.y),
    moveElement(object.id, object.x, boundsVerticalCenter - object.height / 2),
  ])
}

const alignToSelectionVerticalCenter = (
  objectIds: string[]
): AlignToSelectionVerticalCenterInstruction => ({
  operation: 'alignToSelectionVerticalCenter',
  options: { objectIds },
})

export default alignToSelectionVerticalCenter
