export const getInitials = (name = ''): string =>
  name
    .split(/\s/)
    // eslint-disable-next-line no-return-assign, no-param-reassign
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .toUpperCase()
    .slice(0, 2)

// via: https://stackoverflow.com/a/16348977
export const stringToColor = (str: string): string => {
  let hash = 0
  str.split('').forEach(char => {
    // eslint-disable-next-line no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let color = '#'
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff
    color += value.toString(16).padStart(2, '0')
  }

  return color
}
