import React from 'react'
import classNames from 'classnames'

// components
import Button from 'components/Shared/Button'

// custom styles
import './callout.scss'

type ColorTypes =
  | 'teal'
  | 'yellow'
  | 'orange'
  | 'purple'
  | 'green'
  | 'darkPink'
  | 'grey'
  | 'inherit'

type BorderTypes = 'solid' | 'dashed' | 'none'
type AlignmentTypes = 'left' | 'center' | 'right'

type Props = {
  borderStyle?: BorderTypes,
  children: React.ReactNode,
  classNames?: string,
  color?: ColorTypes,
  headerImage?: string,
  alignment?: AlignmentTypes,
}

const CalloutCard = ({
  borderStyle,
  children,
  className,
  color,
  headerImage,
  title,
  body,
  button,
  alignment,
  ...props
}: Props) => {
  const calloutClasses = {
    [`callout-card-color-${color}`]: color && color !== 'inherit',
    [`callout-card-border-${borderStyle}`]: borderStyle,
    [`callout-card-alignment-${alignment}`]: alignment,
  }

  return (
    <div
      className={classNames('callout-card', calloutClasses, className)}
      {...props}
    >
      {headerImage && (
        <img className="callout-card-header-image" src={headerImage} alt="" />
      )}
      <div className="callout-card-content">
        {children || (
          <>
            {title && <h3>{title}</h3>}
            {body && <p>{body}</p>}
            {button && <Button {...button} />}
          </>
        )}
      </div>
    </div>
  )
}

CalloutCard.defaultProps = {
  borderStyle: 'solid',
  color: 'teal',
  alignment: 'center',
}

export default CalloutCard
