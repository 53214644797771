import { useEffect } from 'react'
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom'
import { LAYOUT_SECTION } from '@adalo/constants'

import { StoreState, getPrebuiltLayoutSections } from 'ducks/layoutSections'
import { PrebuiltBuilderContentPlatformTarget } from 'ducks/layoutSections/types'
import { DefaultRootState, connect, useDispatch } from 'react-redux'
import { getApp } from 'ducks/apps'
import { UserState, getCurrentUser } from 'ducks/users'
import { setTool } from 'ducks/editor/tools'

import {
  FormattedSection,
  SectionCategory,
  categorizeSections,
  getFormattedSectionsWithoutCategory,
} from 'utils/layoutSections'

import Tab from '../Tab'
import {
  MOST_USED_SECTIONS_CATEGORY_LABEL,
  renderSectionOption,
  sortCategoriesByPresetOrder,
} from './sectionHelpers'

interface Props extends RouteComponentProps {
  appId: string
  options: SectionCategory[]
  isAdmin: boolean
}

const SectionsTab: React.FC<Props> = props => {
  const { appId, options, isAdmin } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.endsWith('/add')) {
      history.push(`/apps/${appId}/screens/add/section`)
    }
  }, [history, location, appId])

  const handleSelect = (
    type: Record<string, unknown>,
    startPosition: [number, number]
  ): void => {
    if (!type) {
      return
    }

    const toolOptions = type['options']
    const toolType = LAYOUT_SECTION

    dispatch(setTool(toolType, toolOptions, startPosition))
  }

  const handleSelectChip = (tag: string): void => {
    history.push(`/apps/${appId}/screens/add/search?q=${tag}`)
  }

  return (
    <Tab
      key="section"
      emptyMessage="No sections found"
      options={options}
      platform="responsive"
      renderItem={(section: FormattedSection) =>
        renderSectionOption({
          handleSelect,
          handleSelectChip,
          isAdmin,
          ...section,
        })
      }
      defaultOpen={[MOST_USED_SECTIONS_CATEGORY_LABEL]}
      className={undefined}
    />
  )
}

const mapStateToProps = (state: DefaultRootState, { appId }: Props) => {
  const user = getCurrentUser(state as UserState)
  const isAdmin = user?.admin ?? false
  let opts
  let mobileOnly = false

  if (appId) {
    const app = getApp(state, appId)
    const { layoutMode = 'responsive' } = app?.webSettings || {}

    mobileOnly = layoutMode === 'mobile'
    opts = {
      platformTarget: layoutMode as PrebuiltBuilderContentPlatformTarget,
      includeUnlisted: isAdmin,
    }
  }

  const prebuiltLayoutSections = getPrebuiltLayoutSections(
    state as StoreState,
    opts
  )

  const categoriesMap = categorizeSections(prebuiltLayoutSections, mobileOnly)

  let noCategorySections: SectionCategory[] = []
  if (isAdmin) {
    // Just for admins, we can show the sections without category to test
    const uncategorized = getFormattedSectionsWithoutCategory(
      prebuiltLayoutSections,
      mobileOnly
    )
    noCategorySections = [{ label: 'No Category', children: uncategorized }]
  }

  const options = sortCategoriesByPresetOrder([
    ...Array.from(categoriesMap.values()),
    ...noCategorySections,
  ])

  return { options, isAdmin }
}

export default withRouter(connect(mapStateToProps)(SectionsTab))
