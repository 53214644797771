import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { getAppLibraryVersion, loadComponent } from 'ducks/apps'
import { getCurrentAppId } from 'ducks/editor/objects'
import { requestLibrary } from 'ducks/editor/libraries'
import { getCurrentUser } from 'ducks/users/index.ts'

import { librarySocket } from 'utils/developers'
import { getAuthToken } from 'utils/auth'
import { scaleValue } from 'utils/zoom'

import Loading from 'components/Shared/Loading'

import BaseObject from '../BaseObject'
import LibraryComponentSub from './LibraryComponentSub'

// Some libraries may require to be explicitly re-rendered when moved to a new position.
// Otherwise only the bounding box moves, but the inner LibraryComponentWrapper stays put until the next update
const LIBRARY_RE_RENDER_ON_MOVE = new Set(['@adalo/navigation'])

const libraryRequiresReRenderOnMove = libraryName =>
  LIBRARY_RE_RENDER_ON_MOVE.has(libraryName)

class LibraryComponent extends BaseObject {
  state = {
    isLoading: false,
  }

  handleLibraryUpdate = async name => {
    const { libraryName, libraryVersion, requestLibrary } = this.props

    if (libraryName !== name) return null
    this.setState({ isLoading: true })

    await requestLibrary(libraryName, libraryVersion)

    this.setState({ isLoading: false })
  }

  librarySocket = () => {
    const sessionToken = getAuthToken()
    const socket = sessionToken && librarySocket(sessionToken)

    return socket
  }

  componentDidMount() {
    const { currentUser } = this.props

    if (currentUser?.developer) {
      const socket = this.librarySocket()
      if (socket) socket.on('libraryUpdates', this.handleLibraryUpdate)
    }
  }

  componentWillUnmount() {
    const { currentUser } = this.props

    if (currentUser?.developer) {
      const socket = this.librarySocket()
      if (socket) socket.off('libraryUpdates', this.handleLibraryUpdate)
    }
  }

  renderLoading = () => {
    const { xScaled, yScaled, zoom, object } = this.props
    const { height, width } = object

    const boundingWidth = scaleValue(width + 40, zoom)
    const boundingHeight = scaleValue(height + 40, zoom)
    const offsetX = xScaled - scaleValue(20, zoom)
    const offsetY = yScaled - scaleValue(20, zoom)

    return (
      <foreignObject
        x={offsetX}
        y={offsetY}
        width={boundingWidth}
        height={boundingHeight}
        pointerEvents="none"
      >
        <Loading small expanded />
      </foreignObject>
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  render() {
    const {
      xScaled,
      yScaled,
      zoom,
      libraryVersion,
      object,
      deviceType,
      screenHeight,
      screenWidth,
      reRenderOnMove,
    } = this.props
    const { isLoading } = this.state

    const childProps = object

    return (
      <g
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
      >
        {isLoading ? (
          this.renderLoading()
        ) : (
          <LibraryComponentSub
            object={childProps}
            libraryVersion={libraryVersion}
            zoom={zoom}
            x={xScaled}
            y={yScaled}
            isLoading={isLoading}
            deviceType={deviceType}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
            reRenderOnMove={reRenderOnMove}
          />
        )}
      </g>
    )
  }
}

const mapStateToProps = (state, { id, libraryName }) => {
  const appId = getCurrentAppId(state)
  const currentUser = getCurrentUser(state)
  const libraryVersion = getAppLibraryVersion(state, appId, libraryName)
  const reRenderOnMove = libraryRequiresReRenderOnMove(libraryName)

  return { currentUser, libraryVersion, reRenderOnMove }
}

export default connect(mapStateToProps, { loadComponent, requestLibrary })(
  LibraryComponent
)
