/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  backgroundStyle,
  backgroundStyles,
  borderStyles,
} from '@adalo/constants'
import { isContainerSectionElement } from 'utils/layoutSections'
import { EditorObject } from 'utils/responsiveTypes'
import Section from './Section'
import Image from './Image'

export default class LayoutSection extends Section {
  override render(): JSX.Element {
    const { object, borderStyle, shouldHaveBorder } = this.props
    let { borderWidth, borderColor } = this.props
    const transparent = object?.backgroundStyle === backgroundStyle.NONE
    let newBorderStyle = transparent ? borderStyles.NONE : borderStyle

    if (object?.backgroundStyle === backgroundStyle.IMAGE) {
      return <Image {...this.props} backgroundStyle={backgroundStyles.COLOR} />
    }

    const isContainer = isContainerSectionElement(object as EditorObject)
    if (shouldHaveBorder && shouldHaveBorder()) {
      borderWidth = 1
      borderColor = '#bdbdbd'
      newBorderStyle = isContainer ? borderStyles.DASHED : borderStyles.SOLID
    }

    return (
      <Section
        {...this.props}
        hideShadows={transparent}
        borderStyle={newBorderStyle}
        borderWidth={borderWidth}
        borderColor={borderColor}
      />
    )
  }
}
