// eslint-disable-next-line no-restricted-imports
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getAuthToken } from 'utils/auth'

const backendURL = process.env['REACT_APP_BACKEND_URL']
if (!backendURL) throw new Error('REACT_APP_BACKEND_URL not defined')
const marketplaceURL = process.env['REACT_APP_COMPONENT_REGISTRY']
if (!marketplaceURL) console.warn('REACT_APP_COMPONENT_REGISTRY not defined')
const publicApiURL = process.env['REACT_APP_ADALO_API']
if (!publicApiURL) console.warn('REACT_APP_ADALO_API not defined')

export type { AxiosResponse }
// eslint-disable-next-line @typescript-eslint/unbound-method
export const isAxiosError = axios.isAxiosError

const addAuthHeader = (config: AxiosRequestConfig) => {
  const token = getAuthToken()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-param-reassign
  if (token) config.headers['x-proton-auth'] = token

  return config
}
const createAuthedAxiosInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({ baseURL })
  instance.interceptors.request.use(addAuthHeader)

  return instance
}

// for Adalo backend API calls
export const adaloBackendAxios = createAuthedAxiosInstance(backendURL)
// for the marketplace API calls aka component registry
export const marketplaceAxios = createAuthedAxiosInstance(marketplaceURL || '')
// for the public API calls aka collections API & notifications push API
export const publicApiAxios = createAuthedAxiosInstance(publicApiURL || '')

// for any other API calls
export const defaultAxios = axios.create()
