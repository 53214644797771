import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  fetchOrganizations,
  fetchOrganization,
  getCurrentOrganizationId,
} from 'ducks/organizations'

import { requestApp } from 'utils/io'

import { getPlanChanged } from 'ducks/billing'

import { withRouter } from 'react-router-dom'
import Modal from '../Modal'
import Button from '../Button'

import {
  setPlanSelectedFlag,
  setSelectedPlanValue,
  setUpdateCardFlag,
} from '../../../ducks/trialWarning'
import paymentSuccessLogo from '../Icon/icons/Teal_Check_Mark.gif'

const FetchData = ({ appId }) => {
  const dispatch = useDispatch()
  const currentOrganizationId = useSelector(getCurrentOrganizationId)
  const planChanged = useSelector(getPlanChanged)

  const fetchData = async () => {
    await requestApp(appId)
    dispatch(fetchOrganizations())
    dispatch(fetchOrganization(currentOrganizationId))
    dispatch(setPlanSelectedFlag(false))
    dispatch(setSelectedPlanValue(''))
    dispatch(setUpdateCardFlag(false))
  }

  useEffect(() => {
    fetchData()
  }, [planChanged, appId, dispatch, currentOrganizationId])

  return null
}

function PaymentSuccess({ history, match }) {
  const { appId } = match.params

  const handleClose = () => {
    if (appId) {
      history.push(`/apps/${appId}/screens`)
    } else {
      history.push(`/`)
    }
  }

  return (
    <Modal size="sm">
      <Modal.Content style={{ textAlign: 'center' }}>
        <Modal.Container>
          <img className="paymentSuccessLogo" src={paymentSuccessLogo} alt="" />
          <h1 className="trial-signup-success-message">Success!</h1>
          <p>You can edit your plan in your Account Settings at any time.</p>
        </Modal.Container>
      </Modal.Content>
      <Modal.Actions>
        <Button large onClick={handleClose}>
          Done
        </Button>
      </Modal.Actions>
      <FetchData appId={appId} />
    </Modal>
  )
}

export default withRouter(PaymentSuccess)
