import React from 'react'

import Item from './Item'

import './Selector.scss'

class Selector extends React.Component {
  static Item = Item

  render() {
    const { children, input } = this.props

    return (
      <div className="form-selector">
        {React.Children.map(children, child =>
          React.cloneElement(child, { input })
        )}
      </div>
    )
  }
}

export default Selector
