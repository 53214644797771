import { COMPONENT } from '@adalo/constants'
import { getId } from '@adalo/utils'
import {
  applyInstructions,
  moveElement,
  resizeElement,
} from 'ducks/editor/instructions'
import getObject from 'ducks/editor/objects/helpers/getObject'
import { buildState } from 'ducks/editor/__tests__/support/state.utils'
import { EditorObject } from 'utils/responsiveTypes'

type ScreenDimensions = {
  width: number
  height: number
}

type NewObjectLayout = {
  width?: number
  height?: number
  x?: number
  y?: number
}

const getObjectResizedInScreen = (
  obj: EditorObject,
  screenDimensions: ScreenDimensions,
  newLayout: NewObjectLayout
): EditorObject => {
  if (!newLayout.width && !newLayout.height && !newLayout.x && !newLayout.y) {
    return obj
  }

  const objId = obj.id
  const screenObj: Partial<EditorObject> = {
    id: getId() as string,
    x: 0,
    y: 0,
    ...screenDimensions,
    type: COMPONENT,
    children: [obj],
    positioning: null,
  }

  const state = buildState(screenObj as EditorObject)

  const instructions = [
    resizeElement(
      objId,
      newLayout.width ?? obj.width,
      newLayout.height ?? obj.height
    ),
    moveElement(objId, newLayout.x ?? obj.x, newLayout.y ?? obj.y),
  ]

  const { list: result, pathMap } = applyInstructions(state, instructions)

  const resizedObj = getObject(result, pathMap, objId)

  return resizedObj
}

export default getObjectResizedInScreen
