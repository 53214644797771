import React from 'react'

import Modal from 'components/Shared/Modal'

import './UnpublishModal.scss'

const UnpublishModal = ({ onSuccess, onClose }) => {
  return (
    <div className="unpublish-modal">
      <Modal.Header>
        <span role="img" aria-label="">
          👀 🙈
        </span>
        <h1>Heads up about unpublishing</h1>
      </Modal.Header>
      <Modal.Content>
        <p>
          Unpublishing your app will cause it to stop working for your end
          users. How do you want to proceed?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button teal outlined padded onClick={() => onSuccess(true)}>
          Unpublish
        </Modal.Button>
        <Modal.Button teal padded onClick={() => onSuccess(false)}>
          Cancel
        </Modal.Button>
      </Modal.Actions>
    </div>
  )
}

export default UnpublishModal
