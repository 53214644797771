import classNames from 'classnames'
import './styles.scss'

type Props = {
  children: JSX.Element | JSX.Element[] | null
  className?: string
}

export const DBAssistant = ({ children, className }: Props): JSX.Element => {
  return (
    <div
      className={classNames('db-assistant-panel', {
        [className as string]: className,
      })}
    >
      {children}
    </div>
  )
}
