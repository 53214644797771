/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getAppId } from 'utils/location'
import { socket } from 'utils/io/socket'

type LocAppId = string | null | undefined

const LEAVE_APP_ROOM_EVENT = 'leaveAppRoom'

// Bulletproof way of leaving the app room when navigating out of an app
const AppRoomLeaver = (): null => {
  const location = useLocation() as unknown as Location
  const locAppId: LocAppId = getAppId(location)
  const [appId, setAppId] = useState<LocAppId>(locAppId)

  useEffect(() => {
    if (!appId && locAppId) {
      setAppId(locAppId)
    } else if (appId && appId !== locAppId) {
      setAppId(locAppId)
      // When navigating out of the current app, we need to manually leave the room
      socket.emit(LEAVE_APP_ROOM_EVENT, { appId })
    }
  }, [appId, locAppId])

  return null
}

export default AppRoomLeaver
