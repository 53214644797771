import React from 'react'
import Markdown from 'react-markdown'

import CalloutCard from 'components/Shared/CalloutCard'
import Link from 'components/Shared/Markdown/Link'

import FolderImage from './empty-state-folder.png'

const EmptyState = ({ title, subtext, children, color = 'lightGrey' }) => {
  return (
    <CalloutCard borderStyle="none" color={color}>
      <img src={FolderImage} alt="open folder" />
      {children || (
        <>
          <h3>{title}</h3>
          {subtext ? (
            <Markdown components={{ a: Link }}>{subtext}</Markdown>
          ) : null}
        </>
      )}
    </CalloutCard>
  )
}

export default EmptyState
