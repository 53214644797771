import { useState, useCallback, useEffect } from 'react'

const RAPID_CLICK_TIME = 2000

type DisableRapidClickReturn = {
  disabled: boolean
  onClick: () => void
}

export const useDisableRapidClick = (): DisableRapidClickReturn => {
  const [clickEnabled, setClickEnabled] = useState<boolean>(true)

  const onClick = useCallback(() => {
    setClickEnabled(false)
    setTimeout(() => setClickEnabled(true), RAPID_CLICK_TIME)
  }, [])

  useEffect(() => {
    return () => setClickEnabled(true)
  }, [])

  return { disabled: !clickEnabled, onClick }
}
