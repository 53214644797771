/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import CalloutCard from './CalloutCard'
import Icon from './Icon'
import Modal from './Modal'

import './OverLimitModal.scss'

const generateAdaloId = addOnName => {
  switch (addOnName) {
    case 'Editor Seat':
      return 'purchase-editor-seat'
    case 'Published App':
      return 'purchase-app-license'
    default:
      return ''
  }
}

const OverLimitModal = ({
  onSuccess,
  onClose,
  organization,
  addOnPrice,
  addOnName,
  purchaseDescription,
  show,
}) => {
  if (!show) return null

  const [isLoading, setIsLoading] = useState(false)
  const [purchaseError, setPurchaseError] = useState(null)
  const billing = useSelector(({ billing }) => billing[organization?.id])

  const handlePurchase = async () => {
    try {
      setIsLoading(true)
      await onSuccess()
      setIsLoading(false)
    } catch (err) {
      const errorMessage =
        err.errors?._error ||
        err.message ||
        'An unknown error occurred. Please contact support for help.'

      const consoleMessage = `${err.response?.data?.error} \n ${err}`
      console.error(consoleMessage)

      setPurchaseError(
        `There was a problem processing your payment: ${errorMessage}`
      )
      setIsLoading(false)
    }
  }

  return (
    <Modal
      borderless
      constrained
      constrainContent
      onClose={onClose}
      className="over-limit-purchase-addon-modal"
    >
      <div>
        {purchaseError && (
          <div className="addon-purchase-error">
            {purchaseError}
            <br />
            <br />
            Please{' '}
            <a
              className="error-support-link"
              href="https://info.adalo.com/submit-a-support-ticket"
            >
              submit a support ticket
            </a>
            .
          </div>
        )}
        <Modal.Header>
          <h1>Purchase Another {addOnName}</h1>
        </Modal.Header>
        <Modal.Content>
          <p>{purchaseDescription}</p>
          <CalloutCard color="grey">
            <Icon type="done-all" color="green" iconStyle="circle" big />
            <div className="addon-description">
              <strong>Add-On {addOnName}</strong>
              <span>
                Billed monthly to your card ending in {billing?.cardNumber}
              </span>
            </div>
            <div className="addon-pricing">
              <strong>${addOnPrice}/mo</strong>
            </div>
          </CalloutCard>
          <p>
            You'll be charged a prorated amount today and charged the full
            monthly payment of ${addOnPrice} at the start of your next billing
            cycle.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button text grey disabled={isLoading} onClick={onClose}>
            Back
          </Modal.Button>
          <Modal.Button
            data-adalo-id={generateAdaloId(addOnName)}
            loading={isLoading}
            disabled={isLoading}
            onClick={handlePurchase}
          >
            Purchase
          </Modal.Button>
        </Modal.Actions>
      </div>
    </Modal>
  )
}

export default OverLimitModal
