import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { getApp } from '../ducks/apps'
import { getCurrentAppId } from '../ducks/editor/objects'

const FeatureFlagIdentitySync = () => {
  const currentAppId = useSelector(getCurrentAppId)
  const app = useSelector(state => getApp(state, currentAppId))
  const currentUser = useSelector(state => state.users?.currentUser)
  const currentOrganization = app?.Organization

  const client = useLDClient()

  useEffect(() => {
    if (!client) {
      return
    }

    let orgDetails = {}

    if (currentOrganization) {
      orgDetails = {
        organizationId: currentOrganization.id,
        organizationName: currentOrganization.name,
        planType: currentOrganization.planType,
        paying: currentOrganization.active,
        trialEndDate: currentOrganization.trialEndDate,
        totalUserCount: currentOrganization.totalUserCount,
        createdAt: currentOrganization.createdAt,
      }
    }

    if (currentUser) {
      const key =
        process.env.NODE_ENV === 'production'
          ? currentUser.id
          : `${currentUser.id}-${currentUser.email}`

      const context = {
        kind: 'user',
        key,
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.name,
        ip: currentUser.ip,
        custom: {
          admin: currentUser.admin,
          developer: currentUser.developer,
          expert: currentUser.expert,
          ...orgDetails,
        },
      }

      client
        .identify(context)
        .catch(e => console.error('Could not identify user with LD:', e))
    } else {
      client
        .identify({
          kind: 'user',
          anonymous: true,
          // for now, we do not need to segment anonymous users
          // Let's use a shared key to minimize MAUs and cost
          // We can still do percentage rollouts
          // see: https://docs.launchdarkly.com/home/users/anonymous-users/?q=ano#tracking-anonymous-users-with-a-shared-key
          key: 'frontend-anonymous-user',
        })
        .catch(e => console.error('Could not identify user with LD:', e))
    }
  }, [currentUser, currentOrganization, client])

  return null
}

export default FeatureFlagIdentitySync
