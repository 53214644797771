import React, { Component } from 'react'
import { getEndpointParams } from '../../../../utils/apis'

import EmptyState from '../../../Shared/EmptyState'
import FieldValue from './FieldValue'

export default class APIFields extends Component {
  renderField(id, type) {
    const { field, appId, componentId, objectId, actionId, reference } =
      this.props

    return (
      <FieldValue
        name={`${field}.${id}`}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        allowedDataTypes={[type]}
        actionId={actionId}
        reference={reference}
      />
    )
  }

  render() {
    const { datasource, collectionId, endpointId } = this.props

    if (!datasource || !datasource.collections[collectionId]) {
      return null
    }

    const collection = datasource.collections[collectionId]
    const endpoint = collection.endpoints.filter(e => e.id === endpointId)[0]

    if (!endpoint) {
      return null
    }

    const params = getEndpointParams(collection, endpoint)

    if (params.length === 0) {
      return <EmptyState>This endpoint has no fields.</EmptyState>
    }

    return (
      <div>
        {params.map(({ id, type }) => (
          <div className="action-item-form-row" key={id}>
            <label>{id}</label>
            {this.renderField(id, type)}
          </div>
        ))}
      </div>
    )
  }
}
