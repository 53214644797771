import React, { useState, useEffect } from 'react'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

// ducks
import { getAppBranding } from 'ducks/apps'

// utils
import { fontWeightOptions, getFontWeightName, normalizeFont } from 'utils/type'

const TextWeightPicker = ({
  name,
  onChange,
  value,
  currentFont,
  namespace,
}) => {
  const { appId } = useParams()

  const branding = useSelector(state => getAppBranding(state, appId))
  const normalizedFont = normalizeFont(currentFont, branding)

  const key = String(currentFont).startsWith('@')
    ? currentFont.substring(1)
    : null

  const handleSelect = newWeight => {
    const update = { [name]: newWeight }

    onChange(update)
  }

  const [options, setOptions] = useState([])

  const handleWeightOptions = () => {
    const { fonts = {} } = branding

    const variants = fonts?.[key]?.variants

    if (
      !fonts ||
      !key ||
      !variants ||
      normalizedFont.toLowerCase() === 'default'
    ) {
      const styleFontWeightOptions = fontWeightOptions.map(fw => {
        return {
          label: <span style={{ fontWeight: fw.value }}>{fw.label}</span>,
          value: fw.value,
        }
      })

      return setOptions(styleFontWeightOptions)
    }

    const googleFontWeightOptions = fonts[key].variants.map(v => {
      if (v.toLowerCase() === 'regular') v = 'normal'

      return {
        label: (
          <span style={{ fontFamily: normalizedFont, fontWeight: v }}>
            {getFontWeightName(v)}
          </span>
        ),
        value: v,
      }
    })

    return setOptions(googleFontWeightOptions)
  }

  useEffect(() => {
    handleWeightOptions()
  }, [branding, currentFont])

  const weightValue = getFontWeightName(value)

  return (
    <MultiMenuTrigger
      menu={options}
      onSelect={handleSelect}
      rowHeight={40}
      className="text-weight-picker-wrapper"
    >
      <span title={weightValue}>{weightValue}</span>
    </MultiMenuTrigger>
  )
}

TextWeightPicker.defaultProps = {
  value: '400',
}

export default TextWeightPicker
