import React from 'react'
import { reduxForm, Field } from 'redux-form'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import WrappedTextarea from 'components/Shared/Forms/WrappedTextarea'
import Modal from 'components/Shared/Modal'
import Icon from 'components/Shared/Icon'

import { formatUserName } from 'utils/text'

import { isTooLong } from './Validation'
import { UPDATE_TYPE } from './index'

export const FORM_NAME = 'designVersionForm'

const DesignVersionForm = ({
  handleSubmit,
  onClose,
  onCancel,
  loading,
  title,
  buttonLabel,
  type,
  version,
  restoreVersion,
}) => {
  const isUpdateModal = type === 'update'

  const onRestore = () => {
    try {
      return restoreVersion()
    } catch (err) {
      return onCancel(err)
    } finally {
      onClose()
    }
  }

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      className="design-version-form"
    >
      <Modal.Header color="teal" title={title} />
      <Modal.Content>
        <Field
          className="design-version-form-field"
          autoFocus
          name="versionName"
          label="Design Version Name *"
          placeholder="i.e. Awesome Design"
          component={WrappedInput}
          validate={[isTooLong]}
        />

        <Field
          className="design-version-form-field textarea"
          name="versionNotes"
          label="Notes"
          placeholder="i.e. This is a great feature"
          component={WrappedTextarea}
          validate={[isTooLong]}
          type="text"
          spellCheck="false"
        />

        {type === UPDATE_TYPE && (
          <Field
            name="createdBy"
            label="Created by"
            text={formatUserName(version.creatorName, isUpdateModal)}
            description={version.creatorEmail}
            component={WrappedText}
            large={false}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {type === UPDATE_TYPE ? (
          <Modal.Button type="button" text teal outlined onClick={onRestore}>
            <Icon type="restore" />
            Restore Version
          </Modal.Button>
        ) : (
          <Modal.Button type="button" text onClick={onClose}>
            Cancel
          </Modal.Button>
        )}
        <Modal.Button type="submit" teal loading={loading}>
          {buttonLabel}
        </Modal.Button>
      </Modal.Actions>
    </form>
  )
}

const WrappedText = ({ label, text, description }) => {
  return (
    <div className="design-version-creator">
      {label && <label>{label}</label>}
      <div className="version-creator-info">
        <div className="text-name">{text}</div>
        <div className="text-description">{description}</div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(DesignVersionForm)
