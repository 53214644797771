import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  fetchBuildErrorLog,
  getBuildErrorLog,
  getAppBuild,
  ensureAppBuildsAreLoaded,
} from 'ducks/apps/builds'
import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'
import Accordion from 'components/Shared/Accordion'
import Loading from 'components/Shared/Loading'

const cancelEvent = e => {
  e.stopPropagation()

  if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
    e.currentTarget.scrollBy(e.deltaX, 0)
  }
}

function BuildLogsModal({
  match,
  build,
  log,
  history,
  fetchBuildErrorLog,
  ensureAppBuildsAreLoaded,
}) {
  const { appId, buildId } = match.params

  useEffect(() => {
    ensureAppBuildsAreLoaded(appId, 'ios')
    fetchBuildErrorLog(appId, buildId)
  }, [])

  const handleClose = e => {
    e.preventDefault()

    return history.push(`/apps/${appId}/screens/`)
  }

  const renderChildrenLog = () => {
    return (
      <div>
        <p className="log-instructions">
          Use Cmd/Ctrl + F to find the phrase 'Error' to figure out why your
          build failed. (It's normally at the bottom of the logs.)
          <br />
          <br />
          If you need additional help, please contact us by submitting a&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://info.adalo.com/submit-a-support-ticket"
          >
            support ticket
          </a>
          .
        </p>
        {log?.loading ? (
          <Loading small expanded />
        ) : (
          <pre className="pre-transparent">{log?.text}</pre>
        )}
      </div>
    )
  }

  return (
    <Modal
      size="xl"
      scrolling
      className="publish-error-logs"
      onClose={handleClose}
    >
      <div>
        <Modal.Header>
          <div>
            <h1>Error Logs</h1>
          </div>
        </Modal.Header>
        <Modal.Content className="inline-item" onWheel={cancelEvent}>
          {build?.iosErrorSummary ? (
            <>
              <h2 className="error-summary">{build?.iosErrorSummary}</h2>
              <Accordion
                className="error-logs-accordion"
                hideCarret
                title={<Button text>Show Full Logs</Button>}
                expandedTitle={<Button text>Hide Full Logs</Button>}
                renderChildren={renderChildrenLog}
              />
            </>
          ) : (
            renderChildrenLog()
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button to={`/apps/${appId}/screens/`}>Done</Button>
        </Modal.Actions>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, { match }) => {
  const { appId, buildId } = match.params
  const build = getAppBuild(state, appId, null, buildId)

  return {
    build,
    log: getBuildErrorLog(state),
  }
}

export default withRouter(
  connect(mapStateToProps, { fetchBuildErrorLog, ensureAppBuildsAreLoaded })(
    BuildLogsModal
  )
)
