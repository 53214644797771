import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getCollectionEndpointLabel,
  getEndpoint,
  getCollection,
} from '../../../../utils/apis'

import { getDatasource } from '../../../../ducks/apps/datasources'
import EmptyState from '../../../Shared/EmptyState'
import APIFields from './APIFields'
import DatasourceSelector from './DatasourceSelector'

class APIAuthOptions extends Component {
  getEndpointLabel = opt => {
    const { datasource } = this.props

    if (!datasource || !opt) {
      return null
    }

    const { collectionId, endpointId } = opt

    return getCollectionEndpointLabel(datasource, collectionId, endpointId)
  }

  getEndpointInfo = () => {
    const { datasource, type } = this.props
    const auth = (datasource && datasource.auth) || {}
    const login = auth[type] || {}
    const endpointInfo = login.endpoint || {}

    return endpointInfo
  }

  getEndpoint = () => {
    const { datasource } = this.props

    if (!datasource) {
      return null
    }

    const { endpointId, collectionId } = this.getEndpointInfo()
    const collection = getCollection(datasource, collectionId)

    if (!collection) {
      return null
    }

    const endpoint = getEndpoint(collection, endpointId)

    return endpoint
  }

  render() {
    const { datasource, field, appId, componentId, objectId } = this.props

    const { endpointId, collectionId } = this.getEndpointInfo()
    const endpoint = this.getEndpoint()

    if (!endpoint) {
      return (
        <EmptyState>
          <DatasourceSelector
            appId={appId}
            name={`${field}.options.datasourceId`}
          />
          Authentication not configured
        </EmptyState>
      )
    }

    return (
      <div className="action-item-form-options">
        <div>
          <DatasourceSelector
            appId={appId}
            name={`${field}.options.datasourceId`}
          />
          <APIFields
            field={`${field}.options.params`}
            datasource={datasource}
            collectionId={collectionId}
            endpointId={endpointId}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, options }) => ({
  datasource: getDatasource(state, appId, options.datasourceId),
})

export default connect(mapStateToProps)(APIAuthOptions)
