import React, { Component } from 'react'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import welcomeGraphic from './assets/external-users-welcome-graphic.png'
import './Intro.scss'

export default class Intro extends Component {
  render() {
    return (
      <div className="legacy-external-users">
        <h1 className="external-users-title">Getting Started</h1>
        <div className="external-users-body">
          <img src={welcomeGraphic} alt="External Users Welcome" width="50%" />
          <h1>Welcome to the External Users Setup!</h1>
          <p className="external-users-subtitle">
            Connect your existing Adalo app to the users you have in another
            system.
          </p>
          <p className="external-users-text-body">
            If you need help, no worries, we have you covered! Follow the links
            to watch a tutorial video or go to a help doc.
          </p>
          <div className="external-users-btn-row">
            <Button
              to="https://www.youtube.com/watch?v=tg_qTEaXTzA"
              target="_blank"
              orange
              secondary
            >
              <Icon type="ondemand-video" />
              <span>Watch our video tutorial</span>
            </Button>
            <Button
              to="https://help.adalo.com/"
              target="_blank"
              orange
              secondary
              icon="menu-book"
            >
              <span>Read our help docs</span>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
