import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextControl from './TextControl'
import Slider from './Slider'
import './SlideControl.css'

export default class SlideControl extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    percentage: PropTypes.bool,
  }

  handleChange = obj => {
    const { name, onChange, percentage } = this.props

    if (percentage) {
      obj = { [name]: obj[name] / 100 }
    }

    onChange(obj)
  }

  render() {
    let {
      name,
      value,
      defaultValue,
      onChange,
      onFocus,
      onBlur,
      min,
      max,
      percentage,
      gray,
    } = this.props

    if (value === undefined || value === null) {
      value = defaultValue
    }

    if (percentage) {
      value = Math.round(100 * value)
      min = 0
      max = 100
    }

    return (
      <div className="slide-control">
        <Slider
          onChange={this.handleChange}
          name={name}
          value={value}
          min={min}
          max={max}
          onStart={onFocus}
          onEnd={onBlur}
        />
        <div className="slide-control-input">
          <TextControl
            percentage={percentage}
            type="number"
            name={name}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            gray={gray}
          />
        </div>
      </div>
    )
  }
}
