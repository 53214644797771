import { getTables } from 'ducks/apps/datasources'
import { dataTypes } from '@adalo/constants'
import { removeSpecialChars } from 'utils/regex'

const ADALO_API_URL = process.env.REACT_APP_ADALO_API

const ADALO_API_GET_COLLECTION_LIMIT_DEFAULT = 1000

export const paramAuth = apiKey => {
  const auth = [
    {
      name: 'Authorization',
      in: 'header',
      description: 'Your secret API Key',
      required: true,
      schema: {
        type: 'string',
      },
      example: `Bearer ${apiKey}`,
    },
    {
      name: 'Content-Type',
      in: 'header',
      description: 'Indicate the media type of the resource',
      required: true,
      schema: {
        type: 'string',
      },
      example: `application/json`,
    },
  ]

  return auth
}

export const paramApp = appId => {
  return {
    name: 'appId',
    in: 'path',
    description: 'AppId of the app where the collection is',
    required: true,
    schema: {
      type: 'string',
    },
    example: [appId],
  }
}

export const paramCollection = collectionId => {
  return {
    name: 'collectionId',
    in: 'path',
    description: 'Id of collection for getting data',
    required: true,
    schema: {
      type: 'string',
    },
    example: [collectionId],
  }
}

export const paramGetAll = () => [
  {
    name: 'offset',
    in: 'query',
    description: 'How many records to skip (default: 0)',
    required: false,
    schema: {
      type: 'number',
    },
    example: [200],
  },
  {
    name: 'limit',
    in: 'query',
    description: `How many records to return at most (default: ${ADALO_API_GET_COLLECTION_LIMIT_DEFAULT})`,
    required: false,
    schema: {
      type: 'number',
    },
    example: [100],
  },
  {
    name: 'filterKey',
    in: 'query',
    description:
      'The key (i.e. property or column) name to filter by (only for properties of type: Text, Number, True/False [boolean], Date & Time, Date)',
    required: false,
    schema: {
      type: 'string',
    },
    example: ['Email'],
  },
  {
    name: 'filterValue',
    in: 'query',
    description: 'The value to filter by (exact match)',
    required: false,
    schema: {
      type: 'string',
    },
    example: [encodeURIComponent('jane.doe@example.com')],
  },
]

export const paramRecordId = () => {
  return {
    name: 'elementId',
    in: 'path',
    description: 'Element Id',
    required: true,
    schema: {
      type: 'number',
    },
  }
}

export const generateDocsHeader = () => {
  return {
    openapi: '3.0.3',
    info: {
      title: 'Adalo API',
      'x-logo': {
        url: 'https://apto-resources-dev.s3-us-west-1.amazonaws.com/API-Documentation-graphic.png',
      },
      description: `Welcome to the Adalo API documentation! \n>Note: This page contains all the necessary information on how to use Adalo's Collection API in your apps and projects. Every example in this document uses real collection names from your app. \n\n&nbsp;\n### Authentication\nAll requests to 
      the Adalo API will need to contain the following two headers: \n\n \`\`\`\nAuthorization: Bearer [Your App\'s API Key]
      Content-Type: application/json\n\`\`\` \nTo authenticate, 
      you will need to create an API Key from the Settings / App Access menu. Once you have your 
      API Key, you can pass it through an HTTP header like this:\n \`\`\`\ncurl -X GET "${ADALO_API_URL}/v0/apps/{appId}/collections/{collectionId}" -H  "Content-Type: application/json" -H  "Authorization: Bearer {API Key}"\n\`\`\` 
      \n&nbsp;\n\n### Rate Limits\n\nThe Adalo Collection API is limited to 5 requests per second. If you exceed this rate, you will receive a 429 status code and will need to wait 
      some time before subsequent requests will succeed. \n\n We provide the control headers 
      X-RateLimit-Limit, X-RateLimit-Remaining, and X-RateLimit-Reset to help you manage the 
      requests. Once you reach the limit, you will also receive the header Retry-After informing you of
      the amount of time needed before submitting a new request.`,

      contact: {
        name: 'Adalo Team',
        contact: 'contact@adalo.com',
      },
    },
    host: `${ADALO_API_URL}`,
    basePath: '/v0/apps',
    servers: [
      {
        url: `${ADALO_API_URL}`,
      },
    ],
    consumes: ['application/json'],
    produces: ['application/json'],
  }
}

export const generateDocsGetnPut = (
  apiKey,
  appId,
  collectionId,
  collectionName
) => {
  const endpoint = `/v0/apps/${appId}/collections/${collectionId}`

  const parametersGet = [].concat(
    paramAuth(apiKey),
    paramApp(appId),
    paramCollection(collectionId),
    paramGetAll()
  )

  const parametersPost = [].concat(
    paramAuth(apiKey),
    paramApp(appId),
    paramCollection(collectionId)
  )

  return {
    [endpoint]: {
      get: {
        tags: [collectionName],
        summary: 'Get all collection records',
        description: `Endpoint: \`${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}\`\n\n&nbsp;\n## Pagination\nThe Adalo API only returns one page of records at a time. Each page will contain an amount of rows, which is ${ADALO_API_GET_COLLECTION_LIMIT_DEFAULT} by default, but you can request fewer records per page using the LIMIT parameter.\n\n&nbsp;\n\nIf there are more records than can fit in one page of results, you can get the next page of results by using the OFFSET parameter to specify how many records to skip.\n\n&nbsp;\n\nIf both OFFSET and LIMIT are used in the request, then OFFSET rows are skipped before starting to count the LIMIT rows that are returned.\n\n&nbsp;\n\nYou can add these parameters as shown below:\n\n
        curl -X GET \"${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}?offset=0&limit=100\" -H  "Content-Type: application/json" -H  "Authorization: Bearer ${apiKey}"\n`,
        responses: {
          200: {
            description: 'Get a list of records in the specified collection',
            content: {
              'application/json': {
                schema: {
                  $ref: `#/components/schemas/${collectionName}/methods/GETALL`,
                },
              },
            },
          },
        },
        parameters: parametersGet,
        'x-codeSamples': [
          {
            lang: 'curl',
            source: `curl -X GET "${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}" -H  "Content-Type: application/json" -H  "Authorization: Bearer ${apiKey}"`,
          },
        ],
      },
      post: {
        tags: [collectionName],
        summary: 'Add a collection record',
        description: `Endpoint: \`${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}\``,
        responses: {
          200: {
            description: 'Create a record in the specified collection',
          },
        },
        parameters: parametersPost,
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: `#/components/schemas/${collectionName}/methods/POST`,
              },
            },
          },
        },
      },
    },
  }
}

export const generateDocsGetOne = (
  apiKey,
  appId,
  collectionId,
  collectionName
) => {
  const endpoint = `/v0/apps/${appId}/collections/${collectionId}/1`

  const parameters = [].concat(
    paramAuth(apiKey),
    paramApp(appId),
    paramCollection(collectionId),
    paramRecordId()
  )

  const parametersPut = [].concat(
    paramAuth(apiKey),
    paramApp(appId),
    paramCollection(collectionId),
    paramRecordId()
  )

  return {
    [endpoint]: {
      get: {
        tags: [collectionName],
        summary: 'Fetch a single collection record',
        description: `Endpoint: \`${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}/{ElementId}\``,
        responses: {
          200: {
            description: 'Fetched a record in the specified collection',
            content: {
              'application/json': {
                schema: {
                  $ref: `#/components/schemas/${collectionName}/methods/GET`,
                },
              },
            },
          },
        },
        parameters,
        'x-codeSamples': [
          {
            lang: 'curl',
            source: `curl -X GET "${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}/1" -H  "Content-Type: application/json" -H  "Authorization: Bearer ${apiKey}"`,
          },
        ],
      },
      delete: {
        tags: [collectionName],
        summary: 'Delete a single collection record',
        description: `Endpoint: \`${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}/{ElementId}\``,
        responses: {
          204: {
            description: 'Delete a record in the specified collection',
          },
        },
        parameters,
        'x-codeSamples': [
          {
            lang: 'curl',
            source: `curl -X DELETE "${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}/1" -H  "Content-Type: application/json" -H  "Authorization: Bearer ${apiKey}"`,
          },
        ],
      },
      put: {
        tags: [collectionName],
        summary: 'Update a record in the specified collection',
        description: `Endpoint: \`${ADALO_API_URL}/v0/apps/${appId}/collections/${collectionId}/{ElementId}\``,
        responses: {
          200: {
            description: 'Update Successful',
          },
        },
        parameters: parametersPut,
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: `#/components/schemas/${collectionName}/methods/PUT`,
              },
            },
          },
        },
      },
    },
  }
}

export const generatePaths = (apiKey, appId, myCollectionList) => {
  let paths

  if (myCollectionList) {
    for (let key = 0; key < myCollectionList.length; key += 1) {
      const docsGetnPost = generateDocsGetnPut(
        apiKey,
        appId,
        myCollectionList[key].collectionId,
        myCollectionList[key].collectionName
      )

      const docsGetOneUpdate = generateDocsGetOne(
        apiKey,
        appId,
        myCollectionList[key].collectionId,
        myCollectionList[key].collectionName
      )

      paths = { ...paths, ...docsGetnPost, ...docsGetOneUpdate }
    }
  }

  return paths
}

export const generateCollectionList = datasources => {
  const tableListFromDatasources = getTables(datasources)

  let filteredTables = {}

  Object.keys(tableListFromDatasources).map(id => {
    if (tableListFromDatasources[id].name !== 'SingleSignOnIds') {
      if (tableListFromDatasources[id].type !== 'api') {
        filteredTables = {
          ...filteredTables,
          [id]: tableListFromDatasources[id],
        }
      }
    }
  })

  const collectionNamesList = Object.keys(filteredTables).map(id => {
    const name = removeSpecialChars(filteredTables[id].name)

    return {
      collectionId: id,
      collectionName: name,
    }
  })

  return collectionNamesList
}

export const translateProperties = (tableFields, method = null) => {
  let properties = {}
  const hiddenFields = ['Temporary Password Expiration']
  let fieldType

  tableFields.map(id => {
    let type = {}

    if (typeof id.type === 'object') {
      fieldType = 'object'
    } else {
      fieldType = id.type
    }

    if (!hiddenFields.includes(id.name)) {
      switch (fieldType) {
        case dataTypes.TEXT:
          type = { type: 'string' }

          break
        case dataTypes.NUMBER:
          type = { type: 'number' }

          break
        case dataTypes.BOOLEAN:
          type = { type: 'boolean' }

          break
        case dataTypes.DATE:
        case dataTypes.DATE_ONLY:
          type = { type: 'string', format: 'date-time' }

          break
        case dataTypes.OBJECT:
          if (id.type.type) {
            type = getRelationType(id.type, method) || {}
          }
      }

      if (Object.keys(type).length > 0) {
        properties = {
          ...properties,
          [id.name]: type,
        }
      }
    }
  })

  return properties
}

export const getTableFields = datasources => {
  const tableListFromDatasources = getTables(datasources)
  const method = ['GET', 'PUT', 'POST']

  let filteredTables = {}

  Object.keys(tableListFromDatasources).map(id => {
    if (tableListFromDatasources[id].name !== 'SingleSignOnIds') {
      filteredTables = { ...filteredTables, [id]: tableListFromDatasources[id] }
    }
  })

  const tableSchemas = Object.keys(filteredTables).map(table => {
    let methods = {}

    method.map(id => {
      if (id === 'GET') {
        const idField = {
          name: 'id',
          type: 'number',
        }
        const createdAtField = {
          name: 'created_at',
          type: 'date',
        }
        const updatedAtField = {
          name: 'updated_at',
          type: 'date',
        }
        const tableFields = [
          idField,
          ...Object.values(filteredTables[table].fields),
          createdAtField,
          updatedAtField,
        ]
        methods = {
          ...methods,
          [id]: { properties: translateProperties(tableFields, id) },
        }
        methods = {
          ...methods,
          GETALL: {
            properties: {
              records: {
                type: 'array',
                items: {
                  properties: { ...translateProperties(tableFields, id) },
                },
              },
              offset: {
                type: 'number',
                example: 100,
              },
            },
          },
        }
      } else {
        methods = {
          ...methods,
          [id]: {
            properties: translateProperties(
              Object.values(filteredTables[table].fields),
              id
            ),
          },
        }
      }
    })

    const name = removeSpecialChars(filteredTables[table].name)

    return {
      [name]: {
        type: 'object',
        methods,
      },
    }
  })

  const schemas = Object.assign({}, ...tableSchemas)

  return { schemas }
}

const getRelationType = (object, method = null) => {
  const relationsAlowed = []

  if (method === 'POST') return

  if (method) {
    if (method === 'GET') {
      relationsAlowed.push('belongsTo')
      relationsAlowed.push('manyToManyReverse')
    }

    relationsAlowed.push('manyToMany')
    relationsAlowed.push('hasMany')
  }

  if (relationsAlowed.includes(object.type)) {
    return {
      type: 'number',
      example: [1, 2, 3],
    }
  }
}
