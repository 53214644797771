import styled from 'styled-components'
import { ImageRatio, ImageRatios, ImageRatioStyle } from '@adalo/constants'
import type { DefaultCellProps } from './types'

const Container = styled('div')`
  padding-top: 10px;
  padding-bottom: 10px;
`

const Image = styled('div')<{
  $width: number
  $height: number
  $ratio: ImageRatio
}>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  background-color: #eee;
  ${({ $ratio }) => $ratio === ImageRatios.circle && 'border-radius: 50%;'}
`

const ImageCell = ({ field }: DefaultCellProps): JSX.Element | null => {
  if (!field.binding.format?.imageRatio) {
    return null
  }

  const { width, height } = ImageRatioStyle[field.binding.format.imageRatio]

  return (
    <Container>
      <Image
        $width={width}
        $height={height}
        $ratio={field.binding.format.imageRatio}
      />
    </Container>
  )
}

export default ImageCell
