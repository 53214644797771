import Icon from 'components/Shared/Icon'

import { Confetti } from 'components/Shared/Confetti'

import xanoAdaloSvg from './assets/xano-adalo.svg'

import './Banner.scss'

import { XANO_INTEGRATION_HELP_DOCS_URL } from '../../../constants'

type BannerProps = {
  setupComplete: boolean
}

const Banner = ({ setupComplete = false }: BannerProps): JSX.Element => {
  const title = setupComplete
    ? 'Your Xano Database Setup is Complete!'
    : 'Welcome to the Xano External Database Setup'

  const videoUrl = '27z2VQLhLcw'

  const titleIcon = {
    src: xanoAdaloSvg,
    alt: 'External Databases Welcome Confetti',
  }

  return (
    <div className="xano-external-users">
      <div className="external-users-body">
        {setupComplete ? (
          <Confetti width="25%" alt="External Databases Setup Complete" />
        ) : (
          <img src={titleIcon.src} alt={titleIcon.alt} width="20%" />
        )}
        <div className="external-users-text">
          <h1>{title}</h1>
          {!setupComplete ? (
            <p className="external-users-subtitle">
              You'll need a Xano account and Base API URL to complete setup.
              Watch our video on how to get started!
            </p>
          ) : (
            <p className="external-users-subtitle-setup-complete">
              For more videos, check out our
              <a
                className="xano-setup-complete-link"
                href="https://www.youtube.com/@Adalo"
                target="_blank"
                rel="noreferrer"
              >
                Youtube Channel
              </a>
              and
              <a
                className="xano-setup-complete-link"
                href={XANO_INTEGRATION_HELP_DOCS_URL}
                target="_blank"
                rel="noreferrer"
              >
                Help Docs
              </a>
              .
            </p>
          )}
        </div>
        {!setupComplete ? (
          <a
            className="create-xano-account-link"
            href="https://app.xano.com/admin/signup?plan=build&cluster_id=1"
            target="_blank"
            rel="noreferrer"
          >
            Create My Free Xano Account
            <Icon type="open-in-new" small color="teal" />
          </a>
        ) : null}
        {!setupComplete ? (
          <iframe
            width="508px"
            height="283px"
            src={`https://www.youtube.com/embed/${videoUrl}`}
            frameBorder="0"
            allowFullScreen
            title="Adalo Xano Setup Video"
          />
        ) : null}
      </div>
    </div>
  )
}

export default Banner
