import React, { Component } from 'react'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'
import qs from 'qs'

import { librarySocket } from 'utils/developers'
import { getAuthToken } from 'utils/auth'

export default class Frame extends Component {
  constructor(props) {
    super(props)
    this.previewRef = React.createRef()
  }

  librarySocket = () => {
    const sessionToken = getAuthToken()

    return sessionToken && librarySocket(sessionToken)
  }

  componentDidMount() {
    const socket = this.librarySocket()
    if (socket) socket.on('libraryUpdates', this.handlePreviewReload)
  }

  componentWillUnmount() {
    const socket = this.librarySocket()
    if (socket) socket.off('libraryUpdates', this.handlePreviewReload)
  }

  handlePreviewReload = () => {
    if (this.previewRef?.current?.src) {
      // reloads the iFrame by resetting the URL
      this.previewRef.current.src = this.previewRef.current.src
    }
  }

  getScale = () => {
    const { size } = this.props

    const { height } = size
    const margin = 30
    const windowHeight = Math.max(window.innerHeight, 400)
    const yPad = this.hasNotch() ? 20 : 90

    return (windowHeight - 2 * margin) / (height + 2 * yPad)
  }

  forceRender = () => {
    this.forceUpdate()
  }

  hasNotch = () => {
    const { size } = this.props

    return size.os === 'ios' && size.offset_top > 20
  }

  getURL = () => {
    const { appId, size, baseUrl } = this.props
    const { width, height, os } = size
    const token = getAuthToken()

    const params = {
      os,
      w: width,
      h: height,
      offset_bottom: size.offset_bottom,
      offset_top: size.offset_top,
      sessionToken: token,
    }

    const src = `${baseUrl}/preview/${appId}?${qs.stringify(params)}`

    return src
  }

  render() {
    const { size } = this.props
    const { width, height } = size
    const iphonex = this.hasNotch()
    const src = this.getURL()
    const scale = this.getScale()

    const styles = {
      transform: `scale(${scale})`,
      width: width + 40,
      height: height + (iphonex ? 40 : 180),
    }

    const innerStyles = {
      width: Math.ceil(scale * styles.width),
      height: Math.ceil(scale * styles.height),
    }

    return (
      <div className="editor-preview-frame-wrapper">
        <DocumentEvents target={window} onResize={this.forceRender} />
        <div style={innerStyles}>
          <div
            className={classNames('editor-preview-frame-border', { iphonex })}
            style={styles}
          >
            <div className="editor-preview-frame-clip">
              {[
                <iframe
                  allowFullScreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allow="camera *; microphone *; geolocation *;"
                  key={src}
                  src={src}
                  width={width}
                  height={height}
                  className="editor-preview-frame"
                  title="App Preview"
                  ref={this.previewRef}
                />,
              ]}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
