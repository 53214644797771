import { useEffect, useState } from 'react'
import { startCase, toLower } from 'lodash'
import classNames from 'classnames'
import Fuse from 'fuse.js'

import Button from 'components/Shared/Button'
import Loading from 'components/Shared/Loading'

import './styles.scss'

type Props = {
  placeholder: string
  label?: string
  chips: string[]
  onClick?: (chip: string, isCustom?: boolean) => void
  limit?: number
  loading?: boolean
  initialValue?: string | undefined
  skipLabel?: string
  skipValue?: string
  showEmptyState?: boolean
}

const ChipInput: React.FC<Props> = ({
  placeholder,
  label,
  chips = [],
  limit = 6,
  onClick = () => {},
  loading = false,
  initialValue = '',
  skipLabel = 'Skip for now',
  skipValue = 'Skip',
  showEmptyState = false,
}) => {
  const [selectedChip, setSelectedChip] = useState(initialValue)
  const [filteredChips, setFilteredChips] = useState<string[]>(chips)
  const [inputValue, setInputValue] = useState('')

  const [chipHover, setChipHover] = useState<string | null>(null)

  const fuse = new Fuse(chips, { includeScore: true })

  const options = !inputValue
    ? filteredChips
    : fuse.search(inputValue).map(({ item }) => item)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setInputValue(value)

    if (!value) {
      return setFilteredChips(chips)
    }

    setFilteredChips(() =>
      chips.filter(chip => chip.toLowerCase().includes(value.toLowerCase()))
    )
  }

  const handleChipClick = (chip: string, isCustom = false) => {
    const capitalizedChip = startCase(toLower(chip))

    if (selectedChip === chip) {
      setFilteredChips(chips)
      onClick('')

      return setSelectedChip('')
    }

    setSelectedChip(capitalizedChip)
    onClick(capitalizedChip, isCustom)
    setInputValue('')
  }

  useEffect(() => {
    if (chips.length) {
      setFilteredChips(chips)
    }
  }, [chips])

  // TODO @danicunhac: Uncomment this when we have the ability to add new chips
  // const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === 'Enter') {
  //     const value = e.currentTarget.value
  //     if (value) {
  //       setSelectedChip(value)
  //       onClick(value)
  //     }
  //   }
  // }

  return (
    <div className="chip-input">
      {label && <label className="chip-input__label">{label}</label>}
      <div
        className={classNames('chip-input__wrapper', {
          'chip-input__wrapper--selected': selectedChip,
        })}
      >
        {!selectedChip ? (
          <>
            <input
              type="text"
              placeholder={placeholder}
              onChange={handleInputChange}
              className="chip-input__field"
              // TODO @danicunhac: Uncomment this when we have the ability to add new chips
              // onKeyDown={handleEnter}
              value={inputValue}
            />
            <div className="chip-input__chips">
              {chips.length
                ? [
                    options.slice(0, limit).map(chip => (
                      <div key={chip} className="chip-input__chip__wrapper">
                        <Button
                          onClick={() => handleChipClick(chip)}
                          outlined
                          small
                          className={classNames('chip-input__chip', {
                            'chip-input__chip--hover': chipHover === chip,
                          })}
                          onMouseEnter={() => {
                            setChipHover(chip)
                          }}
                          onMouseLeave={() => {
                            setChipHover(null)
                          }}
                        >
                          {chip}
                        </Button>
                      </div>
                    )),
                    // TODO @danicunhac: refactor this so it's more generic
                    inputValue
                      ? [
                          <div className="chip-input__chip__wrapper">
                            <Button
                              key={inputValue}
                              onClick={() => handleChipClick(inputValue, true)}
                              outlined
                              small
                              icon="plus-small"
                              className={classNames('chip-input__chip', {
                                'chip-input__chip--hover':
                                  chipHover === inputValue,
                              })}
                              onMouseEnter={() => {
                                setChipHover(inputValue)
                              }}
                              onMouseLeave={() => {
                                setChipHover(null)
                              }}
                            >
                              Use &lsquo;{inputValue}&lsquo;
                            </Button>
                          </div>,
                          <div className="chip-input__chip__wrapper generic">
                            <Button
                              key="skip-chip"
                              className={classNames('chip-input__chip', {
                                'chip-input__chip--hover generic':
                                  chipHover === skipValue,
                              })}
                              onMouseEnter={() => {
                                setChipHover(skipValue)
                              }}
                              onMouseLeave={() => {
                                setChipHover(null)
                              }}
                              onClick={() => handleChipClick(skipValue)}
                              secondary
                              small
                              text
                              darkGray
                            >
                              {skipLabel}
                            </Button>
                          </div>,
                        ]
                      : null,
                  ]
                : null}
              {chips.length && !filteredChips.length && showEmptyState ? (
                <span className="chip-input__chips--empty">
                  No chips matching your search...
                </span>
              ) : null}
              {loading && <Loading expanded={false} small />}
            </div>
          </>
        ) : (
          <div>
            <Button
              key={selectedChip}
              teal
              secondary
              small
              className="chip-input__chip--selected"
              onClick={() => handleChipClick(selectedChip)}
              icon="close"
              iconSide="right"
            >
              {selectedChip}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChipInput
