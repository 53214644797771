import { set } from 'lodash'
import { resizingOptions, responsivePositioningOptions } from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { EditorObject } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import {
  getSectionFromContainer,
  isContainerSectionElement,
} from 'utils/layoutSections'

import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'
import { moveElementHandler } from './moveElement'

export interface UpdateElementWidthConstraintsOptions {
  objectId: string
  minWidth: number | undefined
  minWidthEnabled: boolean | undefined
  maxWidth: number | undefined
  maxWidthEnabled: boolean | undefined
}

export interface UpdateElementWidthConstraintsInstruction {
  operation: 'updateElementWidthConstraints'
  options: UpdateElementWidthConstraintsOptions
}

const { SCALES_WITH_PARENT } = resizingOptions
const { CENTER, LEFT_AND_RIGHT } = responsivePositioningOptions

export const updateElementWidthConstraintsHandler = (
  state: InstructionState,
  options: UpdateElementWidthConstraintsOptions
): InstructionState => {
  const { list, pathMap } = state
  const { objectId, minWidth, minWidthEnabled, maxWidth, maxWidthEnabled } =
    options

  const object: EditorObject = getObject(list, pathMap, objectId)
  const screenObj = getParentScreen(list, pathMap, objectId)
  if (!screenObj) {
    throw new Error(`Could not find screen for object ${objectId}`)
  }

  const currentDeviceType = getDeviceType(screenObj.width)
  const deviceObject = getDeviceObject(object, currentDeviceType)
  const { responsivity } = deviceObject

  const isScalesAnchorCenter =
    responsivity?.horizontalPositioning === CENTER &&
    responsivity?.horizontalScaling === SCALES_WITH_PARENT
  const isScalesAnchorLeftAndRight =
    responsivity?.horizontalPositioning === LEFT_AND_RIGHT &&
    responsivity?.horizontalScaling === SCALES_WITH_PARENT

  if (!isScalesAnchorCenter && !isScalesAnchorLeftAndRight) {
    return state
  }

  const updatedMinMax = {
    ...(typeof minWidthEnabled === 'boolean' && { minWidthEnabled }),
    ...(typeof maxWidthEnabled === 'boolean' && { maxWidthEnabled }),
    ...(typeof minWidth === 'number' && { minWidth }),
    ...(typeof maxWidth === 'number' && { maxWidth }),
  }

  const updatedObject = {
    ...object,
    ...((typeof object.shared === 'undefined' ||
      (object.shared && object.shared[currentDeviceType]) === true) && {
      ...updatedMinMax,
    }),
    ...(typeof object[currentDeviceType] === 'object' && {
      [currentDeviceType]: {
        ...object[currentDeviceType],
        ...updatedMinMax,
      },
    }),
  }

  if (
    typeof updatedObject.minWidth === 'number' &&
    typeof updatedObject.maxWidth === 'number' &&
    updatedObject.maxWidth <= updatedObject.minWidth
  ) {
    updatedObject.maxWidth = updatedObject.minWidth + 1
  }

  if (
    updatedObject[currentDeviceType] &&
    typeof updatedObject[currentDeviceType]?.minWidth === 'number' &&
    typeof updatedObject[currentDeviceType]?.maxWidth === 'number'
  ) {
    const deviceParams = updatedObject[currentDeviceType]
    if (
      deviceParams &&
      typeof deviceParams.minWidth === 'number' &&
      typeof deviceParams.maxWidth === 'number' &&
      deviceParams.maxWidth <= deviceParams.minWidth
    ) {
      set(
        updatedObject,
        [currentDeviceType, 'maxWidth'],
        deviceParams.minWidth + 1
      )
    }
  }

  const updatedList = updateChangedObject(
    list,
    pathMap,
    updatedObject,
    undefined
  )
  const updatedDeviceObject = getDeviceObject(
    getObject(updatedList, pathMap, objectId),
    currentDeviceType
  )

  const updatedState = moveElementHandler(
    {
      ...state,
      list: updatedList,
    },
    {
      objectId,
      x: updatedDeviceObject.x,
      y: updatedDeviceObject.y,
    }
  )

  if (isContainerSectionElement(object)) {
    const section = getSectionFromContainer(updatedState.list, pathMap, object)
    if (!section) {
      return updatedState
    }

    const deviceSection = getDeviceObject(section, currentDeviceType)
    const updatedDeviceContainer = getDeviceObject(
      getObject(updatedState.list, pathMap, objectId),
      currentDeviceType
    )

    return moveElementHandler(
      {
        ...updatedState,
        list: updatedState.list,
      },
      {
        objectId,
        x:
          deviceSection.x +
          (deviceSection.width - updatedDeviceContainer.width) / 2,
        y: updatedDeviceContainer.y,
      }
    )
  }

  return updatedState
}

const updateElementWidthConstraints = (
  objectId: string,
  minWidth: number | undefined,
  minWidthEnabled: boolean,
  maxWidth: number | undefined,
  maxWidthEnabled: boolean
): UpdateElementWidthConstraintsInstruction => ({
  operation: 'updateElementWidthConstraints',
  options: {
    objectId,
    minWidth,
    minWidthEnabled,
    maxWidth,
    maxWidthEnabled,
  },
})

export const updateElementMinWidthConstraints = (
  objectId: string,
  minWidth: number | undefined,
  minWidthEnabled: boolean | undefined
): UpdateElementWidthConstraintsInstruction => ({
  operation: 'updateElementWidthConstraints',
  options: {
    objectId,
    minWidth,
    minWidthEnabled,
    maxWidth: undefined,
    maxWidthEnabled: undefined,
  },
})

export const updateElementMaxWidthConstraints = (
  objectId: string,
  maxWidth: number | undefined,
  maxWidthEnabled: boolean | undefined
): UpdateElementWidthConstraintsInstruction => ({
  operation: 'updateElementWidthConstraints',
  options: {
    objectId,
    minWidth: undefined,
    minWidthEnabled: undefined,
    maxWidth,
    maxWidthEnabled,
  },
})

export default updateElementWidthConstraints
