import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import classNames from 'classnames'
import { dataTypes } from '@adalo/constants'
import { getCustomAction } from 'ducks/customActions'
import FreeTrial from 'components/Shared/FreeTrial'
import {
  WrappedBindableTextControl,
  WrappedFormulaControl,
} from '../../../Shared/BindableTextControl'

import './CustomAction.scss'

class CustomAction extends Component {
  render() {
    const { appId, field, objectId, actionId, customAction, reference } =
      this.props

    const inputs = customAction.body.inputs
    const role = reference ? 'listItem' : null

    if (!inputs || inputs.length === 0) {
      return (
        <>
          <FreeTrial appId={appId} type="customActions" />
          <p className="inputs-empty-state">No Inputs</p>
        </>
      )
    }

    return (
      <div
        className={classNames(
          'action-item-form-options',
          'form-inspect-field-wrapper'
        )}
      >
        <FreeTrial appId={appId} type="customActions" />
        <>
          {inputs.map(input => (
            <Field
              label={input.label}
              displayName={input.label}
              key={input.value.id}
              name={`${field}.options.source.${input.value.id}`}
              component={
                input.type === dataTypes.TEXT
                  ? WrappedBindableTextControl
                  : WrappedFormulaControl
              }
              objectId={objectId}
              placeholder="Empty"
              actionId={actionId}
              reference={reference}
              role={role}
            />
          ))}
        </>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId, options } = props

  const customAction = getCustomAction(state, appId, options.customActionId)

  return { customAction }
}

export default connect(mapStateToProps)(CustomAction)
