const parsePath = (path: string): number[] => {
  const result = []
  const parts = path.split('.')
  for (const part of parts) {
    const idx = parseInt(part, 10)
    result.push(idx)
  }

  return result
}

const cache = new Map<string, ObjectPath>()
export default class ObjectPath {
  private readonly path: ReadonlyArray<number>
  private parent: ObjectPath | undefined

  constructor(path: number[]) {
    this.path = path
  }

  get(idx: number): number {
    const part = this.path[idx]
    if (part === undefined) {
      throw new Error(
        `Path does not contain segment. (Path: ${String(
          this.path
        )}, idx: ${idx})`
      )
    }

    return part
  }

  length(): number {
    return this.path.length
  }

  toString(): string {
    return this.path.map(n => String(n)).join('.')
  }

  parentPath(): ObjectPath {
    if (!this.parent) {
      const parts = []
      const end = this.path.length - 1
      for (let i = 0; i < end; i += 1) {
        parts.push(this.path[i])
      }

      this.parent = ObjectPath.fromString(parts.join('.'))
    }

    return this.parent
  }

  static fromString(serialized: string): ObjectPath {
    if (!serialized) {
      throw new Error(
        `Cannot create path from empty string. (Path: ${serialized})`
      )
    }
    const objectPath = cache.get(serialized)

    if (objectPath) {
      return objectPath
    }

    const normalizedPath = parsePath(serialized)
    const objPath = new ObjectPath(normalizedPath)
    cache.set(serialized, objPath)

    return objPath
  }
}
