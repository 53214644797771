import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  resizingOptions,
  responsivePositioningOptions,
  COMPONENT,
  GROUP,
} from '@adalo/constants'

import { getApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users'
import { useParams } from 'react-router-dom'
import { useFlags } from 'flags'

import { updateElement, updateElementMargins } from 'ducks/editor/instructions'
import { runInstructions } from 'ducks/editor/objects'
import getDeviceObject from 'utils/getDeviceObject'

import { getDefaultResponsivity } from 'utils/defaultConstraints'
import { isSectionElement } from 'utils/layoutSections'
import MenuControl from '../../../Inspect/Libraries/MenuControl'
import CustomLayoutHelp from './CustomLayout/CustomLayoutHelp'
import CommonInspectRow from '../../../Inspect/GenericRow'
import LayoutOptionTooltip, {
  LAYOUT_OPTIONS_ANIMATIONS,
} from './LayoutOptionTooltip'

const { SCALES_WITH_PARENT, FIXED } = resizingOptions
const { LEFT, RIGHT, CENTER, LEFT_AND_RIGHT } = responsivePositioningOptions

const {
  STAYS_FIXED_ANCHOR_LEFT,
  STAYS_FIXED_ANCHOR_CENTER,
  STAYS_FIXED_ANCHOR_RIGHT,
  SCALES_ANCHOR_CENTER,
  SCALES_ANCHOR_LEFT_RIGHT,
} = LAYOUT_OPTIONS_ANIMATIONS

const HORIZONTAL_POSITIONING_OPTIONS = [
  {
    label: 'Anchor Left',
    value: LEFT,
    rightIcon: (
      <LayoutOptionTooltip
        label={'Anchor Left'}
        animationData={STAYS_FIXED_ANCHOR_LEFT}
      />
    ),
  },
  {
    label: 'Anchor Center',
    value: CENTER,
    rightIcon: (
      <LayoutOptionTooltip
        label={'Anchor Center'}
        animationData={STAYS_FIXED_ANCHOR_CENTER}
      />
    ),
  },
  {
    label: 'Anchor Right',
    value: RIGHT,
    rightIcon: (
      <LayoutOptionTooltip
        label={'Anchor Right'}
        animationData={STAYS_FIXED_ANCHOR_RIGHT}
      />
    ),
  },
]

const getResponsivity = (device, object, mobileOnly) => {
  let { responsivity } = object || {}

  if (object && device && object[device]) {
    responsivity = object[device].responsivity
  }

  if (mobileOnly && object.mobile?.responsivity) {
    responsivity = object.mobile?.responsivity
  }

  return responsivity
}

const LayoutControls = props => {
  const { object, componentParent, device } = props
  const { id } = object

  const dispatch = useDispatch()
  const { appId } = useParams()
  const app = useSelector(state => getApp(state, appId))
  const user = useSelector(state => getCurrentUser(state))
  const userIsAdmin = typeof user !== 'undefined' && user.admin === true
  const { hasNewMobileOnlyApp } = useFlags()
  const mobileOnly =
    hasNewMobileOnlyApp && app?.webSettings?.layoutMode === 'mobile'

  const responsivity = getResponsivity(device, object, mobileOnly)
  const horizontalScaling = responsivity?.horizontalScaling
  const horizontalPositioning = responsivity?.horizontalPositioning
  const hasComponentParent = componentParent?.type === GROUP
  const objectIsSectionElement = isSectionElement(object)
  const displayHorizontalPositioning =
    (objectIsSectionElement && userIsAdmin) || !objectIsSectionElement

  useEffect(() => {
    if (
      !responsivity?.horizontalPositioning ||
      !responsivity?.horizontalScaling
    ) {
      const defaultResponsivity = getDefaultResponsivity(object)
      onChange(defaultResponsivity)
    }

    if (device && !object[device]) {
      onChange(responsivity)
    }
  }, [id])

  const onChange = value => {
    if (value) {
      let newValue = { ...responsivity, ...value }
      let key = 'responsivity'

      if (
        value.horizontalScaling === SCALES_WITH_PARENT &&
        (horizontalPositioning === LEFT || horizontalPositioning === RIGHT)
      ) {
        newValue.horizontalPositioning = CENTER
      } else if (
        value.horizontalScaling === FIXED &&
        horizontalPositioning === LEFT_AND_RIGHT
      ) {
        newValue.horizontalPositioning = LEFT
      }

      if (objectIsSectionElement && !userIsAdmin) {
        newValue.horizontalPositioning = CENTER
      }

      if (device) {
        newValue = { [key]: newValue }
        key = device
      }

      if (mobileOnly && object.mobile?.responsivity) {
        newValue = { [key]: newValue }
        key = 'mobile'
      }

      const deviceObject = getDeviceObject(object, device)
      dispatch(
        runInstructions([
          updateElement(deviceObject.id, { [key]: newValue }),
          updateElementMargins(deviceObject.id),
        ])
      )
    }
  }

  const getPositioningOptions = () => {
    if (horizontalScaling === SCALES_WITH_PARENT) {
      return [
        {
          label: 'Anchor Center',
          value: CENTER,
          rightIcon: (
            <LayoutOptionTooltip
              label="Anchor Center"
              animationData={SCALES_ANCHOR_CENTER}
              description="The left & right margins of the component will grow and shrink."
            />
          ),
        },
        {
          label: 'Anchor Left & Right',
          value: LEFT_AND_RIGHT,
          rightIcon: (
            <LayoutOptionTooltip
              label="Anchor Left & Right"
              animationData={SCALES_ANCHOR_LEFT_RIGHT}
              description="The left & right margins of the component will stay fixed."
            />
          ),
        },
      ]
    }

    return HORIZONTAL_POSITIONING_OPTIONS
  }

  const getResizingOptions = () => {
    const scaleWithParentLabel =
      componentParent.type === COMPONENT
        ? 'Scales with Screen'
        : 'Scales with Parent'

    const resizingOptions = [
      { label: scaleWithParentLabel, value: SCALES_WITH_PARENT },
      { label: 'Stays Fixed', value: FIXED },
    ]

    if (hasComponentParent) {
      resizingOptions[0].label = 'Scales with Group'
    }

    return resizingOptions
  }

  return (
    <>
      <MenuControl
        name="horizontalScaling"
        displayName="Width Resizing"
        options={getResizingOptions()}
        value={horizontalScaling}
        onChange={onChange}
        rowHeight={40}
        mobileOnly={mobileOnly}
      />
      {displayHorizontalPositioning && (
        <MenuControl
          name="horizontalPositioning"
          displayName="Where is the component anchored?"
          options={getPositioningOptions()}
          value={horizontalPositioning}
          onChange={onChange}
          rowHeight={40}
          mobileOnly={mobileOnly}
        />
      )}
      {device ? (
        <>
          <CommonInspectRow
            title="Custom Size &amp; Placement"
            className="size-placement-control"
            toggled={false}
          >
            <p>On</p>
          </CommonInspectRow>
          <div className="device-help-text">
            <CustomLayoutHelp />
          </div>
        </>
      ) : null}
    </>
  )
}

export default LayoutControls
