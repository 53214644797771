import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './TemplateSelect.css'
import { templateIdIsBlankType } from 'ducks/apps'

export default class TemplateSelect extends Component {
  static propTypes = {
    primaryPlatform: PropTypes.oneOf(['web', 'mobile', 'responsive'])
      .isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }

  handleClick = templateId => {
    const { input, disabled } = this.props

    if (input && !disabled) {
      input.onChange(templateId)
    }
  }

  render() {
    const { input, options, primaryPlatform, className, disabled } = this.props
    const value = input && input.value

    return (
      <>
        {input ? <input {...input} type="hidden" /> : null}
        <div
          className={classNames(
            'template-select',
            `template-select-${primaryPlatform}`,
            className
          )}
        >
          {options.map(template => {
            const isSelected = value === template.id
            const isBlank = templateIdIsBlankType(template.id)

            return (
              <div
                className={classNames('template-select-option', {
                  'template-select-option-selected': isSelected,
                  'template-select-option-blank': isBlank,
                  'template-select-option-disabled': disabled,
                })}
                onClick={() => this.handleClick(template.id)}
                key={template.id}
              >
                <div className="template-select-option-name">
                  {template.name}
                </div>
                <div
                  className={classNames('template-select-option-preview', {
                    'without-image': !template.preview,
                    blank: isBlank,
                  })}
                  style={{
                    backgroundImage: template.preview
                      ? `url('${template.preview}')`
                      : null,
                  }}
                />
                {isSelected ? (
                  <div className="template-select-option-selected-badge">
                    <i className="material-icons">check_circle</i>
                    <span>Selected</span>
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
      </>
    )
  }
}
