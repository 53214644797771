import { useContext } from 'react'
import styled from 'styled-components'
import TableThemeContext from './TableThemeContext'

import type { TableRowSeparatorPosition, TableRowSeparatorProps } from './types'

const Separator = styled('div')<{
  $tableRowLineColor: string
  $position: TableRowSeparatorPosition
}>`
  width: calc(100% - 2px);
  height: 1px;
  background-color: ${props => props.$tableRowLineColor};
  position: absolute;
  ${props => props.$position === 'top' && 'top: 0;'}
  ${props => props.$position === 'bottom' && 'bottom: 0;'}
  left: 1px;
`

const TableRowSeparator = ({
  position,
}: TableRowSeparatorProps): JSX.Element => {
  const { tableRowLineColor } = useContext(TableThemeContext)

  return (
    <Separator $tableRowLineColor={tableRowLineColor} $position={position} />
  )
}

export default TableRowSeparator
