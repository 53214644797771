import React, { Component } from 'react'

import { getDevModeEnabled, toggleDevMode } from '../../../utils/developers'

import ToggleButton from '../../Shared/Forms/ToggleButton'

export default class DeveloperSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      devModeEnabled: getDevModeEnabled(),
    }
  }

  handleToggle = () => {
    this.setState({ devModeEnabled: toggleDevMode() })
  }

  render() {
    const { devModeEnabled } = this.state

    return (
      <div className="developer-settings">
        <div className="developer-settings-row">
          <label>Enable developer mode for library development</label>
          <ToggleButton value={devModeEnabled} onChange={this.handleToggle} />
        </div>
      </div>
    )
  }
}
