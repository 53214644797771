import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
// Sentry.init has to be called in the somewhere before

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })

    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })

    Sentry.captureException(error)
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      //render fallback UI
      return <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
    }

    //when there's not an error, render children untouched
    return children
  }
}
