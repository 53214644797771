import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ModalError extends Component {
  render() {
    const { error, errorMessages } = this.props

    if (error) {
      console.error(error)

      return (
        <div className="modal-wrapper-error-message">
          {errorMessages.map(errorMessage => (
            <p key={errorMessage}>{errorMessage}</p>
          ))}
        </div>
      )
    }

    return <></>
  }
}

ModalError.propTypes = {
  error: PropTypes.any,
  errorMessages: PropTypes.array,
}

ModalError.defaultProps = {
  error: null,
  errorMessages: [],
}

export default ModalError
