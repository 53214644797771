import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { inflect } from 'inflection'

import { getSelectedPlanValue } from 'ducks/trialWarning'
import { getPricingPlans } from 'ducks/billing'
import { getCurrentOrganization } from 'ducks/organizations'

import { getPlanName, hasBusinessPlan } from 'utils/billing.ts'

import Modal from 'components/Shared/Modal'
import Icon, { IconButton } from 'components/Shared/Icon'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import {
  PlanChangeContext,
  PUBLISHED_APP_BY_PLAN,
  PRICE_PER_APP_ADDON,
  TEAM_MEMBERS_STEP,
  PAYMENT_STEP,
} from 'components/Shared/TrialWarning/PlanChangeContext'
import AppIcon from 'components/Shared/AppIcon'
import Tooltip from 'components/Shared/Tooltip'

import './SelectionModal.scss'

const PublishedApps = () => {
  const { name } = useSelector(getCurrentOrganization)
  const {
    appList,
    changeAppList,
    additionalPublishedApps,
    includedPublishedApps,
    appLimit,
    appOverage,
  } = useContext(PlanChangeContext)

  const renderApps = appsByDatasource =>
    Object.entries(appsByDatasource).map(([datasourceId, apps]) => {
      const [firstApp] = apps
      const datasourceIsPublished = apps.some(app => app.published)

      let toggleButton = (
        <ToggleButton
          value={datasourceIsPublished}
          onChange={() => changeAppList(datasourceId)}
          wrapped
        />
      )

      if (includedPublishedApps === appLimit && !datasourceIsPublished) {
        toggleButton = (
          <IconButton
            type="add-circle"
            onClick={() => changeAppList(datasourceId)}
          />
        )
      }

      if (apps.length > 1 || firstApp.hasSharedDatasource) {
        return (
          <div key={datasourceId} className="app-selection__shared">
            <div className="app-selection__shared-header app-selection__connected">
              <h4 className="app-selection__shared-header-title">
                Shared Database
              </h4>
              <div className="app-selection__toggle">{toggleButton}</div>
            </div>
            {apps.map(app => {
              return (
                <div
                  key={app.id}
                  className="app-selection__single-app app-selection__single-app--shared app-selection__connected"
                >
                  <AppIcon medium app={app} />
                  <h2 className="selection-modal__header selection-modal__header--light">
                    {app.name}
                  </h2>
                  {datasourceIsPublished ? (
                    <Icon
                      type="done-all"
                      color="green"
                      iconStyle="circle"
                      className="app-selection__published-icon"
                    />
                  ) : null}
                </div>
              )
            })}
          </div>
        )
      }

      return (
        <div key={firstApp.id} className="app-selection__single">
          <div className="app-selection__single-app">
            <AppIcon medium app={firstApp} />
            <h2 className="selection-modal__header selection-modal__header--light">
              {firstApp.name}
            </h2>
            {datasourceIsPublished ? (
              <Icon
                type="done-all"
                color="green"
                iconStyle="circle"
                className="app-selection__published-icon"
              />
            ) : null}
          </div>
          {toggleButton}
        </div>
      )
    })

  return (
    <div className="new-plan-selection-content">
      <div className="new-plan-selection-content__header">
        <h2 className="selection-modal__header">Apps in {name}</h2>

        <div className="selection-modal__header--right">
          <h2 className="selection-modal__header">Mark as Published</h2>
          <Tooltip
            tooltip={
              <span>
                Apps marked as published will give you access to existing apps'
                build history and will allow you to submit your apps to the App
                Stores (Apple or Google) and/or custom domains.{' '}
                <a
                  href="https://help.adalo.com/resources/adalo-pricing-faqs#what-is-a-published-app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </a>
              </span>
            }
          >
            <Icon type="help-small" small />
          </Tooltip>
        </div>
      </div>
      <div className="app-selection">{renderApps(appList)}</div>
      <div className="new-plan-selection-content__count">
        <div className="new-plan-selection-content__count-row">
          <h3 className="selection-modal__subheader">
            Published Apps Within Plan
          </h3>
          <h3 className="selection-modal__subheader">
            {includedPublishedApps} out of {appLimit}
          </h3>
        </div>
        <br />
        <div className="new-plan-selection-content__count-row">
          <h3 className="selection-modal__subheader">{`Additional Published Apps ($${PRICE_PER_APP_ADDON.toLocaleString(
            'en-US'
          )} each per month)`}</h3>
          <h3 className="selection-modal__subheader">
            {additionalPublishedApps}
          </h3>
        </div>
      </div>
      <div className="new-plan-selection-content__total">
        <h2 className="selection-modal__header">Monthly Sub-Total:</h2>
        <h2 className="selection-modal__header">
          ${(appOverage * PRICE_PER_APP_ADDON).toLocaleString('en-US')}
        </h2>
      </div>
    </div>
  )
}

const PublishedAppSelectionModal = ({
  goToPlanSelection,
  isCurrentlyLegacyPlan,
}) => {
  const selectedPlan = useSelector(getSelectedPlanValue)

  const { setCurrentStep, teamMemberOverage, appLimit, currentPublishedApps } =
    useContext(PlanChangeContext)

  const pricingPlans = useSelector(getPricingPlans)
  const planName = getPlanName(selectedPlan, pricingPlans)

  const onClickBack = () => {
    goToPlanSelection()
  }

  const onClickNext = () => {
    if (teamMemberOverage > 0 && !hasBusinessPlan(selectedPlan)) {
      setCurrentStep(TEAM_MEMBERS_STEP)
    } else {
      setCurrentStep(PAYMENT_STEP)
    }
  }

  useEffect(() => {
    if (currentPublishedApps <= appLimit && !isCurrentlyLegacyPlan) {
      setCurrentStep(TEAM_MEMBERS_STEP)
    }
  }, [])

  return (
    <Modal size="lg" className="selection-modal">
      <Modal.Header
        title={`${planName}: Select Your Published Apps`}
        content={() => (
          <Modal.Button
            to="https://adalo.com/pricing"
            iconSide="right"
            iconSize="small"
            target="_blank"
            icon="open-in-new"
            placement="right"
            teal
            text
          >
            Learn more
          </Modal.Button>
        )}
      />

      <Modal.Content>
        <div className="new-plan-selection-header">
          <p className="new-plan-selection-header__description">
            {`The ${planName} Plan has ${
              PUBLISHED_APP_BY_PLAN[planName]
            } published ${inflect(
              'app',
              PUBLISHED_APP_BY_PLAN[planName]
            )}. Publish all apps that are being used by end-users on native iOS, native Android, and custom web domains. All other apps will be considered test apps.`}
          </p>
        </div>
        <PublishedApps />
      </Modal.Content>

      <Modal.Actions>
        <Modal.Button type="button" text onClick={onClickBack}>
          Back
        </Modal.Button>

        <Modal.Button large type="submit" onClick={onClickNext}>
          Next
        </Modal.Button>
      </Modal.Actions>
    </Modal>
  )
}

export default PublishedAppSelectionModal
