import React from 'react'
import { Field, FieldArray, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { NakedBindableTextControl } from 'components/Shared/BindableTextControl'
import ControlField from 'components/Shared/Forms/ControlField'

import AuthFields from './AuthFields'
import Inputs from './Inputs'

import './APIRequest.scss'

const methodOptions = [
  { label: 'POST', value: 'post' },
  { label: 'GET', value: 'get' },
  { label: 'PUT', value: 'put' },
  { label: 'PATCH', value: 'patch' },
  { label: 'DELETE', value: 'delete' },
]

const buildInputs = inputs => {
  inputs = inputs || []
  //TODO: implement "New Input..."

  return inputs
}

export default function APIRequest(props) {
  const { formName } = props
  const selector = formValueSelector(formName)
  const inputs = useSelector(state => selector(state, 'inputs'))

  return (
    <div className="api-request">
      <div className="api-request-values">
        <div className="api-request-url">
          <ControlField
            disableFormulas
            name="baseURL"
            label="API Base URL"
            placeholder="https://api.example.com/v1/action"
            component={NakedBindableTextControl}
            disableChip
            bindingOptions={() => buildInputs(inputs)}
            getLabel={entity => `${entity.label}`}
            autoComplete="off"
          />
        </div>
        <div className="api-request-method">
          <label>Method</label>
          <Field
            name="method"
            placeholder="Select a Method"
            component={WrappedSelect}
            rowHeight={50}
            options={methodOptions}
            autoComplete="off"
          />
        </div>
        <div className="api-request-headers">
          <FieldArray
            name="auth"
            component={AuthFields}
            bindingOptions={() => buildInputs(inputs)}
          />
        </div>
        <div className="api-request-body">
          <ControlField
            disableFormulas
            name="body"
            label="Body"
            placeholder="Enter JSON here"
            component={NakedBindableTextControl}
            disableChip
            bindingOptions={() => buildInputs(inputs)}
            getLabel={entity => `${entity.label}`}
            className="api-request-body"
            autoComplete="off"
          />
        </div>
      </div>
      <div className="api-request-inputs">
        <FieldArray name="inputs" component={Inputs} />
      </div>
    </div>
  )
}
