import {
  ELLIPSE,
  FORM,
  LINE,
  LOCATION_INPUT,
  SECTION,
  TYPE_OPTIONS,
} from '@adalo/constants'
import ObjectType from 'ducks/editor/types/ObjectType'
import { isContainerSectionElement } from './layoutSections'
import { EditorObject } from './responsiveTypes'

const TYPE_NAMES = TYPE_OPTIONS.reduce(
  (acc, option) => ({
    ...acc,
    [option.value]: option.label,
  }),
  {}
) as Record<ObjectType, string>

TYPE_NAMES[SECTION] = 'Shape'
TYPE_NAMES[LINE] = 'Shape'
TYPE_NAMES[ELLIPSE] = 'Shape'
TYPE_NAMES[FORM] = 'Form'
TYPE_NAMES[LOCATION_INPUT] = 'Location Input'

export const getTypeNameForObject = (object: EditorObject): string => {
  if (isContainerSectionElement(object)) {
    return 'Section Container'
  }

  return TYPE_NAMES[object.type]
}
