import React from 'react'
import classNames from 'classnames'

import './FormRow.css'

export const FormRow = ({ children, className }) => (
  <div className={classNames('form-row', className)}>{children}</div>
)

export const Input = ({ children, className }) => (
  <div className={classNames('form-row-input', className)}>{children}</div>
)
