import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { useFlags } from 'flags'

import {
  getOrganizations,
  fetchOrganizations,
  fetchOrganization,
  setCurrentOrganization,
} from 'ducks/organizations'

import { getApp, getLastApp } from 'ducks/apps'
import {
  showModal,
  hideModal,
  getModalVisible,
  getAnnualToggle,
  fetchSubscription,
  cancelSubscription,
  getSubscription,
  setAnnualFlag,
  setUpdateCardFlag,
  setUpdatePaymentSettingsFlag,
  getUpdatePaymentSettingsFlag,
  setPaymentRouteType,
  setPlanSelectedFlag,
  setSelectedPlanValue,
  setSwitchToYearlyFlag,
  reset,
  getSwitchToYearlyFlag,
} from 'ducks/trialWarning'
import { getCurrentUser } from 'ducks/users/index.ts'
import {
  showModal as showEditorModal,
  CANCEL_SUBSCRIPTION_MODAL,
} from 'ducks/editor/modals'

import { requestApp } from 'utils/io'
import {
  createStripeCustomerPortal,
  isLegacyPlanType,
  isPaidPlan,
} from 'utils/billing.ts'

import BillingSummary from './BillingSummary'
import FreeTrialBanner from './FreeTrialBanner'
import TrialModal from './TrialModal'
import '../../Settings/Billing/Billing.scss'
import history from '../../../history'

function TrialWarning(props) {
  const [formVisible, setFormVisible] = useState(false)

  // TODO(itsdaiego): this component should not be fetching organization data for the entire editor
  useEffect(() => {
    const {
      fetchOrganizations,
      organizationId,
      fetchSubscription,
      currentUser,
      fetchOrganization,
      app,
    } = props

    fetchOrganizations()
    fetchSubscription(organizationId)

    const userBelongsToAppOrganization = getOrganization()

    // admin users need organization data for the app they are viewing
    if (!userBelongsToAppOrganization && currentUser.admin && app) {
      setCurrentOrganization(app.OrganizationId)
      fetchOrganization(app.OrganizationId)
    }
  }, [])

  const dispatch = useDispatch()

  const fetchData = async () => {
    const { fetchOrganizations, match } = props
    const { appId } = match.params
    await requestApp(appId)
    fetchOrganizations()
  }

  const getOrganization = () => {
    const { organizations, app } = props

    if (app && app.OrganizationId) {
      return organizations.filter(org => {
        return app.OrganizationId === org.id
      })[0]
    }

    return undefined
  }

  const handleShowModal = () => {
    const { showModal, setPaymentRouteType } = props
    setPaymentRouteType('upgrade')
    showModal()
  }

  const handleCloseModal = () => {
    const {
      match,
      hideModal,
      setPaymentRouteType,
      setPlanSelectedFlag,
      setSelectedPlanValue,
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setSwitchToYearlyFlag,
      lastAppId,
    } = props

    setPaymentRouteType('billing')
    setPlanSelectedFlag(false)
    setSelectedPlanValue('')
    setUpdateCardFlag(false)
    hideModal()
    setUpdatePaymentSettingsFlag(false)
    setSwitchToYearlyFlag(false)
    const appId = match.params.appId || lastAppId

    if (appId) {
      history.push(`/apps/${appId}/screens`)
    } else {
      history.push(`/`)
    }
  }

  const showPaymentSettings = () => {
    const {
      subscription,
      setAnnualFlag,
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setSelectedPlanValue,
    } = props

    const { planInterval, planType: subscriptionPlanType } = subscription

    let planType = subscriptionPlanType

    if (!planType) {
      // Org currently has no stripe subscription. Read the plan from the org.
      const organization = getOrganization()
      planType = organization?.planType
    }

    if (planInterval === 'month') {
      setAnnualFlag(false)
    } else {
      setAnnualFlag(true)
    }

    setSelectedPlanValue(planType)
    setUpdateCardFlag(true)
    setUpdatePaymentSettingsFlag(true)
  }

  const handleSwitchToYearly = () => {
    const {
      setAnnualFlag,
      setSwitchToYearlyFlag,
      subscription,
      setSelectedPlanValue,
    } = props

    const { planType } = subscription
    setAnnualFlag(true)
    setSwitchToYearlyFlag(true)
    setSelectedPlanValue(planType)
  }

  const toggleForm = () => {
    const { updatePaymentSettings, setUpdatePaymentSettingsFlag } = props

    const { planType } = getOrganization() || {}

    const showNewPlanSelect = !isLegacyPlanType(planType)

    if (showNewPlanSelect) {
      // Launch the modal with the new plans
      handleShowModal()

      return
    }

    const showForm = !(formVisible || updatePaymentSettings)
    setUpdatePaymentSettingsFlag(false)
    setFormVisible(showForm)
  }

  const handleClickBackChangePlan = () => {
    const {
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setPlanSelectedFlag,
      setSelectedPlanValue,
    } = props

    setFormVisible(false)
    setUpdatePaymentSettingsFlag(false)
    setUpdateCardFlag(false)
    setPlanSelectedFlag(false)
    setSelectedPlanValue('')
  }

  const customerPortalRedirect = async () => {
    const { organizationId, appId } = props

    await createStripeCustomerPortal(organizationId, appId)
  }

  const cancelSubscription = async () => {
    const { organizationId, appId, cancelSubscription, hideModal, history } =
      props

    const { value: shouldCancelSubscription } = await dispatch(
      showEditorModal(CANCEL_SUBSCRIPTION_MODAL)
    )
    if (!shouldCancelSubscription) {
      return
    }

    await cancelSubscription(organizationId)

    setFormVisible(false)
    await fetchData()

    hideModal()

    if (!appId) {
      history.push(`/`)
    }
  }

  const renderSummary = () => {
    const { subscription } = props
    const organization = getOrganization()

    return (
      <BillingSummary
        organization={organization}
        subscription={subscription}
        onShowPaymentSettings={showPaymentSettings}
        onToggleForm={toggleForm}
        onCustomerPortalRedirect={customerPortalRedirect}
        onSwitchToYearly={handleSwitchToYearly}
      />
    )
  }

  const renderForm = () => {
    const {
      fetchOrganizations,
      appId,
      subscription,
      annual,
      organizationId,
      billing,
    } = props

    const planType =
      billing || isPaidPlan(subscription?.planType)
        ? subscription?.planType
        : 'pro'

    return (
      <TrialModal
        onKeepOpen={handleShowModal}
        onClose={handleCloseModal}
        organizationId={organizationId}
        forceRefresh={fetchOrganizations}
        appId={appId}
        initialValues={{ plan: planType, annual }}
        onDowngrade={cancelSubscription}
        onClickBackChangePlan={handleClickBackChangePlan}
      />
    )
  }

  const {
    appId,
    modalVisible,
    subscription,
    billing,
    updatePaymentSettings,
    switchToYearly,
    render,
  } = props

  const hasSubscription =
    subscription && subscription.configured && isPaidPlan(subscription.planType)

  const { hasViewAppMenu } = useFlags()

  const organization = getOrganization()
  const showBanner = organization && !organization.active && !hasViewAppMenu

  const hasPaidPlan = hasSubscription || isPaidPlan(organization?.planType)

  const showForm =
    !hasPaidPlan || formVisible || updatePaymentSettings || switchToYearly

  if (render) {
    // Hacky render prop to reuse the logic for showing & handling the PlanSelectModal
    return render({
      handleShowModal,
    })
  }

  return (
    <div>
      {billing ? (
        <div className="team-settings-billing">
          {showForm ? renderForm() : renderSummary()}
        </div>
      ) : (
        <div className="trial-warning">
          {showBanner && (
            <FreeTrialBanner appId={appId} onClick={handleShowModal} />
          )}
          {modalVisible && renderForm()}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, { match, organizationId }) => ({
  appId: match.params.appId,
  app: getApp(state, match.params.appId),
  lastAppId: getLastApp(state),
  organizations: getOrganizations(state),
  subscription: getSubscription(state, organizationId),
  modalVisible: getModalVisible(state),
  annual: getAnnualToggle(state),
  updatePaymentSettings: getUpdatePaymentSettingsFlag(state),
  switchToYearly: getSwitchToYearlyFlag(state),
  currentUser: getCurrentUser(state),
})

const connected = connect(mapStateToProps, {
  fetchSubscription,
  cancelSubscription,
  fetchOrganizations,
  getOrganizations,
  showModal,
  hideModal,
  setAnnualFlag,
  setUpdateCardFlag,
  setUpdatePaymentSettingsFlag,
  setPaymentRouteType,
  setPlanSelectedFlag,
  setSelectedPlanValue,
  setSwitchToYearlyFlag,
  reset,
  fetchOrganization,
  setCurrentOrganization,
})(TrialWarning)

export default withRouter(connected)
