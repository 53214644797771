import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import DocumentEvents from 'react-document-events'

import { ESC } from '../../../utils/keyboard'
import Icon from '../../Shared/Icon'

class CloseButton extends Component {
  handleKeyDown = e => {
    const { history } = this.props

    if (e.which === ESC) {
      history.push(this.getBackURL())
    }
  }

  getBackURL = () => {
    const { appId } = this.props

    return `/apps/${appId}/screens`
  }

  render() {
    const backURL = this.getBackURL()

    return (
      <Link to={backURL} className="editor-preview-close">
        <DocumentEvents onKeyDown={this.handleKeyDown} />
        <Icon type="close" />
      </Link>
    )
  }
}

export default withRouter(CloseButton)
