import { DeviceWidth, LIST, listTypes } from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import { EditorObject, ListTypes } from 'utils/responsiveTypes'
import { decreaseWidth, increaseWidth } from 'utils/objects/listControl'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import getObject from '../objects/helpers/getObject'
import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'
import { resizeScreenHandler } from './resizeScreen'

import DeviceType from '../types/DeviceType'
import { updateElementMarginsHandler } from './updateElementMargins'

const SCREEN_SIZES = {
  mobile: DeviceWidth.MOBILE_DEFAULT_WIDTH,
  tablet: DeviceWidth.TABLET_DEFAULT_WIDTH,
  desktop: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
}

export interface UpdateCustomListDeviceColumnsOptions {
  objectId: string
  deviceType: DeviceType
  columnCount: number
  rowMargin: number
}

export interface UpdateCustomListDeviceColumnsInstruction {
  operation: 'updateCustomListDeviceColumns'
  options: UpdateCustomListDeviceColumnsOptions
}

export const updateCustomListDeviceColumnsHandler = (
  state: InstructionState,
  options: UpdateCustomListDeviceColumnsOptions
): InstructionState => {
  const { list, pathMap } = state
  const { objectId, columnCount, rowMargin, deviceType } = options

  let updatedList = [...list]
  const oldObject: EditorObject = getObject(updatedList, pathMap, objectId)
  if (oldObject.type !== LIST) {
    throw new Error(
      `Cannot run this instruction on object type: ${oldObject.type}`
    )
  }

  // Change the screen width to match the column/spacing device being changed
  // so that the user can see the changes they are making
  // If the user is already viewing that device, don't change the screen width
  const screenObj = getParentScreen(list, pathMap, objectId)
  if (!screenObj) {
    throw new Error(`Could not find screen for object ${objectId}`)
  }

  const currentDeviceType = getDeviceType(screenObj.width)
  if (currentDeviceType !== deviceType) {
    ;({ list: updatedList } = resizeScreenHandler(state, {
      screenId: screenObj.id,
      width: SCREEN_SIZES[deviceType],
      height: screenObj.height,
    }))
  }

  const updatedObject = getObject(updatedList, pathMap, objectId)

  if (updatedObject.shared?.[deviceType]) {
    throw new Error(`Cannot change shared device: ${deviceType}`)
  }

  const deviceColumns = updatedObject.deviceColumns?.[deviceType]
  if (!deviceColumns) {
    throw new Error(`Unknown device: ${deviceType}`)
  }

  const validListTypes = new Set<ListTypes>(Object.values(listTypes))
  if (!deviceColumns.listType || !validListTypes.has(deviceColumns.listType)) {
    throw new Error(`Unknown listType: ${deviceColumns.listType as string}`)
  }

  if (
    !Number.isInteger(deviceColumns.columnCount) ||
    typeof deviceColumns.columnCount !== 'number'
  ) {
    throw new Error(
      `Existing deviceColumns.columnCount is not an integer or numeric: ${
        deviceColumns.columnCount as number
      }`
    )
  }

  if (
    !Number.isInteger(deviceColumns.rowMargin) ||
    typeof deviceColumns.rowMargin !== 'number'
  ) {
    throw new Error(
      `Existing deviceColumns.rowMargin is not an integer or numeric: ${
        deviceColumns.rowMargin as number
      }`
    )
  }

  const modifier =
    columnCount > deviceColumns.columnCount ? increaseWidth : decreaseWidth

  updatedList = updateChangedObject(
    updatedList,
    pathMap,
    {
      ...updatedObject,
      deviceColumns: {
        ...updatedObject.deviceColumns,
        [deviceType]: {
          ...deviceColumns,
          columnCount,
          rowMargin,
          listType:
            columnCount == null || columnCount <= 1
              ? listTypes.DEFAULT
              : listTypes.GRID,
        },
      },
      [deviceType]: {
        ...updatedObject[deviceType],
        ...(updatedObject[deviceType]?.width &&
          columnCount !== deviceColumns.columnCount && {
            width: modifier(
              updatedObject[deviceType] as EditorObject,
              columnCount,
              {
                columnCount: deviceColumns.columnCount,
                rowMargin: deviceColumns.rowMargin,
              }
            ),
          }),
      },
    },
    undefined
  )

  // changing column size causes the list width to increase or decrease
  // we need to additionally re-calculate the left and right property of the list
  // to match its new width in relation to the screen
  return updateElementMarginsHandler(
    {
      ...state,
      list: updatedList,
    },
    {
      objectId,
    }
  )
}

const updateCustomListDeviceColumns = (
  objectId: string,
  columnCount: number,
  rowMargin: number,
  deviceType: DeviceType
): UpdateCustomListDeviceColumnsInstruction => ({
  operation: 'updateCustomListDeviceColumns',
  options: {
    objectId,
    columnCount,
    rowMargin,
    deviceType,
  },
})

export default updateCustomListDeviceColumns
