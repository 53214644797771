import React from 'react'
import { imageResize, imageTypes } from '@adalo/constants'

import { scaleValue } from 'utils/zoom'
import { assetURL } from 'utils/assets'

import Section from '../Section'
import Shadow from '../Shadow'

import VideoIcon from './magic-video.svg'
import VideoControlLeft from './video-control-left.svg'
import VideoControlRight from './video-control-right.svg'
import GroupWrapper from '../Group/GroupWrapper'

export default class Video extends Section {
  getMaskId() {
    const { id } = this.props

    return `image-mask-${id}`
  }

  getURL = () => {
    const { imageType, imageId, videoBinding, imageURL, filename1x } =
      this.props

    const order = ['2x', '1x', '3x']

    let filename = null

    if (filename1x && filename1x.url && imageType === imageTypes.UPLOADED) {
      filename = filename1x.url

      return assetURL(filename)
    }

    // placeholder image
    let placeholderImageEnabled = null
    let placeholderImage = null

    if (
      videoBinding &&
      typeof videoBinding.options !== 'undefined' &&
      !filename1x
    ) {
      const { options } = videoBinding

      if (typeof options.placeholderImageEnabled !== 'undefined') {
        placeholderImageEnabled = options.placeholderImageEnabled
      }

      if (typeof options.placeholderImage !== 'undefined') {
        placeholderImage = options.placeholderImage
      }
    }

    if (imageId) {
      filename = `${imageId}@2x.png`
    } else if (placeholderImageEnabled) {
      filename = placeholderImage
    } else if (!videoBinding && filename1x) {
      for (const size of order) {
        const key = `filename${size}`

        if (this.props[key]) {
          filename = this.props[key]

          break
        }
      }
    }

    if (imageType === 'url' && imageURL) {
      // check if url is magic text
      if (Array.isArray(imageURL)) return null

      return imageURL
    }

    if (!filename) return null

    return assetURL(filename)
  }

  renderPlaceholder() {
    const {
      id,
      zoom,
      width,
      height,
      widthScaled,
      heightScaled,
      xScaled,
      yScaled,
      editorResizingProps,
    } = this.props

    const thumbnailImage = this.getURL()
    const opacityModifier = thumbnailImage ? 0.15 : 0

    const minDim = Math.min(width, height)
    const size = Math.max(16, Math.min(80, minDim * 0.7))

    const iconWidth = scaleValue(size, zoom)
    const iconHeight = scaleValue(size, zoom)

    let imageX = '50%'
    let wrapperX = 0
    if (editorResizingProps?.relativeWidth) {
      wrapperX = -iconWidth / 2
    } else {
      imageX = widthScaled / 2 - iconWidth / 2
    }

    return (
      <svg x={wrapperX} y="0" width="100%" height="100%" overflow="visible">
        <g transform={`translate(${xScaled}, ${yScaled})`}>
          <image
            href={VideoIcon}
            width={iconWidth}
            height={iconHeight}
            x={imageX}
            y={heightScaled / 2 - iconHeight / 2}
            clipPath={`url(#clip-${id})`}
            style={{ opacity: 0.35 + opacityModifier }}
          />
        </g>
      </svg>
    )
  }

  renderControls() {
    const {
      id,
      zoom,
      width,
      height,
      widthScaled,
      heightScaled,
      xScaled,
      yScaled,
      editorResizingProps,
      object,
    } = this.props

    if (
      object &&
      typeof object.videoControls === 'boolean' &&
      object.videoControls === false
    ) {
      return null
    }

    const minDim = Math.min(width, height)
    const size = Math.max(16, Math.min(32, minDim * 0.7))

    const iconWidth = scaleValue(size, zoom)
    const iconHeight = scaleValue(size, zoom)

    const wrapperX = editorResizingProps?.relativeWidth ? -iconWidth / 2 : 0

    if (heightScaled < iconHeight * 3) {
      return null
    }

    const thumbnailImage = this.getURL()
    const opacityModifier = thumbnailImage ? 0.15 : 0

    const displayRightControls = iconHeight * 3.125 + iconWidth < widthScaled

    return (
      <svg x={wrapperX} y="0" width="100%" height="100%" overflow="visible">
        <g transform={`translate(${xScaled}, ${yScaled})`}>
          <image
            href={VideoControlLeft}
            width={iconWidth}
            height={iconHeight}
            x={2}
            y={heightScaled - iconHeight * 1.5}
            clipPath={`url(#clip-controls-left-${id})`}
            style={{ opacity: 0.35 + opacityModifier }}
          />
          {displayRightControls && (
            <image
              href={VideoControlRight}
              width={iconHeight * 3.125}
              height={iconHeight}
              x={widthScaled - iconHeight * 3.125 - 3}
              y={heightScaled - iconHeight * 1.5}
              clipPath={`url(#clip-controls-right-${id})`}
              style={{ opacity: 0.35 + opacityModifier }}
            />
          )}
          <rect
            x={5}
            y={heightScaled - iconHeight * 0.4}
            width={widthScaled - 10}
            height={1.5}
            rx={1}
            ry={1}
            opacity={0.15 + opacityModifier}
          />
        </g>
      </svg>
    )
  }

  render() {
    let {
      id,
      width,
      height,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      borderRadius,
      opacity,
      zoom,
      object,
      editorResizingProps,
      shadow,
      children,
    } = this.props

    borderRadius = Math.min(borderRadius, height / 2)
    borderRadius = Math.min(borderRadius, width / 2)
    borderRadius = scaleValue(borderRadius || 0, zoom)

    const borderProps = this.getBorderProps()
    const backgroundColor = this.getBackgroundColor()
    const maskId = this.getMaskId()

    const thumbnailImage = this.getURL()

    const rectProps = {
      x: xScaled,
      y: yScaled,
      width: widthScaled,
      height: heightScaled,
      rx: borderRadius,
      ry: borderRadius,
    }

    return (
      <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
        <g className="group" style={{ opacity }}>
          <g
            className="image"
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDoubleClick}
          >
            <defs>
              <clipPath id={`clip-${id}`}>
                <rect {...rectProps} x={0} y={0} />
              </clipPath>
            </defs>
            <mask id={maskId}>
              <rect {...rectProps} fill="#fff" stroke="none" />
            </mask>
            {shadow?.enabled === true && (
              <g transform={`translate(${xScaled}, ${yScaled})`}>
                <Shadow {...this.getShadowProps()}>
                  <rect
                    {...rectProps}
                    {...borderProps}
                    x={0}
                    y={0}
                    fill={backgroundColor}
                  />
                </Shadow>
              </g>
            )}
            <rect {...rectProps} fill={backgroundColor} stroke="none" />
            <rect {...rectProps} {...borderProps} fill={'#eaeaea'} />
            {!!thumbnailImage && (
              <g mask={`url(#${maskId})`}>
                <image
                  href={thumbnailImage}
                  x={xScaled}
                  y={yScaled}
                  width={widthScaled}
                  height={heightScaled}
                  preserveAspectRatio={`xMidYMid ${
                    this.props.imageResize === imageResize.CONTAIN
                      ? 'meet'
                      : 'slice'
                  }`}
                />
              </g>
            )}
            {this.renderPlaceholder()}
            {this.renderControls()}
          </g>

          {children}
        </g>
      </GroupWrapper>
    )
  }
}
