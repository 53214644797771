import { DeviceType, LAYOUT_SECTION } from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'

// When we're doing a performCreate or adding an object from the sidebar,
// if the base object we're working with is a layout section with a children (layout helper),
// that has a children(container), that has at least one other children, then it's not the
// default layout empty section, it's a prebuilt layout section.
export const isPrebuiltLayoutSection = (object: EditorObject): boolean =>
  object.type === LAYOUT_SECTION &&
  (object?.children?.[0]?.children?.[0]?.children?.length ?? 0) > 0

const devices = [DeviceType.DESKTOP, DeviceType.TABLET, DeviceType.MOBILE]

// When working with prebuilt sections we've found that some have set a very big shared height (>2000)
// when they have all shared settings off. This is causing ghosts to be offset from the cursor position.
// To work around that, we choose to consider the largest device-specific height for the ghost snapping when
// dealing with a prebuilt layout section with no shared layout.
// This is an ugly patch.

type GhostSnapDimensions = { width: number; height: number }

export const getLayoutSectionDimensionsForGhostSnap = (
  object: EditorObject
): GhostSnapDimensions => {
  const { height: sharedHeight, width: sharedWidth } = object

  if (!isPrebuiltLayoutSection(object)) {
    return { height: sharedHeight, width: sharedWidth }
  }

  // if nothing is shared, check for the largest height to return
  if (
    object.shared &&
    !object.shared.mobile &&
    !object.shared.tablet &&
    !object.shared.desktop
  ) {
    const heights = []
    const widths = []
    for (const device of devices) {
      const deviceHeight = object[device]?.height
      const deviceWidth = object[device]?.width

      if (deviceHeight !== undefined) {
        heights.push(deviceHeight)
      }
      if (deviceWidth !== undefined) {
        widths.push(deviceWidth)
      }
    }

    const height = heights.length > 0 ? Math.max(...heights) : sharedHeight
    const width = widths.length > 0 ? Math.max(...widths) : sharedWidth

    return { height, width }
  }

  return { height: sharedHeight, width: sharedWidth }
}
