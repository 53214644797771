import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { backgroundStyle } from '@adalo/constants'

import ToggleButton from 'components/Shared/Forms/ToggleButton'

import { isEditableSectionElement } from 'utils/layoutSections'

import { updateObject } from 'ducks/editor/objects'
import { getAppBranding } from 'ducks/apps'

import ColorDropdown from './ColorDropdown'
import CommonInspectRow from './GenericRow'

class BackgroundControl extends Component {
  static propTypes = {
    branding: PropTypes.shape({}).isRequired,
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    updateObject: PropTypes.func.isRequired,
    boxedToggle: PropTypes.bool,
  }

  handleChange = values => {
    const { object, updateObject } = this.props
    updateObject(object.id, values)
  }

  handleToggle = isEnabled => {
    const style = isEnabled ? backgroundStyle.COLOR : backgroundStyle.NONE
    this.handleChange({ backgroundStyle: style })
  }

  render() {
    const { branding, object, boxedToggle } = this.props
    const layoutSection = isEditableSectionElement(object)

    const isEnabled =
      object.backgroundStyle === backgroundStyle.COLOR ||
      (layoutSection && object.backgroundStyle === backgroundStyle.IMAGE)

    const getTitle = () => {
      if (layoutSection) {
        if (object.backgroundStyle === backgroundStyle.IMAGE) {
          return 'Image Background Color'
        }

        return 'Background Color'
      } else {
        return 'Background'
      }
    }

    const title = getTitle()

    return (
      <CommonInspectRow
        className="background-control"
        {...(!boxedToggle && {
          title,
          togglable: !layoutSection,
          toggled: isEnabled,
          onToggle: this.handleToggle,
        })}
      >
        {boxedToggle && (
          <CommonInspectRow title="Background" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {isEnabled ? 'On' : 'Off'}
              <ToggleButton value={isEnabled} onChange={this.handleToggle} />
            </div>
          </CommonInspectRow>
        )}
        {(!boxedToggle || isEnabled) && (
          <ColorDropdown
            branding={branding}
            value={object.backgroundColor}
            name="backgroundColor"
            disabled={!isEnabled}
            onChange={this.handleChange}
            label={boxedToggle && 'Background Color'}
          />
        )}
      </CommonInspectRow>
    )
  }
}

const mapStateToProps = (state, { appId }) => ({
  branding: getAppBranding(state, appId),
})

export default connect(mapStateToProps, { updateObject })(BackgroundControl)
