import userflow from 'userflow.js'
import { decode as decodeJwt, JwtPayload } from 'jsonwebtoken'

export const parseJWT = (token: string | null): JwtPayload | null => {
  if (!token) {
    return null
  }
  try {
    const tokenPayload = decodeJwt(token) as JwtPayload

    const now = Math.floor(Date.now() / 1000)
    if (!tokenPayload.exp || now < tokenPayload.exp) {
      return tokenPayload
    }
  } catch (err: unknown) {
    const originalErrorMessage =
      err && err instanceof Error ? err.message : 'Unknown error'

    console.warn(`Invalid auth token: ${originalErrorMessage}`)
  }

  return null
}
interface MakerTokenPayload extends JwtPayload {
  userId: number
  email: string
  admin?: boolean | undefined
  developer?: boolean | undefined
}
let inMemoryToken: string | null = null

const hasLocalStorage = (): boolean => {
  try {
    return !!window.localStorage
  } catch (e) {
    return false
  }
}

export const getAuthToken = (): string | null => {
  let token: string | null
  if (hasLocalStorage()) {
    token = window.localStorage.getItem('protonSession')
  } else {
    token = inMemoryToken
  }

  const makerToken = parseJWT(token)
  if (!makerToken) {
    return null
  }

  return token
}

const parseMakerToken = (): MakerTokenPayload | null => {
  const makerToken = getAuthToken()

  return parseJWT(makerToken) as MakerTokenPayload | null
}

export const getMakerUserId = (): number | null => {
  const makerToken = parseMakerToken()

  if (!makerToken) {
    return null
  }

  return makerToken.userId
}

export const isMakerDeveloper = (): boolean => {
  const makerToken = parseMakerToken()

  if (!makerToken) {
    return false
  }

  return !!makerToken.developer
}

export const storeMakerToken = (token: string): void => {
  if (hasLocalStorage()) {
    window.localStorage.setItem('protonSession', token)
  } else {
    inMemoryToken = token
  }
}

export const logoutMaker = (): void => {
  userflow.reset()
  if (hasLocalStorage()) {
    window.localStorage.removeItem('protonSession')
  } else {
    inMemoryToken = null
  }
}
