import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updateObject } from 'ducks/editor/objects'

import ActionRow from './Libraries/ActionRow'

class ScreenAction extends Component {
  handleChange = (changes, actionChanges) => {
    const { updateObject, object } = this.props
    const newObject = { ...changes, componentActions: actionChanges }

    updateObject(object.id, newObject)
  }

  render() {
    const { object } = this.props

    if (!object) {
      return null
    }

    const { onVisit } = object

    return (
      <ActionRow
        objectId={object.id}
        displayName="When a user visits the screen..."
        name="onVisit"
        onChange={this.handleChange}
        value={onVisit}
        actionsKey="componentActions"
      />
    )
  }
}

export default connect(null, { updateObject })(ScreenAction)
