import React, { forwardRef } from 'react'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import {
  dataTypes as DT,
  formatterOptions,
  richObjectDataTypes,
} from '@adalo/constants'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

// components
import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'
import Icon, { IconButton } from 'components/Shared/Icon'
import { addLabelToOptions } from 'components/Shared/BindableTextControl/BindableTextControl'

// ducks
import { getLibraryBindingSuggestions, MenuOption } from 'ducks/recommender'

// utils
import { buildFormula } from 'utils/formulas'

import { THEMES } from '../../../../../constants'

const allowed = [DT.TEXT, DT.NUMBER, DT.DATE, DT.DATE_ONLY]

const MagicText = forwardRef((props, ref) => {
  const {
    actionId,
    appId,
    componentId,
    objectId,
    binding,
    disableFormulas,
    inputName,
    helpers,
  } = props

  const bindingOptions = useSelector(state =>
    getLibraryBindingSuggestions(
      state,
      appId,
      componentId,
      objectId,
      allowed,
      binding,
      actionId,
      inputName,
      helpers
    )
  )

  const getOptions = () => () => {
    const options = bindingOptions() || []

    if (!disableFormulas) {
      if (options.length > 0) {
        options.push(null)
      }

      options.push(
        new MenuOption(
          'New Formula...',
          buildFormula(''),
          <Icon type="magic-number" />
        )
      )
    }

    return addLabelToOptions(options)
  }

  const handleAddEntity = binding => {
    if (binding.type === 'formula' || !binding.source) {
      return ref.current.addEntity(binding, true)
    }

    const parentSource = binding.source.source

    let dataType
    let dataSubType
    let field

    if (parentSource && richObjectDataTypes.includes(parentSource.dataType)) {
      // prettier-ignore
      dataType = parentSource.dataType;

      // prettier-ignore
      [dataSubType, field] = binding.source.fieldId.split('.')
    } else {
      dataType = binding.source.dataType
      dataSubType = null
    }

    let options = formatterOptions[dataType] || []

    if (dataSubType) {
      options = options[dataSubType] || []
    }

    if (field) {
      options = options[field] || []
    }

    const [option] = options
    const format = option ? { type: option.value } : undefined

    ref.current.addEntity({
      ...binding,
      format,
    })
  }

  return (
    <MultiMenuTrigger
      menu={getOptions()}
      onSelect={handleAddEntity}
      className="entity-text-control-trigger"
      menuClassName="entity-text-control-menu"
      rowHeight={40}
      width={260}
      menuTheme={THEMES.DATA}
    >
      <IconButton orange rounded type="magic-text" />
    </MultiMenuTrigger>
  )
})

MagicText.propTypes = {
  actionId: PropTypes.string,
  appId: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  binding: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableFormulas: PropTypes.bool,
}

export default withPremiumFeatureHandler(MagicText)
