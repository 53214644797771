import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentEvents from 'react-document-events'

import { DEFAULT_COLUMN_WIDTH } from 'utils/tables'

import { drag, endDrag, getDragInfo } from 'ducks/editor/tables'
import { updateFieldOrder, getOrderedFields } from 'ducks/apps/datasources'

import './Dragging.css'

const getLeftOffset = () => {
  const modalWidth = Math.min(960, window.innerWidth - 80)

  return Math.floor((window.innerWidth - modalWidth) / 2) + 50
}

class Dragging extends Component {
  state = {}

  getPosition = cursorX => {
    const { table, tableLayout, dragInfo } = this.props
    const fields = getOrderedFields(table, true)

    cursorX -= getLeftOffset()
    cursorX += dragInfo.scrollPosition

    let i
    let x = 0

    for (i = 0; i < fields.length; i += 1) {
      const width = tableLayout[fields[i]] || DEFAULT_COLUMN_WIDTH

      if (i > 0 && cursorX < x + width / 2) {
        return i
      }

      x += width
    }

    return i
  }

  handleMouseMove = e => {
    const cursorX = e.clientX

    const { dragInfo, table, drag } = this.props
    const { dragging } = dragInfo

    if (!table || !dragging) {
      return
    }

    drag(this.getPosition(cursorX), cursorX)
  }

  handleMouseUp = e => {
    const {
      appId,
      datasourceId,
      tableId,
      table,
      dragInfo,
      endDrag,
      updateFieldOrder,
    } = this.props

    let { fieldId, newPosition } = dragInfo
    endDrag()

    if (!newPosition) {
      return
    }

    // Update column order
    const orderedFields = [...getOrderedFields(table)]
    const oldIndex = orderedFields.indexOf(fieldId)

    if (
      oldIndex === -1 ||
      newPosition === oldIndex ||
      newPosition === oldIndex + 1
    ) {
      return
    }

    orderedFields.splice(oldIndex, 1)

    if (newPosition > oldIndex) {
      newPosition -= 1
    }

    orderedFields.splice(newPosition, 0, fieldId)

    updateFieldOrder(appId, datasourceId, tableId, orderedFields)
  }

  renderColumn = () => {
    const { dragInfo, table, tableLayout } = this.props
    const { dragging, fieldId, cursorX, cursorOffset } = dragInfo

    if (!table || !dragging || !cursorX) {
      return null
    }

    const { top, height } = this.getInfo()

    const width = tableLayout[fieldId] || DEFAULT_COLUMN_WIDTH

    const styles = {
      width,
      left: cursorX - cursorOffset,
      top,
      height,
    }

    return (
      <div className="data-browser-drag-wrapper">
        <div className="data-browser-drag-layer" style={styles} />
      </div>
    )
  }

  renderNewPosition() {
    const { dragInfo, table, tableLayout } = this.props
    const { newPosition, scrollPosition } = dragInfo
    const fields = getOrderedFields(table)
    let x = 0

    if (newPosition === null) {
      return null
    }

    const { top, height } = this.getInfo()

    for (let i = 0; i < newPosition; i += 1) {
      x += tableLayout[fields[i]] || DEFAULT_COLUMN_WIDTH
    }

    const styles = {
      left: x + getLeftOffset() - scrollPosition,
      top,
      height,
    }

    return (
      <div className="data-browser-drag-wrapper">
        <div className="data-browser-drag-position" style={styles} />
      </div>
    )
  }

  getInfo = () => {
    const { getContentRect } = this.props
    const { top, height } = getContentRect()

    return { top, height }
  }

  render() {
    const { dragInfo } = this.props
    const { dragging, cursorX } = dragInfo

    if (!dragging) return null

    return (
      <div className="data-browser-drag-manager">
        <DocumentEvents
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
        />
        {cursorX ? (
          <React.Fragment>
            {this.renderNewPosition()}
            {this.renderColumn()}
          </React.Fragment>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dragInfo: getDragInfo(state),
})

export default connect(mapStateToProps, { drag, endDrag, updateFieldOrder })(
  Dragging
)
