import { combineReducers } from 'redux'
import undoable from 'redux-undo'

import { getTouchedFromInstruction } from 'ducks/editor/instructions'
import { getTouchedFromInstruction as getSelectionTouchedFromInstruction } from 'ducks/editor/selection-instructions'
import objectsReducer, {
  RESIZE_OBJECT,
  UPDATE_OBJECT,
  UPDATE_OBJECTS,
  CREATE_OBJECT,
  DELETE_OBJECT,
  REORDER_OBJECTS,
  GROUP_OBJECTS,
  UNGROUP_OBJECTS,
  SET_PAGE_SIZE,
  CHANGE_OBJECT_TYPE,
  POSITION_OBJECTS,
  ALIGN_OBJECTS,
  SET_DATA,
  RUN_INSTRUCTIONS,
  RUN_SELECTION_INSTRUCTION,
} from './objects'

import { PASTE } from './clipboard'
import { BEGIN_DRAG, DRAG, END_DRAG } from './positioning'
import menus from './menus'
import layers from './layers'
import marquee from './marquee'
import datasources from './datasources'
import tools from './tools'
import launchComponent from './launchComponent'
import tables from './tables'
import libraries from './libraries'
import modals from './modals'
import screenTemplates from './screenTemplates'

const FILTER_TYPES = new Set([
  SET_DATA,
  DRAG,
  BEGIN_DRAG,
  END_DRAG,
  POSITION_OBJECTS,
  ALIGN_OBJECTS,
  RESIZE_OBJECT,
  UPDATE_OBJECTS,
  UPDATE_OBJECT,
  CREATE_OBJECT,
  DELETE_OBJECT,
  REORDER_OBJECTS,
  GROUP_OBJECTS,
  UNGROUP_OBJECTS,
  SET_PAGE_SIZE,
  CHANGE_OBJECT_TYPE,
  PASTE,
  RUN_INSTRUCTIONS,
  RUN_SELECTION_INSTRUCTION,
])

let currentDragId = 0

const objects = undoable(objectsReducer, {
  filter: action => {
    if (action.type === RESIZE_OBJECT && action.skipSave) {
      return false
    }

    return FILTER_TYPES.has(action.type)
  },
  groupBy: (action, state) => {
    if (action.type === POSITION_OBJECTS) {
      return `position-${action.ids.join('-')}`
    }

    if (action.type === BEGIN_DRAG) {
      currentDragId += 1

      return `dragging-${currentDragId}`
    }

    if (action.type === DRAG || action.type === END_DRAG) {
      return `dragging-${currentDragId}`
    }

    if (action.type === UPDATE_OBJECTS) {
      const firstObject = action.objects[0]

      if (firstObject) {
        const keys = Object.keys(action.objects[0])

        const ids = action.objects.map(obj => obj.id)

        return `update-${ids.join(',')}-${keys.join(',')}`
      }
    }

    if (action.type === UPDATE_OBJECT) {
      const { undoGroup } = action

      if (undoGroup) {
        return undoGroup
      }
    }

    if (action.type === RUN_INSTRUCTIONS) {
      const { instructions } = action
      const touched = getTouchedFromInstruction(instructions)
      const operations = instructions.map(instruction => instruction.operation)

      return `run-instructions-${touched.join(',')}-${operations.join(',')}}`
    }

    if (action.type === RUN_SELECTION_INSTRUCTION) {
      const { instruction } = action

      const touched = getSelectionTouchedFromInstruction(instruction)
      const operation = instruction.operation

      return `run-selection-instruction-${touched.join(',')}-${operation}}`
    }

    return null
  },
  ignoreInitialState: true,
  syncFiltered: true,
  neverSkipReducer: true,
  limit: 200,
})

export default combineReducers({
  datasources,
  launchComponent,
  menus,
  layers,
  marquee,
  objects,
  tools,
  tables,
  libraries,
  modals,
  screenTemplates,
})
