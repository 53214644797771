import { FontStyle, FontWeight } from 'utils/responsiveTypes'
import DeviceType from '../../types/DeviceType'
import Length from '../Length'
import { FlexLength } from './flex'
import {
  TrackList,
  TrackSizeFunction,
  TRACK_SIZE_AUTO,
  AutoTrackSize,
} from './grid'

// These scores are only used at runtime so they can be changed as-needed to accommodate new ratings.
const SCORE_HIGH = 1
const SCORE_LOW = 0
const SCORE_NO_MATCH = -1

export const Display = {
  Flow: 'flow',
  Grid: 'grid',
  PushGraph: 'push-graph',
  None: 'none',
} as const

export type DisplayType = typeof Display[keyof typeof Display]

export const Position = {
  Static: 'static',
  Absolute: 'absolute',
  Fixed: 'fixed',
} as const

export type PositionType = typeof Position[keyof typeof Position]

export interface PushGraphEdge {
  startNodeId: string
  endNodeId: string
  distance: Length
}

interface ContainerStyleMap {
  display: DisplayType

  paddingTop: Length
  paddingBottom: Length
}

interface GridStyleMap {
  // Grid Container styles
  gridTemplateColumns: TrackList
  gridTemplateRows: TrackList
  columnGap: Length
  rowGap: Length

  // Grid Item styles

  /**
   * Indicates the column track on which to place a grid item. Values are zero-indexed.
   *
   * Grid items will be auto-placed when this value is undefined.
   */
  gridColumnStart: number | undefined
  /**
   * Indicates the row track on which to place a grid item. Values are zero-indexed.
   *
   * Grid items will be auto-placed when this value is undefined.
   */
  gridRowStart: number | undefined
}

interface PushGraphStyleMap {
  // The graph structure is described on each container
  pushGraphNodes: string[]
  pushGraphEdges: PushGraphEdge[]

  // Content items are associated with a node in the graph
  pushNodeId: string | undefined
}

export interface TextStyleMap {
  text: string
  fontSize: number
  fontStyle: FontStyle
  fontFamily: string
  fontWeight: FontWeight
  multiline: boolean
  autoWidth: boolean
  maxLength: number | undefined
}

export interface StyleMap
  extends ContainerStyleMap,
    GridStyleMap,
    PushGraphStyleMap,
    TextStyleMap {
  width: Length | undefined
  minHeight: Length

  minWidth: Length | undefined
  maxWidth: Length | undefined

  left: Length | undefined
  right: Length | undefined
  top: Length | undefined
  bottom: Length | undefined

  marginLeft: Length
  marginRight: Length
  marginTop: Length
  marginBottom: Length

  position: PositionType

  fixedCenterHorizontalStyles?: {
    left: Length | undefined
    right: Length | undefined
    width: Length | undefined
    marginLeft: Length
    marginRight: Length
  }

  scalesCenterHorizontalStyles?: {
    left: Length | undefined
    right: Length | undefined
    width: Length | undefined
    marginLeft: Length
    marginRight: Length
  }
}

export interface StyleCondition {
  device: DeviceType
}

export type StyleKey = keyof StyleMap
export type StyleValue<T extends StyleKey> = StyleMap[T]

export class StyleRule<K extends StyleKey> {
  constructor(
    public readonly key: K,
    public readonly value: StyleValue<K>,
    public readonly condition: StyleCondition | undefined = undefined
  ) {}

  /**
   * Calculates a score for this rule given some rendering context.
   *
   * Any score less than zero means the rule doesn't apply at all. Beyond that, scores are only relative to each other.
   * Higher scores should have higher precedence. Equal scores have same precedence so the caller should give favour to
   * the last rule found in the stylesheet.
   */
  public score(device: DeviceType | undefined): number {
    if (this.condition !== undefined && this.condition.device !== device) {
      // This rule does not apply in the current context.
      return SCORE_NO_MATCH
    }

    if (this.condition?.device === device) {
      // Either the devices match, or the context has no device. Either way, this is a perfect match.
      return SCORE_HIGH
    }

    // This rule has no condition so it qualifies as a fall-back if there's nothing more specific
    return SCORE_LOW
  }
}

export type StyleRuleCollection = ReadonlyArray<StyleRule<StyleKey>>

export {
  type AutoTrackSize,
  type TrackList,
  type TrackSizeFunction,
  FlexLength,
  TRACK_SIZE_AUTO,
}
