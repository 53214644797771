import { httpMethods } from '@adalo/constants'

export const formatBaseURL = url => {
  url = (url || '').trim()

  if (!url.match(/\/$/)) {
    return `${url}/`
  }

  return url
}

export const httpMethodOptions = Object.keys(httpMethods).map(k => ({
  label: k,
  value: httpMethods[k],
}))
