import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'

import { getLabel } from 'utils/sources'

import { getContextualObjects } from 'ducks/recommender'

import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import ControlField from 'components/Shared/Forms/ControlField'

class DeleteObjectOptions extends Component {
  render() {
    const { field, objectOptions, getLabel } = this.props

    return (
      <div className="action-item-form-options">
        <div className="form-inspect-field-wrapper">
          <ControlField
            component={MenuControl}
            name={`${field}.options.bindingId`}
            displayName="Record"
            placeholder="Select object..."
            options={objectOptions}
            getLabel={getLabel}
            comparator={deepEqual}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  objectOptions: getContextualObjects(
    state,
    props.appId,
    props.componentId,
    props.objectId,
    undefined,
    props.actionId,
    props.reference
  ),
  getLabel: source => getLabel(state, source, props.appId, props.componentId),
})

export default connect(mapStateToProps)(DeleteObjectOptions)
