import {
  COMPONENT,
  GROUP,
  LIST,
  resizingOptions,
  responsivePositioningOptions,
  positioning as PositioningValues,
  SECTION,
} from '@adalo/constants'
import { getDeviceType, pathLength, subPath, update } from '@adalo/utils'
import { EditorObject } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import usesSharedLayout from 'utils/objects/usesSharedLayout'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { mapHorizontalLayout } from 'utils/positioning'
import inferFixedPositionAnchor from 'utils/objects/inferFixedPositionAnchor'
import updateParentBounds from 'utils/operations/updateParentBounds'
import shouldResizeParent from 'utils/operations/shouldResizeParent'
import getSpecificSharedDevices from 'utils/getSpecificSharedDevices'
import updateChangedObject from './updateChangedObject'
import getObject from '../objects/helpers/getObject'
import getContainingScreen from '../objects/helpers/getContainingScreen'
import DeviceType from '../types/DeviceType'
import InstructionState from '../types/InstructionState'
import updateDeviceLayoutForObject from '../device-layouts'
import { ObjectList } from '../types/ObjectList'
import getObjectByPath from '../objects/helpers/getObjectByPath'
import ObjectPath from '../objects/ObjectPath'
import usesAbsolutePosition from '../objects/helpers/usesAbsolutePosition'
import mapScreenToDocument from './mapScreenToDocument'
import layoutParent from './layoutParent'
import Length, { LengthUnit } from '../model/Length'
import mapDocumentToState, {
  DEFAULT_RENDER_DEVICES,
} from './mapDocumentToState'
import { ObjectPathMap } from '../types/ObjectPathMap'
import calculatePushGraphs from '../pushing/calculatePushGraphs'
import { getParentId } from '../device-layouts/utils'
import { StyleKey } from '../model/styles'
import { ConditionResolver } from '../device-layouts/conditions'
import {
  LayoutSectionPurpose,
  getSectionFromContainer,
} from '../../../utils/layoutSections'

const { LEFT, RIGHT, CENTER, LEFT_AND_RIGHT } = responsivePositioningOptions
const { SCALES_WITH_PARENT, FIXED } = resizingOptions

export interface MoveElementOptions {
  objectId: string
  x: number
  y: number
}

export interface MoveElementInstruction {
  operation: 'moveElement'
  options: MoveElementOptions
}

const FIXED_POSITION_STYLE_KEYS: StyleKey[] = [
  'top',
  'bottom',
  'marginTop',
  'marginBottom',
]
const RELATIVE_POSITION_STYLE_KEYS: StyleKey[] = [
  'pushGraphNodes',
  'pushGraphEdges',
  'paddingTop',
  'paddingBottom',
]

export const moveElementVertically = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  objectId: string,
  device: DeviceType,
  newY: number | undefined
): ObjectList => {
  const screenObj = getParentScreen(list, pathMap, objectId)
  if (!screenObj) {
    throw new Error(`Could not find screen for object ${objectId}`)
  }
  const { id: screenId } = screenObj
  const document = mapScreenToDocument(list, pathMap, screenId)

  let newList = list

  let newObject = getObject(list, pathMap, objectId)
  // Horizontal delta is relative to the current device
  const { y: oldDeviceY, height } = getDeviceObject(newObject, device)
  const { height: screenHeight } = getContainingScreen(list, pathMap, objectId)

  const usesShared = usesSharedLayout(newObject, device)

  if (usesShared) {
    const positioning = inferFixedPositionAnchor(
      newY ?? 0,
      height,
      screenHeight
    )

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newList = update(newList, pathMap[objectId], {
      ...newObject,
      positioning,
    })
    newObject = getObject(newList, pathMap, objectId)
  }

  const diffY = newY === undefined ? 0 : newY - oldDeviceY

  let affectedLayouts: (DeviceType | undefined)[] = [device]
  if (usesShared) {
    affectedLayouts = getSpecificSharedDevices(newObject)

    // Update the device-specific layouts on all devices that use shared constraints
    affectedLayouts.push(undefined)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newList = update(newList, pathMap[objectId], {
      ...newObject,
      y: newObject.y + diffY,
    })
    newObject = getObject(newList, pathMap, objectId)
  }

  for (const affectedLayout of affectedLayouts) {
    if (affectedLayout === undefined) {
      continue
    }

    const { y: oldY, height: deviceObjectHeight } = getDeviceObject(
      newObject,
      affectedLayout
    )
    const changes = {
      y: oldY + diffY,
      positioning: inferFixedPositionAnchor(
        oldY + diffY,
        deviceObjectHeight,
        screenHeight
      ),
    }
    const resolver = new ConditionResolver(affectedLayout)
    newList = updateDeviceLayoutForObject(
      newList,
      pathMap,
      objectId,
      affectedLayout,
      resolver,
      changes
    )

    newObject = getObject(newList, pathMap, objectId)
  }

  if (usesAbsolutePosition(newObject)) {
    // Step 1: Generate a new document
    const updatedDocument = mapScreenToDocument(newList, pathMap, screenId)

    // Step 2: Copy styles from the latest document back to the original.
    const originalNode = document.getObjectById(objectId)
    const updatedNode = updatedDocument.getObjectById(objectId)

    if (updatedNode === undefined || originalNode === undefined) {
      throw new Error(
        `Could not find document node for object (Object ID: ${objectId})`
      )
    }

    for (const affectedLayout of affectedLayouts) {
      for (const styleKey of FIXED_POSITION_STYLE_KEYS) {
        // If device-specific fixed positioning changes
        // between the original and updated document then
        // remove styles that may clash.
        if (affectedLayout !== undefined) {
          const { positioning } = getDeviceObject(newObject, affectedLayout)

          if (positioning === PositioningValues.FIXED) {
            originalNode.removeStyleRuleForDevice('bottom', affectedLayout)
          }

          if (positioning === PositioningValues.FIXED_TOP) {
            originalNode.removeStyleRuleForDevice('marginTop', affectedLayout)
          }

          if (positioning === PositioningValues.FIXED_BOTTOM) {
            originalNode.removeStyleRuleForDevice('top', affectedLayout)
            originalNode.removeStyleRuleForDevice('marginTop', affectedLayout)
          }
        }

        const updatedRule = updatedNode.getUsedStyleRule(
          styleKey,
          affectedLayout
        )

        if (updatedRule.condition?.device === affectedLayout) {
          originalNode.addStyleRule(
            styleKey,
            updatedRule.value,
            updatedRule.condition?.device
          )
        }
      }
    }

    const viewingDevice = usesSharedLayout(newObject, device)
      ? undefined
      : device

    // Step 3: Re-layout all children using the updated Document
    newList = mapDocumentToState(
      document,
      newList,
      pathMap,
      screenObj.width,
      screenObj.height,
      objectId,
      // Only re-layout the affected devices
      affectedLayouts,
      'moveElement',
      viewingDevice
    )
    newObject = getObject(newList, pathMap, objectId)

    return updateChangedObject(newList, pathMap, newObject, undefined)
  }

  let updateRootObjectId = objectId
  let nonGroupAncestorId = getParentId(newList, pathMap, objectId)

  const parent = getObject(newList, pathMap, nonGroupAncestorId)
  let { type: parentType } = parent
  const { purpose: parentPurpose } = parent
  const affectedAncestorIds = [nonGroupAncestorId]
  while (parentType === GROUP) {
    updateRootObjectId = nonGroupAncestorId

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newList = updateParentBounds(
      newList,
      pathMap,
      objectId,
      null,
      shouldResizeParent,
      device,
      true
    )

    nonGroupAncestorId = getParentId(newList, pathMap, nonGroupAncestorId)
    ;({ type: parentType } = getObject(newList, pathMap, nonGroupAncestorId))

    affectedAncestorIds.push(nonGroupAncestorId)
  }

  if (
    parentType === SECTION &&
    parentPurpose === LayoutSectionPurpose.LAYOUT_HELPER
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newList = updateParentBounds(
      newList,
      pathMap,
      objectId,
      null,
      shouldResizeParent,
      device,
      true
    )

    nonGroupAncestorId = getParentId(newList, pathMap, nonGroupAncestorId)
    affectedAncestorIds.push(nonGroupAncestorId)
  }

  // Step 1: Recalculate push graphs for the parent and build an alternate document
  const throwawayList = calculatePushGraphs(newList, pathMap, screenId)
  const documentWithUpdatedPushGraph = mapScreenToDocument(
    throwawayList,
    pathMap,
    screenId
  )

  // Step 2: Copy parent styles from the latest document back to the original.
  for (const ancestorId of affectedAncestorIds) {
    let originalParentNode = document.getObjectById(ancestorId)
    let updatedParentNode =
      documentWithUpdatedPushGraph.getObjectById(ancestorId)

    if (originalParentNode?.type === LIST) {
      // Changes need to be applied to the list item within
      ;[originalParentNode] = originalParentNode.getChildren()
      ;[updatedParentNode] = updatedParentNode?.getChildren() || []
    }
    if (updatedParentNode === undefined || originalParentNode === undefined) {
      throw new Error(
        `Could not find document node for parent object (Parent ID: ${ancestorId})`
      )
    }

    const styleKeys: StyleKey[] = [...RELATIVE_POSITION_STYLE_KEYS]

    const originalParentObject = getObject(newList, pathMap, ancestorId)
    if (usesAbsolutePosition(originalParentObject)) {
      styleKeys.push(...FIXED_POSITION_STYLE_KEYS)
    }

    for (const affectedLayout of affectedLayouts) {
      for (const styleKey of styleKeys) {
        const updatedRule = updatedParentNode.getUsedStyleRule(
          styleKey,
          affectedLayout
        )

        if (updatedRule.condition?.device === affectedLayout) {
          originalParentNode.addStyleRule(
            styleKey,
            updatedRule.value,
            updatedRule.condition?.device
          )
        }
      }
    }
  }

  const screenDevice = getDeviceType(screenObj.width)
  const viewingDevice = usesSharedLayout(newObject, device)
    ? undefined
    : screenDevice

  // Step 3: Re-layout all children using a Document with the new push graphs. The result of this layout will include
  // all cascading effects across the object hierarchy.
  newList = mapDocumentToState(
    document,
    newList,
    pathMap,
    screenObj.width,
    screenObj.height,
    updateRootObjectId,
    DEFAULT_RENDER_DEVICES,
    'moveElement',
    viewingDevice
  )
  newObject = getObject(newList, pathMap, objectId)

  return updateChangedObject(newList, pathMap, newObject, undefined)
}

const moveElementHorizontally = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  objectId: string,
  screenDevice: DeviceType,
  newX: number | undefined
): ObjectList => {
  const baseObject = getObject(list, pathMap, objectId)
  const deviceObject = getDeviceObject(baseObject, screenDevice)

  // Find the parent object
  const childPath = pathMap[objectId]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parentPath: string = subPath(childPath, pathLength(childPath) - 1)
  const parentObject = getObjectByPath(list, ObjectPath.fromString(parentPath))
  const { type: parentType, id: parentId } = parentObject

  const deviceToUpdate = usesSharedLayout(baseObject, screenDevice)
    ? undefined
    : screenDevice

  let diffX
  // Determine amount to move on device to update.
  if (deviceToUpdate === undefined && parentType !== COMPONENT) {
    // If updating shared layout, convert the delta according to element constraints.

    // sanity-check(device-parent): `screenDevice` will not be undefined here
    const parentDeviceObject = getDeviceObject(parentObject, screenDevice)

    const {
      responsivity = {},
      x: deviceChildX,
      width: deviceChildWidth,
    } = deviceObject
    const newDeviceChildLayout = {
      x: newX ?? deviceChildX,
      width: deviceChildWidth,
    }

    const { x: deviceParentX, width: deviceParentWidth } = parentDeviceObject
    const deviceParentLayout = { x: deviceParentX, width: deviceParentWidth }

    // sanity-check(device-parent):
    // if the deviceToUpdate is undefined and the parent has all custom layout
    // we cannot use the parent's shared layout to calculate the delta
    const parentUsesFullCustomLayout =
      parentObject.shared?.desktop === false &&
      parentObject.shared?.tablet === false &&
      parentObject.shared?.mobile === false
    const sharedParentObject = parentUsesFullCustomLayout
      ? parentDeviceObject
      : parentObject

    const { x: sharedParentX, width: sharedParentWidth } = sharedParentObject
    const sharedParentLayout = { x: sharedParentX, width: sharedParentWidth }

    // Calculate the equivalent delta on the shared layout
    const sharedChildLayout = mapHorizontalLayout(
      responsivity,
      newDeviceChildLayout,
      deviceParentLayout,
      sharedParentLayout
    )
    const { x: newSharedChildX } = sharedChildLayout

    const { x: oldX } = baseObject
    diffX = newSharedChildX - oldX
  } else {
    // If updating current device, can use exact amount.
    const { x: oldX } = deviceObject
    diffX = newX === undefined ? 0 : newX - oldX
  }

  const {
    id: screenId,
    width: screenWidth,
    height: screenHeight,
  } = getContainingScreen(list, pathMap, objectId)

  // Apply the horizontal resize using the Document model so that children reflow correctly.
  // Create a document for the screen
  const document = mapScreenToDocument(list, pathMap, screenId)

  // Find the resized element node within the screen
  const elementNode = document.getObjectById(objectId)
  if (elementNode === undefined) {
    throw new Error(
      `Element node does not exist in screen document (Object ID: ${objectId})`
    )
  }

  // Translate the node horizontally.
  // The element's layout constraints determine which styles need to be adjusted.
  const { responsivity = {} } = getDeviceObject(baseObject, deviceToUpdate)
  const {
    horizontalScaling: scaling = FIXED,
    horizontalPositioning: positioning = scaling === FIXED ? LEFT : CENTER,
  } = responsivity

  const { width: parentWidth } = layoutParent(
    document,
    elementNode,
    deviceToUpdate,
    screenWidth,
    screenHeight
  )

  // Documentation:
  // src/ducks/editor/instructions/CONCEPTS.md
  // Refer to section "Margins"
  if (scaling === FIXED && positioning === LEFT) {
    const oldLeftRule = elementNode.getUsedStyleRule('left', deviceToUpdate)
    const oldLeft = oldLeftRule.value
    if (oldLeft === undefined) {
      throw new Error(`Did not find expected style 'left'.`)
    } else if (oldLeft.unit !== LengthUnit.Pixel) {
      throw new Error(
        `Found percent length where exact length was expected. (Object ID: ${objectId})`
      )
    }
    const newLeft = Length.fromPixels(oldLeft.value + diffX)

    // Appending the new rule will give it higher precedence than the original rule.
    elementNode.addStyleRule('left', newLeft, oldLeftRule.condition?.device)
  } else if (scaling === FIXED && positioning === CENTER) {
    const { value: oldLeft = Length.ZERO, condition: leftCondition } =
      elementNode.getUsedStyleRule('left', deviceToUpdate)
    const oldLeftPixels = oldLeft.toExact(parentWidth)
    const newLeftPixels = oldLeftPixels + diffX
    const newLeftRatio = newLeftPixels / parentWidth
    const newLeft = Length.fromPercent(newLeftRatio)
    elementNode.addStyleRule('left', newLeft, leftCondition?.device)
  } else if (scaling === FIXED && positioning === RIGHT) {
    const { value: oldRight = Length.ZERO, condition: rightCondition } =
      elementNode.getUsedStyleRule('right', deviceToUpdate)
    const oldRightPixels = oldRight.toExact(parentWidth)
    const newRight = Length.fromPixels(oldRightPixels - diffX)
    elementNode.addStyleRule('right', newRight, rightCondition?.device)
  } else if (scaling === SCALES_WITH_PARENT && positioning === CENTER) {
    const { value: oldLeft = Length.ZERO, condition: leftCondition } =
      elementNode.getUsedStyleRule('left', deviceToUpdate)
    const oldLeftPixels = oldLeft.toExact(parentWidth)
    const newLeftPixels = oldLeftPixels + diffX
    const newLeftRatio = newLeftPixels / parentWidth
    const newLeft = Length.fromPercent(newLeftRatio)
    elementNode.addStyleRule('left', newLeft, leftCondition?.device)

    const { value: oldRight = Length.ZERO, condition: rightCondition } =
      elementNode.getUsedStyleRule('right', deviceToUpdate)
    const oldRightPixels = oldRight.toExact(parentWidth)
    const newRightPixels = oldRightPixels - diffX
    const newRightRatio = newRightPixels / parentWidth
    const newRight = Length.fromPercent(newRightRatio)
    elementNode.addStyleRule('right', newRight, rightCondition?.device)
  } else if (scaling === SCALES_WITH_PARENT && positioning === LEFT_AND_RIGHT) {
    const { value: oldLeft = Length.ZERO, condition: leftCondition } =
      elementNode.getUsedStyleRule('left', deviceToUpdate)
    const oldLeftPixels = oldLeft.toExact(parentWidth)
    const newLeft = Length.fromPixels(oldLeftPixels + diffX)
    elementNode.addStyleRule('left', newLeft, leftCondition?.device)

    const { value: oldRight = Length.ZERO, condition: rightCondition } =
      elementNode.getUsedStyleRule('right', deviceToUpdate)
    const oldRightPixels = oldRight.toExact(parentWidth)
    const newRight = Length.fromPixels(oldRightPixels - diffX)
    elementNode.addStyleRule('right', newRight, rightCondition?.device)
  } else {
    throw new Error(
      `Object has unsupported horizontal layout combination. horizontalScaling: ${scaling}; horizontalPositioning: ${positioning}`
    )
  }

  const viewingDevice = usesSharedLayout(baseObject, screenDevice)
    ? undefined
    : screenDevice

  // Convert the document back to a valid state
  return mapDocumentToState(
    document,
    list,
    pathMap,
    screenWidth,
    screenHeight,
    parentType === GROUP ? parentId : objectId,
    DEFAULT_RENDER_DEVICES,
    'moveElement',
    viewingDevice
  )
}

export const moveElementHandler = (
  state: InstructionState,
  { objectId, x, y }: MoveElementOptions
): InstructionState => {
  let { list: newList, pathMap } = state

  // Get the parent screen
  let baseObject: EditorObject = getObject(newList, pathMap, objectId)
  const { width: screenWidth } = getContainingScreen(newList, pathMap, objectId)
  const screenDevice = getDeviceType(screenWidth)
  let newX = x
  let newY = y

  if (baseObject.type === SECTION && baseObject.purpose === 'container') {
    const section = getSectionFromContainer(newList, pathMap, baseObject)
    const { x: sectionX, y: sectionY } = getDeviceObject(section, screenDevice)

    if (y < sectionY) {
      newY = sectionY
    }

    if (x < sectionX) {
      newX = sectionX
    }
  }

  newList = moveElementVertically(
    newList,
    pathMap,
    objectId,
    screenDevice,
    newY
  )
  newList = moveElementHorizontally(
    newList,
    pathMap,
    objectId,
    screenDevice,
    newX
  )

  // If the object is in a sticky group, we need to update the group's layout
  // The reason for this is that by moving the component within the group
  // the group's bounds may now place it within a different fixed position anchor zone
  const parentId = getParentId(newList, pathMap, objectId)
  const parentObject = getObject(newList, pathMap, parentId)
  if (usesAbsolutePosition(parentObject)) {
    const { x: parentX, y: parentY } = getDeviceObject(
      parentObject,
      screenDevice
    )

    ;({ list: newList, pathMap } = moveElementHandler(
      {
        ...state,
        list: newList,
      },
      {
        objectId: parentId,
        x: parentX,
        y: parentY,
      }
    ))
  }

  baseObject = getObject(newList, pathMap, objectId)

  newList = updateChangedObject(newList, pathMap, baseObject, screenDevice)

  return {
    ...state,
    list: newList,
  }
}

const moveElement = (
  objectId: string,
  x: number,
  y: number
): MoveElementInstruction => ({
  operation: 'moveElement',
  options: { objectId, x, y },
})

export default moveElement
