import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import {
  getOrganizations,
  setCurrentOrganization,
  updateOrganizationName,
  fetchOrganizations,
  fetchOrganization,
} from 'ducks/organizations'

import TrialWarning from 'components/Shared/TrialWarning'
import Page from 'components/Shared/Page'
import BodyClass from 'components/Shared/BodyClass'

import WizardForm from '../NewApp/WizardForm'
import './Auth.scss'

const templates = [{ id: 'blank', name: 'Blank' }]

class InitialApp extends Component {
  componentDidMount() {
    const klientBoostScript = `
    var _ubaq = _ubaq || []; 
    _ubaq.push(['trackGoal', 'convert']); 
    (function() { 
    var ub_script = document.createElement('script'); 
    ub_script.type = 'text/javascript'; 
    ub_script.src = 
    ('https:' == document.location.protocol ? 'https://' : 'http://') + 
    'd3pkntwtp2ukl5.cloudfront.net/uba.js'; 
    var s = document.getElementsByTagName('script')[0]; 
    s.parentNode.insertBefore(ub_script, s); 
    }) ();`

    const script = document.createElement('script')
    script.setAttribute('id', 'klientboost')
    script.text = klientBoostScript
    document.head.appendChild(script)
  }

  componentDidUpdate(prevProps) {
    const { currentOrganization } = this.props

    if (!isEqual(prevProps.currentOrganization, currentOrganization)) {
      const { setCurrentOrganization, fetchOrganization } = this.props

      fetchOrganization(currentOrganization.id)
      setCurrentOrganization(currentOrganization.id)
    }
  }

  componentWillUnmount() {
    const script = document.getElementById('klientboost')
    document.head.removeChild(script)
  }

  render() {
    const { organizations, currentOrganization } = this.props
    const size = 'md'

    const modalClassName = classNames('new-app-modal', {
      'has-responsive-option': true,
    })

    const modalProps = { className: modalClassName, grayOut: false, size }

    return (
      <Page
        modalProps={modalProps}
        modalContent={
          <WizardForm
            onSubmit={this.handleSubmit}
            templates={templates}
            organizations={organizations}
          />
        }
      >
        <BodyClass className="auth-screen" />
        {currentOrganization && (
          <TrialWarning organizationId={currentOrganization.id} />
        )}
      </Page>
    )
  }
}

const mapStateToProps = state => {
  const organizations = getOrganizations(state)
  const currentOrganization =
    organizations.length === 1 ? organizations[0] : null

  return { organizations, currentOrganization }
}

export default connect(mapStateToProps, {
  updateOrganizationName,
  fetchOrganizations,
  fetchOrganization,
  setCurrentOrganization,
})(InitialApp)
