import { EditorObject } from 'utils/responsiveTypes'
import DeviceType from 'ducks/editor/types/DeviceType'

export default function usesSharedLayout(
  object: EditorObject,
  device: DeviceType | undefined
): boolean {
  if (device === undefined) {
    // Implies we're checking for the shared layout, specifically.
    return true
  }

  const { shared } = object
  if (shared === undefined) {
    // If the `shared` object is missing, this object likely pre-dates device-specific layouts.
    // The default behavior is to use shared layouts for all devices.
    return true
  }

  const { [device]: usesShared = true } = shared

  return usesShared
}
