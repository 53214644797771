import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updateObject } from '../../../ducks/editor/objects'
import BindableTextControl from '../Inspect/Libraries/SimpleTextControl'

class ScreenName extends Component {
  handleChange = ({ name }) => {
    const { updateObject, object } = this.props

    updateObject(object.id, { name })
  }

  render() {
    const { object } = this.props

    return (
      <BindableTextControl
        displayName="Name"
        name="name"
        aria-label="screen-name"
        value={object.name || ''}
        onChange={this.handleChange}
      />
    )
  }
}

export default connect(null, { updateObject })(ScreenName)
