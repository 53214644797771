import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { inflect } from 'inflection'

import { getSelectedPlanValue } from 'ducks/trialWarning'
import { getCurrentUser } from 'ducks/users/index.ts'
import { getPricingPlans } from 'ducks/billing'
import { getCurrentOrganization } from 'ducks/organizations'

import { getPlanName, hasBusinessPlan } from 'utils/billing.ts'
import Modal from 'components/Shared/Modal'

import {
  PlanChangeContext,
  EDITORS_BY_PLAN,
  PRICE_PER_TEAM_MEMBER_ADDON,
  PAYMENT_STEP,
  PUBLISHED_APPS_STEP,
} from './PlanChangeContext'

import './SelectionModal.scss'

const TeamMembers = () => {
  const currentUser = useSelector(getCurrentUser)
  const { admin: currentUserIsAdmin } = currentUser || {}
  const {
    Users: users,
    name,
    OrganizationUser,
  } = useSelector(getCurrentOrganization)
  const {
    addTeamMember,
    teamMembersToRemove,
    removeTeamMember,
    teamMemberLimit,
    includedTeamMembers,
    additionalTeamMembers,
    teamMemberOverage,
  } = useContext(PlanChangeContext)

  const handleClick = (value, user) => {
    if (value) {
      addTeamMember(user)
    } else {
      removeTeamMember(user)
    }
  }

  const currentUserId = currentUserIsAdmin
    ? currentUser.id
    : OrganizationUser?.UserId

  return (
    <div className="new-plan-selection-content">
      <div className="new-plan-selection-content__header">
        <h2 className="selection-modal__header">{name} Members</h2>
      </div>
      <div className="user-selection">
        {users.map(user => {
          const userIsRemoved = teamMembersToRemove.some(
            teamMembers => teamMembers.id === user.id
          )
          const buttonText = userIsRemoved ? 'Add' : 'Remove'
          const modifier = userIsRemoved ? 'removed' : 'light'

          let rightColumn = (
            <button
              className="user-selection__toggle"
              onClick={() => handleClick(userIsRemoved, user)}
            >
              {buttonText}
            </button>
          )

          if (user.id === currentUserId) {
            rightColumn = <h3 className="selection-modal__subheader">You</h3>
          }

          return (
            <div key={user.id} className="user-selection__user">
              <div>
                <h2
                  className={`selection-modal__header selection-modal__header--${modifier}`}
                >
                  {user.name}
                </h2>
                <h3
                  className={`selection-modal__subheader selection-modal__subheader--${modifier}`}
                >
                  {user.email}
                </h3>
              </div>
              {rightColumn}
            </div>
          )
        })}
      </div>
      <div className="new-plan-selection-content__count">
        <div className="new-plan-selection-content__count-row">
          <h3 className="selection-modal__subheader">
            App Editors Within Plan
          </h3>
          <h3 className="selection-modal__subheader">
            {includedTeamMembers} out of {teamMemberLimit}
          </h3>
        </div>
        <br />
        <div className="new-plan-selection-content__count-row">
          <h3 className="selection-modal__subheader">{`Additional App Editors ($${PRICE_PER_TEAM_MEMBER_ADDON.toLocaleString(
            'en-US'
          )} each per month)`}</h3>
          <h3 className="selection-modal__subheader">
            {additionalTeamMembers}
          </h3>
        </div>
      </div>
      <div className="new-plan-selection-content__total">
        <h2 className="selection-modal__header">Monthly Sub-Total:</h2>
        <h2 className="selection-modal__header">
          $
          {(teamMemberOverage * PRICE_PER_TEAM_MEMBER_ADDON).toLocaleString(
            'en-US'
          )}
        </h2>
      </div>
    </div>
  )
}

const TeamMemberSelectionModal = ({ goToPlanSelection }) => {
  const selectedPlan = useSelector(getSelectedPlanValue)
  const pricingPlans = useSelector(getPricingPlans)
  const planName = getPlanName(selectedPlan, pricingPlans)
  const {
    setCurrentStep,
    appOverage,
    currentTeamMemberCount,
    teamMemberLimit,
  } = useContext(PlanChangeContext)

  const handleBack = () => {
    if (appOverage > 0) {
      setCurrentStep(PUBLISHED_APPS_STEP)
    } else {
      goToPlanSelection()
    }
  }

  useEffect(() => {
    if (
      currentTeamMemberCount <= teamMemberLimit ||
      hasBusinessPlan(selectedPlan)
    ) {
      setCurrentStep(PAYMENT_STEP)
    }
  }, [])

  return (
    <Modal size="lg" className="selection-modal">
      <Modal.Header
        title={`${planName}: Select Your App Editors`}
        content={() => (
          <Modal.Button
            to="https://adalo.com/pricing"
            iconSide="right"
            iconSize="small"
            target="_blank"
            icon="open-in-new"
            placement="right"
            teal
            text
          >
            Learn more
          </Modal.Button>
        )}
      />

      <Modal.Content>
        <div className="new-plan-selection-header">
          <p className="new-plan-selection-header__description">
            {`The ${planName} Plan allows for ${
              EDITORS_BY_PLAN[planName]
            } app ${inflect(
              'editor',
              EDITORS_BY_PLAN[planName]
            )}. Keep as many as you want, but we'll charge you extra for each additional editor.`}
          </p>
        </div>
        <TeamMembers />
      </Modal.Content>

      <Modal.Actions>
        <Modal.Button type="button" text onClick={handleBack}>
          Back
        </Modal.Button>

        <Modal.Button
          large
          type="submit"
          onClick={() => setCurrentStep(PAYMENT_STEP)}
        >
          Next
        </Modal.Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TeamMemberSelectionModal
