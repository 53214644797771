import { getDeviceType } from '@adalo/utils'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { EditorObject } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import { getParent } from '../../device-layouts/utils'
import { ObjectList } from '../../types/ObjectList'
import { ObjectPathMap } from '../../types/ObjectPathMap'
import getObject from './getObject'

const getObjectDeviceParent = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  objectId: string
): EditorObject => {
  // check if object exists
  getObject(list, pathMap, objectId)

  const screen = getParentScreen(list, pathMap, objectId)
  if (!screen) {
    throw new Error(`No screen found for object ${objectId}`)
  }

  const screenDevice = getDeviceType(screen.width)
  const parent = getParent(list, pathMap, objectId)

  return getDeviceObject(parent, screenDevice)
}

export default getObjectDeviceParent
