import React from 'react'
import { dataTypes } from '@adalo/constants'
import { buildIndex } from '@adalo/utils'
import { getIcon } from 'utils/icons'

import Icon from 'components/Shared/Icon'

const DATE_ONLY = /^\d{4}\-\d{2}\-\d{2}$/
const DATE = /^\d{4}\-\d{2}\-\d{2}T/

export const getOutputType = value => {
  if (typeof value === 'string' && value.match(DATE_ONLY)) {
    return dataTypes.DATE_ONLY
  } else if (typeof value === 'string' && value.match(DATE)) {
    return dataTypes.DATE
  } else if (typeof value === 'number') {
    return dataTypes.NUMBER
  } else if (typeof value === 'boolean') {
    return dataTypes.BOOLEAN
  }

  return dataTypes.TEXT
}

export const stringifiedKey = key => {
  const newKey = key.replace(/\./g, '>')

  return newKey
}

// Returns fields object and orderedFields array
export const getOutputs = items => {
  const outputs = buildIndex(getOutputsSub(items), field =>
    stringifiedKey(field.key)
  )

  return outputs
}

export const getOutputsSub = (item, prefix = '') => {
  if (!item && item !== 0) {
    return []
  } else if (Array.isArray(item)) {
    const key = prefix ? `${prefix}.0` : '0'

    return getOutputsSub(item[0], key)
  } else if (item && typeof item === 'object') {
    let fields = []

    for (const fieldId of Object.keys(item)) {
      if (
        fieldId.match(/[^\w\d\s\-_~`"'!@#%&,<>:;=\?\(\)\{}\[]\\\/\^\$\|\*\+]/)
      ) {
        continue
      }

      const key = prefix ? `${prefix}.${fieldId}` : fieldId
      const value = item[fieldId]

      fields = fields.concat(getOutputsSub(value, key))
    }

    return fields
  } else {
    if (!prefix) {
      return []
    }

    const name = prefix
      .split('.')
      .filter(fragment => Number.isNaN(parseInt(fragment)))
      .join('.')

    return [
      {
        key: prefix,
        name,
        type: getOutputType(item),
      },
    ]
  }
}

/**
 * Returns options for new properties
 * @param {*} options
 */
export const getTypeOptions = (options = []) => {
  const labels = options.map(option => {
    if (option !== null) return option.label
  })

  const optionsWithIcon = options.map((option, i) => {
    if (option === null) return option

    const label = labels[i]
    const icon = getIcon(option.value, option.label)

    option = {
      ...option,
      label: (
        <div className="custom-action-type-option">
          <Icon type={icon} />
          {label}
        </div>
      ),
    }

    return option
  })

  return optionsWithIcon
}
