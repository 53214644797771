import { SECTION } from '@adalo/constants'
import {
  getObject,
  pathLength,
  shallowEqual,
  subPath,
  update,
} from '@adalo/utils'
import getDeviceObject from 'utils/getDeviceObject'
import { LayoutSectionPurpose } from 'utils/layoutSections'
import removeInfinity from './removeInfinity'

const updateBounds = (
  list,
  map,
  path,
  shouldUpdate,
  deviceType,
  magicLayout
) => {
  const obj = getObject(list, path)

  if (!obj) {
    return list
  }

  if (shouldUpdate && !shouldUpdate(obj, magicLayout)) {
    return list
  }

  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity

  obj.children.forEach(child => {
    const { x, y, width, height } = getDeviceObject(child, deviceType)
    if (x < minX) {
      minX = x
    }
    if (x + width > maxX) {
      maxX = x + width
    }

    if (y < minY) {
      minY = y
    }
    if (y + height > maxY) {
      maxY = y + height
    }
  })

  const x = removeInfinity(minX, 0)
  const y = removeInfinity(minY, 0)
  const width = removeInfinity(maxX - minX, 0)
  const height = removeInfinity(maxY - minY, 0)

  const updateProperties =
    obj.purpose === LayoutSectionPurpose.LAYOUT_HELPER && obj.type === SECTION
      ? { y, height }
      : { x, y, width, height }

  let updatedObj = {
    ...obj,
    ...updateProperties,
  }

  if (deviceType) {
    updatedObj = {
      ...obj,
      [deviceType]: {
        ...obj[deviceType],
        ...updateProperties,
      },
    }
  }

  if (
    !shallowEqual(obj, updatedObj, [
      'x',
      'y',
      'width',
      'height',
      'mobile',
      'tablet',
      'desktop',
    ])
  ) {
    list = update(list, path, updatedObj)
    const parentPath = subPath(path, pathLength(path) - 1)
    list = updateBounds(
      list,
      map,
      parentPath,
      shouldUpdate,
      deviceType,
      magicLayout
    )
  }

  return list
}

export default updateBounds
