import { Point, Rect, Zoom } from './canvasTypes'

export const DEFAULT_ZOOM: Zoom = {
  scale: 1,
  offset: [0, 0],
}

export const DEFAULT_ZOOM_2X: Zoom = {
  scale: 0.5,
  offset: [0, 0],
}

export function scale(point: Point, zoom: Zoom): Point
export function scale(point: undefined, zoom: Zoom): undefined
export function scale(point: Point | undefined, zoom: Zoom): Point | undefined
export function scale(point: Point | undefined, zoom: Zoom): Point | undefined {
  if (!point) {
    return undefined
  }

  if (!zoom) {
    throw new Error('Zoom cannot be undefined')
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { scale, offset } = zoom

  const [offsetX, offsetY] = offset
  const [x, y] = point

  return [x * scale + offsetX, y * scale + offsetY]
}
export function unScale(point: Point, zoom: Zoom): Point
export function unScale(point: undefined, zoom: Zoom): undefined
export function unScale(point: Point | undefined, zoom: Zoom): Point | undefined
export function unScale(
  point: Point | undefined,
  zoom: Zoom
): Point | undefined {
  if (!point) {
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { scale, offset } = zoom

  const [offsetX, offsetY] = offset
  const [x, y] = point

  return [(x - offsetX) / scale, (y - offsetY) / scale]
}

export function scaleValue(value: number, zoom: Zoom): number
export function scaleValue(
  value: number | undefined,
  zoom: Zoom
): number | undefined
export function scaleValue(value: undefined, zoom: Zoom): number
export function scaleValue(
  value: number | undefined,
  zoom: Zoom
): number | undefined {
  if (!value) {
    return value
  }

  if (!zoom) {
    throw new Error('Zoom cannot be undefined')
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { scale } = zoom

  return value * scale
}

export const unScaleValue = (value: number, zoom: Zoom): number => {
  if (!value) {
    return value
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { scale } = zoom

  return value / scale
}

export function scaleRect(rect: Rect, zoom: Zoom): Rect
export function scaleRect(rect: Rect | undefined, zoom: Zoom): Rect | undefined
export function scaleRect(rect: undefined, zoom: Zoom): undefined
export function scaleRect(
  rect: Rect | undefined,
  zoom: Zoom
): Rect | undefined {
  if (!rect) {
    return rect
  }

  const { x, y, width, height } = rect

  const [newX, newY] = scale([x, y], zoom)
  const newWidth = scaleValue(width, zoom)
  const newHeight = scaleValue(height, zoom)

  return {
    x: newX,
    y: newY,
    width: newWidth,
    height: newHeight,
  }
}
export function unScaleRect(rect: Rect, zoom: Zoom): Rect
export function unScaleRect(
  rect: Rect | undefined,
  zoom: Zoom
): Rect | undefined
export function unScaleRect(rect: undefined, zoom: Zoom): undefined
export function unScaleRect(
  rect: Rect | undefined,
  zoom: Zoom
): Rect | undefined {
  if (!rect) {
    return rect
  }

  const { x, y, width, height } = rect

  const [newX, newY] = unScale([x, y], zoom)
  const newWidth = unScaleValue(width, zoom)
  const newHeight = unScaleValue(height, zoom)

  return {
    x: newX,
    y: newY,
    width: newWidth,
    height: newHeight,
  }
}

export const isVisible = (obj: Rect, zoom: Zoom): boolean => {
  const { x, y, width, height } = scaleRect(obj, zoom ?? DEFAULT_ZOOM)

  const canvasWidth = window.innerWidth
  const widthBuffer = canvasWidth * 0.5
  const canvasHeight = window.innerHeight * 1.5
  const heightBuffer = canvasHeight

  return !(
    x > canvasWidth + widthBuffer ||
    x + width < -widthBuffer ||
    y > canvasHeight + heightBuffer ||
    y + height < -heightBuffer
  )
}

export const calculateZoom = (bbox: Rect | undefined): Zoom => {
  if (!bbox) {
    return DEFAULT_ZOOM
  }

  const { width, height } = bbox

  const margin = 60
  const leftPanel = 425
  const rightPanel = 0
  const topPanel = 64
  const canvasWidth = window.innerWidth - leftPanel - rightPanel
  const canvasHeight = window.innerHeight - topPanel / 2
  const xScale = (canvasWidth - 2 * margin) / width
  const yScale = (canvasHeight - 2 * margin) / height
  // eslint-disable-next-line @typescript-eslint/no-shadow
  let scale = Math.min(xScale, yScale)

  if (scale > 16) {
    scale = 16
  }
  if (scale < 1.0 / 16) {
    scale = 1.0 / 16
  }

  const xOffset =
    (window.innerWidth - rightPanel) / 2 -
    (width / 2 + bbox.x) * scale +
    leftPanel / 2

  const yOffset =
    window.innerHeight / 2 - (height / 2 + bbox.y) * scale + topPanel / 2

  return {
    scale,
    offset: [xOffset, yOffset],
  }
}

export const getTransform = (
  zoom: Zoom,
  svgNamespace = false,
  x = 0,
  y = 0
): string => {
  const unit = svgNamespace && window.devicePixelRatio === 2 ? 2 : 1
  const rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return `translate3d(${rect.x}px, ${rect.y}px, 0) scale(${rect.width})`
}

export const getSVGTransform = (zoom: Zoom, x = 0, y = 0): string => {
  // let unit = (svgNamespace && window.devicePixelRatio === 2) ? 2 : 1
  const unit = 1
  const rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return `translate(${rect.x} ${rect.y}) scale(${rect.width})`
}

export const getBaseZoom = (): Zoom => {
  return window.devicePixelRatio === 2 ? DEFAULT_ZOOM_2X : DEFAULT_ZOOM
}

export const getOffset = (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  scale: number,
  center: Point,
  prevScale: number,
  prevOffset: Point
): Point => {
  const [offsetX, offsetY] = prevOffset

  return [
    (1 - scale / prevScale) * (center[0] - offsetX) + offsetX,
    (1 - scale / prevScale) * (center[1] - offsetY) + offsetY,
  ]
}

// Apply rounding to avoid imprecise calculations
export const applyScaleRounding = (scaledValue: number): number =>
  Math.round(scaledValue + 0.5) - 0.5
