import { EditorObject } from 'utils/responsiveTypes'

export const increaseWidth = (
  object: EditorObject,
  columnCount: number,
  list: { columnCount: number; rowMargin: number }
): number => {
  return (columnCount / list.columnCount) * object.width + list.rowMargin
}

export const decreaseWidth = (
  object: EditorObject,
  columnCount: number,
  list: { columnCount: number; rowMargin: number }
): number => {
  return (columnCount / list.columnCount) * (object.width - list.rowMargin)
}
