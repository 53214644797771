import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getConnected } from '../../../ducks/sockets'
import BodyClass from '../../Shared/BodyClass'

import './OfflineBanner.css'

class OfflineBanner extends Component {
  render() {
    const { connected } = this.props

    return (
      <>
        {connected ? null : <BodyClass className="offline-banner-visible" />}
        <div className="offline-banner">
          We're having difficulties connecting to our servers. Visit our{'  '}
          <a href="https://status.adalo.com" target="_blank" rel="noreferrer">
            status page
          </a>
          {'  '}
          and check your internet connection.
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  connected: getConnected(state),
})

export default connect(mapStateToProps)(OfflineBanner)
