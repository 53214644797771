import { trackStartTrialEvent } from 'utils/facebookPixelEvents'
import { adaloBackendAxios } from 'utils/io/http/axios'

const START_TRIAL = 'START_TRIAL'
const START_SECOND_TRIAL = 'START_SECOND_TRIAL'

// Reducer

export default (state, action) => {
  if (action.type === `${START_TRIAL}_FULFILLED`) {
    const {
      payload,
      meta: { appId },
    } = action

    const { trialEndDate } = payload.data

    trackStartTrialEvent()

    return {
      ...state,
      apps: {
        ...state.apps,
        [appId]: {
          ...state.apps[appId],
          Organization: {
            ...state.apps[appId].Organization,
            trialEndDate,
          },
        },
      },
    }
  }

  return state
}

// Actions

export const startTrial = (orgId, appId) => ({
  type: START_TRIAL,
  payload: adaloBackendAxios.put(`/organizations/${orgId}/trial`),
  meta: { appId },
})

export const startSecondTrial = (orgId, appId, feature) => ({
  type: START_SECOND_TRIAL,
  payload: adaloBackendAxios.put(`/organizations/${orgId}/trial/${feature}`),
  meta: { appId },
})
