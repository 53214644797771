const REGEX_PIXELS = /^(-?\d+)px$/
const REGEX_PERCENT = /^(-?\d+(\.\d+)?)%$/

export const enum LengthUnit {
  Pixel = 'pixel',
  Percent = 'percent',
}

export default class Length {
  private constructor(
    public readonly value: number,
    public readonly unit: LengthUnit
  ) {}

  public toExact(relativeBasis: number | undefined): number {
    switch (this.unit) {
      case LengthUnit.Pixel: {
        return this.value
      }
      case LengthUnit.Percent: {
        if (relativeBasis === undefined) {
          return 0
        }

        return this.value * relativeBasis
      }
      default:
        throw new Error(`Unknown unit: ${this.unit as LengthUnit}`)
    }
  }

  public static readonly ZERO = new Length(0, LengthUnit.Pixel)

  public static fromPixels(pixels: number): Length {
    return new Length(pixels, LengthUnit.Pixel)
  }

  public static fromPercent(percent: number): Length {
    return new Length(percent, LengthUnit.Percent)
  }

  public static parse(len: string): Length {
    const pxMatch = REGEX_PIXELS.exec(len)
    if (pxMatch !== null) {
      const [, value] = pxMatch
      if (value === undefined) {
        throw new Error(`Error parsing pixel value ('${len}')`)
      }

      const n = parseInt(value, 10)

      return Length.fromPixels(n)
    }

    const percentMatch = REGEX_PERCENT.exec(len)
    if (percentMatch !== null) {
      const [, value] = percentMatch
      if (value === undefined) {
        throw new Error(`Error parsing percent value ('${len}')`)
      }

      const f = parseFloat(value)

      return Length.fromPercent(f / 100)
    }

    throw new Error(`Parse error: Invalid Length string: '${len}'`)
  }
}
