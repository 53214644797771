import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { SubmissionError } from 'redux-form'

import UpgradeAlertModal from 'components/Shared/UpgradeAlertModal'
import SeatAddonModal from 'components/Settings/Team/SeatAddonModal'
import Page from 'components/Shared/Page'
import Modal from 'components/Shared/Modal'

import { getApp, updateApp } from 'ducks/apps'
import { addAppUser, requestAppUsers } from 'ducks/appUsers'
import { updateCurrentUser, getCurrentUser } from 'ducks/users'
import { searchUsers } from 'ducks/searchUsers'
import {
  createInvite,
  getCurrentOrganization,
  fetchOrganization,
} from 'ducks/organizations'

import { isCurrentPlanType } from 'utils/billing.ts'

import { INVITE_LIMIT_MESSAGE } from '../../../../constants'
import history from '../../../../history'

import InviteForm from './InviteForm'

import '../Settings.scss'

const InviteModal = props => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)

  const [loading, setLoading] = useState(false)
  const [showSeatLimitModal, setShowSeatLimitModal] = useState(false)
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false)
  const [email, setEmail] = useState('')

  const handleSubmit = async ({ email, freelancer }) => {
    const { currentOrganization, app, location } = props

    const isTeamInvite = location.state?.teamInvite

    setLoading(true)

    const { addons } = currentOrganization

    const planType = currentOrganization?.planType
    const members = currentOrganization.Users

    email = email.toLowerCase()

    const sameEmail = members.filter(u => u.email === email)

    if (sameEmail.length > 0) {
      throw new SubmissionError({
        email: `${email} is already part of your team!`,
      })
    }

    const seatLimit = addons.seats.limit
    const seatUsage = addons.seats.count

    if (planType === 'free') {
      setShowUpgradePlanModal(true)
      setEmail(email)
    } else if (isCurrentPlanType(planType) && seatUsage >= seatLimit) {
      setShowSeatLimitModal(true)
      setEmail(email)
    } else {
      await inviteUser(email)

      if (currentUser && freelancer) {
        dispatch(updateCurrentUser(currentUser.id, { client: 'Yes' }))

        if (!isTeamInvite) {
          dispatch(updateApp(app.id, { builtWithFreelancer: true }))
        }
      }
    }

    setLoading(false)
  }

  const inviteUser = async email => {
    const { createInvite, app, requestAppUsers, searchUsers, location } = props

    const isTeamInvite = location.state?.teamInvite

    try {
      if (isTeamInvite) {
        await createInvite({
          email,
          organizationId: app.Organization.id,
        })
      } else {
        await createInvite({
          email,
          organizationId: app.Organization.id,
          appId: app.id,
        })
      }

      await requestAppUsers(app.id)
      await fetchData()
    } catch (err) {
      const status = err.response && err.response.status
      const data = err.response && err.response.data

      let message = (data && data.error) || 'An unknown error occurred'

      if (status === 429) {
        message = INVITE_LIMIT_MESSAGE
      }

      throw new SubmissionError({
        email: message,
      })
    }

    if (isTeamInvite) return history.goBack()

    let users = []

    try {
      users = (await searchUsers(email)).value
      addAppUser(app.id, users[0].id)
      await requestAppUsers(app.id)
    } catch (err) {
      console.log('ERROR:', err)
    }

    closeModal()
  }

  const closeModal = () => {
    const { match, app, requestAppUsers } = props
    requestAppUsers(app.id)
    history.push(`/apps/${match.params.appId}/app-settings?active=access`)

    setShowSeatLimitModal(false)
    setShowUpgradePlanModal(false)
  }

  const fetchData = async () => {
    const { fetchOrganization, currentOrganization } = props

    await fetchOrganization(currentOrganization?.id)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const { app, location } = props

  const title = app?.name
    ? `Invite New Team Member - ${app.name}`
    : 'Invite New Team Member'

  return (
    <Modal onClose={closeModal}>
      <SeatAddonModal
        show={showSeatLimitModal}
        onClose={closeModal}
        callback={async () => {
          await inviteUser(email)
        }}
      />

      <UpgradeAlertModal
        action="Add Editors"
        body="Switch to a paid plan to start collaborating on your app design."
        onClose={closeModal}
        show={showUpgradePlanModal}
      />

      <Page className="accounts-page" title={title}>
        <InviteForm
          isTeamInvite={location.state?.teamInvite}
          currentUser={currentUser}
          onSubmit={handleSubmit}
          onClose={closeModal}
          loading={loading}
        />
      </Page>
    </Modal>
  )
}

const mapStateToProps = (state, { match }) => ({
  app: getApp(state, match.params.appId),
  currentOrganization: getCurrentOrganization(state),
})

export default connect(mapStateToProps, {
  createInvite,
  fetchOrganization,
  searchUsers,
  addAppUser,
  requestAppUsers,
})(InviteModal)
