import { LIBRARY_COMPONENT } from '@adalo/constants'
import ObjectNode from './ObjectNode'
import LayoutContext from './layout/LayoutContext'
import LayoutObject from './layout/LayoutObject'

export interface ElementSize {
  width: number
  height: number
}

export default class LibraryComponentNode extends ObjectNode<
  typeof LIBRARY_COMPONENT
> {
  private readonly libraryName: string
  private readonly getElementSize: (
    // Disable eslint warnings
    // eslint-disable-next-line
    obj: LayoutObject<typeof LIBRARY_COMPONENT>,
    // eslint-disable-next-line
    context: LayoutContext
  ) => ElementSize

  constructor(
    id: string,
    libraryName: string,
    getElementSize: (
      obj: LayoutObject<typeof LIBRARY_COMPONENT>,
      context: LayoutContext
    ) => ElementSize
  ) {
    super(id, LIBRARY_COMPONENT)

    this.libraryName = libraryName
    this.getElementSize = getElementSize
  }

  public override layout(
    context: LayoutContext
  ): LayoutObject<typeof LIBRARY_COMPONENT> {
    const layout = super.layout(context)

    const currentSize = this.getElementSize(layout, context)

    const result = {
      ...layout,
      ...currentSize,
    }

    return result
  }
}
