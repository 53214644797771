import React from 'react'

import Form from './Form'

import './ConfirmRestore.scss'

const ConfirmRestoreModal = ({ onSuccess, onCancel, restoreVersion }) => {
  return (
    <Form onSubmit={restoreVersion} onSuccess={onSuccess} onCancel={onCancel} />
  )
}

export default ConfirmRestoreModal
