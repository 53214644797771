import React, { createContext, useEffect } from 'react'
import {
  Redirect,
  useHistory,
  useParams,
  NavLink,
  Switch,
  Route,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// ducks
import { getCurrentUser } from 'ducks/users/index.ts'
import { getOrganizations, fetchOrganizations } from 'ducks/organizations'
import {
  fetchDeveloperLibraries,
  fetchTestingOrg,
} from 'ducks/marketplace/developerLibraries'
import { fetchDeveloper } from 'ducks/marketplace'
import { getApp } from 'ducks/apps'

// components
import Modal from 'components/Shared/Modal'
import Page from 'components/Shared/Page'
import { IconButton } from 'components/Shared/Icon'

import DeveloperLibraries from './DeveloperLibraries'
import DeveloperShared from './Shared'

// styles
import './developers.scss'

export const DevelopersContext = createContext()

function Developers({ modal, children }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { appId } = useParams()

  const user = useSelector(getCurrentUser)
  const organizations = useSelector(getOrganizations)
  const app = useSelector(state => getApp(state, appId))
  const developer = useSelector(state => state.marketplace.developer)

  useEffect(() => {
    if (!organizations?.length) dispatch(fetchOrganizations())
  }, [])

  useEffect(() => {
    if (user) {
      dispatch(fetchDeveloper(user.id))
      dispatch(fetchDeveloperLibraries(user.id))
      dispatch(fetchTestingOrg(user.id))
    }
  }, [user?.id])

  // redirect user if the current user is not a developer
  if (user && !user.developer) return <Redirect to="/" />

  function handleClose() {
    if (!appId) return history.push('/')

    return history.push(`/apps/${appId}/screens`)
  }

  function getBaseURL() {
    if (appId) return `/apps/${appId}/screens/developers`

    return '/developers'
  }

  const baseURL = getBaseURL()

  function renderContent() {
    return (
      <Page form className="accounts-page" title="Developers">
        <div className="developers-page-close-button">
          <IconButton type="close-big" onClick={handleClose} />
        </div>

        <div className="developers-page-tabs">
          <NavLink
            className="developers-page-tab"
            activeClassName="active"
            to={`${baseURL}/libraries`}
          >
            My Libraries
          </NavLink>
          <NavLink
            className="developers-page-tab"
            activeClassName="active"
            to={`${baseURL}/shared`}
          >
            Shared With Me
          </NavLink>
        </div>

        <div className="developers-page-content">{children}</div>
      </Page>
    )
  }

  if (modal) {
    return (
      <Modal onClose={handleClose} className="developers-modal">
        <DevelopersContext.Provider
          value={{ appId, user, organizations, app, developer }}
        >
          {renderContent()}
        </DevelopersContext.Provider>
      </Modal>
    )
  }

  return (
    <DevelopersContext.Provider
      value={{ appId, user, organizations, app, developer }}
    >
      {renderContent()}
    </DevelopersContext.Provider>
  )
}

export { DeveloperLibraries }
export { DeveloperShared }
export { Developers }

export const DevelopersRoutes = () => {
  return (
    <Developers>
      <Switch>
        <Route
          exact
          path="/developers/libraries"
          component={DeveloperLibraries}
        />
        <Route exact path="/developers/shared" component={DeveloperShared} />
      </Switch>
    </Developers>
  )
}
