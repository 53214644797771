import update from 'immutability-helper'
import { adaloBackendAxios } from 'utils/io/http/axios'

export const LOAD_METRICS = 'LOAD_METRICS'
export const LOAD_METRICS_FULFILLED = 'LOAD_METRICS_FULFILLED'
export const LOAD_APPS_OVER_METRICS = 'LOAD_APPS_OVER_METRICS'
export const LOAD_APPS_OVER_METRICS_FULFILLED =
  'LOAD_APPS_OVER_METRICS_FULFILLED'

export const initialState = {}

export default (state = initialState, action) => {
  if (action?.type === LOAD_METRICS_FULFILLED) {
    const {
      payload,
      meta: { appId },
    } = action

    const newState = update(state, { [appId]: { $set: payload } })

    return newState
  }

  if (action?.type === LOAD_APPS_OVER_METRICS_FULFILLED) {
    const {
      payload,
      meta: { userId },
    } = action

    const newState = update(state, { [userId]: { $set: payload } })

    return newState
  }

  return state
}

export const loadMetrics = (appId, datasourceId) => {
  const url = `/apps/${appId}/databases/${datasourceId}/metrics`
  const payload = adaloBackendAxios.get(url).then(res => res.data)

  return {
    type: LOAD_METRICS,
    meta: { appId },
    payload,
  }
}

export const loadAppsOverMetrics = userId => {
  const baseURL = process.env.REACT_APP_BACKEND_URL
  const url = `${baseURL}/metrics/limit`
  const payload = adaloBackendAxios.get(url).then(res => res.data)

  return {
    type: LOAD_APPS_OVER_METRICS,
    meta: { userId },
    payload,
  }
}

export const getAppsOverMetrics = (state, userId) => state.metrics[userId] || []

export const getMetrics = (state, appId) => state.metrics[appId] || {}
