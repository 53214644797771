import { useMemo } from 'react'
import { deepMerge } from '@adalo/utils'
import { useFlags } from 'flags'

const PLAN_CARDS = {
  order: ['free', 'starter', 'pro', 'team', 'business'],
  cards: {
    free: {
      cardStyle: 'free',
      planType: 'free',
      displayName: 'Free',
      subtitle: 'Perfect for Exploring',
      prices: {
        monthly: 0,
        yearly: 0,
      },
      buttonAlias: 'Free',
      lowerPerks: [
        { text: '1,000 Actions', subscript: '(per month)' },
        {
          text: `${process.env.REACT_APP_RECORD_LIMIT} Records`,
          subscript: '(per app)',
        },
        '1 App Editor',
      ],
      plusText: '',
      upperPerks: [
        'Database + Collections',
        'Unlimited Screens',
        'Unlimited Test Apps',
      ],
    },
    starter: {
      cardStyle: 'starter',
      planType: 'starter',
      planTypeMatch: 'starter2023', // Used to show user on legacy 'starter' as being on a 'current' plan in the upgrade
      displayName: 'Starter',
      subtitle: 'Launching Your MVP',
      prices: {
        monthly: 45,
        yearly: 36,
      },
      buttonAlias: 'Starter',
      lowerPerks: [
        {
          text: '10,000 Actions',
          subscript: '(per month)',
        },
        '1 Published App',
        '1 App Editor',
      ],
      plusText: 'All Free Features',
      upperPerks: ['Custom Fonts', 'Custom Domain'],
    },
    pro: {
      cardStyle: 'pro',
      planType: 'professional',
      displayName: 'Professional',
      subtitle: 'Growing Your Audience',
      prices: {
        monthly: 65,
        yearly: 52,
      },
      buttonAlias: 'Pro',
      lowerPerks: [
        {
          text: '30,000 Actions',
          subscript: '(per month)',
        },
        '2 Published Apps',
        '5 App Editors',
      ],
      plusText: 'All Starter Features',
      upperPerks: [
        'Collections API',
        'Design Versions',
        'Integrations',
        'Geolocation',
      ],
    },
    team: {
      cardStyle: 'team',
      planType: 'team',
      displayName: 'Team',
      subtitle: 'Expanding Your Business',
      prices: {
        monthly: 200,
        yearly: 160,
      },
      buttonAlias: 'Team',
      lowerPerks: [
        {
          text: '100,000 Actions',
          subscript: '(per month)',
        },
        '5 Published Apps',
        '10 App Editors',
      ],
      plusText: 'All Professional Features',
      upperPerks: ['Priority Support', 'Xano Integration'],
    },
    business: {
      cardStyle: 'business',
      planType: 'business2022',
      planTypeMatch: 'business2023',
      displayName: 'Business',
      subtitle: 'Powering Your Work',
      prices: {
        monthly: 250,
        yearly: 200,
      },
      buttonAlias: 'Business',
      lowerPerks: [
        {
          text: '1 Million Actions',
          subscript: '(per month)',
        },
        '10 Published Apps',
        'Unlimited App Editors',
      ],
      plusText: 'All Team Features',
      upperPerks: [],
    },
  },
}

const PLAN_CARDS_STARTER_2023_PATCH = {
  cards: {
    starter: {
      planType: 'starter2023',
      planTypeMatch: 'starter',
      upperPerks: [
        'Web Publishing',
        'Native Mobile Publishing',
        'Custom Fonts',
        'Custom Domain',
      ],
    },
  },
}

const PLAN_CARDS_JUNE_2023_PATCH = {
  cards: {
    business: {
      planType: 'business2023',
      planTypeMatch: 'business2022',
      lowerPerks: [
        {
          text: '500,000 Actions',
          subscript: '(per month)',
        },
        '10 Published Apps',
        'Unlimited App Editors',
      ],
    },
  },
}

const PLAN_CARDS_PROFESSIONAL_2023_PATCH = {
  cards: {
    pro: {
      cardStyle: 'pro',
      planType: 'professional2023',
      planTypeMatch: 'professional',
      upperPerks: ['Design Versions', 'Integrations', 'Geolocation'],
    },
    team: {
      upperPerks: ['Collections API', 'Priority Support', 'Xano Integration'],
    },
  },
}

const PLAN_CARDS_2024_PATCH = {
  cards: {
    starter: {
      planType: 'starter2024',
      planTypeMatch: 'starter2023',
      lowerPerks: [
        {
          text: '5,000 Actions',
          subscript: '(per month)',
        },
        '1 Published App',
        '1 App Editor',
      ],
    },
    pro: {
      planType: 'professional2024',
      planTypeMatch: 'professional2023',
      lowerPerks: [
        {
          text: '15,000 Actions',
          subscript: '(per month)',
        },
        '2 Published Apps',
        '5 App Editors',
      ],
    },
    team: {
      planType: 'team2024',
      planTypeMatch: 'team',
      lowerPerks: [
        {
          text: '50,000 Actions',
          subscript: '(per month)',
        },
        '5 Published Apps',
        '10 App Editors',
      ],
    },
    business: {
      planType: 'business2024',
      planTypeMatch: 'business2023',
      lowerPerks: [
        {
          text: '200,000 Actions',
          subscript: '(per month)',
        },
        '10 Published Apps',
        'Unlimited App Editors',
      ],
    },
  },
}

const PLANS_2024 = [
  'starter2024',
  'professional2024',
  'team2024',
  'business2024',
]

const usePlanCards = currentPlanType => {
  const { hasPlans2024 } = useFlags()
  const planCards = useMemo(() => {
    let mergedPlanCards = { ...PLAN_CARDS }
    let showPlans2024 = false

    if (currentPlanType === 'free') {
      showPlans2024 = hasPlans2024
    } else if (PLANS_2024.includes(currentPlanType)) {
      showPlans2024 = true
    }

    mergedPlanCards = deepMerge(mergedPlanCards, PLAN_CARDS_STARTER_2023_PATCH)

    if (currentPlanType !== 'business2022') {
      mergedPlanCards = deepMerge(mergedPlanCards, PLAN_CARDS_JUNE_2023_PATCH)
    }

    if (currentPlanType !== 'professional') {
      mergedPlanCards = deepMerge(
        mergedPlanCards,
        PLAN_CARDS_PROFESSIONAL_2023_PATCH
      )
    }

    if (showPlans2024) {
      mergedPlanCards = deepMerge(mergedPlanCards, PLAN_CARDS_2024_PATCH)
    }

    return mergedPlanCards
  }, [])

  return planCards
}

export default usePlanCards
