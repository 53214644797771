import update from 'immutability-helper'
import { deepMerge, sort } from '@adalo/utils'

import { addAssociation as add, removeAssociation as remove } from 'utils/io'
import { getSearchText } from '../utils/search'

const LOAD_DATA = Symbol('LOAD_DATA')
const ADD_ASSOCIATION = Symbol('ADD_ASSOCIATION')
const REMOVE_ASSOCIATION = Symbol('REMOVE_ASSOCIATION')
const LOAD_SEARCH = Symbol('LOAD_SEARCH')
const DISPLAY_ERROR = Symbol('DISPLAY_ERROR')
const SAVING_DATA = Symbol('SAVING_DATA')
const LOAD_ASSOCIATION_DATA = Symbol('LOAD_ASSOCIATION_DATA')
const DELETE_DATA = Symbol('DELETE_DATA')
const BULK_DELETE_DATA = Symbol('BULK_DELETE_DATA')

const INITIAL_STATE = {}
const TYPE = '_type'

export default (state = INITIAL_STATE, action) => {
  if (action.type === LOAD_DATA || action.type === LOAD_SEARCH) {
    let { tableId, data } = action

    if (!Array.isArray(data)) {
      const arr = []
      arr.push(data)
      data = arr
    }

    const changes = {}

    for (const itm of data) {
      changes[tableId] = changes[tableId] || {}

      changes[tableId][itm.id] = {
        ...(state[tableId] && state[tableId][itm.id]),
        ...itm,
      }

      for (const columnId of Object.keys(itm)) {
        const column = itm[columnId]

        if (column && column.id && column[TYPE]) {
          changes[column[TYPE]] = changes[column[TYPE]] || {}
          changes[column[TYPE]][column.id] = column
        }
      }
    }

    if (action.type === LOAD_SEARCH) {
      return {
        ...state,
        ...changes,
        [tableId]: changes[tableId] || {},
        savingData: false,
      }
    }

    for (const changeTableId of Object.keys(changes)) {
      state = update(state, {
        [changeTableId]: {
          $set: deepMerge(state[changeTableId], changes[changeTableId]),
        },
      })
    }

    return {
      ...state,
      savingData: false,
    }
  }

  if (action.type === ADD_ASSOCIATION) {
    const { options, item } = action
    add(options)

    const incrementCount = state => {
      return state + 1
    }
    const newState = update(state, {
      [options.tableId]: {
        [options.id]: {
          [`${options.fieldId}_value`]: option =>
            update(option || [], {
              $push: [item],
            }),
          [`${options.fieldId}_count`]: {
            $apply: incrementCount,
          },
        },
      },
    })

    return newState
  }

  if (action.type === REMOVE_ASSOCIATION) {
    const { options } = action
    remove(options)

    const removeObject = state => {
      const index = state.findIndex(
        obj => String(obj.id) === String(options.id2)
      )

      return update(state, { $splice: [[index, 1]] })
    }
    const decrementCount = state => {
      return state - 1
    }

    const newState = update(state, {
      [options.tableId]: {
        [options.id]: {
          [`${options.fieldId}_value`]: {
            $apply: state => removeObject(state),
          },
          [`${options.fieldId}_count`]: {
            $apply: decrementCount,
          },
        },
      },
    })

    return newState
  }

  if (action.type === LOAD_ASSOCIATION_DATA) {
    const { fieldId, data } = action

    return {
      ...state,
      [fieldId]: data,
    }
  }

  if (action.type === SAVING_DATA) {
    return {
      ...state,
      savingData: true,
    }
  }

  if (action.type === DISPLAY_ERROR) {
    const { error } = action

    return {
      ...state,
      error,
      savingData: false,
    }
  }

  if (action.type === DELETE_DATA) {
    const { tableId, id } = action

    const data = { ...state[tableId] }

    delete data[id]

    return {
      ...state,
      [tableId]: data,
    }
  }

  if (action.type === BULK_DELETE_DATA) {
    const { tableId, blockedList, idList } = action
    const data = { ...state[tableId] }

    if (blockedList) {
      for (let id in data) {
        if (!Number.isNaN(parseInt(id))) {
          id = parseInt(id)

          if (!idList.includes(id)) {
            delete data[id]
          }
        }
      }
    } else {
      for (const id of idList) {
        delete data[id]
      }
    }

    return {
      ...state,
      [tableId]: data,
    }
  }

  return state
}

// ACTIONS

export const displayError = error => ({
  type: DISPLAY_ERROR,
  error,
})

export const loadData = (tableId, data) => ({
  type: LOAD_DATA,
  tableId,
  data,
})

export const loadSearch = (tableId, data) => ({
  type: LOAD_SEARCH,
  tableId,
  data,
})

export const savingDataObject = (tableId, data) => ({
  type: SAVING_DATA,
})

export const loadAssociationData = (fieldId, data) => ({
  type: LOAD_ASSOCIATION_DATA,
  fieldId,
  data,
})

export const deleteData = (tableId, id) => ({
  type: DELETE_DATA,
  tableId,
  id,
})

export const bulkDeleteData = (tableId, blockedList, idList) => ({
  type: BULK_DELETE_DATA,
  tableId,
  blockedList,
  idList,
})

export const addAssociation = (options, item) => ({
  type: ADD_ASSOCIATION,
  options,
  item,
})

export const removeAssociation = (options, tableId) => ({
  type: REMOVE_ASSOCIATION,
  options,
  tableId,
})

// SELECTORS

export const getData = (state, tableId, allowNull = false) => {
  let objects = state.datasources[tableId]

  if (!objects && allowNull) {
    return null
  }

  objects = objects || {}

  return sort(
    Object.keys(objects).map(k => objects[k]),
    obj => obj.created_at
  ).reverse()
}

export const getAssociationObject = (state, tableId, fieldId, primaryField) => {
  let associationObject = []

  Object.keys(state.datasources).forEach(() => {
    const targetTable = state.datasources[tableId]

    if (targetTable) {
      Object.keys(targetTable).forEach(field => {
        const targetField = targetTable[field][`${fieldId}_value`]

        if (targetField && targetField.length > 0) {
          associationObject = targetField.map(key => ({
            value: key.id,
            label: key[primaryField],
          }))
        }
      })
    }
  })

  return associationObject
}

export const getDataObject = (state, tableId, id) => {
  const objects = state.datasources[tableId] || {}

  return objects[id]
}

export const searchData = (state, tableId, query) => {
  let data = getData(state, tableId)

  for (const term of query.trim().split(/\s+/)) {
    data = data.filter(
      itm => getSearchText(itm).indexOf(term.toLowerCase()) >= 0
    )
  }

  return data
}
