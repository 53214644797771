import { useState, useEffect } from 'react'
import FeatureOption from './FeatureOption'
import { FeatureValue, featureValues } from './features'

const FeatureGrid = ({
  selectedFeature,
  selectFeature,
}: {
  selectedFeature: FeatureValue
  selectFeature: (feature: FeatureValue) => void
}): JSX.Element => {
  const [featureList, setFeatureList] = useState(featureValues)

  useEffect(() => {
    if (!selectedFeature) {
      const unsortedArray = [...featureValues]
      const randomizedFeatureList: FeatureValue[] = []

      while (unsortedArray.length > 0) {
        const index = Math.floor(Math.random() * unsortedArray.length)

        randomizedFeatureList.push(unsortedArray[index] as FeatureValue)
        unsortedArray.splice(index, 1)
      }

      setFeatureList(randomizedFeatureList)
    }
  }, [])

  const firstHalf = featureList.slice(0, 3)
  const secondHalf = featureList.slice(-3)

  return (
    <div className="premium-feature-grid">
      <div className="premium-feature-column">
        {firstHalf.map(feature => (
          <FeatureOption
            feature={feature}
            selectedFeature={selectedFeature}
            selectFeature={selectFeature}
            key={feature}
          />
        ))}
      </div>
      <div className="premium-feature-column">
        {secondHalf.map(feature => (
          <FeatureOption
            feature={feature}
            selectedFeature={selectedFeature}
            selectFeature={selectFeature}
            key={feature}
          />
        ))}
      </div>
    </div>
  )
}

export default FeatureGrid
