import { GROUP } from '@adalo/constants'
import { insert, remove, getObject, remapSiblings } from '@adalo/utils'
import calculatePushGraphs from 'ducks/editor/pushing/calculatePushGraphs'
import { EditorObject } from 'utils/responsiveTypes'
import InstructionState from '../types/InstructionState'
import { refreshLeftRightForObjects } from './refreshLeftRight'

export interface UngroupObjectsOptions {
  objectIds: string[]
}

export interface UngroupObjectsInstruction {
  operation: 'ungroupObjects'
  options: UngroupObjectsOptions
}

export const ungroupObjectsHandler = (
  state: InstructionState,
  { objectIds }: UngroupObjectsOptions
): InstructionState => {
  const { list, pathMap } = state

  let updatedList = [...list]
  let updatedPathMap = { ...pathMap }

  const paths = objectIds.map(id => updatedPathMap[id])
  const objects = paths
    .map(path => getObject(list, path) as EditorObject)
    .filter(obj => obj.type === GROUP)

  const selection: string[] = []

  objects.forEach(group => {
    const { id, children } = group
    const path = String(updatedPathMap[id])

    if (children) {
      selection.push(...children.map(c => c.id))
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    updatedList = remove(updatedList, path)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    updatedList = insert(updatedList, path, ...(children ?? []))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    updatedPathMap = remapSiblings(updatedList, updatedPathMap, '0')

    const pieces = path.split('.')
    const screenIndex = pieces[0]

    if (!screenIndex) {
      throw new Error('No screenIndex available')
    }

    const screen = list[Number(screenIndex)]

    if (!screen) {
      throw new Error('No screen available')
    }

    updatedList = calculatePushGraphs(updatedList, updatedPathMap, screen.id)
  })

  return {
    ...state,
    list: refreshLeftRightForObjects(updatedList, updatedPathMap, selection),
    pathMap: updatedPathMap,
    selection,
  }
}

const ungroupObjects = (objectIds: string[]): UngroupObjectsInstruction => {
  return {
    operation: 'ungroupObjects',
    options: {
      objectIds,
    },
  }
}

export default ungroupObjects
