import React, { Component } from 'react'
import classNames from 'classnames'
import ColorDropdown from '../../Editor/Inspect/ColorDropdown'
import { defaultBranding } from '../../../utils/colors'

export default class WrappedColorDropdown extends Component {
  handleChange = ({ fakename: value }) => {
    const {
      input: { onChange },
    } = this.props

    onChange(value)
  }

  render() {
    const {
      className,
      label,
      input: { value, onBlur, onFocus },
    } = this.props

    return (
      <div className={classNames('wrapped-color-dropdown', className)}>
        <ColorDropdown
          branding={defaultBranding}
          title={label}
          normalize={false}
          value={value}
          name="fakename"
          onChange={this.handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    )
  }
}
