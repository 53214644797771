import React from 'react'
import { halveWidth } from 'utils/positioning'
import { scaleValue } from '../../../../utils/zoom'

import Section from '../Section'
import Rect from '../Section/Rect'

export default class FilePicker extends Section {
  render() {
    let {
      xScaled,
      yScaled,
      width,
      height,
      widthScaled,
      heightScaled,
      color,
      zoom,
    } = this.props

    const fontSize = scaleValue(14, zoom)
    color = color || '#06f'

    const textStyles = {
      fontSize,
      fill: color,
    }

    const groupTransform = `translate(${xScaled}, ${yScaled})`

    const wrapperStyles = this.getAttributeStyles()
    const x = halveWidth(widthScaled)

    const defaultRectProps = {
      width,
      height,
      zoom,
      widthScaled,
      heightScaled,
    }

    return (
      <g
        transform={groupTransform}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        style={wrapperStyles}
      >
        <Rect
          {...defaultRectProps}
          {...this.getBorderProps()}
          x={0}
          y={0}
          fill="transparent"
        />
        <text
          x={x}
          y={(heightScaled + fontSize) / 2}
          textAnchor="middle"
          style={textStyles}
        >
          Choose File
        </text>
      </g>
    )
  }
}
