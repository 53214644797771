import { mergeReducers } from '@adalo/utils/lib/reducers'
import update from 'immutability-helper'

import { marketplaceAxios } from 'utils/io/http/axios'
import licensesReducer from './licenses'
import accessTokenReducer from './accessTokens'
import developerLibrariesReducer from './developerLibraries'

// * types
const FETCH_MARKETPLACE_COMPONENT = 'FETCH_MARKETPLACE_COMPONENT'
const FETCH_MARKETPLACE_COMPONENTS = 'FETCH_MARKETPLACE_COMPONENTS'
const UPDATE_LIBRARIES = Symbol('UPDATE_LIBRARIES')
const UPDATE_CHECKOUT_LIBRARYID = Symbol('UPDATE_CHECKOUT_LIBRARYID')
const SET_FEED_SCROLL = Symbol('SET_FEED_SCROLL')
const SET_CATEGORY = Symbol('SET_CATEGORY')
const FETCH_DEVELOPER = 'FETCH_DEVELOPER'
const SET_SORTING = Symbol('SET_SORTING')
const FETCH_TOP_MARKETPLACE_COMPONENTS = 'FETCH_TOP_MARKETPLACE_COMPONENTS'

// * status
const loading = 'loading'
const ready = 'ready'
const idle = 'idle'

// sorting
export const SORTING_POPULAR = 'POPULAR'
export const SORTING_RECENTLY_CREATED = 'RECENTLY_CREATED'

// filtering
export const FILTER_ALL = 'all'
export const FILTER_FREE = 'free'
export const FILTER_PAID = 'paid'

const initialState = {
  status: loading,
  components: [],
  installed: [],
  private: [],
  admin: [],
  checkoutLibraryId: null,
  licenses: {
    appLicenses: {},
    orgLicenses: {},
  },
  accessTokens: {},
  developerLibraries: {
    status: idle,
    libraries: null,
    error: null,
  },
  feedScroll: 0,
  selectedCategory: 'all',
  developer: undefined,
  sorting: SORTING_POPULAR,
  topComponents: [],
}

// REDUCER
export default mergeReducers(
  initialState,
  licensesReducer,
  accessTokenReducer,
  developerLibrariesReducer,
  (state, action) => {
    const { type } = action

    switch (type) {
      case `${FETCH_MARKETPLACE_COMPONENT}_PENDING`:
      case `${FETCH_MARKETPLACE_COMPONENTS}_PENDING`: {
        if (state.components.length > 0) return state

        return {
          ...state,
          status: loading,
        }
      }
      case `${FETCH_MARKETPLACE_COMPONENT}_FULFILLED`: {
        const { data } = action.payload

        const newData = [data]

        return {
          ...state,
          status: ready,
          components: update(state.components, { $push: newData }),
        }
      }
      case `${FETCH_TOP_MARKETPLACE_COMPONENTS}_FULFILLED`: {
        const { data } = action.payload

        return {
          ...state,
          topComponents: data,
        }
      }
      case `${FETCH_MARKETPLACE_COMPONENTS}_FULFILLED`: {
        const { data } = action.payload
        const { components } = state

        let newData = []

        if (!components.length) {
          newData = data
        } else {
          data.forEach(component => {
            const { id } = component

            components.forEach(c => {
              if (c.id === id) return null

              return update(newData, { $push: [component] })
            })
          })
        }

        return {
          ...state,
          status: ready,
          components: update(state.components, { $push: newData }),
        }
      }
      case UPDATE_LIBRARIES: {
        const { payload } = action

        if (payload.type) {
          return { ...state, [payload.type]: payload.libraries }
        }

        return {
          ...state,
          installed: payload.libraries,
        }
      }
      case UPDATE_CHECKOUT_LIBRARYID: {
        const { payload } = action
        const { libraryId } = payload

        return {
          ...state,
          checkoutLibraryId: libraryId || null,
        }
      }
      case SET_FEED_SCROLL: {
        const { payload } = action

        return {
          ...state,
          feedScroll: payload.scroll,
        }
      }
      case SET_CATEGORY: {
        const { payload } = action

        return {
          ...state,
          selectedCategory: payload.category,
        }
      }
      case `${FETCH_DEVELOPER}_FULFILLED`: {
        return {
          ...state,
          developer: action.payload.data,
        }
      }
      case SET_SORTING: {
        const components = [...state.components]

        if (action.payload.sorting === state.sorting) {
          return state
        }

        switch (action.payload.sorting) {
          default:
          case SORTING_POPULAR: {
            components.sort((a, b) => b.installs - a.installs)

            break
          }
          case SORTING_RECENTLY_CREATED: {
            components.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            )

            break
          }
        }

        return {
          ...state,
          components,
          sorting: action.payload.sorting,
        }
      }

      default:
        return state
    }
  }
)

// ACTIONS
export const getMarketplaceComponents = orgId => ({
  type: FETCH_MARKETPLACE_COMPONENTS,
  payload: marketplaceAxios.get(`/api/libraries/listings`, {
    headers: { orgId },
  }),
})

export const fetchTopMarketplaceComponents = () => ({
  type: FETCH_TOP_MARKETPLACE_COMPONENTS,
  payload: marketplaceAxios.get(`/api/libraries/top`),
})

export const getComponent = id => ({
  type: FETCH_MARKETPLACE_COMPONENT,
  payload: marketplaceAxios.get(`/api/libraries/${id}`),
})

export const updateLibraries = (libraries, type) => ({
  type: UPDATE_LIBRARIES,
  payload: { type, libraries },
})

export const setCheckoutLibraryId = libraryId => ({
  type: UPDATE_CHECKOUT_LIBRARYID,
  payload: {
    libraryId,
  },
})

export const resetCheckoutLibraryId = () => ({
  type: UPDATE_CHECKOUT_LIBRARYID,
  payload: {},
})

export const setFeedScroll = scroll => ({
  type: SET_FEED_SCROLL,
  payload: {
    scroll,
  },
})

export const setSelectedCategory = category => ({
  type: SET_CATEGORY,
  payload: {
    category,
  },
})

export const fetchDeveloper = userId => ({
  type: FETCH_DEVELOPER,
  payload: marketplaceAxios.get(`/api/users/${userId}`),
})

export const setSorting = value => ({
  type: SET_SORTING,
  payload: {
    sorting: value,
  },
})

// SELECTORS
export const selectMarketplace = state => {
  const { marketplace } = state

  return marketplace
}

export const selectComponents = state => {
  const { marketplace } = state
  const { components } = marketplace

  return components
}

export const getTopComponents = state => {
  const { marketplace } = state
  const { topComponents } = marketplace

  return topComponents
}

export const getInstalledLibraries = state => {
  return state.marketplace.installed
}

export const getPrivateLibraries = state => {
  return state.marketplace.private
}

export const getAdminLibraries = state => {
  return state.marketplace.admin
}

export const getCheckoutLibraryId = state => {
  return state.marketplace.checkoutLibraryId
}

export const getLibraryById = (state, libraryId) => {
  if (!libraryId || state.marketplace.components.length === 0) {
    return null
  } else {
    return state.marketplace.components.find(lib => lib.id === libraryId)
  }
}

export const getFeedScroll = state => {
  return state.marketplace.feedScroll
}

export const getSelectedCategory = state => {
  return state.marketplace.selectedCategory
}
