import React, { Component } from 'react'
import classNames from 'classnames'

import './OptionControl.css'

export default class OptionControl extends Component {
  handleClick = option => e => {
    const { name, onChange } = this.props
    const { value } = option

    return onChange({ [name]: value })
  }

  render() {
    let { value, defaultValue, options } = this.props

    if (value === undefined) {
      value = defaultValue
    }

    return (
      <div className="option-control">
        {options.map(opt => (
          <div
            key={opt.value}
            className={classNames('option-control-option', {
              'option-control-option-selected': value === opt.value,
            })}
            onClick={this.handleClick(opt)}
          >
            {opt.label}
          </div>
        ))}
      </div>
    )
  }
}
