/* eslint no-multi-spaces: 0 */

import { getObjectsInRect } from '@adalo/utils'

const centerOffset = 25
const arrowHeight = 6
const arrowHalfWidth = 3.5
const minZoomScale = 0.25
const arrowBuffer = arrowHeight / minZoomScale
const topOffset = 20

interface Rect {
  x: number
  y: number
  width: number
  height: number
}

const centerX = (obj: Rect): number => obj.x + obj.width / 2
const centerY = (obj: Rect): number => obj.y + obj.height / 2
const endX = (obj: Rect): number => obj.x + obj.width
const endY = (obj: Rect): number => obj.y + obj.height

export const getOrientation = (
  obj1: Rect,
  obj2: Rect
): [string, number, number] => {
  const separations = [
    ['right', obj2.x - (obj1.x + obj1.width), centerY(obj1) - centerY(obj2)],
    ['left', obj1.x - (obj2.x + obj2.width), centerY(obj1) - centerY(obj2)],
    ['down', obj2.y - (obj1.y + obj1.height), centerX(obj1) - centerX(obj2)],
    ['up', obj1.y - (obj2.y + obj2.height), centerX(obj1) - centerX(obj2)],
  ] as [
    [string, number, number],
    [string, number, number],
    [string, number, number],
    [string, number, number]
  ]

  let max = separations[0]

  for (const itm of separations) {
    if (itm[1] > max[1]) {
      max = itm
    }
  }

  return max
}

export const getPathData = (obj1: Rect, obj2: Rect): string => {
  // If overlapping, return empty
  if (!obj1 || !obj2 || getObjectsInRect([obj1], obj2).length !== 0) {
    return ''
  }

  const orientation = getOrientation(obj1, obj2)
  let startPoint: [number, number]
  let otherPoints: [[number, number], [number, number], [number, number]]
  let endPoint: [number, number]

  const handleDist =
    Math.max(Math.abs(orientation[1]), Math.abs(orientation[2])) / 3

  switch (orientation[0]) {
    case 'down':
      startPoint = [centerX(obj1) - centerOffset, endY(obj1)]
      otherPoints = [
        [centerX(obj1) - centerOffset, endY(obj1) + handleDist],
        [
          centerX(obj2) - centerOffset,
          obj2.y + topOffset - arrowBuffer - handleDist,
        ],
        [centerX(obj2) - centerOffset, obj2.y + topOffset - arrowBuffer],
      ]
      endPoint = [centerX(obj2) - centerOffset, obj2.y + topOffset]

      break

    case 'up':
      startPoint = [centerX(obj1) + centerOffset, obj1.y + topOffset]
      otherPoints = [
        [centerX(obj1) + centerOffset, obj1.y - handleDist + topOffset],
        [centerX(obj2) + centerOffset, endY(obj2) + arrowBuffer + handleDist],
        [centerX(obj2) + centerOffset, endY(obj2) + arrowBuffer],
      ]
      endPoint = [centerX(obj2) + centerOffset, endY(obj2)]

      break

    case 'right':
      startPoint = [endX(obj1), centerY(obj1) - centerOffset]
      otherPoints = [
        [endX(obj1) + handleDist, centerY(obj1) - centerOffset],
        [obj2.x - arrowBuffer - handleDist, centerY(obj2) - centerOffset],
        [obj2.x - arrowBuffer, centerY(obj2) - centerOffset],
      ]
      endPoint = [obj2.x, centerY(obj2) - centerOffset]

      break

    case 'left':
      startPoint = [obj1.x, centerY(obj1) + centerOffset]
      otherPoints = [
        [obj1.x - handleDist, centerY(obj1) + centerOffset],
        [endX(obj2) + arrowBuffer + handleDist, centerY(obj2) + centerOffset],
        [endX(obj2) + arrowBuffer, centerY(obj2) + centerOffset],
      ]
      endPoint = [endX(obj2), centerY(obj2) + centerOffset]

      break

    default:
      console.error('Invalid orientation')

      return ''
  }

  const curveString = otherPoints.map(itm => itm.join(' ')).join(', ')

  return `M ${startPoint.join(' ')} C ${curveString} L ${endPoint.join(' ')}`
}

export const getArrowPath = (
  obj1: Rect,
  obj2: Rect,
  zoomScale: number
): string => {
  if (!obj1 || !obj2 || getObjectsInRect([obj1], obj2).length !== 0) {
    return ''
  }

  const orientation = getOrientation(obj1, obj2)

  let points: [[number, number], [number, number], [number, number]]
  let anchor: [number, number]
  const limitedZoomScale = Math.max(zoomScale, minZoomScale)
  const scaledArrowHeight = arrowHeight / limitedZoomScale
  const scaledArrowHalfWidth = arrowHalfWidth / limitedZoomScale

  switch (orientation[0]) {
    case 'right':
      anchor = [obj2.x, centerY(obj2) - centerOffset]

      points = [
        anchor,
        [anchor[0] - scaledArrowHeight, anchor[1] - scaledArrowHalfWidth],
        [anchor[0] - scaledArrowHeight, anchor[1] + scaledArrowHalfWidth],
      ]

      break

    case 'left':
      anchor = [endX(obj2), centerY(obj2) + centerOffset]

      points = [
        anchor,
        [anchor[0] + scaledArrowHeight, anchor[1] - scaledArrowHalfWidth],
        [anchor[0] + scaledArrowHeight, anchor[1] + scaledArrowHalfWidth],
      ]

      break

    case 'down':
      anchor = [centerX(obj2) - centerOffset, obj2.y + topOffset]

      points = [
        anchor,
        [anchor[0] - scaledArrowHalfWidth, anchor[1] - scaledArrowHeight],
        [anchor[0] + scaledArrowHalfWidth, anchor[1] - scaledArrowHeight],
      ]

      break

    case 'up':
      anchor = [centerX(obj2) + centerOffset, endY(obj2)]

      points = [
        anchor,
        [anchor[0] - scaledArrowHalfWidth, anchor[1] + scaledArrowHeight],
        [anchor[0] + scaledArrowHalfWidth, anchor[1] + scaledArrowHeight],
      ]

      break
    default:
      console.error('Invalid orientation')

      return ''
  }

  return `M ${points.map(p => p.join(' ')).join(' L ')} Z`
}
