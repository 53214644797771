import { useMemo } from 'react'

interface RectProps {
  className: string
  width: number
  height: number
  zoom: { scale: number }
  widthScaled: number
  heightScaled: number
  x: number
  y: number
  fill: string
  fillOpacity: number
  strokeWidth: number
  stroke: string
  strokeDasharray: string
  mask: string
  filter: string
  borderRadius: number
  borderTopLeftRadius: number
  borderTopRightRadius: number
  borderBottomRightRadius: number
  borderBottomLeftRadius: number
}

type BorderRadiusProps = {
  borderTopLeftRadius: number
  borderTopRightRadius: number
  borderBottomLeftRadius: number
  borderBottomRightRadius: number
}

export const getBorderRadiusProps = ({
  width,
  height,
  zoom,
  rawBorderRadius,
  rawBorderTopLeftRadius,
  rawBorderTopRightRadius,
  rawBorderBottomLeftRadius,
  rawBorderBottomRightRadius,
}: {
  width: number
  height: number
  zoom: { scale: number }
  rawBorderRadius: number
  rawBorderTopLeftRadius: number
  rawBorderTopRightRadius: number
  rawBorderBottomLeftRadius: number
  rawBorderBottomRightRadius: number
}): BorderRadiusProps => {
  const normalizeRadius = (radius: number): number => {
    if (radius < 0) {
      return 0
    }

    return (Math.min(Math.min(radius, height / 2), width / 2) || 0) * zoom.scale
  }

  return {
    borderTopLeftRadius: normalizeRadius(
      rawBorderTopLeftRadius ?? rawBorderRadius
    ),
    borderTopRightRadius: normalizeRadius(
      rawBorderTopRightRadius ?? rawBorderRadius
    ),
    borderBottomLeftRadius: normalizeRadius(
      rawBorderBottomLeftRadius ?? rawBorderRadius
    ),
    borderBottomRightRadius: normalizeRadius(
      rawBorderBottomRightRadius ?? rawBorderRadius
    ),
  }
}

const createRectanglePath = ({
  widthScaled,
  heightScaled,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
}: {
  widthScaled: number
  heightScaled: number
  borderTopLeftRadius: number
  borderTopRightRadius: number
  borderBottomRightRadius: number
  borderBottomLeftRadius: number
}) => {
  // via https://stackoverflow.com/a/76647032/22689705
  return [
    // Move to the start point, considering top left radius
    `M ${borderTopLeftRadius} 0`,
    // Draw a horizontal line to the top right corner, considering top right radius
    `H ${widthScaled - borderTopRightRadius}`,
    // Draw an arc for top right corner if radius is greater than 0
    borderTopRightRadius > 0
      ? `A ${borderTopRightRadius} ${borderTopRightRadius} 0 0 1 ${widthScaled} ${borderTopRightRadius}`
      : null,
    // Draw a vertical line to the bottom right corner, considering bottom right radius
    `V ${heightScaled - borderBottomRightRadius}`,
    // Draw an arc for bottom right corner if radius is greater than 0
    borderBottomRightRadius > 0
      ? `A ${borderBottomRightRadius} ${borderBottomRightRadius} 0 0 1 ${
          widthScaled - borderBottomRightRadius
        } ${heightScaled}`
      : null,
    // Draw a horizontal line to the bottom left corner, considering bottom left radius
    `H ${borderBottomLeftRadius}`,
    // Draw an arc for bottom left corner if radius is greater than 0
    borderBottomLeftRadius > 0
      ? `A ${borderBottomLeftRadius} ${borderBottomLeftRadius} 0 0 1 0 ${
          heightScaled - borderBottomLeftRadius
        }`
      : null,
    // Draw a vertical line to the top left corner, considering top left radius
    `V ${borderTopLeftRadius}`,
    // Draw an arc for top left corner if radius is greater than 0
    borderTopLeftRadius > 0
      ? `A ${borderTopLeftRadius} ${borderTopLeftRadius} 0 0 1 ${borderTopLeftRadius} 0`
      : null,
    // Close the path
    'Z',
  ]
    .filter(v => v != null)
    .join(' ')
}

const Rect: React.FC<RectProps> = props => {
  const {
    className,
    width,
    height,
    zoom,
    widthScaled,
    heightScaled,
    borderRadius: rawBorderRadius,
    borderTopLeftRadius: rawBorderTopLeftRadius,
    borderTopRightRadius: rawBorderTopRightRadius,
    borderBottomLeftRadius: rawBorderBottomLeftRadius,
    borderBottomRightRadius: rawBorderBottomRightRadius,
    x,
    y,
    fill,
    fillOpacity,
    strokeWidth,
    stroke,
    strokeDasharray,
    mask,
    filter,
  } = props

  const rect = useMemo(() => {
    const {
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
    } = getBorderRadiusProps({
      width,
      height,
      zoom,
      rawBorderRadius,
      rawBorderTopLeftRadius,
      rawBorderTopRightRadius,
      rawBorderBottomRightRadius,
      rawBorderBottomLeftRadius,
    })

    return (
      <path
        className={className}
        d={createRectanglePath({
          widthScaled,
          heightScaled,
          borderTopLeftRadius,
          borderTopRightRadius,
          borderBottomRightRadius,
          borderBottomLeftRadius,
        })}
        transform={`translate(${x} ${y})`}
        fill={fill}
        fillOpacity={fillOpacity}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        mask={mask}
        filter={filter}
      />
    )
  }, [
    className,
    width,
    height,
    zoom,
    widthScaled,
    heightScaled,
    rawBorderRadius,
    rawBorderTopLeftRadius,
    rawBorderTopRightRadius,
    rawBorderBottomLeftRadius,
    rawBorderBottomRightRadius,
    x,
    y,
    fill,
    fillOpacity,
    strokeWidth,
    stroke,
    strokeDasharray,
    mask,
    filter,
  ])

  return rect
}

export default Rect
