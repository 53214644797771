import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface AlignToSelectionLeftInstruction {
  operation: 'alignToSelectionLeft'
  options: ActionOptions
}

export const alignToSelectionLeftHandler = ({
  bounds,
  objects,
}: HandlerOptions): LayoutInstruction[] =>
  objects.map(object => moveElement(object.id, bounds.left, object.y))

const alignToSelectionLeft = (
  objectIds: string[]
): AlignToSelectionLeftInstruction => ({
  operation: 'alignToSelectionLeft',
  options: { objectIds },
})

export default alignToSelectionLeft
