import React from 'react'
import PropTypes from 'prop-types'
import { bindingTypes } from '@adalo/constants'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import { getLabel } from 'utils/sources'

import {
  getComponentId,
  getCurrentAppId,
  selectObject,
} from 'ducks/editor/objects'

import { getBindingSuggestions } from 'ducks/recommender'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'

import Binding from './Binding'

const Form = ({
  appId,
  componentId,
  objectId,
  actionId,
  reference,
  options,
  binding,
  label,
  field,
  menuTheme,
}) => {
  return (
    <div className="conditional-actions-wrapper">
      <p>This action will only happen if...</p>
      <Field
        name={`${field}.source`}
        component={WrappedSelect}
        options={options}
        getLabel={() => label}
        menuTheme={menuTheme}
      />
      {binding?.source ? (
        <Binding
          appId={appId}
          componentId={componentId}
          objectId={objectId}
          actionId={actionId}
          reference={reference}
          binding={binding}
          field={field}
          menuTheme={menuTheme}
        />
      ) : null}
    </div>
  )
}

Form.propTypes = {
  appId: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
}

const mapStateToProps = (state, props) => {
  const { binding, objectId, actionId, reference, helpers } = props
  const appId = getCurrentAppId(state)
  const componentId = getComponentId(state, objectId)

  const object = selectObject(state, objectId)

  return {
    appId,
    componentId,
    label:
      Object.entries(binding).length > 0
        ? getLabel(state, binding?.source, appId, componentId, true)
        : null,
    options: getBindingSuggestions(
      state,
      appId,
      componentId,
      objectId,
      !!binding,
      bindingTypes.LIBRARY_PROP,
      actionId,
      reference || object?.dataBinding,
      helpers
    )(),
  }
}

export default withPremiumFeatureHandler(connect(mapStateToProps)(Form))
