import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError, change } from 'redux-form'

import { updateAccount } from 'utils/io'

import { getCurrentUser } from 'ducks/users/index.ts'

import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'
import Button from 'components/Shared/Button'
import {
  getCurrentOrganization,
  setCurrentOrganization,
  updateOrganizationName,
} from '../../../ducks/organizations'

import AccountForm, { FORM_NAME } from './AccountForm'
import TeamNameForm from './TeamNameForm'

class Account extends Component {
  state = {
    successBanner: false,
    error: null,
    passwordVisible: false,
    formVisible: false,
    teamFormVisible: false,
    formDirty: false,
  }

  togglePassword = event => {
    event.preventDefault()
    this.setState({ passwordVisible: true })
  }

  toggleForm = event => {
    event.preventDefault()
    this.setState({ formVisible: true })
  }

  toggleTeamNameForm = event => {
    event.preventDefault()

    this.setState({ teamFormVisible: true })
  }

  dirtyForm = dirty => {
    this.setState({ formDirty: dirty })
  }

  closeForm = () => {
    this.setState({ passwordVisible: false, formVisible: false })
  }

  closeTeamNameForm = () => {
    this.setState({ teamFormVisible: false })
  }

  handleSubmit = async accountData => {
    const { change } = this.props

    try {
      await updateAccount(accountData)

      window.setTimeout(() => this.setState({ successBanner: false }), 5000)

      this.setState({
        successBanner: true,
        error: null,
        passwordVisible: false,
        formVisible: false,
      })

      change(FORM_NAME, 'password', '')
      change(FORM_NAME, 'oldPassword', '')
    } catch (err) {
      if (err.errors) {
        this.setState({ successBanner: false, error: err.errors })
        throw new SubmissionError(err.errors)
      } else if (err.message) {
        this.setState({ successBanner: false, error: err.message })
        throw new SubmissionError({ _error: err.message })
      }
    }
  }

  handleChangeTeamName = async ({ name }) => {
    const { currentOrganization, updateOrganizationName } = this.props

    await updateOrganizationName(currentOrganization.id, name)

    this.closeTeamNameForm()
  }

  render() {
    const { user, currentOrganization } = this.props

    const {
      successBanner,
      error,
      passwordVisible,
      formVisible,
      teamFormVisible,
      formDirty,
    } = this.state

    if (!user) {
      return (
        <EmptyState greedy>
          <Loading />
        </EmptyState>
      )
    }

    const { email, name, developer } = user

    const initialValues = {
      email,
      name,
      developer,
    }

    return (
      <div className="account-settings">
        <>
          <h3 className="settings-header">Team</h3>
          <div className="settings-content-box">
            {!teamFormVisible && (
              <>
                <div className="settings-content-row">
                  <p className="settings-content-label">Team Name</p>
                  <h4>{currentOrganization?.name}</h4>
                </div>
              </>
            )}
            <TeamNameForm
              form={`teamName-${currentOrganization?.id}`}
              teamFormVisible={teamFormVisible}
              toggleTeamNameForm={this.toggleTeamNameForm}
              closeTeamNameForm={this.closeTeamNameForm}
              onSubmit={this.handleChangeTeamName}
              initialValues={{ name: currentOrganization?.name }}
              isAdmin={currentOrganization?.OrganizationUser?.isAdmin}
            />
          </div>
        </>

        <h3 className="settings-header">Profile</h3>
        {successBanner && (
          <div className="settings-form-alert settings-save-success">
            Successfully saved!
          </div>
        )}
        {error && typeof error === 'string' ? (
          <div className="settings-form-alert">{error}</div>
        ) : null}
        <div className="settings-content-box">
          {!formVisible && (
            <>
              <div className="settings-content-row">
                <p className="settings-content-label">Email Address</p>
                <h4>{initialValues?.email}</h4>
              </div>
              <div className="settings-content-row">
                <p className="settings-content-label">Your Name</p>
                <h4>{initialValues?.name}</h4>
              </div>
            </>
          )}
          <AccountForm
            onSubmit={this.handleSubmit}
            dirtyForm={this.dirtyForm}
            closeForm={this.closeForm}
            initialValues={initialValues}
            organization={currentOrganization}
            passwordVisible={passwordVisible}
            formVisible={formVisible}
          />
          {!passwordVisible && !formVisible && !formDirty && (
            <div className="setting-modal-button-row">
              <Button small outlined teal onClick={this.toggleForm}>
                Edit
              </Button>
              <Button small outlined teal onClick={this.togglePassword}>
                Reset Password
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: getCurrentUser(state),
  currentOrganization: getCurrentOrganization(state),
})

export default connect(mapStateToProps, {
  change,
  updateOrganizationName,
  setCurrentOrganization,
})(Account)
