import React from 'react'
import WrappedTextarea from 'components/Shared/Forms/WrappedTextarea'

export default class WrappedTextInput extends WrappedTextarea {
  render() {
    const { input, meta, ...props } = this.props

    return <input {...props} {...input} onKeyDown={this.handleKeyDown} />
  }
}
