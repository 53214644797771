import { COMPONENT, DeviceType as DeviceTypeMap } from '@adalo/constants'
import DeviceType from 'ducks/editor/types/DeviceType'
import ObjectType from 'ducks/editor/types/ObjectType'
import { merge } from 'lodash'
import {
  EditorObject,
  LabelAttributes,
  PositioningType,
  PushGraph,
  Responsivity,
  TableAttributes,
} from 'utils/responsiveTypes'
import { defaults } from 'utils/objects'
import { getDefaultResponsivity } from './defaultConstraints'
import { getDefaultLayoutForResponsivity } from './defaultLayout'

export default class ObjectBuilder {
  private readonly germ: EditorObject
  private readonly body: EditorObject | undefined

  private constructor(id: string, type: ObjectType, body?: EditorObject) {
    this.germ = {
      id,
      type,
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    }

    if (body) {
      this.body = body
    }
  }

  withPosition(x: number, y: number): ObjectBuilder {
    this.germ.x = x
    this.germ.y = y

    return this
  }

  withSize(width: number, height: number): ObjectBuilder {
    this.germ.width = width
    this.germ.height = height

    return this
  }

  withHidden(): ObjectBuilder {
    this.germ['hidden'] = true

    return this
  }

  withChildren(children: EditorObject[]): ObjectBuilder {
    this.germ.children = children

    return this
  }

  withPushGraph(pushGraph: PushGraph): ObjectBuilder {
    this.germ.pushGraph = pushGraph

    return this
  }

  withResponsivity(responsivity: Responsivity): ObjectBuilder {
    this.germ.responsivity = responsivity

    return this
  }

  withMinMaxWidth(
    minWidth: number,
    minWidthEnabled: boolean,
    maxWidth: number,
    maxWidthEnabled: boolean
  ): ObjectBuilder {
    this.germ.minWidth = minWidth
    this.germ.minWidthEnabled = minWidthEnabled
    this.germ.maxWidth = maxWidth
    this.germ.maxWidthEnabled = maxWidthEnabled

    return this
  }

  withPositioning(positioning: PositioningType): ObjectBuilder {
    this.germ.positioning = positioning

    return this
  }

  private initShared() {
    if (this.germ.shared) {
      return
    }

    this.germ.shared = {
      [DeviceTypeMap.MOBILE]: true,
      [DeviceTypeMap.TABLET]: true,
      [DeviceTypeMap.DESKTOP]: true,
    }
  }

  withDeviceSpecificLayout(device: DeviceType): ObjectBuilder {
    const { shared } = this.germ
    if (shared && !shared[device]) {
      // Already using device-specific layout
      return this
    }

    this.initShared()
    if (!this.germ.shared) {
      throw new Error(`Shared property was not correctly initialized`)
    }

    this.germ.shared[device] = false

    // Initialize layout
    const { x, y, width, height } = this.germ
    const initialProps: EditorObject[DeviceType] = { x, y, width, height }
    if (this.germ.responsivity) {
      initialProps.responsivity = this.germ.responsivity
    }

    this.germ[device] = initialProps

    return this
  }

  withDeviceSpecificPosition(
    device: DeviceType,
    x: number,
    y: number
  ): ObjectBuilder {
    this.initShared()

    const deviceProps = this.germ[device] || {}
    this.germ[device] = {
      ...deviceProps,
      x,
      y,
    }

    return this
  }

  withDeviceSpecificSize(
    device: DeviceType,
    width: number,
    height: number
  ): ObjectBuilder {
    this.initShared()

    const deviceProps = this.germ[device] || {}
    this.germ[device] = {
      ...deviceProps,
      width,
      height,
    }

    return this
  }

  withDeviceSpecificPushGraph(
    device: DeviceType,
    pushGraph: PushGraph
  ): ObjectBuilder {
    this.initShared()

    const deviceProps = this.germ[device] || {}
    this.germ[device] = {
      ...deviceProps,
      pushGraph,
    }

    return this
  }

  withDeviceSpecificResponsivity(
    device: DeviceType,
    responsivity: Responsivity
  ): ObjectBuilder {
    this.initShared()

    const deviceProps = this.germ[device] || {}
    this.germ[device] = {
      ...deviceProps,
      responsivity,
    }

    return this
  }

  withDeviceVisibility(device: DeviceType, visible: boolean): ObjectBuilder {
    let { deviceVisibility } = this.germ

    if (deviceVisibility === undefined) {
      deviceVisibility = {
        [DeviceTypeMap.MOBILE]: true,
        [DeviceTypeMap.TABLET]: true,
        [DeviceTypeMap.DESKTOP]: true,
      }
    }

    this.germ.deviceVisibility = {
      ...deviceVisibility,
      [device]: visible,
    }

    return this
  }

  withListAttributes(columnCount: number, rowMargin: number): ObjectBuilder {
    this.germ.columnCount = columnCount
    this.germ.rowMargin = rowMargin

    return this
  }

  withLibraryAttributes(
    libraryName: string,
    componentName: string
  ): ObjectBuilder {
    this.germ.libraryName = libraryName
    this.germ.componentName = componentName

    return this
  }

  withLabelAttributes(attributes: Partial<LabelAttributes>): ObjectBuilder {
    for (const [key, value] of Object.entries(attributes)) {
      this.germ[key] = value
    }

    return this
  }

  withTableAttributes(attributes: Partial<TableAttributes>): ObjectBuilder {
    for (const [key, value] of Object.entries(attributes)) {
      this.germ[key] = value
    }

    return this
  }

  withInitialDevice(device: DeviceType): ObjectBuilder {
    this.germ.initialDevice = device

    return this
  }

  withPurpose(purpose: string): ObjectBuilder {
    this.germ.purpose = purpose

    return this
  }

  /**
   * WARNING:
   * This method will overwrite any previously set values.
   * Given the chaining nature of this class, ordering of method calls is important.
   * @returns {ObjectBuilder}
   */
  withDefaults(): ObjectBuilder {
    const defaultValues = defaults[this.germ.type]
    if (defaultValues) {
      for (const [key, value] of Object.entries(defaultValues)) {
        this.germ[key] = value
      }
    }

    this.germ.responsivity = getDefaultResponsivity(this.germ)

    const defaultLayoutForResponsivity = getDefaultLayoutForResponsivity(
      this.germ.responsivity
    )
    for (const [key, value] of Object.entries(defaultLayoutForResponsivity)) {
      this.germ[key] = value
    }

    // TODO(michael-adalo): possibly some Section defaults we could add here...

    return this
  }

  build(): EditorObject {
    return merge(this.body, this.germ)
  }

  static create(id: string, type: ObjectType): ObjectBuilder {
    return new ObjectBuilder(id, type)
  }

  static merge(
    id: string,
    type: ObjectType,
    body: EditorObject
  ): ObjectBuilder {
    return new ObjectBuilder(id, type, body)
  }

  static createScreen(id: string): ObjectBuilder {
    return new ObjectBuilder(id, COMPONENT).withSize(400, 600).withChildren([])
  }
}
