import { INPUT } from '@adalo/constants'

import { EditorObject } from 'utils/responsiveTypes'
import { defaults } from 'utils/objects'

import TextControl from './TextControl'
import GenericInspectRow from './GenericRow'
import MenuControl from './Libraries/MenuControl'
import ColorDropdown from './ColorDropdown'

type FontControlProps = {
  object: {
    fontSize: number
    fontWeight: number
    fontFamily: string
    color: string
    placeholderColor: string
  }
  features: {
    fontSize: boolean
    fontWeight: boolean
    fontFamily: boolean
    color: boolean
    placeholderColor: boolean
  }
  fontFamilyOptions: [
    {
      label: string
      value: string
    }
  ]
  fontWeightOptions: [
    {
      label: string
      value: number
    }
  ]
  onChange: (arg: EditorObject['attributes']) => void
}

export const FontControl: React.FC<FontControlProps> = ({
  object,
  features,
  fontFamilyOptions,
  fontWeightOptions,
  onChange,
}) => {
  return (
    <>
      {features.fontFamily && (
        <MenuControl
          displayName="Font Family"
          name="fontFamily"
          value={object.fontFamily}
          onChange={onChange}
          options={fontFamilyOptions}
          className="font-family-control"
        />
      )}
      <GenericInspectRow className="default-font-control" key="fontControl">
        {features.fontSize && (
          <TextControl
            gray
            type="number"
            name="fontSize"
            value={object.fontSize}
            onChange={onChange}
            title="Font Size"
          />
        )}
        {features.fontWeight && (
          <MenuControl
            displayName="Font Weight"
            name="fontWeight"
            onChange={onChange}
            options={fontWeightOptions}
            value={object.fontWeight ?? '400'}
            className="font-weight-control"
          />
        )}
      </GenericInspectRow>
      {(features.color || features.placeholderColor) && (
        <GenericInspectRow className="default-font-control">
          {features.color && (
            <ColorDropdown
              label="Font Color"
              value={object.color}
              name="color"
              onChange={onChange}
            />
          )}
          {features.placeholderColor && (
            <ColorDropdown
              label="Placeholder Color"
              value={
                object.placeholderColor ?? defaults[INPUT]?.['placeholderColor']
              }
              name="placeholderColor"
              onChange={onChange}
            />
          )}
        </GenericInspectRow>
      )}
    </>
  )
}
