import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RedocStandalone } from 'redoc'
import { requestData } from 'ducks/editor/objects'

import store from 'redux-store'

import { getDatasources } from 'ducks/apps/datasources'

import { getApiKey, fetchApiKey } from 'ducks/apikeys'

import {
  generateDocsHeader,
  generatePaths,
  generateCollectionList,
  getTableFields,
} from './openapi'

import './CollectionAPIDocs.scss'

class collectionAPIDocs extends Component {
  componentDidMount() {
    const { match, requestData, fetchApiKey } = this.props
    const { appId } = match.params

    requestData(appId)
    fetchApiKey(appId)
  }

  render() {
    const { appId, appApiKey, datasources } = this.props

    if (appApiKey && datasources.length > 0) {
      const schemaList = getTableFields(datasources)

      const collectionList = generateCollectionList(datasources)
      const docsHeader = generateDocsHeader()
      const paths = generatePaths(appApiKey, appId, collectionList)

      const openApiSpec = { ...docsHeader, paths, components: schemaList }

      return (
        <div>
          <RedocStandalone
            specUrl={openApiSpec}
            options={{ hideDownloadButton: true }}
          />
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params

  return {
    appId,
    state: store.getState(),
    datasources: getDatasources(state, appId),
    appApiKey: getApiKey(state, appId),
  }
}

export default connect(mapStateToProps, { requestData, fetchApiKey })(
  collectionAPIDocs
)
