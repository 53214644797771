import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { MAX_VERSIONS } from '@adalo/constants'
import { getDesignVersions } from 'utils/io'

import { GroupedAccordion } from 'components/Shared/Accordion'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'

import VersionsList from './List'

import './Versions.scss'

const maxVersions = MAX_VERSIONS || 10
const halfMaxVersions = maxVersions / 2

const Versions = ({ appId }) => {
  const [versionsList, setVersionsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [shownVersionsList, setShownVersionsList] = useState([])
  const [showAll, setShowAll] = useState(false)

  const isEmpty = !versionsList.length

  useEffect(() => {
    if (!isEmpty) {
      if (showAll) setShownVersionsList(versionsList.slice(0, maxVersions))
      if (!showAll) setShownVersionsList(versionsList.slice(0, halfMaxVersions))
    }
  }, [isEmpty, versionsList, showAll])

  const loadVersionsList = async () => {
    try {
      const result = await getDesignVersions(appId)

      if (!result || result.length === 0) {
        setVersionsList([])
      } else {
        const reversedResults = result.reverse()

        setVersionsList(reversedResults)
      }
    } catch (err) {
      alert(err.message)
    }
  }

  useEffect(() => {
    loadVersionsList().then(() => setLoading(false))
  }, [])

  const renderVersions = () => (
    <VersionsList
      appId={appId}
      showAll={showAll}
      setShowAll={setShowAll}
      shownVersionsList={shownVersionsList}
      versionsList={versionsList}
      loadVersionsList={loadVersionsList}
      isEmpty={isEmpty}
      loading={loading}
    />
  )

  return (
    <div className="app-versions-container">
      <GroupedAccordion
        defaultExpanded
        group="versions-accordion"
        itemId="versions"
        title={
          <>
            <div className="versions-accordion-title-inner">
              Design Versions
            </div>
            <div className="versions-count">
              {versionsList?.length}/{maxVersions}
            </div>
          </>
        }
        className="versions-accordion"
        renderChildren={renderVersions}
      />
    </div>
  )
}

const VersionsPanel = ({ appId }) => {
  const tooltip =
    'Design Versions let you save and restore different app designs. Your screens, components, and layout will be saved with each version. Restoring will not bring back altered or deleted Collection data.'

  return (
    <div className="versions-wrapper">
      <div className="left-panel-header">
        <h2 className="left-panel-title">
          <Icon type="versions" />
          Version History
        </h2>
        <Tooltip small hideArrow placement="bottom-start" tooltip={tooltip}>
          <Icon type="help-small" />
        </Tooltip>
      </div>

      <Versions appId={appId} />
    </div>
  )
}

export default withRouter(VersionsPanel)
