import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { getCurrentUser, updateCurrentUser } from 'ducks/users'

import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import Form from './Form'

import './styles.scss'

export type FormValues = {
  primaryUse: string
  audience: string | undefined
}

export const getUserPersona = (
  primaryUse: string,
  audience: string | undefined
): string => {
  if (!audience) return primaryUse

  return audience?.replace('2', '')
}

const UserQuestions = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{ appId: string }>()

  const [loading, setLoading] = useState(false)
  const [primaryUse, setPrimaryUse] = useState('')
  const [audience, setAudience] = useState('')
  const [disableSnooze, setDisableSnooze] = useState(false)

  const currentUser = useSelector(getCurrentUser)

  useEffect(() => {
    const userQuestionsSnooze = localStorage.getItem('userQuestionsSnooze')

    if (userQuestionsSnooze) {
      const { amount } = JSON.parse(userQuestionsSnooze) as {
        amount: number
      }

      if (amount >= 3) {
        setDisableSnooze(true)
      }
    }
  }, [disableSnooze, localStorage])

  useEffect(() => {
    if (currentUser?.persona) {
      history.push(`/apps/${params?.appId}/screens`)
    }
  }, [currentUser, history, params?.appId])

  const disableButton = useMemo(() => {
    if (!primaryUse) {
      return true
    }

    if (primaryUse === 'Work' && !audience) {
      return true
    }

    return false
  }, [primaryUse, audience])

  const handleSubmit = (values: FormValues) => {
    setLoading(true)

    if (values && typeof values === 'object') {
      const { audience: audienceValue, primaryUse: primaryUseValue } = values

      const userId = currentUser?.id

      if (!userId) {
        return history.push('/login')
      }

      try {
        const persona = getUserPersona(primaryUseValue, audienceValue)

        dispatch(updateCurrentUser(userId, { persona }))

        setLoading(false)

        return history.push(`/apps/${params?.appId}/screens`)
      } catch (error) {
        console.error('FAILED TO SUBMIT USER QUESTIONS FORM', error)
        setLoading(false)
      }
    }
  }

  const handleModalSnooze = () => {
    const userQuestionsSnooze = localStorage.getItem('userQuestionsSnooze')

    if (userQuestionsSnooze) {
      const { amount } = JSON.parse(userQuestionsSnooze) as {
        date: string
        amount: number
      }

      localStorage.setItem(
        'userQuestionsSnooze',
        JSON.stringify({ date: moment().format(), amount: amount + 1 })
      )
    } else {
      localStorage.setItem(
        'userQuestionsSnooze',
        JSON.stringify({ date: moment().format(), amount: 1 })
      )
    }

    return history.push(`/apps/${params?.appId}/screens`)
  }

  return (
    <Modal size="xs">
      <Modal.Header>
        <Icon type="hand-shaking" />
        <h1>We'd like to get to know you better!</h1>
      </Modal.Header>
      <Modal.Content>
        <div className="user-questions-form">
          <Form
            onSubmit={handleSubmit}
            loading={loading}
            disableButton={disableButton}
            setPrimaryUse={setPrimaryUse}
            primaryUse={primaryUse}
            setAudience={setAudience}
          />
        </div>
      </Modal.Content>
      <Modal.Actions
        leftButtons={
          !disableSnooze && [
            <Button key="button_ask_me_later" text onClick={handleModalSnooze}>
              Ask me later
            </Button>,
          ]
        }
      >
        <Button
          yellow
          onClick={() => handleSubmit({ primaryUse, audience })}
          loading={loading}
          disabled={disableButton}
        >
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UserQuestions
