import { useCallback, useEffect, useState } from 'react'

import { AppCategoryData } from 'components/Onboarding/Steps/types'
import { adaloBackendAxios } from 'utils/io/http/axios'

const useAppCategoryData = (
  appCategory: string
): {
  loading: boolean
  sampleData: AppCategoryData['sampleData']
  mainCollectionName: AppCategoryData['mainCollectionName']
  homePageTitle: AppCategoryData['homePageTitle']
} => {
  const [categoryData, setCategoryData] = useState<AppCategoryData>({})
  const [loading, setLoading] = useState(true)

  const fetchSampleData = useCallback(async () => {
    const params = new URLSearchParams({ appCategory, limit: '5' })

    const data = await adaloBackendAxios
      .get(`/app-category/data`, { params })
      .then(res => res.data as AppCategoryData)
      .catch((err: Error) => {
        console.warn(`Error fetching app category data: ${err.message}`)

        return { sampleData: [] }
      })

    setCategoryData(data)
  }, [appCategory])

  useEffect(() => {
    if (appCategory) {
      setLoading(true)
      fetchSampleData()
        .catch((err: Error) => {
          console.warn(`Error fetching app category data: ${err.message}`)
        })
        .finally(() => setLoading(false))
    } else {
      setCategoryData({})
    }
  }, [appCategory])

  const { mainCollectionName, homePageTitle } = categoryData
  const sampleData = categoryData?.sampleData ?? []

  return { loading, sampleData, mainCollectionName, homePageTitle }
}

export default useAppCategoryData
