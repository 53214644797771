import React, { Component } from 'react'

import { adaloBackendAxios } from 'utils/io/http/axios'
import '../Analytics.css'
import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

export default class TotalUsers extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    const { start } = this.props

    if (start !== prevProps.start) {
      this.getData()
    }
  }

  getData = async () => {
    const { appId } = this.props
    const queryType = 'total'
    let start = new Date()
    let end = new Date()

    start.setDate(end.getDate() - 30)

    start = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    end = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`

    let data = await adaloBackendAxios.post(`/analytics`, {
      queryType,
      appId,
      start,
      end,
    })

    if (data.success === false) {
      this.setState({
        data: [],
      })
    } else {
      data = +data?.data?.data?.[0]?.count

      this.setState({
        data,
      })
    }
  }

  render() {
    const { data } = this.state
    let userLabel = 'Users'

    if (data === 1) {
      userLabel = 'User'
    }

    return (
      <div className="app-analytics-module-container app-analytics-total-container">
        <Tooltip
          tooltip={
            <div>
              <h2>What are Active Users?</h2>
              <span>
                Users that have logged in and performed at least one action in
                the last 30 days
              </span>
            </div>
          }
          placement="top-start"
          hideArrow
          className="app-analytics-help"
        >
          <Icon type="help" />
        </Tooltip>
        {data && data.length !== 0 ? (
          <div>
            <h2>30 Day Active Users </h2>
            <div className="total-users-label">
              <h1>{data}</h1>
              <h1 className="user-label">{userLabel}</h1>
            </div>
          </div>
        ) : (
          <div>
            <h2>30 Day Active Users </h2>
            <div className="total-users-label">
              <h1>0</h1>
              <h1 className="user-label">Users</h1>
            </div>
          </div>
        )}
      </div>
    )
  }
}
