import { LIST } from '@adalo/constants'
import getCustomListProperties from 'ducks/editor/objects/helpers/getCustomListProperties'
import { DeviceValue, EditorObject } from './responsiveTypes'

export const getDeviceObjectRecursive = (
  object: EditorObject,
  deviceType: DeviceValue | undefined
): EditorObject => {
  const deviceChildren = object.children?.map(child =>
    getDeviceObjectRecursive(child, deviceType)
  )

  let deviceObject = { ...object }

  if (object && deviceType && object[deviceType]) {
    deviceObject = { ...object, ...object[deviceType] }
  }

  if (deviceChildren) {
    deviceObject.children = deviceChildren
  }

  if (object.type === LIST) {
    const customListProperties = getCustomListProperties(
      deviceObject,
      deviceType
    )

    deviceObject = { ...deviceObject, ...customListProperties }
  }

  return deviceObject
}

const getDeviceObject = (
  object: EditorObject,
  deviceType: DeviceValue | undefined
): EditorObject => {
  if (object && deviceType && object[deviceType]) {
    return { ...object, ...object[deviceType] }
  } else {
    return object
  }
}

export default getDeviceObject
