import { assetURL } from 'utils/assets'
import { defaultAxios } from 'utils/io/http/axios'

export const FORM_NAME = 'AndroidPublishSettings'

export const requiredFactory = fieldName => value => {
  if (value) return undefined

  return `${fieldName} is required`
}

// Bundle ID displays as "Package Name"
export const validateBundleIdFormat = val => {
  if (!val.match(/^[A-Za-z\d_]+\.[A-Za-z\d_]+/g)) {
    return 'Android Package Names must have at least two segments, i.e. at least one period.'
  }

  if (val.match(/[^a-z\d\_\.]/i)) {
    return 'Can only contain letters, numbers, underscores (_) and dots (.)'
  }

  if (val.match(/^([A-Za-z]{1}[A-Za-z\d_]*\.)*[A-Za-z][A-Za-z\d_]*$/)) {
    return undefined
  }

  return 'This Package Name is invalid'
}

export const validateDisplayName = val => {
  if (val.match(/`/i)) {
    return "Cannot contain the '`' backtick character"
  }
}

export const validations = {
  displayName: [requiredFactory('App Display Name'), validateDisplayName],
  bundleId: [requiredFactory('Package Name'), validateBundleIdFormat],
  frontendGoogleServices: [requiredFactory('Frontend google-services.json')],
  notificationsIcon: [requiredFactory('Notifications Icon')],
}

export const asyncValidate = async data => {
  const { frontendGoogleServices, bundleId } = data

  if (!frontendGoogleServices || !bundleId) {
    return
  }

  const googleServices = (
    await defaultAxios.get(assetURL(frontendGoogleServices))
  ).data

  const packageName =
    googleServices?.client?.[0]?.client_info?.android_client_info?.package_name

  if (!packageName) {
    throw { frontendGoogleServices: 'Android app ID is missing' }
  } else if (packageName !== bundleId) {
    throw {
      frontendGoogleServices: `Package Names don’t match. Got ${packageName}`,
    }
  }
}
