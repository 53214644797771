import { DeviceType, LIBRARY_COMPONENT } from '@adalo/constants'
import { renderToStaticMarkup } from 'react-dom/server'
import { md5 } from 'js-md5'

import {
  evaluateLibraryProps,
  getAppComponent,
  getAppLibrary,
} from 'utils/libraries'

import { LIBRARY_INSPECT_GROUP } from 'ducks/accordions'

import { ElementSize } from '../model/LibraryComponentNode'
import LayoutContext from '../model/layout/LayoutContext'
import LayoutObject from '../model/layout/LayoutObject'
import TDeviceType from '../types/DeviceType'

type LibraryComponentProps = {
  _width: number
  _height: number
  _fonts: {
    body: string
    heading: string
  }
  _screenHeight: number
  _screenWidth: number
  _deviceType: TDeviceType
  _layoutGuides: {
    top: number
    bottom: number
  }
  [key: string]: unknown
}

type Library = {
  components: {
    [key: string]: React.ComponentClass<LibraryComponentProps>
  }
}

type LibraryConfig = {
  resizeX?: boolean
  resizeY?: boolean
}

const instancesPreviousSize = new Map<string, ElementSize>()

// Using md5 to give us a short key
// Collisions are unlikely but have no negative impact
const makeInputsKey = (
  object: LayoutObject<typeof LIBRARY_COMPONENT>,
  attributes: Record<string, unknown> | undefined,
  viewportWidth: number,
  viewportHeight: number,
  device: TDeviceType
): string => {
  const factors = [
    String(object.id),
    String(object.width),
    String(object.height),
    String(viewportWidth),
    String(viewportHeight),
    device,
    JSON.stringify(attributes ?? {}),
  ]

  const accordion = localStorage.getItem(LIBRARY_INSPECT_GROUP) ?? ''
  if (accordion.includes(object.id)) {
    factors.push(accordion)
  }

  return md5(factors.join(''))
}

const getLibraryComponentInstanceSize =
  (
    libraryName: string,
    componentName: string,
    attributes: Record<string, unknown> | undefined
  ) =>
  (
    obj: LayoutObject<typeof LIBRARY_COMPONENT>,
    context: LayoutContext
  ): ElementSize => {
    const { id: objectId } = obj

    const {
      device = DeviceType.DESKTOP,
      viewportHeight,
      viewportWidth,
    } = context

    if (libraryName === undefined || componentName === undefined) {
      throw new Error(
        `Found LIBRARY_COMPONENT with missing library data (libraryName: ${String(
          libraryName
        )}, componentName: ${String(componentName)})`
      )
    }

    const inputsKey = makeInputsKey(
      obj,
      attributes,
      viewportWidth,
      viewportHeight,
      device
    )

    if (instancesPreviousSize.has(inputsKey)) {
      return instancesPreviousSize.get(inputsKey) as ElementSize
    }

    const library = getAppLibrary(null, libraryName) as Library
    const config = (getAppComponent(null, libraryName, componentName) ||
      {}) as LibraryConfig

    const resizeY = !!config?.resizeY
    const resizeX = 'resizeX' in config ? config?.resizeX : true

    const Component = library?.components?.[componentName]

    // Skip if vertically-resizable
    if (!Component || (resizeY && resizeX)) {
      return { height: obj.height, width: obj.width }
    }

    const props = evaluateLibraryProps(
      config,
      attributes,
      () => 'Hello world',
      // TODO(toby): Fix these values
      { branding: undefined, libraryGlobals: {} }
    )

    let accordion = localStorage.getItem(LIBRARY_INSPECT_GROUP) ?? undefined

    if (accordion?.includes(objectId)) {
      accordion = accordion.split('-')[1]
    }

    try {
      const el = document.createElement('div')

      if (resizeX) {
        el.style.width = `${obj.width}px`
      }

      el.style.position = 'fixed'
      el.style.top = '100%'
      el.style.zIndex = '100000'
      el.style.opacity = '0'
      el.style.pointerEvents = 'none'
      el.style.backgroundColor = 'red'

      document.body.appendChild(el)

      el.innerHTML = renderToStaticMarkup(
        <Component
          editor
          {...props}
          openAccordion={accordion}
          _width={obj.width}
          _height={obj.height}
          _fonts={{
            body: 'body',
            heading: 'heading',
          }}
          _deviceType={device}
          _screenHeight={viewportHeight}
          _screenWidth={viewportWidth}
          _layoutGuides={{ top: 0, bottom: 0 }}
        />
      )

      const { height, width } = el.getBoundingClientRect()
      document.body.removeChild(el)

      const result = {
        width: resizeX ? obj.width : width,
        height: resizeY ? obj.height : height,
      }

      instancesPreviousSize.set(inputsKey, result)

      return result
    } catch (err) {
      console.warn(`Error updating library component bounds:`, err, {
        componentName,
        objectId,
      })

      const { width, height } = obj

      return {
        width,
        height,
      }
    }
  }

export default getLibraryComponentInstanceSize
