import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from './Login'
import Signup from './Signup'
import ForgotPassword from './ForgotPassword'
import RecoverPassword from './RecoverPassword'
import Terms from './Terms'
import { Logout } from './Logout'

const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route
        path={['/signup', '/accept-invite']}
        render={props => (
          <Signup {...props}>
            <Switch>
              <Route
                exact
                path={['/signup/terms', '/accept-invite/terms']}
                component={Terms}
              />
            </Switch>
          </Signup>
        )}
      />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/recover-password" component={RecoverPassword} />
      <Route exact path="/logout" component={Logout} />
    </Switch>
  )
}

export default AuthRoutes
