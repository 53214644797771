import { SECTION, LABEL, borderStyle } from '@adalo/constants'
import { defaults } from './objects'

const rectangle = {
  ...defaults[SECTION],
  x: 8,
  type: SECTION,
  name: 'Rectangle',
  width: 375 - 16,
  height: 80,
  backgroundStyle: 'color',
  backgroundColor: '#ffffff',
  borderStyle: borderStyle.NONE,
  borderWidth: 1,
  borderColor: '#f5f5f5',
  borderRadius: 4,
  shadow: {
    ...defaults[SECTION].shadow,
    enabled: true,
  },
}

const title = {
  ...defaults[LABEL],
  id: 'fake-id-to-disable-border-on-drag1',
  type: LABEL,
  text: 'Title',
  layoutText: ['Title'],
  textColor: '#424242',
  width: 300,
  height: 19,
  autoWidth: false,
  fontWeight: 500,
  fontSize: 16,
  x: 8 + 16,
  y: 19,
}

const subtitle = {
  ...defaults[LABEL],
  id: 'fake-id-to-disable-border-on-drag2',
  type: LABEL,
  text: 'Subtitle',
  layoutText: ['Subtitle'],
  textColor: '#757575',
  width: 300,
  height: 14,
  autoWidth: false,
  fontWeight: 400,
  fontSize: 14,
  x: 8 + 16,
  y: 42,
}

export const defaultListChildren = [rectangle, title, subtitle]

export const responsiveDefaultListChildren = [
  {
    ...rectangle,
    children: [title, subtitle],
  },
]
