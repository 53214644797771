import React from 'react'
import { MAX_VERSIONS } from '@adalo/constants'
import { useDispatch, useSelector } from 'react-redux'

import Loading from 'components/Shared/Loading'
import Button from 'components/Shared/Button'
import FreeTrial from 'components/Shared/FreeTrial'

import { getActiveState, isFeatureEnabled } from 'ducks/organizations'

import { DESIGN_VERSION_MODAL, showModal } from 'ducks/editor/modals'

import { VersionItem } from './Item'

const maxVersions = MAX_VERSIONS || 10

const VersionsList = ({
  appId,
  showAll,
  setShowAll,
  shownVersionsList,
  versionsList = null,
  loadVersionsList,
  isEmpty,
  loading = true,
}) => {
  const dispatch = useDispatch()
  const isPaidOrg = useSelector(state => getActiveState(state, appId))

  const isEnabled = useSelector(state =>
    isFeatureEnabled(state, 'designVersions')
  )

  const limitReached = versionsList.length >= maxVersions
  const seeMoreText = !showAll ? 'Show More' : 'Show Less'

  const openVersionModal = () => {
    dispatch(
      showModal(DESIGN_VERSION_MODAL, {
        type: 'create',
        loadVersionsList,
      })
    )
  }

  if (loading || isPaidOrg === undefined) {
    return <Loading />
  }

  const showFeature = isEnabled || !isEmpty
  const isDisabled = !isEnabled

  if (showFeature) {
    return (
      <>
        <FreeTrial appId={appId} type="designVersions" />
        <div className={isDisabled ? 'content-disabled' : ''}>
          <Button
            className="app-versions-button"
            fluid
            outlined
            teal
            icon="plus-small"
            onClick={openVersionModal}
            disabled={limitReached || isDisabled}
          >
            Create Design Version
          </Button>

          {isEmpty ? (
            <div className="versions-list-empty">
              <div className="versions-list-empty-text">
                <p className="text-title">Create a Design Version</p>
                <div className="text-content">
                  <p className="text-description">
                    Design Versions let you experiment with different app
                    designs. Restoring will not bring back altered or deleted
                    Collection data.
                  </p>
                  <a
                    href="https://help.adalo.com/design/design-versions"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Learn more.
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="versions-list">
              {shownVersionsList.map(version => (
                <VersionItem
                  key={`${appId}-${version.id}`}
                  appId={appId}
                  version={version}
                  loadVersionsList={loadVersionsList}
                  limitReached={limitReached}
                />
              ))}

              {versionsList.length > 5 && (
                <Button text square onClick={() => setShowAll(!showAll)}>
                  {seeMoreText}
                </Button>
              )}
            </div>
          )}
        </div>
      </>
    )
  } else {
    return <FreeTrial appId={appId} type="designVersions" />
  }
}

export default VersionsList
