import Icon from 'components/Shared/Icon'

export const EmptyState = ({
  label = 'Components',
  icon,
}: {
  label?: string
  icon: string
}): JSX.Element => {
  return (
    <div className="global-search-empty-category">
      <Icon type={icon} />
      <p className="global-search-empty-header">No {label} Found</p>
      <p className="global-search-empty-body">
        Try searching with a different keyword
      </p>
    </div>
  )
}
