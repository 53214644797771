import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { union } from 'lodash'

// utils
import { getLibraryDependencies, updateLibraryInfo } from 'utils/libraries'
import { localLibrariesReady, getLocalLibraries } from 'utils/developers'
import { marketplaceAxios } from 'utils/io/http/axios'

// ducks
import { setAppLibraries, updateApp } from 'ducks/apps'
import { updateLibraries, getAdminLibraries } from 'ducks/marketplace'
import { requestLibrary } from 'ducks/editor/libraries'

const DEFAULT_LIBRARIES =
  JSON.parse(process.env.REACT_APP_DEFAULT_LIBRARIES) || []

// Library Loader handles requesting libraries from marketplace,
// requesting the library scripts from s3 and loading them in Adalo

// 1. request libraries from marketplace
//    installed, private and libraries under review (if current user is an admin)
// 2. load libraries from s3
// 3. set library loading status to complete

const LibraryLoader = ({ app, licenses, ...props }) => {
  const dispatch = useDispatch()
  const defaultLibraries = DEFAULT_LIBRARIES.map(lib => lib?.name)
  const { librariesUsed = [] } = app
  const testingLibraries = useSelector(state => getAdminLibraries(state))
  const localLibraries = getLocalLibraries()

  const restrictedLicenses =
    licenses.filter(({ paid, public: isPublic }) => paid || !isPublic) || []

  const getLibraries = async () => {
    let libraries = []

    // include any libraries used, regardless if they are installed or not
    if (app?.librariesUsed) {
      const localLibraries = getLocalLibraries()

      const usedLibraries = app.librariesUsed
        .filter(library => !localLibraries.includes(library))
        .map(library => ({ name: library }))

      libraries = [...usedLibraries, ...libraries]
    }

    try {
      let libraryNames = [...defaultLibraries]

      if (libraries.length > 0) {
        libraries.forEach(library => {
          if (libraryNames.includes(library.name)) return null

          return libraryNames.push(library.name)
        })
      }

      // stringify library names
      libraryNames = JSON.stringify(libraryNames)
      libraryNames = encodeURIComponent(libraryNames)

      // request libraries from marketplace
      const url = `/api/libraries`

      const { data } = await marketplaceAxios.get(url, {
        params: {
          names: libraryNames,
          orgId: app?.OrganizationId,
          appId: app?.id,
          editor: true,
        },
      })

      const appLibraries = [
        ...data,
        ...localLibraries.map(name => ({ name, type: 'dev', licensed: true })),
      ]

      // update app libraries
      dispatch(setAppLibraries(app.id, appLibraries))

      return data
    } catch (err) {
      console.error('ERROR GETTING LIBRARIES', err)

      return []
    }
  }

  const loadLibraries = async () => {
    const { onLoad } = props

    // set librariesReady ready state for library loader to false
    onLoad(false)

    const appLibraries = await getLibraries()
    app = { ...app, libraries: appLibraries }
    await localLibrariesReady()

    const libraries = getLibraryDependencies(app)

    const allowedLibraries = libraries.filter(
      library =>
        library?.licensed ||
        library?.type === 'testing' ||
        library?.version === 'dev'
    )

    // set installed libraries
    const installedLibraries = allowedLibraries.filter(lib => {
      return lib.type === 'public' && !defaultLibraries.includes(lib.name)
    })

    // set private libraries
    const privateLibraries = allowedLibraries.filter(
      ({ type }) => type === 'private'
    )

    // set testing libraries
    const testingLibraries = allowedLibraries.filter(
      ({ type }) => type === 'testing'
    )

    // update libraries with "public", "private" and "testing" libraries
    // "admin" is currently "testing"
    dispatch(updateLibraries(installedLibraries))
    dispatch(updateLibraries(privateLibraries, 'private'))
    dispatch(updateLibraries(testingLibraries, 'admin'))

    const promises = []

    for (const library of libraries) {
      const { name, version } = library

      // request library
      const promise = dispatch(requestLibrary(name, version))
      promises.push(promise)
    }

    const results = await Promise.allSettled(promises)

    results.forEach(({ status, reason }) => {
      if (status === 'rejected') {
        const { libraryName, version } = reason
        console.error(`Error Loading Library ${libraryName}@${version}`)
      }
    })

    updateLibraryInfo(libraries, app, (appId, data) => {
      dispatch(updateApp(appId, data))
    })

    onLoad(true)
  }

  const librariesToLoad = union(
    licenses.map(({ name }) => name),
    librariesUsed,
    testingLibraries.map(({ name }) => name),
    localLibraries
  )

  useEffect(() => {
    loadLibraries()
  }, [librariesToLoad.length, restrictedLicenses.length])

  return null
}

export default React.memo(LibraryLoader)
