import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { SketchPicker } from 'react-color'
import DocumentEvents from 'react-document-events'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { getAppBranding } from 'ducks/apps'
import { RETURN } from 'utils/keyboard'

import {
  getPresetsForBranding,
  getBrandingFromHex,
  normalizeColor,
} from 'utils/colors'

import './ColorPicker.css'

class ColorPicker extends Component {
  state = { open: false, x: 0, y: 0 }

  toggleOpen = e => {
    const width = 220
    const height = 300

    const position = e.currentTarget.getBoundingClientRect()
    let y = position.top + position.height + 10
    let x = position.left + position.width / 2 - width / 2

    if (y + height > window.innerHeight) {
      x = position.x + position.width + 10
      y = window.innerHeight - height - 10
    }

    this.setState(({ open }) => ({ x, y, open: !open }))
  }

  handleChange = (value, e) => {
    const { name, onChange, branding } = this.props

    const color = getBrandingFromHex(value, branding)

    onChange({ [name]: color })
  }

  handleKeyPress = e => {
    if (e.which === RETURN) {
      this.setState({ open: false })
    }
  }

  render() {
    const { open, x, y } = this.state

    let { single, value, values, defaultValue, disabled, label, branding } =
      this.props

    value = normalizeColor(value || defaultValue, branding, values) || '#f00'

    const styles = {
      backgroundColor: value,
    }

    return (
      <div>
        <div
          className={classNames('color-picker-wrapper', { disabled, single })}
        >
          <div className="color-picker-swatch" onClick={this.toggleOpen}>
            <div className="color-picker-swatch-sub" style={styles} />
          </div>
        </div>
        <label>{label}</label>
        {open &&
          ReactDOM.createPortal(
            <div>
              <div
                className="color-picker-close"
                onMouseDown={this.toggleOpen}
              />
              <div className="color-picker" style={{ left: x, top: y }}>
                <SketchPicker
                  color={value}
                  onChangeComplete={this.handleChange}
                  presetColors={getPresetsForBranding(branding)}
                />
                <DocumentEvents onKeyPress={this.handleKeyPress} />
              </div>
            </div>,
            document.body
          )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  branding: getAppBranding(state, ownProps.match.params.appId),
})

export default withRouter(connect(mapStateToProps)(ColorPicker))
