import React, { useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { kebabCase } from 'lodash'

import {
  fetchOrganization,
  setTrialAsOver,
  getTrialState,
  getCurrentOrganization,
  getTrialRemainingTime,
  isFeatureEnabled,
  getActiveState,
  getPlanFeatures,
} from 'ducks/organizations'
import { getApiKey } from 'ducks/apikeys'
import useHandleTrialOrUpgrade from 'hooks/useHandleTrialOrUpgrade'

import { useFlags } from 'flags'
import Loading from '../Loading'
import Button from '../Button'
import EmptyState from '../EmptyState'

import { generateText } from './utils'
import './FreeTrial.scss'
import history from '../../../history'

const useTrialIsOverChecks = () => {
  const dispatch = useDispatch()
  const timeToEnd = useSelector(getTrialRemainingTime)
  let timeoutId

  const addCheck = useCallback(() => {
    if (timeToEnd > 0) {
      timeoutId = setTimeout(() => {
        dispatch(setTrialAsOver())
      }, timeToEnd)
    }
  }, [dispatch, timeToEnd])

  const removeCheck = () => {
    clearTimeout(timeoutId)
  }

  return {
    addTrialIsOverCheck: addCheck,
    removeTrialIsOverCheck: removeCheck,
  }
}

const useFreeTrialData = appId => {
  const dispatch = useDispatch()

  const { addTrialIsOverCheck, removeTrialIsOverCheck } = useTrialIsOverChecks()

  const {
    organization,
    trialState,
    canExtendTrial,
    days,
    trialEndTimestamp,
    isPaid,
    appApiKey,
    planFeatures,
  } = useSelector(state => {
    const { trialState, days, trialEndTimestamp, canExtendTrial } =
      getTrialState(state)

    return {
      organization: getCurrentOrganization(state),
      trialState,
      canExtendTrial,
      days,
      trialEndTimestamp,
      appApiKey: getApiKey(state, appId),
      isPaid: getActiveState(state),
      planFeatures: getPlanFeatures(state),
    }
  })

  useEffect(() => {
    if (organization?.id) {
      dispatch(fetchOrganization(organization.id))
      addTrialIsOverCheck()
    }

    return removeTrialIsOverCheck
  }, [dispatch, organization?.id, trialEndTimestamp])

  return {
    isPaid,
    appApiKey,
    organization,
    trialState,
    days,
    planFeatures,
    canExtendTrial,
  }
}

const getColor = colorType => {
  const dataFeatures = ['externalCollection', 'geolocation', 'externalDatabase']

  return {
    orange: dataFeatures.includes(colorType),
    darkPink: colorType === 'customFonts',
    purple: colorType === 'api',
    teal: colorType === 'designVersions',
    yellow: colorType === 'customActions',
  }
}

const parseType = type => {
  if (type === 'googleApiKey') {
    return 'geolocation'
  }

  if (type === 'api') {
    return 'publicApi'
  }

  const customIntegrations = ['customActions', 'externalCollection']

  return customIntegrations.includes(type) ? 'customIntegrations' : type
}

const DataAdaloIdRestartTrialMap = {
  api: 'restart-free-trial-api',
  customFonts: 'restart-free-trial-custom-fonts',
  designVersions: 'restart-free-trial-design-versions',
  externalCollection: 'restart-free-trial-external-collection',
  geolocation: 'restart-free-trial-google-api-key',
}

const getDataAdaloIdProps = (type, trialState, hasTrialExtension2023) => {
  const props = {}
  let dataAdaloId

  if (hasTrialExtension2023 && trialState === 'after') {
    dataAdaloId = DataAdaloIdRestartTrialMap[type]
  } else {
    const dataAdaloIdType = kebabCase(type)
    dataAdaloId =
      trialState === 'before'
        ? `start-free-trial-${dataAdaloIdType}`
        : `upgradeCTA-${dataAdaloIdType}`
  }

  if (dataAdaloId) {
    props['data-adalo-id'] = dataAdaloId
  }

  return props
}

const FreeTrial = ({ appId, type, colorType = null }) => {
  const { isPaid, appApiKey, trialState, canExtendTrial, days, planFeatures } =
    useFreeTrialData(appId)

  const { hasTrialExtension2023 } = useFlags()
  const showRestartTrial = hasTrialExtension2023 && canExtendTrial

  if (!colorType) {
    colorType = type
  }

  const parsedType = parseType(type)

  const isEnabled = useSelector(state => isFeatureEnabled(state, parsedType))
  const featureEnabledByPlan = planFeatures.includes(parsedType)

  const handleTrial = useHandleTrialOrUpgrade({
    appId,
    trialState,
    type,
    callbackOptions: { appApiKey, noCreate: true },
    isPaidOrg: isPaid,
  })

  const handleClickExtend = () => {
    const url = `/apps/${appId}/free-trial-extend?feature=${type}`

    history.push(url)
  }

  const loading = !trialState || isPaid === undefined

  if (loading) {
    return <Loading />
  }

  if (isEnabled && isPaid && featureEnabledByPlan) {
    return null
  }

  const isExternalDatabase = type === 'externalDatabase'
  const shouldParseState = (!isEnabled && isPaid) || isExternalDatabase

  const parsedState = shouldParseState ? 'upgrade' : trialState

  const { header, body, button, icon } = generateText(days, type, parsedState)
  const color = getColor(colorType)

  const dataAdaloIdProps = getDataAdaloIdProps(
    type,
    trialState,
    hasTrialExtension2023
  )

  if (type === 'externalDatabase' && parsedState !== 'upgrade') {
    return null
  }

  return (
    <EmptyState
      bordered
      className={`data-upgrade-prompt new-trial ${parsedState} ${colorType}-${parsedState}-free-trial-prompt ${
        appApiKey ? 'free-trial-with-api-key' : ''
      }`}
    >
      <div className="prompt-header">
        {icon && (
          <span role="img" aria-label="pen">
            {icon}
          </span>
        )}
        <h2>{header}</h2>
      </div>
      <p>{body}</p>
      <div className="prompt-button-container">
        <Button
          className={`prompt-button-${type}`}
          outlined={trialState === 'during'}
          secondary={type === 'externalCollection'}
          small
          onClick={handleTrial}
          {...color}
          {...dataAdaloIdProps}
        >
          {button}
        </Button>
        {showRestartTrial && (
          <Button
            outlined
            small
            {...color}
            className={`prompt-button-${type}`}
            onClick={handleClickExtend}
          >
            Restart Trial
          </Button>
        )}
      </div>
    </EmptyState>
  )
}

export default withRouter(FreeTrial)
