import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, ScreenHandlerOptions } from './types'

export interface AlignToScreenHorizontalCenterInstruction {
  operation: 'alignToScreenHorizontalCenter'
  options: ActionOptions
}

export const alignToScreenHorizontalCenterHandler = ({
  bounds,
  screenBounds,
  objects,
}: ScreenHandlerOptions): LayoutInstruction[] => {
  const screenBoundsHorizontalCenter =
    screenBounds.left + (screenBounds.right - screenBounds.left) / 2

  const delta =
    screenBoundsHorizontalCenter -
    (bounds.right - bounds.left) / 2 -
    bounds.left

  return objects.map(object =>
    moveElement(object.id, object.x + delta, object.y)
  )
}

const alignToScreenHorizontalCenter = (
  objectIds: string[]
): AlignToScreenHorizontalCenterInstruction => ({
  operation: 'alignToScreenHorizontalCenter',
  options: { objectIds },
})

export default alignToScreenHorizontalCenter
