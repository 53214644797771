import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Frame from './Frame'
import CloseButton from './CloseButton'
import SizeMenu, { DEFAULT_SIZE } from './SizeMenu'
import './Preview.css'

class Preview extends Component {
  state = {
    size: DEFAULT_SIZE,
  }

  handleChangeSize = size => {
    this.setState({ size })
  }

  render() {
    const { match, baseUrl } = this.props
    const { appId } = match.params
    const { size } = this.state

    return (
      <div className="editor-preview">
        <Frame appId={appId} size={size} baseUrl={baseUrl} />
        <CloseButton appId={appId} />
        <SizeMenu size={size} onChange={this.handleChangeSize} />
      </div>
    )
  }
}

export default withRouter(Preview)
