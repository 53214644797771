import { traverse } from '@adalo/utils'

import {
  COMPONENT,
  ACTION_NAMES,
  LABEL,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  SECTION,
  LAYOUT_SECTION,
} from '@adalo/constants'

import { capitalize } from './type'
import { getComponentInfo } from './libraries'

/**
 * Object name field is optional, added so form inputs can show ${form name} ${input name}
 *
 * @param {import('./responsiveTypes').EditorObject} object
 * @param {string | undefined} propName
 * @param {string | undefined} inputId
 * @returns {string} A human-readable name for the object
 */
export const getObjectName = (
  object,
  propName = undefined,
  inputId = undefined
) => {
  if (!object) {
    return '[Deleted]'
  }

  const idComponents = inputId ? inputId.split('.') : undefined

  if (idComponents && idComponents.length === 2) {
    propName = idComponents[1]
  }

  const { libraryName, componentName, version } = object || {}

  const manifest =
    getComponentInfo(libraryName, version, componentName) ||
    object.libraryComponentManifest

  if (propName) {
    const objectProps = manifest.props

    const displayedProp = objectProps.find(prop => {
      return prop.name === propName
    })

    const label = `${object.name} ${displayedProp.displayName}`

    return label || abbreviate(object.text, 20) || capitalize(object.type)
  }

  if (idComponents && idComponents.length === 3) {
    const childComponents = manifest.childComponents

    const displayedChildComponent = childComponents.find(child => {
      return child.name === idComponents[1]
    })

    const childComponentProps = displayedChildComponent.props

    const displayedProp = childComponentProps.find(prop => {
      return prop.name === idComponents[2]
    })

    const label = `${object.name} ${displayedChildComponent.displayName} ${displayedProp.displayName}`

    return label || abbreviate(object.text, 20) || capitalize(object.type)
  }

  if (object.name?.includes('Select')) {
    return object.name.replace('Select', 'Dropdown')
  }

  return object.name || abbreviate(object.text, 20) || capitalize(object.type)
}

export const abbreviate = (text, length) => {
  text = text || ''

  if (typeof text === 'object' && text.length) {
    text = text[0]
  }

  if (text.length > length) {
    return `${text.substring(0, length - 3)}...`
  }

  return text
}

export const getTypeName = type => {
  switch (type) {
    case IMAGE_UPLOAD:
      return 'image-picker'
    case FILE_UPLOAD:
      return 'file-picker'
    case SECTION:
      return 'rectangle'
    case LAYOUT_SECTION:
      return 'section'
    case COMPONENT:
      return 'screen'
    case LABEL:
      return null
    default:
      return type || 'untitled'
  }
}

export const isDefaultName = (typeName, name) => {
  return !name || name?.includes(typeName)
}

/**
 *
 * @param {string} type
 * @param {string | null} name
 * @param {import('./responsiveTypes').EditorObject[]} list
 * @returns {string}
 */
export const createName = (type, name, list) => {
  let largestSuffix = 0

  if (!type) {
    return 'Untitled'
  }

  let typeName = getTypeName(type, name)

  if (!isDefaultName(typeName, name)) {
    typeName = name
  }

  let baseName = null

  if (typeName) {
    const nameParts = typeName.split(/[^a-zA-Z]+/g)
    baseName = nameParts.map(p => capitalize(p)).join(' ')
  }

  traverse(list, obj => {
    const lowerBaseName = baseName?.toLowerCase()

    if (obj.name?.toLowerCase().startsWith(lowerBaseName)) {
      const match = obj.name.match(/\s(\d+)$/)

      if (match) {
        largestSuffix = Math.max(largestSuffix, match[1])
      } else {
        largestSuffix = Math.max(largestSuffix, 1)
      }
    }
  })

  if (largestSuffix > 0) {
    return `${baseName} ${largestSuffix + 1}`
  }

  return baseName
}

export const actionName = action => {
  return ACTION_NAMES[action.actionType] || 'New Action'
}
