export default class Range {
  constructor(public readonly start: number, public readonly end: number) {}

  intersect(other: Range): Range | undefined {
    if (this.end < other.start || this.start > other.end) {
      return undefined
    }

    if (this.start <= other.start && this.end >= other.end) {
      // This range entirely covers the other range.
      return other.clone()
    }

    if (this.start >= other.start && this.end <= other.end) {
      // The other range entirely covers this range.
      return this.clone()
    }

    if (this.start <= other.start) {
      // This range overlaps the left side of the other range.
      return new Range(other.start, this.end)
    }

    // This range overlaps the right side of the other range.
    return new Range(this.start, other.end)
  }

  private clone(): Range {
    return new Range(this.start, this.end)
  }
}
