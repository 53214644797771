/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { VelocityTransitionGroup } from 'velocity-react'
import { difference } from 'lodash'

import Icon from 'components/Shared/Icon'
import 'components/Shared/Accordion.scss'

const Context = React.createContext()

export class AccordionGroup extends Component {
  static propTypes = {
    openAll: PropTypes.bool,
    defaultOpen: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    openAll: false,
    defaultOpen: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      openIds: new Set(props.defaultOpen),
      handleToggle: id => () => {
        this.setState(state => {
          const openIds = new Set(state.openIds)

          if (openIds.has(id)) {
            openIds.delete(id)
          } else {
            openIds.add(id)
          }

          return { openIds }
        })
      },
    }
  }

  componentDidUpdate(prevProps) {
    const { defaultOpen } = this.props
    const diff = difference(defaultOpen, prevProps.defaultOpen)

    if (
      diff.length > 0 ||
      defaultOpen.length !== prevProps.defaultOpen.length
    ) {
      this.replaceOpen(defaultOpen)
    }
  }

  open = ids => {
    if (ids.length === 0) {
      return
    }

    this.setState(state => {
      let openIds = state.openIds

      for (const id of ids) {
        if (state.openIds.has(id)) {
          if (openIds === state.openIds) {
            openIds = new Set(openIds)
          }

          openIds.add(id)
        }
      }

      return { openIds }
    })
  }

  replaceOpen = ids => {
    this.setState({ openIds: new Set(ids) })
  }

  render() {
    const { children, openAll } = this.props
    let value = this.state

    if (openAll) {
      value = {
        openIds: {
          has: () => true,
        },
        handleToggle: () => null,
      }
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
  }
}

export default class Accordion extends Component {
  static Group = AccordionGroup
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    collapsedTitle: PropTypes.node,
    expandedTitle: PropTypes.node,
    className: PropTypes.string,
    hideOnly: PropTypes.bool,
    hideCarret: PropTypes.bool,
    titleComponent: PropTypes.elementType,
  }

  static defaultProps = {
    hideOnly: false,
    hideCarret: false,
    titleComponent: 'div',
  }

  renderChildren() {
    const { renderChildren } = this.props

    return renderChildren ? <div>{renderChildren()}</div> : null
  }

  render() {
    const {
      id,
      title,
      titleComponent: TitleComponent,
      collapsedTitle,
      expandedTitle,
      className,
      hideOnly,
      hideCarret,
    } = this.props

    return (
      <Context.Consumer>
        {({ openIds, handleToggle }) => {
          const expanded = openIds.has(id)

          const currentTitle =
            (expanded ? expandedTitle : collapsedTitle) || title

          return (
            <div
              className={classNames('components-accordion', className, {
                'components-accordion-expanded': expanded,
              })}
            >
              <TitleComponent
                className="components-accordion-title"
                onClick={handleToggle(id)}
              >
                {!hideCarret ? (
                  <Icon type={expanded ? 'expand-vertical' : 'expand'} />
                ) : null}
                {currentTitle}
              </TitleComponent>
              {hideOnly ? (
                this.renderChildren()
              ) : (
                <VelocityTransitionGroup
                  enter={{ animation: 'slideDown' }}
                  leave={{ animation: 'slideUp' }}
                  speed={3}
                >
                  {expanded ? this.renderChildren() : null}
                </VelocityTransitionGroup>
              )}
            </div>
          )
        }}
      </Context.Consumer>
    )
  }
}
