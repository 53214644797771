export const SET_DRAG_START = Symbol('SET_DRAG_START')
export const SET_DRAG_POSITION = Symbol('SET_DRAG_POSITION')
export const END_DRAG = Symbol('END_DRAG')
export const SET_DROP_TARGET = Symbol('SET_DROP_TARGET')
export const COMPLETE_DROP = Symbol('COMPLETE_DROP')

const INITIAL_STATE = {
  isActive: false,
  dragging: false,
  startPoint: null,
  endPoint: null,
  dropTarget: null,
  context: null,
}

// REDUCER

export default (state = INITIAL_STATE, action) => {
  if (action.type === SET_DRAG_START) {
    const { startPoint, context } = action

    return {
      ...INITIAL_STATE,
      context,
      startPoint,
      endPoint: startPoint,
      isActive: true,
      dragging: true,
    }
  }

  if (action.type === SET_DRAG_POSITION) {
    const { endPoint } = action

    if (
      endPoint[0] !== state.endPoint[0] ||
      endPoint[1] !== state.endPoint[1]
    ) {
      return {
        ...state,
        endPoint,
      }
    }
  }

  if (action.type === SET_DROP_TARGET) {
    const { dropTarget } = action

    if (state.dropTarget === dropTarget) {
      return state
    }

    return {
      ...state,
      dropTarget,
    }
  }

  if (action.type === END_DRAG) {
    if (state.dropTarget) {
      return {
        ...state,
        dragging: false,
      }
    }

    return INITIAL_STATE
  }

  if (action.type === COMPLETE_DROP) {
    return INITIAL_STATE
  }

  return state
}

// ACTIONS

export const startDrag = (startPoint, context) => ({
  type: SET_DRAG_START,
  context,
  startPoint,
})

export const setDragPosition = endPoint => ({
  type: SET_DRAG_POSITION,
  endPoint,
})

export const setDropTarget = dropTarget => ({
  type: SET_DROP_TARGET,
  dropTarget,
})

export const endDrag = () => ({
  type: END_DRAG,
})

export const completeDrop = () => ({
  type: COMPLETE_DROP,
})

// SELECTORS

export const getDragPosition = state => {
  const { isActive, startPoint, endPoint } = state.editor.datasources

  if (!isActive) {
    return null
  }

  return { startPoint, endPoint }
}

export const getDropTarget = state => {
  const { isActive, dropTarget } = state.editor.datasources

  if (!isActive) {
    return null
  }

  return dropTarget
}

export const getActiveDrag = state => {
  return state.editor.datasources.isActive
}

export const getDropMenuTarget = state => {
  const { isActive, dropTarget, dragging } = state.editor.datasources

  return isActive && !dragging && dropTarget
}

export const getDragging = state => {
  const { isActive, dragging } = state.editor.datasources

  return isActive && dragging
}

export const getContext = state => {
  const { isActive, context } = state.editor.datasources

  return isActive && context
}
