/**
 * Accepts an array of flyout menu items and searches for clickable menu items
 *    within the first 3 - N levels of each submenu (N = the level of the submenu)
 *
 * Reconstructs the tree of submenus and menu items based on clickable items,
 *    removing any paths that have nothing clickable in them within three levels
 *
 * Uses nested loops instead of recursion for performance
 *
 * General Flow:
 *    - skip if the item is not an object type
 *    - null items represent dividers and should be added to its parent's children if:
 *       - there are already items present in that level (no dividers at the top)
 *       - the previous item added is not null (no consecutive dividers)
 *    - items with values represent clickable items and are added to its parent's children
 *    - if the item is not null and does not have a value, iterate over its children
 *    - if the item is inline (meaning its children are on the same level, but indented):
 *       - the function needs to be called recursively with the item's children
 *    - the deepest level only cares about nulls and items with values
 *    - if the deepest level ends with null, remove it (no bottom dividers)
 *    - if the deepest level still has items, add it to its parent's children
 *    - work back up through the loops, removing bottom dividers and adding to the parent's children
 *
 * The result should be a new tree of submenus that end with a clickable item
 *
 * @param {Object[]} topLevelItems
 */
const filterThreeLevels = topLevelItems => {
  const level1Items = []

  for (const level1Item of getChildren(topLevelItems)) {
    if (typeof level1Item !== 'object') {
      continue
    }

    if (level1Item === null) {
      if (level1Items.length && lastItemIsNotNull(level1Items)) {
        level1Items.push(level1Item)
      }

      continue
    }

    if (level1Item.value) {
      level1Items.push(level1Item)

      continue
    }

    const children = level1Item.inline
      ? filterThreeLevels(level1Item.children)
      : filterTwoLevels(level1Item.children)

    if (lastItemIsNull(children)) {
      children.pop()
    }

    if (children.length) {
      level1Items.push({ ...level1Item, children })
    }
  }

  if (lastItemIsNull(level1Items)) {
    level1Items.pop()
  }

  return level1Items
}

const filterTwoLevels = sourceItems => {
  const topLevelItems = []

  for (const level2Item of getChildren(sourceItems)) {
    if (typeof level2Item !== 'object') {
      continue
    }

    if (level2Item === null) {
      if (topLevelItems.length && lastItemIsNotNull(topLevelItems)) {
        topLevelItems.push(level2Item)
      }

      continue
    }

    if (level2Item.value) {
      topLevelItems.push(level2Item)

      continue
    }

    const children = level2Item.inline
      ? filterTwoLevels(level2Item.children)
      : filterOneLevel(level2Item.children)

    if (lastItemIsNull(children)) {
      children.pop()
    }

    if (children.length) {
      topLevelItems.push({ ...level2Item, children })
    }
  }

  return topLevelItems
}

const filterOneLevel = sourceItems => {
  const topLevelItems = []

  for (const level3Item of getChildren(sourceItems)) {
    if (typeof level3Item !== 'object') {
      continue
    }

    if (level3Item === null) {
      if (topLevelItems.length && lastItemIsNotNull(topLevelItems)) {
        topLevelItems.push(level3Item)
      }

      continue
    }

    if (level3Item.value) {
      topLevelItems.push(level3Item)
    }

    if (level3Item.inline) {
      const children = filterOneLevel(level3Item.children)

      if (lastItemIsNull(children)) {
        children.pop()
      }

      if (children.length) {
        topLevelItems.push({ ...level3Item, children })
      }
    }
  }

  return topLevelItems
}

const getChildren = children => {
  const iterable = typeof children === 'function' ? children() : children

  return iterable || []
}

const lastItemIsNotNull = children => children[children.length - 1] !== null

const lastItemIsNull = children => children[children.length - 1] === null

export default topLevelItems => filterThreeLevels(topLevelItems)
