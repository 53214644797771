import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  asyncWithLDProvider,
  useFlags as useFeatureFlag,
} from 'launchdarkly-react-client-sdk'

import { getCurrentUser } from 'ducks/users/index.ts'

import FeatureFlagIdentitySync from './FeatureFlagIdentitySync'

export const createFeatureFlagProvider = async store => {
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    deferInitialization: true,
    context: {
      kind: 'user',
      key: 'pre-init-fixed-key',
    },
  })

  return ({ children }) => {
    return (
      <LaunchDarklyProvider>
        <FeatureFlagIdentitySync />
        {children}
      </LaunchDarklyProvider>
    )
  }
}
/**
 * @returns {Object.<string, boolean | string| object | undefined>}
 */
export const useFlags = props => {
  const flags = useFeatureFlag(props)
  const user = useSelector(state => getCurrentUser(state))

  const internalFlagsLoaded = user?.internalFlags !== undefined

  const patchedFlags = useMemo(() => {
    const memoFlags = { ...(flags ?? {}), internalFlagsLoaded }

    // Merge internalFlags with LaunchDarkly flags
    if (user?.internalFlags) {
      const internalFlagKeys = Object.keys(user?.internalFlags)
      const launchDarklyFlagKeys = Object.keys(flags)
      if (internalFlagKeys.some(key => !launchDarklyFlagKeys.includes(key))) {
        console.warn(
          'User has internal flags that are not available in LaunchDarkly',
          internalFlagKeys.filter(key => !launchDarklyFlagKeys.includes(key))
        )
      }
    }

    return memoFlags
  }, [flags, user, internalFlagsLoaded])

  return patchedFlags
}
