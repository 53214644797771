export const template = (templateString, opts) => {
  const pattern = /\{\{\s*([a-zA-Z\d_\-]+)\s*\}\}/g

  let match
  let cursor = 0
  let result = ''

  while ((match = pattern.exec(templateString))) {
    const before = templateString.slice(cursor, match.index)
    const key = match[1]
    const length = match[0].length

    result += before
    result += String(opts[key] || '')

    // Update cursor
    cursor = match.index + length
  }

  result += templateString.slice(cursor)

  return result
}
