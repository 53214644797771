import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import {
  change,
  reduxForm,
  Form,
  SubmissionError,
  getFormValues,
} from 'redux-form'
import { CardElement } from '@stripe/react-stripe-js'

// components
import Modal from 'components/Shared/Modal'
import Summary from 'components/Shared/Summary'
import { PaymentForm, PaymentFormValidate } from 'components/Shared/PaymentForm'
import { withStripePromise } from 'components/Shared/TrialWarning/LoadStripe'

// ducks
import { getApp } from 'ducks/apps'
import { getLibraryById } from 'ducks/marketplace'
import { fetchOrgLicenses } from 'ducks/marketplace/licenses'
import { getCurrentUser } from 'ducks/users/index.ts'

// utils
import { formatToCurrency } from 'utils/currency'

// assets
import paymentSuccessLogo from 'components/Shared/Icon/icons/Teal_Check_Mark.gif'

import './Checkout.scss'
import { adaloBackendAxios, marketplaceAxios } from 'utils/io/http/axios'

const Checkout = ({
  handleClose,
  valid,
  handleSubmit,
  submitting,
  error,
  ...props
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { appId } = useParams()
  const app = useSelector(state => getApp(state, appId))
  const libraryId = useSelector(state => state.marketplace.checkoutLibraryId)
  const library = useSelector(state => getLibraryById(state, libraryId))
  const user = useSelector(state => getCurrentUser(state))

  const paymentIntentURL = React.useMemo(
    () => `/api/libraries/${libraryId}/payment`,
    [libraryId]
  )

  const { useNewCard } = useSelector(state =>
    getFormValues('marketplace-checkout')(state)
  )

  const [paymentSuccess, setPaymentSuccess] = React.useState(false)
  const [intent, setIntent] = React.useState({})
  const [hasPaymentMethods, setHasPaymentMethods] = React.useState(false)

  const price = React.useMemo(
    () => formatToCurrency(library?.price),
    [library?.price]
  )

  if (!libraryId) {
    return <Redirect to={`/apps/${appId}/marketplace`} />
  }

  if (!app.Organization) {
    return null
  }

  const { Organization } = app

  const handlePaymentIntent = async () => {
    // gather payment intent secret
    // request client secret
    const orgId = Organization.id

    const {
      data: { id, client_secret },
    } = await marketplaceAxios.post(paymentIntentURL, {
      orgId,
      customerId: Organization.stripeCustomerId,
    })

    setIntent({ id, client_secret })

    return { id, client_secret }
  }

  const handleBack = () => {
    if (hasPaymentMethods && useNewCard) {
      dispatch(change('marketplace-checkout', 'useNewCard', false))
    } else {
      history.goBack()
    }
  }

  const handleCheckoutSubmit = async values => {
    const { elements, stripe } = props

    // form values
    const { name, address_country, address_zip, address_city, address_line1 } =
      values

    if (!valid) {
      const errors = PaymentFormValidate(values)

      throw new SubmissionError(errors)
    }

    let client_secret = intent.client_secret
    let paymentIntentId = intent.id

    if (!client_secret) {
      // grab intent.id from registry and set in state
      const paymentIntent = await handlePaymentIntent()

      client_secret = paymentIntent.client_secret
      paymentIntentId = paymentIntent.id
    }

    let paymentMethod

    // stripe card element
    const cardElement = elements.getElement(CardElement)

    if (values.useNewCard) {
      paymentMethod = {
        card: cardElement,
        billing_details: {
          name,
          email: user.email,
          address: {
            country: address_country?.value,
            postal_code: address_zip,
            city: address_city,
            line1: address_line1,
          },
        },
      }
    } else {
      paymentMethod = values.paymentMethodId
    }

    try {
      // confirm card payment
      const { error: cardError } = await stripe.confirmCardPayment(
        client_secret,
        {
          payment_method: paymentMethod,
          receipt_email: user.email,
          setup_future_usage: values.saveNewCard ? 'on_session' : undefined,
        }
      )

      if (cardError) {
        throw cardError.message
      }

      const marketplaceInstallURL = `/library/${libraryId}/install`

      // Add license to registry and increment install count
      await adaloBackendAxios.post(marketplaceInstallURL, {
        orgId: Organization.id,
        paymentIntentId,
      })

      // update licenses
      await dispatch(fetchOrgLicenses(Organization?.id))

      // set state as successful for success modal
      setPaymentSuccess(true)
    } catch (err) {
      throw new SubmissionError({
        _error: err.message || err,
      })
    }
  }

  const formRef = React.useRef(null)

  // handle scroll to top of new card form
  React.useEffect(() => {
    if (useNewCard && formRef.current) {
      formRef.current.scrollTo({ top: 0 })
    }
  }, [formRef, useNewCard])

  const renderForm = () => {
    return (
      <div className="library-checkout">
        <PaymentForm
          formName="marketplace-checkout"
          setHasPaymentMethods={setHasPaymentMethods}
        />

        <Summary
          divided
          backgroundColor="green"
          footer={`Buying a component is something we covered in the [Adalo Terms and Conditions](${history.location.pathname}?terms=). Remember when you read that?`}
          error={error}
          markdownLinkType="internal"
        >
          <div>
            <img src={library.logo} alt="logo" />
            <span>{library.displayName}</span>
            <strong>{price}</strong>
          </div>
        </Summary>
      </div>
    )
  }

  const renderSuccess = () => {
    return (
      <Modal.Container style={{ textAlign: 'center' }}>
        <img
          className="paymentSuccessLogo"
          src={paymentSuccessLogo}
          alt="Payment Success"
        />
        <h1>Payment Successful!</h1>
      </Modal.Container>
    )
  }

  const renderBackButton = React.useCallback(() => {
    if (paymentSuccess) {
      return (
        <Modal.Button
          type="button"
          to={`/apps/${appId}/marketplace`}
          text
          teal
          outlined
          icon="marketplace"
          iconSize="small"
        >
          Back to Exploring Marketplace
        </Modal.Button>
      )
    } else {
      return (
        <Modal.Button type="button" onClick={handleBack} text>
          Back
        </Modal.Button>
      )
    }
  }, [paymentSuccess, handleBack])

  const renderSubmitButton = React.useCallback(() => {
    if (paymentSuccess) {
      return (
        <Modal.Button type="button" to={`/apps/${appId}/screens`}>
          Done
        </Modal.Button>
      )
    } else {
      return (
        <Modal.Button
          type="submit"
          disabled={!valid || submitting}
          loading={submitting}
        >
          Submit Payment
        </Modal.Button>
      )
    }
  }, [paymentSuccess, intent.id, valid, submitting, handleClose])

  return (
    <Modal scrolling size="lg" fitContent onClose={handleClose}>
      <Form onSubmit={handleSubmit(handleCheckoutSubmit)}>
        <Modal.Header>
          <h1>
            Purchase {library.displayName} for {Organization.name}
          </h1>
        </Modal.Header>
        <Modal.Content childRef={formRef}>
          {paymentSuccess ? renderSuccess() : renderForm()}
        </Modal.Content>
        <Modal.Actions>
          {renderBackButton()}
          {renderSubmitButton()}
        </Modal.Actions>
      </Form>
    </Modal>
  )
}

export default reduxForm({
  form: 'marketplace-checkout',
  validate: PaymentFormValidate,
  initialValues: {
    useNewCard: false,
    saveNewCard: false,
  },
})(withStripePromise(Checkout))
