import React, { useContext, useMemo, useState } from 'react'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux'

import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'

// utils
import { adaloBackendAxios } from 'utils/io/http/axios'
import { abbreviatedFormat } from 'utils/numbers'

// ducks
import { fetchOrgLicenses } from 'ducks/marketplace/licenses'

import { DetailsContext } from './index'

const DetailsContent = () => {
  const { library, user, orgId, isInstalled } = useContext(DetailsContext)
  const dispatch = useDispatch()
  const [adminButtonLoading, setAdminButtonLoading] = useState(false)

  const installAdmin = async () => {
    setAdminButtonLoading(true)
    const marketplaceInstallURL = `/library/${library.id}/install`

    // Add license to registry and increment install count
    await adaloBackendAxios.post(marketplaceInstallURL, {
      orgId,
    })

    // update licenses
    await dispatch(fetchOrgLicenses(orgId))
    setAdminButtonLoading(false)
  }

  const installs = useMemo(() => {
    if (library.installs === 0) {
      return 'No Installs'
    } else if (library.installs === 1) {
      return `${library.installs} Install`
    } else {
      return `${abbreviatedFormat(library.installs)} Installs`
    }
  }, [library.installs])

  const lastUpdate = useMemo(() => {
    const latestVersion = library?.Versions?.[0]

    return `Last Updated ${moment(latestVersion.createdAt).fromNow()}`
  }, [library?.Versions?.length])

  return (
    <div className="marketplace-library-details-content">
      <div className="marketplace-library-details-content-details">
        <ReactMarkdown
          allowedElements={['p', 'strong', 'em']}
          unwrapDisallowed
          skipHtml
        >
          {library.details || library.description}
        </ReactMarkdown>
      </div>
      <div className="marketplace-library-details-content-meta">
        <div className="marketplace-library-details-content-meta-item">
          <Icon type="installs" small /> {installs}
        </div>
        <div
          className="marketplace-library-details-content-meta-item"
          title={moment(library?.Versions?.[0].createdAt).format(
            'MMM Do, YYYY'
          )}
        >
          <Icon type="edit-calendar" small /> {lastUpdate}
        </div>
        {library.requiresThirdPartySubscription ? (
          <div className="marketplace-library-details-content-meta-item">
            <Icon type="support-star" small /> Requires Third Party Subscription
          </div>
        ) : null}
        {library.supportURL ? (
          <div className="marketplace-library-details-content-meta-item">
            <a href={library.supportURL} target="_blank" rel="noreferrer">
              <Icon type="help-tear" small /> Contact Developer
            </a>
          </div>
        ) : null}
        {user.admin && library.price > 0 ? (
          <Button
            onClick={installAdmin}
            disabled={isInstalled}
            loading={adminButtonLoading}
          >
            <span
              className="marketplace-library-details-content-emoji"
              role="img"
              aria-label="Ssh! Admin Install"
            >
              🤫
            </span>
            Admin Install
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default DetailsContent
