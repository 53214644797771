import React, { Component } from 'react'
import classNames from 'classnames'

import './WrappedRadio.css'

export default class WrappedRadio extends Component {
  handleClick = e => {
    const {
      input: { onChange, value },
      radioValue,
      disabled,
    } = this.props

    if (disabled || e.target === this._input || value === radioValue) {
      return
    }

    onChange(radioValue)
  }

  inputRef = el => {
    this.input = el
  }

  render() {
    const { input, label, radioValue, disabled, className, styled } = this.props

    const inputProps = { ...input, value: radioValue, disabled }

    return (
      <div
        className={classNames('wrapped-radio', className, {
          'wrapped-radio-styled': styled,
          disabled,
        })}
        onClick={this.handleClick}
      >
        <input
          {...inputProps}
          ref={this.inputRef}
          type="radio"
          checked={radioValue === input.value}
        />
        {styled ? (
          <div className="wrapped-radio-styled-bullet">
            <div className="wrapped-radio-styled-bullet-inner" />
          </div>
        ) : null}
        <label>{label}</label>
      </div>
    )
  }
}
