import { marketplaceAxios } from 'utils/io/http/axios'

const FETCH_APP_LICENSES = 'FETCH_APP_LICENSES'
const FETCH_APP_LICENSES_FULFILLED = 'FETCH_APP_LICENSES_FULFILLED'
const FETCH_ORG_LICENSES = 'FETCH_ORG_LICENSES'
const FETCH_ORG_LICENSES_FULFILLED = 'FETCH_ORG_LICENSES_FULFILLED'

// Reducers
export default (state, action) => {
  if (action.type === FETCH_APP_LICENSES_FULFILLED) {
    return {
      ...state,
      licenses: {
        ...state.licenses,
        appLicenses: {
          ...state.licenses.appLicenses,
          ...action.payload.data,
        },
      },
    }
  } else if (action.type === FETCH_ORG_LICENSES_FULFILLED) {
    return {
      ...state,
      licenses: {
        ...state.licenses,
        orgLicenses: {
          ...state.licenses.orgLicenses,
          ...action.payload.data,
        },
      },
    }
  }
}

// THUNKS

export const ensureLicensesAreLoaded =
  (orgId, appId) => async (dispatch, getState) => {
    const state = getState()

    if (
      state.marketplace.licenses.orgLicenses[orgId] &&
      state.marketplace.licenses.appLicenses[appId]
    ) {
      return
    }

    if (orgId) {
      await dispatch(fetchOrgLicenses(orgId))
    } else if (appId) {
      await dispatch(fetchAppLicenses(appId))
    }
  }

// Actions
export const fetchAppLicenses = appId => {
  const url = `/api/licenses/app/${appId}`

  return {
    type: FETCH_APP_LICENSES,
    payload: marketplaceAxios.get(url),
  }
}

export const fetchOrgLicenses = orgId => {
  const url = `/api/licenses/org/${orgId}`

  return {
    type: FETCH_ORG_LICENSES,
    payload: marketplaceAxios.get(url),
  }
}

export const fetchOrgsLicenses = orgIds => {
  const url = `/api/licenses/multipleOrg`

  const payload = marketplaceAxios.get(url, {
    params: {
      orgIds,
    },
  })

  return {
    type: FETCH_ORG_LICENSES,
    payload,
  }
}

// Selectors
export const getOrgLicenses = (state, orgId) =>
  state.marketplace.licenses.orgLicenses[orgId] || []

export const getAppLicenses = (state, appId) =>
  state.marketplace.licenses.appLicenses[appId] || []

export const getLicenses = (state, orgId, appId) => [
  ...getAppLicenses(state, appId),
  ...getOrgLicenses(state, orgId),
]

export const getOrgsLicenses = (state, orgIds) => {
  const licenses = {}

  orgIds.forEach(orgId => {
    licenses[orgId] = getOrgLicenses(state, orgId)
  })

  return licenses
}
