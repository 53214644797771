import { EditorObject } from 'utils/responsiveTypes'
import isVisible from 'utils/objects/isVisible'
import { responsivePositioningOptions } from '@adalo/constants'
import DeviceType from '../../types/DeviceType'

const { TOP, FIXED_ON_SCROLL } = responsivePositioningOptions

const isInFlow = (
  object: EditorObject,
  device: DeviceType | undefined
): boolean => {
  if (!isVisible(object, device)) {
    return false
  }

  // Sticky while scrolling cannot be set on a per-device basis so read from the base object.
  const { responsivity: { verticalPositioning = TOP } = {} } = object

  return verticalPositioning !== FIXED_ON_SCROLL
}

export default isInFlow
