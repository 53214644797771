import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrebuiltBuilderContentData } from '../types'
import {
  fetchPrebuiltLayoutSections,
  getCategories,
  getError,
  getLoading,
  getPrebuiltLayoutSections,
  getTags,
  PrebuiltLayoutSectionFilterOptions,
} from '..'

export const useLoadPrebuiltLayoutSections = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPrebuiltLayoutSections())
  }, [dispatch])
}

type UsePrebuiltLayoutSections = (
  opts?: PrebuiltLayoutSectionFilterOptions
) => {
  loading: boolean
  error?: string | null | undefined
  refetch: () => void
  prebuiltLayoutSections: PrebuiltBuilderContentData[]
  categories: string[]
  tags: string[]
}

type LayoutSectionsStoreState = Parameters<typeof getPrebuiltLayoutSections>[0]

export const usePrebuiltLayoutSections: UsePrebuiltLayoutSections = opts => {
  const [lastFetchAt, setLastFetchAt] = useState(Date.now())
  const dispatch = useDispatch()
  const loading = useSelector(getLoading)
  const error = useSelector(getError)
  const prebuiltLayoutSections = useSelector(state =>
    getPrebuiltLayoutSections(state as LayoutSectionsStoreState, opts)
  )
  const categories = useSelector(getCategories)
  const tags = useSelector(getTags)

  useEffect(() => {
    dispatch(fetchPrebuiltLayoutSections())
  }, [dispatch, lastFetchAt])

  const refetch = () => setLastFetchAt(Date.now())

  return {
    loading,
    error,
    refetch,
    prebuiltLayoutSections,
    categories,
    tags,
  }
}
