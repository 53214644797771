import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getCollection,
  updateCollection,
  getDatasource,
} from 'ducks/apps/datasources'

import Form from './Form'
import './APICollection.css'

class APICollection extends Component {
  handleChange = collection => {
    const { appId, datasourceId, id, updateCollection } = this.props

    updateCollection(appId, datasourceId, id, collection)
  }

  getDatasource = () => {
    const { datasourceId, datasource } = this.props

    return { ...datasource, id: datasourceId }
  }

  render() {
    const { id, collection } = this.props

    return (
      <>
        {[
          <Form
            key={id}
            form={`api-collection-form-${id}`}
            initialValues={collection}
            onChange={this.handleChange}
            datasource={this.getDatasource()}
          />,
        ]}
      </>
    )
  }
}

const mapStateToProps = (state, { appId, datasourceId, id }) => ({
  datasource: getDatasource(state, appId, datasourceId),
  collection: getCollection(state, appId, datasourceId, id),
})

export default connect(mapStateToProps, { updateCollection })(APICollection)
