export function validateVersionGreaterThan(version, minVersion) {
  const minParts = minVersion.split('.').map(parseVersionPart)
  const parts = version.split('.').map(parseVersionPart)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < minParts.length; i++) {
    if (parts[i] < minParts[i]) {
      return 'Version has to be greater than the last version'
    }

    if (i === parts.length - 1 && minParts[i] === parts[i]) {
      return 'Version has to be greater than the last version'
    }
  }
}

export function validateVersion(version) {
  if (!version.match(/^[\.0-9]+$/)) {
    return 'Version must contain only numbers and dots (.)'
  }

  if (!version.match(/^[0-9]+(\.[0-9]+)*$/)) {
    return 'Invalid Version'
  }

  try {
    version.split('.').map(parseVersionPart)
  } catch (error) {
    return error.message
  }
}

export function calcNextVersion(version) {
  if (version == null) {
    return '0.0.1'
  }

  try {
    const parts = version.split('.').map(parseVersionPart)

    return parts
      .map((part, index) => {
        if (index === parts.length - 1) {
          return part + 1
        }

        return part
      })
      .join('.')
  } catch (error) {
    return null
  }
}

function parseVersionPart(part) {
  const value = parseInt(part, 10)

  if (Number.isNaN(value)) {
    throw new Error('version part is not a number')
  }

  if (value < 0) {
    throw new Error('version part is negative')
  }

  return value
}
