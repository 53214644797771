import { COMPONENT, LABEL, LAYOUT_SECTION } from '@adalo/constants'
import { update } from '@adalo/utils'
import getDeviceObject from 'utils/getDeviceObject'
import {
  EditorObject,
  LabelAttributes,
  PushGraph,
  PushGraphEdge,
} from 'utils/responsiveTypes'
import { measureTextWidth } from 'utils/textMetrics'
import { CONTAINER_TYPES } from 'utils/positioning'
import { getValueParagraphs } from 'utils/text'
import getObject from '../objects/helpers/getObject'
import usesSharedLayout from '../../../utils/objects/usesSharedLayout'
import DeviceType from '../types/DeviceType'
import { ObjectList } from '../types/ObjectList'
import { ObjectPathMap } from '../types/ObjectPathMap'
import Range from './Range'
import updateDeviceLayoutForObject from '../device-layouts'
import isInFlow from '../objects/helpers/isInFlow'
import { ConditionResolver } from '../device-layouts/conditions'

const always = <T>(value: T | undefined): T => {
  if (value === undefined) {
    throw new Error('Required value is undefined')
  }

  return value
}

const buildDeviceObjectMap = (
  container: EditorObject,
  device: DeviceType | undefined
): Map<string, EditorObject> => {
  const objects = [container]
  if (container.children) {
    objects.push(...container.children)
  }

  return objects.reduce<Map<string, EditorObject>>((map, child) => {
    const deviceObject = getDeviceObject(child, device)

    map.set(child.id, deviceObject)

    return map
  }, new Map())
}

const isContainer = (object: EditorObject): boolean => {
  const { type } = object

  return (
    type === COMPONENT ||
    CONTAINER_TYPES.includes(type) ||
    type === LAYOUT_SECTION
  )
}

const getInFlowChildren = (
  container: EditorObject,
  device: DeviceType | undefined
): EditorObject[] => {
  const { children = [] } = container

  return children.filter(child => isInFlow(child, device))
}

/**
 * Returns the list of node IDs sorted by y-offset of the corresponding object.
 */
const sortNodeIdsByY = (
  nodeIds: string[],
  objectMap: Map<string, EditorObject>
): string[] => {
  const result = [...nodeIds]
  result.sort((aId: string, bId: string) => {
    const objA = always(objectMap.get(aId))

    const objB = always(objectMap.get(bId))

    return objA.y - objB.y
  })

  return result
}

const getXRangeEnd = (object: EditorObject): number => {
  // TODO(michael-adalo): Future considerations:
  // - Should we ensure that width is never negative here?  Would this have unintended consequences by masking bugs?
  // - If width is negative, should we exclude it from the push graph calculations?
  const { x, width, type } = object

  if (type === LABEL && width <= 0) {
    const {
      text,
      fontSize,
      fontFamily: evaluatedFontFamily,
      fontWeight,
      fontStyle,
      multiline,
    } = object as EditorObject & LabelAttributes

    const formattedText = (getValueParagraphs(text) as string[]).join('\n')

    return (
      x +
      measureTextWidth(formattedText, {
        fontSize,
        evaluatedFontFamily,
        fontWeight,
        fontStyle,
        multiline,
        width,
      })
    )
  }

  return x + width
}

const xRange = (object: EditorObject): Range =>
  new Range(object.x, getXRangeEnd(object))

const horizontalOverlap = (
  a: EditorObject,
  b: EditorObject
): Range | undefined => {
  const aRange = xRange(a)
  const bRange = xRange(b)

  return aRange.intersect(bRange)
}

const verticalSpaceBetween = (
  above: EditorObject,
  bottom: EditorObject
): number => {
  const aBottom = above.y + above.height

  return bottom.y - aBottom
}

const canPush = (a: EditorObject, b: EditorObject): boolean => {
  // Expect no vertical overlap. Indicates A's bottom is higher than B's top which is necessary for a push relationship.
  const aAboveB = verticalSpaceBetween(a, b) >= 0

  // Expect some part of each object's x-range to be overlapping. If the top object is off to the side, it doesn't push.
  const xRangesOverlap = horizontalOverlap(a, b) !== undefined

  return aAboveB && xRangesOverlap
}

const findPushingSubgraph = (
  pushed: EditorObject,
  deviceObjectMap: Map<string, EditorObject>,
  { nodeIds, edges }: PushGraph
): PushGraph => {
  // Find the subset of nodes which could potentially push the current object
  const includedNodes = new Set<string>()
  for (const nodeId of nodeIds) {
    const nodeObj = deviceObjectMap.get(nodeId)
    if (nodeObj === undefined) {
      // Skip any buffer nodes
      continue
    }

    if (canPush(nodeObj, pushed)) {
      includedNodes.add(nodeId)
    }
  }

  // Find edges between subgraph nodes
  const includedEdges = edges.filter(e => {
    return includedNodes.has(e.startNodeId) && includedNodes.has(e.endNodeId)
  })

  // Return the resulting subgraph
  return {
    nodeIds: Array.from(includedNodes),
    edges: includedEdges,
  }
}

/**
 * Finds all nodes which have no outgoing edges.
 */
const findGraphLeaves = ({ nodeIds, edges }: PushGraph): string[] => {
  const rootIds = new Set<string>(nodeIds)

  for (const edge of edges) {
    // Any node with an inbound edge cannot be a root.
    rootIds.delete(edge.startNodeId)
  }

  return Array.from(rootIds)
}

/**
 * The "content area" is the area within a container that is occupied by children. Ie, it is the spanning bounding box
 * of all children.
 *
 * The area between the top edge of the container and the top of the content area is treated as padding on the
 * container. Likewise for any space below the content area.
 *
 * ┌────────────────────────────────────────────────────────────┐
 * │                                                            │
 * │                                                            │
 * │                       padding-top                          │
 * │                                                            │
 * │                                                            │
 * │~~~~~~┌──────────┐~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~│
 * │      │\\\\\\\\\\│                                          │
 * │      │\\\\\\\\\\│                                          │
 * │      │\\\\\\\\\\│     content-area                         │
 * │      │\\\\\\\\\\│                                          │
 * │      │\\\\\\\\\\│                   ┌───────────┐          │
 * │      └──────────┘                   │\\\\\\\\\\\│          │
 * │                                     │\\\\\\\\\\\│          │
 * │                                     └───────────┘          │
 * │                                                            │
 * │                       ┌─────────────────────────┐          │
 * │                       │\\\\\\\\\\\\\\\\\\\\\\\\\│          │
 * │                       │\\\\\\\\\\\\\\\\\\\\\\\\\│          │
 * │~~~~~~~~~~~~~~~~~~~~~~~└─────────────────────────┘~~~~~~~~~~│
 * │                                                            │
 * │                                                            │
 * │                     padding-bottom                         │
 * │                                                            │
 * │                                                            │
 * └────────────────────────────────────────────────────────────┘
 *
 * While slightly complicated, this box model is necessary for compatibility with the current Document model mapping in
 * mapScreenToDocument().
 *
 * Therefore, the top of the content area is calculated by finding the top edge of the highest child element.
 */
const calculateContentAreaTop = (
  container: EditorObject,
  deviceObjectMap: Map<string, EditorObject>,
  device: DeviceType | undefined
): number => {
  // TODO(michael-adalo): based on feedback in https://github.com/AdaloHQ/frontend/pull/2028/files#r1164570987
  // it would be possible to refactor this and sound-check any cases without current test coverage
  const { y, height, type } = always(deviceObjectMap.get(container.id))
  // Initialize to the bottom of the container
  let contentTop
  if (type === COMPONENT) {
    // Height on screens is irrelevant to layout so we need to use something else here.
    contentTop = Number.MAX_SAFE_INTEGER
  } else {
    contentTop = y + height
  }

  // Iterate through the children and bump the value of contentTop upward as needed to include each child.
  const childTops = getInFlowChildren(container, device).map(
    ({ id: childId }) => {
      const child = always(deviceObjectMap.get(childId))

      return child.y
    }
  )

  if (childTops.length) {
    contentTop = Math.min(...childTops)
  }

  return contentTop
}

/**
 * Builds the push graph for the content of the given container, on the specified device.
 *
 * 1. Get the list of object IDs. There are the initial nodes in the graph.
 * 2. Order all node IDs by the Y-offset of the corresponding object.
 * 3. Visit each node in-order such that objects higher on the screen are visited first.
 *     a. Find the subgraph, Gsub, containing nodes which can push the current node. That is, the objects have
 *        overlapping x-ranges and the pushing object is completely above the current object.
 *     b. If Gsub is empty:
 *         i. Create a buffer node. This node will remain empty but will push the current node down.
 *         ii. Add this buffer node to Gsub.
 *     c. Gather the set of leaf nodes from Gsub.
 *     d. Calculate the minimum gap between any leaf node and the current node. This will be the distance used for all
 *        inbound edges to this node.
 *     e. For each leaf node from Gsub:
 *         i. Create an edge from the leaf node to the current node, with the minimum gap as the distance.
 *
 * After visiting all nodes, the graph should be complete. The result should be a directed acyclic graph (DAG) where
 * edges indicate that a node pushes another. Furthermore, the result should be transitively reduced such that for an
 * two nodes, u and v, there is at most one path from u to v.
 */
const calculatePushGraph = (
  container: EditorObject,
  device: DeviceType | undefined
): PushGraph => {
  const children = getInFlowChildren(container, device)

  if (children.length === 0) {
    return { nodeIds: [], edges: [] }
  }

  const deviceObjectMap = buildDeviceObjectMap(container, device)

  const contentAreaTop = calculateContentAreaTop(
    container,
    deviceObjectMap,
    device
  )

  let initialNodeIds = children.map(({ id }) => id)
  initialNodeIds = sortNodeIdsByY(initialNodeIds, deviceObjectMap)
  const visited = new Set<string>()
  const edges: PushGraphEdge[] = []

  for (const nodeId of initialNodeIds) {
    const currentObj = always(deviceObjectMap.get(nodeId))
    // Map the visited graph to a subgraph consisting of only nodes which potentially push the current node. The
    // subgraph must preserve edges between included nodes.
    const visitedGraph: PushGraph = { nodeIds: Array.from(visited), edges }
    const pushingGraph = findPushingSubgraph(
      currentObj,
      deviceObjectMap,
      visitedGraph
    )

    // Create a buffer node if the current node needs something to push it down.
    const alignedToContentAreaTop = currentObj.y - contentAreaTop === 0
    if (pushingGraph.nodeIds.length === 0 && !alignedToContentAreaTop) {
      // There are no nodes above this one. However, this node still needs to be pushed. Add a buffer node to fill this
      // role. Buffer nodes remain empty (and, thus, zero height) but they still push according to the edge distance.
      const bufferNodeId = `buffer_${nodeId}`
      pushingGraph.nodeIds.push(bufferNodeId)

      // Add the buffer node to the result node list as well. It can be considered visited immediately as buffer nodes
      // never have incoming edges to calculate.
      visited.add(bufferNodeId)
    }

    // Gather the set of leaf nodes (ie, no outbound edges) from the subgraph. The leaf nodes are sufficient because
    // there should only be a single path from any pushing node to the current node.
    const leafNodes = findGraphLeaves(pushingGraph)

    // Calculate the minimum gap between any pushing leaf and the current node. This minimum gap will be used for all
    // incoming edges.
    let minimumGap = currentObj.y - contentAreaTop

    for (const leafNodeId of leafNodes) {
      const pushingObj = deviceObjectMap.get(leafNodeId)

      let currentGap
      if (pushingObj === undefined) {
        // Assume the node is a buffer node and, thus, aligned to the top of the content area.
        currentGap = currentObj.y - contentAreaTop
      } else {
        currentGap = verticalSpaceBetween(pushingObj, currentObj)
      }

      minimumGap = Math.min(minimumGap, currentGap)
    }

    for (const leafNodeId of leafNodes) {
      edges.push({
        startNodeId: leafNodeId,
        endNodeId: nodeId,
        distance: minimumGap,
      })
    }

    visited.add(nodeId)
  }

  return { nodeIds: Array.from(visited), edges }
}

/**
 * A container needs a device-specific push graph when its children may have a unique arrangement on that device.
 *
 * Here, "arrangement" specifically means the ordering with respect to the push graph. That is, it does not consider the
 * particular size or exact position of a child, only the position relative to the child's siblings.
 *
 * With this definition, arrangements only vary when one or more children have device-specific layouts presenting the
 * opportunity fo a child to be positioned differently (on that specific device) relative to the other children.
 *
 * Needs Device-Specific Push Graph:
 * - Any child has device-specific layout
 * - Any child is hidden on a specific device
 *
 * Does NOT Need Device-Specific Push Graph:
 * - The container has a device-specific layout.
 *   - The exact size and position of children may vary by device but their arrangement relative to each other will not.
 * - The container's parent has a device-specific layout.
 * - A grandchild or other indirect descendent has a device-specific layout.
 *   - Again, this may vary the size (height in particular) of a child by device but it will not vary the arrangement.
 */
export const needsDeviceSpecificPushGraph = (
  container: EditorObject,
  device: DeviceType
): boolean => {
  const { children } = container
  if (!isContainer(container) || children === undefined) {
    return false
  }

  // TODO(device-parent): this probably needs to change to ignore the shared settings and just check the device-specific ones
  const childHasDeviceLayout = children.some(
    child => !usesSharedLayout(child, device)
  )

  const childHiddenOnDevice = children.some(
    child => child.deviceVisibility && !child.deviceVisibility[device]
  )

  return childHasDeviceLayout || childHiddenOnDevice
}

interface ConditionResolvers {
  mobile: ConditionResolver
  tablet: ConditionResolver
  desktop: ConditionResolver
}
const recursivelyUpdatePushGraphs = (
  objects: ObjectList,
  pathMap: ObjectPathMap,
  containerId: string,
  resolvers: ConditionResolvers
): ObjectList => {
  const container: EditorObject = getObject(objects, pathMap, containerId)
  if (!isContainer(container)) {
    return objects
  }

  const { children = [] } = container

  // Calculate for the shared layout
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let newList: ObjectList = update(objects, pathMap[container.id], {
    ...container,
    pushGraph: calculatePushGraph(container, undefined),
  })

  // Calculate for each device
  for (const device of ['mobile', 'tablet', 'desktop'] as const) {
    newList = updateDeviceLayoutForObject(
      newList,
      pathMap,
      container.id,
      device,
      resolvers[device],
      {
        pushGraph: calculatePushGraph(container, device),
      }
    )
  }

  // Recurse for all children
  for (const child of children) {
    newList = recursivelyUpdatePushGraphs(newList, pathMap, child.id, resolvers)
  }

  return newList
}

const calculatePushGraphs = (
  objects: ObjectList,
  pathMap: ObjectPathMap,
  screenId: string
): ObjectList => {
  const screen = getObject(objects, pathMap, screenId)
  if (screen.type !== COMPONENT) {
    throw new Error(`Object is not a screen. (Object ID: ${screenId})`)
  }

  const resolvers = {
    mobile: new ConditionResolver('mobile'),
    tablet: new ConditionResolver('tablet'),
    desktop: new ConditionResolver('desktop'),
  }

  return recursivelyUpdatePushGraphs(objects, pathMap, screen.id, resolvers)
}

export default calculatePushGraphs
