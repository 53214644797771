import {
  loadLibrary,
  removeLibrary as removeLibraryUtil,
} from 'utils/libraries'

const LOAD = 'LOAD_LIBRARY'
const LOAD_ERROR = `${LOAD}_ERROR`

const REMOVE = 'REMOVE_LIBRARY'
const REMOVE_FULFILLED = `${REMOVE}_FULFILLED`

const INITIAL_STATE = {}

const LOADING = {
  ready: false,
  loading: true,
  error: false,
}

const READY = {
  ready: true,
  loading: false,
  error: false,
}

const ERROR = {
  ready: false,
  loading: false,
  error: true,
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === LOAD || action.type === LOAD_ERROR) {
    const { libraryName, version } = action.payload

    return {
      ...state,
      [libraryName]: {
        ...state[libraryName],
        [version]: {
          ...(action.type === LOAD && { ...READY }),
          ...(action.type === LOAD_ERROR && { ...ERROR }),
        },
      },
    }
  }

  // removes library
  if (action.type === REMOVE_FULFILLED) {
    const { libraryName } = action.payload

    const newState = { ...state }
    delete newState?.[libraryName]

    return newState
  }

  return state
}

// Actions

const libraryLoaded = (libraryName, version) => ({
  type: LOAD,
  payload: {
    libraryName,
    version,
  },
})

const libraryLoadError = (libraryName, version) => ({
  type: LOAD_ERROR,
  payload: {
    libraryName,
    version,
  },
})

export const requestLibrary = (libraryName, version) => dispatch =>
  new Promise(async (resolve, reject) => {
    const loadLib = async () => {
      try {
        await loadLibrary(libraryName, version)

        return { libraryName, version }
      } catch (err) {
        throw { libraryName, version }
      }
    }

    try {
      const payload = await loadLib()
      dispatch(libraryLoaded(libraryName, version))

      resolve(payload)
    } catch (err) {
      dispatch(libraryLoadError(libraryName, version))

      reject(err)
    }
  })

export const removeLibrary = (library, librariesUsed) => ({
  type: REMOVE,
  libraryName: library.name,
  payload: () => {
    try {
      if (
        Array.isArray(!librariesUsed) &&
        !librariesUsed.includes(library.name)
      ) {
        removeLibraryUtil(library.name)
      }

      return { libraryName: library.name }
    } catch (err) {
      throw new Error(`ERROR REMOVING LIBRARY: ${library.name}`)
    }
  },
})

// Selectors

/**
 *
 * @param {*} state
 * @param {string} libraryName
 * @param {string} version
 * @returns {READY | LOADING | ERROR}
 */
const getLibraryStatus = (state, libraryName, version) => {
  const { libraries } = state.editor

  if (!libraries[libraryName] || !libraries[libraryName][version]) {
    return libraries[libraryName]?.dev ?? LOADING
  }

  return libraries[libraryName][version]
}

/**
 *
 * @param {*} state
 * @param {string} libraryName
 * @param {string} version
 * @returns {boolean}
 */
export const isLibraryLoading = (state, libraryName, version) => {
  const status = getLibraryStatus(state, libraryName, version)

  return status.loading === true
}

/**
 *
 * @param {*} state
 * @param {string} libraryName
 * @param {string} version
 * @returns {boolean}
 */
export const isLibraryReady = (state, libraryName, version) => {
  const status = getLibraryStatus(state, libraryName, version)

  return status.ready === true
}

/**
 *
 * @param {*} state
 * @param {string} libraryName
 * @param {string} version
 * @returns {boolean}
 */
export const isLibraryError = (state, libraryName, version) => {
  const status = getLibraryStatus(state, libraryName, version)

  return status.error === true
}
