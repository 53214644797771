import React from 'react'
import { reduxForm, Field } from 'redux-form'

import Modal from 'components/Shared/Modal'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Button from 'components/Shared/Button'

const FORM_NAME = 'twoFactorForm'

const validate = data => {
  const errors = {}

  if (!data.code) {
    errors.code = 'Required'
  } else if (!(data.code.match(/^\d{6}$/) || data.code.match(/^\d{4}$/))) {
    errors.code = 'Should be a 4 or 6 digit code.'
  }

  return errors
}

function TwoFactorForm(props) {
  const { handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header title="Apple 2-Factor Authentication" />
      <Modal.Content>
        <Field name="code" component={WrappedInput} label="2-Factor Code" />
        <p className="two-factor-form-explanation">
          You should receive an SMS or notification on your Mac or iOS device
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button>Submit</Button>
      </Modal.Actions>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(TwoFactorForm)
