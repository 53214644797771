import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './XanoUpsellCard.scss'

import { XANO_EXTERNAL_COLLECTIONS_DOC_URL } from '../../../constants'

export default class XanoUpsellCard extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    const { children, className } = this.props

    return (
      <div className={classNames('xano-upsell-card', className)}>
        <h2 className="upsell-title">New Xano External Users Collection!</h2>
        <p className="upsell-text">
          Add even more power to your app by exploring our new External Users
          with Xano.
        </p>
        <a
          className="upsell-link"
          href={XANO_EXTERNAL_COLLECTIONS_DOC_URL}
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </a>
        {children}
      </div>
    )
  }
}
