// TODO(dyego): convert this to TypeScript

import { createContext, useReducer } from 'react'

import baseUrlReducer, { BASE_URL_INITIAL_STATE } from './reducers/baseUrl'
import authReducer, { AUTH_INITIAL_STATE } from './reducers/auth'
import userReducer, { USER_INITIAL_STATE } from './reducers/user'

export const RESET = 'reset'

export const XanoContextProvider = createContext({})

export const GlobalXanoContextProvider = ({ children }) => {
  const [baseURLState, baseURLDispatch] = useReducer(
    baseUrlReducer,
    BASE_URL_INITIAL_STATE
  )

  const [authState, authDispatch] = useReducer(authReducer, AUTH_INITIAL_STATE)

  const [userState, userDispatch] = useReducer(userReducer, USER_INITIAL_STATE)

  return (
    <XanoContextProvider.Provider
      value={{
        baseURLState,
        baseURLDispatch,
        authState,
        authDispatch,
        userState,
        userDispatch,
      }}
    >
      {children}
    </XanoContextProvider.Provider>
  )
}
