import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { defaultAxios } from 'utils/io/http/axios'

// components
import Modal from 'components/Shared/Modal'
import Loading from 'components/Shared/Loading'
import FormattedText from 'components/Shared/FormattedText'

const TermsModal = ({ onClose }) => {
  const history = useHistory()
  const [terms, setTerms] = useState(null)

  const handleTermsFetch = () => {
    defaultAxios.get('/terms.html').then(r => setTerms(r.data))
  }

  useEffect(() => {
    if (!terms) handleTermsFetch()
  }, [terms])

  useEffect(() => {
    return () => {
      if (history.location.search.includes('terms=')) {
        history.replace({ pathname: history.location.pathname })
        history.go(-1)
      }
    }
  }, [history.location.search])

  return (
    <div>
      <Modal.Header title="Terms of Use" />
      <Modal.Content>
        {terms ? (
          <FormattedText>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: terms }} />
          </FormattedText>
        ) : (
          <Loading large expanded />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button teal onClick={onClose}>
          Done
        </Modal.Button>
      </Modal.Actions>
    </div>
  )
}

export default TermsModal
