import React, { Component } from 'react'
import { connect } from 'react-redux'

import { requestDomains, getDomains } from 'ducks/domains'

import Loading from '../../Shared/Loading'
import EmptyState from '../../Shared/EmptyState'
import CustomDomain from './CustomDomain'

class CustomDomainList extends Component {
  componentDidMount() {
    const { requestDomains, organizationId } = this.props

    requestDomains(organizationId)
  }

  render() {
    const { domains, organizationId } = this.props

    if (!domains) {
      return (
        <EmptyState>
          <Loading />
        </EmptyState>
      )
    }

    return (
      <>
        {domains.map(domain => (
          <CustomDomain domain={domain} organizationId={organizationId} />
        ))}
      </>
    )
  }
}

const mapStateToProps = (state, { organizationId }) => ({
  domains: getDomains(state, organizationId),
})

export default connect(mapStateToProps, { requestDomains })(CustomDomainList)
