import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'

import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
import ToggleField from 'components/Shared/Forms/ToggleField'

import NotificationsPreview from './NotificationsIconPreview'
import { FORM_NAME, validations } from './shared'

const AppSettingsForm = ({ appId, handleSubmit, publishing }) => {
  const selector = formValueSelector(FORM_NAME)

  const notificationsIcon = useSelector(state =>
    selector(state, 'notificationsIcon')
  )

  const hasLocationEnabled = useSelector(state =>
    selector(state, 'hasLocationEnabled')
  )

  return (
    <form id={FORM_NAME} onSubmit={handleSubmit} autoComplete="off">
      <Field
        component={SettingsFormField}
        name="displayName"
        label="App Display Name"
        placeholder="Untitled App"
        type="text"
        validate={publishing ? validations.displayName : null}
      />
      <div className="notifications-icon-wrapper">
        <Field
          isAsset
          appId={appId}
          component={SettingsFormField}
          name="notificationsIcon"
          label="Notifications Icon (96 x 96)"
          type="image"
          validate={publishing ? validations.notificationsIcon : null}
        />
        <div className="settings-form-row">
          <div className="settings-form-label-wrapper">
            <label>Status Bar Preview</label>
          </div>
          <NotificationsPreview notificationsIcon={notificationsIcon} />
          <div className="settings-form-label-wrapper">
            <p>
              *In order for your icon to show up correctly please make sure it's
              only black with a transparent background.
            </p>
          </div>
        </div>
      </div>
      <Field
        name="hasLocationEnabled"
        label="Location Features"
        component={ToggleField}
        inputValue={hasLocationEnabled ? 'On' : 'Off'}
      />
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AppSettingsForm)
