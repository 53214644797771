import { DeviceType } from '@adalo/constants'
import { isShared } from './positioning'
import { DeviceValue, EditorObject } from './responsiveTypes'

const getSpecificSharedDevices = (object: EditorObject): DeviceValue[] => {
  const possibleDevices: DeviceValue[] = Object.values(DeviceType)

  return possibleDevices.filter(device => isShared(object, device))
}

export default getSpecificSharedDevices
