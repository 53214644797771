import { EditorObject } from 'utils/responsiveTypes'
import { ObjectList } from '../../types/ObjectList'
import ObjectPath from '../ObjectPath'

const getObjectByPath = (
  objectList: ObjectList,
  path: ObjectPath
): EditorObject => {
  const pathLength = path.length()
  let currentObject: EditorObject | undefined
  let currentObjectList = objectList

  for (let currentIdx = 0; currentIdx < pathLength; currentIdx += 1) {
    const currentKey = path.get(currentIdx)
    currentObject = currentObjectList[currentKey]

    if (currentObject === undefined) {
      throw new Error(
        `Failed to find object in list. (Path: ${path.toString()})`
      )
    }
    if (currentIdx === pathLength - 1) {
      return currentObject
    }

    if (currentObject.children === undefined) {
      throw new Error(
        `Failed to find object under leaf. (Path: ${path.toString()})`
      )
    }
    currentObjectList = currentObject.children
  }
  throw new Error(`Could not find object at path: ${path.toString()}`)
}

export default getObjectByPath
