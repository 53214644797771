import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { selectObjects, selectObject } from '../../../../ducks/editor/objects'

import {
  getLaunchComponentId,
  getAuthComponentId,
  setLaunchComponentId,
  setAuthComponentId,
} from '../../../../ducks/apps'

import DraggableIndicator from './DraggableIndicator'
import './LaunchComponent.css'

class LaunchComponentIndicator extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps)
  }

  render() {
    /**
     * @type {{
     * launchComponent: object,
     * authComponent: object,
     * zoom: number,
     * match: object,
     * setLaunchComponentId: function,
     * setAuthComponentId: function,
     * objects: array,
     * isLoaded: boolean,
     * }}
     */
    const {
      launchComponent,
      authComponent,
      appId,
      setLaunchComponentId,
      setAuthComponentId,
      objects,
    } = this.props

    return (
      <div className="launch-component-indicators">
        <DraggableIndicator
          appId={appId}
          component={launchComponent}
          onChangeComponent={setLaunchComponentId}
          objects={objects}
        />
        <DraggableIndicator
          appId={appId}
          component={authComponent}
          onChangeComponent={setAuthComponentId}
          objects={objects}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  appId: match.params.appId,
  launchComponent: selectObject(
    state,
    getLaunchComponentId(state, match.params.appId)
  ),
  authComponent: selectObject(
    state,
    getAuthComponentId(state, match.params.appId)
  ),
  objects: selectObjects(state),
})

export default withRouter(
  connect(mapStateToProps, {
    setLaunchComponentId,
    setAuthComponentId,
  })(LaunchComponentIndicator)
)
