import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'

import { borderStyle, borderStyleOptions } from '@adalo/constants'

import ToggleButton from 'components/Shared/Forms/ToggleButton'

import { updateObject } from 'ducks/editor/objects'
import { getAppBranding } from 'ducks/apps'
import { isEditableSectionElement } from 'utils/layoutSections'

import SingleNumberControl from './SingleNumberControl'
import SelectControl from './SelectControl'
import ColorDropdown from './ColorDropdown'
import CommonInspectRow from './GenericRow'
import MenuControl from './Libraries/MenuControl'
import TextControl from './TextControl'

const BorderControl = ({
  object,
  updateObject,
  branding,
  boxedToggle = false,
  onChange,
}) => {
  const [savedStyle, setSavedStyle] = useState(borderStyle.SOLID)

  const handleChange = values => {
    if (onChange) {
      onChange(values)
    } else {
      updateObject(object.id, values)
    }
  }

  const handleToggle = isEnabled => {
    if (isEnabled) {
      handleChange({
        borderStyle: savedStyle,
      })
    } else {
      setSavedStyle(object.borderStyle || borderStyle.SOLID)
      handleChange({ borderStyle: borderStyle.NONE })
    }
  }

  const isEnabled = useMemo(
    () => object.borderStyle !== borderStyle.NONE,
    [object]
  )
  const layoutSection = isEditableSectionElement(object)
  const rowTitle = layoutSection ? 'Border Color' : 'Border'

  return (
    <>
      {layoutSection && (
        <MenuControl
          key="borderStyle"
          name="borderStyle"
          options={[
            { label: 'On', value: true },
            { label: 'Off', value: false },
          ]}
          value={isEnabled}
          displayName="Border"
          onChange={() => handleToggle(!isEnabled)}
        />
      )}
      <CommonInspectRow
        className="border-control"
        {...(!boxedToggle && {
          title: rowTitle,
          togglable: !layoutSection,
          toggled: isEnabled,
          onToggle: handleToggle,
        })}
      >
        {boxedToggle && (
          <CommonInspectRow title="Border" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {isEnabled ? 'On' : 'Off'}
              <ToggleButton value={isEnabled} onChange={handleToggle} />
            </div>
          </CommonInspectRow>
        )}
        {!boxedToggle && (
          <>
            <ColorDropdown
              branding={branding}
              value={object.borderColor}
              name="borderColor"
              disabled={!isEnabled}
              onChange={handleChange}
            />
            <div className="border-control-split">
              <SingleNumberControl
                gray
                title={layoutSection && 'Border Size'}
                name="borderWidth"
                value={object.borderWidth}
                disabled={!isEnabled}
                onChange={handleChange}
              />
              <SelectControl
                title={layoutSection && 'Line Type'}
                name="borderStyle"
                small={false}
                defaultValue={borderStyle.NONE}
                value={isEnabled ? object.borderStyle : savedStyle}
                disabled={!isEnabled}
                onChange={handleChange}
                options={borderStyleOptions}
              />
            </div>
          </>
        )}
        {boxedToggle && isEnabled && (
          <CommonInspectRow className="border-control-grid">
            <ColorDropdown
              branding={branding}
              value={object.borderColor}
              name="borderColor"
              disabled={!isEnabled}
              onChange={handleChange}
              label="Border Color"
            />
            <TextControl
              gray
              type="number"
              name="borderWidth"
              value={object.borderWidth}
              onChange={handleChange}
              disabled={!isEnabled}
              title="Size"
            />
            <MenuControl
              name="borderStyle"
              options={borderStyleOptions}
              value={object.borderStyle}
              onChange={handleChange}
              disabled={!isEnabled}
              displayName="Line"
            />
          </CommonInspectRow>
        )}
      </CommonInspectRow>
    </>
  )
}

const mapStateToProps = (state, { appId }) => ({
  branding: getAppBranding(state, appId),
})

BorderControl.propTypes = {
  object: PropTypes.shape({
    id: PropTypes.string.isRequired,
    borderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    borderStyle: PropTypes.string,
  }).isRequired,
  updateObject: PropTypes.func.isRequired,
  branding: PropTypes.shape({}).isRequired,
  boxedToggle: PropTypes.bool,
}

export default connect(mapStateToProps, { updateObject })(BorderControl)
