import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import { buildSearchFilter } from 'utils/search'
import { DEFAULT_COLUMN_WIDTH } from 'utils/tables'
import { requestData } from 'utils/io'

import { getData } from 'ducks/datasources'
import { loadMetrics } from 'ducks/metrics'
import {
  getTable,
  getDatasource,
  removeTableField,
  getOrderedFields,
} from 'ducks/apps/datasources'
import { getTableLayout, resizeColumn } from 'ducks/apps/tableLayout'

import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'

import { DEBOUNCE_TIME } from '../../../../constants'

import Header from './Header'
import Row from './Row'
import Dragging from './Dragging'
import './Browser.scss'

const ROW_HEIGHT = 54

class DataBrowser extends Component {
  constructor(props) {
    super(props)

    const { appId, datasourceId, onLoadMetric } = this.props

    loadMetrics(appId, datasourceId)
      .payload.then(response => {
        const { tableId } = this.props
        this.setState({ loaded: response[tableId] === 0 })
        onLoadMetric(response[tableId])
      })
      .catch(() => {
        this.setState({ loaded: true })
      })

    this.state = {
      containerWidth: 0,
      tableOffset: 0,
      loaded: false,
    }

    this.requestData = throttle(this.requestData, DEBOUNCE_TIME)
    this.searchData = debounce(this.searchData, DEBOUNCE_TIME)
  }

  table = null

  requestData = (opts = {}) => {
    let { tableOffset } = this.state
    const { appId, datasourceId, tableId, table, searchTerm } = this.props
    const queryParams = {}

    if (searchTerm) {
      queryParams.column_filter = buildSearchFilter(table, searchTerm)
    }

    if (opts.hasSearchOffset) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.tableOffset = 0
      tableOffset = 0
    }

    if (tableOffset) queryParams.offset = tableOffset
    if (opts && opts.offset) queryParams.offset = opts.offset

    return requestData({
      appId,
      datasourceId,
      tableId,
      queryParams,
      tableConfig: table,
      hasSearchOffset: opts.hasSearchOffset,
      isCollectionSearch: searchTerm,
    })
  }

  searchData = () => {
    this.requestData({ hasSearchOffset: true })
  }

  handleResize = () => {
    const { getContentRect } = this.props
    const { width } = getContentRect() || {}

    if (width) {
      this.setState({ containerWidth: width })
    }
  }

  getFields() {
    const { table } = this.props
    const fields = getOrderedFields(table, true)

    return fields
  }

  getTableWidth = () => {
    const { data, table, tableLayout } = this.props
    const { containerWidth } = this.state
    let width = 20

    if (data.length === 0) return Math.max(containerWidth)

    const keys = Object.keys(table.fields).concat([
      'id',
      'created_at',
      'updated_at',
    ])

    for (const fieldId of keys) {
      width += tableLayout[fieldId] || DEFAULT_COLUMN_WIDTH
    }

    width += 120

    return Math.max(containerWidth, width)
  }

  handleResizeColumn = (fieldId, width, skipSave = false) => {
    const { appId, datasourceId, tableId, resizeColumn } = this.props
    resizeColumn({ appId, datasourceId, tableId, fieldId, width }, skipSave)
  }

  handleRemoveColumn = fieldId => {
    const { appId, datasourceId, tableId, removeTableField } = this.props
    removeTableField(appId, datasourceId, tableId, fieldId)
  }

  getData = () => {
    const { data } = this.props

    return data
  }

  markAsLoaded = () => {
    this.setState({ loaded: true })
  }

  componentDidMount() {
    const { table } = this.props

    if (table) {
      this.requestData()
    }

    window.addEventListener('resize', this.handleResize)

    setTimeout(() => {
      this.handleResize()
    }, 50)
  }

  componentDidUpdate(prevProps, prevState) {
    const { containerWidth, loaded } = this.state
    const { table, searchTerm, data } = this.props

    if (table && !prevProps.table) {
      this.requestData()
    }

    if (searchTerm !== prevProps.searchTerm) {
      this.searchData()
    }

    if (prevState.containerWidth !== containerWidth) {
      this.getTableWidth()
    }

    if (!loaded && data.length > 0) {
      this.markAsLoaded()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  renderBody() {
    const {
      scrollY,
      appId,
      datasourceId,
      tableId,
      datasource,
      table,
      tableLayout,
      blockedList,
      idList,
      handleSelectToggle,
    } = this.props

    const { tableOffset } = this.state

    const data = this.getData()
    const fields = this.getFields()
    const height = window.innerHeight - 136
    const offset = Math.max(0, Math.floor(scrollY / ROW_HEIGHT))
    const count = Math.ceil(height / ROW_HEIGHT)
    const currentData = data.slice(offset, offset + count)

    if (currentData.length > 0) {
      const currId = currentData[currentData.length - 1].id
      const dataId = data[data.length - 1].id

      if (currId === dataId) {
        if (tableOffset !== currId) {
          const newTableOffset = data.length

          if (tableOffset !== newTableOffset) {
            window.setTimeout(() => {
              this.setState({ tableOffset: newTableOffset }, () => {
                this.requestData({ offset: data.length })
              })
            }, 0)
          }
        }
      }
    }

    return (
      <div
        className="data-browser-data"
        style={{ height: data.length * ROW_HEIGHT }}
      >
        {currentData.map((row, i) => (
          <Row
            key={row.id}
            datasource={datasource}
            table={table}
            row={row}
            appId={appId}
            datasourceId={datasourceId}
            tableId={tableId}
            fields={fields}
            tableLayout={tableLayout}
            position={(offset + i) * ROW_HEIGHT}
            divId={offset + i}
            handleSelectToggle={handleSelectToggle}
            checked={
              blockedList ? !idList.includes(row.id) : idList.includes(row.id)
            }
          />
        ))}
      </div>
    )
  }

  render() {
    const {
      appId,
      datasourceId,
      tableId,
      table,
      data,
      tableLayout,
      getContentRect,
      blockedList,
      handleBlockedListToggle,
      searchTerm,
    } = this.props

    const { loaded } = this.state

    if (!table) return null

    const fields = this.getFields()

    const styles = {
      width: this.getTableWidth(),
      paddingBottom: data.length === 0 ? 0 : null,
    }

    return (
      <div
        className={classNames('data-browser', {
          'data-browser-empty': data.length === 0,
        })}
      >
        <div className="data-browser-table" style={styles}>
          <Header
            table={table}
            fields={fields}
            tableLayout={tableLayout}
            onResizeColumn={this.handleResizeColumn}
            onRemoveColumn={this.handleRemoveColumn}
            blockedList={blockedList}
            handleBlockedListToggle={handleBlockedListToggle}
            appId={appId}
            datasourceId={datasourceId}
            tableId={tableId}
            blockedListLength={data.length}
          />
          {this.renderBody()}
        </div>
        {(!data || data.length === 0) && searchTerm === '' ? (
          <EmptyState modalWidth>
            {loaded ? <h1>No Data Yet</h1> : <Loading />}
          </EmptyState>
        ) : null}
        <Dragging
          appId={appId}
          datasourceId={datasourceId}
          tableId={tableId}
          tableLayout={tableLayout}
          table={table}
          getContentRect={getContentRect}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, datasourceId, tableId }) => ({
  table: getTable(state, appId, datasourceId, tableId),
  datasource: getDatasource(state, appId, datasourceId),
  tableLayout: getTableLayout(state, appId, datasourceId, tableId),
  data: getData(state, tableId),
})

export default connect(mapStateToProps, { resizeColumn, removeTableField })(
  DataBrowser
)
