import React from 'react'
import Lottie from 'react-lottie'

import Icon from 'components/Shared/Icon'

import scalesAnchorLeftRightAnimationData from './scales_anchor_left_right.json'
import scalesAnchorCenterAnimationData from './scales_anchor_center.json'
import staysFixedAnchorCenterAnimationData from './stays_fixed_anchor_center.json'
import staysFixedAnchorLeftAnimationData from './stays_fixed_anchor_left.json'
import staysFixedAnchorRightAnimationData from './stays_fixed_anchor_right.json'

export const LAYOUT_OPTIONS_ANIMATIONS = {
  SCALES_ANCHOR_CENTER: scalesAnchorCenterAnimationData,
  SCALES_ANCHOR_LEFT_RIGHT: scalesAnchorLeftRightAnimationData,
  STAYS_FIXED_ANCHOR_CENTER: staysFixedAnchorCenterAnimationData,
  STAYS_FIXED_ANCHOR_LEFT: staysFixedAnchorLeftAnimationData,
  STAYS_FIXED_ANCHOR_RIGHT: staysFixedAnchorRightAnimationData,
}

const LayoutOptionTooltip = ({ label, description, animationData }) => {
  const options = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
      filterSize: {
        width: '200%',
        height: '200%',
        x: '-50%',
        y: '-50%',
      },
    },
  }

  return (
    <Icon
      type="info-outline"
      small
      color="darkerGray"
      placement="right-start"
      className="layout-option-tooltip-icon"
      tooltipClassname="layout-option-tooltip-wrapper"
      tooltip={
        <div className="layout-option-tooltip-content">
          <p>{label}</p>
          <Lottie height={150} width={260} options={options} />
          {description ? (
            <p className="layout-option-tooltip-description">{description}</p>
          ) : null}
        </div>
      }
    />
  )
}

export default LayoutOptionTooltip
