import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getApiKey, createApiKey } from 'ducks/apikeys'

import {
  showModal as showUpgradeModal,
  setPaymentRouteType,
} from 'ducks/trialWarning'

import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import Icon, { IconButton } from 'components/Shared/Icon'
import Button from 'components/Shared/Button'

import { abbreviatedFormat } from 'utils/numbers'
import { getWarningMessage } from 'utils/text'
import { removeSpecialChars } from 'utils/regex'
import { getTrialState, isFeatureEnabled } from 'ducks/organizations'
import { showModal, FREE_TRIAL_MODAL } from 'ducks/editor/modals'
import { getUpgraded } from 'ducks/apps'

// TODO @danicunhac - refactor into a functional component
class AccordionTitle extends Component {
  handleRemoveCollection = () => {
    let { appId, datasourceId, removeTable, tableId, name, paying } = this.props

    if (!name) name = ''

    const message = getWarningMessage('deleteCollection', {
      name,
      paying,
    })

    const prompt = window.prompt(message)

    if (prompt && prompt.toLowerCase() === name.toLowerCase()) {
      return removeTable(appId, datasourceId, tableId)
    }

    return null
  }

  handleEditCollectionName = e => {
    e.stopPropagation()
    const { toggleEditCollection } = this.props

    return toggleEditCollection()
  }

  ensureExpanded = e => {
    e.stopPropagation()
    const { accordion, openAccordion, tableId, group } = this.props

    if (accordion !== tableId) {
      openAccordion(group, tableId)
    }
  }

  handleRecordsClick = e => {
    this.ensureExpanded(e)
    const { history, url } = this.props

    return history.push(url)
  }

  handleClickEdit = e => {
    this.ensureExpanded(e)
    const { history, url, appId, name, isXano } = this.props

    const isXanoUsers = isXano && name === 'Users'

    const path = isXanoUsers
      ? `/apps/${appId}/external-database/setup?step=1`
      : `${url}/external`

    return history.push(path)
  }

  renderRecords = () => {
    const { metric } = this.props
    const formatted = abbreviatedFormat(metric)
    const tag = metric === 1 ? 'Record' : 'Records'

    return `${formatted} ${tag}`
  }

  handleAPIDocumentation = async e => {
    if (e) {
      e.stopPropagation()
    }

    const {
      appId,
      name,
      appApiKey,
      paying,
      isAfterTrial,
      createApiKey,
      showModal,
      showUpgradeModal,
      setPaymentRouteType,
    } = this.props

    const sanitizedName = removeSpecialChars(name, true)

    const url = `/apps/${appId}/api-docs/#tag/${sanitizedName}`

    if (paying) {
      if (!appApiKey) {
        await createApiKey(appId)
      }

      window.open(url)
    } else {
      try {
        if (isAfterTrial) {
          await setPaymentRouteType('upgrade')
          await showUpgradeModal()
        } else {
          await showModal(FREE_TRIAL_MODAL, { type: 'api', appId })
          this.handleAPIDocumentation(null)
        }
      } catch (err) {
        // modal closed
        console.error("modal closed, here's the error:", err)
      }
    }
  }

  render() {
    const { name, table, disableTable, isXano } = this.props

    const icon = name === 'Users' ? 'users' : 'collections'
    const isExternal = table.type === 'api' || isXano
    const hideShowMore = isExternal && disableTable && name === 'Users'

    let options = []

    if (isExternal) {
      options.push({
        label: 'Edit Settings',
        icon: 'pencil-small',
        onClick: this.handleClickEdit,
      })
    } else {
      options.push({
        label: 'View / Edit Records',
        icon: 'collections',
        onClick: this.handleRecordsClick,
      })

      if (name !== 'Users') {
        options.push({
          label: 'Edit Name',
          icon: 'text-format',
          onClick: this.handleEditCollectionName,
        })
      }

      options.push({
        label: 'API Documentation',
        icon: 'code',
        onClick: this.handleAPIDocumentation,
      })
    }

    if (name !== 'Users') {
      const deleteLabel = isXano ? 'Remove Collection' : 'Delete Collection'

      options.push({
        label: deleteLabel,
        icon: 'trash',
        onClick: this.handleRemoveCollection,
      })
    }

    if (disableTable) {
      options = options.filter(option => (option.disabled = true))
      options = options.filter(option => option.label === 'Delete Collection')
    }

    return (
      <>
        <Icon
          type={icon}
          className={disableTable ? 'expand-disabled-icon' : ''}
        />
        <span className={disableTable ? 'expand-disabled-title' : 'title'}>
          {name}
        </span>
        {!isExternal && (
          <Button onClick={this.handleRecordsClick}>
            {this.renderRecords()}
          </Button>
        )}
        {!hideShowMore ? (
          <MultiMenuTrigger menu={options} rowHeight={40} width={230}>
            <IconButton type="more-vert" />
          </MultiMenuTrigger>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state, { match, table }) => {
  const { appId } = match.params

  const isCustomIntegrationsEnabled = isFeatureEnabled(
    state,
    'customIntegrations'
  )
  const isExternalDatabaseEnabled = isFeatureEnabled(state, 'externalDatabase')

  const { trialState } = getTrialState(state)

  const paying = getUpgraded(state, appId)
  const isAfterTrial = !paying && trialState === 'after'

  const isXano = table.type === 'xano'

  const disableCustomIntegrations =
    !isCustomIntegrationsEnabled && table.type === 'api'

  const disableExternalDatabase = !isExternalDatabaseEnabled && isXano

  const disableTable = disableCustomIntegrations || disableExternalDatabase

  return {
    appId,
    appApiKey: getApiKey(state, appId),
    paying: paying || trialState === 'during',
    isAfterTrial,
    disableTable,
    isXano,
  }
}

export default connect(mapStateToProps, {
  showModal,
  showUpgradeModal,
  setPaymentRouteType,
  createApiKey,
})(AccordionTitle)
