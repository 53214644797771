import _, { cloneDeep } from 'lodash'
import {
  COMPONENT,
  LIST,
  resizingOptions,
  responsivePositioningOptions,
} from '@adalo/constants'
import { getDeviceType, update } from '@adalo/utils'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { DeviceValue, EditorObject } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import { ObjectList } from '../types/ObjectList'
import { ObjectPathMap } from '../types/ObjectPathMap'
import getObject from '../objects/helpers/getObject'
import { NON_ZERO_WIDTH } from '../model/ObjectNode'
import { ConditionResolver } from '../device-layouts/conditions'
import updateDeviceLayoutForObject from '../device-layouts'
import DeviceType from '../types/DeviceType'
import { DEFAULT_RENDER_DEVICES } from './mapDocumentToState'
import getCustomListProperties, {
  COLUMN_BREAKPOINTS,
} from '../objects/helpers/getCustomListProperties'
import { getParent } from '../device-layouts/utils'
import getObjectDeviceParent from '../objects/helpers/getObjectDeviceParent'

const { LEFT, CENTER, RIGHT, LEFT_AND_RIGHT } = responsivePositioningOptions
const { SCALES_WITH_PARENT, FIXED } = resizingOptions

const allowedChanges = ['left', 'right', 'fixedLeft', 'fixedRight']
type Changes =
  | { left: number; right: number }
  | { fixedLeft: number }
  | { fixedRight: number }

// WARNING!!!!
// Do NOT use these functions for any part of the product where fluid motion is required

// NOTE
// These functions...
// - WILL re-calculate the left and right values for the object based on the parent container
// - WILL NOT change the width of any object
// - WILL NOT change the width of the parent container

const getContainerWidthAndOffsetX = (
  container: EditorObject
): {
  containerWidth: number
  containerOffsetX: number
} => {
  const containerWidth = container.width === 0 ? NON_ZERO_WIDTH : container.width // prettier-ignore
  if (container.type === COMPONENT) {
    return {
      containerWidth,
      containerOffsetX: 0,
    }
  }

  if (container.type === LIST && container.rowMargin && container.columnCount) {
    const columnCount = container.columnCount ?? 1
    const listContainerWidth = (containerWidth - container.rowMargin * (columnCount - 1)) / columnCount // prettier-ignore

    return {
      containerWidth: listContainerWidth,
      containerOffsetX: container.x,
    }
  }

  return {
    containerWidth,
    containerOffsetX: container.x,
  }
}

/**
 * Get a suitable parent object for the child object
 * - if the device is undefined, get the parent screen object using the current containing screen to determine the device
 * - if the device is defined, get the parent object using the device to determine the parent object
 */
const getDeviceAndParent = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  child: EditorObject,
  childDevice: DeviceType | undefined
): {
  screenDevice: DeviceType
  parentDeviceObject: EditorObject
} => {
  if (childDevice === undefined) {
    const screen = getParentScreen(objectList, pathMap, child.id)
    if (!screen) {
      throw new Error(`Could not find screen for object ${child.id}`)
    }

    const screenDevice = getDeviceType(screen.width)

    return {
      screenDevice,
      parentDeviceObject: getObjectDeviceParent(objectList, pathMap, child.id),
    }
  }

  return {
    screenDevice: childDevice,
    parentDeviceObject: getDeviceObject(
      getParent(objectList, pathMap, child.id),
      childDevice
    ),
  }
}

const getParentDeviceObject = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  child: EditorObject,
  device: DeviceType | undefined
): EditorObject => {
  const { screenDevice, parentDeviceObject } = getDeviceAndParent(
    objectList,
    pathMap,
    child,
    device
  )

  if (
    parentDeviceObject.type === LIST &&
    parentDeviceObject.columnBreakpoints === COLUMN_BREAKPOINTS.CUSTOM
  ) {
    const customListProperties = getCustomListProperties(
      parentDeviceObject,
      screenDevice
    )

    return {
      ...parentDeviceObject,
      ...customListProperties,
    }
  }

  return parentDeviceObject
}

const computeLeftRight = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  child: EditorObject,
  device: DeviceValue | undefined
): Changes => {
  const parentDevice = getParentDeviceObject(objectList, pathMap, child, device)
  const childDevice = getDeviceObject(child, device)

  const { containerWidth, containerOffsetX } = getContainerWidthAndOffsetX(parentDevice) // prettier-ignore
  const { responsivity = {} } = childDevice
  const {
    horizontalScaling: scaling = FIXED,
    horizontalPositioning: positioning = scaling === FIXED ? LEFT : CENTER,
  } = responsivity

  const leftOffset = childDevice.x - containerOffsetX
  const width = childDevice.width
  const rightOffset = containerWidth - (leftOffset + width)

  // Documentation:
  // src/ducks/editor/instructions/CONCEPTS.md
  // Refer to section "Margins"
  if (scaling === SCALES_WITH_PARENT && positioning === CENTER) {
    const leftRatio = containerWidth === 0 ? 0 : leftOffset / containerWidth
    const rightRatio = containerWidth === 0 ? 0 : rightOffset / containerWidth

    return {
      left: leftRatio,
      right: rightRatio,
    }
  } else if (scaling === SCALES_WITH_PARENT && positioning === LEFT_AND_RIGHT) {
    return {
      left: leftOffset,
      right: rightOffset,
    }
  } else if (scaling === FIXED && positioning === LEFT) {
    return {
      fixedLeft: leftOffset,
    }
  } else if (scaling === FIXED && positioning === RIGHT) {
    return {
      fixedRight: rightOffset,
    }
  } else if (scaling === FIXED && positioning === CENTER) {
    const marginLeft = -width / 2
    const leftRatio = containerWidth === 0 ? 0 : (leftOffset - marginLeft) / containerWidth // prettier-ignore

    return {
      fixedLeft: leftRatio,
    }
  }

  throw new Error(
    `Object has unsupported horizontal layout combination. horizontalScaling: ${scaling}; horizontalPositioning: ${positioning}`
  )
}

export const computeLeftRightForObject = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  child: EditorObject,
  renderDevices: (DeviceType | undefined)[]
): ObjectList => {
  let updatedList = objectList

  for (const device of renderDevices) {
    if (device === undefined) {
      continue
    }

    const changes = computeLeftRight(objectList, pathMap, child, device)
    const removeProps = _.difference(allowedChanges, Object.keys(changes))

    const resolver = new ConditionResolver(device)
    updatedList = updateDeviceLayoutForObject(
      updatedList,
      pathMap,
      child.id,
      device,
      resolver,
      changes,
      removeProps
    )
  }

  if (renderDevices.includes(undefined)) {
    const changes = computeLeftRight(objectList, pathMap, child, undefined)
    const updatedChild = getObject(updatedList, pathMap, child.id)

    const removeProps = _.difference(allowedChanges, Object.keys(changes))
    for (const change of removeProps) {
      delete updatedChild[change]
    }

    updatedList = update(updatedList, pathMap[child.id], {
      ...updatedChild,
      ...changes,
    }) as ObjectList
  }

  return updatedList
}

export const refreshLeftRightForObjects = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  objectIds: string[],
  renderDevices: (DeviceType | undefined)[] = DEFAULT_RENDER_DEVICES
): ObjectList => {
  if (Array.isArray(objectIds) && objectIds.length === 0) {
    return objectList
  }

  let updatedList = cloneDeep(objectList)

  for (const objectId of objectIds) {
    const object = getObject(updatedList, pathMap, objectId)

    updatedList = computeLeftRightForObject(
      updatedList,
      pathMap,
      object,
      renderDevices
    )
  }

  return updatedList
}

export const refreshLeftRightForObjectChildren = (
  objectList: ObjectList,
  pathMap: ObjectPathMap,
  objectId: string,
  renderDevices: (DeviceType | undefined)[] = DEFAULT_RENDER_DEVICES
): ObjectList => {
  const group = getObject(objectList, pathMap, objectId)

  if (Array.isArray(group.children) && group.children.length > 0) {
    const childIds = group.children.map(child => child.id)

    return refreshLeftRightForObjects(
      objectList,
      pathMap,
      childIds,
      renderDevices
    )
  }

  return objectList
}
