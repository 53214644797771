import React, { Component } from 'react'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { getDayName } from 'utils/datetime'
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import { findDomain, formatTick, GraphTooltip } from './CumulativeSignupsChart'

import '../Analytics.css'

export default class UserChart extends Component {
  state = {
    data: [],
  }

  async componentDidMount() {
    await this.getData()
  }

  componentDidUpdate(prevProps) {
    const { range, granularity, start } = this.props

    if (
      start !== prevProps.start ||
      granularity !== prevProps.granularity ||
      range !== prevProps.range
    ) {
      this.getData()
    }
  }

  getData = async () => {
    let { appId, start, end, granularity } = this.props
    const queryType = 'active'

    start = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    end = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`

    const data = await adaloBackendAxios.post(`/analytics`, {
      appId,
      queryType,
      start,
      end,
      granularity,
    })

    if (data) {
      if (data.data.success && data.data.data.length !== 0) {
        const name = 'Active Users'

        const chartData = [
          {
            name,
            data: data.data.data,
          },
        ]

        if (chartData[0].data) {
          for (let i = 0; i < chartData[0].data.length; i += 1) {
            chartData[0].data[i].y = +chartData[0].data[i].y
          }
        }

        let max = chartData[0].data.reduce((prev, current) => {
          return prev.y > current.y ? prev : current
        })

        max = findDomain(max.y)
        const maxLabel = formatTick(max, 1)
        const halfLabel = formatTick(max / 2, 1)

        this.setState({
          data: chartData,
          max,
          maxLabel,
          halfLabel,
        })
      } else {
        this.setState({
          data: [{ name: '', data: [{ x: '', y: 0 }] }],
        })
      }
    } else {
      this.setState({
        data: [{ name: '', data: [{ x: '', y: 0 }] }],
      })
    }
  }

  renderRange() {
    const { start, end } = this.props
    const startName = getDayName(start, 'en-US')
    const endName = getDayName(end, 'en-US')

    const startString = `${startName} ${
      start.getMonth() + 1
    }/${start.getDate()}/${start.getFullYear()}`

    const endString = `${endName} ${
      end.getMonth() + 1
    }/${end.getDate()}/${end.getFullYear()}`

    return (
      <div className="user-chart-date-container">
        <h2 className="user-chart-start-date grey-header">{startString}</h2>
        <h2 className="user-chart-end-date grey-header">{endString}</h2>
      </div>
    )
  }

  render() {
    const { data, max, maxLabel, halfLabel } = this.state
    const { width, start, end, granularity } = this.props
    const maxDomain = max || 'auto'

    return (
      <div>
        <div className="user-chart-header-container">
          <h2 className="user-chart-header">Active Users Over Time</h2>
        </div>
        <div
          className="custom-tick-max"
          style={maxLabel && maxLabel !== 0 ? null : { opacity: 0 }}
        >
          <span>{maxLabel || ''}</span>
        </div>
        <div
          className="custom-tick-half"
          style={halfLabel && halfLabel !== 0 ? null : { opacity: 0 }}
        >
          <span>{halfLabel || ''}</span>
        </div>
        <div className="app-analytics-module-container app-analytics-module-container-line">
          <LineChart width={width - 62} height={233}>
            <XAxis
              hide
              dataKey="x"
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis hide type="number" domain={[0, maxDomain]} />
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              horizontalPoints={[5, 116]}
            />
            <Tooltip content={<GraphTooltip granularity={granularity} />} />
            {data.map(d => (
              <Line
                type="monotone"
                dataKey="y"
                stroke="rgb(168, 32, 88)"
                strokeWidth={3}
                data={d.data}
                name={d.name}
                key={d.name}
                dot={false}
              />
            ))}
          </LineChart>
        </div>
        {start && end && data ? this.renderRange() : null}
      </div>
    )
  }
}
