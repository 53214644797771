import { getDeviceType, pathLength, subPath, update } from '@adalo/utils'
import {
  GROUP,
  LAYOUT_SECTION,
  SECTION,
  resizingOptions,
  responsivePositioningOptions,
} from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'
import translateChildren from 'utils/operations/translateChildren'
import getDeviceObject from 'utils/getDeviceObject'
import getSpecificSharedDevices from 'utils/getSpecificSharedDevices'
import usesSharedLayout from 'utils/objects/usesSharedLayout'
import { removeUnusedSize } from 'utils/objects/removeUnusedSize'
import {
  resizeSectionElements,
  getContainerFromSection,
  getSectionFromContainer,
  isContainerSectionElement,
} from 'utils/layoutSections'
import Length from '../model/Length'
import mapScreenToDocument from './mapScreenToDocument'
import { ObjectList } from '../types/ObjectList'
import mapDocumentToState, {
  DEFAULT_RENDER_DEVICES,
} from './mapDocumentToState'
import DeviceType from '../types/DeviceType'
import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'
import getObject from '../objects/helpers/getObject'
import getContainingScreen from '../objects/helpers/getContainingScreen'
import updateDeviceLayoutForObject from '../device-layouts'
import layoutParent from './layoutParent'
import getObjectByPath from '../objects/helpers/getObjectByPath'
import ObjectPath from '../objects/ObjectPath'
import calculatePushGraphs from '../pushing/calculatePushGraphs'
import { ConditionResolver } from '../device-layouts/conditions'

const { LEFT, RIGHT, CENTER, LEFT_AND_RIGHT } = responsivePositioningOptions
const { SCALES_WITH_PARENT, FIXED } = resizingOptions

export interface ResizeElementOptions {
  objectId: string
  width: number
  height: number
}

export interface ResizeElementInstruction {
  operation: 'resizeElement'
  options: ResizeElementOptions
}

export const resizeElementHandler = (
  state: InstructionState,
  instructionOptions: ResizeElementOptions
): InstructionState => {
  const { list: oldList, pathMap } = state
  let { objectId, width, height } = instructionOptions

  const oldObject = getObject(oldList, pathMap, objectId)
  const screenObject = getContainingScreen(oldList, pathMap, objectId)

  // Find the parent object
  const childPath = pathMap[objectId]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parentPath: string = subPath(childPath, pathLength(childPath) - 1)

  const { type: parentType, id: parentId } = getObjectByPath(
    oldList,
    ObjectPath.fromString(parentPath)
  )

  const {
    id: screenId,
    width: screenWidth,
    height: screenHeight,
  } = screenObject
  const screenDevice = getDeviceType(screenWidth)

  // First apply vertical resizing and any translation. These operations should not affect layout so they are done in isolation before building the Document model.
  let newObject: EditorObject = { ...oldObject }
  let newList: ObjectList = [...oldList]

  // Cap resizing for layout section elements
  // Need to check if children exist first, because this function runs immediately upon creating a new section, before children are created
  if (
    oldObject.type === LAYOUT_SECTION &&
    oldObject.children &&
    oldObject.children[0]
  ) {
    const container = getContainerFromSection(oldObject)
    // sanity-check(device-parent): `screenDevice` will not be undefined here
    const { y: containerY } = getDeviceObject(container, screenDevice)
    // sanity-check(device-parent): `screenDevice` will not be undefined here
    const { y: sectionY } = getDeviceObject(oldObject, screenDevice)
    const padding = containerY - sectionY
    const minSectionHeight = 2 * padding + 1

    if (height < minSectionHeight) {
      height = minSectionHeight
    }
  } else if (oldObject.type === SECTION && oldObject.purpose === 'container') {
    const section = getSectionFromContainer(oldList, pathMap, oldObject)
    const { height: sectionHeight, width: sectionWidth } = getDeviceObject(
      section,
      screenDevice
    )

    if (height > sectionHeight) {
      height = sectionHeight
    }

    if (width > sectionWidth) {
      width = sectionWidth
    }
  }

  // Update the layout for the current device if the object has device-specific layouts enabled; otherwise, update the shared layout.
  const { height: oldHeight, width: prevWidth } = getDeviceObject(
    oldObject,
    screenDevice
  )
  const diffHeight = height === undefined ? 0 : height - oldHeight
  const diffWidth = width === undefined ? 0 : width - prevWidth

  let updatedDevice: DeviceType | undefined
  let devicesToUpdate: DeviceType[] = [screenDevice]

  if (
    // Only update the shared layout if we're currently on the object's initial screen
    ((oldObject.initialDevice && screenDevice === oldObject.initialDevice) ||
      !oldObject.initialDevice) &&
    usesSharedLayout(oldObject, screenDevice)
  ) {
    devicesToUpdate = getSpecificSharedDevices(oldObject)

    // Update the base object
    updatedDevice = undefined
    newObject = {
      ...oldObject,
      height: diffHeight + oldObject.height,
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newList = update(newList, pathMap[objectId], newObject)
  }

  for (const deviceType of devicesToUpdate) {
    const { height: oldDeviceHeight } = getDeviceObject(oldObject, deviceType)

    // Note this re-calculates the height for the current device. It's technically redundant for that device but allows us to run the same code across all devices being modified.
    const changes = { height: diffHeight + oldDeviceHeight }
    const resolver = new ConditionResolver(deviceType)

    newList = updateDeviceLayoutForObject(
      newList,
      pathMap,
      objectId,
      deviceType,
      resolver,
      changes
    )

    newObject = getObject(newList, pathMap, objectId)
  }

  if (screenDevice && newObject[screenDevice]) {
    newObject[screenDevice] = removeUnusedSize(
      oldObject,
      newObject[screenDevice]
    )
  }

  newObject = translateChildren(
    newObject,
    oldObject,
    updatedDevice,
    updatedDevice === undefined
  )

  if (newObject?.type === LAYOUT_SECTION && diffHeight && oldObject.children) {
    newObject.children = oldObject.children?.map(child => {
      const newChildren = child.children?.map(container =>
        resizeSectionElements(container, oldObject, diffHeight, screenDevice)
      )

      const newChild = child
      if (newChildren) {
        newChild.children = newChildren
      }

      return resizeSectionElements(
        newChild,
        oldObject,
        diffHeight,
        screenDevice
      )
    })
  }

  // TODO(toby): Should device be set here?
  newList = updateChangedObject(newList, pathMap, newObject, undefined)

  // Apply the horizontal resize using the Document model so that children reflow correctly.
  // Create a document for the screen
  const document = mapScreenToDocument(newList, pathMap, screenId)

  // Find the resized element node within the screen
  const elementNode = document.getObjectById(objectId)
  if (elementNode === undefined) {
    throw new Error(
      `Element node does not exist in screen document (Object ID: ${objectId})`
    )
  }

  const updateDevices: (DeviceType | undefined)[] = [screenDevice]

  for (const updateDevice of updateDevices) {
    // Resize the node horizontally.
    // The element's layout constraints determine which styles need to be adjusted.
    const { responsivity = {} } = getDeviceObject(newObject, updateDevice)
    const {
      horizontalScaling: scaling = FIXED,
      horizontalPositioning: positioning = scaling === FIXED ? LEFT : CENTER,
    } = responsivity

    const { width: parentWidth } = layoutParent(
      document,
      elementNode,
      updateDevice,
      screenWidth,
      screenHeight
    )

    if (scaling === FIXED && positioning === LEFT) {
      const oldWidth = elementNode.getUsedStyleRule('width', updateDevice)
      const newWidth = Length.fromPixels(width)
      // Appending the new rule will give it higher precedence than the original rule.
      elementNode.addStyleRule('width', newWidth, oldWidth.condition?.device)
    } else if (scaling === FIXED && positioning === CENTER) {
      // Assumes `marginLeft` on the element is '-50%' and won't need to be adjusted.
      const oldWidth = elementNode.getUsedStyleRule('width', updateDevice)
      const newWidth = Length.fromPixels(width)
      elementNode.addStyleRule('width', newWidth, oldWidth.condition?.device)
    } else if (scaling === FIXED && positioning === RIGHT) {
      // Changing the width of a right-aligned element without also adjusting the 'right' style means the object will
      // shrink from the left side. The intention is to adjust the right side of the element so the 'right' spacing will
      // need to be adjusted in proportion to the change in width.

      const { value: oldWidth = Length.ZERO, condition: widthCondition } =
        elementNode.getUsedStyleRule('width', updateDevice)
      const delta = width - oldWidth.toExact(parentWidth)

      const newWidth = Length.fromPixels(width)
      elementNode.addStyleRule('width', newWidth, widthCondition?.device)

      const { value: oldRight = Length.ZERO, condition: rightCondition } =
        elementNode.getUsedStyleRule('right', updateDevice)
      const oldRightPixels = oldRight.toExact(parentWidth)
      const newRight = Length.fromPixels(oldRightPixels - delta)
      elementNode.addStyleRule('right', newRight, rightCondition?.device)
    } else if (scaling === SCALES_WITH_PARENT && positioning === CENTER) {
      // This constraint calculates the width by subtracting 'left' and 'right' from the parent. Changing the width must
      // be done indirectly by altering 'right'. ('left' should stay fixed to avoid shifting the left edge of the element.)

      const { value: left = Length.ZERO } = elementNode.getUsedStyleRule(
        'left',
        updateDevice
      )
      const { value: oldRight = Length.ZERO, condition: rightCondition } =
        elementNode.getUsedStyleRule('right', updateDevice)

      const leftPixels = left.toExact(parentWidth)
      const oldRightPixels = oldRight.toExact(parentWidth)
      const oldWidth = parentWidth - (leftPixels + oldRightPixels)

      const delta = width - oldWidth

      const newRightPixels = oldRightPixels - delta
      // This constraint type has parent-relative values for 'right'
      const newRightRatio = newRightPixels / parentWidth
      elementNode.addStyleRule(
        'right',
        Length.fromPercent(newRightRatio),
        rightCondition?.device
      )
    } else if (
      scaling === SCALES_WITH_PARENT &&
      positioning === LEFT_AND_RIGHT
    ) {
      // This constraint calculates the width by subtracting 'left' and 'right' from the parent. Changing the width must
      // be done indirectly by altering 'right'. ('left' should stay fixed to avoid shifting the left edge of the element.)

      const { value: left = Length.ZERO } = elementNode.getUsedStyleRule(
        'left',
        updateDevice
      )
      const { value: oldRight = Length.ZERO, condition: rightCondition } =
        elementNode.getUsedStyleRule('right', updateDevice)

      const leftPixels = left.toExact(parentWidth)
      const oldRightPixels = oldRight.toExact(parentWidth)
      const oldWidth = parentWidth - (leftPixels + oldRightPixels)

      const delta = width - oldWidth

      const newRightPixels = oldRightPixels - delta
      elementNode.addStyleRule(
        'right',
        Length.fromPixels(newRightPixels),
        rightCondition?.device
      )
    } else {
      throw new Error(
        `Object has unsupported horizontal layout combination. horizontalScaling: ${scaling}; horizontalPositioning: ${positioning}`
      )
    }
  }

  let updateId = objectId

  if (parentType === GROUP) {
    updateId = parentId
  } else if (isContainerSectionElement(newObject) && diffWidth) {
    const section = getSectionFromContainer(newList, pathMap, newObject)

    if (section) {
      updateId = section.id
    }
  }

  const viewingDevice = usesSharedLayout(newObject, screenDevice)
    ? undefined
    : screenDevice

  // Convert the document back to a valid state
  newList = mapDocumentToState(
    document,
    newList,
    pathMap,
    screenWidth,
    screenHeight,
    updateId,
    DEFAULT_RENDER_DEVICES,
    'resizeElement',
    viewingDevice
  )

  newList = calculatePushGraphs(newList, pathMap, screenId)

  return {
    ...state,
    list: newList,
  }
}

const resizeElement = (
  objectId: string,
  width: number,
  height: number
): ResizeElementInstruction => ({
  operation: 'resizeElement',
  options: {
    objectId,
    width,
    height,
  },
})

export default resizeElement
