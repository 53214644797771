import React, { Component } from 'react'
import classNames from 'classnames'

import Icon from '../../Shared/Icon'
import './TextAlignControl.css'

export default class TextAlignControl extends Component {
  handleChange = newValue => () => {
    const { name, onChange } = this.props

    onChange({ [name]: newValue })
  }

  render() {
    let { value } = this.props

    value = value || 'left'

    return (
      <div className="text-align-control">
        <TextAlignButton
          direction="left"
          value={value}
          onChange={this.handleChange('left')}
        />
        <TextAlignButton
          direction="center"
          value={value}
          onChange={this.handleChange('center')}
        />
        <TextAlignButton
          direction="right"
          value={value}
          onChange={this.handleChange('right')}
        />
      </div>
    )
  }
}

const TextAlignButton = ({ direction, value, onChange }) => (
  <span
    onClick={onChange}
    className={classNames('text-align-item', { selected: value === direction })}
  >
    <Icon type={`text-align-${direction}`} />
  </span>
)
