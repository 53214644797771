import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import { connect, useSelector } from 'react-redux'
import { getCurrentOrganizationId } from 'ducks/organizations'
import { startTrial as startIntegrationPackTrial } from 'ducks/apps/trial'
import useSetSeenTrialEndModal from 'hooks/useSetSeenTrialEndModal'

import Modal from '../Modal'

import './IntegrationTrialModal.scss'
import SuccessfulTrialAnimation from './integrationTrialSuccess'
import externalCollectionsIcon from './external-collections-integration.png'
import customActionsIcon from './custom-actions-integration.png'
import collectionApiIcon from './collection-api-integration.png'
import AdaloStarBullet from './adalo-star-bullet'

const FORM_NAME = 'integrationTrialModal'

const integrationTrialPack = [
  {
    title: 'External Collections',
    type: 'external-collections',
    image: externalCollectionsIcon,
    bullets: [
      'Integration data from external sources like Airtable & Google',
      'Works with custom APIs',
    ],
    link: 'https://help.adalo.com/integrations/external-collections-with-apis',
  },
  {
    title: 'Custom Actions',
    type: 'custom-actions',
    image: customActionsIcon,
    bullets: [
      'Connect & run actions in external apps (i.e. trigger an email, text, or Slack message)',
      'Works with Zapier, Integromat, in addition to any custom API',
    ],
    link: 'https://help.adalo.com/integrations/custom-actions',
  },
  {
    title: 'Collections API',
    type: 'api',
    image: collectionApiIcon,
    bullets: [
      "Full access to your Adalo App's API",
      'Send and receive custom API requests',
    ],
    link: 'https://help.adalo.com/integrations/the-adalo-api',
  },
]

const getModalTitle = type => {
  let title = 'Interested In Trying Out External Collections?'

  if (type === 'api') {
    title = `Interested In Trying Out Your App's Collections API?`
  } else if (type === 'custom-actions') {
    title = 'Interested In Trying Out Custom Actions?'
  }

  return title
}

const getSecondaryButtonText = step => {
  if (step === 1) {
    return 'Not Right Now'
  }

  return 'Done'
}

const getPrimaryButtonText = (step, type) => {
  let buttonText = 'Start Integrations Pack Trial'

  if (step !== 1) {
    if (type === 'api') {
      buttonText = 'View Adalo API Documentation'
    } else if (type === 'custom-actions') {
      buttonText = 'Create New Custom Action'
    } else if (type === 'externalCollection') {
      buttonText = 'Connect An External Collection'
    }
  }

  return buttonText
}

const getContent = step => {
  if (step === 1) {
    return <StepOne />
  } else {
    return <StepTwo />
  }
}

const StepOne = () => (
  <>
    <h2 className="integration-trial-header">
      Start your{' '}
      <span className="integration-trial-day">14-day free trial</span> of the
      Integration Pack to get full use of External Collections, Custom Actions,
      & Collections API. No credit card needed!
    </h2>
    <div className="integration-trial-container">
      {integrationTrialPack.map(integration => (
        <div className="integration-trial-box" key={integration.title}>
          <img
            className="integration-trial-image"
            src={integration.image}
            alt={integration.title}
          />
          <h1>{integration.title}</h1>
          <ul>
            {integration.bullets.map(bullet => (
              <div className="integration-trial-bullet" key={bullet}>
                <AdaloStarBullet
                  fill={
                    integration.type === 'custom-actions' ? '#ffc00e' : '#333'
                  }
                />
                <li>{bullet}</li>
              </div>
            ))}
          </ul>
          <div className="integration-trial-bottom-section">
            <Modal.Button
              small
              type="button"
              text
              className="integration-trial-learn-btn"
              to={integration.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </Modal.Button>
          </div>
        </div>
      ))}
    </div>
  </>
)

const StepTwo = () => (
  <div className="success-integration-pack-container">
    <SuccessfulTrialAnimation />
    <h1>Congrats! You now have 14 days to try out integrations on Adalo!</h1>
    <p>
      The Integrations Pack Trial includes access to External Collections,
      Custom Actions and use of the Collections API.
    </p>
  </div>
)

const IntegrationTrialModal = ({
  startIntegrationPackTrial,
  type,
  appId,
  onCancel: closeModal,
  onSuccess,
}) => {
  const title = getModalTitle(type)
  const [step, setStep] = useState(1)

  const currentOrgId = useSelector(getCurrentOrganizationId)
  useSetSeenTrialEndModal(false)

  const handleClick = () => {
    setStep(2)

    if (currentOrgId) {
      startIntegrationPackTrial(currentOrgId, appId)
    }
  }

  return (
    <form className="integration-trial-modal" onSubmit={onSuccess}>
      {step === 1 && <Modal.Header style={{ padding: '50px' }} title={title} />}
      <Modal.Content style={{ padding: '20px 50px 40px 50px' }}>
        {getContent(step)}
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button type="button" text onClick={closeModal}>
          {getSecondaryButtonText(step)}
        </Modal.Button>
        {step === 1 && (
          <Modal.Button orange type="button" onClick={handleClick}>
            {getPrimaryButtonText(step, type)}
          </Modal.Button>
        )}
        {step === 2 && (
          <Modal.Button orange type="submit">
            {getPrimaryButtonText(step, type)}
          </Modal.Button>
        )}
      </Modal.Actions>
    </form>
  )
}

const connected = connect(null, {
  startIntegrationPackTrial,
})(IntegrationTrialModal)

export default reduxForm({ form: FORM_NAME })(connected)
