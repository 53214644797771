import React, { Component } from 'react'
import classNames from 'classnames'

export default class Point extends Component {
  handleMouseDown = e => {
    const { onSelect } = this.props

    e.stopPropagation()
    onSelect()
  }

  renderControlPoint = (point, controlKey) => {
    const { scalePoint, setDraggingInControl, setDraggingOutControl } =
      this.props

    if (!point || !point[controlKey]) {
      return null
    }

    const startPoint = scalePoint(point.point)
    const endPoint = scalePoint(point[controlKey])

    const action =
      controlKey === 'inControl' ? setDraggingInControl : setDraggingOutControl

    return (
      <g>
        <line
          className="shape-editor-line"
          x1={startPoint[0]}
          y1={startPoint[1]}
          x2={endPoint[0]}
          y2={endPoint[1]}
        />
        <PointControl point={endPoint} onMouseDown={action} />
      </g>
    )
  }

  render() {
    const { point, isClosePoint, selected, scalePoint } = this.props

    return (
      <g className="shape-editor-point-wrapper">
        {selected ? (
          <React.Fragment>
            {this.renderControlPoint(point, 'inControl')}
            {this.renderControlPoint(point, 'outControl')}
          </React.Fragment>
        ) : null}
        <PointControl
          point={scalePoint(point.point)}
          selected={selected}
          isClosePoint={isClosePoint}
          onMouseDown={this.handleMouseDown}
        />
      </g>
    )
  }
}

class PointControl extends Component {
  handleMouseDown = e => {
    const { onMouseDown } = this.props

    e.preventDefault()
    e.stopPropagation()

    if (onMouseDown) {
      onMouseDown(e)
    }
  }

  render() {
    const { point, selected, isClosePoint } = this.props

    return (
      <g onMouseDown={this.handleMouseDown}>
        <ellipse
          className={classNames('shape-editor-point', { selected })}
          cx={point[0]}
          cy={point[1]}
          rx={3}
          ry={3}
        />
        <ellipse
          className={classNames('shape-editor-point-target', {
            'cursor-pen-close-path': isClosePoint,
          })}
          cx={point[0]}
          cy={point[1]}
          rx={5}
          ry={5}
        />
      </g>
    )
  }
}
