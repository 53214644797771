import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'

import { getApps, templateIdIsBlankType } from 'ducks/apps'
import { isFeatureEnabled } from 'ducks/organizations'
import { showUpgradeModal } from 'ducks/trialWarning'

import Button from 'components/Shared/Button'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import WrappedRadio from 'components/Shared/Forms/WrappedRadio'
import Alert from 'components/Shared/Alert'

import { FORM_NAME } from './shared'
import WizardFooter from './WizardFooter'

const WizardFormAdvancedPage = ({
  handleSubmit,
  datasourceType,
  templateId,
  apps,
  change,
  isExternalDatabaseEnabled,
  showUpgradeModal,
}) => {
  const [originalTemplateId, setOriginalTemplateId] = useState('')

  useEffect(() => {
    setOriginalTemplateId(templateId)
  }, [])

  const showAppOptions = datasourceType === 'copy'

  const handleClickUpgrade = async () => {
    await showUpgradeModal('upgrade')
  }

  return (
    <>
      <form
        id="new-app-advanced-form"
        className="new-app-form"
        onSubmit={handleSubmit}
      >
        <div className="new-app-advanced-form-title">Advanced Options</div>
        <div className="new-app-advanced-settings">
          <div className="new-app-datasource-help">
            How do you want to set up your data?
          </div>
          <Field
            className="new-app-datasource-option"
            component={WrappedRadio}
            styled
            name="datasourceType"
            radioValue="database"
            label="Create New Database"
          />

          <Field
            className="new-app-datasource-option"
            component={WrappedRadio}
            styled
            name="datasourceType"
            radioValue="copy"
            disabled={apps.length === 0}
            label="Share Database with An Existing App..."
          />

          {showAppOptions ? (
            <div className="new-app-datasource-app-select">
              <Field
                autoSelect
                component={WrappedSelect}
                name="datasourceAppId"
                options={apps
                  .filter(app => !app.externalUsersEnabled)
                  .map(app => ({
                    label: app.name,
                    value: app.id,
                  }))}
                placeholder="Select App..."
              />
            </div>
          ) : null}

          <Field
            className="new-app-datasource-option"
            component={WrappedRadio}
            styled
            name="datasourceType"
            radioValue="xano"
            label="Use Xano Database and Authentication (New!)"
            disabled={!isExternalDatabaseEnabled}
          />
          {!isExternalDatabaseEnabled && (
            <a
              className="new-app-upgrade-plan"
              href="#"
              onClick={handleClickUpgrade}
            >
              {' '}
              Upgrade{' '}
            </a>
          )}
        </div>

        {datasourceType !== 'database' &&
        originalTemplateId &&
        !templateIdIsBlankType(originalTemplateId) ? (
          <Alert color="yellow">
            Your template selection will be reset to "blank"
          </Alert>
        ) : null}
      </form>

      <WizardFooter>
        <Button
          className="next align-right"
          type="submit"
          form="new-app-advanced-form"
        >
          Done
        </Button>
      </WizardFooter>
    </>
  )
}

const mapStateToProps = state => {
  const selector = formValueSelector(FORM_NAME)

  return {
    datasourceType: selector(state, 'datasourceType'),
    apps: getApps(state),
    templateId: selector(state, 'templateId'),
    isExternalDatabaseEnabled: isFeatureEnabled(state, 'externalDatabase'),
  }
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, { change, showUpgradeModal })(WizardFormAdvancedPage)
)
