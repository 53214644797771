import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchOrganization,
  getCycleAppActionsByApp,
  updateOrganizationBilling,
} from 'ducks/organizations'
import { getCurrentUser, userIsAdmin } from 'ducks/users/index.ts'

import AdaloTooltip from 'components/Shared/Tooltip'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import Stat from 'components/Shared/Stat/index.tsx'

import {
  getActionsSpendingLimit,
  getSpendingLimitOptions,
  getTotalActions,
  getPriceMultiplier,
} from 'utils/billing.ts'

import './AppActionsSpendingLimit.scss'

const AppActionsSpendingLimit = ({ organization }) => {
  const { billing } = organization || {}

  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [isEditing, setIsEditing] = useState(!billing?.hasCustomLimit)
  const [hasCustomLimit, setHasCustomLimit] = useState(billing?.hasCustomLimit)
  const [spendingLimit, setSpendingLimit] = useState(billing?.spendingLimit)

  const actionsByApp = useSelector(state => getCycleAppActionsByApp(state))

  if (!organization) {
    return null
  }

  const { planType, limits } = organization

  if (planType === 'free') {
    return null
  }

  const handleToggle = async () => {
    const toggledCustomLimit = !hasCustomLimit

    setHasCustomLimit(toggledCustomLimit)

    if (toggledCustomLimit && !billing?.hasCustomLimit) {
      // Start in editing mode when turning toggle on
      setIsEditing(true)
    }

    const toggledOff = !toggledCustomLimit

    // If turning toggle off, set spendingLimit to default value and update organization
    if (toggledOff) {
      setSpendingLimit(getPriceMultiplier(planType))

      // Set organization spending limit to unlimited
      dispatch(
        updateOrganizationBilling(organization.id, {
          hasCustomLimit: false,
          spendingLimit: null,
          overageChargePlanType: planType,
        })
      )

      return dispatch(fetchOrganization(organization.id))
    }
  }

  const currentUser = useSelector(getCurrentUser)
  const isAdmin = userIsAdmin(organization, currentUser)

  const handleSetLimit = async () => {
    setIsEditing(false)

    // Update organization spending limit to custom
    dispatch(
      updateOrganizationBilling(organization.id, {
        hasCustomLimit: true,
        spendingLimit,
        overageChargePlanType: planType,
      })
    )

    return dispatch(fetchOrganization(organization.id))
  }

  const handleEditLimit = () => {
    setIsEditing(!isEditing)
  }

  const handleCancel = () => {
    setSpendingLimit(billing?.spendingLimit)
    setHasCustomLimit(billing?.hasCustomLimit)

    // Turn off edit mode if the team has a custom limit so toggle is disabled
    if (hasCustomLimit && hasCustomLimit === billing?.hasCustomLimit) {
      setIsEditing(false)
    }
  }
  const actionsSpendingLimit = getActionsSpendingLimit(
    spendingLimit,
    billing?.overageChargePlanType
  )

  const totalSpendingLimit = limits.actions + actionsSpendingLimit
  const totalActions = getTotalActions(actionsByApp)

  const limitReached = totalActions >= totalSpendingLimit
  const actionsPercentage = Math.floor(
    (totalActions / totalSpendingLimit) * 100
  )
  const warningPercentageReached = actionsPercentage >= 75

  const getUsageState = () => {
    if (limitReached) {
      return 'error'
    } else if (warningPercentageReached) {
      return 'warning'
    }

    return null
  }

  const usageState = getUsageState()

  useEffect(() => {
    ;(async () => {
      const result = await getSpendingLimitOptions(planType)

      if (result.length) {
        setOptions(result)
      }
    })()
  }, [billing])

  useEffect(() => {
    // Sets default spending limit option
    const noSpendingLimitSet =
      spendingLimit === null || spendingLimit === undefined

    if (noSpendingLimitSet) {
      const priceMultiplier = getPriceMultiplier(planType)
      setSpendingLimit(priceMultiplier)
    }
  }, [hasCustomLimit])

  const renderSpendingLimitBody = () => {
    if (!hasCustomLimit) {
      return (
        <div className="billing-usage-spending-limit-content-unlimited">
          <p>
            Leaving your spending limit as Unlimited will ensure your apps never
            stop working if you go over your plan’s app action limit.
          </p>
        </div>
      )
    }

    return (
      <div className="billing-usage-spending-limit-content-custom">
        <div className="billing-usage-spending-limit-content-custom-content">
          {isEditing ? (
            <>
              <div className="billing-usage-spending-limit-content-custom-header">
                <span>Custom Limit</span>
                <p>
                  Set your budget for additional app actions after you reach
                  your plan’s app action limit.
                </p>
              </div>
              <WrappedSelect
                options={options}
                value={spendingLimit}
                onChange={val => setSpendingLimit(val)}
              />
              <p>
                Leaving it at $0 will avoid any extra monthly expenses, but will
                cause your apps to stop working if your plan’s app action limit
                is reached.
              </p>
              <div className="billing-usage-spending-limit-content-custom-content-editing">
                <Button small text onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  small
                  outlined
                  darkPink
                  onClick={handleSetLimit}
                  disabled={spendingLimit === billing?.spendingLimit}
                >
                  Set Limit to ${spendingLimit}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="billing-usage-spending-limit-content-custom-stats">
                <Stat
                  title="Custom Limit"
                  stat={`$${spendingLimit}`}
                  statExtra={`(+${actionsSpendingLimit.toLocaleString()} app actions)`}
                />
                <Stat
                  title="Total Monthly App Action Limit"
                  stat={`${totalSpendingLimit.toLocaleString()} App Actions`}
                />
              </div>

              <div
                className={classNames(
                  'billing-usage-spending-limit-content-custom-content-buttons',
                  {
                    'with-banner': !!usageState,
                  }
                )}
              >
                {!!usageState && (
                  <div
                    className={classNames('billing-usage-banner', {
                      warning: usageState === 'warning',
                      error: usageState === 'error',
                    })}
                  >
                    {usageState === 'warning' && (
                      <p>
                        <Icon medium type="warning" color="yellow" />
                        You have used {actionsPercentage}% of your monthly
                        spending limit.
                      </p>
                    )}
                    {usageState === 'error' && (
                      <p>
                        <Icon
                          medium
                          className="flipped"
                          color="orange"
                          type="info"
                        />
                        Your spending limit has been reached. Your apps are now
                        offline.
                      </p>
                    )}
                  </div>
                )}
                {isAdmin ? (
                  <span onClick={handleEditLimit}>Edit limit</span>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div id="spending-limit" className="billing-usage-spending-limit-container">
      <div className="billing-usage-spending-limit-header">
        <h3 className="billing-usage-spending-limit-header-text">
          App Actions Overages Spending Limit
          <span className="actions-over-time-tooltip">
            <AdaloTooltip
              tooltip={
                <p>
                  Control your spending limit for app action overages if you go
                  over your plan's monthly allotment.{' '}
                  <a
                    href="https://help.adalo.com/resources/adalo-subscription-add-ons#app-actions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              }
              placement="bottom-start"
              hideArrow
            >
              <Icon type="help" />
            </AdaloTooltip>
          </span>
        </h3>
        <div className="billing-usage-spending-limit-toggle">
          <ToggleButton
            wrapped
            label={hasCustomLimit ? 'Custom' : 'Unlimited'}
            value={hasCustomLimit}
            onChange={handleToggle}
            disabled={!isAdmin || !isEditing}
          />
        </div>
      </div>
      <div className="billing-usage-spending-limit-content">
        {renderSpendingLimitBody()}
      </div>
    </div>
  )
}

export default AppActionsSpendingLimit
