import React, { Component } from 'react'
import EntityTextarea from '@protonapp/react-entity-textarea'
import classNames from 'classnames'

import { convertToSimple, convertToBlocks } from '../../../utils/entities'

import '@protonapp/react-entity-textarea/styles.css'
import './EntityTextarea.css'

export default class WrappedEntityTextarea extends Component {
  handleChange = value => {
    const { onChange } = this.props

    onChange(convertToSimple(value))
  }

  getValue = () => {
    const { value } = this.props

    return convertToBlocks(value)
  }

  render() {
    const { childRef, className, sanitization } = this.props

    return (
      <EntityTextarea
        {...this.props}
        ref={childRef}
        className={classNames('wrapped-entity-textarea', className)}
        value={this.getValue()}
        onChange={this.handleChange}
        sanitization={sanitization}
      />
    )
  }
}
