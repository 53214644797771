import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dataTypes } from '@adalo/constants'

import { getObjectName } from 'utils/naming'

import { selectObject } from 'ducks/editor/objects'
import { getInputObjects } from 'ducks/recommender'

import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import ControlField from 'components/Shared/Forms/ControlField'

import SourceField from './SourceField'

class SetInput extends Component {
  getInputLabel = val => {
    const { getLabel } = this.props

    if (!val) {
      return null
    }

    return getLabel(val)
  }

  render() {
    const {
      field,
      appId,
      componentId,
      objectId,
      actionId,
      inputOptions,
      reference,
      menuTheme,
    } = this.props

    return (
      <div className="form-inspect-field-wrapper">
        <ControlField
          component={MenuControl}
          name={`${field}.options.inputId`}
          displayName="Input"
          options={inputOptions}
          getLabel={this.getInputLabel}
          menuTheme={menuTheme}
        />
        <SourceField
          name={`${field}.options.source`}
          dataTypes={[dataTypes.TEXT, dataTypes.NUMBER]}
          appId={appId}
          componentId={componentId}
          objectId={objectId}
          actionId={actionId}
          reference={reference}
          displayName="Value"
          boxed
          menuTheme={menuTheme}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, componentId }) => {
  return {
    inputOptions: getInputObjects(state, appId, componentId),
    getLabel: inputId =>
      getObjectName(selectObject(state, inputId), undefined, inputId),
  }
}

export default connect(mapStateToProps)(SetInput)
