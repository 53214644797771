import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { getId } from '@adalo/utils'
import { bindingTypes, comparators } from '@adalo/constants'

import Icon from 'components/Shared/Icon'
import ControlField from 'components/Shared/Forms/ControlField'
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'

import ConditionalForm from './Form'
import { THEMES } from '../../../../../../constants'

const ConditionalAction = ({
  field,
  formName,
  objectId,
  actionId,
  reference,
}) => {
  const conditionalName = `${field}.options.conditional`
  const selector = formValueSelector(formName)
  const conditional = useSelector(state => selector(state, conditionalName))

  const label = (text, icon) => (
    <div className="multi-menu-label">
      {icon ? <Icon type={icon} /> : null}
      {text}
    </div>
  )

  const initialConditional = {
    bindingType: bindingTypes.LIBRARY_PROP,
    comparator: comparators.EQUAL,
    id: getId(),
    options: {},
    source: undefined,
    type: 'binding',
  }

  const conditionalOptions = [
    { label: label('Always', 'action'), value: null },
    {
      label: label('Sometimes...', 'conditional-action'),
      value: initialConditional,
    },
  ]

  const comparator = (val1, val2) => {
    if (!val1 !== !val2) return false

    return true
  }

  return (
    <>
      <ControlField
        displayName="When does this happen?"
        component={MenuControl}
        name={conditionalName}
        options={conditionalOptions}
        comparator={comparator}
        menuTheme={THEMES.DATA}
      />
      {conditional ? (
        <ConditionalForm
          binding={conditional}
          objectId={objectId}
          actionId={actionId}
          reference={reference}
          field={conditionalName}
          menuTheme={THEMES.DATA}
        />
      ) : null}
    </>
  )
}

ConditionalAction.propTypes = {
  formName: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
}

export default ConditionalAction
