import React, { useState } from 'react'
import { reduxForm } from 'redux-form'

import Modal from 'components/Shared/Modal'
import Icon from 'components/Shared/Icon'

export const FORM_NAME = 'confirmRestoreForm'

const ConfirmRestoreForm = ({ onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    setLoading(true)
    await onSubmit()
    setLoading(false)
  }

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      className="confirm-restore-form"
    >
      <Modal.Content>
        <div className="restore-modal-content">
          <Icon type="versions-full" />
          <h2>Whoops, Version Limit Reached</h2>
          <p>
            If you restore this version, no automatic version will be created
            with your current design. Restore anyway?
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Button type="button" text onClick={onCancel}>
          No, go back
        </Modal.Button>
        <Modal.Button type="submit" teal loading={loading}>
          Yes, restore
        </Modal.Button>
      </Modal.Actions>
    </form>
  )
}

export default reduxForm({
  form: 'confirmRestoreForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ConfirmRestoreForm)
