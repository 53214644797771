import React from 'react'
import { connect } from 'react-redux'

// ducks
import {
  getAnnualToggle,
  getPaymentRouteType,
  getSubscription,
  setAnnualFlag,
  setPlanSelectedFlag,
  setSelectedPlanValue,
} from 'ducks/trialWarning'
import { getOrganization } from 'ducks/organizations'
import { loadAppsOverMetrics, getAppsOverMetrics } from 'ducks/metrics'
import NewPlanSelectModal from '../PlanSelectModal/NewPlanSelectModal'

const PlanSelectModal = props => {
  return <NewPlanSelectModal {...props} />
}

const mapStateToProps = (state, { organizationId }) => {
  const organization = getOrganization(state, organizationId)

  const userId = state?.users?.currentUser?.id

  return {
    annual: getAnnualToggle(state),
    subscription: getSubscription(state, organizationId),
    paymentRouteType: getPaymentRouteType(state),
    isOrganizationAdmin: organization?.OrganizationUser.isAdmin,
    organizationName: organization?.name,
    userId,
    appsOver: getAppsOverMetrics(state, userId),
  }
}

const connected = connect(mapStateToProps, {
  loadAppsOverMetrics,
  setAnnualFlag,
  setPlanSelectedFlag,
  setSelectedPlanValue,
})(PlanSelectModal)

export default connected
