import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import Icon from 'components/Shared/Icon'

import './SelectableInput.scss'

export default class SelectableInput extends Component {
  handleChange = newValue => {
    const { input } = this.props
    const { onChange, value } = input || this.props

    if (value === newValue) {
      return
    }

    onChange(newValue)
  }

  handleChangeInput = e => {
    return this.handleChange(e.currentTarget.value)
  }

  renderInput() {
    let { placeholder, value, onChange, input } = this.props
    input = input || { value, onChange }

    input = {
      ...input,
      value: input.value || '',
    }

    return (
      <input
        {...input}
        autoComplete="off"
        onChange={this.handleChangeInput}
        type="text"
        className="selectable-input-input"
        placeholder={placeholder || 'Empty'}
      />
    )
  }

  renderSelectedOption() {
    let { getOptionLabel, getLabel, input } = this.props
    const { value } = input || this.props

    getLabel = getLabel || getOptionLabel || (opt => opt && opt.label)

    const label = getLabel(value)

    return (
      <div className="selectable-input-selected-value">
        <div className="selectable-input-value-text" title={label}>
          {label}
        </div>
        <Icon type="close" onClick={() => this.handleChange('')} />
      </div>
    )
  }

  render() {
    const { options, expandTrigger, input, displayName, boxed, menuTheme } =
      this.props

    const { value, onBlur, ...selectProps } = input || this.props

    return (
      <div
        className={classNames('selectable-input-wrapper', {
          'selectable-input-wrapper-boxed': boxed,
          'selectable-input-wrapper-labeled': displayName,
        })}
      >
        {displayName ? <label>{displayName}</label> : null}
        <div className="selectable-input">
          <div className="selectable-input-value">
            {value && typeof value === 'object'
              ? this.renderSelectedOption()
              : this.renderInput()}
          </div>
          <MultiMenuTrigger
            {...selectProps}
            className="selectable-input-menu-trigger"
            menu={options}
            value={value}
            onSelect={this.handleChange}
            rowHeight={36}
            menuTheme={menuTheme}
          >
            {expandTrigger || <Icon type="expand-vertical" />}
          </MultiMenuTrigger>
        </div>
      </div>
    )
  }
}

SelectableInput.propTypes = {
  displayName: PropTypes.string,
  placeholder: PropTypes.string,
}

SelectableInput.defaultProps = {}
