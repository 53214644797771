import { Component } from 'react'
import { connect } from 'react-redux'

class DraggableIndicator extends Component {
  // @todo (michael-adalo): this is a hack that we may not need anymore, revisit
  handleAutoChange = () => {
    window.setTimeout(() => {
      const { appId, component, objects, onChangeComponent } = this.props

      if (!component && objects.length > 0) {
        onChangeComponent(appId, objects[0].id)
      }
    }, 50)
  }

  render() {
    const { component, objects } = this.props

    if (!component && objects.length > 0) {
      this.handleAutoChange()
    }

    return null
  }
}

export default connect(undefined)(DraggableIndicator)
