import { Field } from 'redux-form'
import { CSSTransition } from 'react-transition-group'
import { WebSettings } from 'ducks/apps/App'
import SelectList from 'components/Shared/Forms/SelectList'

import placeholderImage from '../assets/onboarding-app-platform-placeholder.png'
import mobileImage from '../assets/onboarding-app-platform-mobile.png'
import responsiveImage from '../assets/onboarding-app-platform-responsive.png'

type PlatformOption = {
  value: WebSettings['layoutMode']
  label: string
  description: string
}

const PLATFORM_OPTIONS: PlatformOption[] = [
  {
    value: 'mobile' as const,
    label: 'On Mobile Devices (Easiest Way to Learn Adalo)',
    description: `This is the easiest way to start. You can always design this app to look beautiful on every screen size later on.`,
  },
  {
    value: 'responsive' as const,
    label: 'On Mobile, Tablet, & Desktop Devices',
    description: `These apps are designed to look good on every screen size from mobile, to tablet, to desktop.`,
  },
]

type Props = {
  setAppLayoutMode: (value: WebSettings['layoutMode']) => void
  disabled?: boolean
}

const AppPlatform: React.FC<Props> = ({ setAppLayoutMode, disabled }) => {
  return (
    <>
      <h3>Where do you want your users to view this app?</h3>
      <div>
        <Field
          id="appPlatform"
          name="appPlatform"
          label="Every app can be published on all platforms."
          className="appPlatform"
          type="text"
          component={SelectList}
          disabled={disabled}
          options={PLATFORM_OPTIONS}
          onChangeValue={(value: WebSettings['layoutMode']) =>
            setAppLayoutMode(value)
          }
        />
      </div>
    </>
  )
}

type AppPlatformStepGraphicProps = {
  appLayoutMode?: WebSettings['layoutMode'] | undefined
}

const deviceGraphics: { [k: string]: string } = {
  mobile: mobileImage,
  responsive: responsiveImage,
  placeholder: placeholderImage,
}

export const DeviceGraphic = ({ device }: { device: string }): JSX.Element => (
  <img
    className="onboarding-device-graphic"
    src={deviceGraphics[device] ?? ''}
    alt={`${device}`}
  />
)

export const AppPlatformStepGraphic = (
  props: AppPlatformStepGraphicProps
): JSX.Element => {
  const { appLayoutMode } = props

  return (
    <div className="onboarding-graphics-devices">
      <CSSTransition
        in={appLayoutMode === 'mobile'}
        timeout={2000}
        classNames="onboarding-graphic-transition"
        unmountOnExit
      >
        <div className="onboarding-graphics-devices-layout">
          <DeviceGraphic device="mobile" />
        </div>
      </CSSTransition>

      <CSSTransition
        in={appLayoutMode === 'responsive'}
        timeout={2000}
        classNames="onboarding-graphic-transition"
        unmountOnExit
      >
        <div className="onboarding-graphics-devices-layout">
          <DeviceGraphic device="responsive" />
        </div>
      </CSSTransition>

      <CSSTransition
        in={!appLayoutMode}
        timeout={2000}
        classNames="onboarding-graphic-transition"
        unmountOnExit
      >
        <div className="onboarding-graphics-devices-layout">
          <DeviceGraphic device="placeholder" />
        </div>
      </CSSTransition>
    </div>
  )
}

export default AppPlatform
