import { COMPONENT } from '@adalo/constants'
import LayoutObject from './layout/LayoutObject'
import Document from './Document'
import LayoutContext from './layout/LayoutContext'
import ObjectNode from './ObjectNode'
import { StyleMap } from './styles'
import Length from './Length'

export default class ScreenNode extends ObjectNode<typeof COMPONENT> {
  private document: Document | undefined = undefined
  protected override readonly defaultStyles: Partial<StyleMap> = {
    left: Length.ZERO,
    right: Length.ZERO,
  }

  constructor(id: string) {
    super(id, COMPONENT)
  }

  public getDocument(): Document | undefined {
    return this.document
  }

  public setDocument(document: Document | undefined): void {
    this.document = document
  }

  public override layout(
    context: LayoutContext
  ): LayoutObject<typeof COMPONENT> {
    const { containerWidth } = context

    const contentAreaContext: LayoutContext = {
      ...context,
      // Screens never enforce a container height
      containerHeight: undefined,
      containerOffsetX: 0,
      containerOffsetY: 0,
    }

    const result = super.layout(contentAreaContext)

    return {
      ...result,
      x: 0,
      y: 0,
      width: containerWidth,
    }
  }
}
