import React from 'react'
import classNames from 'classnames'
import Modal from '../Shared/Modal'
import WizardForm from './WizardForm'

const NewAppModal = ({ className, ...otherProps }) => {
  otherProps.size = 'md'

  return (
    <Modal
      className={classNames('new-app-modal', className, {
        'has-responsive-option': true,
      })}
      {...otherProps}
    >
      <WizardForm />
    </Modal>
  )
}

export default NewAppModal
