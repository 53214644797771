import { setZoom } from 'ducks/editor/objects'
import { setSelection } from 'ducks/editor/selection'
import { Dispatch } from 'react'
import { EditorObject } from 'utils/responsiveTypes'
import { calculateZoom } from 'utils/zoom'

export const getScaleAndOffset = (
  object: EditorObject,
  paddingX = 200,
  paddingY = 200,
  offsetX = 200,
  offsetY = 200
): { scale: number; offset: number[] } => {
  let { x, y, width, height } = object

  width += paddingX * 2
  height += paddingY * 2

  const { scale, offset } = calculateZoom({
    x: x - offsetX,
    y: y - offsetY,
    width,
    height,
  })

  return { scale, offset }
}

const centerScreenOnCanvas =
  (
    object: EditorObject,
    paddingX?: number,
    paddingY?: number,
    offsetX?: number,
    offsetY?: number
  ) =>
  (dispatch: Dispatch<unknown>): void => {
    if (!object) {
      return dispatch(setSelection(null))
    }

    const { id } = object
    dispatch(setSelection(id))

    const { scale, offset } = getScaleAndOffset(
      object,
      paddingX,
      paddingY,
      offsetX,
      offsetY
    )

    return dispatch(setZoom(scale, offset))
  }

export default centerScreenOnCanvas
