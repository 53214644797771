import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getApp } from 'ducks/apps'
import { createCustomAction, deleteCustomAction } from 'ducks/customActions'
import { openAccordion } from 'ducks/accordions'

import Form from './Form'

class CustomActionModal extends Component {
  state = { step: 0, deleteWarning: false }

  componentDidMount() {
    const { opts } = this.props

    if (opts?.template) {
      this.setState({ step: 1 })
    }
  }

  handleClose = () => {
    const { onCancel } = this.props

    return onCancel()
  }

  handleBack = () => {
    const { step, deleteWarning } = this.state
    const { opts } = this.props

    if (deleteWarning) return this.setState({ deleteWarning: false })

    if (step === 0 || (step === 1 && opts?.template)) {
      return this.handleClose()
    } else {
      return this.setState({ step: step - 1 })
    }
  }

  handleNext = e => {
    if (e?.preventDefault) e.preventDefault()
    const { step } = this.state
    this.setState({ step: Math.min(step + 1, 2) })
  }

  handleSubmit = async values => {
    const { step, deleteWarning } = this.state
    const { match, onSuccess, createCustomAction } = this.props
    const { appId } = match.params

    if (deleteWarning) {
      return this.handleDelete()
    }

    if (step < 2) {
      return this.handleNext()
    }

    let customActionId

    try {
      customActionId = (await createCustomAction(appId, values))?.value?.id
    } catch (err) {
      console.error(err)
    }

    return onSuccess(customActionId)
  }

  handleDelete = async () => {
    const { match, deleteCustomAction, opts } = this.props
    const { appId } = match.params

    try {
      await deleteCustomAction(appId, opts.id)
    } catch (err) {
      console.error(err)
    }

    return this.handleClose()
  }

  handleWarning = e => {
    return this.setState({ deleteWarning: true })
  }

  render() {
    const { step, deleteWarning } = this.state
    const { onCancel, opts } = this.props

    return (
      <Form
        handleBack={this.handleBack}
        handleWarning={this.handleWarning}
        handleNext={this.handleNext}
        handleTest={this.handleTest}
        handleClose={this.handleClose}
        onSubmit={this.handleSubmit}
        step={step}
        deleteWarning={deleteWarning}
        onCancel={onCancel}
        initialValues={opts}
      />
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  app: getApp(state, match.params.appId),
})

export default withRouter(
  connect(mapStateToProps, {
    createCustomAction,
    deleteCustomAction,
    openAccordion,
  })(CustomActionModal)
)
