import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dataTypes } from '@adalo/constants'
import moment from 'moment'

import Icon from 'components/Shared/Icon'

import { getDataObject } from 'ducks/datasources'

import { uploadURL } from 'utils/uploads'
import { getPrimaryField } from 'utils/includes'
import { singularize, pluralize } from 'utils/strings'
import { formatDateOnly } from 'utils/datetime'

import Cell from './Cell'

class BodyCell extends Component {
  renderValue() {
    let { fieldId, field, value, belongsToObject, row, datasource } = this.props

    if (!field) {
      return value
    }

    if (field.type.type) {
      const targetTableId = field.type.tableId
      const targetTable = datasource.tables[targetTableId]
      const primaryField = getPrimaryField(targetTable)

      if (field.type.type === 'belongsTo') {
        if (primaryField && belongsToObject) {
          const target = belongsToObject[primaryField]

          if (target && !React.isValidElement(target) && target.url) {
            value = (
              <img
                src={uploadURL(target.url, 48)}
                alt="Reference"
                height={24}
              />
            )
          } else {
            value = target
          }

          field = primaryField

          if (targetTable.fields[primaryField].type === dataTypes.IMAGE) {
            value = <img src={uploadURL(value, 48)} alt="Upload" height={24} />
          } else {
            value = (value && value.filename) || value
          }
        } else {
          return null
        }
      } else if (
        field.type.type === 'hasMany' ||
        field.type.type.includes('manyToMany')
      ) {
        const count = row[`${fieldId}_count`] || 0
        const table = targetTable

        if (count === 0) return null

        return (
          <div className="data-browser-collection-item">
            {count === 1
              ? `1 ${singularize(table.name).toLowerCase()}`
              : `${count} ${pluralize(table.name).toLowerCase()}`}
          </div>
        )
      }
    }

    if (field.type === dataTypes.IMAGE && value) {
      return <img src={uploadURL(value, 48)} alt="Upload" height={24} />
    }

    if (field.type === dataTypes.BOOLEAN) {
      if (value) {
        return <Icon type="check" />
      }

      return null
    }

    if (field.type === dataTypes.FILE && value) {
      return value.filename
    }

    if (field.type === dataTypes.DATE && value) {
      return moment(value).format('LLL')
    }

    if (field.type === dataTypes.DATE_ONLY && value) {
      return formatDateOnly(value)
    }

    if (field.type === dataTypes.LOCATION && value) {
      return value.name || value.fullAddress
    }

    return value
  }

  render() {
    const { field, width } = this.props

    return (
      <Cell field={field} width={width}>
        {this.renderValue()}
      </Cell>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { value, field } = props
  let belongsToObject = {}

  if (field) {
    belongsToObject = getDataObject(state, field.type.tableId, value)
  }

  return {
    belongsToObject,
  }
}

export default connect(mapStateToProps)(BodyCell)
