import { dataTypes } from '@adalo/constants'

import Input from '../Input'

export default class DatePicker extends Input {
  render() {
    const { datePickerStyle } = this.props

    let defaultValue = 'Thursday, May 4th, 2024'

    if (!datePickerStyle || datePickerStyle === dataTypes.DATE) {
      defaultValue = `${defaultValue} at 4:00 PM`
    } else if (datePickerStyle === 'dateTextInput') {
      defaultValue = '05/04/2024'
    }

    return super.render({ defaultValue })
  }
}
