/* eslint-disable @typescript-eslint/no-unused-vars */
import ObjectType from '../types/ObjectType'
import ObjectNode from './ObjectNode'

export default abstract class ObjectTreeVisitor<R> {
  public visitObjectNode<T extends ObjectType>(node: ObjectNode<T>): R {
    return this.visitChildren(node)
  }

  // Indicate how results should be combined
  abstract getDefaultResult(): R
  abstract aggregateResults(a: R, b: R): R

  public visitChildren<T extends ObjectType>(containerNode: ObjectNode<T>): R {
    const children = containerNode.getChildren()

    let result: R = this.getDefaultResult()
    for (const child of children) {
      const currentResult = child.visit(this)

      result = this.aggregateResults(result, currentResult)
    }

    return result
  }
}
