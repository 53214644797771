import React, { useState } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'

import { createDesignVersion, updateDesignVersion } from 'utils/io'

import { getCurrentAppId } from 'ducks/editor/objects'
import { IconButton } from 'components/Shared/Icon'

import { isMissing } from './Validation'
import Form from './Form'

import './DesignVersion.scss'

export const FORM_NAME = 'newDesignVersion'

export const UPDATE_TYPE = 'update'

const DesignVersionModal = ({
  appId,
  onSuccess,
  onClose,
  onCancel,
  tables,
  datasourceId,
  version,
  loadVersionsList,
  type,
  restoreVersion,
  deleteVersion,
}) => {
  const [loading, setLoading] = useState(false)

  const updateVersion = async (versionName, versionNotes) => {
    return updateDesignVersion(
      appId,
      version.versionId,
      versionName,
      versionNotes
    )
  }

  const createVersion = async (versionName, versionNotes) => {
    return createDesignVersion(appId, versionName, versionNotes)
  }

  const handleSubmit = async ({ versionName, versionNotes }) => {
    if (isMissing(versionName)) {
      throw new SubmissionError({
        versionName: 'Required',
        _error: 'Required',
      })
    }

    setLoading(true)

    const saveOrUpdatePromise =
      type === UPDATE_TYPE ? updateVersion : createVersion

    try {
      await saveOrUpdatePromise(versionName, versionNotes)
      await loadVersionsList()
    } catch (err) {
      console.error(err)
      alert(err.message)
    }

    setLoading(false)

    return onSuccess({ versionName })
  }

  const onDelete = () => {
    try {
      deleteVersion()
    } catch (err) {
      onCancel(err)
    } finally {
      onClose()
    }
  }

  const initialValues = {
    versionName: version?.versionName,
    versionNotes: version?.notes,
  }

  const title =
    type === UPDATE_TYPE ? (
      <>
        <div className="design-version-form-title-left">
          <IconButton type="arrow-back" onClick={onClose} />
          <h1>{initialValues.versionName}</h1>
        </div>
        <div>
          <IconButton type="trash-small" onClick={onDelete} />
        </div>
      </>
    ) : (
      'New Design Version'
    )

  const buttonLabel = type === UPDATE_TYPE ? 'Update' : 'Create'

  return (
    <Form
      title={title}
      buttonLabel={buttonLabel}
      onClose={onClose}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
      tables={tables}
      datasourceId={datasourceId}
      initialValues={initialValues}
      type={type}
      version={version}
      restoreVersion={restoreVersion}
    />
  )
}

const mapStateToProps = state => {
  const appId = getCurrentAppId(state)

  return {
    appId,
  }
}

export default connect(mapStateToProps, {})(DesignVersionModal)
