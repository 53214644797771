import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class ModalContent extends Component {
  render() {
    const { children, childRef, fluid, classNames, ...props } = this.props

    return (
      <div
        {...props}
        ref={childRef}
        className={classnames('modal-content', { fluid }, classNames)}
      >
        {children}
      </div>
    )
  }
}

ModalContent.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ModalContent
