import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import AuthPageLogo from './Logo'

const FORM_NAME = 'forgotPasswordForm'

export const validate = data => {
  const errors = {}

  if (!data.email) {
    errors.email = 'Email is required'
  } else if (!data.email.match(/^.+@([\w\-]+\.)+\w{2,}$/)) {
    errors.email = 'Please enter a valid email address'
  }

  return errors
}

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, error, submitSucceeded } = this.props

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Forgot Password</h1>
        <p>Please enter your email and a password reset link will be sent</p>
        <Field
          autoFocus
          name="email"
          placeholder="me@example.com"
          type="email"
          label="Email Address"
          component={WrappedInput}
        />
        <div className="auth-form-submission-row">
          <Button large yellow disabled={submitSucceeded}>
            Send
          </Button>
        </div>
        {error && <div className="auth-form-error">{error}</div>}
        {submitSucceeded && (
          <div className="auth-form-error">
            A password recovery link has been sent to your email.
          </div>
        )}
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(ForgotPasswordForm)
