import { LABEL } from '@adalo/constants'
import { calculateResponsiveTextHeight } from 'utils/responsiveText'
import LayoutContext from './layout/LayoutContext'
import LayoutObject from './layout/LayoutObject'
import ObjectNode from './ObjectNode'
import { TextStyleMap } from './styles'

export default class LabelNode extends ObjectNode<typeof LABEL> {
  constructor(id: string) {
    super(id, LABEL)
  }

  private calcTextStyles({ device }: LayoutContext): TextStyleMap {
    return {
      fontSize: this.getStyle('fontSize', device),
      text: this.getStyle('text', device),
      fontStyle: this.getStyle('fontStyle', device),
      fontFamily: this.getStyle('fontFamily', device),
      fontWeight: this.getStyle('fontWeight', device),
      autoWidth: this.getStyle('autoWidth', device),
      multiline: this.getStyle('multiline', device),
      maxLength: this.getStyle('maxLength', device),
    }
  }

  public override layout(context: LayoutContext): LayoutObject<typeof LABEL> {
    const objectLayout = super.layout(context)

    const { width, left, right } = objectLayout

    const {
      text,
      fontSize,
      fontStyle,
      fontWeight,
      fontFamily: evaluatedFontFamily,
      multiline,
      autoWidth,
      maxLength = 0,
    } = this.calcTextStyles(context)

    const height = calculateResponsiveTextHeight({
      text,
      fontSize,
      evaluatedFontFamily,
      fontWeight,
      fontStyle,
      multiline,
      autoWidth,
      maxLength,
      width,
    })

    return {
      id: objectLayout.id,
      type: objectLayout.type,
      x: objectLayout.x,
      y: objectLayout.y,
      children: objectLayout.children,
      height,
      width,
      left,
      right,
    }
  }
}
