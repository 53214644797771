import ObjectType from '../types/ObjectType'
import LayoutContext from './layout/LayoutContext'
import LayoutObject from './layout/LayoutObject'
import ObjectNode from './ObjectNode'
import { Position } from './styles'

const layoutAbsoluteContent = (
  container: ObjectNode<ObjectType>,
  context: LayoutContext
): LayoutObject<ObjectType>[] => {
  const { device } = context

  // Out of flow children are children that are positioned absolutely and aren't hidden
  const absoluteChildren: ObjectNode<ObjectType>[] = container
    .getChildren()
    .filter(child => child.isVisible(context))
    .filter(child => child.getStyle('position', device) === Position.Absolute)

  return absoluteChildren.map(child => child.layout(context))
}

export default layoutAbsoluteContent
