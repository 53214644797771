import {
  sourceTypes,
  selectors,
  bindingTypes,
  dataTypes,
  numberFormats,
  formatters,
} from '@adalo/constants'
import { TableFields } from 'components/Editor/XanoExternalDatabaseModal/lib/user'
import { BindingSource } from 'utils/responsiveTypes'
import { buildBinding } from 'utils/bindings'
import type {
  TableField,
  TableFieldType,
  EditorObject,
} from 'utils/responsiveTypes'
import type { RelatedField } from './types'

type BindingInput = {
  fieldId: string
  label: string
  type: TableFieldType
  name?: string
  listObjectId: string
  source: BindingSource
}

const RELATIONSHIP_COUNT_TYPES = new Set([
  'hasMany',
  'manyToMany',
  'manyToManyReverse',
])

const RELATIONSHIP_FIELD_TYPES = new Set(['belongsTo'])

export const getBindingFormatterForFieldType = (
  fieldType: TableFieldType
): {
  type?: string
  columnWidth: string
  currency?: string
  textLength?: string
  imageRatio?: string
  locationFormat?: string
} => {
  switch (fieldType) {
    case dataTypes.NUMBER:
      return {
        type: numberFormats.COMMAS,
        currency: 'usd',
        columnWidth: 'standard',
      }
    case dataTypes.TEXT:
      return {
        textLength: 'singleLine',
        columnWidth: 'standard',
      }
    case dataTypes.IMAGE:
      return {
        columnWidth: 'standard',
        imageRatio: 'landscape',
      }
    case dataTypes.DATE:
      return {
        type: formatters.date.RELATIVE,
        columnWidth: 'standard',
      }
    case dataTypes.DATE_ONLY:
      return {
        type: formatters.dateOnly.RELATIVE,
        columnWidth: 'standard',
      }
    case dataTypes.LOCATION:
      return {
        locationFormat: 'fullAddress',
        columnWidth: 'standard',
      }
    default:
      return {
        columnWidth: 'standard',
      }
  }
}

const generateDataSource = (input: BindingInput): BindingSource => ({
  type: sourceTypes.DATA,
  dataType: dataTypes.OBJECT,
  datasourceId: input.source.datasourceId,
  tableId: input.source.tableId,
  selector: {
    type: selectors.LIST_ITEM,
    listObjectId: input.listObjectId,
  },
  source: null,
})

export const generateBindingSource = (
  input: BindingInput
): {
  type: string
  dataType: string
  fieldId: string
  source: BindingSource
} => {
  if (typeof input.type !== 'string') {
    throw new Error(`type is not a string`)
  }

  return {
    type: sourceTypes.FIELD,
    dataType: input.type,
    fieldId: input.fieldId,
    source: generateDataSource(input),
  }
}

const generateBindingSourceForRelatedCount = (input: BindingInput) => {
  if (typeof input.type !== 'object') {
    throw new Error(`type is not a relationship type`)
  }

  return {
    type: sourceTypes.COUNT,
    dataType: dataTypes.NUMBER,
    source: {
      type: `${input.type.type}Relation`,
      dataType: dataTypes.LIST,
      datasourceId: input.source?.datasourceId,
      tableId: input.type.tableId, // 👈 the Related table
      fieldId: input.fieldId, // 👈 the Relationship field ID shared between each side of the relationship
      source: generateDataSource(input),
    },
  }
}

const generateBindingSourceForRelatedField = (
  input: BindingInput,
  related: RelatedField
) => {
  if (typeof input.type !== 'object') {
    throw new Error(`type is not a relationship type`)
  }

  if (typeof related !== 'object') {
    throw new Error(`related is not an object`)
  }

  return {
    type: sourceTypes.FIELD,
    dataType: related.type as string, // 👈 the Relation field type
    fieldId: related.field, // 👈 the Relation field ID
    source: {
      type: `${input.type.type}Relation`,
      dataType: dataTypes.OBJECT,
      datasourceId: input.source?.datasourceId,
      tableId: input.type.tableId, // 👈 the Related table
      fieldId: input.fieldId, // 👈 the Relationship field ID shared between each side of the relationship
      source: generateDataSource(input),
    },
  }
}

export const makeTableField = (
  object: EditorObject,
  tableFields: TableFields,
  field: string,
  related?: RelatedField
): TableField => {
  if (!tableFields) {
    throw new Error(`tableFields is required`)
  }

  if (typeof object.dataBinding !== 'object') {
    throw new Error(`object dataBinding is required`)
  }

  // const tableId = object.dataBinding.source.tableId
  // if (!tableId) {
  //   throw new Error(`object dataBinding source has no tableId`)
  // }

  const tableField = tableFields[field]
  if (!tableField) {
    throw new Error(`table field not found for field id: ${field}`)
  }

  if (typeof tableField.type === 'object') {
    if (RELATIONSHIP_COUNT_TYPES.has(tableField.type.type)) {
      if (tableField.type.type === 'manyToManyReverse') {
        tableField.type.type = 'manyToMany'
      }

      return {
        fieldId: field,
        label: tableField.name,
        type: tableField.type,
        binding: buildBinding(
          bindingTypes.LIBRARY_PROP,
          generateBindingSourceForRelatedCount({
            fieldId: field,
            label: tableField.name,
            type: tableField.type,
            listObjectId: object.id,
            source: object.dataBinding.source,
          }),
          // hasMany and manyToMany table relationships are always returned as a count
          // and support all number formatters
          getBindingFormatterForFieldType(dataTypes.NUMBER)
        ),
      }
    }

    if (
      typeof related === 'object' &&
      RELATIONSHIP_FIELD_TYPES.has(tableField.type.type)
    ) {
      return {
        fieldId: field,
        label: `${tableField.name} ${related.name}`,
        type: tableField.type,
        binding: buildBinding(
          bindingTypes.LIBRARY_PROP,
          generateBindingSourceForRelatedField(
            {
              fieldId: field,
              label: tableField.name,
              type: tableField.type,
              listObjectId: object.id,
              source: object.dataBinding.source,
            },
            related
          ),
          // belongsTo table relationships are always returned as a field
          // so we need to use the related field type to determine the formatter
          getBindingFormatterForFieldType(related.type)
        ),
      }
    }

    throw new Error(
      `unsupported relationship type: ${
        tableField.type.type
      }, related: ${JSON.stringify(related)}`
    )
  }

  return {
    fieldId: field,
    label: tableField.name,
    type: tableField.type,
    binding: buildBinding(
      bindingTypes.LIBRARY_PROP,
      generateBindingSource({
        fieldId: field,
        label: tableField.name,
        type: tableField.type,
        listObjectId: object.id,
        source: object.dataBinding.source,
      }),
      getBindingFormatterForFieldType(tableField.type)
    ),
  }
}
