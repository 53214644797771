/* eslint-disable no-underscore-dangle */

import type { SuggestedSchemaTable } from 'ducks/dbAssistant'

export const updateCollectionName = (
  tables: SuggestedSchemaTable[],
  _id: string,
  name: string
): SuggestedSchemaTable[] => {
  const targetTable = tables.find(t => t._id === _id && t.isNew === true)

  if (!targetTable) {
    // Don't log warning on tests
    if (process.env.NODE_ENV !== 'test') {
      console.warn(`Failed to find target table to update ${name}`)
    }

    return tables
  }

  const originalName = targetTable.name

  const updatedTables = tables.map(table => {
    const updatedTable = { ...table }
    const isTargetTable = table._id === _id && table.isNew === true

    if (isTargetTable) {
      updatedTable.name = name
    }

    updatedTable.fields = table.fields.map(field => {
      if (field.type !== 'relationship') {
        return field
      }

      const isRelationshipWithTarget =
        field.relationship?._relationship_id === _id

      if (isRelationshipWithTarget && field.relationship) {
        const updatedRelationship = { ...field.relationship }
        updatedRelationship.collection = name

        if (updatedRelationship.description) {
          updatedRelationship.description =
            updatedRelationship.description.replace(originalName, name)
        }

        return {
          ...field,
          name: field.name.replace(originalName, name),
          relationship: updatedRelationship,
        }
      } else if (isTargetTable) {
        if (field.relationship?.description) {
          const updatedDescription = field.relationship.description.replace(
            originalName,
            name
          )

          return {
            ...field,
            relationship: {
              ...field.relationship,
              description: updatedDescription,
            },
          }
        }
      }

      return field
    })

    return updatedTable
  })

  return updatedTables
}

export const updatePropertyName = (
  tables: SuggestedSchemaTable[],
  id: string,
  name: string
): SuggestedSchemaTable[] => {
  const field = tables
    .map(t => t.fields)
    .flat()
    .find(f => f._id === id)

  // cannot update an existing collection field name
  if (field && field.isNew === false) {
    return tables
  }

  return tables.map(t => ({
    ...t,
    fields: t.fields.map(f => {
      if (f._id === id) {
        return {
          ...f,
          name,
        }
      }

      return f
    }),
  }))
}

export const removeCollection = (
  tables: SuggestedSchemaTable[],
  id: string
): SuggestedSchemaTable[] => {
  const table = tables.find(t => t._id === id)

  // cannot remove an existing collection
  if (table && table.isNew === false) {
    return tables
  }

  return tables
    .filter(t => t._id !== id)
    .map(t => ({
      ...t,
      fields: t.fields.filter(f => f.relationship?._relationship_id !== id),
    }))
}

export const removeCollectionProperty = (
  tables: SuggestedSchemaTable[],
  id: string,
  relationshipPairId: string
): SuggestedSchemaTable[] => {
  const field = tables
    .map(t => t.fields)
    .flat()
    .find(f => f._id === id)

  // cannot remove an existing collection field
  if (field && field.isNew === false) {
    return tables
  }

  // cannot remove a field that's locked, like the `Name` field
  if (field && field.locked === true) {
    return tables
  }

  return tables
    .map(t => ({
      ...t,
      fields: t.fields.filter(f => f._id !== id),
    }))
    .map(t => ({
      ...t,
      fields: t.fields.filter(
        f => f._relationship_pair_id !== relationshipPairId
      ),
    }))
}
