import { DeviceWidth, resizingOptions } from '@adalo/constants'
import { Responsivity } from './responsiveTypes'

const { SCALES_WITH_PARENT } = resizingOptions

export const MINIMUM_MIN_WIDTH = 1
export const MAXIMUM_MAX_WIDTH = DeviceWidth.DESKTOP_MAX_WIDTH

type DefaultLayout =
  | {
      minWidth: number
      minWidthEnabled: boolean
      maxWidth: number
      maxWidthEnabled: boolean
    }
  | Record<string, never>

export const getDefaultLayoutForResponsivity = (
  responsivity: Responsivity
): DefaultLayout => {
  if (responsivity.horizontalScaling === SCALES_WITH_PARENT) {
    return {
      minWidth: 5,
      minWidthEnabled: true,
      maxWidth: DeviceWidth.DESKTOP_MAX_WIDTH,
      maxWidthEnabled: false,
    }
  }

  return {}
}
