import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import QS from 'qs'

import { createDomain } from 'ducks/domains'

import Button from 'components/Shared/Button'
import SettingsModalField from 'components/Shared/Forms/SettingsModalField'
import { adaloBackendAxios } from 'utils/io/http/axios'

const isDomainFormat = val => {
  const pattern = /^([a-z\d]([a-z\d\-]*[a-z\d])?\.)+([a-z\d]{2,}|xn--\w+)$/

  if (!val) {
    return
  }

  if (!val.match(pattern)) {
    return 'Invalid domain name'
  }

  // *-adalo.com, *_adalo.com, *.adalo.com or adalo.com, com.adalo.* are not allowed
  if (
    val.match(/[a-z\d]+[\._-]adalo[\._-][a-z\d]+/) ||
    val.match(/^adalo.com$/)
  ) {
    return 'You can only add domains that you own. However, you can edit your subdomain above.'
  }
}

const asyncValidate = async ({ domain }) => {
  try {
    const query = QS.stringify({ domain })
    const url = `/domain-availability?${query}`

    await adaloBackendAxios.get(url)
  } catch (err) {
    throw { domain: `${domain} is not available.` }
  }
}

class AddDomainForm$ extends Component {
  render() {
    const { handleSubmit, dirty } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Field
          inline
          small
          component={SettingsModalField}
          name="domain"
          validate={[isDomainFormat]}
          placeholder="Add custom domain... Ex: app.mydomain.com"
          autoComplete="off"
        >
          <Button disabled={!dirty}>Add</Button>
        </Field>
      </form>
    )
  }
}

const AddDomainForm = reduxForm({
  form: 'customDomain',
  asyncValidate,
  enableReinitialize: true,
})(AddDomainForm$)

class AddCustomDomain extends Component {
  state = {
    domain: null,
    confirmed: false,
    error: null,
    loading: false,
    statusCheckCount: 0,
  }

  handleSubmitForm = ({ domain }) => {
    this.setState({ domain })
  }

  checkDomainStatus = async () => {
    const { domain } = this.state

    this.setState({ loading: true, error: null })

    try {
      const url = `/domain-status?domain=${domain}`
      await adaloBackendAxios.get(url)

      this.setState({ confirmed: true, loading: false })
    } catch (err) {
      this.setState({
        loading: false,
        error:
          'Your custom domain could not be verified. Please check your settings and try again.',
      })
    }
  }

  componentWillUnmount() {
    if (this.domainTimeout) {
      clearTimeout(this.domainTimeout)
    }
  }

  handleAdd = async () => {
    const { domain } = this.state
    const { createDomain, organizationId } = this.props
    this.setState({ loading: true, error: null })

    try {
      await createDomain(organizationId, domain)

      this.setState({
        domain: null,
        confirmed: false,
        loading: false,
      })
    } catch (err) {
      console.log('ERROR ADDING DOMAIN:', err)

      this.setState({
        error: 'Error adding domain. Please wait a few minutes and try again.',
        loading: false,
      })
    }
  }

  renderForm = () => <AddDomainForm onSubmit={this.handleSubmitForm} />

  renderSetup = () => {
    const { domain, error, loading, statusCheckCount } = this.state
    let subdomain = domain

    if (typeof domain === 'string') {
      const domainArr = domain.split('.')

      if (domainArr.length === 3) {
        subdomain = domainArr[0]
      }
    }

    return (
      <div className="settings-content-box">
        <div className="settings-content-row">
          <h4>Add the following record to your DNS provider.</h4>
          <h4>
            Once you’ve added this record, press “Test Setup” below to continue.
          </h4>
        </div>
        <div className="settings-content-row">
          <p className="settings-content-label">Name/@/Host</p>
          <h4>{subdomain}</h4>
        </div>
        <div className="settings-content-row">
          <p className="settings-content-label">Type</p>
          <h4>CNAME</h4>
        </div>
        <div className="settings-content-row">
          <p className="settings-content-label">Points To / Domain / Value</p>
          <h4>hosting.adalo.com</h4>
        </div>
        <div className="setting-modal-button-row centered">
          <Button text onClick={this.resetForm} loading={loading}>
            Cancel
          </Button>
          <Button onClick={this.checkDomainStatus} loading={loading}>
            Test Setup
          </Button>
        </div>
        {error && (
          <div>
            <p className="domain-settings-error">{error}</p>
          </div>
        )}
        {statusCheckCount > 0 && (
          <div>
            {`We are waiting for your DNS settings to update, which \
                can take up to 30 minutes. Please do not close this page.`}
          </div>
        )}
      </div>
    )
  }

  renderConfirm = () => {
    const { domain, error, loading } = this.state

    return (
      <div className="settings-content-box domain-settings-confirm">
        <h5>Success!</h5>
        <p>Your DNS Records have been successfully added for {domain}</p>
        <div className="setting-modal-button-row centered">
          <Button text onClick={this.resetForm} loading={loading}>
            Cancel
          </Button>
          <Button onClick={this.handleAdd} loading={loading}>
            Finish
          </Button>
        </div>
        {error && (
          <div className="settings-content-row">
            <p className="domain-settings-error">{error}</p>
          </div>
        )}
      </div>
    )
  }

  resetForm = () => {
    this.setState({
      domain: null,
      confirmed: false,
      loading: false,
    })
  }

  render() {
    const { domain, confirmed } = this.state

    if (!domain) {
      return this.renderForm()
    }

    if (!confirmed) {
      return this.renderSetup()
    }

    return this.renderConfirm()
  }
}

export default connect(null, { createDomain })(AddCustomDomain)
