import { Action } from 'redux'
import { AiFeatureLimits } from '../organizations/Organization'

export enum DBAssistantStatus {
  idle = 'idle',
  in_progress = 'in_progress',
  done = 'done',
  failed = 'failed',
}

enum ActionTypes {
  UPDATE_MAGIC_START = 'UPDATE_MAGIC_START',
  RESET_MAGIC_START = 'RESET_MAGIC_START',
  UPDATE_MAGIC_START_STATUS = 'UPDATE_MAGIC_START_STATUS',
  UPDATE_MAGIC_ADD = 'UPDATE_MAGIC_ADD',
  RESET_MAGIC_ADD = 'RESET_MAGIC_ADD',
}

export type SuggestedSchemaField = {
  name: string
  type: string
  relationship?: {
    type: 'hasMany' | 'belongsTo' | 'manyToMany' | 'manyToManyReverse'
    collection: string
    description?: string
    _relationship_id: string
  }
  locked?: boolean
  isPrimaryField?: boolean
  isNew?: boolean
  isHidden?: boolean
  _id: string
  _relationship_pair_id?: string
}

export type SuggestedSchemaTable = {
  name: string
  description?: string
  fields: SuggestedSchemaField[]
  isNew?: boolean
  hasNewFields?: boolean
  _id: string
}

export type MagicFeatureType = {
  conversationId?: string
  status?: DBAssistantStatus
  schema?: {
    tables: SuggestedSchemaTable[]
  }
  errorMessage?: string
  limits?: AiFeatureLimits['dbAssistant']
}

export type MagicStartState = MagicFeatureType

export type MagicAddFeatureType = {
  id: string
  description: string
  promptTips: string[]
  placeholder: string
}

export type MagicAddState = {
  features: MagicAddFeatureType[]
} & MagicFeatureType

interface UpdateMagicStartAction extends Action {
  type: ActionTypes.UPDATE_MAGIC_START
  meta: { appId: string; appMagicStart: MagicStartState }
}

interface ResetMagicStartction extends Action {
  type: ActionTypes.RESET_MAGIC_START
  meta: { appId: string }
}

interface UpdateMagicStartStatusAction extends Action {
  type: ActionTypes.UPDATE_MAGIC_START_STATUS
  meta: { appId: string; status: DBAssistantStatus }
}

interface UpdateMagicAddAction extends Action {
  type: ActionTypes.UPDATE_MAGIC_ADD
  meta: { appId: string; appMagicAdd: MagicAddState }
}

interface ResetMagicAddAction extends Action {
  type: ActionTypes.RESET_MAGIC_ADD
  meta: { appId: string }
}

type DBAssistantAction =
  | UpdateMagicStartAction
  | ResetMagicStartction
  | UpdateMagicStartStatusAction
  | UpdateMagicAddAction
  | ResetMagicAddAction

interface State {
  magicStart: { [appId: string]: MagicStartState }
  magicAdd: { [appId: string]: MagicAddState }
}

export interface DBAssistantState {
  dbAssistant: State
}

export const INITIAL_STATE: State = {
  magicStart: {},
  magicAdd: {},
}

// Reducers
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: DBAssistantAction): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_MAGIC_START:
      return {
        ...state,
        magicStart: {
          ...state.magicStart,
          [action.meta.appId]: {
            ...state.magicStart[action.meta.appId],
            ...action.meta.appMagicStart,
          },
        },
      }

    case ActionTypes.RESET_MAGIC_START:
      return {
        ...state,
        magicStart: {
          ...state.magicStart,
          [action.meta.appId]: {},
        },
      }
    case ActionTypes.UPDATE_MAGIC_START_STATUS:
      return {
        ...state,
        magicStart: {
          ...state.magicStart,
          [action.meta.appId]: {
            ...state.magicStart[action.meta.appId],
            status: action.meta.status,
          },
        },
      }

    case ActionTypes.UPDATE_MAGIC_ADD:
      return {
        ...state,
        magicAdd: {
          ...state.magicAdd,
          [action.meta.appId]: {
            ...state.magicAdd?.[action.meta.appId],
            ...action.meta.appMagicAdd,
          },
        },
      }
    case ActionTypes.RESET_MAGIC_ADD:
      return {
        ...state,
        magicAdd: {
          ...state.magicAdd,
          [action.meta.appId]: {
            features: state.magicAdd?.[action.meta.appId]?.features ?? [],
          },
        },
      }

    default:
      return state
  }
}

// Actions
export const updateAppMagicStart = (
  appId: string,
  appMagicStart: MagicStartState
): UpdateMagicStartAction => ({
  type: ActionTypes.UPDATE_MAGIC_START,
  meta: { appId, appMagicStart },
})

export const resetAppMagicStart = (appId: string): ResetMagicStartction => ({
  type: ActionTypes.RESET_MAGIC_START,
  meta: { appId },
})

export const updateAppMagicStartStatus = (
  appId: string,
  status: DBAssistantStatus
): UpdateMagicStartStatusAction => ({
  type: ActionTypes.UPDATE_MAGIC_START_STATUS,
  meta: { appId, status },
})

export const updateAppMagicAdd = (
  appId: string,
  appMagicAdd: MagicAddState
): UpdateMagicAddAction => ({
  type: ActionTypes.UPDATE_MAGIC_ADD,
  meta: { appId, appMagicAdd },
})

export const resetAppMagicAdd = (appId: string): ResetMagicAddAction => ({
  type: ActionTypes.RESET_MAGIC_ADD,
  meta: { appId },
})

// Selectors
export const getAppMagicStart = (
  state: { dbAssistant: State },
  appId: string
): MagicStartState | undefined => {
  return state?.dbAssistant.magicStart?.[appId]
}

export const getAppMagicAdd = (
  state: { dbAssistant: State },
  appId: string
): MagicAddState | undefined => {
  return state?.dbAssistant.magicAdd?.[appId]
}
