import React from 'react'
import { connect } from 'react-redux'
import { FieldArray } from 'redux-form'
import { sourceTypes } from '@adalo/constants'

import { getTableInfo, getLabel } from 'utils/sources'

import { getTable } from 'ducks/apps/datasources'
import { getFilterOptions } from 'ducks/recommender'

import FilterItem from './Item'

const Filter = props => {
  const { binding, name, source } = props

  if (!source) return null
  const options = source?.options || binding?.options || {}

  let filterPath =
    binding?.source?.type === sourceTypes.COUNT
      ? 'source.source.options.filter'
      : 'source.options.filter'

  if (name) filterPath = name

  return (
    <FieldArray
      {...props}
      name={filterPath}
      component={FilterItem}
      source={source}
      options={options}
    />
  )
}

const mapStateToProps = (state, { appId, binding, object }) => {
  const bindingId = binding?.id

  let table = null
  let tableId
  let datasourceId
  let source = binding?.source
  source = source?.type === sourceTypes?.COUNT ? source?.source : source

  if (source) {
    const value = getTableInfo(source)
    tableId = value?.tableId
    datasourceId = value?.datasourceId
    table = getTable(state, appId, datasourceId, tableId)
  }

  return {
    table,
    bindingId,
    source,
    getLabel: value => getLabel(state, value, appId, datasourceId, true),
    fieldOptions: table
      ? getFilterOptions(
          state,
          appId,
          datasourceId,
          tableId,
          object?.id,
          bindingId || object?.id
        )
      : null,
  }
}

export default connect(mapStateToProps)(Filter)
