import { getDeviceType } from '@adalo/utils'

import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { EditorObject } from 'utils/responsiveTypes'
import InstructionState from '../types/InstructionState'
import { hasDeviceLayoutEnabled } from '../device-layouts/utils'
import getObject from '../objects/helpers/getObject'

import { refreshLeftRightForObjects } from './refreshLeftRight'

export interface UpdateElementMarginsOptions {
  objectId: string
}

export interface UpdateElementMarginsInstruction {
  operation: 'updateElementMargins'
  options: UpdateElementMarginsOptions
}

export const updateElementMarginsHandler = (
  state: InstructionState,
  options: UpdateElementMarginsOptions
): InstructionState => {
  const { list, pathMap } = state
  const { objectId } = options

  const screenObj = getParentScreen(list, pathMap, objectId)
  if (!screenObj) {
    throw new Error(`Could not find screen for object ${objectId}`)
  }

  const object: EditorObject = getObject(list, pathMap, objectId)
  const screenDevice = getDeviceType(screenObj.width)
  const deviceToUpdate = hasDeviceLayoutEnabled(object, screenDevice)
    ? [screenDevice]
    : [undefined]

  return {
    ...state,
    list: refreshLeftRightForObjects(
      list,
      pathMap,
      [object.id],
      deviceToUpdate
    ),
  }
}

const updateElementMargins = (
  objectId: string
): UpdateElementMarginsInstruction => ({
  operation: 'updateElementMargins',
  options: {
    objectId,
  },
})

export default updateElementMargins
