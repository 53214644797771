import immutabilityHelper from 'immutability-helper'
import { getId, getTableId, getColumnId } from '@adalo/utils'
import { fields as protonFields } from '@adalo/constants'

import { saveDatasource } from 'utils/io'
import { getDefaultEndpoints, sluggify } from 'utils/apis'
import { createReciprocals, createReciprocal } from 'utils/relations'

const UPDATE_DATASOURCE = Symbol('UPDATE_DATASOURCE')
const CREATE_TABLE = Symbol('CREATE_TABLE')
const UPDATE_TABLE = Symbol('UPDATE_TABLE')
const REMOVE_TABLE = Symbol('REMOVE_TABLE')
const ADD_TABLE_FIELD = Symbol('ADD_TABLE_FIELD')
const REMOVE_TABLE_FIELD = Symbol('REMOVE_TABLE_FIELD')
const UPDATE_TABLE_FIELD = Symbol('UPDATE_TABLE_FIELD')
const UPDATE_FIELD_ORDER = Symbol('UPDATE_FIELD_ORDER')

const CREATE_COLLECTION = Symbol('CREATE_COLLECTION')
const UPDATE_COLLECTION = Symbol('UPDATE_COLLECTION')

// TODO: DatasourceTable and TableFields types should be moved out of .../Editor/XanoExternalDatabaseModal/...
/**
 *
 * @param {import('components/Editor/XanoExternalDatabaseModal/lib/user').DatasourceTable} table
 * @returns {Array<String>}
 */

export const getOrderedFields = table => {
  const fields = table.fields
  const fieldIds = table.orderedFields || Object.keys(fields)

  const unorderedFields = Object.keys(fields).filter(id => {
    return fields[id] && !fieldIds.includes(id)
  })

  let result = fieldIds
    .filter(id => fields[id] && fields[id].isPrimaryField)
    .concat(fieldIds.filter(id => fields[id] && !fields[id].isPrimaryField))
    .concat(unorderedFields)

  const hiddenFields = [
    protonFields.TEMPORARY_PASSWORD,
    protonFields.TEMPORARY_PASSWORD_EXPIRES_AT,
  ]

  result = result.filter(field => field && !hiddenFields.includes(field))

  return result
}

const setTableField = (
  state,
  appId,
  datasourceId,
  tableId,
  fieldId,
  field,
  isNew = false,
  skipSave = false
) => {
  const app = state.apps[appId]
  const datasource = app && app.datasources[datasourceId]
  let table = datasource && datasource.tables[tableId]

  if (!table) {
    return state
  }

  const fields = table.fields || {}

  const primaryField = Object.keys(fields).filter(
    fieldId => fields[fieldId].isPrimaryField
  )[0]

  if (!primaryField) {
    field = { ...field, isPrimaryField: true }
  } else if (primaryField && field && field.isPrimaryField) {
    table = {
      ...table,
      fields: {
        ...table.fields,
        [primaryField]: {
          ...table.fields[primaryField],
          isPrimaryField: null,
        },
      },
    }
  }

  let operation = {
    [fieldId]: {
      $set: field,
    },
  }

  if (!field) {
    operation = {
      $unset: [fieldId],
    }
  }

  let orderedFields = getOrderedFields(table)

  if (field && isNew) {
    orderedFields = orderedFields.concat(fieldId)
  }

  table = immutabilityHelper(table, {
    orderedFields: { $set: orderedFields },
    fields: operation,
  })

  orderedFields = getOrderedFields(table)
  table.orderedFields = orderedFields

  let newState = immutabilityHelper(state, {
    apps: {
      [appId]: {
        datasources: {
          [datasourceId]: {
            tables: {
              [tableId]: {
                $set: table,
              },
            },
          },
        },
      },
    },
  })

  if (isNew && field && field.type.type) {
    const datasource = newState.apps[appId].datasources[datasourceId]

    const newDatasource = createReciprocal(
      datasource,
      datasourceId,
      field,
      fieldId,
      tableId
    )

    if (JSON.stringify(newDatasource) !== JSON.stringify(datasource)) {
      newState = immutabilityHelper(newState, {
        apps: {
          [appId]: {
            datasources: {
              [datasourceId]: {
                $set: newDatasource,
              },
            },
          },
        },
      })
    }
  }

  if (!skipSave) {
    saveDatasource(
      appId,
      datasourceId,
      newState.apps[appId].datasources[datasourceId]
    )
  }

  return newState
}

export default (state, action) => {
  if (action.type === UPDATE_DATASOURCE) {
    const { appId, datasourceId, data } = action

    const app = state.apps[appId]
    let datasource = app && app.datasources[datasourceId]

    datasource = { ...datasource, ...data }

    const newState = immutabilityHelper(state, {
      apps: {
        [appId]: {
          datasources: {
            [datasourceId]: {
              $set: datasource,
            },
          },
        },
      },
    })

    saveDatasource(appId, datasourceId, datasource)

    return newState
  }

  if (action.type === CREATE_TABLE) {
    const {
      id,
      appId,
      datasourceId,
      name,
      fields,
      orderedFields,
      type,
      tableType,
      ...opts
    } = action

    let fieldsObject

    if (fields && !Array.isArray(fields)) {
      fieldsObject = fields
    }

    let newState = immutabilityHelper(state, {
      apps: {
        [appId]: {
          datasources: {
            [datasourceId]: {
              tables: {
                [id]: {
                  $set: {
                    ...opts,
                    name,
                    type: tableType,
                    fields: fieldsObject || {},
                    orderedFields: orderedFields || [],
                  },
                },
              },
            },
          },
        },
      },
    })

    const datasource = newState.apps[appId].datasources[datasourceId]
    const newDatasource = createReciprocals(datasource, datasourceId)

    if (JSON.stringify(newDatasource) !== JSON.stringify(datasource)) {
      newState = immutabilityHelper(newState, {
        apps: {
          [appId]: {
            datasources: {
              [datasourceId]: {
                $set: newDatasource,
              },
            },
          },
        },
      })
    }

    //TODO: write a test for this
    if (Array.isArray(fields) && fields.length > 0) {
      fields.forEach(field => {
        newState = setTableField(
          newState,
          appId,
          datasourceId,
          id,
          field.fieldId || getColumnId(),
          field,
          true,
          true
        )
      })
    }

    saveDatasource(
      appId,
      datasourceId,
      newState.apps[appId].datasources[datasourceId]
    )

    return newState
  }

  if (action.type === UPDATE_TABLE) {
    const { appId, datasourceId, tableId, data } = action

    const app = state.apps[appId]
    const datasource = app && app.datasources[datasourceId]
    const table = datasource && datasource.tables[tableId]

    if (!table) {
      return state
    }

    const newState = immutabilityHelper(state, {
      apps: {
        [appId]: {
          datasources: {
            [datasourceId]: {
              tables: {
                [tableId]: {
                  $set: data,
                },
              },
            },
          },
        },
      },
    })

    saveDatasource(
      appId,
      datasourceId,
      newState.apps[appId].datasources[datasourceId]
    )

    return newState
  }

  if (action.type === REMOVE_TABLE) {
    const { appId, datasourceId, tableId } = action

    const removeBoundTableField = (object, tableId) => {
      object = { ...object }

      Object.keys(object).forEach(table => {
        const target = object[table]

        if (target && target.fields) {
          Object.keys(target.fields).forEach(field => {
            const current = target.fields[field]

            if (current && current.type) {
              const { type } = current

              if (type.tableId === tableId) {
                object = immutabilityHelper(object, {
                  [table]: {
                    fields: {
                      $unset: [field],
                    },
                  },
                })
              }
            }
          })
        }
      })

      return object
    }

    const newState = immutabilityHelper(state, {
      apps: {
        [appId]: {
          datasources: {
            [datasourceId]: {
              tables: {
                $unset: [tableId],
                $apply: table => removeBoundTableField(table, tableId),
              },
            },
          },
        },
      },
    })

    const source = newState.apps[appId]
    saveDatasource(appId, datasourceId, source.datasources[datasourceId])

    return newState
  }

  if (action.type === ADD_TABLE_FIELD) {
    const { appId, datasourceId, tableId, field, fieldId } = action

    return setTableField(
      state,
      appId,
      datasourceId,
      tableId,
      fieldId,
      field,
      true
    )
  }

  if (action.type === UPDATE_TABLE_FIELD) {
    let { appId, datasourceId, tableId, fieldId, field } = action

    const app = state.apps[appId]
    const datasource = app && app.datasources[datasourceId]
    const table = datasource && datasource.tables[tableId]
    const currentField = table && table.fields[fieldId]

    if (!currentField) {
      return state
    }

    field = { ...currentField, ...field }

    return setTableField(state, appId, datasourceId, tableId, fieldId, field)
  }

  if (action.type === REMOVE_TABLE_FIELD) {
    const { appId, datasourceId, tableId, fieldId } = action

    return setTableField(state, appId, datasourceId, tableId, fieldId, null)
  }

  if (action.type === UPDATE_FIELD_ORDER) {
    let { appId, datasourceId, tableId, orderedFields } = action

    const app = state.apps[appId]
    const datasource = app && app.datasources[datasourceId]
    const table = datasource && datasource.tables[tableId]

    if (!table) {
      return state
    }

    orderedFields = getOrderedFields({ ...table, orderedFields })

    const newState = immutabilityHelper(state, {
      apps: {
        [appId]: {
          datasources: {
            [datasourceId]: {
              tables: {
                [tableId]: {
                  orderedFields: {
                    $set: orderedFields,
                  },
                },
              },
            },
          },
        },
      },
    })

    saveDatasource(
      appId,
      datasourceId,
      newState.apps[appId].datasources[datasourceId]
    )

    return newState
  }

  // APIs
  if (action.type === CREATE_COLLECTION) {
    const { id, appId, datasourceId, name } = action

    const newState = immutabilityHelper(state, {
      apps: {
        [appId]: {
          datasources: {
            [datasourceId]: {
              collections: {
                [id]: {
                  $set: {
                    name,
                    baseURL: sluggify(name),
                    fields: [],
                    endpoints: getDefaultEndpoints(),
                  },
                },
              },
            },
          },
        },
      },
    })

    saveDatasource(
      appId,
      datasourceId,
      newState.apps[appId].datasources[datasourceId]
    )

    return newState
  }

  if (action.type === UPDATE_COLLECTION) {
    const { appId, datasourceId, collectionId, data } = action

    const app = state.apps[appId]
    const datasource = app && app.datasources[datasourceId]
    const collection = datasource && datasource.collections[collectionId]

    if (!collection) {
      return state
    }

    const newState = immutabilityHelper(state, {
      apps: {
        [appId]: {
          datasources: {
            [datasourceId]: {
              collections: {
                [collectionId]: {
                  $set: data,
                },
              },
            },
          },
        },
      },
    })

    saveDatasource(
      appId,
      datasourceId,
      newState.apps[appId].datasources[datasourceId]
    )

    return newState
  }
}

// ACTIONS

export const updateDatasource = (appId, datasourceId, data) => ({
  type: UPDATE_DATASOURCE,
  appId,
  datasourceId,
  data,
})

export const createTable = (
  appId,
  datasourceId,
  name,
  id,
  fields,
  orderedFields
) => {
  if (typeof name === 'object') {
    const opts = name

    return {
      ...opts,
      appId,
      datasourceId,
      type: CREATE_TABLE,
    }
  }

  return {
    type: CREATE_TABLE,
    appId,
    datasourceId,
    name,
    id: id || getTableId(),
    fields,
    orderedFields,
  }
}

export const updateTable = (appId, datasourceId, tableId, data) => ({
  type: UPDATE_TABLE,
  appId,
  datasourceId,
  tableId,
  data,
})

export const removeTable = (appId, datasourceId, tableId) => ({
  type: REMOVE_TABLE,
  appId,
  datasourceId,
  tableId,
})

export const addTableField = (appId, datasourceId, tableId, field, fieldId) => {
  if (!fieldId) fieldId = getColumnId()

  return {
    type: ADD_TABLE_FIELD,
    appId,
    datasourceId,
    tableId,
    field,
    fieldId,
  }
}

export const updateTableField = opts => ({
  ...opts,
  type: UPDATE_TABLE_FIELD,
})

export const removeTableField = (appId, datasourceId, tableId, fieldId) => ({
  type: REMOVE_TABLE_FIELD,
  appId,
  datasourceId,
  tableId,
  fieldId,
})

export const updateFieldOrder = (
  appId,
  datasourceId,
  tableId,
  orderedFields
) => ({
  type: UPDATE_FIELD_ORDER,
  appId,
  datasourceId,
  tableId,
  orderedFields,
})

export const createCollection = (appId, datasourceId, name) => ({
  type: CREATE_COLLECTION,
  appId,
  datasourceId,
  name,
  id: getId(),
})

export const updateCollection = (appId, datasourceId, collectionId, data) => ({
  type: UPDATE_COLLECTION,
  appId,
  datasourceId,
  collectionId,
  data,
})

// SELECTORS

export const getDatasources = (state, appId) => {
  const datasources = getDatasourcesObject(state, appId)

  return Object.keys(datasources).map(id => ({
    ...datasources[id],
    id,
  }))
}

export const getDatasourcesObject = (state, appId) => {
  return (state.apps.apps[appId] && state.apps.apps[appId].datasources) || {}
}

export const getDatasourcesMap = getDatasourcesObject

export const getDatasource = (state, appId, datasourceId) => {
  const datasources =
    (state.apps.apps[appId] && state.apps.apps[appId].datasources) || {}

  return datasources[datasourceId]
}

/**
 *
 * @param {*} state
 * @param {*} appId
 * @returns {import('components/Editor/XanoExternalDatabaseModal/lib/user').Datasource}
 */
export const getDefaultDatasource = (state, appId) => {
  return getDatasources(state, appId)[0]
}

export const getDatasourceRelationships = (state, appId, datasourceId) => {
  const relationships =
    (state.apps.apps[appId] && state.apps.apps[appId].relationships) || {}

  return relationships[datasourceId]
}

export const getTables = datasources => {
  if (!datasources) {
    return []
  }

  return Object.keys(datasources).map(id => ({
    ...datasources[id].tables,
  }))[0]
}

export const getTableIds = (state, appId, datasourceId = null) => {
  let datasource

  if (!datasourceId) {
    datasource = getDatasources(state, appId)[0]
    datasourceId = datasource && datasource.id
  }

  datasource = getDatasource(state, appId, datasourceId)

  if (!datasource) {
    return []
  }

  return Object.keys(datasource.tables).map(id => ({
    ...datasource.tables[id],
    id,
  }))
}

export const getTable = (state, appId, datasourceId, tableId) => {
  const datasource = getDatasource(state, appId, datasourceId)

  if (!datasource) {
    return datasource
  }

  return datasource.tables && datasource.tables[tableId]
}

export const getCollection = (state, appId, datasourceId, collectionId) => {
  const datasource = getDatasource(state, appId, datasourceId)

  if (!datasource) {
    return undefined
  }

  return datasource.collections && datasource.collections[collectionId]
}
