import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'components/Shared/Icon'

const MobilePublishStep = ({ step, validation }) => {
  return (
    <div
      className={classNames('mobile-publish-step', {
        'mobile-publish-step-valid': validation?.(),
      })}
    >
      <p>{step}</p>
      <Icon type="check-circle" />
    </div>
  )
}

MobilePublishStep.propTypes = {
  step: PropTypes.string.isRequired,
  validation: PropTypes.func.isRequired,
}

export default MobilePublishStep
