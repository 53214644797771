import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { CurrentUser } from 'ducks/users'

import { FormValues } from '.'

const FORM_NAME = 'app-questions-form'

const appUsageOptions = [
  { label: "Mostly me, I'm just testing", value: 'test' },
  { label: 'Internal users (coworkers or employees)', value: 'internal' },
  { label: 'Existing customers or community', value: 'external' },
  { label: 'Customers of my startup', value: 'startup' },
  { label: 'Both internal and external users', value: 'two-sided' },
]

const withHelpOptions = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
  {
    value: 'freelancer',
    label: "Yes, I'm the freelancer",
  },
]

interface Props {
  appUsage: string
  setAppUsage: (value: string) => void
  setWithHelp: (value: string) => void
  currentUser: CurrentUser
}

const AppQuestionsForm: React.FC<
  Props & InjectedFormProps<FormValues, Props>
> = props => {
  const { handleSubmit, appUsage, setAppUsage, setWithHelp, currentUser } =
    props

  const showWithHelp =
    appUsage &&
    appUsage !== 'test' &&
    currentUser?.persona !== 'Freelancer/Agency'

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          label="Who are the users of this app?"
          className="app-usage"
          placeholder="Select one..."
          type="text"
          name="appUsage"
          component={WrappedSelect}
          options={appUsageOptions}
          onChangeValue={(value: string) => setAppUsage(value)}
        />
      </div>
      {showWithHelp ? (
        <div>
          <Field
            label="Is this app being built with the help of a freelancer or agency?"
            className="with-help"
            placeholder="Select one..."
            type="text"
            name="withHelp"
            component={WrappedSelect}
            options={withHelpOptions}
            onChangeValue={(value: string) => setWithHelp(value)}
          />
        </div>
      ) : null}
    </form>
  )
}

const WrappedAppQuestionsForm = reduxForm<FormValues, Props>({
  form: FORM_NAME,
})(AppQuestionsForm)

export default WrappedAppQuestionsForm
