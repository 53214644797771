import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { assetURL } from '../../../utils/assets'
import './AppIcon.css'

export default class AppIcon extends Component {
  static defaultProps = {
    app: {},
  }

  static propTypes = {
    app: PropTypes.shape({
      icon: PropTypes.string,
    }),
  }

  render() {
    let { className, small, medium, darkBackground, app } = this.props
    const { icon } = app

    className = classNames('app-icon', className, {
      'app-icon-small': small,
      'app-icon-medium': medium,
      'app-icon-empty': !icon,
      'app-icon-dark-background': darkBackground,
    })

    return (
      <div
        className={className}
        style={{
          backgroundImage: icon ? `url('${assetURL(icon)}')` : null,
        }}
      />
    )
  }
}
