import BetaLabel from 'components/AppDetail/BetaLabel'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'

type Props = {
  onClickBack: () => void
  title: string
}

export default function Header({ onClickBack, title }: Props): JSX.Element {
  return (
    <>
      <div className="right-panel-back" onClick={onClickBack} aria-hidden>
        <Icon type="keyboard-back" />
        Database Collections
      </div>
      <h2 className="left-panel-title">
        <Icon type="data" />
        <span className="title">{title}</span>
        <Tooltip tooltip="This feature is still in Beta and might return inaccurate results.">
          <BetaLabel />
        </Tooltip>
      </h2>
    </>
  )
}
