import {
  COMPONENT,
  IMAGE,
  LABEL,
  LAYOUT_SECTION,
  LIBRARY_COMPONENT,
} from '@adalo/constants'

import {
  deepMap,
  getBoundingBox,
  getInsertPath,
  getObject,
  pathLength,
  subPath,
  traverse,
} from '@adalo/utils'

import {
  getPasteParent,
  getAbsoluteBbox,
  toRelativeCoords,
  translate,
  getInsertPosition,
} from 'utils/geometry'

import { uploadImage } from 'utils/io'
import { removeInvalidBindings } from 'utils/bindings'
import { unScaleRect } from 'utils/zoom'
import { resetLibraryBindingIds, addComponentUsed } from 'utils/libraries'
import {
  changeActionIds,
  changeFormulaIds,
  changeFilterIds,
} from 'utils/actions'
import { changeChildIds } from 'utils/copying'
import { sortSelection, rewriteSelection } from 'utils/selection'

import { performCreate, performDelete } from './objects'

const CUT = Symbol('CUT')
const COPY = Symbol('COPY')
export const PASTE = Symbol('PASTE')

const MIMETYPE = 'application/x-proton-data'

export default (state, action) => {
  if (action.type === COPY || action.type === CUT) {
    const { evt } = action
    let { selection, map, list } = state

    selection = sortSelection(rewriteSelection(list, map, selection), list, map)

    // Prevent default behavior
    evt.preventDefault()

    // If there are components being copied don't copy anything else
    const components = selection.filter(id => pathLength(map[id]) === 1)

    if (components.length > 0) {
      selection = components
    }

    if (selection.length === 0) {
      return
    }

    let data = selection.map(id => getObject(list, map[id]))

    const parentIds = []

    selection.forEach(id => {
      const path = state.map[id]
      const parentPath = subPath(path, 1)
      const parentId = getObject(state.list, parentPath).id

      if (!parentIds.includes(parentId)) {
        parentIds.push(parentId)
      }
    })

    const parents = parentIds.map(id => getObject(state.list, state.map[id]))
    const bbox = getBoundingBox(parents)

    data = deepMap(
      data,
      object => ({
        ...object,
        ...translate(getAbsoluteBbox(object, list, map), -bbox.x, -bbox.y),
      }),
      obj => obj.type !== COMPONENT
    )

    evt.preventDefault()
    evt.clipboardData.setData(MIMETYPE, JSON.stringify(data))

    if (action.type === CUT) {
      return performDelete(state, selection)
    }
  }

  if (action.type === PASTE) {
    const { evt, app } = action
    let { list, map, zoom } = state

    let objects = null

    if (action.objects) {
      objects = action.objects
    } else {
      const data = evt.clipboardData.getData(MIMETYPE)

      try {
        objects = JSON.parse(data)
      } catch (err) {
        console.error('Attempted to paste invalid object')
      }
    }

    if (!objects || objects.length === 0) {
      return state
    }

    // Remove invalid datasources
    const datasourceIds = Object.keys(app.datasources)
    objects = objects.map(obj => removeInvalidBindings(obj, datasourceIds))
    objects = changeActionIds(objects)
    objects = changeFilterIds(objects)

    const bbox = getBoundingBox(objects)

    const screenBbox = unScaleRect(
      {
        x: 220, // Width of left sidebar
        y: 60, // Height of top bar
        width: window.innerWidth - 220,
        height: window.innerHeight - 60,
      },
      zoom
    )

    let isComponent = objects.some(obj => obj.type === COMPONENT)

    const parentId =
      state.activeComponent || getPasteParent(bbox, screenBbox, list, map)

    const shouldChangeId = true

    objects = changeFormulaIds(objects, parentId, shouldChangeId)

    if (!parentId && !isComponent) {
      return state
    }

    let newObjects = objects

    // Relative coords centers the objects in the target screen + window
    // Disabling because this is not producing the desired result.
    if (parentId) {
      newObjects = toRelativeCoords(objects, parentId, screenBbox, list, map)
    }

    // Reset IDs
    newObjects = changeChildIds({ children: newObjects }).children

    // Reset binding IDs on libraryComponents
    newObjects = deepMap(newObjects, obj => resetLibraryBindingIds(obj))

    let insertPath = getInsertPath(list, null, map[parentId])

    const isSection = objects.some(obj => obj.type === LAYOUT_SECTION)

    // Allow pasting into the currently selected component,
    // unless pasting a Section component, in which case, it should be pasted at screen-level
    if (state.selection.length > 0 && !isSection) {
      const path = map[state.selection[0]]

      if (pathLength(path) > 1) {
        insertPath = getInsertPath(list, null, path, false)
      }
    }

    const insertPosition = getInsertPosition(list)
    const offsetX = insertPosition.x - bbox.x
    const offsetY = insertPosition.y - bbox.y

    newObjects.forEach(obj => {
      if (obj.type === COMPONENT) {
        isComponent = true
        obj.x += offsetX
        obj.y += offsetY
      }
    })

    const mobileOnly = app?.webSettings?.layoutMode === 'mobile'

    const newState = performCreate(
      state,
      insertPath,
      newObjects,
      null,
      isComponent ? null : parentId,
      !isComponent,
      true,
      true,
      false,
      true,
      mobileOnly
    )[0]

    list = newState.list
    map = newState.map
    const typeIndex = newState.typeIndex
    const selection = newState.selection
    const activeComponent = newState.activeComponent

    return {
      ...state,
      list,
      map,
      selection,
      typeIndex,
      activeComponent,
      textEditing: false,
      shapeEditing: false,
      hoverSelection: [],
    }
  }
}

export const copy = evt => ({
  type: COPY,
  evt,
})

export const cut = evt => ({
  type: CUT,
  evt,
})

export const paste = (evt, app, updateApp) => (dispatch, getState) => {
  const data = evt.clipboardData.getData(MIMETYPE)

  if (data) {
    let objects

    try {
      objects = JSON.parse(data)
    } catch (err) {
      console.error('Attempted to paste invalid object')
    }

    if (objects && objects.length > 0) {
      traverse(objects, obj => {
        if (obj.type === LIBRARY_COMPONENT) {
          addComponentUsed(obj.libraryName, obj.componentName, app, updateApp)
        }
      })
    }

    return dispatch({ type: PASTE, evt, app, globalState: getState() })
  }

  const items = Array.from(evt.clipboardData.items)

  // Check for image data
  const imageItem = items.filter(itm =>
    itm.type.match(/^image\/(png|jpg|jpeg|gif)/)
  )[0]

  if (imageItem) {
    const file = imageItem.getAsFile()
    const filename = file.name
    const reader = new FileReader()

    reader.onload = async () => {
      const dataURL = reader.result
      const file = dataURL.split(',')[1]
      const url = await uploadImage(app, file, filename)

      const img = new Image()

      img.onload = () => {
        const { width, height } = img

        const object = {
          x: -50000,
          y: -50000,
          width,
          height,
          type: IMAGE,
          name: file.name,
          filename1x: url,
        }

        dispatch({
          type: PASTE,
          app,
          objects: [object],
          globalState: getState(),
        })
      }

      img.src = dataURL
    }

    reader.readAsDataURL(file)
  } else {
    // Check for text data
    const text = evt.clipboardData.getData('text/plain')

    if (text) {
      const object = {
        type: LABEL,
        text,
      }

      dispatch({
        type: PASTE,
        app,
        objects: [object],
        globalState: getState(),
      })
    }
  }
}
