import React from 'react'
import './PrerequisiteAlert.scss'

const PrerequisiteAlert = ({ isPrerequisiteMet, name, footer, context }) => {
  const modifier = isPrerequisiteMet ? 'valid' : 'invalid'
  const icon = isPrerequisiteMet ? 'check_circle' : 'warning'
  const baseClass = 'prerequisite-alert'

  return (
    <div className={`${baseClass} ${baseClass}--${context}`}>
      <div
        className={`${baseClass}__indicator ${baseClass}__indicator--${modifier}`}
      >
        {name}
        <span className={`material-icons ${baseClass}__icon`}>{icon}</span>
      </div>
      {footer}
    </div>
  )
}

export default PrerequisiteAlert
