import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './index.scss'
import { GroupedAccordion } from 'components/Shared/Accordion'
import { IconButton } from 'components/Shared/Icon'
import { getActiveState, getTrialState } from 'ducks/organizations'

import { BEFORE, START_TRIAL, UPGRADE } from '../../../../../constants'

const DisabledActionItem = ({
  title,
  subtitle,
  icon,
  onRemove,
  handleTrial = () => {},
}) => {
  const { trialState } = useSelector(getTrialState)
  const isPaidOrg = useSelector(getActiveState)
  const [hoverContent, setHoverContent] = useState(null)

  const onMouseEnter = () =>
    setHoverContent(trialState === BEFORE && !isPaidOrg ? START_TRIAL : UPGRADE)

  const onMouseLeave = () => setHoverContent(null)

  return (
    <div
      className="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <GroupedAccordion
        boxed
        disabled
        className="action-item-form-item"
        icon={icon}
        title={<Title title={title} subtitle={subtitle} />}
      />
      <div className="absolute flex align-center top-2 right-4">
        <span
          className="color-yellow bg-gray semibold pl-1 uppercase f-10 pointer"
          onClick={() => handleTrial(trialState, isPaidOrg, { noCreate: true })}
        >
          {hoverContent}
        </span>
        <IconButton type="trash-small" onClick={onRemove} />
      </div>
    </div>
  )
}

const Title = ({ title, subtitle }) => (
  <div className="action-item-form-item-header">
    <div className="action-item-type">
      <div className="action-item-type-title">{title}</div>
      <div className="action-item-type-subtitle">{subtitle}</div>
    </div>
  </div>
)

export default DisabledActionItem
