import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, HandlerOptions } from './types'

export interface AlignToSelectionRightInstruction {
  operation: 'alignToSelectionRight'
  options: ActionOptions
}

export const alignToSelectionRightHandler = ({
  bounds,
  objects,
}: HandlerOptions): LayoutInstruction[] =>
  objects.map(object =>
    moveElement(object.id, bounds.right - object.width, object.y)
  )

const alignToSelectionRight = (
  objectIds: string[]
): AlignToSelectionRightInstruction => ({
  operation: 'alignToSelectionRight',
  options: { objectIds },
})

export default alignToSelectionRight
