// TODO(enzo): the disable for the @typescript-eslint/no-explicit-any should be taken out as soon as we add some more typescript here
// We never want to disable no-explicit-any, this is just temporary, do not follow this example

/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO(enzo): this rule is here to avoid build warnings
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  COMPONENT,
  dataTypes,
  DATE_PICKER,
  FILE_UPLOAD,
  IMAGE_UPLOAD,
  INPUT,
  LIBRARY_COMPONENT,
  LIST,
  LOCATION_INPUT,
  SELECT,
} from '@adalo/constants'
import {
  getDeviceType,
  getObject,
  getParentPath,
  pathLength,
  subPath,
  uniqueElements,
} from '@adalo/utils'
import { App } from 'ducks/apps'
import { PositionObject } from 'utils/canvasTypes'
import getDeviceObject from 'utils/getDeviceObject'
import { getAppComponent, hasRole } from 'utils/libraries'
import { isSupportedOnWeb } from 'utils/platform'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { EditorObject } from 'utils/responsiveTypes'
import { ObjectList } from '../types/ObjectList'
import { ObjectPathMap } from '../types/ObjectPathMap'

// TODO(enzo): We need to add more specific types here. We're using 'any' for now while we refactor the objects ducks state
type EditorObjectState = {
  appId?: string | null
  name: string
  list: ObjectList
  map: ObjectPathMap
  selection: string[]
  panning: boolean
  loading: boolean
  selectedParent: string | null
  zoom: { scale: number; offset: [number, number] }
  typeIndex: Record<string, any>
}

export type State = {
  editor: { objects: { present: EditorObjectState } }
  apps: { apps: Record<string, App> }
}

export const getPath = (state: State, objectId: string) =>
  state.editor.objects.present.map[objectId]

export const selectObjects = (
  state: State,
  ids: string[] | null = null
): ObjectList => {
  /* eslint-disable @typescript-eslint/no-unsafe-return*/
  const list = state.editor.objects.present.list

  if (!ids) {
    return list
  }

  const uniqueIds = uniqueElements(ids)

  return uniqueIds
    .map((id: string) => getObject(list, getPath(state, id)))
    .filter(o => o)
  /* eslint-enable @typescript-eslint/no-unsafe-return*/
}

// Used by marquee selection
export const selectVisible = (state: State): ObjectList =>
  state.editor.objects.present.list.filter((o: EditorObject) => !o['hidden'])

export const selectObject = (
  state: State,
  id: string | any
): EditorObject | null => {
  /* eslint-disable @typescript-eslint/no-unsafe-return*/

  const idComponents = id && typeof id === 'string' ? id.split('.') : undefined

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const objectId =
    idComponents && idComponents.length > 0 ? (idComponents[0] as string) : id
  const path = getPath(state, objectId as string)
  const objects = selectObjects(state)

  return getObject(objects, path)
  /* eslint-enable @typescript-eslint/no-unsafe-return*/
}

export const getObjectList = (state: State): ObjectList => {
  return state.editor.objects.present.list
}

export const getMap = (state: State): ObjectPathMap => {
  return state.editor.objects.present.map
}

export const getSelectedSub = (
  objects: EditorObjectState,
  selection: string[]
) => {
  /* eslint-disable @typescript-eslint/no-unsafe-return*/
  const { map, list } = objects

  const result = selection.map(id => getObject(list, map[id]))

  const idMap: Record<string, boolean> = {}

  return result.filter((obj: EditorObject) => {
    if (!obj) {
      return false
    }

    const { id } = obj

    if (idMap[id]) {
      return false
    }

    idMap[id] = true

    return true
  })
}

export const getSelectedObjects = (state: State) => {
  const { objects } = state.editor
  const selection = objects.present.selection

  return getSelectedSub(objects.present, selection)
}

export const getSelectedIds = (state: State) => {
  return state.editor.objects.present.selection
}

export const getZoom = ({ editor }: State) => editor.objects.present.zoom

export const getCurrentAppId = (state: State) => {
  const { appId } = state?.editor?.objects?.present || {}

  return appId
}

/**
 * Indicates if the current app is responsive.
 */
export const getMagicLayout = (state: State): boolean => {
  const appId = getCurrentAppId(state)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return state?.apps?.apps[appId!]?.magicLayout || false
}

export const getObjectsOfType = (
  state: State,
  componentId: string,
  type: string
): any[] => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access*/
  const { typeIndex } = state.editor.objects.present
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let index: any[] = (typeIndex[componentId] || {})[type]

  if (!componentId) {
    index = []

    for (const component of state.editor.objects.present.list) {
      index = index.concat((typeIndex[component.id] || {})[type] || [])
    }
  }

  return index || []
}

type DataType = typeof dataTypes[keyof typeof dataTypes]

export const getLibraryInputs = (
  state: State,
  componentId: string,
  allowedDataTypes: DataType[]
) => {
  /* eslint-disable @typescript-eslint/no-unsafe-assignment*/

  const libraryObjects = getObjectsOfType(state, componentId, LIBRARY_COMPONENT)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const app = state.apps.apps[getCurrentAppId(state)!]

  // Results will have the form: { objectId, fieldId: [childComponent, prop] }
  const results = []

  const defaultDataType = dataTypes.TEXT

  for (const id of libraryObjects) {
    const obj = selectObject(state, id)

    if (!obj) continue

    const { libraryName, componentName } = obj
    const config = getAppComponent(app, libraryName, componentName)

    if (!config) continue

    // Loop through props
    for (const prop of config.props) {
      const dataType: DataType = prop.type ?? defaultDataType
      if (
        hasRole(prop, 'formValue') &&
        (!allowedDataTypes || allowedDataTypes.includes(dataType))
      ) {
        results.push({
          objectId: id,
          prop: [prop.name],
          dataType,
        })
      }
    }

    // Loop through child components
    for (const child of config.childComponents || []) {
      for (const prop of child.props) {
        const dataType: DataType = prop.type ?? defaultDataType
        if (
          hasRole(prop, 'formValue') &&
          (!allowedDataTypes || allowedDataTypes.includes(dataType))
        ) {
          results.push({
            objectId: id,
            prop: [child.name, prop.name],
            dataType,
          })
        }
      }
    }
  }

  return results
}

export const getInputs = (
  state: State,
  componentId: string,
  objectId: string,
  objectType: string = INPUT
) => {
  /* eslint-disable @typescript-eslint/no-unsafe-argument*/
  const inputs = getLibraryInputs(state, componentId, [
    dataTypes.TEXT,
    dataTypes.NUMBER,
  ])
  inputs.push(...getObjectsOfType(state, componentId, objectType))

  const list = state.editor.objects.present?.list

  const inputIds = inputs.map((id: any) => {
    const path = getPath(state, id)

    const listParentIds = []

    for (let i = pathLength(path) - 1; i > 0; i -= 1) {
      const obj = getObject(list, subPath(path, pathLength(path) - i))

      if (!obj) continue
      if (obj.type === LIST) listParentIds.push(obj.id)
    }

    if (listParentIds.length === 0) return id

    return listParentIds.concat([id])
  })

  const objectListParents: string[] = []
  const path = getPath(state, objectId)

  for (let i = pathLength(path) - 1; i > 0; i -= 1) {
    const obj = getObject(
      list,
      subPath(path, pathLength(path) - i)
    ) as EditorObject | null

    if (!obj) continue
    if (obj.type === LIST) objectListParents.push(obj.id)
  }

  return inputIds.filter(id => {
    if (!Array.isArray(id)) return id
    const inputPath = id.slice(0, id.length - 1).join('.')
    const objectPath = objectListParents.join('.')

    return objectPath.startsWith(inputPath)
  })
}

export const getLocationInputs = (state: State, componentId: string) => [
  ...getLibraryInputs(state, componentId, [dataTypes.LOCATION]),
  ...getObjectsOfType(state, componentId, LOCATION_INPUT),
]

export const getImagePickers = (state: State, componentId: string) => {
  const inputs = getLibraryInputs(state, componentId, [dataTypes.IMAGE])

  inputs.push(...getObjectsOfType(state, componentId, IMAGE_UPLOAD))

  return inputs
}

export const getFilePickers = (state: State, componentId: string) => {
  const inputs = getLibraryInputs(state, componentId, [dataTypes.FILE])
  inputs.push(...getObjectsOfType(state, componentId, FILE_UPLOAD))

  return inputs
}

export const getDatePickers = (state: State, componentId: string) => {
  const inputs = getLibraryInputs(state, componentId, [
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
  ])

  inputs.push(...getObjectsOfType(state, componentId, DATE_PICKER))

  return inputs
}

export const getSelects = (
  state: State,
  componentId: string,
  objectId: string
) => {
  return getInputs(state, componentId, objectId, SELECT)
}

export const getLists = (state: State, componentId: string) => {
  return getObjectsOfType(state, componentId, LIST)
}

export const getCheckboxes = (state: State, componentId: string) => {
  return getLibraryInputs(state, componentId, [dataTypes.BOOLEAN])
}

export const getLoading = (state: State) => state.editor.objects.present.loading

export const getName = (state: State) => state.editor.objects.present.name

export const getComponent = (
  state: State,
  objectId: string
): EditorObject | null => {
  const path = getPath(state, objectId)

  if (!path) {
    return null
  }

  const componentPath = subPath(path, 1)
  const objects = selectObjects(state)
  const component = getObject(objects, componentPath)

  return component || null
}

export const getComponentId = (state: State, objectId: string) => {
  const component = getComponent(state, objectId)

  return (component && component.id) || null
}

export const getPlatform = (state: State): App['primaryPlatform'] => {
  const appId = getCurrentAppId(state)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const app = state?.apps?.apps?.[appId!]

  return app?.primaryPlatform ?? 'mobile'
}

export const getYOffset = (state: State) => {
  const platform = getPlatform(state)

  if (isSupportedOnWeb(platform)) {
    return 20
  }

  return 0
}

export const getObjectPosition = (
  state: State,
  objectId: string
): EditorObject | null | PositionObject => {
  const object = selectObject(state, objectId)

  if (!object) {
    return object
  }

  if (object.type === COMPONENT) {
    const deviceObject = getDeviceObject(object, getDeviceType(object.width))
    const { x, y, width, height } = deviceObject
    const yOffset = getYOffset(state)

    return {
      width,
      height: height - yOffset,
      x,
      y: y + yOffset,
    }
  }

  const component = getComponent(state, objectId) as EditorObject
  const deviceObject = getDeviceObject(object, getDeviceType(component.width))
  const { x, y, width, height } = deviceObject

  return {
    width,
    height,
    x: x + component.x,
    y: y + component.y,
  }
}

export const getDataBinding = (state: State, objectId: string): any => {
  const object = selectObject(state, objectId)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return object?.dataBindings?.[0]
}

export const getActions = (state: State, objectId: string) => {
  const object = selectObject(state, objectId)

  return (object && object['actions']) || {}
}

export const getParentComponent = (
  state: State,
  objectId: string
): EditorObject | null => {
  const { list, map } = state.editor.objects.present

  if (objectId && map[objectId]) {
    const parentPath = getParentPath(map[objectId])

    return getObject(list, parentPath)
  }

  return null
}

export const getParentScreenComponent = (state: State, objectId: string) => {
  const list = getObjectList(state)
  const map = getMap(state)

  if (!map[objectId]) {
    throw new Error(`Object not found: ${objectId}`)
  }

  if (objectId && map[objectId]) {
    const parentScreen = getParentScreen(list, map, objectId)

    if (!parentScreen) {
      throw new Error(`Failed to resolve parent screen for ${objectId}`)
    }

    return parentScreen
  }

  return null
}

export const getSelectedParent = (state: State) => {
  const { selectedParent } = state.editor.objects.present

  if (!selectedParent) {
    return null
  }

  const objects = selectObjects(state, [selectedParent])

  return objects ? objects[0] : null
}

export const getPanning = (state: State): any =>
  state.editor.objects.present.panning

export const getLibraryGlobals = (
  state: any,
  libraryName: string,
  componentName: string
): any => {
  const globals = state?.editor?.objects?.present?.libraryGlobals

  return globals[libraryName]?.[componentName] ?? {}
}
