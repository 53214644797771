import React, { useEffect, useMemo, useState, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// ducks
import { requestTeamApps } from 'ducks/apps'
import { getCurrentOrganization } from 'ducks/organizations'
import { getCurrentUser } from 'ducks/users/index.ts'

import AppsList from './AppsList'

import './Apps.scss'

export const TeamAppsContext = createContext({
  currentUserId: undefined,
  currentOrganizationId: undefined,
  isCurrentUserTeamAdmin: undefined,
  query: '',
  isLoaded: false,
})

const Apps = () => {
  const dispatch = useDispatch()

  const currentOrganization = useSelector(getCurrentOrganization)
  const currentUser = useSelector(getCurrentUser)
  const { teamAppsList } = useSelector(state => state.apps)

  const [query, setQuery] = useState('')
  const [isLoaded, setIsLoaded] = useState('')

  useEffect(() => {
    if (currentOrganization) {
      dispatch(requestTeamApps(currentOrganization.id))
    }
  }, [currentOrganization?.id])

  useEffect(() => {
    if (teamAppsList) {
      if (Object.keys(teamAppsList).length > 0) {
        setIsLoaded(true)
      }
    }
  }, [teamAppsList && Object.keys(teamAppsList).length])

  const isCurrentUserTeamAdmin = useMemo(() => {
    if (currentUser && currentOrganization) {
      const org = currentUser.Organizations.find(
        org => org.id === currentOrganization.id
      )

      return org.OrganizationUser.isAdmin
    } else {
      return false
    }
  }, [currentUser?.id, currentOrganization?.id])

  return (
    <TeamAppsContext.Provider
      value={{
        currentUserId: currentUser?.id,
        currentOrganizationId: currentOrganization?.id,
        isCurrentUserTeamAdmin,
        isAppsListLoaded: isLoaded,
      }}
    >
      <div className="apps-settings">
        <h3 className="settings-header">Apps</h3>
        <input
          placeholder="Search Apps..."
          onChange={e => setQuery(e.target.value)}
          value={query}
        />
        <AppsList query={query} />
      </div>
    </TeamAppsContext.Provider>
  )
}

export default Apps
