import DeviceType from '../../types/DeviceType'
import ObjectType from '../../types/ObjectType'
import ObjectNode from '../ObjectNode'
import GridTemplate from './GridTemplate'
import cellId from './cellId'
import GridItem from './GridItem'
import LayoutContext from '../layout/LayoutContext'

interface AutoPlacementGridItem {
  element: ObjectNode<ObjectType>
  row: number | undefined
  column: number | undefined
}

interface AutoPlacementGrid {
  items: AutoPlacementGridItem[]
  numRows: number
  numColumns: number
}

interface Cursor {
  row: number
  column: number
}

const generateGridItems = (
  elements: ObjectNode<ObjectType>[],
  device: DeviceType | undefined
): AutoPlacementGridItem[] => {
  const items: AutoPlacementGridItem[] = []

  for (const element of elements) {
    const row = element.getStyle('gridRowStart', device)
    const column = element.getStyle('gridColumnStart', device)

    const item: AutoPlacementGridItem = {
      element,
      row,
      column,
    }
    items.push(item)
  }

  return items
}

const initializeGridTemplate = (
  container: ObjectNode<ObjectType>,
  context: LayoutContext
): AutoPlacementGrid => {
  const { device } = context

  const gridRows = container.getStyle('gridTemplateRows', device)
  const gridColumns = container.getStyle('gridTemplateColumns', device)

  const explicitRows = gridRows.trackSizes.length
  const explicitColumns = gridColumns.trackSizes.length

  const visibleChildren = container.getInFlowChildren(context)
  const items = generateGridItems(visibleChildren, device)

  return {
    items,
    numRows: explicitRows,
    numColumns: explicitColumns,
  }
}

const indexOccupiedCells = (items: AutoPlacementGridItem[]): Set<string> => {
  const result: Set<string> = new Set()
  for (const { row, column } of items) {
    if (row !== undefined && column !== undefined) {
      result.add(cellId(row, column))
    }
  }

  return result
}

const positionRowLockedItems = (
  gridTemplate: AutoPlacementGrid
): AutoPlacementGrid => {
  const occupiedCells = indexOccupiedCells(gridTemplate.items)

  // Track the last placement on each row
  const rowCursors: Map<number, number> = new Map()

  const items = gridTemplate.items.map(item => {
    const { row, column } = item
    // Search for items with explicit row and undetermined column
    if (row === undefined || column !== undefined) {
      return item
    }

    let rowCursor = rowCursors.get(row)
    if (rowCursor === undefined) {
      rowCursor = 0
      rowCursors.set(row, rowCursor)
    }

    // Find next unoccupied cell in the row. For this step, there's no limit on number of columns in the row.
    while (occupiedCells.has(cellId(row, rowCursor))) {
      rowCursor += 1
    }

    // Set the items column
    const result = {
      ...item,
      column: rowCursor,
    }

    // Mark the cell as occupied
    occupiedCells.add(cellId(row, rowCursor))

    return result
  })

  return {
    ...gridTemplate,
    items,
  }
}

const resolveImplicitColumns = (
  gridTemplate: AutoPlacementGrid
): AutoPlacementGrid => {
  const { items } = gridTemplate

  // Initialize to the set of explicit columns
  let { numColumns } = gridTemplate

  // Scan all items to find any which are positioned outside the explicit grid.
  for (const { column } of items) {
    if (column !== undefined) {
      // The value is incremented by one because `column` is a zero-based index.
      numColumns = Math.max(numColumns, column + 1)
    }
  }

  return {
    ...gridTemplate,
    numColumns,
  }
}

const resolveImplicitRows = (gridArrangement: GridTemplate): GridTemplate => {
  const { items } = gridArrangement

  // Initialize to the set of explicit rows
  let { numRows } = gridArrangement

  // Scan all items to find any which are positioned outside the explicit grid.
  for (const { row } of items) {
    if (row !== undefined) {
      // The value is incremented by one because `row` is a zero-based index.
      numRows = Math.max(numRows, row + 1)
    }
  }

  return {
    ...gridArrangement,
    numRows,
  }
}

const findNextUnoccupiedRow = (
  occupiedCells: Set<string>,
  cursor: Cursor
): Cursor => {
  let { row, column } = cursor

  if (!occupiedCells.has(cellId(row, column))) {
    return cursor
  }

  row += 1

  return findNextUnoccupiedRow(occupiedCells, { row, column })
}

const findNextUnoccupiedCell = (
  numColumns: number,
  occupiedCells: Set<string>,
  cursor: Cursor
): Cursor => {
  let { row, column } = cursor
  if (!occupiedCells.has(cellId(row, column))) {
    return { row, column }
  }

  if (column >= numColumns - 1) {
    // Move to next row
    row += 1
    column = 0
  } else {
    column += 1
  }

  return findNextUnoccupiedCell(numColumns, occupiedCells, { row, column })
}

const positionRemainingItems = (
  gridTemplate: AutoPlacementGrid
): GridTemplate => {
  const { items, numColumns, numRows } = gridTemplate
  const occupiedCells = indexOccupiedCells(items)

  let cursor: Cursor = {
    row: 0,
    column: 0,
  }

  const positionedItems: GridItem[] = []
  for (const item of items) {
    const {
      element: { id },
      row,
      column,
    } = item
    if (row !== undefined && column !== undefined) {
      // Item is already positioned
      positionedItems.push({
        ...item,
        row,
        column,
      })
    } else if (column !== undefined) {
      // Column is fixed. Move cursor to explicitly set column.
      cursor = { ...cursor, column }

      // Search down until an unoccupied row is found, adding implicit rows as necessary.
      cursor = findNextUnoccupiedRow(occupiedCells, cursor)

      // Mark this cell as occupied
      occupiedCells.add(cellId(cursor.row, cursor.column))

      positionedItems.push({
        ...item,
        ...cursor,
      })
    } else if (row !== undefined) {
      // This case should never it happen as these items must be previously positioned in positionRowLockedItems()
      throw new Error(`Found unplaced row-locked item (Object ID: ${id})`)
    } else {
      cursor = findNextUnoccupiedCell(numColumns, occupiedCells, cursor)

      // Mark this cell as occupied
      occupiedCells.add(cellId(cursor.row, cursor.column))

      positionedItems.push({
        ...item,
        ...cursor,
      })
    }
  }

  return {
    items: positionedItems,
    numRows,
    numColumns,
  }
}

/**
 * Auto-positions items which do not have explicitly position set.
 *
 * Reference: https://drafts.csswg.org/css-grid/#auto-placement-algo
 */
const positionGridItems = (
  container: ObjectNode<ObjectType>,
  context: LayoutContext
): GridTemplate => {
  const gridTemplate = initializeGridTemplate(container, context)

  // Position items locked to a specific row
  let disarrangedGrid = positionRowLockedItems(gridTemplate)

  // Determine columns in the implicit grid
  disarrangedGrid = resolveImplicitColumns(disarrangedGrid)

  // Position remaining grid items
  let arrangedGrid = positionRemainingItems(disarrangedGrid)

  // Determine rows in the implicit grid
  arrangedGrid = resolveImplicitRows(arrangedGrid)

  return arrangedGrid
}

export default positionGridItems
