import GenericInspectRow from './GenericRow'
import MenuControl from './Libraries/MenuControl'
import ImageControl from './ImageControl'

const backgroundSizeOptions = [
  { label: 'Crop Image to Fill', value: 'cover' },
  {
    label: 'Show Full Image',
    value: 'contain',
  },
]

const horizontalOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' },
]

const verticalOptions = [
  { label: 'Top', value: 'top' },
  { label: 'Center', value: 'center' },
  { label: 'Bottom', value: 'bottom' },
]

type Props = {
  object: {
    backgroundImage: string
    backgroundSize: string
    backgroundPositionX: string
    backgroundPositionY: string
  }
  onChange: () => void
}

const BackgroundImageControl: React.FC<Props> = ({ object, onChange }) => {
  const isEnabled = Boolean(object.backgroundImage)

  return (
    <GenericInspectRow
      className="background-image-control"
      title="Background Image"
    >
      <ImageControl
        name="backgroundImage"
        value={object.backgroundImage}
        onChange={onChange}
      />
      {isEnabled && [
        <MenuControl
          name="backgroundSize"
          options={backgroundSizeOptions}
          value={object.backgroundSize ?? 'cover'}
          onChange={onChange}
          disabled={!isEnabled}
          displayName="Image Cropping"
        />,
        <GenericInspectRow className="default-font-control">
          <MenuControl
            name="backgroundPositionX"
            options={horizontalOptions}
            value={object.backgroundPositionX ?? 'center'}
            onChange={onChange}
            disabled={!isEnabled}
            displayName="Horizontal Position"
          />
          <MenuControl
            name="backgroundPositionY"
            options={verticalOptions}
            value={object.backgroundPositionY ?? 'center'}
            onChange={onChange}
            disabled={!isEnabled}
            displayName="Vertical Position"
          />
        </GenericInspectRow>,
      ]}
    </GenericInspectRow>
  )
}

export default BackgroundImageControl
