import React, { Component } from 'react'
import classNames from 'classnames'
import DocumentEvents from 'react-document-events'
import { dataTypes } from '@adalo/constants'

import { DEFAULT_COLUMN_WIDTH } from 'utils/tables'

export default class Cell extends Component {
  state = {
    resizing: false,
    startPosition: 0,
    startWidth: 0,
  }

  handleMouseDown = e => {
    e.preventDefault()
    e.stopPropagation()

    const startPosition = e.clientX
    const startWidth = this.getWidth()

    this.setState({ resizing: true, startPosition, startWidth })
  }

  handleMouseMove = e => {
    const { resizing } = this.state

    e.preventDefault()
    e.stopPropagation()

    if (resizing) {
      this.handleResize(e, true)
    }
  }

  handleMouseUp = e => {
    const { resizing } = this.state

    if (resizing) {
      this.handleResize(e)
    }

    this.setState({ resizing: false })
  }

  handleResize = (e, skipSave = false) => {
    const { resizing, startPosition, startWidth } = this.state
    const { fieldId, onResize } = this.props

    if (!resizing) {
      return
    }

    const x = e.clientX

    let width = startWidth + x - startPosition
    width = Math.max(50, width)
    onResize(fieldId, width, skipSave)
  }

  handleClick = e => {
    const { onClick } = this.props

    if (onClick) {
      e.preventDefault()
      e.stopPropagation()

      onClick()
    }
  }

  getWidth() {
    let { width } = this.props
    width = width || DEFAULT_COLUMN_WIDTH

    return width
  }

  render() {
    const { children, className, field, onResize, select } = this.props
    const { resizing } = this.state

    const width = this.getWidth()

    const styles = {
      minWidth: width,
      maxWidth: width,
    }

    let center = false

    if (select) {
      center = true
    }

    if (field) {
      switch (field.type) {
        case dataTypes.BOOLEAN:
        case dataTypes.IMAGE:
          center = true

          break
      }

      if (typeof field.type === 'object') {
        if (
          field.type.type === 'manyToMany' ||
          field.type.type === 'manyToManyReverse' ||
          field.type.type === 'hasMany'
        ) {
          center = true
        }
      }
    }

    return (
      <div
        className={classNames(
          'data-browser-cell',
          className,
          onResize && 'data-browser-cell-resizeable'
        )}
        style={styles}
        onClick={this.handleClick}
      >
        <div className={classNames('data-browser-cell-content', { center })}>
          {children}
        </div>
        {onResize && (
          <>
            <div
              className="data-browser-cell-resize"
              onMouseDown={this.handleMouseDown}
            />
            {resizing ? (
              <DocumentEvents
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
              />
            ) : null}
          </>
        )}
      </div>
    )
  }
}
