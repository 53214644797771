import React from 'react'
import { useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
// TODO @michael-adalo; fork/replace library as it is not supported beyond React 16
import JotformEmbed from 'react-jotform-embed'

import { getCurrentUser } from 'ducks/users/index'
import { getCurrentOrganization } from 'ducks/organizations'

import Icon from 'components/Shared/Icon'
import './Experts.css'
import topImage from './assets/experts-panel-top-image.jpeg'

export const EXPERTS_GROUP = 'experts'

const ExpertsPanel: React.FC = () => {
  const { appId } = useParams<{ appId: string }>()
  const currentUser = useSelector(getCurrentUser)
  const currentOrganization = useSelector(getCurrentOrganization)

  if (
    !appId ||
    !currentUser ||
    !currentUser.name ||
    !currentUser.email ||
    !currentOrganization ||
    !currentOrganization.id
  ) {
    return null
  }

  const jotformUrl = `https://form.jotform.com/230366065507050?${new URLSearchParams(
    {
      appId,
      orgId: currentOrganization.id,
      fullName: currentUser.name,
      email: currentUser.email,
      source: 'app',
    }
  ).toString()}`

  return (
    <div className="experts-panel">
      <img
        className="experts-panel-top-image"
        src={topImage}
        alt="Team Up With an Adalo Expert"
        width="300px"
      />
      <h3 className="experts-panel-header-title">
        Team Up With an Adalo Expert
      </h3>
      <p className="experts-panel-header-text">
        Need an upgrade? Build an Adalo app with the help of expert designers,
        developers, and rockstar coaches!
      </p>
      <a
        className="browse-experts-button"
        href="https://www.adalo.com/experts"
        target="_blank"
        rel="noopener noreferrer"
      >
        Browse Experts
        <Icon type="arrow-next" />
      </a>
      <JotformEmbed src={jotformUrl} />
    </div>
  )
}

export default withRouter(ExpertsPanel)
