import React, { Component } from 'react'

import { BarChart, Bar, XAxis, YAxis, Legend } from 'recharts'
import '../Analytics.css'
import { adaloBackendAxios } from 'utils/io/http/axios'

export default class EngagedUsersChart extends Component {
  state = {
    key: '',
    showTooltip: false,
    loading: true,
  }

  async componentDidMount() {
    this.render()
    let data = await this.getData()
    let showEmpty = false

    if (data) {
      const { daily, weekly, monthly } = data[0]

      if (daily === 0 && weekly === 0 && monthly === 0) {
        data = [{ daily: 5, weekly: 10, monthly: 70 }]
        showEmpty = true
      }
    } else {
      data = [{ daily: 5, weekly: 10, monthly: 70 }]
      showEmpty = true
    }

    this.setState({ data, showEmpty, loading: false })
  }

  componentDidUpdate(prevProps) {
    const { start } = this.props

    if (start !== prevProps.start) {
      this.getData()
    }
  }

  getData = async () => {
    const { appId, lifetimeDifference } = this.props
    const queryType = 'engaged'
    let start = new Date()
    let end = new Date()
    let threshold

    if (lifetimeDifference <= 30) {
      threshold = {
        daily: Math.max(2, Math.floor((13 / 30) * lifetimeDifference)),
        weekly: Math.max(1, Math.floor((5 / 30) * lifetimeDifference)),
      }
    } else {
      threshold = { daily: 13, weekly: 5 }
    }

    start.setDate(end.getDate() - 30)
    start = { inner: start, outer: start }

    start.inner = `${start.inner.getFullYear()}-${
      start.inner.getMonth() + 1
    }-${start.inner.getDate()}`

    start.outer = `${start.outer.getFullYear()}-${
      start.outer.getMonth() + 1
    }-${start.outer.getDate()}`

    end = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`

    let data = await adaloBackendAxios.post(`/analytics`, {
      queryType,
      appId,
      start,
      end,
      threshold,
    })

    if (data.data.success) {
      data = data.data.data[0]

      for (const d in data) {
        if (Object.prototype.hasOwnProperty.call(data, d)) {
          data[d] = +data[d]
        }
      }

      data = [data]

      return data
    } else {
      data = [{ daily: 0, weekly: 0, monthly: 0 }]

      return data
    }
  }

  renderLegendText = (value, entry) => {
    const { data } = this.state

    return (
      <div className="legend-label-container">
        <span className="legend-label-value">{data[0][entry.id]}</span>
        <span>{value}</span>
      </div>
    )
  }

  handleMouseOver = key => value => {
    this.setState({ showTooltip: true, key, value })
  }

  handleMouseOut = () => {
    this.setState({ showTooltip: false })
  }

  renderTooltip = () => {
    const { lifetimeDifference } = this.props
    const { showEmpty } = this.state
    let threshold

    if (showEmpty) {
      return null
    }

    if (lifetimeDifference <= 30) {
      threshold = {
        daily: Math.max(2, Math.floor((13 / 30) * lifetimeDifference)),
        weekly: Math.max(1, Math.floor((5 / 30) * lifetimeDifference)),
      }
    } else {
      threshold = { daily: 13, weekly: 5 }
    }

    let { key, data, value } = this.state
    const users = data[0][key]
    let userLabel = 'Users'

    if (users === 1) {
      userLabel = 'User'
    }

    let body = ''

    switch (key) {
      case 'daily':
        body = `Have performed at least one action \n in ${threshold.daily} out of the last 30 days`

        break
      case 'weekly':
        body = `Have performed at least one action \n between ${
          threshold.weekly
        } and ${threshold.daily - 1} out of the last 30 days`

        break
      case 'monthly':
        body = `Have performed at least one action \n between 1 and 4 out of the last 30 days`
    }

    key = key.charAt(0).toUpperCase() + key.slice(1)

    return (
      <div className="engaged-tooltip" style={{ left: 310 + value.width / 4 }}>
        <h2>{`${users} ${key} ${userLabel}`} </h2>
        <p>{body}</p>
      </div>
    )
  }

  render() {
    const { data, showTooltip, showEmpty, loading } = this.state
    let total = 0

    for (const d in data) {
      if (Object.prototype.hasOwnProperty.call(data, d)) {
        total += data[d]
      }
    }

    return (
      <div className="app-analytics-module-container app-analytics-engaged-container">
        {showTooltip ? this.renderTooltip() : null}
        <div className="engaged-header-container">
          <h3>Active User Frequency</h3>
          {showEmpty ? <p>No Data Found</p> : null}
        </div>
        {loading ? null : (
          <BarChart
            layout="vertical"
            width={487}
            height={showEmpty ? 50 : 65}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            className="engaged-bar-chart"
          >
            <XAxis hide type="number" domain={[0, total]} />
            <YAxis hide type="category" />
            <Bar
              name="Daily"
              dataKey="daily"
              stackId="a"
              fill={showEmpty ? 'rgb(160, 160, 160)' : 'rgb(168, 32, 88)'}
              strokeWidth={1}
              stroke={showEmpty ? 'rgb(160, 160, 160)' : 'rgb(168, 32, 88)'}
              onMouseOver={this.handleMouseOver('daily')}
              onMouseOut={this.handleMouseOut}
              onFocus={this.handleMouseOver('daily')}
              onBlur={this.handleMouseOut}
            />
            <Bar
              name="Weekly"
              dataKey="weekly"
              stackId="a"
              fill={showEmpty ? 'rgb(189, 189, 189)' : 'rgb(168, 32, 88)'}
              fillOpacity={0.6}
              strokeWidth={1}
              stroke={showEmpty ? 'rgb(189, 189, 189)' : 'rgb(168, 32, 88)'}
              strokeOpacity={0.6}
              onMouseOver={this.handleMouseOver('weekly')}
              onMouseOut={this.handleMouseOut}
              onFocus={this.handleMouseOver('weekly')}
              onBlur={this.handleMouseOut}
            />
            <Bar
              name="Monthly"
              dataKey="monthly"
              stackId="a"
              fill={showEmpty ? 'rgb(224, 224, 224)' : 'rgb(168, 32, 88)'}
              fillOpacity={0.2}
              strokeWidth={1}
              stroke={showEmpty ? 'rgb(224, 224, 224)' : 'rgb(168, 32, 88)'}
              strokeOpacity={0.2}
              onMouseOver={this.handleMouseOver('monthly')}
              onMouseOut={this.handleMouseOut}
              onFocus={this.handleMouseOver('monthly')}
              onBlur={this.handleMouseOut}
            />

            {showEmpty ? null : (
              <Legend
                wrapperStyle={{ bottom: -2 }}
                formatter={this.renderLegendText}
                payload={[
                  {
                    id: 'daily',
                    value: 'Daily',
                    type: 'square',
                    color: 'rgb(168, 32, 88)',
                  },
                  {
                    id: 'weekly',
                    value: 'Weekly',
                    type: 'square',
                    color: 'rgba(168, 32, 88, 0.60)',
                  },
                  {
                    id: 'monthly',
                    value: 'Monthly',
                    type: 'square',
                    color: 'rgba(168, 32, 88, 0.20)',
                  },
                ]}
                align="left"
              />
            )}
          </BarChart>
        )}
      </div>
    )
  }
}
