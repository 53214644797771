import React from 'react'
import classNames from 'classnames'

import './Loading.scss'

const loading = ({ expanded = false, small = false, color = null }) => (
  <div
    className={classNames('loading', {
      'loading-expanded': expanded,
      'loading-small': small,
    })}
  >
    <div
      className={classNames('loading-icon', {
        [`loading-icon-${color}`]: color,
      })}
    />
  </div>
)

export default loading
