import React, { Component } from 'react'
import classNames from 'classnames'

export default class BooleanControl extends Component {
  handleChange = e => {
    const { onChange, name, selectedValue, deselectedValue } = this.props
    let newValue = e.target.checked

    if (selectedValue || deselectedValue) {
      newValue = newValue ? selectedValue : deselectedValue
    }

    if (name !== undefined) {
      onChange({ [name]: newValue })
    }
  }

  render() {
    let { value, selectedValue, standalone } = this.props

    if (selectedValue) {
      value = value === selectedValue
    }

    value = !!value

    return (
      <div
        className={classNames('boolean-control', {
          'boolean-control-standalone': standalone,
        })}
      >
        <input type="checkbox" checked={value} onChange={this.handleChange} />
      </div>
    )
  }
}
