import React, { Component, useMemo } from 'react'
import { reduxForm } from 'redux-form'
import MultiSelectMenu from '@protonapp/react-multi-menu'

import Icon from 'components/Shared/Icon'

export const FORM_NAME = 'appSettingsAddForm'

const validate = values => {
  const errors = {}

  if (!values.userId) {
    errors.userId = 'Cannot be blank'
  }

  return errors
}

class FormItem extends Component {
  render() {
    const { name, isInvite, email } = this.props

    if (isInvite) {
      return (
        <div
          className="app-settings-user-item-select-invite"
          data-adalo-id="invite-new-editor-app-settings-access"
        >
          <div className="app-settings-user-details-select">
            <div className="app-settings-user-name-select">
              <i> {name}</i>
            </div>
            <div className="app-settings-user-email-select">{email}</div>
          </div>
        </div>
      )
    }

    return (
      <div
        className="app-settings-user-item-select"
        data-adalo-id="invite-new-editor-app-settings-access"
      >
        <div className="app-settings-user-details-select">
          <div className="app-settings-user-name-select">{name}</div>
          <div className="app-settings-user-email-select">{email}</div>
        </div>
        <div className="app-settings-user-action-select">
          <Icon type="plus" />
        </div>
      </div>
    )
  }
}

const AddForm = props => {
  const { organization, isOrgAdmin, users, onSubmit } = props

  const options = useMemo(() => {
    const opts = organization.Users.filter(user => {
      // check if org user is already an app user
      const appUserExists = !!users.find(({ UserId }) => UserId === user.id)

      // filter out any existing app users
      return !appUserExists
    }).map(user => ({
      label: <FormItem name={user.name} email={user.email} />,
      value: user.id,
    }))

    if (isOrgAdmin) {
      if (opts.length !== 0) {
        opts.push(null)
      }

      opts.push({
        label: <FormItem name="Invite New Team Members..." email="" isInvite />,
        value: 'invite',
      })
    }

    return opts
  }, [organization.Users.length, users.length])

  return (
    <div className="app-settings-add-user-input">
      <MultiSelectMenu
        autoSelect
        options={options}
        placeholder="Add Team Member"
        onChange={onSubmit}
        rowHeight={54}
      />
    </div>
  )
}

export default reduxForm({
  validate,
  form: FORM_NAME,
})(AddForm)
