import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sort } from '@adalo/utils'

import { PanelItem } from 'components/Shared/Panel'

import { selectObjects } from 'ducks/editor/objects'
import { getApp } from 'ducks/apps'
import { getComponentView, setLayersHover } from 'ducks/editor/selection'

import { getScreenIcon } from 'utils/icons'
import centerScreenOnCanvas from 'utils/objects/centerScreenOnCanvas'

const ComponentControl = props => {
  const { appId } = props
  const dispatch = useDispatch()
  const componentView = useSelector(getComponentView)
  const screens = useSelector(selectObjects)
  const app = useSelector(state => getApp(state, appId))
  const { authComponentId, launchComponentId } = app || {}

  const filteredScreens = screens.filter(screen =>
    componentView.screenIds.includes(screen.id)
  )
  const sortedScreens = sort(filteredScreens, obj => obj.name)

  const handleClick = object => {
    dispatch(centerScreenOnCanvas(object))
  }

  return (
    <>
      {sortedScreens.map(({ name, id, ...screen }) => (
        <PanelItem
          key={id}
          content={name}
          title={name}
          icon={getScreenIcon(id, authComponentId, launchComponentId)}
          fluid
          onMouseEnter={() => dispatch(setLayersHover(screen))}
          onMouseLeave={() => dispatch(setLayersHover(null))}
          onClick={() => handleClick({ id, name, ...screen })}
        />
      ))}
    </>
  )
}

export default ComponentControl
