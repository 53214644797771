import React, { Component } from 'react'
import DocumentEvents from 'react-document-events'

export default class PositioningManager extends Component {
  // Not sure when this would ever happen but...
  _prevDragPoint = [0, 0]

  handleMouseMove = e => {
    const { drag } = this.props

    if (e.clientX !== undefined && e.clientY !== undefined) {
      this._prevDragPoint = [e.clientX, e.clientY]
    }

    drag(this._prevDragPoint, e.shiftKey, e.altKey, {
      mouseX: e.clientX,
      mouseY: e.clientY,
    })
  }

  handleMouseUp = e => {
    const { endDrag } = this.props

    endDrag({
      mouseX: e.clientX,
      mouseY: e.clientY,
    })
  }

  render() {
    return (
      <DocumentEvents
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
      />
    )
  }
}
