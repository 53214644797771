import { omit } from 'lodash'
import {
  ColumnAlignment,
  ColumnAlignments,
  columnTypeDefaultAlignment,
  type FormatKeys,
} from '@adalo/constants'
import { Form } from 'utils/responsiveTypes'
import {
  Datasource,
  TableField,
  TableFields,
} from 'components/Editor/XanoExternalDatabaseModal/lib/user'
import { getAccordionState } from 'ducks/accordions'
import type {
  TableField as TableFieldType,
  EditorObject,
} from 'utils/responsiveTypes'
import { DEFAULT_FIELDS, INACCESSIBLE_FIELDS } from './consts'
import { type AccordionGroup } from './types'

export const ACCORDION_GROUP = 'table-component-inspect'
export const ACCORDION_GROUP_DEFAULT_ACCORDION = 'table-component-data'
export const ACCORDION_FIELD_GROUP = 'table-component-field-inspect'

type SimpleField = {
  name: string
  type: string
}

export const parseTableFields = (
  fields: Record<string, SimpleField> | undefined
): Form[] => {
  if (!fields) {
    return []
  }

  const newFields = []

  for (const fieldId in fields) {
    if (fieldId) {
      newFields.push({
        fieldId,
        label: fields[fieldId]?.name ?? '',
        type: fields[fieldId]?.type ?? '',
      })
    }
  }

  return newFields
}

export const updateBindingType = (
  field: TableFieldType,
  option: FormatKeys | undefined,
  onChange: (field: TableFieldType, index: number) => void,
  fieldIndex: number
): void => {
  if (!field.binding) {
    console.error('binding not found for field', field.label)

    return
  }

  const updatedField: TableFieldType = {
    ...field,
    binding: {
      ...field.binding,
      format: {
        ...field.binding.format,
        ...option,
      },
    },
  }

  onChange(updatedField, fieldIndex)
}

export const updateBindingFirstColumn = (
  field: TableFieldType,
  value: boolean,
  onChange: (field: TableFieldType, index: number) => void,
  fieldIndex: number
): void => {
  if (!field.binding) {
    console.error('binding not found for field', field.label)

    return
  }

  const updatedField: TableFieldType = {
    ...field,
    binding: {
      ...field.binding,
      format: {
        ...field.binding?.format,
        shouldFreezeFirstColumn: value,
      },
    },
  }

  onChange(updatedField, fieldIndex)
}

export const updateBindingPrefixSuffix = (
  field: TableFieldType,
  suffix: TableFieldType['binding'],
  onChange: (field: TableFieldType, index: number) => void,
  fieldIndex: number
): void => {
  if (!field.binding) {
    console.error('binding not found for field', field.label)

    return
  }

  if (!suffix) {
    console.error('suffix was not provided')

    return
  }

  const { format } = suffix

  const { ...prefixProps } = omit(format, 'type')

  const updatedField: TableFieldType = {
    ...field,
    binding: {
      ...field.binding,
      format: {
        ...field.binding?.format,
        ...prefixProps,
      },
    },
  }

  onChange(updatedField, fieldIndex)
}

export const updateLabel = (
  field: TableFieldType,
  currentField: TableFieldType,
  onChange: (field: TableFieldType, index: number) => void,
  fieldIndex: number
): void => {
  onChange(currentField, fieldIndex)
}

export const getTableFields = (
  datasource: Datasource,
  tableId: string
): TableFields => {
  const table = datasource.tables[tableId]

  if (!table) {
    throw new Error(`table not found for table id: ${tableId}`)
  }

  const tableFields = table.fields

  if (!tableFields) {
    throw new Error(`table fields not found for table id: ${tableId}`)
  }

  const filteredFields: TableFields = {}

  // Do not add ID field if table type is Xano or External
  if (!table.type) {
    filteredFields['id'] = DEFAULT_FIELDS.get('id') as TableField
  }

  for (const [fieldId, field] of Object.entries(tableFields)) {
    if (INACCESSIBLE_FIELDS.has(fieldId)) {
      continue
    }

    filteredFields[fieldId] = field
  }

  // Do not add created_at and updated_at fields if table type is Xano or External
  if (!table.type) {
    filteredFields['created_at'] = DEFAULT_FIELDS.get('created_at') as TableField // prettier-ignore
    filteredFields['updated_at'] = DEFAULT_FIELDS.get('updated_at') as TableField // prettier-ignore
  }

  return filteredFields
}

export const getInitialTableFields = (
  datasource: Datasource,
  tableId: string
): TableFields => {
  const tableFields = getTableFields(datasource, tableId)

  const filteredFields: TableFields = {}

  for (const [fieldId, field] of Object.entries(tableFields)) {
    // skip id, created_at, and updated_at fields
    if (DEFAULT_FIELDS.has(fieldId)) {
      continue
    }

    // skip adding relationship fields by default
    if (typeof field.type === 'object') {
      continue
    }

    filteredFields[fieldId] = field
  }

  return filteredFields
}

export const tableHasAdaloExternalCollection = (
  datasource: Datasource,
  object: EditorObject
): boolean => {
  if (!object.dataBinding?.source?.tableId) {
    return false
  }

  const table = datasource.tables[object.dataBinding.source.tableId]

  if (!table) {
    throw new Error(
      `table not found for table id: ${object.dataBinding.source.tableId}`
    )
  }

  return Boolean(table.type === 'api')
}

/**
 * Determine if the currently empty state applies to the current object
 */
export const getRenderEmptyState = (
  state: unknown,
  object: EditorObject
): boolean => {
  try {
    const accordionState = getAccordionState(state, ACCORDION_GROUP)
    if (typeof accordionState !== 'string') {
      throw new Error('No accordion state')
    }

    const accordionGroup = JSON.parse(accordionState) as AccordionGroup

    return (
      accordionGroup.key === 'table-component-empty-state' &&
      accordionGroup.subject === object.id
    )
  } catch (e) {
    return false
  }
}

/**
 * Determine if the currently focussed field applies to the current object
 */
export const getFocussedField = (
  state: unknown,
  object: EditorObject
): string | null => {
  try {
    const isViewingColumns = getAccordionState(state, ACCORDION_GROUP) === 'table-component-columns' // prettier-ignore
    if (isViewingColumns === false) {
      throw new Error('Not viewing columns')
    }

    const accordionState = getAccordionState(state, ACCORDION_FIELD_GROUP)
    if (typeof accordionState !== 'string') {
      throw new Error('No accordion state')
    }

    const accordionGroup = JSON.parse(accordionState) as AccordionGroup
    if (accordionGroup.key !== object.id) {
      throw new Error('Not the same object')
    }

    return accordionGroup.subject
  } catch (e) {
    return null
  }
}

export const makeUniqueFieldId = (field: TableFieldType): string => {
  const pieces = []

  if (typeof field.binding?.source?.source?.tableId === 'string') {
    pieces.push(field.binding.source.source.tableId)
  }

  // direct and m:1 fields
  if (typeof field.binding?.source?.fieldId === 'string') {
    pieces.push(field.binding.source.fieldId)
  }

  // 1:m and m:m are always represented as `counts` and have a fieldId one level deeper
  if (typeof field.binding?.source?.source?.fieldId === 'string') {
    pieces.push(field.binding.source.source.fieldId)
  }

  return pieces.join('--')
}

export const getFieldColumnAlignment = (
  field: TableFieldType
): ColumnAlignment => {
  if (field.binding?.format?.columnAlignment) {
    return field.binding?.format?.columnAlignment
  }

  if (typeof field.type === 'string') {
    const defaultAlignment = (
      columnTypeDefaultAlignment as Record<string, ColumnAlignment | undefined>
    )[field.type]

    return defaultAlignment ?? ColumnAlignments.center
  }

  return ColumnAlignments.center
}
