import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { PREVIEW_URL } from 'constants.js'
import Settings, {
  Account,
  Developer,
  Domain,
  Team,
  Billing,
  Apps,
} from 'components/Settings'
import { Developers, DeveloperShared } from 'components/Developers'
import NewDeveloperLibraries from 'components/Developers/Libraries'
import InviteModal from 'components/AppDetail/Settings/InviteModal/InviteModal'
import CopyApp from 'components/Editor/CopyApp'
import CloneApp from 'components/Editor/CloneApp'
import Preview from 'components/Editor/Preview'
import BuildLogsModal from 'components/Editor/Publish/BuildList/BuildLogsModal'
import AppQuestionsModal from 'components/Editor/Publish/AppQuestionsModal'

const Screens = props => {
  return (
    <Switch>
      <Route
        path="/apps/:appId/screens/preview"
        render={routeProps => <Preview {...routeProps} baseUrl={PREVIEW_URL} />}
      />
      <Route path="/apps/:appId/screens/copy" component={CopyApp} />
      <Route path="/apps/:appId/screens/screens" component={CloneApp} />
      <Route path="/apps/:appId/screens/data" component={CloneApp} />

      <Route
        path="/apps/:appId/screens/builds/:buildId"
        component={BuildLogsModal}
      />
      <Route
        path="/apps/:appId/screens/settings"
        render={() => (
          <Settings {...props} modal>
            <Switch>
              <Route
                exact
                path="/apps/:appId/screens/settings/account"
                component={Account}
              />
              <Route
                exact
                path="/apps/:appId/screens/settings/apps"
                component={Apps}
              />
              <Route
                exact
                path="/apps/:appId/screens/settings/team"
                component={Team}
              />
              <Route
                exact
                path="/apps/:appId/screens/settings/billing"
                component={Billing}
              />
              <Route
                exact
                path="/apps/:appId/screens/settings/domains"
                component={Domain}
              />
              <Route
                exact
                path="/apps/:appId/screens/settings/developers"
                component={Developer}
              />
            </Switch>
          </Settings>
        )}
      />
      <Route
        path="/apps/:appId/screens/developers"
        render={() => (
          <Developers {...props} modal>
            <Switch>
              <Route
                exact
                path="/apps/:appId/screens/developers/libraries"
                component={NewDeveloperLibraries}
              />
              <Route
                exact
                path="/apps/:appId/screens/developers/shared"
                component={DeveloperShared}
              />
            </Switch>
          </Developers>
        )}
      />
      <Route path="/apps/:appId/screens/invite" component={InviteModal} />
      <Route
        path="/apps/:appId/screens/native-app-questions"
        component={AppQuestionsModal}
      />
    </Switch>
  )
}

export default Screens
