import * as inflection from 'inflection'

/**
 *
 * @returns {string}
 */
export const singularize = (string, ...args) => {
  if (!string) {
    return '[Untitled]'
  }

  return inflection.singularize(string, ...args)
}

/**
 *
 * @returns {string}
 */
export const pluralize = (string, ...args) => {
  if (!string) {
    return '[Untitled]'
  }

  return inflection.pluralize(string, ...args)
}

export const capitalize = str => {
  if (!str) {
    return str
  }

  return str
    .split(/\s+/)
    .map(word => {
      const first = word.substring(0, 1)
      const rest = word.substring(1)

      return `${first.toUpperCase()}${rest}`
    })
    .join(' ')
}

export const tableNamesEqual = (str1, str2) => {
  if (!str1 || !str2) {
    return false
  }

  str1 = str1.toLowerCase().trim()
  str2 = str2.toLowerCase().trim()

  if (str1 === str2) {
    return true
  }

  if (singularize(str1) === str2) {
    return true
  }

  if (singularize(str2) === str1) {
    return true
  }

  return false
}

export const possessive = string => {
  if (!string) {
    return string
  }

  const isPlural = string === inflection.pluralize(string)
  const endsWithS = string.slice(-1).toLowerCase() === 's'

  if (isPlural && endsWithS) {
    return `${string}'`
  }

  return `${string}'s`
}

export const possessiveExpansion = string => {
  if (!string) {
    return string
  }

  return `${possessive(string)}...`
}

export const stripPossessive = string => {
  if (string.endsWith("'...")) {
    return string.slice(0, -4)
  }

  if (string.endsWith("'s...")) {
    return string.slice(0, -5)
  }

  return string
}
