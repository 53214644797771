import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import {
  DESIGN_VERSION_MODAL,
  CONFIRM_RESTORE_MODAL,
  showModal,
} from 'ducks/editor/modals'
import { isFeatureEnabled } from 'ducks/organizations'

import { deleteDesignVersion, restoreDesignVersion } from 'utils/io'
import { formatUserName } from 'utils/text'

import Icon, { IconButton } from 'components/Shared/Icon'
import Loading from 'components/Shared/Loading'

import { formatDate } from './utils'

export const VersionItem = ({
  appId,
  version,
  loadVersionsList,
  limitReached,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const isEnabled = useSelector(state =>
    isFeatureEnabled(state, 'designVersions')
  )

  if (!version.id) {
    return null
  }

  const handleRestoreVersion = async () => {
    if (limitReached) {
      dispatch(showModal(CONFIRM_RESTORE_MODAL, { restoreVersion }))
    } else {
      await restoreVersion()
    }
  }

  const restoreVersion = async () => {
    setIsLoading(true)

    try {
      const versionRestored = await restoreDesignVersion(
        appId,
        version.versionId,
        limitReached
      )

      if (versionRestored) {
        window.location.reload()
      }
    } catch (err) {
      alert(err.message)
    }

    setIsLoading(false)
  }

  const deleteVersion = async () => {
    const deletionConfirmation = window.confirm(
      `Are you sure you want to delete ${version.versionName}?`
    )

    if (deletionConfirmation) {
      setIsLoading(true)

      try {
        await deleteDesignVersion(appId, version.versionId)
        await loadVersionsList()
      } catch (err) {
        alert(err.message)
      }

      setIsLoading(false)
    }
  }

  const openModal = () => {
    dispatch(
      showModal(DESIGN_VERSION_MODAL, {
        type: 'update',
        version,
        loadVersionsList,
        handleRestoreVersion,
        deleteVersion,
      })
    )
  }

  const handleOptions = () => {
    const options = [
      {
        label: (
          <div className="versions-menu">
            <Icon type="text-format" /> <p>Version Details</p>
          </div>
        ),
        onClick: openModal,
      },
      {
        label: (
          <div className="versions-menu">
            <Icon type="restore" /> <p>Restore Version</p>
          </div>
        ),
        onClick: handleRestoreVersion,
      },
      {
        label: (
          <div className="versions-menu">
            <Icon type="trash" /> <p>Delete Version</p>
          </div>
        ),
        onClick: deleteVersion,
      },
    ]

    if (!isEnabled) {
      return options[options.length - 1]
    }

    return options
  }

  const versionNotes = version.notes && (
    <p className="version-item-description">{version.notes}</p>
  )

  const multiMenuTrigger = isLoading ? (
    <Loading />
  ) : (
    <MultiMenuTrigger menu={handleOptions} rowHeight={40} width={220}>
      <IconButton type="more-vert" />
    </MultiMenuTrigger>
  )

  const formattedDate = formatDate(version.created_at)

  return (
    <div className="version-item" key={version.id}>
      <div className="version-item-header">
        <div className="version-item-left">
          <div className="version-item-title">{version.versionName}</div>
          <div className="version-item-date">
            Created by {formatUserName(version.creatorName)} on {formattedDate}
          </div>
        </div>
        <div className="version-item-right">{multiMenuTrigger}</div>
      </div>
      <div className="version-item-description">{versionNotes}</div>
    </div>
  )
}
