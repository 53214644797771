import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import {
  COMPONENT,
  COMPONENT_INSTANCE,
  LIBRARY_COMPONENT,
  LABEL,
  FORM,
  SECTION,
  ELLIPSE,
  SHAPE,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  WEB_VIEW,
  SELECT,
  IMAGE,
  GROUP,
  LIST,
  LINE,
  LOCATION_INPUT,
  LAYOUT_SECTION,
  VIDEO,
  TABLE,
} from '@adalo/constants'

import { getObjectName } from 'utils/naming'
import { getAppComponent } from 'utils/libraries'

import { getLaunchComponentId, getAuthComponentId } from 'ducks/apps'
import {
  selectObjects,
  getComponentId,
  getParentComponent,
} from 'ducks/editor/objects'
import {
  getSelection,
  setSelection,
  resetSelection,
  getComponentView,
} from 'ducks/editor/selection'
import { CurrentTab, getCurrentTab, setCurrentTab } from 'ducks/magicLayout.ts'

import { LayoutSectionPurpose, isSectionElement } from 'utils/layoutSections'

import { PanelSection, PanelItem } from 'components/Shared/Panel'
import ErrorBoundary from 'components/Shared/ErrorBoundary'
import Icon from 'components/Shared/Icon'
import ScreenName from 'components/Editor/RightPanel/ScreenName'
import LaunchComponent from 'components/Editor/LaunchComponent'
import LayoutTab from 'components/Editor/RightPanel/MagicLayout/LayoutTab'
import { getTypeNameForObject } from 'utils/objectNames'

import InspectPanel from '../Inspect'
import LibraryInspect from '../Inspect/Libraries'
import FormInspect from '../Inspect/Forms'
import ScreenControl from '../Inspect/ScreenControl'
import LegacyDataBinding from '../LegacyDataBinding'
import Actions from '../Actions'
import OverflowMenu from './OverflowMenu'
import OverflowMenuSelection from './OverflowMenuSelection'
import Visibility from './Visibility'
import ComponentControl from '../Inspect/ComponentControl'

import './RightPanel.scss'
import PrebuiltLayoutSectionCollapsible from '../Inspect/ScreenControl/PrebuiltLayoutSectionCollapsible'

const NO_GENERIC_BINDING = new Set([
  COMPONENT,
  LIBRARY_COMPONENT,
  FORM,
  LABEL,
  SHAPE,
  SECTION,
  ELLIPSE,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  WEB_VIEW,
  SELECT,
  IMAGE,
  VIDEO,
  GROUP,
  LIST,
  LINE,
  LOCATION_INPUT,
  LAYOUT_SECTION,
  TABLE,
])

export const NO_TYPE_HEADER = new Set([
  LIBRARY_COMPONENT,
  COMPONENT_INSTANCE,
  FORM,
  COMPONENT,
])

class RightPanel extends Component {
  state = {
    visibilityExpanded: false,
    screenTemplateExpanded: false,
    prebuiltLayoutSectionExpanded: false,
  }

  showVisibility = e => {
    e.stopPropagation()
    this.setState({ visibilityExpanded: true })
  }

  hideVisibility = () => {
    this.setState({ visibilityExpanded: false })
  }

  showScreenTemplates = () => {
    this.setState({ screenTemplateExpanded: true })
  }

  onShowPrebuiltLayoutSection = () => {
    this.setState({ prebuiltLayoutSectionExpanded: true })
  }

  onToggleCreatePrebuiltSection = () => {
    this.setState(s => ({
      prebuiltLayoutSectionExpanded: !s.prebuiltLayoutSectionExpanded,
    }))
  }

  handleBack = () => {
    const {
      componentParent,
      setSelection,
      resetSelection,
      componentView,
      history,
      match,
    } = this.props
    const { appId } = match.params

    if (componentView) {
      resetSelection()
      history.push(`/apps/${appId}/screens/add`)

      return
    }

    if (!componentParent) {
      resetSelection()
      history.push(`/apps/${appId}/screen-list`)

      return
    }

    const { id } = componentParent

    return setSelection(id, false)
  }

  getObject = (_objects = null) => {
    let { objects } = this.props

    objects = _objects || objects

    const object = objects?.length === 1 ? objects[0] : null

    return object
  }

  collapseVisibility = () => {
    this.setState({ visibilityExpanded: false })
  }

  collapseScreenTemplate = () => {
    this.setState({ screenTemplateExpanded: false })
  }

  collapsePrebuiltLayoutSection = () => {
    this.setState({ prebuiltLayoutSectionExpanded: false })
  }

  componentDidUpdate(oldProps) {
    const {
      visibilityExpanded,
      screenTemplateExpanded,
      prebuiltLayoutSectionExpanded,
    } = this.state

    const oldObject = this.getObject(oldProps.objects)
    const newObject = this.getObject()

    if (
      visibilityExpanded &&
      (!oldObject || !newObject || oldObject.id !== newObject.id)
    ) {
      this.collapseVisibility()
    }

    if (
      screenTemplateExpanded &&
      (!oldObject || !newObject || oldObject.id !== newObject.id)
    ) {
      this.collapseScreenTemplate()
    }

    if (
      prebuiltLayoutSectionExpanded &&
      (!oldObject || !newObject || oldObject.id !== newObject.id)
    ) {
      this.collapsePrebuiltLayoutSection()
    }
  }

  renderContent() {
    const { match, componentView } = this.props
    const { screenTemplateExpanded } = this.state

    const { appId } = match.params

    const object = this.getObject()

    if (componentView) {
      return <ComponentControl appId={appId} />
    }

    if (object?.type === LIBRARY_COMPONENT) {
      return <LibraryInspect object={object} />
    }

    if (object?.type === FORM) {
      return <FormInspect object={object} />
    }

    if (object?.type === COMPONENT && !object.reusableComponent) {
      return (
        <ScreenControl
          screenTemplateExpanded={screenTemplateExpanded}
          appId={appId}
          object={object}
          onShowScreenTemplates={this.showScreenTemplates}
        />
      )
    }

    return <InspectPanel />
  }

  renderTypeHeader() {
    const object = this.getObject()

    if (!object) {
      return null
    }

    const { type, componentName, libraryName } = object

    switch (type) {
      case LIBRARY_COMPONENT: {
        const component = getAppComponent(null, libraryName, componentName)

        return component?.displayName
      }
    }

    return getTypeNameForObject(object)
  }

  renderVisibility() {
    const object = this.getObject()
    const { visibilityExpanded } = this.state

    if (!object) {
      return null
    }

    return (
      <Visibility
        key={object.id}
        object={object}
        expanded={visibilityExpanded}
        onClose={this.hideVisibility}
        onExpand={this.showVisibility}
      />
    )
  }

  /**
   * Backward-compatibility support for bindings used up until ~2019
   * Ref: https://github.com/AdaloHQ/frontend/commit/0d1394cf9edade89079f1ae532bf91e58ed0f94a
   */
  renderLegacyDataBindings = object => {
    const { componentId, match } = this.props
    const { appId } = match.params

    const NON_LEGACY_OBJECT_TYPES = [LIST, TABLE]

    return (
      <>
        {object &&
        !NON_LEGACY_OBJECT_TYPES.includes(object.type) &&
        (object.dataBinding || !NO_GENERIC_BINDING.has(object.type)) ? (
          <PanelSection title="Data Connections">
            <LegacyDataBinding
              appId={appId}
              componentId={componentId}
              object={object}
            />
          </PanelSection>
        ) : null}
      </>
    )
  }

  renderBack = () => {
    const { componentParent, componentView } = this.props

    let backText = 'All Screens'

    if (componentView) {
      backText = 'All Components'
    }

    if (componentParent) {
      backText = getObjectName(componentParent)
    }

    return (
      <div className="right-panel-back" onClick={this.handleBack}>
        <Icon type="keyboard-back" />
        {backText}
      </div>
    )
  }

  renderActions = object => {
    const { match } = this.props
    const { appId } = match.params

    if (!object) return null

    return (
      <OverflowMenu
        appId={appId}
        object={object}
        onShowVisibility={this.showVisibility}
        onShowScreenTemplates={this.showScreenTemplates}
        onToggleCreatePrebuiltSection={this.onToggleCreatePrebuiltSection}
      />
    )
  }

  renderConfigurationForObject = object => {
    const { prebuiltLayoutSectionExpanded } = this.state
    const {
      match,
      componentId,
      componentParent,
      hasMagicLayout,
      currentTab,
      setCurrentTab,
    } = this.props

    const { appId } = match.params
    const shouldShowMagicLayout = hasMagicLayout && object?.type !== COMPONENT
    const displayTabs = object && shouldShowMagicLayout
    const displaySetupTab = currentTab === CurrentTab.Setup || !displayTabs
    const displayLayoutTab = currentTab === CurrentTab.Layout && displayTabs

    let prebuiltSectionCollapsible = null

    if (prebuiltLayoutSectionExpanded) {
      prebuiltSectionCollapsible = (
        <PrebuiltLayoutSectionCollapsible
          expanded={prebuiltLayoutSectionExpanded}
          appId={appId}
          object={object}
          onClose={this.collapseScreenTemplate}
          onExpand={this.onShowPrebuiltLayoutSection}
        />
      )
    }

    return (
      <>
        {prebuiltSectionCollapsible}
        {displayTabs && (
          <>
            <div className="editor-add-panel-tabs">
              <div
                className={classNames('editor-add-panel-tab', {
                  'editor-add-panel-tab-active':
                    currentTab === CurrentTab.Setup,
                })}
                onClick={() => setCurrentTab(CurrentTab.Setup)}
              >
                SETUP
              </div>
              <div
                className={classNames('editor-add-panel-tab', {
                  'editor-add-panel-tab-active':
                    currentTab === CurrentTab.Layout,
                })}
                onClick={() => setCurrentTab(CurrentTab.Layout)}
              >
                LAYOUT
              </div>
            </div>
          </>
        )}
        {displaySetupTab && (
          <>
            {object && !NO_GENERIC_BINDING.has(object.type) && (
              <ErrorBoundary>
                <PanelSection noPad title="Interactions">
                  <Actions
                    appId={appId}
                    componentId={componentId}
                    object={object}
                  />
                </PanelSection>
              </ErrorBoundary>
            )}
            <ErrorBoundary>
              {this.renderLegacyDataBindings(object)}
            </ErrorBoundary>
            <ErrorBoundary>{this.renderContent()}</ErrorBoundary>
          </>
        )}
        {displayLayoutTab && (
          <ErrorBoundary>
            <LayoutTab
              object={object}
              componentParent={componentParent}
              appId={appId}
            />
          </ErrorBoundary>
        )}
      </>
    )
  }

  render() {
    const { objects, match, componentView } = this.props
    const { appId } = match.params

    let object = null
    if (!objects || objects.length === 0) return null
    if (objects.length === 1) object = objects[0]

    const { isAuthComponent, isLaunchComponent } = this.props

    const name = getObjectName(object)

    const subtitle = () => {
      if (object.type !== COMPONENT) return this.renderTypeHeader()
      if (isAuthComponent) return 'Welcome Screen'
      if (isLaunchComponent) return 'Home Screen'

      return 'Normal Screen'
    }

    let header = (
      <span className="right-panel-multi-selected-summary">
        <span>{objects.length} Components Selected</span>
        <OverflowMenuSelection appId={appId} objects={objects} />
      </span>
    )

    if (componentView) {
      const { type, name } = componentView

      header = (
        <span className="right-panel-selected-object-name">
          <Icon
            type={type === 'Marketplace' ? 'marketplace' : 'lock'}
            title={type}
          />
          <span>{name}</span>
        </span>
      )
    } else if (object) {
      header = (
        <PanelItem
          title={name}
          subtitle={subtitle()}
          renderActions={() => this.renderActions(object)}
          group={object.id}
          fluid
          header
          boxed
        >
          <ScreenName object={object} />
          {object.type === COMPONENT && (
            <LaunchComponent appId={appId} componentId={object.id} />
          )}
        </PanelItem>
      )
    }

    return (
      <>
        {this.renderBack()}
        <div
          className={classNames({
            'multiple-objects-header': !object || componentView,
          })}
        >
          {header}
        </div>
        <ErrorBoundary>{this.renderVisibility()}</ErrorBoundary>

        {this.renderConfigurationForObject(object)}
      </>
    )
  }
}

const mapStateToProps = (state, { match, magicLayout }) => {
  const { appId } = match.params
  const selection = getSelection(state)
  let componentId = null
  const hasMagicLayout = magicLayout

  if (selection.length === 0) {
    return {}
  }

  if (selection.length === 1) {
    componentId = getComponentId(state, selection[0])
  }

  let componentParent = getParentComponent(state, selection[0])

  if (
    isSectionElement(componentParent) &&
    componentParent?.purpose === LayoutSectionPurpose.LAYOUT_HELPER
  ) {
    componentParent = getParentComponent(state, componentParent.id)
  }

  return {
    componentId,
    componentParent,
    objects: selectObjects(state, selection),
    isLaunchComponent: componentId === getLaunchComponentId(state, appId),
    isAuthComponent: componentId === getAuthComponentId(state, appId),
    hasMagicLayout,
    componentView: getComponentView(state),
    currentTab: getCurrentTab(state),
  }
}

export default withRouter(
  connect(mapStateToProps, {
    setSelection,
    resetSelection,
    setCurrentTab,
  })(RightPanel)
)
