import { update } from '@adalo/utils'
import { isEmpty, has, set, unset } from 'lodash'
import {
  ContainerAttributes,
  EditorObject,
  LayoutAttributes,
} from 'utils/responsiveTypes'
import DeviceType from 'ducks/editor/types/DeviceType'
import { DeviceVisibility } from './types'
import getObject from '../objects/helpers/getObject'
import { ObjectList } from '../types/ObjectList'
import { ObjectPathMap } from '../types/ObjectPathMap'
import { ConditionResolver } from './conditions'
import { amendChangesWithDeviceSpecificObject, getParent } from './utils'

const MINMAX_WIDTH_CONSTRAINTS = new Set<keyof Partial<LayoutAttributes>>([
  'minWidth',
  'maxWidth',
  'minWidthEnabled',
  'maxWidthEnabled',
  'left',
  'fixedLeft',
  'right',
  'fixedRight',
])

const updateDeviceLayoutForObject = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  objectId: string,
  device: DeviceType,
  resolver: ConditionResolver,
  newChanges: Partial<
    LayoutAttributes & ContainerAttributes & DeviceVisibility
  >,
  removeProps: Array<string> = []
): ObjectList => {
  const object = getObject(list, pathMap, objectId)
  // merges incoming newChanges on top of existing device-specific properties
  // TODO - we may need to sound-check this, as there could be a case where
  // we wish to keep the existing prop and discard the incoming change
  const changes = amendChangesWithDeviceSpecificObject(
    object,
    device,
    newChanges
  )

  // sanity-check(device-parent): we're not comparing device-specific values here
  // therefore we don't need parent object with device-specific values
  const parentProvider = (child: EditorObject) => {
    return getParent(list, pathMap, child.id)
  }

  // prettier-ignore
  const deviceSpecificChanges = {} as Partial<LayoutAttributes & ContainerAttributes & DeviceVisibility>
  // prettier-ignore
  if ('x' in changes && resolver.requiresDeviceProp('xOffset', object, parentProvider)) {
    deviceSpecificChanges.x = changes.x
  }
  // prettier-ignore
  if ('y' in changes && resolver.requiresDeviceProp('yOffset', object, parentProvider)) {
    deviceSpecificChanges.y = changes.y
  }
  // prettier-ignore
  if ('width' in changes && resolver.requiresDeviceProp('width', object, parentProvider)) {
    deviceSpecificChanges.width = changes.width
  }
  // prettier-ignore
  if ('height' in changes && resolver.requiresDeviceProp('height', object, parentProvider)) {
    deviceSpecificChanges.height = changes.height
  }
  // prettier-ignore
  if ('responsivity' in changes && resolver.requiresDeviceProp('horizontalConstraints', object, parentProvider)) {
    deviceSpecificChanges.responsivity = changes.responsivity
  }
  // prettier-ignore
  if ('pushGraph' in changes && resolver.requiresDeviceProp('pushGraph', object, parentProvider)) {
    deviceSpecificChanges.pushGraph = changes.pushGraph
  }
  // prettier-ignore
  if ('positioning' in changes && resolver.requiresDeviceProp('positioning', object, parentProvider)) {
    deviceSpecificChanges.positioning = changes.positioning
  }

  // require one, require all
  const requiresWidthConstraint = resolver.requiresDeviceProp(
    'left', // picked from MINMAX_WIDTH_CONSTRAINTS
    object,
    parentProvider
  )
  for (const prop of MINMAX_WIDTH_CONSTRAINTS) {
    if (prop in changes && requiresWidthConstraint === true) {
      set(deviceSpecificChanges, prop, changes[prop])
    }
  }

  const newObject = {
    ...object,
    ...(!has(object, 'children') && { children: [] }),
    // current deviceVisibility will be included in the `object` spread
    // by the current typing of deviceVisibility, if specified, every deviceType
    // must be present within it
    ...('deviceVisibility' in changes &&
      resolver.requiresDeviceProp('visibility', object, parentProvider) && {
        deviceVisibility: {
          ...changes.deviceVisibility,
        },
      }),
  }

  if (isEmpty(deviceSpecificChanges)) {
    // in effect, set `[device]: undefined`, as opposed to `[device]: {}`
    // where there are no properties specific to that device
    delete newObject[device]
  } else {
    newObject[device] = deviceSpecificChanges

    // We unset the margin properties that are not required for the current device
    // Documentation:
    // src/ducks/editor/instructions/CONCEPTS.md
    // Refer to section "Margins"

    for (const prop of removeProps) {
      unset(newObject, [device, prop])
    }
  }

  return update(list, pathMap[object.id], newObject) as ObjectList
}

export default updateDeviceLayoutForObject
