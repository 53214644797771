import { adaloBackendAxios } from 'utils/io/http/axios'

const RESET_SEARCH = Symbol('RESET_SEARCH')
const SEARCH = 'SEARCH_USERS'

const INITIAL_STATE = []

export default (state = INITIAL_STATE, action) => {
  if (action.type === RESET_SEARCH) {
    return INITIAL_STATE
  }

  if (action.type === `${SEARCH}_FULFILLED`) {
    return action.payload
  }

  return state
}

export const searchUsers = (email, appId) => ({
  type: SEARCH,
  payload: adaloBackendAxios
    .get(`/users/search?email=${encodeURIComponent(email)}&appId={appId}`)
    .then(resp => resp.data),
})
