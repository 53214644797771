import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { actionTypes, transitions } from '@adalo/constants'
import { withRouter } from 'react-router-dom'

import {
  showModal,
  NEW_SCREEN_MODAL,
  NEW_TABLE,
  CUSTOM_ACTION_MODAL,
  CHOOSE_ZAP_MODAL,
  FEATURE_TEMPLATE_MODAL,
} from 'ducks/editor/modals'
import { getFeatureFlag } from 'ducks/featureFlags'
import { getScreens, getUpgraded, getApp } from 'ducks/apps'
import { getDatasources } from 'ducks/apps/datasources'
import { getContextualObjects } from 'ducks/recommender'
import {
  createCustomAction,
  deleteCustomAction,
  getCustomActions,
} from 'ducks/customActions'
import { getTrialState, isFeatureEnabled } from 'ducks/organizations'
import { getThirdPartyApiKeyFor } from 'ducks/thirdPartyApiKeys'

import EmptyState from 'components/Shared/EmptyState'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import { generateHandleTrial } from 'hooks/useHandleTrialOrUpgrade'
import { findTemplate, createChatGPTCustomAction } from './Form/template'

import { THEMES } from '../../../constants'

import getMenu from './getMenu'

class ActionsEmptyState extends Component {
  getMenu = () => {
    return getMenu(this.props)
  }

  handleSelect = async value => {
    const {
      onCreate,
      showModal,
      paying,
      appId,
      handleTrial,
      areCustomActionsEnabled,
      trialState,
      objectId,
    } = this.props

    if (typeof value === 'object') {
      if (value.target === 'new') {
        let target
        let transition
        try {
          const { value } = await showModal(NEW_SCREEN_MODAL)
          target = value.target
          transition = value.transition
        } catch (e) {
          return
        }

        try {
          value = {
            target,
            transition: transitions[transition] ?? null,
          }
        } catch (err) {
          return
        }
      }

      if (value.target === 'createNewCollection') {
        try {
          value = {
            target: (await showModal(NEW_TABLE)).value,
          }

          return onCreate({
            actionType: actionTypes.CREATE_OBJECT,
            options: value.target,
          })
        } catch (err) {
          return null
        }
      }

      if (value.target === 'addFeatureTemplate') {
        return showModal(FEATURE_TEMPLATE_MODAL, {
          appId,
          linkingObjectId: objectId,
        })
      }

      if (value.target === 'createNewCustomAction') {
        if (!areCustomActionsEnabled) {
          const callbackOptions = {
            onCreate,
          }

          handleTrial(trialState, paying, callbackOptions)

          return
        }

        try {
          let customActionId

          if (value?.type === 'chatgpt') {
            const {
              createCustomAction,
              customActions,
              openaiApiKey,
              history,
              match: { params },
            } = this.props

            if (!openaiApiKey) {
              history.push(`/apps/${params.appId}/app-settings?active=apikeys`)

              return alert(
                'To use this Custom Action, go to Settings > API Keys in the sidebar and add your OpenAI API Key.'
              )
            }

            const chatGPTTemplate = createChatGPTCustomAction(openaiApiKey)
            const template = findTemplate(customActions)

            customActionId = template
              ? template.id
              : (await createCustomAction(appId, chatGPTTemplate))?.value?.id
          } else {
            customActionId = (await showModal(CUSTOM_ACTION_MODAL)).value
          }

          return onCreate({
            actionType: actionTypes.CUSTOM,
            options: { customActionId },
          })
        } catch (err) {
          return null
        }
      }

      if (value?.actionType === actionTypes.CUSTOM) {
        return onCreate({
          actionType: actionTypes.CUSTOM,
          options: { customActionId: value.options.id },
        })
      }

      if (value?.actionType === actionTypes.AUTHENTICATE_EXTERNAL) {
        return onCreate({
          actionType: actionTypes.AUTHENTICATE_EXTERNAL,
          options: { customActionId: value.options.id },
        })
      }

      if (value?.actionType === actionTypes.SIGNUP_EXTERNAL) {
        return onCreate({
          actionType: actionTypes.SIGNUP_EXTERNAL,
          options: { customActionId: value.options.id },
        })
      }

      if (value?.actionType) return onCreate(value)

      if (value?.target === 'chooseZap') {
        return showModal(CHOOSE_ZAP_MODAL, {
          zapType: value?.type,
          appId,
        })
      }

      return onCreate({
        actionType: actionTypes.NAVIGATE,
        options: value,
      })
    }

    onCreate({ actionType: value })
  }

  render() {
    const { children } = this.props

    return (
      <div className="empty-action-items">
        <EmptyState bordered>No Actions</EmptyState>
        <MultiMenuTrigger
          menu={this.getMenu()}
          onSelect={this.handleSelect}
          rowHeight={40}
          menuTheme={THEMES.ACTION}
          childWidth={240}
        >
          {children || (
            <Button>
              <span>Add Interaction</span>
              <Icon type="expand-vertical" />
            </Button>
          )}
        </MultiMenuTrigger>
      </div>
    )
  }
}

const mapStateToProps = (
  state,
  { appId, componentId, objectId, reference }
) => {
  const googleApiKey = getThirdPartyApiKeyFor(state, appId, 'google')
  const googleApiKeyIsValid = googleApiKey && googleApiKey.isValid

  const openaiApiKey = getThirdPartyApiKeyFor(state, appId, 'openai')?.key

  const { trialState } = getTrialState(state)

  const contextOptions = getContextualObjects(
    state,
    appId,
    componentId,
    objectId,
    undefined,
    undefined,
    reference
  )()

  return {
    areCustomActionsEnabled: isFeatureEnabled(state, 'customIntegrations'),
    isGeolocationEnabled: isFeatureEnabled(state, 'geolocation'),
    trialState,
    app: getApp(state, appId),
    screens: getScreens(state, appId),
    datasources: getDatasources(state, appId),
    contextOptions,
    customActions: getCustomActions(state, appId),
    paying: getUpgraded(state, appId) || trialState === 'during',
    googleApiKeyIsValid,
    openaiApiKey,
    hasFeatureTemplatesAction: getFeatureFlag(state, 'hasFeatureTemplates'),
  }
}

const mapDispatchToProps = (dispatch, { appId }) => {
  const handleTrial = (trialState, isPaidOrg, callbackOptions) => {
    const handle = generateHandleTrial({
      appId,
      trialState,
      type: 'customActions',
      dispatch,
      callbackOptions,
      isPaidOrg,
    })

    handle()
  }

  return {
    handleTrial,
    showModal: (...props) => dispatch(showModal(...props)),
    createCustomAction: (...props) => dispatch(createCustomAction(...props)),
    deleteCustomAction: (...props) => dispatch(deleteCustomAction(...props)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsEmptyState)
)
