import React, { Component } from 'react'
import classNames from 'classnames'
import { listTypes } from '@adalo/constants'
import Icon from '../../Shared/Icon'

import './ListTypeControl.css'

export default class ListTypeControl extends Component {
  handleChange = value => {
    const { name, onChange } = this.props

    onChange({ [name]: value })
  }

  render() {
    const { value } = this.props

    return (
      <div className="list-type-control">
        <ListTypeOption
          value={listTypes.DEFAULT}
          selected={!value || value === listTypes.DEFAULT}
          label="Rows"
          icon="rows"
          onChange={this.handleChange}
        />
        <ListTypeOption
          value={listTypes.GRID}
          selected={value === listTypes.GRID}
          label="Grid"
          icon="grid"
          onChange={this.handleChange}
        />
        {/*
        <ListTypeOption
          value={listTypes.HORIZONTAL}
          selected={value === listTypes.HORIZONTAL}
          label="Columns"
          icon="columns"
          onChange={this.handleChange}
        />
        */}
      </div>
    )
  }
}

class ListTypeOption extends Component {
  handleClick = () => {
    const { onChange, value } = this.props

    onChange(value)
  }

  render() {
    const { selected, label, icon } = this.props

    return (
      <div
        className={classNames('list-type-option', {
          'list-type-option-selected': selected,
        })}
        onClick={this.handleClick}
      >
        <Icon type={icon} />
        <p className="list-type-option-label">{label}</p>
      </div>
    )
  }
}
