import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectObject } from '../../../ducks/editor/objects'
import LayerItem, { EMPTY_ARRAY } from './Item'

class DragLayer extends Component {
  render() {
    const { objects, offset, position } = this.props

    const styles = {
      left: `${position[0] - offset[0]}px`,
      top: `${position[1] - offset[1]}px`,
    }

    return (
      <div className="layers-drag-items" style={styles}>
        {objects.map((obj, i) => (
          <LayerItem
            object={obj}
            hideDropTargets
            key={obj.id}
            path=""
            selection={EMPTY_ARRAY}
            hoverSelection={EMPTY_ARRAY}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state, { objectIds }) => ({
  objects: objectIds.map(id => selectObject(state, id)).filter(obj => obj),
})

export default connect(mapStateToProps)(DragLayer)
