import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  showModal as showUpgradeModal,
  setPaymentRouteType,
} from 'ducks/trialWarning'
import { createApiKey } from 'ducks/apikeys'
import {
  showModal,
  FREE_TRIAL_MODAL,
  CUSTOM_ACTION_MODAL,
} from 'ducks/editor/modals'
import { actionTypes } from '@adalo/constants'
import { openAccordion } from 'ducks/accordions'

import { newActionItem } from 'utils/actions'
import { BEFORE } from '../constants'

const onExternalCollection = ({ history, appId }) => {
  history.push(`/apps/${appId}/data/new-external-collection`)
}

const onApi = async ({ dispatch, appId, appApiKey }) => {
  if (!appApiKey) {
    await dispatch(createApiKey(appId))
  }
}

const onCustomAction = async ({
  dispatch,
  onAdd,
  fields,
  firstLinkActionItemIndex,
  onCreate,
  noCreate = false,
}) => {
  if (noCreate) return

  let options
  try {
    const result = await dispatch(showModal(CUSTOM_ACTION_MODAL))
    options = result.value
  } catch (e) {
    // dialog was closed. Nothing to do here
    return
  }

  const defaults = {
    options: { customActionId: options },
    actionType: actionTypes.CUSTOM,
  }

  if (onCreate) {
    onCreate(defaults)

    return
  }

  const action = newActionItem(defaults)
  dispatch(openAccordion('action-item', action.id))

  if (onAdd) {
    onAdd(action)
  }

  if (fields) {
    const newIndex = firstLinkActionItemIndex
    fields.splice(newIndex, 0, action)
  }
}

export const generateHandleTrial = ({
  appId,
  trialState,
  type,
  dispatch,
  callbackOptions = {},
  isPaidOrg = false,
}) => {
  const openUpgradeModal = async () => {
    await dispatch(setPaymentRouteType('upgrade'))
    await dispatch(showUpgradeModal())
  }

  const openModal = async () => {
    try {
      const { value } = await dispatch(
        showModal(FREE_TRIAL_MODAL, { type, appId })
      )

      if (!value) return
    } catch (e) {
      // bail if the trial modal throws a rejection (ie closed without starting the trial)
      return
    }

    if (type === 'externalCollection') {
      const { history } = callbackOptions

      onExternalCollection({
        history,
        appId,
      })
    } else if (type === 'api') {
      const { appApiKey } = callbackOptions

      onApi({
        dispatch,
        appId,
        appApiKey,
      })
    } else if (type === 'customActions') {
      const { onAdd, fields, firstLinkActionItemIndex, onCreate, noCreate } =
        callbackOptions

      onCustomAction({
        dispatch,
        onAdd,
        fields,
        firstLinkActionItemIndex,
        onCreate,
        noCreate,
      })
    }
  }

  const handleTrial = async () => {
    try {
      const shouldOpenTrialModal =
        trialState === BEFORE && !isPaidOrg && type !== 'externalDatabase'

      if (shouldOpenTrialModal) {
        openModal()
      } else {
        openUpgradeModal()
      }
    } catch (err) {
      // modal closed
      console.error("modal closed, here's the error:", err)
    }
  }

  return handleTrial
}

const useHandleTrialOrUpgrade = ({
  appId,
  trialState,
  type,
  callbackOptions = {},
  isPaidOrg = false,
}) => {
  const dispatch = useDispatch()
  callbackOptions.history = useHistory()

  return generateHandleTrial({
    dispatch,
    appId,
    trialState,
    type,
    callbackOptions,
    isPaidOrg,
  })
}

export default useHandleTrialOrUpgrade
