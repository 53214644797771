import { SECTION } from '@adalo/constants'
import { DEVICE_TYPES } from 'ducks/editor/positioning'
import { isShared, hasDevicePosition } from 'utils/positioning'
import getDeviceObject from 'utils/getDeviceObject'

const getPositionDiffs = (group, oldGroup, deviceType) => {
  if (deviceType) {
    const { x: groupX, y: groupY } = getDeviceObject(group, deviceType)
    const { x: oldGroupX, y: oldGroupY } = getDeviceObject(oldGroup, deviceType) // prettier-ignore

    return [groupX - oldGroupX, groupY - oldGroupY]
  }

  return [group.x - oldGroup.x, group.y - oldGroup.y]
}

/**
 *
 * @param {import('utils/responsiveTypes').EditorObject} group
 * @param {import('utils/responsiveTypes').EditorObject} oldGroup
 * @param {import('ducks/editor/types/DeviceType').DeviceType | undefined} deviceType
 * @param {boolean?} updateShared
 * @returns {import('utils/responsiveTypes').EditorObject}
 */
const translateChildren = (
  group,
  oldGroup,
  deviceType = undefined,
  attemptTranslateAllDevices = true
) => {
  if (!group?.children?.length) {
    return group
  }

  const [diffX, diffY] = getPositionDiffs(group, oldGroup, deviceType)

  if (!diffX && !diffY) {
    return group
  }

  return {
    ...group,
    children: group.children.map(child => {
      let updatedPosition = {
        x: child.x + diffX,
        y: child.y + diffY,
      }

      if (attemptTranslateAllDevices) {
        for (const device of DEVICE_TYPES) {
          const { x: deviceX, y: deviceY } = getDeviceObject(child, device)

          const parentHasDevicePosition =
            hasDevicePosition(group, device) && group.type === SECTION
          const isSharedWithDevicePosition =
            isShared(group, device) && hasDevicePosition(child, device)

          if (parentHasDevicePosition || isSharedWithDevicePosition) {
            updatedPosition[device] = {
              ...child[device],
              x: deviceX + diffX,
              y: deviceY + diffY,
            }
          }
        }
      } else {
        const { x: deviceX, y: deviceY } = getDeviceObject(child, deviceType)

        // TODO: (Tom) updateDeviceLayoutForObject() here?
        updatedPosition = {
          [deviceType]: {
            ...child[deviceType],
            x: deviceX + diffX,
            y: deviceY + diffY,
          },
        }
      }

      return translateChildren(
        {
          ...child,
          ...updatedPosition,
        },
        child,
        deviceType,
        attemptTranslateAllDevices
      )
    }),
  }
}

export default translateChildren
