import {
  DeviceType,
  resizingOptions,
  responsivePositioningOptions,
} from '@adalo/constants'
import {
  ContainerAttributes,
  EditorObject,
  LayoutAttributes,
  Responsivity,
} from 'utils/responsiveTypes'

const { TOP, LEFT, RIGHT, CENTER, LEFT_AND_RIGHT, FIXED_ON_SCROLL } =
  responsivePositioningOptions
const { FIXED, SCALES_WITH_PARENT } = resizingOptions

// Layout Settings
export const FIXED_LEFT = 'FIXED_LEFT' as const
export const FIXED_CENTER = 'FIXED_CENTER' as const
export const FIXED_RIGHT = 'FIXED_RIGHT' as const
export const SCALES_RELATIVE = 'SCALES_RELATIVE' as const
export const SCALES_FIXED = 'SCALES_FIXED' as const

type LayoutSettings =
  | typeof FIXED_LEFT
  | typeof FIXED_CENTER
  | typeof FIXED_RIGHT
  | typeof SCALES_FIXED
  | typeof SCALES_RELATIVE

export const translateLibraryLayoutValueToResponsivity = (
  value: LayoutSettings | string,
  sticky = false
): Responsivity => {
  const baseVerticalResponsivity: Responsivity = {
    verticalPositioning: TOP,
    verticalScaling: FIXED,
  }

  if (sticky) baseVerticalResponsivity.verticalPositioning = FIXED_ON_SCROLL

  switch (value) {
    case FIXED_LEFT:
      return {
        ...baseVerticalResponsivity,
        horizontalPositioning: LEFT,
        horizontalScaling: FIXED,
      }
    case FIXED_CENTER:
      return {
        ...baseVerticalResponsivity,
        horizontalPositioning: CENTER,
        horizontalScaling: FIXED,
      }
    case FIXED_RIGHT:
      return {
        ...baseVerticalResponsivity,
        horizontalPositioning: RIGHT,
        horizontalScaling: FIXED,
      }
    case SCALES_FIXED:
      return {
        ...baseVerticalResponsivity,
        horizontalPositioning: LEFT_AND_RIGHT,
        horizontalScaling: SCALES_WITH_PARENT,
      }
    default:
    case SCALES_RELATIVE:
      return {
        ...baseVerticalResponsivity,
        horizontalPositioning: CENTER,
        horizontalScaling: SCALES_WITH_PARENT,
      }
  }
}

const handleLibraryDeviceLayout = (
  layoutValue: LayoutSettings | string
): Partial<LayoutAttributes & ContainerAttributes> => {
  return {
    responsivity: translateLibraryLayoutValueToResponsivity(layoutValue),
  }
}

export type LibraryDeviceLayout = Partial<{
  sticky: boolean
  shared: string
  [DeviceType.MOBILE]: string
  [DeviceType.TABLET]: string
  [DeviceType.DESKTOP]: string
}>

export const getLibraryDeviceLayout = (
  obj: LibraryDeviceLayout
): Partial<EditorObject> => {
  const layout: Partial<EditorObject> = {}

  if (obj) {
    const acceptedDeviceLayoutKeys = ['mobile', 'desktop', 'tablet'] as const
    const deviceLayoutKeys = Object.keys(obj).filter(key =>
      acceptedDeviceLayoutKeys.some(value => key === value)
    ) as Partial<['mobile', 'desktop', 'tablet']>

    if (deviceLayoutKeys.length > 0) {
      layout.shared = {
        desktop: true,
        tablet: true,
        mobile: true,
      }

      for (const device of deviceLayoutKeys) {
        if (!device) continue

        const value = obj[device]

        // verify that the device value provided is a string
        if (typeof value !== 'string') continue

        layout.shared[device] = false
        layout[device] = handleLibraryDeviceLayout(value)
      }
    }
  }

  return layout
}
