import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const Panning = ({ objects, zoom, setZoom, handlePanning }) => {
  const panning = React.createRef()
  const [isDragging, setIsDragging] = useState(false)

  const onMouseDown = e => {
    return setIsDragging(true)
  }

  const onMouseUp = e => {
    return setIsDragging(false)
  }

  const onMouseMove = e => {
    e.stopPropagation()
    if (!isDragging) return null

    return handlePanning(e)
  }

  const props = {
    id: 'panning',
    ref: panning,
    onMouseDown,
    onMouseUp,
    onMouseMove,
  }

  return ReactDOM.createPortal(React.createElement('div', props), document.body)
}

Panning.propTypes = {
  objects: PropTypes.array.isRequired,
  setZoom: PropTypes.func.isRequired,
}

export default Panning
