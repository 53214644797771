import React from 'react'

import { THEMES } from 'constants.js'

import ListFilters from '../Libraries/ListFilters'
import MenuControl from '../Libraries/MenuControl'

export const FilterControl = ({
  filterOptions,
  value,
  getLabel,
  handleChangeFilter,
  filterComparator,
  handleChangeSecondaryFilter,
  object,
  appId,
  componentId,
}) => {
  return (
    <>
      <MenuControl
        options={filterOptions}
        name="filter"
        displayName="Filter"
        value={value}
        getLabel={getLabel}
        onChange={handleChangeFilter}
        comparator={filterComparator}
        menuTheme={THEMES.DATA}
      />
      <ListFilters
        onChange={handleChangeSecondaryFilter}
        binding={value}
        object={object}
        appId={appId}
        componentId={componentId}
      />
    </>
  )
}
