import React from 'react'
import classNames from 'classnames'

// components
import ComponentsAccordion from 'components/Editor/ComponentsAccordion'
import CalloutCard from 'components/Shared/CalloutCard'

import './tab.scss'

const TabItems = ({
  items,
  emptyMessage,
  className,
  renderItem,
  isSearching,
  showMore,
}) => {
  const renderCalloutCard = item => {
    const { callout } = item
    if (isSearching && items.length > 0) return null

    return <CalloutCard {...callout} />
  }

  if (items.length === 0) {
    return <div className="editor-add-no-results">{emptyMessage}</div>
  }

  return items.map(item => {
    const { title, options, callout } = item

    // non-default section
    if (title) {
      return (
        <div key={title} className="editor-add-section">
          <div className="section-header-content">
            <h2>{title}</h2>

            {callout && renderCalloutCard(item)}
          </div>

          {options?.length > 0
            ? options.map(sectionItem => {
                const { children, label } = sectionItem

                const itemProps = {
                  title: label,
                  options: children,
                  renderItem,
                  emptyCallout: sectionItem?.emptyCallout,
                }

                return (
                  <ComponentsAccordion
                    key={label}
                    className={classNames(
                      'editor-add-panel-accordion',
                      className
                    )}
                    {...itemProps}
                  />
                )
              })
            : null}
        </div>
      )
    }

    return (
      <ComponentsAccordion
        key={item.label}
        className={classNames('editor-add-panel-accordion', className)}
        title={item.label}
        options={item.children}
        renderItem={renderItem}
        emptyCallout={item?.emptyCallout}
        maxItems={item?.maxItems}
        showMore={showMore}
      />
    )
  })
}

export default TabItems
