import { moveElement } from '../instructions'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import type { ActionOptions, ScreenHandlerOptions } from './types'

export interface AlignToScreenVerticalCenterInstruction {
  operation: 'alignToScreenVerticalCenter'
  options: ActionOptions
}

export const alignToScreenVerticalCenterHandler = ({
  bounds,
  screenBounds,
  objects,
}: ScreenHandlerOptions): LayoutInstruction[] => {
  const screenBoundsVerticalCenter =
    screenBounds.top + (screenBounds.bottom - screenBounds.top) / 2

  const delta =
    screenBoundsVerticalCenter - (bounds.bottom - bounds.top) / 2 - bounds.top

  return objects.flatMap(object => [
    // TODO (michael-adalo): see notes in `alignToSelectionVerticalCenter.ts` for why we're doing this
    moveElement(object.id, object.x, object.y),
    moveElement(object.id, object.x, object.y + delta),
  ])
}

const alignToScreenVerticalCenter = (
  objectIds: string[]
): AlignToScreenVerticalCenterInstruction => ({
  operation: 'alignToScreenVerticalCenter',
  options: { objectIds },
})

export default alignToScreenVerticalCenter
