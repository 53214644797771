import React from 'react'
import { createPath } from '@adalo/utils'

import { scaleValue } from '../../../../utils/zoom'
import BaseShape from '../BaseShape'
import Shadow from '../Shadow'

export default class Shape extends BaseShape {
  getPathData() {
    const { width, height, points, isClosed, zoom } = this.props

    const path = createPath(points, isClosed, pt => {
      if (!pt) {
        return null
      }

      return [scaleValue(pt[0] * width, zoom), scaleValue(pt[1] * height, zoom)]
    })

    return path
  }

  doubleClickHandler() {
    if (this.isSelected()) {
      return this.props.onEdit()
    }

    return super.doubleClickHandler()
  }

  renderPath() {
    return (
      <path
        {...this.getBorderProps()}
        fill={this.getBackgroundColor()}
        d={this.getPathData()}
      />
    )
  }

  render() {
    const {
      xScaled,
      yScaled,
      points,
      zoom,
      hideShadows,
      shadow,

      x,
      y,
      width,
      height,
      left,
      right,
      minWidth,
      maxWidth,
      ...styles
    } = this.props

    return (
      <g
        className="shape"
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        style={styles}
        transform={`translate(${xScaled} ${yScaled})`}
      >
        {shadow && shadow.enabled && !hideShadows ? (
          <Shadow {...this.getShadowProps()}>{this.renderPath()}</Shadow>
        ) : null}
        {this.renderPath()}
      </g>
    )
  }
}
