import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '../../Shared/Icon'
import Button from '../../Shared/Button'

class RightPanelCollapsible extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    visibilityControl: PropTypes.node.isRequired,
    collapsedText: PropTypes.string.isRequired,
    collapsedClassName: PropTypes.string,
    collapsibleClassName: PropTypes.string,
    iconType: PropTypes.string,
    expanded: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitLabel: PropTypes.string,
  }

  static defaultProps = {
    submitLabel: 'Done',
  }

  handleSubmit = () => {
    const { onSubmit, onClose } = this.props

    if (onSubmit) {
      const maybePromise = onSubmit()

      if (maybePromise && maybePromise.then) {
        maybePromise.then(() => {
          onClose()
        })
      } else {
        onClose()
      }
    } else {
      onClose()
    }
  }

  render() {
    const {
      children,
      collapsibleClassName,
      collapsedClassName,
      collapsedText,
      visible,
      visibilityControl,
      iconType,
      expanded,
      onExpand,
      submitLabel,
    } = this.props

    if (!expanded) {
      const collapsedClassNames = classNames(
        'right-panel-collapsible-collapsed',
        collapsedClassName
      )

      return visible ? (
        <div className={collapsedClassNames} onClick={onExpand}>
          {iconType ? <Icon type={iconType} /> : null}
          <span>{collapsedText}</span>
        </div>
      ) : null
    }

    const collapsibleClassNames = classNames(
      'right-panel-collapsible',
      collapsibleClassName
    )

    return (
      <div className={collapsibleClassNames}>
        {visibilityControl}
        {visible ? (
          <div className="right-panel-collapsible-options">{children}</div>
        ) : null}
        <div className="right-panel-collapsible-done">
          <Button square small onClick={this.handleSubmit}>
            {submitLabel}
          </Button>
        </div>
      </div>
    )
  }
}

export default RightPanelCollapsible
