import { EditorObject } from 'utils/responsiveTypes'
import { COMPONENT } from '@adalo/constants'
import {
  SCREEN_MIN_HEIGHT,
  SCREEN_MIN_WIDTH,
} from '../instructions/resizeScreen'

/**
 * Checks current changes against minimum screen size and adjusts the changes to
 * prevent undesirable shifting of the screen when it cannot be made any smaller.
 */
const coerceScreenResizeToMinimums = (
  oldObject: EditorObject,
  changes: Partial<EditorObject>
): Partial<EditorObject> => {
  if (oldObject?.type !== COMPONENT) {
    return changes
  }

  let updatedChanges = changes

  // Prevent screens from shifting right when trying to resize from the left
  if ('x' in changes && 'width' in changes) {
    const movingRight = changes.x > oldObject.x
    const narrowing = changes.width < oldObject.width

    if (movingRight && narrowing && changes.width < SCREEN_MIN_WIDTH) {
      const blockedDeltaX = changes.width - SCREEN_MIN_WIDTH

      updatedChanges = {
        ...updatedChanges,
        width: SCREEN_MIN_WIDTH,
        x: changes.x + blockedDeltaX,
      }
    }
  }

  // Prevent screens from shifting down when trying to resize from the top
  if ('y' in changes && 'height' in changes) {
    const movingDown = changes.y > oldObject.y
    const shortening = changes.height < oldObject.height

    if (movingDown && shortening && changes.height < SCREEN_MIN_HEIGHT) {
      const blockedDeltaY = changes.height - SCREEN_MIN_HEIGHT

      updatedChanges = {
        ...updatedChanges,
        height: SCREEN_MIN_HEIGHT,
        y: changes.y + blockedDeltaY,
      }
    }
  }

  return updatedChanges
}

export default coerceScreenResizeToMinimums
