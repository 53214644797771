import React, { Component } from 'react'
import { PaddingDragHandle } from './PaddingDragHandle'

export default class ResizeHandle extends Component {
  static defaultProps = {
    x: 0,
    y: 0,
    height: 14,
    width: 14,
  }

  render() {
    const {
      invisible,
      cursor,
      x,
      y,
      onMouseDown,
      height,
      width,
      position,
      extraHandleStyle,
      onMouseEnter,
      onMouseLeave,
    } = this.props

    const translateX = () => {
      if (position === 'top' || position === 'bottom') return 0

      if (extraHandleStyle) {
        if (position === 'left') {
          return x - 12
        } else if (position === 'right') {
          return x - 4
        }
      }

      return x - 7
    }

    const translateY = () => {
      if (position === 'left' || position === 'right') return 0

      if (extraHandleStyle) {
        if (position === 'top') {
          return y - 12
        } else if (position === 'bottom') {
          return y - 4
        }
      }

      return y - 7
    }

    return (
      <g
        transform={`translate(${translateX()}, ${translateY()})`}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {invisible ? null : (
          <circle
            className="resize-handle"
            r={4}
            cx={width / 2}
            cy={height / 2}
          />
        )}
        <PaddingDragHandle {...this.props} />
        <rect
          className="resize-click-target"
          width={width}
          height={height}
          style={{
            cursor: `${cursor}-resize`,
          }}
        />
      </g>
    )
  }
}
