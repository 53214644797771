import React from 'react'

function GroupWrapper(props) {
  const { children, object, editorResizingProps } = props || {}

  if (!editorResizingProps) {
    return children
  }

  const { relativeWidth, relativeX } = editorResizingProps
  const groupX = relativeX / 2
  const groupWidth = relativeWidth ? `100%` : object.width / 2

  return (
    <svg x={groupX} y="0" width={groupWidth} height="100%" overflow="visible">
      {children}
    </svg>
  )
}

export default GroupWrapper
