import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { getTableId } from '@adalo/utils'

import { getCurrentAppId } from 'ducks/editor/objects'
import {
  addTableField,
  getTableIds,
  createTable,
  getDatasources,
  updateTableField,
} from 'ducks/apps/datasources'

import { pluralize } from 'utils/strings'

import Form from './Form'

import './NewTable.scss'

class NewTableModal extends Component {
  handleSubmit = async values => {
    const { createTable, appId, reset, datasourceId, onSuccess } = this.props

    // TODO: not great to set the id in the component...
    const tableId = getTableId()

    // create the table
    await createTable(
      appId,
      datasourceId,
      values.tableName,
      tableId,
      values.columns
    )

    const { tables } = this.props
    const table = tables.find(table => table.id === tableId)

    const fields = table.fields

    for (const fieldId in fields) {
      if (fieldId) {
        const field = fields[fieldId]

        if (typeof field.type === 'object') {
          const { type } = field.type

          if (type === 'hasMany') {
            const { addTableField, updateTableField } = this.props

            const hasManyField = {
              name: pluralize(values.tableName),
              type: { type: 'belongsTo', datasourceId, tableId },
            }

            await addTableField(
              appId,
              datasourceId,
              field.type.tableId,
              hasManyField,
              fieldId
            )

            const reciprocal = {
              tableId: field.type.tableId,
              datasourceId,
              fieldId,
            }

            field.reciprocal = reciprocal

            await updateTableField({
              appId,
              datasourceId,
              tableId,
              field,
              fieldId,
            })
          }
        }
      }
    }

    reset('newTableForm')

    return onSuccess({ tableName: values.tableName, tableId, datasourceId })
  }

  render() {
    const { onCancel, tables, datasourceId } = this.props

    return (
      <Form
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        tables={tables}
        datasourceId={datasourceId}
      />
    )
  }
}

const mapStateToProps = state => {
  const appId = getCurrentAppId(state)
  //TODO: what's the correct way to get datasource?
  const datasource = getDatasources(state, appId)[0]
  const datasourceId = datasource && datasource.id

  return {
    appId,
    datasourceId,
    tables: getTableIds(state, appId, datasourceId),
  }
}

export default connect(mapStateToProps, {
  addTableField,
  createTable,
  reset,
  updateTableField,
})(NewTableModal)
