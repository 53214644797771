import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { destroy } from 'redux-form'
import { getId } from '@adalo/utils'

import { getLabel, getTableInfo } from 'utils/sources'

import { getFilterOptions } from 'ducks/recommender'
import { getTable } from 'ducks/apps/datasources'

import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'

import { ConnectedFilterForm } from './ListFilterForm'

const FORM_NAME = 'listFilterForm'

const AddFilterButton = ({
  label,
  hasBackground,
  onClick,
  fitsParentWidth = false,
  ...props
}) => {
  return (
    <div
      className={`library-inspect-list-filters-add ${
        fitsParentWidth && 'btn-fits-parent-width'
      }`}
    >
      <Button
        listAddButton
        darkGray
        onClick={onClick}
        icon="plus-small"
        hasBackground={hasBackground}
        {...props}
      >
        {label}
      </Button>
    </div>
  )
}

const FilterItemHeader = ({ hasPrevious = false, onRemove }) => {
  return (
    <div className="library-inspect-filter-item-header">
      {hasPrevious ? (
        <p className="filter-label logic">AND</p>
      ) : (
        <p className="filter-label">Custom Filter</p>
      )}
      <Icon
        type="trash-small"
        onClick={onRemove}
        className="library-inspect-filter-item-delete"
      />
    </div>
  )
}

const FilterItem = ({
  appId,
  componentId,
  table,
  options,
  object,
  filter,
  formName,
  onChange,
  getLabel,
  reference,
}) => {
  return (
    <ConnectedFilterForm
      form={formName}
      appId={appId}
      componentId={componentId}
      object={object}
      onChange={onChange}
      table={table}
      fieldOptions={options}
      getLabel={getLabel}
      filter={filter}
      initialValues={filter}
      reference={reference}
    />
  )
}

const mapStateToProps = (state, props) => {
  const { binding, bindingId, appId, componentId, objectId } = props
  let datasourceId
  let tableId
  let table

  if (binding && binding.source) {
    const tableInfo = getTableInfo(binding.source)
    datasourceId = tableInfo.datasourceId
    tableId = tableInfo.tableId
    table = getTable(state, appId, datasourceId, tableId)
  }

  return {
    table,
    getLabel: val => getLabel(state, val, appId, componentId, true),
    options: getFilterOptions(
      state,
      appId,
      datasourceId,
      tableId,
      objectId,
      bindingId || (binding && binding.id)
    ),
  }
}

const ConnectedFilterItem = connect(mapStateToProps)(FilterItem)

const ListFilters = ({
  appId,
  componentId,
  object,
  binding,
  bindingId,
  addLabel,
  reference,
  onChange,
  fitsParentWidth,
  isForm,
}) => {
  const dispatch = useDispatch()

  const getFilter = () => {
    let { filter } = binding.source.options || {}

    if (!Array.isArray(filter)) {
      filter = filter ? [filter] : []
    }

    return filter
  }

  const handleAdd = () => {
    const filter = getFilter().slice()

    const updatedFilter = [...filter, [{ id: getId() }]]

    onChange(updatedFilter)
  }

  const handleAddAnd = groupIndex => {
    const filter = getFilter().slice()

    filter[groupIndex].push({ id: getId() })

    onChange(filter)
  }

  const handleChange = (groupIndex, itemIndex) => val => {
    const filter = getFilter().slice()

    if (!val) {
      filter[groupIndex].splice(itemIndex, 1)
      onChange(filter)

      return
    }

    if (!Array.isArray(val)) {
      val = [val]
    }

    if (!Array.isArray(filter[groupIndex])) {
      filter[groupIndex] = val
    } else {
      filter[groupIndex] = filter[groupIndex].map(item => {
        if (item.id === val[0].id) {
          return val[0]
        }

        return item
      })
    }

    onChange(filter)
  }

  const handleRemoveAnd = (groupIndex, itemIndex, formName) => {
    const filter = getFilter().slice()

    if (filter[groupIndex].length === 1) {
      filter.splice(groupIndex, 1)
    } else {
      filter[groupIndex] = filter[groupIndex].filter(
        (_, index) => itemIndex !== index
      )
    }

    dispatch(destroy(formName))
    onChange(filter)
  }

  const filters = getFilter().slice()
  const itemLabel = addLabel || 'Add custom filter'

  return (
    <div className={`library-inspect-list-filters ${isForm && 'is-form'}`}>
      {filters.map((filterGroup, groupIndex) => {
        if (!Array.isArray(filterGroup)) {
          filterGroup = [filterGroup]
        }

        return (
          <React.Fragment key={`${(String(filterGroup), groupIndex)}`}>
            <div className="library-inspect-filter-item">
              {filterGroup.map((filterItem, itemIndex) => {
                const formName = `${FORM_NAME}-${filterItem.id || itemIndex}`

                return (
                  <React.Fragment key={filterItem.id || itemIndex}>
                    <FilterItemHeader
                      isActive={!!filterItem.fieldId}
                      hasPrevious={!!filterGroup[itemIndex - 1]}
                      onRemove={() =>
                        handleRemoveAnd(groupIndex, itemIndex, formName)
                      }
                    />
                    <ConnectedFilterItem
                      formName={formName}
                      onChange={handleChange(groupIndex, itemIndex)}
                      appId={appId}
                      componentId={componentId}
                      object={object}
                      binding={binding}
                      bindingId={bindingId}
                      filter={filterItem}
                      reference={reference}
                    />
                    {!filterGroup[itemIndex + 1] && (
                      <AddFilterButton
                        small
                        hasBackground
                        label="AND"
                        onClick={() => handleAddAnd(groupIndex)}
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
            {filters[groupIndex + 1] && (
              <p className="filter-label logic">OR</p>
            )}
          </React.Fragment>
        )
      })}
      <AddFilterButton
        medium
        hasBackground={!isForm}
        bigMarginTop={!isForm}
        label={itemLabel}
        onClick={handleAdd}
        fitsParentWidth={fitsParentWidth}
      />
    </div>
  )
}

export default ListFilters
