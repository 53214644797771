import { upperFirst, get, set, unset, cloneDeep } from 'lodash'

export const ACTIONS = ['list', 'detail', 'create', 'update', 'delete'] as const

type ActionType = typeof ACTIONS[number]
type FormFields = {
  [key in ActionType]: {
    resultsKey?: string
    [index: string]: unknown
    // incomplete
  }
}
type ExtraLevelType = 2 | 3 | 4 | 5 | 6
type LevelType = 1 | ExtraLevelType

const DEPTH_LEVELS_NAME_MAP: {
  [key in ExtraLevelType]: string
} = {
  2: 'second',
  3: 'third',
  4: 'forth',
  5: 'fifth',
  6: 'sixth',
}

export const DEPTH_LEVEL_MIN = Math.min(
  ...Object.keys(DEPTH_LEVELS_NAME_MAP).map(l => Number(l))
)

export const DEPTH_LEVEL_MAX = Math.max(
  ...Object.keys(DEPTH_LEVELS_NAME_MAP).map(l => Number(l))
)

export const getReadableDepthForLevel = (level: ExtraLevelType): string =>
  upperFirst(DEPTH_LEVELS_NAME_MAP[level])

const getResultsKeyEnabledNameForLevel = (
  actionName: ActionType,
  level: ExtraLevelType
): string => `${actionName}.${DEPTH_LEVELS_NAME_MAP[level]}ResultsKeyEnabled`

const getResultsKeyForLevel = (
  actionName: ActionType,
  level: ExtraLevelType
): string => `${actionName}.${DEPTH_LEVELS_NAME_MAP[level]}ResultsKey`

export const getResultsKeyPropsForLevel = (
  actionName: ActionType,
  level: ExtraLevelType
): {
  name: string
  fieldName: string
  level: ExtraLevelType
} => {
  return {
    name: `endpoints.${getResultsKeyEnabledNameForLevel(actionName, level)}`,
    fieldName: `endpoints.${getResultsKeyForLevel(actionName, level)}`,
    level,
  }
}

export const isLevelEnabled = (
  actionName: ActionType,
  formFields: FormFields,
  level: LevelType
): boolean => {
  if (level === 1) {
    return !!formFields[actionName]?.resultsKey
  }

  const field = get(
    formFields,
    [actionName, `${DEPTH_LEVELS_NAME_MAP[level]}ResultsKeyEnabled`],
    ''
  ) as string

  return field === 'enabled'
}

export const getPath = (
  formFields: FormFields,
  actionName: ActionType = 'list'
): string[] => {
  const path = []

  let level: LevelType
  for (level = 1; level <= DEPTH_LEVEL_MAX; level += 1) {
    const previousLevelEnabled =
      level === 1
        ? true
        : isLevelEnabled(actionName, formFields, (level - 1) as LevelType)

    const levelEnabled = isLevelEnabled(
      actionName,
      formFields,
      level as LevelType
    )

    const levelValue = get(formFields, [
      actionName,
      level === 1
        ? 'resultsKey'
        : `${DEPTH_LEVELS_NAME_MAP[level as ExtraLevelType]}ResultsKey`,
    ]) as string

    if (previousLevelEnabled && levelEnabled && levelValue) {
      path.push(levelValue)
    }
  }

  return path
}

export const removeLevelAndConsolidateFormFields = (
  formFields: FormFields,
  actionName: ActionType,
  removeLevel: ExtraLevelType
): FormFields => {
  const updatedFormFields = cloneDeep(formFields)

  const activeLevels = []

  let level: ExtraLevelType
  for (
    level = DEPTH_LEVEL_MIN as ExtraLevelType;
    level <= DEPTH_LEVEL_MAX;
    level += 1
  ) {
    const levelEnabled = isLevelEnabled(actionName, formFields, level)

    const resultsKey = `${DEPTH_LEVELS_NAME_MAP[level]}ResultsKey`
    const levelValue = get(formFields, [actionName, resultsKey]) as string

    if (levelEnabled && levelValue && removeLevel !== level) {
      activeLevels.push(levelValue)
    }

    unset(updatedFormFields, getResultsKeyEnabledNameForLevel(actionName, level)) // prettier-ignore
    unset(updatedFormFields, getResultsKeyForLevel(actionName, level))
  }

  for (const [index, activeLevel] of activeLevels.entries()) {
    const realignedIndex = (index + 2) as ExtraLevelType

    set(updatedFormFields, getResultsKeyEnabledNameForLevel(actionName, realignedIndex), 'enabled') // prettier-ignore
    set(updatedFormFields, getResultsKeyForLevel(actionName, realignedIndex), activeLevel) // prettier-ignore
  }

  return updatedFormFields
}
