import React, { Component } from 'react'
import { connect } from 'react-redux'
import { buildIndex } from '@adalo/utils'

import { buildSearchFilter } from 'utils/search'
import { requestData } from 'utils/io'
import { uploadURL } from 'utils/uploads'
import { singularize, capitalize } from 'utils/strings'
import { getPrimaryField } from 'utils/includes'
import { getData } from 'ducks/datasources'
import Loading from '../../../Shared/Loading'
import Select from './Select'

class AssociationList extends Component {
  componentDidMount() {
    const { appId, datasourceId, tableId, isDisabled } = this.props

    if (isDisabled) {
      return
    }

    requestData({
      appId,
      datasourceId,
      tableId,
      skipDBAssistantInitialization: true,
    })
  }

  handleRemove = id => {
    const { onRemoveItem, primaryField } = this.props
    const data = this.getAssociationData()

    const item = data.filter(itm => String(itm.id) === String(id))[0]

    if (!item) return null

    const remove = window.confirm(
      `Are you sure you want to remove ${item[primaryField] || 'Untitled'}?`
    )

    if (remove) return onRemoveItem(id)

    return null
  }

  handleAdd = id => {
    const { data, onAddItem } = this.props

    const obj = data.find(item => String(item.id) === String(id))
    if (id && obj) onAddItem(id, obj)
  }

  getLabel = itm => {
    const { datasource, tableId } = this.props

    const primaryField = getPrimaryField(datasource.tables[tableId])

    if (!primaryField) {
      return `${this.getTableName()} ${itm.id}`
    }

    let val = itm[primaryField]

    if (val && typeof val === 'object') {
      val = (
        <div
          className="data-object-form-input-image"
          style={{
            backgroundImage: `url(${uploadURL(val.url)})`,
          }}
        />
      )
    }

    return val
  }

  getTable = () => {
    const { field, datasource } = this.props
    const { tableId } = field.type
    const table = datasource.tables[tableId]

    return table
  }

  getTableName = () => {
    const table = this.getTable()

    return (table && capitalize(singularize(table.name))) || ''
  }

  getFieldName = () => {
    const { field } = this.props
    const { name } = field

    return (name && capitalize(singularize(name))) || ''
  }

  handleChange = newValue => {
    const associationData = this.getAssociationData()

    if (!newValue) newValue = []
    const newIds = newValue.map(itm => itm.value)

    const oldMap = buildIndex(associationData, itm => itm.id)
    const newMap = buildIndex(newIds, itm => itm)
    const allMap = { ...oldMap, ...newMap }

    const added = []
    const removed = []

    for (const key in allMap) {
      if (!oldMap[key]) {
        added.push(key)
      } else if (!newMap[key]) {
        removed.push(key)
      }
    }

    added.forEach(id => this.handleAdd(id))
    removed.forEach(id => this.handleRemove(id))
  }

  getOptions = () => {
    const { data } = this.props

    if (!data) return []

    return data.map(itm => ({
      label: this.getLabel(itm),
      value: itm.id,
    }))
  }

  requestData = (opts = {}) => {
    const { appId, datasourceId, tableId } = this.props
    const tableConfig = this.getTable()

    const queryParams = {}

    if (opts) {
      if (opts.offset) queryParams.offset = opts.offset

      if (opts.column_filter) {
        queryParams.column_filter = opts.column_filter
        if (queryParams.offset) delete opts.offset
      }
    }

    return requestData(
      {
        appId,
        datasourceId,
        tableId,
        tableConfig,
        queryParams,
        skipDBAssistantInitialization: true,
      },
      true
    )
  }

  searchData = async value => {
    const { primaryField } = this.props

    const table = this.getTable()
    const filter = buildSearchFilter(table, String(value))
    let result

    if (value && value.length > 0) {
      result = await this.requestData({ column_filter: filter })
    } else {
      result = await this.requestData()
    }

    return result.map(itm => ({
      label: itm[primaryField] || 'Untitled',
      value: itm.id,
    }))
  }

  loadOptions = value => this.searchData(value)

  getAssociationData = () => {
    const { object, fieldId } = this.props
    const associationData = object[`${fieldId}_value`]

    return associationData || []
  }

  render() {
    const { isDisabled, primaryField } = this.props
    const table = this.getTable()

    if (!table) return <Loading small />

    const options = this.getOptions()

    const associationData = this.getAssociationData()

    const values = associationData.map(itm => ({
      label: itm[primaryField] || 'Untitled',
      value: itm.id,
    }))

    if (!associationData) return <Loading small />

    return (
      <Select
        isMulti
        isClearable={false}
        isDisabled={isDisabled}
        cacheOptions
        value={values}
        defaultOptions={options}
        loadOptions={this.loadOptions}
        onInputChange={this.loadOptions}
        onChange={this.handleChange}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'rgba(239, 76, 48, 0.07)',
            primary50: 'rgba(239, 76, 48, 0.1)',
          },
        })}
        placeholder={`Add ${this.getFieldName()}...`}
      />
    )
  }
}

const mapStateToProps = (state, { tableId }) => ({
  data: getData(state, tableId),
})

export default connect(mapStateToProps)(AssociationList)
