import {
  resizingOptions,
  responsivePositioningOptions,
  GROUP,
  LIBRARY_COMPONENT,
  ELLIPSE,
  SHAPE,
} from '@adalo/constants'
import ObjectType from 'ducks/editor/types/ObjectType'
import { EditorObject, Responsivity } from './responsiveTypes'

const { SCALES_WITH_PARENT, FIXED } = resizingOptions
const { TOP, CENTER, RIGHT, LEFT_AND_RIGHT, FIXED_ON_SCROLL } =
  responsivePositioningOptions

export const isActionButton = (obj: EditorObject): boolean =>
  obj.componentName === 'ActionButton' &&
  obj.libraryName === '@protonapp/material-components' &&
  obj.type === LIBRARY_COMPONENT

export const isGroup = ({ type }: EditorObject): boolean => type === GROUP

export const isAdaloMaterialComponent = ({
  libraryName,
  type,
}: EditorObject): boolean =>
  type === LIBRARY_COMPONENT && libraryName === '@protonapp/material-components'

export const isTabNavigator = (obj: EditorObject): boolean =>
  isAdaloMaterialComponent(obj) && obj.componentName === 'TabNavigator'

export const isAppBar = (obj: EditorObject): boolean =>
  isAdaloMaterialComponent(obj) && obj.componentName === 'AppBar'

export const isIcon = (obj: EditorObject): boolean =>
  isAdaloMaterialComponent(obj) && obj.componentName === 'Icon'

export const isToggle = (obj: EditorObject): boolean =>
  isAdaloMaterialComponent(obj) && obj.componentName === 'IconToggle'

export const isMarketplaceSwitch = (obj: EditorObject): boolean =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === 'switch' &&
  obj.componentName === 'Switch'

export const isLegacySwitch = (obj: EditorObject): boolean =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === 'switch-component' &&
  obj.componentName === 'SwitchComponent'

export const isProgressBar = (obj: EditorObject): boolean =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === 'progress-bar' &&
  obj.componentName === 'ProgressBar'

export const isProgressBarFromKit = (obj: EditorObject): boolean =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === 'progress-bars-kit' &&
  obj.componentName === 'ProgressBar'

export const isCircularProgressBar = (obj: EditorObject): boolean =>
  obj.type === LIBRARY_COMPONENT &&
  obj.libraryName === 'progress-bars-kit' &&
  obj.componentName === 'CircularProgressBar'

const SHAPE_TYPES = new Set<ObjectType>([ELLIPSE, SHAPE])

export const getDefaultResponsivity = (obj: EditorObject): Responsivity => {
  if (isActionButton(obj)) {
    return {
      horizontalPositioning: RIGHT,
      horizontalScaling: FIXED,
      verticalPositioning: FIXED_ON_SCROLL,
      verticalScaling: FIXED,
    }
  }

  if (isTabNavigator(obj) || isAppBar(obj)) {
    return {
      horizontalPositioning: LEFT_AND_RIGHT,
      horizontalScaling: SCALES_WITH_PARENT,
      verticalPositioning: FIXED_ON_SCROLL,
      verticalScaling: FIXED,
    }
  }

  if (
    SHAPE_TYPES.has(obj.type) ||
    isIcon(obj) ||
    isToggle(obj) ||
    isLegacySwitch(obj) ||
    isMarketplaceSwitch(obj) ||
    isProgressBar(obj) ||
    isProgressBarFromKit(obj) ||
    isCircularProgressBar(obj)
  ) {
    return {
      horizontalPositioning: CENTER,
      horizontalScaling: FIXED,
      verticalPositioning: TOP,
      verticalScaling: FIXED,
    }
  }

  return {
    horizontalPositioning: CENTER,
    horizontalScaling: SCALES_WITH_PARENT,
    verticalPositioning: TOP,
    verticalScaling: FIXED,
  }
}

export const getDefaultListResponsivity = (): Responsivity => ({
  horizontalPositioning: LEFT_AND_RIGHT,
  horizontalScaling: SCALES_WITH_PARENT,
  verticalPositioning: TOP,
  verticalScaling: FIXED,
})
