import classNames from 'classnames'
import React from 'react'

interface Props {
  extraHandleStyle: string | null
  position: string
  width: number
  height: number
  showIcon: boolean
}

export const PaddingDragHandle = (props: Props): JSX.Element | null => {
  const { extraHandleStyle, position, width, height, showIcon } = props

  if (!extraHandleStyle) {
    return null
  }

  const transparent = extraHandleStyle === 'transparent'
  const verticalHandle = position === 'left' || position === 'right'

  const containerDragHandleWidth = verticalHandle ? 2 : width
  const containerDragHandleHeight = verticalHandle ? height : 2
  const containerDragHandleX = verticalHandle ? 7 : 0
  const containerDragHandleY = verticalHandle ? 0 : 7

  const iconX = verticalHandle ? 0 : containerDragHandleWidth / 2 - 8
  const iconY = verticalHandle ? containerDragHandleHeight / 2 - 8 : 0
  const transform = verticalHandle ? undefined : 'rotate(90, 8, 8)'

  return (
    <>
      <rect
        className={classNames('container-resize-click-target', {
          transparent,
        })}
        width={containerDragHandleWidth}
        height={containerDragHandleHeight}
        x={containerDragHandleX}
        y={containerDragHandleY}
        style={{
          fill: '#00a898',
        }}
      />
      {showIcon && (
        <svg
          width="16"
          height="16"
          x={iconX}
          y={iconY}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#00A996" />
          <path
            d="M5.08333 10.3333L2.75 8L5.08333 5.66667L5.91458 6.48333L4.98125 7.41667H11.0188L10.1 6.48333L10.9167 5.66667L13.25 8L10.9167 10.3333L10.1 9.51667L11.0188 8.58333H4.98125L5.9 9.51667L5.08333 10.3333Z"
            fill="white"
            transform={transform}
          />
        </svg>
      )}
    </>
  )
}
