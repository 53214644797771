import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { loadMetrics } from 'ducks/metrics'
import { bulkDeleteDataObjects } from 'utils/io'
import { buildSearchFilter } from 'utils/search'
import { updateTable, removeTable } from '../../../ducks/apps/datasources'
import Section from './Section'
import DataBrowser from './Browser'
import { IconButton } from '../../Shared/Icon'

class Table extends Component {
  state = {
    searchTerm: '',
    blockedList: false,
    idList: [],
    metric: 0,
  }

  handleChangeName = name => {
    let { appId, datasourceId, id, table, updateTable } = this.props

    table = { ...table, name }
    updateTable(appId, datasourceId, id, table)
  }

  handleBlockedListToggle = (blockedList, blockedListLength) => {
    this.setState({ blockedList, idList: [], blockedListLength })
  }

  handleBulkDelete = deleteCount => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${deleteCount.toString()} ${
          deleteCount > 1 ? 'records' : 'record'
        }?`
      )
    ) {
      return
    }

    const { blockedList, idList, searchTerm } = this.state
    const { appId, datasourceId, id, loadMetrics, table } = this.props
    const queryParams = {}

    if (searchTerm) {
      queryParams.column_filter = buildSearchFilter(table, searchTerm)
    }

    bulkDeleteDataObjects(
      appId,
      datasourceId,
      id,
      blockedList,
      idList,
      queryParams
    )

    this.setState({ blockedList: false, idList: [] })
    loadMetrics(appId, datasourceId)
  }

  handleSelectToggle = (id, selected) => {
    const { blockedList, idList } = this.state

    if ((!blockedList && !selected) || (blockedList && selected)) {
      this.setState({
        idList: idList.filter(value => {
          return value !== id
        }),
      })
    } else {
      this.setState({ idList: idList.concat(id) })
    }
  }

  handleSearch = e => {
    const searchTerm = e.target.value

    return this.setState({ searchTerm })
  }

  handleRemoveTable = async () => {
    const { appId, datasourceId, id, removeTable, history, table } = this.props

    const confirm = await window.prompt(
      `Are you sure you want to delete the collection "${table.name}"? This action can not be undone. Please type the collection name to confirm.`
    )

    if (confirm && confirm.toLowerCase() === table.name.toLowerCase()) {
      await removeTable(appId, datasourceId, id)

      return history.push(`/apps/${appId}/data`)
    }
  }

  getImportLink = () => {
    const { match } = this.props
    const { appId, datasourceId, collectionId } = match.params

    return `/apps/${appId}/data/${datasourceId}/${collectionId}/csv-import`
  }

  renderHeader = () => {
    const { searchTerm } = this.state
    const { table } = this.props

    return (
      <div className="data-actions">
        <Link
          className="deemphasize data-import-link"
          to={this.getImportLink()}
        >
          Import Data
        </Link>
        <input
          type="search"
          placeholder="Search..."
          onChange={this.handleSearch}
          value={searchTerm}
        />
        {!table.fields.password ? (
          <IconButton type="trash-small" onClick={this.handleRemoveTable} />
        ) : null}
      </div>
    )
  }

  onLoadMetric = metric => {
    this.setState({ metric })
  }

  handleCloseModal = () => {
    const { match, history } = this.props
    const { appId } = match.params
    const baseURL = `/apps/${appId}/data`

    return history.push(baseURL)
  }

  render() {
    const { appId, datasourceId, id, table, match, history } = this.props

    const { searchTerm, blockedList, idList, metric, blockedListLength } =
      this.state

    if (!table) return null

    return (
      <>
        {[
          <Section
            name={table.name}
            key={id}
            expandedDefault={!table.name}
            handleCloseModal={this.handleCloseModal}
            handleSearch={this.handleSearch}
            handleBlockedListToggle={this.handleBlockedListToggle}
            handleSelectToggle={this.handleSelectToggle}
            handleBulkDelete={this.handleBulkDelete}
            blockedList={blockedList}
            blockedListLength={blockedListLength}
            idList={idList}
            searchValue={searchTerm}
            metric={metric}
            renderContent={(
              scrollY,
              getContentRect,
              blockedList,
              idList,
              handleBlockedListToggle,
              handleSelectToggle
            ) => {
              const data = { appId, datasourceId, tableId: id }

              const props = {
                ...data,
                scrollY,
                searchTerm,
                getContentRect,
                blockedList,
                idList,
                handleBlockedListToggle,
                handleSelectToggle,
                onLoadMetric: this.onLoadMetric,
              }

              return [1].map(() => <DataBrowser key={id} {...props} />)
            }}
            match={match}
            history={history}
          />,
        ]}
      </>
    )
  }
}

export default withRouter(
  connect(null, { updateTable, removeTable, loadMetrics })(Table)
)
