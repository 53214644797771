import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'

import './style.scss'
import FeatureGrid from './FeatureGrid'
import { FeatureValue } from './features'

const StepOne = ({
  handleClose,
  handleClickExtend,
  selectFeature,
  selectedFeature,
}: {
  handleClose: () => void
  handleClickExtend: () => void
  selectFeature: (feature: FeatureValue) => void
  selectedFeature: FeatureValue
}): JSX.Element => {
  const leftButton = (
    <Button
      text
      onClick={handleClose}
      type="button"
      data-adalo-id="extend-trial-close-modal"
    >
      Close
    </Button>
  )

  return (
    <>
      <Modal.Header>
        <div className="extend-trial-header">
          <h1 className="free-trial-extend-title">Unlock 7 More Days</h1>
          <p className="free-trial-extend-subtitle">
            Extend your free trial &#8212; because sometimes we all need just a
            little more time to play.
          </p>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="free-trial-extend">
          <p className="free-trial-extend-body">
            Which feature is the most important to test on the free trial?
            (Select One)
          </p>
          <div data-adalo-id="extend-trial-most-important-feature">
            {selectedFeature}
          </div>
          <div className="premium-feature-list">
            <FeatureGrid
              selectFeature={selectFeature}
              selectedFeature={selectedFeature}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions leftButtons={leftButton}>
        <Button
          onClick={handleClickExtend}
          type="button"
          teal
          gradient
          disabled={!selectedFeature}
          data-adalo-id="extend-trial-confirm"
        >
          Extend Trial
        </Button>
      </Modal.Actions>
    </>
  )
}

export default StepOne
