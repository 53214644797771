const isInstalled = licenses => component =>
  licenses?.find?.(lib => lib.id === component.id)

const notInstalled = licenses => component => !isInstalled(licenses)(component)

const boolFilter = (filter, bool) => component =>
  Object.prototype.hasOwnProperty.call(component, filter) &&
  typeof component[filter] === 'boolean' &&
  component[filter] === bool

const stringFilter = (type, filter) => component =>
  Object.prototype.hasOwnProperty.call(component, type) &&
  typeof component[type] === 'string' &&
  component[type].toLowerCase().includes(filter.toLowerCase())

const filterComponents = (tokens, licenses, result) => {
  const [type, filter] = tokens

  switch (type) {
    case 'is': {
      switch (filter) {
        case 'installed':
          return result.filter(isInstalled(licenses))
        default:
          return result.filter(boolFilter(filter, true))
      }
    }

    case 'not': {
      switch (filter) {
        case 'installed':
          return result.filter(notInstalled(licenses))
        default:
          return result.filter(boolFilter(filter, false))
      }
    }

    default:
      return result.filter(stringFilter(type, filter))
  }
}

export default (components, search, licenses) => {
  const tokens = search.split(' ')
  let result = components.slice()

  for (const token of tokens) {
    const tokens = token.split(':')

    if (tokens.length === 1) {
      const [filter] = tokens

      result = result.filter(
        component =>
          component.displayName.toLowerCase().includes(filter.toLowerCase()) ||
          component.description.toLowerCase().includes(filter.toLowerCase())
      )

      continue
    }

    result = filterComponents(tokens, licenses, result)
  }

  return result
}
