import React, { Component } from 'react'
import classNames from 'classnames'

import './WrappedCheckbox.css'

export default class WrappedCheckbox extends Component {
  handleClick = e => {
    e.stopPropagation()
  }

  handleChange = e => {
    const el = e.currentTarget
    const value = el.checked
    const { input } = this.props
    const { onBlur, onChange } = input || this.props

    const func = onBlur || onChange

    func(value)
  }

  toggleValue = e => {
    const { input } = this.props
    const { onBlur, onChange, value } = input || this.props
    const func = onBlur || onChange

    func(!value)
  }

  render() {
    const { className, label, input } = this.props
    const { value } = input || this.props

    return (
      <span className={classNames('wrapped-checkbox', className)}>
        <input
          type="checkbox"
          checked={value}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          onClick={this.handleClick}
        />
        <label onClick={this.toggleValue}>{label}</label>
      </span>
    )
  }
}
