import update from 'immutability-helper'

import { getObject, getObjectId, uniqueElements, subPath } from '@adalo/utils'
import { COMPONENT, LAYOUT_SECTION } from '@adalo/constants'
import { getFeatureFlag } from 'ducks/featureFlags'

import {
  getContainerFromObject,
  getSectionFromLayoutHelper,
  isChildOfContainer,
  isChildOfSection,
  isSectionElement,
} from 'utils/layoutSections'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'

import {
  getMap,
  getObjectList,
  getSelectedObjects,
  selectObject,
} from './objects'

import {
  getSelectableObject,
  getDoubleClickSelection,
} from '../../utils/selection'

import { getSnapGrid, getSnapGridForParent } from '../../utils/snapping'
import getObjectUpdated from './objects/helpers/getObject'

const SET_SELECTION = Symbol('SET_SELECTION')
const SET_EXPAND_SELECTION = Symbol('SET_EXPAND_SELECTION')
const SET_LAYERS_HOVER = Symbol('SET_LAYERS_HOVER')
const SET_CANVAS_HOVER = Symbol('SET_CANVAS_HOVER')
const RECALCULATE_SNAP_GRID = Symbol('RECALCULATE_SNAP_GRID')
const SET_SNAP_GRID_PARENT = Symbol('SET_SNAP_GRID_PARENT')
const SET_PARENT_SELECTION = Symbol('SET_PARENT_SELECTION')
const RESET_PARENT_SELECTION = Symbol('RESET_PARENT_SELECTION')

export const SELECT_PARENT = Symbol('SELECT_PARENT')

export const getActiveComponent = (list, map, objectIds) => {
  const id = objectIds[0]

  if (id) {
    const screen = getObject(list, subPath(map[id], 1))

    return screen.id
  }

  return null
}

export default (state, action) => {
  if (action.type === SET_SELECTION) {
    let {
      groupsFirst,
      object,
      shiftKey,
      componentView,
      globalState,
      hasAltKeyPressed,
    } = action
    let { selection } = state

    const hasSectionComponent = getFeatureFlag(
      globalState,
      'hasSectionComponent'
    )

    const path = state.map[object]
    let newPath

    if (Array.isArray(object)) {
      if (shiftKey) {
        return {
          ...state,
          componentView: null,
          selectedParent: null,
          selection: uniqueElements(selection.concat(object)),
        }
      }

      return {
        ...state,
        componentView,
        selectedParent: null,
        selection: object,
      }
    } else if (groupsFirst) {
      const selectionPaths = selection.map(id => state.map[id])
      newPath = getSelectableObject(selectionPaths, path, 2)

      if (hasSectionComponent) {
        const parent = getObject(state.list, newPath)

        if (!isSectionElement(parent) && parent.type !== LAYOUT_SECTION) {
          object = getObject(state.list, newPath)?.id
        } else {
          object = getSectionFromLayoutHelper(state, path) ?? object
        }
      } else {
        object = getObject(state.list, newPath)?.id
      }
    } else if (hasAltKeyPressed) {
      const path = state.map[object]

      if (hasSectionComponent) {
        object = getSectionFromLayoutHelper(state, path) ?? object
      }
    }

    const fullObject = getObject(state.list, path)

    const selectedObject = selectObject(globalState, selection[0])

    if (
      isChildOfSection(fullObject?.id, state) &&
      isChildOfContainer(fullObject?.id, state) &&
      !isChildOfContainer(selectedObject?.id, state) &&
      groupsFirst &&
      selection[0] !== object
    ) {
      const container = getContainerFromObject(object, state)
      object = container ? container.id : object
    }

    const index = selection.indexOf(object)

    if (shiftKey) {
      if (object && index >= 0 && selection.length > 1) {
        selection = update(selection, { $splice: [[index, 1]] })
      } else if (object && index === -1) {
        selection = selection.concat([object])
      }
    } else if (index === -1) {
      if (object) {
        selection = [object]
      } else {
        selection = []
      }
    }

    return {
      ...state,
      componentView,
      selectedParent: null,
      selection,
      parentSelection: [],
      activeComponent: getActiveComponent(state.list, state.map, selection),
    }
  }

  if (action.type === SET_EXPAND_SELECTION) {
    const selectionPaths = state.selection.map(id => state.map[id])
    const path = state.map[action.objectId]
    const selectionPath = getDoubleClickSelection(selectionPaths, path)
    const selection = [getObjectId(state.list, selectionPath)]

    const object = getObject(state.list, selectionPath)

    if (isSectionElement(object)) {
      return {
        ...state,
      }
    }

    if (
      isSectionElement(object) ||
      isChildOfSection(object?.id, state) ||
      object.type === LAYOUT_SECTION
    ) {
      return {
        ...state,
        selection,
        parentSelection: [],
        selectedParent: null,
      }
    }

    return {
      ...state,
      selection,
      selectedParent: null,
    }
  }

  if (action.type === SET_LAYERS_HOVER || action.type === SET_CANVAS_HOVER) {
    const { object, hasSectionComponent } = action
    const { positioningObjects } = state

    const objectId = object?.id

    let hover = [objectId]

    if (positioningObjects) {
      return
    }

    const selection = state.selection.map(id => state.map[id])
    const path = state.map[objectId]

    if (
      hasSectionComponent &&
      (isSectionElement(object) ||
        object?.type === LAYOUT_SECTION ||
        isChildOfSection(object?.id ? object.id : object, state))
    ) {
      // 5 means that we can only go deeper until we find a container child
      // meaning we won't add hover states to children of container's children
      // example:
      // Layout Section -> Layout Helper -> Container -> Rectangle -> Button
      // in this context button will never highlighted
      const newPath = getSelectableObject(selection, path, 5)
      hover = [getObject(state.list, newPath).id]

      return {
        ...state,
        hoverSelection: hover,
      }
    }
    // when object is null it means that we are no longer hovering over a certain
    // object.
    else if (!objectId) {
      // when hovering a section related component (meaning that hoveredObject's length will be greater than 1)
      // we should only pop the latest hovered object.
      return {
        ...state,
        hoverSelection: [],
      }
    }

    if (object && action.type === SET_CANVAS_HOVER) {
      const newPath = getSelectableObject(selection, path, 2)
      hover = [getObject(state.list, newPath).id]
    }

    return {
      ...state,
      hoverSelection: hover,
    }
  }

  if (action.type === RECALCULATE_SNAP_GRID) {
    return {
      ...state,
      ...getSnapGrid(state, state.selection),
    }
  }

  if (action.type === SET_SNAP_GRID_PARENT) {
    const parentId = action.payload

    if (!parentId) {
      return { ...state, snapGridParent: null }
    }

    const newSnapGrid = getSnapGridForParent(state, state.selection, parentId)

    return { ...state, ...newSnapGrid, snapGridParent: parentId }
  }

  if (action.type === SELECT_PARENT) {
    const { objectId } = action

    return {
      ...state,
      selectedParent: objectId,
    }
  }

  if (action.type === SET_PARENT_SELECTION) {
    return {
      ...state,
      parentSelection: action.payload,
    }
  }

  if (action.type === RESET_PARENT_SELECTION) {
    return {
      ...state,
      parentSelection: [],
    }
  }

  return state
}

export const setSelection =
  (
    object,
    shiftKey,
    groupsFirst = false,
    componentView = null,
    hasAltKeyPressed = false
  ) =>
  (dispatch, getState) =>
    dispatch({
      type: SET_SELECTION,
      groupsFirst,
      object,
      shiftKey,
      componentView,
      globalState: getState(),
      hasAltKeyPressed,
    })

export const resetSelection = () => (dispatch, getState) =>
  dispatch({
    type: SET_SELECTION,
    object: [],
    globalState: getState(),
  })

export const setExpandSelection = objectId => ({
  type: SET_EXPAND_SELECTION,
  objectId,
})

export const setLayersHover = object => ({
  type: SET_LAYERS_HOVER,
  object,
})

export const setCanvasHover = (object, hasSectionComponent = false) => ({
  type: SET_CANVAS_HOVER,
  object,
  hasSectionComponent,
})

export const recalculateSnapGrid = () => ({
  type: RECALCULATE_SNAP_GRID,
})

export const setSnapGridParent = parentId => ({
  type: SET_SNAP_GRID_PARENT,
  payload: parentId,
})

export const setSelectionParent = objectIds => ({
  type: SET_PARENT_SELECTION,
  payload: objectIds,
})

export const resetSelectionParent = () => ({
  type: RESET_PARENT_SELECTION,
})

// SELECTORS

/**
 *
 * @param {*} state
 * @returns {Array<string>}
 */
export const getSelection = ({ editor }) => editor.objects.present.selection

/**
 *
 * @param {*} state
 * @returns {Array<string>}
 */
export const getHoverSelection = ({ editor }) => {
  return editor.objects.present.hoverSelection
}

export const getParentSelection = ({ editor }) => {
  return Array.isArray(editor.objects.present.parentSelection)
    ? editor.objects.present.parentSelection
    : []
}

export const getCanvasHover = ({ editor }) => {
  return editor.objects.present.hoverSelection
}

export const getComponentView = ({ editor }) => {
  return editor.objects.present.componentView
}

/**
 *
 * @param {*} state
 * @returns {string | undefined}
 */
export const getSnapGridParent = ({ editor }) => {
  return editor.objects.present.snapGridParent
}

/**
 * Returns the current screen if all selected objects are on the same screen.
 *
 * @param {*} state
 * @returns {import('utils/responsiveTypes').EditorObject | null}
 */
export const getCurrentScreen = state => {
  /** @type {import('utils/responsiveTypes').EditorObject[]} */
  const selection = getSelectedObjects(state)

  if (selection.length === 0) {
    return null
  }

  /** @type {Set<string>} */
  const screens = new Set()

  /** @type {import('ducks/editor/types/ObjectList').ObjectList} */
  const list = getObjectList(state)
  /** @type {import('ducks/editor/types/ObjectPathMap').ObjectPathMap} */
  const map = getMap(state)

  for (const object of selection) {
    if (object.type === COMPONENT) {
      screens.add(object.id)
    } else {
      const screen = getParentScreen(list, map, object.id)

      if (screen && screen.type === COMPONENT) {
        screens.add(screen.id)
      }
    }

    if (screens.size !== 1) {
      return null
    }
  }

  const screenList = Array.from(screens)

  return getObjectUpdated(list, map, screenList[0])
}
