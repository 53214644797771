import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Button from 'components/Shared/Button'
import Icon, { IconButton } from 'components/Shared/Icon'
import { useFlags } from 'flags'

import { getFeatureTemplates } from 'ducks/featureTemplates'

import thumbnail from './feature-templates-thumbnail.png'

import './styles.scss'

type Props = {
  onClick: () => void
}

const CallToAction = ({ onClick }: Props): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false)
  const { hasNewAddMenuWithSections } = useFlags()

  const featureTemplates = useSelector(getFeatureTemplates)

  const featureTemplatesLength = featureTemplates?.length ?? 0

  const showCTA = useMemo(() => {
    return localStorage.getItem('seenTemplateFeaturesCTA') === null
  }, [localStorage.getItem('seenTemplateFeaturesCTA'), collapsed])

  if (!showCTA || hasNewAddMenuWithSections) {
    const icon = hasNewAddMenuWithSections
      ? 'features-gallery'
      : 'plus-box-multiple'

    const title = hasNewAddMenuWithSections
      ? 'View Features Gallery'
      : 'Add Feature Template'

    return (
      <div className="feature-templates-cta__collapsed">
        <Button
          listAddButton
          icon={icon}
          onClick={onClick}
          data-adalo-id="feature-templates-launch-modal"
        >
          {title}
        </Button>
      </div>
    )
  }

  const handleClick = () => {
    onClick()
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    localStorage.setItem('seenTemplateFeaturesCTA', JSON.stringify(true))
    setCollapsed(true)
  }

  return (
    <div
      role="button"
      className="feature-templates-cta__wrapper"
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
      data-adalo-id="feature-templates-launch-modal"
    >
      <div className="feature-templates-cta__content">
        <IconButton
          className="feature-templates-cta__close_arrows"
          type="close-double-arrows"
          onClick={handleClose}
        />

        <img
          src={thumbnail}
          alt="Feature templates thumbnail"
          width="280"
          height="auto"
        />
        <div className="feature-templates-cta__content__text">
          <h2>Feature Templates</h2>
          <p>
            With feature templates, you can add an entire feature to your app
            all at once.
          </p>
        </div>
        <span>
          See all {featureTemplatesLength} templates
          <Icon type="slim-arrow-next" color="teal" />
        </span>
      </div>
    </div>
  )
}

export default CallToAction
