import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { ADD, TABS } from 'utils/tabs'
import { capitalize } from 'utils/strings'
import { getSelection, resetSelection } from 'ducks/editor/selection'

import Icon from '../../Shared/Icon'

import './LeftNav.css'

const LeftNav = props => {
  const { history, match, location, selection, resetSelection } = props
  const { appId } = match.params

  const { pathname } = location
  const activeTab =
    selection.length > 0
      ? null
      : TABS.find(config => {
          return pathname.includes(config.path)
        })

  const handleClick = item => () => {
    const { path } = item

    resetSelection()
    if (item === activeTab && selection.length === 0) {
      // close tab panel
      history.push(`/apps/${appId}/screens`)

      return
    }
    history.push(`/apps/${appId}/${path}`)
  }

  return (
    <div className="editor-left-nav">
      <AddButton onClick={handleClick(ADD)} active={activeTab === ADD} />
      {TABS.slice(1).map(config => (
        <LeftNavItem
          icon={config.icon}
          key={config.type}
          type={config.type}
          tooltip={config.tooltip}
          active={activeTab === config}
          onClick={handleClick(config)}
        />
      ))}
    </div>
  )
}

class LeftNavItem extends Component {
  handleClick = () => {
    const { onClick } = this.props

    onClick()
  }

  render() {
    const { icon, type, tooltip, active } = this.props

    return (
      <div
        className={classNames(
          'editor-left-nav-item',
          `editor-left-nav-item-${type}`,
          { active }
        )}
        onClick={this.handleClick}
      >
        <div className="editor-left-nav-item-sub">
          <Icon type={icon} />
        </div>
        <div className="editor-left-nav-tooltip">{capitalize(tooltip)}</div>
      </div>
    )
  }
}

class AddButton extends Component {
  handleClick = () => {
    const { onClick } = this.props

    onClick()
  }

  render() {
    const { active } = this.props

    return (
      <div
        className={classNames('editor-left-nav-add-button', { active })}
        onClick={this.handleClick}
      >
        <div className="editor-left-nav-add-button-sub" />
      </div>
    )
  }
}

export const mapStateToProps = state => ({
  selection: getSelection(state),
})

export default withRouter(
  connect(mapStateToProps, {
    resetSelection,
  })(LeftNav)
)
