import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, getFormValues, change } from 'redux-form'
import { isEmpty as _isEmpty } from 'lodash'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { getOutputs } from 'utils/custom-actions'
import { deepGet } from 'utils/objects'
import Button from 'components/Shared/Button'
import Loading from 'components/Shared/Loading'
import Accordion from 'components/Shared/Accordion'
import Outputs from './Outputs'

import './TestRequest.scss'

class TestRequest extends Component {
  state = { fetching: false }

  componentDidMount() {
    const {
      input: { onChange },
    } = this.props

    onChange(null)

    this.startTest()
  }

  handleUpdateOutputs = (response, outputs) => {
    const {
      change,
      values: { testResults },
      formName,
    } = this.props

    const { availableOutputs } = testResults || {}

    let updatedOutputs = {}

    Object.keys(outputs)
      .filter(index => availableOutputs[index])
      .forEach(key => (updatedOutputs[key] = outputs[key]))

    updatedOutputs = this.addSampleValues(response, updatedOutputs)

    change(formName, 'outputs', updatedOutputs)
  }

  addSampleValues = (response, availableOutputs) => {
    if (!response) {
      return availableOutputs
    }

    for (const itm of Object.keys(availableOutputs)) {
      availableOutputs[itm].sampleValue = deepGet(
        response,
        availableOutputs[itm].key.split('.')
      )
    }

    return availableOutputs
  }

  buildOutputs = availableOutputs => {
    const { values } = this.props

    if (values?.outputs && !_isEmpty(values.outputs)) {
      return values.outputs
    }

    return availableOutputs
  }

  startTest = async () => {
    const {
      input: { onChange },
      values,
    } = this.props

    this.setState({ fetching: true })
    const response = await adaloBackendAxios.post(`/custom-action/test`, values)
    this.setState({ fetching: false })

    const data = response.data.response.data
    const fields = getOutputs(data)

    const availableOutputs = response.data.success
      ? this.addSampleValues(data, fields)
      : values?.testResults?.availableOutputs || {}

    onChange({ ...response.data, availableOutputs })

    const outputs = this.buildOutputs(availableOutputs)
    this.handleUpdateOutputs(data, outputs)
  }

  renderInitial() {
    return (
      <div className="test-connection-initial">
        <>
          <Loading large />
          <h2>Testing Request...</h2>
        </>
      </div>
    )
  }

  renderError() {
    const { fetching } = this.state

    const {
      input: { value },
    } = this.props

    const response = value.response || {}
    const { error } = value

    return (
      <div className="test-connection-results">
        <Button
          yellow
          type="button"
          onClick={this.startTest}
          loading={fetching}
          className="test-connection-button"
        >
          Try Again
        </Button>
        <h2>An error occurred when performing your API request</h2>
        <h3>{error}</h3>
        <p>URL Called:</p>
        <pre>
          <>{value.url}</>
        </pre>
        {response.data && (
          <>
            <p>Full Response:</p>
            <pre>{JSON.stringify(response.data, null, 2)}</pre>
          </>
        )}
      </div>
    )
  }

  handleAccordionTitle(e) {
    e.preventDefault()
  }

  renderSuccess() {
    const {
      input: { value },
      formName,
    } = this.props

    const response = value.response || {}

    return (
      <div className="test-request-results">
        <h2>Test Successful!</h2>
        <label>URL Called</label>
        <pre>
          <>{value.url}</>
        </pre>
        {response.data && (
          <>
            <div className="custom-action-outputs">
              <Field name="outputs" component={Outputs} formName={formName} />
            </div>
            <Accordion
              hideCarret
              title={
                <Button onClick={this.handleAccordionTitle} text>
                  Show Full Response
                </Button>
              }
              expandedTitle={
                <Button onClick={this.handleAccordionTitle} text>
                  Hide Full Response
                </Button>
              }
              renderChildren={() => (
                <pre>{JSON.stringify(response.data, null, 2)}</pre>
              )}
            />
          </>
        )}
      </div>
    )
  }

  render() {
    const {
      input: { value },
    } = this.props

    if (!value) {
      return this.renderInitial()
    } else if (!value.success) {
      return this.renderError()
    }

    return this.renderSuccess()
  }
}

const mapStateToProps = (state, props) => {
  const selector = getFormValues(props.formName)

  return {
    values: selector(state),
  }
}

export default connect(mapStateToProps, { change })(TestRequest)
