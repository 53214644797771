import React from 'react'
import { useSelector } from 'react-redux'

import { isFeatureEnabled } from 'ducks/organizations'

import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'
import FreeTrial from 'components/Shared/FreeTrial'

import ApiKeyItem from './ApiKeyItem'

const tooltip =
  `Unsure about how to use an API Key or why you even need one?` +
  `\n\nDon't worry we've got you covered in this `

const ApiKey = ({ appId }) => {
  const isEnabled = useSelector(state => isFeatureEnabled(state, 'publicApi'))

  return (
    <>
      <div className="app-settings-api-key">
        <div className="app-settings-api-key header">
          <div className="app-settings-api-key header title">API Key</div>
          <div className="app-settings-api-key header help">
            <Tooltip
              tooltip={
                <>
                  {''}
                  {tooltip}{' '}
                  <a
                    href="https://www.youtube.com/watch?v=dWJWNXn3RPc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help doc
                  </a>{' '}
                </>
              }
              placement="bottom-start"
              hideArrow
            >
              <Icon type="help-small" />
            </Tooltip>
          </div>
        </div>
      </div>
      {isEnabled && <ApiKeyItem appId={appId} />}
      <FreeTrial appId={appId} type="api" />
    </>
  )
}

export default ApiKey
