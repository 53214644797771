import React, { Component } from 'react'

import ActionList from './ActionList'

import './Form.scss'

export default class DummyActionForm extends Component {
  render() {
    const { appId, onAdd, componentId, objectId, reference, dummyActions } =
      this.props

    return (
      <div className="action-item-form">
        <ActionList
          appId={appId}
          dummyActions={dummyActions}
          componentId={componentId}
          objectId={objectId}
          reference={reference}
          onAdd={onAdd}
        />
      </div>
    )
  }
}
