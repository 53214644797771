import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

// DUCKS
import { getLibraryBindingSuggestions } from 'ducks/recommender'
import { selectObject } from 'ducks/editor/objects'

// UTILS
import { getLabel } from 'utils/sources'

// COMPONENTS
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'

const SelectDefaultValueControl = props => {
  const { appId, componentId, objectId, value, onChange } = props

  const object = useSelector(state => selectObject(state, objectId))

  // get binding options for menu
  const options = useSelector(state => {
    const suggestions = getLibraryBindingSuggestions(
      state,
      appId,
      componentId,
      objectId,
      [{ type: 'belongsTo', tableId: object?.select?.source?.tableId }]
    )

    return suggestions
  })

  // get label for select value
  const label = useSelector(state => getLabel(state, value, appId, componentId))
  const selectLabel = () => label

  return (
    <MenuControl
      name="defaultValue"
      displayName="Default Value"
      getLabel={selectLabel}
      options={options}
      value={value}
      onChange={onChange}
      reference="libraryProp"
    />
  )
}

SelectDefaultValueControl.propTypes = {
  appId: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SelectDefaultValueControl
