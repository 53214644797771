import React from 'react'
import { connect } from 'react-redux'
import { useFlags } from 'flags'

import { updateTableField } from 'ducks/apps/datasources'
import { startDrag } from 'ducks/editor/tables'

import HeaderCell from './HeaderCell'
import SelectCell from './SelectCell'

const Header = ({
  table,
  fields,
  tableLayout,
  onResizeColumn,
  onRemoveColumn,
  startDrag,
  blockedList,
  handleBlockedListToggle,
  blockedListLength,
  appId,
  datasourceId,
  tableId,
  updateTableField,
}) => {
  const { hasExposedRecordId } = useFlags()

  const handleUpdateField = (fieldId, field) => {
    updateTableField({
      appId,
      datasourceId,
      tableId,
      fieldId,
      field,
    })
  }

  return (
    <>
      <div className="data-browser-header">
        <SelectCell
          header
          checked={blockedList}
          blockedListLength={blockedListLength}
          handleChange={handleBlockedListToggle}
        />
        {fields.map(fieldId => (
          <HeaderCell
            editable
            key={fieldId}
            fieldId={fieldId}
            field={table.fields[fieldId]}
            width={tableLayout[fieldId]}
            onResize={onResizeColumn}
            onRemove={onRemoveColumn}
            onUpdate={handleUpdateField}
            onDragStart={startDrag}
          />
        ))}
        {hasExposedRecordId ? (
          <HeaderCell
            fieldId="id"
            name="ID"
            width={tableLayout.id}
            onResize={onResizeColumn}
          />
        ) : null}
        <HeaderCell
          fieldId="created_at"
          name="Created"
          width={tableLayout.created_at}
          onResize={onResizeColumn}
        />
        <HeaderCell
          fieldId="updated_at"
          name="Updated"
          width={tableLayout.updated_at}
          onResize={onResizeColumn}
        />
      </div>
    </>
  )
}

export default connect(null, { updateTableField, startDrag })(Header)
