import { useState } from 'react'

import DebugOptions from './DebugOptions'

import './DebugPanel.scss'

interface OptionsSectionProps {
  defaultOptions: DebugOptions
  onChange: (value: DebugOptions) => void
}

function OptionsSection({
  defaultOptions: options,
  onChange,
}: OptionsSectionProps): React.ReactElement<OptionsSectionProps> {
  const [currentOptions, setCurrentOptions] = useState<DebugOptions>(options)

  const handleOptionsChanged = (changes: Partial<DebugOptions>) => {
    const updated: DebugOptions = {
      ...currentOptions,
      ...changes,
    }
    setCurrentOptions(updated)

    onChange(updated)
  }

  const handleIncludeChildrenChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target

    handleOptionsChanged({
      jsonIncludeChildren: checked,
    })
  }

  return (
    <>
      <h2>Options</h2>
      <p>
        <input
          id="debug-include-children"
          type="checkbox"
          onChange={handleIncludeChildrenChanged}
          defaultChecked={currentOptions.jsonIncludeChildren}
        />{' '}
        <label htmlFor="debug-include-children">
          Include <code>children</code> in JSON
        </label>
      </p>
    </>
  )
}

export default OptionsSection
