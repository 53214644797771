import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { GROUP } from '@adalo/constants'

import { setLayersHover, setSelection } from 'ducks/editor/selection'
import { getCurrentAppId } from 'ducks/editor/objects'
import { getAppLibraries } from 'ducks/apps'

import { getObjectName } from 'utils/naming'
import { hasUnlicensedLibrary } from 'utils/libraries'
import { getTypeNameForObject } from 'utils/objectNames'

import GenericInspectRow from './GenericRow'

class GroupControlChild$ extends Component {
  static displayName = 'GroupControlChild'
  static propTypes = {
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    setSelection: PropTypes.func.isRequired,
    setLayersHover: PropTypes.func.isRequired,
  }

  handleMouseEnter = () => {
    const { setLayersHover, object } = this.props
    setLayersHover(object)
  }

  handleMouseLeave = () => {
    const { setLayersHover } = this.props
    setLayersHover(null)
  }

  handleClick = () => {
    const { setSelection, object } = this.props
    setSelection(object.id, false)
  }

  render() {
    const { object, libraries } = this.props

    const unlicensedLibrary = hasUnlicensedLibrary(object, libraries)

    return (
      <div
        className={classnames('group-child-component', {
          'group-child-component-unlicensed': unlicensedLibrary,
        })}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div>
          <div className="group-child-component-type">
            {getTypeNameForObject(object)}
          </div>
          <div className="group-child-component-name">
            {getObjectName(object)}
          </div>
        </div>
        <i className="material-icons">arrow_forward</i>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const appId = getCurrentAppId(state)
  const libraries = getAppLibraries(state, appId)

  return { libraries }
}

const GroupControlChild = connect(mapStateToProps, {
  setLayersHover,
  setSelection,
})(GroupControlChild$)

export default class GroupControl extends Component {
  static propTypes = {
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf([GROUP]),
    }).isRequired,
  }

  render() {
    const { filteredChildren: children = [] } = this.props

    return (
      <GenericInspectRow
        className="group-components-inspect-row"
        title="Components"
      >
        {children.map(child => (
          <GroupControlChild key={child.id} object={child} />
        ))}
      </GenericInspectRow>
    )
  }
}
