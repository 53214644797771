import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { dataTypes } from '@adalo/constants'

import { getFeatureFlag } from 'ducks/featureFlags'
import { getFieldIcon } from 'utils/icons'

import Icon from 'components/Shared/Icon'

import Cell from './Cell'

class HeaderCell extends Component {
  handleChange = name => {
    const { fieldId, onUpdate } = this.props

    if (onUpdate) {
      onUpdate(fieldId, { name })
    }
  }

  getEditMenu = () => {
    const { field } = this.props

    const menu = [
      {
        label: 'Delete Column',
        value: 'delete',
        disabled: field.isPrimaryField,
      },
    ]

    if (!field.isPrimaryField) {
      menu.push({
        label: 'Make Primary',
        value: 'primary',
        disabled: field.type.type,
      })
    }

    return menu
  }

  handleMenuSelect = value => {
    if (value === 'delete') {
      const { onRemove, fieldId, field } = this.props
      const { name } = field

      window.setTimeout(() => {
        if (window.confirm(`Are you sure you want to delete ${name}?`)) {
          onRemove(fieldId)
        }
      }, 0)
    }

    if (value === 'primary') {
      const { fieldId, onUpdate } = this.props

      onUpdate(fieldId, { isPrimaryField: true })
    }
  }

  handleMouseDown = e => {
    const { fieldId, field, editable, onDragStart } = this.props
    const rect = e.currentTarget.getBoundingClientRect()
    const offsetX = e.clientX - rect.left + 10

    if (!editable || field.isPrimaryField) return null

    return onDragStart(fieldId, offsetX)
  }

  render() {
    const {
      editable,
      field,
      fieldId,
      name,
      onResize,
      width,
      hasExposedRecordId,
    } = this.props

    const draggable = editable && !field.isPrimaryField

    const getIcon = () => {
      if (field) {
        return field && getFieldIcon(field.type)
      }

      if (hasExposedRecordId && fieldId === 'id') {
        return getFieldIcon(dataTypes.NUMBER)
      }

      return getFieldIcon(dataTypes.DATE_ONLY)
    }

    const icon = getIcon()

    return (
      <Cell
        key={fieldId}
        fieldId={fieldId}
        width={width}
        onResize={onResize}
        className={classNames('data-browser-header-item', {
          'data-browser-header-item-editable': editable,
        })}
      >
        <span
          className={classNames({ 'data-browser-drag-target': draggable })}
          onMouseDown={this.handleMouseDown}
        >
          <Icon type={icon} />
          <span className="text">{field ? field.name : name}</span>
        </span>
      </Cell>
    )
  }
}

export default connect(state => ({
  hasExposedRecordId: getFeatureFlag(state, 'hasExposedRecordId'),
}))(HeaderCell)
