import { useEffect, useMemo } from 'react'
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { FeatureTemplate, getFeatureTemplates } from 'ducks/featureTemplates'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import {
  ensureScreenTemplatesAreLoaded,
  getScreenTemplatesState,
} from 'ducks/editor/screenTemplates'

import { assetURL } from 'utils/assets'

import Tab from '../Tab'
import { renderFeatureOption } from './featureHelpers'

const sections = [
  {
    label: 'Blank Screens',
    children: [],
  },
  {
    label: 'Most Used Features',
    children: [],
  },
  {
    label: 'Browsing',
    children: [],
  },
  {
    label: 'Social',
    children: [],
  },
  {
    label: 'Purchasing',
    children: [],
  },
  {
    label: 'Booking',
    children: [],
  },
  {
    label: 'Assigning',
    children: [],
  },
  {
    label: 'Admin',
    children: [],
  },
] as { label: string; children: FeatureTemplate[] }[]

type FeaturesTabProps = {
  appId: string
} & RouteComponentProps

const FeaturesTab: React.FC<FeaturesTabProps> = ({ appId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const featureTemplates = useSelector(getFeatureTemplates)

  const { list } = useSelector(state =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getScreenTemplatesState(state, 'responsive')
  ) as {
    list: Partial<FeatureTemplate & { thumbnail: string }>[]
  }

  const datasources = useSelector(state => getDefaultDatasource(state, appId))

  const datasourceId = datasources?.id

  useEffect(() => {
    if (location.pathname.endsWith('/add')) {
      history.push(`/apps/${appId}/screens/add/feature`)
    }

    dispatch(ensureScreenTemplatesAreLoaded('responsive'))
  }, [history, location, appId])

  const options = useMemo(
    () =>
      sections.map(section => {
        if (Array.isArray(list) && section.label === 'Blank Screens') {
          return {
            ...section,
            children: list
              .filter(({ name }) => name === 'Blank Screen')
              .map(screenTemplate => {
                const { name, thumbnail } = screenTemplate

                const child = { ...screenTemplate, image: assetURL(thumbnail) }

                return {
                  label: name || 'Screen',
                  value: child as FeatureTemplate,
                }
              }),
          }
        }

        const children = featureTemplates
          ?.filter(feature => {
            if (section.label === 'Most Used Features') {
              return feature.category.includes('most_used')
            }

            return feature.category.includes(section.label.toLowerCase())
          })
          .map(feature => {
            return {
              label: feature.name,
              value: feature,
            }
          })

        return { ...section, children: children || [] }
      }),
    [list, featureTemplates, sections]
  )

  return (
    <Tab
      key="feature"
      emptyMessage="No features found"
      options={options}
      platform="responsive"
      renderItem={({ value }: { value: FeatureTemplate }) =>
        renderFeatureOption({
          value,
          history,
          dispatch,
          appId,
          datasourceId,
          label: value.name,
        })
      }
      defaultOpen={['Blank Screens']}
      className="editor-features-tab"
    />
  )
}

export default withRouter(FeaturesTab)
