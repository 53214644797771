import React, { Component } from 'react'
import { statusBarStyles } from '@adalo/constants'
import StatusBar from '@protonapp/react-status-bar'

import '@protonapp/react-status-bar/styles.css'

export default class StausBarWrapper extends Component {
  static defaultProps = {
    opacity: 1,
  }

  render() {
    const { component, zoom, opacity } = this.props
    const { statusBarStyle, width } = component

    const light = statusBarStyle === statusBarStyles.LIGHT_CONTENT

    if (statusBarStyle === statusBarStyles.HIDDEN) {
      return null
    }

    const transform = `scale(${zoom.scale})`

    return (
      <g transform={transform} opacity={opacity}>
        <foreignObject width={width} height={20} pointerEvents="none">
          <StatusBar platform="ios" light={light} />
        </foreignObject>
      </g>
    )
  }
}
