import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { GroupedAccordion } from 'components/Shared/Accordion'

import {
  getAccordionState,
  openAccordion,
  closeAccordion,
} from 'ducks/accordions'

import { getTable, getTableIds } from 'ducks/apps/datasources'

import AccordionTitle from './Title'
import AccordionChildren from './Children'

export const GROUP = 'database-collections'

const Item = props => {
  const { appId, closeAccordion, datasourceId, table, tables, tableId, name } =
    props

  const url = `/apps/${appId}/data/${datasourceId}/${tableId}`

  const [isEditingCollection, setIsEditingCollection] = useState(false)

  const toggleEditCollection = () => {
    return setIsEditingCollection(!isEditingCollection)
  }

  useEffect(() => {
    const { accordion, openAccordion } = props
    // if the user is wanting to edit collection, but accordion state is closed
    // we need to open it up
    if (isEditingCollection && !accordion) openAccordion(GROUP, tableId)
  }, [isEditingCollection])

  // function to render title content
  const renderTitle = () => (
    <AccordionTitle
      url={url}
      group={GROUP}
      toggleEditCollection={toggleEditCollection}
      {...props}
    />
  )

  // function to render children within an accordion
  const renderChildren = () => {
    const { accordion, updateTable, openAccordion } = props

    return (
      <AccordionChildren
        appId={appId}
        datasourceId={datasourceId}
        table={table}
        tableId={tableId}
        tables={tables}
        closeAccordion={closeAccordion}
        toggleEditCollection={toggleEditCollection}
        isEditingCollection={isEditingCollection}
        name={name}
        updateTable={updateTable}
        accordion={accordion}
        openAccordion={openAccordion}
      />
    )
  }

  return (
    <GroupedAccordion
      title={renderTitle()}
      appId={appId}
      isExternal={table.type === 'api' || table.type === 'xano'}
      type={table.type}
      itemId={tableId}
      group={GROUP}
      renderChildren={renderChildren}
      className="data-panel-accordion"
    />
  )
}

const mapStateToProps = (state, { appId, datasourceId, tableId }) => ({
  accordion: getAccordionState(state, GROUP),
  table: getTable(state, appId, datasourceId, tableId),
  tables: getTableIds(state, appId, datasourceId),
})

const mapDispatchToProps = {
  openAccordion,
  closeAccordion,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(Item)

export default withRouter(connected)
