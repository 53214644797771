import React from 'react'
import { Field, reduxForm } from 'redux-form'
import deepEqual from 'deep-equal'
import { connect, useSelector } from 'react-redux'
import classNames from 'classnames'
import { dataTypes } from '@adalo/constants'

import { getOptions, getSimplePropertyOptions } from 'utils/dataTypes'

import { getTable } from 'ducks/apps/datasources'
import { getActiveState, getTrialState } from 'ducks/organizations'
import { getThirdPartyApiKeyFor } from 'ducks/thirdPartyApiKeys'

import useHandleTrialOrUpgrade from 'hooks/useHandleTrialOrUpgrade'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import GoogleHelpLink, {
  GoogleUpgradeLink,
} from 'components/Shared/GoogleHelpLink'
import PrerequisiteAlert from 'components/Shared/PrerequisiteAlert'

import {
  BEFORE,
  START_TRIAL,
  THEMES,
  UPGRADE,
} from '../../../../../../../constants'
import RelationshipField from './RelationshipField'

const FORM_NAME = 'accordionChildrenContent'

const customComparator = (optionValue, value) => {
  if (value && value.type) {
    value = 'relationship'
  }

  return deepEqual(value, optionValue)
}

const PASSWORD_MENU_OPTION = {
  label: (
    <>
      <Icon type="lock" />
      Password
    </>
  ),
  value: 'password',
}

const AccordionChildrenContent = ({
  appId,
  handleSubmit,
  tables,
  tableId,
  datasourceId,
  relationshipTable,
  initialValues,
  googleApiKey,
  closeAccordion,
}) => {
  const { trialState, isPaidOrg } = useSelector(state => ({
    trialState: getTrialState(state),
    isPaidOrg: getActiveState(state),
  }))

  const premiumFeatureType =
    initialValues.type === dataTypes.LOCATION
      ? 'geolocation'
      : initialValues.type

  const handleTrialOrUpgrade = useHandleTrialOrUpgrade({
    appId,
    trialState,
    type: premiumFeatureType,
    isPaidOrg,
  })

  const table = tables.find(t => t.tableId === tableId)
  const isAPI = table.type === 'api'

  // If initialValues.type is an object, then the field is a relationship
  const isRelationship = Boolean(initialValues?.type?.type)

  const menu = isAPI
    ? getSimplePropertyOptions({ api: true })
    : getOptions(tables, datasourceId)

  if (!isAPI) {
    menu.push(PASSWORD_MENU_OPTION)
  }

  const getPrerequisites = () => {
    if (!initialValues) {
      return null
    }

    let name
    let isPrerequisiteMet
    let footer = null

    switch (initialValues.type) {
      case dataTypes.LOCATION:
        name = 'Google Maps API Key'
        isPrerequisiteMet = googleApiKey && googleApiKey.isValid
        footer = <GoogleHelpLink />

        break
      default:
        return null
    }

    return (
      <PrerequisiteAlert
        isPrerequisiteMet={isPrerequisiteMet}
        name={name}
        footer={footer}
        context="data"
      />
    )
  }

  const { disabled, type, enabled } = initialValues
  const isLocation = type === dataTypes.LOCATION
  const disabledText = trialState === BEFORE ? START_TRIAL : UPGRADE
  const alertDisabled = isAPI || disabled

  const isExternal = table.type === 'api' || table.type === 'xano'

  const upgradeMessage = () => {
    if (isLocation) {
      return <GoogleUpgradeLink appId={appId} />
    } else {
      return (
        <div className="disabled-alert">
          Access to this feature has been paused. Please{' '}
          <span onClick={handleTrialOrUpgrade}>{disabledText}</span>.
        </div>
      )
    }
  }

  return (
    <form
      className="data-edit-collection-property form-inspect-field-wrapper"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {!enabled ? upgradeMessage(type) : null}
      <div className="library-inspect-menu-control">
        {isRelationship ? (
          <RelationshipField
            initialValues={initialValues}
            relationshipTable={relationshipTable}
            table={table}
          />
        ) : (
          <>
            <p>Type</p>
            <Field
              name="type"
              component={WrappedSelect}
              placeholder="Select Type"
              options={menu}
              comparator={customComparator}
              disabled="disabled"
              menuTheme={THEMES.DATA}
            />
          </>
        )}
      </div>
      {!isExternal && (
        <div className="library-inspect-text-control">
          <p>Name</p>
          <div className="library-inspect-text-control-input-wrapper">
            <Field
              name="name"
              component="input"
              placeholder="Property Name"
              className={classNames('library-inspect-text-control-input', {
                disabled: alertDisabled,
              })}
              disabled={alertDisabled}
            />
          </div>
        </div>
      )}
      {getPrerequisites()}
      {!isExternal && (
        <div className="actions">
          <Button type="button" text onClick={() => closeAccordion(tableId)}>
            Cancel
          </Button>
          <Button orange>Save</Button>
        </div>
      )}
    </form>
  )
}

const WrappedAccordionChildrenContent = reduxForm({
  form: FORM_NAME,
  validate: values => {
    if (!values.name) return { name: 'Required' }

    return null
  },
})(AccordionChildrenContent)

const mapStateToProps = (state, { appId, datasourceId, initialValues }) => {
  const { type } = initialValues
  const { tableId } = type

  return {
    relationshipTable: getTable(state, appId, datasourceId, tableId),
    googleApiKey: getThirdPartyApiKeyFor(state, appId, 'google'),
  }
}

const connected = connect(mapStateToProps)(WrappedAccordionChildrenContent)

export default connected
