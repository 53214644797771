import React, { useState, useRef, useEffect, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, isEqual as _isEqual } from 'lodash'

import { requestUsers, getUsers } from 'ducks/users/index'
import type { UserState, User } from 'ducks/users/index'

import Page from 'components/Shared/Page'

import { DEBOUNCE_TIME } from '../../constants'
import { AdminUserOrganization } from './Apps'
import './Admin.scss'

interface ItemParams {
  user: User
}

const UserItem = ({ user }: ItemParams): ReactElement => (
  <Link className="admin-user-item" to={`/admin/users/${user.id}`}>
    <h5>{user.name}</h5>
    <p>{user.email}</p>
    <p>{user.createdAt}</p>
    <p>
      {user.Organizations.map(org => (
        <AdminUserOrganization key={org.id} organization={org} />
      ))}
    </p>
  </Link>
)

const AdminUsers = (): ReactElement => {
  const mounted = useRef<boolean>(false)
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const users = useSelector((state: UserState) => getUsers(state), _isEqual)

  const handleQuery = debounce(() => {
    if (!value) {
      return dispatch(requestUsers())
    }

    return dispatch(requestUsers({ query: value }))
  }, DEBOUNCE_TIME)

  useEffect(() => {
    if (!mounted.current) {
      dispatch(requestUsers())
      mounted.current = true
    } else {
      handleQuery()
    }
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    setValue(e.target.value)
  }

  return (
    <Page className="admin-users-page" title="Users">
      <Link to="/admin/marketplace">Marketplace Libraries</Link>
      {` • `}
      <Link to="/admin/builds">Builds</Link>
      <section className="users-header">
        <h1>Users</h1>
        <input
          type="text"
          onChange={handleChange}
          value={value}
          placeholder="Search by name or email"
          autoFocus
        />
      </section>
      <div className="admin-users-header">
        <p>Name</p>
        <p>Email</p>
        <p>Signup Date</p>
        <p>Team(s)</p>
      </div>
      {users.map(u => (
        <UserItem key={u.id} user={u} />
      ))}
    </Page>
  )
}

export default AdminUsers
