import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './Profile.scss'

const Profile = props => {
  const { className, color, profileText, medium, big } = props

  return (
    <div
      className={classNames('profile', className, {
        [`profile-${color}`]: color,
        'profile-medium': medium,
        'profile-big': big,
      })}
    >
      {profileText}
    </div>
  )
}

export default Profile

Profile.propTypes = {
  color: PropTypes.oneOf([
    'teal',
    'orange',
    'yellow',
    'green',
    'purple',
    'darkPink',
    'white',
    'darkerGray',
  ]),
}
