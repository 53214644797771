import { GROUP, LABEL, SECTION, INPUT } from '@adalo/constants'
import { deepFind } from '@adalo/utils'

import rgb from 'color-space/rgb'
import 'color-space/hsl'

import { defaults } from './objects'
import { getLines } from './text'

export const convertToInput = obj => {
  const { id, text, name, x, y, width, height } = obj

  let input = {
    ...defaults[INPUT],
    id,
    x,
    y,
    width,
    height,
    type: INPUT,
    name: name || text,
  }

  if (obj.type === LABEL) {
    input = {
      ...input,
      ...getAttributesFromLabel(obj),
      padding: 0,
    }
  } else if (obj.type === SECTION) {
    input = {
      ...input,
      ...getAttributesFromSection(obj),
    }
  } else if (obj.type === GROUP) {
    const labels = deepFind([obj], o => o.type === LABEL && !o.hidden)
    const sections = deepFind([obj], o => o.type === SECTION && !o.hidden)

    if (labels.length > 0) {
      input = { ...input, ...getAttributesFromLabel(labels[0]) }
    }

    if (sections.length > 0) {
      input = { ...input, ...getAttributesFromSection(sections[0]) }
    }

    if (labels.length > 0 && sections.length > 0) {
      input.padding = labels[0].x - sections[0].x

      if (input.padding < 0) {
        input.padding = 2
      }
    }

    const { fontSize } = input

    if (input.height > 3 * fontSize) {
      input.multiline = true
    }

    // Add left/right padding + height
  } else {
    return obj
  }

  return input
}

export const getAttributesFromLabel = label => {
  // Check color of label.
  // If has opacity ~0.5 or gray color, consider placeholder
  // Otherwise consider value
  // In any case, use font size / fontFamily
  const { fontSize } = label
  const lines = getLines(label)
  const isGrey = colorIsGrey(label.color)
  const color = isGrey ? '#000' : label.color

  const obj = {
    color,
    fontSize,
    multiline: !!(lines.length > 1),
  }

  if (isGrey) {
    obj.placeholder = label.text
    obj.placeholderColor = label.color
    obj.color = '#000'
  } else {
    obj.defaultValue = label.text
  }

  return obj
}

export const getAttributesFromSection = section => {
  // Use background color, border style, background color, wdth, height

  const {
    backgroundColor,
    backgroundStyle,
    borderColor,
    borderWidth,
    borderStyle,
  } = section

  const obj = {
    backgroundColor,
    backgroundStyle,
    borderColor,
    borderWidth,
    borderStyle,
  }

  return obj
}

export const colorIsGrey = (color, opacity = 1) => {
  let red
  let green
  let blue
  let match
  let alpha = 1

  const rgbPattern = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(,\s*([\d\.]+)){0,1}/

  if ((match = color.match(rgbPattern))) {
    red = +match[1]
    green = +match[2]
    blue = +match[3]

    if (match[5]) {
      alpha = match[5]
    }
  } else if ((match = color.match(/^#(\w{2})(\w{2})(\w{2})$/))) {
    red = parseInt(match[1], 16)
    green = parseInt(match[2], 16)
    blue = parseInt(match[3], 16)
  } else if ((match = color.match(/^#(\w)(\w)(\w)$/))) {
    red = parseInt(`${match[1]}${match[1]}`, 16)
    green = parseInt(`${match[2]}${match[2]}`, 16)
    blue = parseInt(`${match[3]}${match[3]}`, 16)
  }

  if (opacity * alpha <= 0.7) {
    return true
  }

  const [, saturation, balance] = rgb.hsl([red, green, blue])

  if (saturation < 25 && balance > 40) {
    return true
  }

  return false
}
