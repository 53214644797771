import React, { useCallback, useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import WebFont from 'webfontloader'
import { debounce } from 'lodash'

// components
import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import CalloutCard from 'components/Shared/CalloutCard'
import Icon from 'components/Shared/Icon'
import FreeTrial from 'components/Shared/FreeTrial'

// ducks
import { getAppBranding, updateApp, getApp } from 'ducks/apps'
import {
  getTrialState,
  getOrganization,
  isFeatureEnabled,
  getActiveState,
} from 'ducks/organizations'

import useHandleTrialOrUpgrade from 'hooks/useHandleTrialOrUpgrade'

// utils
import {
  fontPairings,
  freeFontOptions,
  popularBodyFonts,
  popularHeadingFonts,
} from 'utils/fonts'
import { adaloBackendAxios } from 'utils/io/http/axios'

import {
  THEMES,
  DEBOUNCE_TIME,
  START_TRIAL,
  UPGRADE,
  BEFORE,
} from '../../constants'

let loadedFonts = []

const lockIcon = <Icon type="lock" color="darkerGray" small />

const useIsEnabled = () => {
  const isEnabled = useSelector(state => isFeatureEnabled(state, 'customFonts'))

  return isEnabled
}

const Fonts = () => {
  const dispatch = useDispatch()
  const { appId } = useParams()

  // selectors
  const branding = useSelector(state => getAppBranding(state, appId))
  const app = useSelector(state => getApp(state, appId))

  const org = useSelector(state =>
    getOrganization(state, app?.Organization?.id)
  )

  const { active } = org || {}
  const isEnabled = useIsEnabled()
  const isActive = isEnabled

  // state
  const [headingOptions, setHeadingOptions] = useState([])
  const [bodyOptions, setBodyOptions] = useState([])
  const [headingSearchValue, setHeadingSearchValue] = useState('')
  const [bodySearchValue, setBodySearchValue] = useState('')

  const headingFont = branding?.fonts?.heading?.family || 'default'
  const bodyFont = branding?.fonts?.body?.family || 'default'
  const searchPlaceholder = 'Search Over 1,000 Fonts...'
  const defaultLabel = 'Standard System Font'

  const googleFontsObj = () => {
    return {
      label: 'Explore Available Fonts',
      icon: <Icon type="launch" color="darkPink" />,
      onClick: () => window.open('https://fonts.google.com', '_blank'),
      styles: { color: '#a82058', textTransform: 'uppercase' },
    }
  }

  const { trialState } = useSelector(getTrialState)

  const handleTrialOrUpgrade = useHandleTrialOrUpgrade({
    appId,
    trialState,
    type: 'customFonts',
    isPaidOrg: active,
  })

  const openModal = handleTrialOrUpgrade

  const handleHeadingOptions = useCallback(
    debounce(async value => {
      const fontLabel = headingFont === 'default' ? defaultLabel : headingFont

      const showTrial = trialState === BEFORE && !active

      const hoverContent = showTrial ? START_TRIAL : UPGRADE

      const selectedFont = {
        label: fontLabel,
        value: headingFont,
        type: 'hidden',
      }

      let options = [selectedFont]

      if (value) {
        const { data } = await adaloBackendAxios.get(`/fonts`, {
          params: { search: value },
        })

        const googleFontOptions = data.map(font => ({
          label: font.family,
          value: font.family,
          styles: { fontFamily: font.family },
        }))

        if (googleFontOptions?.length > 0) {
          options = [selectedFont, ...googleFontOptions]
        } else {
          options = [
            selectedFont,
            {
              label: 'No fonts found',
              disabled: true,
              styles: { textAlign: 'center' },
            },
          ]
        }
      } else if (isActive) {
        options = [
          selectedFont,
          { label: defaultLabel, value: 'default' },
          null,
          { label: 'Popular Heading Fonts', inline: true },
          ...popularHeadingFonts.map(({ value, label }) => ({
            value,
            label,
            styles: { fontFamily: value },
          })),
        ]
      } else {
        options = [
          selectedFont,
          { label: defaultLabel, value: 'default' },
          null,
          { label: 'Free Fonts', inline: true },
          ...freeFontOptions.map(({ value, label }) => ({
            value,
            label,
            styles: { fontFamily: value },
          })),
          null,
          { label: 'Popular Heading Fonts', inline: true },
          ...popularHeadingFonts.map(({ label, value }) => ({
            label,
            value,
            styles: { fontFamily: value },
            rightIcon: lockIcon,
            locked: true,
            hoverContent,
            onClick: openModal,
          })),
        ]
      }

      options.push(googleFontsObj())

      return setHeadingOptions(options)
    }, DEBOUNCE_TIME),
    [isActive, trialState]
  )

  const handleBodyOptions = useCallback(
    debounce(async value => {
      const fontLabel = bodyFont === 'default' ? defaultLabel : bodyFont

      const selectedFont = { label: fontLabel, value: bodyFont, type: 'hidden' }

      const showTrial = trialState === BEFORE && !active

      const hoverContent = showTrial ? START_TRIAL : UPGRADE

      let pairings = fontPairings[headingFont] || []

      if (pairings.length > 0) {
        pairings = [
          { label: `Popular pairings with ${headingFont}`, inline: true },
          ...fontPairings[headingFont].map(f => ({
            label: f,
            value: f,
            styles: { fontFamily: f },
            rightIcon: isActive ? null : lockIcon,
            locked: !isActive,
            hoverContent,
            onClick: !isActive ? openModal : null,
          })),
          null,
        ]
      }

      let options = [selectedFont]

      if (value) {
        const { data } = await adaloBackendAxios.get(`/fonts`, {
          params: { search: value },
        })

        const googleFontOptions = data.map(font => ({
          label: font.family,
          value: font.family,
          styles: { fontFamily: font.family },
        }))

        if (googleFontOptions?.length > 0) {
          options = [selectedFont, ...googleFontOptions]
        } else {
          options = [
            selectedFont,
            {
              label: 'No fonts found',
              disabled: true,
              styles: { textAlign: 'center' },
            },
          ]
        }
      } else if (isActive) {
        options = [
          selectedFont,
          { label: defaultLabel, value: 'default' },
          null,
          ...pairings,
          { label: 'Popular Body Fonts', inline: true },
          ...popularBodyFonts.map(({ value, label }) => ({
            value,
            label,
            styles: { fontFamily: value },
          })),
        ]
      } else {
        options = [
          selectedFont,
          { label: defaultLabel, value: 'default' },
          null,
          { label: 'Free Fonts', inline: true },
          ...freeFontOptions.map(({ value, label }) => ({
            value,
            label,
            styles: { fontFamily: value },
          })),
          null,
          ...pairings,
          { label: 'Popular Body Fonts', inline: true },
          ...popularBodyFonts.map(({ label, value }) => ({
            label,
            value,
            styles: { fontFamily: value },
            rightIcon: lockIcon,
            locked: true,
            hoverContent,
            onClick: openModal,
          })),
        ]
      }

      options.push(googleFontsObj())

      return setBodyOptions(options)
    }, DEBOUNCE_TIME),
    [headingFont, isActive, trialState]
  )

  const handleHeadingSearch = e => setHeadingSearchValue(e.target.value)
  const handleBodySearch = e => setBodySearchValue(e.target.value)

  useEffect(() => {
    handleHeadingOptions(headingSearchValue)
    handleBodyOptions(bodySearchValue)
  }, [isActive, headingSearchValue, bodySearchValue, headingFont, trialState])

  const handleLoadFonts = () => {
    const families = []

    for (const opt of headingOptions) {
      if (!opt || !opt.value || opt.value === 'default') continue

      families.push(opt.value)
    }

    for (const opt of bodyOptions) {
      if (!opt || !opt.value || opt.value === 'default') continue

      families.push(opt.value)
    }

    const mergedFamilies = [...loadedFonts, ...families]

    loadedFonts = mergedFamilies.filter(
      (c, index) => mergedFamilies.indexOf(c) === index
    )

    if (families.length > 0) WebFont.load({ google: { families: loadedFonts } })
  }

  useEffect(() => {
    handleLoadFonts()
  }, [headingOptions, bodyOptions])

  const handleChange = async newValue => {
    const property = Object.keys(newValue)[0]
    const family = newValue[property]

    const previousValue = branding.fonts && branding.fonts[property]

    // eager update to prevent flicker
    dispatch(
      updateApp(appId, {
        branding: {
          ...branding,
          fonts: { ...branding?.fonts, [property]: { family } },
        },
      })
    )

    try {
      const { data } = await adaloBackendAxios.get(`/fonts?family=${family}`)
      const { category } = data
      const variants = data.variants?.filter(v => !v.includes('italic'))

      const updates = { family, variants, category }

      dispatch(
        updateApp(appId, {
          branding: {
            ...branding,
            fonts: { ...branding?.fonts, [property]: updates },
          },
        })
      )
    } catch (err) {
      dispatch(
        updateApp(appId, {
          branding: {
            ...branding,
            fonts: { ...branding?.fonts, [property]: previousValue },
          },
        })
      )
    }
  }

  return (
    <>
      <MenuControl
        displayName="Headings"
        onChange={handleChange}
        name="heading"
        options={headingOptions}
        value={headingFont}
        tooltip="Headings are commonly used for titles, subtitles, &amp; other primary text and are typically a bolder, more expressive font."
        handleSearch={isActive ? handleHeadingSearch : null}
        searchValue={headingSearchValue}
        searchPlaceholder={searchPlaceholder}
        menuTheme={THEMES.BRANDING}
      />
      <MenuControl
        displayName="Body"
        onChange={handleChange}
        name="body"
        options={bodyOptions}
        value={bodyFont}
        tooltip="Body font is used for a majority of the text in your app and is typically a simple easy to read font."
        handleSearch={isActive ? handleBodySearch : null}
        searchValue={bodySearchValue}
        searchPlaceholder={searchPlaceholder}
        menuTheme={THEMES.BRANDING}
      />
      <Callout isActive={isActive} openModal={openModal} appId={appId} />
    </>
  )
}

export default Fonts

const Callout = props => {
  const { isActive, appId } = props
  const isPaid = useSelector(getActiveState)

  const renderActiveContent = () => {
    return (
      <>
        <h3>
          <span role="img" aria-label="artist">
            👩‍🎨
          </span>{' '}
          Want help picking out fonts?
        </h3>
        <a
          href="https://www.adalo.com/posts/tips-tricks-for-using-fonts-in-your-mobile-and-web-apps"
          target="_blank"
          rel="noreferrer"
        >
          Learn more about typography here.
        </a>
      </>
    )
  }

  if (isActive && isPaid) {
    return (
      <CalloutCard className="callout-card-custom-fonts">
        {renderActiveContent()}
      </CalloutCard>
    )
  }

  return <FreeTrial appId={appId} type="customFonts" />
}
