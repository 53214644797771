import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MultiSelectMenu from '@protonapp/react-multi-menu'
import { traverse } from '@adalo/utils'
import classNames from 'classnames'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'

import '@protonapp/react-multi-menu/styles.css'
import './WrappedSelect.scss'
import './InlineForm.css'

export default class WrappedSelect extends Component {
  handleChange = newValue => {
    const { onChangeValue, input } = this.props
    const { onChange, value } = input || this.props

    if (value === newValue) {
      return
    }

    onChange(newValue)

    if (onChangeValue) {
      onChangeValue(newValue)
    }
  }

  setValue = props => {
    let { autoSelect, options, comparator, input } = props

    const { value, onChange } = input || this.props

    if (!autoSelect) {
      return
    }

    comparator = comparator || ((a, b) => a === b)

    if (typeof options === 'function') {
      options = options()
    }

    let currentOption

    traverse(options, opt => {
      if (opt && comparator(opt.value, value)) {
        currentOption = opt
      }
    })

    if (options.length > 0 && !currentOption) {
      if (options[0] === null) {
        onChange(null)
      } else {
        onChange(options[0].value)
      }
    } else if (options.length === 0 && value) {
      onChange(null)
    }
  }

  shouldComponentUpdate(nextProps) {
    this.setValue(nextProps)

    return true
  }

  componentDidMount() {
    this.setValue(this.props)
  }

  render() {
    const {
      className,
      disabled,
      boxed,
      input,
      rowHeight,
      meta,
      label,
      tooltip,
    } = this.props
    const { value, ...selectProps } = input || this.props
    const { touched, error } = meta || {}
    const baseClass = 'select-field'

    return (
      <div
        className={classNames('wrapped-select', className, {
          disabled,
          boxed,
          'wrapped-select-error': touched && error,
        })}
      >
        <div className={`${baseClass}__label-container`}>
          {label ? <label>{label}</label> : null}
          <div className={`${baseClass}__tooltip`}>
            {tooltip && (
              <Tooltip tooltip={tooltip} placement="bottom-start" hideArrow>
                <Icon type="help-small" />
              </Tooltip>
            )}
          </div>
        </div>
        <MultiSelectMenu
          {...this.props}
          {...selectProps}
          value={value}
          onChange={this.handleChange}
          menuClassName={className}
          rowHeight={rowHeight}
        />
        {touched && error ? (
          <div className="wrapped-select-error-message">{error}</div>
        ) : null}
      </div>
    )
  }
}

WrappedSelect.propTypes = {
  rowHeight: PropTypes.number,
}

WrappedSelect.defaultProps = {
  rowHeight: 32,
}
