import React, { Component } from 'react'
import { traverse } from '@adalo/utils'
import classNames from 'classnames'
import { VelocityTransitionGroup } from 'velocity-react'

import '@protonapp/react-multi-menu/styles.css'
import './InlineForm.css'
import './SelectList.scss'

export default class SelectList extends Component {
  handleChange = newValue => {
    const { onChangeValue, input } = this.props
    const { onChange, value } = input || this.props

    if (value === newValue) {
      return
    }

    onChange(newValue)

    if (onChangeValue) {
      onChangeValue(newValue)
    }
  }

  setValue = props => {
    let { autoSelect, options, comparator, input } = props

    const { value, onChange } = input || this.props

    if (!autoSelect) {
      return
    }

    comparator = comparator || ((a, b) => a === b)

    if (typeof options === 'function') {
      options = options()
    }

    let currentOption

    traverse(options, opt => {
      if (opt && comparator(opt.value, value)) {
        currentOption = opt
      }
    })

    if (options.length > 0 && !currentOption) {
      if (options[0] === null) {
        onChange(null)
      } else {
        onChange(options[0].value)
      }
    } else if (options.length === 0 && value) {
      onChange(null)
    }
  }

  shouldComponentUpdate(nextProps) {
    this.setValue(nextProps)

    return true
  }

  componentDidMount() {
    this.setValue(this.props)
  }

  render() {
    const {
      className,
      disabled,
      boxed,
      input,
      meta,
      label,
      options,
      defaultValue,
      dropdown = false,
    } = this.props

    let { value } = input || this.props

    if (!value && defaultValue) {
      value = defaultValue
    }

    const { touched, error } = meta || {}

    const selectListClassName = classNames('select-list', className, {
      disabled,
      boxed,
      'select-list-error': touched && error,
    })

    return (
      <div className={selectListClassName}>
        {label && <label>{label}</label>}

        <div className="select-list-options">
          {options.map((option, index) => {
            const selected = option.value === value
            const className = classNames('select-list-option', {
              selected,
              dropdown,
            })

            return (
              <div
                data-adalo-id={option.dataAdaloId}
                key={option.value}
                className={className}
                onClick={() => this.handleChange(option.value)}
              >
                <div className="select-list-option__title">
                  <div>
                    <p className="select-list-option-label">{option.label}</p>
                    {option.description && (
                      <p className="select-list-option-description">
                        {option.description}
                      </p>
                    )}
                  </div>
                  <div className="select-list-circle-check" />
                </div>
                <VelocityTransitionGroup
                  enter={{ animation: 'slideDown' }}
                  leave={{ animation: 'slideUp' }}
                  speed={3}
                >
                  {dropdown && selected && option.dropdownContent ? (
                    <div className="select-list-option__dropdown">
                      {option.dropdownContent}
                    </div>
                  ) : null}
                </VelocityTransitionGroup>
              </div>
            )
          })}
        </div>

        {touched && error && (
          <div className="select-list-error-message">{error}</div>
        )}
      </div>
    )
  }
}
