import React, { Component } from 'react'

import { getDayName, getMonthAbbr, formatDateOnly } from 'utils/datetime'

import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import '../Analytics.css'
import { adaloBackendAxios } from 'utils/io/http/axios'

export const findDomain = max => {
  let domainMax

  if (max < 40) {
    domainMax = Math.ceil(max / 4) * 4
  } else if (max >= 40 && max < 100) {
    const nearestFive = Math.ceil(max / 4 / 5) * 5
    domainMax = nearestFive * 4
  } else {
    const length = Math.max(Math.floor(Math.log10(Math.abs(max))), 0) - 2
    const multiplier = 10 ** length
    const ceil = max / (4 * multiplier)
    const nearestFive = Math.ceil(ceil / 5) * 5
    domainMax = nearestFive * multiplier * 4
  }

  return domainMax
}

export const formatTick = (number, decPlaces) => {
  decPlaces = 10 ** decPlaces
  const abbrev = ['k', 'm', 'b', 't']

  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    const size = 10 ** ((i + 1) * 3)

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1
        i += 1
      }

      number += abbrev[i]

      break
    }
  }

  return number
}

export class GraphTooltip extends Component {
  componentDidMount() {
    this.render()
  }

  render() {
    const { active } = this.props

    if (active) {
      const { payload, label, granularity } = this.props

      if (payload) {
        const { value } = payload[0]
        const date = new Date(`${label} 00:00`)
        let prefix = ''

        switch (granularity) {
          case 'day':
            prefix = getDayName(date, 'en-US')

            break
          case 'week':
            prefix = 'Week'

            break
          case 'month':
            prefix = `${getMonthAbbr(date, 'en-US')} ${date.getFullYear()}`

            break
        }

        const string = `${prefix} ${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`

        return (
          <div className="chart-tooltip">
            <h2>{value} Users</h2>
            <p>{granularity === 'month' ? prefix : string}</p>
          </div>
        )
      } else {
        return null
      }
    }

    return null
  }
}

export default class CumulativeSignupsChart extends Component {
  state = {
    data: [],
  }

  async componentDidMount() {
    await this.getData()
  }

  componentDidUpdate(prevProps) {
    const { range, granularity, start } = this.props

    if (
      start !== prevProps.start ||
      granularity !== prevProps.granularity ||
      range !== prevProps.range
    ) {
      this.getData()
    }
  }

  getData = async () => {
    let {
      start,
      end,
      granularity,
      datasourceId,
      tableId,
      lifetimeStart,
      lifetimeEnd,
    } = this.props

    if (start < lifetimeStart) {
      lifetimeStart = start
    }

    if (end > lifetimeEnd) {
      lifetimeEnd = end
    }

    start = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    end = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`

    lifetimeStart = `${lifetimeStart.getFullYear()}-${
      lifetimeStart.getMonth() + 1
    }-${lifetimeStart.getDate()}`

    lifetimeEnd = `${lifetimeEnd.getFullYear()}-${
      lifetimeEnd.getMonth() + 1
    }-${lifetimeEnd.getDate()}`

    const data = await adaloBackendAxios.post(`/analytics/cumulative`, {
      datasourceId,
      tableId,
      queryParams: { start, end, granularity, lifetimeStart, lifetimeEnd },
    })

    if (data) {
      if (data.data.success && data.data.data.length !== 0) {
        const name = 'Sign Ups'

        const chartData = [
          {
            name,
            data: data.data.data,
          },
        ]

        if (chartData[0].data) {
          for (let i = 0; i < chartData[0].data.length; i += 1) {
            chartData[0].data[i].x = formatDateOnly(chartData[0].data[i].x)
            chartData[0].data[i].y = +chartData[0].data[i].y
          }
        }

        let max = chartData[0].data.reduce((prev, current) => {
          return prev.y > current.y ? prev : current
        })

        max = findDomain(max.y)
        const maxLabel = formatTick(max, 1)
        const halfLabel = formatTick(max / 2, 1)

        this.setState({
          data: chartData,
          max,
          maxLabel,
          halfLabel,
        })
      } else {
        this.setState({
          data: [
            {
              name: '',
              data: [
                { x: start, y: 0 },
                { x: end, y: 0 },
              ],
            },
          ],
        })
      }
    } else {
      this.setState({
        data: [
          {
            name: '',
            data: [
              { x: start, y: 0 },
              { x: end, y: 0 },
            ],
          },
        ],
      })
    }
  }

  renderRange() {
    const { start, end } = this.props
    const startName = getDayName(start, 'en-US')
    const endName = getDayName(end, 'en-US')

    const startString = `${startName} ${
      start.getMonth() + 1
    }/${start.getDate()}/${start.getFullYear()}`

    const endString = `${endName} ${
      end.getMonth() + 1
    }/${end.getDate()}/${end.getFullYear()}`

    return (
      <div className="user-chart-date-container">
        <h2 className="user-chart-start-date grey-header">{startString}</h2>
        <h2 className="cumu-chart-end-date grey-header">{endString}</h2>
      </div>
    )
  }

  render() {
    const { data, max, maxLabel, halfLabel } = this.state
    const { width, start, end, granularity } = this.props
    const maxDomain = max || 'auto'

    return (
      <div>
        <div className="user-chart-header-container">
          <h2 className="user-chart-header">Total Signups</h2>
        </div>
        <div
          className="custom-tick-max"
          style={maxLabel && maxLabel !== 0 ? null : { opacity: 0 }}
        >
          <span>{maxLabel || ''}</span>
        </div>
        <div
          className="custom-tick-half"
          style={halfLabel && halfLabel !== 0 ? null : { opacity: 0 }}
        >
          <span>{halfLabel || ''}</span>
        </div>
        <div
          className="app-analytics-module-container app-analytics-module-container-line"
          style={{ width: width / 2 - 40 }}
        >
          <AreaChart width={width / 2 - 40} height={233}>
            <XAxis
              hide
              dataKey="x"
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis hide type="number" domain={[0, maxDomain]} />
            <Tooltip content={<GraphTooltip granularity={granularity} />} />
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              horizontalPoints={[5, 116]}
            />
            {data.map(d => (
              <Area
                type="monotone"
                dataKey="y"
                stroke="rgb(168, 32, 88)"
                strokeWidth={3}
                fill="rgb(168, 32, 88)"
                data={d.data}
                name={d.name}
                key={d.name}
              />
            ))}
          </AreaChart>
        </div>
        {start && end && data ? this.renderRange() : null}
      </div>
    )
  }
}
