import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import {
  LIBRARY_COMPONENT,
  dataTypes,
  imageTypes,
  LABEL,
  fileTypes,
  LAYOUT_SECTION,
  COMPONENT,
  controlTypes,
} from '@adalo/constants'
import {
  getDeviceType,
  getId,
  traverseMapObject,
  traverseObject,
} from '@adalo/utils'

import { getAuthToken } from './auth'
import { getLocalLibraries } from './developers'
import { getObjectName } from './naming'
import { capitalize } from './strings'
import { normalizeColor } from './colors'
import { assetURL, fileURL } from './assets'
import { getFontFamily } from './type'
import { deepSet } from './objects'
import getDeviceObject from './getDeviceObject'
import isVisibleInDevice from './objects/isVisible'
import { forceSaveComponents } from './io'
import { layoutSectionHeightPerScreen } from './layoutSections/modifiers/buildDefaultLayoutSection'
import { getTopScreenObjectToSnapTo } from './layoutSections/modifiers/getLayoutSectionFitForScreen'
import { isPrebuiltLayoutSection } from './layoutSections/prebuilts'

const noop = () => {}
const libraryURL = process.env.REACT_APP_COMPONENT_URL
const defaultURL = `${libraryURL}`
const localURL = `${process.env.REACT_APP_DEV_SERVER}/libraries`
const cache = {}

export const FIXED_POSITION_COMPONENTS = ['@adalo/navigation']

// Returns promise
export const loadLibrary = (name, version) => {
  const baseURL = version === 'dev' ? localURL : defaultURL
  let url

  if (version === 'dev') {
    url = `${baseURL}/${name}/editor-${version}.js`
    url = `${url}?authToken=${getAuthToken()}&timestamp=${+new Date()}`
  } else {
    url = `${baseURL}/${name}/${version}/editor-${version}.js`
  }

  // Check if already in cache
  if (cache[url]) {
    return cache[url]
  }

  const script = document.createElement('script')
  script.src = url
  script.setAttribute('crossorigin', '')
  document.body.appendChild(script)

  const promise = new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      resolve()
    })

    script.addEventListener('error', () => {
      reject(new Error(`Script could not load: ${url}`))
    })
  })

  cache[url] = promise

  return promise
}

export const removeLibrary = name => {
  const { scripts } = document

  // remove appended script from page
  Object.entries(scripts).forEach(([key]) => {
    const script = scripts[key]

    if (script?.src.includes(name)) {
      delete cache?.[script.src]
      document.body.removeChild(script)
    }
  })

  // remove library from protonLibraries object
  delete window.protonLibraries?.[name]
}

export const getLibraryComponent = (libraryName, version, componentName) => {
  const library = getLibrary(libraryName, version)

  if (!library) {
    return null
  }

  return library.components[componentName]
}

/**
 *
 * @param {string} libraryName
 * @param {string} componentName
 * @param {number} [version]
 * @returns {{type: string, object: import('./responsiveTypes').EditorObject | null}}
 */
export const getLibraryComponentOptions = (
  libraryName,
  componentName,
  version
) => {
  const library = getLibrary(libraryName, version)

  if (!library) {
    return {}
  }

  const component = library.config.components.find(comp => {
    return comp.name === componentName
  })

  if (!component) return {}

  return {
    type: LIBRARY_COMPONENT,
    object: {
      ...getDefaultProps(component),
      libraryName: library.config.name,
      libraryVersion: library.config.version,
      componentName: component.name,
      width: component.defaultWidth,
      height: component.defaultHeight,
      snappingRules: component.snappingRules,
    },
  }
}

export const getLibrary = (libraryName, version) => {
  const versionMap = window.protonLibraries?.[libraryName]
  if (!version && versionMap) version = Object.keys(versionMap)?.[0]

  return versionMap?.[version]
}

export const getAppLibrary = (app, libraryName) => {
  const dependencies = getLibraryDependencies(app)

  const dependency = dependencies?.find(({ name }) => name === libraryName)

  const version = dependency?.version

  return getLibrary(libraryName, version)
}

export const getComponentInfo = (libraryName, version, componentName) => {
  const library = getLibrary(libraryName, version)

  if (!library) {
    return null
  }

  return library.config.components.find(c => c.name === componentName)
}

export const getLibraryInfo = (libraryName, version) => {
  const library = getLibrary(libraryName, version)

  if (!library) {
    return null
  }

  const libraryConfig = {}

  library.config.components.map(
    component => (libraryConfig[component.name] = component)
  )

  return libraryConfig
}

export const getAppComponent = (app, libraryName, componentName) => {
  const library = getAppLibrary(app, libraryName)

  if (!library) {
    return null
  }

  return library.config.components.find(c => c.name === componentName)
}

export const getDefaultProps = config => {
  const props = getDefaultMap(config.props)

  if (config.childComponents) {
    for (const child of config.childComponents) {
      props[child.name] = getDefaultMap(child.props)
    }
  }

  return {
    positioning: props.positioning,
    attributes: props,
  }
}

const getDefaultMap = props => {
  const result = {}

  if (!Array.isArray(props)) {
    throw new Error('Invalid props:', props)
  }

  for (const prop of props) {
    if (typeof prop.default !== 'undefined' && !prop.global) {
      if (prop.default !== null) {
        result[prop.name] = prop.default
      }
    }
  }

  return result
}

export const getLibraryDependencies = app => {
  const libraries = app?.libraries ?? []
  const localLibraries = getLocalLibraries()

  if (!localLibraries || localLibraries.length === 0) return libraries

  const libraryEntries = libraries.map(lib => [lib.name, lib])
  const localLibraryEntries = localLibraries.map(lib => [
    lib,
    {
      name: lib,
      version: 'dev',
    },
  ])

  const map = new Map([...libraryEntries, ...localLibraryEntries])

  return Array.from(map.values())
}

export const updateLibraryInfo = (libraries, app, updateApp) => {
  const libraryComponentManifests = {}
  let { componentsUsed } = app

  if (
    !componentsUsed ||
    (Object.keys(componentsUsed).length === 0 &&
      componentsUsed.constructor === Object)
  ) {
    componentsUsed = getComponentsUsed(app)
  }

  for (const library of libraries) {
    const { name, version } = library

    const wholeLibraryManifest = getLibraryInfo(name, version)
    const filteredLibraryComponentManifest = {}

    for (const key in wholeLibraryManifest) {
      if (componentsUsed[name] && componentsUsed[name].includes(key)) {
        filteredLibraryComponentManifest[key] = wholeLibraryManifest[key]
      }
    }

    libraryComponentManifests[name] = filteredLibraryComponentManifest
  }

  updateApp(app.id, { libraryComponentManifests, componentsUsed })
}

export const checkComponentsUsed = (libraryName, componentName, app) => {
  if (!app.componentsUsed || !app.componentsUsed[libraryName]) {
    return false
  }

  return !!app.componentsUsed[libraryName].find(
    component => component === componentName
  )
}

export const addComponentUsed = (
  libraryName,
  componentName,
  app,
  updateApp
) => {
  const {
    componentsUsed = {},
    libraryComponentManifests = {},
    librariesUsed = [],
  } = app

  if (!componentsUsed[libraryName]) {
    componentsUsed[libraryName] = []
  }

  if (!librariesUsed.includes(libraryName)) {
    librariesUsed.push(libraryName)
  }

  if (!libraryComponentManifests[libraryName]) {
    libraryComponentManifests[libraryName] = {}
  }

  // Add component to componentsUsed
  componentsUsed[libraryName].push(componentName)

  // And add the component's manifest to the current list of libraryComponentManifests
  libraryComponentManifests[libraryName][componentName] = getComponentInfo(
    libraryName,
    null,
    componentName
  )

  forceSaveComponents()

  updateApp(app.id, {
    componentsUsed,
    libraryComponentManifests,
    librariesUsed,
  })
}

const getComponentsUsed = app => {
  const componentsUsed = {}

  traverseObject(app, obj => {
    if (
      obj.libraryName &&
      obj.componentName &&
      (!componentsUsed[obj.libraryName] ||
        !componentsUsed[obj.libraryName].includes(obj.componentName))
    ) {
      if (!componentsUsed[obj.libraryName]) {
        componentsUsed[obj.libraryName] = []
      }

      componentsUsed[obj.libraryName].push(obj.componentName)
    }
  })

  return componentsUsed
}

export const getLibraryMenu = app => {
  const libraries = getLibraryDependencies(app)

  const options = []

  libraries.forEach(({ name, version }) => {
    const library = getLibrary(name, version)

    if (library) {
      options.push({
        label: library.config.displayName,
        library: library.config.name,
        children: getComponentOptions(library),
      })
    }
  })

  return options
}

const getComponentOptions = library => {
  return library.config.components.map(component => ({
    library: library.config.name,
    name: library.config.displayName,
    label: component.displayName,
    icon: 'component',
    value: {
      type: LIBRARY_COMPONENT,
      options: {
        ...getDefaultProps(component),
        libraryName: library.config.name,
        libraryVersion: library.config.version,
        componentName: component.name,
        width: component.defaultWidth,
        height: component.defaultHeight,
        snappingRules: component.snappingRules,
      },
    },
  }))
}

const calculateLayoutSectionSnappedPosition = (obj, parent) => {
  const newX = obj.x
  let newY = obj.y

  const currentDevice = getDeviceType(parent.width)
  const deviceObject = getDeviceObject(obj, currentDevice)

  const newWidth = obj.width

  let newHeight = deviceObject.height
  const isPrebuilt = isPrebuiltLayoutSection(obj)
  if (!isPrebuilt) {
    /**
     * Modify the height of layout section so it reflects the end result on the canvas
     * This is necessary because the height is determined by the screen type (mobile, tablet, desktop)
     *
     * If it's a prebuilt layout section, we don't need to modify the height
     */
    newHeight = layoutSectionHeightPerScreen(newWidth)
  }

  const parentY = parent.y
  const yRelativeToParent = newY - parentY

  const TOP_THRESHOLD = 40

  // Don't even check for top object if the current object is below this threshold, keep it cheap
  const CHECK_TOP_OBJECT_THRESHOLD = 500

  if (
    parent.type === COMPONENT &&
    yRelativeToParent < CHECK_TOP_OBJECT_THRESHOLD
  ) {
    const topScreenObjectToSnapTo = getTopScreenObjectToSnapTo(parent)
    if (topScreenObjectToSnapTo.targetObject) {
      const shouldSnapToTopObject =
        yRelativeToParent < topScreenObjectToSnapTo.ySnap + TOP_THRESHOLD
      if (shouldSnapToTopObject) {
        const snappedPosition = {
          x: newX,
          y: parentY + topScreenObjectToSnapTo.ySnap,
          height: newHeight,
          width: newWidth,
        }

        return snappedPosition
      }
    }
  }

  const shouldSnapToTop = yRelativeToParent < TOP_THRESHOLD

  if (shouldSnapToTop) {
    const snappedPosition = {
      x: newX,
      y: parentY + 20, // top of screen is 20 pixels down
      height: newHeight,
      width: newWidth,
    }

    return snappedPosition
  }

  let hasSiblingSections = false
  let siblingsTopY
  let siblingsBottomY

  for (const child of parent.children) {
    if (child.type === LAYOUT_SECTION) {
      // only consider siblings visible in the currentDevice
      const isVisible = isVisibleInDevice(child, currentDevice)
      if (isVisible) {
        hasSiblingSections = true
        const deviceSibling = getDeviceObject(child, currentDevice)
        const yTop = deviceSibling.y
        const yBottom = deviceSibling.y + deviceSibling.height

        if (siblingsTopY === undefined || yTop < siblingsTopY) {
          siblingsTopY = yTop
        }

        if (siblingsBottomY === undefined || yBottom > siblingsBottomY) {
          siblingsBottomY = yBottom
        }
      }
    }
  }

  if (hasSiblingSections) {
    // configure the threshold for how close the new object should be to the top or bottom sibling to snap
    const TOP_SIBLING_THRESHOLD = 50
    const BOTTOM_SIBLING_THRESHOLD = 60

    const shouldSnapToBottomSibling =
      siblingsTopY &&
      Math.abs(siblingsBottomY - yRelativeToParent) < BOTTOM_SIBLING_THRESHOLD

    if (shouldSnapToBottomSibling) {
      newY = parent.y + siblingsBottomY
    } else {
      const newObjectBottom = yRelativeToParent + deviceObject.height

      const shouldSnapToTopSibling =
        siblingsTopY &&
        Math.abs(siblingsTopY - newObjectBottom) < TOP_SIBLING_THRESHOLD

      if (shouldSnapToTopSibling) {
        newY = parent.y + (siblingsTopY - newHeight)
      }
    }
  }

  const snappedPosition = {
    x: newX,
    y: newY,
    height: newHeight,
    width: newWidth,
  }

  return snappedPosition
}

export const calculateSnappedPosition = (obj, parent) => {
  const snappingRules = obj.snappingRules ?? {}

  let newX = obj.x
  let newY = obj.y
  let newWidth = obj.width
  let newHeight = obj.height

  if (parent) {
    if (snappingRules.lock) {
      const { lock } = snappingRules

      if ('left' in lock) {
        newX = parent.x + lock.left
      }

      if ('right' in lock && 'left' in lock) {
        newWidth = parent.width - lock.left - lock.right
      } else if ('right' in lock) {
        newX = parent.x + parent.width - lock.right - obj.width
      }

      if ('top' in lock) {
        newY = parent.y + lock.top
      }

      if ('bottom' in lock && 'top' in lock) {
        newHeight = parent.height - lock.top - lock.bottom
      } else if ('bottom' in lock) {
        newY = parent.y + parent.height - lock.bottom - obj.height
      }
    }

    if (obj.type === LAYOUT_SECTION && parent) {
      const updatedObj = {
        ...obj,
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
      }

      const snappedPosition = calculateLayoutSectionSnappedPosition(
        updatedObj,
        parent
      )

      return snappedPosition
    }
  }

  return { ...obj, x: newX, y: newY, width: newWidth, height: newHeight }
}

export const evaluateLibraryProps = (
  config,
  props = {},
  getLabel,
  { branding, libraryGlobals } = {}
) => {
  let result = {}
  const referenceMap = {}
  const childComponents = config?.childComponents ?? []

  for (const child of childComponents) {
    const value = evaluateLibraryProps(child, props[child.name], getLabel, {
      branding,
      libraryGlobals: libraryGlobals?.[child.name] ?? {},
    })

    if (hasRole(child, 'listItem') && child.reference) {
      const ref = child.reference

      referenceMap[ref] = referenceMap[ref] ?? {}
      referenceMap[ref][child.name] = value

      if (value.styles) {
        result = deepSet(result, [child.name], value)
      }
    } else {
      result[child.name] = value
    }
  }

  const refProps = config?.props?.filter(prop => hasRole(prop, 'listItem'))
  const normalProps = config?.props?.filter(prop => !hasRole(prop, 'listItem'))

  if (refProps) {
    for (const prop of refProps) {
      const propValue = getPropValue(prop, props[prop.name], getLabel, {
        branding,
        props,
        libraryGlobals,
      })

      if (prop.type === 'file' || prop.type === 'image') {
        result[prop.name] = { value: propValue }
      } else if (hasRole(prop, 'autosaveInput')) {
        result[prop.name] = { value: propValue }
      } else if (hasRole(prop, 'formValue')) {
        result[prop.name] = { value: propValue, initial: propValue }
      } else {
        result[prop.name] = propValue
      }

      const ref = prop.reference

      referenceMap[ref] = referenceMap[ref] ?? {}
      referenceMap[ref][prop.name] = propValue
    }
  }

  if (normalProps) {
    for (const prop of normalProps) {
      const propValue = getPropValue(prop, props[prop.name], getLabel, {
        children: referenceMap[prop.name],
        branding,
        props,
        libraryGlobals,
      })

      if (prop.type === 'file' || prop.type === 'image') {
        if (
          prop.type === 'image' &&
          !hasRole(prop, 'formValue') &&
          !hasRole(prop, 'autosaveInput')
        ) {
          if (typeof propValue === 'string') {
            result[prop.name] = propValue
          } else {
            result[prop.name] = propValue ? propValue.uri : ''
          }
        } else {
          result[prop.name] = { value: propValue }
        }
      } else {
        result[prop.name] = propValue
      }

      if (hasRole(prop, 'autosaveInput')) {
        result[prop.name] = {
          value: propValue,
        }
      } else if (hasRole(prop, 'formValue')) {
        result[prop.name] = {
          value: propValue,
          initial: propValue,
        }
      }

      if (prop.styles) {
        const key = ['styles', prop.name]

        result = deepSet(result, key, getPropStyles(props, prop, { branding }))
      }
    }
  }

  return result
}

const getPropValue = (prop, value, getLabel, opts = {}) => {
  const { branding, children, props, libraryGlobals = {} } = opts

  if (prop.global) {
    value = libraryGlobals[prop.name]
  }

  if (prop.type === dataTypes.LIST) {
    return [1, 2, 3].map(id => ({
      ...children,
      id,
    }))
  }

  if (value && Array.isArray(value) && prop.type === 'text') {
    return value
      .map(itm => getPropValue(prop, itm, getLabel, children, opts))
      .join('')
      .replace(/ +/g, ' ')
  }

  if (value && value.type === 'formula') {
    if (value.formula.length === 1 && typeof value.formula[0] === 'string') {
      return +value.formula[0]
    }

    return 0
  }

  if (
    value &&
    (value.type === 'binding' ||
      value.type === 'imageBinding' ||
      value.type === 'fileBinding')
  ) {
    if (prop.type === dataTypes.TEXT) return getLabel(value.source)

    if (prop.type === dataTypes.IMAGE) {
      if (
        value.type === 'imageBinding' &&
        value.imageType === imageTypes.URL &&
        typeof value.binding === 'string' &&
        value.binding.trim() !== ''
      ) {
        return { uri: value.binding }
      } else if (value.imageType === imageTypes.UPLOADED) {
        const uri = value.filename1x
          ? assetURL(value.filename1x.url || value.filename1x)
          : undefined

        const filename = value.filename1x
          ? value.filename1x.filename
          : undefined

        const size = value.filename1x ? value.filename1x.size : undefined

        return { uri, filename, size }
      } else if ('options' in value && typeof value.options !== 'undefined') {
        const { options } = value

        if (typeof options.placeholderImageEnabled === 'undefined') {
          return undefined
        }

        if (typeof options.placeholderImage === 'undefined') return undefined

        const { placeholderImage, placeholderImageEnabled } = options

        if (placeholderImageEnabled && placeholderImage) {
          return assetURL(placeholderImage)
        }
      }
    }

    if (prop.type === dataTypes.FILE) {
      if (
        value.type === 'fileBinding' &&
        value.fileType === fileTypes.URL &&
        typeof value.binding === 'string'
      ) {
        return { uri: value.binding }
      } else if (value.fileType === fileTypes.UPLOADED) {
        const uri = fileURL(value.filename1x ? value.filename1x.url : undefined)

        const filename = value.filename1x
          ? value.filename1x.filename
          : undefined

        const size = value.filename1x ? value.filename1x.size : undefined

        return { uri, filename, size }
      }
    }

    return undefined
  }

  if (value && prop.type === 'action') {
    return noop
  }

  if (value && prop.type === dataTypes.COLOR) {
    return normalizeColor(value, branding, props)
  }

  if (value && prop.control) {
    if (prop.control.type === controlTypes.BORDER && value.borderColor) {
      return {
        ...value,
        borderColor: normalizeColor(value.borderColor, branding, props),
      }
    } else if (
      prop.control.type === controlTypes.SHADOW &&
      value.enabled &&
      value.color
    ) {
      return { ...value, color: normalizeColor(value.color, branding, props) }
    }
  }

  return value
}

const getPropStyles = (prop, config, opts = {}) => {
  const { branding } = opts

  const propStyles = { ...config?.styles }
  const keys = Object.keys(propStyles)

  const target = prop?.styles?.[config.name] ?? prop

  for (const key of keys) {
    let style = target?.[key] || config?.styles?.[key]

    switch (key) {
      case 'fontFamily': {
        propStyles[key] = getFontFamily(style, branding)

        break
      }
      case 'fontSize': {
        if (typeof style === 'number') {
          style = `${style}px`
        } else if (typeof style === 'string') {
          if (!style.includes('px')) style = `${style}px`
        }

        propStyles[key] = style

        break
      }
      case 'color': {
        propStyles[key] = normalizeColor(style, branding, prop)

        break
      }
      case 'textAlignment': {
        propStyles.textAlign = style
        delete propStyles.textAlignment

        break
      }

      default: {
        propStyles[key] = style

        break
      }
    }
  }

  return propStyles
}

export const getComponentIcon = (library, version, component) => {
  if (version === 'dev') return null

  return `${defaultURL}/${library}/${version}/icons/${component}-${version}.png`
}

export const updateComponentBounds = (
  obj,
  libraryGlobals,
  openAccordion,
  component
) => {
  const { id: objectId, libraryName, componentName } = obj
  const library = getAppLibrary(null, libraryName)
  const config = getAppComponent(null, libraryName, componentName) ?? {}
  const resizeY = !!config?.resizeY
  const resizeX = 'resizeX' in config ? config?.resizeX : true
  let accordion

  if (openAccordion?.includes(obj.id)) {
    accordion = openAccordion.split('-')[1]
  }

  // Skip if vertically-resizable
  if (resizeY && resizeX) {
    return obj
  }

  const Component = library?.components?.[componentName]

  const props = evaluateLibraryProps(
    config,
    obj.attributes,
    () => 'Hello world',
    { libraryGlobals }
  )

  const _screenHeight = component.height
  const device = getDeviceType(component.width)

  try {
    const el = document.createElement('div')

    if (resizeX) {
      el.style.width = `${obj.width}px`
    }

    el.style.position = 'fixed'
    el.style.top = '100%'
    el.style.top = 0
    el.style.zIndex = 100000
    el.style.opacity = 0
    el.style.pointerEvents = 'none'
    el.style.backgroundColor = 'red'

    document.body.appendChild(el)

    el.innerHTML = renderToStaticMarkup(
      <Component
        editor
        {...props}
        openAccordion={accordion}
        _width={obj.width}
        _height={obj.height}
        _fonts={{
          body: 'body',
          heading: 'heading',
        }}
        _deviceType={device}
        _screenHeight={_screenHeight}
        _screenWidth={component.width}
        _layoutGuides={{ top: 0, bottom: 0 }}
      />
    )

    const { width, height } = el.getBoundingClientRect()
    document.body.removeChild(el)

    if (obj[device] && (obj[device].width || obj[device].height)) {
      return {
        ...obj,
        [device]: {
          ...obj[device],
          ...(!resizeX && { width }),
          ...(!resizeY && { height }),
        },
      }
    }

    return {
      ...obj,
      ...(!resizeX && { width }),
      ...(!resizeY && { height }),
    }
  } catch (err) {
    console.warn(`Error updating library component bounds: ${err}`, {
      componentName,
      objectId,
    })

    return obj
  }
}

export const resetLibraryBindingIds = obj => {
  if (obj.type !== LIBRARY_COMPONENT && obj.type !== LABEL) {
    return obj
  }

  const idMap = {}

  let newObject = traverseMapObject(obj, obj => {
    if (obj.type === 'binding') {
      const newId = getId()
      idMap[obj.id] = newId

      return { ...obj, id: newId }
    }

    return obj
  })

  newObject = traverseMapObject(newObject, obj => {
    if (obj.listObjectId && idMap[obj.listObjectId]) {
      return { ...obj, listObjectId: idMap[obj.listObjectId] }
    }

    return obj
  })

  return newObject
}

export const getLibraryPropLabel = (
  app,
  object,
  path,
  returnComponentName = true,
  returnPropName = true
) => {
  let childComponentName
  let propName

  if (path.length === 2) {
    childComponentName = path[0]
    propName = path[1]
  } else {
    propName = path[0]
  }

  const { libraryName, componentName } = object
  const config = getAppComponent(app, libraryName, componentName)

  const childComponent =
    childComponentName &&
    config.childComponents.find(c => c.name === childComponentName)

  const propParent = childComponent ?? config
  const prop = propParent.props.find(p => p.name === propName)

  const label = [
    getObjectName(object),
    childComponent && childComponent.displayName,
    prop.displayName || capitalize(prop.name),
  ]

  if (returnComponentName === returnPropName) {
    return label.filter(itm => itm).join(' ')
  } else if (returnComponentName) {
    return getObjectName(object)
  } else if (returnPropName) {
    return prop.displayName || capitalize(prop.name)
  }
}

export const evaluateEnabled = (enabled, values) => {
  values = values ?? {}

  if (!enabled) {
    return true
  }

  for (const key of Object.keys(enabled)) {
    let targetValues = enabled[key]

    if (!Array.isArray(targetValues)) {
      targetValues = [targetValues]
    } else {
      targetValues = [...targetValues]
    }

    //handle "not" logic for "enabled" props
    let negatedValues = []
    const keyword = 'ADALO_INTERNAL_!previous'

    while (targetValues.slice(1).includes(keyword)) {
      const keywordIndex = targetValues.indexOf(keyword, 1)

      negatedValues = negatedValues.concat(
        targetValues.splice(keywordIndex - 1, keywordIndex + 1)
      )
    }

    if (negatedValues.includes(values[key])) {
      return false
    }

    if (targetValues.length === 0) {
      return true
    }

    if (!targetValues.includes(values[key])) {
      return false
    }
  }

  return true
}

export const hasRole = (prop, role) => {
  if (Array.isArray(prop.role)) {
    return prop.role.includes(role)
  }

  return prop.role === role
}

export const isRole = (thisRole, role) => {
  if (Array.isArray(thisRole)) {
    return thisRole.includes(role)
  }

  return thisRole === role
}

export const hasUnlicensedLibrary = (object, appLibraries) => {
  if (!appLibraries) return false

  let hasUnlicensedLibrary = false

  const recurseObject = obj => {
    if (obj.type === LIBRARY_COMPONENT) {
      const library = appLibraries.find(l => l.name === obj.libraryName)

      if (!library || !library.licensed) {
        hasUnlicensedLibrary = true
      }
    } else if (obj.children) {
      obj.children.forEach(child => recurseObject(child))
    }
  }

  recurseObject(object)

  return hasUnlicensedLibrary
}

/**
 * Check if the Component Tab Category already has the component added
 */
export const categoryHasComponent = (category, component) => {
  const componentName = component.$ref.component

  return category.components.find(
    ({ $ref }) => $ref.component === componentName
  )
}

/**
 * Add a Component to the Component Tab Category
 */
export const addComponentToCategory = (category, component, position = 0) => {
  if (!categoryHasComponent(category, component)) {
    category.components.splice(position, 0, component)
  }

  return category
}

/**
 * Remove a Component from the Component Tab Category
 */
export const removeComponentFromCategory = (category, component) => {
  if (categoryHasComponent(category, component)) {
    category.components = category.components.filter(({ $ref }) => {
      return $ref.component !== component.$ref.component
    })
  }

  return category
}
