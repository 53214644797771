import React, { Component } from 'react'

export default class Element extends Component {
  static defaultProps = {
    type: 'div',
    dragTolerence: 3,
  }

  state = {
    clickStartPoint: null,
    targetOffset: null,
    dragging: false,
  }

  handleMouseDown = e => {
    const { onMouseDown } = this.props

    const bbox = e.currentTarget.getBoundingClientRect()

    if (onMouseDown) {
      onMouseDown(e)
    }

    this.setState({
      clickStartPoint: [e.clientX, e.clientY],
      dragging: false,
      targetOffset: [e.clientX - bbox.left, e.clientY - bbox.top],
    })

    document.documentElement.addEventListener('mousemove', this.handleMouseMove)
    document.documentElement.addEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseMove = e => {
    const { clickStartPoint, dragging, targetOffset } = this.state
    const { onDragStart, onDrag, dragTolerence } = this.props

    if (!clickStartPoint) {
      return
    }

    const squaredDist =
      (e.clientX - clickStartPoint[0]) ** 2 +
      (e.clientY - clickStartPoint[1]) ** 2

    if (!dragging && squaredDist > dragTolerence ** 2) {
      this.setState({ dragging: true })

      if (onDragStart) {
        onDragStart(e, targetOffset)
      }
    } else if (onDrag) {
      onDrag(e, targetOffset)
    }
  }

  handleMouseUp = e => {
    const { clickStartPoint, dragging, targetOffset } = this.state
    const { onClick, onDragEnd } = this.props

    if (!clickStartPoint) {
      return
    }

    if (dragging) {
      if (onDragEnd) {
        onDragEnd(e, targetOffset)
      }
    } else {
      onClick(e)
    }

    this.setState({
      clickStartPoint: null,
    })

    this.removeListeners()
  }

  removeListeners = () => {
    const doc = document.documentElement

    doc.removeEventListener('mousemove', this.handleMouseMove)
    doc.removeEventListener('mouseup', this.handleMouseUp)
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  render() {
    const {
      type,
      children,
      dragTolerence,
      onClick,
      onDragStart,
      onDrag,
      onDragEnd,
      ...passThroughProps
    } = this.props

    const props = {
      ...passThroughProps,
      onMouseDown: this.handleMouseDown,
    }

    return React.createElement(type, props, children)
  }
}
