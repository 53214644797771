import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import {
  getDeveloperLibraries,
  getDeveloperLibrariesStatus,
} from 'ducks/marketplace/developerLibraries'
import { getOrgsLicenses } from 'ducks/marketplace/licenses'

import Loading from 'components/Shared/Loading'

import AccessToken from './AccessToken'
import Library from './Library'
import { DevelopersContext } from './index'

export default function Shared() {
  const libraries = useSelector(getDeveloperLibraries) || []
  const { status, error } = useSelector(getDeveloperLibrariesStatus)
  const { organizations, app } = useContext(DevelopersContext)
  const orgIds = organizations.map(org => org.id)
  const orgLicenses = useSelector(state => getOrgsLicenses(state, orgIds))

  if (['loading', 'idle'].includes(status)) return <Loading />

  const renderSharedLibraries = () => {
    const sharedLibraries = libraries.filter(l => l.shared)

    if (status === 'error') {
      return <p style={{ color: 'red' }}>{error.response.data.message}</p>
    }

    if (sharedLibraries.length === 0) {
      return <></>
    } else {
      return (
        <>
          <h2>Shared With Me</h2>
          <div className="developer-libraries">
            {sharedLibraries.map(library => (
              <Library
                library={library}
                organizations={organizations}
                orgLicenses={orgLicenses}
                app={app}
              />
            ))}
          </div>
        </>
      )
    }
  }

  return (
    <div className="developer-libraries-wrapper">
      <AccessToken />
      {renderSharedLibraries()}
    </div>
  )
}
