import getDeviceObject from 'utils/getDeviceObject'
import { DeviceValue, EditorObject } from 'utils/responsiveTypes'

const resetChildrenToDevice = (
  object: EditorObject,
  deviceType: DeviceValue
): EditorObject => {
  if (!object?.children?.length) {
    return object
  }

  return {
    ...object,
    children: object.children.map(child => {
      const deviceProps = getDeviceObject(child, deviceType)
      const newChild = { ...child, ...deviceProps, initialDevice: deviceType }

      return resetChildrenToDevice(newChild, deviceType)
    }),
  }
}

export default resetChildrenToDevice
