import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import { getApp } from 'ducks/apps'
import { getDomains, requestDomains } from 'ducks/domains'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import history from '../../../history'

const getSubdomain = app => {
  const baseURL = process.env.REACT_APP_WEB_BASE_URL

  return `${app?.Organization?.subdomain}.${baseURL}`
}

const DomainPicker = props => {
  const { app, domains, requestDomains, customDomainsDisabled } = props

  const getOptions = () => {
    const subdomain = getSubdomain(app)

    let subdomainOptions

    if (app.primaryPlatform === 'mobile') {
      subdomainOptions = { label: 'None', value: null }
    } else {
      subdomainOptions = { label: subdomain, value: null }
    }

    const domainOptions = domains?.map(domain => ({
      label: domain?.domain,
      value: domain?.id,
    }))

    const getDomainsLink = () => {
      return history.push(`/apps/${app.id}/screens/settings/domains`)
    }

    const addDomain = [
      null,
      { label: 'Add Domain...', onClick: getDomainsLink },
    ]

    return [subdomainOptions].concat(domainOptions).concat(addDomain)
  }

  const options = getOptions()

  const comparator = (val1, val2) => {
    if (!val1 && !val2) return true

    return val1 === val2
  }

  useEffect(() => {
    if (app) requestDomains(app?.Organization?.id)
  }, [app])

  if (!domains) return null

  return (
    <div className="settings-form-row">
      <label>Domain</label>
      <div className="settings-form-input">
        <Field
          className="settings-form-input-select"
          name="DomainId"
          component={WrappedSelect}
          options={options}
          comparator={comparator}
          disabled={customDomainsDisabled}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, { appId }) => {
  const app = getApp(state, appId)
  const domains = getDomains(state, app?.Organization?.id)

  return { app, domains }
}

const connected = connect(mapStateToProps, { requestDomains })(DomainPicker)

export default connected
