import { locationTypes } from '@adalo/constants'
import { isFeatureEnabled } from 'ducks/organizations'
import { connect } from 'react-redux'
import Input from '../Input'

class LocationInput extends Input {
  render() {
    const { defaultValue, isEnabled } = this.props

    if (!defaultValue) {
      return super.render({ isEnabled })
    }

    const { defaultType, fallbackValue } = defaultValue

    let displayedValue

    if (
      (defaultType === locationTypes.DEVICE ||
        defaultType === locationTypes.DATABASE) &&
      fallbackValue
    ) {
      displayedValue =
        fallbackValue.addressElements.address1 || fallbackValue.fullAddress
    }

    return super.render({ defaultValue: displayedValue, isEnabled })
  }
}

const mapStateToProps = state => {
  const isEnabled = isFeatureEnabled(state, 'geolocation')

  return {
    isEnabled,
  }
}

export default connect(mapStateToProps)(LocationInput)
