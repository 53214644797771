import React, { Component } from 'react'
import MultiSelectMenu from '@protonapp/react-multi-menu'
import classNames from 'classnames'

import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

import './MenuControl.scss'

export default class MenuControl extends Component {
  static defaultProps = {
    rowHeight: 36,
  }

  setValue = () => {
    const { autoSelect, name, options, onChange, value } = this.props

    if (autoSelect && !value && options?.length > 0) {
      onChange({ [name]: options?.[0]?.value })
    }
  }

  handleChange = val => {
    const { name, onChange, input } = this.props

    if (input) {
      input.onChange(val)
    }

    if (onChange) {
      onChange({ [name]: val })
    }
  }

  componentDidMount() {
    this.setValue()
  }

  componentDidUpdate() {
    this.setValue()
  }

  renderTooltip() {
    const { tooltip } = this.props

    return (
      <Tooltip small hideArrow placement="bottom-start" tooltip={tooltip}>
        <Icon type="help-small" />
      </Tooltip>
    )
  }

  render() {
    let {
      name,
      displayName,
      value,
      options,
      comparator,
      placeholder,
      getLabel,
      input,
      rowHeight,
      childWidth,
      tooltip,
      handleSearch,
      searchValue,
      searchPlaceholder,
      className,
      menuTheme,
      mobileOnly,
      objectName,
    } = this.props

    if (input) {
      value = input.value
    }

    const skipComponentControl = {
      SideNav: ['onDesktop', 'onTablet'],
      NavigationBar: ['universalLayout'],
    }

    const isMobileNavComponent =
      mobileOnly && !skipComponentControl[objectName]?.includes(name)

    const shouldRenderMenu = !mobileOnly || isMobileNavComponent

    if (!shouldRenderMenu) {
      return null
    }

    const shouldRenderLabel = mobileOnly
      ? !displayName.toLowerCase().includes('mobile')
      : Boolean(displayName)

    const controlHeader = tooltip ? (
      <div className="library-inspect-menu-control-header">
        <p>{displayName}</p>
        {this.renderTooltip()}
      </div>
    ) : (
      <p>{shouldRenderLabel && displayName}</p>
    )

    return (
      <div className={classNames('library-inspect-menu-control', className)}>
        {controlHeader}
        <div className={classNames({ 'mobile-only-select-menu': mobileOnly })}>
          <MultiSelectMenu
            className="small"
            options={options}
            value={value}
            onChange={this.handleChange}
            comparator={comparator}
            getLabel={getLabel}
            placeholder={placeholder}
            rowHeight={rowHeight}
            childWidth={childWidth}
            handleSearch={handleSearch}
            searchValue={searchValue}
            searchPlaceholder={searchPlaceholder}
            menuTheme={menuTheme}
          />
        </div>
      </div>
    )
  }
}
