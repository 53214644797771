import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LAYOUT_SECTION } from '@adalo/constants'

import { isSectionElement } from 'utils/layoutSections'

import {
  groupObjects,
  groupObjectsToList,
  runInstructions,
} from 'ducks/editor/objects'
import {
  groupObjects as groupObjectsInstruction,
  groupObjectsToList as groupObjectsToListInstruction,
} from 'ducks/editor/instructions'
import AppMagicLayoutContext from 'components/Editor/AppMagicLayoutContext'

import Button from '../../Shared/Button'

class MultiSelectionControl extends Component {
  static propTypes = {
    objects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    groupObjects: PropTypes.func.isRequired,
    groupObjectsToList: PropTypes.func.isRequired,
  }

  static contextType = AppMagicLayoutContext

  handleMakeGroup = () => {
    const { groupObjects, runInstructions, objects } = this.props
    const { hasMagicLayout } = this.context
    const objectIds = objects.map(obj => obj.id)

    if (hasMagicLayout) {
      runInstructions([groupObjectsInstruction(objectIds)])
    } else {
      groupObjects(objectIds)
    }
  }

  handleMakeList = () => {
    const { groupObjectsToList, runInstructions, objects } = this.props
    const { hasMagicLayout } = this.context
    const objectIds = objects.map(obj => obj.id)

    if (hasMagicLayout) {
      runInstructions([groupObjectsToListInstruction(objectIds)])
    } else {
      groupObjectsToList(objectIds)
    }
  }

  render() {
    const { objects } = this.props
    const hasSectionElements = objects.some(
      object => object.type === LAYOUT_SECTION || isSectionElement(object)
    )

    return (
      !hasSectionElements && (
        <div className="multi-selection-control">
          <Button square onClick={this.handleMakeGroup}>
            <i className="material-icons">merge_type</i>
            <span>Make Group</span>
          </Button>
          <Button square outlined onClick={this.handleMakeList}>
            <i className="material-icons">list</i>
            <span>Make List</span>
          </Button>
        </div>
      )
    )
  }
}

export default connect(null, {
  groupObjects,
  groupObjectsToList,
  runInstructions,
})(MultiSelectionControl)
