import { connect, useSelector, batch } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { getDateTimeContextualSources } from 'ducks/recommender'
import {
  getComponent,
  getCurrentAppId,
  State,
  updateObject as updateObjectAction,
} from 'ducks/editor/objects'
import { DateTimeSource, getLabel } from 'utils/sources'
import { EditorObject } from 'utils/responsiveTypes'
import MenuControl from './MenuControl'

const useDefaultDateOptions = (
  objectId?: string,
  componentId?: string,
  appId?: string
) => {
  const databaseOptions = useSelector((state: State) =>
    getDateTimeContextualSources(state, appId, componentId, objectId)
  )

  return databaseOptions
}

type EditorObjectWithDefaultDate = EditorObject & {
  defaultDate?: DateTimeSource | undefined
}

type OwnProps = {
  name: string
  key: string
  displayName: string | null
  namespace: string
  object?: EditorObjectWithDefaultDate
}

type UpdateObject = typeof updateObjectAction

type WrappedProps = OwnProps & {
  objectId?: string | undefined
  appId: string | undefined
  componentId: string | undefined
  updateObject: UpdateObject
  getDefaultDateLabel: (value: DateTimeSource) => string | null
}

const DefaultDateControl: React.FC<WrappedProps> = props => {
  const {
    key,
    name,
    displayName,
    object,
    appId,
    componentId,
    objectId,
    updateObject,
    getDefaultDateLabel,
  } = props

  const options = useDefaultDateOptions(objectId, componentId, appId)

  if (!appId || !componentId || !object) {
    return null
  }

  const value = object.defaultDate ?? options?.[0]?.value

  const onChange = (change: Partial<EditorObjectWithDefaultDate>) => {
    // Clear the existing defaultDate first, otherwise there will be a deep merge
    // with the previous defaultDate and the final defaultDate object won't match the spec
    const newDefaultDate = change?.defaultDate
    batch(() => {
      updateObject(objectId, { defaultDate: undefined })
      if (!newDefaultDate?.options?.none) {
        updateObject(objectId, { defaultDate: newDefaultDate })
      }
    })
  }

  return (
    <MenuControl
      key={key}
      name={name}
      displayName={displayName}
      value={value}
      onChange={onChange}
      options={options}
      comparator={isEqual}
      getLabel={getDefaultDateLabel}
    />
  )
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const { object } = props

  const objectId = object?.id
  const appId = getCurrentAppId(state) ?? undefined
  const componentId = objectId ? getComponent(state, objectId)?.id : undefined

  const getDefaultDateLabel = (value: DateTimeSource): string | null => {
    if (!value || !appId || !componentId) {
      return null
    }

    return getLabel(state, object?.defaultDate, appId, componentId)
  }

  return { ...props, appId, componentId, objectId, getDefaultDateLabel }
}

export default connect(mapStateToProps, {
  updateObject: updateObjectAction,
})(DefaultDateControl)
