import React from 'react'
import { useSelector } from 'react-redux'

import { selectObject } from 'ducks/editor/objects'

import Form from './Form'

const DummyAction = props => {
  const { dummyAction, objectId } = props
  const object = useSelector(state => selectObject(state, objectId))

  return (
    <Form
      form={`dummy-action-form-${objectId}`}
      initialValues={object?.submitButton}
      dummyAction={dummyAction}
      onChange={dummyAction?.onChange}
    />
  )
}

export default DummyAction
