import { isEmpty } from 'lodash'
import { getDeviceType } from '@adalo/utils'
import { COMPONENT, LIST } from '@adalo/constants'
import getDeviceObject from 'utils/getDeviceObject'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { EditorObject } from 'utils/responsiveTypes'
import { hasMultipleContainers } from 'utils/hasMultipleContainers'
import { getListItemWidth } from 'ducks/editor/objects/helpers/getListItemWidth'
import getObject from '../objects/helpers/getObject'
import { getParent } from '../device-layouts/utils'
import type { Instruction as LayoutInstruction } from '../instructions/applyInstructions'
import applyInstructions from '../instructions/applyInstructions'
import InstructionState from '../types/InstructionState'
import { getSelectionBounds } from '../getSelectionBounds'
import { enableDeviceSpecificLayout } from '../instructions'
import type { HandlerOptions, ScreenHandlerOptions } from './types'

import {
  alignToScreenHorizontalCenterHandler,
  AlignToScreenHorizontalCenterInstruction,
} from './alignToScreenHorizontalCenter'

import {
  alignToScreenVerticalCenterHandler,
  AlignToScreenVerticalCenterInstruction,
} from './alignToScreenVerticalCenter'

import {
  alignToSelectionBottomHandler,
  AlignToSelectionBottomInstruction,
} from './alignToSelectionBottom'

import {
  alignToSelectionHorizontalCenterHandler,
  AlignToSelectionHorizontalCenterInstruction,
} from './alignToSelectionHorizontalCenter'

import {
  alignToSelectionLeftHandler,
  AlignToSelectionLeftInstruction,
} from './alignToSelectionLeft'

import {
  alignToSelectionRightHandler,
  AlignToSelectionRightInstruction,
} from './alignToSelectionRight'

import {
  alignToSelectionTopHandler,
  AlignToSelectionTopInstruction,
} from './alignToSelectionTop'

import {
  alignToSelectionVerticalCenterHandler,
  AlignToSelectionVerticalCenterInstruction,
} from './alignToSelectionVerticalCenter'

import {
  distributeHorizontalHandler,
  DistributeHorizontalInstruction,
} from './distributeHorizontal'

import {
  distributeVerticalHandler,
  DistributeVerticalInstruction,
} from './distributeVertical'

export type Instruction =
  | AlignToScreenHorizontalCenterInstruction
  | AlignToScreenVerticalCenterInstruction
  | AlignToSelectionBottomInstruction
  | AlignToSelectionHorizontalCenterInstruction
  | AlignToSelectionLeftInstruction
  | AlignToSelectionRightInstruction
  | AlignToSelectionTopInstruction
  | AlignToSelectionVerticalCenterInstruction
  | DistributeHorizontalInstruction
  | DistributeVerticalInstruction

export const getTouchedFromInstruction = (
  instruction: Instruction
): string[] => [...new Set(instruction.options.objectIds)]

const SCREEN_OPERATIONS = new Set([
  'alignToScreenHorizontalCenter',
  'alignToScreenVerticalCenter',
])

type InstructionHandler = (options: HandlerOptions) => LayoutInstruction[]

type ScreenInstructionHandler = (
  options: ScreenHandlerOptions
) => LayoutInstruction[]

let InstructionsHandlersMap: { [operation: string]: InstructionHandler } = {}

let ScreenInstructionsHandlersMap: {
  [operation: string]: ScreenInstructionHandler
} = {}

const setupHandlers = () => {
  // We setup the handlers at runtime to avoid issues with dependencies
  if (isEmpty(InstructionsHandlersMap)) {
    InstructionsHandlersMap = {
      alignToSelectionLeft: alignToSelectionLeftHandler,
      alignToSelectionRight: alignToSelectionRightHandler,
      alignToSelectionTop: alignToSelectionTopHandler,
      alignToSelectionBottom: alignToSelectionBottomHandler,
      alignToSelectionHorizontalCenter: alignToSelectionHorizontalCenterHandler,
      alignToSelectionVerticalCenter: alignToSelectionVerticalCenterHandler,
      distributeHorizontal: distributeHorizontalHandler,
      distributeVertical: distributeVerticalHandler,
    }
  }

  if (isEmpty(ScreenInstructionsHandlersMap)) {
    ScreenInstructionsHandlersMap = {
      alignToScreenHorizontalCenter: alignToScreenHorizontalCenterHandler,
      alignToScreenVerticalCenter: alignToScreenVerticalCenterHandler,
    }
  }
}

const getScreenBounds = (
  instructionState: InstructionState,
  objectIds: string[]
) => {
  const { list, pathMap } = instructionState

  let [container] = new Set(objectIds.map(id => getParent(list, pathMap, id)))

  if (!container) {
    // Likely unreachable
    throw new Error('No container object found')
  }

  const screen = getParentScreen(list, pathMap, container.id)
  const device = getDeviceType(screen.width)

  if (container.type !== COMPONENT) {
    // sanity-check(device-parent): `device` will not be undefined here
    container = getDeviceObject(container, device)
  }

  if (container.type === LIST && container.children) {
    const listItemIds = container.children.map(child => child.id)

    const listItemWidth = getListItemWidth(container, device)
    const { bottom } = getSelectionBounds(instructionState, listItemIds)

    return {
      left: container.x,
      top: container.y,
      right: container.x + listItemWidth,
      bottom,
    }
  }

  return {
    left: container.type === COMPONENT ? 0 : container.x,
    right:
      container.type === COMPONENT
        ? container.width
        : container.x + container.width,
    top: container.type === COMPONENT ? 0 : container.y,
    bottom:
      container.type === COMPONENT
        ? container.height
        : container.y + container.height,
  }
}

export const getLayoutInstructions = (
  instructionState: InstructionState,
  instruction: Instruction
): LayoutInstruction[] => {
  try {
    const { list, pathMap } = instructionState
    const { operation, options } = instruction
    const { objectIds } = options

    if (!objectIds.length) {
      throw new Error('No objects selected')
    }

    const objectParents = new Map<string, EditorObject>(
      objectIds.map(objectId => {
        const parent = getParentScreen(list, pathMap, objectId)

        return [objectId, parent]
      })
    )

    const objects = objectIds.map(id => {
      const object = getObject(list, pathMap, id)

      if (object.type === COMPONENT) {
        throw new Error(
          'Not implemented: Screens cannot be aligned or distributed'
        )
      }

      const parent = objectParents.get(id)
      if (!parent) {
        throw new Error(`Missing parent for object ${id} `)
      }

      const device = getDeviceType(parent.width)

      return getDeviceObject(object, device)
    })

    if (hasMultipleContainers(list, pathMap, objects)) {
      throw new Error(
        'Not implemented: Object selection across multiple containers cannot be aligned or distributed'
      )
    }

    const instructions = []
    for (const obj of objects) {
      const { id: objectId } = obj
      const parent = objectParents.get(objectId)
      if (!parent) {
        throw new Error(`Missing parent for object ${objectId} `)
      }

      const device = getDeviceType(parent.width)

      // If auto custom layouts is enabled, then before applying a layout instruction,
      // we need to enable device specific layout if we're on a different device than the initialDevice.
      // Objects only get initialDevice set if the hasAutoCustomLayout flag is set on.
      if (obj.initialDevice && obj.initialDevice !== device) {
        instructions.push(enableDeviceSpecificLayout(objectId, device))
      }
    }

    let layoutInstructions: LayoutInstruction[] | undefined

    const handlerOptions: HandlerOptions = {
      objects,
      bounds: getSelectionBounds(instructionState, objectIds),
    }

    if (SCREEN_OPERATIONS.has(operation)) {
      const screenHandlerOptions = {
        ...handlerOptions,
        screenBounds: getScreenBounds(instructionState, objectIds),
      }

      layoutInstructions =
        ScreenInstructionsHandlersMap[operation]?.(screenHandlerOptions)
    } else {
      layoutInstructions = InstructionsHandlersMap[operation]?.(handlerOptions)
    }

    if (!layoutInstructions) {
      throw new Error(`Unknown operation: ${operation as string}`)
    }

    return [...instructions, ...layoutInstructions]
  } catch (e) {
    return []
  }
}

const applyLayoutInstruction = (
  instructionState: InstructionState,
  instruction: Instruction
): InstructionState => {
  setupHandlers()

  return applyInstructions(
    instructionState,
    getLayoutInstructions(instructionState, instruction)
  )
}

export default applyLayoutInstruction
