import { COMPONENT, responsivePositioningOptions } from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { EditorObject, PositioningType } from 'utils/responsiveTypes'
import getDeviceObject from 'utils/getDeviceObject'
import hiddenOnDevice from 'utils/objects/hiddenOnDevice'
import getObject from './objects/helpers/getObject'
import InstructionState from './types/InstructionState'

const { FIXED_ON_SCROLL } = responsivePositioningOptions

export interface SelectionBounds {
  left: number
  right: number
  top: number
  bottom: number
}

export const defaultBounds = {
  left: Number.MAX_SAFE_INTEGER,
  right: Number.MIN_SAFE_INTEGER,
  top: Number.MAX_SAFE_INTEGER,
  bottom: Number.MIN_SAFE_INTEGER,
}

export const getSelectionBounds = (
  state: InstructionState,
  selectionIds: string[]
): SelectionBounds => {
  if (selectionIds.length < 1) {
    return defaultBounds
  }

  const { list, pathMap } = state
  const selection = selectionIds.map(id => getObject(list, pathMap, id))

  const getSelectionBoundsReducer = (
    previousValue: SelectionBounds,
    current: EditorObject
  ): SelectionBounds => {
    const parent = getParentScreen(list, pathMap, current.id)
    const device = getDeviceType(parent.width)
    const currentValue = getDeviceObject(current, device)

    const currentRight = currentValue.x + currentValue.width
    const currentBottom = currentValue.y + currentValue.height

    return {
      left: Math.min(previousValue.left, currentValue.x),
      right: Math.max(previousValue.right, currentRight),
      top: Math.min(previousValue.top, currentValue.y),
      bottom: Math.max(previousValue.bottom, currentBottom),
    }
  }

  return selection.reduce(getSelectionBoundsReducer, defaultBounds)
}

export const getInflowScreenElements = (
  state: InstructionState,
  screenId: string
): string[] => {
  const { list, pathMap } = state

  const screen = getObject(list, pathMap, screenId)
  if (screen.type !== COMPONENT) {
    throw new Error(`Supplied screenId='${screenId}' is not a screen`)
  }

  if (
    !screen.children ||
    !Array.isArray(screen.children) ||
    screen.children.length === 0
  ) {
    return []
  }

  const device = getDeviceType(screen.width)
  const objectIds = new Set<string>()

  for (const object of screen.children) {
    if (hiddenOnDevice(object, device)) {
      continue
    }

    const deviceObject = getDeviceObject(
      getObject(list, pathMap, object.id),
      device
    )

    if (deviceObject.responsivity?.verticalPositioning === FIXED_ON_SCROLL) {
      continue
    }

    objectIds.add(object.id)
  }

  return Array.from(objectIds)
}

export const getStickyScreenElements = (
  state: InstructionState,
  screenId: string,
  positioning: PositioningType
): string[] => {
  const { list, pathMap } = state

  const screen = getObject(list, pathMap, screenId)
  if (screen.type !== COMPONENT) {
    throw new Error(`Supplied screenId='${screenId}' is not a screen`)
  }

  if (
    !screen.children ||
    !Array.isArray(screen.children) ||
    screen.children.length === 0
  ) {
    return []
  }

  const device = getDeviceType(screen.width)
  const objectIds = new Set<string>()

  for (const object of screen.children) {
    if (hiddenOnDevice(object, device)) {
      continue
    }

    const deviceObject = getDeviceObject(
      getObject(list, pathMap, object.id),
      device
    )

    if (
      deviceObject.responsivity?.verticalPositioning === FIXED_ON_SCROLL &&
      deviceObject.positioning === positioning
    ) {
      objectIds.add(object.id)
    }
  }

  return Array.from(objectIds)
}
