import classNames from 'classnames'
import Icon from 'components/Shared/Icon'
import { MagicAddFeatureType } from 'ducks/dbAssistant'

type Props = {
  features: MagicAddFeatureType[] | undefined
  onSelect: (feat: MagicAddFeatureType) => void
}

const ALTERNATIVE_OPTION = 'Something Else'

export default function FeatureList({
  features,
  onSelect,
}: Props): JSX.Element | null {
  if (!features) {
    return null
  }

  return (
    <>
      <div className="magic-add-setup-title">
        <p>
          What kind of feature do you want to update your database to support?
        </p>
      </div>
      <div className="magic-add-setup__content__feature-list">
        {features?.map(feature => (
          <div
            data-adalo-id={feature.id}
            key={feature.id}
            role="button"
            onClick={() => {
              onSelect(feature)
            }}
            className="magic-add-setup__content__feature-list__item"
            tabIndex={0}
            onKeyDown={() => {
              onSelect(feature)
            }}
          >
            <div
              className={classNames(
                'magic-add-setup__content__feature-list__item__description',
                {
                  '--alternative': feature.description === ALTERNATIVE_OPTION,
                }
              )}
            >
              {feature.description === ALTERNATIVE_OPTION
                ? `${feature.description}...`
                : feature.description}
              <Icon small type="arrow-next" color="midGray" />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
