import React from 'react'
import Markdown from 'react-markdown'
import classNames from 'classnames'

import Link from 'components/Shared/Markdown/Link'

import './Summary.scss'

const Summary = props => {
  const {
    header = 'Order Summary',
    footer,
    error,
    children,
    divided = false,
    backgroundColor,
    markdownLinkType = 'external',
  } = props

  return (
    <div className={classNames('summary', { 'summary-divided': divided })}>
      <div className="summary-header">
        <h2>{header}</h2>
      </div>
      {children ? (
        <div
          className={classNames('summary-content', {
            [`summary-background-color-${backgroundColor}`]: backgroundColor,
          })}
        >
          {children}
        </div>
      ) : null}
      {footer ? (
        <div className="summary-footer">
          <Markdown
            components={{ a: p => <Link {...p} linkType={markdownLinkType} /> }}
          >
            {footer}
          </Markdown>
        </div>
      ) : null}
      {error ? <div className="summary-error-message">{error}</div> : null}
    </div>
  )
}

export default Summary
