import React from 'react'

// child components
import Form from './Form'

import './NewRelationshipModal.scss'

const NewRelationshipModal = props => {
  const { onSuccess } = props

  const handleSubmit = values => {
    onSuccess(values)
  }

  return <Form onSubmit={handleSubmit} {...props} />
}

export default NewRelationshipModal
