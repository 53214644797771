import React, { Component } from 'react'
import { connect } from 'react-redux'

import history from '../../../history'

import {
  getDatasource,
  createTable,
  createCollection,
} from '../../../ducks/apps/datasources'

import Icon from '../../Shared/Icon'

class CreateTable extends Component {
  handleClick = () => {
    const { createTable, createCollection, appId, datasourceId, datasource } =
      this.props

    const name = window.prompt(`Enter ${this.getName()} Name (i.e. Users)`)

    if (!name) {
      return
    }

    let id

    if (datasource.type === 'api') {
      id = createCollection(appId, datasourceId, name).id
    } else {
      id = createTable(appId, datasourceId, name).id
    }

    const url = `/apps/${appId}/data/${datasourceId}/${id}`

    history.replace(url)
  }

  getName() {
    const { datasource } = this.props

    if (datasource.type === 'api') {
      return 'Collection'
    } else {
      return 'Table'
    }
  }

  render() {
    const { datasourceId, datasource } = this.props

    if (!datasourceId || !datasource) {
      return null
    }

    return (
      <p className="create-table-link">
        <a className="deemphasize" onClick={this.handleClick}>
          <Icon type="plus" />
          Add {this.getName()}
        </a>
      </p>
    )
  }
}

const mapStateToProps = (state, { appId, datasourceId }) => ({
  datasource: getDatasource(state, appId, datasourceId),
})

export default connect(mapStateToProps, { createTable, createCollection })(
  CreateTable
)
