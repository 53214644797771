import React, { Component } from 'react'

export default class InviteItem extends Component {
  handleDeleteInvite = () => {
    const { key, onDelete } = this.props
    onDelete(key)
  }

  render() {
    const { email, onDelete, isAdminUser } = this.props

    return (
      <div className="app-settings-user-item">
        <div className="app-settings-user-details">
          <div className="app-settings-user-name">{email}</div>
          <div className="app-settings-user-email">
            <i>Invited!</i>
          </div>
        </div>
        {isAdminUser ? (
          <div className="app-settings-user-action">
            <div>
              <a onClick={onDelete} className="deemphasize">
                Cancel
              </a>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
