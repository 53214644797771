import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { DOWN_ARROW, UP_ARROW, RETURN } from 'utils/keyboard'
import Incrementer from './Incrementer'

const UPDATE_SOURCE = {
  INPUT: 'input',
  INCREMENTER: 'incrementer',
  RETURN_KEY: 'return_key',
  BLUR: 'blur',
}

export const formatters = {
  number: val => {
    if (typeof val === 'number') {
      return Math.max(0, val)
    }

    const stripped = val.replace(/[^\d.\-]/g, '')

    if (`${val}` !== stripped) {
      return +stripped
    }

    return +(+val).toFixed(2)
  },
}

export default class TextControl extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...(props.submitViaBlurOrEnter === true && { value: props.value }),
      dirty: false,
    }
  }

  static propTypes = {
    variant: PropTypes.oneOf(['slim', 'advanced', null]),
    // TODO @danicunhac: implement small and large
    fontSize: PropTypes.oneOf(['small', 'medium', 'large']),
  }

  static defaultProps = {
    variant: null,
    fontSize: 'medium',
  }

  componentDidUpdate(prevProps) {
    const { value, submitViaBlurOrEnter } = this.props

    if (prevProps.value !== value && submitViaBlurOrEnter === true) {
      this.setState({ value, dirty: false })
    }
  }

  componentWillUnmount() {
    const { submitViaBlurOrEnter } = this.props

    if (submitViaBlurOrEnter === true) {
      const { value, dirty } = this.state
      if (dirty === true) {
        this.handleChangeValue(value, UPDATE_SOURCE.BLUR)
      }
    }
  }

  format = (val, oldValue) => {
    const { type, allowEmpty } = this.props
    const formatter = formatters[type] || (v => v)
    const result = formatter(val)

    if (result !== val) {
      if (val === '' && allowEmpty) {
        return null
      }

      return result
    }

    return val
  }

  handleKeyDown = e => {
    const { value: propValue, submitViaBlurOrEnter } = this.props
    const { value: stateValue } = this.state

    const value = submitViaBlurOrEnter === true ? stateValue : propValue

    e.stopPropagation()

    let increment = 1

    if (e.shiftKey) {
      increment = 10
    }

    if (e.which === UP_ARROW) {
      e.preventDefault()
      this.handleChangeValue((+value || 0) + increment, UPDATE_SOURCE.INCREMENTER) // prettier-ignore
    } else if (e.which === DOWN_ARROW) {
      e.preventDefault()
      this.handleChangeValue(+value - increment || 0, UPDATE_SOURCE.INCREMENTER)
    } else if (e.which === RETURN) {
      e.currentTarget.blur()

      if (submitViaBlurOrEnter === true) {
        this.handleChangeValue(stateValue, UPDATE_SOURCE.RETURN_KEY)
      }
    }
  }

  handleChange = e => {
    const { value, submitViaBlurOrEnter } = this.props
    const newValue = this.format(e.target.value, value)

    if (e.target.value === '' && submitViaBlurOrEnter !== true) {
      return value
    }

    return this.handleChangeValue(newValue, UPDATE_SOURCE.INPUT)
  }

  handleChangeValue = (newValue, via) => {
    const {
      name,
      onChange,
      type,
      percentage,
      minValue,
      maxValue,
      submitViaBlurOrEnter,
    } = this.props

    if (type === 'number' && percentage) {
      newValue = Math.max(0, newValue)
      newValue = Math.min(100, newValue)
      newValue = newValue / 100
    }

    const enforceMinMaxValue =
      submitViaBlurOrEnter !== true ||
      (submitViaBlurOrEnter === true &&
        (via === UPDATE_SOURCE.INCREMENTER ||
          via === UPDATE_SOURCE.RETURN_KEY ||
          via === UPDATE_SOURCE.BLUR))

    const enforceMinValue =
      type === 'number' &&
      typeof minValue === 'number' &&
      enforceMinMaxValue === true &&
      newValue < minValue

    if (enforceMinValue) {
      newValue = minValue
    }

    const enforceMaxValue =
      type === 'number' &&
      typeof maxValue === 'number' &&
      enforceMinMaxValue === true &&
      newValue > maxValue

    if (enforceMaxValue) {
      newValue = maxValue
    }

    if (submitViaBlurOrEnter === true && via === UPDATE_SOURCE.INPUT) {
      this.setState({ value: newValue, dirty: true })
    } else {
      onChange({ [name]: newValue })
      this.setState({ value: newValue, dirty: false })
    }
  }

  handleBlur = e => {
    const { submitViaBlurOrEnter, onBlur } = this.props
    const { value } = this.state

    if (submitViaBlurOrEnter === true) {
      this.handleChangeValue(value, UPDATE_SOURCE.BLUR)
    }

    if (typeof onBlur === 'function') {
      onBlur(e)
    }
  }

  render() {
    const {
      label,
      name,
      value: propValue,
      onFocus,
      placeholder,
      disabled,
      type,
      gray,
      disableIncrementer,
      title,
      toggleValue,
      toggleDisabledPlaceholder,
      onToggleChange,
      variant,
      fontSize,
      onClick,
      submitViaBlurOrEnter = false,
    } = this.props

    const { value: stateValue, dirty } = this.state

    let value = submitViaBlurOrEnter === true ? stateValue : propValue

    const isNumber = type === 'number'

    if (value === undefined || value === null) {
      value = ''
    }

    if (isNumber && value !== '') {
      const numberValue = +value

      if (!Number.isNaN(numberValue)) {
        value = +numberValue.toFixed(2)
      }
    }

    const hasToggle = typeof onToggleChange === 'function' && toggleValue !== undefined // prettier-ignore
    const hasToggleDisabledPlaceholder = typeof toggleDisabledPlaceholder === 'string' && toggleDisabledPlaceholder.length > 0 // prettier-ignore
    const displayPlaceholder = disabled === true && hasToggle && hasToggleDisabledPlaceholder // prettier-ignore
    const displayIncrementer = isNumber && !disableIncrementer && !disabled

    return (
      <div
        className={classNames('text-control', {
          'text-control-gray': gray,
          [`text-control-variant-${variant}`]: variant,
          [`text-control-font-size-${fontSize}`]: fontSize,
          [`text-control-click-action`]: Boolean(onClick),
        })}
      >
        {Boolean(title) && <label>{title}</label>}
        <div className="text-control-sub">
          <div
            className={classNames('input-container', {
              disabled,
              dirty: submitViaBlurOrEnter === true && dirty === true,
            })}
          >
            {displayPlaceholder === false && (
              <input
                type={type === 'number' ? 'number' : 'text'}
                name={name}
                value={`${value}`}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                onFocus={onFocus}
                onBlur={this.handleBlur}
                placeholder={placeholder}
                onClick={onClick}
              />
            )}
            {displayPlaceholder === true && (
              <input type="text" value={toggleDisabledPlaceholder} />
            )}
            {displayIncrementer === true && (
              <Incrementer
                value={value}
                onChange={value =>
                  this.handleChangeValue(value, UPDATE_SOURCE.INCREMENTER)
                }
                hasToggle={hasToggle}
              />
            )}
          </div>
          {hasToggle && (
            <div className="toggle">
              <input
                type="checkbox"
                checked={toggleValue}
                onChange={onToggleChange}
              />
            </div>
          )}
        </div>
        {Boolean(label) && <label>{label}</label>}
      </div>
    )
  }
}
