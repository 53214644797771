import { useRef } from 'react'

import useClickOutside from 'hooks/useClickOutside'

import Accordion from '../Accordion'
import './styles.scss'

type FloatingAccordionProps = {
  renderChildren: () => React.ReactNode
  spaced?: boolean
  keepExpanded?: boolean
  onClickOutside: () => void
}

const FloatingAccordion: React.FC<FloatingAccordionProps> = ({
  renderChildren,
  keepExpanded,
  onClickOutside,
}) => {
  const wrapperRef = useRef<Accordion>(null)
  useClickOutside(wrapperRef, onClickOutside)

  const renderContent = () => {
    return (
      <div className="floating-accordion-children-wrapper">
        {renderChildren()}
      </div>
    )
  }

  return (
    <Accordion
      wrapperRef={wrapperRef}
      keepExpanded={keepExpanded}
      defaultExpanded
      hideCarret
      className="floating-accordion"
      renderChildren={renderContent}
      transitionSpeed={0}
    />
  )
}

export default FloatingAccordion
