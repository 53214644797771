import React, { useEffect, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Loading from 'components/Shared/Loading'
import Modal from 'components/Shared/Modal'

import { getLibraryById, getMarketplaceComponents } from 'ducks/marketplace'
import { getApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users/index.ts'

// marketplace hooks
import {
  useLibraryStatus,
  useLibraryHandler,
} from 'components/Editor/Routes/Marketplace/hooks'

import DetailsContent from './Content'
import DetailsHeader from './Header'
import DetailsBanner from './Banner'

import './Details.scss'

export const DetailsContext = createContext({})

const Details = ({ history, match }) => {
  const dispatch = useDispatch()

  const { appId, libraryId } = match.params
  const app = useSelector(state => getApp(state, appId))
  const user = useSelector(getCurrentUser)

  const library = useSelector(state => getLibraryById(state, libraryId))

  useEffect(() => {
    if (!library?.id && libraryId && app?.Organization?.id) {
      dispatch(getMarketplaceComponents(app?.Organization?.id))
    }
  }, [libraryId, library?.id, app?.Organization?.id])

  const handleBack = () => {
    history.goBack()
  }

  const handleClose = () => {
    history.push(`/apps/${appId}/screens`)
  }

  const { label, price, isInstalled } = useLibraryStatus(libraryId)

  const { handleLibraryInstall, handleLibraryUninstall, isLoading } =
    useLibraryHandler(libraryId)

  const handleAction = () => {
    if (isInstalled) {
      handleLibraryUninstall()
    } else {
      handleLibraryInstall()
    }
  }

  const renderDetails = () => {
    if (!library) return <Loading />

    return (
      <DetailsContext.Provider
        value={{
          library,
          isInstalled,
          isLoading,
          handleAction,
          installLabel: label,
          price,
          user,
          orgId: app?.Organization?.id,
        }}
      >
        <DetailsHeader />
        <DetailsBanner />
        <DetailsContent />
      </DetailsContext.Provider>
    )
  }

  return (
    <Modal size="lg" scrolling onClose={handleClose}>
      <div className="marketplace-library-details">
        <Modal.Content>
          {library?.id ? renderDetails() : <Loading />}
        </Modal.Content>
        <Modal.Actions
          leftButtons={
            <Modal.Button text onClick={handleBack}>
              Back
            </Modal.Button>
          }
        />
      </div>
    </Modal>
  )
}

export default Details
