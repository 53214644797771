import React, { Component } from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

import { getFilename, uploadURL, uploadImage } from 'utils/uploads'
import { assetURL } from 'utils/assets'
import { uploadImage as uploadAssetImage } from 'utils/io'
import { validateImage } from 'utils/validation'

import Loading from '../Loading'

import './ImageInput.css'

export default class ImageInput extends Component {
  static defaultProps = {
    buttons: ['view', 'remove'],
  }

  state = {
    uploadingImage: null,
  }

  handleChange = () => {
    if (this.dropzone) {
      this.dropzone.open()
    }
  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    const { isAsset, input, maxPixelCount } = this.props
    const { onChange } = input

    const file = acceptedFiles.concat(rejectedFiles)[0]

    if (!file) {
      return this.errorMessage('No valid file')
    }

    const reader = new FileReader()

    reader.onload = async evt => {
      try {
        await validateImage(reader.result, file.type, maxPixelCount)
      } catch (error) {
        console.error('Error validating image, error:', error)

        const defaultErrorString =
          'Image is invalid. Make sure the file you are uploading is actually an image.'

        return this.errorMessage(error || defaultErrorString)
      }

      this.setState({ uploadingImage: reader.result })

      const uploadFunc = isAsset ? this.uploadAssetImage : this.uploadImage

      try {
        const url = await uploadFunc(reader.result, file.name, file.type)

        onChange(url, file.name)
      } catch (err) {
        console.error('ERROR UPLOADING IMAGE:', err)
        this.errorMessage()
      }

      this.setState({ uploadingImage: null })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  uploadImage = async (dataURL, filename, extension) => {
    const { isAsset } = this.props
    const result = await uploadImage(dataURL, filename, extension)

    return isAsset ? result.url : result
  }

  uploadAssetImage = async dataURL => {
    const { appId } = this.props
    const filename = getFilename(dataURL)
    const file = dataURL.split(',')[1]

    return uploadAssetImage(appId, file, filename)
  }

  errorMessage = (msg = null) => {
    if (msg) {
      console.error(msg)
      // eslint-disable-next-line no-alert
      alert(msg)
    } else {
      // eslint-disable-next-line no-alert
      alert(
        'There was an error uploading your image. Only .jpg, .png, & .gif images are allowed. Check that the file type extension is correct and try again. If this problem persists, please contact support.'
      )
    }
  }

  getImageURL = () => {
    const { uploadingImage } = this.state

    const {
      isAsset,
      input: { value },
    } = this.props

    if (uploadingImage) {
      return uploadingImage
    } else if (value) {
      return isAsset ? assetURL(value) : uploadURL(value)
    }

    return null
  }

  handleRemove = () => {
    const {
      input: { onChange },
    } = this.props

    onChange(null)
  }

  renderLinks(url) {
    const {
      input: { value },
      buttons,
    } = this.props

    if (!value) {
      return null
    }

    return (
      <div className="image-input-links">
        {buttons.map(button => {
          if (button === 'view') {
            return (
              <a
                key="view"
                href={url}
                target="_blank"
                className="deemphasize"
                rel="noopener noreferrer"
              >
                View
              </a>
            )
          } else if (button === 'change') {
            return (
              <a
                key="change"
                className="deemphasize image-input-change"
                onClick={this.handleChange}
              >
                Change
              </a>
            )
          } else if (button === 'remove') {
            return (
              <a
                key="remove"
                className="deemphasize image-input-remove"
                onClick={this.handleRemove}
              >
                Remove
              </a>
            )
          }
        })}
      </div>
    )
  }

  setDropzoneRef = zone => {
    this.dropzone = zone
  }

  render() {
    const { uploadingImage } = this.state
    const { displayName } = this.props
    const { isSettingsFormField } = this.props
    const url = this.getImageURL()

    return (
      <div
        className={classNames('image-input-wrapper', {
          'extra-margin-bottom': isSettingsFormField && url,
        })}
      >
        {displayName ? <p>{displayName}</p> : null}
        <Dropzone
          accept="image/jpeg,image/png,image/gif"
          onDrop={this.handleDrop}
          ref={this.setDropzoneRef}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className={classNames('image-input', !url && 'image-input-empty')}
              {...getRootProps()}
            >
              <div className="image-input-sub">
                {url ? (
                  <div
                    style={{ backgroundImage: `url('${url}')` }}
                    className="image-input-preview"
                    value={displayName}
                  />
                ) : null}
                <input {...getInputProps()} />
                <p className="image-input-select">
                  {uploadingImage ? <Loading expanded /> : 'Choose image'}
                </p>
              </div>
            </div>
          )}
        </Dropzone>
        {this.renderLinks(url)}
      </div>
    )
  }
}
