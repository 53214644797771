import React, { useCallback, useMemo, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Button from 'components/Shared/Button'
import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'

import { getCurrentUser } from 'ducks/users/index.ts'
import {
  getCurrentOrganization,
  fetchOrganizations,
  fetchOrganization,
} from 'ducks/organizations'

import { hasBusinessPlan, isCurrentPlanType } from 'utils/billing.ts'

import Members from './Members'

import './Team.css'

const TeamSettings = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { appId } = useParams()

  const refreshData = useCallback(id => {
    dispatch(fetchOrganization(id))
    dispatch(fetchOrganizations())
  }, [])

  const showInviteModal = () => {
    return history.push(`/apps/${appId}/screens/invite`, {
      teamInvite: true,
    })
  }

  const org = useSelector(getCurrentOrganization)

  const currentUser = useSelector(getCurrentUser)

  const currentUserIsAdmin = currentUser?.admin

  const seatLimit = useMemo(() => {
    if (hasBusinessPlan(org?.planType)) {
      return 'unlimited'
    }

    return org?.addons?.seats?.limit
  }, [org?.addons?.seats?.limit, org?.planType])

  useEffect(() => {
    if (!org) {
      refreshData()
    }
  }, [org])

  const currentUserId = useMemo(
    () => (currentUserIsAdmin ? currentUser.id : org?.OrganizationUser?.UserId),
    [currentUserIsAdmin, currentUser?.id, org?.OrganizationUser?.UserId]
  )

  const currentPlanType = useMemo(
    () => isCurrentPlanType(org?.planType),
    [org?.planType]
  )

  const showSeatUsage = useMemo(() => {
    return currentUserIsAdmin || currentPlanType || org?.planType === 'free'
  }, [org?.Users, org?.planType, currentUserIsAdmin, currentPlanType])

  const currentUsers = useMemo(
    () => org?.addons?.seats?.count,
    [org?.addons?.seats?.count]
  )

  const userIsAdmin = useMemo(() => {
    // if the User is an admin (this is an internal Adalo admin)
    if (currentUserIsAdmin) {
      return true
    }

    // if the OrganizationUser is an admin (this is a maker who is an admin for their org)
    const members = org?.Users
    const user = members?.find(u => u.id === currentUserId)

    return user?.OrganizationUser.isAdmin
  }, [currentUserIsAdmin, org?.Users?.length])

  if (!currentUserId) {
    return null
  }

  if (!org) {
    return (
      <EmptyState>
        <Loading />
      </EmptyState>
    )
  }

  return (
    <div className="team-settings-sub">
      <div className="team-settings-header">
        <div className="settings-header-wrapper">
          <h3 className="settings-header">Team Members</h3>
          {showSeatUsage && (
            <SeatUsage currentUsers={currentUsers} seatLimit={seatLimit} />
          )}
        </div>
        {userIsAdmin ? (
          <Button
            small
            data-adalo-id="invite-new-editor-team-members"
            onClick={showInviteModal}
          >
            Invite
          </Button>
        ) : null}
      </div>
      <Members
        organization={org}
        currentUserId={currentUserId}
        userIsAdmin={userIsAdmin}
        refreshData={refreshData}
        seatLimit={seatLimit}
        currentUsers={currentUsers}
      />
    </div>
  )
}

const SeatUsage = ({ currentUsers, seatLimit }) => {
  if (typeof seatLimit !== 'number' && seatLimit !== 'unlimited') {
    return `(${currentUsers})`
  }

  const usage = `(${currentUsers} out of ${seatLimit})`

  return <p className="editor-limit-subheading">{usage}</p>
}

export default TeamSettings
