import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useFlags } from 'flags'
import { LAYOUT_SECTION, resizingOptions } from '@adalo/constants'
import { GroupedAccordion } from 'components/Shared/Accordion'
import useSetScreenDevice from 'hooks/editor/useSetScreenDevice'
import {
  getLayoutSettingsGroup,
  getLayoutSettingsItemId,
} from 'utils/accordionGroups'
import { isSectionElement } from 'utils/layoutSections'
import getDeviceObject from 'utils/getDeviceObject'
import LayoutControls from '../LayoutControls'
import { LayoutAdvancedSettings } from '../LayoutAdvancedSettings'

const { SCALES_WITH_PARENT } = resizingOptions

const AutoCustomLayoutSettings = ({
  visibleDeviceTypes,
  object,
  updateObject,
  componentParent,
}) => {
  const customDevices = visibleDeviceTypes.filter(item => !item.shared)
  const empty = customDevices.length === 0

  let settings = null

  if (!empty) {
    settings = customDevices.map(item => (
      <AutoCustomLayoutDeviceSettings
        device={item.device}
        object={object}
        updateObject={updateObject}
        componentParent={componentParent}
        key={`${item.device}-custom-settings`}
      />
    ))
  }

  return <div className={classNames('custom-layout-container')}>{settings}</div>
}

const AutoCustomLayoutDeviceSettings = ({
  device,
  object,
  updateObject,
  componentParent,
}) => {
  const { hasSectionComponent, hasMinMaxWidth } = useFlags()
  const setScreenDevice = useSetScreenDevice(object.id)

  const deviceName = device.charAt(0).toUpperCase() + device.slice(1)
  const deviceTitle = (
    <>
      {`${deviceName} Layout`}
      <span className="layout-settings-header-custom-note">Custom</span>
    </>
  )
  const groupId = getLayoutSettingsGroup(object.id)
  const accordionItemId = getLayoutSettingsItemId(object.id, device)

  const onToggled = toggled => {
    if (toggled) {
      setScreenDevice(device)
    }
  }

  const renderChildren = () => (
    <div className="custom-device-layout-controls">
      <LayoutControls
        object={object}
        updateObject={updateObject}
        componentParent={componentParent}
        device={device}
      />
    </div>
  )

  const showAdvanced = useMemo(() => {
    const deviceObject = getDeviceObject(object, device)
    const isScalable =
      deviceObject.responsivity.horizontalScaling === SCALES_WITH_PARENT

    return (
      (hasSectionComponent === true &&
        (object.type === LAYOUT_SECTION || isSectionElement(object))) ||
      (hasMinMaxWidth === true && isScalable === true)
    )
  }, [hasSectionComponent, hasMinMaxWidth, object, device])

  const renderAdvanced = () => (
    <LayoutAdvancedSettings object={object} device={device} />
  )

  return (
    <GroupedAccordion
      className={classNames({
        'layout-section-layout-controls-accordion': showAdvanced,
      })}
      group={groupId}
      key={accordionItemId}
      itemId={accordionItemId}
      title={deviceTitle}
      closeDisabled
      expandedTitle={deviceTitle}
      renderChildren={renderChildren}
      renderAdvanced={showAdvanced ? renderAdvanced : null}
      onToggled={onToggled}
      sectionAdvancedSettings={showAdvanced}
      hideDone={showAdvanced}
      {...(showAdvanced && { color: 'black' })}
    />
  )
}

export default AutoCustomLayoutSettings
