import React, { Component } from 'react'

import Modal from 'components/Shared/Modal'

class WrappedCollection extends Component {
  handleCloseModal = () => {
    const { match, history } = this.props
    const { appId } = match.params
    const baseURL = `/apps/${appId}/data`

    return history.push(baseURL)
  }

  render() {
    const { children } = this.props

    return (
      <Modal
        scrolling
        size="lg"
        className="overflow-visible"
        onClose={this.handleCloseModal}
      >
        {children}
      </Modal>
    )
  }
}

export default WrappedCollection
