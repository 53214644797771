import { DeviceWidth } from '@adalo/constants'
import { EditorObject } from 'utils/responsiveTypes'

export const getWidthConstraints = (
  deviceObject: EditorObject,
  width: number
): {
  constrainMinWidth: boolean
  constrainMaxWidth: boolean
} => {
  const constrainMinWidth =
    typeof deviceObject.minWidthEnabled === 'boolean' &&
    deviceObject.minWidthEnabled === true &&
    typeof deviceObject.minWidth === 'number' &&
    deviceObject.minWidth >= 1 &&
    typeof width === 'number' &&
    width < deviceObject.minWidth

  const constrainMaxWidth =
    typeof deviceObject.maxWidthEnabled === 'boolean' &&
    deviceObject.maxWidthEnabled === true &&
    typeof deviceObject.maxWidth === 'number' &&
    deviceObject.maxWidth <= DeviceWidth.DESKTOP_MAX_WIDTH &&
    typeof width === 'number' &&
    width > deviceObject.maxWidth

  return {
    constrainMinWidth,
    constrainMaxWidth,
  }
}
