import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import * as Sentry from '@sentry/browser'
import userflow from 'userflow.js'

import AppIcon from 'components/Shared/AppIcon'
import Button from 'components/Shared/Button'
import { NavbarContext } from 'components/App'

import { getCurrentUser } from 'ducks/users'
import { getApp } from 'ducks/apps'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { isMobileTouchDevice } from 'utils/browsers'

import appPreviewWithStars from './assets/app-preview-with-stars.png'
import './styles.scss'

const MobileBlocker = (): JSX.Element => {
  const history = useHistory()
  const { appId } = useParams<{ appId: string }>()
  const { setShowNavbar } = useContext(NavbarContext) as {
    setShowNavbar: (T: boolean) => void
  }
  const [emailSent, setEmailSent] = useState(false)

  const app = useSelector(state => getApp(state, appId))
  const currentUser = useSelector(getCurrentUser)

  const sendMobileBlockerEmail = async () => {
    if (currentUser?.id && app?.name && !emailSent) {
      setEmailSent(true)

      await adaloBackendAxios.post(
        `/users/${currentUser?.id}/notify-mobile-blocker`,
        {
          appName: app.name,
          appUrl: `${window.location.origin}/apps/${appId}/screens`,
        }
      )
    }
  }

  useEffect(() => {
    const hideMobileBlocker = !isMobileTouchDevice()

    userflow
      .updateUser({
        device_type: hideMobileBlocker ? 'desktop' : 'mobile',
      })
      .catch((err: Error) => {
        console.error('ERROR UPDATING USERFLOW USER', err.message)
        Sentry.captureMessage('ERROR UPDATING USERFLOW USER', {
          user: {
            email: currentUser?.email || '',
          },
          level: 'error',
          extra: {
            error: err.message,
            appId,
          },
        })
      })

    if (hideMobileBlocker) {
      setShowNavbar(true)
      history.push('/')
    } else {
      setShowNavbar(false)

      sendMobileBlockerEmail().catch((err: Error) => {
        console.error('ERROR SENDING MOBILE BLOCKER EMAIL', err.message)
        Sentry.captureMessage('ERROR SENDING MOBILE BLOCKER EMAIL', {
          user: {
            email: currentUser?.email || '',
          },
          level: 'error',
          extra: {
            error: err.message,
            appId,
          },
        })
      })
    }
  }, [app, currentUser])

  return (
    <div className="editor-mobile-blocker">
      <section className="app-preview-section">
        <div className="app-information">
          <AppIcon app={app} />
          <div>
            <h2>{app?.name}</h2>
            <span>Created By {app?.flagUser?.name}</span>
          </div>
        </div>
        <img
          className="app-preview-frame"
          src={appPreviewWithStars}
          alt="Preview of app in a frame"
        />
      </section>
      <section className="check-email-section">
        <h1>Your App Is Waiting!</h1>
        <p>
          Adalo’s builder works best on bigger screens. Head over to your laptop
          and log into Adalo to keep creating your app.
          <br />
          <br />
          We’ve sent you an email with a link to quickly log you in!
        </p>
        <Button icon="mailbox" square outlined>
          Check Your Email On Your Desktop
        </Button>
      </section>
    </div>
  )
}

export default MobileBlocker
