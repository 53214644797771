import React, { Component } from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import '../../Settings/Billing/Billing.scss'

export default class WrappedNativeSelect extends Component {
  static defaultProps = {
    rowHeight: 40,
    height: 45,
    minHeight: 45,
  }

  setValue = () => {
    const { autoSelect, name, options, onChange, input } = this.props
    const { value } = input

    if (autoSelect && !value && options?.length > 0) {
      onChange({ [name]: options?.[0]?.value })
    }
  }

  handleChange = val => {
    const { name, onChange, input } = this.props

    if (input) {
      input.onChange(val)
    }

    if (onChange) {
      onChange({ [name]: val })
    }
  }

  componentDidMount() {
    this.setValue()
  }

  componentDidUpdate() {
    this.setValue()
  }

  render() {
    let {
      value,
      options,
      comparator,
      placeholder,
      getLabel,
      input,
      rowHeight,
      minHeight,
      height,
      childWidth,
      className,
      meta,
      label,
    } = this.props

    const { touched, error } = meta || {}

    if (input) {
      value = input.value
    }

    return (
      <>
        <label className="label-styling">{label}</label>
        <Select
          options={options}
          value={value}
          onChange={this.handleChange}
          comparator={comparator}
          getLabel={getLabel}
          placeholder={placeholder}
          rowHeight={rowHeight}
          height={height}
          childWidth={childWidth}
          styles={{
            control: base => ({
              ...base,
              minHeight,
              borderRadius: '6px',
              border: '1px solid #e0e0e0',
            }),
          }}
          className={classNames('wrapped-native-select', className, {
            'wrapped-native-select-error': touched && error,
          })}
        />

        {touched && error ? (
          <div className="wrapped-select-error-message">{error}</div>
        ) : null}
      </>
    )
  }
}
