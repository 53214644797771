import { Screen } from 'ducks/editor/ObjectTypes'
import { Point } from './canvasTypes'

export const getScreenOnPointer = (
  pointerCoords: Point,
  screens: Screen[]
): string | undefined => {
  const [x, y] = pointerCoords
  const result = screens.find((screen: Screen) => {
    const isIntersecting =
      x >= screen.x &&
      x <= screen.x + screen.width &&
      y >= screen.y &&
      y <= screen.y + screen.height

    return isIntersecting
  })

  return result?.id
}
