import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'

import { passwordRecovery } from '../../utils/io'
import Form from './ForgotPasswordForm'
import AuthPage from './AuthPage'
import './Auth.scss'

class ForgotPassword extends Component {
  handleRecovery = data =>
    new Promise((resolve, reject) => {
      passwordRecovery(data, ({ success, errors }) => {
        console.log(success)

        if (!success) {
          // Show errors
          return reject(new SubmissionError(errors))
        }

        resolve()
      })
    })

  render() {
    return (
      <AuthPage className="login-page">
        <Form onSubmit={this.handleRecovery} />
      </AuthPage>
    )
  }
}

export default connect(null)(ForgotPassword)
