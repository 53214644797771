import { publicApiAxios } from 'utils/io/http/axios'

const SET_APIKEY = 'SET_APIKEY'
const GET_APIKEY = 'GET_APIKEY'
const UPDATE_APIKEY = 'UPDATE_APIKEY'
const DELETE_APIKEY = 'DELETE_APIKEY'

const INITIAL_STATE = {}

// Reducers

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${SET_APIKEY}_FULFILLED`: {
      const { appId } = action.meta
      const { apiKey } = action.payload.data

      return {
        ...state,
        [appId]: {
          appApiKey: apiKey,
        },
      }
    }
    case `${UPDATE_APIKEY}_FULFILLED`:
    case `${GET_APIKEY}_FULFILLED`: {
      const { appId } = action.meta
      const { apiKey } = action.payload.data

      return {
        ...state,
        [appId]: {
          appApiKey: apiKey,
        },
      }
    }
    case `${DELETE_APIKEY}_FULFILLED`: {
      const { appId } = action.meta

      return {
        ...state,
        [appId]: {
          appApiKey: null,
        },
      }
    }

    default:
      return state
  }
}

// Actions

export const createApiKey = appId => {
  return {
    type: SET_APIKEY,
    payload: publicApiAxios.post(`/tokens`, { appId }),
    meta: { appId },
  }
}

export const deleteApiKey = appId => {
  return {
    type: DELETE_APIKEY,
    payload: publicApiAxios.delete(`/tokens/${appId}`),
    meta: { appId },
  }
}

export const fetchApiKey = appId => ({
  type: GET_APIKEY,
  payload: publicApiAxios.get(`/tokens/${appId}`),
  meta: { appId },
})

export const updateApiKey = appId => ({
  type: UPDATE_APIKEY,
  payload: publicApiAxios.put(`/tokens/${appId}`),
  meta: { appId },
})

// Selectors

export const getApiKey = (state, appId) => {
  return state.apikeys[appId]?.appApiKey
}
