import { Field } from 'redux-form'
import classNames from 'classnames'
import WrappedTextArea from 'components/Shared/Forms/WrappedTextarea'
import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import ErrorMessage from '../ErrorMessage'
import './styles.scss'

type Props = {
  value: string
  placeholder: string | undefined
  disableInput: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick: () => Promise<void> | void
  errorMessage: string
  disableButton: boolean
  buttonText: string
  submitted: boolean
  maxLength: number
}

export default function PromptInput({
  value,
  placeholder,
  disableInput,
  onChange,
  onClick,
  errorMessage,
  disableButton,
  buttonText,
  submitted,
  maxLength,
}: Props): JSX.Element {
  if (submitted) {
    return (
      <Field
        className="magic-form-input__field"
        name="prompt"
        disabled
        component={WrappedTextArea}
        value={value}
      />
    )
  }

  return (
    <>
      <div
        className={classNames('magic-form-input__wrapper', {
          error: errorMessage.length > 0,
        })}
      >
        <Field
          className="magic-form-input__field"
          autoFocus
          name="prompt"
          placeholder={placeholder}
          disabled={disableInput}
          maxLength={maxLength}
          autoComplete="off"
          component={WrappedTextArea}
          onChange={onChange}
          value={value}
        />
        <div className="magic-form-input__footer">
          <span>
            Powered By AI
            <Icon type="adalo-sparkles" small />
          </span>
          <Button
            disabled={disableButton}
            orange
            onClick={onClick}
            gradient
            small
          >
            {buttonText}
          </Button>
        </div>
      </div>
      <ErrorMessage errorMessage={errorMessage} />
    </>
  )
}
