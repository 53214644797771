import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { getAppBranding } from 'ducks/apps'

import ColorDropdown from '../ColorDropdown'

import './ColorControl.css'

class LibraryColorControl extends Component {
  render() {
    const {
      name,
      value,
      displayName,
      onChange,
      branding,
      values,
      label,
      topTitle,
    } = this.props

    const titleValue = !topTitle ? displayName : null
    const labelValue = topTitle ? label || displayName : label

    return (
      <ColorDropdown
        normalize
        className={
          (classNames('library-color-control'),
          {
            'library-color-control--labeled': Boolean(label),
          })
        }
        branding={branding}
        name={name}
        onChange={onChange}
        value={value}
        title={titleValue}
        ctx={values}
        label={labelValue}
      />
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  branding: getAppBranding(state, match.params.appId),
})

export default withRouter(connect(mapStateToProps)(LibraryColorControl))
