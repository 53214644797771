import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'flags'
import { getApp } from 'ducks/apps'
import { getAppMagicStart, getAppMagicAdd } from 'ducks/dbAssistant'
import { verifyXanoApp } from 'utils/externalDatabases'
import { initializeDbAssistant, getLimits } from 'utils/io/dbAssistant'
import { AppExternalUsers } from 'components/Editor/XanoExternalDatabaseModal/lib/user'

type DbAssistantState = Parameters<typeof getAppMagicStart>[0]

type DbAssistantMenuOptions = {
  appSupportsMagicStart: boolean
  magicStartIsAvailable: boolean
  hasRemainingMagicStartTries: boolean
  handleMagicStartClicked: () => void
  appSupportsMagicAdd: boolean
  magicAddIsAvailable: boolean
  hasRemainingMagicAddTries: boolean
  handleMagicAddClicked: () => void
}

const useDbAssistantMenuOptions = (appId: string): DbAssistantMenuOptions => {
  const history = useHistory()
  const { hasMagicStartPanel, hasMagicAdd } = useFlags()

  const app = useSelector(state => getApp(state, appId))

  const appMagicStart = useSelector((state: DbAssistantState) =>
    getAppMagicStart(state, appId)
  )
  const appMagicAdd = useSelector((state: DbAssistantState) =>
    getAppMagicAdd(state, appId)
  )

  useEffect(() => {
    if ((appId && !appMagicStart) || !appMagicAdd) {
      initializeDbAssistant(appId).catch((err: Error) => {
        console.warn(`Error initializing db assistant: ${err.message}`)
      })
    }
  }, [appId, appMagicStart, appMagicAdd])

  useEffect(() => {
    if (appId) {
      getLimits(appId).catch((err: Error) => {
        console.warn(`Error getting db assistant limits: ${err.message}`)
      })
    }
  }, [appId])

  const isXanoApp = verifyXanoApp(app as unknown as AppExternalUsers)

  const appSupportsMagicStart = Boolean(
    hasMagicStartPanel && !isXanoApp && app?.primaryPlatform === 'responsive'
  )

  const magicStartIsAvailable =
    appMagicStart?.limits?.schemaGenerateAvailable === true

  const hasRemainingMagicStartTries =
    (appMagicStart?.limits?.remaining ?? 0) > 0

  const appSupportsMagicAdd = Boolean(
    hasMagicAdd && !isXanoApp && app?.primaryPlatform === 'responsive'
  )

  const magicAddIsAvailable =
    appMagicAdd?.limits?.schemaUpdateAvailable === true

  const hasRemainingMagicAddTries = (appMagicAdd?.limits?.remaining ?? 0) > 0

  const handleMagicStartClicked = () => {
    if (!magicStartIsAvailable || !hasRemainingMagicStartTries) return

    return history.push(`/apps/${appId}/data/magic-start`)
  }

  const handleMagicAddClicked = () => {
    if (!magicAddIsAvailable || !hasRemainingMagicAddTries) return

    return history.push(`/apps/${appId}/data/magic-add`)
  }

  const dbAssistantOptions = {
    appSupportsMagicStart,
    magicStartIsAvailable,
    hasRemainingMagicStartTries,
    handleMagicStartClicked,
    appSupportsMagicAdd,
    magicAddIsAvailable,
    hasRemainingMagicAddTries,
    handleMagicAddClicked,
  }

  return dbAssistantOptions
}

export default useDbAssistantMenuOptions
