import { DeviceType, LIST } from '@adalo/constants'
import {
  insert,
  remove,
  update,
  getGroupPath,
  getObject,
  getDeviceType,
  remapSiblings,
  removeChildren,
  pathLength,
  sortPaths,
  getId,
} from '@adalo/utils'
import { hasDevicePosition } from 'utils/positioning'
import getSharedObject from 'utils/operations/getSharedObject'
import updateBounds from 'utils/operations/updateBounds'
import resizeParent from 'utils/operations/shouldResizeParent'
import calculatePushGraphs from 'ducks/editor/pushing/calculatePushGraphs'
import createEmptyObject from 'ducks/editor/objects/helpers/createEmptyObject'
import { DEVICE_TYPES } from 'ducks/editor/positioning'

import {
  ContainerAttributes,
  EditorObject,
  LayoutAttributes,
  SharedObject,
} from 'utils/responsiveTypes'
import InstructionState from '../types/InstructionState'
import { toggleFixedPositionHandler } from './toggleFixedPosition'
import usesAbsolutePosition from '../objects/helpers/usesAbsolutePosition'
import {
  refreshLeftRightForObjectChildren,
  refreshLeftRightForObjects,
} from './refreshLeftRight'
import { hasDeviceLayoutEnabled } from '../device-layouts/utils'
import { enableDeviceSpecificLayoutHandler } from './enableDeviceSpecificLayout'
import getObjectNew from '../objects/helpers/getObject'

export interface GroupObjectsToListOptions {
  objectIds: string[]
}

export interface GroupObjectsToListInstruction {
  operation: 'groupObjectsToList'
  options: GroupObjectsToListOptions
}

export const groupObjectsToListHandler = (
  state: InstructionState,
  { objectIds }: GroupObjectsToListOptions
): InstructionState => {
  const { list, pathMap, featureFlags, app } = state
  const { webSettings } = app || {}

  if (objectIds.length === 0) {
    return state
  }

  let updatedList = [...list]
  let updatedPathMap = { ...pathMap }

  let paths = objectIds
    .map(id => updatedPathMap[id])
    .filter(path => pathLength(path) > 1)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  paths = removeChildren(sortPaths(paths))

  const skeletonObj: EditorObject = {
    id: getId() as string,
    type: LIST,
    children: [],
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    positioning: null,
  }
  const hasNewMobileOnlyApp = featureFlags?.['hasNewMobileOnlyApp']
  const mobileOnly = hasNewMobileOnlyApp && webSettings?.layoutMode === 'mobile'

  const hasMinMaxWidth = featureFlags?.['hasMinMaxWidth'] === true

  const group = createEmptyObject(
    updatedList,
    updatedPathMap,
    skeletonObj,
    undefined,
    mobileOnly,
    hasMinMaxWidth
  )

  const groupPath = String(getGroupPath(paths))

  if (!groupPath) {
    return state
  }

  const pieces = groupPath.split('.')
  const screenIndex = pieces[0]

  if (!screenIndex) {
    throw new Error('No screenIndex available')
  }

  const screen = updatedList[Number(screenIndex)]

  if (!screen) {
    throw new Error('No screen available')
  }

  // turn off fixed-positioning for all prospective children
  for (const objectId of objectIds) {
    const object = getObject(updatedList, pathMap[objectId]) as EditorObject

    if (!usesAbsolutePosition(object)) {
      continue
    }

    ;({ list: updatedList } = toggleFixedPositionHandler(
      {
        ...state,
        list: updatedList,
      },
      { objectId, isFixed: false }
    ))
  }

  const childDevicesToInherit: Set<keyof SharedObject> = new Set()
  const childHasDeviceSpecificLayout: Set<keyof SharedObject> = new Set()

  group.children = []
  for (const path of paths) {
    const object = getObject(updatedList, path) as EditorObject

    for (const device of Object.values(DeviceType)) {
      if (hasDeviceLayoutEnabled(object, device)) {
        childDevicesToInherit.add(device)
      }

      if (hasDevicePosition(object, device)) {
        childHasDeviceSpecificLayout.add(device)
      }
    }

    group.children.push(object)
  }

  const pathsReversed = [...paths].reverse()

  for (const path of pathsReversed) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    updatedList = remove(updatedList, path)
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  updatedList = insert(updatedList, groupPath, group)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  updatedPathMap = remapSiblings(updatedList, updatedPathMap, groupPath)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const sharedObject = getSharedObject(getObject(updatedList, groupPath))

  for (const device of DEVICE_TYPES) {
    if (
      !sharedObject[device as keyof SharedObject] ||
      childHasDeviceSpecificLayout.has(device as keyof SharedObject)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      updatedList = updateBounds(
        updatedList,
        updatedPathMap,
        groupPath,
        resizeParent,
        device,
        true
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  updatedList = updateBounds(
    updatedList,
    updatedPathMap,
    groupPath,
    resizeParent,
    undefined,
    true
  )

  // START different to groupObjects
  //
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let tempGroup: EditorObject = getObject(updatedList, groupPath)

  tempGroup = {
    ...tempGroup,
    height: tempGroup.height * 3 + (tempGroup.rowMargin || 0) * 2,
  }

  // Only set initialDevice if the feature flag is enabled
  const hasAutoCustomLayout = featureFlags?.['hasAutoCustomLayout']

  if (hasAutoCustomLayout) {
    tempGroup.initialDevice = getDeviceType(screen.width)
  }

  for (const device of DEVICE_TYPES) {
    if (
      !sharedObject[device as keyof SharedObject] ||
      childHasDeviceSpecificLayout.has(device as keyof SharedObject)
    ) {
      const previousTeamGroup = tempGroup[device] as Partial<
        LayoutAttributes & ContainerAttributes
      >
      tempGroup[device] = {
        ...previousTeamGroup,
        height:
          (previousTeamGroup.height ?? 0) * 3 + (tempGroup.rowMargin || 0) * 2,
      }
    }
    tempGroup.shared = sharedObject
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  updatedList = update(updatedList, groupPath, tempGroup)
  //
  // END different to groupObjects

  updatedList = calculatePushGraphs(updatedList, updatedPathMap, screen.id)

  const updatedGroup = getObjectNew(updatedList, updatedPathMap, group.id)
  for (const device of childDevicesToInherit) {
    if (updatedGroup.children && Array.isArray(updatedGroup.children)) {
      for (const child of updatedGroup.children) {
        ;({ list: updatedList } = enableDeviceSpecificLayoutHandler(
          {
            ...state,
            pathMap: updatedPathMap,
            list: updatedList,
          },
          { objectId: child.id, device }
        ))
      }
    }
  }

  updatedList = refreshLeftRightForObjects(updatedList, updatedPathMap, [
    group.id,
  ])

  updatedList = refreshLeftRightForObjectChildren(
    updatedList,
    updatedPathMap,
    group.id
  )

  return {
    list: updatedList,
    pathMap: updatedPathMap,
    selection: group?.id ? [group.id] : [],
  }
}

const groupObjectsToList = (
  objectIds: string[]
): GroupObjectsToListInstruction => {
  return {
    operation: 'groupObjectsToList',
    options: {
      objectIds,
    },
  }
}

export default groupObjectsToList
