import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dataTypes, fileTypes, LIBRARY_COMPONENT } from '@adalo/constants'

import { getLabel } from 'utils/sources'
import { isRole } from 'utils/libraries'
import {
  getCurrentAppId,
  getComponent,
  selectObjects,
} from 'ducks/editor/objects'

import {
  getLibraryBindingSuggestions,
  getListChildBindings,
} from 'ducks/recommender'

import { getSelection } from 'ducks/editor/selection'
import { getDatasources } from 'ducks/apps/datasources'

import IconOption from 'components/Shared/IconOption'
import FileInput from 'components/Shared/Forms/FileInput'
import BindableTextControl from 'components/Shared/BindableTextControl'

import MenuControl from './MenuControl'

import './FileAndImageControl.css'
import { THEMES } from '../../../../constants'

class FileControl extends Component {
  getLabel = () => {
    const { label, value } = this.props

    if (this.isLibraryComponent() && value?.fileTypes === fileTypes.URL) {
      return <IconOption icon="link" label="url" />
    }

    return label
  }

  isLibraryComponent = () => {
    const { object } = this.props

    return object.type === LIBRARY_COMPONENT
  }

  getMenuOptions = () => {
    const { options, canBeUploaded } = this.props

    if (canBeUploaded || this.isLibraryComponent()) {
      return [
        {
          label: <IconOption icon="dynamic-file" label="Upload" />,
          value: fileTypes.UPLOADED,
        },
        {
          label: (
            <IconOption
              icon="dynamic-image"
              label="database"
              className="file-control-dynamic-file-option"
            />
          ),
          children: options,
        },
        //TODO: add this back when we fix the issues with permissions
        // urls without the correct access headers will not be displayed
        // {
        //   label: <IconOption icon="link" label="URL" />,
        //   value: fileTypes.URL,
        // },
      ]
    }

    return options
  }

  getType = () => {
    const { role } = this.props

    return role && isRole(role, 'listItem')
  }

  getTarget = () => {
    const { namespace, object } = this.props

    if (object?.type === 'libraryComponent') {
      if (namespace) {
        return object.attributes[namespace]
      } else {
        return object.attributes
      }
    } else {
      return object
    }
  }

  getFileKey = () => {
    const { object, name } = this.props
    if (name && object.type !== dataTypes.FILE) return name
    else return 'fileBinding'
  }

  getFileBinding = () => {
    const target = this.getTarget()
    const key = this.getFileKey()

    if (!target) return null

    return target?.[key]
  }

  getFileOptions = () => {
    const object = this.getFileBinding()

    return object?.options
  }

  getFileName = () => {
    const { value } = this.props

    if (value?.fileType === fileTypes.UPLOADED) return value.filename1x

    return null
  }

  handleChange = newValues => {
    const { onChange } = this.props
    const key = this.getFileKey()

    if (!this.isLibraryComponent()) {
      // check if the newValue contains one of these properties
      const filename1x = 'filename1x' in newValues
      // if the new value is for cropping or a static image we want to
      // update the root of the object instead of where the binding takes place
      if (filename1x) return onChange(newValues)
    }

    return onChange({ [key]: newValues })
  }

  handleFileChange = value => {
    const { object, value: libraryValue } = this.props
    let { fileType } = object

    if (this.isLibraryComponent()) {
      fileType = libraryValue?.fileType
    }

    switch (fileType) {
      case 'uploaded':
        return this.handleChange({ filename1x: value })
      default:
        console.warn('Something went wrong uploading the file')
    }
  }

  renderFileInput = () => {
    const { appId } = this.props
    const { accepted } = this.props

    const displayName = ''
    const fileName = this.getFileName()
    const name = ''

    return (
      <FileInput
        acceptedMimeTypes={accepted}
        isAsset
        displayName={displayName}
        buttons={['view', 'remove']}
        appId={appId}
        input={{ value: fileName, name, onChange: this.handleFileChange }}
      />
    )
  }

  handleFileURLChange = ({ url: fileURL }) => {
    const { onChange, value: oldValue, name } = this.props

    if (this.isLibraryComponent()) {
      const newVal = { ...oldValue, binding: fileURL }

      return onChange({ [name]: newVal })
    } else {
      return onChange({ fileURL })
    }
  }

  renderLibraryFileURLInput = () => {
    const { object, role, reference, value } = this.props
    const menuValue = value?.binding

    return (
      <BindableTextControl
        displayName="URL"
        name="url"
        onChange={this.handleFileURLChange}
        value={menuValue}
        objectId={object?.id}
        placeholder="https://example.com/file.pdf"
        role={role}
        reference={reference}
      />
    )
  }

  renderFileURLInput = () => {
    const { object } = this.props

    return (
      <BindableTextControl
        displayName="URL"
        name="url"
        onChange={this.handleFileURLChange}
        value={object?.fileURL || ''}
        objectId={object?.id}
        placeholder="https://example.com/file.pdf"
      />
    )
  }

  handleFileSourceChange = val => {
    const { onChange, name, value: oldValue } = this.props

    if (this.isLibraryComponent()) {
      const fileType =
        typeof val[name] === 'string' ? val[name] : fileTypes.INTERNAL

      let binding

      if (fileType === fileTypes.INTERNAL) {
        binding = val[name]
      } else {
        if (oldValue?.fileType) {
          binding = oldValue?.binding
        } else {
          binding = oldValue
        }
      }

      const newVal = {
        binding,
        fileType,
        type: 'fileBinding',
      }

      return onChange({ [name]: newVal })
    } else {
      return onChange(val)
    }
  }

  render() {
    const { name, displayName, value, object, canBeUploaded } = this.props

    const { fileType } = object
    const options = this.getMenuOptions()

    const rowHeight = canBeUploaded ? 40 : undefined

    let menuValue = value?.type === 'fileBinding' ? value.binding : value

    if (value?.fileType === fileTypes.URL) {
      menuValue = fileTypes.URL
    } else if (value?.imageType === fileTypes.UPLOADED) {
      menuValue = fileTypes.UPLOADED
    }

    if (
      menuValue &&
      Object.keys(menuValue).length === 0 &&
      menuValue.constructor === Object
    ) {
      menuValue = null
    }

    return (
      <div className="library-file-control">
        <MenuControl
          options={options}
          name={name}
          displayName={displayName}
          value={menuValue}
          onChange={this.handleFileSourceChange}
          getLabel={this.getLabel}
          rowHeight={rowHeight}
          menuTheme={THEMES.DATA}
        />

        {fileType === 'url' && this.renderFileURLInput()}
        {value?.fileType === fileTypes.URL && this.renderLibraryFileURLInput()}
        {fileType === fileTypes.UPLOADED ||
        value?.fileType === fileTypes.UPLOADED
          ? this.renderFileInput()
          : null}
      </div>
    )
  }
}

const mapStateToProps = (
  state,
  { objectId, value, role, reference, canBeUploaded }
) => {
  const appId = getCurrentAppId(state)
  const componentId = getComponent(state, objectId).id
  const realValue = value?.type === 'fileBinding' ? value.binding : value
  const binding = typeof realValue === 'object' ? realValue : null
  let label

  if (binding) {
    label = getLabel(state, binding.source, appId, componentId)
  }

  if (
    (canBeUploaded && value === 'uploaded') ||
    value?.fileType === fileTypes.UPLOADED
  ) {
    label = 'Uploaded File'
  }

  if (value === 'url') {
    label = 'URL'
  }

  const options =
    isRole(role, 'listItem') && reference
      ? getListChildBindings({
          state,
          appId,
          componentId,
          objectId,
          reference,
          allowedDataTypes: [dataTypes.FILE],
        })
      : getLibraryBindingSuggestions(state, appId, componentId, objectId, [
          dataTypes.FILE,
        ])

  const selection = getSelection(state)
  const objects = selectObjects(state, selection)

  return {
    appId,
    componentId,
    label,
    options,
    datasources: getDatasources(state, appId),
    object: objects.length === 1 ? objects[0] : null,
  }
}

export default connect(mapStateToProps)(FileControl)
