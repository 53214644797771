import React, { useState } from 'react'
import classNames from 'classnames'
import Icon, { IconButton } from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'

import './PasswordInput.scss'

const PasswordInput = props => {
  const { label, input, tooltip, hasToggle, placeholder, meta, disabled } =
    props

  const { error, touched } = meta

  const showError = error && touched

  const [showKey, setShowKey] = useState(false)

  const baseClass = 'password-field'

  const showKeyHandler = () => {
    setShowKey(prevState => !prevState)
  }

  return (
    <div className={baseClass}>
      <div
        className={classNames({
          [`${baseClass}__label-container`]: true,
          [`${baseClass}__label-container--error`]: error,
        })}
      >
        <label className={`${baseClass}__label`}>{label}</label>
        <div className={`${baseClass}__tooltip`}>
          {tooltip && (
            <Tooltip tooltip={tooltip} placement="bottom-start" hideArrow>
              <Icon type="help-small" />
            </Tooltip>
          )}
        </div>
      </div>

      <div className={`${baseClass}__input-container`}>
        <input
          className={`${baseClass}__input`}
          type={showKey ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder={placeholder}
          disabled={disabled}
          {...input}
        />

        {hasToggle ? (
          <div className={`${baseClass}__toggle`}>
            <span>
              <IconButton type="eye" onClick={showKeyHandler} />
            </span>
          </div>
        ) : null}
      </div>
      {showError && <div className="settings-form-error">{error}</div>}
    </div>
  )
}

export default PasswordInput
