import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getId } from '@adalo/utils'

import { newAction } from '../../../utils/actions'
import { getActions, updateObject } from '../../../ducks/editor/objects'
import { getDatasourceType } from '../../../ducks/apps'
import { openAccordion } from '../../../ducks/accordions'

import EmptyState from './EmptyState'
import Item from './Item'
import './Actions.css'

class Actions extends Component {
  handleCreate = action => {
    const { openAccordion } = this.props

    const actionItem = newAction(action)

    openAccordion('action-item', actionItem.actions[0].id)

    return this.handleUpdate(getId())(actionItem)
  }

  handleUpdate = id => action => {
    let { updateObject, object, actions } = this.props

    actions = {
      ...actions,
      [id]: action,
    }

    if (!action.actions || action.actions.length === 0) {
      return this.handleRemove(id)()
    }

    updateObject(object.id, { actions })
  }

  handleRemove = id => () => {
    let { updateObject, object, actions } = this.props

    actions = {
      ...actions,
      [id]: undefined,
    }

    updateObject(object.id, { actions })
  }

  render() {
    const { actions, appId, componentId, object, datasourceType } = this.props

    return (
      <div className="actions-panel">
        {Object.keys(actions).length === 0 ? (
          <EmptyState
            onCreate={this.handleCreate}
            appId={appId}
            componentId={componentId}
            datasourceType={datasourceType}
          />
        ) : null}
        {Object.keys(actions).map(id => (
          <Item
            key={id}
            id={id}
            action={actions[id]}
            onRemove={this.handleRemove(id)}
            onUpdate={this.handleUpdate(id)}
            appId={appId}
            componentId={componentId}
            objectId={object.id}
            datasourceType={datasourceType}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, componentId, object }) => {
  let actions = {}

  if (object) {
    actions = getActions(state, object.id)
  }

  return {
    actions,
    datasourceType: getDatasourceType(state, appId),
  }
}

export default connect(mapStateToProps, { updateObject, openAccordion })(
  Actions
)
