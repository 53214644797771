import React from 'react'
import classNames from 'classnames'

import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'
import {
  ACTION_NONE,
  ACTION_UPGRADE,
  ACTION_DOWNGRADE,
  ACTION_SWITCH_TO_MONTHLY,
  ACTION_SWITCH_TO_YEARLY,
} from 'utils/billing.ts'

import Stars from './Stars'

import './index.scss'

import usePlanCards from '../usePlanCards'

const Price = ({ amount }) => {
  return (
    <div className="Price">
      <div className="Price-currency">$</div>
      <div className="Price-amount">{amount}</div>
      <div className="Price-buffer" />
    </div>
  )
}

const NewPlanCard = ({
  cardKey = 'free',
  annual,
  current = false,
  currentPlanType,
  isLegacyPlan = false,
  action,
  buttonDisabled,
  buttonTooltip,
  onSelect,
  recommended,
}) => {
  const planCards = usePlanCards(currentPlanType)

  const {
    cardStyle,
    prices,
    displayName,
    subtitle,
    buttonAlias,
    upperPerks,
    lowerPerks,
    plusText,
  } = planCards.cards[cardKey] || {}

  //apply discount
  let price = prices.monthly

  if (annual) {
    price = prices.yearly
  }

  const renderPerk = obj => {
    const text = obj?.text || obj || ''
    const subscript = obj?.subscript || ''

    return <Perk key={text} text={text} subscript={subscript} />
  }

  const hasIntentToDowngrade = cardKey === 'free' && action === 'DOWNGRADE'

  let button = (
    <PlanButton
      planName={buttonAlias}
      current={current}
      action={action}
      disabled={buttonDisabled}
      tooltip={buttonTooltip}
      isLegacyPlan={isLegacyPlan}
      dataAdaloIdLabel={hasIntentToDowngrade ? 'intent-to-churn' : undefined}
    />
  )

  const NO_ACTION = action === ACTION_NONE

  if (buttonTooltip && !NO_ACTION) {
    button = (
      <Tooltip
        triggerClassname="NewPlanCard-buttonTooltip"
        tooltip={buttonTooltip}
        placement="bottom"
      >
        {button}
      </Tooltip>
    )
  }

  const onClick = NO_ACTION || buttonDisabled ? () => {} : onSelect

  const stars = recommended ? (
    <div className="NewPlanCard-stars">
      <Stars cardStyle={cardStyle} />
    </div>
  ) : undefined

  const topLabel = recommended ? (
    <div className="NewPlanCard-featureBanner">
      <div className="NewPlanCard-featureBanner-banner">Recommended</div>
    </div>
  ) : undefined

  const intervalLabel = (
    <div className="NewPlanCard-interval">
      per month {annual ? '/ billed annually' : ''}
    </div>
  )

  return (
    <div
      className={classNames(['NewPlanCard', `NewPlanCard--${cardStyle}`])}
      data-adalo-id={hasIntentToDowngrade ? 'intent-to-churn' : undefined}
      onClick={onClick}
    >
      {topLabel}
      {stars}
      <div className="NewPlanCard-info">
        <div className="NewPlanCard-name">{displayName}</div>
        <div className="NewPlanCard-tagline">{subtitle} </div>
        <Price amount={price} />
        {intervalLabel}
        {button}
      </div>
      <div className="NewPlanCard-perks">
        {lowerPerks.map(renderPerk)}
        <hr className="NewPlanCard-perks-divider" />
        {plusText && <Perk type="plus" text={plusText} />}
        {upperPerks.map(renderPerk)}
      </div>
    </div>
  )
}

const getPlanButtonText = (action, planName, isLegacyPlan) => {
  switch (action) {
    case ACTION_UPGRADE: {
      return `${isLegacyPlan ? 'Switch' : 'Upgrade'} to ${planName}`
    }
    case ACTION_DOWNGRADE: {
      return `${isLegacyPlan ? 'Switch' : 'Downgrade'} to ${planName}`
    }
    case ACTION_SWITCH_TO_MONTHLY: {
      return 'Switch to monthly'
    }

    case ACTION_SWITCH_TO_YEARLY: {
      return 'Switch to yearly'
    }
    case ACTION_NONE: {
      return `You are on ${planName}`
    }
  }
}

const PlanButton = ({
  planName,
  current,
  action,
  disabled,
  isLegacyPlan,
  dataAdaloIdLabel,
}) => {
  const text = getPlanButtonText(action, planName, isLegacyPlan)

  return (
    <Button
      className={classNames('PlanButton', {
        'PlanButton-current': current,
      })}
      type="button"
      disabled={disabled}
      data-adalo-id={dataAdaloIdLabel}
    >
      {text}
    </Button>
  )
}

const Perk = ({ type = 'check', text, subscript }) => {
  let icon = 'list-check'

  if (type === 'plus') {
    icon = 'plus'
  }

  return (
    <li className={classNames('Perk', `Perk--${type}`)}>
      <Icon className="Perk-icon" type={icon} small />
      <span className="Perk-text">{text}</span>
      <span className="Perk-subscript">{subscript}</span>
    </li>
  )
}

export default NewPlanCard
