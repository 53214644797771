export type AuthenticationState = {
  externalDatabases: {
    xano: {
      auth: {
        signup: {
          missingRequestFields: string[]
          missingResponseFields: string[]
          missingEndpoint: boolean
        }
        login: {
          missingRequestFields: string[]
          missingResponseFields: string[]
          missingEndpoint: boolean
        }
        me: {
          missingRequestFields: string[]
          missingResponseFields: string[]
          missingEndpoint: boolean
          authMeErrorMessage?: string
        }
      }
    }
  }
}

type AuthEndpoint = {
  entity: string
  path: string
  label: string
  type: string
  isValid: boolean
  error: {
    requestMissingFields: string[] | undefined
    responseMissingFields: string[] | undefined
    missingEndpoint: boolean
    authMeErrorMessage: string
  }
}

type EndpointsValidation = {
  Authentication: AuthEndpoint[]
}

export const updateAuthSignupEndpointValidation = (
  authState: AuthenticationState,
  endpointsValidation: EndpointsValidation
): void => {
  const missingSignupRequestFields =
    authState.externalDatabases.xano.auth.signup.missingRequestFields
  const missingSignupResponseFields =
    authState.externalDatabases.xano.auth.signup.missingResponseFields

  const missingSignupEndpoint =
    authState.externalDatabases.xano.auth.signup.missingEndpoint

  const hasSignup = endpointsValidation.Authentication.find(
    endpoint => endpoint.path === '/auth/signup'
  )

  const isValid =
    !missingSignupEndpoint &&
    missingSignupRequestFields.length === 0 &&
    missingSignupResponseFields.length === 0

  if (!hasSignup) {
    endpointsValidation.Authentication.push({
      entity: 'Authentication',
      path: '/auth/signup',
      label: 'Sign Up',
      type: 'post',
      isValid,
      error: {
        requestMissingFields:
          missingSignupRequestFields.length > 0
            ? missingSignupRequestFields
            : undefined,
        responseMissingFields:
          missingSignupResponseFields.length > 0
            ? missingSignupResponseFields
            : undefined,
        missingEndpoint: missingSignupEndpoint,
      },
    })
  }
}

export const updateAuthLoginEndpointValidation = (
  authState: AuthenticationState,
  endpointsValidation: EndpointsValidation
): void => {
  const missingLoginRequestFields =
    authState.externalDatabases.xano.auth.login.missingRequestFields
  const missingLoginResponseFields =
    authState.externalDatabases.xano.auth.login.missingResponseFields
  const missingLoginEndpoint =
    authState.externalDatabases.xano.auth.login.missingEndpoint

  const hasLogin = endpointsValidation.Authentication.find(
    endpoint => endpoint.path === '/auth/login'
  )

  const isValid =
    !missingLoginEndpoint &&
    missingLoginRequestFields.length === 0 &&
    missingLoginResponseFields.length === 0

  if (!hasLogin) {
    endpointsValidation.Authentication.push({
      entity: 'Authentication',
      path: '/auth/login',
      label: 'Login',
      type: 'post',
      isValid,
      error: {
        requestMissingFields:
          missingLoginRequestFields.length > 0
            ? missingLoginRequestFields
            : undefined,
        responseMissingFields:
          missingLoginResponseFields.length > 0
            ? missingLoginResponseFields
            : undefined,
        missingEndpoint: missingLoginEndpoint,
      },
    })
  }
}

export const updateAuthMeEndpointValidation = (
  authState: AuthenticationState,
  endpointsValidation: EndpointsValidation
): void => {
  const missingAuthMeRequestFields =
    authState.externalDatabases.xano.auth.me.missingRequestFields
  const missingAuthMeResponseFields =
    authState.externalDatabases.xano.auth.me.missingResponseFields
  const missingAuthMeEndpoint =
    authState.externalDatabases.xano.auth.me.missingEndpoint

  const authMeErrorMessage =
    authState.externalDatabases.xano.auth.me.authMeErrorMessage

  const hasUserAuth = endpointsValidation.Authentication.find(
    endpoint => endpoint.path === '/auth/me'
  )

  const isValid =
    !missingAuthMeEndpoint &&
    missingAuthMeRequestFields.length === 0 &&
    missingAuthMeResponseFields.length === 0 &&
    !authMeErrorMessage

  if (!hasUserAuth) {
    endpointsValidation.Authentication.push({
      entity: 'Authentication',
      path: '/auth/me',
      label: 'User Info',
      type: 'get',
      isValid,
      error: {
        requestMissingFields:
          missingAuthMeRequestFields.length > 0
            ? missingAuthMeRequestFields
            : undefined,
        responseMissingFields:
          missingAuthMeResponseFields.length > 0
            ? missingAuthMeResponseFields
            : undefined,
        missingEndpoint: missingAuthMeEndpoint,
        authMeErrorMessage,
      },
    })
  }
}
