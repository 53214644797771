import { useFlags } from 'flags'
import PreviewButton from '../Editor/PreviewButton'
import ShareButton from '../Editor/ShareButton'
import ViewApp from '../Editor/ViewApp'
import { Spacer } from '../App/Navbar'
import AppRooms from '../AppRooms'
import OrgAppSwitcher from './OrgAppSwitcher'

import './Nav.scss'

const EditorNav: React.FC = () => {
  const flags = useFlags()
  const { hasViewAppMenu } = flags

  const openApp = hasViewAppMenu ? (
    <ViewApp />
  ) : (
    <>
      <PreviewButton />
      <ShareButton />
    </>
  )

  return (
    <>
      <OrgAppSwitcher />
      <AppRooms />
      <Spacer />
      {openApp}
    </>
  )
}

export default EditorNav
