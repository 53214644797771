import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../../Shared/Icon'

export default class Link extends Component {
  render() {
    const { to, children, icon } = this.props

    return (
      <NavLink
        className="data-item-link"
        activeClassName="data-item-link-active"
        to={to}
      >
        <Icon type={icon} />
        <span className="data-item-link-text">{children}</span>
      </NavLink>
    )
  }
}
