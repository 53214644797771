import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { withRouter } from 'react-router-dom'

import Modal from 'components/Shared/Modal'

import { formatBaseURL } from '../../../../utils/urls'
import WrappedInput from '../../../Shared/Forms/WrappedInput'
import { validate } from './validations'
import FieldsSection from './FieldsSection'
import EndpointsSection from './EndpointsSection'

export const FORM_NAME = 'apiCollectionForm'

class APICollectionForm extends Component {
  handleSubmit = e => {
    e.preventDefault()
  }

  render() {
    const { datasource, form, match } = this.props
    const { appId, datasourceId } = match.params
    const { baseURL } = datasource

    const url = `/apps/${appId}/data/${datasourceId}`

    return (
      <form className="api-collection-form" onSubmit={this.handleSubmit}>
        <Modal.Content>
          <Field
            autoComplete="off"
            component={WrappedInput}
            name="name"
            placeholder="Users"
            className="api-collection-title"
          />
          <Field
            autoComplete="off"
            component={WrappedInput}
            name="baseURL"
            label="Base URL"
            placeholder="users"
            prefix={formatBaseURL(baseURL)}
          />
          <FieldsSection datasource={datasource} formName={form} />
          <EndpointsSection formName={form} />
        </Modal.Content>
        <Modal.Actions>
          <Modal.Button to={url} orange>
            Done
          </Modal.Button>
        </Modal.Actions>
      </form>
    )
  }
}

const form = reduxForm({
  form: FORM_NAME,
  validate,
})(APICollectionForm)

export default withRouter(form)
