import update from 'immutability-helper'

export const ACTION = {
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

export const CONDITION = {
  ANYONE: 'ANYONE',
  LOGGED_IN: 'LOGGED_IN',
  OWNER: 'OWNER',
  NONE: 'NONE',
}

const USER_TABLE_DEFAULTS = {
  email: {
    [ACTION.READ]: CONDITION.ANYONE,
    [ACTION.UPDATE]: CONDITION.OWNER,
  },
  username: {
    [ACTION.READ]: CONDITION.ANYONE,
    [ACTION.UPDATE]: CONDITION.OWNER,
  },
  full_name: {
    [ACTION.READ]: CONDITION.ANYONE,
    [ACTION.UPDATE]: CONDITION.OWNER,
  },
  password: {
    [ACTION.READ]: CONDITION.NONE,
    [ACTION.UPDATE]: CONDITION.OWNER,
  },
  temporary_password: {
    [ACTION.READ]: CONDITION.NONE,
    [ACTION.UPDATE]: CONDITION.NONE,
  },
  temporary_password_expires_at: {
    [ACTION.READ]: CONDITION.NONE,
    [ACTION.UPDATE]: CONDITION.NONE,
  },
}

export const collectionPolicy = (action, allowed, paths) => {
  const result = {
    action,
    allowed,
  }

  if (paths) {
    result.paths = [...paths]
  }

  return result
}

export const fieldPolicy = (fieldId, action, allowed) => ({
  fieldId,
  action,
  allowed,
})

export const getCollectionPolicy = (policies, action) => {
  const defaultPolicy = {
    action,
    allowed: CONDITION.ANYONE, // Default to Everyone can access
  }

  if (!policies) {
    return defaultPolicy
  }

  const policy = policies.find(
    policy => !policy.fieldId && policy.action === action
  )

  return policy || defaultPolicy
}

export const updatePolicy = (prevPolicies, policy) => {
  if (!prevPolicies) {
    prevPolicies = []
  }

  const { action, fieldId } = policy

  // Filter out prior conflicting policy
  const result = prevPolicies.filter(current => {
    const sameAction = current.action === action

    const sameField =
      (!current.fieldId && !fieldId) || current.fieldId === fieldId

    return !(sameAction && sameField)
  })

  // Append the new policy
  result.push(policy)

  return result
}

export const setPoliciesOnTable = (prevTable, policies) => {
  return Object.assign({}, prevTable, { policies })
}

export const defaultFieldPolicy = (isUsersTable, fieldId, action) => {
  if (!isUsersTable) {
    return fieldPolicy(fieldId, action, CONDITION.ANYONE)
  }

  let allowed = CONDITION.ANYONE

  if (USER_TABLE_DEFAULTS[fieldId] && USER_TABLE_DEFAULTS[fieldId][action]) {
    allowed = USER_TABLE_DEFAULTS[fieldId][action]
  }

  return fieldPolicy(fieldId, action, allowed)
}

// Policy changes represent a pending change made by a maker which hasn't been saved yet.
export const policyChange = (tableId, newPolicy) => [tableId, newPolicy]

/**
 * Applies a set of policy changges to the datasource and returns the updated version.
 */
export const applyPolicyChanges = (prevDatasource, unsavedChanges) => {
  let result = update(prevDatasource, {})

  // Apply all new policies in unsavedChanges
  for (const [tableId, newPolicy] of unsavedChanges) {
    const table = result.tables[tableId]
    const { policies } = table
    const updatedPolicies = updatePolicy(policies, newPolicy)
    const updatedTable = setPoliciesOnTable(table, updatedPolicies)

    result = update(result, {
      tables: {
        [tableId]: {
          $set: updatedTable,
        },
      },
    })
  }

  return result
}
