import React from 'react'

import Modal from 'components/Shared/Modal'

import './ButtonBar.scss'

export default function ButtonBar({
  enableSave,
  footnote,
  onSaveClick,
  onCancelClick,
}) {
  return (
    <div className="data-permissions-button-bar">
      <Modal.Actions>
        {footnote && (
          <div className="footnote">
            <p>{footnote}</p>
          </div>
        )}
        <Modal.Button text onClick={onCancelClick}>
          Cancel
        </Modal.Button>
        <Modal.Button orange onClick={onSaveClick} disabled={!enableSave}>
          Save &amp; Close
        </Modal.Button>
      </Modal.Actions>
    </div>
  )
}
