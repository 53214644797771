import React from 'react'
import _ from 'lodash'
import { deepMap, getId, sort } from '@adalo/utils'
import {
  ACTION,
  NAVIGATION_BACK,
  LIBRARY_COMPONENT,
  FORM,
  LIST,
  actionTypes,
  events,
  COMPONENT,
  SELECT,
  LABEL,
  INPUT,
  IMAGE,
  TABLE,
} from '@adalo/constants'
import { v4 as uuid } from 'uuid'

import { MenuOption, Submenu } from 'ducks/recommender'

import Icon from 'components/Shared/Icon'

import { GoogleTooltip } from 'components/Shared/GoogleHelpLink'

import { singularize } from './strings'
import { getScreenIcon } from './icons'
import { verifyXanoApp } from './externalDatabases'

const lockIcon = <Icon type="lock" color="darkerGray" small />

const ACTION_OPTIONS = [
  actionTypes.NAVIGATE,
  'Divider',
  actionTypes.CREATE_OBJECT,
  actionTypes.UPDATE_OBJECT,
  actionTypes.DELETE_OBJECT,
  'Divider',
  'Notifications',
  actionTypes.LOCATION_PERMISSION,
  actionTypes.CUSTOM,
  'Zapier',
  'Divider',
  'More',
]

const API_ACTION_OPTIONS = [
  actionTypes.NAVIGATE,
  'Divider',
  actionTypes.AUTHENTICATE_API,
  actionTypes.LOGOUT,
  actionTypes.SIGNUP_API,
  'Divider',
  actionTypes.SHARE,
  actionTypes.SET_INPUT_VALUE,
  'Divider',
  actionTypes.CUSTOM,
]

const XANO_ACTION_OPTIONS = ACTION_OPTIONS.filter(option => option !== 'Zapier')

export const newActionItem = defaults => {
  return {
    actionType: actionTypes.CREATE_OBJECT,
    options: {},
    ...defaults,
    id: getId(),
  }
}

export const newAction = actionDefaults => ({
  type: ACTION,
  eventType: events.TAP,
  actions: [newActionItem(actionDefaults)],
})

export const getLinkOptions = (screens, componentId, app, opts = {}) => {
  let { allScreens, skipNew, appId, hasFeatureTemplatesAction } = opts
  allScreens = allScreens || !componentId

  let options = []

  const showFeatureTemplatesAction =
    hasFeatureTemplatesAction && app.magicLayout && !app.externalUsers?.enabled

  if (!skipNew) {
    options.push(new MenuOption('New Screen...', 'new', <Icon type="plus" />))

    if (showFeatureTemplatesAction) {
      options.push(
        new MenuOption(
          'New Feature...',
          'addFeatureTemplate',
          <Icon type="add-to-photos" />
        )
      )
    }
  }

  if (screens.length > 0) {
    let screensList = screens

    if (!allScreens) {
      if (options.length > 0) {
        options.push(null)
      }

      options.push(
        new MenuOption('Back', NAVIGATION_BACK, <Icon type="arrow-back" />)
      )

      screensList = screens.filter(screen => screen.id !== componentId)
    }

    if (options.length > 0 && screensList.length > 0) {
      options.push(null)
    }

    screensList = sort(screensList, screen => {
      return (screen.name || 'Untitled Screen').toLowerCase()
    })

    const { authComponentId, launchComponentId } = app

    options = options.concat(
      screensList.map(
        screen =>
          new MenuOption(
            componentId === screen.id
              ? `${screen.name} (this screen)`
              : screen.name || 'Untitled Screen',
            appId ? { appId, screenId: screen.id } : screen.id,
            (
              <Icon
                type={getScreenIcon(
                  screen.id,
                  authComponentId,
                  launchComponentId
                )}
              />
            )
          )
      )
    )
  }

  if (options.slice(-1)[0] !== null) {
    options.push(null)

    options.push(
      new MenuOption(
        'Website...',
        actionTypes.EXTERNAL_LINK,
        <Icon type="new-window" />
      )
    )
  }

  return options
}

const getDatasourceOptions = datasources => {
  const options = []

  if (!datasources?.[0]) return options

  const datasourceId = datasources?.[0]?.id

  Object.entries(datasources?.[0]?.tables).map(items => {
    const [tableId, table] = items

    options.push(
      new MenuOption(
        table?.name,
        { datasourceId, tableId },
        <Icon type="relationship-single" />
      )
    )
  })

  return options
}

const getCustomActionOptions = (customActions, appId) => {
  const options = []
  let isExternalUsers = false

  Object.values(customActions).map(action => {
    if (!action.deleted && action.body.type !== 'External Users') {
      options.push(
        new MenuOption(
          action.body.name,
          { type: actionTypes.CUSTOM, id: action.id },
          <Icon type="lightning" />
        )
      )
    } else if (
      action.body.type === 'External Users' &&
      action.body.appId === appId
    ) {
      isExternalUsers = true
    }
  })

  if (options.length) {
    options.push(null)
  }

  return { options, isExternalUsers }
}

const getInternalUsersOptions = () =>
  new Submenu('User Login', [
    new MenuOption(
      'Log In',
      actionTypes.AUTHENTICATE,
      <Icon type="lock-open" />
    ),
    new MenuOption('Log Out', actionTypes.LOGOUT, <Icon type="power" />),
    new MenuOption('Sign Up', actionTypes.SIGNUP, <Icon type="person-add" />),
    new MenuOption(
      'Forgot Password',
      actionTypes.FORGOT_PASSWORD,
      <Icon type="key" />
    ),
  ])

const getExternalUsersOptions = (customActions, appId) => {
  const options = Object.values(customActions)
    .filter(
      action =>
        action.body.type === 'External Users' && action.body.appId === appId
    )
    .map(action => {
      const actionType = action.body.name.includes('Login')
        ? actionTypes.AUTHENTICATE_EXTERNAL
        : actionTypes.SIGNUP_EXTERNAL

      return new MenuOption(
        action.body.name,
        {
          actionType,
          options: { id: action.id, type: actionType },
        },
        <Icon type="lightning" />
      )
    })

  options.push(
    new MenuOption('Log Out', actionTypes.LOGOUT, <Icon type="power" />)
  )

  return new Submenu('User Login', options)
}

export const getActionOptions = ({
  datasourceType,
  datasources,
  screens,
  componentId,
  contextOpts,
  customActions,
  paying,
  isAfterTrial,
  appId,
  app,
  customActionParams = {},
  geolocationParams = {},
  hasFeatureTemplatesAction,
}) => {
  const linkOptions = getLinkOptions(screens, componentId, app, {
    hasFeatureTemplatesAction,
  }).map(opt => {
    if (
      !opt ||
      !opt.value ||
      opt.disabled ||
      opt.value === actionTypes.EXTERNAL_LINK
    ) {
      return opt
    }

    return new MenuOption(opt.label, { target: opt.value }, opt.icon)
  })

  let { options: customActionOptions, isExternalUsers } =
    getCustomActionOptions(customActions, appId)

  const { disableCustomActions } = customActionParams

  customActionOptions = customActionOptions.map(opt => {
    if (!disableCustomActions && opt && !paying && isAfterTrial) {
      opt.disabled = true
    }

    return opt
  })

  customActionOptions = customActionOptions.map(opt => {
    if (!opt || !opt.value || opt.disabled) {
      return opt
    }

    const menuOption = new MenuOption(
      opt.label,
      { actionType: actionTypes.CUSTOM, options: opt.value },
      <Icon type="lightning" />
    )

    const { disableCustomActions, startTrial, onClick } = customActionParams

    if (disableCustomActions) {
      menuOption.locked = true
      menuOption.rightIcon = lockIcon
      menuOption.onClick = onClick
    }

    if (startTrial) {
      menuOption.hoverContent = 'start trial'
    }

    return menuOption
  })

  customActionOptions.push(
    new MenuOption(
      'Ask ChatGPT',
      { target: 'createNewCustomAction', type: 'chatgpt' },
      <Icon type="chatgpt" color="transparent" />
    )
  )

  customActionOptions.push(
    new MenuOption(
      'New Custom Action...',
      { target: 'createNewCustomAction' },
      <Icon type="plus" />
    )
  )

  let datasourceOptions = getDatasourceOptions(datasources).map(opt => {
    if (!opt || !opt.value || opt.disabled) {
      return opt
    }

    return new MenuOption(
      singularize(opt.label),
      { actionType: actionTypes.CREATE_OBJECT, options: opt.value },
      <Icon type="relationship-single" />
    )
  })

  const isXanoApp = verifyXanoApp(app)

  if (!isXanoApp) {
    datasourceOptions = datasourceOptions.concat([
      null,
      new MenuOption(
        'New Collection...',
        { target: 'createNewCollection' },
        <Icon type="plus" />
      ),
    ])
  }

  const contextOptions = (context, actionType) => {
    if (!context) {
      return
    }

    if (typeof context === 'function') {
      return () => contextOptions(context(), actionType)
    }

    const menuOptions = []
    const subMenus = []

    for (const option of context) {
      if (!option || option.disabled) {
        continue
      }

      const children =
        typeof option.children === 'function'
          ? option.children()
          : option.children

      if (option.value) {
        menuOptions.push(
          new MenuOption(
            option.label,
            { actionType, options: { bindingId: option.value } },
            <Icon type="relationship-single" />
          )
        )
      }

      if (children?.length) {
        subMenus.push(
          new Submenu(option.label, contextOptions(option.children, actionType))
        )
      }
    }

    return [
      ...menuOptions,
      ...(menuOptions.length && subMenus.length ? [null] : []),
      ...subMenus,
    ]
  }

  const getOptions = (datasourceType, isXano) => {
    if (isXano) {
      return XANO_ACTION_OPTIONS
    }

    if (datasourceType === 'api') {
      return API_ACTION_OPTIONS
    }

    return ACTION_OPTIONS
  }

  const options = getOptions(datasourceType, isXanoApp)

  return options.map(opt => {
    const { geolocationDisabled, googleApiKeyIsValid, onClick, hoverContent } =
      geolocationParams

    switch (opt) {
      case actionTypes.NAVIGATE:
        return new Submenu('Link', linkOptions)
      case actionTypes.CREATE_OBJECT:
        return new Submenu('Create', datasourceOptions)
      case actionTypes.UPDATE_OBJECT:
        return new Submenu('Update', contextOptions(contextOpts, opt))
      case actionTypes.DELETE_OBJECT:
        return new Submenu('Delete', contextOptions(contextOpts, opt))
      case actionTypes.CUSTOM:
        return new Submenu('Custom Action', customActionOptions)
      case actionTypes.AUTHENTICATE_API:
        return new MenuOption(
          'Log In',
          actionTypes.AUTHENTICATE_API,
          'lock-open'
        )
      case actionTypes.LOGOUT:
        return new MenuOption('Log Out', actionTypes.LOGOUT, 'power')
      case actionTypes.SIGNUP_API:
        return new MenuOption('Sign Up', actionTypes.SIGNUP_API, 'person-add')
      case actionTypes.SHARE:
        return new MenuOption('Share', actionTypes.SHARE, <Icon type="share" />)
      case actionTypes.SET_INPUT_VALUE:
        return new MenuOption(
          'Change Input Value',
          actionTypes.SET_INPUT_VALUE,
          'change-input'
        )
      case 'Notifications':
        return new Submenu('Notifications', [
          new MenuOption(
            'Trigger Notification',
            actionTypes.PUSH_NOTIFICATION,
            'email'
          ),
          new MenuOption(
            'Request Permission',
            actionTypes.NOTIFICATION_PERMISSION,
            'notification-permission'
          ),
        ])
      case actionTypes.LOCATION_PERMISSION:
        if (geolocationDisabled) {
          return {
            label: 'Location',
            locked: true,
            rightIcon: <Icon type="lock-small" small />,
            onClick,
            hoverContent,
          }
        }

        if (googleApiKeyIsValid) {
          return new Submenu('Location', [
            new MenuOption(
              'Request Permission',
              actionTypes.LOCATION_PERMISSION,
              'location-permission'
            ),
          ])
        }

        // displayed when the Google API key is missing or invalid
        // tooltip contains a link to where you can set or fix
        // the Google API key
        return {
          label: 'Location',
          children: [],
          rightIcon: GoogleTooltip,
        }
      case 'Zapier':
        return new Submenu('New Integration', [
          new MenuOption(
            'Gmail',
            { target: 'chooseZap', type: 'gmail' },
            <Icon type="gmail" color="transparent" />
          ),
          new MenuOption(
            'SendGrid',
            { target: 'chooseZap', type: 'sendgrid' },
            <Icon type="sendgrid" color="transparent" />
          ),
          new MenuOption(
            'Google Sheets',
            { target: 'chooseZap', type: 'google-sheets' },
            <Icon type="google-sheets" color="transparent" />
          ),
          new MenuOption(
            'Twilio',
            { target: 'chooseZap', type: 'twilio' },
            <Icon type="twilio" color="transparent" />
          ),
          new MenuOption(
            'Mailchimp',
            { target: 'chooseZap', type: 'mailchimp' },
            <Icon type="mailchimp" color="transparent" />
          ),
          new MenuOption(
            'Slack',
            { target: 'chooseZap', type: 'slack' },
            <Icon type="slack" color="transparent" />
          ),
          new MenuOption(
            'Google Drive',
            { target: 'chooseZap', type: 'google-drive' },
            <Icon type="google-drive" color="transparent" />
          ),
          null,
          new MenuOption('Browse all 5,000 apps...', {
            target: 'chooseZap',
            type: 'all',
          }),
        ])
      case 'More':
        return new Submenu('More...', [
          new MenuOption('Share', actionTypes.SHARE, <Icon type="share" />),
          new MenuOption(
            'Change Input Value',
            actionTypes.SET_INPUT_VALUE,
            'change-input'
          ),
          isExternalUsers && datasourceType !== 'api'
            ? getExternalUsersOptions(customActions, appId)
            : getInternalUsersOptions(),
        ])
      case 'Divider':
        return null
      default:
        return opt
    }
  })
}

export const getIndividualActions = actionGroup => {
  const actions = []
  // eslint-disable-next-line guard-for-in
  for (const actionId in actionGroup) {
    const groupActions = actionGroup[actionId].actions
    if (!groupActions) continue
    for (const action of groupActions) {
      const { id, options, actionType } = action
      actions.push({
        id,
        options,
        actionType,
        actionId,
      })
    }
  }

  return actions
}

export const changeActionIds = objects => {
  if (!objects) {
    return objects
  }

  if (!Array.isArray(objects)) {
    return changeActionIds([objects])[0]
  }

  // Update action IDs, storing each
  objects = objects.map(obj => {
    const actions = obj.actions || {}
    const newActions = {}
    const idMap = {}

    for (const actionId of Object.keys(actions)) {
      const newId = getId()
      newActions[newId] = actions[actionId]
      idMap[actionId] = newId
    }

    obj = { ...obj, actions: newActions }

    if (obj.children) {
      obj.children = changeActionIds(obj.children)
    }

    if (obj.type === LIBRARY_COMPONENT) {
      obj.attributes = updateActionRefs(obj.attributes, idMap)
    }

    if (obj.type === FORM) {
      obj.submitButton = updateActionRefs(obj.submitButton, idMap)
    }

    return obj
  })

  return objects
}

const deepChangeFilters = filter => {
  return filter.map(innerFilter => {
    if (Array.isArray(innerFilter)) {
      return innerFilter.map(filterItem => {
        return { ...filterItem, id: getId() }
      })
    }

    return {
      ...innerFilter,
      id: getId(),
    }
  })
}

/**
 *
 * @param {import('./responsiveTypes').EditorObject} object
 * @param {string} attribute
 * @returns
 */
const updateMagicTextBindings = (object, attribute) => {
  const updatedObject = _.cloneDeep(object)

  if (Array.isArray(object[attribute])) {
    for (let i = 0; i < object[attribute].length; i = i + 1) {
      const text = object[attribute][i]
      if (typeof text !== 'object') {
        continue
      }

      const filter = text.source?.source?.options?.filter
      if (Array.isArray(filter)) {
        updatedObject[attribute][i].source.source.options.filter =
          deepChangeFilters(filter)
      }
    }
  }

  return updatedObject
}

/**
 *
 * @param {import('./responsiveTypes').EditorObject[]} [objects]
 * @returns
 */
export const changeFilterIds = objects => {
  if (!objects) {
    return objects
  }

  if (!Array.isArray(objects)) {
    return changeFilterIds([objects])[0]
  }

  // Update filters IDs, storing each
  const result = objects.map(obj => {
    // Handle case where object is a list of filters (e.g. filter in formula)
    if (obj.type === 'binding') {
      const { filter } = obj.source?.source?.options ?? {}

      if (filter && Array.isArray(filter)) {
        obj.source.source.options.filter = deepChangeFilters(filter)
      }

      return obj
    }

    if (obj.type === COMPONENT) {
      obj.children = changeFilterIds(obj.children)

      return obj
    }

    // Custom List
    if (obj.type === LIST && obj?.dataBinding) {
      const {
        source: { options },
      } = obj.dataBinding

      if (options) {
        const { filter } = options

        if (filter && Array.isArray(filter)) {
          obj.dataBinding.source.options.filter = deepChangeFilters(filter)
        }
      }
    }

    if (obj.type === TABLE) {
      try {
        // Update the filter(s) for the Table datasource
        const filter = obj.dataBinding?.source?.options?.filter
        if (Array.isArray(filter)) {
          obj.dataBinding.source.options.filter = deepChangeFilters(filter)
        }
      } catch (e) {
        // do nothing, table dataBinding has no filter
      }

      try {
        // Update the filter(s) for the Table emptyStateTitleText
        obj = updateMagicTextBindings(obj, 'emptyStateTitleText')
      } catch (e) {
        // do nothing, table emptyStateTitleText
      }

      try {
        // Update the filter(s) for the Table emptyStateSubtitleText
        obj = updateMagicTextBindings(obj, 'emptyStateSubtitleText')
      } catch (e) {
        // do nothing, table emptyStateSubtitleText
      }
    }

    if (obj.type === LIBRARY_COMPONENT) {
      let options

      if (obj.attributes.imageList) {
        // Avatar List, Horizontal Card List, Horizontal Chip List
        options = obj.attributes.imageList.source.options
      } else if (obj.attributes.items) {
        // Simple List, Card List, Image List
        options = obj.attributes.items.source.options
      } else {
        // App Bar
        return obj
      }

      if (options) {
        const { filter } = options

        if (filter && Array.isArray(filter)) {
          options.filter = deepChangeFilters(filter)
        }
      }
    }

    if (obj.type === SELECT) {
      const { filter } = obj.select?.source?.options ?? {}

      if (filter && Array.isArray(filter)) {
        obj.select.id = getId()
        obj.select.source.options.filter = deepChangeFilters(filter)
      }
    }

    if (obj.type === LABEL && Array.isArray(obj.text)) {
      obj.text.forEach(text => {
        if (text.type && text.type === 'binding') {
          const { filter } = text.source?.source?.options ?? {}

          if (filter && Array.isArray(filter)) {
            text.source.source.options.filter = deepChangeFilters(filter)
          }
        }
      })
    }

    if (obj.type === INPUT && Array.isArray(obj.defaultValue)) {
      obj.defaultValue.forEach(text => {
        if (text.type && text.type === 'binding') {
          const { filter } = text.source?.source?.options ?? {}

          if (filter && Array.isArray(filter)) {
            text.source.source.options.filter = deepChangeFilters(filter)
          }
        }
      })
    }

    if (obj.type === IMAGE && Array.isArray(obj.imageURL)) {
      obj.imageURL.forEach(text => {
        if (text.type && text.type === 'binding') {
          const { filter } = text.source?.source?.options ?? {}

          if (filter && Array.isArray(filter)) {
            text.source.source.options.filter = deepChangeFilters(filter)
          }
        }
      })
    }

    return obj
  })

  return result
}

const updateActionRefs = (attributes, idMap) => {
  attributes = { ...attributes }

  for (const key of Object.keys(attributes)) {
    if (attributes[key] && attributes[key].type === 'actionRef') {
      attributes[key] = {
        ...attributes[key],
        actionId: idMap[attributes[key].actionId],
      }
    } else if (
      attributes[key] &&
      !Array.isArray(attributes[key]) &&
      typeof attributes[key] === 'object'
    ) {
      attributes[key] = updateActionRefs(attributes[key], idMap)
    }
  }

  return attributes
}

export const changeFormulaIds = (
  objects,
  newParentId,
  shouldChangeId = false
) => {
  if (!objects) return objects

  if (Array.isArray(objects)) {
    const parsedObjects = deepMap(objects, object => {
      return changeId(object, newParentId, shouldChangeId)
    })

    return parsedObjects
  }

  if (typeof objects === 'object') {
    return changeId(objects, newParentId, shouldChangeId)
  }
}

const changeId = (obj, newParentId, shouldChangeId) => {
  const object = JSON.parse(JSON.stringify(obj))

  for (const field in object) {
    if (field) {
      if (Array.isArray(object[field])) {
        const parsedField = object[field].map(item => {
          if (item.type === 'formula') {
            if (shouldChangeId) {
              item.id = uuid()

              if (Array.isArray(item.formula)) {
                item.formula = changeFilterIds(item.formula)
              }
            }

            if (newParentId && newParentId !== item.componentId) {
              item.componentId = newParentId
            }
          }

          return item
        })

        object[field] = parsedField
      }
    }
  }

  return object
}

export const setDefaultTransitionToNone = transitionOptions => {
  const finalTransitionOptions = [...transitionOptions]

  const temp = finalTransitionOptions[0]
  finalTransitionOptions[0] = finalTransitionOptions[2]
  finalTransitionOptions[2] = temp

  return finalTransitionOptions
}
