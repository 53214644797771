import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import { getDatasources } from '../../../../ducks/apps/datasources'
import WrappedSelect from '../../../Shared/Forms/WrappedSelect'

class DatasourceSelector extends Component {
  datasourceOptions() {
    const { datasources } = this.props

    return Object.keys(datasources).map(id => ({
      label: 'App Data',
      value: datasources[id].id,
    }))
  }

  render() {
    const { name } = this.props

    return (
      <div style={{ display: 'none' }}>
        <Field
          autoSelect
          name={name}
          component={WrappedSelect}
          options={this.datasourceOptions()}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { appId }) => ({
  datasources: getDatasources(state, appId),
})

export default connect(mapStateToProps)(DatasourceSelector)
