import type { AxiosResponse } from 'utils/io/http/axios'
import { FETCH_USER } from '../actions/user'
import { RESET } from '..'

export type XanoDatabaseState = {
  externalDatabases: {
    xano: {
      user: {
        '/users/{id}'?: ['post' | 'get' | 'delete']
        '/users'?: ['post' | 'get']
      }
    }
  } | null
}

type BaseUrlResponse = {
  status: number
}

export interface XanoDatabaseAction {
  type: typeof FETCH_USER
  payload?: Promise<AxiosResponse<BaseUrlResponse>>
}

interface XanoDatabaseActionResponse
  extends Omit<XanoDatabaseAction, 'payload'> {
  payload: {
    table: string
    fields: {
      [key: string]: {
        type: string
        required: boolean
        locked: boolean
        name: string
      }
    }
    '/users/{id}'?: ['post' | 'get' | 'delete']
    '/users'?: ['post' | 'get']
  }
}

export const USER_INITIAL_STATE = {
  externalDatabases: {
    xano: {
      user: {},
    },
  },
}

// Reducer
export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = USER_INITIAL_STATE,
  action: XanoDatabaseActionResponse
): XanoDatabaseState => {
  if (action.type === `${RESET}`) {
    return USER_INITIAL_STATE
  }

  if (action.type === `${FETCH_USER}`) {
    const userResponse = state.externalDatabases?.xano?.user

    const userData = userResponse

    return {
      externalDatabases: {
        xano: {
          user: {
            ...userData,
            ...action.payload,
          },
        },
      },
    }
  }

  return state
}
