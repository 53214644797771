export const SHOW = Symbol('SHOW_NOTIFICATION')
export const HIDE = Symbol('HIDE_NOTIFICATION')
export const CLEAR_ALL = Symbol('CLEAR_ALL_NOTIFICATIONS')

export const INITIAL_STATE = []

let counter = 0

export default (state = INITIAL_STATE, action) => {
  if (action?.type === SHOW) {
    const { id, message } = action

    return state.concat([{ id, message }])
  }

  if (action?.type === HIDE) {
    const { id } = action

    return state.filter(itm => itm.id !== id)
  }

  if (action?.type === CLEAR_ALL) {
    return INITIAL_STATE
  }

  return state
}

// Actions

export const notify = message => dispatch => {
  const id = counter
  counter += 1

  dispatch({ type: SHOW, id, message })

  window.setTimeout(() => {
    dispatch(hideNotification(id))
  }, 4000)
}

export const hideNotification = id => ({
  type: HIDE,
  id,
})

export const clearNotifications = () => ({
  type: CLEAR_ALL,
})

// Selectors

export const getNotifications = state => {
  return state.notifications
}
