import { CustomActionState, CustomAction } from './customAction.type'

export default function removeTemplateObject(
  customActions: CustomActionState
): CustomActionState {
  const result: CustomActionState = {}

  for (const key in customActions) {
    if (
      Object.prototype.hasOwnProperty.call(customActions, key) &&
      customActions[key]?.body?.template !== true
    ) {
      result[key] = { ...customActions[key] } as CustomAction
    }
  }

  return result
}
