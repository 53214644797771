import ObjectType from '../types/ObjectType'
import ContentLayoutResult from './ContentLayoutResult'
import LayoutContext from './layout/LayoutContext'
import ObjectNode from './ObjectNode'
import { Display } from './styles'

const layoutFlowContent = (
  container: ObjectNode<ObjectType>,
  context: LayoutContext
): ContentLayoutResult => {
  const {
    containerWidth: contentWidth,
    containerOffsetX: contentOffsetX,
    containerOffsetY: contentOffsetY,
    device,
    subtreeRootObject,
  } = context

  const layoutChildren = []

  let contentHeight = 0

  for (const child of container.getInFlowChildren(context)) {
    const childDisplay = child.getStyle('display', device)
    if (childDisplay === Display.None) {
      // Do not render hidden children
      continue
    }

    const childContext: LayoutContext = {
      ...context,
      containerOffsetX: contentOffsetX,
      containerOffsetY: contentOffsetY + contentHeight,
      containerWidth: contentWidth,
      subtreeRootObject,
      // TODO(toby): Set containerHeight when the container has an explicit `height` style set
    }

    const childLayout = child.layout(childContext)
    layoutChildren.push(childLayout)

    const { y: childY, height: childHeight } = childLayout
    const childBottom = childY + childHeight
    contentHeight = Math.max(contentHeight, childBottom - contentOffsetY)
  }

  return { children: layoutChildren, contentHeight }
}

export default layoutFlowContent
