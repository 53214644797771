import { DeviceVisibility, EditorObject } from 'utils/responsiveTypes'
import { DEFAULT_DEVICE_SETTINGS } from 'utils/defaultDeviceSettings'

import getObject from '../objects/helpers/getObject'

import { ObjectList } from '../types/ObjectList'
import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'
import DeviceType from '../types/DeviceType'

export interface ToggleDeviceVisibilityOptions {
  objectId: string
  device: DeviceType
}

export interface ToggleDeviceVisibilityInstruction {
  operation: 'toggleDeviceVisibility'
  options: ToggleDeviceVisibilityOptions
}

export const toggleDeviceVisibilityHandler = (
  state: InstructionState,
  { objectId, device }: ToggleDeviceVisibilityOptions
): InstructionState => {
  const { list: oldList, pathMap } = state
  const oldObject = getObject(oldList, pathMap, objectId)

  const oldShownDevices: DeviceVisibility = {
    ...DEFAULT_DEVICE_SETTINGS,
    ...oldObject.deviceVisibility,
  }
  const newShownDevices: DeviceVisibility = {
    ...oldShownDevices,
    [device]: !oldShownDevices[device],
  }

  const newObject: EditorObject = {
    ...oldObject,
    deviceVisibility: newShownDevices,
  }

  const newList: ObjectList = updateChangedObject(
    oldList,
    pathMap,
    newObject,
    device
  )

  return {
    ...state,
    list: newList,
  }
}

const toggleDeviceVisibility = (
  objectId: string,
  device: DeviceType
): ToggleDeviceVisibilityInstruction => ({
  operation: 'toggleDeviceVisibility',
  options: {
    objectId,
    device,
  },
})

export default toggleDeviceVisibility
