import numeral from 'numeral'

/**
 * Returns an abbreviated, formatted number
 * @param {Number} num Number you wish to format
 * @example
 * abbreviatedFormat(5000)
 * @returns {String} 5k
 */
export const abbreviatedFormat = num => {
  const order = ((3 * Math.log(num)) / Math.log(1000)) % 3
  let format = '0[.][00]a'

  if (order >= 2) {
    format = '0a'
  } else if (order >= 1) {
    format = '0[.][0]a'
  }

  return numeral(num).format(format)
}
