import React, { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getObject, getDeviceType } from '@adalo/utils'
import { withRouter } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import {
  getTransform,
  getBaseZoom,
  scaleValue,
  isVisible,
  DEFAULT_ZOOM,
} from 'utils/zoom'
import { normalizeColor } from 'utils/colors'
import { assetURL } from 'utils/assets'
import { isSupportedOnWeb } from 'utils/platform'
import getDeviceObject from 'utils/getDeviceObject'

import { setCanvasHover, setSelection } from 'ducks/editor/selection'
import { beginDrag } from 'ducks/editor/positioning'
import {
  getSelectedParent,
  getPath,
  getYOffset,
  getPlatform,
} from 'ducks/editor/objects'

import CanvasObjectWrapper from './CanvasObjectWrapper'
import PageShadow from './PageShadow'
import StatusBar from './StatusBar'

const ScreenChildren = memo(
  ({ deviceType, zoom, hideShadows, branding, component, magicLayout }) =>
    component.children.map(object => (
      <CanvasObjectWrapper
        deviceType={deviceType}
        key={object.id + deviceType}
        object={object}
        zoom={zoom}
        hideShadows={hideShadows}
        branding={branding}
        component={component}
        magicLayout={magicLayout}
      />
    ))
)

class Screen extends Component {
  static contextTypes = {
    editable: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = { shouldRenderChildren: false }
  }

  handleMouseLeave = e => {
    const { editable } = this.context
    const { setCanvasHover } = this.props

    if (!editable) {
      return
    }

    setCanvasHover(null)
  }

  handleEnterTitle = () => {
    const { editable } = this.context

    if (!editable) {
      return
    }

    const { component, setCanvasHover } = this.props

    setCanvasHover(component)
  }

  handleClickTitle = e => {
    const { component, setSelection, beginDrag, history, match } = this.props
    const { editable } = this.context

    if (!editable) {
      return
    }

    const {
      params: { appId },
    } = match

    history.push(`/apps/${appId}/screens`)

    setSelection(component.id, e.shiftKey)
    beginDrag([e.clientX, e.clientY])
  }

  getBackgroundPosition = () => {
    const { component } = this.props

    let value = ''
    const backgroundSize = component?.backgroundSize
    const backgroundPositionX = component?.backgroundPositionX
    const backgroundPositionY = component?.backgroundPositionY

    switch (backgroundPositionX) {
      case 'left':
        value = value.concat('xMin')

        break
      case 'right':
        value = value.concat('xMax')

        break
      case 'center':
      default:
        value = value.concat('xMid')

        break
    }

    switch (backgroundPositionY) {
      case 'top':
        value = value.concat('YMin')

        break
      case 'bottom':
        value = value.concat('YMax')

        break
      case 'center':
      default:
        value = value.concat('YMid')

        break
    }

    switch (backgroundSize) {
      case 'contain':
        value = value.concat(' meet')

        break
      case 'cover':
      default:
        value = value.concat(' slice')

        break
    }

    return value
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { zoomActive } = nextProps

    return (
      !zoomActive &&
      (!isEqual(this.props, nextProps) || !isEqual(this.state, nextState))
    )
  }

  render() {
    const {
      component,
      zoom,
      zoomActive,
      isLaunchComponent,
      isAuthComponent,
      branding,
      yOffset,
      platform,
      selectedParent,
      selectedParentScreenId,
      magicLayout,
      deviceType,
    } = this.props

    const { shouldRenderChildren } = this.state
    const isScreenVisible = isVisible(component, zoom)

    const hideShadows = zoom.scale > 8

    const fakeZoom = DEFAULT_ZOOM

    const transform = getTransform(fakeZoom, true, component.x, component.y)
    const baseZoom = getBaseZoom()
    const maskId = `screen-mask-${component.id}`
    const hightlightMaskId = `screen-hightlight-mask-${component.id}`

    const styles = { transform }

    const hightlightBleedSize = 6

    const backgroundColor = normalizeColor(
      component.backgroundColor || '@background',
      branding
    )

    const backgroundImage = component?.backgroundImage
      ? assetURL(component.backgroundImage)
      : null

    const backgroundImagePosition = this.getBackgroundPosition()

    if (!shouldRenderChildren) {
      // rendering children is expensive
      // especially if many screens try to do this at the same time
      // this will make sure children are rendered next time, in a less blocking way
      setTimeout(() => this.setState({ shouldRenderChildren: true }), 0)
    }

    return (
      <div>
        <PageShadow
          zoomScale={zoom.scale}
          zoomActive={zoomActive}
          component={component}
          onEnterTitle={this.handleEnterTitle}
          onLeaveTitle={this.handleMouseLeave}
          onClickTitle={this.handleClickTitle}
          isLaunchComponent={isLaunchComponent}
          isAuthComponent={isAuthComponent}
          deviceType={deviceType}
          yOffset={yOffset}
          transparent={!!selectedParent}
        />
        <svg
          className="canvas-objects"
          width={Math.ceil(component.width * baseZoom.scale)}
          height={Math.ceil(component.height * baseZoom.scale)}
          style={styles}
        >
          <mask id={maskId}>
            <rect
              x={0}
              y={scaleValue(yOffset, baseZoom)}
              width={component.width * baseZoom.scale}
              height={component.height * baseZoom.scale}
              fill="#fff"
            />
          </mask>
          {selectedParent && (
            <mask id={hightlightMaskId}>
              <rect
                x={0}
                y={0}
                width={component.width * baseZoom.scale}
                height={component.height * baseZoom.scale}
                fill="#fff"
              />
              {selectedParentScreenId === component.id && (
                <rect
                  x={selectedParent.x - hightlightBleedSize}
                  y={selectedParent.y - hightlightBleedSize}
                  width={selectedParent.width + hightlightBleedSize * 2}
                  height={selectedParent.height + hightlightBleedSize * 2}
                  fill="#000"
                />
              )}
            </mask>
          )}
          <g onMouseLeave={this.handleMouseLeave} mask={`url(#${maskId})`}>
            <rect
              x={0}
              y={0}
              fill={backgroundColor}
              width={component.width * baseZoom.scale}
              height={component.height * baseZoom.scale}
              pointerEvents="none"
            />
            {backgroundImage && (
              <image
                href={backgroundImage}
                x={0}
                y={0}
                width={component.width * baseZoom.scale}
                height={component.height * baseZoom.scale}
                preserveAspectRatio={backgroundImagePosition}
                pointerEvents="none"
              />
            )}
            {isScreenVisible && shouldRenderChildren && (
              <ScreenChildren
                deviceType={deviceType}
                zoom={baseZoom}
                hideShadows={hideShadows}
                branding={branding}
                component={component}
                magicLayout={magicLayout}
              />
            )}
            {selectedParent && (
              <rect
                mask={`url(#${hightlightMaskId})`}
                pointerEvents="none"
                x={0}
                y={0}
                width={component.width * baseZoom.scale}
                height={component.height * baseZoom.scale}
                fill="#fff"
                fillOpacity={0.9}
              />
            )}
          </g>
          {component.reusableComponent || isSupportedOnWeb(platform) ? null : (
            <StatusBar
              component={component}
              zoom={baseZoom}
              opacity={selectedParent ? 0.1 : 1}
            />
          )}
        </svg>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedParent = getSelectedParent(state)
  let selectedParentScreenId

  if (selectedParent) {
    const path = getPath(state, selectedParent.id)
    const { list } = state.editor.objects.present
    const selectedParentScreen = getObject(list, path.split('.')[0])
    selectedParentScreenId = selectedParentScreen && selectedParentScreen.id
  }

  const { component, magicLayout } = ownProps
  const deviceType = magicLayout && getDeviceType(component.width)

  return {
    selectedParentScreenId,
    selectedParent,
    yOffset: getYOffset(state),
    platform: getPlatform(state),
    deviceType,
    component: getDeviceObject(component, deviceType),
  }
}

export default connect(mapStateToProps, {
  setCanvasHover,
  setSelection,
  beginDrag,
})(withRouter(Screen))
