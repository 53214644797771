import React, { useState, useCallback } from 'react'
import { reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux'

import { updateApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users/index.ts'

import Modal from 'components/Shared/Modal'

import AppleCredentialsForm from './AppleCredentialsForm'
import AppSettingsForm from './AppSettingsForm'

import { FORM_NAME } from './shared'

import './SettingsModal.scss'

const SettingsModal = props => {
  const {
    appId,
    onClose,
    onSubmit,
    initialValues,
    iosSettings,
    updateApp,
    formValues,
    publishing,
    isAdmin,
  } = props
  const steps = ['Apple Credentials', 'App Settings']
  const totalSteps = steps.length - 1
  const [step, setStep] = useState(0)

  const handleSubmit = useCallback(
    values => {
      const skipBuildEnqueue = values?.skipBuildEnqueue
      delete values?.skipBuildEnqueue
      updateApp(appId, { iosSettings: { ...iosSettings, ...values } })

      return onSubmit(skipBuildEnqueue)
    },
    [updateApp, onSubmit]
  )

  const handleNextStep = e => {
    if (e?.preventDefault) e.preventDefault()

    if (step === totalSteps) return handleSubmit(formValues)

    return setStep(step + 1)
  }

  const handlePrevStep = () => (step === 0 ? onClose() : setStep(step - 1))

  const renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <AppleCredentialsForm
            onSubmit={handleNextStep}
            initialValues={initialValues}
            publishing={publishing}
          />
        )
      case 1:
        return (
          <AppSettingsForm
            appId={appId}
            onSubmit={handleNextStep}
            initialValues={initialValues}
            publishing={publishing}
            isAdmin={isAdmin}
          />
        )
    }
  }

  const nextButtonText =
    step !== totalSteps
      ? 'Next'
      : publishing
      ? 'Save and Continue'
      : 'Save iOS Settings'

  return (
    <>
      <Modal scrolling>
        <div>
          <Modal.Header
            stepper
            steps={steps}
            currentStep={step}
            color="green"
          />
          <Modal.Content>{renderSteps()}</Modal.Content>
          <Modal.Actions>
            <Modal.Button type="button" text gray onClick={handlePrevStep}>
              Back
            </Modal.Button>
            <Modal.Button type="submit" form={FORM_NAME} green>
              {nextButtonText}
            </Modal.Button>
          </Modal.Actions>
        </div>
      </Modal>
    </>
  )
}

SettingsModal.propTypes = {}

const mapStateToProps = state => {
  const currentUser = getCurrentUser(state)

  return {
    formValues: getFormValues(FORM_NAME)(state),
    isAdmin: !!(currentUser && currentUser.admin),
  }
}

const connected = connect(mapStateToProps, { updateApp })(SettingsModal)

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connected)
