import {
  COMPONENT,
  DeviceType as DeviceTypeKeys,
  FORM,
  LABEL,
  LAYOUT_SECTION,
  LIBRARY_COMPONENT,
  LIST,
  positioning as positioningValues,
  resizingOptions,
  responsivePositioningOptions,
} from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import {
  EditorObject,
  PushGraphEdge as AppBodyPushGraphEdge,
} from 'utils/responsiveTypes'
import { getValueParagraphs } from 'utils/text'
import getDeviceObject from 'utils/getDeviceObject'
import usesSharedLayout from 'utils/objects/usesSharedLayout'
import { CONTAINER_TYPES } from 'utils/positioning'
import hiddenOnDevice from 'utils/objects/hiddenOnDevice'
import { isSectionElement } from 'utils/layoutSections'
import Document from '../model/Document'
import Length from '../model/Length'
import ObjectNode, { NON_ZERO_WIDTH } from '../model/ObjectNode'
import ScreenNode from '../model/ScreenNode'
import ListItemNode from '../model/ListItemNode'
import ObjectType from '../types/ObjectType'
import { ObjectList } from '../types/ObjectList'
import { ObjectPathMap } from '../types/ObjectPathMap'
import DeviceType from '../types/DeviceType'
import LabelNode from '../model/LabelNode'
import FormNode from '../model/FormNode'
import getObject from '../objects/helpers/getObject'
import isInFlow from '../objects/helpers/isInFlow'
import {
  Display,
  FlexLength,
  Position,
  PushGraphEdge,
  TrackSizeFunction,
} from '../model/styles'
import getLibraryComponentInstanceSize from './getLibraryComponentInstanceSize'
import LibraryComponentNode from '../model/LibraryComponentNode'
import getCustomListProperties from '../objects/helpers/getCustomListProperties'

const { LEFT, RIGHT, CENTER, LEFT_AND_RIGHT, TOP, FIXED_ON_SCROLL } =
  responsivePositioningOptions
const { SCALES_WITH_PARENT, FIXED } = resizingOptions
const {
  FIXED_TOP: POSITION_FIXED_TOP,
  FIXED: POSITION_FIXED_MIDDLE,
  FIXED_BOTTOM: POSITION_FIXED_BOTTOM,
} = positioningValues

type ObjectIndex = Map<string, EditorObject>

interface RenderContext {
  containerWidth: number
  containerOffsetX: number
  containerOffsetY: number

  viewportWidth: number
  viewportHeight: number
}

interface HorizontalStyles {
  width: Length | undefined
  left: Length | undefined
  right: Length | undefined
  marginLeft: Length
  marginRight: Length
  minWidth: Length | undefined
  maxWidth: Length | undefined
}

const listItemId = (listId: string): string => `${listId}:item`

const buildNode = (object: EditorObject): ObjectNode<ObjectType> => {
  const { id, type, children } = object

  let result: ObjectNode<ObjectType>
  if (type === COMPONENT) {
    result = new ScreenNode(id)
  } else if (type === LABEL) {
    result = new LabelNode(id)
  } else if (type === LIBRARY_COMPONENT) {
    const { libraryName, componentName, attributes } = object

    if (libraryName === undefined || componentName === undefined) {
      throw new Error(
        `Found LIBRARY_COMPONENT with missing library data (libraryName: ${String(
          libraryName
        )}, componentName: ${String(componentName)})`
      )
    }

    const getElementSize = getLibraryComponentInstanceSize(
      libraryName,
      componentName,
      attributes
    )

    result = new LibraryComponentNode(id, libraryName, getElementSize)
  } else if (type === FORM && object?.fields) {
    result = new FormNode(id, object)
  } else {
    result = new ObjectNode(id, type)
  }

  let contentContainer: ObjectNode<ObjectType>
  if (type === LIST) {
    // Lists require two nodes.
    // The parent is the list itself which calculates its bounds from the parent context.
    // The child represents a single list item and acts as the container for content within the list.
    const listItem = new ListItemNode(listItemId(id))
    result.addChild(listItem)

    contentContainer = listItem
  } else {
    contentContainer = result
  }

  if (children !== undefined) {
    for (const child of children) {
      const childNode = buildNode(child)
      contentContainer.addChild(childNode)
    }
  }

  return result
}

const buildObjectIndex = (object: EditorObject, map: ObjectIndex): void => {
  const { id, type } = object

  map.set(id, object)

  if (type === LIST) {
    map.set(listItemId(id), object)
  }

  const { children } = object
  if (children !== undefined) {
    for (const child of children) {
      buildObjectIndex(child, map)
    }
  }
}

const getInFlowChildren = (
  container: EditorObject,
  device: DeviceType | undefined
): EditorObject[] => {
  const { children = [] } = container

  return children.filter(child => isInFlow(child, device))
}

// Documentation:
// src/ducks/editor/instructions/CONCEPTS.md
// Refer to section "Margins"
const calculateHorizontalLayout = (
  context: RenderContext,
  object: EditorObject
): HorizontalStyles => {
  const { containerOffsetX } = context
  let { containerWidth } = context
  const {
    left,
    fixedLeft,
    right,
    fixedRight,
    minWidth,
    minWidthEnabled,
    maxWidth,
    maxWidthEnabled,
    responsivity = {},
  } = object
  const {
    horizontalScaling: scaling = FIXED,
    horizontalPositioning: positioning = scaling === FIXED ? LEFT : CENTER,
  } = responsivity

  containerWidth = containerWidth === 0 ? NON_ZERO_WIDTH : containerWidth

  const leftOffset = object.x - containerOffsetX
  const width = object.width
  // Take the parent container's width and subtract both the left offset and the object's width. Whatever remains must
  // be the right offset.
  const rightOffset = containerWidth - (leftOffset + width)

  if (scaling === FIXED && positioning === LEFT) {
    return {
      width: Length.fromPixels(width),
      left: Length.fromPixels(fixedLeft ?? leftOffset),
      right: undefined,
      marginLeft: Length.ZERO,
      marginRight: Length.ZERO,
      minWidth: undefined,
      maxWidth: undefined,
    }
  } else if (scaling === FIXED && positioning === CENTER) {
    // Set a negative left margin to position the object's anchor point at it's center.
    const marginLeft = -width / 2

    const leftRatio =
      containerWidth === 0 ? 0 : (leftOffset - marginLeft) / containerWidth

    return {
      width: Length.fromPixels(width),
      left: Length.fromPercent(fixedLeft ?? leftRatio),
      right: undefined,
      marginLeft: Length.fromPixels(marginLeft),
      marginRight: Length.ZERO,
      minWidth: undefined,
      maxWidth: undefined,
    }
  } else if (scaling === FIXED && positioning === RIGHT) {
    return {
      width: Length.fromPixels(width),
      right: Length.fromPixels(fixedRight ?? rightOffset),
      left: undefined,
      marginLeft: Length.ZERO,
      marginRight: Length.ZERO,
      minWidth: undefined,
      maxWidth: undefined,
    }
  } else if (scaling === SCALES_WITH_PARENT && positioning === CENTER) {
    // Left and right offsets should be relative to parent. Width will be left undefined so that it is calculated during layout.
    const leftRatio = containerWidth === 0 ? 0 : leftOffset / containerWidth
    const rightRatio = containerWidth === 0 ? 0 : rightOffset / containerWidth

    return {
      left: Length.fromPercent(left ?? leftRatio),
      right: Length.fromPercent(right ?? rightRatio),
      width: undefined,
      marginLeft: Length.ZERO,
      marginRight: Length.ZERO,
      minWidth:
        minWidth === undefined || minWidthEnabled === false
          ? undefined
          : Length.fromPixels(minWidth),
      maxWidth:
        maxWidth === undefined || maxWidthEnabled === false
          ? undefined
          : Length.fromPixels(maxWidth),
    }
  } else if (scaling === SCALES_WITH_PARENT && positioning === LEFT_AND_RIGHT) {
    // Left and right offsets should be fixed values. Width will be undefined so that it is calculated during layout.
    return {
      left: Length.fromPixels(left ?? leftOffset),
      right: Length.fromPixels(right ?? rightOffset),
      width: undefined,
      marginLeft: Length.ZERO,
      marginRight: Length.ZERO,
      minWidth:
        minWidth === undefined || minWidthEnabled === false
          ? undefined
          : Length.fromPixels(minWidth),
      maxWidth:
        maxWidth === undefined || maxWidthEnabled === false
          ? undefined
          : Length.fromPixels(maxWidth),
    }
  } else {
    throw new Error(
      `Object has unsupported horizontal layout combination. horizontalScaling: ${scaling}; horizontalPositioning: ${positioning}`
    )
  }
}

const setFixedElementStyles = (
  context: RenderContext,
  node: ObjectNode<ObjectType>,
  object: EditorObject,
  device: DeviceType | undefined
): void => {
  const { viewportHeight } = context
  const {
    y,
    height,
    positioning = POSITION_FIXED_MIDDLE,
  } = getDeviceObject(object, device)

  // There are three possible attachments: Fixed distance from top, fixed distance from bottom, or relative distance from top.
  // The value saved in `positioning` is treated as the source of truth here to avoid the attachment changing during
  // screen resizes.
  if (positioning === POSITION_FIXED_TOP) {
    // Set a fixed distance from top of viewport
    node.addStyleRule('top', Length.fromPixels(y), device)
  } else if (positioning === POSITION_FIXED_BOTTOM) {
    // Set a fixed distance from bottom of viewport
    let bottom = viewportHeight - (y + height)

    if (node?.type === LABEL) {
      bottom = viewportHeight - y
    }

    node.addStyleRule('bottom', Length.fromPixels(bottom), device)
  } else {
    // Keep a relative distance from the top of the viewport

    // Determine the vertical midpoint of the current object, relative to the viewport.
    const midpoint = height / 2
    const midpointYOffsetExact = y + midpoint
    const midpointYOffsetRatio = midpointYOffsetExact / viewportHeight

    // This could be -50% however that would not match the current behaviour at runtime. Using the exact value keeps the
    // vertical position consistent across screen resizes.
    node.addStyleRule('marginTop', Length.fromPixels(-midpoint), device)

    node.addStyleRule('top', Length.fromPercent(midpointYOffsetRatio), device)
  }

  node.addStyleRule('position', Position.Fixed, device)
}

const setLabelStyles = (
  node: LabelNode,
  object: EditorObject,
  device: DeviceType | undefined
): void => {
  const {
    text,
    fontSize,
    fontStyle,
    calculatedFontFamily,
    fontWeight,
    multiline,
    autoWidth,
    maxLength,
  } = object

  if (text !== undefined) {
    const textWithData = getValueParagraphs(text) as string[]
    const textWithNewLines = textWithData.join('\n')

    node.addStyleRule('text', textWithNewLines, device)
  }
  if (fontSize !== undefined) {
    node.addStyleRule('fontSize', fontSize, device)
  }
  if (fontStyle !== undefined) {
    node.addStyleRule('fontStyle', fontStyle, device)
  }
  if (calculatedFontFamily !== undefined) {
    node.addStyleRule('fontFamily', calculatedFontFamily, device)
  }
  if (fontWeight !== undefined) {
    node.addStyleRule('fontWeight', fontWeight, device)
  }
  if (multiline !== undefined) {
    node.addStyleRule('multiline', multiline, device)
  }
  if (maxLength !== undefined) {
    node.addStyleRule('maxLength', maxLength, device)
  }
  if (autoWidth !== undefined) {
    node.addStyleRule('autoWidth', autoWidth, device)
  }

  // Reset minHeight for labels
  node.addStyleRule('minHeight', Length.ZERO, device)
}

const calculateContentBounds = (
  parentOffsetY: number,
  children: EditorObject[],
  device: DeviceType | undefined
): { contentTop: number; contentBottom: number } => {
  const [first, ...rest] = children.map(child => getDeviceObject(child, device))
  if (first === undefined) {
    // Empty list
    return { contentTop: parentOffsetY, contentBottom: parentOffsetY }
  }

  let top = first.y
  let bottom = first.y + first.height
  for (const child of rest) {
    top = Math.min(top, child.y)
    bottom = Math.max(bottom, child.y + child.height)
  }

  return { contentTop: top, contentBottom: bottom }
}

const mapPushGraphEdge = (appBodyEdge: AppBodyPushGraphEdge): PushGraphEdge => {
  const { startNodeId, endNodeId, distance } = appBodyEdge

  return {
    startNodeId,
    endNodeId,
    distance: Length.fromPixels(distance),
  }
}

const setListStyles = (
  context: RenderContext,
  node: ObjectNode<ObjectType>,
  index: ObjectIndex,
  device: DeviceType | undefined
): void => {
  const { id } = node
  const baseObject = index.get(id)
  if (baseObject === undefined) {
    throw new Error(`List object missing from index: ${id}`)
  }

  /*
   * Container styles need to be set when any of the following are true.
   * 1. The base styles are always set when device === undefined.
   * 2. Any child uses a device-specific layout.
   * 3. Any child is hidden on the device.
   * 4. The container uses a device-specific layout.
   */
  const setDeviceStyles =
    device === undefined || !usesSharedLayout(baseObject, device)
  const hasDeviceSpecificChildren = (baseObject.children || []).some(
    child => !usesSharedLayout(child, device) || hiddenOnDevice(child, device)
  )

  if (!setDeviceStyles && !hasDeviceSpecificChildren) {
    return
  }

  const deviceObject = getDeviceObject(baseObject, device)
  const { y, height } = deviceObject
  const { columnCount, rowMargin } = getCustomListProperties(baseObject, device)

  const children = getInFlowChildren(baseObject, device)
  const { contentBottom } = calculateContentBounds(y, children, device)

  // The height of the item content spans from the top edge of the list to the bottom edge of the lowest child element.
  const contentHeight = contentBottom - y

  // Determine the number of rows which are displayed in the editor.
  const numRows = Math.ceil(height / (contentHeight + rowMargin))
  // It's possible that the last row extends some distance beyond the bottom of the list. Treat this as negative
  // padding so that the overhang is preserved across screen sizes.
  const numGutters = Math.max(numRows - 1, 0)
  const paddingBottom =
    height - (numRows * contentHeight + numGutters * rowMargin)

  const gridColumns: TrackSizeFunction[] = []
  for (let i = 0; i < columnCount; i += 1) {
    gridColumns.push(new FlexLength(1))
  }

  const gridRows: TrackSizeFunction[] = []
  for (let i = 0; i < numRows; i += 1) {
    gridRows.push(new FlexLength(1))
  }

  node.addStyleRule('display', Display.Grid, device)
  node.addStyleRule('gridTemplateColumns', { trackSizes: gridColumns }, device)
  node.addStyleRule('gridTemplateRows', { trackSizes: gridRows }, device)
  node.addStyleRule('columnGap', Length.fromPixels(rowMargin), device)
  node.addStyleRule('rowGap', Length.fromPixels(rowMargin), device)

  node.addStyleRule('paddingBottom', Length.fromPixels(paddingBottom), device)

  // List's height gets calculated from the content, shouldn't have a minHeight set
  node.addStyleRule('minHeight', Length.ZERO, device)
}

const setContainerStyles = (
  context: RenderContext,
  node: ObjectNode<ObjectType>,
  index: ObjectIndex,
  device: DeviceType | undefined
): void => {
  const { id } = node
  const baseObject = index.get(id)
  if (baseObject === undefined) {
    throw new Error(`Container object missing from index: ${id}`)
  }

  /*
   * Container styles need to be set when any of the following are true.
   * 1. The base styles are always set when device === undefined.
   * 2. Any child uses a device-specific layout.
   * 3. Any child is hidden on the device.
   * 4. The container uses a device-specific layout.
   */
  const setDeviceStyles =
    device === undefined ||
    !usesSharedLayout(baseObject, device) ||
    (isSectionElement(baseObject) && baseObject[device])
  const hasDeviceSpecificChildren = (baseObject.children || []).some(
    child =>
      !usesSharedLayout(child, device) ||
      hiddenOnDevice(child, device) ||
      (isSectionElement(child) && device && child[device])
  )

  // sanity-check(device-parent):
  // if the `device` is undefined and the parent has all custom layout
  // we cannot use the parent's shared layout to calculate the delta
  const deviceObject = getDeviceObject(baseObject, device)
  const { type, height, pushGraph } = deviceObject
  let { width } = deviceObject

  // Slice width when container is a list with multiple columns
  if (type === LIST) {
    const { columnCount, rowMargin } = getCustomListProperties(
      baseObject,
      device
    )

    const numGutters = Math.max(columnCount - 1, 0)
    const totalGutterWidth = numGutters * rowMargin

    let itemWidth = (width - totalGutterWidth) / columnCount

    // Force to non-zero width to mirror the list item width during layout.
    if (itemWidth === 0) {
      itemWidth = NON_ZERO_WIDTH
    }

    width = itemWidth
  }

  let x = 0
  let y = 0
  if (type !== COMPONENT) {
    ;({ x, y } = deviceObject)
  }

  const children = getInFlowChildren(baseObject, device)
  const { contentTop, contentBottom } = calculateContentBounds(
    y,
    children,
    device
  )

  const paddingTop = contentTop - y
  const paddingBottom =
    baseObject.type === LAYOUT_SECTION ? paddingTop : y + height - contentBottom

  if (setDeviceStyles || hasDeviceSpecificChildren) {
    if (pushGraph === undefined) {
      // Every container should have a push graph. If not, there's a bug somewhere and we should aggressively raise flags.
      throw new Error(
        `Could not find a push graph for container. (Object ID: ${id})`
      )
    }

    const { nodeIds, edges } = pushGraph
    const styleEdges = edges.map(mapPushGraphEdge)

    node.addStyleRule('display', Display.PushGraph, device)
    node.addStyleRule('pushGraphNodes', nodeIds, device)
    node.addStyleRule('pushGraphEdges', styleEdges, device)

    node.addStyleRule('paddingTop', Length.fromPixels(paddingTop), device)
    node.addStyleRule('paddingBottom', Length.fromPixels(paddingBottom), device)

    // Container height gets calculated from the content, shouldn't have a minHeight set
    node.addStyleRule('minHeight', Length.ZERO, device)
  }

  const contentWidth = width
  const contentOffsetX = x
  const contentOffsetY = contentTop

  const contentContext: RenderContext = {
    ...context,
    containerWidth: contentWidth,
    containerOffsetX: contentOffsetX,
    containerOffsetY: contentOffsetY,
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  setChildStyles(contentContext, node, index, device)
}

const setElementStyles = (
  context: RenderContext,
  node: ObjectNode<ObjectType>,
  index: ObjectIndex,
  device: DeviceType | undefined
): void => {
  const { id } = node
  const baseObject = index.get(id)
  if (baseObject === undefined) {
    throw new Error(`Object missing from index: ${id}`)
  }

  /*
   * Styles are set in these cases:
   * 1. The base styles are always set for device === undefined
   * 2. This object has a specific layout for the current device.
   * 3. The object is hidden on the current device. (sets `display: none`)
   */
  // Never check the shared setting on deviceObject (returned from getDeviceObject()) because our app data is very messy
  // and the device props may have overwritten the 'shared' property with outdated data.
  const setDeviceStyles =
    device === undefined || !usesSharedLayout(baseObject, device)

  // sanity-check(device-parent):
  // if the device is `undefined` and the parent has all custom layout
  // we cannot use the parent's shared layout to calculate the delta
  const baseObjectUsesFullCustomLayout =
    baseObject.shared?.desktop === false &&
    baseObject.shared?.tablet === false &&
    baseObject.shared?.mobile === false
  const overrideDevice =
    device === undefined && baseObjectUsesFullCustomLayout
      ? getDeviceType(context.viewportWidth)
      : device
  const deviceObject = getDeviceObject(baseObject, overrideDevice)

  // Per-device fixed positioning is not currently supported. Read the vertical responsivity from the base object.
  const { responsivity: { verticalPositioning = TOP } = {} } = baseObject
  const fixedVertically = verticalPositioning === FIXED_ON_SCROLL

  if (setDeviceStyles) {
    let relativeContext = context
    if (fixedVertically) {
      // Fixed elements need to be mapped relative to the viewport.
      relativeContext = {
        ...context,
        containerOffsetX: 0,
        containerOffsetY: 0,
        containerWidth: context.viewportWidth,
      }

      setFixedElementStyles(context, node, baseObject, device)
    } else {
      node.addStyleRule('pushNodeId', id, device)
    }

    const { left, right, width, marginLeft, marginRight, minWidth, maxWidth } =
      calculateHorizontalLayout(relativeContext, deviceObject)

    node.addStyleRule('left', left, device)
    node.addStyleRule('right', right, device)
    node.addStyleRule('width', width, device)
    node.addStyleRule('marginLeft', marginLeft, device)
    node.addStyleRule('marginRight', marginRight, device)
    node.addStyleRule('minWidth', minWidth, device)
    node.addStyleRule('maxWidth', maxWidth, device)

    if (
      deviceObject.responsivity?.horizontalPositioning === LEFT_AND_RIGHT ||
      (deviceObject.responsivity?.horizontalPositioning === CENTER &&
        deviceObject.responsivity?.horizontalScaling === SCALES_WITH_PARENT)
    ) {
      const {
        left: leftUnused,
        right: rightUnused,
        ...deviceObjectWithoutLeftRight
      } = deviceObject

      node.addStyleRule(
        'fixedCenterHorizontalStyles',
        calculateHorizontalLayout(relativeContext, {
          ...deviceObjectWithoutLeftRight,
          responsivity: {
            ...deviceObject.responsivity,
            horizontalPositioning: CENTER,
            horizontalScaling: FIXED,
          },
        }),
        device
      )

      node.addStyleRule(
        'scalesCenterHorizontalStyles',
        calculateHorizontalLayout(relativeContext, {
          ...deviceObjectWithoutLeftRight,
          responsivity: {
            ...deviceObject.responsivity,
            horizontalPositioning: CENTER,
            horizontalScaling: SCALES_WITH_PARENT,
          },
        }),
        device
      )
    }

    const { height } = deviceObject

    // minHeight is set from height on all Elements
    // containers that calculate their height from their content's height then get the minHeight reset to 0
    const minHeight = Length.fromPixels(height)
    node.addStyleRule('minHeight', minHeight, device)

    if (node instanceof LabelNode) {
      setLabelStyles(node, deviceObject, device)
    }
  }

  const { type } = baseObject
  const isContainer = CONTAINER_TYPES.includes(type) || type === LAYOUT_SECTION

  // Set container styles
  if (type === LIST) {
    // Set List styles
    setListStyles(context, node, index, device)

    // Set container styles on child list item
    const [listItem] = node.getChildren()
    if (listItem === undefined) {
      throw new Error(`List Item node not found (Object ID: ${id})`)
    }

    setContainerStyles(context, listItem, index, device)

    listItem.addStyleRule('gridColumnStart', 0, device)
    listItem.addStyleRule('gridRowStart', 0, device)

    // List Items always inherit their height directly from the content. There's no concept of bottom padding on a list item.
    listItem.addStyleRule('paddingBottom', Length.ZERO, device)

    // The list item should span its cell in the grid.
    listItem.addStyleRule('top', Length.ZERO, device)
    listItem.addStyleRule('left', Length.ZERO, device)
    listItem.addStyleRule('width', Length.parse('100%'), device)
  } else if (isContainer) {
    setContainerStyles(context, node, index, device)
  }

  // Set Display None on hidden components
  if (hiddenOnDevice(baseObject, device)) {
    node.addStyleRule('display', Display.None, device)
  }
}

const setChildStyles = (
  context: RenderContext,
  parentNode: ObjectNode<ObjectType>,
  index: ObjectIndex,
  device: DeviceType | undefined
): void => {
  const { containerOffsetY } = context
  let contentOffsetY = containerOffsetY

  for (const child of parentNode.getChildren()) {
    let childObj = index.get(child.id)
    if (childObj === undefined) {
      throw new Error(`Could not find child object: ${child.id}`)
    }

    const childContext: RenderContext = {
      ...context,
      containerOffsetY: contentOffsetY,
    }

    setElementStyles(childContext, child, index, device)

    if (device !== undefined && device in childObj) {
      childObj = {
        ...childObj,
        ...childObj[device],
      }
    }

    // Update the content area for the next child to be the bottom of the current child (unless the current child's
    // bottom is above the previous offset)

    const childBottom = childObj.y + childObj.height
    contentOffsetY = Math.max(contentOffsetY, childBottom)
  }
}

const buildScreenNode = (object: EditorObject): ScreenNode => {
  const { id, type, children } = object
  if (type !== COMPONENT) {
    throw new Error(`Object is not a screen: ${id}`)
  }

  const screenNode = new ScreenNode(id)
  if (children !== undefined) {
    for (const child of children) {
      const childNode = buildNode(child)
      screenNode.addChild(childNode)
    }
  }

  const index: ObjectIndex = new Map()
  buildObjectIndex(object, index)

  const { width: screenWidth, height: screenHeight } = object
  const context: RenderContext = {
    containerWidth: screenWidth,
    containerOffsetX: 0,
    containerOffsetY: 0,

    viewportWidth: screenWidth,
    viewportHeight: screenHeight,
  }

  setContainerStyles(context, screenNode, index, undefined)
  setContainerStyles(context, screenNode, index, DeviceTypeKeys.MOBILE)
  setContainerStyles(context, screenNode, index, DeviceTypeKeys.TABLET)
  setContainerStyles(context, screenNode, index, DeviceTypeKeys.DESKTOP)

  return screenNode
}

const mapScreenToDocument = (
  objects: ObjectList,
  pathMap: ObjectPathMap,
  screenId: string
): Document => {
  const screenObj = getObject(objects, pathMap, screenId)

  const screen = buildScreenNode(screenObj)

  const result = new Document(screen)

  return result
}

export default mapScreenToDocument
