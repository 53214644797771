import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import QS from 'qs'
import { DeviceBreakpoint, DeviceWidth } from '@adalo/constants'
import { cssTransition, toast } from 'react-toastify'

import {
  getApp,
  updateApp,
  requestApp,
  deleteApp,
  getOrgApps,
  getScreens,
} from 'ducks/apps'
import { openAccordion } from 'ducks/accordions'
import { getCurrentOrganizationId } from 'ducks/organizations'
import { fetchApiKey } from 'ducks/apikeys'
import {
  getThirdPartyApiKeys,
  deleteThirdPartyApiKey,
  createThirdPartyApiKey,
  updateThirdPartyApiKey,
  updateManyThirdPartyApiKeys,
  deleteManyThirdPartyApiKeys,
} from 'ducks/thirdPartyApiKeys'
import { createCustomAction, getCustomActions } from 'ducks/customActions'
import { getFeatureFlag } from 'ducks/featureFlags'
import { resizeObject } from 'ducks/editor/objects'
import { requestScreenTemplates } from 'utils/io'

import { getNewAppWebSettings } from 'utils/webSettings'

import Page from 'components/Shared/Page'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import Item from 'components/Shared/Panel/Item'
import { findTemplate } from 'components/Editor/Actions/Form/template'

import AppUsers from './AppUsers'
import AppSettingsForm from './AppSettingsForm'
import AppLayoutTypeSettings from './AppLayoutTypeSettings'
import DisplaySettings from './DisplaySettings'
import Cloneable from './AppUsers/Cloneable'
import ApiKey from './AppUsers/ApiKey'
import ThirdPartyApiKeys from './APIKeys/ThirdPartyApiKeys'

import './Settings.scss'

const GROUP = 'settings'

class Settings extends Component {
  state = {
    loading: false,
  }

  handleDelete = async () => {
    const { match, history, app, deleteApp, currentOrgId, orgAppIds } =
      this.props

    const { appId } = match.params

    if (!app) {
      return
    }

    const message = `
      Are you sure you want to delete ${app.name}?
      
      Please type the app name to confirm:
    `
    const prompt = window.prompt(message)
    const accepted = prompt && prompt.toLowerCase() === app.name.toLowerCase()

    if (!accepted) {
      return
    }

    this.setState({ loading: true })

    const [lastAppId] = orgAppIds.filter(id => id !== appId)

    await deleteApp(appId)

    let url = '/'

    if (currentOrgId && lastAppId) {
      if (lastAppId) {
        url = `/apps/${lastAppId}`
      } else {
        url = `/first-app-setup`
      }
    }

    this.setState({ loading: false })

    history.push(url)
  }

  handleAppSettingsSubmit = data => {
    const { match, updateApp } = this.props
    const { appId } = match.params

    if (data.showBranding !== undefined) {
      data.webSettings = {
        ...(data.webSettings ?? {}),
        showAdaloAttribution: data.showBranding,
      }
    }

    updateApp(appId, data)
  }

  handleAppLayoutSubmit = layoutMode => {
    const { match, app, updateApp, resizeObject, screens } = this.props
    const { appId } = match.params
    const { webSettings } = app

    if (layoutMode === 'mobile') {
      const wideScreens = screens.filter(
        screen => screen.width > DeviceBreakpoint.MOBILE_BREAKPOINT
      )

      for (const screen of wideScreens) {
        resizeObject(screen.id, { width: DeviceWidth.MOBILE_DEFAULT_WIDTH })
      }
    }

    updateApp(appId, { webSettings: { ...webSettings, layoutMode } })
    requestScreenTemplates('responsive')

    const toastHeaderText =
      layoutMode === 'mobile'
        ? 'This App Now Works on Mobile Devices Only'
        : 'This App Now Works Across All Devices'

    toast(
      <>
        <div>
          <h2>{toastHeaderText} </h2>
          <p>See our help doc to learn about designing on all screen sizes</p>
        </div>
        <Button
          yellow
          outlined
          target="_blank"
          to="https://help.adalo.com/design/designing-your-app"
        >
          LEARN MORE
        </Button>
      </>,
      {
        position: 'bottom-right',
        className: 'settings-cta__toast',
        bodyClassName: 'settings-cta__toast-body',
        hideProgressBar: true,
        autoClose: 7500,
        transition: cssTransition({
          enter: 'animate__animated animate__fadeInUp',
          exit: 'animate__animated animate__fadeOutDown',
          collapse: false,
        }),
      }
    )
  }

  confirmOpenAIApiChange = (action = 'change') =>
    window.confirm(
      `Are you sure you want to ${action} the OpenAI API key? This will replace the key used in all apps across your organization.`
    )

  handleAPIKeysSubmit = async data => {
    const {
      template,
      match,
      deleteThirdPartyApiKey,
      createThirdPartyApiKey,
      updateThirdPartyApiKey,
      updateManyThirdPartyApiKeys,
      thirdPartyApiKeys,
      orgAppIds,
    } = this.props

    const { appId } = match.params

    for (const type of Object.keys(data)) {
      if (type === 'openai') {
        if (!this.confirmOpenAIApiChange()) {
          break
        }
      }

      const { key, id: keyId } = data[type]

      if (key) {
        if (thirdPartyApiKeys.find(({ id }) => id === keyId)) {
          try {
            await updateThirdPartyApiKey(appId, keyId, key)

            if (type === 'openai' && template) {
              // If the user has multiple apps, update the API key for the other apps
              if (orgAppIds.length) {
                await updateManyThirdPartyApiKeys(appId, key, orgAppIds)

                // Update the template custom action API key
                const newTemplate = { id: template.id, ...template.body }
                newTemplate.auth[0].value = `Bearer ${key}`
                await createCustomAction(appId, newTemplate)
              }
            }
          } catch ({ error }) {
            throw new SubmissionError({ [type]: { key: error } })
          }
        } else {
          try {
            await createThirdPartyApiKey(appId, { type, key })

            // If the user has multiple apps, update the API key for the other apps
            if (type === 'openai' && orgAppIds.length) {
              const promises = []

              for (const orgAppId of orgAppIds) {
                promises.push(
                  await createThirdPartyApiKey(orgAppId, { type, key })
                )
              }

              await Promise.all(promises)
            }
          } catch ({ error }) {
            throw new SubmissionError({ [type]: { key: error } })
          }
        }
      } else {
        try {
          await deleteThirdPartyApiKey(appId, keyId)
        } catch ({ error }) {
          throw new SubmissionError({ [type]: { key: error } })
        }
      }
    }
  }

  handleAPIKeyDelete = async type => {
    const {
      match,
      deleteThirdPartyApiKey,
      deleteManyThirdPartyApiKeys,
      orgAppIds,
    } = this.props
    const { appId } = match.params

    if (type === 'openai') {
      if (!this.confirmOpenAIApiChange('delete')) {
        return
      }
    }

    const keyToDelete = this.getInitialAPIKeysData()[type]

    if (keyToDelete) {
      try {
        await deleteThirdPartyApiKey(appId, keyToDelete.id)

        // If the user has multiple apps, update the API key for the other apps
        if (type === 'openai' && orgAppIds.length) {
          await deleteManyThirdPartyApiKeys(appId, orgAppIds)
        }
      } catch ({ error }) {
        throw new SubmissionError({ [type]: { key: error } })
      }
    }
  }

  getInitialAppSettingsData = () => {
    const { app } = this.props
    const {
      name,
      icon,
      mixpanelToken,
      OrganizationId,
      description,
      primaryPlatform,
      magicLayout,
    } = app
    let { showBranding, webSettings } = app

    if (!webSettings) {
      webSettings = getNewAppWebSettings({ primaryPlatform })
    }

    // appBody.showBranding is being replaced with appBody.webSettings.showAdaloAttribution
    // 'showBranding' is used on mobile apps
    // 'webSettings.showAdaloAttributions' is used on desktop & responsive web apps
    // For desktop, we default to not showing attribution, so as to not change how apps currently behave
    // It'll be set to true by default for mobile apps, and for responsive web apps
    const shouldShowAdaloAttributionByDefault =
      magicLayout || primaryPlatform === 'mobile'

    if (showBranding === undefined) {
      if (webSettings.showAdaloAttribution !== undefined) {
        showBranding = webSettings.showAdaloAttribution
      } else {
        showBranding = shouldShowAdaloAttributionByDefault
        webSettings.showAdaloAttribution = shouldShowAdaloAttributionByDefault
      }
    } else if (webSettings.showAdaloAttribution === undefined) {
      if (primaryPlatform === 'mobile') {
        webSettings.showAdaloAttribution = showBranding
      } else {
        // If it's not 'mobile', it'll have 'showBranding' set to true by legacy, we want to reset it to the real value
        showBranding = shouldShowAdaloAttributionByDefault
        webSettings.showAdaloAttribution = shouldShowAdaloAttributionByDefault
      }
    } else if (webSettings.showAdaloAttribution !== showBranding) {
      // Set legacy `showBranding` to match 'webSettings.showAdaloAttribution' for apps that had it set beforehand
      showBranding = webSettings.showAdaloAttribution
    }

    return {
      name,
      icon,
      mixpanelToken,
      OrganizationId,
      description,
      showBranding,
      webSettings,
    }
  }

  getInitialAPIKeysData = () => {
    const { thirdPartyApiKeys = [] } = this.props

    const hash = {}

    for (const thirdPartyApiKey of thirdPartyApiKeys) {
      const { type, key, id } = thirdPartyApiKey
      hash[type] = { key, id }
    }

    return hash
  }

  setupAccordion() {
    const { history, openAccordion } = this.props

    const { active } = QS.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })

    switch (active) {
      case 'display':
        return openAccordion(GROUP, 'Display')
      case 'access':
        return openAccordion(GROUP, 'Access')
      case 'copydelete':
        return openAccordion(GROUP, 'CopyDelete')
      case 'apikeys':
        return openAccordion(GROUP, 'APIKeys')
      case 'layout':
        return openAccordion(GROUP, 'Layout')
      default:
        return openAccordion(GROUP, 'General')
    }
  }

  componentDidMount() {
    const { match, fetchApiKey } = this.props
    const { appId } = match.params

    fetchApiKey(appId)
    this.setupAccordion()
  }

  render() {
    const { app, match, location, history, hasNewMobileOnlyApp } = this.props

    const { loading } = this.state

    const { appId } = match.params

    if (!app) {
      return null
    }

    const { primaryPlatform } = app

    const shouldShowLayoutSettings =
      hasNewMobileOnlyApp && primaryPlatform === 'responsive'

    return (
      <div className="app-settings">
        <Item title="App Settings" group={GROUP} fluid itemId="General">
          <AppSettingsForm
            appId={appId}
            onSubmit={this.handleAppSettingsSubmit}
            initialValues={this.getInitialAppSettingsData()}
          />
        </Item>
        {shouldShowLayoutSettings && (
          <Item title="App Layout Type" group={GROUP} fluid itemId="Layout">
            <AppLayoutTypeSettings
              app={app}
              onSubmit={this.handleAppLayoutSubmit}
            />
          </Item>
        )}
        <Item title="Display Settings" group={GROUP} fluid itemId="Display">
          <DisplaySettings />
        </Item>
        <Item title="App Access" group={GROUP} fluid itemId="Access">
          <AppUsers appId={appId} location={location} history={history} />
          <Cloneable />
          <ApiKey appId={appId} />
        </Item>
        <Item title="Copy / Delete" group={GROUP} fluid itemId="CopyDelete">
          <div className="delete-app-section app-settings-section">
            <h2>Copy App</h2>
            <Button to={`/apps/${appId}/screens/copy`}>Copy {app.name}</Button>
          </div>
          <div className="delete-app-section app-settings-section">
            <h2>Delete App</h2>
            <p>
              Once deleted, all data in the app’s database will be removed. This
              cannot be undone.
            </p>
            <Button danger onClick={this.handleDelete} loading={loading}>
              Delete {app.name}
            </Button>
          </div>
        </Item>
        <Item title="API Keys" group={GROUP} fluid itemId="APIKeys">
          <ThirdPartyApiKeys
            initialValues={this.getInitialAPIKeysData()}
            onSubmit={this.handleAPIKeysSubmit}
            handleDelete={this.handleAPIKeyDelete}
            appId={appId}
          />
        </Item>
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const orgApps = getOrgApps(state)
  const orgAppIds = Object.values(orgApps)
    .flatMap(appArr => appArr)
    .map(appObj => appObj.id)
    .filter(appId => appId !== match.params.appId)

  return {
    orgAppIds,
    app: getApp(state, match.params.appId),
    currentOrgId: getCurrentOrganizationId(state),
    thirdPartyApiKeys: getThirdPartyApiKeys(state, match.params.appId),
    template: findTemplate(getCustomActions(state, match.params.appId)),
    screens: getScreens(state, match.params.appId),
    hasNewMobileOnlyApp: getFeatureFlag(state, 'hasNewMobileOnlyApp'),
  }
}

export const SettingsSub = withRouter(
  connect(mapStateToProps, {
    updateApp,
    deleteApp,
    fetchApiKey,
    deleteThirdPartyApiKey,
    createThirdPartyApiKey,
    updateThirdPartyApiKey,
    updateManyThirdPartyApiKeys,
    deleteManyThirdPartyApiKeys,
    createCustomAction,
    openAccordion,
    resizeObject,
  })(Settings)
)

class SettingsPage extends Component {
  componentDidMount() {
    const { requestApp, match } = this.props
    const { appId } = match.params

    requestApp(appId)
  }

  render() {
    return (
      <Page form title="Settings">
        <h1>App Settings</h1>
        <SettingsSub />
      </Page>
    )
  }
}

export default connect(null, { requestApp })(SettingsPage)

export class SettingsPanel extends Component {
  render() {
    return (
      <div className="app-settings-panel">
        <h2 className="left-panel-title">
          <Icon type="settings" />
          {' Settings'}
        </h2>
        <SettingsSub />
      </div>
    )
  }
}
