import React, { Component } from 'react'
import Icon from '../../../Shared/Icon'

export default class UserItem extends Component {
  handleRemove = () => {
    const { appUser, onRemove } = this.props
    const userId = appUser.UserId
    onRemove(userId)
  }

  renderRightAction() {
    const { appUser, isOrgAdmin } = this.props

    if (appUser.isOwner) {
      return (
        <div className="app-settings-user-owner">
          <Icon type="lock" />
        </div>
      )
    }

    if (!isOrgAdmin) return null

    return <a onClick={this.handleRemove}>Remove</a>
  }

  render() {
    const { appUser } = this.props

    return (
      <div className="app-settings-user-item">
        <div className="app-settings-user-details">
          <div className="app-settings-user-name">{appUser.User.name}</div>
          <div className="app-settings-user-email">{appUser.User.email}</div>
        </div>

        <div className="app-settings-user-action">
          {this.renderRightAction()}
        </div>
      </div>
    )
  }
}
