import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getShortLabel } from '../../../utils/sources'
import { getCurrentAppId, getComponentId } from '../../../ducks/editor/objects'

class BindingValue extends Component {
  render() {
    const { label } = this.props

    return <React.Fragment>{label}</React.Fragment>
  }
}

const mapStateToProps = (state, { binding, objectId }) => ({
  label: getShortLabel(
    state,
    binding,
    getCurrentAppId(state),
    getComponentId(state, objectId)
  ),
})

export default connect(mapStateToProps)(BindingValue)
