import React, { useCallback, useState } from 'react'
import { reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux'

import { updateApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users/index.ts'

import Modal from 'components/Shared/Modal'

import { FORM_NAME } from './shared'
import AndroidCredentialsForm from './AndroidCredentialsForm'
import AppSettingsForm from './AppSettingsForm'
import './AndroidSettingsModal.scss'

const AndroidSettingsModal = ({
  appId,
  onClose,
  onSubmit,
  publishing,
  initialValues,
  androidSettings,
  formValues,
  updateApp,
  isAdmin,
}) => {
  const steps = ['Android Credentials', 'App Settings']
  const totalSteps = steps.length - 1
  const [step, setStep] = useState(0)

  const handleSubmit = useCallback(
    values => {
      const skipBuildEnqueue = values?.skipBuildEnqueue
      delete values?.skipBuildEnqueue
      updateApp(appId, { androidSettings: { ...androidSettings, ...values } })

      return onSubmit(skipBuildEnqueue)
    },
    [updateApp, onSubmit]
  )

  const handleNextStep = e => {
    if (e?.preventDefault) e.preventDefault()
    if (step === totalSteps) return handleSubmit(formValues)

    return setStep(step + 1)
  }

  const handlePrevStep = () => (step === 0 ? onClose() : setStep(step - 1))

  const renderSteps = () => {
    const props = {
      appId,
      onSubmit: handleNextStep,
      initialValues,
      publishing,
      isAdmin,
    }

    switch (step) {
      case 0:
        return <AndroidCredentialsForm {...props} />
      case 1:
        return <AppSettingsForm {...props} />
    }
  }

  const nextButtonText =
    step !== totalSteps
      ? 'Next'
      : publishing
      ? 'Save and Continue'
      : 'Save Android Settings'

  return (
    <Modal scrolling>
      <div>
        <Modal.Header stepper steps={steps} currentStep={step} color="green" />
        <Modal.Content>{renderSteps()}</Modal.Content>
        <Modal.Actions>
          <Modal.Button type="button" text gray onClick={handlePrevStep}>
            Back
          </Modal.Button>
          <Modal.Button form={FORM_NAME} type="submit" green>
            {nextButtonText}
          </Modal.Button>
        </Modal.Actions>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => {
  const currentUser = getCurrentUser(state)

  return {
    formValues: getFormValues(FORM_NAME)(state),
    isAdmin: !!(currentUser && currentUser.admin),
  }
}

const connected = connect(mapStateToProps, { updateApp })(AndroidSettingsModal)

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connected)
