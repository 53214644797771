import React, { Component } from 'react'

import { scaleLinear } from 'd3-scale'
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps'
import { adaloBackendAxios } from 'utils/io/http/axios'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'
import geoUrl from './world-110m.json'

class Legend extends Component {
  render() {
    const { legendData, colorScale } = this.props

    if (legendData.length === 0) {
      return (
        <div className="module-empty-state" style={{ height: 80 }}>
          <div className="module-empty-state-content">
            <h2>No Location Data</h2>
            <span>There isn't any user data for the selected time frame.</span>
          </div>
        </div>
      )
    }

    return (
      <div className="location-legend-wrapper">
        <ul className="location-legend">
          {legendData.map((entry, index) => {
            const countryString =
              entry?.country?.length > 20
                ? `${entry.country.substring(0, 19)}...`
                : entry.country

            return (
              <li>
                <svg width="14" height="14" className="location-legend-icon">
                  <rect width="14" height="14" fill={colorScale(entry.users)} />
                </svg>
                <div className="legend-label-container">
                  {entry?.country?.length > 20 ? (
                    <Tooltip
                      tooltip={
                        <div>
                          <span>{entry.country}</span>
                        </div>
                      }
                      placement="top-start"
                      hideArrow
                      className="visited-item-name"
                    >
                      <span> - {countryString}</span>
                    </Tooltip>
                  ) : (
                    <span> - {countryString}</span>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default class UserLocation extends Component {
  state = {
    loading: true,
    zoom: 1,
    coordinates: [0, 0],
  }

  async componentDidMount() {
    await this.getData()
  }

  componentDidUpdate(prevProps) {
    const { start, range } = this.props

    if (start !== prevProps.start || range !== prevProps.range) {
      this.getData()
    }
  }

  getData = async () => {
    let { appId, start, end } = this.props
    let colorScale
    const queryType = 'locations'

    start = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    end = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`

    let mapData = await adaloBackendAxios.post(`/analytics`, {
      appId,
      queryType,
      start,
      end,
    })

    let legendData = []

    if (mapData.data.success) {
      mapData = mapData.data.data

      if (mapData.length !== 0) {
        for (let i = 0; i < mapData.length; i += 1) {
          mapData[i].users = +mapData[i].users
        }

        const max = mapData[0].users
        legendData = mapData.slice(0, 5)

        colorScale = scaleLinear()
          .domain([1, max])
          .range(['rgba(168, 32, 88, 0.20)', 'rgb(168, 32, 88)'])
      }
    } else {
      mapData = []
    }

    this.setState({ loading: false, mapData, legendData, colorScale })
  }

  renderTooltip = (e, d) => {
    const tooltip = (
      <div
        className="engaged-tooltip"
        style={{ left: e.clientX - 70, top: e.clientY - 100 }}
      >
        <p>
          {d.country}: {d.users}
        </p>
      </div>
    )

    this.setState({ tooltip, showTooltip: true })
  }

  hideTooltip = () => {
    this.setState({ showTooltip: false })
  }

  handleZoomIn = () => {
    const { zoom } = this.state

    if (zoom >= 7) {
      this.setState({ zoom: 8 })
    } else {
      this.setState({ zoom: zoom + 1 })
    }
  }

  handleZoomOut = () => {
    const { zoom } = this.state

    if (zoom <= 2) {
      this.setState({ zoom: 1 })
    } else {
      this.setState({ zoom: zoom - 1 })
    }
  }

  handleCenter = () => {
    this.setState({ zoom: 1, coordinates: [0, 0] })
  }

  render() {
    const {
      loading,
      mapData,
      colorScale,
      tooltip,
      showTooltip,
      legendData,
      zoom,
      coordinates,
    } = this.state

    const { width } = this.props

    return (
      <div>
        <div className="module-header-container">
          <h2 className="module-header">Users by Location</h2>
        </div>
        <div
          className="app-analytics-module-container app-analytics-locations-container"
          style={{ width: width / 2 - 40 }}
        >
          {showTooltip ? tooltip : null}
          {loading ? null : (
            <ComposableMap
              projection="geoMercator"
              projectionConfig={{
                rotate: [-10, 0, 0],
                scale: 35,
              }}
              height={160}
              width={width / 2 - 41}
            >
              <ZoomableGroup
                zoom={zoom}
                center={coordinates}
                onMoveEnd={e => {
                  this.setState({ zoom: e.zoom, coordinates: e.coordinates })
                }}
              >
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map(geo => {
                      const d = mapData.find(
                        s => s.country === geo.properties.NAME_LONG
                      )

                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={d ? colorScale(d.users) : '#f1f1f1'}
                          onMouseEnter={
                            d
                              ? e => {
                                  this.renderTooltip(e, d)
                                }
                              : null
                          }
                          onMouseLeave={d ? this.hideTooltip : null}
                        />
                      )
                    })
                  }
                </Geographies>
              </ZoomableGroup>
            </ComposableMap>
          )}
          {loading ? null : (
            <div className="map-controls">
              <button onClick={this.handleZoomIn}>+</button>
              <button onClick={this.handleZoomOut}>-</button>
              <button onClick={this.handleCenter}>
                <Icon type="center" />
              </button>
            </div>
          )}
          {loading ? null : (
            <Legend legendData={legendData} colorScale={colorScale} />
          )}
        </div>
      </div>
    )
  }
}
