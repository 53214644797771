import { validators, PASSWORD_LENGTH_MAX } from '@adalo/constants'

export const validateSignUpForm = data => {
  const errors = {}

  if (!data.email) {
    errors.email = 'Email is required'
  } else if (!data.email.match(validators.email)) {
    errors.email = 'Please enter a valid email address'
  }

  if (!data.name) {
    errors.name = 'Name is required'
  }

  if (data.name && !data.name.match(validators.name)) {
    errors.name = 'Name has unsupported characters'
  }

  if (!data.password || data.password.length < 8) {
    errors.password = 'Password must be at least 8 characters'
  }

  if (data.password && data.password.length > PASSWORD_LENGTH_MAX) {
    errors.password = 'Password is too long'
  }

  if (
    data.password &&
    data.password.length >= 8 &&
    !data.password.match(validators.password)
  ) {
    errors.password = 'Password has unsupported characters'
  }

  if (!data.terms) {
    errors.terms = 'You must accept the Terms of Use'
  }

  return errors
}

export const validateEmailForm = (values, props) => {
  const { currentUser } = props
  const { emailValidation } = values
  const errors = {}

  if (!emailValidation) {
    errors.emailValidation = 'Validation code is required'
  }

  if (
    emailValidation &&
    emailValidation.length !== 6 &&
    !typeof emailValidation === 'string'
  ) {
    errors.emailValidation = 'Validation code has unsupported characters'
  }

  if (!currentUser) {
    errors.emailValidation = 'User not found'
  }

  if (currentUser && emailValidation !== currentUser.emailValidationString) {
    errors.emailValidation = 'Incorrect validation code'
  }

  return errors
}
