export const getAppTitle = (app, suffix) => {
  if (app?.name && suffix) {
    return `${app.name} - ${suffix}`
  }

  return app ? app.name || 'Untitled' : 'Loading...'
}

export const getOrgInitials = (name = '') =>
  name
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .toUpperCase()
    .slice(0, 2)
