/* eslint-disable no-alert */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  PrebuiltBuilderContentData,
  PrebuiltBuilderContentPlatformTarget,
  PrebuiltBuilderContentStatus,
} from 'ducks/layoutSections/types'
import { usePrebuiltLayoutSections } from 'ducks/layoutSections/hooks'
import { EditorObject } from 'utils/responsiveTypes'
import getPrebuiltLayoutSectionBodyFromEditorObject from 'utils/objects/getPrebuiltLayoutSectionBodyFromEditorObject'
import {
  useCreateLayoutSectionBuilderContent,
  useUpdateLayoutSectionBuilderContent,
} from './hooks'
import { validatePrebuiltSectionValues } from './utils'
import {
  PrebuiltSectionBaseValues,
  PrebuiltSectionImageValues,
  PrebuiltSectionValues,
} from './types'

const NEW_LAYOUT_SECTION_MOCK_ID = 0 // new layout section has no id

type SelectOption = { label: string; value: number }
type PartialData = Partial<PrebuiltBuilderContentData>
type PrebuiltLayoutSectionItem = SelectOption & PartialData

const newLayoutSectionOption: PrebuiltLayoutSectionItem = {
  label: 'New Layout Section',
  value: NEW_LAYOUT_SECTION_MOCK_ID,
}

const usePrebuiltLayoutSectionsFormOptions = () => {
  const {
    prebuiltLayoutSections: data,
    loading,
    error,
    refetch,
  } = usePrebuiltLayoutSections({ includeUnlisted: true })

  const [prebuiltLayoutSections, setPrebuiltLayoutSections] = useState<
    PrebuiltLayoutSectionItem[]
  >([])

  useEffect(() => {
    if (data && data?.length > 0) {
      const parsedOptions = data
        .map(({ name, id, ...other }) => {
          if (!id) {
            return null
          }

          const label = `${id} - ${name}`

          return { label, value: id, id, name, ...other }
        })
        // need to do casting cause typescript doesn't understand that we've filtered out nulls
        .filter(Boolean) as unknown as PrebuiltLayoutSectionItem[]

      parsedOptions.sort((a, b) => (b.id ?? 0) - (a.id ?? 0))
      setPrebuiltLayoutSections(parsedOptions)
    }
  }, [data])

  const inputOptions = [newLayoutSectionOption, ...prebuiltLayoutSections]

  return { loading, prebuiltLayoutSections, inputOptions, error, refetch }
}

type UseManageLayoutSectionsParams = {
  object: EditorObject
}

const defaultValue: PrebuiltSectionValues = {
  targetLayoutSectionId: 0,
  rank: 0,
  name: '',
  platformTarget: PrebuiltBuilderContentPlatformTarget.BOTH,
  status: PrebuiltBuilderContentStatus.UNLISTED,
  categories: '',
  primaryTags: '',
  secondaryTags: '',
  mobileThumbnail: '',
  responsiveThumbnail: '',
}

type GetChangeHandler = <T extends keyof PrebuiltSectionBaseValues>(
  key: T
) => (value: Pick<PrebuiltSectionBaseValues, T>) => void

type GetImageChangeHandler = (
  key: keyof PrebuiltSectionImageValues
) => (value: string) => void

type UseManagePrebuiltLayoutSections = (
  params: UseManageLayoutSectionsParams
) => {
  onChangeTargetId: (p: { targetLayoutSectionId: number }) => void
  targetInputOptions: PrebuiltLayoutSectionItem[]
  prebuiltLayoutSections: PrebuiltLayoutSectionItem[]
  values: PrebuiltSectionValues
  setValues: Dispatch<SetStateAction<PrebuiltSectionValues>>
  getChangeHandler: GetChangeHandler
  getImageChangeHandler: GetImageChangeHandler
  submitting: boolean
  onSubmit: () => Promise<void>
  error?: Error | null
}

const useManagePrebuiltLayoutSections: UseManagePrebuiltLayoutSections =
  params => {
    const { object } = params
    const [mounted, setMounted] = useState(false)
    const [prefillPerformed, setPrefillPerformed] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [values, setValues] = useState<PrebuiltSectionValues>(defaultValue)
    const { createLayoutSectionBuilderContent } =
      useCreateLayoutSectionBuilderContent()
    const { updateLayoutSectionBuilderContent } =
      useUpdateLayoutSectionBuilderContent()

    const {
      inputOptions: targetInputOptions,
      prebuiltLayoutSections,
      refetch,
    } = usePrebuiltLayoutSectionsFormOptions()

    const getChangeHandler: GetChangeHandler = key => value => {
      if (key !== 'targetLayoutSectionId') {
        setValues(prev => ({ ...prev, [key]: value[key] }))
      } else {
        console.warn(
          `Trying to set targetLayoutSectionId with getChangeHandler, this should be handled by onChangeTargetId`
        )
      }
    }

    const getImageChangeHandler: GetImageChangeHandler = key => value => {
      // these values are just an s3 key (just as we do with ScreenTemplates)
      setValues(prev => ({ ...prev, [key]: value }))
    }

    const onChangeTargetId = (ev: { targetLayoutSectionId: number }) => {
      const value = ev.targetLayoutSectionId

      if (value === NEW_LAYOUT_SECTION_MOCK_ID) {
        setValues(defaultValue)
      } else {
        const selected = prebuiltLayoutSections.find(
          ({ value: id }) => id === value
        )

        if (selected) {
          const {
            name,
            platformTarget,
            status,
            categories,
            primaryTags,
            secondaryTags,
            mobileThumbnail,
            responsiveThumbnail,
            rank,
          } = selected

          setValues({
            targetLayoutSectionId: value,
            rank: rank ?? 0,
            name: name ?? '',
            platformTarget:
              platformTarget ?? PrebuiltBuilderContentPlatformTarget.BOTH,
            status: status ?? PrebuiltBuilderContentStatus.UNLISTED,
            categories: categories ?? '',
            primaryTags: primaryTags ?? '',
            secondaryTags: secondaryTags ?? '',
            mobileThumbnail: mobileThumbnail ?? '',
            responsiveThumbnail: responsiveThumbnail ?? '',
          })
        } else {
          console.warn(`No prebuiltLayoutSection found for id ${value}`)
        }
      }
    }

    useEffect(() => {
      setMounted(true)
    }, [])

    const objectMetadataPrebuiltId =
      object.metadata?.['prebuiltLayoutSectionId']

    useEffect(() => {
      // After mounting, if no auto prefill was performed, check for the metadata of the selected object to use for prefilling
      if (mounted && !prefillPerformed) {
        if (
          objectMetadataPrebuiltId &&
          values.targetLayoutSectionId !== objectMetadataPrebuiltId &&
          typeof objectMetadataPrebuiltId === 'number'
        ) {
          onChangeTargetId({
            targetLayoutSectionId: objectMetadataPrebuiltId,
          })
          setPrefillPerformed(true)
        }
      }
    }, [objectMetadataPrebuiltId, onChangeTargetId, mounted, prefillPerformed])

    const onSubmit = async () => {
      setSubmitting(true)
      try {
        const rawBody = object
        const body = getPrebuiltLayoutSectionBodyFromEditorObject(rawBody)

        if (!values.targetLayoutSectionId) {
          // Create new prebuilt layout section
          const payload = { ...values, body, rawBody }
          const payloadErrors = validatePrebuiltSectionValues(payload)
          if (payloadErrors.length > 0) {
            alert(
              `Prebuilt layout section error: ${payloadErrors.join(', \n')}`
            )
          } else {
            const result = await createLayoutSectionBuilderContent(payload)
            console.log(`Created PrebuiltLayoutSection: `, result)
            alert(`Created Layout Section ${String(result?.id)}`)
          }
        } else {
          // Update existing prebuilt layout section
          const id = values.targetLayoutSectionId
          const payload = { ...values, body, rawBody, id }
          delete payload.targetLayoutSectionId
          const payloadErrors = validatePrebuiltSectionValues(payload)
          if (payloadErrors.length > 0) {
            alert(
              `Prebuilt layout section error: ${payloadErrors.join(', \n')}`
            )
          } else {
            const result = await updateLayoutSectionBuilderContent(payload)
            console.log(`Updated PrebuiltLayoutSection: `, result)
            alert(`Updated Layout Section ${id}`)
          }
        }
      } catch (err: unknown) {
        setError(err as Error)
      } finally {
        setSubmitting(false)
        refetch()
      }
    }

    return {
      onChangeTargetId,
      targetInputOptions,
      prebuiltLayoutSections,
      values,
      setValues,
      getChangeHandler,
      getImageChangeHandler,
      submitting,
      onSubmit,
      error,
    }
  }

export default useManagePrebuiltLayoutSections
