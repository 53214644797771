import React from 'react'
import PropTypes from 'prop-types'

import { GroupedAccordion } from 'components/Shared/Accordion'
import ControlField from 'components/Shared/Forms/ControlField'
import SimpleTextControl from 'components/Editor/Inspect/Libraries/SimpleTextControl'
import Icon from 'components/Shared/Icon'

const RequestPermission = ({
  title,
  name,
  displayName,
  placeholder,
  description,
  goodExample,
  badExample,
}) => {
  const renderChildren = () => {
    return (
      <>
        {description && <p>{description}</p>}
        <div className="app-settings-permissions-example good">
          <Icon type="thumb-up" />
          <span>
            <p>Good example for a Travel App</p>
            <p>{goodExample}</p>
          </span>
        </div>
        <div className="app-settings-permissions-example bad">
          <Icon type="thumb-down" />
          <span>
            <p>Bad example for a Travel App</p>
            <p>{badExample}</p>
          </span>
        </div>
        <div className="form-inspect-field-wrapper">
          <ControlField
            name={name}
            component={SimpleTextControl}
            displayName={displayName}
            placeholder={placeholder}
          />
        </div>
      </>
    )
  }

  return (
    <GroupedAccordion
      className="app-settings-permissions"
      color="green"
      title={title}
      renderChildren={renderChildren}
      group="iosAppRequestPermissions"
      boxed
    />
  )
}

RequestPermission.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  goodExample: PropTypes.string.isRequired,
  badExample: PropTypes.string.isRequired,
}

RequestPermission.defaultProps = {
  displayName: 'Text',
}

export default React.memo(RequestPermission)
