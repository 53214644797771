import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getDeviceType } from '@adalo/utils'

import { setCurrentTab, CurrentTab } from 'ducks/magicLayout'
import { getBestParent } from 'utils/geometry'
import { scale, unScale, scaleValue } from 'utils/zoom'
import {
  getLibrary,
  calculateSnappedPosition,
  checkComponentsUsed,
  addComponentUsed,
} from 'utils/libraries'
import { getSnapValue } from 'utils/snapping'
import {
  setSelectionParent,
  resetSelectionParent,
} from 'ducks/editor/selection'
import {
  getLibraryDeviceLayout,
  translateLibraryLayoutValueToResponsivity,
} from 'utils/objects/libraryLayout'

import { getAppLibraryVersion, updateApp, getApp } from 'ducks/apps'
import { selectObjects, createObject } from 'ducks/editor/objects'
import { getBestParentForNewObject } from 'utils/positioning'

import {
  getXGrid,
  getYGrid,
  setXSnap,
  setYSnap,
  resetSnaps,
} from '../../../ducks/editor/snapping'

import { resetTool, getStartPosition } from '../../../ducks/editor/tools'
import LibraryComponent from './LibraryComponent/LibraryComponentSub'
import './AddLibraryComponent.css'

const MIN_RADIUS_SQUARED = 20 ** 2

class AddComponentInstance extends Component {
  constructor(props) {
    super(props)

    const startPosition = props.startPosition
      ? unScale(props.startPosition, props.zoom)
      : [0, 0]

    let [x, y] = startPosition
    const { width, height } = props.options

    x -= Math.floor(width / 2)
    y -= Math.floor(height / 2)

    this.state = {
      x,
      y,
      mouseOver: !!props.startPosition,
      xSnapped: x,
      ySnapped: y,
      widthSnapped: 0,
      heightSnapped: 0,
      deviceType: 'desktop',
    }
  }

  handleMouseUp = e => {
    const { startPosition } = this.props
    const [x, y] = startPosition
    const distSquared = (e.clientX - x) ** 2 + (e.clientY - y) ** 2

    if (distSquared < MIN_RADIUS_SQUARED) {
      return
    }

    this.handleMouseDown(e)
  }

  handleMouseDown = e => {
    const { xSnapped, ySnapped, widthSnapped, heightSnapped } = this.state

    const {
      createObject,
      resetTool,
      resetSnaps,
      options,
      updateApp,
      app,
      setCurrentTab,
      resetSelectionParent,
    } = this.props

    const { libraryName, libraryVersion, componentName } = options

    const library = getLibrary(libraryName, libraryVersion)

    const component = library.config.components.filter(
      c => c.name === componentName
    )[0]

    const layoutAttributes = {
      x: xSnapped,
      y: ySnapped,
      width: widthSnapped || options.width,
      height: heightSnapped || options.height,
    }

    let object = {
      ...options,
      ...layoutAttributes,
      name: component.displayName,
    }

    if (app.magicLayout && component.layout) {
      const responsivity = translateLibraryLayoutValueToResponsivity(
        component.layout.shared,
        component.layout.sticky
      )

      if (Object.keys(responsivity).length > 0) {
        object.responsivity = responsivity
      }

      const deviceLayout = getLibraryDeviceLayout(
        component.layout,
        layoutAttributes
      )

      object = { ...object, ...deviceLayout }
    }

    createObject(object, null, false, {
      mouseX: e.clientX,
      mouseY: e.clientY,
    })

    if (!checkComponentsUsed(libraryName, componentName, app)) {
      addComponentUsed(libraryName, componentName, app, updateApp)
    }

    resetTool()
    resetSnaps()
    setCurrentTab(CurrentTab.Setup)
    resetSelectionParent()
  }

  handleMouseMove = e => {
    const { zoom, options, setSelectionParent } = this.props
    const mouseX = e.clientX
    const mouseY = e.clientY

    let [x, y] = unScale([e.clientX, e.clientY], zoom)
    x = Math.round(x - options.width / 2)
    y = Math.round(y - options.height / 2)

    const parent = this.getBestParent(x, y, {
      mouseX,
      mouseY,
    })

    const snap = this.calculateSnappedPosition(x, y, parent)
    const [xSnapped, ySnapped, widthSnapped, heightSnapped] = snap

    const deviceType = parent ? getDeviceType(parent.width) : 'desktop'

    this.setState(
      {
        mouseOver: true,
        x,
        y,
        xSnapped,
        ySnapped,
        widthSnapped,
        heightSnapped,
        deviceType,
      },
      () => {
        const {
          xSnapped: x,
          ySnapped: y,
          widthSnapped: width,
          heightSnapped: height,
        } = this.state

        setSelectionParent(
          getBestParentForNewObject(parent, x, y, width, height, zoom, {
            mouseX,
            mouseY,
          })
        )
      }
    )
  }

  handleMouseLeave = () => {
    this.setState({ mouseOver: false }, () => {
      const { resetSelectionParent } = this.props
      resetSelectionParent()
    })
  }

  getBestParent = (xArg, yArg, coords = {}) => {
    let { x, y } = this.state
    x = xArg || x
    y = yArg || y

    const { options, screens, zoom } = this.props

    const obj = { ...options, x, y }
    const parentId = getBestParent(obj, screens, null, null, zoom, coords)

    return screens.filter(s => s.id === parentId)[0]
  }

  calculateGridSnapPosition = (x, y) => {
    const { options, xGrid, yGrid, setXSnap, setYSnap, zoom } = this.props
    const { width, height } = options

    if (!xGrid || !yGrid) {
      return [x, y, width, height]
    }

    let xDiff = 0
    let yDiff = 0

    const xCoords = {
      left: x,
      center: x + width / 2,
      right: x + width,
    }

    const yCoords = {
      top: y,
      center: y + height / 2,
      bottom: y + height,
    }

    const xSnap = getSnapValue(xGrid, xCoords, zoom)
    const ySnap = getSnapValue(yGrid, yCoords, zoom)

    if (xSnap) {
      const key = Object.keys(xSnap)[0]
      xDiff = xSnap[key] - xCoords[key]
      setXSnap(xSnap[key])
    } else {
      setXSnap(null)
    }

    if (ySnap) {
      const key = Object.keys(ySnap)[0]
      yDiff = ySnap[key] - yCoords[key]
      setYSnap(ySnap[key])
    } else {
      setYSnap(null)
    }

    return [Math.round(x + xDiff), Math.round(y + yDiff), width, height]
  }

  calculateSnappedPosition = (x, y, parent) => {
    const { options } = this.props

    if (!options.snappingRules) {
      return this.calculateGridSnapPosition(x, y)
    }

    const pos = calculateSnappedPosition({ ...options, x, y }, parent)

    return [pos.x, pos.y, pos.width, pos.height]
  }

  renderComponent = () => {
    const { zoom, options, libraryVersion } = this.props
    const { xSnapped, ySnapped, widthSnapped, heightSnapped, deviceType } =
      this.state
    const [x, y] = scale([xSnapped, ySnapped], zoom)

    let { width, height } = options

    const unScaledWidth = widthSnapped || width
    const unScaledHeight = heightSnapped || height

    width = scaleValue(unScaledWidth, zoom)
    height = scaleValue(unScaledHeight, zoom)

    const object = { ...options, width: unScaledWidth, height: unScaledHeight }

    const newZoom = {
      ...zoom,
      offset: [0, 0],
    }

    return (
      <g opacity={0.5}>
        <LibraryComponent
          object={object}
          zoom={newZoom}
          libraryVersion={libraryVersion}
          deviceType={deviceType}
          x={x}
          y={y}
        />
        <g transform={`translate(${x}, ${y})`}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            className="add-component-outline"
          />
        </g>
      </g>
    )
  }

  render() {
    const { mouseOver } = this.state

    const width = window.innerWidth
    const height = window.innerHeight

    return (
      <g className="add-component-instance">
        {mouseOver ? this.renderComponent() : null}
        <rect
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
          onMouseLeave={this.handleMouseLeave}
          onMouseEnter={this.handleMouseMove}
          x={0}
          y={0}
          width={width}
          height={height}
          className="add-component-backdrop"
        />
      </g>
    )
  }
}

const mapStateToProps = (state, { match, options }) => ({
  screens: selectObjects(state),
  xGrid: getXGrid(state),
  yGrid: getYGrid(state),
  startPosition: getStartPosition(state),
  libraryVersion: getAppLibraryVersion(
    state,
    match.params.appId,
    options.libraryName
  ),
  app: getApp(state, match.params.appId),
})

export default withRouter(
  connect(mapStateToProps, {
    createObject,
    resetTool,
    setXSnap,
    setYSnap,
    resetSnaps,
    updateApp,
    setCurrentTab,
    setSelectionParent,
    resetSelectionParent,
  })(AddComponentInstance)
)
