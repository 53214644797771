import React, { Component } from 'react'
import IconControl from './IconControl'

export default class IconRow extends Component {
  render() {
    const { displayName, name, value, onChange } = this.props

    return (
      <div className="library-inspect-icon-row">
        <p>{displayName}</p>
        <IconControl name={name} onChange={onChange} value={value} />
      </div>
    )
  }
}
