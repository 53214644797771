import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError, getFormValues } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { CardElement } from '@stripe/react-stripe-js'

import {
  fetchOrganizations,
  getOrganization,
  updateOrganizationByID,
  fetchOrganization,
  bulkRemoveUsers,
} from 'ducks/organizations'
import { getOrgApps, bulkUpdateApps } from 'ducks/apps'

import { getMetrics } from 'ducks/metrics'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import {
  saveSubscription,
  configureSavedSubscription,
  fetchSubscription,
  getPricingPlans,
  cancelSubscription,
  resetSubscription,
} from 'ducks/billing'

import { adaloBackendAxios } from 'utils/io/http/axios'
import {
  PRICE_TYPE_MONTHLY,
  PRICE_TYPE_YEARLY,
  getPlanPrice,
} from 'utils/billing'
import { trackSubscriptionStartEvent } from 'utils/facebookPixelEvents'

import PlanChangeContextWrapper, {
  PlanChangeContext,
} from './PlanChangeContext'

import Modal from '../Modal'
import SignupForm from './SignupForm'
import {
  getAnnualToggle,
  getPaymentRouteType,
  getSelectedPlanValue,
  getUpdateCardFlag,
  getUpdatePaymentSettingsFlag,
  setUpdatePaymentSettingsFlag,
  setPlanSelectedFlag,
  setSelectedPlanValue,
  setSwitchToYearlyFlag,
  setUpdateCardFlag,
  setPaymentRouteType,
  hideModal,
} from '../../../ducks/trialWarning'
import { withStripePromise } from './LoadStripe'
import history from '../../../history'

class TrialModal extends Component {
  static contextType = PlanChangeContext

  updatePaymentMethod = async values => {
    const { elements, stripe, organizationId } = this.props
    const { name, address_zip, address_city, address_line1 } = values
    let { address_country } = values

    // assign correct country value
    address_country = address_country?.value

    // retrieve stripe card element
    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      throw new Error('Could not find Stripe Card Element')
    }

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          address: {
            country: address_country,
            postal_code: address_zip,
            city: address_city,
            line1: address_line1,
          },
        },
      })

      if (error) {
        throw new Error(error)
      }

      await adaloBackendAxios.post(
        `/organizations/${organizationId}/payment_methods`,
        {
          paymentMethodId: paymentMethod.id,
        }
      )

      this.handleSuccess()
    } catch (err) {
      console.error(err)
      alert(`There was a problem updating your payment method.`)
    }
  }

  createSubscription = async ({
    coupon,
    name,
    address_country,
    address_zip,
    address_city,
    address_line1,
  }) => {
    const {
      stripe,
      elements,
      saveSubscription,
      organizationId,
      initialValues,
      fetchSubscription,
      selectedPlanValue: planType,
      configureSavedSubscription,
      paymentFormValues,
      pricingPlans,
    } = this.props

    const { appOverage, teamMemberOverage } = this.context

    //get selected payment card from payment form
    const { paymentMethodId: selectedPaymentMethodId } = paymentFormValues

    const { annual } = initialValues

    const planPrice = getPlanPrice(
      planType,
      pricingPlans,
      annual ? PRICE_TYPE_YEARLY : PRICE_TYPE_MONTHLY
    )

    const subscription = (await fetchSubscription(organizationId)).value.data
    const { paymentMethodId: defaultPaymentMethodId, configured } =
      subscription || {}

    let paymentMethodId = selectedPaymentMethodId

    if (!paymentMethodId) {
      paymentMethodId = defaultPaymentMethodId
    }

    const cardElement = elements.getElement(CardElement)

    address_country = address_country && address_country.value

    let paymentMethodError

    if (cardElement) {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          address: {
            country: address_country,
            postal_code: address_zip,
            city: address_city,
            line1: address_line1,
          },
        },
      })

      if (error) {
        paymentMethodError = error
      }

      paymentMethodId = paymentMethod && paymentMethod.id
    } else {
      paymentMethodError = {
        message:
          'Error obtaining saved card element. Try again using a new card.',
      }
    }

    if (paymentMethodId || configured) {
      const addonData = {
        appOverage,
        teamMemberOverage,
      }

      try {
        const response = await saveSubscription(organizationId, {
          coupon,
          planType,
          paymentMethodId,
          annual,
          ...addonData,
        })

        if (response instanceof Error) {
          const errorMessage = response.response?.data?.error

          if (typeof errorMessage === 'string' && errorMessage.length > 0) {
            throw new SubmissionError({ _error: errorMessage })
          }
        }

        const paymentIntent = response.value.data.paymentIntent || null

        if (
          paymentIntent &&
          (paymentIntent.status === 'requires_action' ||
            paymentIntent.status === 'requires_payment_method')
        ) {
          await this.handleCardSetupRequired(
            paymentIntent,
            paymentMethodId,
            subscription,
            { planType, annual }
          )
        }

        if (planType === 'pro' || planType === 'individual') {
          if (window.ga) {
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'button',
              eventAction: 'click',
              eventLabel: 'Payment Made',
              eventValue: 50,
            })
          }
        }

        if (planType === 'business' || planType === 'launch') {
          if (window.ga) {
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'button',
              eventAction: 'click',
              eventLabel: 'Payment Made',
              eventValue: 200,
            })
          }
        }

        await configureSavedSubscription(organizationId, {
          planType,
          configureExistingSubscription: true,
        })

        if (!configured) {
          // Pixel event for first time subscriptions
          trackSubscriptionStartEvent(planPrice)
        }

        this.handleSuccess(planType)
      } catch (err) {
        const { response, message, errors } = err

        const errorMessage =
          errors?._error ||
          response?.data?.error ||
          message ||
          'An unknown error occurred'

        throw new SubmissionError({ _error: errorMessage })
      }
    } else {
      console.error('ERROR SAVING SUBSCRIPTION:', paymentMethodError)
      if (paymentMethodError) {
        throw new SubmissionError({
          _error: paymentMethodError?.message,
        })
      }
      if (!configured) {
        throw new SubmissionError({
          _error: `Stripe not configured`,
        })
      }
      throw new SubmissionError({
        _error: `An unknown error occurred. If this persists, please contact support.`,
      })
    }
  }

  handleSubmit = async values => {
    const { updatePaymentSettings } = this.props

    if (updatePaymentSettings) {
      try {
        await this.updatePaymentMethod(values)
      } catch (err) {
        console.error(err)
        alert('There was a problem updating your payment method.')
      }
    } else {
      try {
        await this.createSubscription(values)
      } catch (err) {
        const errorMessage =
          err.errors?._error || err.message || 'An unknown error occurred.'

        const consoleMessage = `${err.response?.data?.error} \n ${err}`
        console.error(consoleMessage)

        alert(`There was a problem processing your payment.\n\n${errorMessage}`)
      }
    }
  }

  handleCardSetupRequired = async (
    paymentIntent,
    paymentMethodId,
    subscription,
    newSubscription
  ) => {
    try {
      const { stripe, organizationId } = this.props
      const { planType, annual } = subscription || {}
      const { planType: newPlanType, annual: newAnnual } = newSubscription

      const result = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: paymentMethodId,
        }
      )

      if (result.error) {
        // If payment failed on renewing plan or upgrading from free, downgrade back to free
        if (
          planType === 'free' ||
          (planType === newPlanType && annual === newAnnual)
        ) {
          await cancelSubscription(organizationId)
        } else {
          await resetSubscription(organizationId)
        }

        const { error } = result

        const errorMessage =
          (error.code && error.message) || 'Error in confirm card payment'

        throw new SubmissionError({ _error: errorMessage })
      }
    } catch (error) {
      const errMessage = error.errors._error || error.message

      throw new SubmissionError({ _error: errMessage })
    }
  }

  updateAddons = () => {
    const {
      organizationId,
      canonicalAppsList,
      organization,
      updateOrganizationByID,
      fetchOrganizations,
      fetchOrganization,
      bulkUpdateApps,
      bulkRemoveUsers,
    } = this.props

    const {
      appList,
      numberOfPublishdApps,
      teamMembersToRemove,
      appLimit,
      resetPlanChangeContext,
    } = this.context

    const removedTeamMemberIds = teamMembersToRemove.map(
      teamMember => teamMember.id
    )

    const changedApps = {}

    for (const [datasourceId, provisionalApps] of Object.entries(appList)) {
      const provisionalPublishedStatus = provisionalApps[0].published
      const canonicalPublishedStatus =
        canonicalAppsList[datasourceId][0].published

      if (provisionalPublishedStatus !== canonicalPublishedStatus) {
        for (const { id, published } of provisionalApps) {
          changedApps[id] = {
            published,
            ...(published ? {} : { DomainId: null }),
          }
        }
      }
    }

    bulkUpdateApps(organizationId, changedApps)
    bulkRemoveUsers(organizationId, removedTeamMemberIds)

    fetchOrganization(organizationId)
    fetchOrganizations()

    updateOrganizationByID(organizationId, {
      publishedApps: {
        ...organization.publishedApps,
        limit: appLimit,
        count: numberOfPublishdApps,
      },
    })

    resetPlanChangeContext()
  }

  handleSuccess = async () => {
    const {
      match,
      hideModal,
      setPaymentRouteType,
      setPlanSelectedFlag,
      setSelectedPlanValue,
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setSwitchToYearlyFlag,
      lastAppId,
      forceRefresh,
    } = this.props

    try {
      hideModal()

      const appId = match.params.appId || lastAppId

      if (appId) {
        history.push(`/apps/${appId}/payment-success`)
      } else {
        history.push(`/`)
      }

      if (forceRefresh) {
        forceRefresh()
      }

      this.updateAddons()

      setPaymentRouteType('billing')
      setPlanSelectedFlag(false)
      setSelectedPlanValue('')
      setUpdateCardFlag(false)
      setUpdatePaymentSettingsFlag(false)
      setSwitchToYearlyFlag(false)
    } catch (err) {
      const errMessage = err?.errors?._error || err?.message || err
      throw new SubmissionError({ _error: errMessage })
    }
  }

  handleClose = () => {
    const {
      match,
      hideModal,
      setPaymentRouteType,
      setPlanSelectedFlag,
      setSelectedPlanValue,
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setSwitchToYearlyFlag,
      lastAppId,
    } = this.props

    setPaymentRouteType('billing')
    setPlanSelectedFlag(false)
    setSelectedPlanValue('')
    setUpdateCardFlag(false)
    hideModal()
    setUpdatePaymentSettingsFlag(false)
    setSwitchToYearlyFlag(false)
    const appId = match.params.appId || lastAppId

    if (appId) {
      history.push(`/apps/${appId}/screens`)
    } else {
      history.push(`/`)
    }
  }

  renderForm() {
    const {
      onClose,
      metrics,
      datasource,
      onDowngrade,
      initialValues,
      onClickBackChangePlan,
      organizationId,
    } = this.props

    return (
      <SignupForm
        onSubmit={this.handleSubmit}
        onCancel={onClose || this.handleClose}
        initialValues={initialValues}
        metrics={metrics}
        datasource={datasource}
        onDowngrade={onDowngrade}
        onClickBackChangePlan={onClickBackChangePlan}
        applyCoupon={this.handleCoupon}
        organizationId={organizationId}
      />
    )
  }

  render() {
    const { onClose, paymentRouteType } = this.props

    if (paymentRouteType === 'upgrade') {
      return (
        <Modal size="xl" scrolling onClose={onClose || this.handleClose}>
          {this.renderForm()}
        </Modal>
      )
    } else {
      return this.renderForm()
    }
  }
}

const TrialModalWithContext = props => (
  <PlanChangeContextWrapper>
    <TrialModal {...props} />
  </PlanChangeContextWrapper>
)

const mapStateToProps = (state, { appId, organizationId }) => {
  const paymentFormSelector = getFormValues('trialModalSignup')

  return {
    metrics: getMetrics(state, appId),
    datasource: getDefaultDatasource(state, appId),
    updateCardFlag: getUpdateCardFlag(state),
    updatePaymentSettings: getUpdatePaymentSettingsFlag(state),
    annual: getAnnualToggle(state),
    paymentRouteType: getPaymentRouteType(state),
    selectedPlanValue: getSelectedPlanValue(state),
    paymentFormValues: paymentFormSelector(state),
    canonicalAppsList: getOrgApps(state),
    organization: getOrganization(state, organizationId),
    pricingPlans: getPricingPlans(state),
  }
}

const connected = connect(mapStateToProps, {
  fetchSubscription,
  saveSubscription,
  configureSavedSubscription,
  fetchOrganizations,
  setPlanSelectedFlag,
  setSelectedPlanValue,
  setPaymentRouteType,
  setSwitchToYearlyFlag,
  setUpdateCardFlag,
  setUpdatePaymentSettingsFlag,
  hideModal,
  updateOrganizationByID,
  fetchOrganization,
  bulkUpdateApps,
  bulkRemoveUsers,
})(withStripePromise(TrialModalWithContext))

export default withRouter(connected)
