import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import { getLabel } from 'utils/sources'

import { getSources } from 'ducks/recommender'

import SelectableInput from 'components/Shared/Forms/SelectableInput'

class SourceField extends Component {
  render() {
    const { name, options, getLabel, boxed, displayName, menuTheme } =
      this.props

    return (
      <Field
        name={name}
        component={SelectableInput}
        options={options}
        getLabel={getLabel}
        boxed={boxed}
        displayName={displayName}
        menuTheme={menuTheme}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const { appId, componentId, objectId, actionId, dataTypes, reference } = props

  const options = getSources(
    state,
    appId,
    componentId,
    objectId,
    dataTypes,
    null,
    actionId,
    reference
  )

  return {
    options,
    getLabel: source => getLabel(state, source, appId, componentId),
  }
}

export default connect(mapStateToProps)(SourceField)
