import React from 'react'
import Form from './Form'

export default function TwoFactorModal(props) {
  const { onSuccess } = props

  const handleSubmit = data => {
    onSuccess(data.code)
  }

  return <Form onSubmit={handleSubmit} />
}
