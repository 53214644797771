import moment from 'moment'
import { dataTypes } from '@adalo/constants'
import { buildIndex, deepMerge } from '@adalo/utils'

const DATE_ONLY = /^\d{4}\-\d{2}\-\d{2}$/
const ID_KEYS = ['id', '_id']

const hasValidTimestampFormat = value =>
  new Date(value).getTime() > 0 &&
  (String(value).length === 12 || String(value).length === 13)

export const getFieldType = value => {
  if (typeof value === 'string' && value.match(DATE_ONLY)) {
    return dataTypes.DATE_ONLY
  } else if (
    typeof value === 'string' &&
    moment(value, moment.ISO_8601, true).isValid()
  ) {
    return dataTypes.DATE
  } else if (typeof value === 'number' && hasValidTimestampFormat(value)) {
    return dataTypes.DATE
  } else if (typeof value === 'number') {
    return dataTypes.NUMBER
  } else if (typeof value === 'boolean') {
    return dataTypes.BOOLEAN
  }

  return dataTypes.TEXT
}

export const getIdField = (fields, orderedFields) => {
  for (const key of ID_KEYS) {
    if (key in fields) {
      return key
    }
  }

  return orderedFields[0]
}

export const mergeProps = items =>
  items.reduce((acc, curr) => {
    Object.entries(curr).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value
      }
    })

    return acc
  }, {})

// Returns fields object and orderedFields array
export const getFields = items => {
  const item = combineRecords(items)

  const fieldsArray = getFieldsSub(item)

  const fields = buildIndex(fieldsArray, field => field.key)
  let orderedFields = fieldsArray.map(field => field.key)

  for (const key of Object.keys(fields)) {
    delete fields[key].key
  }

  // Assign primaryField
  const idField = getIdField(fields, orderedFields)

  if (idField) {
    fields[idField].isPrimaryField = true

    orderedFields = [idField].concat(orderedFields.filter(f => f !== idField))
  }

  return [fields, orderedFields]
}

export const combineRecords = records => {
  let result = {}

  for (const record of records) {
    result = deepMerge(record, result)
  }

  return result
}

export const getFieldsSub = (item, prefix = '') => {
  if (Array.isArray(item)) {
    const key = prefix ? `${prefix}.0` : '0'

    return getFieldsSub(item[0], key)
  } else if (item && typeof item === 'object') {
    let fields = []

    for (const fieldId of Object.keys(item)) {
      if (
        fieldId.match(/[^\w\d\s\-_~`"'!@#%&,<>:;=\?\(\)\{}\[]\\\/\^\$\|\*\+]/)
      ) {
        continue
      }

      const key = prefix ? `${prefix}.${fieldId}` : fieldId
      const value = item[fieldId]

      fields = fields.concat(getFieldsSub(value, key))
    }

    return fields
  } else {
    if (!prefix) {
      return []
    }

    const name = prefix
      .split('.')
      .filter(fragment => Number.isNaN(parseInt(fragment)))
      .join(' > ')

    return [
      {
        key: prefix,
        name,
        type: getFieldType(item),
      },
    ]
  }
}
